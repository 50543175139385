import { api } from '@src/api/index'
import { API, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM } from '@src/constants/api'
import { getTenantSubdomain } from '@src/utils'

export type GoogleConsentSourceType = 'linked_accounts' | 'candidate_settings'
export type GoogleConsentType = 'calendar' | 'email'

export interface GoogleConsentStateInterface {
  subdomain?: string
  source?: GoogleConsentSourceType
  type?: GoogleConsentType
}

export const connectGmail = (
  code: string,
  redirectUrl: string,
  source?: GoogleConsentSourceType,
) =>
  api.post(API.CONNECT_GMAIL, {
    code,
    source,
    redirect_url: redirectUrl,
  })

export const connectGoogleCalendar = (code: string, redirectUrl: string) =>
  api.post(API.CONNECT_CALENDAR, {
    code,
    redirect_url: redirectUrl,
  })

export const getPermissionPopupUrl = (
  redirectUrl: string,
  state?: GoogleConsentStateInterface,
) =>
  api.get<{ auth_url: string }>(API.CONNECT_GMAIL, {
    params: {
      redirect_url: redirectUrl,
      state: state ? JSON.stringify(state) : undefined,
    },
  })

export const getCalendarPermissionPopupUrl = (
  redirectUrl: string,
  state?: GoogleConsentStateInterface,
) =>
  api.get<{ auth_url: string }>(API.CONNECT_CALENDAR, {
    params: {
      redirect_url: redirectUrl,
      state: state ? JSON.stringify(state) : undefined,
    },
  })

export const askEmailPermissions = async (source: GoogleConsentSourceType) => {
  let state: GoogleConsentStateInterface = {
    source,
    type: 'email',
  }

  state.subdomain = getTenantSubdomain('askEmailPermissions')

  const loginResponse = await getPermissionPopupUrl(
    GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
    state,
  )

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askCalendarPermissions = async () => {
  let state: GoogleConsentStateInterface = {
    type: 'calendar',
  }

  state.subdomain = getTenantSubdomain('askCalendarPermissions')

  const loginResponse = await getCalendarPermissionPopupUrl(
    GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
    state,
  )

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}
