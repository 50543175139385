import React from 'react'
import { DropdownController, Toolbox, Dropdown, HStack, Text } from '@revolut/ui-kit'
import {
  LexicalEditor,
  FORMAT_ELEMENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  INDENT_CONTENT_COMMAND,
} from 'lexical'
import { ChevronDownSmall } from '@revolut/icons'
import Icon from '@components/Icon/Icon'

interface AlignPluginProps {
  editor: LexicalEditor
}

export const AlignPlugin = ({ editor }: AlignPluginProps) => {
  return (
    <DropdownController>
      {dropdown => (
        <>
          <Toolbox.Action
            aria-label="Align"
            useEndIcon={ChevronDownSmall}
            {...dropdown.getAnchorProps()}
          >
            Align
          </Toolbox.Action>
          <Dropdown autoClose {...dropdown.getTargetProps()}>
            <Dropdown.Group>
              <Dropdown.Item
                use="button"
                color="foreground"
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
                }}
              >
                <HStack gap="s-16" align="center">
                  <Icon type="TextLeft" size="small" />
                  <Text>Left Align</Text>
                </HStack>
              </Dropdown.Item>
              <Dropdown.Item
                use="button"
                color="foreground"
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
                }}
              >
                <HStack gap="s-16" align="center">
                  <Icon type="TextCenter" size="small" />
                  <Text>Center Align</Text>
                </HStack>
              </Dropdown.Item>
              <Dropdown.Item
                use="button"
                color="foreground"
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
                }}
              >
                <HStack gap="s-16" align="center">
                  <Icon type="TextRight" size="small" />
                  <Text>Right Align</Text>
                </HStack>
              </Dropdown.Item>
              <Dropdown.Item
                use="button"
                color="foreground"
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
                }}
              >
                <HStack gap="s-16" align="center">
                  <Icon type="Justify" size="small" />
                  <Text>Justify Align</Text>
                </HStack>
              </Dropdown.Item>
            </Dropdown.Group>
            <Dropdown.Group>
              <Dropdown.Item
                use="button"
                color="foreground"
                onClick={() => {
                  editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
                }}
              >
                <HStack gap="s-16" align="center">
                  <Icon type="Outdent" size="small" />
                  <Text>Outdent</Text>
                </HStack>
              </Dropdown.Item>
              <Dropdown.Item
                use="button"
                color="foreground"
                onClick={() => {
                  editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)
                }}
              >
                <HStack gap="s-16" align="center">
                  <Icon type="Indent" size="small" />
                  <Text>Indent</Text>
                </HStack>
              </Dropdown.Item>
            </Dropdown.Group>
          </Dropdown>
        </>
      )}
    </DropdownController>
  )
}
