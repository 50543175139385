import React, { useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import {
  Action,
  ActionButton,
  Avatar,
  Box,
  Color,
  Item,
  Skeleton,
  StatusPopup,
  Subheader,
  useStatusPopup,
} from '@revolut/ui-kit'
import JobDescription from '@src/pages/Forms/JobPosting/Components/JobPosting'
import {
  patchJobDescriptionOfRequisition,
  useGetJobDescriptionOfRequisition,
} from '@src/api/requisitions'
import ChangeJobPostingSidebar from '@src/pages/Forms/JobPosting/Components/ChangeJobPostingSidebar'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { PageBody } from '@src/components/Page/PageBody'
import { Lightbulb } from '@revolut/icons'

const JobPosting = () => {
  const { values } = useLapeContext<RequisitionInterface>()
  const {
    data: jobDescription,
    isLoading: loadingJobPosting,
    refetch: refetchJobDescription,
  } = useGetJobDescriptionOfRequisition(values.id)
  const [openJobPostingSidebar, setOpenJobPostingSidebar] = useState(false)
  const statusPopup = useStatusPopup()
  const handleJobPostingChange = async (jobPosting?: JobPostingInterface) => {
    setOpenJobPostingSidebar(false)
    if (jobPosting) {
      const result = await patchJobDescriptionOfRequisition(values?.id, {
        job_posting: { id: jobPosting?.id },
      })

      if (result.data) {
        await refetchJobDescription()
        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>Job posting has changed</StatusPopup.Title>
          </StatusPopup>,
        )
      }
    }
  }
  return (
    <>
      <ChangeJobPostingSidebar
        onClose={() => setOpenJobPostingSidebar(false)}
        selectedJobPostingId={jobDescription?.job_posting?.id}
        specialisationId={values?.specialisation?.id}
        onChange={handleJobPostingChange}
        isOpen={openJobPostingSidebar}
      />
      <PageBody mt="-s-24">
        <Box>
          <Subheader variant="nested">
            <Subheader.Title>Job posting</Subheader.Title>
            {values.specialisation.id && jobDescription?.job_posting?.id && (
              <Subheader.Side>
                <Action onClick={() => setOpenJobPostingSidebar(true)}>Change</Action>
              </Subheader.Side>
            )}
          </Subheader>
          {loadingJobPosting ? (
            <Skeleton />
          ) : (
            <>
              {!jobDescription?.job_posting?.id ? (
                <Item>
                  <Item.Avatar>
                    <Avatar color={Color.BLUE} useIcon={Lightbulb} />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Description>
                      You can connect a Job Posting to this requisition, so locations in
                      the job posting can be passed directly from the requisition and so a
                      job posting will get unpublished automatically once all the
                      requisitions connected to it are closed.
                    </Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <Item.Value>
                      <ActionButton onClick={() => setOpenJobPostingSidebar(true)}>
                        Connect job posting
                      </ActionButton>
                    </Item.Value>
                  </Item.Side>
                </Item>
              ) : (
                <JobDescription id={jobDescription?.job_posting?.id} />
              )}
            </>
          )}
        </Box>
      </PageBody>
    </>
  )
}

export default JobPosting
