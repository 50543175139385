import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Flex,
  Text,
  Widget,
  chain,
  Color,
  Tooltip as UIKitTooltip,
  useTooltip,
  MoreButton,
  Tag,
  HStack,
  TextButton,
  Token,
} from '@revolut/ui-kit'
import {
  AnytimeFeedbackInterface,
  AnytimeFeedbackRating,
  AnytimeFeedbackVisibility,
  FeedbackCategory,
  FeedbackCategoryTitles,
} from '@src/interfaces/anytimeFeedback'
import {
  LikeDislike,
  SocialDislike,
  SocialLike,
  Ellipsis,
  Pencil,
  Delete,
  InfoOutline,
} from '@revolut/icons'
import Tooltip from '@components/Tooltip/Tooltip'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { deleteAnytimeFeedback } from '@src/api/anytimeFeedback'
import { getInitials, getAvatarUrl } from '@src/utils/employees'
import { PermissionTypes } from '@src/store/auth/types'
import DOMPurify from 'dompurify'
import { formatDateTime } from '@src/utils/format'
import isNil from 'lodash/isNil'
import { formatRelative } from 'date-fns'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { EmployeeInterface } from '@src/interfaces/employees'

type Props = {
  feedback: AnytimeFeedbackInterface
  refreshFeedbackCard: () => void
  employeeData: EmployeeInterface
  type?: FeedbackCategory
  withBorder?: boolean
}

export const AnytimeFeedbackCard = ({
  feedback,
  refreshFeedbackCard,
  employeeData,
  type = FeedbackCategory.Anytime,
  withBorder = false,
}: Props) => {
  const [deletePending, setDeletePending] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const feedbackReporter = feedback.reporter
  const feedbackReporterInitials =
    feedbackReporter?.full_name && getInitials(feedbackReporter.full_name)
  const tooltip = useTooltip()
  const canEditFeedback = !!feedback?.field_options?.permissions?.includes(
    PermissionTypes.CanEditFeedback,
  )
  const formPath =
    type === FeedbackCategory.Anytime
      ? ROUTES.FORMS.EMPLOYEE.FEEDBACK.ANYTIME
      : ROUTES.FORMS.EMPLOYEE.FEEDBACK.ONE_TO_ONE

  const editedAt = !isNil(feedback.update_date_time)
    ? formatRelative(new Date(feedback.update_date_time), new Date())
    : null

  const feedbackIcons = {
    [AnytimeFeedbackRating.Superpower]: <SocialLike color={Color.GREEN} size={16} />,
    [AnytimeFeedbackRating.Performing]: (
      <LikeDislike color={Color.GREY_TONE_50} size={16} />
    ),
    [AnytimeFeedbackRating.ImprovementArea]: (
      <SocialDislike color={Color.ORANGE} size={16} />
    ),
  }

  const getRelationship = () => {
    if (feedbackReporter.id === employeeData.line_manager?.id) {
      return 'LM'
    }
    if (feedbackReporter.id === employeeData.quality_control?.id) {
      return 'FM'
    }
    return 'peer'
  }

  const handleDeleteFeedback = async () => {
    try {
      setDeletePending(true)
      await deleteAnytimeFeedback(feedback.employee.id, feedback.id)
      pushNotification({
        value: 'Feedback was deleted',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
      refreshFeedbackCard()
    } finally {
      setDeletePending(false)
      setShowDeletePopup(false)
    }
  }

  const getDescription = () => {
    const body = feedback.body
    const isLongComment = body.split('\n').length > 4 || body.length > 400
    return isLongComment ? (
      <Flex flexDirection="column">
        <Box
          mt="s-12"
          style={
            showMore
              ? { whiteSpace: 'break-spaces' }
              : {
                  whiteSpace: 'break-spaces',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxHeight: '80px',
                }
          }
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(body),
          }}
        />
        <TextButton onClick={() => setShowMore(!showMore)} height="20px" mt="s-8">
          {showMore ? 'See less' : 'See more'}
        </TextButton>
      </Flex>
    ) : (
      <Box
        mt="s-12"
        style={{ whiteSpace: 'break-spaces' }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(body),
        }}
      />
    )
  }

  return (
    <>
      {feedback && (
        <>
          <Box
            mb="s-16"
            border={withBorder ? `1px solid ${Token.color.greyTone10}` : undefined}
            borderRadius={16}
          >
            <Widget p="s-16">
              <Flex justifyContent="space-between" alignItems="center">
                <Flex>
                  <Flex flex={1} height={40} alignItems="center" gap="s-8" mr="s-16">
                    {feedbackReporterInitials && (
                      <Avatar image={getAvatarUrl(feedbackReporter?.avatar)} size={40}>
                        {feedbackReporterInitials}
                      </Avatar>
                    )}
                  </Flex>
                  <Flex flexDirection="column">
                    <Flex alignItems="center" style={{ fontWeight: 500 }}>
                      {chain(
                        `${feedbackReporter?.full_name} (${getRelationship()})`,
                        feedbackIcons[feedback.rating],
                      )}
                    </Flex>
                    <Flex color="grey-tone-50">
                      {chain(
                        formatDateTime(feedback.creation_date_time),
                        feedback.updated_by && editedAt && (
                          <HStack gap="s-4">
                            <Text>Edited</Text>
                            <Tooltip placement="top" text={editedAt}>
                              <InfoOutline size={12} />
                            </Tooltip>
                          </HStack>
                        ),
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex alignItems="center">
                  <HStack space="s-8" mr="s-12">
                    <Tag variant="outlined" color={Token.color.deepGrey}>
                      {FeedbackCategoryTitles[feedback.category]}
                    </Tag>
                    {!!feedback.labels?.length && (
                      <Tag
                        variant="outlined"
                        color="deep-grey"
                        style={{ cursor: 'pointer' }}
                        {...tooltip.getAnchorProps()}
                      >
                        +{feedback.labels.length}
                        <UIKitTooltip
                          {...tooltip.getTargetProps()}
                          placement="bottom-start"
                          width="100"
                        >
                          <Box style={{ whiteSpace: 'break-spaces' }}>
                            {feedback.labels.join('\n')}
                          </Box>
                        </UIKitTooltip>
                      </Tag>
                    )}
                  </HStack>
                  {canEditFeedback && (
                    <MoreButton
                      variant="icon"
                      label="More actions"
                      useIcon={Ellipsis}
                      // @ts-ignore color exists, TS definition is lying
                      color="grey-tone-50"
                    >
                      <MoreButton.Action
                        useIcon={Pencil}
                        onClick={() => {
                          navigateTo(
                            pathToUrl(formPath, {
                              userId: feedback.employee.id,
                              id: feedback.id,
                            }),
                          )
                        }}
                      >
                        Edit Feedback
                      </MoreButton.Action>
                      <MoreButton.Action
                        useIcon={Delete}
                        onClick={() => setShowDeletePopup(true)}
                      >
                        Delete Feedback
                      </MoreButton.Action>
                    </MoreButton>
                  )}
                </Flex>
              </Flex>
              {getDescription()}
              {feedback.visibility === AnytimeFeedbackVisibility.ManagersOnly && (
                <Text display="flex" color="grey-tone-50" fontSize="small" mt="s-8">
                  Visible to managers only
                </Text>
              )}
            </Widget>
          </Box>
          <ConfirmationDialog
            open={showDeletePopup}
            onClose={() => setShowDeletePopup(false)}
            onConfirm={handleDeleteFeedback}
            onReject={() => setShowDeletePopup(false)}
            label="Delete this feedback?"
            body="Deleting a feedback will permanently remove it from employee’s profile"
            loading={deletePending}
            submitDisabled={deletePending}
            yesMessage="Delete"
            noMessage="Cancel"
          />
        </>
      )}
    </>
  )
}
