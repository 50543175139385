import React from 'react'
import styled from 'styled-components'
import Button from '@components/Button/Button'
import { colorGetter } from '@src/styles/colors'
import { Color, Token } from '@revolut/ui-kit'

const Container = styled.div`
  color: ${colorGetter(Color.FOREGROUND)};
  background-color: ${Token.color.layoutBackground};
  z-index: ${props => props.theme.zIndex.popup};
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const Content = styled.div`
  max-width: 800px;
`

const Title = styled.div`
  font-size: 35px;
  line-height: 44px;
  width: 100%;
  text-align: left;
  font-weight: 600;
`

const Steps = styled.div`
  display: grid;
  grid-template-columns: 180px 180px 180px;
  justify-content: start;
  grid-gap: 16px;
  margin: 24px 0;
  counter-reset: section;
`

const Step = styled.div`
  width: 100%;
  height: 116px;
  padding: 8px 16px;
  background: ${colorGetter(Color.GREY_20_OPAQUE_50)};
  border-radius: 13px;
  font-weight: 500;
  font-size: 32px;

  &::before {
    counter-increment: section;
    content: counter(section);
  }
`
const A = styled.a`
  color: ${colorGetter(Color.BLUE)};
  text-decoration: none;

  &:hover {
    color: ${colorGetter(Color.BLUE_OPAQUE_60)};
  }
`

const StepName = styled.div`
  font-size: 14px;
`

const ContinueButton = styled(Button)`
  margin-top: 36px;
`

const Text = styled.p`
  line-height: 28px;
`

interface Props {
  onClose: () => void
}

export const Welcome = ({ onClose }: Props) => (
  <Container>
    <Wrapper>
      <Content>
        <Title>Deliverables</Title>

        <p>
          We are introducing a new deliverables assessment tool to facilitate continuous
          feedback.
        </p>

        <Steps>
          <Step>
            <StepName>Track your completed Jira tickets over time </StepName>
          </Step>
          <Step>
            <StepName>Get manager’s feedback continuously</StepName>
          </Step>
          <Step>
            <StepName>Follow your personal progress over time</StepName>
          </Step>
        </Steps>
        <Text>
          Functional managers must take actions on all the deliverables at least once a
          month.
          <br />
          <A
            href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1654309109/Functional+deliverables+review"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </A>{' '}
          about functional reviews.
        </Text>
        <ContinueButton type="button" onClick={onClose} square>
          Continue
        </ContinueButton>
      </Content>
    </Wrapper>
  </Container>
)
