import React from 'react'
import { Header, Popup } from '@revolut/ui-kit'
import SendOnlineTest from '@src/pages/Forms/SendOnlineTest'

type Props = {
  onClose: () => void
  nextStageId: number
  candidateId: number
  roundId?: number
  onSuccess: () => void
}

const CvScreeningSchedule = ({
  onClose,
  nextStageId,
  candidateId,
  roundId,
  onSuccess,
}: Props) => {
  return (
    <Popup size="md" open onClose={onClose}>
      <Header variant="compact">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Send online test</Header.Title>
      </Header>
      <SendOnlineTest
        candidateId={candidateId}
        roundId={roundId}
        stageId={nextStageId}
        onSuccess={onSuccess}
        insideSidebar
      />
    </Popup>
  )
}

export default CvScreeningSchedule
