import * as React from 'react'
import styled from 'styled-components'
import Label, { TopProps } from '../partials/Label'
import BottomText, { BottomProps } from '../partials/BottomText'
import { useState } from 'react'
import { getListIndicator, ListType } from '@src/utils'
import { getColor } from '@src/styles/colors'
import { Color, Token } from '@revolut/ui-kit'

const Input = styled.textarea<{
  error?: string | boolean
  minHeight: number
  border?: string
  type: 'primary' | 'secondary'
  background?: string
  isListType?: boolean
  defaultHeight: number
}>`
  resize: none;
  border: 1px solid
    ${({ theme, error, border, type }) => {
      if (error) {
        return theme.colors.red
      }
      if (type === 'secondary') {
        return 'transparent'
      }
      return border || getColor(theme, Color.GREY_20_OPAQUE_90)
    }};
  background-color: ${({ background, type }) => {
    if (background) {
      return background
    }
    if (type === 'secondary') {
      return '#f6f7f8'
    }
    return background || Token.color.background
  }};
  border-radius: 4px;
  outline: none;
  height: ${({ defaultHeight }) => `${defaultHeight}px`};
  min-height: ${({ minHeight }) => `${minHeight}px`};
  width: ${props => (props.isListType ? 'calc(100%)' : '100%')};
  padding: 9px 10px;
  box-sizing: border-box;
  padding-left: ${props => (props.isListType ? '29px' : '9px')};
  font-size: 16px;
  line-height: 18px;
  color: ${props => props.theme.colors.foreground};

  &::placeholder {
    color: ${props => {
      if (props.type === 'secondary') {
        return getColor(props.theme, Color.GREY_TONE_50)
      }
      return getColor(props.theme, Color.GREY_20_OPAQUE_90)
    }};
    font-size: 13px;
    line-height: 18px;
    opacity: 1;
  }

  &:disabled {
    background-color: ${({ background, theme }) => {
      return background || theme.colors['grey-tone-2']
    }};
    cursor: not-allowed;
  }
`

const LabelWrapper = styled.label`
  position: relative;
  display: grid;
  align-items: start;
  align-content: start;
`

const InputContainer = styled.div``

const ListContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 9px 10px;
  width: 100%;
  pointer-events: none;
  font-size: 16px;
  line-height: 18px;
`

const ListItemText = styled.pre`
  color: transparent;
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
  letter-spacing: normal;
  font-family: inherit;
  margin: 0 0 0 20px;
  font-size: inherit;
  line-height: inherit;
  display: inline-block;
`

const ListIndicator = styled.div<{ isPlaceholder?: boolean }>`
  color: ${props =>
    getColor(
      props.theme,
      props.isPlaceholder ? Color.GREY_20_OPAQUE_90 : Color.GREY_TONE_50,
    )};
  line-height: inherit;
  position: absolute;
  left: -20px;
`

export interface TextAreaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>,
    TopProps,
    BottomProps {
  /** The color of outline border of the input */
  outlineColor?: string
  /** THe color fot he background of the input */
  backgroundColor?: string
  type?: 'primary' | 'secondary'
  minHeight?: number
  listType?: ListType
  defaultHeight?: number
  onChange?: (value: string) => void
  onGetElement?: (elem: HTMLTextAreaElement | null) => void
}

const TextAreaField = ({
  bottomInfo,
  minHeight = 38,
  defaultHeight = 38,
  listType,
  outlineColor,
  type = 'primary',
  backgroundColor,
  onChange,
  onGetElement,
  name,
  ...rest
}: TextAreaProps) => {
  const [height, setHeight] = useState(defaultHeight)

  const calcHeight = (ref: HTMLTextAreaElement | null) => {
    if (ref) {
      ref.style.height = '1px'
      const newHeight =
        ref.scrollHeight > defaultHeight ? ref.scrollHeight + 2 : defaultHeight
      ref.style.height = `${newHeight}px`
      setHeight(newHeight)
    }
    onGetElement && onGetElement(ref)
  }
  const calculateTextAreaHeight = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(event.target.value)
    calcHeight(event.target)
  }

  const value = rest.value ? rest.value : rest.placeholder

  return (
    <LabelWrapper>
      <Label kind={type} {...rest} />
      <InputContainer style={{ height, minHeight: `${minHeight}px` }}>
        {listType && (
          <ListContainer
            className={rest.className}
            style={{ height, minHeight: `${minHeight}px` }}
          >
            {`${value || ''}`.split('\n').map((section, id) => {
              return (
                <ListItemText key={id}>
                  <ListIndicator isPlaceholder={!rest.value}>
                    {getListIndicator(id, listType)}
                  </ListIndicator>
                  {section || ` `}
                </ListItemText>
              )
            })}
          </ListContainer>
        )}
        <Input
          style={{ height }}
          type={type}
          data-testid={name}
          {...rest}
          background={backgroundColor}
          border={outlineColor}
          isListType={!!listType}
          ref={calcHeight}
          minHeight={minHeight}
          defaultHeight={defaultHeight}
          onChange={calculateTextAreaHeight}
        />
      </InputContainer>
      <BottomText bottomInfo={bottomInfo} error={rest.error} />
    </LabelWrapper>
  )
}

export default TextAreaField
