import { TextButton, Widget } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import {
  performanceGradeColumn,
  performanceTimelineNameColumn,
  performanceTimelineSeniorityColumn,
  performanceTimelineSpecialisationColumn,
} from '@src/constants/columns/performance'
import { TableNames } from '@src/constants/table'
import { SummaryTableContainer, summaryTableProps } from '@src/features/Summary/common'
import { RowInterface } from '@src/interfaces/data'
import { PerformanceTimelineInterface } from '@src/interfaces/performance'
import React, { useState } from 'react'
import { usePerformanceTimelineTable } from '../../EmployeeProfile/Preview/Performance/PerformanceHistory/hooks'
import { EmployeeInterface } from '@src/interfaces/employees'

const ROW: RowInterface<PerformanceTimelineInterface> = {
  noChildrenRequest: true,
  cells: [
    {
      ...performanceTimelineNameColumn,
      width: 100,
    },
    {
      ...performanceGradeColumn,
      width: 100,
    },
    {
      ...performanceTimelineSeniorityColumn,
      width: 140,
    },
    {
      ...performanceTimelineSpecialisationColumn,
      width: 100,
    },
  ],
}

interface PerformanceTimelineProps {
  employee: EmployeeInterface
}

export const PerformanceTimeline = (props: PerformanceTimelineProps) => {
  const { employee } = props
  const [rowsExpanded, setRowsExpanded] = useState(false)

  const performanceTimelineTable = usePerformanceTimelineTable(employee)
  const tableData = performanceTimelineTable.table?.data || []
  const cutByLines = 5
  const showBtn = tableData.length > cutByLines
  const filteredTableData =
    !showBtn || rowsExpanded ? tableData : tableData.slice(0, cutByLines)

  return (
    <Widget p="s-16">
      <SummaryTableContainer>
        <AdjustableTable
          loading={performanceTimelineTable.table?.loading}
          hideCountAndButtonSection
          expandableType="chevron"
          name={TableNames.PerformanceTimelineSidebar}
          noDataMessage="Performance timeline will appear here"
          row={ROW}
          {...summaryTableProps}
          {...performanceTimelineTable.table}
          data={filteredTableData}
          count={filteredTableData.length}
        />
      </SummaryTableContainer>
      {showBtn && (
        <TextButton onClick={() => setRowsExpanded(!rowsExpanded)} mt="s-8">
          View {rowsExpanded ? 'less' : 'more'}
        </TextButton>
      )}
    </Widget>
  )
}
