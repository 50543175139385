import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Flex } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { navigateTo } from '@src/actions/RouterActions'
import { reportsTableRequests } from '@src/api/dataAnalytics'
import {
  reportsDescriptionTableColumn,
  reportsLastRunTableColumn,
  reportsNameTableColumn,
  reportsNextRunTableColumn,
  reportsOwnerColumn,
  reportsStatusColumn,
} from '@src/constants/columns/dataAnalytics'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { RowInterface } from '@src/interfaces/data'
import { ReportInterface } from '@src/interfaces/dataAnalytics'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableWrapper } from '@components/Table/TableWrapper'
import { useTable } from '@components/Table/hooks'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

const ROW: RowInterface<ReportInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.VIEW_DATA_ANALYTICS_REPORT.PREVIEW, { id })),
  cells: [
    {
      ...reportsNameTableColumn,
      width: 200,
    },
    {
      ...reportsDescriptionTableColumn,
      width: 250,
    },
    {
      ...reportsOwnerColumn,
      width: 200,
    },
    {
      ...reportsStatusColumn,
      width: 150,
    },
    {
      ...reportsLastRunTableColumn,
      width: 250,
    },
    {
      ...reportsNextRunTableColumn,
      width: 250,
    },
  ],
}

export const ReportsTable = () => {
  const table = useTable<ReportInterface>(reportsTableRequests)
  const featureFlags = useSelector(selectFeatureFlags)
  const canAdd = featureFlags?.includes(FeatureFlags.ReportingApp)

  return (
    <TableWrapper>
      <Stat label="Total" mb="s-12" val={table?.loading ? undefined : table?.count} />
      {canAdd && (
        <Flex>
          <Button
            size="sm"
            to={pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_REPORT.TARGET)}
            variant="secondary"
            use={InternalLink}
            useIcon={Plus}
          >
            Add new report
          </Button>
        </Flex>
      )}
      <AdjustableTable
        {...table}
        dataType="Reports"
        hideCount
        name={TableNames.ReportingAppReports}
        noDataMessage="List of all reports will appear here."
        row={ROW}
        useWindowScroll
      />
    </TableWrapper>
  )
}
