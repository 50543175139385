import React, { ReactChild } from 'react'
import { Modal } from '../Modal'
import styled, { keyframes } from 'styled-components'

type DirectionType = 'right' | 'left'

const OpacityAppear = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`

const SlideFromLeft = keyframes`
  0% {
    transform: translateX(-100%)
  }
  
  100% {
    transform: translateX(0%)
  }
`

const SlideFromRight = keyframes`
  0% {
    transform: translateX(100%)
  }
  
  100% {
    transform: translateX(0%)
  }
`

const Curtain = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  animation-name: ${OpacityAppear};
  animation-fill-mode: forwards;
  animation-duration: 200ms;
  animation-timing-function: ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
`

const Container = styled.div<{ direction: DirectionType }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100vh;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  animation-name: ${props =>
    props.direction === 'right' ? SlideFromRight : SlideFromLeft};
  animation-fill-mode: forwards;
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
  ${props => props.direction === 'left' && 'left: 0'};
  ${props => props.direction === 'right' && 'right: 0'};
`

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${props => props.theme.zIndex.popup};
`

interface DialogProps {
  children: ReactChild
  onClose?: () => void
  open: boolean
  direction?: DirectionType
}

const Drawer = ({ open, onClose, children, direction = 'left' }: DialogProps) => {
  if (!open) {
    return null
  }

  return (
    <Modal>
      <Wrapper>
        <Curtain data-testid="drawer-curtain" onClick={onClose} />
        <Container direction={direction}>{children}</Container>
      </Wrapper>
    </Modal>
  )
}

export default Drawer
