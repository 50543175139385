import React from 'react'
import { VStack, Text, useMatchMedia, Token, Avatar } from '@revolut/ui-kit'
import { getIconImage } from '@src/pages/Hub/utils'

interface StepsBlockItemProps {
  image?: string
  title?: string
  description?: string
}

export const StepsBlockItem = ({ image, title, description }: StepsBlockItemProps) => {
  const isXxlBreakpoint = useMatchMedia(Token.media.xxl)
  const iconSize = isXxlBreakpoint ? 40 : 32

  return (
    <VStack
      bg="grouped-background"
      borderRadius={20}
      padding={{ all: 's-24', xxl: 's-32' }}
      space={{ all: 's-8', xxl: 's-16' }}
      use="li"
    >
      {image && <Avatar size={iconSize} image={getIconImage(image, 60, 'png')} />}
      {title && (
        <Text mt="s-8" variant="h6" whiteSpace="pre-wrap">
          {title}
        </Text>
      )}
      {description && (
        <Text use="p" color="grey-50" variant="primary" whiteSpace="pre-wrap">
          {description}
        </Text>
      )}
    </VStack>
  )
}
