import React, { useState } from 'react'
import ActionWidget, { ActionWidgetProps } from '@components/ActionWidget/ActionWidget'
import { Button, StatusPopup } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { approveProbationGoals } from '@src/api/probationReview'
import { useParams } from 'react-router-dom'
import { ProbationCheckpoint } from '@src/interfaces/probationReview'
import { PermissionTypes } from '@src/store/auth/types'

export interface PendingGoalsBannerProps extends ActionWidgetProps {
  employee: EmployeeInterface
  checkpoint: ProbationCheckpoint
  refetch: () => Promise<any>
}

const ApproveGoalsWidget = ({
  checkpoint,
  employee,
  refetch,
  ...props
}: PendingGoalsBannerProps) => {
  const { cycleId } = useParams<{ cycleId: string }>()
  const [isApproving, setIsApproving] = useState<boolean>()
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>()

  const canApprove = checkpoint.user_permissions?.includes(
    PermissionTypes.ApproveProbationCheckpoint,
  )

  return (
    <>
      <ActionWidget
        title="Goals pending review by HR manager"
        text={`Probation goals needs to be reviewed and checked if they are set to the 
          standards described in this playbook. ${
            canApprove
              ? `If you are not happy with the goals, 
          please talk with the Line manager of this employee: ${employee.line_manager.full_name}`
              : ''
          }`}
        {...props}
      >
        {canApprove && (
          <Button
            variant="secondary"
            size="sm"
            pending={isApproving}
            onClick={() => {
              setIsApproving(true)
              approveProbationGoals(employee.id, cycleId, checkpoint.id)
                .then(refetch)
                .finally(() => setIsApproving(false))
            }}
          >
            Mark as reviewed
          </Button>
        )}
      </ActionWidget>

      <StatusPopup
        variant="success"
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>Probation goals approved</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

export default ApproveGoalsWidget
