import React from 'react'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { formatDuration } from 'date-fns'
import { FormPreview } from '@components/FormPreview/FormPreview'

const OnlineTestPreviewDuration = () => {
  return (
    <FormPreview.Item<HiringProcessInterface>
      title="Test duration"
      insert={data => {
        if (!data.online_test?.duration) {
          return '-'
        }

        const hours = Math.floor(data.online_test.duration / 60)
        const minutes = data.online_test.duration - hours * 60

        return formatDuration({
          hours,
          minutes,
        })
      }}
    />
  )
}

export default OnlineTestPreviewDuration
