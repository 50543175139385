import React from 'react'
import { Button, Flex } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { useSelector } from 'react-redux'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/Table/AdjustableTable'
import Stat from '@src/components/Stat/Stat'
import { DocumentsCategoriesInterface } from '@src/interfaces/documentsCategories'
import { getDocumentsCategories } from '@src/api/documentsCategories'
import { documentCategoryName } from '@src/constants/columns/documentCategories'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const ROW: RowInterface<DocumentsCategoriesInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT_CATEGORIES.GENERAL, { id })),
  cells: [
    {
      ...documentCategoryName,
      width: 720,
    },
  ],
}

const Categories = () => {
  const table = useTable<DocumentsCategoriesInterface>({
    getItems: getDocumentsCategories,
  })
  const permissions = useSelector(selectPermissions)

  const canCreate = permissions?.includes(PermissionTypes.AddDocumentCategories)

  return (
    <TableWrapper>
      <Stat label="Total" val={table.loading ? undefined : table.count} mb="s-16" />

      {canCreate ? (
        <Flex mb="s-16">
          <Button
            onClick={() =>
              navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT_CATEGORIES.GENERAL))
            }
            mr="s-16"
            useIcon={Plus}
            size="sm"
            variant="secondary"
          >
            Create category
          </Button>
        </Flex>
      ) : null}

      <AdjustableTable
        name={TableNames.DocumentCategories}
        useWindowScroll
        row={ROW}
        {...table}
      />
    </TableWrapper>
  )
}

export default Categories
