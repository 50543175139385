import React from 'react'
import { BREAKPOINTS, Box, Flex, MoreBar } from '@revolut/ui-kit'
import { useTable } from '@src/components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  paymentsEmployeeColumn,
  paymentsPaygroupColumn,
  paymentsCycleColumn,
  paymentsElementColumn,
  paymentsDirectionColumn,
  paymentsAmountColumn,
  paymentsStatusColumn,
} from '@src/constants/columns/payments'
import Stat from '@src/components/Stat/Stat'
import { TableWrapper } from '@src/components/Table/TableWrapper'
import { paymentsTableRequests } from '@src/api/payroll'
import { Coins, Delete, Upload } from '@revolut/icons'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import pluralize from 'pluralize'
import { PayCyclePaymentInterface } from '@src/interfaces/payroll'
import { useParams } from 'react-router-dom'
import { findShareOptionsPayGroup } from '@src/apps/People/Compensation/helpers/findShareOptionsPayGroup'
import { groupFiltersIntoURLQuery } from '@src/utils/table'
import { PageBody } from '@src/components/Page/PageBody'
import { navigateTo } from '@src/actions/RouterActions'

export const OptionPaymentsTable = () => {
  const { id: payCycleId } = useParams<{ id: string }>()
  const { isAvailable, payGroupId } = findShareOptionsPayGroup()

  const navigateWithQueryParams = (url: string, queryParams: {}) => {
    const queryString = new URLSearchParams(Object.entries(queryParams)).toString()
    navigateTo(`${url}?${queryString}`)
  }

  const getInitialFilters = () => {
    if (!isAvailable || !payGroupId) {
      return []
    }
    return [
      {
        filters: [
          {
            name: `${payGroupId}`,
            id: payGroupId,
          },
        ],
        columnName: 'pay_cycle__pay_group_id',
        nonResettable: true,
      },
      {
        filters: [
          {
            name: `${payCycleId}`,
            id: payCycleId,
          },
        ],
        columnName: 'pay_cycle__id',
        nonResettable: true,
      },
    ]
  }

  const table = useTable(paymentsTableRequests, getInitialFilters())
  const queryParams = groupFiltersIntoURLQuery(table.filterBy)

  const Row: RowInterface<PayCyclePaymentInterface> = {
    cells: [
      { ...paymentsEmployeeColumn, width: 150 },
      { ...paymentsPaygroupColumn, width: 150 },
      { ...paymentsCycleColumn, width: 130 },
      { ...paymentsElementColumn, width: 130 },
      { ...paymentsDirectionColumn, width: 100 },
      { ...paymentsAmountColumn, width: 150 },
      { ...paymentsStatusColumn, width: 150 },
    ],
  }

  return (
    <PageBody maxWidth={BREAKPOINTS.lg}>
      <TableWrapper>
        <Flex mb="s-16" justifyContent="space-between">
          <Flex gap="s-32">
            <Stat
              label={pluralize('Charge', table.stats?.total)}
              val={table.stats?.total}
            />
            <Stat label="Unpaid/Unreconciled" val={table.stats?.unpaid} color="orange" />
            <Stat label="Pending" val={table.stats?.pending} color="orange" />
            <Stat label="Failed" val={table.stats?.failed} color="red" />
            <Stat label="Completed" val={table.stats?.completed} color="green" />
          </Flex>
        </Flex>
        <Box mb="s-16">
          <MoreBar>
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.UPLOAD, { id: payCycleId })}
              useIcon={Upload}
            >
              Upload exercise payments
            </MoreBar.Action>
            <MoreBar.Action
              onClick={() =>
                navigateWithQueryParams(
                  pathToUrl(ROUTES.APPS.PAYROLL.RECONCILE_PAYMENT),
                  queryParams,
                )
              }
              useIcon={Coins}
            >
              Reconcile payments
            </MoreBar.Action>
            <MoreBar.Action
              onClick={() =>
                navigateWithQueryParams(
                  pathToUrl(ROUTES.APPS.PAYROLL.BULK_DELETE_PAYMENTS),
                  queryParams,
                )
              }
              variant="negative"
              useIcon={Delete}
            >
              Delete charges
            </MoreBar.Action>
          </MoreBar>
        </Box>

        <AdjustableTable
          name={TableNames.OptionPayments}
          row={Row}
          useWindowScroll
          {...table}
        />
      </TableWrapper>
    </PageBody>
  )
}
