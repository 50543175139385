import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Icon from '../Icon/Icon'
import { Color } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Callback for clear button click */
  onRemove?: () => void
  /** Callback for on click anywhere else */
  onClick?: () => void
  /** Label of the Chip */
  label: ReactNode
}

const RemoveButton = styled.button.attrs({ type: 'button' })`
  flex: 0 0 32px;
  width: 32px;
  color: ${({ theme }) => theme.colors.blue};
  opacity: 0.1;
  &:hover {
    opacity: 0.2;
  }
`

const Label = styled.button.attrs({ type: 'button' })`
  display: flex;
  font-size: 12px;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: 500;
  overflow: hidden;
  flex-grow: 1;
  text-overflow: ellipsis;
  padding-left: 8px;
`

const Wrapper = styled.div<{ disabled?: boolean }>`
  max-width: 180px;
  background-color: ${({ theme, disabled }) =>
    disabled ? getColor(theme, Color.GREY_50_OPAQUE_10) : 'rgba(0, 117, 235, 0.06)'};
  min-width: 110px;
  font-size: 10px;
  height: 32px;
  border-radius: 4px;
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? getColor(theme, Color.GREY_50_OPAQUE_10) : 'rgba(0, 117, 235, 0.06)'};
  }
  display: flex;

  & > ${Label} {
    color: ${({ theme, disabled }) =>
      getColor(theme, disabled ? Color.GREY_TONE_50 : Color.BLUE)};
  }
`

const Chip = ({ label, onRemove, onClick, className, disabled }: Props) => {
  return (
    <Wrapper className={className} disabled={disabled}>
      <Label onClick={!disabled ? onClick : () => {}}>{label}</Label>
      {onRemove && (
        <RemoveButton onClick={onRemove}>
          <Icon type="CloseCircle" size="small" />
        </RemoveButton>
      )}
    </Wrapper>
  )
}

export default Chip
