import React, { useMemo, useState, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Color,
  Dots,
  Text,
  Widget,
  Tooltip,
  useTooltip,
  BottomSheet,
  Header,
} from '@revolut/ui-kit'

import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import {
  deleteScorecard,
  postFeedbackRequest,
  postPerfReviewFeedbackRequest,
} from '@src/api/performance'
import {
  PerformanceSelector,
  PerfReviewRequestFeedbackInterface,
  RequestFeedbackInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { CheckpointType, ProbationCheckpoint } from '@src/interfaces/probationReview'
import { Grid } from '@components/CommonSC/Grid'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { Statuses } from '@src/interfaces'
import SideBar from '@components/SideBar/SideBar'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import Reviewer from '../Common/Reviewer'

export interface RequestFeedbackSelector
  extends Pick<PerformanceSelector, 'id' | 'category' | 'version'> {}

type Props = {
  canRequest: boolean
  performanceCycle: RequestFeedbackSelector
  isNewFlow: boolean
  category: ReviewCategory
  checkpoints?: ProbationCheckpoint[]
  requests?: (PerfReviewRequestFeedbackInterface | RequestFeedbackInterface)[]
  onClose: () => void
  id: number
  onAddRequest: (
    request?: PerfReviewRequestFeedbackInterface | RequestFeedbackInterface,
  ) => void
  fetching?: boolean
  checkpointNumber?: number
  type?: 'sidebar' | 'popup'
}

type ContainerProps = PropsWithChildren<{
  onClose: () => void
}>

export const RequestFeedbackSidebar = ({ onClose, children }: ContainerProps) => {
  return (
    <SideBar title="Request review" onClose={onClose}>
      {children}
    </SideBar>
  )
}

export const RequestFeedbackPopup = ({ children, onClose }: ContainerProps) => {
  return (
    <BottomSheet onClose={onClose} open>
      <Header>
        <Header.Title>Request review</Header.Title>
      </Header>
      {children}
    </BottomSheet>
  )
}

export const RequestFeedback = ({
  performanceCycle,
  canRequest,
  isNewFlow,
  category,
  checkpoints,
  id,
  onClose,
  requests,
  onAddRequest,
  fetching,
  checkpointNumber,
  type = 'sidebar',
}: Props) => {
  const user = useSelector(selectUser)
  const tooltip = useTooltip()
  const { data: performanceSettings } = useGetPerformanceSettings()

  const [employee, setEmployee] = useState<OptionInterface | null>(null)
  const [checkpoint, setCheckpoint] = useState<OptionInterface | null>(null)
  const [relationship, setRelationship] = useState<OptionInterface | null>(null)
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState<{ [key: string]: boolean }>({})
  const permissions = useSelector(selectPermissions)
  const canSeeRelationship =
    permissions.includes(PermissionTypes.ViewEmployeesprobation) &&
    (category === ReviewCategory.Probation || category === ReviewCategory.PIP_V2)

  const checkpointOptions = useMemo(() => {
    if (!checkpoints) {
      return []
    }
    return checkpoints.reduce((acc: OptionInterface[], cp) => {
      if (cp.checkpoint_type === CheckpointType.Review) {
        acc.push({
          id: cp.checkpoint_number,
          name: `Checkpoint ${cp.checkpoint_number}`,
        })
      }
      return acc
    }, [])
  }, [checkpoints])

  const getCheckpointOptions = async (): Promise<OptionInterface[]> => {
    return checkpointOptions
  }

  const handleCheckpointChange = (chosenCheckpoint: OptionInterface | null) => {
    setCheckpoint(chosenCheckpoint)
  }

  const clear = () => {
    setEmployee(null)
    setCheckpoint(null)
    setRelationship(null)
  }

  const createNewFlowFeedback = async (chosenEmployee: OptionInterface) => {
    setLoading(true)
    let checkpointFilter

    if (checkpointNumber) {
      checkpointFilter = checkpointNumber
    } else if (checkpoint) {
      checkpointFilter = +checkpoint.id
    }

    try {
      const result = await postPerfReviewFeedbackRequest(
        id,
        String(performanceCycle.id),
        {
          reviewer: {
            id: +chosenEmployee!.id,
          },
          team_mate: !relationship?.id
            ? {
                id: +chosenEmployee!.id,
              }
            : undefined,
          reviewer_relation: (relationship?.id as string) || undefined,
          category:
            performanceCycle.category === ReviewCycleCategory.Probation
              ? ReviewCategory.Probation
              : category,
          checkpoint_number: checkpointFilter,
        },
      )

      if (result.data) {
        onAddRequest(result.data)
        clear()

        pushNotification({
          value: `Review request successfully created.`,
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (request: PerfReviewRequestFeedbackInterface) => {
    if (request?.id) {
      let newDeleting = { ...deleting }
      newDeleting[request.id] = true
      setDeleting(newDeleting)

      try {
        await deleteScorecard(id, performanceCycle.id, request.id)
        pushNotification({
          value: 'The request was deleted.',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
        onAddRequest()
      } finally {
        let newDel = { ...deleting }
        newDel[request.id] = false
        setDeleting(newDel)
      }
    }
  }

  const onSendRequest = async (selfReviewDisabled: boolean) => {
    if (!employee || selfReviewDisabled) {
      return
    }

    if (isNewFlow || checkpoints) {
      createNewFlowFeedback(employee)
      return
    }

    setLoading(true)
    try {
      const result = await postFeedbackRequest({
        requested_by: {
          id: user.id,
        },
        reviewed_by: {
          id: employee!.id as number,
        },
        reviewed_person: {
          id,
        },
        cycle:
          performanceCycle.category === ReviewCycleCategory.Probation ||
          (performanceCycle.category === ReviewCycleCategory.PIP &&
            performanceCycle.version === 2)
            ? null
            : {
                id: performanceCycle.id,
              },
        category: performanceCycle.category,
      })
      if (result.data) {
        onAddRequest(result.data)
        clear()
      }
    } finally {
      setLoading(false)
    }
  }

  const handleRequesteeChange = async (chosenEmployee: OptionInterface | null) => {
    setEmployee(chosenEmployee)
  }

  const renderContent = () => {
    if (fetching || !requests) {
      return (
        <Widget overflow="auto" p="s-16" variant="plain">
          <Dots color={Color.GREY_TONE_50} />
        </Widget>
      )
    }

    if (!requests.length) {
      return (
        <Widget overflow="auto" p="s-16" variant="plain">
          <Text>No requests sent</Text>
        </Widget>
      )
    }

    if (category === ReviewCategory.Upwards) {
      return (
        <Widget overflow="auto" p="s-16" variant="plain">
          <Stat label="Requests sent" val={requests.length} />
        </Widget>
      )
    }

    if (
      (category === ReviewCategory.Probation || category === ReviewCategory.PIP_V2) &&
      checkpoints
    ) {
      return checkpoints
        .filter(item => item.checkpoint_type === CheckpointType.Review)
        .map(cp => (
          <Box key={cp.checkpoint_number}>
            <Text
              use="div"
              mt="s-16"
              mb="s-16"
              fontWeight={500}
              color={Color.GREY_TONE_50}
            >
              Checkpoint {cp.checkpoint_number}
            </Text>
            <Widget overflow="auto" p="s-16" variant="plain">
              <Grid flow="row" gap={32}>
                {requests
                  .filter(
                    item => item.probation_checkpoint?.number === cp.checkpoint_number,
                  )
                  .map(request => (
                    <Reviewer
                      canDelete={
                        request.status !== Statuses.completed &&
                        request.status !== Statuses.completed_late
                      }
                      key={request.id}
                      pendingDeletion={request.id ? deleting[request.id] : false}
                      request={request}
                      isNewFlow={isNewFlow}
                      onDelete={() =>
                        handleDelete(request as PerfReviewRequestFeedbackInterface)
                      }
                    />
                  ))}
              </Grid>
            </Widget>
          </Box>
        ))
    }

    return (
      <Widget overflow="auto" p="s-16" variant="plain">
        <Grid flow="row" gap={32}>
          {requests.map(request => (
            <Reviewer key={request.id} request={request} isNewFlow={isNewFlow} />
          ))}
        </Grid>
      </Widget>
    )
  }

  const selfReviewDisabled =
    employee?.id === id && !performanceSettings?.enable_self_reviews

  const Container = type === 'sidebar' ? RequestFeedbackSidebar : RequestFeedbackPopup

  return (
    <Container onClose={onClose}>
      {checkpoints && (
        <Box mt="s-16">
          <RadioSelectInput
            label="Checkpoint"
            value={checkpoint}
            selector={getCheckpointOptions}
            onChange={handleCheckpointChange}
            disabled={!canRequest || loading}
          />
        </Box>
      )}

      <Box mt="s-16">
        <RadioSelectInput
          label="Reviewer"
          value={employee}
          selector={selectorKeys.all_employees}
          onChange={handleRequesteeChange}
          disabled={!canRequest || loading}
        />
      </Box>
      {canSeeRelationship && (
        <Box mt="s-16">
          <RadioSelectInput
            label="Scorecard relationship"
            value={relationship}
            selector={selectorKeys.request_probation_scorecard_relations}
            onChange={setRelationship}
            disabled={!canRequest || loading}
          />
        </Box>
      )}

      <Button
        variant="secondary"
        size="sm"
        mt="s-16"
        aria-disabled={!employee || selfReviewDisabled}
        pending={loading}
        {...tooltip.getAnchorProps()}
        onClick={() => onSendRequest(selfReviewDisabled)}
      >
        Send request
      </Button>
      {selfReviewDisabled && (
        <Tooltip {...tooltip.getTargetProps()}>Self review is disabled</Tooltip>
      )}
      {(category !== ReviewCategory.Probation || !checkpoints) && (
        <Text mt="s-16" fontWeight={500} color={Color.GREY_TONE_50}>
          Requests sent
        </Text>
      )}

      <Box mt="s-16">{renderContent()}</Box>
    </Container>
  )
}

export default RequestFeedback
