import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import React from 'react'

export const OrgUnitSelector = () => {
  const { values } = useLapeContext<GoalsInterface>()

  switch (values.content_type?.model) {
    case 'department':
      return (
        <LapeRadioSelectInput
          selector={selectorKeys.department}
          value={values.object_id ? { id: values.object_id } : undefined}
          onChange={option => {
            values.object_id = option?.id!
          }}
          name="content_object"
          label="Organisational unit"
          required
        />
      )
    case 'teams':
      return (
        <LapeRadioSelectInput
          selector={selectorKeys.team}
          value={values.object_id ? { id: values.object_id } : undefined}
          onChange={option => {
            values.object_id = option?.id!
          }}
          name="content_object"
          label="Organisational unit"
          required
        />
      )
    default:
      return null
  }
}
