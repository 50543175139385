import { useLapeContext } from '@src/features/Form/LapeForm'
import { GroupAccessRequestInterface } from '@src/interfaces/accessManagement'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useFetchUser } from '@src/pages/Forms/AssignUserToPermission/hooks'
import { useGetAccessManagementSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import React, { useEffect } from 'react'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import { InputGroup } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { connect } from 'lape'
import addMonths from 'date-fns/addMonths'

const General = () => {
  const { values } = useLapeContext<GroupAccessRequestInterface>()
  const currentUser = useSelector(selectUser)
  const user = useFetchUser(String(currentUser.user_id))
  const { data: settings } = useGetAccessManagementSettings()

  const backUrl = ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS

  useEffect(() => {
    if (!values.user && user) {
      values.user = user
    }
  }, [values.user, user])

  useEffect(() => {
    if (!values?.start_date_time) {
      values.start_date_time = new Date().toISOString()
    }
    if (!values.end_date_time) {
      values.end_date_time = addMonths(new Date(), 3).toISOString()
    }
  }, [])

  return (
    <>
      <PageHeader title="Request access" backUrl={backUrl} />
      <PageBody>
        <AutoStepper>
          <InputGroup>
            <LapeRadioSelectInput
              name="user"
              label="User"
              selector={selectorKeys.active_users}
            />
            <LapeRadioSelectInput
              name="group"
              label="Group"
              selector={selectorKeys.groups}
            />

            {settings?.enable_limited_time_access ? (
              <InputGroup variant="horizontal">
                <LapeDatePickerInput
                  disabledDays={{ before: new Date() }}
                  defaultValue={new Date()}
                  required
                  name="start_date_time"
                  label="Valid from"
                />
                <LapeDatePickerInput
                  disabledDays={
                    values.start_date_time
                      ? { before: new Date(values.start_date_time) }
                      : undefined
                  }
                  defaultValue={addMonths(new Date(), 3)}
                  name="end_date_time"
                  label="Valid to"
                />
              </InputGroup>
            ) : null}
            <LapeNewTextArea name="motivation" label="Motivation" rows={2} required />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup<GroupAccessRequestInterface>
          onAfterSubmit={res => {
            navigateReplace(
              pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.USERS, { id: res.group.id }),
            )
          }}
          useValidator
          successText="Access request created"
        />
      </PageActions>
    </>
  )
}

export default connect(General)
