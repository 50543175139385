import React, { PropsWithChildren } from 'react'
import { Box, Flex, Text, Token, VStack, Widget } from '@revolut/ui-kit'
import {
  ReviewDataAdditionalScorecardsInterface,
  ReviewDataAdditionalScorecardsQuestionInterface,
  ReviewerRelation,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { Question } from '@src/pages/Forms/EmployeePerformance/AdditionalScorecards/Question'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { relationToString } from '@src/features/Scorecard/constants'

type AdditionalScorecardsProps = {
  data: ReviewDataAdditionalScorecardsInterface
  scorecardIdx: number
  viewMode?: boolean
  relation?: ReviewerRelation
  noWidget?: boolean
}

type WrapperProps = PropsWithChildren<{
  noWidget: boolean
}>

const Wrapper = ({ noWidget = false, children }: WrapperProps) => {
  if (noWidget) {
    return (
      <Box
        borderRadius={Token.radius.r16}
        border={`1px solid ${Token.color.greyTone10}`}
        width="100%"
        display="block"
        p="s-16"
      >
        {children}
      </Box>
    )
  }

  return (
    <Widget width="100%" display="block" p="s-16">
      {children}
    </Widget>
  )
}

export const AdditionalScorecards = ({
  data,
  scorecardIdx,
  viewMode = false,
  relation,
  noWidget = false,
}: AdditionalScorecardsProps) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const getQuestionAndName = (
    questionIdx: number,
  ): {
    question?: ReviewDataAdditionalScorecardsQuestionInterface | null
    name: string
  } => {
    let question = null
    let name = ''
    if (viewMode && 'summary' in values) {
      question = values.summary?.cycle_sections?.[scorecardIdx]?.questions[questionIdx]
      name = `summary.cycle_sections.${scorecardIdx}.questions.${questionIdx}.answers.${relation}`
    } else if ('review_data' in values) {
      question = values.review_data.cycle_sections?.[scorecardIdx]?.questions[questionIdx]
      name = `review_data.cycle_sections.${scorecardIdx}.questions.${questionIdx}.value`
    }
    return { question, name }
  }

  return (
    <Wrapper noWidget={noWidget}>
      <Box>
        <Flex justifyContent="space-between" alignItems="start" mb="s-16">
          <Text fontWeight={500} fontSize="h5" use="div">
            {data.title}
            {viewMode && relation && (
              <Text color="grey-tone-50"> ({relationToString(relation, true)})</Text>
            )}
          </Text>
        </Flex>
        {data?.description && (
          <Box my="s-8">
            <Text variant="secondary">{data?.description}</Text>
          </Box>
        )}
      </Box>
      <VStack space="s-24">
        {data?.questions?.map((_, questionIdx) => {
          const { question, name } = getQuestionAndName(questionIdx)
          return (
            <Question
              key={questionIdx}
              questionIdx={questionIdx}
              question={question}
              questionName={name}
              viewMode={viewMode}
              relation={relation}
            />
          )
        })}
      </VStack>
    </Wrapper>
  )
}
