import React from 'react'
import { Box, Text, Token } from '@revolut/ui-kit'
import PredefinedLabel from '@src/pages/Careers/CareersApplication/PredefinedSectionLabel'

type Props = {
  title: string
  subtitle?: string
  text?: string
  locked?: boolean
}

const SectionHead = ({ title, subtitle, text, locked }: Props) => {
  return (
    <Box mb="s-16">
      <Text variant="h4" display="block">
        {title} {locked && <PredefinedLabel />}
      </Text>
      {subtitle && (
        <Text variant="h6" display="block" pt="s-4">
          {subtitle}
        </Text>
      )}
      {text && (
        <Text variant="secondary" color={Token.color.greyTone50} use="div" pt="s-4">
          {text}
        </Text>
      )}
    </Box>
  )
}

export default SectionHead
