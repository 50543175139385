import styled from 'styled-components'
import { IconProps, ChevronDown } from '@revolut/icons'

type ShevronToggleProps = IconProps & {
  isOpen: boolean
}

const ShevronToggle = styled(ChevronDown)<ShevronToggleProps>`
  transform: rotate(${props => (props.isOpen ? '0' : '-90deg')});
  transition: transform 0.6s;
  &:hover,
  &:active {
    cursor: pointer;
  }
`

export default ShevronToggle
