import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, Flex, Header, Image, Popup, Text, Token, Widget } from '@revolut/ui-kit'
import { ChevronDown, ChevronUp, ExclamationTriangle } from '@revolut/icons'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import styled from 'styled-components'

import { useTable, useTableReturnType } from '@components/Table/hooks'
import { tableRequests } from '@src/interfaces'
import {
  CellInsertParams,
  ColumnCellInterface,
  FilterByInterface,
  SortByInterface,
  Stats,
} from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableProps } from '@components/Table/Table'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { successNotification } from '@src/actions/NotificationActions'
import {
  ownershipActionColumn,
  ownershipTransferToColumn,
} from '@src/constants/columns/ownership'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { TableNames } from '@src/constants/table'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { TableActionButton } from '@components/Button/TableActionButton'

export type TransferSelections = Record<string, EmployeeOptionInterface>

type PendingTransfers = Record<string, boolean>

const OwnershipTransferContext = React.createContext<{
  selections: TransferSelections
  setSelections: React.Dispatch<React.SetStateAction<TransferSelections>>
  transferAllPending: boolean
  setTransferAllPending: React.Dispatch<React.SetStateAction<boolean>>
  pendingTransfers: PendingTransfers
  setPendingTransfers: React.Dispatch<React.SetStateAction<PendingTransfers>>
  table: useTableReturnType<any, any>
  transferApi: (selections: TransferSelections) => Promise<any>
} | null>(null)

interface OwnershipTransferProviderProps<T, S> {
  getApi: tableRequests<T, S>
  filterByInitial?: FilterByInterface[]
  sortByInitial?: SortByInterface[]
  defaultOwner: EmployeeOptionInterface
  transferApi: (selections: TransferSelections) => Promise<any>
  children: React.ReactNode
}

export const OwnershipTransferProvider = <T extends { id: number }, S = Stats>({
  children,
  getApi,
  filterByInitial,
  sortByInitial,
  defaultOwner,
  transferApi,
}: OwnershipTransferProviderProps<T, S>) => {
  const [selections, setSelections] = useState<TransferSelections>({})
  const [transferAllPending, setTransferAllPending] = useState(false)
  const [pendingTransfers, setPendingTransfers] = useState<PendingTransfers>({})

  const table = useTable(getApi, filterByInitial, sortByInitial)

  useEffect(() => {
    setSelections(prevSelections => {
      const newSelections = table.data
        .filter(row => !prevSelections[row.id])
        .reduce((acc, row) => ({ ...acc, [row.id]: defaultOwner }), {})

      const filteredSelections = pickBy(prevSelections, (_, id) =>
        table.data.map(row => String(row.id)).includes(id),
      )

      return {
        ...filteredSelections,
        ...newSelections,
      }
    })
  }, [table.data])

  const contextValue = useMemo(
    () => ({
      selections,
      setSelections,
      transferAllPending,
      setTransferAllPending,
      pendingTransfers,
      setPendingTransfers,
      table,
      transferApi,
    }),
    [
      selections,
      setSelections,
      transferAllPending,
      setTransferAllPending,
      pendingTransfers,
      setPendingTransfers,
      table,
      transferApi,
    ],
  )

  return (
    <OwnershipTransferContext.Provider value={contextValue}>
      {children}
    </OwnershipTransferContext.Provider>
  )
}

const useOwnershipTransfer = () => {
  const context = useContext(OwnershipTransferContext)
  if (context === null) {
    throw new Error(
      `useOwnershipTransfer must be used within an OwnershipTransferProvider`,
    )
  }
  return context
}

type TransferButtonProps = {
  disabled: boolean
  pending: boolean
  title: string
  onTransfer: () => void
  isTableAction?: boolean
}

export const TransferWarningButton = ({
  disabled,
  pending,
  title,
  onTransfer,
  isTableAction,
}: TransferButtonProps) => {
  const [transferWarning, setTransferWarning] = useState(false)
  return (
    <>
      {isTableAction ? (
        <TableActionButton pending={pending} disabled={disabled}>
          {title}
        </TableActionButton>
      ) : (
        <Button
          onClick={() => {
            setTransferWarning(true)
          }}
          mr="s-16"
          size="sm"
          variant="secondary"
          pending={pending}
          disabled={disabled}
        >
          {title}
        </Button>
      )}
      <Popup
        open={transferWarning}
        preventUserClose
        variant="bottom-sheet"
        onClose={() => setTransferWarning(false)}
      >
        <Header variant="compact">
          <Header.Title>
            Are you sure you want to transfer ownership of all items to their new owners?
          </Header.Title>
          <Header.Description>
            If there is no new owner selected for the category, edit it first and then
            perform bulk transfer. Items missing new owners won’t be transferred.
          </Header.Description>
        </Header>
        <Popup.Actions horizontal>
          <Button
            onClick={() => setTransferWarning(false)}
            variant="secondary"
            disabled={pending}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setTransferWarning(false)
              onTransfer()
            }}
            pending={pending}
          >
            {title}
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export const TrasferAllButton = () => {
  const {
    selections,
    transferAllPending,
    setTransferAllPending,
    pendingTransfers,
    table,
    transferApi,
  } = useOwnershipTransfer()

  const onTransferAllClick = () => {
    setTransferAllPending(true)

    transferApi(selections)
      .then(() => {
        successNotification('Ownership transferred successfully')
        table.refresh()
      })
      .finally(() => setTransferAllPending(false))
  }

  return (
    <TransferWarningButton
      disabled={table.data.length === 0 || Object.keys(pendingTransfers).length > 0}
      pending={transferAllPending}
      title="Transfer all"
      onTransfer={onTransferAllClick}
    />
  )
}

// A utility because React.memo doesn't play well with generics
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
const typedMemo: <T>(c: T) => T = React.memo

interface TransferSelectProps<T> {
  id: T
  value: EmployeeOptionInterface
  options: RadioSelectOption<EmployeeOptionInterface>[]
  disabled: boolean
  onSelect: (option: EmployeeOptionInterface, id: T) => void
}

export const TransferSelect = typedMemo(
  <T,>({ id, disabled, onSelect, options, value }: TransferSelectProps<T>) => {
    return (
      <RadioSelectInput
        value={value}
        options={options}
        renderInput={(open, setOpen, ref) => (
          <button type="button" ref={ref} onClick={() => setOpen(!open)}>
            <Flex
              alignItems="center"
              color={disabled ? Token.color.greyTone50 : Token.color.foreground}
            >
              <Text mr="s-8">{value.name}</Text>
              {open ? <ChevronUp size={12} /> : <ChevronDown size={12} />}
            </Flex>
          </button>
        )}
        onChange={option => {
          if (option) {
            onSelect(option, id)
          }
        }}
        disabled={disabled}
      />
    )
  },
)

interface TransferTableProps<T, S> extends Partial<TableProps<T, S>> {
  cells: ColumnCellInterface<T>[]
  ownerColTitle?: string
}

export const TransferTable = <T extends { id: number }, S = Stats>({
  cells,
  ownerColTitle = 'New owner',
  ...tableProps
}: TransferTableProps<T, S>) => {
  const {
    selections,
    setSelections,
    transferAllPending,
    pendingTransfers,
    setPendingTransfers,
    table,
    transferApi,
  } = useOwnershipTransfer()
  const { options, asyncState } = useFetchOptions<EmployeeOptionInterface>(
    selectorKeys.employee,
  )

  const onSelect = React.useCallback((option: EmployeeOptionInterface, id: number) => {
    setSelections(prevState => ({ ...prevState, [id]: option }))
  }, [])

  const onTransfer = (option: EmployeeOptionInterface, id: number) => {
    setPendingTransfers(prevPending => ({ ...prevPending, [id]: true }))

    transferApi({ [id]: option })
      .then(() => {
        table.refresh()
        successNotification('Transfer successful')

        setSelections(prevSelections => omit(prevSelections, id))
        setPendingTransfers(prevPending => omit(prevPending, id))
      })
      .catch(() => {
        setPendingTransfers(prevPending => ({ ...prevPending, [id]: false }))
      })
  }

  const isDisabled = asyncState !== 'ready' || transferAllPending

  return (
    <AdjustableTable
      name={TableNames.EmployeeOwnershipTransfer}
      useWindowScroll
      {...tableProps}
      {...table}
      row={{
        cells: [
          ...cells,
          {
            ...ownershipTransferToColumn,
            title: ownerColTitle,
            width: 300,
            insert: ({ data }: CellInsertParams<T>) =>
              selections[data.id] ? (
                <TransferSelect
                  id={data.id}
                  value={selections[data.id]}
                  disabled={isDisabled}
                  options={options}
                  onSelect={onSelect}
                />
              ) : null,
          },
          {
            ...ownershipActionColumn,
            width: 100,
            insert: ({ data }: CellInsertParams<T>) => (
              <TableActionButton
                onClick={() => onTransfer(selections[data.id], data.id)}
                pending={pendingTransfers[data.id]}
                disabled={isDisabled}
              >
                Transfer
              </TableActionButton>
            ),
          },
        ],
      }}
    />
  )
}

const SmallBannerWidget = styled(Widget)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-left: 18px;
`

interface SmallBannerProps {
  title: string
  hidden?: boolean
}

export const SmallBanner = ({ title, hidden }: SmallBannerProps) =>
  hidden ? null : (
    <SmallBannerWidget backgroundColor="terracotta-5">
      <ExclamationTriangle color="terracotta" />
      <Text use="p" p="s-12">
        {title}
      </Text>
    </SmallBannerWidget>
  )

const BannerWidget = styled(Widget)`
  display: flex;
  align-items: center;
`

interface BannerProps {
  title: React.ReactNode
  description: React.ReactNode
  noAlert?: boolean
  hidden?: boolean
}

export const Banner = ({ title, description, noAlert, hidden }: BannerProps) =>
  hidden ? null : (
    <BannerWidget>
      {noAlert ? null : (
        <Image
          ml="s-20"
          width="s-56"
          height="s-56"
          src="https://assets.revolut.com/media/business/illustrations-repository/alert.png"
        />
      )}
      <Flex flexDirection="column" m="s-20">
        <Text variant="primary">{title}</Text>
        <Text variant="caption" color="grey-tone-50">
          {description}
        </Text>
      </Flex>
    </BannerWidget>
  )
