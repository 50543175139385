import React from 'react'
import { EntityTypes } from '@src/constants/api'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { TabReport } from './components/TabReport'

// hardcode until we have interface to configure tab
const reportId = 4

interface BugsReportProps {
  data: TeamInterface | DepartmentInterface
  entity: EntityTypes.department | EntityTypes.team
}

export const BugsReport = ({ entity, data }: BugsReportProps) => {
  return <TabReport data={data} entity={entity} reportId={reportId} />
}
