import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  InputGroup,
  Checkbox,
  Radio,
  Text,
  Spacer,
  HStack,
} from '@revolut/ui-kit'
import { LikeDislike, Minus, SocialDislike, SocialLike } from '@revolut/icons'
import { Input } from '@revolut/ui-kit'
import { InterviewScorecardSectionQuestionType } from '@src/interfaces/interviewScorecardTemplates'
import { Grid } from '@components/CommonSC/Grid'
import { useLapeContext } from '@src/features/Form/LapeForm'
import get from 'lodash/get'
import BottomInputMessage from '@components/BottomInputMessage/BottomInputMessage'
import set from 'lodash/set'
import OptionsPagination, {
  PAGE_SIZE,
} from '@src/pages/Forms/InterviewScorecardTemplate/Sections/Option/OptionPagination'
import BulkOption from '@src/pages/Forms/InterviewScorecardTemplate/Sections/Option/BulkOption'

export interface MultiInputProps<T extends number | string> {
  value?: InterviewScorecardSectionQuestionType<T>[]
  valueDisabled?: boolean
  deleteDisabled?: boolean
  deleteLastDisabled?: boolean
  previewMode?: boolean
  onChange?: (val: InterviewScorecardSectionQuestionType<T>[]) => void
  type: 'radio' | 'checkbox' | 'thumbs'
  hidePoints?: boolean
  optionsKey?: string
  allowBulk?: boolean
}

const OptionSelect = <T extends number | string>({
  value,
  valueDisabled,
  deleteDisabled,
  deleteLastDisabled,
  previewMode,
  onChange,
  type,
  hidePoints,
  optionsKey,
  allowBulk = false,
}: MultiInputProps<T>) => {
  const { errors } = useLapeContext()
  const [activeInputIdx, setActiveInputIdx] = useState<number | null>(null)

  const [page, setPage] = useState(0)
  const [bulkMode, setBulkMode] = useState(false)

  const handlePageValue = (length: number) => {
    if (allowBulk) {
      setPage(Math.floor((length - 1) / PAGE_SIZE))
    }
  }

  const handleBulkConfirm = (values: InterviewScorecardSectionQuestionType<T>[]) => {
    onChange?.(values)
    handlePageValue(values.length)
    setBulkMode(false)
  }

  const handleBulkCancel = () => {
    setBulkMode(false)
  }

  const getValues = () => {
    const arr = value ?? []
    if (allowBulk && arr.length) {
      const start = page * PAGE_SIZE
      const end = start + PAGE_SIZE
      return arr.slice(start, end)
    }
    return arr
  }

  useEffect(() => {
    if (!value?.length) {
      handleAdd()
    }
  }, [type])

  const getValueIndex = (idx: number) => {
    if (allowBulk) {
      return page * PAGE_SIZE + idx
    }
    return idx
  }

  const getActiveIndex = (length: number) => {
    let idx = length - 1
    if (allowBulk) {
      return length > PAGE_SIZE ? idx % PAGE_SIZE : idx
    }
    return idx
  }

  const handleAdd = () => {
    const newValues: InterviewScorecardSectionQuestionType<T>[] = [
      ...(value || []),
      { text: '' },
    ]
    handlePageValue(newValues.length)
    setActiveInputIdx(getActiveIndex(newValues.length))
    onChange?.(newValues)
  }

  const handleDelete = (idx: number) => {
    const id = getValueIndex(idx)
    const newValues = value?.filter((_, i) => i !== id) || []
    handlePageValue(newValues.length)
    onChange?.(newValues)
  }

  const handleExistingValuesChange = (val: string, idx: number, isPoint?: boolean) => {
    const newValues = [...(value || [])]
    const id = getValueIndex(idx)
    if (isPoint) {
      if (type === 'thumbs' && val[0] === '-') {
        newValues[id].points = +val.substring(1)
      } else {
        newValues[id].points = val === '' ? undefined : +val
      }
    } else {
      newValues[id].text = val
    }
    // newValues[id].id = undefined
    onChange?.(newValues)
  }

  const onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.code === 'Enter') {
      handleAdd()
    }
  }

  const iconRouter = () => {
    switch (type) {
      case 'checkbox':
        return <Checkbox tabIndex={-1} checked={false} />
      case 'radio':
        return <Radio tabIndex={-1} checked={false} />
      case 'thumbs':
        return <LikeDislike color="grey-tone-20" />
      default:
        return null
    }
  }

  if (previewMode) {
    return (
      <Grid gap={18}>
        {value?.map((val, id) => {
          const error = get(errors, `${optionsKey}.${id}.text`)

          return (
            <React.Fragment key={id}>
              {type === 'thumbs' ? (
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  data-name={`${optionsKey}.${id}.text`}
                >
                  <Text use="div" fontSize="primary">
                    {val.text}
                  </Text>
                  <Grid flow="column" gap={8}>
                    <Flex
                      p="s-6"
                      borderStyle="solid"
                      borderColor="grey-tone-20"
                      borderWidth={1}
                      borderRadius={4}
                    >
                      <SocialLike size={14} color="grey-tone-50" />
                    </Flex>
                    <Flex
                      p="s-6"
                      borderStyle="solid"
                      borderColor="grey-tone-20"
                      borderWidth={1}
                      borderRadius={4}
                    >
                      <LikeDislike size={14} color="grey-tone-50" />
                    </Flex>
                    <Flex
                      p="s-6"
                      borderStyle="solid"
                      borderColor="grey-tone-20"
                      borderWidth={1}
                      borderRadius={4}
                    >
                      <SocialDislike size={14} color="grey-tone-50" />
                    </Flex>
                  </Grid>
                </Flex>
              ) : (
                <Flex alignItems="center" data-name={`${optionsKey}.${id}.text`}>
                  <Box mr="18px">{iconRouter()}</Box>
                  <Flex flex="1" justifyContent="space-between">
                    <Text use="div" fontSize="primary">
                      {val.text}
                    </Text>
                    {val.points && (
                      <Text
                        color={val.points > 0 ? 'grey-tone-50' : 'red'}
                        use="div"
                        fontSize="primary"
                      >
                        {val.points} pts
                      </Text>
                    )}
                  </Flex>
                </Flex>
              )}
              <BottomInputMessage hasError message={error} />
            </React.Fragment>
          )
        })}
      </Grid>
    )
  }

  if (bulkMode) {
    return (
      <BulkOption
        values={value ?? []}
        onConfirm={handleBulkConfirm}
        onCancel={handleBulkCancel}
      />
    )
  }

  return (
    <InputGroup>
      {getValues().map((val, id) => {
        const error = get(errors, `${optionsKey}.${id}.text`)
        return (
          <Box key={id}>
            <InputGroup
              variant="horizontal"
              style={{
                alignItems: 'flex-start',
              }}
            >
              <Input
                value={val.text}
                style={{ width: '100%' }}
                disabled={valueDisabled}
                label={`Option ${id + 1}`}
                onChange={e => {
                  handleExistingValuesChange(e.currentTarget.value, id)
                  set(errors, `${optionsKey}.${id}.text`, undefined)
                }}
                data-testid={`${optionsKey}.${id}`}
                onKeyDown={onInputKeyDown}
                autoFocus={id === activeInputIdx}
                data-name={`${optionsKey}.${id}.text`}
                renderPrefix={iconRouter}
                errorMessage={error}
                aria-invalid={!!error}
              />
              {!hidePoints && (
                <Input
                  value={val.points}
                  width="15%"
                  placeholder="Points"
                  type="number"
                  onWheel={e => e.currentTarget.blur()} // Prevent number change on scroll wheel
                  onChange={e => {
                    handleExistingValuesChange(e.currentTarget.value, id, true)
                  }}
                  data-testid="option-points-input"
                  onKeyDown={onInputKeyDown}
                />
              )}
              {!deleteDisabled && (
                <Flex alignItems="center" height={56}>
                  <Button
                    ml="s-8"
                    radius="button-sm"
                    onClick={() => handleDelete(id)}
                    data-testid="option-delete-button"
                    variant="secondary"
                    height={36}
                    width={36}
                    useIcon={Minus}
                    disabled={deleteLastDisabled && value?.length === 1}
                  />
                </Flex>
              )}
            </InputGroup>
          </Box>
        )
      })}
      <Flex justifyContent="space-between">
        {!valueDisabled && (
          <Flex>
            <HStack space="s-8">
              <Button
                data-testid="option-new-button"
                onClick={() => handleAdd()}
                variant="secondary"
                size="sm"
              >
                Add
              </Button>
              <Spacer />
              {allowBulk && (
                <Button
                  data-testid="option-new-button"
                  onClick={() => setBulkMode(true)}
                  variant="secondary"
                  size="sm"
                >
                  Bulk
                </Button>
              )}
            </HStack>
          </Flex>
        )}
        {!!value?.length && allowBulk && (
          <OptionsPagination
            page={page}
            total={value?.length}
            onPageChange={newPage => setPage(newPage)}
          />
        )}
      </Flex>
    </InputGroup>
  )
}

export default OptionSelect
