import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Avatar,
  Bar,
  Button,
  Cell,
  Checkbox,
  FilterButton,
  FilterButtonSkeleton,
  Flex,
  Group,
  Item,
  ItemSkeleton,
  Search,
  Side,
  Text,
  useIntersectViewport,
  VStack,
} from '@revolut/ui-kit'
import FilterButtonCheckboxSelect from '@components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import pluralize from 'pluralize'
import { EngagementAudienceInterface } from '@src/interfaces/engagement'
import { People } from '@revolut/icons'
import debounce from 'lodash/debounce'
import { filterSortPageIntoQuery } from '@src/utils/table'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import {
  getEngagementAudiences,
  useEngagementAudiencesSelector,
} from '@src/api/engagement'
import { OptionInterface } from '@src/interfaces/selectors'

const MAX_FILTERS_SHOWN = 3
const DEFAULT_PAGE_SIZE = 25

type AudienceType = IdAndName<React.Key, React.ReactNode>

const toggleArrayItemByKey = <T,>(items: T[], toggleItem: T, key: keyof T) => {
  if (items?.some(item => item[key] === toggleItem[key])) {
    return items.filter(item => toggleItem[key] !== item[key])
  }
  return [...items, toggleItem]
}

const mapFiltersToParams = (search: string, filters: AudienceType[]) =>
  filterSortPageIntoQuery(undefined, [
    {
      columnName: 'search',
      filters: [{ id: search, name: search }],
    },
    {
      columnName: 'audience_type',
      filters: filters.map(({ id }) => ({ id, name: String(id) })),
    },
    {
      columnName: 'page_size',
      filters: [{ id: DEFAULT_PAGE_SIZE, name: String(DEFAULT_PAGE_SIZE) }],
    },
  ])

type Props = {
  audiences: EngagementAudienceInterface[]
  onAddAudiences: (audiences: EngagementAudienceInterface[]) => void
  onClose: () => void
}
export const SidebarContent = ({
  audiences: selectedAudiences,
  onAddAudiences,
  onClose,
}: Props) => {
  const [audiencesOptions, setAudiencesOptions] = useState<EngagementAudienceInterface[]>(
    [],
  )

  const [selectedAudiencesOptions, setSelectedAudiencesOptions] = useState<
    EngagementAudienceInterface[]
  >([])

  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isReloadingFilters, setIsReloadingFilters] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [hasMoreData, setHasMoreData] = useState<boolean>(false)
  const ref = useRef(null)

  useIntersectViewport(ref, isIntersecting => {
    if (isReloadingFilters) {
      return
    }
    if (hasMoreData && isIntersecting) {
      setPage(page + 1)
    }
  })

  const [searchValue, setSearchValue] = useState('')
  const [textFilter, setTextFilter] = useState('')
  const [audienceTypesFilters, setAudienceTypesFilters] = useState<AudienceType[]>([])
  const [moreAudienceTypesFilters, setMoreAudienceTypesFilters] = useState<
    AudienceType[]
  >([])

  const setTextFilterDebounced = useCallback(debounce(setTextFilter, 500), [])

  const filterParams = useMemo(() => {
    return mapFiltersToParams(textFilter, [
      ...audienceTypesFilters,
      ...moreAudienceTypesFilters,
    ])
  }, [textFilter, audienceTypesFilters, moreAudienceTypesFilters])

  const { data: audiences } = useEngagementAudiencesSelector(filterParams)

  const { options, asyncState } = useFetchOptions<AudienceType>(
    selectorKeys.audience_types,
  )
  const audienceTypes = options.map(({ value, label }) => ({
    id: value.id,
    name: label,
  }))
  const moreFiltersButtons = audienceTypes.slice(MAX_FILTERS_SHOWN, audienceTypes.length)

  const filteredAudienceOptions = useMemo(
    () =>
      audiencesOptions.filter(
        audience => !selectedAudiences?.some(selected => selected.id === audience.id),
      ),
    [audiencesOptions],
  )

  const loadAudiences = (newPage = 1) => {
    setPage(newPage)

    return getEngagementAudiences({ ...filterParams, page: newPage }).then(({ data }) => {
      const { results, pages } = data

      setHasMoreData(!!pages.next)

      return results
    })
  }

  useEffect(() => {
    if (isReloadingFilters || page === 1) {
      return
    }
    setIsLoadingMore(true)

    loadAudiences(page)
      .then(newAudiences => {
        setAudiencesOptions([...audiencesOptions, ...newAudiences])
      })
      .finally(() => {
        setIsLoadingMore(false)
      })
  }, [page])

  useEffect(() => {
    if (isLoadingMore) {
      return
    }
    setIsReloadingFilters(true)

    loadAudiences()
      .then(filteredAudiences => {
        setAudiencesOptions(filteredAudiences)
      })
      .finally(() => {
        setIsReloadingFilters(false)
      })
  }, [filterParams])

  return (
    <Flex style={{ flex: '1 0' }} flexDirection="column" justifyContent="space-between">
      <VStack space="s-16">
        <Search
          value={searchValue}
          placeholder="Search"
          onChange={newValue => {
            setSearchValue(newValue)
            setTextFilterDebounced(newValue)
            setSelectedAudiencesOptions([])
          }}
        />
        {asyncState === 'pending' ? (
          <Bar>
            <FilterButtonSkeleton />
            <FilterButtonSkeleton />
            <FilterButtonSkeleton />
          </Bar>
        ) : (
          <Bar>
            {audienceTypes.slice(0, MAX_FILTERS_SHOWN).map(filter => (
              <FilterButton
                key={filter.id}
                onClick={() => {
                  setAudienceTypesFilters(
                    toggleArrayItemByKey<AudienceType>(
                      audienceTypesFilters,
                      filter,
                      'id',
                    ),
                  )
                  setSelectedAudiencesOptions([])
                }}
                active={audienceTypesFilters.some(
                  selectedFilter => selectedFilter.id === filter.id,
                )}
              >
                {filter.name}
              </FilterButton>
            ))}
            {audienceTypes.length > MAX_FILTERS_SHOWN && (
              <FilterButtonCheckboxSelect
                options={moreFiltersButtons as OptionInterface[]}
                label="More filters"
                value={
                  moreFiltersButtons.filter(filterButton =>
                    moreAudienceTypesFilters.some(({ id }) => id === filterButton.id),
                  ) as OptionInterface[]
                }
                onChange={value => {
                  const formattedValue = value
                    ? value.map(({ id, name }) => ({ id: String(id), name }))
                    : []
                  setMoreAudienceTypesFilters(formattedValue)
                  setSelectedAudiencesOptions([])
                }}
              />
            )}
          </Bar>
        )}
        {
          <Group>
            <Cell>
              <Checkbox
                onChange={value => {
                  if (value.target.checked) {
                    setSelectedAudiencesOptions(audiences?.options || [])
                  } else {
                    setSelectedAudiencesOptions([])
                  }
                }}
              >
                <Text variant="secondary">Select all</Text>
              </Checkbox>
            </Cell>
            {isReloadingFilters ? (
              <>
                <ItemSkeleton />
                <ItemSkeleton />
                <ItemSkeleton />
              </>
            ) : (
              filteredAudienceOptions.map(audienceOption => {
                const title = audienceOption.name
                const description = `${pluralize(
                  'employee',
                  audienceOption.headcount,
                  true,
                )}`

                return (
                  <Item key={audienceOption.id} use="label">
                    <Item.Prefix>
                      <Checkbox
                        aria-labelledby={title}
                        aria-describedby={description}
                        checked={selectedAudiencesOptions.some(
                          ({ id }) => id === audienceOption.id,
                        )}
                        onClick={() =>
                          setSelectedAudiencesOptions(
                            toggleArrayItemByKey<EngagementAudienceInterface>(
                              selectedAudiencesOptions,
                              audienceOption,
                              'id',
                            ),
                          )
                        }
                      />
                    </Item.Prefix>
                    <Item.Avatar>
                      <Avatar useIcon={People} />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title id="item-title">{title}</Item.Title>
                      <Item.Description id="item-description">
                        {description}
                      </Item.Description>
                    </Item.Content>
                  </Item>
                )
              })
            )}
            <VStack ref={ref} space="s-12">
              {isLoadingMore && hasMoreData && (
                <>
                  <ItemSkeleton />
                  <ItemSkeleton />
                  <ItemSkeleton />
                </>
              )}
            </VStack>
          </Group>
        }
      </VStack>
      {!!selectedAudiencesOptions.length && (
        <Side.Actions>
          <Button
            elevated
            onClick={() => {
              onAddAudiences(selectedAudiencesOptions)
              onClose()
            }}
          >
            Add selected
          </Button>
        </Side.Actions>
      )}
    </Flex>
  )
}
