import React, { useState } from 'react'
import {
  Button,
  Image,
  Popup,
  StatusPopup,
  Text,
  VStack,
  Portal,
  MoreBar,
  ActionButtonVariant,
  Header,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { AxiosPromise } from 'axios'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  EngagementDriverInterface,
  EngagementQuestionInterface,
  EngagementSurveyInterface,
} from '@src/interfaces/engagement'
import { IconComponentType } from '@revolut/icons'

type ActionButtonProps<T> = {
  text: string
  icon: IconComponentType
  action: (id: number) => AxiosPromise<T>
  successText: string
}

type PopupProps = {
  title: string
  subtitle?: string
}

type Props<T> = {
  isActive: boolean
  activeButton: ActionButtonProps<T>
  activeSubmit?: PopupProps
  activeWarn?: PopupProps
  inactiveButton: ActionButtonProps<T>
  inactiveSubmit?: PopupProps
  inactiveWarn?: PopupProps
  showWarn?: boolean
  disabled?: boolean
  tooltip?: string
}
export const ActionToggleButton = <
  T extends
    | EngagementDriverInterface
    | EngagementQuestionInterface
    | EngagementSurveyInterface,
>({
  isActive,
  activeButton,
  activeSubmit,
  activeWarn,
  inactiveButton,
  inactiveSubmit,
  inactiveWarn,
  showWarn,
  disabled,
  tooltip,
}: Props<T>) => {
  const { initialValues, reset } = useLapeContext<T>()
  const tooltipProps = useTooltip()
  const [isActionPending, setIsActionPending] = useState<boolean>(false)
  const [submitWarningOpen, setSubmitWarningOpen] = useState<boolean>(false)
  const [submitPopupOpen, setSubmitPopupOpen] = useState<boolean>(false)
  const [successPopupOpen, setSuccessPopupOpen] = useState<boolean>(false)

  const actionHandler = async () => {
    const action = isActive ? activeButton.action : inactiveButton.action
    setIsActionPending(true)
    try {
      const { data } = await action(initialValues.id!)
      reset(data)
      setSuccessPopupOpen(true)
    } finally {
      setIsActionPending(false)
      setSubmitWarningOpen(false)
      setSubmitPopupOpen(false)
    }
  }

  const onActionClick = showWarn
    ? () => (disabled ? () => {} : setSubmitWarningOpen(true))
    : () => (disabled ? () => {} : setSubmitPopupOpen(true))

  const actionButtonProps = isActive
    ? {
        ...activeButton,
        variant: 'negative' as ActionButtonVariant,
      }
    : {
        ...inactiveButton,
        variant: 'primary' as ActionButtonVariant,
      }
  const warningPopupProps = isActive ? activeWarn : inactiveWarn
  const submitPopupProps = isActive ? activeSubmit : inactiveSubmit

  return (
    <>
      <MoreBar.Action
        use={disabled ? 'div' : undefined}
        useIcon={actionButtonProps.icon}
        variant={actionButtonProps.variant}
        pending={isActionPending}
        onClick={onActionClick}
        disabled={disabled}
        style={{ cursor: disabled ? 'default' : 'pointer' }}
        {...tooltipProps.getAnchorProps()}
      >
        {actionButtonProps.text}
        {tooltip ? (
          <Tooltip {...tooltipProps.getTargetProps()} maxWidth={200}>
            {tooltip}
          </Tooltip>
        ) : null}
      </MoreBar.Action>
      <Portal>
        {!!warningPopupProps && (
          <Popup
            open={submitWarningOpen}
            onClose={() => setSubmitWarningOpen(false)}
            variant="bottom-sheet"
          >
            <VStack space="s-24" align="center">
              <Image
                size={104}
                src="https://assets.revolut.com/assets/3d-images/3D018.png"
              />
              <Text variant="h5" textAlign="center">
                {warningPopupProps.title}
              </Text>
              <Text
                use="div"
                mb="s-16"
                variant="caption"
                color="grey-tone-50"
                textAlign="center"
              >
                {warningPopupProps.subtitle}
              </Text>
            </VStack>
            <Popup.Actions>
              <Button
                elevated
                pending={isActionPending}
                disabled={isActionPending}
                onClick={actionHandler}
              >
                Confirm
              </Button>
              <Button
                variant="secondary"
                onClick={e => {
                  e.stopPropagation()
                  setSubmitWarningOpen(false)
                }}
                disabled={isActionPending}
              >
                Go back
              </Button>
            </Popup.Actions>
          </Popup>
        )}
        {!!submitPopupProps && (
          <Popup
            open={submitPopupOpen}
            onClose={() => setSubmitPopupOpen(false)}
            variant="bottom-sheet"
          >
            <Header variant="bottom-sheet">
              <Header.Title>{submitPopupProps.title}</Header.Title>
            </Header>
            <Text use="p" variant="caption" color="grey-tone-50">
              {submitPopupProps.subtitle}
            </Text>
            <Popup.Actions horizontal>
              <Button
                variant="secondary"
                onClick={e => {
                  e.stopPropagation()
                  setSubmitPopupOpen(false)
                }}
                disabled={isActionPending}
              >
                Cancel
              </Button>
              <Button
                elevated
                pending={isActionPending}
                disabled={isActionPending}
                onClick={actionHandler}
              >
                Confirm
              </Button>
            </Popup.Actions>
          </Popup>
        )}
        <StatusPopup
          variant="success"
          open={successPopupOpen}
          onClose={() => setSuccessPopupOpen(false)}
          // @ts-ignore this prop exists, TS definition is lying
          labelButtonClose="Close success popup"
        >
          <StatusPopup.Title>
            {isActive ? inactiveButton.successText : activeButton.successText}
          </StatusPopup.Title>
        </StatusPopup>
      </Portal>
    </>
  )
}
