import { Box, Text, Token } from '@revolut/ui-kit'
import { LockClosed } from '@revolut/icons'
import React from 'react'

const PredefinedLabel = () => (
  <Text color={Token.color.greyTone50}>
    (predefined){' '}
    <Box display="inline-block">
      <LockClosed size={16} />
    </Box>
  </Text>
)

export default PredefinedLabel
