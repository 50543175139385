import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { ROUTES } from '@src/constants/routes'
import { saveLoginData, VerificationCodeForm } from './common'
import { SESSION_STORAGE } from '@src/constants/api'
import { goBack } from '@src/actions/RouterActions'
import { setAuthenticatedAction } from '@src/store/auth/actions'
import PublicPageLayout from '@components/CommonSC/PublicPageLayout'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

export const TwoFactorLogin = () => {
  const dispatch = useDispatch()

  const sessionStorageData = useMemo(() => {
    const twoFactorLoginData = sessionStorage.getItem(SESSION_STORAGE.TWO_FACTOR_LOGIN)

    if (!twoFactorLoginData) {
      return null
    }

    try {
      /** TODO: Need a proper parser for these sort of things */
      const parsed = JSON.parse(twoFactorLoginData)

      /**
       * Ideally the whole `parsed` object should be parsed and validated completely,
       * but if these props exist, it's pretty safe to assume the model is correct
       */
      if (
        !(
          'token' in parsed &&
          'employee' in parsed &&
          'email' in parsed &&
          'permissions' in parsed
        )
      ) {
        throw new Error()
      }

      return {
        token: parsed.token,
        employee: parsed.employee,
        email: parsed.email,
        permissions: parsed.permissions,
      }
    } catch (err) {
      console.error(`Invalid two factor login data`)
      return null
    }
  }, [])

  if (!sessionStorageData) {
    /** Cleanup in case there's something in the sessionStorage, but the parsing failed */
    sessionStorage.removeItem(SESSION_STORAGE.TWO_FACTOR_LOGIN)
    return <InternalRedirect to={ROUTES.MAIN} />
  }

  return (
    <PublicPageLayout>
      <VerificationCodeForm
        token={sessionStorageData.token}
        onSuccess={() => {
          sessionStorage.removeItem(SESSION_STORAGE.TWO_FACTOR_LOGIN)
          saveLoginData({ ...sessionStorageData, authenticated: 'true' })
          goBack(ROUTES.MAIN)
          dispatch(setAuthenticatedAction(true))
        }}
      />
    </PublicPageLayout>
  )
}
