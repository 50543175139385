import * as React from 'react'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import EmployeeSelector from '@components/Inputs/EmployeeSelector/EmployeeSelector'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { useFormValidator } from '@src/features/Form/FormValidator'

interface Props {
  name: string
  value?: string
  disabled?: boolean
  onChange?: (value?: EmployeeOptionInterface[]) => void
  required?: boolean
  label: string
}

const LapeEmployeeSelector = ({ name, label, ...props }: Props) => {
  const lapeProps = useLapeField(name)
  const { value, onChange, hidden, error, touched } = lapeProps
  const formValidator = useFormValidator()
  const commonInputProps = getCommonInputProps(
    { ...props, name, label },
    lapeProps,
    !!formValidator?.validated,
  )

  if (hidden) {
    return null
  }

  return (
    <EmployeeSelector
      onChange={onChange}
      error={touched && error}
      value={value || null}
      disabled={commonInputProps.disabled}
      label={label}
    />
  )
}

export default connect(LapeEmployeeSelector)
