import React, { useState } from 'react'

import {
  Avatar,
  Box,
  Button,
  Color,
  Group,
  HStack,
  InputGroup,
  Item,
  StatusWidget,
  Text,
  TextArea,
  VStack,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FaqInterface } from '@src/interfaces/faq'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { useFormattedFaqTopicOptions } from '@src/api/faq'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { Delete, Pencil, Plus, RobotoRegular } from '@revolut/icons'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import InputErrorMessage from '@components/InputErrorMessage/InputErrorMessage'
import LapeEditor from '@src/components/Inputs/LapeFields/LapeEditor'

export const HelpCenterQuestionForm = () => {
  const { values } = useLapeContext<FaqInterface>()

  const [selectedPhrase, setSelectedPhrase] = useState<string>()
  const [phraseInputValue, setPhraseInputValue] = useState<string>('')
  const [editPhraseDialogOpen, setEditPhraseDialogOpen] = useState(false)
  const [deletePhraseDialogOpen, setDeletePhraseDialogOpen] = useState(false)

  const topicsOptions = useFormattedFaqTopicOptions({
    filters: [{ columnName: 'status', filters: [{ id: 'active', name: 'active' }] }],
  })
  const isExistingPhrase =
    !selectedPhrase &&
    values.training_phrases?.some(phrase => phrase === phraseInputValue)

  const onAddNew = () => {
    setSelectedPhrase(undefined)
    setPhraseInputValue('')
    setEditPhraseDialogOpen(true)
  }

  return (
    <>
      <PageHeader
        backUrl={
          values.id
            ? pathToUrl(ROUTES.APPS.HELP_CENTER.QUESTION.PREVIEW, {
                id: String(values.id),
              })
            : pathToUrl(ROUTES.APPS.HELP_CENTER.FAQ_TABLE)
        }
        title={values.id ? 'Edit question' : 'Add question'}
      />
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="General" />
          <InputGroup>
            <LapeNewTextArea required rows={3} name="title" label="Question title" />
            <LapeRadioSelectInput name="topic" options={topicsOptions} label="Topic" />
            <LapeRadioSelectInput
              name="maintainer"
              selector={selectorKeys.employee}
              label="Maintainer"
            />
            <LapeNewMultiSelect
              name="countries"
              placeholder="Countries"
              selector={selectorKeys.countries}
            />
          </InputGroup>
          <NewStepperTitle title="Training phrases" />
          <Text color={Color.GREY_TONE_50}>
            Adding more phrases increases question to answer matching quality
          </Text>
          <Group mt="s-24">
            <VStack>
              {values.training_phrases?.length ? (
                values.training_phrases.map(phrase => (
                  <Item key={phrase}>
                    <Item.Avatar>
                      <Avatar useIcon={RobotoRegular} />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title>{phrase}</Item.Title>
                    </Item.Content>

                    <Item.Side>
                      <HStack space="s-16">
                        <Button
                          variant="secondary"
                          size="sm"
                          useIcon={Pencil}
                          aria-label={`Edit training phrase: ${phrase}`}
                          onClick={() => {
                            setSelectedPhrase(phrase)
                            setPhraseInputValue(phrase)
                            setEditPhraseDialogOpen(true)
                          }}
                        />
                        <Button
                          variant="negative"
                          size="sm"
                          useIcon={Delete}
                          aria-label={`Delete training phrase: ${phrase}`}
                          onClick={() => {
                            setSelectedPhrase(phrase)
                            setDeletePhraseDialogOpen(true)
                          }}
                        />
                      </HStack>
                    </Item.Side>
                  </Item>
                ))
              ) : (
                <StatusWidget>
                  <StatusWidget.Image
                    image={{
                      default: 'https://assets.revolut.com/assets/3d-images/3D086.png',
                      '2x': 'https://assets.revolut.com/assets/3d-images/3D086@2x.png',
                      '3x': 'https://assets.revolut.com/assets/3d-images/3D086@3x.png',
                    }}
                  />
                  <StatusWidget.Title>
                    No phrases found for this question
                  </StatusWidget.Title>
                  <StatusWidget.Action onClick={onAddNew}>Add new</StatusWidget.Action>
                </StatusWidget>
              )}
              {!!values.training_phrases?.length && (
                <Button
                  m="s-16"
                  size="sm"
                  variant="secondary"
                  useIcon={Plus}
                  onClick={onAddNew}
                >
                  Add new
                </Button>
              )}
            </VStack>
          </Group>
          <NewStepperTitle title="Content" />
          <LapeEditor
            required
            name="content"
            placeholder="Enter answer content here"
            allowImages={false}
          />
        </AutoStepper>
        <ConfirmationDialog
          open={editPhraseDialogOpen}
          onClose={() => setEditPhraseDialogOpen(false)}
          label={selectedPhrase ? 'Edit phrase' : 'Add new phrase'}
          submitDisabled={isExistingPhrase || !phraseInputValue}
          onConfirm={async () => {
            setEditPhraseDialogOpen(false)

            if (selectedPhrase) {
              values.training_phrases = [
                ...(values.training_phrases || []).map(phrase => {
                  if (phrase === selectedPhrase) {
                    return phraseInputValue
                  }
                  return phrase
                }),
              ]
            } else if (phraseInputValue) {
              values.training_phrases = [
                ...(values.training_phrases || []),
                phraseInputValue,
              ]
            }
          }}
          onReject={() => {
            setEditPhraseDialogOpen(false)
          }}
          body={
            <VStack space="s-24">
              <Box>
                <TextArea
                  rows={1}
                  label="Enter phrase text"
                  value={phraseInputValue}
                  onChange={e => setPhraseInputValue(e.currentTarget.value)}
                />
                {isExistingPhrase && (
                  <InputErrorMessage message="This phrase already exists, please use another one" />
                )}
              </Box>
            </VStack>
          }
          yesMessage="Continue"
          noMessage="Cancel"
        />
        <ConfirmationDialog
          open={deletePhraseDialogOpen}
          onClose={() => setDeletePhraseDialogOpen(false)}
          onConfirm={() => {
            values.training_phrases = [
              ...(values.training_phrases?.filter(phrase => phrase !== selectedPhrase) ||
                []),
            ]
            setDeletePhraseDialogOpen(false)
          }}
          onReject={() => {
            setDeletePhraseDialogOpen(false)
          }}
          label={`Delete phrase`}
          body={<Text variant="primary">{selectedPhrase}</Text>}
          yesMessage="Continue"
          noMessage="Cancel"
        />
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText={
            values.id ? 'Question successfully updated' : 'Question successfully created'
          }
          previewUrl={ROUTES.APPS.HELP_CENTER.QUESTION.PREVIEW}
        />
      </PageActions>
    </>
  )
}
