import React, { useState } from 'react'
import { useTableReturnType } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import {
  RiskIncidentsInterface,
  RiskLevel,
  RiskStatsInterface,
  RiskStatus,
} from '@src/interfaces/risk'
import {
  riskAssigneeColumn,
  riskDueDateColumn,
  riskIncidentLinkColumn,
  riskLevelColumn,
  riskReportedDateColumn,
  riskSeverityColumn,
  riskStatusColumn,
  riskSummaryColumn,
} from '@src/constants/columns/risk'
import Tooltip from '@components/Tooltip/Tooltip'
import { TooltipContainer } from '@components/CommonSC/Tooltip'
import { getColor } from '@src/styles/colors'
import { Color, Flex, MoreBar, Switch, Text } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { InfoOutline, Plus } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import { teamNameColumn } from '@src/constants/columns/team'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

interface Props {
  table: useTableReturnType<RiskIncidentsInterface, RiskStatsInterface>
  isEmployee?: boolean
  useWrapper?: boolean
}

const ROW = (isEmployee: boolean): RowInterface<RiskIncidentsInterface> => ({
  highlight: (data, theme) => {
    if (data.risk_level === RiskLevel.high) {
      return getColor(theme, Color.RED_OPAQUE_20)
    }
    return ''
  },
  cells: [
    {
      ...riskLevelColumn,
      width: 140,
      headerTooltip: (
        <TooltipContainer>
          <p>
            High - Open severity 3 and 4 incidents. These will be reported to the
            Executive Risk Committee and possibly board.
          </p>
          <p>Medium - Open severity 1 and 2 incidents.</p>
          <p>Low - Closed incidents.</p>
        </TooltipContainer>
      ),
    },
    {
      ...riskSummaryColumn,
      width: 573,
    },
    {
      ...riskSeverityColumn,
      width: 130,
    },
    {
      ...(isEmployee ? teamNameColumn : riskAssigneeColumn),
      width: 160,
    },
    {
      ...riskReportedDateColumn,
      width: 140,
    },
    {
      ...riskDueDateColumn,
      width: 100,
    },
    {
      ...riskStatusColumn,
      width: 100,
    },
    {
      ...riskIncidentLinkColumn,
      width: 140,
    },
  ],
})

const Incidents = ({ table, isEmployee = false, useWrapper }: Props) => {
  const [showClosed, setShowClosed] = useState(true)

  const handleNewRow = () => {
    window.open('https://backoffice.revolut.com/risks/report-incident', '_blank')
  }

  const onChangeShowClosed = () => {
    if (showClosed) {
      table.onFilterChange({
        filters: [{ id: RiskStatus.progress, name: RiskStatus.progress }],
        columnName: 'status',
      })
    } else {
      table.onFilterChange({
        filters: [],
        columnName: 'status',
      })
    }
    setShowClosed(!showClosed)
  }

  return (
    <TableWrapper useWrapper={useWrapper}>
      <Flex mb="s-24">
        <Stat
          label="High"
          val={
            table.stats?.high !== undefined ? (
              <Tooltip
                text="Open severity 3 and 4 incidents. These will be reported to the Executive Risk Committee and possibly board."
                placement="right"
              >
                <Text color="red">{table.stats?.high}</Text>
              </Tooltip>
            ) : undefined
          }
          mr="s-32"
        />
        <Stat
          label="Medium"
          val={
            table.stats?.medium !== undefined ? (
              <Tooltip text="Open severity 1 and 2 incidents." placement="right">
                <Text color="warning">{table.stats?.medium}</Text>
              </Tooltip>
            ) : undefined
          }
          mr="s-32"
        />
      </Flex>
      <Flex mb="s-16">
        <MoreBar>
          <MoreBar.Action useIcon={Plus} onClick={handleNewRow}>
            Add Incident
          </MoreBar.Action>
          <MoreBar.Action
            target="_blank"
            rel="noreferrer noopener"
            href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1464808658/How+to+report+a+risk+incident"
            use="a"
            useIcon={InfoOutline}
          >
            How to resolve risk incidents?
          </MoreBar.Action>
        </MoreBar>
        <Switch
          value="Show closed incidents"
          checked={showClosed}
          onChange={onChangeShowClosed}
        >
          <Text>Show closed incidents</Text>
        </Switch>
      </Flex>
      <AdjustableTable<RiskIncidentsInterface, RiskStatsInterface>
        name={TableNames.Incidents}
        useWindowScroll
        dataType="Risk incident"
        row={ROW(isEmployee)}
        {...table}
        noDataMessage="This team has no data on their Risk incidents"
      />
    </TableWrapper>
  )
}

export default Incidents
