import React from 'react'
import { useSelector } from 'react-redux'
import { MoreBar } from '@revolut/ui-kit'
import { Document, Plus, SendMessage } from '@revolut/icons'

import { PermissionTypes } from '@src/store/auth/types'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useGetDocumentsSettings } from '@src/api/settings'
import { selectUser } from '@src/store/auth/selectors'
import { useParams } from 'react-router-dom'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'

type ActionButtonsProps = {
  data: EmployeeInterface
  showSecondary?: boolean
}
export const ActionButtons = ({ data, showSecondary }: ActionButtonsProps) => {
  const user = useSelector(selectUser)
  const { data: documentsSettings } = useGetDocumentsSettings()

  const { categoryId } = useParams<{ categoryId: string }>()
  const { data: documentCategories, isLoading: isLoadingCategories } = useGetSelectors(
    selectorKeys.document_categories,
  )
  const selectedCategory = documentCategories?.find(
    category => String(category.id) === categoryId,
  )

  const canAddDocuments = !!data?.field_options?.permissions?.includes(
    PermissionTypes.UploadDocuments,
  )
  const handleAddDocument = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT.GENERAL, { employeeId: data.id }), {
      initialValues: {
        employee: { id: data.id, name: data.full_name },
        issuer: { id: user.id, name: user.full_name },
        category: selectedCategory,
      },
    })
  }

  const canRequestGenerateDocuments =
    data?.field_options?.permissions?.includes(PermissionTypes.HRManagerPermissions) ||
    data?.field_options?.permissions?.includes(
      PermissionTypes.DocumentsManagerPermissions,
    )

  const showRequest =
    showSecondary &&
    canRequestGenerateDocuments &&
    documentsSettings?.enable_document_requests
  const handleRequest = () =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.DOCUMENT_REQUEST.GENERAL, {
        employeeId: data.id,
      }),
      {
        initialValues: {
          employee: { id: data.id, name: data.full_name },
          issuer: { id: user.id, name: user.full_name },
        },
      },
    )

  const showGenerate =
    showSecondary &&
    canRequestGenerateDocuments &&
    documentsSettings?.enable_document_generation_from_templates
  const handleGenerate = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.DOCUMENT_GENERATE.GENERAL, { employeeId: data.id }),
      {
        initialValues: {
          generated_by: { id: user.id, name: user.full_name },
        },
      },
    )
  }

  return (
    <MoreBar>
      {canAddDocuments && (
        <MoreBar.Action
          useIcon={Plus}
          variant="accent"
          onClick={handleAddDocument}
          disabled={isLoadingCategories}
        >
          Add document
        </MoreBar.Action>
      )}
      {!!showRequest && (
        <MoreBar.Action useIcon={SendMessage} onClick={handleRequest}>
          Request
        </MoreBar.Action>
      )}
      {!!showGenerate && (
        <MoreBar.Action useIcon={Document} onClick={handleGenerate}>
          Generate
        </MoreBar.Action>
      )}
    </MoreBar>
  )
}
