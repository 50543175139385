import React from 'react'
import SideBar, { SideBarProps } from '@components/SideBar/SideBar'
import { Box, Bullet, Cell, Flex, Text } from '@revolut/ui-kit'

interface Props extends Pick<SideBarProps, 'isOpen' | 'onClose'> {}

const Instructions = (props: Props) => {
  return (
    <SideBar {...props} title="Instructions">
      <Box height={0}>
        <Text variant="h4" use="h4" pb="s-12">
          General
        </Text>
        <Cell flexDirection="column" mb="s-24">
          <Box width="100%">
            <Flex alignItems="flex-start">
              <Bullet style={{ flexShrink: 0 }} m="s-8">
                1
              </Bullet>
              <Text use="div" mt="s-12" ml="s-6">
                Define a brief but informative rule name
              </Text>
            </Flex>
            <Flex alignItems="flex-start">
              <Bullet style={{ flexShrink: 0 }} m="s-8">
                2
              </Bullet>
              <Text use="div" mt="s-12" ml="s-6">
                Assign a KPI owner
              </Text>
            </Flex>
            <Flex alignItems="flex-start">
              <Bullet style={{ flexShrink: 0 }} m="s-8">
                3
              </Bullet>
              <Text use="div" mt="s-12" ml="s-6">
                Write a concise but exhaustive description so that others will understand
                what is the rule based on which the KPI template will be approved
              </Text>
            </Flex>
            <Flex alignItems="flex-start">
              <Bullet style={{ flexShrink: 0 }} m="s-8">
                4
              </Bullet>
              <Text use="div" mt="s-12" ml="s-6">
                Select a template from the list. The type of template you select will
                determine the resource you can apply the template to. Example: NPS - teams
                can be applied only for teams.
              </Text>
            </Flex>
            <Flex alignItems="flex-start">
              <Bullet style={{ flexShrink: 0 }} m="s-8">
                5
              </Bullet>
              <Text use="div" mt="s-12" ml="s-6">
                Use the table to filter all the entities you want the KPI template to be
                approved to.
              </Text>
            </Flex>
          </Box>
        </Cell>
        <Text variant="h4" use="h4" pb="s-12">
          Apply to:
        </Text>
        <Cell flexDirection="column">
          <Box width="100%">
            <Flex alignItems="flex-start">
              <Bullet style={{ flexShrink: 0 }} m="s-8">
                a
              </Bullet>
              <Text use="div" mt="s-12" ml="s-6">
                <Text use="p">
                  <b>Apply to all future ones</b>
                </Text>
                <Text use="p">
                  Applies to all items that are matching the filter, present and future
                  ones.
                </Text>
                <Text use="p" mt="s-8">
                  Example: Filtering by role will retrieve all the employees that
                  currently have that role and apply the template. If other people will
                  join the company with that role in the future the template will apply to
                  them automatically.
                </Text>
              </Text>
            </Flex>
            <Flex alignItems="flex-start">
              <Bullet style={{ flexShrink: 0 }} m="s-8">
                b
              </Bullet>
              <Text use="div" mt="s-12" ml="s-6">
                <Text use="p">
                  <b>Apply to filtered</b>
                </Text>
                <Text use="p">
                  Applies strictly to the filtered items. Future items that will match
                  this filters will not be included.
                </Text>
              </Text>
            </Flex>
          </Box>
        </Cell>
        <Box height={24} />
      </Box>
    </SideBar>
  )
}

export default Instructions
