import React from 'react'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { initialRequisitionStatusFilter } from '@src/interfaces/requisitions'
import { RoleInterface } from '@src/interfaces/roles'
import CommonRequisitionTable from '@src/features/CommonRequisitionTable/CommonRequisitionTable'

interface StructureProps {
  data: RoleInterface
}

const Requisitions = ({ data }: StructureProps) => {
  const filterBy = [
    {
      filters: [{ name: data.name, id: data.id }],
      columnName: 'position__id',
      nonResettable: true,
    },
    {
      filters: initialRequisitionStatusFilter,
      columnName: 'status',
      nonResettable: true,
    },
  ]
  const sortBy = [
    {
      sortBy: 'pipeline_queue_position',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'team__name',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'seniority__level',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'priority',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const statsData = {
    filters: [
      {
        filters: [{ name: data.name, id: data.id }],
        columnName: 'position__id',
      },
    ],
  }
  return (
    <CommonRequisitionTable
      filterBy={filterBy}
      sortBy={sortBy}
      statsData={statsData}
      type="role"
    />
  )
}

export default Requisitions
