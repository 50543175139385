import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { DocumentsBulkRequestEligibleEmployeeInterface } from '@src/interfaces/documents'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import React from 'react'
import { getEmployeeValidationStatusColor } from '@src/apps/People/Documents/BulkRequest/common'

export const documentsBulkRequestEligibleEmployeeNameColumn: ColumnInterface<DocumentsBulkRequestEligibleEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'id',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.employee,
    title: 'Employee',
    insert: ({ data }) => <UserWithAvatar {...data.employee} />,
  }

export const documentsBulkRequestEligibleEmployeeCountryColumn: ColumnInterface<DocumentsBulkRequestEligibleEmployeeInterface> =
  {
    type: CellTypes.text,
    idPoint: 'employee.location.id',
    dataPoint: 'employee.location.country.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.countries,
    title: 'Country',
  }

export const documentsBulkRequestEligibleEmployeeDepartmentColumn: ColumnInterface<DocumentsBulkRequestEligibleEmployeeInterface> =
  {
    type: CellTypes.text,
    idPoint: 'employee.team.department.id',
    dataPoint: 'employee.team.department.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.department,
    title: 'Department',
  }

export const documentsBulkRequestEligibleEmployeeSeniorityColumn: ColumnInterface<DocumentsBulkRequestEligibleEmployeeInterface> =
  {
    type: CellTypes.text,
    idPoint: 'employee.seniority.id',
    dataPoint: 'employee.seniority.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.seniority,
    title: 'Seniority',
  }

export const documentsBulkRequestEmployeeValidationStatusColumn: ColumnInterface<DocumentsBulkRequestEligibleEmployeeInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Validation status',
    colors: data => getEmployeeValidationStatusColor(data.status),
  }
