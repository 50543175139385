import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { VStack, InputGroup, Text, Color, Box } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateReplace } from '@src/actions/RouterActions'
import { selectUser } from '@src/store/auth/selectors'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { selectorKeys } from '@src/constants/api'
import { RevolutTheme } from '@src/styles/theme'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'

type InputSectionProps = {
  insideSidebarPortal?: boolean
  theme?: RevolutTheme
}

export const QuestionMainInputs = ({ insideSidebarPortal, theme }: InputSectionProps) => {
  const zIndex = insideSidebarPortal ? (theme?.zIndex.sideBar || 0) + 1 : undefined

  return (
    <InputGroup>
      <LapeNewTextArea
        required
        name="question_text"
        label="Question"
        rows={2}
        message="This is the main part of the question. It will be shown in a large font size and in bold, at the top of the screen."
      />
      <LapeHTMLEditor name="description" placeholder="Description" height={300} />
      <Box mt="-s-12" ml="s-12">
        <Text fontSize="small" color={Color.GREY_TONE_50}>
          You can add additional information to show under the main question text. This
          will be shown in a smaller and lighter font.
        </Text>
      </Box>
      <LapeRadioSelectInput
        required
        name="owner"
        label="Owner"
        selector={selectorKeys.employee}
        zIndex={zIndex}
      />
    </InputGroup>
  )
}

export const QuestionSettingsInputs = ({
  insideSidebarPortal,
  theme,
}: InputSectionProps) => {
  const zIndex = insideSidebarPortal ? (theme?.zIndex.sideBar || 0) + 1 : undefined
  const { values } = useLapeContext<EngagementQuestionInterface>()

  const isOpenEnded = values?.type?.id === 'open_ended'

  useEffect(() => {
    if (isOpenEnded) {
      values.comment = {
        id: 'mandatory',
        name: 'Mandatory',
      }
    }
  }, [isOpenEnded])

  return (
    <VStack space="s-16">
      <LapeRadioSelectInput
        required
        disabled={!!values?.id}
        name="type"
        label="Question type"
        selector={selectorKeys.engagement_question_types}
        message="This defines how the question will be answered (1-5 scale rating or open ended). This cannot be changed later."
        zIndex={zIndex}
      />
      <LapeRadioSelectInput
        required
        name="driver"
        label="Driver"
        selector={selectorKeys.engagement_drivers}
        message="Define driver with which the question will be linked."
        zIndex={zIndex}
      />
      <LapeRadioSelectInput
        required
        disabled={isOpenEnded}
        name="comment"
        label="Comment"
        selector={selectorKeys.engagement_question_comment_options}
        zIndex={zIndex}
      />
      {isOpenEnded && (
        <LapeMultiInput
          title="Answer categories"
          description="You can define below which categories employees can select when answering the question. If you leave this blank, the category selector will not appear in the survey when this question is answered."
          name="categories.names"
          valueFieldName={undefined}
          withCell
        />
      )}
    </VStack>
  )
}

export const QuestionForm = () => {
  const { values } = useLapeContext<EngagementQuestionInterface>()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: user.id, full_name: user.full_name }
    }
  }, [])

  const title = values.id ? 'Edit question' : 'Add new question'

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={ROUTES.APPS.ENGAGEMENT.QUESTIONS_TABLE} />
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Define question" />
          <QuestionMainInputs />
          <NewStepperTitle title="Settings" />
          <QuestionSettingsInputs />
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText={
            values.id ? 'Question successfully updated' : 'Question successfully added'
          }
          onAfterSubmit={res => {
            navigateReplace(
              pathToUrl(ROUTES.APPS.ENGAGEMENT.QUESTION.PREVIEW, { id: res.id }),
            )
          }}
        >
          Save
        </NewSaveButtonWithPopup>
      </PageActions>
    </PageWrapper>
  )
}
