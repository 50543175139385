import React, { useEffect, useState } from 'react'
import SectionTitle from '../components/SectionTitle'
import { Message } from '../components/Message'
import { Box, Cell, Flex, Spacer, TextSkeleton, Token, VStack } from '@revolut/ui-kit'
import SkillsTable from '@src/pages/Performance/Skills/SkillsTable'
import {
  skillCategoryColumn,
  skillFunctionColumn,
  skillGenericNameColumn,
  skillRolesColumn,
} from '@src/constants/columns/skill'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { SkillInterface, SkillType } from '@src/interfaces/skills'
import { getDeleteOrgUnitActionColumn } from '@src/constants/columns/common'
import { silentDeleteSkill, skillRequests } from '@src/api/skills'
import ScorecardSection from '@src/components/ScorecardGeneral/ScorecardSection'
import styled from 'styled-components'
import { getCard } from '@src/pages/Forms/SkillForm/ScorecardPreview'
import { PerformanceReviewTypes, ReviewCategory } from '@src/interfaces/performance'
import { SkillForm } from '@src/pages/Forms/SkillForm/General'

const FitScorecard = styled(ScorecardSection)`
  margin-top: -30px;
  margin-bottom: 0;
`
export const SkillsPreview = ({ asValue = false }: { asValue?: boolean }) => {
  const [selectedSkill, setSelectedSkill] = useState<SkillInterface>()
  const [skillsDescriptions, setSkillsDescriptions] = useState<Record<number, string>>({})

  useEffect(() => {
    if (selectedSkill && !skillsDescriptions[selectedSkill.id]) {
      skillRequests.getItem(selectedSkill.id).then(({ data }) => {
        setSkillsDescriptions({
          ...skillsDescriptions,
          [selectedSkill.id]: data.description || 'Description',
        })
      })
    }
  }, [selectedSkill])

  return (
    <>
      <Flex gap="s-16">
        <VStack flex={3}>
          <SectionTitle
            title="Confirm your skills"
            subtitle="You can check the scorecard preview by clicking on the row in the table"
          />
          <Message
            storageKey="onboarding_skills_preview_message"
            title="We have created Skills just for you"
            description="Feel free to take a look and make any adjustments to suit your preferences"
          />
          <Spacer height="s-16" />
          <SkillsTable
            asValue={asValue}
            skillForm={
              asValue ? (
                <SkillForm
                  hidePlaybookInput
                  typeOptions={[SkillType.Culture, SkillType.Executive]}
                />
              ) : undefined
            }
            popupTitle={asValue ? 'Create new value' : undefined}
            initialFilter={
              asValue
                ? [
                    {
                      columnName: 'skill_type',
                      nonResettable: true,
                      filters: [
                        { id: SkillType.Culture, name: 'Really?' },
                        { id: SkillType.Executive, name: 'Really?' },
                      ],
                    },
                  ]
                : undefined
            }
            createVariant="popup"
            createRow={table => ({
              highlight: skill => (selectedSkill === skill ? Token.color.greyTone8 : ''),
              linkToForm: skill => setSelectedSkill(skill),
              cells: asValue
                ? [
                    {
                      ...skillGenericNameColumn,
                      title: 'Value name',
                      width: 200,
                    },
                    {
                      ...skillCategoryColumn,
                      width: 200,
                    },
                    {
                      ...getDeleteOrgUnitActionColumn({
                        handleDetete: async (skill: SkillInterface) => {
                          const result = await silentDeleteSkill(skill)
                          table.refresh()
                          return result
                        },
                        props: {
                          confirmMessage: 'Are you sure you want to delete this value',
                        },
                      }),
                      width: 100,
                    },
                  ]
                : [
                    {
                      ...skillGenericNameColumn,
                      title: 'Name',
                      width: 200,
                    },
                    {
                      ...ownerNameColumn,
                      width: 150,
                    },
                    {
                      ...skillFunctionColumn,
                      width: 150,
                    },
                    {
                      ...skillRolesColumn,
                      width: 250,
                    },
                    {
                      ...getDeleteOrgUnitActionColumn({
                        handleDetete: async (skill: SkillInterface) => {
                          const result = await silentDeleteSkill(skill)
                          table.refresh()
                          return result
                        },
                        props: {
                          confirmMessage: 'Are you sure you want to delete this skill',
                        },
                      }),
                      width: 100,
                    },
                  ],
            })}
          />
        </VStack>
        <Box flex={2}>
          {selectedSkill && (
            <Cell width="100%">
              <VStack>
                <SectionTitle
                  title={selectedSkill.name}
                  subtitle={
                    typeof skillsDescriptions[selectedSkill.id] === 'string' ? (
                      skillsDescriptions[selectedSkill.id]
                    ) : (
                      <TextSkeleton width="250px" />
                    )
                  }
                />
                <FitScorecard
                  skillId="0"
                  cardIndex={0}
                  onChange={() => {}}
                  onSetResultLoading={() => {}}
                  card={getCard(selectedSkill)}
                  type={PerformanceReviewTypes.skills}
                  category={ReviewCategory.Performance}
                  hideCardTitle
                  hideJustification
                  hideResult
                  disabled
                  hidePlaybookDescription
                />
              </VStack>
            </Cell>
          )}
        </Box>
      </Flex>
    </>
  )
}
