import {
  changeInterviewStage,
  skipScheduledInterview,
  unSkipScheduledInterview,
} from '@src/api/recruitment/interviews'
import { InterviewType } from '@src/interfaces/interviewTool'

export const changeStage = async (roundId: number, stageId: number) => {
  const response = await changeInterviewStage(roundId, stageId)
  return response.data
}

export const skipInterview = async (roundId: number, stageId: number) => {
  await skipScheduledInterview(roundId, stageId)
}

export const unskipInterview = async (roundId: number, stageId: number) => {
  await unSkipScheduledInterview(roundId, stageId)
}

export const NON_ELIGIBLE_FEEDBACK_STAGES: InterviewType[] = [
  'online_test',
  'offer',
  'cv_screening',
]
