import React from 'react'
import styled from 'styled-components'
import { Box, Color, Flex, Text, TextButton, Avatar } from '@revolut/ui-kit'
import { Pencil } from '@revolut/icons'
import Tooltip from '@components/Tooltip/Tooltip'
import { FunctionalDeliverableScorecardInterface } from '@src/interfaces/deliverables'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { getAvatarUrl } from '@src/utils/employees'

const CommentTooltip = styled(Tooltip)`
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`

const PencilIcon = styled(Box)`
  display: none;
`

const ViewComment = styled.button`
  border: 0;
  outline: none;
  background-color: transparent;
  height: 100%;
  text-align: left;
  width: 100%;

  &:hover {
    ${PencilIcon} {
      display: block;
    }
  }

  &:disabled {
    color: inherit;
    cursor: auto;
  }
`

type Props = {
  scorecard?: FunctionalDeliverableScorecardInterface
  deliverableId: number
  onClickComment: (
    ticketId: number,
    ticketKey: string,
    scorecard?: FunctionalDeliverableScorecardInterface,
  ) => void
  deliverableKey: string
  canEdit?: boolean
  ignored?: boolean
  comment?: string
  locked?: boolean
  loading?: boolean
}

const UserAvatar = ({
  scorecard,
}: {
  scorecard?: FunctionalDeliverableScorecardInterface
}) => {
  if (!scorecard) {
    return null
  }
  return (
    <Tooltip
      placement="top"
      text={scorecard.reviewer?.full_name}
      hide={!scorecard.reviewer?.full_name}
    >
      <Box width={32} height={32} mr="s-8" style={{ flexShrink: 0 }}>
        <Avatar size={32} image={getAvatarUrl(scorecard.reviewer?.avatar)}>
          {scorecard.reviewer?.avatar ? '' : scorecard.reviewer?.full_name?.[0]}
        </Avatar>
      </Box>
    </Tooltip>
  )
}

const CommentCell = ({
  scorecard,
  deliverableId,
  deliverableKey,
  onClickComment,
  locked,
  canEdit,
  ignored,
  comment,
  loading,
}: Props) => {
  const user = useSelector(selectUser)
  const notSelf = !!scorecard?.reviewer?.id && scorecard.reviewer.id !== user.id

  const disabled = !canEdit || notSelf

  if (ignored) {
    return (
      <Flex alignItems="center" width="95%">
        <UserAvatar scorecard={scorecard} />
        <Text
          textOverflow="ellipsis"
          overflow="hidden"
          width="95%"
          color={Color.GREY_20_OPAQUE_90}
          data-testid="comment"
        >
          {comment || `Ignored by ${scorecard?.reviewer?.display_name}`}
        </Text>
      </Flex>
    )
  }

  if (scorecard?.comment) {
    return (
      <Flex alignItems="center" height="100%">
        <UserAvatar scorecard={scorecard} />
        <CommentTooltip
          placement="top"
          body={
            <Text
              use="div"
              p="s-16"
              color={Color.BACKGROUND}
              width={300}
              whiteSpace="pre-wrap"
            >
              {scorecard.comment}
            </Text>
          }
        >
          <ViewComment
            type="button"
            onClick={() => {
              onClickComment(deliverableId, deliverableKey, scorecard)
            }}
            disabled={disabled || loading}
            data-testid="btn-comment"
          >
            <Flex alignItems="center" justifyContent="space-between" width="95%">
              <Text
                textOverflow="ellipsis"
                overflow="hidden"
                width="95%"
                data-testid="comment"
              >
                {scorecard.comment}
              </Text>
              {!disabled && !loading && (
                <PencilIcon style={{ flexShrink: 0 }}>
                  <Pencil color={Color.LIGHT_BLUE} size={16} />
                </PencilIcon>
              )}
            </Flex>
          </ViewComment>
        </CommentTooltip>
      </Flex>
    )
  }

  if (disabled) {
    if (scorecard?.reviewer) {
      return (
        <Flex alignItems="center" width="95%">
          <UserAvatar scorecard={scorecard} />
          <Text color={Color.GREY_TONE_50} data-testid="comment">
            -
          </Text>
        </Flex>
      )
    }

    return <Text color={Color.GREY_20_OPAQUE_90}>-</Text>
  }

  return (
    <TextButton
      lineHeight="initial"
      onClick={() => {
        onClickComment(deliverableId, deliverableKey, scorecard)
      }}
      disabled={locked || loading}
      data-testid="btn-comment"
    >
      Add comment
    </TextButton>
  )
}

export default CommentCell
