import React, { forwardRef } from 'react'
import { ChevronDown, ChevronUp } from '@revolut/icons'
import { Flex, H4 } from '@revolut/ui-kit'

type Props = {
  open: boolean
  onClick: () => void
  children: React.ReactNode
}

const DropdownStatAction = forwardRef<HTMLButtonElement, Props>(
  ({ children, open, onClick, ...props }, ref) => (
    <button type="button" onClick={onClick} aria-label="open menu" ref={ref} {...props}>
      <Flex alignItems="center" color="foreground">
        <H4 use="span" fontWeight="bold">
          {children}
        </H4>
        {open ? (
          <ChevronUp color="foreground" hoverColor="grey-tone-50" size={24} />
        ) : (
          <ChevronDown color="foreground" hoverColor="grey-tone-50" size={24} />
        )}
      </Flex>
    </button>
  ),
)

export default DropdownStatAction
