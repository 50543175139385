import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Icon, { Icons } from '../Icon/Icon'
import { useTheme } from '@src/styles/theme'
import { getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

const Container = styled.div<{ color: string; multiline: boolean }>`
  display: grid;
  border-radius: 4px;
  grid-template-columns: 20px auto;
  grid-column-gap: 15px;
  width: 100%;
  align-items: ${props => (props.multiline ? 'start' : 'center')};
  white-space: ${props => (props.multiline ? 'break-spaces' : 'normal')};
  padding: 16px;
  background-color: ${props => props.color};
`
const IconContainer = styled.div<{ color: string }>`
  color: ${props => props.color};
`

const Message = styled.div``

interface WarningMessageProps {
  className?: string
  /** The message itself */
  children: ReactNode
  /** Type of the warning component */
  type?: WarningTypes
  /** Select what icon to show, default: Info */
  iconType?: Icons
  /** Possibly long message spanning across multiple lines */
  multiline?: boolean
}

enum WarningTypes {
  default = 'default',
}

const WarningMessage = ({
  className,
  iconType = 'Info',
  children,
  type = WarningTypes.default,
  multiline = false,
}: WarningMessageProps) => {
  const theme = useTheme()

  const getBackgroundColor = (_: WarningTypes) => {
    return getColor(theme, Color.GREY_50_OPAQUE_10)
  }
  const getIconColor = (_: WarningTypes) => {
    return theme.colors.orange
  }
  return (
    <Container
      color={getBackgroundColor(type)}
      className={className}
      multiline={multiline}
    >
      <IconContainer color={getIconColor(type)}>
        <Icon size="medium" type={iconType} />
      </IconContainer>
      <Message>{children}</Message>
    </Container>
  )
}

export default WarningMessage
