import React from 'react'
import { Box, Color, Flex, Text, Widget } from '@revolut/ui-kit'
import PredefinedLabel from '@src/pages/Careers/CareersApplication/PredefinedSectionLabel'

export interface ApplicationFormWidgetSectionInterface {
  locked?: boolean
  onClickEdit?: (idx: number) => void
  idx?: number
  actions?: React.ReactNode
  title?: React.ReactNode
  description?: string | null
  children?: React.ReactNode
  titleIndexNumber: number
}

const ApplicationFormWidgetSection = ({
  locked,
  onClickEdit,
  idx,
  actions,
  title,
  description,
  children,
  titleIndexNumber,
}: ApplicationFormWidgetSectionInterface) => {
  return (
    <Widget
      style={{
        cursor: locked ? 'auto' : 'pointer',
      }}
      onClick={() => {
        if (!locked && idx !== undefined) {
          onClickEdit?.(idx)
        }
      }}
      width="100%"
      display="block"
      p="s-16"
      data-testid={`preview_section_${titleIndexNumber}`}
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="start">
          <Box mb="s-4">
            <Text fontWeight={500} fontSize="h5" use="div">
              {titleIndexNumber}. {title || 'Section'} {locked && <PredefinedLabel />}
            </Text>
          </Box>
          {actions}
        </Flex>
        {description && (
          <Box mb="s-16">
            <Text variant="secondary" color={Color.GREY_TONE_50}>
              {description}
            </Text>
          </Box>
        )}
      </Box>
      {children}
    </Widget>
  )
}

export default ApplicationFormWidgetSection
