import React from 'react'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { HStack, Skeleton, Tag, Text, Token, chain } from '@revolut/ui-kit'
import { formatDateTime } from '@src/utils/format'

type CandidateSubtitleProps = {
  candidate?: CandidateInterface
  round?: InterviewRoundInterface
}

const CandidateSubtitle = ({ candidate, round }: CandidateSubtitleProps) => {
  const isArchived = round?.state === 'archived'
  const currentStage = isArchived ? (
    <Tag
      variant="outlined"
      color={candidate?.can_re_engage ? Token.color.green : Token.color.red}
    >
      {candidate?.can_re_engage ? 'Can be contacted' : 'Cannot be contacted'}
    </Tag>
  ) : (
    <Text use="div" color={Token.color.greyTone50} variant="caption">
      Current stage: {round?.latest_interview_stage?.title || '-'}
    </Text>
  )
  const latestActivityDate = (
    <Text use="div" color={Token.color.greyTone50} variant="caption">
      {candidate?.latest_activity_date_time
        ? `Last activity at ${formatDateTime(candidate?.latest_activity_date_time)}`
        : ''}
    </Text>
  )
  return (
    <HStack align="center">
      {!candidate && !round ? <Skeleton /> : chain(currentStage, latestActivityDate)}
    </HStack>
  )
}

export default CandidateSubtitle
