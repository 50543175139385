import React from 'react'
import { Color, InputGroup, Switch, Text } from '@revolut/ui-kit'
import { formatDistance, isAfter } from 'date-fns'

import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { selectorKeys } from '@src/constants/api'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { SQLQuery } from '@src/features/AudienceSelection/SQLQuery'
import { AudienceTable } from '@src/features/AudienceSelection/AudienceTable'
import { runQuery } from '@src/api/accessManagement'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  AudienceSelectionType,
  GroupAccessManagementInterface,
} from '@src/interfaces/accessManagement'

export const getLastUpdatedInfo = (dateTime?: string | null) => {
  return `Updates every 30 minutes${
    dateTime
      ? ` (last updated ${formatDistance(new Date(dateTime), new Date())} ago)`
      : ''
  }`
}

export const checkIsExpired = (expirationDateTime?: string | null) =>
  expirationDateTime != null && isAfter(new Date(), new Date(expirationDateTime))

export const Form = () => {
  const { values, initialValues } = useLapeContext<GroupAccessManagementInterface>()

  return (
    <PageBody>
      <AutoStepper>
        <NewStepperTitle title="Group details" />
        <InputGroup>
          <LapeNewInput name="name" label="Group name" required />
          <LapeNewMultiSelect
            name="permissions"
            selector={selectorKeys.permissions}
            placeholder="Permissions"
            required
          />
          <LapeRadioSelectInput
            name="settings.owner"
            selector={selectorKeys.employee}
            label="Owner"
          />
          <LapeDatePickerInput
            label="Certification expiration date"
            name="settings.certification_expiration_date_time"
            message="The date on which the role will expire. After this date the role will be marked as expired"
            required
          />
          <LapeNewTextArea
            name="settings.description"
            label="Description"
            required
            rows={3}
          />
        </InputGroup>

        <NewStepperTitle
          title="Group rules"
          subtitle={getLastUpdatedInfo(initialValues.settings?.audience_update_date_time)}
        />
        <InputGroup>
          <Text color={Color.GREY_TONE_50}>
            Permission groups can be assigned automatically to employees based on a set of
            rules or filters that get applied on them. When the employees details change
            and they no longer qualify the access is removed automatically.
          </Text>
          <Switch
            onChange={e => {
              const checked = e.currentTarget.checked
              if (
                checked &&
                values.settings &&
                !values.settings.audience_selection_type
              ) {
                values.settings.audience_selection_type = {
                  id: 'filtered_table',
                  name: 'Table with filters',
                }
              }
              if (!checked && values.settings) {
                values.settings.audience_selection_type = null
                values.settings.sql_query = null
                values.settings.sql_query_db = null
                values.settings.table_filters = null
              }
            }}
            checked={!!values.settings?.audience_selection_type}
          >
            Assign group automatically
          </Switch>
          {values.settings?.audience_selection_type ? (
            <>
              <LapeRadioSelectInput<AudienceSelectionType>
                name="settings.audience_selection_type"
                label="Selection mode"
                selector={selectorKeys.group_setting_audience_selection_types}
                onAfterChange={mode => {
                  if (mode?.id === 'sql' && values.settings?.table_filters) {
                    values.settings.table_filters = null
                  }
                  if (mode?.id === 'filtered_table') {
                    if (values.settings?.sql_query) {
                      values.settings.sql_query = null
                    }
                    if (values.settings?.sql_query_db) {
                      values.settings.sql_query_db = null
                    }
                  }
                }}
              />
              <HideIfCommercial>
                {values.settings?.audience_selection_type.id === 'sql' && (
                  <SQLQuery
                    type="access-groups"
                    api={() =>
                      runQuery(values.settings?.sql_query, values.settings?.sql_query_db)
                    }
                    sqlQueryDbName={values.settings?.sql_query_db?.name}
                    queryFieldsToFormFields={{
                      sql_query: 'settings.sql_query',
                      sql_query_db: 'settings.sql_query_db',
                    }}
                  />
                )}
              </HideIfCommercial>
              {values.settings?.audience_selection_type.id === 'filtered_table' && (
                <AudienceTable
                  filter={initialValues.settings?.table_filters}
                  onFilterChange={tableFilters => {
                    if (values.settings) {
                      values.settings.table_filters = tableFilters
                    }
                  }}
                />
              )}
            </>
          ) : null}
        </InputGroup>
      </AutoStepper>
    </PageBody>
  )
}

interface HeaderProps {
  name: string
}

export const Header: React.FC<HeaderProps> = ({ name, children }) => (
  <PageHeader pb="s-8" title={name} backUrl={ROUTES.ADMIN.ACCESS_MANAGEMENT.GROUPS}>
    {children}
  </PageHeader>
)
