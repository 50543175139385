import React, { useEffect, useState } from 'react'
import { API, selectorKeys } from '@src/constants/api'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { rolesRequests } from '@src/api/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { InputGroup, Tooltip, useTooltip } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import SideBar from '@components/SideBar/SideBar'
import GeneralExamples from '@src/pages/Forms/SpecialisationForm/General/GeneralExamples'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import { Statuses } from '@src/interfaces'
import LapeMultiInput from '@components/Inputs/LapeFields/LapeMultiInput'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useNextSpecialisationStepButtonLink } from '@src/pages/Forms/SpecialisationForm/hooks'

const CURRENT_STEP = 'general'

const General = () => {
  const { values, valid } = useLapeContext<SpecialisationInterface>()
  const [isSidebarOpen, setSidebarOpen] = useState(true)
  const tooltip = useTooltip()
  const user = useSelector(selectUser)
  const nextLink = useNextSpecialisationStepButtonLink(CURRENT_STEP)

  useEffect(() => {
    if (!values.owner && user) {
      values.owner = { id: user.id, full_name: user.full_name }
    }

    if (!values.id && values.role?.id) {
      prePopulate()
    }
  }, [])

  useEffect(() => {
    if (!values.id && values.performance_weights?.length) {
      // clean up performance weights if saved in browser
      values.performance_weights = []
    }
  }, [])

  const prePopulate = async () => {
    const role = await rolesRequests.getItem(values.role?.id)

    if (role.data) {
      values.goals = role.data.role_goals || []
      values.mission = role.data.role_description
      values.candidate_expectations = {
        must_have: role.data?.candidate_expectations?.must_have || [],
        good_to_have: role.data?.candidate_expectations?.good_to_have || [],
        red_flags: role.data?.candidate_expectations?.red_flags || [],
        example_profiles: role.data?.candidate_expectations?.example_profiles || [],
        relevant_sectors: role.data?.candidate_expectations?.relevant_sectors || [],
      }
    }
  }

  const disableRoleChange = !!values.performance_weights?.length // changing role when weights are set is broken. BE ignores update and keeps weights for previous parent skills

  return (
    <>
      <PageBody>
        <AutoStepper pt="s-8" pb="100px">
          <SideBar
            title="Information"
            isOpen={isSidebarOpen}
            onClose={() => setSidebarOpen(false)}
          >
            <GeneralExamples />
          </SideBar>
          <AutoStepperTitle title="Specialisation details" />
          <InputGroup>
            <div {...tooltip.getAnchorProps()}>
              <LapeRadioSelectInput
                disabled={disableRoleChange}
                name="role"
                label="Parent role"
                selector={selectorKeys.role}
                onAfterChange={value => {
                  values.name = value?.name ? `${value.name} (specialisation)` : ''
                }}
              />
              {disableRoleChange && (
                <Tooltip width="350px" {...tooltip.getTargetProps()}>
                  Unable to change the parent role for existing specialisations. Please
                  create a new specialisation inside the desired role, or duplicate an
                  existing one.
                </Tooltip>
              )}
            </div>

            <LapeNewInput label="Specialisation name" name="name" required />
            <LapeRadioSelectInput
              name="owner"
              label="Specialisation Owner"
              selector={selectorKeys.employee}
              optional
            />
          </InputGroup>
          <AutoStepperTitle title="Specialisation description" />
          <InputGroup>
            <LapeNewTextArea name="mission" label="Specialisation mission" rows={3} />
            <LapeMultiInput title="Enter specialisation goals" name="goals" />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      {/* TODO: make it work for multiple buttons */}
      <PageActions>
        <>
          {values.status === Statuses.draft && (
            <RoleSaveDraftButton
              title="Specialisation"
              pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {})}
              pathAfterSave={ROUTES.FORMS.SPECIALISATIONS.GENERAL}
              isNew
              notification={{
                path: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {
                  id: values?.role?.id,
                }),
                updateMsg: 'Specialisation draft successfully updated.',
                createMsg: 'Specialisation draft successfully created.',
              }}
            />
          )}
          <ContinueRoleButton
            api={API.SPECIALISATIONS}
            step={CURRENT_STEP}
            type="specialisation"
            to={nextLink}
            disabled={!valid}
          />
        </>
      </PageActions>
    </>
  )
}

export default connect(General)
