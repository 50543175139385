import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { A } from '@components/CommonSC/General'
import { selectorKeys } from '@src/constants/api'
import React from 'react'
import {
  CXComplaintsInterface,
  CXCsatDetractorsInterface,
  CXSupportLoadInterface,
  CXTrustpilotInterface,
} from '@src/interfaces/businessDebts'
import { Color, Flex, Text, TextButton } from '@revolut/ui-kit'
import {
  CXCategoryStatus,
  CXCategoryTitles,
  CXDepartmentCategoryPaths,
  CXSummaryInterface,
  CXTeamCategoryPaths,
  CXValueType,
} from '@src/interfaces/cx'
import { formatNumber, formatPercentage } from '@src/utils/format'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import Tooltip from '@components/Tooltip/Tooltip'
import { TooltipContainer } from '@components/CommonSC/Tooltip'

export const cxComplaintIdColumn: ColumnInterface<CXComplaintsInterface> = {
  type: CellTypes.insert,
  idPoint: 'link',
  dataPoint: 'link',
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => (
    <A href={data.complaint_url} target="_blank">
      Link
    </A>
  ),
  selectorsKey: selectorKeys.none,
  title: 'Compliance reference',
}

export const cxComplaintSummaryColumn: ColumnInterface<
  CXComplaintsInterface | CXCsatDetractorsInterface | CXTrustpilotInterface
> = {
  type: CellTypes.insert,
  idPoint: 'summary',
  dataPoint: 'summary',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data, theme }) => {
    return (
      <Flex>
        <Tooltip
          noArrow
          body={
            <Text color="foreground" p="s-16">
              {data.summary}
            </Text>
          }
          placement="top-start"
          backgroundColor={theme.colors['grey-tone-10']}
        >
          <Text>{data.summary}</Text>
        </Tooltip>
      </Flex>
    )
  },
  title: 'Summary',
}

export const cxComplaintCreatedAtColumn: ColumnInterface<
  | CXComplaintsInterface
  | CXCsatDetractorsInterface
  | CXSupportLoadInterface
  | CXTrustpilotInterface
> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Creation date',
}

export const cxCSATScoreColumn: ColumnInterface<CXCsatDetractorsInterface> = {
  type: CellTypes.text,
  idPoint: 'score',
  dataPoint: 'score',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Rating',
}

export const cxCSATLinkColumn: ColumnInterface<
  CXCsatDetractorsInterface | CXTrustpilotInterface
> = {
  type: CellTypes.insert,
  idPoint: 'feedback_url',
  dataPoint: 'feedback_url',
  sortKey: null,
  filterKey: null,
  insert: ({ data }) =>
    data.feedback_url ? (
      <A href={data.feedback_url} target="_blank">
        Link
      </A>
    ) : (
      '-'
    ),
  selectorsKey: selectorKeys.none,
  title: 'Feedback',
}

export const cxSupportTicketIdColumn: ColumnInterface<CXSupportLoadInterface> = {
  type: CellTypes.insert,
  idPoint: 'link',
  dataPoint: 'link',
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => (
    <A href={data.ticket_url} target="_blank">
      {data.ticket_id}
    </A>
  ),
  selectorsKey: selectorKeys.none,
  title: 'Ticket',
}

export const cxSupportTagColumn: ColumnInterface<
  CXSupportLoadInterface | CXComplaintsInterface
> = {
  type: CellTypes.text,
  idPoint: 'tag',
  dataPoint: 'tag',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Tag',
}

export const cxSummaryCategoryColumn: ColumnInterface<CXSummaryInterface> = {
  type: CellTypes.insert,
  idPoint: 'category',
  dataPoint: 'category',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'CX Metric',
  insert: ({ data }) => CXCategoryTitles[data.category],
}

export const cxSummaryLastValueColumn: ColumnInterface<CXSummaryInterface> = {
  type: CellTypes.insert,
  idPoint: 'last_period_value',
  dataPoint: 'last_period_value',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Latest',
  headerTooltip: (
    <TooltipContainer>
      The value calculated for the latest completed time period (e.g. the last full
      day/week/month).
    </TooltipContainer>
  ),
  insert: ({ data }) => {
    if (data.value_type === CXValueType.Percentage) {
      return formatPercentage(data.last_period_value)
    }
    return formatNumber(data.last_period_value)
  },
}

export const cxSummaryTrendColumn: ColumnInterface<CXSummaryInterface> = {
  type: CellTypes.insert,
  idPoint: 'trend',
  dataPoint: 'trend',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Trend VS Previous',
  insert: ({ data }) => {
    const color: Color = data.status === CXCategoryStatus.Good ? 'success' : 'error'
    const value =
      data.value_type === CXValueType.Numeric
        ? formatNumber(data.trend)
        : formatPercentage(data.trend)

    return (
      <Flex alignItems="center">
        <Text color={color} pr="s-4">
          {data.trend > 0 ? '+' : ''}
          {value}
        </Text>
      </Flex>
    )
  },
}

export const cxSummary28DayAverageColumn: ColumnInterface<CXSummaryInterface> = {
  type: CellTypes.insert,
  idPoint: 'twenty_eight_day_average',
  dataPoint: 'twenty_eight_day_average',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: '28 Day Average',
  insert: ({ data }) => {
    if (data.value_type === CXValueType.Percentage) {
      return formatPercentage(data.twenty_eight_day_average)
    }
    return formatNumber(data.twenty_eight_day_average)
  },
}

export const getCxSummaryActionColumn = (
  isTeam: boolean,
  id: number,
): ColumnInterface<CXSummaryInterface> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => (
    <TextButton
      onClick={e => {
        navigateTo(
          pathToUrl(
            isTeam
              ? CXTeamCategoryPaths[data.category]
              : CXDepartmentCategoryPaths[data.category],
            { id },
          ),
        )
        e.stopPropagation()
      }}
    >
      Details
    </TextButton>
  ),
})
