import React from 'react'
import { connect } from 'lape'
import { useSelector } from 'react-redux'
import { matchPath, Route, Switch, useLocation } from 'react-router-dom'

import Form from '@src/features/Form/Form'
import { PageWrapper } from '@components/Page/Page'
import { PermissionTypes } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'
import { RequestInterfaceNew } from '@src/interfaces'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'

interface SettingsFormRoute {
  title: string
  description?: string
  path: string
  url: string
  canView: PermissionTypes[]
  /** don't include route in tab navigation bar */
  hideInTabs?: boolean
  component: () => JSX.Element
}

interface SettingsRouteProps {
  title: string
}

interface SettingsFormContentsProps {
  routes: SettingsFormRoute[]
  title?: string
  subtitle?: string
}

const SettingsRoute: React.FC<SettingsRouteProps> = ({ title, children }) => {
  useSetDocumentTitle('Settings', title)

  return <>{children}</>
}

const SettingsFormContents = ({ routes, title, subtitle }: SettingsFormContentsProps) => {
  const location = useLocation()
  const permissions = useSelector(selectPermissions)
  const filteredRoutes = routes.filter(route =>
    route.canView
      ? route.canView?.some(permission => permissions.includes(permission))
      : true,
  )

  const tabs = filteredRoutes.filter(tab => !tab.hideInTabs)

  const currentRoute = filteredRoutes.find(
    tab =>
      !!matchPath(location.pathname, {
        path: tab.path,
        exact: true,
      }),
  )

  return (
    <PageWrapper>
      {currentRoute && (
        <PageHeader
          title={title || currentRoute.title}
          subtitle={subtitle || currentRoute.description}
          backUrl={pathToUrl(ROUTES.SETTINGS.ALL)}
          key={currentRoute.path}
        />
      )}

      {tabs.length > 1 && <TabBarNavigation tabs={tabs} />}
      <Switch>
        {filteredRoutes.map(route => {
          return (
            <Route exact key={route.path} path={route.path}>
              <SettingsRoute title={route.title}>
                <route.component />
              </SettingsRoute>
            </Route>
          )
        })}
        <Route>
          <NoAccessError />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

interface SettingsFormInterface {
  api: RequestInterfaceNew<{}>
  routes: SettingsFormRoute[]
  multiInputFields?: string[]
  title?: string
  subtitle?: string
}

export const SettingsForm: React.FC<SettingsFormInterface> = connect(
  ({ api, routes, multiInputFields, ...rest }) => (
    /** Backend is setup to only have a singleton object for settings, forcing `id: 1` here will be fine */
    <Form api={api} forceParams={{ id: '1' }} clearMultiInputFields={multiInputFields}>
      <SettingsFormContents routes={routes} {...rest} />
    </Form>
  ),
)
