import React, { useEffect, useRef } from 'react'
import { Box, DetailsCell, Flex, Skeleton } from '@revolut/ui-kit'
import styled from 'styled-components'

import { EmployeeInterface } from '@src/interfaces/employees'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RewardsSummary } from '@src/interfaces/rewards'
import {
  rewardsAmountColumn,
  rewardsDescriptionColumn,
  rewardsTypeColumn,
} from '@src/constants/columns/rewards'
import { getEmployeeRewardsSummary } from '@src/api/rewards'
import { TotalErrorIcon, useCompensationTotal, useYear, YearFilter } from './common'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { CurrencySelect } from '@components/CurrencySelect/CurrencySelect'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'
import { AnonymizedWarning } from '../components/AnonymizedWarning'

const ROW: RowInterface<RewardsSummary> = {
  cells: [
    {
      ...rewardsTypeColumn,
      width: 300,
    },
    {
      ...rewardsDescriptionColumn,
      width: 800,
    },
    {
      ...rewardsAmountColumn,
      noResize: true,
      width: 200,
    },
  ],
}

const TotalCell = styled(DetailsCell)`
  background-color: ${props => props.theme.colors['grey-tone-2']};
  border: 1px solid ${props => props.theme.colors['grey-5']};
  margin-top: 8px;
`

interface Props {
  data: EmployeeInterface
  currency?: string
  setCurrency: (currency: string) => void
}

const Summary = ({ data, currency, setCurrency }: Props) => {
  const [year, setYear] = useYear()
  const mounted = useRef(false)

  const table = useTable<RewardsSummary>({
    getItems: getEmployeeRewardsSummary(data.id, year, currency),
  })

  useEffect(() => {
    if (mounted.current) {
      table.refresh()
    } else {
      mounted.current = true
    }
  }, [currency, year])

  const { total, totalError } = useCompensationTotal(table.data, currency, table.loading)

  ROW.linkToForm = linkData => {
    switch (linkData.reward_type.id) {
      case 'benefits':
        return navigateTo(
          pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.BENEFITS, { id: data.id }),
        )
      case 'bonus':
        return navigateTo(
          pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.BONUSES, { id: data.id }),
        )
      case 'contract_salary':
        return navigateTo(
          pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.SALARY, { id: data.id }),
        )
      case 'salary_sacrifice':
        return navigateTo(
          pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.SALARY_SACRIFICE, { id: data.id }),
        )
      default:
        return undefined
    }
  }

  return (
    <>
      <AnonymizedWarning employee={data} />
      <TableWrapper mb="s-20">
        <Flex mb="s-16">
          <Box mr="s-32">
            <CurrencySelect
              value={totalError ? <TotalErrorIcon /> : total}
              label="Total"
              onCurrencyChange={({ iso_code }) => setCurrency(iso_code)}
            />
          </Box>
          <YearFilter value={year} onChange={setYear} />
        </Flex>

        <AdjustableTable
          name={TableNames.Rewards}
          useWindowScroll
          dataType="Rewards"
          row={ROW}
          {...table}
          noDataMessage="Rewards will appear here"
        />

        <TotalCell>
          <DetailsCell.Title color="foreground">Total</DetailsCell.Title>
          {table.loading ? (
            <Skeleton height={16} width={64} />
          ) : (
            <DetailsCell.Content color="foreground">{total}</DetailsCell.Content>
          )}
        </TotalCell>
      </TableWrapper>
    </>
  )
}

export default Summary
