import {
  Archive,
  Check,
  Cross,
  IconComponentType,
  Reverted,
  SandWatch,
} from '@revolut/icons'
import { Button, Color, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import React, { useEffect } from 'react'
import { LocalStorageKeys } from '@src/store/auth/types'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

export const mapAccountStatusToProps = (
  status?: Statuses,
): {
  statusText: string
  statusIcon: IconComponentType
  color: Color
} => {
  switch (status) {
    case 'no_account':
      return {
        statusText: 'No account',
        statusIcon: Reverted,
        color: Color.GREY_TONE_50,
      }
    case 'pending':
      return {
        statusText: 'Pending action',
        statusIcon: SandWatch,
        color: Color.ORANGE,
      }
    case 'linked':
      return {
        statusText: 'Linked',
        statusIcon: Check,
        color: Color.GREEN,
      }
    case 'failed':
      return {
        statusText: 'Failed',
        statusIcon: Cross,
        color: Color.RED,
      }
    case 'archived':
      return {
        statusText: 'Archived',
        statusIcon: Archive,
        color: Color.GREY_TONE_50,
      }
    default:
      return {
        statusText: 'No account',
        statusIcon: Reverted,
        color: Color.GREY_TONE_50,
      }
  }
}

export const useShowGooglePermissionsError = (label: string) => {
  const statusPopup = useStatusPopup()

  useEffect(() => {
    const error = workspaceLocalStorage.getItem(LocalStorageKeys.GOOGLE_PERMISSIONS_ERROR)

    if (error) {
      workspaceLocalStorage.removeItem(LocalStorageKeys.GOOGLE_PERMISSIONS_ERROR)

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Could not link {label} account</StatusPopup.Title>
          {error && <StatusPopup.Description>{error}</StatusPopup.Description>}
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>Close</Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }
  }, [])
}
