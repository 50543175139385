import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { Employees } from '@src/pages/Settings/Employees/Employees'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { employeeSettings } from '@src/api/settings'

const routes = [
  {
    title: 'General',
    path: ROUTES.SETTINGS.EMPLOYEES.GENERAL,
    url: ROUTES.SETTINGS.EMPLOYEES.GENERAL,
    canView: [
      PermissionTypes.ViewEmployeePreferences,
      PermissionTypes.ChangeEmployeePreferences,
    ],
    component: Employees,
  },
]

export const EmployeeSettings = () => {
  return (
    <SettingsForm
      routes={routes}
      api={employeeSettings}
      multiInputFields={['allowed_email_domains']}
      title="Employee app settings"
      subtitle="All settings for employee work and personal profiles, employee groups and custom fields"
    />
  )
}
