import styled from 'styled-components'

import { Color, Space, themeColor, themeRadius, themeSpace } from '@revolut/ui-kit'

export const MONTH_CELL_HEIGHT = 48
export const MONTH_CELL_WIDTH = 32
export const MONTH_CELL_PADDING = 2

export const WEEK_CELL_HEIGHT = 56
export const WEEK_CELL_WIDTH = 140
export const WEEK_CELL_PADDING = 4

export const BORDER_WIDTH = 1

export const START_DATE_FILTER_KEY = 'start_date'
export const END_DATE_FILTER_KEY = 'end_date'
export const EMPLOYEE_STATUS_FILTER_KEY = 'employee_status'

export const DAYS_IN_WEEK = 7

export const AFTERNOON = 12

export const TooltipContainer = styled.div`
  background-color: ${themeColor(Color.FOREGROUND)};
  border-radius: ${themeRadius('tooltip')};
  padding: ${themeSpace(Space.S_8)};
  color: ${themeColor(Color.BACKGROUND)};
  opacity: 0.9;
  z-index: 999;
`

export enum TableCalendarTabs {
  Table = 'Table',
  Calendar = 'Calendar',
}

export enum WeekMonthTabs {
  Week = 'Week',
  Month = 'Month',
}
