import React, { Reducer, useEffect, useReducer, useState } from 'react'
import styled from 'styled-components'
import { CellTypes, FilterByInterface } from '@src/interfaces/data'
import { RadioSelect } from '@components/Inputs/RadioSelect/RadioSelect'
import {
  COMMENT_ACTIONS,
  CommentActionInterface,
  CommentStateInterface,
  DeliverableCriteriaType,
  DeliverableInterface,
  DeliverableQualityCriteria,
  DeliverableRating,
  DeliverablesAveragesInterface,
  DeliverableScorecardInterface,
  DeliverableSettingsInterface,
} from '@src/interfaces/deliverables'
import {
  changeScorecard,
  getDeliverablesAverageRatings,
  getDeliverablesSettings,
  postScorecard,
} from '@src/api/deliverables'
import { format } from 'date-fns'
import Icon, { Icons } from '@components/Icon/Icon'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import {
  CommentsPopupDataInterface,
  DeliverableOptions,
  ReviewCategory,
  ReviewInterface,
} from '@src/interfaces/performance'
import { EmployeeInterface } from '@src/interfaces/employees'
import { AxiosResponse } from 'axios'
import Tooltip from '@components/Tooltip/Tooltip'
import Drawer from '@components/Modals/Drawer/Drawer'
import { QualitySelector } from '@src/pages/EmployeeProfile/Preview/Performance/Deliverables/QualitySelector'
import { getIconKeyByIssue, ratingToColor } from '@src/utils/performance'
import {
  COMPLEXITY_OPTIONS,
  EXTENDED_QUALITY_OPTIONS,
  QUALITY_OPTIONS,
  SPEED_OPTIONS,
} from '@src/pages/EmployeeProfile/Preview/Performance/Deliverables/constants'
import CommentsPopup from '@components/ColumnInserts/Justification/CommentsPopup'
import CommentDialog from '@src/pages/EmployeeProfile/Preview/Performance/Deliverables/CommentDialog'
import { getProbationJiraTickets, getProbationTickets } from '@src/api/probationReview'
import { MatrixCellInsertParams } from '@components/Table/MatrixTable'
import { ProbationDeliverablesInterface } from '@src/interfaces/probationReview'
import AggregatedCell from '@src/pages/EmployeeProfile/Preview/Performance/Deliverables/AggregatedCell'
import Loader from '@components/CommonSC/Loader'
import Averages from '@src/pages/Forms/Probation/Averages'
import RefreshButton from '@src/pages/Forms/Probation/RefreshButton'
import { colorGetter, getColor } from '@src/styles/colors'
import { Bar, Button, Color, Text, Flex } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import HelpSidebar from '@src/pages/Forms/EmployeePerformance/components/HelpSidebar'
import ProbationDeliverablesHelp from '@src/pages/Forms/EmployeePerformance/components/HelpSections/ProbationDeliverablesHelp'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import { connect, useLape } from 'lape'
import produce from 'immer'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { InfoOutline } from '@revolut/icons'
import { FeatureFlags } from '@src/store/auth/types'
import AdjustableMatrixTable from '@components/Table/AdjustableMatrixTable'

const TableTitle = styled.div`
  padding-top: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const TableWrapper = styled.div`
  padding: 12px 0;
`

const Cell = styled.div<{ backgroundColor?: string | null }>`
  padding: 0 16px;
  background-color: ${props => props.backgroundColor};
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const JiraLink = styled.a`
  color: ${colorGetter(Color.BLUE)};
`

const RadioSelectStyled = styled(RadioSelect)<{ backgroundColor: string | null }>`
  padding: 0 16px;
  background-color: ${props => props.backgroundColor || 'inherit'};
  height: 49px;
`

const Key = styled(Cell)<{ ignored?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${props => (props.ignored ? 0.5 : 1)};
`

const IssueType = styled.div<{ ignored?: boolean }>`
  display: grid;
  justify-content: center;
  width: 100%;
  opacity: ${props => (props.ignored ? 0.5 : 1)};
`

const AddComment = styled.button`
  border: 0;
  outline: none;
  background-color: transparent;
  color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
  height: 49px;
  text-align: left;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }

  &:active {
    color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
  }
`

const ViewComment = styled.button`
  border: 0;
  outline: none;
  background-color: transparent;
  color: ${colorGetter(Color.BLUE)};
  height: 50px;
  text-align: left;
  width: 100%;
`

const ToggleButton = styled.button`
  margin-left: 6px;
  color: ${colorGetter(Color.BLUE)};

  &:hover {
    color: ${colorGetter(Color.BLUE_OPAQUE_60)};
  }
`

interface RowInterface {
  onChangeScorecard: (
    ticketId: number,
    type: string,
    value: DeliverableOptions | boolean,
    scorecard?: DeliverableScorecardInterface,
  ) => void
  user: EmployeeInterface
  onClickComment: (
    ticketId: number,
    scorecard?: DeliverableScorecardInterface,
    viewData?: CommentsPopupDataInterface,
    isView?: boolean,
  ) => void
  isViewMode: boolean
  isExtendedQuality: boolean
  onClickQuality: (
    deliverable: DeliverableInterface,
    scorecard?: DeliverableScorecardInterface,
    ratings?: DeliverableRating[],
    results?: DeliverableRating[],
  ) => void
  filter: FilterByInterface[]
  jiraEnabled: boolean
}

const ROW = ({
  onChangeScorecard,
  user,
  onClickComment,
  onClickQuality,
  isViewMode,
  isExtendedQuality,
  filter,
  jiraEnabled = true,
}: RowInterface) => ({
  cells: [
    ...(jiraEnabled
      ? [
          {
            type: CellTypes.insert,
            title: 'T',
            width: 48,
            insert: ({
              data: deliverable,
            }: MatrixCellInsertParams<DeliverableInterface>) => {
              const iconType = getIconKeyByIssue(deliverable.issue_type)
              return (
                <IssueType>
                  <Tooltip placement="top" text={deliverable.issue_type}>
                    <Icon type={iconType as Icons} />
                  </Tooltip>
                </IssueType>
              )
            },
          },
        ]
      : []),
    {
      type: CellTypes.insert,
      title: 'Goal',
      width: jiraEnabled ? 626 : 674,
      insert: ({ data: deliverable }: MatrixCellInsertParams<DeliverableInterface>) => {
        return jiraEnabled ? (
          <Key>
            <JiraLink href={deliverable.url} target="_blank" rel="noopener norefferer">
              {deliverable.key}
            </JiraLink>
            : {deliverable.title}
          </Key>
        ) : (
          <Flex justifyContent="space-between" p="0 24px 0 16px">
            <Text>{deliverable.title}</Text>
            {deliverable.description && (
              <Tooltip placement="top" text={deliverable.description}>
                <InfoOutline size={16} color="grey-tone-50" />
              </Tooltip>
            )}
          </Flex>
        )
      },
    },
    {
      type: CellTypes.insert,
      title: 'Due date',
      width: 110,
      insert: ({ data: deliverable }: MatrixCellInsertParams<DeliverableInterface>) => (
        <Cell>
          {deliverable.due_date
            ? format(new Date(deliverable.due_date), 'd MMM yyyy')
            : '-'}
        </Cell>
      ),
    },
    {
      type: CellTypes.insert,
      title: 'Complexity',
      width: 142,
      resizeWidth: 10,
      insert: ({
        data: deliverable,
        theme,
      }: MatrixCellInsertParams<DeliverableInterface>) => {
        const scorecard = deliverable.deliverable_scorecards.find(
          item => item.reviewer?.id === user.id,
        )

        if (isViewMode) {
          return (
            <AggregatedCell
              type={DeliverableCriteriaType.Complexity}
              deliverable={deliverable}
              filter={filter}
              scorecard={scorecard}
              ignored={false}
            />
          )
        }

        const value = COMPLEXITY_OPTIONS.find(
          option => option.id === scorecard?.complexity,
        ) || {
          id: '',
          name: '',
          text: '',
        }

        const tooltip = {
          tooltipTitle: value.name,
          tooltipText: value.text,
        }

        return (
          <RadioSelectStyled
            id={`select_complexity_score_${deliverable.id}`}
            options={COMPLEXITY_OPTIONS.map(({ summary, ...rest }) => rest)}
            onChange={option => {
              onChangeScorecard &&
                onChangeScorecard(
                  deliverable.id,
                  'complexity',
                  option.id as DeliverableOptions,
                  scorecard,
                )
            }}
            value={value}
            width={480}
            {...tooltip}
            backgroundColor={
              scorecard?.ignored
                ? getColor(theme, Color.GREY_TONE_2)
                : ratingToColor(theme, scorecard?.complexity)
            }
            disabled={deliverable.loading}
          />
        )
      },
    },
    {
      type: CellTypes.insert,
      title: 'Speed',
      width: 142,
      resizeWidth: 10,
      insert: ({
        data: deliverable,
        theme,
      }: MatrixCellInsertParams<DeliverableInterface>) => {
        const scorecard = deliverable.deliverable_scorecards.find(
          item => item.reviewer?.id === user.id,
        )

        if (isViewMode) {
          return (
            <AggregatedCell
              type={DeliverableCriteriaType.Speed}
              deliverable={deliverable}
              filter={filter}
              scorecard={scorecard}
              ignored={false}
            />
          )
        }

        const value = SPEED_OPTIONS.find(option => option.id === scorecard?.speed) || {
          id: '',
          name: '',
          text: '',
        }

        const tooltip = {
          tooltipTitle: value.name,
          tooltipText: value.text,
        }

        return (
          <RadioSelectStyled
            id={`select_score_speed__${deliverable.id}`}
            options={SPEED_OPTIONS.map(({ summary, ...rest }) => rest)}
            onChange={option => {
              onChangeScorecard &&
                onChangeScorecard(
                  deliverable.id,
                  'speed',
                  option.id as DeliverableOptions,
                  scorecard,
                )
            }}
            value={value}
            width={480}
            {...tooltip}
            backgroundColor={
              scorecard?.ignored
                ? getColor(theme, Color.GREY_TONE_2)
                : ratingToColor(theme, scorecard?.speed)
            }
            disabled={deliverable.loading}
          />
        )
      },
    },
    {
      type: CellTypes.insert,
      title: 'Quality',
      width: 142,
      resizeWidth: 10,
      insert: ({
        data: deliverable,
        theme,
      }: MatrixCellInsertParams<DeliverableInterface>) => {
        const scorecard = deliverable.deliverable_scorecards.find(
          item => item.reviewer?.id === user.id,
        )

        if (isViewMode) {
          return (
            <AggregatedCell
              type={DeliverableCriteriaType.Quality}
              deliverable={deliverable}
              filter={filter}
              scorecard={scorecard}
              ignored={false}
              onClickQuality={isExtendedQuality ? onClickQuality : undefined}
            />
          )
        }

        const options = isExtendedQuality
          ? EXTENDED_QUALITY_OPTIONS
          : QUALITY_OPTIONS.map(({ summary, ...rest }) => rest)
        const value = options.find(option => option.id === scorecard?.quality) || {
          id: '',
          name: '',
          text: '',
        }
        const tooltip = isExtendedQuality
          ? {}
          : {
              tooltipTitle: value.name,
              tooltipText: value.text,
            }

        return (
          <RadioSelectStyled
            id={`select_quality_score_${deliverable.id}`}
            options={options}
            onChange={option => {
              onChangeScorecard &&
                onChangeScorecard(
                  deliverable.id,
                  'quality',
                  option.id as DeliverableOptions,
                  scorecard,
                )
            }}
            value={value}
            width={480}
            {...tooltip}
            backgroundColor={
              scorecard?.ignored
                ? getColor(theme, Color.GREY_TONE_2)
                : ratingToColor(theme, scorecard?.quality)
            }
            onClick={
              isExtendedQuality
                ? () => {
                    onClickQuality(deliverable, scorecard)
                  }
                : undefined
            }
            disabled={deliverable.loading}
          />
        )
      },
    },
    {
      type: CellTypes.insert,
      title: 'Comments',
      width: 156,
      insert: ({ data: deliverable }: MatrixCellInsertParams<DeliverableInterface>) => {
        const scorecard = deliverable.deliverable_scorecards.find(
          item => item.reviewer?.id === user.id,
        )

        const data = {
          justifications: deliverable.deliverable_scorecards
            .filter(item => !item.is_aggregation && item.comment)
            .map(item => ({
              review: {
                reviewer: item.reviewer!,
                reviewer_relation: item.reviewer_relation,
              } as ReviewInterface,
              value: [
                {
                  comment: item.comment,
                  reference_url: null,
                },
              ],
            })),
        }

        if (isViewMode) {
          return data.justifications?.some(review => review.value[0].comment) ? (
            <Cell>
              <ViewComment
                type="button"
                onClick={() => {
                  onClickComment(deliverable.id, scorecard, data, true)
                }}
              >
                View
              </ViewComment>
            </Cell>
          ) : (
            <Cell>-</Cell>
          )
        }

        const locked = deliverable.filtered_aggregation?.locked

        return (
          <Cell>
            {scorecard?.comment ? (
              <ViewComment
                type="button"
                onClick={() => {
                  if (locked) {
                    onClickComment(deliverable.id, scorecard, data, true)
                  } else {
                    onClickComment(deliverable.id, scorecard)
                  }
                }}
                disabled={deliverable.loading}
              >
                View
              </ViewComment>
            ) : (
              <AddComment
                type="button"
                onClick={() => {
                  onClickComment(deliverable.id, scorecard)
                }}
                disabled={deliverable.loading}
              >
                Click to add
              </AddComment>
            )}
          </Cell>
        )
      },
    },
  ],
})

const commentReducer = (state: CommentStateInterface, action: CommentActionInterface) => {
  switch (action.type) {
    case COMMENT_ACTIONS.SET_VALUE:
      return { ...state, value: action.payload.value }

    case COMMENT_ACTIONS.SET_VIEW_DATA:
      return { ...state, viewData: action.payload.viewData }

    case COMMENT_ACTIONS.SET_OPEN:
      return {
        ...state,
        open: action.payload.open,
      }

    case COMMENT_ACTIONS.SET_VIEW_OPEN:
      return {
        ...state,
        viewOpen: action.payload.viewOpen,
      }

    case COMMENT_ACTIONS.SET_SCORECARD:
      return {
        ...state,
        scorecard: action.payload.scorecard,
        ticketId: action.payload.ticketId,
      }
    default:
      throw Error('Comment reducer: action is not defined')
  }
}

const tableTitle = {
  [ReviewCategory.Probation]: 'Probation Goals',
  [ReviewCategory.PIP_V2]: 'PIP Goals',
}

interface Props {
  isViewMode: boolean
}

const Deliverables = ({ isViewMode = false }: Props) => {
  const { query } = useQuery()
  const [loading, setLoading] = useState(true)
  const [isHelpOpen, setHelpOpen] = useState(false)
  const [otherTicketsVisible, setOtherTicketsVisible] = useState(false)
  const [averages, setAverages] = useState<DeliverablesAveragesInterface>()
  const [deliverablesSettings, setDeliverablesSettings] = useState<{
    settings: DeliverableSettingsInterface
    loaded: boolean
  }>({
    settings: {
      is_deliverable_review_enabled: false,
      deliverable_review_quality_criteria: null,
      permissions: {
        can_review: false,
      },
    },
    loaded: false,
  })

  // using lape state here to avoid side-effects in setDate in table hooks for multiple parallel requests
  const state = useLape<{
    data: ProbationDeliverablesInterface
  }>({
    data: {
      goals: [],
      others: [],
    },
  })

  const { values } = useLapeContext<DeliverableScorecardInterface>()
  const [comment, dispatchComment] = useReducer<
    Reducer<CommentStateInterface, CommentActionInterface>
  >(commentReducer, {
    open: false,
    viewOpen: false,
    value: '',
  })
  const [qualitySelectorData, setQualitySelectorData] = useState<{
    opened: boolean
    criteria: DeliverableQualityCriteria[]
    deliverable?: DeliverableInterface
    scorecard?: DeliverableScorecardInterface
    ratings?: DeliverableRating[]
    results?: DeliverableRating[]
  }>({
    opened: false,
    criteria: [],
  })

  const employeeId = isViewMode
    ? (values as any).reviews[0]?.reviewed_employee.id // FIXME: interface problemo?
    : values.reviewed_employee.id

  const category = isViewMode ? (values as any).reviews[0].category : values.category

  const { data: performanceSettings } = useGetPerformanceSettings()
  const featureFlags = useSelector(selectFeatureFlags)
  const pipJiraDisabled = featureFlags.includes(
    FeatureFlags.PipGoalsJiraIntegrationDisabled,
  )
  const jiraIntegrationEnabled =
    performanceSettings?.enable_probation_and_pip_goals_via_jira &&
    (category !== ReviewCategory.PIP_V2 || !pipJiraDisabled)

  const fetchDeliverablesSettings = async () => {
    try {
      const result = await getDeliverablesSettings(employeeId)
      setDeliverablesSettings({
        settings: result.data,
        loaded: true,
      })
    } catch (e) {
      setDeliverablesSettings(prevState => ({
        ...prevState,
        loaded: true,
      }))
    }
  }

  useEffect(() => {
    fetchDeliverablesSettings()
  }, [])

  const user = useSelector(selectUser)

  const filterCategory = category === ReviewCategory.PIP_V2 ? 'PIP' : category
  const cycleId = values.performance_checkpoint?.cycle_id

  const getTicketsFilters = () => {
    const filters = [
      {
        filters: [{ name: `${employeeId}`, id: `${employeeId}` }],
        columnName: 'employee__id',
      },
    ]
    const cycleProbationFilter = [
      {
        filters: [{ name: filterCategory, id: filterCategory }],
        columnName: 'category',
      },
    ]
    const cyclePipFilter = [
      {
        filters: [{ name: `E-${cycleId}`, id: `E-${cycleId}` }],
        columnName: 'review_cycle__id',
      },
    ]
    const additionalFilters =
      category === ReviewCategory.PIP_V2 && cycleId
        ? cyclePipFilter
        : cycleProbationFilter

    return [...filters, ...additionalFilters]
  }

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const { data } = await getProbationJiraTickets(
          category,
          employeeId,
          isViewMode
            ? query[Queries.CheckpointNumber]
            : values.probation_checkpoint?.number,
          isViewMode ? query[Queries.ReviewerId] : undefined,
        )

        let jiraConsideredGoals = data.goals ? [...data.goals] : []
        if (jiraIntegrationEnabled === false && employeeId) {
          const { data: goals } = await getProbationTickets(
            employeeId,
            getTicketsFilters(),
          )
          if (goals?.results.length) {
            jiraConsideredGoals = produce(goals?.results, draft => {
              draft.forEach(item => {
                item.title = item.name || ''
                item.due_date = item.deadline_date_time
              })
            })
          }
        }

        state.data = { ...data, goals: jiraConsideredGoals }
      } finally {
        setLoading(false)
      }
    }

    fetchTickets()
  }, [
    query[Queries.CheckpointNumber],
    query[Queries.ReviewerId],
    jiraIntegrationEnabled,
    employeeId,
  ])

  const fetchAverages = async () => {
    const filters = [
      {
        filters: [{ name: `${category}`, id: category }],
        columnName: 'category',
      },
      {
        filters: [
          {
            name: `${values.probation_checkpoint?.number}`,
            id: values.probation_checkpoint?.number!,
          },
        ],
        columnName: 'checkpoint_number',
      },
    ]

    const { data } = await getDeliverablesAverageRatings(employeeId, {
      filters,
    })
    setAverages(data)
  }

  useEffect(() => {
    if (isViewMode) {
      return
    }

    fetchAverages()
  }, [isViewMode])

  const setRowLoading = (ticketId: number, isLoading: boolean) => {
    Object.keys(state.data).forEach(key => {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      const item = state.data[key].find(
        (ticket: DeliverableInterface) => ticket.id === ticketId,
      )

      if (item) {
        item.loading = isLoading
      }
    })
  }

  const onChangeScorecard = async (
    ticketId: number,
    type: string,
    value?: DeliverableOptions | boolean | string | DeliverableQualityCriteria[],
    scorecard?: DeliverableScorecardInterface,
  ) => {
    if (!state.data) {
      return
    }

    let response: AxiosResponse<DeliverableScorecardInterface>

    if (scorecard) {
      response = (await changeScorecard(employeeId, scorecard.id, {
        [type]: value,
      })) as AxiosResponse<DeliverableScorecardInterface>
    } else {
      // set loading rows only for new scorecards, because we need id to be able to change it later
      setRowLoading(ticketId, true)
      const card = jiraIntegrationEnabled
        ? {
            functional_issue: ticketId,
            [type]: value,
            probation_checkpoint: { id: values.probation_checkpoint?.id },
          }
        : {
            employee_goal: { id: ticketId },
            [type]: value,
            probation_checkpoint: { id: values.probation_checkpoint?.id },
          }
      response = (await postScorecard(
        employeeId,
        card,
        filterCategory,
      )) as AxiosResponse<DeliverableScorecardInterface>
    }

    Object.keys(state.data).map(key => {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      state.data[key] = state.data[key].map((issue: DeliverableInterface) => {
        if (issue.id === ticketId) {
          const scorecardIndex = issue.deliverable_scorecards.findIndex(
            item => item.id === scorecard?.id,
          )

          if (scorecardIndex !== -1) {
            issue.deliverable_scorecards.splice(scorecardIndex, 1, response.data)
          } else {
            issue.deliverable_scorecards.push(response.data)
          }

          return issue
        }
        return issue
      })

      return undefined
    })

    setRowLoading(ticketId, false)

    setQualitySelectorData(prevState => ({
      ...prevState,
      scorecard: response.data,
    }))

    fetchAverages()
  }

  const onClickComment = (
    ticketId: number,
    scorecard?: DeliverableScorecardInterface,
    viewData?: CommentsPopupDataInterface,
    isView?: boolean,
  ) => {
    dispatchComment({
      type: COMMENT_ACTIONS.SET_SCORECARD,
      payload: {
        scorecard,
        ticketId,
      },
    })

    if (isView) {
      dispatchComment({
        type: COMMENT_ACTIONS.SET_VIEW_DATA,
        payload: {
          viewData,
        },
      })

      dispatchComment({
        type: COMMENT_ACTIONS.SET_VIEW_OPEN,
        payload: {
          viewOpen: true,
        },
      })
    } else {
      dispatchComment({
        type: COMMENT_ACTIONS.SET_VALUE,
        payload: {
          value: scorecard?.comment || '',
        },
      })

      dispatchComment({
        type: COMMENT_ACTIONS.SET_OPEN,
        payload: {
          open: true,
        },
      })
    }
  }

  const onClickQuality = (
    deliverable: DeliverableInterface,
    scorecard?: DeliverableScorecardInterface,
    ratings?: DeliverableRating[],
    results?: DeliverableRating[],
  ) => {
    setQualitySelectorData({
      opened: true,
      criteria: deliverablesSettings.settings.deliverable_review_quality_criteria!,
      deliverable,
      scorecard,
      ratings,
      results,
    })
  }

  const onCloseQualitySidebar = () => {
    setQualitySelectorData(prevState => ({
      ...prevState,
      opened: false,
    }))
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Container>
      {comment.viewData && (
        <CommentsPopup
          isOpen={!!comment.viewOpen}
          onClose={() => {
            dispatchComment({
              type: COMMENT_ACTIONS.SET_VIEW_OPEN,
              payload: {
                viewOpen: false,
              },
            })
          }}
          data={comment.viewData}
        />
      )}

      <CommentDialog
        open={!!comment.open}
        onClose={() => {
          dispatchComment({
            type: COMMENT_ACTIONS.SET_OPEN,
            payload: {
              open: false,
            },
          })
        }}
        onSubmit={value => {
          onChangeScorecard(comment.ticketId!, 'comment', value, comment.scorecard)
          dispatchComment({
            type: COMMENT_ACTIONS.SET_OPEN,
            payload: {
              open: false,
            },
          })
        }}
        value={comment.value}
      />
      <Bar>
        {jiraIntegrationEnabled && <RefreshButton employeeId={employeeId} />}
        {!isViewMode && (
          <Button variant="secondary" size="sm" onClick={() => setHelpOpen(!isHelpOpen)}>
            Help and instructions
          </Button>
        )}
      </Bar>

      {state.data && (
        <>
          {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
          <TableTitle>{`1. ${tableTitle[category]}`}</TableTitle>
          <TableWrapper>
            <AdjustableMatrixTable
              rows={ROW({
                user,
                onChangeScorecard,
                onClickComment,
                onClickQuality,
                isViewMode,
                isExtendedQuality:
                  !!deliverablesSettings.settings.deliverable_review_quality_criteria
                    ?.length,
                filter: [],
                jiraEnabled: jiraIntegrationEnabled || false,
              })}
              data={state.data.goals}
              noPadding
              autoResize
            />
          </TableWrapper>

          {!!state.data.others.length && (
            <>
              <TableTitle>
                2. Other tickets completed during probation (Optional to review)
                <ToggleButton
                  onClick={e => {
                    e.preventDefault()
                    setOtherTicketsVisible(!otherTicketsVisible)
                  }}
                >
                  {otherTicketsVisible ? 'Hide' : 'Show'}
                </ToggleButton>
              </TableTitle>
              {otherTicketsVisible && (
                <TableWrapper>
                  <AdjustableMatrixTable
                    rows={ROW({
                      user,
                      onChangeScorecard,
                      onClickComment,
                      onClickQuality,
                      isViewMode,
                      isExtendedQuality:
                        !!deliverablesSettings.settings
                          .deliverable_review_quality_criteria?.length,
                      filter: [],
                      jiraEnabled: jiraIntegrationEnabled || false,
                    })}
                    data={state.data.others}
                    noPadding
                    autoResize
                  />
                </TableWrapper>
              )}
            </>
          )}
        </>
      )}

      {averages && <Averages data={averages} />}

      <Drawer
        open={qualitySelectorData.opened}
        onClose={onCloseQualitySidebar}
        direction="right"
      >
        <QualitySelector
          deliverable={qualitySelectorData.deliverable}
          scorecard={qualitySelectorData.scorecard}
          criteria={qualitySelectorData.criteria}
          ratings={qualitySelectorData.ratings}
          results={qualitySelectorData.results}
          onClose={onCloseQualitySidebar}
          onChangeScorecard={onChangeScorecard}
          isViewMode={isViewMode}
          jiraEnabled={jiraIntegrationEnabled}
        />
      </Drawer>

      <HelpSidebar
        isOpen={isHelpOpen}
        helpSection={<ProbationDeliverablesHelp />}
        onExit={() => setHelpOpen(false)}
      />
    </Container>
  )
}

export default connect(Deliverables)
