import { useEffect, useState } from 'react'
import {
  CellTypes,
  ColumnCellInterface,
  RowInterface,
  SortByInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import {
  ReportColumnsTableInterface,
  ReportInterface,
  QueryColumnInterface,
} from '@src/interfaces/dataAnalytics'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'

export interface ReorderSettingsInterface {
  hidden: ReportColumnsTableInterface[]
  visible: ReportColumnsTableInterface[]
}

export interface SortSettingsInterface {
  hidden: SortByInterface[]
  visible: SortByInterface[]
}

export const orderingToSort = (ordering: string[]): SortByInterface[] => {
  return ordering.map(val => {
    const desc = val[0] === '-'

    if (desc) {
      return {
        sortBy: val.slice(1),
        direction: SORT_DIRECTION.DESC,
      }
    }

    return {
      sortBy: val,
      direction: SORT_DIRECTION.ASC,
    }
  })
}

const sortToOrdering = (sort: SortByInterface[]): string[] => {
  return sort.map(val =>
    val.direction === SORT_DIRECTION.DESC ? `-${val.sortBy}` : val.sortBy,
  )
}

export const useTableSettings = () => {
  const { values } = useLapeContext<ReportInterface>()

  const allVisibleColumnsReorder: ReorderSettingsInterface = {
    visible: values.query.columns.map(column => ({ name: column.name, alias: null })),
    hidden: [],
  }

  const initialReorderSettings = values.output_format.query_column_mappings
    ? {
        visible: values.output_format.query_column_mappings,
        hidden: allVisibleColumnsReorder.visible.filter(
          column =>
            !values.output_format.query_column_mappings.find(
              vCol => vCol.name === column.name,
            ),
        ),
      }
    : allVisibleColumnsReorder

  let initialSortSettings: SortSettingsInterface = {
    visible: [],
    hidden: initialReorderSettings.visible.map(column => ({
      sortBy: column.name,
      direction: SORT_DIRECTION.DESC,
    })),
  }

  if (values.output_format.ordering) {
    const visibleColumnsSort = orderingToSort(values.output_format.ordering).filter(
      vCol => initialReorderSettings.visible.find(column => column.name === vCol.sortBy),
    )

    initialSortSettings = {
      visible: visibleColumnsSort,
      hidden: initialReorderSettings.visible
        .filter(column => !visibleColumnsSort.find(vCol => vCol.sortBy === column.name))
        .map(col => ({
          sortBy: col.name,
          direction: SORT_DIRECTION.DESC,
        })),
    }
  }

  const [reorderSettings, setReorderSettings] =
    useState<ReorderSettingsInterface>(initialReorderSettings)
  const [sortSettings, setSortSettings] =
    useState<SortSettingsInterface>(initialSortSettings)
  const [tableRows, setTableRows] = useState<RowInterface<QueryColumnInterface>>({
    cells: [],
  })

  useEffect(() => {
    values.output_format.query_column_mappings = [...reorderSettings.visible]

    setSortSettings(current => {
      const visibleColumns = current.visible.filter(vCol =>
        reorderSettings.visible.find(column => column.name === vCol.sortBy),
      )

      return {
        visible: visibleColumns,
        hidden: reorderSettings.visible
          .filter(column => !visibleColumns.find(vCol => vCol.sortBy === column.name))
          .map(col => ({
            sortBy: col.name,
            direction: SORT_DIRECTION.DESC,
          })),
      }
    })

    setTableRows({
      cells: reorderSettings.visible.map(
        key =>
          ({
            type: CellTypes.text,
            idPoint: key.name,
            dataPoint: key.name,
            sortKey: key.name,
            filterKey: null,
            selectorsKey: selectorKeys.none,
            title: key.alias || key.name,
            width: 300,
          } as ColumnCellInterface<QueryColumnInterface>),
      ),
    })
  }, [reorderSettings])

  useEffect(() => {
    values.output_format.ordering = sortToOrdering(sortSettings.visible)
  }, [sortSettings])

  return {
    reorderSettings,
    setReorderSettings,
    sortSettings,
    setSortSettings,
    tableRows,
    setTableRows,
  }
}
