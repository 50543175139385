import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { APPLICATIONS } from '@src/constants/hub'
import { ROUTES } from '@src/constants/routes'
import HubApp from '@src/features/HubApp/HubApp'
import { Paygroup } from '@src/apps/People/Payroll/Paygroup'
import { PayCycle } from '@src/apps/People/Payroll/PayCycle'
import { ReconcilePaymentsTable } from './PaymentsTable/ReconcilePaymentsTable'
import { DeletePaymentsTable } from './PaymentsTable/DeletePaymentsTable'

const Payroll = () => {
  return (
    <Switch>
      <Route path={ROUTES.APPS.PAYROLL.PAY_CYCLE.ANY}>
        <PayCycle />
      </Route>
      <Route path={ROUTES.APPS.PAYROLL.PAY_GROUP.ANY}>
        <Paygroup />
      </Route>
      {/* <Route path={ROUTES.APPS.PAYROLL.SCHEDULE_PAYMENT}>
        <PayChargesTable />
      </Route> */}
      <Route path={ROUTES.APPS.PAYROLL.RECONCILE_PAYMENT}>
        <ReconcilePaymentsTable />
      </Route>
      <Route path={ROUTES.APPS.PAYROLL.BULK_DELETE_PAYMENTS}>
        <DeletePaymentsTable />
      </Route>
      <Route path={ROUTES.APPS.PAYROLL.ANY}>
        <HubApp app={APPLICATIONS.payroll} />
      </Route>
    </Switch>
  )
}

export default Payroll
