import React, { FC } from 'react'
import {
  Button,
  Text,
  Image,
  Box,
  H3,
  VStack,
  HStack,
  Flex,
  Grid,
  H4,
  H2,
} from '@revolut/ui-kit'
import * as Icons from '@revolut/icons'
import { useSelector } from 'react-redux'

import { STEPS, BENEFITS_1, BENEFITS_2, SD_LINK } from './constants'
import { MainPage, MainContainer, GlobalStyle } from './styles'
import bg from '@src/assets/revbets/bg.png'
import logo from '@src/assets/revbets/logo.svg'
import { selectAuth } from '@src/store/auth/selectors'
import { ROUTES } from '@src/constants/routes'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const SPACE_MD = '120px'
const SPACE_XL = '240px'

const Page: FC = ({ children, ...restProps }) => (
  <MainPage>
    <MainContainer p={['s-32', 's-32', 's-64']} {...restProps}>
      {children}
    </MainContainer>
  </MainPage>
)

const BenefitBox: FC<{ image: string; description: string }> = ({
  image,
  description,
}) => {
  return (
    <Flex
      p="s-32"
      style={{
        borderColor: '#ABB2E8',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '19px',
      }}
      flexDirection="column"
      justifyContent="center"
    >
      <Flex mb="s-12" width="s-32" mx="auto">
        <Image width="100%" height="auto" src={image} />
      </Flex>
      <Text use="p" textAlign="center" variant="primary" color="#D4CFE4">
        {description}
      </Text>
    </Flex>
  )
}

export const RevbetsPage: FC = () => {
  const auth = useSelector(selectAuth)

  const isAuthenticated = auth.authenticated

  if (!isAuthenticated) {
    return <InternalRedirect to={ROUTES.LOGIN.MAIN} />
  }

  return (
    <>
      <GlobalStyle />
      <Page>
        <Box minHeight="100vh">
          <Box mb={['80px', '120px', '180px']}>
            <Image width="80" src={logo} />
          </Box>

          <Box>
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems={['center', 'center', 'flex-start']}
            >
              <Text
                mb="s-16"
                variant="h0"
                use="h1"
                fontSize={['2.75rem', '2.75rem', '3.5rem']}
              >
                Unleash <br /> Your Inner <br /> Entrepreneur
              </Text>
              <Box mb="s-72">
                <H4>Dream it. Pitch it. Build it.</H4>
              </Box>
              <Box>
                <Button
                  useEndIcon={Icons.ArrowThinRight}
                  use="a"
                  href={SD_LINK}
                  target="_blank"
                  size="md"
                  width="14rem"
                  bg="#402EA1"
                  radius="round"
                  maxWidth="32rem"
                  mt="4rem"
                >
                  Submit your idea
                </Button>
              </Box>
            </Flex>
            <Box
              hide="*-md"
              width="50%"
              height="100vh"
              style={{
                background: `url(${bg}) no-repeat`,
                backgroundSize: 'cover',
                position: 'absolute',
                top: '-4rem',
                right: '-2rem',
              }}
            />
          </Box>
        </Box>

        <Box
          mb={SPACE_XL}
          maxWidth={['100%', '100%', '80%']}
          mx="auto"
          mt={['90px', '90px', '-180px']}
        >
          <Box mb={SPACE_MD}>
            <Text
              mb="s-24"
              textAlign="center"
              variant="h0"
              use="p"
              fontSize={['2.75rem', '2.75rem', '3.5rem']}
            >
              5 steps from idea to launch
            </Text>
            <Box>
              <H3
                textAlign="center"
                use="p"
                color="background"
                fontSize={['1.25rem', '1.25rem', '2rem']}
              >
                CEO Office and Product Strategy will help you validate your idea, assemble
                a team, build the product and bring your vision to life
              </H3>
            </Box>
          </Box>

          <Flex maxWidth={['100%', '100%', '80%']} mx="auto">
            {/* @ts-ignore */}
            <VStack space={SPACE_MD}>
              {STEPS.map(({ image, title, description: Description }, idx) => (
                <HStack space="s-64" key={title} align="center">
                  <Box width={['auto', 'auto', '40%']}>
                    <Image src={image} width="100%" height="auto" hide="*-md" />
                    <Text hide="md-*" use="p" fontSize="2rem">
                      {idx + 1}.
                    </Text>
                  </Box>
                  <Box width={['auto%', 'auto', '60%']}>
                    <H2 mb="s-4" use="p" fontSize={['1.25rem', '1.25rem', '2rem']}>
                      {title}
                    </H2>
                    <Description />
                  </Box>
                </HStack>
              ))}
            </VStack>
          </Flex>
        </Box>

        <Box mb={SPACE_XL} maxWidth={['100%', '100%', '80%']} mx="auto">
          <Box mb="s-72">
            <Text
              use="p"
              variant="h0"
              textAlign="center"
              fontSize={['2.75rem', '2.75rem', '3.5rem']}
            >
              What’s in it for me?
            </Text>
          </Box>

          <Grid gap="s-32" columns={{ all: 1, md: 3 }} rows="2" mb="s-32">
            {BENEFITS_1.map((benefit, idx) => (
              <BenefitBox
                key={idx}
                image={benefit.image}
                description={benefit.description}
              />
            ))}
          </Grid>

          <Grid
            gap="s-32"
            columns={{ all: 1, md: '33% 33%' }}
            rows="2"
            placeContent="center"
          >
            {BENEFITS_2.map((benefit, idx) => (
              <BenefitBox
                key={idx}
                image={benefit.image}
                description={benefit.description}
              />
            ))}
          </Grid>
        </Box>

        <Flex pb={SPACE_MD} flexDirection="column" alignItems="center">
          <Box mb="s-48">
            <Button
              useEndIcon={Icons.ArrowThinRight}
              use="a"
              href={SD_LINK}
              target="_blank"
              variant="bar"
              bg="#402EA1"
              maxWidth="32rem"
            >
              Submit your idea
            </Button>
          </Box>

          <Text use="p" color="#D2D0DD" variant="primary">
            All employees regardless of their role or seniority are encouraged to
            participate.
          </Text>
        </Flex>
      </Page>
    </>
  )
}
