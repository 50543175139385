import React, { useContext } from 'react'
import { Widget, Color, Flex, Skeleton, Text } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'
import { ResponsiveBar } from '@nivo/bar'
import { useTheme } from '@src/styles/theme'
import { BarChart } from '@revolut/icons'
import { useGetCandidatesPipelineView } from '@src/api/recruitment/candidates'
import { ChartInsideTooltipContext } from '@components/Charts/ChartInsideTooltip/ChartInsideTooltip'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

type Props = {
  id?: number
  isOwnPipelines?: boolean
}

const HiringPipelineChartContent = ({ id, isOwnPipelines }: Props) => {
  const user = useSelector(selectUser)
  const theme = useTheme()
  const chartContext = useContext(ChartInsideTooltipContext)
  const filters = []

  if (id) {
    filters.push({
      columnName: 'active_hiring_pipeline',
      filters: [{ id, name: String(id) }],
    })
  }

  if (isOwnPipelines) {
    filters.push({
      columnName: 'recruiter',
      filters: [{ id: user.id, name: String(user.id) }],
    })
  }
  const { data, isLoading } = useGetCandidatesPipelineView(
    filters.length ? filters : null,
    20000,
  )

  if (isLoading) {
    return (
      <Widget p="s-16" height="100%">
        <Skeleton height={32} mb="s-32" />
        <Skeleton height="calc(100% - 70px)" radius={'card'} />
      </Widget>
    )
  }

  return (
    <Widget p="s-16" height="100%">
      <Text fontWeight={500} fontSize="small" pt="s-16" use="div">
        Number of candidates in pipeline
      </Text>
      <Flex
        height="calc(100% - 50px)"
        onClick={chartContext?.onChartClick}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {data ? (
          <ResponsiveBar
            data={data}
            keys={['count']}
            indexBy="label"
            enableLabel
            labelFormat={
              labelValue =>
                (
                  <tspan y={-8} fill={getColor(theme, Color.GREY_TONE_50)}>
                    {labelValue}
                  </tspan>
                ) as any as string // hack: nivo doesn't support labels above the bars
            }
            margin={{ top: 20, right: 40, bottom: 85, left: 0 }}
            padding={0.85}
            colors={[getColor(theme, Color.BLUE)]}
            theme={{
              axis: {
                ticks: {
                  line: {
                    stroke: getColor(theme, Color.GREY_TONE_50),
                  },
                  text: {
                    fill: getColor(theme, Color.GREY_TONE_50),
                  },
                },
              },
              grid: {
                line: {
                  strokeWidth: 0.5,
                },
              },
              tooltip: {
                container: {
                  background: '#333',
                  padding: '10px 15px',
                  borderRadius: 4,
                },
              },
            }}
            axisTop={null}
            axisLeft={null}
            axisBottom={{
              tickPadding: 8,
              tickSize: 0,
              tickRotation: 50,
            }}
            axisRight={{
              tickValues: 5,
              tickSize: 0,
              tickPadding: 8,
              tickRotation: 0,
              format: (d: any) => parseFloat(d as string),
            }}
            gridYValues={5}
            tooltip={({ value }: any) => <Text color="background">{value}</Text>}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'top-right',
                direction: 'column',
                justify: false,
                translateX: 190,
                translateY: 0,
                itemsSpacing: 10,
                itemWidth: 100,
                itemHeight: 15,
                itemTextColor: getColor(theme, Color.GREY_TONE_50),
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 12,
                symbolShape: 'circle',
              },
            ]}
            animate={false}
            motionStiffness={90}
            motionDamping={15}
          />
        ) : (
          <>
            <BarChart color="grey-tone-50" />
            <Text color="grey-tone-50">No data to display</Text>
          </>
        )}
      </Flex>
    </Widget>
  )
}

export default HiringPipelineChartContent
