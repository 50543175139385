import React from 'react'
import { DetailsCell, Group, Text, textChain, Token } from '@revolut/ui-kit'
import { CareersApplicationWorkInterface } from '@src/interfaces/careers'
import { formatDistanceStrict } from 'date-fns'
import CollapsibleSubheader from '@components/CollapsibleSubheader/CollapsibleSubheader'
import { formatExperienceDateRange } from '@src/utils/hiring'

type Props = {
  data?: CareersApplicationWorkInterface[]
  years?: number | null
}

const WorkExperienceInfo = ({ data, years }: Props) => {
  if (!data?.length) {
    return null
  }

  return (
    <CollapsibleSubheader title="Work experience" openByDefault>
      <Group>
        <DetailsCell>
          <DetailsCell.Title>Years of experience</DetailsCell.Title>
          <DetailsCell.Content>
            {Number.isFinite(years) ? `${years} y` : '-'}
          </DetailsCell.Content>
          <DetailsCell.Note>
            {data.map((item, i) => (
              <Text
                display="block"
                color={item.end_date ? Token.color.greyTone50 : undefined}
                key={i}
              >
                {textChain(
                  item.company?.name || item.other_company_name,
                  item.position?.name || item.other_position_title,
                  formatExperienceDateRange(item.start_date, item.end_date),
                  formatDistanceStrict(
                    new Date(item.start_date),
                    item.end_date ? new Date(item.end_date) : new Date(),
                  ),
                )}
              </Text>
            ))}
          </DetailsCell.Note>
        </DetailsCell>
      </Group>
    </CollapsibleSubheader>
  )
}

export default WorkExperienceInfo
