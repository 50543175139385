import React from 'react'
import { Action, Color } from '@revolut/ui-kit'
import { ArrowThinDown, ArrowThinUp } from '@revolut/icons'
import { InterviewScorecardTemplateSectionInterface } from '@src/interfaces/interviewScorecardTemplates'
import { ApplicationFormSectionInterface } from '@src/interfaces/applicationForm'
import { Grid } from '@components/CommonSC/Grid'

type Props = {
  id: number
  onDelete?: () => void
  onEdit?: () => void
  onMove?: (id: number, direction: Direction) => void
  onCopy?: () => void
  isView?: boolean
  sections?:
    | InterviewScorecardTemplateSectionInterface[]
    | ApplicationFormSectionInterface[]
  disableDelete?: boolean
  disableEdit?: boolean
  disableCopy?: boolean
}

export type Direction = 'up' | 'down'

const ScorecardSectionControls = ({
  id,
  onDelete,
  onEdit,
  onMove,
  isView = false,
  onCopy,
  sections,
  disableDelete = false,
  disableEdit = false,
  disableCopy = false,
}: Props) => {
  const handleMove = (index: number, direction: Direction) => {
    const offset = direction === 'down' ? 1 : -1

    if (sections?.[index] && sections?.[index + offset]) {
      ;[sections[index], sections[index + offset]] = [
        sections[index + offset],
        sections[index],
      ]
    }

    onMove?.(id, direction)
  }

  return (
    <Grid gap={8} flow="column" pl="s-8" alignItems="center">
      {onCopy && (
        <Action
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            onCopy()
          }}
          disabled={disableCopy}
        >
          Copy
        </Action>
      )}
      {isView && (
        <Action
          onClick={e => {
            if (onEdit) {
              e.preventDefault()
              e.stopPropagation()
              onEdit()
            }
          }}
          disabled={disableEdit}
        >
          Edit
        </Action>
      )}
      <Action
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onDelete?.()
        }}
        disabled={disableDelete}
      >
        Delete
      </Action>
      {id > 0 && (
        <Action
          useIcon={ArrowThinUp}
          aria-label="Up"
          color={Color.BLUE}
          onClick={e => {
            e.stopPropagation()
            handleMove(id, 'up')
          }}
          disabled={disableEdit}
        />
      )}
      {id + 1 < (sections?.length || 0) && (
        <Action
          useIcon={ArrowThinDown}
          aria-label="Down"
          color={Color.BLUE}
          onClick={e => {
            e.stopPropagation()
            handleMove(id, 'down')
          }}
          disabled={disableEdit}
        />
      )}
    </Grid>
  )
}

export default ScorecardSectionControls
