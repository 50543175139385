import React from 'react'
import { Text, Flex, Color } from '@revolut/ui-kit'
import { ExclamationMarkOutline } from '@revolut/icons'
import { startCase } from 'lodash'
import { isValid as isValidDate } from 'date-fns'

import Tooltip from '@src/components/Tooltip/Tooltip'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { ImportBonusesInterface } from '@src/interfaces/importBonuses'
import { formatDate, formatNumber } from '@src/utils/format'
import { CompensationBonusInterface } from '@src/interfaces/compensation'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'

interface CellWithErrorProps {
  data: ImportBonusesInterface
  prop: keyof Omit<ImportBonusesInterface, 'errors'>
}

const CellWithError: React.FC<CellWithErrorProps> = ({ prop, data, children }) => {
  const errors = data.errors?.[prop]
  const fallbackValue = data[prop]
  const hasError = !!errors?.length

  return (
    <Flex>
      <Tooltip text={errors?.join('\n')} placement="top" hide={!hasError} noArrow>
        <Flex gap="s-8" justifyContent="flex-start">
          <Text color={hasError ? Color.RED : Color.FOREGROUND}>
            {children || fallbackValue}
          </Text>
          {hasError ? <ExclamationMarkOutline color={Color.RED} size={16} /> : null}
        </Flex>
      </Tooltip>
    </Flex>
  )
}

export const importBonusesCategoryColumn: ColumnInterface<ImportBonusesInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Category',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="bonus_category">
        {startCase(data.bonus_category)}
      </CellWithError>
    )
  },
}

export const importBonusesEmployeeColumn: ColumnInterface<ImportBonusesInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Employee',
  insert: ({ data }) => (
    <CellWithError data={data} prop="employee_id">
      {data.employee_id && data.employee_full_name ? (
        <UserWithAvatar
          id={data.employee_id}
          name={data.employee_full_name}
          avatar={data.employee_avatar}
        />
      ) : null}
    </CellWithError>
  ),
}

export const importBonusesPeriodColumn: ColumnInterface<ImportBonusesInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Period',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="bonus_cycle">
        {data.bonus_cycle}
      </CellWithError>
    )
  },
}

export const importBonusesAmountColumn: ColumnInterface<ImportBonusesInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Value',
  insert: ({ data }) => {
    const isValidNumber = !isNaN(Number(data.bonus_amount))
    return (
      <CellWithError data={data} prop="bonus_amount">
        {isValidNumber ? formatNumber(Number(data.bonus_amount)) : data.bonus_amount}
      </CellWithError>
    )
  },
}

export const importBonusesCurrencyColumn: ColumnInterface<ImportBonusesInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Currency',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="bonus_currency_iso_code">
        {data.bonus_currency_iso_code}
      </CellWithError>
    )
  },
}

export const importBonusesTaxColumn: ColumnInterface<ImportBonusesInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Tax',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="taxability">
        {startCase(data.taxability)}
      </CellWithError>
    )
  },
}

export const importBonusesTypeColumn: ColumnInterface<ImportBonusesInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bonus type',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="bonus_type">
        {startCase(data.bonus_type)}
      </CellWithError>
    )
  },
}

export const importBonusesDateColumn: ColumnInterface<ImportBonusesInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Date',
  insert: ({ data }) => {
    const isValid = isValidDate(new Date(data.bonus_date))
    return (
      <CellWithError data={data} prop="bonus_date">
        {isValid ? formatDate(data.bonus_date) : data.bonus_date}
      </CellWithError>
    )
  },
}

export const importBonusesDetailsColumn: ColumnInterface<ImportBonusesInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Details',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="details">
        {data.details}
      </CellWithError>
    )
  },
}

export const importBonusesActionColumn: ColumnInterface<ImportBonusesInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="action">
        {startCase(data.action)}
      </CellWithError>
    )
  },
}

export const compensationBonusesEmployeeColumn: ColumnInterface<CompensationBonusInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: 'employee__full_name',
    filterKey: 'employee__id',
    selectorsKey: selectorKeys.employee,
    title: 'Employee',
    insert: ({ data }) => <UserWithAvatar {...data.employee} />,
  }

export const compensationBonusesDetailsColumn: ColumnInterface<CompensationBonusInterface> =
  {
    type: CellTypes.text,
    idPoint: 'details',
    dataPoint: 'details',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Details',
  }
