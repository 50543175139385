import React from 'react'
import ApplicationFormWidgetSection, {
  ApplicationFormWidgetSectionInterface,
} from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormWidgetSection'
import { Box, Color, InputGroup, Text } from '@revolut/ui-kit'
import RightToWorkInput from '@src/pages/Settings/HiringProcess/components/RightToWorkInputField'
import GeneralQuestion from '@src/pages/Settings/HiringProcess/components/GeneralQuestionField'

type FormErrors = {
  right_to_work?: string | number
  is_eligible_to_relocate?: boolean | null
  is_ready_to_relocate?: boolean | null
}

interface Props extends Partial<ApplicationFormWidgetSectionInterface> {
  disabled?: boolean
  errors?: FormErrors
  isEligibleToRelocate?: boolean | null
  isReadyToRelocate?: boolean | null
  optional?: boolean
  rightToWork?: string | number
  onChangeIsEligibleToRelocate?: (v: boolean | null) => void
  onChangeIsReadyToRelocate?: (v: boolean | null) => void
  onChangeRightToWork?: (v: string | number) => void
}

const RightToWorkQuestionSection = ({
  disabled,
  errors,
  isEligibleToRelocate,
  isReadyToRelocate,
  locked,
  optional,
  rightToWork,
  onChangeIsEligibleToRelocate,
  onChangeIsReadyToRelocate,
  onChangeRightToWork,
  ...props
}: Props) => {
  return (
    <ApplicationFormWidgetSection
      titleIndexNumber={3}
      title="Right to work"
      description="Current candidate right to work in the current location"
      locked={locked}
      {...props}
    >
      <InputGroup>
        <RightToWorkInput
          disabled={disabled || locked}
          error={errors?.right_to_work}
          name="right_to_work"
          optional={optional}
          rightToWork={rightToWork}
          onChangeRightToWork={v => {
            onChangeRightToWork?.(v)
          }}
        />
        <Box>
          <Text variant="secondary" color={Color.GREY_TONE_50}>
            Eligible to relocate
          </Text>
        </Box>
        <GeneralQuestion
          disabled={locked || disabled}
          error={errors?.is_eligible_to_relocate}
          name="is_eligible_to_relocate"
          value={isEligibleToRelocate}
          onChange={value => {
            onChangeIsEligibleToRelocate?.(value)
          }}
        />
        <Box>
          <Text variant="secondary" color={Color.GREY_TONE_50}>
            Ready to relocate
          </Text>
        </Box>
        <GeneralQuestion
          disabled={locked || disabled}
          error={errors?.is_ready_to_relocate}
          name="is_ready_to_relocate"
          value={isReadyToRelocate}
          onChange={value => {
            onChangeIsReadyToRelocate?.(value)
          }}
        />
      </InputGroup>
    </ApplicationFormWidgetSection>
  )
}

export default RightToWorkQuestionSection
