import * as React from 'react'
import styled from 'styled-components'
import Tooltip from '../../Tooltip/Tooltip'
import Icon from '../../Icon/Icon'
import { ReactNode } from 'react'
import { colorGetter } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

const InfoIcon = styled(Icon)`
  height: 14px;
  color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
  transition: 150ms all;
  &:hover {
    color: ${colorGetter(Color.GREY_TONE_50)};
  }
`

const InfoTooltip = styled(Tooltip)`
  position: initial;
  height: 14px;
`

interface Props {
  tooltipText?: string
  tooltipTitle?: string
  tooltipBody?: ReactNode
}

const Info = ({ tooltipText, tooltipTitle, tooltipBody }: Props) =>
  tooltipText || tooltipBody ? (
    <InfoTooltip
      body={tooltipBody}
      title={tooltipTitle}
      text={tooltipText}
      placement="right"
    >
      <InfoIcon type="Info" />
    </InfoTooltip>
  ) : null

export default Info
