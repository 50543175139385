import React, { FormEvent, useEffect, useState } from 'react'
import { EmployeeGoal } from '@src/interfaces/employees'
import { useAddGoal, useDeleteGoal, useGetGoals } from '@src/api/performance'
import Loader from '@components/CommonSC/Loader'
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  Text,
  TextArea,
  Grid,
} from '@revolut/ui-kit'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { formatDate } from '@src/utils/format'
import { isAfter, isSameDay } from 'date-fns'
import { Widget, VStack } from '@revolut/ui-kit'
import {
  DatePickerInput,
  DatePickerInputDisabledDays,
} from '@components/Inputs/DatePickerInput/DatePickerInput'
import { Delete } from '@revolut/icons'

interface GoalsProps {
  category: ReviewCycleCategory
  employeeId: number
  reviewCycleId?: number | string
  canManageGoals?: boolean
  reviewCycleEndDate?: string
  reviewCycleStartDate?: string
  onGoalsUpdated?: (goals: EmployeeGoal[]) => void
}

const GoalsJiraDisabled = ({
  category,
  employeeId,
  canManageGoals,
  reviewCycleId,
  reviewCycleEndDate,
  reviewCycleStartDate,
  onGoalsUpdated,
}: GoalsProps) => {
  const [goalTitle, setGoalTitle] = useState<string>('')
  const [goalDescription, setGoalDescription] = useState<string>('')
  const [goalDeadline, setGoalDeadline] = useState<Date | null>(null)

  const filters = [
    {
      filters: [{ name: `${employeeId}`, id: `${employeeId}` }],
      columnName: 'employee__id',
    },
    category !== ReviewCycleCategory.Probation
      ? {
          filters: [{ name: `${reviewCycleId}`, id: `${reviewCycleId}` }],
          columnName: 'review_cycle__id',
        }
      : {
          filters: [{ name: category, id: category }],
          columnName: 'category',
        },
  ]

  const { data: goals = [], isLoading } = useGetGoals(
    category && employeeId ? employeeId : null,
    filters,
  )

  const mutationDelete = useDeleteGoal(employeeId, filters, (oldData, id) => ({
    ...oldData,
    results: oldData.results.filter(item => item.id !== id),
  }))

  const mutationAdd = useAddGoal(employeeId, filters, (oldData, newData) => ({
    ...oldData,
    results: [...oldData.results, newData],
  }))

  const isMutationLoading = mutationDelete.isLoading || mutationAdd.isLoading

  let isCycleEnded = !(category && employeeId && reviewCycleEndDate)

  if (reviewCycleEndDate) {
    isCycleEnded =
      !goals.length &&
      isAfter(new Date(), new Date(reviewCycleEndDate)) &&
      !isSameDay(new Date(), new Date(reviewCycleEndDate))
  }

  useEffect(() => {
    onGoalsUpdated?.(goals)
  }, [goals])

  const handleSubmit = async () => {
    const data: EmployeeGoal = {
      name: goalTitle!,
      deadline_date_time: goalDeadline,
      employee: { id: `${employeeId}` },
      category: category as string,
      review_cycle: null,
      description: goalDescription,
    }

    if (category !== ReviewCycleCategory.Probation) {
      data.review_cycle = { id: `${reviewCycleId}` }
    }
    const result = await mutationAdd.mutateAsync(data)

    if (result?.data) {
      pushNotification({
        value: 'The goal was successfully saved.',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
      setGoalDeadline(null)
      setGoalTitle('')
      setGoalDescription('')
    }
  }

  const handleDeleteGoal = async (id: number) => {
    const result = await mutationDelete.mutateAsync(id)
    if (result) {
      pushNotification({
        value: 'The goal was successfully deleted.',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    }
  }

  if (isLoading) {
    return (
      <Flex mb="s-24" flexDirection="column" alignItems="flex-start">
        <Loader size="big" />
      </Flex>
    )
  }

  const getDisabledDays = () => {
    const disabledDays: { before?: Date; after?: Date } = {}
    if (reviewCycleStartDate) {
      disabledDays.before = new Date(reviewCycleStartDate)
    }
    if (reviewCycleEndDate) {
      disabledDays.after = new Date(reviewCycleEndDate)
    }
    return disabledDays as DatePickerInputDisabledDays
  }

  return (
    <Box>
      <Widget p="s-16">
        {goals.length ? (
          <VStack space="s-12">
            {goals.map(goal => (
              <Flex justifyContent="space-between" key={goal.id}>
                <Flex flexDirection="column" maxWidth="75%">
                  <Text variant="primary" fontWeight={500}>
                    {goal.name}
                  </Text>
                  {goal.description && (
                    <Text variant="caption" color="grey-tone-50">
                      {goal.description}
                    </Text>
                  )}
                </Flex>
                <Flex>
                  {goal.deadline_date_time && (
                    <Text variant="caption" color="grey-tone-50">
                      Due {formatDate(goal.deadline_date_time)}
                    </Text>
                  )}
                  {canManageGoals && (
                    <Box ml="s-16" mt="s-2">
                      <Delete
                        size={16}
                        onClick={() => handleDeleteGoal(goal.id!)}
                        cursor="pointer"
                        color="grey-tone-50"
                      />
                    </Box>
                  )}
                </Flex>
              </Flex>
            ))}
          </VStack>
        ) : (
          <Text use="div">No goals defined.</Text>
        )}
      </Widget>
      {canManageGoals && !isCycleEnded && (
        <Box mt="s-16">
          <InputGroup>
            <Grid columns="1fr 200px" gap="s-16">
              <Input
                value={goalTitle}
                placeholder="Enter SMART goal"
                onChange={(e: FormEvent<HTMLInputElement>) => {
                  setGoalTitle(e.currentTarget.value)
                }}
                disabled={isMutationLoading}
                onClear={() => setGoalTitle('')}
              />
              <DatePickerInput
                value={goalDeadline}
                disabledDays={getDisabledDays()}
                label="Due date"
                onChange={d => setGoalDeadline(d)}
                disabled={isMutationLoading}
                clearable
              />
            </Grid>
            <TextArea
              value={goalDescription}
              placeholder="How this goal will be measured"
              onChange={(e: FormEvent<HTMLTextAreaElement>) => {
                setGoalDescription(e.currentTarget.value)
              }}
              disabled={isMutationLoading}
              onClear={() => setGoalDescription('')}
              rows={2}
            />
          </InputGroup>
          <Button
            variant="secondary"
            pending={isMutationLoading}
            size="sm"
            mt="s-16"
            onClick={handleSubmit}
            disabled={!goalTitle || !goalDeadline}
          >
            Add goal
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default GoalsJiraDisabled
