import React from 'react'
import { InfoOutline } from '@revolut/icons'
import { MoreBarActionProps, ActionButton, MoreBar } from '@revolut/ui-kit'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

interface Props extends MoreBarActionProps {
  href: string
  children?: React.ReactNode
  useMoreBar?: boolean
}

const MoreInfoButton = ({ href, children, useMoreBar = true, ...props }: Props) => {
  const Component = useMoreBar ? MoreBar.Action : ActionButton
  return (
    <HideIfCommercial>
      {/* @ts-ignore */}
      <Component
        target="_blank"
        rel="noreferrer noopener"
        href={href}
        use="a"
        useIcon={InfoOutline}
        size="sm"
        variant="secondary"
        {...props}
      >
        {children || 'More Info'}
      </Component>
    </HideIfCommercial>
  )
}

export default MoreInfoButton
