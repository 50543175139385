import React, { useEffect, useState } from 'react'
import { PerformanceRating } from '@src/interfaces/performance'
import { Box, Color, Flex, RadioSelect, Text } from '@revolut/ui-kit'
import { ChevronDown, ChevronUp } from '@revolut/icons'

import { PerformanceRatingTitle } from '@src/constants/performance'

const options = Object.values(PerformanceRating)
  .filter(id => id !== PerformanceRating.skipped && id !== PerformanceRating.dont_know)
  .map(id => ({
    key: id,
    value: id,
    label: PerformanceRatingTitle[id],
  }))

interface Props {
  value?: PerformanceRating | null
  onChange: (rating?: PerformanceRating) => void
  readOnly?: boolean
}

type ShevronProps = {
  open: boolean
}
const Shevron = ({ open }: ShevronProps) => {
  return open ? <ChevronUp size={16} /> : <ChevronDown size={16} />
}

const PerformanceRatingSelect = ({ value, onChange, readOnly }: Props) => {
  const [selectedRating, setSelectedRating] = useState(value)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setSelectedRating(value)
  }, [value])

  const labelText = value ? PerformanceRatingTitle[value] : 'Select'

  return (
    <Box width="100%">
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => !readOnly && setOpen(!open)}
        color={value ? Color.FOREGROUND : Color.GREY_20_OPAQUE_90}
      >
        <Text>{labelText}</Text>
        {!readOnly && <Shevron open={open} />}
      </Flex>
      <RadioSelect<PerformanceRating>
        open={open}
        onClose={() => setOpen(false)}
        options={options}
        value={selectedRating}
        onChange={rating => {
          setSelectedRating(rating)
          onChange(rating || undefined)
        }}
        autoClose
        labelList="Ratings"
      />
    </Box>
  )
}

export default PerformanceRatingSelect
