import {
  Cell,
  Link,
  MoreBar,
  VStack,
  Text,
  Flex,
  Box,
  Table,
  Token,
  List,
  Icon,
  Fixed,
  Side,
  TextButton,
} from '@revolut/ui-kit'
import { PERFORMANCE_REVIEW_TOPICS } from '@src/constants/externalLinks'
import { useTheme } from '@src/styles/theme'
import React from 'react'
import { data, columns } from './example'

interface GradeExplainSidebarProps {
  open: boolean
  onClose: () => void
}
const GradeExplainSidebar = ({ open, onClose }: GradeExplainSidebarProps) => {
  const theme = useTheme()
  return open ? (
    <Fixed
      right={0}
      top={0}
      zIndex={theme.zIndex.sideBar}
      p="s-16"
      height="100vh"
      overflow="auto"
    >
      <Side open={open} onClose={onClose} variant="wide" height="100%">
        <VStack space="s-16">
          <TextButton aria-label="Close" onClick={onClose} color={Token.color.foreground}>
            <Icon name="Cross" size={24} />
          </TextButton>
          <Text variant="h1">How are grades calculated?</Text>
        </VStack>
        <VStack space="s-8" mt="s-32">
          <Cell backgroundColor={Token.color.widgetBackground}>
            <Text>
              You can find more detailed information on grades and all performance review
              topics within our playbooks{' '}
              <Link
                rel="noreferrer noopener"
                use="a"
                target="_blank"
                href={PERFORMANCE_REVIEW_TOPICS}
              >
                here
              </Link>
              .
            </Text>
          </Cell>
          <Cell>
            <VStack space="s-8">
              <Text variant="h5">The final grade</Text>
              <Text>
                The final grade shown for an employee is based on the inputs from both
                Head of Department (HoD) and Head of Function (HoF), according to a matrix
                of recommended grades.
                <br />
                <br />
                In places where inputs are missing from either HoD and/or HoF, the system
                recommendation is used in its place when evaluating the matrix.
              </Text>
              <Flex flexDirection="column">
                <Flex flexDirection="row">
                  <Box width="50px" />
                  <Box flex={1}>
                    <Text
                      variant="h6"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      HoD Input
                    </Text>
                  </Box>
                </Flex>
                <Flex flexDirection="row" mt="s-12">
                  <Text
                    variant="h6"
                    width="50px"
                    style={{ alignItems: 'center', display: 'flex' }}
                  >
                    HoF Input
                  </Text>
                  <Box flex={1}>
                    <Table rowHeight="extra-small" columns={columns} data={data} />
                  </Box>
                </Flex>
              </Flex>
            </VStack>
          </Cell>
          <Cell>
            <VStack space="s-8">
              <Text variant="h5">The system recommendation</Text>
              <Text use="p">
                The system recommendation is built from an average of 3 main inputs:
                <List variant="compact">
                  <List.Item useIcon={<Icon size="12px" name="Dot" />}>
                    The recommended grade from the line manager
                  </List.Item>
                  <List.Item useIcon={<Icon size="12px" name="Dot" />}>
                    The recommended grade from the functional manager
                  </List.Item>
                  <List.Item useIcon={<Icon size="12px" name="Dot" />}>
                    The calculated grade based on scorecard ratings
                    <List variant="compact">
                      <List.Item useIcon={<Icon size="12px" name="Dot" />}>
                        Determined by comparing the ratings given by managers for
                        deliverables and skills, with the expectation defined on the
                        competency matrix.
                      </List.Item>
                    </List>
                  </List.Item>
                </List>
                <br />
                The average (rounded down) of these 3 grades is the foundation of the
                system recommendation.
                <br />
                <br />
                By evaluating the manager responses to the bar raiser questions within the
                scorecard, a weighted-NIPS score is taken which can shift the
                recommendation up or down one grade.
              </Text>
            </VStack>
          </Cell>
        </VStack>
      </Side>
    </Fixed>
  ) : null
}

export interface ShowGradeExplainActionProps {
  open: boolean
  onOpen: () => void
  onClose: () => void
}

export const ShowGradeExplainAction = ({
  open,
  onClose,
  onOpen,
}: ShowGradeExplainActionProps) => {
  return (
    <>
      <MoreBar.Action onClick={onOpen} useIcon="16/QuestionOutline">
        How are grades calculated?
      </MoreBar.Action>
      <GradeExplainSidebar open={open} onClose={onClose} />
    </>
  )
}
