import React from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { KPITemplateInterface } from '@src/interfaces/KpiTemplates'
import { useTable } from '@components/Table/hooks'
import { kpiTemplateRequests } from '@src/api/kpiTemplate'
import { RowInterface } from '@src/interfaces/data'
import {
  kpiTemplateCountColumn,
  kpiTemplateEnforcedColumn,
  kpiTemplateNameColumn,
  kpiTypeColumn,
} from '@src/constants/columns/kpi'
import { updatedAtColumn } from '@src/constants/columns/dates'
import { ownerFullNameColumn } from '@src/constants/columns/employee'
import { Flex, MoreBar } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { Plus } from '@revolut/icons'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const Rows: RowInterface<KPITemplateInterface> = {
  linkToForm: d => navigateTo(pathToUrl(ROUTES.FORMS.KPI_TEMPLATE.GENERAL, { id: d.id })),
  cells: [
    {
      ...kpiTemplateNameColumn,
      width: 400,
    },
    {
      ...kpiTypeColumn,
      width: 200,
    },
    {
      ...ownerFullNameColumn,
      title: 'Owner',
      width: 200,
    },
    {
      ...updatedAtColumn,
      title: 'Updated',
      width: 200,
    },
    {
      ...kpiTemplateEnforcedColumn,
      width: 220,
    },
    {
      ...kpiTemplateCountColumn,
      width: 220,
    },
  ],
}

const Templates = () => {
  const table = useTable<KPITemplateInterface>(kpiTemplateRequests)

  return (
    <TableWrapper>
      <Flex mb="s-16">
        <MoreBar>
          <MoreBar.Action
            useIcon={Plus}
            onClick={() => {
              navigateTo(pathToUrl(ROUTES.FORMS.KPI_TEMPLATE.GENERAL))
            }}
          >
            Add New Template
          </MoreBar.Action>
        </MoreBar>
      </Flex>
      <AdjustableTable<KPITemplateInterface>
        name={TableNames.KpiTemplates}
        dataType="KPI template"
        useWindowScroll
        row={Rows}
        {...table}
        noDataMessage="KPI templates will appear here."
      />
    </TableWrapper>
  )
}

export default Templates
