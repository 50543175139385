import React, { FormEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { EmployeeGoal } from '@src/interfaces/employees'
import { useAddGoal, useDeleteGoal, useGetGoals } from '@src/api/performance'
import Loader from '@components/CommonSC/Loader'
import { Box, Button, Color, Flex, Input, Text, Grid } from '@revolut/ui-kit'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import MatrixTable, { MatrixRowInterface } from '@components/Table/MatrixTable'
import { CellTypes } from '@src/interfaces/data'
import { formatDate } from '@src/utils/format'
import Tooltip from '@components/Tooltip/Tooltip'
import { isAfter, isSameDay } from 'date-fns'
import produce from 'immer'
import Icon from '@components/Icon/Icon'
import { colorGetter } from '@src/styles/colors'
import { Widget } from '@revolut/ui-kit'
import {
  DatePickerInput,
  DatePickerInputDisabledDays,
} from '@components/Inputs/DatePickerInput/DatePickerInput'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
`

const Cell = styled.div`
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
`

const RemoveBtn = styled.button`
  width: 100%;
  color: ${colorGetter(Color.GREY_TONE_50)};

  &:hover {
    color: ${colorGetter(Color.BLUE)};
  }
`

const ROW: MatrixRowInterface<EmployeeGoal> = {
  cells: [
    {
      type: CellTypes.insert,
      idPoint: 'name',
      dataPoint: 'name',
      title: 'Jira',
      width: 450,
      insert: ({ data }) => <Cell title={data.name}>{data.name}</Cell>,
    },
    {
      type: CellTypes.insert,
      idPoint: 'due_date',
      dataPoint: 'due_date',
      title: 'Deadline',
      width: 130,
      insert: ({ data }) => (
        <Cell>
          <Flex justifyContent="flex-end">
            {data.deadline_date_time ? (
              <>Due {formatDate(data.deadline_date_time)}</>
            ) : (
              <Tooltip placement="top" text="Deadline date is missing">
                -
              </Tooltip>
            )}
          </Flex>
        </Cell>
      ),
    },
  ],
}

interface GoalsProps {
  category: ReviewCycleCategory
  employeeId: number
  reviewCycleId?: number | string
  canManageGoals?: boolean
  reviewCycleEndDate?: string
  reviewCycleStartDate?: string
  onGoalsUpdated?: (goals: EmployeeGoal[]) => void
}

const Goals = ({
  category,
  employeeId,
  canManageGoals,
  reviewCycleId,
  reviewCycleEndDate,
  reviewCycleStartDate,
  onGoalsUpdated,
}: GoalsProps) => {
  const [goalTitle, setGoalTitle] = useState<string>('')
  const [goalDeadline, setGoalDeadline] = useState<Date | null>(null)

  const filters = [
    {
      filters: [{ name: `${employeeId}`, id: `${employeeId}` }],
      columnName: 'employee__id',
    },
    category !== ReviewCycleCategory.Probation
      ? {
          filters: [{ name: `${reviewCycleId}`, id: `${reviewCycleId}` }],
          columnName: 'review_cycle__id',
        }
      : {
          filters: [{ name: category, id: category }],
          columnName: 'category',
        },
  ]

  const { data: goals = [], isLoading } = useGetGoals(
    category && employeeId && reviewCycleEndDate ? employeeId : null,
    filters,
  )

  const mutationDelete = useDeleteGoal(employeeId, filters, (oldData, id) => ({
    ...oldData,
    results: oldData.results.filter(item => item.id !== id),
  }))

  const mutationAdd = useAddGoal(employeeId, filters, (oldData, newData) => ({
    ...oldData,
    results: [...oldData.results, newData],
  }))

  const isMutationLoading = mutationDelete.isLoading || mutationAdd.isLoading

  let isCycleEnded = !(category && employeeId && reviewCycleEndDate)

  if (reviewCycleEndDate) {
    isCycleEnded =
      !goals.length &&
      isAfter(new Date(), new Date(reviewCycleEndDate)) &&
      !isSameDay(new Date(), new Date(reviewCycleEndDate))
  }

  useEffect(() => {
    onGoalsUpdated?.(goals)
  }, [goals])

  const handleSubmit = async () => {
    const data: EmployeeGoal = {
      name: goalTitle!,
      deadline_date_time: goalDeadline,
      employee: { id: `${employeeId}` },
      category: category as string,
      review_cycle: null,
    }

    if (category !== ReviewCycleCategory.Probation) {
      data.review_cycle = { id: `${reviewCycleId}` }
    }
    const result = await mutationAdd.mutateAsync(data)

    if (result?.data) {
      pushNotification({
        value: 'The goal was successfully saved.',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
      setGoalDeadline(null)
      setGoalTitle('')
    }
  }

  const handleDeleteGoal = async (id: number) => {
    const result = await mutationDelete.mutateAsync(id)
    if (result) {
      pushNotification({
        value: 'The goal was successfully deleted.',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    }
  }

  const row = produce(ROW, draft => {
    if (canManageGoals) {
      draft.cells.push({
        type: CellTypes.insert,
        idPoint: 'remove',
        dataPoint: 'remove',
        title: 'Remove',
        width: 40,
        insert: ({ data }) => (
          <RemoveBtn
            type="button"
            onClick={() => {
              handleDeleteGoal(data.id!)
            }}
          >
            <Icon type="Delete" size="tiny" />
          </RemoveBtn>
        ),
      })
    }
  })

  if (isLoading) {
    return (
      <Container>
        <Loader size="big" />
      </Container>
    )
  }

  const getDisabledDays = () => {
    const disabledDays: { before?: Date; after?: Date } = {}
    if (reviewCycleStartDate) {
      disabledDays.before = new Date(reviewCycleStartDate)
    }
    if (reviewCycleEndDate) {
      disabledDays.after = new Date(reviewCycleEndDate)
    }
    return disabledDays as DatePickerInputDisabledDays
  }

  return (
    <Box mt="s-48">
      <Text color={Color.GREY_TONE_50} fontWeight={500}>
        Goals
      </Text>
      <Box mt="s-8">
        <>
          <Widget px="s-16" mt="s-16">
            {goals.length ? (
              <MatrixTable
                rows={row}
                data={goals}
                noPadding
                noHeader
                noBorder
                autoResize
                width={650}
              />
            ) : (
              <Text use="div" py="s-16">
                No goals defined.
              </Text>
            )}
          </Widget>
          {canManageGoals && !isCycleEnded && (
            <Box mt="s-16">
              <Grid columns="1fr 200px" gap="s-16">
                <Input
                  value={goalTitle}
                  placeholder="Enter goal"
                  onChange={(e: FormEvent<HTMLInputElement>) => {
                    setGoalTitle(e.currentTarget.value)
                  }}
                  disabled={isMutationLoading}
                  onClear={() => setGoalTitle('')}
                />
                <DatePickerInput
                  value={goalDeadline}
                  disabledDays={getDisabledDays()}
                  label="Deadline"
                  onChange={d => setGoalDeadline(d)}
                  disabled={isMutationLoading}
                  clearable
                />
              </Grid>
              <Button
                variant="secondary"
                pending={isMutationLoading}
                size="sm"
                mt="s-16"
                onClick={handleSubmit}
                disabled={!goalTitle || !goalDeadline}
              >
                Add goal
              </Button>
            </Box>
          )}
        </>
      </Box>
    </Box>
  )
}

export default Goals
