import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, InputGroup, StatusWidget } from '@revolut/ui-kit'
import { CalendarDate } from '@revolut/icons'
import { useParams } from 'react-router-dom'
import format from 'date-fns/format'
import debounce from 'lodash/debounce'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  EmployeeShiftInterface,
  ScheduleShiftInterface,
} from '@src/interfaces/attendance'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import { formatWithoutTimezone } from '@src/utils/format'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { useDaySummary, useShiftOverview } from '@src/api/attendance'
import { ShiftOverview } from './ShiftOverview'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { selectorKeys } from '@src/constants/api'
import SideBar from '@src/components/SideBar/SideBar'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import {
  scheduleShiftApprovalColumn,
  scheduleShiftDurationColumn,
  scheduleShiftEndColumn,
  scheduleShiftStartColumn,
  scheduleShiftTypesColumn,
} from '@src/constants/columns/attendance'
import { withoutFilterAndSort } from '@src/utils/table'
import { TableNames } from '@src/constants/table'

export const DEBOUNCE_WAIT = 750

const Row: RowInterface<EmployeeShiftInterface> = {
  cells: withoutFilterAndSort([
    {
      ...scheduleShiftTypesColumn,
      width: 220,
    },
    {
      ...scheduleShiftStartColumn,
      width: 40,
    },
    {
      ...scheduleShiftEndColumn,
      width: 40,
    },
    {
      ...scheduleShiftDurationColumn,
      width: 40,
    },
    {
      ...scheduleShiftApprovalColumn,
      width: 60,
    },
  ]),
}

const General = () => {
  const params = useParams<{ employeeId: string; id?: string }>()

  const { values } = useLapeContext<ScheduleShiftInterface>()

  const [start, setStart] = useState<string | undefined>(values.start_time)
  const [end, setEnd] = useState<string | undefined>(values.end_time)
  const [isDaySummaryOpen, setIsDaySummaryOpen] = useState(false)

  const shiftOverview = useShiftOverview(params.employeeId, {
    date: values.date,
    start_time: start,
    end_time: end,
    id: values.id,
  })
  const daySummary = useDaySummary(params.employeeId, values.date)

  const title = values.id ? 'Edit shift' : 'Add extra shift'

  const compensationAllowed = !!shiftOverview.data?.compensation_allowed
  const toilDisallowed = shiftOverview.data?.toil_allowed === false

  useEffect(() => {
    if (toilDisallowed && compensationAllowed) {
      values.preferred_compensation = { id: 'remuneration', name: 'Remuneration' }
    }
  }, [toilDisallowed, compensationAllowed])

  const debouncedSetStart = useCallback(debounce(setStart, DEBOUNCE_WAIT), [])
  const debouncedSetEnd = useCallback(debounce(setEnd, DEBOUNCE_WAIT), [])

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={title}
          backUrl={pathToUrl(ROUTES.FORMS.SCHEDULE_SHIFT.PREVIEW, params)}
          subtitle={values.id ? formatWithoutTimezone(values.date) : undefined}
        />

        <PageBody>
          <Button
            onClick={() => setIsDaySummaryOpen(true)}
            useIcon={CalendarDate}
            variant="secondary"
            disabled={daySummary.isFetching}
            size="sm"
            mb="s-24"
          >
            Show day summary
          </Button>

          <InputGroup>
            {values.id ? null : (
              <LapeDatePickerInput
                onChange={date => {
                  if (date) {
                    values.date = format(date, 'yyyy-MM-dd')
                  }
                }}
                label="Date"
                name="date"
                required
              />
            )}

            <InputGroup variant="horizontal" data-name="time">
              <LapeNewInput
                name="start_time"
                label="Start time"
                required
                type="time"
                onAfterChange={debouncedSetStart}
              />
              <LapeNewInput
                name="end_time"
                label="End time"
                required
                type="time"
                onAfterChange={debouncedSetEnd}
              />
            </InputGroup>

            <ShiftOverview overview={shiftOverview} />

            {compensationAllowed && (
              <LapeRadioSelectInput
                name="preferred_compensation"
                label="Preferred compensation"
                selector={selectorKeys.shift_compensation_types}
                disabled={toilDisallowed}
                message="Please indicate your preferred compensation for the overtime work."
              />
            )}
          </InputGroup>
        </PageBody>

        <PageActions>
          <NewSaveButtonWithPopup
            onAfterSubmit={response => {
              if (params.id) {
                goBack()
              } else {
                navigateReplace(
                  pathToUrl(ROUTES.FORMS.SCHEDULE_SHIFT.PREVIEW, {
                    id: response.id,
                    employeeId: params.employeeId,
                  }),
                )
              }
            }}
            useValidator
            hideWhenNoChanges={false}
          >
            Save shift
          </NewSaveButtonWithPopup>
        </PageActions>
      </PageWrapper>

      <SideBar
        title="Day summary"
        subtitle={values.date ? formatWithoutTimezone(values.date) : undefined}
        isOpen={isDaySummaryOpen && !!daySummary.data}
        onClose={() => setIsDaySummaryOpen(false)}
        variant="wide"
      >
        {daySummary.data?.children ? (
          <Box>
            <AdjustableTable
              name={TableNames.ScheduledShifts}
              row={Row}
              data={daySummary.data.children}
              onRowClick={row => {
                if (row.type.id === 'time_off') {
                  window.open(
                    pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
                      employeeId: params.employeeId,
                      id: row.id,
                    }),
                  )
                }
                if (row.type.id === 'work') {
                  window.open(
                    pathToUrl(ROUTES.FORMS.SCHEDULE_SHIFT.PREVIEW, {
                      employeeId: params.employeeId,
                      id: row.id,
                    }),
                  )
                }
              }}
              hideCount
              count={0}
              emptyState={
                <StatusWidget>
                  <StatusWidget.Image
                    src="https://assets.revolut.com/assets/3d-images/3D036.png"
                    srcSet="https://assets.revolut.com/assets/3d-images/3D036@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D036@3x.png 3x"
                  />
                  <StatusWidget.Title>
                    No shifts scheduled for this day yet
                  </StatusWidget.Title>
                  <StatusWidget.Description>
                    Once you add and save shifts for this day, these will be shown here
                  </StatusWidget.Description>
                </StatusWidget>
              }
            />
          </Box>
        ) : null}
      </SideBar>
    </>
  )
}

export default General
