import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { pathToUrl } from '@src/utils/router'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { getLocationDescriptor } from '@src/actions/RouterActions'

type LapeScorecardFieldProps = {
  isClearable: boolean
  isDisabled: boolean
  isRequired: boolean
  message?: React.ReactNode
}

const LapeScorecardField = ({
  isClearable,
  isDisabled,
  isRequired,
  message,
}: LapeScorecardFieldProps) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  return (
    <LapeRadioSelectInput<HiringProcessInterface>
      disabled={isDisabled}
      name="scorecard_template"
      required={isRequired}
      label="Scorecard"
      clearable={isClearable}
      selector={selectorKeys.interview_scorecard_templates}
      useQuery
      referenceUrl={
        values.scorecard_template?.id
          ? getLocationDescriptor(
              pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW, {
                id: values.scorecard_template.id,
              }),
            )
          : undefined
      }
      message={message}
    />
  )
}

export default LapeScorecardField
