import React from 'react'
import { Box, Color, Flex, Text } from '@revolut/ui-kit'

const Footer = () => {
  return (
    <Box
      px="s-8"
      py="s-16"
      width="100%"
      backgroundColor={Color.FOREGROUND}
      style={{ flexShrink: 0, flexGrow: 0 }}
      mt="s-32"
    >
      <Flex justifyContent="center" alignItems="center">
        <Text variant="small" fontWeight={700} color={Color.GREY_20}>
          Powered by Revolut People
        </Text>
      </Flex>
    </Box>
  )
}

export default Footer
