import React from 'react'
import isNumber from 'lodash/isNumber'
import { Text } from '@revolut/ui-kit'

import { formatNumber, formatNumberMillions, formatPercentage } from '@src/utils/format'
import Tooltip from '@src/components/Tooltip/Tooltip'
import {
  ColoredPercent,
  getFunctionNIPSColor,
} from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { useTheme } from '@src/styles/theme'

interface Props {
  headcount?: number
  nips?: number
}

export const getTalentStatsQuickSummary = ({ headcount, nips }: Props) => {
  if (!isNumber(nips) || !isNumber(headcount)) {
    return null
  }

  return <TalentStats headcount={headcount} nips={nips} />
}

export const getMandatoryTasksStatsQuickSummary = (total?: number) => {
  if (!isNumber(total)) {
    return null
  }
  return <Text color="red">{formatPercentage(total, 2)}</Text>
}

export const TalentStats = ({ headcount, nips }: Required<Props>) => {
  const theme = useTheme()

  const nipsFormatted = formatNumber(nips * 100, 1)
  const headcountFormatted = formatNumberMillions(headcount)

  if (headcount === 0) {
    return <Text color={theme.colors['grey-20-opaque-90']}>0</Text>
  }

  return (
    <>
      <Tooltip placement="bottom" text="Headcount">
        {headcountFormatted}
      </Tooltip>
      <Text whiteSpace="pre"> · </Text>
      {
        <Tooltip
          placement="bottom"
          text="Net Individual Performance Score (NIPS) = % (Strong and Exceptional) — % (Average Minus and Unsatisfactory)"
        >
          <ColoredPercent
            percent={nipsFormatted}
            color={getFunctionNIPSColor(theme, nipsFormatted)}
          />
        </Tooltip>
      }
    </>
  )
}
