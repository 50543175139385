import React, { useState } from 'react'
import { Color, IconButton, Spinner, Token } from '@revolut/ui-kit'
import { Download } from '@revolut/icons'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  SubscriptionInvoiceInterface,
  SubscriptionPlanInterface,
} from '@src/interfaces/plans'
import { selectorKeys } from '@src/constants/api'
import { formatMoney, formatPeriod } from '@src/utils/format'
import { downloadInvoice } from '@src/api/plans'
import { saveFile } from '@src/utils'
import { formatSubscriptionFreePeriod } from '@src/pages/Settings/PlanManagement/common'

export const subscriptionInvoicesDateColumn: ColumnInterface<SubscriptionInvoiceInterface> =
  {
    type: CellTypes.dateInUtc,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Created on',
  }

export const subscriptionInvoicesPeriodColumn: ColumnInterface<SubscriptionInvoiceInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'period',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Period',
    insert: ({ data }) =>
      data.start_date && data.end_date
        ? formatPeriod(data.start_date, data.end_date)
        : '-',
  }

export const subscriptionInvoicesOrderNumberColumn: ColumnInterface<SubscriptionInvoiceInterface> =
  {
    type: CellTypes.text,
    idPoint: 'order_number',
    dataPoint: 'order_number',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Order number',
  }

export const invoiceStatusColor = (status: SubscriptionInvoiceInterface['status']) => {
  switch (status?.id) {
    case 'open':
      return Token.color.orange
    case 'paid':
      return Token.color.greyTone50
    case 'paying':
      return Token.color.green
    default:
      return ''
  }
}

export const subscriptionInvoicesStatusColumn: ColumnInterface<SubscriptionInvoiceInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    colors: data => invoiceStatusColor(data.status),
  }

export const subscriptionInvoicesTotalPriceColumn: ColumnInterface<SubscriptionInvoiceInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'total',
    dataPoint: 'total',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    textAlign: 'right',
    title: 'Total price',
    insert: ({ data }) => {
      const originalTotalPrice = formatMoney(Number(data.original_total), data.currency)
      return data.original_total !== data.total ? (
        <>
          <s style={{ color: Token.color.greyTone50 }}>{originalTotalPrice}</s>{' '}
          {formatMoney(Number(data.total), data.currency)}
        </>
      ) : (
        originalTotalPrice
      )
    },
  }

interface DownloadInvoiceActionProps {
  id: number
}

const DownloadInvoiceAction = ({ id }: DownloadInvoiceActionProps) => {
  const [pending, setPending] = useState(false)

  return pending ? (
    <Spinner color={Color.BLUE} size={16} />
  ) : (
    <IconButton
      color={Color.BLUE}
      size={16}
      aria-label="Download invoice"
      useIcon={Download}
      onClick={e => {
        e.stopPropagation()
        setPending(true)

        downloadInvoice(id)
          .then(response => {
            saveFile(response.file, response.fileName)
          })
          .finally(() => {
            setPending(false)
          })
      }}
    />
  )
}

export const subscriptionInvoicesActionColumn: ColumnInterface<SubscriptionInvoiceInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'action',
    dataPoint: 'action',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Action',
    insert: ({ data }) => <DownloadInvoiceAction id={data.id} />,
  }

export const subscriptionPlanNameColumn: ColumnInterface<SubscriptionPlanInterface> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const subscriptionPlanPriceColumn: ColumnInterface<SubscriptionPlanInterface> = {
  type: CellTypes.insert,
  idPoint: 'monthly_fee',
  dataPoint: 'monthly_fee',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Price per user, £',
  insert: ({ data }) => formatMoney(Number(data.monthly_fee), data.currency),
}

export const subscriptionPlanTrialPeriodColumn: ColumnInterface<SubscriptionPlanInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'free_days',
    dataPoint: 'free_days',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Trial period',
    insert: ({ data }) => formatSubscriptionFreePeriod(data.free_days),
  }
