import { AppIcon, AppIconGrid, useMatchBreakpoint } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import React from 'react'
import { HUB_INTEGRATIONS } from '@src/constants/hub'
import { getIconProps } from '@src/pages/Hub/utils'
import { pathToUrl } from '@src/utils/router'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { LockClosed } from '@revolut/icons'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { NavLink } from 'react-router-dom'

const Integrations = () => {
  const permissions = useSelector(selectPermissions)
  const size = useMatchBreakpoint('md') ? 76 : 60

  const checkAccess = (canView?: PermissionTypes[]) => {
    return canView ? canView.some(permission => permissions.includes(permission)) : true
  }

  return (
    <PageBody>
      <AppIconGrid size={size}>
        {HUB_INTEGRATIONS.map(app => {
          const enabled = checkAccess(app.canView)
          return (
            <AppIcon
              // @ts-expect-error object works fine here, but UI kit expects string
              to={app.url ? getLocationDescriptor(pathToUrl(app.url)) : undefined}
              use={app.url ? NavLink : undefined}
              {...getIconProps(app.image, 'white', size, 'png')}
              disabled={!enabled}
              data-testid={`integration-${app.id}`}
              key={app.id}
            >
              {!enabled && <AppIcon.Badge bg="grey-tone-50" useIcon={LockClosed} />}
              <AppIcon.Title>{app.title}</AppIcon.Title>
            </AppIcon>
          )
        })}
      </AppIconGrid>
    </PageBody>
  )
}

export default Integrations
