import React, { ReactNode } from 'react'
import { Color, Text, Flex, Box } from '@revolut/ui-kit'
import { connect } from 'lape'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

interface SkillsNotesProps {
  title?: string | ReactNode
  subtitle?: string | ReactNode
  name?: string
  note?: string | null
}

const SkillsNotes = ({ title, subtitle, name, note }: SkillsNotesProps) => {
  if (!note?.length) {
    return null
  }

  return (
    <Box
      borderRadius="12px"
      p="16px 16px 12px"
      m="16px 0"
      backgroundColor={Color.GREY_20_OPAQUE_30}
    >
      {title && (
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Flex flexDirection="column">
            <Text fontSize="primary" fontWeight={500}>
              {title}
            </Text>
            {subtitle && (
              <Text fontSize="caption" mb="s-8">
                {subtitle}
              </Text>
            )}
          </Flex>
          {name && <UserWithAvatar size={32} name={name} compact width={32} />}
        </Flex>
      )}

      <Box mb="s-8">
        <Text use="div" fontWeight={500} color={Color.GREY_TONE_50}>
          {note}
        </Text>
      </Box>
    </Box>
  )
}

export default connect(SkillsNotes)
