import React, { useState } from 'react'
import { Button } from '@revolut/ui-kit'
import { Inbox } from '@revolut/icons'
import { connect } from 'lape'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { Statuses } from '@src/interfaces'
import { successNotification } from '@src/actions/NotificationActions'

interface ArchiveButtonProps {
  isStringStatus?: boolean
  activeStatus?: Statuses
}

const mapStatus = (status: Statuses) => ({ id: status, name: status })

export const ArchiveButton = connect(
  ({ isStringStatus, activeStatus = Statuses.active }: ArchiveButtonProps) => {
    const { values, initialValues, submit } =
      useLapeContext<{ status: Statuses | { id: string; name: string }; id: number }>()
    const [pending, setPending] = useState(false)

    const onClick = (status: Statuses) => {
      setPending(true)
      values.status = isStringStatus ? status : mapStatus(status)
      submit()
        .then(() =>
          successNotification(
            status === Statuses.archived
              ? 'Label successfully archived'
              : 'Label successfully set to active',
          ),
        )
        .finally(() => setPending(false))
    }

    if (!values.id || !initialValues.status) {
      return null
    }

    if (
      (initialValues.status as { id: string; name: string }).id === Statuses.archived ||
      initialValues.status === Statuses.archived
    ) {
      return (
        <Button
          variant="secondary"
          size="sm"
          pending={pending}
          onClick={() => onClick(activeStatus)}
        >
          Make active
        </Button>
      )
    }

    return (
      <Button
        variant="secondary"
        size="sm"
        useIcon={Inbox}
        pending={pending}
        onClick={() => onClick(Statuses.archived)}
      >
        Archive
      </Button>
    )
  },
)
