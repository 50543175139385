import { TableRequestInterface } from '@src/interfaces'
import { api } from '@src/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  HiringPipelineInterface,
  HiringPipelinesStatsInterface,
} from '@src/interfaces/hiringPipelines'
import { useFetch } from '@src/utils/reactQuery'

export const hiringPipelinesRequests: TableRequestInterface<HiringPipelineInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get('/hiringPipelines', {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const useGetHiringPipelinesStats = () =>
  useFetch<HiringPipelinesStatsInterface>(`/hiringPipelines/statistics`)

export const useGetHiringPipeline = (id: number | string) =>
  useFetch<HiringPipelineInterface>(`/hiringPipelines/${id}`)
