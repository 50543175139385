import React, { useState, useRef } from 'react'
import { Button, ButtonSkeleton, Dropdown, Icon, Portal } from '@revolut/ui-kit'

import { PermissionTypes } from '@src/store/auth/types'
import { forceTriggerOnboardingProcess } from '@src/api/onboardingEmployees'
import { IdAndName } from '@src/interfaces'
import { EmployeeInterface, IdStatuses } from '@src/interfaces/employees'
import { StatusChangeButtonsGroup } from './StatusChange'
import { SetInactivePopup } from './SetInactive/Popup'
import { UseEmployeeStatusTransitionsReturnType } from './StatusChange/hooks'
import { UseEmployeeInactivityDataReturnType } from './SetInactive/hooks'
import { StatusChange } from './StatusChange/StatusChange'
import { canViewSetInactive, canViewTerminate } from '../common'

type StatusButtonBaseProps = {
  title: string
  notification: string
  newStatus: IdAndName<IdStatuses>
  canView?: (permissions: EmployeeInterface) => boolean
  onAfterStatusChange?: (employeeId: number) => Promise<void>
}

const statusButtons: StatusButtonBaseProps[] = [
  {
    title: 'Transition to pending',
    notification: 'Status set to Pending',
    newStatus: { id: IdStatuses.pending, name: 'Pending' },
  },
  {
    title: 'Switch to active',
    notification: 'Status set to Active',
    newStatus: { id: IdStatuses.active, name: 'Active' },
  },
  {
    title: 'Approve onboarding',
    notification: 'Status set to Hired',
    newStatus: { id: IdStatuses.hired, name: 'Hired' },
    canView: data =>
      !!data.field_options.permissions?.includes(PermissionTypes.ApproveOnboarding),
  },
  {
    title: 'Start onboarding',
    notification: 'Status set to Onboarding',
    newStatus: { id: IdStatuses.onboarding, name: 'Onboarding' },
    onAfterStatusChange: async employeeId => {
      // RHR-3268 It's a workaround to speed up onboarding process on BE (should be refactored)
      // If force triggering fails for some reason, it will be triggered later with a background job
      await forceTriggerOnboardingProcess(employeeId)
    },
  },
  {
    title: 'Mark as not hired',
    notification: 'Status set to Not hired',
    newStatus: { id: IdStatuses.not_hired, name: 'Not hired' },
  },
]

export const getStatusTransitionButtons = (
  employee: EmployeeInterface,
  statusTransitions: UseEmployeeStatusTransitionsReturnType,
  isDropdownItem?: boolean,
) =>
  statusTransitions.pending
    ? [<ButtonSkeleton key={0} height={24} width={96} m="s-12" />]
    : statusButtons
        .map(button => {
          const { status_transitions } = statusTransitions.data || {}
          const transitionAllowed = status_transitions?.some(
            status => status.id === button.newStatus.id,
          )
          const canView = button?.canView ? button.canView(employee) : true

          if (!canView || !transitionAllowed) {
            return null
          }
          return (
            <StatusChange
              isDropdownItem={isDropdownItem}
              key={button.title}
              data={employee}
              statusTransitions={statusTransitions}
              onStatusTransitionSuccess={async () => {
                await button.onAfterStatusChange?.(employee.id)
              }}
              {...button}
            />
          )
        })
        .filter(Boolean)

type Props = {
  data: EmployeeInterface
  isCurrentUser: boolean
  statusTransitions: UseEmployeeStatusTransitionsReturnType
  employeeInactivity: UseEmployeeInactivityDataReturnType
}
export const ChangeStatusDropdown = ({
  data,
  isCurrentUser,
  statusTransitions,
  employeeInactivity,
}: Props) => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const statusTransitionButtons = getStatusTransitionButtons(
    data,
    statusTransitions,
    true,
  )
  const hasStatusTransitionButtons = !!statusTransitionButtons.length
  const showSetInactive = canViewSetInactive(employeeInactivity, statusTransitions)
  const showTerminate = canViewTerminate(data)
  const hasChangesActions =
    !isCurrentUser && (hasStatusTransitionButtons || showSetInactive || showTerminate)

  if (!hasChangesActions) {
    return null
  }

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={() => setOpen(!open)}
        useIcon={<Icon name="Profile" size={16} />}
        size="sm"
        variant="secondary"
      >
        Change status
      </Button>
      <Dropdown
        open={open}
        anchorRef={anchorRef}
        onClose={() => setOpen(false)}
        minWidth={180}
      >
        <StatusChangeButtonsGroup
          isDropdownItem
          statusTransitionButtons={statusTransitionButtons}
          employee={data}
          statusTransitions={statusTransitions}
          employeeInactivity={employeeInactivity}
        />
      </Dropdown>
      <Portal>
        {showSetInactive && (
          <SetInactivePopup
            employeeName={data.full_name}
            statusTransitions={statusTransitions}
            employeeInactivity={employeeInactivity}
          />
        )}
      </Portal>
    </>
  )
}
