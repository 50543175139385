import React, { useEffect } from 'react'
import ScorecardGeneral from '@components/ScorecardGeneral/ScorecardGeneral'
import {
  ChangeScorecardInterface,
  CriteriaAssessment,
  PerformanceReviewTypes,
  ReviewScorecardInterface,
  ScorecardError,
} from '@src/interfaces/performance'
import {
  assessButtonsValidation,
  getClearedIncompleteSections,
  getNormalizedCards,
  getUpdatedRatingCard,
} from '@src/utils/performance'
import * as yup from 'yup'
import get from 'lodash/get'
import { useLapeContext } from '@src/features/Form/LapeForm'
import SkillsHelp from '@src/pages/Forms/EmployeePerformance/components/HelpSections/SkillsHelp'

export const validator = {
  review_data: yup.object().shape({
    functional_skills: yup.object().shape({
      cards: yup.array().of(
        yup.object().shape({
          sections: yup.array().of(
            yup
              .object()
              .shape<any>({
                items: yup.array().of(
                  yup.object().shape({
                    value: yup.string().nullable(),
                  }),
                ),
              })
              .test(
                'assess-buttons',
                'Please complete Skills before submitting.',
                assessButtonsValidation as any,
              ),
          ),
        }),
      ),
    }),
  }),
}

const Skills = () => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, submitFailed, errors, fields, change } = form
  const normalizedCards = getNormalizedCards(
    values.review_data?.functional_skills?.cards!,
  )

  useEffect(() => {
    // We should reset submitFailed state when we enter this page to avoid showing validation errors
    if (submitFailed) {
      form.submitFailed = false
    }
  }, [])

  const onChange = async ({
    cardIndex,
    sectionIndex,
    optionIndex,
    value,
  }: ChangeScorecardInterface) => {
    const path = `review_data.functional_skills.cards.${cardIndex}`
    change(`${path}.sections.${sectionIndex}.items.${optionIndex}.value`, value)

    const updatedCard = await getUpdatedRatingCard(
      values.id,
      values.category,
      values.reviewed_employee.id!,
      values.review_data?.functional_skills?.cards?.[cardIndex],
    )
    if (updatedCard) {
      change(`${path}.rating`, updatedCard.rating)
    }

    const visibleSections = getNormalizedCards(
      values.review_data.functional_skills?.cards || [],
    )?.[cardIndex]?.sections
    const sections = get(values, `${path}.sections`, []) as CriteriaAssessment[]

    const clearedIncompleteLevels = getClearedIncompleteSections(
      sections,
      visibleSections,
    )
    change(`${path}.sections`, clearedIncompleteLevels)
  }

  return (
    <ScorecardGeneral
      onChange={onChange}
      cards={normalizedCards}
      helpSection={<SkillsHelp />}
      errors={
        submitFailed
          ? (errors?.review_data?.functional_skills?.cards as ScorecardError[])
          : null
      }
      touched={Object.values(fields).some(state => state.touched)}
      type={PerformanceReviewTypes.skills}
      title="Skills"
      previousReviews={values?.review_data?.previous_reviews_by_cycle}
      category={values.category}
    />
  )
}

export default Skills
