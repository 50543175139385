import React from 'react'
import { connect } from 'lape'

import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { actionOptions } from '@src/pages/Forms/NotificationTemplate/common'
import {
  NotificationAction,
  NotificationTemplateInterface,
} from '@src/interfaces/notificationTemplate'
import ColorSelect from '@src/pages/Forms/NotificationTemplate/components/ColorSelect/ColorSelect'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeEditor from '@components/Inputs/LapeFields/LapeEditor'

const AnnouncementContent = () => {
  const { values } = useLapeContext<NotificationTemplateInterface>()

  if (!values.announcement_action) {
    values.announcement_action = NotificationAction.url
  }

  return (
    <>
      <LapeNewInput
        name="announcement_title"
        label="Title"
        message="Make it short and engaging"
        required
      />
      <RadioSelectInput
        label="Action when clicking on announcement"
        message="Define what should happen when user clicks on announcement"
        options={actionOptions}
        onChange={val => {
          values.announcement_action = val?.id
        }}
        value={
          actionOptions.find(option => option.value.id === values.announcement_action)
            ?.value
        }
        searchable={false}
        required
      />
      {values.announcement_action === NotificationAction.url ? (
        <LapeNewInput
          name="announcement_action_url"
          label="Url"
          message="Define URL where user should navigate after clicking on announcement"
          required
        />
      ) : (
        <LapeEditor name="template_text" placeholder="Description" />
      )}
      <ColorSelect />
      <LapeNewSwitch
        itemTypeProps={{
          title: 'Show avatar',
          description: 'Show owner’s avatar in the announcement tile',
        }}
        name="announcement_show_icon"
      />
      <LapeNewSwitch
        itemTypeProps={{
          title: 'Pin announcement to employees’ dashboards',
          description:
            'For special notices that will be kept visible for employees during given time frame.',
        }}
        name="announcement_pinned"
      />
      {values.announcement_pinned ? (
        <LapeDatePickerInput
          clearable={false}
          name="announcement_pinned_to"
          label="When notification should disappear from dashboards?"
          disabledDays={{ before: new Date() }}
          required
        />
      ) : null}
    </>
  )
}

export default connect(AnnouncementContent)
