import { Token } from '@revolut/ui-kit'

import {
  DocumentsBulkRequestEmployeeValidationStatus,
  DocumentsBulkRequestInterface,
  DocumentsBulkRequestStatus,
} from '@src/interfaces/documents'
import { documentsBulkRequestFormRequests } from '@src/api/documents'

export type CommonTabProps = {
  nextTabPath: string
  setStatus: (newStatus: DocumentsBulkRequestStatus) => void
  setName?: (newName: string) => void
}

export const isReadyToSubmit = (bulkRequestStatus: DocumentsBulkRequestStatus) =>
  bulkRequestStatus.id === 'ready_to_be_submitted'

export const getRefreshRequestData =
  (requestId: number | string, handler: (data: DocumentsBulkRequestInterface) => void) =>
  () =>
    documentsBulkRequestFormRequests.get({ id: String(requestId) }).then(res => {
      handler(res.data)
      return res.data
    })

export const getEmployeeValidationStatusColor = (
  status: DocumentsBulkRequestEmployeeValidationStatus,
): string => {
  switch (status.id) {
    case 'validation_pending':
      return Token.color.warning
    case 'validation_completed':
      return Token.color.success
    default:
      return Token.color.foreground
  }
}

export const getDocumentsBulkRequestStatusColor = (
  status: DocumentsBulkRequestStatus,
): string => {
  switch (status.id) {
    case 'validation_completed':
      return Token.color.warning
    case 'submit_completed':
      return Token.color.success
    case 'validation_pending':
    case 'validation_in_progress':
    case 'submit_in_progress':
    default:
      return Token.color.foreground
  }
}
