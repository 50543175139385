import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { InputGroup } from '@revolut/ui-kit'
import addMonths from 'date-fns/addMonths'
import produce from 'immer'

import { EmployeeInterface } from '@src/interfaces/employees'
import { canAddPipCycle } from '@src/store/auth/selectors'
import AutoStepper from '@components/Stepper/AutoStepper'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PerformanceCycleInterface } from '@src/interfaces/performance'
import AsyncButton from '@src/features/Form/Buttons/AsyncButton'
import { initiatePIP } from '@src/api/performance'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { localDateToUtc, utcToLocalDate } from '@src/utils/timezones'
import { DatePickerInput } from '@components/Inputs/DatePickerInput/DatePickerInput'

interface Props {
  data: EmployeeInterface
}

const Pip = (props: Props) => {
  const { values } = useLapeContext<EmployeeInterface>()
  const [dates, setDates] = useState<Partial<PerformanceCycleInterface>>({
    start_date_time: undefined,
    end_date_time: undefined,
  })

  const canEditPipCycle = useSelector(canAddPipCycle)
  const params = useParams<{ id?: string; new?: string }>()

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, params)

  const parseDate = (date: Date | null) => {
    if (!date) {
      return undefined
    }

    return localDateToUtc(date)
  }

  const onStartDateChange = (value: Date | null) => {
    const updateData = produce(dates, draft => {
      draft.start_date_time = parseDate(value)
      if (!draft.end_date_time && value) {
        draft.end_date_time = parseDate(addMonths(value, 3))
      }
    })
    setDates(updateData)
  }

  const onEndDateChange = (value: Date | null) => {
    setDates({ ...dates, end_date_time: parseDate(value) })
  }

  const onAfterSubmit = () => {
    navigateReplace(backUrl)
  }

  if (params.new) {
    return (
      <>
        <PageBody>
          <AutoStepper>
            <NewStepperTitle title={`Initiate PIP for ${props.data.full_name}`} />
            <InputGroup variant="horizontal">
              <DatePickerInput
                onChange={onStartDateChange}
                label="PIP start date"
                value={
                  dates.start_date_time ? utcToLocalDate(dates.start_date_time) : null
                }
              />
              <DatePickerInput
                onChange={onEndDateChange}
                label="PIP end date"
                value={dates.end_date_time ? utcToLocalDate(dates.end_date_time) : null}
              />
            </InputGroup>
          </AutoStepper>
        </PageBody>
        <PageActions>
          <AsyncButton
            submit={() => initiatePIP(props.data.id, dates)}
            hide={!dates.end_date_time || !dates.start_date_time}
            afterSubmitUrl={backUrl}
            successMessage="PIP started"
            elevated
          >
            Initiate PIP
          </AsyncButton>
        </PageActions>
      </>
    )
  }

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Performance cycles" />
          <InputGroup>
            {values?.performance_cycles
              ?.filter(cycle => cycle.category === 'PIP')
              .map((cycle, index) => (
                <InputGroup variant="horizontal" key={cycle.id}>
                  <LapeDatePickerInput
                    name={`performance_cycles[${index}].start_date_time`}
                    label="PIP start date"
                    disabled={!canEditPipCycle}
                    required
                  />
                  <LapeDatePickerInput
                    name={`performance_cycles[${index}].end_date_time`}
                    label="PIP end date"
                    disabled={!canEditPipCycle}
                    required
                  />
                </InputGroup>
              ))}
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup onAfterSubmit={onAfterSubmit} useValidator />
      </PageActions>
    </>
  )
}

export default Pip
