import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { selectorKeys } from '@src/constants/api'
import React from 'react'
import styled from 'styled-components'
import { colorGetter, getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

const DraftWrap = styled.span`
  padding-right: 6px;
  color: ${colorGetter(Color.GREY_TONE_50)};
`

export const specialisationGenericNameColumn: ColumnInterface<SpecialisationInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Specialisation',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, { id: data.id }),
  insert: ({ data }) =>
    data.status === Statuses.draft ? (
      <span>
        <DraftWrap>[Draft]</DraftWrap>
        {data.name}
      </span>
    ) : (
      data.name
    ),
}

export const specialisationStatusColumn: ColumnInterface<SpecialisationInterface> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status_display',
  colors: (data, theme) => {
    switch (data.status) {
      case Statuses.approved:
        return theme.colors.green
      case Statuses.pending:
        return theme.colors.warning
      case Statuses.draft:
        return theme.colors['grey-tone-50']
      default:
        return theme.colors.foreground
    }
  },
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.roles_status,
  title: 'Status',
}

export const specialisationManagerColumn: ColumnInterface<SpecialisationInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.full_name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
}

export const specialisationRoleColumn: ColumnInterface<SpecialisationInterface> = {
  type: CellTypes.text,
  idPoint: 'role.id',
  dataPoint: 'role.name',
  sortKey: 'role__name',
  filterKey: 'role__id',
  selectorsKey: selectorKeys.role,
  title: 'Role',
}

export const specialisationHeadcountColumn: ColumnInterface<SpecialisationInterface> = {
  type: CellTypes.text,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: 'headcount',
  filterType: FilterType.range,
  filterKey: 'headcount',
  selectorsKey: selectorKeys.none,
  colors: (data, theme) =>
    getColor(theme, data.headcount ? Color.FOREGROUND : Color.GREY_20_OPAQUE_90),
  title: 'headcount',
}

export const specialisationRequisitionsColumn: ColumnInterface<SpecialisationInterface> =
  {
    type: CellTypes.text,
    idPoint: 'requisition_headcount',
    dataPoint: 'requisition_headcount',
    sortKey: 'requisition_headcount',
    filterKey: 'requisition_headcount',
    filterType: FilterType.range,
    colors: (data, theme) =>
      getColor(
        theme,
        data.requisition_headcount ? Color.FOREGROUND : Color.GREY_20_OPAQUE_90,
      ),
    selectorsKey: selectorKeys.none,
    title: 'Reqs',
  }

export const specialisationSkillsColumn: ColumnInterface<SpecialisationInterface> = {
  type: CellTypes.insert,
  idPoint: 'skills',
  dataPoint: 'skills',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Additional Skills',
  insert: ({ data }) => {
    if (!data.skillsets) {
      return '-'
    }
    return data.skillsets.reduce((prev, curr) => {
      if (prev !== '') {
        return `${prev}, ${curr.name}`
      }
      return curr.name
    }, '')
  },
}

export const specialisationAllSkillsColumn: ColumnInterface<SpecialisationInterface> = {
  type: CellTypes.insert,
  idPoint: 'skills',
  dataPoint: 'skills',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Skills',
  insert: ({ data }) => {
    if (!data.skillsets && !data?.role?.skillsets) {
      return '-'
    }

    const skills = [...(data.skillsets || []), ...(data?.role?.skillsets || [])]
    return skills.reduce((prev, curr) => {
      if (prev !== '') {
        return `${prev}, ${curr.name}`
      }
      return curr.name
    }, '')
  },
}
