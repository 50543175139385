import React from 'react'
import { Skeleton, Box, Color, Text, themeSpace, chain, List } from '@revolut/ui-kit'
import pluralize from 'pluralize'
import styled from 'styled-components'

import { UseFetchResult } from '@src/interfaces'
import { ShiftOverviewInterface } from '@src/interfaces/attendance'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { CrossCircle, CrossSmall } from '@revolut/icons'

const formatDuration = (hours: number = 0, minutes?: number) => {
  const hourPart = hours ? pluralize('hour', hours, true) : ''
  const minutePart = minutes ? pluralize('minute', minutes, true) : ''
  return `${hourPart} ${minutePart}`.trim()
}

const CustomList = styled.ul`
  padding-left: ${themeSpace('s-20')};
  margin: 0;
`

interface ShiftOverviewProps {
  overview: UseFetchResult<ShiftOverviewInterface>
}

export const ShiftOverview = ({ overview }: ShiftOverviewProps) => {
  if (overview.isError) {
    return null
  }

  if (overview.isLoading) {
    return (
      <ActionWidget
        title={<Skeleton width={72} />}
        text={<Skeleton width={160} />}
        avatar={
          <Box width={43}>
            <Skeleton />
          </Box>
        }
      />
    )
  }

  if (overview.data) {
    const { errors, shift, approval_required } = overview.data
    const hasErrors = !!errors.length

    return (
      <ActionWidget
        title={`Shift duration: ${formatDuration(
          shift.time_range?.duration_hours,
          shift.time_range?.duration_minutes,
        )}`}
        text={(() => {
          if (hasErrors) {
            return (
              <>
                <Text color={Color.FOREGROUND}>
                  This shift doesn't meet schedule requirements:
                </Text>
                <List variant="compact" mt="s-8">
                  {errors.map(error => (
                    <List.Item useIcon={<CrossSmall color={Color.RED} />} key={error}>
                      {error}
                    </List.Item>
                  ))}
                </List>
              </>
            )
          }
          return (
            <>
              {approval_required ? (
                <Text color={Color.FOREGROUND}>
                  This shift includes non-standard working hours and must be reviewed.
                  After saving this shift, approval request will be automatically sent to
                  your line manager.
                </Text>
              ) : null}
              <Box mt="s-8" color={Color.FOREGROUND}>
                <Text fontWeight={500}>Shift summary:</Text>
                <CustomList>
                  {shift.sub_shifts?.map(subShift => (
                    <li key={subShift.type.id}>
                      {chain(
                        subShift.type.name,
                        `${subShift.time_range.start} - ${
                          subShift.time_range.end
                        } (${formatDuration(
                          subShift.time_range.duration_hours,
                          subShift.time_range.duration_minutes,
                        )})`,
                      )}
                    </li>
                  ))}
                </CustomList>
              </Box>
            </>
          )
        })()}
        avatar={hasErrors ? <CrossCircle color={Color.RED} /> : undefined}
        avatarColor={hasErrors ? undefined : Color.GREY_TONE_50}
      />
    )
  }

  return null
}
