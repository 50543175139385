import React, { useRef, useState } from 'react'
import { FilterButton as FilterButtonComponent } from '@revolut/ui-kit'
import { CalendarDate } from '@revolut/icons'
import { FilterDateRange } from '@components/Inputs/Filters/FilterDateRange/FilterDateRange'
import { FilterOption } from '@src/interfaces/data'

type Props = {
  label: string
  onChange: (options: FilterOption[]) => void
  value?: FilterOption[]
  clearable?: boolean
  disabled?: boolean
}

const FilterButtonDateRange = ({
  label,
  onChange,
  value,
  clearable = true,
  disabled,
}: Props) => {
  const anchorRef = useRef<HTMLSpanElement>(null)
  const [isOpen, setOpen] = useState(false)
  const hasValue = !!value?.length

  return (
    <>
      <span ref={anchorRef}>
        <FilterButtonComponent
          useIcon={CalendarDate}
          onClick={() => setOpen(!isOpen)}
          onClear={
            hasValue && clearable
              ? () => {
                  onChange([])
                  setOpen(false)
                }
              : undefined
          }
          active={hasValue}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          disabled={disabled}
        >
          {label}
        </FilterButtonComponent>
      </span>
      <FilterDateRange
        open={isOpen}
        value={value}
        onClose={() => setOpen(false)}
        onChange={newValue => {
          onChange(newValue)
          setOpen(false)
        }}
        anchorRef={anchorRef}
      />
    </>
  )
}

export default FilterButtonDateRange
