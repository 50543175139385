import React, { useState } from 'react'
import { Box, Button, Group as UIKitGroup, Text, Token } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  PerformanceSettingsInterface,
  PerformanceSettingsManagersWithType,
} from '@src/interfaces/settings'
import SideBar from '@src/components/SideBar/SideBar'
import {
  PerformanceSettingsManagersEntityType,
  PerformanceSettingsManagers,
} from '@src/interfaces/settings'
import {
  probationManagersNameColumn,
  probationManagersTypeColumn,
} from '@src/constants/columns/probation'
import { ManagersForm } from './ManagersForm'
import { performanceSettings } from '@src/api/performanceSettings'

interface ApprovalStepsProps {
  type: 'probation' | 'pip'
  showTitle?: boolean
}

export interface SidebarState {
  open: boolean
  id: number | null
  name: string | null
  full_name: string | null
}

const getEntitiesWithType = (
  entities: PerformanceSettingsManagers[],
  type: PerformanceSettingsManagersEntityType,
) => {
  return entities.map(el => ({ ...el, type }))
}

const row: RowInterface<PerformanceSettingsManagersWithType> = {
  cells: [
    {
      ...probationManagersTypeColumn,
      width: 100,
    },
    {
      ...probationManagersNameColumn,
      width: 300,
    },
  ],
}

export const Managers = ({ type, showTitle = true }: ApprovalStepsProps) => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()

  const [sidebarState, setSidebarState] = useState<SidebarState>({
    open: false,
    id: null,
    name: null,
    full_name: null,
  })

  const isProbationType = type === 'probation'
  const entity = isProbationType ? 'Probation' : 'PIP'
  const getEntitiesList = (setValues: PerformanceSettingsInterface) => {
    return isProbationType
      ? [
          ...getEntitiesWithType(
            setValues.probation_manager_employees,
            PerformanceSettingsManagersEntityType.Employee,
          ),
          ...getEntitiesWithType(
            setValues.probation_manager_groups,
            PerformanceSettingsManagersEntityType.Group,
          ),
        ]
      : [
          ...getEntitiesWithType(
            setValues.pip_manager_employees,
            PerformanceSettingsManagersEntityType.Employee,
          ),
          ...getEntitiesWithType(
            setValues.pip_manager_groups,
            PerformanceSettingsManagersEntityType.Group,
          ),
        ]
  }

  const [entitiesList, setEntitiesList] = useState(getEntitiesList(values))

  const onCloseSidebar = () =>
    setSidebarState({ open: false, id: null, name: null, full_name: null })

  return (
    <>
      <UIKitGroup p={showTitle ? 's-16' : 0}>
        {showTitle && (
          <Box mb="s-16">
            <Text variant="primary" display="block">
              Define {entity} Managers
            </Text>
            <Text variant="caption" color={Token.color.greyTone50} display="block">
              These are users who can be assigned to manage {entity} cycles.
            </Text>
          </Box>
        )}
        <Box>
          <AdjustableTable<PerformanceSettingsManagersWithType>
            name={isProbationType ? TableNames.ProbationManagers : TableNames.PIPManagers}
            dataType="Groups"
            row={row}
            data={entitiesList}
            count={entitiesList.length}
            disabledFiltering
            hideCount
            hideCountAndButtonSection
            rowHeight="medium"
            onRowClick={({ id, name, full_name }) =>
              setSidebarState({
                open: true,
                id,
                name: name || null,
                full_name: full_name || null,
              })
            }
          />
          <Button
            onClick={() =>
              setSidebarState({ open: true, id: null, name: null, full_name: null })
            }
            useIcon={Plus}
            size="sm"
            variant="secondary"
            mt="s-16"
          >
            Add users
          </Button>
        </Box>
      </UIKitGroup>

      <SideBar
        title={sidebarState.id ? `Edit ${entity} Managers` : `Add ${entity} Managers`}
        isOpen={sidebarState.open}
        onClose={onCloseSidebar}
      >
        <Form
          api={performanceSettings}
          /** id: 1 is hardcoded on the BE */
          forceParams={{ id: '1' }}
          disableLocalStorageCaching
        >
          <ManagersForm
            sidebarState={sidebarState}
            onComplete={result => {
              onCloseSidebar()
              setEntitiesList(getEntitiesList(result))
            }}
            type={type}
            onAfterDelete={result => {
              onCloseSidebar()
              setEntitiesList(getEntitiesList(result))
            }}
          />
        </Form>
      </SideBar>
    </>
  )
}
