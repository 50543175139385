import React from 'react'
import { FilterButton, MoreBar, TableWidget, Token } from '@revolut/ui-kit'

import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/Table/AdjustableTable'
import Stat from '@src/components/Stat/Stat'
import { AllDocumentsStats, EmployeeDocumentInterface } from '@src/interfaces/documents'
import {
  documentCategory,
  documentEmployee,
  documentIssueDateTime,
  documentIssuer,
  documentName,
  documentSigningDateTime,
  documentSource,
  documentStatus,
} from '@src/constants/columns/documents'
import { getAllDocuments, getAllDocumentsStats } from '@src/api/documents'
import {
  getDocumentHighlight,
  getDocumentLink,
} from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useSelector } from 'react-redux'
import {
  selectFeatureFlags,
  selectPermissions,
  selectUser,
} from '@src/store/auth/selectors'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { TableNames } from '@src/constants/table'
import { useGetDocumentsSettings } from '@src/api/settings'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

const ROW: RowInterface<EmployeeDocumentInterface> = {
  linkToForm: getDocumentLink,
  highlight: getDocumentHighlight,
  cells: [
    {
      ...documentName,
      filterKey: null,
      width: 250,
    },
    {
      ...documentCategory,
      width: 200,
    },
    {
      ...documentSource,
      width: 120,
    },
    {
      ...documentEmployee,
      width: 200,
    },
    {
      ...documentIssueDateTime,
      width: 140,
    },
    {
      ...documentSigningDateTime,
      width: 140,
    },
    {
      ...documentIssuer,
      width: 200,
    },
    {
      ...documentStatus,
      width: 200,
    },
  ],
}

export const AllDocuments = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_DOCUMENTS_ASSIGNED_TO_ME,
    false,
  )
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const { data: documentsSettings } = useGetDocumentsSettings()

  const showDocumentButtons = permissions.includes(PermissionTypes.UploadDocuments)
  const canAddBulkRequest = permissions.includes(PermissionTypes.AddDocumentBulkRequest)
  const showBulkRequestButton =
    canAddBulkRequest && featureFlags.includes(FeatureFlags.BulkRequestDocuments)

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'issuer__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const table = useTable<EmployeeDocumentInterface, AllDocumentsStats>(
    {
      getItems: getAllDocuments,
      getStats: getAllDocumentsStats,
    },
    getInitialFilters(),
  )

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <Stat label="Total" val={table.loading ? undefined : table.stats?.total} />
          <Stat
            label="Pending upload"
            val={table.loading ? undefined : table.stats?.pending_upload}
            color={Token.color.orange}
          />
          <Stat
            label="Pending approval"
            val={table.loading ? undefined : table.stats?.pending_approval}
            color={Token.color.orange}
          />
          <Stat
            label="Pending signature"
            val={table.loading ? undefined : table.stats?.pending_signature}
            color={Token.color.orange}
          />
        </TableWidget.Info>
        <TableWidget.Actions>
          {showDocumentButtons && (
            <MoreBar>
              {!!documentsSettings?.enable_document_requests && (
                <>
                  {showBulkRequestButton && (
                    <MoreBar.Action
                      use={InternalLink}
                      to={pathToUrl(ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.BASICS)}
                      useIcon="SendMessage"
                      variant="primary-on-blue"
                    >
                      Bulk request
                    </MoreBar.Action>
                  )}
                  <MoreBar.Action
                    use={InternalLink}
                    to={pathToUrl(ROUTES.FORMS.DOCUMENT_REQUEST.GENERAL)}
                    useIcon="Plus"
                  >
                    Request document
                  </MoreBar.Action>
                </>
              )}
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.DOCUMENT.GENERAL)}
                useIcon="Plus"
              >
                Add document
              </MoreBar.Action>
              {documentsSettings?.enable_document_generation_from_templates ? (
                <MoreBar.Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.DOCUMENT_GENERATE.GENERAL)}
                  useIcon="Plus"
                >
                  Generate document
                </MoreBar.Action>
              ) : null}
              <MoreBar.Action
                to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.DOCUMENTS.UPLOAD_FILE)}
                use={InternalLink}
                useIcon="ShareIOs"
              >
                Import documents
              </MoreBar.Action>
            </MoreBar>
          )}
        </TableWidget.Actions>
        <TableWidget.Filters>
          <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
            Issued by me
          </FilterButton>
        </TableWidget.Filters>

        <TableWidget.Table>
          <AdjustableTable
            name={TableNames.AllDocuments}
            useWindowScroll
            row={ROW}
            {...table}
            noDataMessage="All documents will appear here"
          />
        </TableWidget.Table>
      </TableWidget>
    </>
  )
}
