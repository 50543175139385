import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  InterviewStageRowChildrenInterface,
  InterviewStageRowWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { Text } from '@revolut/ui-kit'
import upperFirst from 'lodash/upperFirst'
import { getInterviewStatusColor } from '@src/constants/columns/candidates'

export const interviewStageNameColumn: ColumnInterface<
  InterviewStageRowWithoutRoundInterface | InterviewStageRowChildrenInterface
> = {
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Stage',
  insert: ({ data, parentIndexes }) => {
    if ('children' in data) {
      return `${parentIndexes[0] + 1}. ${data.title}`
    }

    return data.title || ''
  },
}

export const interviewStageActionColumn: ColumnInterface<
  InterviewStageRowWithoutRoundInterface | InterviewStageRowChildrenInterface
> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
}

export const interviewStageRecommendationColumn: ColumnInterface<
  InterviewStageRowWithoutRoundInterface | InterviewStageRowChildrenInterface
> = {
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Recommendation',
}

export const interviewStageResultColumn: ColumnInterface<
  InterviewStageRowWithoutRoundInterface | InterviewStageRowChildrenInterface
> = {
  type: CellTypes.insert,
  idPoint: 'rating',
  dataPoint: 'rating',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Result',
}

export const interviewStageStatusColumn: ColumnInterface<
  InterviewStageRowWithoutRoundInterface | InterviewStageRowChildrenInterface
> = {
  type: CellTypes.insert,
  idPoint: 'scheduling_status',
  dataPoint: 'scheduling_status_display',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data }) => {
    const statusDisplay =
      'children' in data ? data.scheduling_status_display : upperFirst(data.status)
    const status = 'children' in data ? data.scheduling_status : data.status

    return <Text color={getInterviewStatusColor(status)}>{statusDisplay}</Text>
  },
}
