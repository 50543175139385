import React from 'react'
import HiringPipelinesCommonTable, {
  HiringPipelinesCommonTableProps,
} from '@src/pages/Recruitment/RecruitmentSubTabs/HiringPipelines/HiringPipelinesCommonTable'

const MyHiringPipelines = (props: Omit<HiringPipelinesCommonTableProps, 'type'>) => {
  return <HiringPipelinesCommonTable type="own" {...props} />
}

export default MyHiringPipelines
