import React, { useEffect } from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import Icon from '@components/Icon/Icon'
import IconButton from '@components/ButtonIcon/IconButton'
import { colorGetter } from '@src/styles/colors'
import { Bar, Button, Color, InputGroup } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'

const Container = styled.div``
const InputsContainer = styled.div`
  position: relative;
  border-radius: 8px;
`

const DeleteButton = styled(IconButton)`
  position: absolute;
  right: -30px;
  top: 50px;
  color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
`

const JustificationsContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 16px;
  margin-bottom: 16px;
`

interface LinkJustificationProps {
  className?: string
  name: string
  previousReviews?: React.ReactNode
}

const LinkJustification = ({
  className,
  name,
  previousReviews,
}: LinkJustificationProps) => {
  const { values, change } = useLapeContext()
  const inputs: { reference_url: string; comment: string }[] = get(values, name, [])

  useEffect(() => {
    if (!inputs || inputs?.length === 0) {
      change(name, [{ comment: '', reference_url: null }])
    }
  }, [values])

  const handleAddAnotherLink = () => {
    const newValues: { reference_url: string | null; comment: string }[] = [
      ...inputs,
      { comment: '', reference_url: null },
    ]
    change(name, newValues)
  }

  const handleDelete = (id: number) => {
    const newValues = inputs.filter((_, i) => i !== id)
    change(name, newValues)
  }

  return (
    <Container className={className}>
      <JustificationsContainer>
        {get(values, name)?.map((_: any, id: number) => {
          return (
            <InputsContainer key={`${name}.${id}`}>
              <InputGroup>
                <LapeNewInput
                  name={`${name}.${id}.reference_url`}
                  label={`Link ${id + 1}`}
                />
                <LapeNewTextArea name={`${name}.${id}.comment`} label={'Justification'} />
              </InputGroup>
              {id > 0 ? (
                <DeleteButton
                  onClick={() => {
                    handleDelete(id)
                  }}
                >
                  <Icon type="Delete" size="small" />
                </DeleteButton>
              ) : null}
            </InputsContainer>
          )
        })}
      </JustificationsContainer>
      <Bar>
        <Button variant="secondary" size="sm" onClick={handleAddAnotherLink}>
          Add deliverable
        </Button>
        {previousReviews}
      </Bar>
    </Container>
  )
}

export default connect(LinkJustification)
