import React, { useEffect, useMemo, useState } from 'react'
import { sumBy } from 'lodash'
import { Button, Flex, TableWidget, Text } from '@revolut/ui-kit'
import { Pencil } from '@revolut/icons'
import { RowInterface, SortByInterface } from '@src/interfaces/data'
import {
  getQueryRunResultColumns,
  getQueryRunResult,
  queryRunsTableRequests,
} from '@src/api/dataAnalytics'
import { TableNames } from '@src/constants/table'
import {
  ReportColumnsTableInterface,
  ReportInterface,
  RunQueryResponseInterface,
  QueryRunStatuses,
} from '@src/interfaces/dataAnalytics'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useTable } from '@components/Table/hooks'
import Stat from '@src/components/Stat/Stat'
import { orderingToSort, useTableSettings } from '../hooks/useTableSettings'
import { FormatTableSidebar } from './FormatTableSidebar'

const maxItemsToShow = 20

interface Props {
  reorderSettings: ReportColumnsTableInterface[]
  sortSettings: SortByInterface[]
  tableSummary: { value: string | null; count: number }[]
  tableRows: RowInterface<any>
  runId: number
  queryId: number
}

const ResultTable = ({
  reorderSettings,
  sortSettings,
  tableSummary,
  tableRows,
  runId,
  queryId,
}: Props) => {
  const { values } = useLapeContext<ReportInterface>()
  const initialSortBy = orderingToSort(values.output_format.ordering || [])
  const resultFileds = reorderSettings.map(item => item.name).join(',')

  const resultTable = useTable(
    {
      getItems: getQueryRunResult(runId, queryId, resultFileds),
    },
    undefined,
    initialSortBy,
  )

  useEffect(() => {
    resultTable.resetFiltersAndSorting([], sortSettings)
  }, [sortSettings])

  return (
    <TableWidget>
      <TableWidget.Info>
        {values.output_format.summary_column && (
          <Flex gap="s-32">
            {tableSummary.map(item => (
              <Stat
                key={item.value}
                label={item.value === null ? '(blank)' : item.value}
                val={item.count}
              />
            ))}
            <Stat label="total" val={sumBy(tableSummary, 'count')} />
          </Flex>
        )}
      </TableWidget.Info>
      <TableWidget.Table>
        <AdjustableTable
          {...resultTable}
          count={maxItemsToShow}
          data={resultTable.data.slice(0, maxItemsToShow)}
          enableSettings={false}
          name={TableNames.ReportingAppReportsUITable}
          row={tableRows}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export const UiOutputFormat = () => {
  const { values } = useLapeContext<ReportInterface>()
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [tableSummary, setTableSummary] = useState<
    { value: string | null; count: number }[]
  >([])
  const {
    reorderSettings,
    setReorderSettings,
    sortSettings,
    setSortSettings,
    tableRows,
  } = useTableSettings()

  const queryRunsTable = useTable<RunQueryResponseInterface>({
    getItems: queryRunsTableRequests(values.query.id),
  })

  const latestRunSuccess = useMemo(() => {
    return queryRunsTable.data.find(run => run.status === QueryRunStatuses.Success)
  }, [queryRunsTable.data])

  const summarizeOptions = useMemo(
    () =>
      values.query.columns.map(column => ({
        label: column.name,
        value: { id: column.name, ...column },
      })),
    [values.query.columns],
  )

  const fetchSummary = async () => {
    const runResult = await getQueryRunResultColumns(
      latestRunSuccess?.id!,
      values.query.id,
      values.output_format.summary_column!,
    )
    if (runResult.data) {
      setTableSummary(runResult.data)
    }
  }

  useEffect(() => {
    if (latestRunSuccess?.id && values.output_format.summary_column) {
      fetchSummary()
    } else {
      setTableSummary([])
    }
  }, [latestRunSuccess?.id, values.output_format.summary_column])

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mb="s-16" mt="s-32">
        <Text variant="h4">Report preview</Text>
        <Button
          onClick={() => setSideBarOpen(!sideBarOpen)}
          size="sm"
          useIcon={Pencil}
          variant="secondary"
        >
          Format Table
        </Button>
      </Flex>
      {latestRunSuccess?.id && (
        <ResultTable
          reorderSettings={reorderSettings.visible}
          sortSettings={sortSettings.visible}
          tableSummary={tableSummary}
          tableRows={tableRows}
          runId={latestRunSuccess.id}
          queryId={values.query.id}
        />
      )}
      <FormatTableSidebar
        isOpen={sideBarOpen}
        handleClose={() => setSideBarOpen(false)}
        outputFormat={values.output_format}
        reorderSettings={reorderSettings}
        setReorderSettings={setReorderSettings}
        sortSettings={sortSettings}
        setSortSettings={setSortSettings}
        summarizeOptions={summarizeOptions}
        tableSummary={tableSummary}
      />
    </>
  )
}
