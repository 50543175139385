import React from 'react'
import { Box, COLORS, TabBar } from '@revolut/ui-kit'
import { CheckSuccess, RadiobuttonOff } from '@revolut/icons'
import { useLocation, useParams } from 'react-router-dom'
import { getPrevLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'
import { RouteParams } from '../types'
import TabBarLink from '@components/TabBarLink/TabBarLink'

export const useNavigationBar = (
  resignation?: EmployeeResignationInterface,
): { name: string; to: string; isDisabled?: boolean; isChecked?: boolean }[] => {
  const { id: resignationId, employeeId } = useParams<RouteParams>()
  const isReasonsFormFilledIn = !!resignation?.id
  const isSubmitFormFilledIn = !!resignation?.employee_next_steps
  const isFileUploaded = !!resignation?.notice_file

  return [
    {
      name: 'Reasons to leave',
      to: pathToUrl(
        isReasonsFormFilledIn
          ? ROUTES.FORMS.EMPLOYEE_RESIGNATION.REASONS_PREVIEW
          : ROUTES.FORMS.EMPLOYEE_RESIGNATION.REASONS,
        {
          employeeId,
          id: resignationId,
        },
      ),
      isChecked: isReasonsFormFilledIn,
    },
    {
      name: 'Resignation form',
      to: pathToUrl(
        isSubmitFormFilledIn
          ? ROUTES.FORMS.EMPLOYEE_RESIGNATION.SUBMIT_PREVIEW
          : ROUTES.FORMS.EMPLOYEE_RESIGNATION.SUBMIT,
        {
          employeeId,
          id: resignationId,
        },
      ),
      isDisabled: !resignation?.id,
      isChecked: isFileUploaded && isSubmitFormFilledIn,
    },
  ]
}

export type Props = {
  resignation?: EmployeeResignationInterface
}
const NavigationBar = ({ resignation }: Props) => {
  const { pathname: currentLocation } = useLocation()
  const locations = useNavigationBar(resignation)

  if (!locations.length) {
    return null
  }
  return (
    <Box my="s-16">
      <TabBar>
        {locations.map(({ name, to, isDisabled, isChecked }) => {
          return (
            <TabBarLink
              key={name}
              to={getPrevLocationDescriptor(to)}
              aria-selected={to === currentLocation}
              useIcon={
                isChecked ? (
                  <CheckSuccess size={12} color={COLORS.green} />
                ) : (
                  <RadiobuttonOff size={12} color={COLORS['grey-tone-50']} />
                )
              }
              disabled={isDisabled}
            >
              {name}
            </TabBarLink>
          )
        })}
      </TabBar>
    </Box>
  )
}

export default NavigationBar
