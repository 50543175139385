import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { SendMessage } from '@revolut/icons'
import { SigningStatuses } from '@src/interfaces/offerCreation'
import SendOfferAndEmailSidebar from './SendOfferAndEmailSidebar'

type Props = {
  status?: SigningStatuses
  onRefresh: () => void
}

const SendOfferButton = ({ status, onRefresh }: Props) => {
  const [openSendOfferAndEmailSidebar, setOpenSendOfferAndEmailSidebar] = useState(false)

  return (
    <>
      {openSendOfferAndEmailSidebar && (
        <SendOfferAndEmailSidebar
          onRefresh={() => {
            setOpenSendOfferAndEmailSidebar(false)
            onRefresh()
          }}
        />
      )}
      <MoreBar.Action
        useIcon={SendMessage}
        onClick={() => setOpenSendOfferAndEmailSidebar(true)}
      >
        {status === 'offer_not_sent' ? 'Send offer' : 'Re-send offer'}
      </MoreBar.Action>
    </>
  )
}

export default SendOfferButton
