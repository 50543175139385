import {
  GetRequestData,
  IdAndName,
  RequestInterface,
  RequestInterfaceNew,
} from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import {
  EligibleSpecialisationsInterface,
  OfferChecklistInterface,
  OfferCreationInterface,
  OfferLetterHTMLInterface,
} from '@src/interfaces/offerCreation'
import { useFetch } from '@src/utils/reactQuery'
import { LegacyApprovalStep } from '@src/interfaces/approvalFlow'
import { getCommentsAPI } from '@src/api/comments'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import isNumber from 'lodash/isNumber'

export const offerCreationFormRequest: RequestInterfaceNew<OfferCreationInterface> = {
  get: async ({ id }) => api.get(`/offerForms/${id}`),
  update: async (data, { id }) => apiWithoutHandling.patch(`/offerForms/${id}`, data),
  submit: async data => apiWithoutHandling.post(`/offerForms`, data),
  delete: async ({ id }) => api.delete(`/offerForms/${id}`),
}

export const changelogOfferRequests = (
  offerId: number,
): RequestInterface<ChangelogInterface<OfferCreationInterface>> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/offerForms/${offerId}${API.CHANGELOG}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`/offerForms/${offerId}${API.CHANGELOG}/${id}`),
  patchItem: async (data, id) =>
    api.patch(`/offerForms/${offerId}${API.CHANGELOG}/${id}`, data),
  deleteItem: async id => api.delete(`/offerForms/${offerId}${API.CHANGELOG}/${id}`),
  postItem: async data => api.post(`/offerForms/${offerId}${API.CHANGELOG}`, data),
})

export const offersTableRequests: RequestInterface<OfferCreationInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/offerForms`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`/offerForms/${id}`, undefined),
  patchItem: async (data, id) => api.patch(`/offerForms/${id}`, data, undefined),
  deleteItem: async id => api.delete(`/offerForms/${id}`, undefined),
  postItem: async data => api.post(`/offerForms`, data, undefined),
}

export const getPendingOfferChangesRequest = (id: number) =>
  api.get<ChangelogInterface<OfferCreationInterface>>(
    `/offerForms/${id}/changelogPreview`,
  )

export const changelogFieldsOfferRequests = (
  offerId: number,
): RequestInterface<ChangelogInterface<OfferCreationInterface>> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/offerForms/${offerId}${API.CHANGELOG_FIELDS}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id =>
    api.get(`/offerForms/${offerId}${API.CHANGELOG_FIELDS}/${id}`, undefined),
  patchItem: async (data, id) =>
    api.patch(`/offerForms/${offerId}${API.CHANGELOG_FIELDS}/${id}`, data, undefined),
  deleteItem: async id =>
    api.delete(`/offerForms/${offerId}${API.CHANGELOG_FIELDS}/${id}`, undefined),
  postItem: async data => api.post(`/offerForms${API.CHANGELOG_FIELDS}`, data, undefined),
})

export const useOfferCreationRequestApprovals = (id?: number) =>
  useFetch<LegacyApprovalStep[]>(id ? `/offerForms/${id}/approvals` : null)

export const getOfferComments = (id: number) =>
  getCommentsAPI({
    baseUrl: `/offerForms/${id}/comments`,
  })

export const useGetLatestOffer = (roundId?: number | null) => {
  const context = useFetch<GetRequestData<OfferCreationInterface>>(
    roundId ? `/interviews/rounds/${roundId}/offerForms?ordering=-id` : null,
  )
  return { ...context, data: context.data?.results?.[0] }
}

export const sendOffer = (id: number) => api.post(`/offerForms/${id}/sendOffer`)

export const useGetOfferHTML = (id?: number | string) =>
  useFetch<OfferLetterHTMLInterface>(id ? `/offerForms/${id}/getOfferAsHTML` : null)

export const hireCandidate = (offerId: number) =>
  api.post(`/offerForms/${offerId}/hireCandidate`)

export const useGetOfferChecklist = (id: number) =>
  useFetch<OfferChecklistInterface>(`/offerForms/${id}/checklist`)

export const useGetRecommendedJobTitle = (
  seniorityId: number | null,
  specialisationId: number | null,
) => {
  const context = useFetch<{ job_title: string }>(
    seniorityId !== null && specialisationId !== null
      ? `/offerForms/getRecommendedJobTitle`
      : null,
    undefined,
    {
      params: {
        specialisation_id: specialisationId,
        seniority_id: seniorityId,
      },
    },
  )

  return { ...context, data: context.data?.job_title }
}

export const getRecommendedJobTitle = (
  seniorityId: number | string,
  specialisationId: number | string,
) =>
  api.get<{ job_title: string; is_offer_job_title_suggestion_enabled: boolean }>(
    `/offerForms/getRecommendedJobTitle`,
    {
      params: {
        specialisation_id: specialisationId,
        seniority_id: seniorityId,
      },
    },
  )

export const cancelOffer = (id: number) => api.post(`/offerForms/${id}/cancelOffer`)

export const useGetEligibleSpecialisations = (roleId?: number) =>
  useFetch<EligibleSpecialisationsInterface>(
    roleId ? `/offerForms/eligibleSpecialisations` : null,
    undefined,
    {
      params: {
        role_id: roleId,
      },
    },
  )

export const useGetEligibleEntities = (locationId?: number | string) =>
  useFetch<{ options: IdAndName[] }>(
    isNumber(locationId) ? `/offerForms/eligibleEntities` : null,
    undefined,
    {
      params: {
        location_id: locationId,
      },
    },
  )
