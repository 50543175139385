import { API } from '@src/constants/api'
import { api } from '.'
import { useFetch } from '@src/utils/reactQuery'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { GetRequestInterface } from '@src/interfaces'
import { AxiosPromise } from 'axios'
import { BackgroundJobResult, BackgroundJobStats } from '@src/interfaces/backgroundJobs'

export const getBackgroundJobs = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<BackgroundJobResult>> => {
  return api.get(API.BACKGROUND_JOBS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })
}

export const getBackgroundJobStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) => {
  return api.get<BackgroundJobStats>(API.BACKGROUND_JOBS_STATS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })
}

export const useBackgroundJob = (id: number) => {
  return useFetch<BackgroundJobResult>(`${API.BACKGROUND_JOBS}/${id}`)
}
