import React, { useState } from 'react'
import { InputGroup, StatusPopup } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'

import { PageBody } from '@src/components/Page/PageBody'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageActions } from '@components/Page/PageActions'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AccountsSettingsInterface } from '@src/interfaces/settings'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import { accountSettings } from '@src/api/settings'
import Form from '@src/features/Form/Form'
import { LapePhoneInput } from '@src/components/Inputs/LapeFields/LapePhoneInput'

const General = () => {
  const params = useParams<{ id?: string }>()
  const { initialValues } = useLapeContext<AccountsSettingsInterface>()
  const [showCreatingPopup, setShowCreatingPopup] = useState(false)
  const [accountId, setAccountId] = useState<string | number>()

  return (
    <>
      <PageHeader
        title={params.id ? 'Edit account' : 'Create new account'}
        backUrl={
          params.id
            ? pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INFORMATION, params)
            : ROUTES.SETTINGS.ACCOUNTS.LIST
        }
        subtitle={params.id ? initialValues.company_name : undefined}
      />

      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Company info" />
          <InputGroup>
            <LapeNewInput name="company_name" label="Company name" required />
            <LapeNewInput name="subdomain" label="Company subdomain" required />
          </InputGroup>

          <NewStepperTitle title="Admin info" />
          <InputGroup>
            <LapeNewInput name="admin.first_name" label="First name" required />
            <LapeNewInput name="admin.middle_name" label="Middle name" />
            <LapeNewInput name="admin.last_name" label="Last name" required />
            <LapeNewInput name="admin.email" label="Email" required />
            <LapePhoneInput
              prefixName="admin.phone_country_code"
              phoneName="admin.phone_number_short"
              phonePlaceholder="Phone number"
              required
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={res => {
            if (params.id) {
              goBack(pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INFORMATION, params))
            } else {
              setAccountId(res.id)
              setShowCreatingPopup(true)
            }
          }}
          noPopup={params.id == null}
          useValidator
        />
      </PageActions>

      <StatusPopup
        variant="loading"
        open={showCreatingPopup}
        onClose={() => {
          navigateReplace(
            pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INFORMATION, { id: accountId }),
          )
        }}
      >
        <StatusPopup.Title>Creating the new account...</StatusPopup.Title>
        <StatusPopup.Description>
          The creation might take a couple of minutes, once done we will confirm and send
          activation email to the admin
        </StatusPopup.Description>
      </StatusPopup>
    </>
  )
}

export const GeneralForm = connect(() => (
  <Form api={accountSettings} disableLocalStorageCaching>
    <General />
  </Form>
))
