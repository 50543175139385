import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  NewPlaceholdersTableInterface,
  OfferTemplateInterface,
  PredefinedPlaceholdersTableInterface,
} from '@src/interfaces/offerTemplates'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Action } from '@revolut/ui-kit'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

export const offerTemplateNameColumn: ColumnInterface<OfferTemplateInterface> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.offer_templates,
  title: 'Name',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.OFFER_TEMPLATE.GENERAL, {
      id: data.id,
    }),
}

export const offerTemplateCreatedByColumn: ColumnInterface<OfferTemplateInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner',
  dataPoint: 'owner',
  sortKey: 'owner',
  filterKey: 'owner',
  selectorsKey: selectorKeys.employee,
  title: 'Created by',
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
}

export const offerTemplateCreatedOnColumn: ColumnInterface<OfferTemplateInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Created on',
}

export const placeholderNameColumn: ColumnInterface<
  NewPlaceholdersTableInterface | PredefinedPlaceholdersTableInterface
> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Placeholder',
  dynamicHyperlinks: data =>
    'id' in data
      ? pathToUrl(ROUTES.FORMS.OFFER_PLACEHOLDER.GENERAL, { id: data.id })
      : null,
}

export const placeholderSimilarColumn: ColumnInterface<NewPlaceholdersTableInterface> = {
  type: CellTypes.text,
  idPoint: 'similar',
  dataPoint: 'similar',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Similar predefined placeholder',
}

export const placeholderLabelColumn: ColumnInterface<
  NewPlaceholdersTableInterface | PredefinedPlaceholdersTableInterface
> = {
  type: CellTypes.text,
  idPoint: 'label',
  dataPoint: 'label',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Label in form',
}

export const placeholderTypeColumn: ColumnInterface<PredefinedPlaceholdersTableInterface> =
  {
    type: CellTypes.text,
    idPoint: 'type.id',
    dataPoint: 'type.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Type',
  }

export const placeholderActionColumn: ColumnInterface<NewPlaceholdersTableInterface> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => (
    <Action
      onClick={() =>
        navigateTo(pathToUrl(ROUTES.FORMS.OFFER_PLACEHOLDER.GENERAL), {
          placeholder: data.name,
        })
      }
    >
      Create
    </Action>
  ),
}
