import { BarChart, ViewListMedium } from '@revolut/icons'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'

export enum ChartTableTabs {
  Chart = 'Chart',
  Table = 'Table',
}
const tabs = [ChartTableTabs.Chart, ChartTableTabs.Table]
const tableFirstTabs = [ChartTableTabs.Table, ChartTableTabs.Chart]

export interface ChartTableSwitcherOptions {
  showTableByDefault?: boolean
  showTableFirst?: boolean
  highlightSelected?: boolean
  onTabChange?: (newTab: string) => void
}

const tabsIcons = {
  [ChartTableTabs.Chart]: BarChart,
  [ChartTableTabs.Table]: ViewListMedium,
}

const useChartTableSwitcher = ({
  showTableByDefault,
  showTableFirst,
  ...options
}: ChartTableSwitcherOptions = {}) => {
  return useTabBarSwitcher<ChartTableTabs>({
    useIcons: tabsIcons,
    tabs: showTableFirst ? tableFirstTabs : tabs,
    defaultTab: showTableByDefault ? ChartTableTabs.Table : ChartTableTabs.Chart,
    ...options,
  })
}

export default useChartTableSwitcher
