import React, { useCallback, MouseEvent } from 'react'
import styled from 'styled-components'
import { Clip } from '@revolut/icons'
import isArray from 'lodash/isArray'
import BottomText, { BottomProps } from '@components/Inputs/partials/BottomText'
import { FileWithPath, useDropzone } from 'react-dropzone'
import { Text, Flex, Box, ItemSkeleton, VStack, Image, Token } from '@revolut/ui-kit'
import NewLabel, { NewLabelProps } from '@components/Inputs/partials/NewLabel'
import UploadedFile, {
  UploadedFileProps,
} from '@components/Inputs/FileUploader/UploadedFile'
import { FileGroup } from './FileGroup'
import { FileInterface } from '@src/interfaces/files'

const FileUploaderContainer = styled.div`
  width: auto;
  position: relative;
  display: grid;
  min-width: 280px;
  align-items: start;
  align-content: start;
`

const DropArea = styled.div<{
  isDragActive: boolean
  height?: string
  noHighlight?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: ${props => props.height || '80px'};
  outline: none;
  background: ${props =>
    props.isDragActive
      ? `rgba(6, 102, 235, 0.10)`
      : props.noHighlight
      ? undefined
      : props.theme.colors['action-background']};
  border: ${props =>
    props.noHighlight ? `1px dashed ${Token.color.greyTone20};` : 'undefined'};
  border-radius: 12px;
`

const Message = styled(Flex)`
  color: ${Token.color.greyTone50};
  white-space: pre;
  align-items: center;
`

export interface FileUploaderProps extends NewLabelProps, BottomProps {
  className?: string
  /** Flag to disable input */
  disabled?: boolean
  /** Value of the file to display as selected */
  value?: File | File[] | FileInterface | null
  /** Callback for when user selects or clears items */
  onChange: (file: File | File[] | null) => void
  /** Flag to allow multiple file upload */
  multiple?: boolean
  name?: string
  attachButtonText?: string
  accept?: string | string[]
  loading?: boolean
  onOpen?: (file: FileInterface | FileWithPath) => void
  directory?: boolean
  showDropAreaImage?: boolean
  uploadInstructions?: React.ReactNode
  noDropAreaHighlight?: boolean
}

const FileUploader = ({
  multiple,
  onChange,
  bottomInfo,
  error,
  value,
  disabled,
  name,
  attachButtonText,
  accept,
  loading,
  onOpen,
  directory,
  showDropAreaImage,
  uploadInstructions,
  noDropAreaHighlight,
  ...rest
}: FileUploaderProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File | File[] | null) => {
      if (multiple && isArray(acceptedFiles)) {
        onChange([...((value as File[] | null) || []), ...acceptedFiles])
      } else {
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        onChange(acceptedFiles?.[0] || null)
      }
    },
    [value, onChange],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept,
    noClick: true,
    // https://github.com/react-dropzone/react-dropzone/discussions/1157#discussioncomment-2537815
    ...(directory ? { useFsAccessApi: false } : {}),
  })

  const handleClear = (idx: number | null) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (idx === null) {
      onChange(null)
      return
    }

    if (isArray(value)) {
      const files = value.filter((_, i) => idx !== i)
      onChange(files.length ? files : null)
    }
  }

  const getCommonProps = (file: FileWithPath | FileInterface): UploadedFileProps => ({
    disabled,
    title: (file as FileWithPath).path || file.name,
    size: file.size,
    onOpen: onOpen ? () => onOpen(file) : undefined,
  })

  return (
    <FileUploaderContainer
      data-name={name}
      style={disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}}
    >
      {rest.label || rest.link ? <NewLabel {...rest} /> : null}

      {loading ? (
        <ItemSkeleton />
      ) : (
        <>
          {multiple || (!multiple && !value) ? (
            <label htmlFor="file-uploader">
              <DropArea
                {...getRootProps(
                  // Drag and drop doesn't work well with directories, disabling it
                  directory
                    ? {
                        onDragEnter: e => {
                          e.stopPropagation()
                        },
                        onDragLeave: e => {
                          e.stopPropagation()
                        },
                        onDragOver: e => {
                          e.stopPropagation()
                        },
                        onDrop: e => {
                          e.stopPropagation()
                        },
                        isDragActive: false,
                      }
                    : undefined,
                )}
                isDragActive={isDragActive}
                data-testid={`dropzone-${name}`}
                height={showDropAreaImage ? '160px' : undefined}
                noHighlight={noDropAreaHighlight}
              >
                <input
                  {...getInputProps()}
                  multiple={multiple}
                  data-testid={`file-${name}`}
                  id="file-uploader"
                  aria-invalid={!!error}
                  {...(directory ? { webkitdirectory: '' } : {})}
                />
                <VStack align="center">
                  {showDropAreaImage && (
                    <Image
                      src="https://assets.revolut.com/assets/3d-images/3D131.png"
                      alt="Upload"
                      size={64}
                    />
                  )}
                  {isDragActive ? (
                    <Message>Drop here</Message>
                  ) : (
                    <Message>
                      <Box mr="s-6">
                        <Clip color={Token.color.blue} size={14} />
                      </Box>
                      {directory ? null : (
                        <Text variant="secondary">Drag &amp; drop or </Text>
                      )}
                      <Text variant="primary" color={Token.color.blue}>
                        {attachButtonText || 'Click to attach document'}
                      </Text>
                    </Message>
                  )}
                  {uploadInstructions}
                </VStack>
              </DropArea>
            </label>
          ) : null}
          {multiple && isArray(value) && value.length > 0 ? (
            <FileGroup mt="s-16">
              {value.map((file, idx) => (
                <UploadedFile
                  key={idx}
                  {...getCommonProps(file)}
                  onDelete={handleClear(idx)}
                />
              ))}
            </FileGroup>
          ) : null}
          {!multiple && value ? (
            <UploadedFile
              {...getCommonProps(value as File | FileInterface)}
              onDelete={handleClear(null)}
            />
          ) : null}
          <BottomText bottomInfo={bottomInfo} error={error} />
        </>
      )}
    </FileUploaderContainer>
  )
}

export default FileUploader
