import React, { useEffect } from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KPIFormLocationState, KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { Grid } from '@components/CommonSC/Grid'
import NewSelectionCard from '@components/NewSelectionCard/NewSelectionCard'
import { Jira, ArrowDowngrade, AutoExchange, Pencil } from '@revolut/icons'
import { PageBody } from '@components/Page/PageBody'
import { useLocation } from 'react-router-dom'
import { KPITypes } from '@src/constants/table'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { KPIQueryParams } from '@src/pages/Forms/KpiForm/common/common'
import { useGetRoadmapSettings } from '@src/api/settings'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

interface Props {
  onSelect?: (type: KPIQueryParams['type']) => void
  useLocalStorageCaching?: boolean
}

const SelectKPIType = connect(({ onSelect, useLocalStorageCaching = true }: Props) => {
  const { values } = useLapeContext<KpiInterface>()
  const location = useLocation<KPIFormLocationState>()
  const { probationId } = location?.state || {}

  const { data: roadmapSettings } = useGetRoadmapSettings()

  const { data: settings } = useGetPerformanceSettings()
  const sqlTypeEnabled = !!settings?.allowed_kpi_types.find(type => type.id === 'sql')
  const manualTypeEnabled = !!settings?.allowed_kpi_types.find(
    type => type.id === 'manual',
  )
  const cascadedTypeEnabled = !!settings?.allowed_kpi_types.find(
    type => type.id === 'cascaded',
  )
  const roadmapTypeEnabled =
    roadmapSettings?.enabled &&
    !!settings?.allowed_kpi_types.find(type => type.id === 'roadmap')

  useEffect(() => {
    if (useLocalStorageCaching) {
      workspaceLocalStorage.removeItem(pathToUrl(ROUTES.FORMS.KPI.GENERAL))
      localStorage.setItem(location.pathname, JSON.stringify(values))
    }
  }, [useLocalStorageCaching])

  const handleSelect = (type: KPIQueryParams['type']) => {
    if (!type) {
      return
    }
    if (onSelect) {
      onSelect(type)
    } else {
      // Query is used to make sure local storage cash doesn't mix up different kpi types
      navigateReplace(pathToUrl(ROUTES.FORMS.KPI.GENERAL, undefined, { type }))
    }
  }

  return (
    <PageBody>
      <Grid mt="s-32" flow="row" gap={24} data-testid="kpi_select_type">
        {values.kpi_type.id === KPITypes.employee_kpi &&
          !probationId &&
          cascadedTypeEnabled && (
            <NewSelectionCard
              onClick={() => {
                values.is_inherited = true
                handleSelect('inherit')
              }}
              icon={ArrowDowngrade}
              title="Cascade from a higher level KPI"
              subtitle="The performance will be the same as the parent KPI"
            />
          )}
        {cascadedTypeEnabled &&
          [KPITypes.department_kpi, KPITypes.team_kpi].includes(values.kpi_type.id) && (
            <NewSelectionCard
              onClick={() => {
                values.is_inherited = true
                handleSelect('inherit')
              }}
              icon={ArrowDowngrade}
              title="Cascade from a higher level KPI"
              subtitle="The performance will be the same as the parent KPI"
            />
          )}
        {sqlTypeEnabled && (
          <NewSelectionCard
            onClick={() => {
              values.is_inherited = false
              values.update_type = UpdateTypes.sql
              handleSelect(UpdateTypes.sql)
            }}
            icon={AutoExchange}
            title="Auto-update KPI"
            subtitle="Progress determined daily via a defined data connection"
            data-testid="kpi_sql_type"
          />
        )}
        {!probationId && roadmapTypeEnabled && (
          <NewSelectionCard
            onClick={() => {
              values.is_inherited = false
              values.update_type = UpdateTypes.roadmap
              handleSelect(UpdateTypes.roadmap)
            }}
            icon={Jira}
            title="Roadmap KPI"
            subtitle="Progress will be determined by the completion of linked roadmap deliverables"
          />
        )}
        {manualTypeEnabled && (
          <NewSelectionCard
            onClick={() => {
              values.is_inherited = false
              values.update_type = UpdateTypes.manual
              handleSelect(UpdateTypes.manual)
            }}
            icon={Pencil}
            title="Manual KPI"
            subtitle="The performance will be measured by manual inputs to the KPI"
            data-testid="kpi_manual_type"
          />
        )}
      </Grid>
    </PageBody>
  )
})

export default SelectKPIType
