import React from 'react'
import {
  Button,
  CounterWidget,
  Header,
  Popup,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import {
  getCVScreeningPerformance,
  getCVScreeningSession,
  setCVScreeningPerformance,
} from '@src/pages/Forms/CVScreening/utils'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import pluralize from 'pluralize'

export const CvScreeningResultPopup = () => {
  const performance = getCVScreeningPerformance()
  const session = getCVScreeningSession()
  const avgTime = session.screenedCandidateIds?.length
    ? Math.round((session.duration || 0) / session.screenedCandidateIds.length)
    : 0

  const onProceed = () => {
    setCVScreeningPerformance({
      avgTime,
      screened: session.screenedCandidateIds?.length || 0,
    })
    navigateTo(ROUTES.FORMS.CV_SCREENING_SUMMARY)
  }

  const getDuration = (sec: number) => {
    const hours = Math.floor(sec / 3600)
    const minutes = Math.floor((sec % 3600) / 60)
    const seconds = Math.floor(sec) % 60

    let res = []

    if (hours) {
      res.push(`${hours}h`)
    }

    if (minutes || hours) {
      res.push(`${minutes}min`)
    }

    res.push(`${seconds}s`)

    return res.join(' ')
  }

  const calcDiff = () => {
    if (!performance.avgTime) {
      return undefined
    }

    let diff = (avgTime * 100) / performance.avgTime
    return Math.round(diff - 100)
  }

  const perfDiff = calcDiff()

  return (
    <Popup size="md" open closeOnOverlayClick={false}>
      <Header variant="compact">
        <Header.Title>Well done</Header.Title>
        <Header.Description>Screening completed. Good job!</Header.Description>
      </Header>

      <VStack gap="s-16" mb="s-32">
        {!!perfDiff && (
          <Text>
            Your average screening time per CV has been{' '}
            {perfDiff > 0 ? 'increased' : 'decreased'} by {Math.abs(perfDiff)}%{' '}
            {perfDiff < 0 ? '🥳' : ''}
          </Text>
        )}
        <CounterWidget>
          <CounterWidget.Column>
            <CounterWidget.Title>Screen session duration</CounterWidget.Title>
            <CounterWidget.Description textStyle="h2" textAlign="center">
              {getDuration(session.duration || 0)}
            </CounterWidget.Description>
          </CounterWidget.Column>

          <CounterWidget.Column>
            <CounterWidget.Title>Average time per CV</CounterWidget.Title>
            <CounterWidget.Description textStyle="h2" textAlign="center">
              {getDuration(avgTime)}
            </CounterWidget.Description>
          </CounterWidget.Column>
        </CounterWidget>

        <CounterWidget>
          <CounterWidget.Column>
            <CounterWidget.Title>Screened</CounterWidget.Title>
            <CounterWidget.Description textStyle="h4">
              {session.screenedCandidateIds?.length || 0}
            </CounterWidget.Description>
            <CounterWidget.Subtitle>
              {session.screenedCandidateIds?.length === 1 ? 'CV' : 'CVs'}
            </CounterWidget.Subtitle>
          </CounterWidget.Column>
          <CounterWidget.Column>
            <CounterWidget.Title>Archived</CounterWidget.Title>
            <CounterWidget.Description textStyle="h4">
              {session.archivedCandidateIds?.length || 0}
            </CounterWidget.Description>
            <CounterWidget.Subtitle>
              {pluralize('Candidate', session.archivedCandidateIds?.length || 0)}
            </CounterWidget.Subtitle>
          </CounterWidget.Column>
          <CounterWidget.Column>
            <CounterWidget.Title>Skipped</CounterWidget.Title>
            <CounterWidget.Description textStyle="h4">
              {session.skippedCandidateIds?.length || 0}
            </CounterWidget.Description>
            <CounterWidget.Subtitle>
              {pluralize('Candidate', session.skippedCandidateIds?.length || 0)}
            </CounterWidget.Subtitle>
          </CounterWidget.Column>
          <CounterWidget.Column>
            <CounterWidget.Title>Moved to next stage</CounterWidget.Title>
            <CounterWidget.Description textStyle="h4">
              {session.movedToNextStageCandidateIds?.length || 0}
            </CounterWidget.Description>
            <CounterWidget.Subtitle>
              {pluralize('Candidate', session.movedToNextStageCandidateIds?.length || 0)}
            </CounterWidget.Subtitle>
          </CounterWidget.Column>
        </CounterWidget>
        <CounterWidget>
          <CounterWidget.Column>
            <CounterWidget.Title>Scheduled interview</CounterWidget.Title>
            <CounterWidget.Description textStyle="h4">
              {session.scheduledCandidateIds?.length || 0}
            </CounterWidget.Description>
            <CounterWidget.Subtitle>
              {pluralize('Candidate', session.scheduledCandidateIds?.length || 0)}
            </CounterWidget.Subtitle>
          </CounterWidget.Column>
          <CounterWidget.Column>
            <CounterWidget.Title>Online test sent</CounterWidget.Title>
            <CounterWidget.Description textStyle="h4">
              {session.onlineTestSentCandidateIds?.length || 0}
            </CounterWidget.Description>
            <CounterWidget.Subtitle>
              {pluralize('Candidate', session.onlineTestSentCandidateIds?.length || 0)}
            </CounterWidget.Subtitle>
          </CounterWidget.Column>
        </CounterWidget>
      </VStack>

      <Popup.Actions horizontal style={{ padding: Token.space.s24 }}>
        <Button
          onClick={() =>
            navigateTo(session.referrerPath || ROUTES.RECRUITMENT.CANDIDATES)
          }
          variant="secondary"
        >
          Go to candidates table
        </Button>
        <Button onClick={onProceed}>View Summary</Button>
      </Popup.Actions>
    </Popup>
  )
}
