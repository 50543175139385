import React from 'react'
import styled from 'styled-components'
import { Token, Link, Text, LinkProps } from '@revolut/ui-kit'

export const StyledLink = styled(Link)({
  color: Token.color.greyTone50,
  transition: 'color 0.15s',
  '&:hover': { color: Token.color.white },
  '&:active': { color: Token.color.white_80 },
})

export const FooterLink = (props: LinkProps) => {
  return <Text use={StyledLink} variant="small" {...props} />
}
