import React from 'react'
import { Button, Flex, Stars, Text, TextArea, ToggleButton, Box } from '@revolut/ui-kit'
import { LikeDislike } from '@revolut/icons'

interface FeedbackCardProps {
  title: string
  placeholder?: string
  labelsOptions?: string[]
  labels?: string[]
  onLabelClick?: (label: string) => void
  score: number | null
  onScoreChange: (score: number | null) => void
  text?: string
  onTextChange: (text: string) => void
  onSubmit: () => void
  pending?: boolean
}

export const FeedbackCard = ({
  title,
  placeholder,
  labelsOptions,
  labels,
  onLabelClick,
  score,
  onScoreChange,
  text,
  onTextChange,
  onSubmit,
  pending,
}: FeedbackCardProps) => {
  return (
    <>
      <LikeDislike color="blue" size={40} />
      <Text variant="h1" use="h1" fontSize="28px" textAlign="center" mt="s-24">
        {title}
      </Text>
      <Box my="s-24">
        <Stars value={score} onChange={onScoreChange} size={26} />
      </Box>
      {labelsOptions && (
        <Flex flexWrap="wrap" gap="s-8" mb="s-24" rowGap="s-8">
          {labelsOptions.map(option => {
            const checked = labels?.includes(option)
            return (
              <ToggleButton
                checked={checked}
                key={option}
                onClick={() => onLabelClick?.(option)}
              >
                {option}
              </ToggleButton>
            )
          })}
        </Flex>
      )}
      <TextArea
        value={text}
        onChange={e => onTextChange(e.currentTarget.value)}
        label={placeholder}
        rows={2}
      />
      <Button
        onClick={onSubmit}
        disabled={!score}
        pending={pending}
        mt="s-48"
        maxWidth={343}
        elevated
      >
        Submit
      </Button>
    </>
  )
}
