import React, { useEffect, useState } from 'react'
import { Bar, Button, InputGroup, Link, Text } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import { selectorKeys } from '@src/constants/api'
import LapeFileUploader from '@components/Inputs/LapeFields/LapeFileUploader'
import { DocumentInterface } from '@src/interfaces/documents'
import {
  downloadUploaded,
  downloadUploadedAsBlob,
  newDocumentRequests,
} from '@src/api/documents'
import LoadingButton from '@components/Button/LoadingButton'
import Tooltip from '@components/Tooltip/Tooltip'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { Download } from '@revolut/icons'
import { EmployeeInterface } from '@src/interfaces/employees'
import { employeesRequestsNew } from '@src/api/employees'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { DeleteButtonLape } from '../FormButtons'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { Grid } from '@src/components/CommonSC/Grid'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'

const General = () => {
  const { values } = useLapeContext<DocumentInterface>()
  const [showUpload, setShowUpload] = useState(!values.id)
  const [file, setFile] = useState<string | null>()
  const [employee, setEmployee] = useState<EmployeeInterface>()
  const params = useParams<{ employeeId?: string }>()

  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const updateEmployee = (id: string) => {
    employeesRequestsNew.get({ id }).then(res => {
      setEmployee(res.data)
    })
  }

  useEffect(() => {
    const newEmployeeId = params.employeeId || values.employee?.id

    if (newEmployeeId) {
      updateEmployee(`${newEmployeeId}`)
    }
  }, [params.employeeId, values.employee?.id])

  const backUrl = employee
    ? pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS, {
        id: employee.id,
      })
    : ROUTES.PEOPLE.EMPLOYEES

  useEffect(() => {
    // We can't save file to LocalStorage that is why I remove it if the data comes from there.
    if (values.file && !values.file.name) {
      values.file = undefined
      setShowUpload(true)
    }
  })

  useEffect(() => {
    const fetchDoc = async () => {
      const response = await downloadUploadedAsBlob(values.id)
      setFile(response)
    }

    if (values.id && !file && values.file_name) {
      fetchDoc()
    }
  }, [values.id, file])

  const renderButtons = () => {
    if (!values.file_name) {
      return <Text variant="bullet">There are no files. Please upload a file.</Text>
    }
    return (
      <Grid flow="column" alignItems="center" justifyContent="start" gap={10}>
        {values.file_name}
        <Tooltip placement="top" text="Download the file">
          <LoadingButton
            customIcon={<Download size={20} />}
            onSubmit={() => downloadUploaded(values.id, `${employee?.id}`)}
          >
            Download
          </LoadingButton>
        </Tooltip>
      </Grid>
    )
  }

  return (
    <PageWrapper>
      <PageHeader
        title={`Document for ${employee?.full_name || 'employee'}`}
        backUrl={backUrl}
      />
      <PageBody>
        {values.id && (
          <Bar>
            <DeleteButtonLape
              data={values}
              backUrl={backUrl}
              deleteApi={newDocumentRequests.delete!}
              title="Delete document"
            />
          </Bar>
        )}

        <AutoStepper>
          {!params.employeeId && (
            <>
              <NewStepperTitle title="Employee" />
              <InputGroup>
                <LapeRadioSelectInput
                  name="employee"
                  selector={selectorKeys.employee}
                  label="Employee"
                />
              </InputGroup>
            </>
          )}
          <NewStepperTitle title="Details" />
          <InputGroup>
            <LapeNewInput name="name" label="File Name" required />
            <LapeDatePickerInput name="issue_date_time" label="Issue Date" required />
            <LapeRadioSelectInput
              name="issuer"
              selector={selectorKeys.employee}
              label="Issuer"
            />
            <LapeDatePickerInput name="signing_date_time" label="Signing date" />
            <LapeRadioSelectInput
              name="category"
              message={
                !isCommercial ? (
                  <>
                    Please make sure you select the appropriate document label for the
                    file you are uploading. If you cannot find a label that describes
                    precisely the document type, you can request one on the Revoluters
                    service desk{' '}
                    <Link
                      target="_blank"
                      href="https://revolut.atlassian.net/servicedesk/customer/portal/137/group/362/create/13105"
                    >
                      here
                    </Link>
                  </>
                ) : undefined
              }
              selector={selectorKeys.document_categories}
              label="Category"
            />
            {employee?.field_options.permissions?.includes(
              PermissionTypes.AccessDocumentConfidentialityField,
            ) && (
              <LapeRadioSelectInput
                name="confidentiality"
                selector={selectorKeys.uploaded_documents_confidentiality_choices}
                label="Confidentiality"
                message="Please note that this setting only applies to employees when viewing their own documents. Any user with special permissions to view documents will not be affected by this restriction"
              />
            )}
            <LapeNewTextArea
              name="description"
              label="Description"
              message="Add some description to explain what the document is about."
              rows={3}
            />
          </InputGroup>

          <NewStepperTitle title="Document" />
          <InputGroup>
            {showUpload && <LapeFileUploader name="file" label="File attachment" />}
            {!showUpload && renderButtons()}
            {file && !showUpload && (
              <iframe
                src={file}
                width="556"
                frameBorder="0"
                onLoad={iframe => {
                  const maxHeight = 800
                  iframe.currentTarget.height = `${Math.min(
                    iframe.currentTarget.contentWindow?.document.body.scrollHeight ||
                      maxHeight,
                    maxHeight,
                  )}px`
                }}
                title="Document"
              />
            )}
            {values.id && !showUpload && (
              <Button variant="text" onClick={() => setShowUpload(true)} size="sm" p={0}>
                Reupload document
              </Button>
            )}
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          disabled={!values.employee}
          successText="Document successfully saved"
          afterSubmitUrl={backUrl}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default General
