import React, { useMemo } from 'react'
import { AuditInterface, AuditSeverities, AuditStatuses } from '@src/interfaces/audit'
import { RowInterface, SORT_DIRECTION, Stats } from '@src/interfaces/data'
import { EntityTypes } from '@src/constants/api'
import { useTableReturnType } from '@components/Table/hooks'
import { Box, Cell, chain, Color, Flex, Link } from '@revolut/ui-kit'
import { FindingsStats } from '@src/features/Findings/FindingsStats'
import {
  SummarySubheader,
  SummaryTableContainer,
  summaryTableProps,
} from '@src/features/Summary/common'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  auditAssigneeColumn,
  auditCreationDateColumn,
  auditIssueLinkColumn,
  auditLatenessColumn,
  auditSeverityColumn,
  auditSummaryColumn,
  auditTeamNameColumn,
  auditTypeColumn,
} from '@src/constants/columns/audit'
import { withoutFilterAndSort } from '@src/utils/table'
import { getColor } from '@src/styles/colors'
import { useParams } from 'react-router'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'

export interface FindingsSummaryProps {
  table: useTableReturnType<AuditInterface, Stats>
  entityType: EntityTypes
}

export const findingsSummaryFilters = [
  {
    filters: [
      { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
      { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
    ],
    columnName: 'status',
    nonResettable: true,
  },
]

export const findingsSummarySorting = [
  {
    sortBy: 'severity_v2',
    direction: SORT_DIRECTION.ASC,
  },
  {
    sortBy: 'due_date',
    direction: SORT_DIRECTION.DESC,
  },
]

const getRow = (entityType: EntityTypes): RowInterface<AuditInterface> => ({
  highlight: (data, theme) => {
    if (
      data.severity_v2?.id === AuditSeverities.Critical ||
      data.severity_v2?.id === AuditSeverities.High
    ) {
      return getColor(theme, Color.RED_OPAQUE_20)
    }
    return ''
  },
  cells: withoutFilterAndSort([
    {
      ...auditIssueLinkColumn,
      width: 100,
    },
    {
      ...auditTypeColumn,
      width: 100,
    },
    ...(entityType !== EntityTypes.employee
      ? [
          {
            ...auditAssigneeColumn,
            width: 100,
          },
        ]
      : []),
    ...(entityType === EntityTypes.department
      ? [
          {
            ...auditTeamNameColumn,
            width: 100,
          },
        ]
      : []),
    ...(entityType !== EntityTypes.employee
      ? [
          {
            ...auditCreationDateColumn,
            width: 100,
          },
        ]
      : []),
    ...(entityType === EntityTypes.employee
      ? [
          {
            ...auditSummaryColumn,
            width: 200,
          },
        ]
      : []),
    {
      ...auditSeverityColumn,
      width: 100,
    },
    {
      ...auditLatenessColumn,
      width: 100,
    },
  ]),
})

const getFormUrl = (entityType: EntityTypes, params: { id?: string }): string => {
  switch (entityType) {
    case EntityTypes.department:
      return pathToUrl(ROUTES.FORMS.DEPARTMENT.AUDIT, params)
    case EntityTypes.team:
      return pathToUrl(ROUTES.FORMS.TEAM.AUDIT, params)
    case EntityTypes.employee:
      return pathToUrl(ROUTES.FORMS.EMPLOYEE.AUDIT, params)
    default:
      return ''
  }
}

const maxItemsToShow = 5

const FindingsSummary = ({ table, entityType }: FindingsSummaryProps) => {
  const params = useParams<{ id?: string }>()
  const formUrl = getFormUrl(entityType, params)
  const row = useMemo(() => getRow(entityType), [entityType])

  return (
    <>
      <SummarySubheader formUrl={formUrl}>Findings</SummarySubheader>
      <Cell>
        <Flex flexDirection="column" width="100%">
          <FindingsStats entity={entityType} table={table} />
          <SummaryTableContainer>
            <AdjustableTable
              name={TableNames.FindingsSummary}
              row={row}
              {...table}
              data={table.data.slice(0, maxItemsToShow)}
              {...summaryTableProps}
              hideCountAndButtonSection
            />
          </SummaryTableContainer>
          {table.data.length > maxItemsToShow && (
            <Box pt="s-12">
              <Link fontWeight={500} use="a" href={formUrl} target="_blank">
                {chain('Show all', table.count)}
              </Link>
            </Box>
          )}
        </Flex>
      </Cell>
    </>
  )
}

export default FindingsSummary
