import React from 'react'
import styled from 'styled-components'
import { Box, Flex, HStack, Text, Token } from '@revolut/ui-kit'
import { useCareersSettings } from '@src/api/recruitment/careers'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import MobileMenu from '@src/pages/Careers/MobileMenu'

const Logo = styled.img`
  width: auto;
  height: 100%;
`

const Links = styled(HStack)`
  @media all and (max-width: 1024px) {
    display: none;
  }
`

const Header = () => {
  const { data: settings } = useCareersSettings()

  return (
    <Box
      p="s-8"
      width="100%"
      borderBottom="1px solid"
      borderBottomColor={Token.color.greyTone10}
      height={64}
      mb="s-72"
      style={{ flexShrink: 0, flexGrow: 0 }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        maxWidth="1000px"
        m="auto"
        height="100%"
      >
        <HStack gap="s-40" align="center" height="100%">
          {settings?.company_logo && (
            <a href={PUBLIC_ROUTES.CAREERS.JOB_LIST} style={{ height: '100%' }}>
              <Logo src={settings.company_logo.url} title={settings.company_logo.name} />
            </a>
          )}

          <Links gap="s-40" align="center" height="100%">
            {settings?.company_links?.map(item => (
              <Text
                key={item.label}
                variant="primary"
                use="a"
                href={item.url}
                target="_blank"
                rel="noreferrer noopener"
                textDecoration="none"
                color={Token.color.foreground}
                aria-label={item.label}
              >
                {item.label}
              </Text>
            ))}
          </Links>
        </HStack>

        <MobileMenu
          links={settings?.company_links}
          logoUrl={settings?.company_logo?.url}
          logoName={settings?.company_logo?.name}
        />
      </Flex>
    </Box>
  )
}

export default Header
