import React from 'react'
import { InputGroup, Text, Widget } from '@revolut/ui-kit'
import PredefinedLabel from '@src/pages/Careers/CareersApplication/PredefinedSectionLabel'
import { OptionInterface } from '@src/interfaces/selectors'
import { CareersPosition } from '@src/interfaces/careers'
import { useCareersGeneralSectionFields } from '@src/pages/Careers/hooks/useCareersGeneralSectionFields'
import { connect } from 'lape'

type Props = {
  locked?: boolean
  countries?: OptionInterface[]
  position?: CareersPosition
}

const CareersApplicationGeneral = ({ locked, position, countries }: Props) => {
  const fields = useCareersGeneralSectionFields({
    locked,
    position,
    countries,
  })

  return (
    <Widget p="s-16">
      <Text variant="h4" display="block" pb="s-16">
        General information {locked && <PredefinedLabel />}
      </Text>
      <InputGroup>{fields.map(field => field.node)}</InputGroup>
    </Widget>
  )
}

export default connect(CareersApplicationGeneral)
