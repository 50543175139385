import { ROUTES } from '@src/constants/routes'

export const BACK_URL = ROUTES.APPS.DATA_ANALYTICS.QUERIES
export const QUERY_GENERAL_URL = ROUTES.FORMS.QUERY.GENERAL

export const MATCH_REG_EXP = /{{(.*?)}}/g
export const REPLACE_REG_EXP = /[{{}}]/g
export const RUN_QUERY_TIMEOUT = 1000
export const RUN_QUERY_RESULT_TIMEOUT = 1000
export const UNKNOWN_ERROR = 'Something went wrong. Please try later.'

export const MIN_EDITOR_HEIGHT = 56
export const MIN_EDITOR_HEIGHT_WITH_PARAMS = 132
export const MIN_RESULT_HEIGHT = 180
export const RESIZE_HANDLER_HEIGHT = 12
export const PAGE_PADDING_BOTTOM = 16
export const SCHEMA_NODE_MARGIN = 16

export const CHART_MARGIN = { top: 20, right: 25, bottom: 85, left: 25 }
export const AXIS_BOTTOM = {
  tickPadding: 8,
  tickRotation: -70,
  tickSize: 0,
}
export const AXIS_LEFT = {
  tickPadding: 8,
  tickRotation: 0,
  tickSize: 0,
}
