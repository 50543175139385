import { ProbationRecommendationInterface } from '@src/interfaces/probationReview'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { Box, Button, Flex, useTooltip, VStack, Token } from '@revolut/ui-kit'
import { GradedRequestInterface } from '@src/pages/Forms/Overview/Checkpoint/CheckpointReviewers'
import React from 'react'
import AvatarSnippet from '@components/AvatarSnippet/AvatarSnippet'
import { relationToString } from '@src/features/Scorecard/constants'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'
import { SingleTimelineEventReviewInterface } from '@src/interfaces/performance'
import { navigateTo } from '@src/actions/RouterActions'
import styled from 'styled-components'

const FlexWrap = styled(Flex)<{ isLink?: boolean }>`
  border-radius: ${Token.radius.r12};
  transition: all 0.3s;
  &:hover {
    background-color: ${props => (props.isLink ? Token.color.greyTone2 : 'inherit')};
  }
`

export const StepReviewersList = ({
  reviews,
  onClickAddReview,
  buttonDisabled,
  icon,
  title,
  performanceLink,
}: {
  reviews:
    | GradedRequestInterface[]
    | ProbationRecommendationInterface[]
    | SingleTimelineEventReviewInterface[]
  onClickAddReview: (e: React.MouseEvent) => void
  buttonDisabled: boolean
  icon: ResolveIconType
  title: string
  performanceLink?: string
}) => {
  const user = useSelector(selectUser)
  const tooltip = useTooltip()

  if (!reviews?.length) {
    return null
  }

  return (
    <Box>
      <VStack>
        {reviews.map(item => {
          const userIsReviewer = item.reviewer.id === user.id
          return (
            <FlexWrap
              key={item.id}
              alignItems="center"
              justifyContent="space-between"
              style={{ cursor: performanceLink ? 'pointer' : 'default' }}
              onClick={() => {
                if (performanceLink) {
                  navigateTo(`${performanceLink}?reviewer_id=${item.reviewer.id}`)
                }
              }}
              p="s-8"
              isLink={!!performanceLink}
            >
              <AvatarSnippet
                avatar={item.reviewer.avatar}
                badge={item.status}
                fullName={`${item.reviewer.full_name} (${relationToString(
                  item.reviewer_relation,
                  true,
                )})`}
                noItemWrap
              />
              {userIsReviewer && !buttonDisabled && (
                <Button
                  useIcon={icon}
                  variant="secondary"
                  size="sm"
                  onClick={e => {
                    e.stopPropagation()
                    onClickAddReview(e)
                  }}
                  {...tooltip.getAnchorProps()}
                >
                  {title}
                </Button>
              )}
            </FlexWrap>
          )
        })}
      </VStack>
    </Box>
  )
}
