import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiWithoutHandling } from './index'
import { API, PerformanceTimeRange } from '../constants/api'
import {
  KpiBulkAssign,
  KpiInterface,
  KpiTargetEpics,
  KpiTargets,
  RelevantKpiInterface,
  RunQueryResponse,
} from '../interfaces/kpis'
import {
  GetRequestData,
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
  Statuses,
} from '../interfaces'
import { AxiosPromise } from 'axios'
import { PerformanceChartCycles } from '../interfaces/chart'
import { getMonthsByRange } from '@src/utils/kpi'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { useFetch } from '@src/utils/reactQuery'
import { getCommentsAPI } from '@src/api/comments'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import isNil from 'lodash/isNil'
import { KPITypes } from '@src/constants/table'

export const kpisRequests: RequestInterface<KpiInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      API.KPIS,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
  getStats: async ({ filters, page }) =>
    api.get(
      API.KPIS_STATS,
      {
        params: filterSortPageIntoQuery(undefined, filters, page),
      },
      'v2',
    ),
  getItem: async id => api.get(`${API.KPIS}/${id}`, undefined, 'v2'),
  patchItem: async (data, kpiId) =>
    api.patch(`${API.KPIS}/${kpiId}`, data, undefined, 'v2'),
  deleteItem: async id => api.delete(`${API.KPIS}/${id}`, undefined, 'v2'),
  postItem: async data => api.post(API.KPIS, data, undefined, 'v2'),
}

export const kpisApprovalsRequests: RequestInterface<KpiInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      API.KPIS_APPROVALS,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
  getStats: async ({ filters, page }) =>
    api.get(
      API.KPIS_STATS,
      {
        params: filterSortPageIntoQuery(undefined, filters, page),
      },
      'v2',
    ),
  getItem: async id => api.get(`${API.KPIS_APPROVALS}/${id}`, undefined, 'v2'),
  patchItem: async (data, kpiId) =>
    api.patch(`${API.KPIS_APPROVALS}/${kpiId}`, data, undefined, 'v2'),
  deleteItem: async id => api.delete(`${API.KPIS_APPROVALS}/${id}`, undefined, 'v2'),
  postItem: async data => api.post(API.KPIS_APPROVALS, data, undefined, 'v2'),
}

export const validateKpi = (data: Partial<KpiInterface>) =>
  apiWithoutHandling.post<KpiInterface>(`${API.KPIS}/validate`, data)

export const kpisRequestsNew: RequestInterfaceNew<KpiInterface> = {
  get: async ({ id }) => api.get(`${API.KPIS}/${id}`, undefined, 'v2'),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.KPIS}/${id}`, data, undefined, 'v2'),
  delete: async ({ id }) => api.delete(`${API.KPIS}/${id}`, undefined, 'v2'),
  submit: async data => apiWithoutHandling.post(API.KPIS, data, undefined, 'v2'),
}

export const kpiTargetsRequestsNew: RequestInterfaceNew<KpiTargets | KpiTargetEpics> = {
  get: async ({ id }) => api.get(`${API.KPI_TARGETS}/${id}`),
  update: async (data, { id }, initialData) => {
    if (data.is_top_down) {
      data.owner = initialData?.owner
    }
    return apiWithoutHandling.patch(`${API.KPI_TARGETS}/${id}`, data)
  },
  delete: async ({ id }) => api.delete(`${API.KPI_TARGETS}/${id}`),
  submit: async data => apiWithoutHandling.post(API.KPI_TARGETS, data),
}

export const getKPITargetsListRequest = <T = KpiTargets>(
  kpiId: number | string,
  cycleOffset?: number | string,
): (() => ReturnType<RequestInterface<T>['getItems']>) => {
  return () =>
    api.get<GetRequestInterface<T>>(API.KPI_TARGETS, {
      params: {
        kpi__id: kpiId,
        ...(!isNil(cycleOffset)
          ? {
              review_cycle__offset: cycleOffset,
            }
          : {}),
      },
    })
}

export const useGetKPITargets = <T = KpiTargets | KpiTargetEpics>(
  kpiId: number | string | null,
) => {
  return useFetch<GetRequestData<T>>(
    API.KPI_TARGETS,
    'v1',
    {
      params: { kpi__id: kpiId },
    },
    false,
    {
      enabled: !!kpiId,
    },
  )
}

export const useGetKPI = (id: number | string | null) => {
  return useFetch<KpiInterface>(id ? `${API.KPIS}/${id}` : null)
}

export const useGetKPIForGoal = (goalId: number) => {
  return useFetch<GetRequestData<KpiInterface>>(`${API.KPIS}?goal__id=${goalId}`)
}

export const companyKpiRequests: RequestInterface<KpiInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      API.COMPANY_KPIS,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
  getItem: async id => api.get(`${API.COMPANY_KPIS}/${id}`, undefined, 'v2'),
  patchItem: async (data, kpiId) =>
    api.patch(`${API.COMPANY_KPIS}/${kpiId}`, data, undefined, 'v2'),
  deleteItem: async id => api.delete(`${API.COMPANY_KPIS}/${id}`, undefined, 'v2'),
  postItem: async data => api.post(API.COMPANY_KPIS, data, undefined, 'v2'),
  getStats: async ({ filters, page }) =>
    api.get(API.COMPANY_GOALS_STATS, {
      params: filterSortPageIntoQuery(undefined, filters, page),
    }),
}

export const relevantKpisRequests = (
  employeeId: number | string,
): RequestInterface<RelevantKpiInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}${API.KPIS}`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
  getItem: async id => api.get(`${API.KPIS}/${id}`, undefined, 'v2'),
  patchItem: async (data, id) => api.patch(`${API.KPIS}/${id}`, data, undefined, 'v2'),
  deleteItem: async id => api.delete(`${API.KPIS}/${id}`, undefined, 'v2'),
  postItem: async data => api.post(API.KPIS, data, undefined, 'v2'),
})

export const getAllRelevantKpis = (
  employeeId: number | string,
  { sortBy, filters, page }: FetchDataQueryInterface,
) => {
  return api.get<KpiInterface[]>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}${API.KPIS}/relevant`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  ) as unknown as AxiosPromise<GetRequestInterface<KpiInterface>>
}

export const testSqlQuery = (
  query?: string | null,
  id?: number,
  database?: string,
  look_url?: string | null,
): AxiosPromise<RunQueryResponse> =>
  apiWithoutHandling.post(`${API.KPIS}/query`, {
    query,
    id,
    query_db: database,
    look_url,
  })

export const getKpiPerformanceGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
) =>
  api.get<PerformanceChartCycles>(`${API.KPIS}/${id}/performanceCycles`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })

export const updateKPI = (id: number) => api.patch(`${API.KPIS}/${id}/progress`)

// TODO: hopefully someone will be so kind to implement it on the backend again
// export const useGetKPIApprovals = (id: number) => {
//   return useFetch<ApprovalFlowResponse>(`${API.KPIS}/${id}/approvals`)
// }

export const useGetKPITargetApprovals = (targetId: number | null) => {
  return useFetch<ApprovalFlowResponse>(
    targetId ? `${API.KPI_TARGETS}/${targetId}/approvals` : null,
  )
}

export const getKPIsSelector = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<KpiInterface[]> =>
  api.get(
    `${API.KPIS}/selectors`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const getKPIsParentSelector = (
  cycleOffset: number | string,
  entityType: KPITypes,
  entityId: number,
): AxiosPromise<KpiInterface[]> =>
  api.get(`${API.KPIS}/parentSelector`, {
    params: {
      review_cycle__offset: cycleOffset,
      type: entityType,
      entity_id: entityId,
    },
  })

export const getEmployeeAvailableKPIs = (
  employeeId: number | string,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<KpiInterface[]> =>
  api.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}${API.KPIS}/all`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const bulkAssignEmployeeKPIs = (
  employeeId: number | string,
  parentKPIs: KpiInterface[],
): Promise<KpiBulkAssign> => {
  return api
    .post<KpiBulkAssign>(`${API.KPIS}/individual/assign`, {
      employee: { id: employeeId },
      kpis: parentKPIs,
    })
    .then(response => response.data)
}

export const kpiBulkApprove = (
  kpis: KpiInterface[],
  approvalStep: string,
  reviewCycleId: number | string,
) => {
  return api.post(`${API.KPIS}/updateStatusBulk`, {
    kpis,
    approval_step: approvalStep,
    status: Statuses.approved,
    review_cycle: { id: Number(reviewCycleId) },
  })
}

export const getKPICommentsAPI = (kpiId: string | number) =>
  getCommentsAPI({ baseUrl: `${API.KPIS}/${kpiId}/comments` })

export const refreshKPIs = () => api.post(`/kpis/refresh`)

export const getKPIsCSV = (cycleId: number | string) => {
  return api.get(`${API.KPIS}/csv`, {
    params: {
      cycle__id: cycleId,
    },
  })
}

export const refreshEntityKPIs = ({ filters, page }: FetchDataQueryInterface) => {
  return api.post(
    `${API.KPIS}/refreshEntity`,
    {},
    {
      params: filterSortPageIntoQuery(undefined, filters, page),
    },
  )
}

export const linkExampleKPIs = () => {
  return api.post(`${API.KPIS}/linkToCycles`)
}
