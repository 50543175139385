import React from 'react'
import { RoleInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { Action, Box, DetailsCell, Flex, Group, Subheader, Widget } from '@revolut/ui-kit'
import Loader from '@components/CommonSC/Loader'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  hiringProcessEligibleInterviewsLinkColumn,
  hiringProcessPlaybookColumn,
  hiringProcessScorecardLinkColumn,
  hiringProcessSkillsColumn,
  hiringProcessTitleWithLockColumn,
} from '@src/constants/columns/hiringProcess'
import { Queries } from '@src/constants/api'
import { useQuery } from '@src/utils/queryParamsHooks'
import PreviewStageSidebar from '@src/pages/Forms/RoleForm/Preview/PreviewStageSidebar/index'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import RoleSubmitButton from '@src/pages/Forms/RoleForm/Buttons/RoleSubmitButton'
import StickyContainerLape from '@src/features/Form/Containers/StickyContainer'
import { useParams } from 'react-router-dom'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'
import JobDescription from '@src/pages/Forms/RoleForm/JobDescription'
import SettingsButtons, {
  ApproveButton,
  ArchiveButton,
  CopyButton,
  RejectButton,
} from '@src/features/SettingsButtons'
import { silentDeleteRole } from '@src/api/roles'
import { Statuses } from '@src/interfaces'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { parseLegacyApprovalSteps } from '@src/utils/approvalFlow'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { TableNames } from '@src/constants/table'
import {
  useGetHiringProcessSettings,
  useGetOrganisationSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { PermissionTypes } from '@src/store/auth/types'
import LapeDeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'
import { SectionOptions } from '@src/interfaces/customFields'

const HiringProcessRow: RowInterface<HiringProcessInterface> = {
  cells: [
    {
      ...hiringProcessTitleWithLockColumn,
      width: 188,
    },
    {
      ...hiringProcessSkillsColumn,
      width: 144,
    },
    {
      ...hiringProcessPlaybookColumn,
      width: 100,
    },
    {
      ...hiringProcessScorecardLinkColumn,
      width: 134,
    },
    {
      ...hiringProcessEligibleInterviewsLinkColumn,
      width: 154,
    },
  ],
}

const Preview = () => {
  const context = useLapeContext<RoleInterface>()
  const params = useParams()
  const { changeQueryParam } = useQuery()

  const { data: settings } = useGetOrganisationSettings()
  const {
    settings: { job_postings_enabled, candidates_enabled },
  } = useGlobalSettings()
  const { data: hiringProcessSettings } = useGetHiringProcessSettings()
  const approvalsEnabled = !!settings?.enable_roles_approvals

  const isNew = !context.values.id || context.values.status === Statuses.draft

  const modelPermissions = context.values.field_options.permissions || []
  const allowEdit = modelPermissions?.includes(PermissionTypes.ChangeRoles)
  const allowDelete = modelPermissions?.includes(PermissionTypes.DeleteRoles)
  const allowArchive = modelPermissions.includes(PermissionTypes.ArchiveRoles)
  const allowApprove = modelPermissions.includes(PermissionTypes.ApproveRoles)
  const allowCopy = modelPermissions.includes(PermissionTypes.AddRoles)

  const showActionsBar =
    !isNew &&
    !![allowEdit, allowDelete, allowArchive, allowCopy, allowApprove].filter(Boolean)
      .length

  if (context.loading) {
    return (
      <Flex>
        <Loader />
      </Flex>
    )
  }

  const handleRowEdit = (data: HiringProcessInterface) => {
    if (data.id) {
      changeQueryParam(Queries.StageId, `${data.id}`)
    }
  }

  const renderEditLInk = (url: string) => {
    return allowEdit ? (
      <Subheader.Side>
        <Action
          onClick={() =>
            navigateTo(
              pathToUrl(url, {
                id: context.values.id,
              }),
            )
          }
        >
          Edit
        </Action>
      </Subheader.Side>
    ) : null
  }

  return (
    <>
      <PreviewStageSidebar />
      <PageBody maxWidth={782}>
        {showActionsBar && (
          <SettingsButtons mb="s-32">
            {approvalsEnabled && allowApprove ? (
              <>
                <ApproveButton
                  isVisible={context.initialValues.status === Statuses.pending}
                  notification={{
                    updateMsg: 'Role successfully approved.',
                  }}
                />
                <RejectButton
                  isVisible={context.initialValues.status === Statuses.pending}
                  notification={{
                    updateMsg: 'Role successfully rejected.',
                  }}
                  dialog={{
                    title: 'Reasons for rejection',
                    placeholder: 'Please provide reasons for rejection',
                    fieldName: 'description',
                  }}
                />
              </>
            ) : null}
            <CopyButton
              globalPermissions={[PermissionTypes.AddRoles]}
              isVisible={allowCopy && !!context.values.id}
              cleanFields={['cv_criterias', 'hiring_process_rounds']}
              afterSubmitUrl={pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {})}
            />
            <LapeDeleteOrgUnitButton
              onAfterDelete={() => goBack(ROUTES.FUNCTION.ROLES)}
              deleteApi={silentDeleteRole}
              prefix="role"
              displayName="role"
            />
            <ArchiveButton
              isVisible={allowArchive && !!context.values.id}
              notification={{
                updateMsg: 'Role successfully archived.',
              }}
            />
          </SettingsButtons>
        )}
        {approvalsEnabled && context.values.status !== Statuses.draft && (
          <ApprovalFlow
            isLoading={!context.values.approval_flow_status}
            steps={parseLegacyApprovalSteps(
              context.values.approval_flow_status?.approval_steps,
            )}
          />
        )}
        <FormPreview<RoleInterface>
          data={context.values}
          title="About the role"
          onEdit={
            allowEdit
              ? () => navigateTo(pathToUrl(ROUTES.FORMS.ROLE.GENERAL, params))
              : undefined
          }
        >
          <Group>
            <FormPreview.Item title="Role name" field="name" />
            <FormPreview.Item title="Role owner" type="employee" field="role_manager" />
            <FormPreview.Item
              title="Function"
              field="function.name"
              to={() =>
                pathToUrl(ROUTES.FORMS.FUNCTION.SUMMARY, {
                  id: context.values.function?.id,
                })
              }
            />
            {settings?.enable_location_limitations ? (
              <>
                <FormPreview.Item<RoleInterface>
                  title="Locations"
                  field="locations"
                  insert={data =>
                    data?.locations?.length
                      ? data.locations?.map(loc => loc.name).join(', ')
                      : 'All'
                  }
                />
              </>
            ) : null}
            <FormPreview.Details title="Role mission" field="role_description" />
            <FormPreview.Details<RoleInterface>
              title="Role goals"
              field="role_goals"
              insert={data => (
                <ul style={{ paddingInlineStart: 16 }}>
                  {data.role_goals?.map((goal, id) => {
                    return <li key={id}>{goal.text}</li>
                  })}
                </ul>
              )}
            />
            <FormPreview.CustomFields
              sectionId={SectionOptions.Roles}
              roleId={context.values.id}
            />
          </Group>
        </FormPreview>
        <Subheader variant="nested">
          <Subheader.Title>Skills competency matrix</Subheader.Title>
          {renderEditLInk(ROUTES.FORMS.ROLE.COMPETENCY_MATRIX)}
        </Subheader>
        <Box mb="s-16">
          <DetailsCell>
            <DetailsCell.Title>Allowed seniorities</DetailsCell.Title>
            <DetailsCell.Content whiteSpace="pre-line">
              {context.values.seniority_min?.name || ''} -{' '}
              {context.values.seniority_max?.name || ''}
            </DetailsCell.Content>
          </DetailsCell>
        </Box>
        <Widget p="s-16">
          <CompetencyMatrixTable
            competencyMatrices={[
              {
                children: context.values.functional_competency_matrix,
                sectionTitle: 'Competency matrix',
                disabled: true,
                hideActionsColumn: true,
              },
            ]}
            minSeniority={context.values.seniority_min}
            maxSeniority={context.values.seniority_max}
          />
        </Widget>
        {candidates_enabled &&
          hiringProcessSettings?.enable_role_level_process_definition && (
            <>
              <Subheader variant="nested">
                <Subheader.Title>Hiring process</Subheader.Title>
                {renderEditLInk(ROUTES.FORMS.ROLE.HIRING_PROCESS)}
              </Subheader>
              <Widget p="s-16">
                <AdjustableTable<HiringProcessInterface>
                  name={TableNames.RoleHiringStages}
                  dataType="Hiring stage"
                  hideCount
                  row={HiringProcessRow}
                  data={context.values?.hiring_process_rounds}
                  count={context.values?.hiring_process_rounds?.length || 0}
                  onRowClick={handleRowEdit}
                  noDataMessage="Hiring stages will appear here."
                />
              </Widget>
            </>
          )}

        {job_postings_enabled && (
          <>
            <Subheader variant="nested">
              <Subheader.Title>Job posting</Subheader.Title>
            </Subheader>
            <JobDescription type="role" />
          </>
        )}

        <StickyContainerLape sticky isCenter maxWidth={624}>
          {(!context.values?.id || context.values.status === Statuses.draft) && (
            <>
              <RoleSaveDraftButton
                title="role"
                pathInLocalStorage={pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {})}
                pathAfterSave={ROUTES.FORMS.ROLE.PREVIEW}
                isNew
                notification={{
                  path: ROUTES.FORMS.ROLE.GENERAL,
                  updateMsg: 'Role draft successfully updated.',
                  createMsg: 'Role draft successfully created.',
                }}
              />
              <RoleSubmitButton
                isNew
                notification={{
                  path: ROUTES.FORMS.ROLE.GENERAL,
                  updateMsg: 'Role successfully updated.',
                  createMsg: 'Role successfully created.',
                }}
              />
            </>
          )}
        </StickyContainerLape>
      </PageBody>
    </>
  )
}

export default connect(Preview)
