import React, { useState } from 'react'
import { Flex, LineClamp, Text, TextButton } from '@revolut/ui-kit'

export const EvidenceDescription = ({ text }: { text: string }) => {
  const isLongComment = text.split('\n').length > 3 || text.length > 300
  const [showMore, setShowMore] = useState<boolean>(false)

  const toggle = () => setShowMore(!showMore)

  return isLongComment ? (
    <Flex flexDirection="column">
      {showMore ? (
        <Text variant="caption">{text}</Text>
      ) : (
        <LineClamp max={3}>
          <Text variant="caption">{text}</Text>
        </LineClamp>
      )}
      <TextButton onClick={toggle} fontSize="14px" height="20px" mt="s-8">
        {showMore ? 'Show less' : 'Show more'}
      </TextButton>
    </Flex>
  ) : (
    <Text variant="caption">{text}</Text>
  )
}
