import { ROUTES } from '@src/constants/routes'
import EmployeeTable from '@src/pages/People/PeopleSubTabs/Employees/EmployeeTable'
import OnboardingEmployeesTable from '@src/pages/People/PeopleSubTabs/OnboardingEmployees/OnboardingEmployeesTable'
import NotificationTable from '@src/pages/People/PeopleSubTabs/Notifications/NotificationsTable'
import { GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import ResignationsTable from '@src/pages/People/PeopleSubTabs/Resignations/ResignationsTable'
import Offboarding from '@src/pages/People/PeopleSubTabs/Offboarding/OffboardingTable'
import AttendancePolicies from '@src/pages/People/PeopleSubTabs/Attendance/Policies'
import AttendanceScheduler from '@src/pages/People/PeopleSubTabs/Attendance/Scheduler'
import AttendanceShiftBreakdown from '@src/pages/People/PeopleSubTabs/Attendance/ShiftBreakdown'
import AttendanceShiftSummary from '@src/pages/People/PeopleSubTabs/Attendance/ShiftSummary'
import TimeOffPolicies from '@src/pages/People/PeopleSubTabs/TimeOffPage/Policies'
import TimeOffPublicHolidays from '@src/pages/People/PeopleSubTabs/TimeOffPage/PublicHolidays'
import TimeOffRegimes from '@src/pages/People/PeopleSubTabs/TimeOffPage/Regimes'
import TimeOffRequests from '@src/pages/People/PeopleSubTabs/TimeOffPage/Requests'
import { TimeOffCategories } from '@src/pages/People/PeopleSubTabs/TimeOffPage/Categories'
import { AllDocuments } from '@src/pages/People/PeopleSubTabs/Documents/AllDocuments'
import DocumentCategories from '@src/pages/People/PeopleSubTabs/Documents/Categories'
import DocumentTemplates from '@src/pages/People/PeopleSubTabs/Documents/Templates'
import DocumentRules from '@src/pages/People/PeopleSubTabs/Documents/Rules'
import CompensationBenchmarks from '@src/pages/People/PeopleSubTabs/Compensation/Benchmarks'
import { Bonuses } from '@src/pages/People/PeopleSubTabs/Compensation/Bonuses'
import BenefitEnrolment from '@src/pages/People/PeopleSubTabs/Benefits/Enrolment'
import BenefitTemplates from '@src/pages/People/PeopleSubTabs/Benefits/Templates'
import { ContractsTable } from '@src/pages/People/PeopleSubTabs/Contracts/ContractsTable'
import { HomeSectionId, TabBarWithPinningTabInterface } from '@src/interfaces/tabPinning'
import BackgroundJobs from '@src/pages/People/PeopleSubTabs/BackgroundJobs/BackgroundJobs'

export const peopleSubtabs: TabBarWithPinningTabInterface[] = [
  {
    id: HomeSectionId.employees,
    title: 'Employees',
    path: ROUTES.PEOPLE.EMPLOYEES,
    url: ROUTES.PEOPLE.EMPLOYEES,
    permission: PermissionTypes.ViewEmployees,
    component: EmployeeTable,
  },
  {
    id: HomeSectionId.contracts,
    title: 'Contracts',
    permission: PermissionTypes.ViewContractsTab,
    path: ROUTES.PEOPLE.CONTRACTS,
    url: ROUTES.PEOPLE.CONTRACTS,
    component: ContractsTable,
  },
  {
    id: HomeSectionId.offboarding,
    title: 'Offboarding',
    path: ROUTES.PEOPLE.OFFBOARDING,
    url: ROUTES.PEOPLE.OFFBOARDING,
    permission: PermissionTypes.ViewOffboardingV2,
    component: Offboarding,
  },
  {
    id: HomeSectionId.onboarding,
    title: 'Onboarding',
    path: ROUTES.PEOPLE.ONBOARDING,
    url: ROUTES.PEOPLE.ONBOARDING,
    permission: PermissionTypes.ViewEmployeeHROnboardingProcess,
    component: OnboardingEmployeesTable,
  },
  {
    id: HomeSectionId.resignations,
    title: 'Resignations',
    path: ROUTES.PEOPLE.RESIGNATIONS,
    url: ROUTES.PEOPLE.RESIGNATIONS,
    permission: PermissionTypes.ViewResignation,
    globalSetting: GlobalSettings.ResignationsEnabled,
    component: ResignationsTable,
  },
  {
    id: HomeSectionId.documents,
    title: 'Documents',
    path: ROUTES.PEOPLE.DOCUMENTS.ANY,
    url: ROUTES.PEOPLE.DOCUMENTS.DOCUMENTS,
    subtabs: [
      {
        id: 'documents',
        path: ROUTES.PEOPLE.DOCUMENTS.DOCUMENTS,
        to: ROUTES.PEOPLE.DOCUMENTS.DOCUMENTS,
        title: 'Documents',
        permission: PermissionTypes.ViewEmployeeDocuments,
        component: AllDocuments,
      },
      {
        id: 'categories',
        path: ROUTES.PEOPLE.DOCUMENTS.CATEGORIES,
        to: ROUTES.PEOPLE.DOCUMENTS.CATEGORIES,
        title: 'Categories',
        permission: PermissionTypes.ViewDocumentCategories,
        component: DocumentCategories,
      },
      {
        id: 'templates',
        path: ROUTES.PEOPLE.DOCUMENTS.TEMPLATES,
        to: ROUTES.PEOPLE.DOCUMENTS.TEMPLATES,
        title: 'Templates',
        permission: PermissionTypes.ViewDocumenttemplates,
        globalSetting: GlobalSettings.DocumentsTemplatesEnabled,
        component: DocumentTemplates,
      },
      {
        id: 'rules',
        path: ROUTES.PEOPLE.DOCUMENTS.RULES,
        to: ROUTES.PEOPLE.DOCUMENTS.RULES,
        title: 'Rules',
        permission: PermissionTypes.ViewDocumenttemplaterules,
        globalSetting: GlobalSettings.DocumentsTemplatesEnabled,
        component: DocumentRules,
      },
    ],
  },
  {
    id: HomeSectionId.notifications,
    title: 'Notifications',
    permission: PermissionTypes.ViewTemplatedNotifications,
    path: ROUTES.PEOPLE.NOTIFICATIONS,
    url: ROUTES.PEOPLE.NOTIFICATIONS,
    component: NotificationTable,
  },
  {
    id: HomeSectionId.benefits,
    title: 'Benefits',
    permission: PermissionTypes.ViewBenefitsTemplate,
    path: ROUTES.PEOPLE.BENEFITS.ANY,
    url: ROUTES.PEOPLE.BENEFITS.ENROLMENT,
    globalSetting: GlobalSettings.BenefitsEnabled,
    subtabs: [
      {
        id: 'enrolment',
        title: 'Enrolment',
        path: ROUTES.PEOPLE.BENEFITS.ENROLMENT,
        to: ROUTES.PEOPLE.BENEFITS.ENROLMENT,
        component: BenefitEnrolment,
        permission: PermissionTypes.ViewBenefitsTemplate,
      },
      {
        id: 'templates',
        title: 'Templates',
        path: ROUTES.PEOPLE.BENEFITS.TEMPLATES,
        to: ROUTES.PEOPLE.BENEFITS.TEMPLATES,
        component: BenefitTemplates,
        permission: PermissionTypes.ViewBenefitsTemplate,
      },
    ],
  },
  {
    id: HomeSectionId.compensation,
    title: 'Compensation',
    path: ROUTES.PEOPLE.COMPENSATION.ANY,
    url: ROUTES.PEOPLE.COMPENSATION.BANDS,
    globalSetting: GlobalSettings.CompensationEnabled,
    subtabs: [
      {
        id: 'bands',
        title: 'Bands',
        path: ROUTES.PEOPLE.COMPENSATION.BANDS,
        to: ROUTES.PEOPLE.COMPENSATION.BANDS,
        component: CompensationBenchmarks,
        permission: PermissionTypes.ViewBenchmarks,
      },
      {
        id: 'bonuses',
        title: 'Bonuses',
        path: ROUTES.PEOPLE.COMPENSATION.BONUSES,
        to: ROUTES.PEOPLE.COMPENSATION.BONUSES,
        component: Bonuses,
        permission: PermissionTypes.ViewEmployeeBonusBulkUploadSession,
      },
    ],
  },
  {
    id: HomeSectionId.timeOff,
    title: 'Time Off',
    path: ROUTES.PEOPLE.TIME_OFF.ANY,
    url: ROUTES.PEOPLE.TIME_OFF.REQUESTS,
    globalSetting: GlobalSettings.TimeOffEnabled,
    subtabs: [
      {
        id: 'requests',
        title: 'Requests',
        path: ROUTES.PEOPLE.TIME_OFF.REQUESTS,
        to: ROUTES.PEOPLE.TIME_OFF.REQUESTS,
        component: TimeOffRequests,
        permission: PermissionTypes.ViewTimeOffRequestsOfReports,
      },
      {
        id: 'policies',
        title: 'Policies',
        path: ROUTES.PEOPLE.TIME_OFF.POLICIES,
        to: ROUTES.PEOPLE.TIME_OFF.POLICIES,
        component: TimeOffPolicies,
        permission: PermissionTypes.ViewTimeOffPolicies,
      },
      {
        id: 'regimes',
        title: 'Regimes',
        path: ROUTES.PEOPLE.TIME_OFF.REGIMES,
        to: ROUTES.PEOPLE.TIME_OFF.REGIMES,
        component: TimeOffRegimes,
        permission: PermissionTypes.ViewTimeOffRegimes,
      },
      {
        id: 'public-holidays',
        title: 'Public holidays',
        path: ROUTES.PEOPLE.TIME_OFF.PUBLIC_HOLIDAYS,
        to: ROUTES.PEOPLE.TIME_OFF.PUBLIC_HOLIDAYS,
        component: TimeOffPublicHolidays,
        permission: PermissionTypes.ViewPublicHolidays,
      },
      {
        id: 'categories',
        title: 'Categories',
        path: ROUTES.PEOPLE.TIME_OFF.CATEGORIES,
        to: ROUTES.PEOPLE.TIME_OFF.CATEGORIES,
        component: TimeOffCategories,
        permission: PermissionTypes.ViewTimeOffPolicyCategory,
      },
    ],
  },
  {
    id: HomeSectionId.attendance,
    title: 'Attendance',
    path: ROUTES.PEOPLE.ATTENDANCE.ANY,
    url: ROUTES.PEOPLE.ATTENDANCE.SCHEDULER,
    globalSetting: GlobalSettings.AttendanceEnabled,
    subtabs: [
      {
        id: 'schedule',
        title: 'Schedule',
        path: ROUTES.PEOPLE.ATTENDANCE.SCHEDULER,
        to: ROUTES.PEOPLE.ATTENDANCE.SCHEDULER,
        component: AttendanceScheduler,
        permission: PermissionTypes.ViewDirectReportsSchedule,
      },
      {
        id: 'shifts',
        title: 'Shifts',
        path: ROUTES.PEOPLE.ATTENDANCE.SHIFT_SUMMARY,
        to: ROUTES.PEOPLE.ATTENDANCE.SHIFT_SUMMARY,
        component: AttendanceShiftSummary,
        permission: PermissionTypes.ViewShiftsSummary,
      },
      {
        id: 'compensation',
        title: 'Compensation',
        path: ROUTES.PEOPLE.ATTENDANCE.SHIFT_BREAKDOWN,
        to: ROUTES.PEOPLE.ATTENDANCE.SHIFT_BREAKDOWN,
        component: AttendanceShiftBreakdown,
        permission: PermissionTypes.ViewShiftsBreakdown,
      },
      {
        id: 'policies',
        title: 'Policies',
        path: ROUTES.PEOPLE.ATTENDANCE.POLICIES,
        to: ROUTES.PEOPLE.ATTENDANCE.POLICIES,
        component: AttendancePolicies,
        permission: PermissionTypes.ViewSchedulingPolicy,
      },
    ],
  },
  {
    id: HomeSectionId.backgroundJobs,
    title: 'Background Jobs',
    path: ROUTES.PEOPLE.BACKGROUND_JOBS,
    url: ROUTES.PEOPLE.BACKGROUND_JOBS,
    component: BackgroundJobs,
    permission: PermissionTypes.ViewBackgroundJobs,
  },
]
