import React, { useState } from 'react'
import { StatusPopup, TextButton } from '@revolut/ui-kit'
import { addPromotionComment, resetPromotionStage } from '@src/api/promotions'
import PopupWithComment from '../common/PopupWithComment'

export interface ResetStageButtonProps {
  stageId: string
  employeeId: string
  onSuccess: () => void
  nominationId: string
}

export type ResetState =
  | {
      openedPopup: null
    }
  | {
      openedPopup: 'status'
      resetStatus: 'success' | 'error'
      statusMessage: string
    }
  | {
      openedPopup: 'confirm'
      pendingReopen?: boolean
    }

const ResetStageButton = ({
  stageId,
  employeeId,
  onSuccess,
  nominationId,
}: ResetStageButtonProps) => {
  const [state, setState] = useState<ResetState>({ openedPopup: null })
  const reset = async (comment: string) => {
    try {
      setState({ openedPopup: 'confirm', pendingReopen: true })
      if (comment.length) {
        // agreed with BE to ignore failed comment
        addPromotionComment(nominationId, comment).catch(() => {})
      }
      const { data } = await resetPromotionStage({ stageId, employeeId })
      onSuccess()
      setState({
        openedPopup: 'status',
        resetStatus: 'success',
        statusMessage: `${data.stage_type.name} successfully reset`,
      })
    } catch (err) {
      setState({
        openedPopup: 'status',
        resetStatus: 'error',
        statusMessage: 'Failed to reset nomination stage. Please try again',
      })
    }
  }
  return (
    <>
      <TextButton onClick={() => setState({ openedPopup: 'confirm' })}>Reset</TextButton>
      {state.openedPopup === 'status' && (
        <StatusPopup
          variant={state.resetStatus}
          open
          onClose={() => {
            setState({ openedPopup: null })
          }}
          preventUserClose={state.resetStatus === 'success'}
        >
          <StatusPopup.Title>{state.statusMessage}</StatusPopup.Title>
        </StatusPopup>
      )}
      {state.openedPopup === 'confirm' && (
        <PopupWithComment
          title="Reset nomination stage"
          subtitle="Please comment on the reason for this action. This will be added to the nomination comments."
          isOpen
          onSubmit={reset}
          onClose={() => setState({ openedPopup: null })}
          isSubmitting={!!state.pendingReopen}
        />
      )}
    </>
  )
}

export default ResetStageButton
