import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { Group, Flex, Box } from '@revolut/ui-kit'
import { getIconImage } from '@src/pages/Hub/utils'
import { APPLICATIONS } from '@src/constants/hub'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CompanyPreferencesInterface,
  RecruitmentAppsToSetup,
} from '@src/interfaces/settings'

interface App {
  id: RecruitmentAppsToSetup
  title: string
  image: string
  description: string
  isRecommended: boolean
}

const apps: App[] = [
  {
    id: 'requisitions',
    title: APPLICATIONS.requisitions.title,
    image: APPLICATIONS.requisitions.image,
    description: 'I want to manage open positions and plan headcount within my company',
    isRecommended: true,
  },
  {
    id: 'jobPostings',
    title: 'Job postings',
    image: APPLICATIONS.jobPostings.image,
    description: 'I want to publish and manage job listings',
    isRecommended: true,
  },
  {
    id: 'hiring',
    title: 'Hiring process',
    image: APPLICATIONS.hiringProcess.image,
    description: 'I want to setup my hiring processes',
    isRecommended: true,
  },
  {
    id: 'careers',
    title: 'Careers website',
    image: APPLICATIONS.careerPageSettings.image,
    description: 'I want to setup my careers website and publish job postings',
    isRecommended: true,
  },
  {
    id: 'candidates',
    title: APPLICATIONS.candidates.title,
    image: APPLICATIONS.candidates.image,
    description: 'I want to add and manage candidates',
    isRecommended: true,
  },
]

const InitialRecruitmentApps = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCompanySettings)

  const { values } = useLapeContext<CompanyPreferencesInterface>()

  useEffect(() => {
    if (values.recruitment_apps_to_setup.selected == null) {
      values.recruitment_apps_to_setup.selected = true
      values.recruitment_apps_to_setup.candidates = true
      values.recruitment_apps_to_setup.careers = true
      values.recruitment_apps_to_setup.hiring = true
      values.recruitment_apps_to_setup.jobPostings = true
      values.recruitment_apps_to_setup.requisitions = true
    }
  }, [])

  return (
    <>
      <Box pb="s-16">
        <SectionTitle title="Do you want to setup the Recruitment product now?" />

        <LapeBooleanRadioSwitch
          name="recruitment_apps_to_setup.selected"
          disabled={disableEdit}
          yesLabel={
            <Flex>
              Yes
              <Recommended />
            </Flex>
          }
          yesDescription="I want to set up my Recruitment applications during onboarding"
          noDescription="I’d like to skip this step for now. I will set up my Recruitmant applications later"
        />
      </Box>

      {values.recruitment_apps_to_setup.selected ? (
        <>
          <SectionTitle
            title="Which Recruitment applications you would like to  setup during onboarding?"
            subtitle="Please select Recruitment applications to setup"
          />
          <Group>
            {apps.map(app => (
              <LapeNewSwitch
                itemTypeProps={{
                  title: app.isRecommended ? (
                    <Flex>
                      {app.title} <Recommended />
                    </Flex>
                  ) : (
                    app.title
                  ),
                  description: app.description,
                  image: getIconImage(app.image, 60, 'png'),
                }}
                name={`recruitment_apps_to_setup.${app.id}`}
                disabled={disableEdit}
                key={app.id}
              />
            ))}
          </Group>
        </>
      ) : null}
    </>
  )
}

export default InitialRecruitmentApps
