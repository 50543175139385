import React from 'react'
import { Flex, BREAKPOINTS } from '@revolut/ui-kit'
import Favourites from '@components/Favourites/Favourites'
import { DashboardToDo } from '@src/pages/Home/HomeSubTabs/Dashboard/DashboardToDo'
import { PageBody } from '@components/Page/PageBody'
import Announcements from '@src/pages/Home/HomeSubTabs/Dashboard/components/Announcements/Announcements'

const Dashboard = () => {
  return (
    <PageBody maxWidth={BREAKPOINTS.xl}>
      <Flex gap="s-16" flexDirection="column" overflow={{ all: 'hidden', md: 'visible' }}>
        <Announcements />
        <DashboardToDo />
        <Favourites />
      </Flex>
    </PageBody>
  )
}

export default Dashboard
