import React from 'react'
import styled from 'styled-components'
import Cookies from 'js-cookie'
import { Flex, Link, TextButton, Token } from '@revolut/ui-kit'
import { RepairTool } from '@revolut/icons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { hasTestCycleCookie } from '@src/utils/reviewCycles'
import { cookiesApi } from '@src/utils/cookies'
import { COOKIE } from '@src/constants/api'
import { getTenantSubdomain } from '@src/utils'

const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 42px;
  font-size: 13px;
  color: ${Token.color.white};
  z-index: ${props => props.theme.zIndex.popup};
  background-color: ${Token.color.warning};
`

const TestingCycleWarningBar = () => {
  const testCycleCookie = hasTestCycleCookie()

  if (!testCycleCookie) {
    return null
  }

  const onDisableClick = () => {
    const workspace = getTenantSubdomain('TestingCycleWarningBar')

    if (workspace && Cookies.get(`${COOKIE.SANDBOX_MODE_ENABLED}_${workspace}`)) {
      cookiesApi.remove(`${COOKIE.SANDBOX_MODE_ENABLED}_${workspace}`)
    } else {
      cookiesApi.remove(COOKIE.SANDBOX_MODE_ENABLED)
    }

    window.location.reload()
  }

  return (
    <Container>
      <Flex justifyContent="center" alignItems="center" gap="s-8" height="100%">
        <RepairTool size={16} />
        Demo mode enabled. You can control it in
        <Link
          href={pathToUrl(ROUTES.SETTINGS.USER_PREFERENCES)}
          color={Token.color.white}
          textDecoration="underline"
        >
          Settings
        </Link>
        or
        <TextButton
          color={Token.color.white}
          style={{ textDecoration: 'underline' }}
          onClick={onDisableClick}
        >
          Disable now
        </TextButton>
      </Flex>
    </Container>
  )
}

export default TestingCycleWarningBar
