import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import { Box, Color, chain, Group, MoreBar } from '@revolut/ui-kit'
import { Chat, Pencil } from '@revolut/icons'
import { navigateTo } from '@src/actions/RouterActions'
import {
  approvePipGoals,
  pipCycleRequestsNew,
  pipCommentsApi,
  requestEditPipGoals,
  useGetPipCheckpoints,
} from '@src/api/pip'
import { ROUTES } from '@src/constants/routes'
import { PIP_PLAYBOOK } from '@src/constants/externalLinks'
import { CheckpointType } from '@src/interfaces/probationReview'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import {
  CycleDetailInterface,
  PipFormProps,
  PipRouteParams,
} from '@src/pages/Forms/PipV2/interfaces'
import Form from '@src/features/Form/Form'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import SettingsButtons from '@src/features/SettingsButtons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { PipComments } from './PipComments'
import { PipGoals } from './PipGoals'
import { useGetEmployeeWithManagerFilter } from '@src/api/employees'

export const PipGoalSettings = ({ employee }: PipFormProps) => {
  const { id } = useParams<PipRouteParams>()
  const { values } = useLapeContext<CycleDetailInterface>()
  const [isApproving, setIsApproving] = useState(false)
  const [isCommentsOpen, setIsCommentsOpen] = useState(false)
  const [isRequestingEdit, setIsRequestingEdit] = useState(false)

  const permissions = useSelector(selectPermissions)
  const employeeWithManager = useGetEmployeeWithManagerFilter(
    employee.id,
    'line_manager__id',
  )

  const canSetGoals =
    permissions.includes(PermissionTypes.SetGooalsEmployees) ||
    employeeWithManager.data?.field_options.permissions?.includes(
      PermissionTypes.SetGooalsEmployees,
    )
  const canManagePIPCycle = permissions.includes(PermissionTypes.ManagePipCycles)
  const canManagePIPCommitteeDecision = permissions.includes(
    PermissionTypes.ManagePipCommitteeDecision,
  )

  const commentsData = pipCommentsApi(id).useGetComments(false).data

  const { data: checkpoints } = useGetPipCheckpoints(employee.id, id)
  const goalCheckpoint = checkpoints?.checkpoints?.find?.(
    c => c.checkpoint_type === CheckpointType.Goals,
  )

  const handleEditClick = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.PIP.SETTINGS, {
        employeeId: employee.id,
        id,
      }),
    )
  }

  return (
    <>
      <PageBody>
        <SettingsButtons>
          <MoreInfoButton href={PIP_PLAYBOOK}>Open PIP Playbook</MoreInfoButton>
          <MoreBar.Action onClick={() => setIsCommentsOpen(true)} useIcon={Chat}>
            {chain('View comments', commentsData?.count)}
          </MoreBar.Action>
          {goalCheckpoint && (canManagePIPCycle || canManagePIPCommitteeDecision) && (
            <>
              <MoreBar.Action
                onClick={() => {
                  setIsRequestingEdit(true)
                  requestEditPipGoals(employee.id, goalCheckpoint.id, id).finally(() =>
                    setIsRequestingEdit(false),
                  )
                }}
                pending={isRequestingEdit}
                variant="negative"
                useIcon={Pencil}
              >
                Request edits
              </MoreBar.Action>
              <MoreBar.Action
                onClick={() => {
                  setIsApproving(true)
                  approvePipGoals(employee.id, goalCheckpoint.id, id).finally(() =>
                    setIsApproving(false),
                  )
                }}
                pending={isApproving}
                variant="negative"
              >
                Initiate PIP
              </MoreBar.Action>
            </>
          )}
        </SettingsButtons>
        <ActionWidget
          avatarColor={Color.WARNING}
          mt="s-16"
          text="Goals for PIP cycles must follow the SMART framework. Please ensure each goal is measurable with clearly defined success criteria. Goals will be reviewed prior to the start of the PIP. Please refer to the PIP playbook for more information."
          title="Setting effective PIP Goals:"
        />
        <Box mt="s-16">
          <FormPreview data={values} onEdit={handleEditClick} title="PIP cycle details">
            <Group>
              <FormPreview.Item
                field="start_date_time"
                title="Recommended Start Date"
                type="date"
              />
              <FormPreview.Item field="end_date_time" title="PIP End Date" type="date" />
              <FormPreview.Item
                field="review_checkpoints_number"
                title="Number of Checkpoints"
              />
              <FormPreview.Item field="goals_type" title="Goals Type" />
            </Group>
          </FormPreview>
        </Box>
        <Box pt="s-16">
          <LapeNewTextArea
            disabled={!canSetGoals}
            label="Performance Concerns"
            name="performance_concerns"
            required
          />
        </Box>
        <AutoStepper>
          <NewStepperTitle title="Set PIP goals" />
          <PipGoals canEditGoals={!!canSetGoals} cycleId={id} employeeId={employee.id} />
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          afterSubmitUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
            id: employee.id,
          })}
          successText="PIP details submitted for review"
          useValidator
        />
      </PageActions>
      <PipComments
        cycleId={id}
        isOpen={isCommentsOpen}
        onClose={() => setIsCommentsOpen(false)}
      />
    </>
  )
}

const PipGoalSettingsForm = ({ employee }: PipFormProps) => {
  return (
    <Form<CycleDetailInterface> api={pipCycleRequestsNew}>
      <PipGoalSettings employee={employee} />
    </Form>
  )
}

export default connect(PipGoalSettingsForm)
