import React from 'react'
import { Box, Flex, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getEmployeeDocuments } from '@src/api/accessRequests'
import {
  FetchDataQueryInterface,
  RowInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { PermissionTypes } from '@src/store/auth/types'
import { EmployeeDocumentInterface } from '@src/interfaces/documents'
import {
  documentCategory,
  documentIssueDateTime,
  documentIssuer,
  documentName,
  documentSigningDateTime,
  documentSource,
  documentStatus,
} from '@src/constants/columns/documents'
import { useTheme } from '@src/styles/theme'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { API } from '@src/constants/api'
import { api } from '@src/api'
import { OptionInterface } from '@src/interfaces/selectors'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { getDocumentHighlight, getDocumentLink } from '../ProfileSummary/common'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'
import { useGetDocumentsSettings } from '@src/api/settings'
import { AnonymizedWarning } from '../components/AnonymizedWarning'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
}

const ROW: RowInterface<EmployeeDocumentInterface> = {
  linkToForm: getDocumentLink,
  highlight: getDocumentHighlight,
  cells: [
    {
      ...documentName,
      width: 350,
    },
    {
      ...documentCategory,
      width: 150,
    },
    {
      ...documentSource,
      width: 150,
    },
    {
      ...documentIssueDateTime,
      width: 100,
    },
    {
      ...documentSigningDateTime,
      width: 200,
    },
    {
      ...documentIssuer,
      width: 290,
    },
    {
      ...documentStatus,
      width: 200,
    },
  ],
}

export const Documents = ({ data, stats }: Props) => {
  const theme = useTheme()
  const { data: documentsSettings } = useGetDocumentsSettings()
  const initialSortBy = [
    {
      sortBy: 'status',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const table = useTable<EmployeeDocumentInterface>(
    {
      getItems: (requestData: FetchDataQueryInterface) =>
        getEmployeeDocuments(requestData, data.id),
    },
    [],
    initialSortBy,
  )
  const user = useSelector(selectUser)

  const canAddDocuments = data?.field_options?.permissions?.includes(
    PermissionTypes.UploadDocuments,
  )

  const canRequestGenerateDocuments =
    data?.field_options?.permissions?.includes(PermissionTypes.HRManagerPermissions) ||
    data?.field_options?.permissions?.includes(
      PermissionTypes.DocumentsManagerPermissions,
    )

  ROW.cells[0].selectorsKey = async () => {
    const result = await api.get(`${API.EMPLOYEES}/${data.id}/documentNames`)
    if (result.data) {
      return result.data as { options: OptionInterface[] }
    }
    return { options: [] }
  }

  const handleAdd = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT.GENERAL, { employeeId: data.id }), {
      initialValues: {
        employee: { id: data.id, name: data.full_name },
        issuer: { id: user.id, name: user.full_name },
      },
    })
  }

  const handleNewRequest = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.DOCUMENT_REQUEST.GENERAL, { employeeId: data.id }),
      {
        initialValues: {
          employee: { id: data.id, name: data.full_name },
          issuer: { id: user.id, name: user.full_name },
        },
      },
    )
  }

  const handleGenerate = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.DOCUMENT_GENERATE.GENERAL, { employeeId: data.id }),
      {
        initialValues: {
          generated_by: { id: user.id, name: user.full_name },
        },
      },
    )
  }

  return (
    <>
      <AnonymizedWarning employee={data} />
      <TableWrapper>
        <Flex mb="s-24">
          <Stat label="Total docs" val={stats?.document_count} mr="s-32" />
          <Stat
            label="Pending"
            color={theme.colors.warning}
            val={stats?.pending_document_count}
          />
        </Flex>
        {canAddDocuments && (
          <Box mb="s-16">
            <MoreBar>
              {canRequestGenerateDocuments &&
              documentsSettings?.enable_document_requests ? (
                <MoreBar.Action onClick={handleNewRequest} useIcon={Plus}>
                  Request document
                </MoreBar.Action>
              ) : null}
              <MoreBar.Action onClick={handleAdd} useIcon={Plus}>
                Add document
              </MoreBar.Action>
              {canRequestGenerateDocuments &&
              documentsSettings?.enable_document_generation_from_templates ? (
                <MoreBar.Action onClick={handleGenerate} useIcon={Plus}>
                  Generate document
                </MoreBar.Action>
              ) : null}
            </MoreBar>
          </Box>
        )}
        <AdjustableTable<EmployeeDocumentInterface>
          name={TableNames.EmployeeDocuments}
          useWindowScroll
          dataType="Employee Document"
          row={ROW}
          {...table}
          noDataMessage="All employee documents will appear here."
        />
      </TableWrapper>
    </>
  )
}
