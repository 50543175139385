import React, { forwardRef } from 'react'
import styled, { keyframes } from 'styled-components'
import {
  Box,
  Color,
  Duration,
  Radius,
  Skeleton,
  SkeletonProps,
  themeColor,
  useDuration,
} from '@revolut/ui-kit'
import { RowHeight } from '@src/interfaces/table'

const moveGradient = keyframes`
  100% {
    transform: translateX(60%);
  }
`

const ROW_HEIGHT = {
  small: 32,
  medium: 40,
  large: 48,
}

const RowSkeleton = styled(Skeleton)<Pick<SkeletonProps, 'duration' | 'delay'>>`
  border-bottom: 1px solid ${themeColor(Color.GREY_TONE_8)};
  overflow: hidden;
  background: ${themeColor(Color.BACKGROUND)};

  :first-child {
    margin-top: -1px;
  }

  &::after {
    transform: translateX(-60%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 245, 255, 0) 40%,
      ${themeColor(Color.SHIMMER)} 50%,
      rgba(255, 255, 255, 0) 60%
    );
    animation-name: ${moveGradient};
  }
`

interface TableBodySkeletonProps {
  containerWidth?: number
  rowHeight: RowHeight
}

export const TableBodySkeleton = forwardRef<HTMLDivElement, TableBodySkeletonProps>(
  ({ rowHeight, containerWidth }, ref) => {
    const duration = useDuration(Duration.TABLE_SKELETON)
    return (
      <Box
        ref={ref}
        role="presentation"
        data-testid="table-body-skeleton"
        width={containerWidth}
      >
        <RowSkeleton
          radius={Radius.UNSET}
          duration={duration}
          height={ROW_HEIGHT[rowHeight]}
        />
        <RowSkeleton
          radius={Radius.UNSET}
          duration={duration}
          height={ROW_HEIGHT[rowHeight]}
          delay={200}
        />
        <RowSkeleton
          radius={Radius.UNSET}
          duration={duration}
          height={ROW_HEIGHT[rowHeight]}
          delay={400}
        />
      </Box>
    )
  },
)
