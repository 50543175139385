import React from 'react'
import styled from 'styled-components'
import Icon from '@components/Icon/Icon'
import { colorGetter } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

const LegendItem = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;
  grid-gap: 13px;

  & > svg {
    color: ${props => props.theme.colors.foreground};
  }
`

const LegendText = styled.div`
  color: ${colorGetter(Color.GREY_TONE_50)};
`

const WarningIcon = styled(Icon)`
  color: ${props => props.theme.colors.orange};
`

const WrongFunctionLegend = () => {
  return (
    <LegendItem>
      <WarningIcon type="WarningFilled" size="small" />
      <LegendText>
        Employees with wrong reporting line are highlighted in amber
      </LegendText>
    </LegendItem>
  )
}

export default WrongFunctionLegend
