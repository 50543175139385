import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react'
import { AxiosPromise } from 'axios'
import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Color,
  Flex,
  H4,
  MoreBarActionProps,
  Skeleton,
  StatusWidget,
  Text,
} from '@revolut/ui-kit'
import { ChevronDown, ChevronUp } from '@revolut/icons'

import { FetchDataQueryInterface, Stats } from '@src/interfaces/data'
import {
  GetRequestData,
  GetRequestInterface,
  IdAndName,
  UseFetchResult,
} from '@src/interfaces'
import {
  CompensationReviewInterface,
  CompensationReviewOption,
  DepartmentCompensationReviewDetailsInterface,
  EmployeeCompensationEditInterface,
} from '@src/interfaces/compensation'
import { useTable, useTableReturnType } from '@src/components/Table/hooks'
import Stat from '@src/components/Stat/Stat'
import FilterSelect, {
  FilterSelectType,
} from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { CurrencySelect } from '@src/components/CurrencySelect/CurrencySelect'
import SwitchButton from '@src/components/SwitchButton/SwitchButton'
import { selectorKeys } from '@src/constants/api'
import { useDepartmentCompensationDetails } from '@src/api/compensation'

interface BudgetDistributionContextInterface {
  yearOptions: CompensationReviewInterface[] | null
  currencyCode: string
  setCurrencyCode: React.Dispatch<React.SetStateAction<string>>
  showInLocalCurrencies: boolean
  setShowInLocalCurrencies: React.Dispatch<React.SetStateAction<boolean>>
  table: useTableReturnType<
    EmployeeCompensationEditInterface,
    Stats,
    GetRequestData<EmployeeCompensationEditInterface>
  >
  selectedCompensationReview?:
    | CompensationReviewInterface
    | DepartmentCompensationReviewDetailsInterface
  setSelectedReview: React.Dispatch<React.SetStateAction<number | undefined>>
  details: UseFetchResult<DepartmentCompensationReviewDetailsInterface>
  isEmpty: boolean
}

const BudgetDistributionContext =
  createContext<BudgetDistributionContextInterface | null>(null)

export const useBudgetDistributionContext = () => {
  const context = useContext(BudgetDistributionContext)
  if (context == null) {
    throw new Error(
      `useBudgetDistributionContext must be used within a BudgetDistributionProvider`,
    )
  }
  return context
}

export const defaultCurrency = 'GBP'

export const targetCurrencyCodeKey = 'target_currency_iso_code'

interface BudgetDistributionProviderOptions {
  api: (
    id?: number,
  ) => (
    query: FetchDataQueryInterface,
  ) => AxiosPromise<GetRequestInterface<EmployeeCompensationEditInterface>>
  optionsApi?: (
    id: string,
    currencyCode: string,
  ) => UseFetchResult<{ results: CompensationReviewInterface[] }>
  detailsApi?: (
    id: string,
    currencyCode: string,
  ) => UseFetchResult<DepartmentCompensationReviewDetailsInterface>
}

type BudgetDistributionProviderProps =
  | Pick<BudgetDistributionProviderOptions, 'api' | 'optionsApi'>
  | Pick<BudgetDistributionProviderOptions, 'api' | 'detailsApi'>

export const BudgetDistributionProvider: React.FC<BudgetDistributionProviderProps> = ({
  children,
  ...props
}) => {
  const params = useParams<{ id: string }>()

  const [selectedReview, setSelectedReview] = useState<number>()
  const [currencyCode, setCurrencyCode] = useState(defaultCurrency)
  const [showInLocalCurrencies, setShowInLocalCurrencies] = useState(false)

  const firstRefreshSkipped = useRef(false)

  const optionsApiResult =
    'optionsApi' in props && props.optionsApi
      ? props.optionsApi(params.id, currencyCode)
      : null
  const detailsApiResult =
    'detailsApi' in props && props.detailsApi
      ? props.detailsApi(params.id, currencyCode)
      : null

  const yearOptions = useMemo(() => {
    const options = optionsApiResult || detailsApiResult

    if (options == null || options.isLoading) {
      return null
    }
    if (options.data) {
      return 'results' in options.data ? options.data.results : [options.data]
    }
    return []
  }, [optionsApiResult?.data, detailsApiResult?.data])

  const selectedCompensationReview = yearOptions?.find(opt => opt.id === selectedReview)

  const fetchDetails = useDepartmentCompensationDetails(
    detailsApiResult != null ? undefined : selectedCompensationReview?.id,
  )
  const details = detailsApiResult || fetchDetails

  useEffect(() => {
    if (selectedReview) {
      if (!firstRefreshSkipped.current) {
        firstRefreshSkipped.current = true
      } else {
        table.refresh()
      }
    }
  }, [selectedReview])

  const table = useTable(
    {
      getItems: props.api(selectedReview),
    },
    [
      {
        columnName: targetCurrencyCodeKey,
        filters: [{ id: currencyCode, name: currencyCode }],
        nonResettable: true,
      },
    ],
    undefined,
    {
      disable: !selectedReview,
      disableQuery: true,
    },
  )

  useEffect(() => {
    if (yearOptions?.[0] && !selectedReview) {
      const review = yearOptions[0]
      setSelectedReview(review.id)
    }
  }, [yearOptions, selectedReview])

  const isEmpty =
    !details.isLoading &&
    ((!table.loading && table.data.length === 0) || yearOptions?.length === 0)

  const values = useMemo(
    () => ({
      yearOptions,
      currencyCode,
      setCurrencyCode,
      showInLocalCurrencies,
      setShowInLocalCurrencies,
      table,
      selectedCompensationReview,
      setSelectedReview,
      isEmpty,
      details,
    }),
    [
      yearOptions,
      currencyCode,
      setCurrencyCode,
      showInLocalCurrencies,
      setShowInLocalCurrencies,
      table,
      selectedCompensationReview,
      setSelectedReview,
      isEmpty,
      details,
    ],
  )

  return (
    <BudgetDistributionContext.Provider value={values}>
      {children}
    </BudgetDistributionContext.Provider>
  )
}

interface DepartmentStatsProps {
  selectedCompensationReview?:
    | CompensationReviewInterface
    | DepartmentCompensationReviewDetailsInterface
  isEmpty: boolean
}

export const DepartmentStats = ({
  selectedCompensationReview,
  isEmpty,
}: DepartmentStatsProps) => {
  const strongExceptionalStat =
    selectedCompensationReview != null
      ? selectedCompensationReview.headcount_strong +
        selectedCompensationReview.headcount_exceptional
      : undefined

  const averageBadStat =
    selectedCompensationReview != null
      ? selectedCompensationReview.headcount_unsatisfactory +
        selectedCompensationReview.headcount_avg_minus
      : undefined

  return (
    <>
      <Stat
        label="Headcount"
        val={isEmpty ? '-' : selectedCompensationReview?.headcount}
      />
      <Stat
        label="Strong, Exceptional"
        val={isEmpty ? '-' : strongExceptionalStat}
        color={Color.GREEN}
      />
      <Stat
        label="Avg+"
        val={isEmpty ? '-' : selectedCompensationReview?.headcount_avg_plus}
        color={Color.ORANGE}
      />
      <Stat
        label="Avg-, Unsatisfactory"
        val={isEmpty ? '-' : averageBadStat}
        color={Color.RED}
      />
    </>
  )
}

interface BudgetDistributionHeaderProps {
  stats: React.ReactNode
  onFilterChange?: () => void
}

export const BudgetDistributionHeader = (props: BudgetDistributionHeaderProps) => {
  const [yearSelectOpen, setYearSelectOpen] = useState(false)

  const {
    yearOptions,
    currencyCode,
    setCurrencyCode,
    showInLocalCurrencies,
    table,
    selectedCompensationReview,
    setSelectedReview,
    isEmpty,
  } = useBudgetDistributionContext()

  const yearSelectRef = useRef<HTMLButtonElement>(null)

  const yearSelector = async () => ({
    options:
      yearOptions?.map(option => ({
        ...option,
        name: `${option.company_compensation_review.year}`,
      })) || [],
  })

  const onCurrencyChange = (value: IdAndName) => {
    setCurrencyCode(value.name)
    if (!showInLocalCurrencies) {
      props.onFilterChange?.()
      table.onFilterChange([
        {
          columnName: targetCurrencyCodeKey,
          filters: [{ id: value.name, name: value.name }],
          nonResettable: true,
        },
      ])
    }
  }

  return (
    <Flex mb="s-24" gap="s-32">
      <Stat
        label="Cycle"
        val={
          <Box>
            <Button
              ref={yearSelectRef}
              onClick={() => setYearSelectOpen(!yearSelectOpen)}
              variant="text"
              height="fit-content"
              p={0}
            >
              {yearOptions == null ? (
                <Skeleton width={72} height="s-20" my="s-4" />
              ) : (
                <Flex alignItems="center" color="foreground">
                  <H4 use="span" fontWeight="bold">
                    {selectedCompensationReview?.company_compensation_review.year}
                  </H4>
                  {yearSelectOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                </Flex>
              )}
            </Button>
            <FilterSelect<CompensationReviewOption>
              open={yearSelectOpen}
              selector={yearSelector}
              anchorRef={yearSelectRef}
              onClose={() => setYearSelectOpen(false)}
              value={
                selectedCompensationReview && [
                  {
                    ...selectedCompensationReview,
                    name: `${selectedCompensationReview.company_compensation_review.year}`,
                  },
                ]
              }
              onChange={options => {
                const option = options[0]
                if (option) {
                  props.onFilterChange?.()
                  setSelectedReview(option.id)
                  setYearSelectOpen(false)
                }
              }}
              type={FilterSelectType.SingleSelect}
              width={100}
            />
          </Box>
        }
      />
      <CurrencySelect
        value={currencyCode}
        label="Currency"
        onCurrencyChange={onCurrencyChange}
        selector={selectorKeys.department_compensation_review_currencies}
      />
      {isEmpty ? null : props.stats}
      <DepartmentStats
        selectedCompensationReview={selectedCompensationReview}
        isEmpty={isEmpty}
      />
    </Flex>
  )
}

export const SHOW_IN_LOCAL_CURRENCIES_KEY = 'showInLocalCurrencies'

export const ShowInLocalCurrenciesButtonBase = (props: MoreBarActionProps) => {
  return <SwitchButton {...props}>Show in local currencies</SwitchButton>
}

interface ShowInLocalCurrenciesButtonProps {
  onFilterChange?: () => void
}

export const ShowInLocalCurrenciesButton = ({
  onFilterChange,
}: ShowInLocalCurrenciesButtonProps) => {
  const { currencyCode, setShowInLocalCurrencies, showInLocalCurrencies, table } =
    useBudgetDistributionContext()

  return (
    <ShowInLocalCurrenciesButtonBase
      checked={showInLocalCurrencies}
      onClick={() => {
        const nextShowInLocalCurrencies = !showInLocalCurrencies
        setShowInLocalCurrencies(nextShowInLocalCurrencies)

        onFilterChange?.()
        table.onFilterChange([
          {
            columnName: targetCurrencyCodeKey,
            filters: nextShowInLocalCurrencies
              ? []
              : [{ id: currencyCode, name: currencyCode }],
            nonResettable: true,
          },
        ])
      }}
    />
  )
}

interface EmptyStateProps {
  showDepartmentDetail?: boolean
}

export const EmptyState = ({ showDepartmentDetail }: EmptyStateProps) => {
  return (
    <StatusWidget>
      <StatusWidget.Image
        src="https://assets.revolut.com/assets/3d-images/3D098.png"
        srcSet="https://assets.revolut.com/assets/3d-images/3D098@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D098@3x.png 3x"
      />
      <StatusWidget.Title>No budget data was uploaded yet</StatusWidget.Title>
      <StatusWidget.Description>
        As soon as company budget for the cycle will be uploaded, the
        {showDepartmentDetail ? ' department' : ' '}budget will appear here.
      </StatusWidget.Description>
    </StatusWidget>
  )
}

export const getAllocatedColor = (allocated: number, total: number) => {
  if (allocated > total) {
    return Color.RED
  }
  if (allocated < total) {
    return Color.LIGHT_GREEN
  }
  return Color.FOREGROUND
}

interface AllocatedBudgetStatsProps {
  total_salary_budget?: string
  total_bonus_budget?: string
  allocated_salary_budget?: string
  allocated_bonus_budget?: string
  total_salary_budget_num?: number
  total_bonus_budget_num?: number
  allocated_bonus_budget_num?: number
  allocated_salary_budget_num?: number
}

export const AllocatedBudgetStats = ({
  total_salary_budget,
  total_bonus_budget,
  allocated_salary_budget,
  allocated_bonus_budget,
  total_salary_budget_num,
  total_bonus_budget_num,
  allocated_bonus_budget_num,
  allocated_salary_budget_num,
}: AllocatedBudgetStatsProps) => {
  return (
    <>
      <Stat
        label="Allocated salaries / Total salaries budget"
        val={
          total_salary_budget != null && allocated_salary_budget != null ? (
            <>
              <Text
                color={getAllocatedColor(
                  allocated_salary_budget_num!,
                  total_salary_budget_num!,
                )}
              >
                {allocated_salary_budget}
              </Text>{' '}
              / {total_salary_budget}
            </>
          ) : undefined
        }
      />
      <Stat
        label="Allocated bonus / Total bonus budget"
        val={
          total_bonus_budget != null && allocated_bonus_budget != null ? (
            <>
              <Text
                color={getAllocatedColor(
                  allocated_bonus_budget_num!,
                  total_bonus_budget_num!,
                )}
              >
                {allocated_bonus_budget}
              </Text>{' '}
              / {total_bonus_budget}
            </>
          ) : undefined
        }
      />
    </>
  )
}
