import React from 'react'
import {
  Cell,
  Flex,
  HStack,
  Text,
  VStack,
  Link,
  TextButton,
  Button,
} from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { differenceInDays } from 'date-fns'
import {
  CommonProps,
  getStatColor,
  MeetingsTableEmptyState,
  MeetingsWithPageParams,
  onCreateGoogleEvent,
} from '@src/pages/Forms/MeetingsWith/common'
import MeetingsTable from '@src/pages/Forms/MeetingsWith/MeetingsTable'
import { FilterByInterface, SORT_DIRECTION, SortByInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import {
  EmployeeRelation,
  MeetingInterface,
  MeetingStatus,
} from '@src/interfaces/meetings'
import { getMeetingsWithCompanionRequests } from '@src/api/meetings'
import { useParams } from 'react-router-dom'
import PageLoading from '@components/PageLoading/PageLoading'
import { PageBody } from '@components/Page/PageBody'
import { StyledLI, StyledOL } from '@components/CommonSC/General'
import { Document, Form } from '@revolut/icons'
import { ONE_TO_ONES_GUIDE } from '@src/constants/externalLinks'
import Icon from '@components/Icon/Icon'
import { useGetEmployee } from '@src/api/employees'
import { getCreateNewMeetingLink } from '@src/utils/oneToOnes'

const sorting: SortByInterface[] = [
  {
    sortBy: 'start',
    direction: SORT_DIRECTION.DESC,
  },
]
const filtering: FilterByInterface[] = [
  {
    filters: [{ id: MeetingStatus.Scheduled, name: MeetingStatus.Scheduled }],
    columnName: 'status',
    nonResettable: true,
  },
]

const Upcoming = ({ data }: CommonProps) => {
  const { companionId, employeeId } = useParams<MeetingsWithPageParams>()
  const table = useTable<MeetingInterface>(
    getMeetingsWithCompanionRequests(employeeId, companionId),
    filtering,
    sorting,
  )
  const { data: employee } = useGetEmployee(employeeId)
  const { data: companion } = useGetEmployee(companionId)
  const onCreateGoogleEventClick = () => {
    const isManager =
      data.relation === EmployeeRelation.LineManager ||
      data.relation === EmployeeRelation.FunctionalManager
    const link = getCreateNewMeetingLink(
      isManager ? employee : companion,
      isManager ? companion : employee,
    )
    onCreateGoogleEvent(link)
  }

  if (table.data.length < 1) {
    if (table.loading) {
      return <PageLoading />
    }
    return (
      <PageBody>
        <VStack space="s-16">
          <MeetingsTableEmptyState />
          <Cell>
            <Flex flexDirection="column">
              <Text use="p" mb="s-8" variant="primary">
                How to setup tracking:
              </Text>
              <StyledOL>
                <StyledLI>
                  Go through this playbook to learn how to set up and conduct meetings. We
                  recommend you follow this playbook when conducting the meetings.
                </StyledLI>
                <StyledLI>
                  Add the prefix [1:1] to the title of your google calendar meetings. It
                  might take up to 30 minutes for the information to show up here.
                </StyledLI>
                <StyledLI>
                  It is recommended to schedule a meeting which repeats at least once per
                  week. The platform will notify you if the meeting is missing.
                </StyledLI>
              </StyledOL>
              <VStack mt="s-12">
                <HStack space="s-16" py="s-16">
                  <Icon size="small" type="GoogleCalendar" />
                  <TextButton onClick={onCreateGoogleEventClick}>
                    Create google event
                  </TextButton>
                </HStack>
                <HStack space="s-16" py="s-16">
                  <Document color="primary" size={20} />
                  <Link href={ONE_TO_ONES_GUIDE} target="_blank">
                    How to conduct meetings
                  </Link>
                </HStack>
              </VStack>
            </Flex>
          </Cell>
        </VStack>
      </PageBody>
    )
  }

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <HStack mb="s-16" space="s-16">
          <Stat
            label="Days since last meeting"
            color={getStatColor(data.last_meeting_date)}
            val={
              data.last_meeting_date
                ? differenceInDays(new Date(), new Date(data.last_meeting_date))
                : '-'
            }
          />
          <Stat
            label="Avg days between meetings"
            color={getStatColor(data.avg_days_between_meetings)}
            val={data.avg_days_between_meetings || '-'}
          />
        </HStack>
        <HStack space="s-8" mb="s-16">
          <Button
            useIcon={Form}
            variant="secondary"
            size="sm"
            use="a"
            href={ONE_TO_ONES_GUIDE}
            target="_blank"
          >
            How to conduct 1:1s
          </Button>
        </HStack>
        <MeetingsTable table={table} />
      </Flex>
    </Cell>
  )
}

export default Upcoming
