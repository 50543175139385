import React from 'react'
import { DropdownController, Toolbox, Dropdown } from '@revolut/ui-kit'
import {
  LexicalEditor,
  $isRangeSelection,
  $getSelection,
  $createParagraphNode,
} from 'lexical'
import { $wrapNodes, $setBlocksType } from '@lexical/selection'
import { $createHeadingNode } from '@lexical/rich-text'
import {
  EditorH1,
  ChevronDownSmall,
  EditorH2,
  Paragraph,
  ListBullet,
  ListNumber,
  IconComponentType,
} from '@revolut/icons'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'

interface VariantPluginProps {
  editor: LexicalEditor
  blockType: BlockType
}

export const blockTypes = ['paragraph', 'h1', 'h2', 'bullet', 'number', 'check'] as const
export type BlockType = typeof blockTypes[number]

const iconMap: { [key in BlockType]: IconComponentType | undefined } = {
  paragraph: Paragraph,
  h1: EditorH1,
  h2: EditorH2,
  bullet: ListBullet,
  number: ListNumber,
  check: undefined,
}

export const VariantPlugin = ({ editor, blockType }: VariantPluginProps) => {
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatLargeHeading = () => {
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h1'))
        }
      })
    }
  }

  const formatSmallHeading = () => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h2'))
        }
      })
    }
  }

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }
  return (
    <DropdownController>
      {dropdown => (
        <>
          <Toolbox.Action
            aria-label="Text variant"
            useIcon={blockType ? iconMap[blockType] : Paragraph}
            useEndIcon={ChevronDownSmall}
            {...dropdown.getAnchorProps()}
          />
          <Dropdown autoClose {...dropdown.getTargetProps()}>
            <Dropdown.Item
              use="button"
              useIcon={Paragraph}
              color="foreground"
              onClick={formatParagraph}
              aria-pressed={blockType === 'paragraph'}
            >
              Normal
            </Dropdown.Item>
            <Dropdown.Item
              use="button"
              useIcon={EditorH1}
              color="foreground"
              onClick={formatLargeHeading}
              aria-pressed={blockType === 'h1'}
            >
              Heading 1
            </Dropdown.Item>
            <Dropdown.Item
              use="button"
              useIcon={EditorH2}
              color="foreground"
              onClick={formatSmallHeading}
              aria-pressed={blockType === 'h2'}
            >
              Heading 2
            </Dropdown.Item>
            <Dropdown.Item
              use="button"
              useIcon={ListBullet}
              color="foreground"
              onClick={formatBulletList}
              aria-pressed={blockType === 'bullet'}
            >
              Bullet List
            </Dropdown.Item>
            <Dropdown.Item
              use="button"
              useIcon={ListNumber}
              color="foreground"
              onClick={formatNumberedList}
              aria-pressed={blockType === 'number'}
            >
              Numbered List
            </Dropdown.Item>
          </Dropdown>
        </>
      )}
    </DropdownController>
  )
}
