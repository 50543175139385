import React, { useContext } from 'react'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { PayCycleReportInterface } from '@src/interfaces/payroll'
import { formatMoneyWithCode } from '@src/utils/format'
import { CurrencyContext } from '@src/apps/People/Payroll/PayCycle/PayCycleReportsTable/ReportsTableWidget'
import { getIssuesInfoString } from '@src/apps/People/Payroll/helpers'

export const payCycleReportsEmployeeColumn: ColumnInterface<PayCycleReportInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.name',
  sortKey: 'employee__name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Employee',
  insert: ({ data }) => <UserWithAvatar {...data.employee} />,
}

export const payCycleReportsChangesColumn: ColumnInterface<PayCycleReportInterface> = {
  type: CellTypes.text,
  idPoint: 'change_count',
  dataPoint: 'change_count',
  sortKey: 'change_count',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Changes',
}

export const payCycleReportsIssuesColumn: ColumnInterface<PayCycleReportInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Issues',
  insert: ({ data }) => getIssuesInfoString<PayCycleReportInterface>(data),
}

const InLocalCurrency = ({ amount }: { amount: string | number }) => {
  const currency = useContext(CurrencyContext)

  return <>{formatMoneyWithCode(Number(amount), currency)}</>
}

export const payCycleReportsGrossSalaryColumn: ColumnInterface<PayCycleReportInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'total_gross_pay',
    dataPoint: 'total_gross_pay',
    sortKey: 'total_gross_pay',
    filterKey: 'total_gross_pay',
    filterType: FilterType.range,
    selectorsKey: selectorKeys.none,
    title: 'Gross salary',
    insert: ({ data }) => <InLocalCurrency amount={data.total_gross_pay} />,
  }

export const payCycleReportsActionsTableColumn: ColumnInterface<PayCycleReportInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'actions',
    dataPoint: 'actions',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  }
