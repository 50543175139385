import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import { Text, Color, Flex, VStack, Box } from '@revolut/ui-kit'
import Form from '@src/features/Form/Form'
import {
  QuestionMainInputs,
  QuestionSettingsInputs,
} from '@src/apps/People/Engagement/Question/General'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { engagementQuestionFormRequests } from '@src/api/engagement'
import { useTheme } from '@src/styles/theme'
import { selectUser } from '@src/store/auth/selectors'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

type Props = {
  onAddQuestion: (q: EngagementQuestionInterface, closeSidebar?: boolean) => void
}
const FormContent = ({ onAddQuestion }: Props) => {
  const { values } = useLapeContext<EngagementQuestionInterface>()
  const theme = useTheme()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: user.id, full_name: user.full_name }
    }
  }, [])

  return (
    <Flex flexDirection="column">
      <VStack mb="s-32">
        <Text use="div" variant="h6" color={Color.GREY_TONE_50} mb="s-16">
          Define question
        </Text>
        <QuestionMainInputs insideSidebarPortal theme={theme} />
      </VStack>
      <VStack mb="s-32">
        <Text use="div" variant="h6" color={Color.GREY_TONE_50} mb="s-16">
          Settings
        </Text>
        <QuestionSettingsInputs insideSidebarPortal theme={theme} />
      </VStack>
      <Box px="s-16" mt="s-40">
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          onAfterSubmit={({ id }) => {
            if (id) {
              onAddQuestion({ ...values, id: Number(id) }, true)
            }
          }}
          data-testid="save-sidebar"
        >
          Save
        </NewSaveButtonWithPopup>
      </Box>
    </Flex>
  )
}

export const CreateNewQuestionTab = connect((props: Props) => (
  <Form api={engagementQuestionFormRequests} forceParams={{ id: undefined }}>
    <FormContent {...props} />
  </Form>
))
