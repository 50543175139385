import isNumber from 'lodash/isNumber'
import formatDistance from 'date-fns/formatDistance'

import { chain, Color, Token } from '@revolut/ui-kit'
import {
  EngagementDriverInterface,
  EngagementQuestionInterface,
  EngagementSurveyHistoryInterface,
  EngagementSurveyInterface,
  EngagementSurveyQuestionInterface,
} from '@src/interfaces/engagement'
import { RevolutTheme } from '@src/styles/theme'
import { Statuses } from '@src/interfaces'
import { getColor } from '@src/styles/colors'

export const mapStatusToColor = (
  data: Partial<
    EngagementSurveyInterface | EngagementDriverInterface | EngagementQuestionInterface
  >,
  theme: RevolutTheme,
) => {
  switch (data.status?.id) {
    case Statuses.active:
      return theme.colors.green
    case Statuses.scheduled:
      return theme.colors.orange
    case Statuses.archived:
    case Statuses.closed:
    case Statuses.completed:
    case Statuses.inactive:
      return theme.colors['grey-tone-50']
    default:
      return theme.colors.foreground
  }
}

export const mapSurveyRoundStatusToColor = (
  data: Partial<EngagementSurveyHistoryInterface>,
  theme: RevolutTheme,
) => {
  switch (data.status?.id) {
    case 'ongoing':
      return theme.colors.green
    case 'upcoming':
      return theme.colors.orange
    case 'terminated':
      return theme.colors.red
    default:
      return theme.colors['grey-tone-50']
  }
}

export const participationRateToColor = (theme: RevolutTheme, num?: number | null) => {
  if (!isNumber(num)) {
    return getColor(theme, Color.GREY_TONE_50)
  }

  if (num >= 80) {
    return getColor(theme, Color.GREEN)
  }
  if (num >= 50) {
    return getColor(theme, Color.WARNING)
  }

  return getColor(theme, Color.ERROR)
}

export const overallScoreToColor = (num?: number | null) => {
  if (!isNumber(num)) {
    return Token.color.greyTone50
  }
  if (num >= 80) {
    return Token.color.green
  }
  if (num >= 50) {
    return Token.color.warning
  }
  return Token.color.error
}

export const formatLastAnswered = (answerDate?: string | null) => {
  return answerDate
    ? `Last asked ${formatDistance(Date.now(), new Date(answerDate))} ago`
    : 'Was not answered yet'
}

export const getQuestionDescription = (question: EngagementQuestionInterface) =>
  chain(
    question.driver.name,
    question.type.id === 'open_ended' ? 'Open-ended' : 'Rating (1-5 scale)',
  )

export const sortSurveyQuestionsByOrder = (
  questions: EngagementSurveyQuestionInterface[] = [],
) => {
  return questions.reduce<EngagementQuestionInterface[]>(
    (acc, { order, question }, idx) => {
      if (acc[0] && order === 0) {
        // if all order's values are 0 we treat the items as if they don't need any sorting
        acc[idx] = question
      } else {
        acc[order] = question
      }
      return acc
    },
    [],
  )
}

export const getScheduleString = (values: Partial<EngagementSurveyInterface>) => {
  const firstQDay = values.first_quarter_day?.name
  const secondQDay = values.second_quarter_day?.name
  const thirdQDay = values.third_quarter_day?.name
  const fourthQDay = values.fourth_quarter_day?.name
  const firstQMonth = values.first_quarter_month?.name
  const secondQMonth = values.second_quarter_month?.name
  const thirdQMonth = values.third_quarter_month?.name
  const fourthQMonth = values.fourth_quarter_month?.name

  if (values.sent_every?.id === 'manual') {
    return values.sent_every?.name
  }
  if (values.sent_every?.id === 'week') {
    return `Every week on ${values.day_of_week?.name}`
  }
  if (values.sent_every?.id === 'two_weeks') {
    return `Every two weeks on ${values.day_of_week?.name}`
  }
  if (values.sent_every?.id === 'month') {
    return `Every month on ${values.day_of_month?.name} day`
  }
  if (values.sent_every?.id === 'quarter') {
    return `Every quarter
 (${firstQDay} of ${firstQMonth},
 ${secondQDay} of ${secondQMonth},
 ${thirdQDay} of ${thirdQMonth},
 ${fourthQDay} of ${fourthQMonth})`
  }
  if (values.sent_every?.id === 'year') {
    return `Every year on ${values.day_of_month?.name} of ${values.month?.name}`
  }
  return ''
}
