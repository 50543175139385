import React from 'react'
import { RevertRight } from '@revolut/icons'
import { MoreBar } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useGetCandidateSettings } from '@src/api/settings'

const ReferCandidateButton = () => {
  const { data: settings } = useGetCandidateSettings()

  if (!settings?.enable_referral) {
    return null
  }

  return (
    <MoreBar.Action
      useIcon={RevertRight}
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.REFER_CANDIDATE.NEW)}
    >
      Refer a candidate
    </MoreBar.Action>
  )
}

export default ReferCandidateButton
