import React from 'react'

import { OnboardingBulkDataUpload } from '../components/BulkDataUpload/OnboardingBulkDataUpload'
import { ROUTES } from '@src/constants/routes'
import { performanceConfig } from '../common/constants'
import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import {
  ImportLegacyTimelineDataInterface,
  importLegacyTimelineCompletionDate,
  importLegacyTimelineCycleName,
  importLegacyTimelineEmail,
  importLegacyTimelineFeedback,
  importLegacyTimelineGrade,
  importLegacyTimelineReviewerEmail,
  importLegacyTimelineReviewerRelation,
} from '@src/constants/columns/importLegacyTimeline'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { EmployeeEmails } from '@src/interfaces/selectors'
import { IdAndName } from '@src/interfaces'
import { generateInitialCycles } from '@src/api/reviewCycles'
import { useGetOnboardingCheckpointCategory } from '@src/api/onboardingChecklist'

export const PerformanceLegacy = () => {
  const { data } = useGetOnboardingCheckpointCategory('performance')
  const needToGenerate = !!data?.state.id && data?.state.id !== 'completed'

  const { options: employeeOptions } = useFetchOptions<EmployeeEmails>(
    selectorKeys.employee_emails,
    true,
  )
  const { options: relationOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.employee_relations,
    true,
  )
  const cellOptions = {
    employeeOptions,
    relationOptions,
  }

  return (
    <OnboardingBulkDataUpload<ImportLegacyTimelineDataInterface>
      title="Do you have past performance data you wish to record?"
      subtitle="This legacy information can be stored alongside future assessment feedback"
      importRoute={ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.UPLOAD.IMPORT}
      previousRoute={ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.GRADES}
      sessionRoute={ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.UPLOAD.SESSION}
      anyRoute={ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.UPLOAD.ANY}
      apiEndpoint={API.PERFORMANCE_UPLOADS}
      config={performanceConfig}
      currentStep="Past performance"
      category="legacy_timelines_bulk_upload"
      name="past-performance-template"
      onBeforeSubmit={needToGenerate ? generateInitialCycles : undefined}
      row={{
        cells: [
          {
            ...importLegacyTimelineEmail(cellOptions),
            width: 200,
          },
          {
            ...importLegacyTimelineGrade,
            width: 100,
          },
          {
            ...importLegacyTimelineCompletionDate,
            width: 150,
          },
          {
            ...importLegacyTimelineCycleName,
            width: 150,
          },
          {
            ...importLegacyTimelineReviewerEmail(cellOptions),
            width: 200,
          },
          {
            ...importLegacyTimelineReviewerRelation(cellOptions),
            width: 150,
          },
          {
            ...importLegacyTimelineFeedback,
            width: 200,
          },
        ],
      }}
      tableName={TableNames.ImportPerformance}
      fields={[
        {
          type: 'radio-select',
          field: 'employee_email',
          selector: selectorKeys.employee_emails,
          label: 'Line manager',
          valueKey: 'name',
          labelPath: 'full_name',
        },
        {
          type: 'input',
          field: 'grade',
          label: 'Grade',
        },
        {
          type: 'date',
          field: 'completion_date',
          label: 'Completion Date',
        },
        {
          type: 'input',
          field: 'cycle_name',
          label: 'Cycle Name',
        },
        {
          type: 'radio-select',
          field: 'reviewer_email',
          label: 'Reviewer Email',
          selector: selectorKeys.employee_emails,
          valueKey: 'name',
          labelPath: 'full_name',
        },
        {
          type: 'radio-select',
          selector: selectorKeys.employee_relations,
          valueKey: 'id',
          labelPath: 'name',
          field: 'reviewer_relation',
          label: 'Reviewer Relation',
        },
        {
          type: 'input',
          field: 'feedback',
          label: 'Feedback',
        },
      ]}
    />
  )
}
