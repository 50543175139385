import * as React from 'react'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import toString from 'lodash/toString'
import NewCodeEditor, {
  NewCodeEditorProps,
} from '@components/Inputs/NewCodeEditor/NewCodeEditor'
import NewResponsiveCodeEditor from '@components/Inputs/NewCodeEditor/NewResponsiveCodeEditor'
import { useFormValidator } from '@src/features/Form/FormValidator'

interface Props extends Omit<NewCodeEditorProps, 'value' | 'onChange'> {
  name: string
  diff?: string
  onRun?: () => void
  onSave?: () => void
  responsive?: boolean
  height?: number | string
  width?: number | string
}

const LapeCodeEditor = ({ name, responsive, ...props }: Props) => {
  const {
    value,
    error: formError,
    touched,
    apiError,
    onChange,
    hidden,
    disabled,
    changelog,
  } = useLapeField(name)
  const formValidator = useFormValidator()

  if (hidden) {
    return null
  }

  const showChangelog = !!changelog
  const error = formError || apiError
  const errorMessage = error ? toString(error) : undefined
  const validated = !!formValidator?.validated

  const EditorComponent = responsive ? NewResponsiveCodeEditor : NewCodeEditor

  return (
    <EditorComponent
      onChange={onChange}
      value={value}
      {...props}
      disabled={disabled}
      diff={showChangelog ? props.diff : undefined}
      error={validated && errorMessage ? errorMessage : (touched && error) || props.error}
      name={name}
    />
  )
}

export default connect(LapeCodeEditor)
