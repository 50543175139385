import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FilterByInterface } from '@src/interfaces/data'
import get from 'lodash/get'

export type SelectTableWrapperOnChangeData<T> = {
  thisRow?: { id: string; isSelected: boolean; data: T } | null
  selectedRowsIds: Set<string>
  selectedRowsData: T[]
  isAllSelected: boolean
  someSelected: boolean
  excludeListIds: Set<string>
}

export interface SelectionControls<T> {
  setSelection: (selectedRowsData: T[]) => void
  resetState: () => void
}

export interface SelectTableWrapperProps<T> {
  onChange?: (onChangeData: SelectTableWrapperOnChangeData<T>) => void
  children: JSX.Element | JSX.Element[]
  enabled: boolean
  primaryKey?: keyof T
  onControlsLoaded?: (selectionControls: SelectionControls<T>) => void
  filters: FilterByInterface[]
  tableDataLength: number
  selectAll?: boolean
}

export type SelectContextType = {
  selectedRowsData: Map<string, any>
  excludeList: Map<string, any>
  onSelect: (rowId: string, isSelected: boolean, rowData: any) => void
  isAllSelected: boolean
  onSelectAll: (isSelected: boolean) => void
}
export const SelectContext = React.createContext<SelectContextType>({
  selectedRowsData: new Map(),
  excludeList: new Map(),
  onSelect: () => {},
  isAllSelected: false,
  onSelectAll: () => {},
})

const selectionMapToOnChangeData = <T,>(
  map: Map<string, T>,
): Omit<SelectTableWrapperOnChangeData<T>, 'thisRow'> => ({
  selectedRowsData: Array.from(map.values()),
  selectedRowsIds: new Set(map.keys()),
  isAllSelected: false,
  someSelected: map.size > 0,
  excludeListIds: new Set(),
})

const SelectTableWrapper = <T,>({
  onChange,
  enabled,
  children,
  primaryKey = 'id' as keyof T,
  onControlsLoaded = () => {},
  filters,
  tableDataLength,
  selectAll = false,
}: SelectTableWrapperProps<T>) => {
  const mounted = useRef(false)
  const [isAllSelected, setAllSelected] = useState(selectAll)
  const [selectedRowsData, setSelectedRowsData] = useState<Map<string, T>>(new Map())
  const [excludeList, setExcludeList] = useState<Map<string, T>>(new Map())

  const resetState = () => {
    setExcludeList(new Map())
    setSelectedRowsData(new Map())
    setAllSelected(false)
    onChange?.({
      thisRow: undefined,
      selectedRowsData: [],
      selectedRowsIds: new Set(),
      isAllSelected: false,
      someSelected: false,
      excludeListIds: new Set(),
    })
  }

  useEffect(() => {
    const controls: SelectionControls<T> = {
      setSelection,
      resetState,
    }
    onControlsLoaded(controls)
  }, [])

  useEffect(() => {
    if (mounted.current) {
      resetState()
    } else {
      mounted.current = true
    }
  }, [filters])

  const onSelect: SelectContextType['onSelect'] = useCallback(
    (rowId: string, isSelected: boolean, data: T) => {
      if (isAllSelected) {
        const newDataMap = new Map(excludeList)

        if (isSelected) {
          newDataMap.delete(rowId)
        } else {
          newDataMap.set(rowId, data)
        }

        if (tableDataLength - newDataMap.size === 0) {
          setExcludeList(new Map())
          setSelectedRowsData(new Map())
          setAllSelected(false)
          onChange?.({
            thisRow: { id: rowId, isSelected, data },
            selectedRowsData: [],
            selectedRowsIds: new Set(),
            isAllSelected: false,
            someSelected: false,
            excludeListIds: new Set(),
          })
        } else {
          setExcludeList(newDataMap)
          onChange?.({
            thisRow: { id: rowId, isSelected, data },
            selectedRowsData: [],
            selectedRowsIds: new Set(),
            isAllSelected: true,
            someSelected: true,
            excludeListIds: new Set(newDataMap.keys()),
          })
        }
      } else {
        const newDataMap = new Map(selectedRowsData)

        if (isSelected) {
          newDataMap.set(rowId, data)
        } else {
          newDataMap.delete(rowId)
        }

        setSelectedRowsData(newDataMap)
        onChange?.({
          thisRow: { id: rowId, isSelected, data },
          ...selectionMapToOnChangeData<T>(newDataMap),
        })
      }
    },
    [
      isAllSelected,
      excludeList,
      selectedRowsData,
      setExcludeList,
      setSelectedRowsData,
      tableDataLength,
    ],
  )

  const onSelectAll: SelectContextType['onSelectAll'] = (isSelected: boolean) => {
    setExcludeList(new Map())
    setSelectedRowsData(new Map())
    setAllSelected(isSelected)
    onChange?.({
      isAllSelected: isSelected,
      someSelected: isSelected && tableDataLength > 0,
      selectedRowsData: [],
      selectedRowsIds: new Set(),
      excludeListIds: new Set(),
    })
  }

  const setSelection: SelectionControls<T>['setSelection'] = useCallback(rowsData => {
    const selection = new Map()

    rowsData.forEach(data => {
      const rowId = String(get(data, primaryKey))
      selection.set(rowId, data)
    })

    setSelectedRowsData(selection)
    setAllSelected(false)
    onChange?.(selectionMapToOnChangeData<T>(selection))
  }, [])

  const contextValue = useMemo(
    () => ({
      onSelect,
      onSelectAll,
      isAllSelected,
      selectedRowsData,
      excludeList,
    }),
    [onSelect, onSelectAll, selectedRowsData, isAllSelected, excludeList],
  )

  if (!enabled) {
    return <>{children}</>
  }

  return <SelectContext.Provider value={contextValue}>{children}</SelectContext.Provider>
}

export default SelectTableWrapper
