import React from 'react'
import { useSelector } from 'react-redux'
import { Flex, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { RowInterface } from '@src/interfaces/data'
import {
  timeOffCategoryDescriptionColumn,
  timeOffCategoryNameColumn,
} from '@src/constants/columns/timeOff'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@src/components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import { getTimeOffCategories } from '@src/api/timeOff'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { TimeOffCategoryInterface } from '@src/interfaces/timeOff'
import { TableWrapper } from '@src/components/Table/TableWrapper'

const ROW: RowInterface<TimeOffCategoryInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.TIME_OFF_CATEGORIES.GENERAL, { id })),
  cells: [
    {
      ...timeOffCategoryNameColumn,
      width: 200,
    },
    {
      ...timeOffCategoryDescriptionColumn,
      width: 500,
    },
  ],
}

export const TimeOffCategories = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddTimeOffPolicyCategory)

  const table = useTable({ getItems: getTimeOffCategories })

  return (
    <TableWrapper>
      <Flex mb="s-24">
        <Stat label="Total" val={table?.loading ? undefined : table?.count} />
      </Flex>
      {canAdd && (
        <Flex mb="s-16">
          <MoreBar>
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.TIME_OFF_CATEGORIES.GENERAL)}
              useIcon={Plus}
            >
              Add category
            </MoreBar.Action>
          </MoreBar>
        </Flex>
      )}
      <AdjustableTable
        name={TableNames.TimeOffCategories}
        useWindowScroll
        row={ROW}
        {...table}
        noDataMessage="Categories will appear here"
      />
    </TableWrapper>
  )
}
