import React from 'react'

import {
  Text,
  DetailsCell,
  Spinner,
  TextButton,
  chain,
  HStack,
  Color,
  TextProps,
  TextButtonProps,
} from '@revolut/ui-kit'
import { NavigationBack, NavigationForward } from '@revolut/icons'

interface Props {
  title: string
  currentPage: number
  total: number
  pending?: boolean
  onPageSelect: (page: number) => void
}

export const Pagination = (props: Props) => {
  const { title, currentPage, total, pending, onPageSelect } = props

  const StyledText = ({ children, ...rest }: TextProps) => (
    <Text variant="primary" {...rest} color={Color.GREY_TONE_50}>
      {children}
    </Text>
  )

  const StyledNavigation = ({ children, ...rest }: TextButtonProps) => (
    <TextButton color={Color.GREY_TONE_50} variant="primary" {...rest}>
      <HStack align="center">{children}</HStack>
    </TextButton>
  )

  return (
    <DetailsCell borderRadius="25px">
      <DetailsCell.Title>
        <StyledText>{chain(title, `${currentPage}/${total}`)}</StyledText>
      </DetailsCell.Title>
      <DetailsCell.Content>
        {pending ? (
          <Spinner size={16} />
        ) : (
          <HStack align="center" space="s-16">
            <StyledNavigation
              onClick={() => onPageSelect(currentPage - 1)}
              disabled={currentPage === 1 || pending}
            >
              <NavigationBack size={16} />
              <StyledText pl="s-8">Previous</StyledText>
            </StyledNavigation>
            <StyledText>·</StyledText>
            <StyledNavigation
              onClick={() => onPageSelect(currentPage + 1)}
              disabled={currentPage === total || pending}
            >
              <StyledText pr="s-8">Next</StyledText>
              <NavigationForward size={16} />
            </StyledNavigation>
          </HStack>
        )}
      </DetailsCell.Content>
    </DetailsCell>
  )
}
