import React, { useContext, useMemo, useState } from 'react'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { Flex, MoreBar, Text } from '@revolut/ui-kit'
import { ChangelogApi } from '@src/interfaces/data'
import {
  changelogRoadmapRequests,
  fieldChangelogRoadmapRequests,
  getHistoryRequest,
} from '@src/api/changelog'
import HistoryTooltip from '@components/Stepper/HistoryTooltip'
import { placement as Placement } from '@components/Tooltip/Tooltip'
import { useTheme } from '@src/styles/theme'
import { ExclamationMark, SwitchOff, SwitchOn } from '@revolut/icons'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { compareDesc } from 'date-fns'

export interface RoadmapChangelogProps {
  column: keyof RoadmapInterface
  children: React.ReactNode
  data: RoadmapInterface
  hasChanged: boolean
  tooltipPlacement?: Placement
}

interface RoadmapChangelogContextValue {
  active: boolean
}

export type UseRoadmapChangelogReturnType = {
  changelogState: RoadmapChangelogContextValue
  changelogSwitcher: React.ReactNode
}

const changelogApi: ChangelogApi = {
  form: changelogRoadmapRequests,
  field: fieldChangelogRoadmapRequests,
}

export const RoadmapChangelogContext = React.createContext<RoadmapChangelogContextValue>({
  active: false,
})

export const useRoadmapChangelog = (
  reviewCycle: ReviewCyclesInterface | null,
): UseRoadmapChangelogReturnType => {
  const [active, setActive] = useState<boolean>(false)

  const changelogState: RoadmapChangelogContextValue = useMemo(
    () => ({
      active,
    }),
    [active],
  )

  const startDate = reviewCycle?.roadmap_start_date
  const roadmapsLocked = startDate && compareDesc(new Date(), new Date(startDate)) <= 0

  const changelogSwitcher = roadmapsLocked && (
    <MoreBar.Action
      onClick={() => setActive(!active)}
      useIcon={active ? SwitchOn : SwitchOff}
      data-testid="changelog-switcher"
    >
      Highlight roadmap changes
    </MoreBar.Action>
  )

  return {
    changelogState,
    changelogSwitcher,
  }
}

const RoadmapChangelog = ({
  column,
  children,
  data,
  hasChanged,
  tooltipPlacement,
}: RoadmapChangelogProps) => {
  const theme = useTheme()
  const { active } = useContext(RoadmapChangelogContext)

  const request = useMemo(
    () => getHistoryRequest(data, column, changelogApi, true),
    [data, column],
  )

  if (data.deleted && active && column === 'status') {
    return (
      <Flex alignItems="center">
        <Text color="red">Removed from roadmap</Text>
        <Text px="s-6">
          <ExclamationMark size={16} color="red" />
        </Text>
      </Flex>
    )
  }

  if (!hasChanged || !active) {
    return <>{children}</>
  }

  return (
    <Flex alignItems="center">
      {children}
      <Text pl="s-6">
        <HistoryTooltip
          color={theme.colors.orange}
          request={request}
          placement={tooltipPlacement}
        />
      </Text>
    </Flex>
  )
}

export default RoadmapChangelog
