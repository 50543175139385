import React, { useState } from 'react'
import HiringProcessDetails from '@src/pages/OnboardingChecklist/HiringProcess/components/HiringProcessDetails'
import { HiringProcessInterface, StageType } from '@src/interfaces/hiringProccess'
import AddStageButton from '@src/pages/OnboardingChecklist/HiringProcess/components/AddStageButton'
import RoundDetailsSidebar from '@src/pages/OnboardingChecklist/HiringProcess/components/RoundDetailsSidebar'
import HiringStagePopup from '@src/pages/OnboardingChecklist/HiringProcess/components/HiringStagePopup'

type HiringStagesListProps = {
  disableAddStageButton: boolean
  isSpecialisation: boolean
  hiringProcessRounds: HiringProcessInterface[]
  onDelete: (id: number) => void
  onOrderChange: (hiringProcessRounds: HiringProcessInterface[]) => void
  onRefresh: () => void
}

const HiringStagesList = ({
  disableAddStageButton,
  isSpecialisation,
  hiringProcessRounds,
  onDelete,
  onOrderChange,
  onRefresh,
}: HiringStagesListProps) => {
  const [openPopup, setOpenPopup] = useState(false)
  const [stage, setStage] = useState<Partial<HiringProcessInterface>>()
  const [showRoundDetails, setShowRoundDetails] = useState<HiringProcessInterface>()
  return (
    <>
      <AddStageButton
        disabled={disableAddStageButton}
        onClick={stageType => {
          setStage({
            stage_type: {
              id: stageType.id as StageType,
              name: stageType.name,
            },
          })
          setOpenPopup(true)
        }}
      />
      <HiringProcessDetails
        hiringProcessRounds={hiringProcessRounds}
        onDeleteRound={({ id }) => {
          onDelete(id)
        }}
        onEditRound={hiringProcessRound => {
          setStage(hiringProcessRound)
          setOpenPopup(true)
        }}
        onOrderChange={onOrderChange}
        onShowRoundDetails={hiringProcessRound => {
          if (showRoundDetails?.id !== hiringProcessRound.id) {
            setShowRoundDetails(hiringProcessRound)
          } else {
            setShowRoundDetails(undefined)
          }
        }}
      />
      {showRoundDetails && (
        <RoundDetailsSidebar
          hiringProcessRound={showRoundDetails}
          generatedScorecard
          onClose={() => {
            setShowRoundDetails(undefined)
          }}
        />
      )}
      {openPopup && (
        <HiringStagePopup
          disableStageType
          generateScorecard
          isSpecialisation={isSpecialisation}
          stage={stage}
          onClose={() => {
            setOpenPopup(false)
            setStage(undefined)
          }}
          onRefresh={onRefresh}
        />
      )}
    </>
  )
}

export default HiringStagesList
