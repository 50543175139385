import React from 'react'
import AdjustableTable from '@components/Table/AdjustableTable'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { Statuses } from '@src/interfaces'
import { Flex, Widget } from '@revolut/ui-kit'
import { useTable } from '@components/Table/hooks'
import SearchTable from '@components/Table/SearchTable/SearchTable'
import { TableNames } from '@src/constants/table'
import { hiringPipelinesRequests } from '@src/api/recruitment/hiringPipelines'
import { HiringPipelineInterface } from '@src/interfaces/hiringPipelines'
import {
  getHiringPipelineSummaryColumn,
  hiringPipelineHiringManagerColumn,
  hiringPipelineJobPostingColumn,
  hiringPipelineLocationsColumn,
  hiringPipelineNameColumn,
  hiringPipelinePublishedStatusColumn,
  hiringPipelineRecruiterColumn,
  hiringPipelineRequisitionsColumn,
  hiringPipelineSpecialisationColumn,
} from '@src/constants/columns/hiringPipelines'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import Stat from '@components/Stat/Stat'
import { PublishingStatuses } from '@src/interfaces/jobPosting'

type TableType = 'shared' | 'own' | 'team' | 'all'

const row: (type: TableType) => RowInterface<HiringPipelineInterface> = type => ({
  linkToForm: ({ id }) => {
    navigateTo(pathToUrl(ROUTES.FORMS.HIRING_PIPELINE_OVERVIEW.CANDIDATES, { id }))
  },
  cells: [
    {
      ...hiringPipelineNameColumn,
      width: 250,
    },

    {
      ...hiringPipelineLocationsColumn,
      width: 200,
    },
    {
      ...hiringPipelineRequisitionsColumn,
      width: 250,
    },
    {
      ...hiringPipelineSpecialisationColumn,
      width: 250,
    },
    {
      ...hiringPipelineHiringManagerColumn,
      width: 150,
    },
    {
      ...hiringPipelineRecruiterColumn,
      width: 150,
    },
    {
      ...hiringPipelinePublishedStatusColumn,
      width: 150,
    },
    {
      ...getHiringPipelineSummaryColumn(type === 'own'),
      width: 80,
    },
    {
      ...hiringPipelineJobPostingColumn,
      width: 80,
    },
  ],
})

export type HiringPipelinesCommonTableProps = {
  type: TableType
  totalCount?: number
}

const HiringPipelinesCommonTable = ({
  type,
  totalCount,
}: HiringPipelinesCommonTableProps) => {
  const user = useSelector(selectUser)

  const initialFilterBy: FilterByInterface[] = [
    {
      columnName: 'job_posting__approval_status',
      filters: [
        { id: Statuses.pending, name: Statuses.pending },
        { id: Statuses.approved, name: Statuses.approved },
      ],
      nonResettable: true,
    },
    {
      columnName: 'job_posting__status',
      filters: [
        {
          id: PublishingStatuses.published_internally,
          name: PublishingStatuses.published_internally,
        },
        {
          id: PublishingStatuses.published_externally,
          name: PublishingStatuses.published_externally,
        },
        {
          id: PublishingStatuses.fully_published,
          name: PublishingStatuses.fully_published,
        },
      ],
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'id',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  if (type === 'shared') {
    initialFilterBy.push({
      columnName: 'shared_with',
      filters: [{ id: user.id, name: String(user.id) }],
      nonResettable: true,
    })
  }

  if (type === 'own') {
    initialFilterBy.push({
      columnName: 'recruiters',
      filters: [{ id: user.id, name: String(user.id) }],
      nonResettable: true,
    })
  }

  if (type === 'team') {
    initialFilterBy.push({
      columnName: 'recruiters__team__team_owner',
      filters: [{ id: user.id, name: String(user.id) }],
      nonResettable: true,
    })
  }

  if (type === 'all') {
    initialFilterBy.push({
      columnName: 'employees',
      filters: [{ id: user.id, name: String(user.id) }],
      nonResettable: true,
    })
  }

  const table = useTable<HiringPipelineInterface>(
    hiringPipelinesRequests,
    initialFilterBy,
    initialSortBy,
  )

  return (
    <Widget p="s-16" width="100%">
      <Flex flexDirection="column" width="100%">
        <Flex justifyContent="space-between">
          <Stat label="Total" val={totalCount} />
          <SearchTable
            placeholder="Search by name or title"
            mb="s-24"
            onFilter={table.onFilterChange}
          />
        </Flex>

        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<HiringPipelineInterface>
            name={TableNames.HiringPipelines}
            useWindowScroll
            dataType="Hiring pipelines"
            row={row(type)}
            {...table}
            noDataMessage="Hiring pipelines will appear here."
            hideCount
          />
        </Flex>
      </Flex>
    </Widget>
  )
}

export default HiringPipelinesCommonTable
