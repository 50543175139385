import React from 'react'
import styled from 'styled-components'
import { IdAndName } from '@src/interfaces'
import { TooltipContainer } from '@components/MultiLineChart/Chart/Tooltip'
import { Box, Color, Flex, HStack, Text, VStack } from '@revolut/ui-kit'
import { LikeDislike, QuestionOutline, SocialDislike, SocialLike } from '@revolut/icons'
import { BenchmarkId } from '@src/interfaces/engagement'
import {
  mapScoreToColor,
  NON_ANSWERED_KEY,
} from '@src/apps/People/Engagement/Results/components/constants'

type ScoresDistribution = Record<
  1 | 2 | 3 | 4 | 5,
  { percentage: string; scoresNumber: number; width: number }
>

export interface DataPoint {
  xAxis: number
  label: string
  score: number
  benchmark: IdAndName<BenchmarkId> | null
  promoters: number
  passives: number
  detractors: number
  unanswered: number
  scoresDistribution: ScoresDistribution
}

export const Divider = styled.hr`
  background-color: ${({ theme }) => theme.colors['grey-tone-50']} !important;
  height: 1px;
  border: 0;
  margin: 0;
`

const renderDistributionBars = (scoresDistribution: ScoresDistribution) => {
  return Object.entries(scoresDistribution)
    .sort(([scoreValue]) => -Number(scoreValue))
    .map(([scoreValue, distributionData], idx) => (
      <Flex key={idx} justifyContent="space-between">
        <Flex flex="1 0" alignItems="center">
          {scoreValue === NON_ANSWERED_KEY ? (
            <QuestionOutline size={15} color={Color.GREY_20} />
          ) : (
            <Text mx="s-4" variant="small" color={Color.GREY_20}>
              {scoreValue}
            </Text>
          )}
          <Flex flex="1 0" alignItems="center" px="s-8">
            <Box
              width={distributionData.width}
              height="s-4"
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              bg={mapScoreToColor[scoreValue]}
              style={{
                borderRadius: '4px',
              }}
            />
          </Flex>
        </Flex>
        <Text variant="small" color={Color.GREY_20}>
          {distributionData.scoresNumber} ({distributionData.percentage})
        </Text>
      </Flex>
    ))
}

type Props = {
  dataPoint: DataPoint
  isAverage: boolean
}
export const ChartTooltip = ({ dataPoint, isAverage }: Props) => {
  return (
    <TooltipContainer>
      <Flex flexDirection="column" width={220}>
        <Text use="div" mb="s-8" variant="h6">
          {dataPoint.label}
        </Text>
        <VStack space="s-4">
          <Flex justifyContent="space-between">
            <Text variant="small">Total score</Text>
            <Text variant="small">{dataPoint.score}</Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text variant="small" color={Color.GREY_20}>
              Company benchmark
            </Text>
            <Text variant="small" color={Color.GREY_20}>
              {dataPoint.benchmark?.name ? dataPoint.benchmark.name : 'N/A'}
            </Text>
          </Flex>
          <Box py="s-4">
            <Divider />
          </Box>
          {isAverage ? (
            <>
              <Text use="div" mb="s-8" variant="h6">
                Score distribution
              </Text>
              {renderDistributionBars(dataPoint.scoresDistribution)}
            </>
          ) : (
            <>
              <Flex justifyContent="space-between">
                <HStack space="s-8">
                  <SocialLike size={16} color={Color.GREEN} />
                  <Text variant="small" color={Color.GREY_20}>
                    Promoters
                  </Text>
                </HStack>
                <Text variant="small" color={Color.GREY_20}>
                  {dataPoint.promoters}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <HStack space="s-8">
                  <LikeDislike size={16} color={Color.YELLOW} />
                  <Text variant="small" color={Color.GREY_20}>
                    Passives
                  </Text>
                </HStack>
                <Text variant="small" color={Color.GREY_20}>
                  {dataPoint.passives}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <HStack space="s-8">
                  <SocialDislike size={16} color={Color.RED} />
                  <Text variant="small" color={Color.GREY_20}>
                    Detractors
                  </Text>
                </HStack>
                <Text variant="small" color={Color.GREY_20}>
                  {dataPoint.detractors}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <HStack space="s-8" align="center">
                  <QuestionOutline size={15} color={Color.GREY_20} />
                  <Text variant="small" color={Color.GREY_20}>
                    I don't know
                  </Text>
                </HStack>
                <Text variant="small" color={Color.GREY_20}>
                  {dataPoint.unanswered}
                </Text>
              </Flex>
            </>
          )}
        </VStack>
      </Flex>
    </TooltipContainer>
  )
}
