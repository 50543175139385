import React, { useState } from 'react'

import {
  documentDraftFormRequests,
  documentsBulkRequestFormRequests,
} from '@src/api/documents'
import { DocumentDraftInterface } from '@src/interfaces/documents'
import Form from '@src/features/Form/Form'
import { CommonTabProps } from '../../common'
import { NewDocumentSidebar } from './NewDocumentSidebar'
import { BasicsTabContent } from './BasicsTabContent'
import { SelectTemplatesSidebar } from '@src/apps/People/Documents/BulkRequest/Edit/Basics/SelectTemplatesSidebar'
import { IdAndName } from '@src/interfaces'

export const Basics = (props: CommonTabProps) => {
  const [isNewDocumentSidebarOpen, setIsNewDocumentSidebarOpen] = useState(false)
  const [isTemplatesSidebarOpen, setIsTemplatesSidebarOpen] = useState(false)

  const [createdDocuments, setCreatedDocuments] = useState<DocumentDraftInterface[]>([])
  const [selectedTemplates, setSelectedTemplates] = useState<IdAndName[]>([])

  return (
    <>
      <Form disableLocalStorageCaching api={documentsBulkRequestFormRequests}>
        <BasicsTabContent
          {...props}
          setIsNewDocumentSidebarOpen={setIsNewDocumentSidebarOpen}
          setIsTemplatesSidebarOpen={setIsTemplatesSidebarOpen}
          createdDocuments={createdDocuments}
          removeDocumentById={(documentId: number) =>
            setCreatedDocuments(createdDocuments.filter(d => d.id !== documentId))
          }
          selectedTemplates={selectedTemplates}
          removeTemplateById={(templateId: number) =>
            setSelectedTemplates(selectedTemplates.filter(t => t.id !== templateId))
          }
        />
      </Form>
      {isNewDocumentSidebarOpen && (
        <Form disableLocalStorageCaching api={documentDraftFormRequests}>
          <NewDocumentSidebar
            isOpen={isNewDocumentSidebarOpen}
            onClose={() => setIsNewDocumentSidebarOpen(false)}
            onDocumentCreated={(newDocument: DocumentDraftInterface) =>
              setCreatedDocuments([...createdDocuments, newDocument])
            }
          />
        </Form>
      )}
      <SelectTemplatesSidebar
        isOpen={isTemplatesSidebarOpen}
        onClose={() => setIsTemplatesSidebarOpen(false)}
        initTemplates={selectedTemplates}
        onSubmit={newTemplates => setSelectedTemplates(newTemplates)}
      />
    </>
  )
}
