import React, { useEffect, useState } from 'react'
import { ROUTES } from '@src/constants/routes'
import {
  InterviewFeedbackStatus,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'
import { connect } from 'lape'
import { PageWrapper } from '@components/Page/Page'
import { Box, Flex, MoreBar, Text, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import HeadCard from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/HeadCard'
import InterviewFeedbackForm from '@src/features/InterviewFeedbackForm'
import { pathToUrl } from '@src/utils/router'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import CVPreviewSidebar from '@src/features/CVPreviewSidebar/CVPreviewSidebar'
import CandidateInfoSidebar from '@components/CandidateInfoSidebar/CandidateInfoSidebar'
import { useGetCV } from '@src/api/recruitment/interviews'
import { PageBody } from '@components/Page/PageBody'
import RejectFeedbackButton from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/RejectFeedbackButton'
import { navigateTo } from '@src/actions/RouterActions'
import { PageActions } from '@components/Page/PageActions'
import SaveToDraftButton from '@src/pages/Forms/InterviewFeedback/General/SaveToDraftButton'
import SubmitButton from '@src/pages/Forms/InterviewFeedback/General/SubmitButton'
import useAutoSavingDraft from '@src/hooks/useAutoSavingDraft'
import { interviewFeedbackFormRequests } from '@src/api/recruitment/interviewFeedback'
import { Warning } from '@revolut/icons'
import usePreventUserClose from '@src/hooks/usePreventUserClose'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'

const General = () => {
  const { values } = useLapeContext<InterviewFeedbackInterface>()
  const [openedSidebar, setOpenedSidebar] = useState<'cv' | 'info' | undefined>()
  const round = values.interview_stage?.interview_round
  const candidate = round.candidate
  const { data: cv, isLoading } = useGetCV(candidate.id)
  const { lastSavedMessage, autosaveFailed, saving, hasChanges } =
    useAutoSavingDraft<InterviewFeedbackInterface>(interviewFeedbackFormRequests.update)

  usePreventUserClose(saving || hasChanges())
  useSetDocumentTitle(
    `${candidate?.full_name}, ${values.interview_stage.title}`,
    'Interview feedback',
  )

  const tooltipProps = useTooltip()

  useEffect(() => {
    if (isLoading) {
      return
    }

    setOpenedSidebar(cv ? 'cv' : 'info')
  }, [!!cv, isLoading])

  const canViewCandidate = values?.field_options?.permissions?.includes(
    PermissionTypes.ViewCandidate,
  )
  const backUrl = canViewCandidate
    ? pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
        id: candidate.id,
      })
    : pathToUrl(ROUTES.RECRUITMENT.INTERVIEWS.COMPLETED)

  const canEdit = values?.field_options?.permissions?.includes(
    PermissionTypes.ChangeInterviewFeedback,
  )

  const canCancel = !!values.field_options?.actions?.includes(EntityPermissions.Cancel)

  return (
    <PageWrapper>
      <PageBody maxWidth={720}>
        <PageHeader
          title={`${candidate?.full_name} · ${values.interview_stage.title}`}
          subtitle={
            <Text use="div" color="grey-tone-50" variant="caption">
              Interviewing for{' '}
              {round.seniority ? (
                <>
                  {round.seniority.name} - {round.role.name}
                </>
              ) : (
                round.role.name
              )}
              <Flex gap="s-4" alignItems="center">
                <div>{lastSavedMessage}</div>
                {autosaveFailed && (
                  <>
                    <Warning
                      color={Token.color.warning}
                      {...tooltipProps.getAnchorProps()}
                    />
                    <Tooltip {...tooltipProps.getTargetProps()}>
                      Auto saving failed, please save the feedback manually
                    </Tooltip>
                  </>
                )}
              </Flex>
            </Text>
          }
          backUrl={backUrl}
        />
        <MoreBar>
          {cv && (
            <MoreBar.Action onClick={() => setOpenedSidebar('cv')}>
              Open CV
            </MoreBar.Action>
          )}
          <MoreBar.Action onClick={() => setOpenedSidebar('info')}>
            View candidate info
          </MoreBar.Action>
          {canCancel &&
            (values.status === InterviewFeedbackStatus.pending ||
              values.status === InterviewFeedbackStatus.draft) && (
              <RejectFeedbackButton
                id={values.id}
                onRefresh={() => navigateTo(backUrl)}
                menuType="negative"
              />
            )}
        </MoreBar>
        <Flex
          flexDirection="row"
          width="100%"
          minHeight={0}
          mt="s-16"
          style={{ position: 'relative' }}
        >
          <Box width="100%" pb="100px" pt="s-8">
            <HeadCard data={values?.scorecard.interview_objective} />
            <InterviewFeedbackForm
              isViewMode={!canEdit}
              round={round}
              stageType={values.interview_type}
            />
          </Box>
        </Flex>
      </PageBody>
      <PageActions>
        <SaveToDraftButton />
        <SubmitButton afterSubmitUrl={backUrl} />
      </PageActions>
      <CVPreviewSidebar
        isOpen={openedSidebar === 'cv'}
        id={candidate.id}
        onClose={() => setOpenedSidebar(undefined)}
      />
      <CandidateInfoSidebar
        isOpen={openedSidebar === 'info'}
        onClose={() => setOpenedSidebar(undefined)}
        onShowCV={() => setOpenedSidebar('cv')}
        canViewCandidate={values?.field_options?.permissions?.includes(
          PermissionTypes.ViewCandidate,
        )}
        showOpenPostings
        round={values.interview_stage.interview_round}
        interviewer={values.interviewer}
      />
    </PageWrapper>
  )
}

export default connect(General)
