import { AxiosPromise } from 'axios'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { OnboardingData } from '@src/interfaces/onboarding'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { EmployeeDocumentInterface } from '@src/interfaces/documents'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getOnboardingSteps = (id: number | string): AxiosPromise<OnboardingData> =>
  api.get(`${API.EMPLOYEES}/${id}/onboarding/steps`)

export const onboardingWelcomeStepApi: RequestInterfaceNew<{}> = {
  // there is no get for welcome screen
  get: () => Promise.resolve({ data: {} } as any),
  update: async (_, { id }) => api.put(`${API.EMPLOYEES}/${id}/onboarding/welcomeStep`),
  submit: async (_, { id }) => api.put(`${API.EMPLOYEES}/${id}/onboarding/welcomeStep`),
}

export const onboardingBasicInfoApi: RequestInterfaceNew<{}> = {
  get: ({ id }) => api.get(`${API.EMPLOYEES}/${id}/onboarding/basicInfoStep`),
  update: async (_, { id }, data) =>
    api.put(`${API.EMPLOYEES}/${id}/onboarding/basicInfoStep`, data),
  submit: async (data, { id }) =>
    api.put(`${API.EMPLOYEES}/${id}/onboarding/basicInfoStep`, data),
}

export const onboardingGetEmailPropositions = (id: number) =>
  api.get<string[]>(
    `${API.EMPLOYEES}/${id}/onboarding/basicInfoStep/getEmailPropositions`,
  )

export const onboardingPersonalInfoApi: RequestInterfaceNew<{}> = {
  get: ({ id }) => api.get(`${API.EMPLOYEES}/${id}/onboarding/personalInfoStep`),
  update: async (_, { id }, data) =>
    apiWithoutHandling.put(`${API.EMPLOYEES}/${id}/onboarding/personalInfoStep`, data),
  submit: async (data, { id }) =>
    apiWithoutHandling.put(`${API.EMPLOYEES}/${id}/onboarding/personalInfoStep`, data),
}

export const onboardingPayrollApi: RequestInterfaceNew<{}> = {
  get: ({ id }) => api.get(`${API.EMPLOYEES}/${id}/onboarding/payrollStep`),
  update: async (_, { id }, data) =>
    apiWithoutHandling.put(`${API.EMPLOYEES}/${id}/onboarding/payrollStep`, data),
  submit: async (data, { id }) =>
    apiWithoutHandling.put(`${API.EMPLOYEES}/${id}/onboarding/payrollStep`, data),
}

export const onboardingDiversityApi: RequestInterfaceNew<{}> = {
  get: ({ id }) => api.get(`${API.EMPLOYEES}/${id}/onboarding/diversityStep`),
  update: async (_, { id }, data) =>
    apiWithoutHandling.put(`${API.EMPLOYEES}/${id}/onboarding/diversityStep`, data),
  submit: async (data, { id }) =>
    apiWithoutHandling.put(`${API.EMPLOYEES}/${id}/onboarding/diversityStep`, data),
}

export const onboardingDocumentsApi: RequestInterfaceNew<{}> = {
  get: ({ id }) => api.get(`${API.EMPLOYEES}/${id}/onboarding/documentsStep`),
  update: async (_, { id }, data) =>
    api.put(`${API.EMPLOYEES}/${id}/onboarding/documentsStep`, data),
  submit: async (data, { id }) =>
    api.put(`${API.EMPLOYEES}/${id}/onboarding/documentsStep`, data),
}

export const getOnboardingDocuments = (
  requestData: FetchDataQueryInterface,
  employeeId?: number | string,
): AxiosPromise<GetRequestInterface<EmployeeDocumentInterface>> => {
  const { sortBy, filters, page } = requestData
  return api.get(`${API.EMPLOYEES}/${employeeId}/onboarding/documentsStep`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })
}
