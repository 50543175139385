import React, { useMemo } from 'react'
import NotificationsAll from '@src/pages/Forms/Notifications/AllNotifications'
import NotificationsReviews from '@src/pages/Forms/Notifications/Reviews'
import NotificationsKPIs from '@src/pages/Forms/Notifications/KPIs'
import NotificationsKPIsUpload from '@src/pages/Forms/Notifications/KPIsUpload'
import NotificationsKPIsToAssign from '@src/pages/Forms/Notifications/KpisToAssign'
import NotificationsKPIsToApprove from '@src/pages/Forms/Notifications/KpisToApprove'
import NotificationsPendingDocuments from '@src/pages/Forms/Notifications/PendingDocuments'
import NotificationsRequisitions from '@src/pages/Forms/Notifications/Requisitions'
import NotificationsRoles from '@src/pages/Forms/Notifications/Roles'
import NotificationsSkills from '@src/pages/Forms/Notifications/Skills'
import NotificationsPip from '@src/pages/Forms/Notifications/PIP'
import NotificationsProbation from '@src/pages/Forms/Notifications/Probation'
import NotificationsUpwardsReviews from '@src/pages/Forms/Notifications/Upword'
import NotificationsDeliverables from '@src/pages/Forms/Notifications/Deliverables'
import NotificationsTrainings from '@src/pages/Forms/Notifications/Trainings'
import NotificationsReviewCalibration from '@src/pages/Forms/Notifications/ReviewCalibration'
import NotificationsDataAccess from '@src/pages/Forms/Notifications/DataAccess'
import NotificationsServiceDesk from '@src/pages/Forms/Notifications/ServiceDesk'
import NotificationsFindings from '@src/pages/Forms/Notifications/Findings'
import NotificationsInterviews from '@src/pages/Forms/Notifications/Interviews'
import NotificationsCatchupInterviews from '@src/pages/Forms/Notifications/CatchupInterviews'
import NotificationsCandidates from '@src/pages/Forms/Notifications/Candidates'
import OwnershipTransfer from '@src/pages/Forms/Notifications/OwnershipTransfer'
import JobPostings from '@src/pages/Forms/Notifications/JobPostings'
import TimeOff from '@src/pages/Forms/Notifications/TimeOff'
import NotificationsComments from '@src/pages/Forms/Notifications/Comments'
import NotificationsPromotion from '@src/pages/Forms/Notifications/Promotion'
import NotificationsMeetings from '@src/pages/Forms/Notifications/Meetings'
import { TaggedUsersMap } from '@src/interfaces/chat'
import { useGetInboxTaggedEmployees } from '@src/api/comments'
import { ROUTES } from '@src/constants/routes'
import BulkUpload from '@src/pages/Forms/Notifications/BulkUpload'
import GroupAccessRequest from '@src/pages/Forms/Notifications/GroupAccessRequest'
import Group from '@src/pages/Forms/Notifications/Group'
import SuccessionPlans from '@src/pages/Forms/Notifications/SuccessionPlans'

export const commonDashboardSubtabs = [
  {
    title: `To do's`,
    path: ROUTES.APPS.TODO.ALL,
    url: ROUTES.APPS.TODO.ALL,
    statPath: 'total',
    component: NotificationsAll,
  },
  {
    title: 'Comments',
    path: ROUTES.APPS.TODO.COMMENTS,
    url: ROUTES.APPS.TODO.COMMENTS,
    statPath: 'comment',
    component: NotificationsComments,
  },
  {
    title: 'Meetings',
    path: ROUTES.APPS.TODO.MEETINGS,
    url: ROUTES.APPS.TODO.MEETINGS,
    statPath: 'one_to_one_meeting',
    component: NotificationsMeetings,
  },
  {
    title: 'Reviews',
    path: ROUTES.APPS.TODO.REVIEWS,
    url: ROUTES.APPS.TODO.REVIEWS,
    statPath: 'review',
    component: NotificationsReviews,
  },
  {
    title: 'KPIs',
    path: ROUTES.APPS.TODO.KPIS,
    url: ROUTES.APPS.TODO.KPIS,
    statPath: 'kpi',
    component: NotificationsKPIs,
  },
  {
    title: 'KPIs Upload',
    path: ROUTES.APPS.TODO.KPIS_UPLOAD,
    url: ROUTES.APPS.TODO.KPIS_UPLOAD,
    statPath: 'kpi_bulk_upload',
    component: NotificationsKPIsUpload,
  },
  {
    title: 'Set KPIs',
    path: ROUTES.APPS.TODO.KPIS_TO_ASSIGN,
    url: ROUTES.APPS.TODO.KPIS_TO_ASSIGN,
    statPath: 'kpi_to_assign',
    component: NotificationsKPIsToAssign,
  },
  {
    title: 'Approve KPIs',
    path: ROUTES.APPS.TODO.KPIS_TO_APPROVE,
    url: ROUTES.APPS.TODO.KPIS_TO_APPROVE,
    statPath: 'kpi_to_approve',
    component: NotificationsKPIsToApprove,
  },
  {
    title: 'Pending Documents',
    path: ROUTES.APPS.TODO.PENDING_DOCUMENTS,
    url: ROUTES.APPS.TODO.PENDING_DOCUMENTS,
    statPath: 'document',
    component: NotificationsPendingDocuments,
  },
  {
    title: 'Requisitions',
    path: ROUTES.APPS.TODO.REQUISITIONS,
    url: ROUTES.APPS.TODO.REQUISITIONS,
    statPath: 'requisition',
    component: NotificationsRequisitions,
  },
  {
    title: 'Promotion',
    path: ROUTES.APPS.TODO.PROMOTION,
    url: ROUTES.APPS.TODO.PROMOTION,
    statPath: 'promotion',
    component: NotificationsPromotion,
  },
  {
    title: 'Roles',
    path: ROUTES.APPS.TODO.ROLES,
    url: ROUTES.APPS.TODO.ROLES,
    statPath: 'role',
    component: NotificationsRoles,
  },
  {
    title: 'Skills',
    path: ROUTES.APPS.TODO.SKILLS,
    url: ROUTES.APPS.TODO.SKILLS,
    statPath: 'skill',
    component: NotificationsSkills,
  },
  {
    title: 'Probation',
    path: ROUTES.APPS.TODO.PROBATION,
    url: ROUTES.APPS.TODO.PROBATION,
    statPath: 'probation',
    component: NotificationsProbation,
  },
  {
    title: 'PIP',
    path: ROUTES.APPS.TODO.PIP,
    url: ROUTES.APPS.TODO.PIP,
    statPath: 'pip',
    component: NotificationsPip,
  },
  {
    title: 'Upwards Reviews',
    path: ROUTES.APPS.TODO.UPWARDS_REVIEW,
    url: ROUTES.APPS.TODO.UPWARDS_REVIEW,
    statPath: 'upwards_review',
    component: NotificationsUpwardsReviews,
  },
  {
    title: 'Deliverables review',
    path: ROUTES.APPS.TODO.DELIVERABLES,
    url: ROUTES.APPS.TODO.DELIVERABLES,
    statPath: 'deliverable_review',
    component: NotificationsDeliverables,
  },
  {
    title: 'Trainings',
    path: ROUTES.APPS.TODO.TRAININGS,
    url: ROUTES.APPS.TODO.TRAININGS,
    statPath: 'training',
    component: NotificationsTrainings,
  },
  {
    title: 'Calibration',
    path: ROUTES.APPS.TODO.REVIEW_CALIBRATION,
    url: ROUTES.APPS.TODO.REVIEW_CALIBRATION,
    statPath: 'review_calibration',
    component: NotificationsReviewCalibration,
  },
  {
    title: 'Data Access',
    path: ROUTES.APPS.TODO.DATA_ACCESS,
    url: ROUTES.APPS.TODO.DATA_ACCESS,
    statPath: 'data_access',
    component: NotificationsDataAccess,
  },
  {
    title: 'Service Tickets',
    path: ROUTES.APPS.TODO.SERVICE_DESK,
    url: ROUTES.APPS.TODO.SERVICE_DESK,
    statPath: 'service_desk',
    component: NotificationsServiceDesk,
  },
  {
    title: 'Findings',
    path: ROUTES.APPS.TODO.FINDINGS,
    url: ROUTES.APPS.TODO.FINDINGS,
    statPath: 'finding',
    component: NotificationsFindings,
  },
  {
    title: 'Interviews',
    path: ROUTES.APPS.TODO.INTERVIEWS,
    url: ROUTES.APPS.TODO.INTERVIEWS,
    statPath: 'interview',
    component: NotificationsInterviews,
  },
  {
    title: 'Candidates',
    path: ROUTES.APPS.TODO.CANDIDATES,
    url: ROUTES.APPS.TODO.CANDIDATES,
    statPath: 'candidate',
    component: NotificationsCandidates,
  },
  {
    title: 'Catchup Interviews',
    path: ROUTES.APPS.TODO.CATCHUP_INTERVIEWS,
    url: ROUTES.APPS.TODO.CATCHUP_INTERVIEWS,
    statPath: 'adhoc_interview',
    component: NotificationsCatchupInterviews,
  },
  {
    title: 'Ownership transfer',
    path: ROUTES.APPS.TODO.OWNERSHIP,
    url: ROUTES.APPS.TODO.OWNERSHIP,
    statPath: 'ownership_transfer',
    component: OwnershipTransfer,
  },
  {
    title: 'Job postings',
    path: ROUTES.APPS.TODO.JOB_POSTINGS,
    url: ROUTES.APPS.TODO.JOB_POSTINGS,
    statPath: 'job_posting',
    component: JobPostings,
  },
  {
    title: 'Time Off',
    path: ROUTES.APPS.TODO.TIME_OFF,
    url: ROUTES.APPS.TODO.TIME_OFF,
    statPath: 'time_off',
    component: TimeOff,
  },
  {
    title: 'Bulk upload',
    path: ROUTES.APPS.TODO.BULK_UPLOAD,
    url: ROUTES.APPS.TODO.BULK_UPLOAD,
    statPath: 'bulk_upload',
    component: BulkUpload,
  },
  {
    title: 'Group access request',
    path: ROUTES.APPS.TODO.GROUP_ACCESS_REQUEST,
    url: ROUTES.APPS.TODO.GROUP_ACCESS_REQUEST,
    statPath: 'group_access_request',
    component: GroupAccessRequest,
  },
  {
    title: 'Group',
    path: ROUTES.APPS.TODO.GROUP,
    url: ROUTES.APPS.TODO.GROUP,
    statPath: 'group',
    component: Group,
  },
  {
    title: 'Succession plans',
    path: ROUTES.APPS.TODO.SUCCESSION_PLANS,
    url: ROUTES.APPS.TODO.SUCCESSION_PLANS,
    statPath: 'succession_plans',
    component: SuccessionPlans,
  },
]

export interface NotificationsContextValue {
  taggedEmployees?: TaggedUsersMap
}

export const NotificationsContext = React.createContext<NotificationsContextValue>({
  taggedEmployees: {},
})

export const NotificationsContextProvider: React.FC = ({ children }) => {
  const { data: taggedEmployees } = useGetInboxTaggedEmployees()

  const dashboardContextValue: NotificationsContextValue = useMemo(
    () => ({ taggedEmployees }),
    [taggedEmployees],
  )

  return (
    <NotificationsContext.Provider value={dashboardContextValue}>
      {children}
    </NotificationsContext.Provider>
  )
}
