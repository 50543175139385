import {
  SelectInput as SelectInputBase,
  TabBar as TabBarBase,
  Token,
  TransitionSlide,
  Text,
  Box,
} from '@revolut/ui-kit'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import css from '@styled-system/css'
import { ContentBlockLayout } from '@src/pages/Landing/components/ContentBlockLayout'
import { textStyles } from '@src/pages/Landing/styles'
import { StepsBlockItem } from '@src/pages/Landing/components/StepsBlockItem'
import { BlockItemInterface } from '@src/pages/Landing/components/types'

const TabBar = styled(TabBarBase)({
  '&[aria-orientation=horizontal]:before': {
    height: 'auto', // RGS-5639: remove after UI-kit fixes https://revolut.atlassian.net/browse/DEUI-2962
  },
})

const TabBarItem = styled(TabBar.Item)(() =>
  css({
    '&[role=tab]': {
      ...textStyles.subtitle2,
      paddingX: ['s-24', null, null, 's-24', 's-32'],
      paddingY: 's-6',
    },
  }),
)

const SelectInput: typeof SelectInputBase = styled(SelectInputBase)({
  [`@media ${Token.media.md}`]: { display: 'none' },
})

const StepsBlockItems = styled(Box)(
  css({
    display: 'grid',
    gridAutoFlow: 'row',
    listStyle: 'none',
    gap: Token.space.s16,
    gridTemplateColumns: ['1fr', null, 'repeat(3, 1fr)'],
    [`@media ${Token.media.md}`]: { gap: Token.space.s24 },
  }),
)

interface TabsBlockProps {
  items: BlockItemInterface[]
}

export const TabsBlock = ({ items }: TabsBlockProps) => {
  const [selected, setSelected] = useState<BlockItemInterface>(items[0])

  const selectOptions = useMemo(() => {
    return items.map(item => ({
      key: item.id,
      label: item.title,
      value: item,
    }))
  }, [items])

  if (items.length === 0) {
    return null
  }

  return (
    <>
      <ContentBlockLayout>
        <TabBar hide="*-md" variant="segmented fit" mx="auto">
          {items.map(tab => (
            <TabBarItem
              use="button"
              key={tab.id}
              onClick={() => setSelected(tab)}
              aria-selected={selected.id === tab.id}
            >
              <Text
                // @ts-ignore
                variant="subtitle2"
              >
                {tab.title}
              </Text>
            </TabBarItem>
          ))}
        </TabBar>
        <SelectInput
          value={selected}
          onChange={value => setSelected(value ?? items[0])}
          searchable={false}
          options={selectOptions}
        />
      </ContentBlockLayout>

      <ContentBlockLayout>
        <TransitionSlide in key={selected.id}>
          <StepsBlockItems>
            {selected.blocks.map(block => (
              <StepsBlockItem
                title={block.title}
                image={block.image}
                description={block.description}
                key={block.id}
              />
            ))}
          </StepsBlockItems>
        </TransitionSlide>
      </ContentBlockLayout>
    </>
  )
}
