import React, { useMemo, useState } from 'react'
import { connect } from 'lape'
import {
  Box,
  BREAKPOINTS,
  ErrorWidget,
  Flex,
  MoreBar,
  StatusWidget,
  Spinner,
  Token,
} from '@revolut/ui-kit'
import { Download } from '@revolut/icons'
import { reportRunsTableRequests } from '@src/api/dataAnalytics'
import {
  ReportInterface,
  RunReportResponseInterface,
  QueryRunStatuses,
} from '@src/interfaces/dataAnalytics'
import { PageBody } from '@src/components/Page/PageBody'
import { useTable } from '@components/Table/hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequiredParametersPopup } from './components/Popup'
import { PreviewTable } from './components/PreviewTable'
import { useActions } from './hooks/useActions'

export const PreviewForm = connect(() => {
  const { values } = useLapeContext<ReportInterface>()
  const table = useTable<RunReportResponseInterface>({
    getItems: reportRunsTableRequests(values.id),
  })
  const [popupOpen, setPopupOpen] = useState(false)

  const requiredParameters = useMemo(() => {
    return Object.keys(values.default_query_parameters).filter(
      key => !values.default_query_parameters[key],
    )
  }, [values.default_query_parameters])

  const latestSuccessRun = useMemo(() => {
    return table.data.find(run => run.status === QueryRunStatuses.Success)
  }, [table.data])

  const latestErrorRun = useMemo(() => {
    return table.data.find(run => run.status === QueryRunStatuses.Error)
  }, [table.data])

  const {
    executionParameters,
    setExecutionParameters,
    isDownloadLoading,
    isLoading,
    runId,
    runStatus,
    runReport,
    downloadReport,
  } = useActions({ latestSuccessRun, setPopupOpen, table })

  const renderPreview = () => {
    if (table.loading) {
      return (
        <Flex alignItems="center" justifyContent="center">
          <Spinner color={Token.color.blue} size={48} />
        </Flex>
      )
    }

    if (latestSuccessRun) {
      return <PreviewTable run={latestSuccessRun} />
    }
    if (latestErrorRun) {
      return (
        <ErrorWidget>
          <ErrorWidget.Image />
          <ErrorWidget.Description>{latestErrorRun.error_detail}</ErrorWidget.Description>
        </ErrorWidget>
      )
    }
    return (
      <StatusWidget>
        <StatusWidget.Description>Report has not been run yet</StatusWidget.Description>
      </StatusWidget>
    )
  }

  return (
    <PageBody maxWidth={BREAKPOINTS.xxl}>
      <MoreBar>
        <MoreBar.Action
          disabled={isLoading}
          onClick={requiredParameters.length > 0 ? () => setPopupOpen(true) : runReport}
          pending={isLoading}
          variant="accent"
        >
          {values.report_type === 'ui' ? 'Refresh Report Data Now' : 'Run Report Now'}
        </MoreBar.Action>
        <MoreBar.Action
          disabled={
            !(
              latestSuccessRun?.id ||
              (runId && runStatus === QueryRunStatuses.Success)
            ) || isDownloadLoading
          }
          onClick={downloadReport}
          pending={isDownloadLoading}
          useIcon={Download}
        >
          Download Latest Run
        </MoreBar.Action>
      </MoreBar>
      <Box mt="s-16">{renderPreview()}</Box>
      <RequiredParametersPopup
        executionParameters={executionParameters}
        isOpen={popupOpen}
        handleClose={setPopupOpen}
        handleRun={runReport}
        requiredParameters={requiredParameters}
        setExecutionParameters={setExecutionParameters}
      />
    </PageBody>
  )
})
