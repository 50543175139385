import React from 'react'
import { Popup } from '@revolut/ui-kit'
import ScheduleSidebar from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduleSidebar'
import {
  InterviewStageWithoutRoundInterface,
  ScheduleSidebarModeType,
} from '@src/interfaces/interviewTool'

type Props = {
  onClose: () => void
  nextStageId?: number
  stages: InterviewStageWithoutRoundInterface[]
  roundId?: number
  mode?: ScheduleSidebarModeType
  onSuccess: () => void
  onChangeInitialMode: (mode?: ScheduleSidebarModeType) => void
}

export const CVScreeningSchedule = ({
  onClose,
  nextStageId,
  stages,
  roundId,
  mode,
  onSuccess,
  onChangeInitialMode,
}: Props) => {
  return (
    <Popup size="md" open onClose={onClose}>
      <ScheduleSidebar
        selectedStageId={nextStageId}
        stages={stages}
        totalStagesCount={stages.length}
        roundId={roundId}
        onClose={onClose}
        initialMode={mode}
        onSchedulingSuccess={onSuccess}
        onChangeInitialMode={onChangeInitialMode}
        noSideBar
      />
    </Popup>
  )
}
