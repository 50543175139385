import React from 'react'
import { Box, BoxProps, MoreBar } from '@revolut/ui-kit'
import ApproveButton from '@src/features/SettingsButtons/ApproveButton/LapeApproveButton'
import RejectButton from '@src/features/SettingsButtons/RejectButton/LapeRejectButton'
import CopyButton from '@src/features/SettingsButtons/CopyButton/CopyButton'
import DeleteButton from '@src/features/SettingsButtons/DeleteButton/DeleteButton'
import ArchiveButton from '@src/features/SettingsButtons/ArchiveButton/LapeArchiveButton'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'
import { connect } from 'lape'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'

export {
  ApproveButton,
  RejectButton,
  CopyButton,
  DeleteButton,
  ArchiveButton,
  EditButton,
}

export interface SettingsButtonsInstanceProps {
  isVisible?: boolean
  entityPermissions?: EntityPermissions
  globalPermissions?: PermissionTypes[]
}

interface SettingsButtonsState extends BoxProps {
  children: React.ReactNode
}

const SettingsButtons = ({ children, ...props }: SettingsButtonsState) => {
  if (!children) {
    return null
  }
  return (
    <Box {...props}>
      <MoreBar>{children}</MoreBar>
    </Box>
  )
}

export default connect(SettingsButtons)
