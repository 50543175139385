import React from 'react'
import { Button, FilterButton, Flex } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { DocumentsRuleInterface } from '@src/interfaces/documentsRule'
import { documentRulesRequests } from '@src/api/documentsRules'
import {
  documentsRulesCreated,
  documentsRulesName,
  documentsRulesOwner,
  documentsRulesScheduleType,
  documentsRulesStatus,
  documentsRulesTemplateType,
  documentsRulesType,
} from '@src/constants/columns/documentsRules'
import AdjustableTable from '@components/Table/AdjustableTable'
import Stat from '@src/components/Stat/Stat'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const ROW: RowInterface<DocumentsRuleInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT_RULE.PREVIEW, { id })),
  cells: [
    {
      ...documentsRulesName,
      width: 300,
    },
    {
      ...documentsRulesTemplateType,
      width: 140,
    },
    {
      ...documentsRulesType,
      width: 140,
    },
    {
      ...documentsRulesScheduleType,
      width: 140,
    },
    {
      ...documentsRulesOwner,
      width: 300,
    },
    {
      ...documentsRulesCreated,
      width: 200,
    },
    {
      ...documentsRulesStatus,
      width: 200,
    },
  ],
}

const Rules = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_DOCUMENT_RULES_ASSIGNED_TO_ME,
    false,
  )

  const user = useSelector(selectUser)

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const table = useTable<DocumentsRuleInterface>(
    documentRulesRequests,
    getInitialFilters(),
  )
  const permissions = useSelector(selectPermissions)

  const canAddDocumentsRule = permissions?.includes(
    PermissionTypes.AddDocumenttemplaterules,
  )

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT_RULE.GENERAL, {}), {
      owner: { id: user.id },
    })
  }

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <TableWrapper>
      <Stat label="Total" val={table.loading ? undefined : table.count} mb="s-16" />

      <Flex mb="s-16" justifyContent="space-between">
        {canAddDocumentsRule && (
          <Button
            onClick={handleNewRow}
            mr="s-16"
            useIcon={Plus}
            size="sm"
            variant="secondary"
          >
            Create rule
          </Button>
        )}
        <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
          My rules
        </FilterButton>
      </Flex>

      <AdjustableTable
        name={TableNames.DocumentRules}
        useWindowScroll
        row={ROW}
        {...table}
        noDataMessage="All rules will appear here"
      />
    </TableWrapper>
  )
}

export default Rules
