import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { Upload } from '@src/pages/Forms/ImportData/Contracts/Upload'
import { ReviewData } from '@src/pages/Forms/ImportData/Contracts/ReviewData'

export const ImportContracts = () => {
  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.PEOPLE.CONTRACTS}
        title="Import data"
        subtitle="Contracts"
      />
      <Switch>
        <Route exact path={ROUTES.FORMS.IMPORT_DATA.CONTRACTS.UPLOAD_FILE}>
          <Upload />
        </Route>
        <Route exact path={ROUTES.FORMS.IMPORT_DATA.CONTRACTS.REVIEW_DATA}>
          <ReviewData />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
