import React from 'react'
import Form from '@src/features/Form/Form'
import { getCandidate, updatePersonalData } from '@src/api/recruitment/interviews'
import PersonalDataSidebar, {
  PersonalDataSidebarProps,
} from '@src/features/InterviewTool/PersonalDataSidebar'
import { CandidateInterface } from '@src/interfaces/interviewTool'

const PersonalDataSidebarForm = (props: PersonalDataSidebarProps) => {
  if (!props.isOpen) {
    return null
  }

  return (
    <Form<CandidateInterface>
      api={{
        get: ({ id }) => getCandidate(id!),
        update: (data, { id }) => updatePersonalData(+id!, data),
        submit: ({ id }, data) => updatePersonalData(+id!, data),
      }}
    >
      <PersonalDataSidebar {...props} />
    </Form>
  )
}

export default PersonalDataSidebarForm
