import React, { useEffect, useState } from 'react'
import { Box, Text, Color, InputGroup } from '@revolut/ui-kit'
import { Line } from '@components/CommonSC/Line'
import {
  CustomFieldsForSectionInterface,
  DynamicGroupIDs,
  SectionOptions,
} from '@src/interfaces/customFields'
import { getCustomFieldsForSectionRequest } from '@src/api/customFields'
import { FilterByInterface } from '@src/interfaces/data'
import { FieldOptions, Statuses } from '@src/interfaces'
import LapeCustomFieldsRouter from '@components/Stepper/LapeCustomFieldsRouter'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CUSTOM_FIELD_SECTIONS,
  EmployeeProfileSubSections,
} from '@src/interfaces/employees'
import { useGetEmployeeSettings } from '@src/api/settings'

interface CustomFieldsFilters {
  dynamicGroups?: DynamicGroupIDs
  departmentId?: number
  roleId?: number
  requisitionId?: number
}

interface NewStepperSection extends CustomFieldsFilters {
  className?: string
  sectionId: SectionOptions
  subSectionId?: EmployeeProfileSubSections
  children?: React.ReactNode
  customFieldsOnly?: boolean
}

interface UseCustomFieldsProps extends CustomFieldsFilters {
  sectionId: SectionOptions
  subSectionId?: string
}

export const useCustomFields = ({
  sectionId,
  subSectionId,
  dynamicGroups,
  departmentId,
  requisitionId,
  roleId,
}: UseCustomFieldsProps) => {
  const [customFields, setCustomFields] = useState<CustomFieldsForSectionInterface[]>([])
  const { data: settings } = useGetEmployeeSettings(
    sectionId !== SectionOptions.EmployeeProfile,
  )

  useEffect(() => {
    const filters: FilterByInterface[] = [
      {
        filters: [
          {
            name: `${sectionId}`,
            id: sectionId,
          },
        ],
        columnName: 'section__id',
      },
      {
        filters: [{ name: Statuses.active, id: Statuses.active }],
        columnName: 'status',
      },
    ]
    let fetchFields = false

    switch (sectionId) {
      case SectionOptions.EmployeeProfile:
        if (subSectionId && dynamicGroups?.length && !!settings?.enable_custom_fields) {
          filters.push(
            {
              filters: dynamicGroups.map(group => ({
                name: `${group.id}`,
                id: group.id,
              })),
              columnName: 'dynamic_groups',
            },
            {
              filters: [
                {
                  name: CUSTOM_FIELD_SECTIONS.EMPLOYEE_PROFILE,
                  id: CUSTOM_FIELD_SECTIONS.EMPLOYEE_PROFILE,
                },
              ],
              columnName: 'section__id',
            },
            {
              filters: [{ name: `${subSectionId}`, id: `${subSectionId}` }],
              columnName: 'sub_section__id',
            },
          )

          fetchFields = true
        }
        break
      case SectionOptions.Departments:
        if (departmentId) {
          filters.push({
            filters: [{ name: `${departmentId}`, id: `${departmentId}` }],
            columnName: 'table_filters__id',
          })
          fetchFields = true
        }
        break
      case SectionOptions.Roles:
        if (roleId) {
          filters.push({
            filters: [{ name: `${roleId}`, id: `${roleId}` }],
            columnName: 'table_filters__id',
          })
          fetchFields = true
        }
        break
      case SectionOptions.Requisitions:
        if (requisitionId) {
          filters.push({
            filters: [{ name: `${requisitionId}`, id: `${requisitionId}` }],
            columnName: 'table_filters__id',
          })
          fetchFields = true
        }
        break
    }

    if (fetchFields) {
      getCustomFieldsForSectionRequest(filters).then(res => {
        const fields = res.data.results
        setCustomFields(fields)
      })
    }
  }, [subSectionId, dynamicGroups, settings?.enable_custom_fields])

  return customFields
}

const NewStepperSectionCustomFields = ({
  className,
  children,
  sectionId,
  subSectionId,
  departmentId,
  roleId,
  requisitionId,
  dynamicGroups,
  customFieldsOnly,
}: NewStepperSection) => {
  const form = useLapeContext<{ field_options: FieldOptions; custom_fields?: any }>()
  const { values } = form

  const hidden = values?.field_options?.no_access?.includes('custom_fields')
  const disabled = values?.field_options?.read_only?.includes('custom_fields')

  const customFields = useCustomFields({
    sectionId,
    subSectionId,
    dynamicGroups,
    departmentId,
    roleId,
    requisitionId,
  })

  useEffect(() => {
    if (customFields.length) {
      // Needed for BE to do validation on it
      if (!values.custom_fields) {
        values.custom_fields = {}
      }
    }
  }, [customFields])

  if ((!children || customFieldsOnly) && !customFields?.length) {
    return null
  }

  return (
    <Box className={className}>
      {children}
      {!!customFields?.length && !hidden && (
        <>
          {customFieldsOnly ? null : (
            <Box mt="s-12" mb="s-12">
              <Line />
            </Box>
          )}
          <Text mb="s-12" display="block" color={Color.GREY_TONE_50}>
            Custom fields
          </Text>
          <InputGroup>
            {customFields.map(field => (
              <LapeCustomFieldsRouter field={field} disabled={disabled} key={field.id} />
            ))}
          </InputGroup>
        </>
      )}
    </Box>
  )
}

export default NewStepperSectionCustomFields
