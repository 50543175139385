import React, { useMemo, useState } from 'react'
import { Flex } from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import { QueryRunResultColumnsInterface } from '@src/interfaces/dataAnalytics'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'

const chartTypes = ['Area', 'Bar', 'Line', 'Scatter']

const chartTypesOptions = chartTypes.map((value, index) => ({
  label: value,
  value: {
    id: index,
    name: value,
  },
}))

export const useChartSettings = (columns: QueryRunResultColumnsInterface) => {
  const [xValue, setXValue] = useState<IdAndName>()
  const [yValue, setYValue] = useState<IdAndName>()
  const [chartType, setChartType] = useState<IdAndName>()

  const axisOptions = useMemo(() => {
    return columns.map((value, index) => ({
      label: value.name,
      value: {
        id: index,
        name: value.name,
      },
    }))
  }, [columns])

  const chartSettings = (
    <Flex flexDirection="column" gap="s-12">
      <RadioSelectInput
        label="X-Axis (Sortable values)"
        onChange={value => {
          if (value) {
            setXValue(value)
          }
        }}
        options={axisOptions}
        value={xValue}
      />
      <RadioSelectInput
        label="Y-Axis (Numeric values only)"
        onChange={value => {
          if (value) {
            setYValue(value)
          }
        }}
        options={axisOptions}
        value={yValue}
      />
      <RadioSelectInput
        label="Type"
        onChange={value => {
          if (value) {
            setChartType(value)
          }
        }}
        options={chartTypesOptions}
        value={chartType}
      />
    </Flex>
  )

  return { chartSettings, chartType, xValue, yValue }
}
