import React, { useMemo } from 'react'
import { InputGroup } from '@revolut/ui-kit'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { referralBonusFormRequests } from '@src/api/referralBonus'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { BonusType, ReferralBonusInterface } from '@src/interfaces/referralBonus'
import { ROUTES } from '@src/constants/routes'
import { selectorKeys } from '@src/constants/api'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'

const ReferralBonus = () => {
  const { values } = useLapeContext<ReferralBonusInterface>()

  const { options: bonusTypeOptions, asyncState: bonusTypeOptionsState } =
    useFetchOptions<IdAndName<BonusType>>(selectorKeys.referral_bonuses_bonus_type)
  const { options: taxTypeOptions, asyncState: taxTypeOptionsState } = useFetchOptions<
    IdAndName<string>
  >(selectorKeys.referral_bonuses_tax_type)

  const taxType = useMemo(
    () => taxTypeOptions.find(item => item.value.id === values.tax_type),
    [values.tax_type, taxTypeOptions],
  )

  const bonusType = useMemo(
    () => bonusTypeOptions.find(item => item.value.id === values.bonus_type),
    [values.bonus_type, bonusTypeOptions],
  )

  return (
    <PageWrapper>
      <PageHeader
        title={values.specialisation.name}
        backUrl={ROUTES.APPS.CANDIDATES.REFERRAL_BONUSES}
      />

      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Specialisation info" />
          <InputGroup>
            <LapeRadioSelectInput
              name="specialisation"
              selector={selectorKeys.specialisations}
              label="Specialisation"
            />
            <LapeRadioSelectInput
              name="seniority"
              selector={selectorKeys.seniority}
              label="Seniority"
            />
            <LapeRadioSelectInput
              name="location"
              selector={selectorKeys.location}
              label="Location"
            />
          </InputGroup>

          <NewStepperTitle title="Referral bonus details" />

          <InputGroup>
            <InputGroup variant="horizontal">
              <RadioSelectInput
                value={taxType?.value}
                label="Tax"
                options={taxTypeOptions}
                loading={taxTypeOptionsState === 'pending'}
                onChange={option => {
                  if (option?.id) {
                    values.tax_type = option?.id
                  }
                }}
              />
              <LapeRadioSelectInput
                name="currency"
                selector={selectorKeys.currencies}
                label="Currency"
              />
            </InputGroup>
            <InputGroup variant="horizontal">
              <RadioSelectInput
                value={bonusType?.value}
                label="Type"
                options={bonusTypeOptions}
                loading={bonusTypeOptionsState === 'pending'}
                onChange={option => {
                  if (option?.id) {
                    values.bonus_type = option.id
                  }
                }}
              />
              <LapeNewInput type="number" name="amount" label="Amount" required />
            </InputGroup>
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup useValidator />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={referralBonusFormRequests}>
    <ReferralBonus />
  </Form>
))
