import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { getColor } from '@src/styles/colors'
import { getReviewCycleStatusColor } from '@src/utils/reviewCycles'

export const reviewCyclesNameColumn: ColumnInterface<ReviewCyclesInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const reviewCyclesStartDateColumn: ColumnInterface<ReviewCyclesInterface> = {
  type: CellTypes.date,
  idPoint: 'start_date_time',
  dataPoint: 'start_date_time',
  sortKey: 'start_date_time',
  filterKey: 'start_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Start Date',
}

export const reviewCyclesEndDateColumn: ColumnInterface<ReviewCyclesInterface> = {
  type: CellTypes.date,
  idPoint: 'end_date_time',
  dataPoint: 'end_date_time',
  sortKey: 'end_date_time',
  filterKey: 'end_date_time',
  selectorsKey: selectorKeys.none,
  title: 'End Date',
}

export const reviewCyclesStatusColumn: ColumnInterface<ReviewCyclesInterface> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.requisitions_status,
  colors: (data: ReviewCyclesInterface, theme) => {
    return getColor(theme, getReviewCycleStatusColor(data.status))
  },
  title: 'Status',
}
