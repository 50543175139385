import { useLapeContext } from '@src/features/Form/LapeForm'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalsInterface } from '@src/interfaces/goals'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { LinkRoadmapsForm } from '@src/pages/Forms/GoalForm/Form/SidebarForms/LinkRoadmapsForm'
import { BaseWidget } from '@src/pages/Forms/GoalForm/Form/Widgets/BaseWidget'
import { useSidebar } from '@src/pages/Forms/GoalForm/common/SidebarProvider'
import React from 'react'
import { RoadmapsList } from './RoadmapsList'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

export const RoadmapsWidget = ({
  initialCycle,
  viewMode = false,
}: {
  initialCycle?: ReviewCyclesInterface
  viewMode?: boolean
}) => {
  const { values } = useLapeContext<GoalsInterface>()
  const sidebar = useSidebar()
  const { getEntityProps } = useOrgEntity()

  const showSidebar = () => {
    sidebar.show({
      children: (
        <LinkRoadmapsForm
          initialCycle={initialCycle}
          ownerId={values.owner.id}
          submit={({ cycle, epics }) => {
            sidebar.hide()
            values.roadmaps = [
              ...(values.roadmaps || []),
              ...epics.map(epic => ({
                review_cycle: cycle,
                name: epic.epic_name,
                ...getEntityProps(),
                ...epic,
              })),
            ] as RoadmapInterface[]
          }}
        />
      ),
      title: 'Link roadmaps',
      isOpen: true,
    })
  }

  return (
    <BaseWidget
      title={viewMode ? 'Roadmap' : 'Link your roadmaps'}
      desciption={
        viewMode
          ? undefined
          : '(Optional) What projects, tasks and actions are planned to deliver the goal?'
      }
      icon="Map"
      emptyState={{
        description: 'No roadmaps added',
        ctaLabel: 'Add roadmap',
        ctaHandler: showSidebar,
        icon: 'Map',
      }}
    >
      {values.roadmaps && values.roadmaps.length ? (
        <RoadmapsList onAddRoadmap={showSidebar} viewMode={viewMode} />
      ) : null}
    </BaseWidget>
  )
}
