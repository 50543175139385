import React, { useEffect, useState } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'

import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import {
  eligibleEmployeesRequests,
  syncEligibleEmployeeData,
  triggerGenerateScorecards,
  useGetScorecardsGenerationsCategories,
  useGetScorecardsGenerationsStatus,
  validateEligibleEmployeeData,
} from '@src/api/supportTool/eligibleEmployees'
import {
  actionsColumn,
  commentColumn,
  cycleColumn,
  eligibilityColumn,
  employeeNameColumn,
  employeeStatusColumn,
  functionalManagerColumn,
  lineManagerColumn,
  seniorityColumn,
  specialisationColumn,
  talentTypeColumn,
  teamNameColumn,
} from '@src/constants/columns/supportTool/eligibleEmployees'
import AddEligibleEmployeePopup from '@src/features/Popups/supportTool/AddEligibleEmployeePopup'
import { connect, useLape } from 'lape'
import { OptionInterface } from '@src/interfaces/selectors'
import { pushNotification, successNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import UploadPopup, {
  UploadPopupType,
} from '@src/features/Popups/supportTool/UploadPopup'
import { EligibleEmployeesInterface } from '@src/interfaces/supportTool/eligibleEmployees'
import Loader from '@components/CommonSC/Loader'
import { useSelectedPerformanceCycle } from '@src/utils/performance'
import { selectorKeys } from '@src/constants/api'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  Action,
  Flex,
  MoreBar,
  useTooltip,
  Tooltip as UIKitTooltip,
  Widget,
  useStatusPopup,
  StatusPopup,
} from '@revolut/ui-kit'
import { ArrowRecurring, Plus, ShareIOs, People } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { GenerateScorecardsPopup } from '@src/pages/Performance/SupportTool/GenerateScorecardsPopup'

const ROW: RowInterface<EligibleEmployeesInterface> = {
  cells: [
    {
      ...employeeNameColumn,
      width: 200,
    },
    {
      ...employeeStatusColumn,
      width: 100,
    },
    {
      ...teamNameColumn,
      width: 200,
    },
    {
      ...seniorityColumn,
      width: 140,
    },
    {
      ...specialisationColumn,
      width: 200,
    },
    {
      ...lineManagerColumn,
      width: 150,
    },
    {
      ...functionalManagerColumn,
      width: 150,
    },
    {
      ...cycleColumn,
      width: 150,
    },
    {
      ...eligibilityColumn,
      width: 100,
    },
    {
      ...talentTypeColumn,
      width: 200,
    },
    {
      ...commentColumn,
      width: 160,
    },
    {
      ...actionsColumn,
      width: 100,
      insert: ({ data }) => (
        <Action
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.ELIGIBLE_PEOPLE_EDIT.GENERAL, {
                id: data.id,
              }),
            )
          }}
        >
          Edit
        </Action>
      ),
    },
  ],
}

const EligiblePeopleContent = ({
  initialCycleOffset,
  cycles,
}: {
  initialCycleOffset: number
  cycles: OptionInterface[]
}) => {
  const state = useLape<{
    openAddPopup: boolean
    removeEmployee?: {
      id: number
      name: string
      cycle: string
    }
  }>({
    openAddPopup: false,
    removeEmployee: undefined,
  })
  const [showUploadPopup, setShowUploadPopup] = useState(false)
  const [showScorecardsPopup, setShowScorecardsPopup] = useState(false)
  const [scorecardsPending, setScorecardsPending] = useState(false)
  const [syncPending, setSyncPending] = useState(false)
  const [validatePending, setValidatePending] = useState(false)
  const [enableGeneration, setEnableGeneration] = useState(false)
  const { data: categories } = useGetScorecardsGenerationsCategories()
  const { data: generationStatus } = useGetScorecardsGenerationsStatus()
  const statusPopup = useStatusPopup()
  const generateTooltip = useTooltip()
  const syncTooltip = useTooltip()
  const validateTooltip = useTooltip()

  const initialFilterBy = [
    {
      filters: [{ id: initialCycleOffset, name: `${initialCycleOffset}` }],
      columnName: 'cycle__offset',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'employee__full_name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const table = useTable<EligibleEmployeesInterface>(
    eligibleEmployeesRequests,
    initialFilterBy,
    initialSortBy,
  )

  const onAddEmployee = async (employee: OptionInterface, cycle: OptionInterface) => {
    await eligibleEmployeesRequests.postItem({
      employee,
      cycle,
    } as unknown as Partial<EligibleEmployeesInterface>)
    state.openAddPopup = false
    pushNotification({
      type: NotificationTypes.success,
      value: 'Employee added to the eligible list successfully',
      duration: SUCCESS_DEFAULT_DURATION,
    })
    table.refresh()
  }

  const handleSubmit = () => {
    setShowUploadPopup(false)
    pushNotification({
      type: NotificationTypes.success,
      value: 'Employee info uploaded successfully',
      duration: SUCCESS_DEFAULT_DURATION,
    })
  }

  useEffect(() => {
    if (generationStatus) {
      setEnableGeneration(true)
    }
  }, [generationStatus])

  const selectedCycles = table.filterBy.find(
    filter => filter.columnName === 'cycle__offset',
  )
  const canGenerateScorecards = enableGeneration && selectedCycles?.filters.length === 1
  const canSyncData =
    selectedCycles?.filters.length === 1 && selectedCycles?.filters[0]?.id === 0
  const selectedCycleId = cycles.find(
    cycle => cycle.offset === String(selectedCycles?.filters[0]?.id),
  )?.id

  const handleGenerateScorecards = async () => {
    setScorecardsPending(true)
    try {
      const enabledCategories =
        categories?.filter(category => category.enabled).map(category => category.id) ||
        []
      if (selectedCycleId) {
        await triggerGenerateScorecards(
          selectedCycleId,
          enabledCategories,
          table.filterBy,
        )
        setEnableGeneration(false)
        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>
              Background jobs were triggered to generate scorecards
            </StatusPopup.Title>
          </StatusPopup>,
        )
      }
    } finally {
      setScorecardsPending(false)
      setShowScorecardsPopup(false)
    }
  }

  const handleSyncData = async () => {
    if (canSyncData && selectedCycleId) {
      setSyncPending(true)
      try {
        const res = await syncEligibleEmployeeData(selectedCycleId, table.filterBy)
        successNotification(res.data.result)
      } finally {
        setSyncPending(false)
      }
    }
  }

  const handleValidateData = async () => {
    if (canSyncData && selectedCycleId) {
      setValidatePending(true)
      try {
        const res = await validateEligibleEmployeeData(selectedCycleId, table.filterBy)
        successNotification(res.data.result)
      } finally {
        setValidatePending(false)
      }
    }
  }

  return (
    <Widget p="s-16">
      <AddEligibleEmployeePopup
        open={state.openAddPopup}
        onClose={() => {
          state.openAddPopup = false
        }}
        onSubmit={onAddEmployee}
        cycles={cycles}
      />
      <UploadPopup
        open={showUploadPopup}
        onClose={() => setShowUploadPopup(false)}
        onSubmit={handleSubmit}
        type={UploadPopupType.EligibleEmployees}
      />
      <GenerateScorecardsPopup
        isOpen={showScorecardsPopup}
        isPending={scorecardsPending}
        onSubmit={handleGenerateScorecards}
        onCancel={() => setShowScorecardsPopup(false)}
      />
      <CycleFilter
        onFilterChange={table.onFilterChange}
        columnName="cycle__offset"
        filter={table.filterBy}
        type={CycleFilterType.NewUI}
      />
      <Flex alignItems="center" my="s-16">
        <MoreBar>
          <MoreBar.Action
            useIcon={Plus}
            onClick={() => {
              state.openAddPopup = true
            }}
          >
            Add employee
          </MoreBar.Action>
          <MoreBar.Action useIcon={ShareIOs} onClick={() => setShowUploadPopup(true)}>
            Bulk upload
          </MoreBar.Action>
          <MoreBar.Action
            {...generateTooltip.getAnchorProps()}
            onClick={() => {
              if (canGenerateScorecards) {
                setShowScorecardsPopup(true)
              }
            }}
            aria-disabled={!canGenerateScorecards}
          >
            Generate scorecards
            {!canGenerateScorecards && (
              <UIKitTooltip
                {...generateTooltip.getTargetProps()}
                width={250}
                style={{ textAlign: 'center' }}
                placement="bottom-start"
              >
                You can only generate scorecards for a single cycle and if no background
                jobs are pending
              </UIKitTooltip>
            )}
          </MoreBar.Action>
          <MoreBar.Action
            useIcon={ArrowRecurring}
            onClick={handleSyncData}
            aria-disabled={!canSyncData}
            pending={syncPending}
            {...syncTooltip.getAnchorProps()}
          >
            Sync employee data
            {!canSyncData && (
              <UIKitTooltip
                {...syncTooltip.getTargetProps()}
                style={{ textAlign: 'center' }}
                placement="bottom-start"
              >
                You can only sync data for ongoing cycle
              </UIKitTooltip>
            )}
          </MoreBar.Action>
          <MoreBar.Action
            useIcon={People}
            onClick={handleValidateData}
            aria-disabled={!canSyncData}
            pending={validatePending}
            {...validateTooltip.getAnchorProps()}
          >
            Validate employee data
            {!canSyncData && (
              <UIKitTooltip
                {...validateTooltip.getTargetProps()}
                style={{ textAlign: 'center' }}
                placement="bottom-start"
              >
                You can only validate data for ongoing cycle
              </UIKitTooltip>
            )}
          </MoreBar.Action>
        </MoreBar>
      </Flex>

      <AdjustableTable<EligibleEmployeesInterface>
        name={TableNames.EligibleEmployees}
        useWindowScroll
        row={ROW}
        {...table}
        noDataMessage="There are no employees"
      />
    </Widget>
  )
}

const LapeConnectedEligiblePeople = connect(EligiblePeopleContent)

const EligibleEmployees = () => {
  const { initialCycleOffset, cycles } = useSelectedPerformanceCycle(
    selectorKeys.review_cycles,
    true,
  )

  if (initialCycleOffset === undefined) {
    return <Loader />
  }

  return (
    <LapeConnectedEligiblePeople
      cycles={cycles}
      initialCycleOffset={initialCycleOffset}
    />
  )
}

export default EligibleEmployees
