import React from 'react'
import { Banner, Link } from '@revolut/ui-kit'
import { Link as RouterLink } from 'react-router-dom'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

const TalentCycleSettingsBanner = () => {
  return (
    <Banner>
      <Banner.Content>
        <Banner.Title>No active performance review period set</Banner.Title>
        <Banner.Description>
          In order to view this page, we require one review cycle to be set as active for
          performance. This can be configured in the review cycle settings{' '}
          <Link
            use={RouterLink}
            to={getLocationDescriptor(
              pathToUrl(ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES),
            )}
          >
            here.
          </Link>
        </Banner.Description>
      </Banner.Content>
    </Banner>
  )
}

export default TalentCycleSettingsBanner
