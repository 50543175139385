import React, { useMemo } from 'react'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { Avatar, Box, Flex, Text, Widget, InputProps, Token } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { Cross } from '@revolut/icons'
import { Grid } from '@components/CommonSC/Grid'
import { SelectorType } from '@src/interfaces/selectors'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { getAvatarUrl } from '@src/utils/employees'
import useFetchOptions from '../hooks/useFetchOptions'
import Tooltip from '@components/Tooltip/Tooltip'

interface EmployeeSelectorProps {
  value?: EmployeeOptionInterface[]
  selector?: SelectorType
  onChange?: (value?: EmployeeOptionInterface[]) => void
  inputProps?: InputProps
  disabled?: boolean
  label: string
  error?: boolean
}

const EmployeeSelector = ({
  value,
  onChange,
  disabled,
  selector = selectorKeys.employee,
  label = 'Select Employee',
  inputProps,
  error,
}: EmployeeSelectorProps) => {
  const { options: optionsData } = useFetchOptions<EmployeeOptionInterface>(selector)
  const valueIds = useMemo(() => {
    return value?.map(({ id }) => id) ?? []
  }, [value])
  const isSelected = (id: number) => valueIds.includes(id)
  const options = useMemo(() => {
    return optionsData.filter(o => !isSelected(o.value.id))
  }, [valueIds, optionsData])

  const handleSelect = (v?: EmployeeOptionInterface | null) => {
    if (v && !isSelected(v.id)) {
      onChange?.([...(value || []), v])
    }
  }

  const handleDelete = (id: number) => {
    onChange?.(value?.filter((_, i) => i !== id))
  }

  return (
    <Grid gap={16}>
      {!disabled && (
        <RadioSelectInput<EmployeeOptionInterface>
          label={label}
          selector={selector}
          disabled={disabled}
          onChange={e => {
            handleSelect(e)
          }}
          inputProps={inputProps}
          hasError={error}
          options={options}
        />
      )}
      {!!value?.length && (
        <Widget p="s-16">
          <Flex flexWrap="wrap">
            {value.map((employee, id) => {
              return (
                <Box
                  width={96}
                  data-testid={`employee_selector_avatar_${id}`}
                  key={`employee_selector_avatar_${id}`}
                >
                  <Tooltip
                    placement="top"
                    text={
                      employee.status !== 'active' ? 'Employee is not active' : undefined
                    }
                    hide={employee.status === 'active'}
                  >
                    <Flex flexDirection="column" alignItems="center">
                      <Avatar
                        size={56}
                        image={getAvatarUrl(employee.avatar)}
                        opacity={employee.status !== 'active' ? 0.4 : 1}
                      >
                        {!disabled && (
                          <Avatar.Badge
                            data-testid={`employee_selector_delete_button_${id}`}
                            onClick={() => handleDelete(id)}
                            style={{ cursor: 'pointer' }}
                            bg="red"
                            position="top-right"
                            useIcon={Cross}
                          />
                        )}
                        {!employee.avatar && employee.name?.[0]}
                      </Avatar>
                      <Text
                        textAlign="center"
                        lineHeight="1.2"
                        use="div"
                        pt="s-4"
                        style={{
                          wordBreak: 'break-word',
                        }}
                        color={
                          employee.status !== 'active'
                            ? Token.color.greyTone50
                            : undefined
                        }
                      >
                        {employee.name}
                      </Text>
                    </Flex>
                  </Tooltip>
                </Box>
              )
            })}
          </Flex>
        </Widget>
      )}
    </Grid>
  )
}

export default EmployeeSelector
