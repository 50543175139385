import React from 'react'
import { DetailsCell, Group, Token } from '@revolut/ui-kit'
import { formatDate, formatDateTime } from '@src/utils/format'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import { formatStatus } from '@src/utils/string'
import CollapsibleSubheader from '@components/CollapsibleSubheader/CollapsibleSubheader'

type EngagementInfoProps = {
  candidate: CandidateInterface
  isArchived: boolean
}

const EngagementInfo = ({ candidate, isArchived }: EngagementInfoProps) => {
  return (
    <CollapsibleSubheader title="Engagement info" openByDefault={isArchived}>
      <Group>
        <DetailsCell>
          <DetailsCell.Title>Can be contacted</DetailsCell.Title>
          <DetailsCell.Content
            color={candidate.can_re_engage ? Token.color.green : Token.color.red}
          >
            {candidate.can_re_engage ? 'Yes' : 'No'}
          </DetailsCell.Content>
        </DetailsCell>
        {candidate.anonymising_expected_date_time && (
          <DetailsCell>
            <DetailsCell.Title>Data retained until</DetailsCell.Title>
            <DetailsCell.Content>
              {formatDate(candidate.anonymising_expected_date_time)}
            </DetailsCell.Content>
          </DetailsCell>
        )}
        <DetailsCell>
          <DetailsCell.Title>Re-engagement status</DetailsCell.Title>
          <DetailsCell.Content>
            {formatStatus(candidate.re_engagement_status)}
          </DetailsCell.Content>
        </DetailsCell>
        {candidate.re_engagement_date_time && (
          <DetailsCell>
            <DetailsCell.Title>Re-engagement date</DetailsCell.Title>
            <DetailsCell.Content>
              {formatDateTime(candidate.re_engagement_date_time)}
            </DetailsCell.Content>
          </DetailsCell>
        )}
      </Group>
    </CollapsibleSubheader>
  )
}

export default EngagementInfo
