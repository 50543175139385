import { goalsRequests } from '@src/api/goals'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import React, { useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { GoalFormPage } from './GoalFormPage'
import { GoalPreviewPage } from './Form/Preview/GoalPreviewPage'
import { SidebarProvider } from './common/SidebarProvider'
import { EntityFetcher } from './EntityFetcher'

export const GoalRouterWithForm = () => {
  const params = useParams<{ id: string }>()
  const [excludeKpis, setExcludeKpis] = useState<boolean>(!!params.id)
  return (
    <Form
      api={goalsRequests}
      fieldsToExclude={excludeKpis ? ['kpis'] : undefined}
      refetchOnLocationChange
    >
      <EntityFetcher>
        <SidebarProvider>
          <Switch>
            <Route exact path={ROUTES.FORMS.GOAL.NEW}>
              <GoalFormPage />
            </Route>

            <Route exact path={ROUTES.FORMS.GOAL.PREVIEW}>
              <GoalPreviewPage />
            </Route>

            <Route exact path={ROUTES.FORMS.GOAL.EDIT}>
              <GoalFormPage
                onKpisAddedOrRemoved={() => {
                  if (excludeKpis) {
                    setExcludeKpis(false)
                  }
                }}
              />
            </Route>
          </Switch>
        </SidebarProvider>
      </EntityFetcher>
    </Form>
  )
}
