import React, { useState } from 'react'
import { KpiInterface } from '@src/interfaces/kpis'
import { Button, Flex, Header, Input, Popup, Text, Widget } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import { getKPITargetsListRequest, kpiTargetsRequestsNew } from '@src/api/kpis'
import { Loading } from '@revolut/icons'
import { handleError } from '@src/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

export interface KPIApproveRejectProps {
  kpi: KpiInterface
  onAfterSubmit: () => void
  reviewCycle: ReviewCyclesInterface
}

const loader = (
  <Flex pr="s-8">
    <Loading size={14} />
  </Flex>
)

const getTargetId = async (kpi: KpiInterface, reviewCycle: ReviewCyclesInterface) => {
  try {
    const targetsResponse = await getKPITargetsListRequest(kpi.id, reviewCycle.offset)()
    return targetsResponse.data.results?.[0]?.id
  } catch {
    return null
  }
}

const KPIApproveReject = ({ kpi, reviewCycle, onAfterSubmit }: KPIApproveRejectProps) => {
  const [isRejectPopupOpen, setIsRejectPopupOpen] = useState<boolean>(false)
  const [rejectionReason, setRejectionReason] = useState<string>()
  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [isRejecting, setIsRejecting] = useState<boolean>(false)

  const approve = async () => {
    if (isApproving) {
      return
    }
    setIsApproving(true)
    const targetId = await getTargetId(kpi, reviewCycle)

    await kpiTargetsRequestsNew
      .update({ status: Statuses.approved }, { id: `${targetId}` })
      .then(() => onAfterSubmit())
      .catch(handleError)
      .finally(() => setIsApproving(false))
  }

  const reject = async () => {
    const targetId = await getTargetId(kpi, reviewCycle)

    return kpiTargetsRequestsNew
      .update(
        {
          status: Statuses.requires_changes,
          rejection_reason: rejectionReason,
        },
        { id: `${targetId}` },
      )
      .then(() => onAfterSubmit())
      .catch(handleError)
      .finally(() => setIsRejecting(false))
  }

  const submitRejecting = () => {
    setIsRejecting(true)
    reject().then(() => {
      setIsRejectPopupOpen(false)
    })
  }

  return (
    <>
      {(kpi.target_status === Statuses.pending ||
        kpi.target_status === Statuses.requires_changes) && (
        <Flex>
          <Text
            mr="10px"
            fontWeight={500}
            color="light-blue"
            onClick={e => {
              e.stopPropagation()
              approve()
            }}
          >
            <Flex>
              {isApproving && loader}
              Approve
            </Flex>
          </Text>
          {kpi.target_status !== Statuses.requires_changes && (
            <Text
              fontWeight={500}
              color="warning"
              onClick={e => {
                e.stopPropagation()
                setIsRejectPopupOpen(true)
              }}
            >
              <Flex>
                {isRejecting && loader}
                Request changes
              </Flex>
            </Text>
          )}
        </Flex>
      )}

      <Popup
        open={isRejectPopupOpen}
        onClose={() => setIsRejectPopupOpen(false)}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet">
          <Header.Title>Request change KPI</Header.Title>
        </Header>
        <Text use="p" variant="caption" color="grey-tone-50">
          Please provide a change reason
        </Text>
        <Widget>
          <Input
            autoFocus
            value={rejectionReason}
            onChange={e => setRejectionReason(e.currentTarget.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                submitRejecting()
              }
            }}
          />
        </Widget>
        <Popup.Actions>
          <Button
            elevated
            disabled={!rejectionReason}
            pending={isRejecting}
            onClick={e => {
              e.stopPropagation()
              submitRejecting()
            }}
          >
            Confirm
          </Button>
          <Button
            variant="secondary"
            onClick={e => {
              e.stopPropagation()
              setIsRejectPopupOpen(false)
            }}
          >
            Cancel
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export default KPIApproveReject
