import React from 'react'
import { Delete } from '@revolut/icons'
import { HiringProcessInterface, HiringStageType } from '@src/interfaces/hiringProccess'
import {
  formHiringProcessStagesRequest,
  formHiringProcessStagesRoleRequest,
  formHiringProcessStagesSpecialisationRequest,
} from '@src/api/hiringProcess'
import { PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import AsyncButton from '@src/features/Form/Buttons/AsyncButton'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { pathToUrl } from '@src/utils/router'

type Props = {
  type: HiringStageType
  onSuccess?: () => void
}

export const getBackUrl = (type: HiringStageType, values: HiringProcessInterface) => {
  switch (type) {
    case 'general':
      return pathToUrl(ROUTES.RECRUITMENT.HIRING_STAGES)

    case 'specialisation':
      return pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
        id: values.specialisation?.id,
      })

    case 'role':
      return pathToUrl(ROUTES.FORMS.ROLE.HIRING_PROCESS, {
        id: values.role?.id,
      })

    default:
      return ''
  }
}

const HiringStageDeleteAction = ({ type, onSuccess }: Props) => {
  const { values } = useLapeContext<HiringProcessInterface>()

  const onClick = async () => {
    let result

    switch (type) {
      case 'general': {
        result = await formHiringProcessStagesRequest.delete!({ id: values.id }, {})
        break
      }
      case 'role': {
        result = await formHiringProcessStagesRoleRequest.delete!({ id: values.id }, {})
        break
      }
      case 'specialisation': {
        result = await formHiringProcessStagesSpecialisationRequest.delete!(
          { id: values.id },
          {},
        )
        break
      }
    }

    if (result?.status === 204) {
      onSuccess?.()
    }

    return result
  }

  const canDelete =
    type === 'general'
      ? values?.field_options?.permissions?.includes(PermissionTypes.DeleteHiringstage)
      : true

  return (
    <AsyncButton
      submit={onClick}
      variant="negative"
      hide={!canDelete}
      size="sm"
      afterSubmitUrl={getBackUrl(type, values)}
      notification={{
        path: ROUTES.FORMS.HIRING_STAGES.GENERAL,
        updateMsg: 'Hiring stage successfully deleted.',
      }}
      useIcon={Delete}
    >
      Delete
    </AsyncButton>
  )
}

export default HiringStageDeleteAction
