import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import React from 'react'
import { GoalForm } from './Form/GoalForm'
import { PageActions } from '@src/components/Page/PageActions'
import { BREAKPOINTS, Button, Flex } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Link, useLocation } from 'react-router-dom'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { EntityTypes } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { IdAndName, Statuses } from '@src/interfaces'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeEffect } from 'lape'

export const GoalFormPage = ({
  onKpisAddedOrRemoved,
}: {
  onKpisAddedOrRemoved?: () => void
}) => {
  const { values, submit, initialValues } = useLapeContext<GoalsInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: reviewCycles } = useGetReviewCycles()
  const { getEntityProps } = useOrgEntity()
  const location = useLocation<{ reviewCycleId?: string }>()

  useLapeEffect(() => {
    if (onKpisAddedOrRemoved && initialValues.kpis?.length !== values.kpis.length) {
      onKpisAddedOrRemoved()
    }
  })

  const backUrl = pathToUrl(ROUTES.FORMS.DEPARTMENT.KPI, { id: values.object_id }) // fixme: doesn't work
  const target = (values.kpis || [])[0]

  const onSubmit = async () => {
    const updateType = target.is_inherited
      ? UpdateTypes.cascaded
      : values.kpis[0].update_type

    const originalKpis = values.kpis
    if (!values.id) {
      if (updateType !== UpdateTypes.cascaded) {
        values.kpis = values.kpis.map(
          kpi =>
            ({
              ...kpi,
              name: kpi.name || values.name,
              description: values.description,
              owner: values.owner as KpiInterface['owner'],
              ...getEntityProps(),
            } as unknown as KpiInterface),
        )
      } else {
        values.kpis = (values.parent?.kpis.map(kpi => ({
          owner: values.owner as KpiInterface['owner'],
          parent: { id: kpi.id } as KpiInterface,
          is_inherited: true,
          ...getEntityProps(),
        })) || []) as unknown[] as KpiInterface[]
      }
    }

    values.kpis.forEach(kpi => {
      if (kpi) {
        if (values.id && kpi.update_type !== UpdateTypes.roadmap) {
          // @ts-expect-error
          delete kpi.target_epics
        }

        if (values.id && kpi.update_type === UpdateTypes.roadmap) {
          // @ts-expect-error
          delete kpi.targets
        }

        if (kpi.update_type === UpdateTypes.sql && kpi.sql_query_db?.id === 'looker') {
          kpi.sql_query = null
        }

        if (kpi.update_type === UpdateTypes.sql && kpi.sql_query_db?.id !== 'looker') {
          kpi.look_url = null
        }

        if (values.id && (kpi.status as unknown as IdAndName<Statuses>)?.id) {
          kpi.status = (kpi.status as unknown as IdAndName<Statuses>).id
        }
      }
    })

    return submit().catch(err => {
      values.kpis = originalKpis
      throw err
    })
  }

  const contentLabelByType: Record<EntityTypes, string> = {
    [EntityTypes.department]: 'department',
    [EntityTypes.team]: 'team',
    [EntityTypes.teams]: 'team',
    [EntityTypes.employees]: 'employee',
    [EntityTypes.employee]: 'employee',
    [EntityTypes.company]: 'company',
    [EntityTypes.function]: 'function',
    [EntityTypes.role]: 'role',
    [EntityTypes.specialisation]: 'specialisation',
  }

  const isDefaultCycle = (cycle: ReviewCyclesInterface) => {
    return location.state?.reviewCycleId
      ? cycle.id === Number(location.state.reviewCycleId)
      : cycle.offset === 0
  }

  const entityTitle = values.content_type?.model
    ? contentLabelByType[values.content_type.model]
    : 'Company'

  const title =
    values.id && values.content_type?.model
      ? `Edit ${entityTitle} goal`
      : `Add new${values.content_type ? ` ${entityTitle}` : ''} goal`

  return performanceSettings ? (
    <PageWrapper>
      <PageHeader
        variant="narrow"
        noWrap={false}
        title={title}
        backUrl={backUrl}
        subtitle="Define the goal details"
      />
      <PageBody maxWidth={BREAKPOINTS.lg}>
        <GoalForm
          enforceParentKpi={performanceSettings.enable_mandatory_parent_kpi}
          defaultReviewCycle={reviewCycles?.results.find(isDefaultCycle)}
        />

        <PageActions alignSelf="center">
          <Flex justifyContent="center" maxWidth="340px" alignSelf="center" gap="s-8">
            <Button variant="secondary" use={Link} to={backUrl}>
              Back
            </Button>
            <NewSaveButtonWithPopup<GoalsInterface>
              hideWhenNoChanges={false}
              successText={values.id ? undefined : 'Goal created'}
              useValidator
              disabled={!values.kpis?.length}
              onClick={onSubmit}
              elevated
              previewUrl={goal => pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: goal.id })}
            />
          </Flex>
        </PageActions>
      </PageBody>
    </PageWrapper>
  ) : null
}
