import { PageHeader } from '@components/Page/Header/PageHeader'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageWrapper } from '@components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import { InputGroup, Text, Flex } from '@revolut/ui-kit'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageActions } from '@components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { connect } from 'lape'
import { useGetAssignedGroups, userRequest } from '@src/api/accessManagement'
import {
  AssignedGroup,
  AssignGroupsAccessManagementInterface,
} from '@src/interfaces/accessManagement'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { Check } from '@revolut/icons'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { useGetAccessManagementSettings } from '@src/api/settings'

const renderOption = (option: RadioSelectOption<AssignedGroup>) => {
  return (
    <Flex justifyContent="space-between">
      <Text use="p">{option.label}</Text>
      {option.value.assigned && <Check color="blue" />}
    </Flex>
  )
}

const AssignUserToPermissionGroups = () => {
  const { userId } = useParams<{ userId?: string }>()
  const { values } = useLapeContext<AssignGroupsAccessManagementInterface>()
  const backUrl = userId
    ? pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_USER.GROUPS, { id: userId })
    : pathToUrl(ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS)
  const [activeUserId, setActiveUserId] = useState<string | number | undefined>(userId)
  const { data: assignedGroups, isLoading } = useGetAssignedGroups(activeUserId)
  const { data: settings } = useGetAccessManagementSettings()

  useEffect(() => {
    if (userId) {
      const fetchUser = async () => {
        const resp = await userRequest.get({ id: userId })
        values.user = resp.data
      }
      fetchUser()
    }

    if (!values.start_date_time) {
      values.start_date_time = new Date().toISOString()
    }

    if (!values.end_date_time) {
      values.end_date_time = null
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader
        title="Assign new permissions groups"
        subtitle={
          values?.user?.user_type === 'employee' ? (
            <UserWithAvatar {...values?.user?.employee} />
          ) : (
            values?.user?.email
          )
        }
        backUrl={backUrl}
      />
      <PageBody>
        <AutoStepper>
          <InputGroup>
            {!userId && (
              <LapeRadioSelectInput
                name="user"
                label="User"
                selector={selectorKeys.active_users}
                onAfterChange={activeUser => setActiveUserId(activeUser?.id)}
              />
            )}
            <LapeNewMultiSelect
              name="groups"
              placeholder="Groups"
              options={assignedGroups?.map(item => ({
                label: item.name,
                value: item,
              }))}
              required
              disabled={!activeUserId || isLoading}
            >
              {renderOption}
            </LapeNewMultiSelect>

            {settings?.enable_limited_time_access ? (
              <InputGroup variant="horizontal">
                <LapeDatePickerInput
                  disabledDays={{ before: new Date() }}
                  defaultValue={new Date()}
                  required
                  name="start_date_time"
                  label="From Date"
                />
                <LapeDatePickerInput
                  disabledDays={
                    values.start_date_time
                      ? { before: new Date(values.start_date_time) }
                      : undefined
                  }
                  name="end_date_time"
                  label="To Date"
                />
              </InputGroup>
            ) : null}
            <LapeNewTextArea name="reason" label="Notes" rows={2} required />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          afterSubmitUrl={backUrl}
          useValidator
          successText="User has been assigned to the groups successfully"
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(AssignUserToPermissionGroups)
