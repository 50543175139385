import { Button, Dropdown, Icon } from '@revolut/ui-kit'
import React, { PropsWithChildren, useRef, useState } from 'react'

export const ButtonsGroup = ({
  variant,
  children,
}: PropsWithChildren<{ variant: 'flat' | 'grouped' }>) => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  if (variant === 'flat') {
    return <>{children}</>
  }
  return (
    <>
      <Button
        ref={anchorRef}
        onClick={() => setOpen(!open)}
        useIcon={<Icon name={open ? 'ChevronUp' : 'ChevronDown'} size={16} />}
        size="sm"
        variant="secondary"
      >
        Actions
      </Button>
      <Dropdown
        autoClose
        open={open}
        anchorRef={anchorRef}
        onClose={() => setOpen(false)}
        minWidth={180}
      >
        {children}
      </Dropdown>
    </>
  )
}
