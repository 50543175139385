import React, { useMemo } from 'react'
import { get } from 'lodash'
import { chain, Text, Token } from '@revolut/ui-kit'

import { RevolutTheme } from '@src/styles/theme'
import { PayCycleInterface, PaygroupInterface } from '@src/interfaces/payroll'
import { ContractingCountryInterface } from '@src/interfaces/enitities'
import { FilterByInterface } from '@src/interfaces/data'
import { groupFiltersIntoURLQuery } from '@src/utils/table'

export const mapStatusToColor = (
  data: Partial<PaygroupInterface | ContractingCountryInterface>,
  theme: RevolutTheme,
) => {
  switch (data.status?.id) {
    case 'active':
      return theme.colors.green
    case 'inactive':
      return theme.colors.orange
    case 'existing':
      return theme.colors['grey-tone-50']
    default:
      return theme.colors.foreground
  }
}

export const mapCycleStatusToColor = (data: PayCycleInterface) => {
  switch (data.status?.id) {
    case 'open':
      return Token.color.green
    case 'closed':
      return Token.color.red
    case 'pending_closure':
      return Token.color.orange
    case 'scheduled':
      return Token.color.greyTone50
    default:
      return Token.color.foreground
  }
}

// Custom hook for checking if all items in an array have the same property value
export const useUniformValue = <T, K>(items?: T[], propertyPath?: string): K | null => {
  return useMemo(() => {
    if (!items || !propertyPath) {
      return null
    }

    const firstItem = get(items[0], propertyPath)

    if (!firstItem) {
      return null
    }

    const isUniform = items.every(item => get(item, propertyPath) === firstItem)
    return isUniform ? firstItem : null
  }, [items, propertyPath])
}

// TODO: RHR-3652 Create new pages for recon & delete payments tables instead of using payroll ones

export const isReconcilingExercisePayments = (
  shareOptionsPayGroupId: number,
  tableFilters: FilterByInterface[],
) => {
  const queryParams = groupFiltersIntoURLQuery(tableFilters) as {
    pay_cycle__pay_group_id: string
  }
  return queryParams?.pay_cycle__pay_group_id === `${shareOptionsPayGroupId}`
}

export const getIssuesInfoString = <T extends { criticals: number; warnings: number }>(
  data: T,
) => {
  if (data.criticals || data.warnings) {
    return (
      <>
        {chain(
          data.criticals ? (
            <Text color={Token.color.red}>{`${data.criticals} Critical`}</Text>
          ) : null,
          data.warnings ? (
            <Text color={Token.color.orange}>{`${data.warnings} Warning`}</Text>
          ) : null,
        )}
      </>
    )
  }

  return <Text color={Token.color.green}>0</Text>
}
