import Form from '@src/features/Form/Form'
import { googleCalendarRequests } from '@src/api/integrations'
import React from 'react'
import { CalendarForm } from '@src/pages/Hub/Integrations/Calendar/Calendar'

const CalendarIntegration = () => {
  return (
    <Form api={googleCalendarRequests} forceParams={{ id: '1' }}>
      <CalendarForm />
    </Form>
  )
}

export default CalendarIntegration
