import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Cell,
  Text,
  Box,
  List,
  Flex,
  VStack,
  HStack,
  COLORS,
  Button,
  BoxProps,
} from '@revolut/ui-kit'
import { CalendarWeek, InfoOutline } from '@revolut/icons'
import { navigateReplace } from '@src/actions/RouterActions'
import { pushError } from '@src/store/notifications/actions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import {
  resignationReasonsFormRequests,
  useResignationNextStepsList,
} from '@src/api/employeeResignation'
import {
  AddToAlumniNetworkVariants,
  AnonymousDisclosureVariants,
  ExperienceVariants,
} from '../../constants'
import { RouteParams } from '../../types'
import ValidationErrorPopup from '../../Components/ValidationErrorPopup'
import BottomInputMessage from '@src/components/BottomInputMessage/BottomInputMessage'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  EmployeeResignationInterface,
  ResignationApprovalInterface,
} from '@src/interfaces/employeeResignation'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import { formatApproval, getProfileBackUrl } from '../../common'
import { LetterFileWithStatus } from '../../Components/LetterFileWithStatus'
import { useResignationPermissions } from '../../hooks'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const getRenderRadioWithError =
  ({ hasError, boxProps }: { hasError: boolean; boxProps?: BoxProps }) =>
  (radio: React.ReactNode) =>
    (
      <Box mt="s-16" {...boxProps}>
        {React.cloneElement(radio as React.ReactElement, {
          hasError,
        })}
      </Box>
    )

type Props = {
  resignation?: EmployeeResignationInterface
  approval?: ResignationApprovalInterface
}
const SubmitResignationForm = ({ resignation, approval }: Props) => {
  const { id, employeeId } = useParams<RouteParams>()
  const { values } = useLapeContext<EmployeeResignationInterface>()
  const { data: nextStepsOptions = [] } = useResignationNextStepsList()
  const { canEdit } = useResignationPermissions(resignation)

  const [recommendRevolut, setRecommendRevolut] = useState<number>(
    resignation?.recommend_revolut || 0,
  )
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [openValidationErrorPopup, setOpenValidationErrorPopup] = useState<boolean>(false)

  useEffect(() => {
    if (resignation) {
      values.id = resignation.id || values.id
      values.notice_file = resignation.notice_file || values.notice_file
      values.positive_experience =
        resignation.positive_experience || values.positive_experience
      values.resignation_text = resignation.resignation_text || values.resignation_text
      values.revolut_alumni = resignation.revolut_alumni || values.revolut_alumni
      values.anonymous_disclosure =
        resignation.anonymous_disclosure || values.anonymous_disclosure
      values.employee_next_steps =
        resignation.employee_next_steps || values.employee_next_steps

      setRecommendRevolut(resignation?.recommend_revolut || values.recommend_revolut || 0)
    }
  }, [resignation])

  const hasAttachmentErr = !values.notice_file
  const hasExperienceErr = values.positive_experience === undefined
  const hasRecommendErr = !recommendRevolut
  const hasResignationTextErr = !values.resignation_text
  const hasRevolutAlumniErr = values.revolut_alumni === undefined
  const hasDisclosureErr = values.anonymous_disclosure === undefined
  const hasNextStepsErr = !values.employee_next_steps

  const handleSubmit = async () => {
    setIsFormSubmitted(true)

    const hasErrors =
      hasAttachmentErr ||
      hasExperienceErr ||
      hasRecommendErr ||
      hasResignationTextErr ||
      hasRevolutAlumniErr ||
      hasDisclosureErr ||
      hasNextStepsErr

    if (hasErrors) {
      setOpenValidationErrorPopup(true)
      return Promise.reject()
    }

    try {
      await resignationReasonsFormRequests.update(
        {
          ...values,
          recommend_revolut: recommendRevolut,
        },
        { id, employeeId },
      )
    } catch (error) {
      pushError({ error })
    }
    return Promise.resolve()
  }

  if (!canEdit) {
    return null
  }
  return (
    <>
      <PageBody>
        <AutoStepper>
          <Box mb="s-8">
            <Cell>
              <Flex flexDirection="column">
                <Text variant="primary" use="div" mb="s-8">
                  Your resignation will be submitted only once you fill in this form
                </Text>
                <List variant="compact">
                  <List.Item
                    useIcon={<InfoOutline color={COLORS['grey-tone-50']} size={16} />}
                  >
                    Please note that your notice period starts only after you submitted
                    your resignation letter. If you are looking to exit the business as
                    soon as possible, you can submit your resignation letter now.
                  </List.Item>
                  <List.Item useIcon={<CalendarWeek color={COLORS.blue} size={16} />}>
                    Your notice period is {values.notice_period}
                  </List.Item>
                </List>
              </Flex>
            </Cell>
          </Box>

          <NewStepperTitle title="Resignation letter" />

          {resignation && (
            <LetterFileWithStatus
              file={values.notice_file}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.LETTER_EDIT_PREVIEW, {
                id,
                employeeId,
              })}
              employeeId={employeeId}
              resignationId={values.id}
              approval={formatApproval(approval)}
              canAdd
            />
          )}

          {isFormSubmitted && hasAttachmentErr && (
            <Box mt="s-16">
              <BottomInputMessage
                hasError={isFormSubmitted && hasAttachmentErr}
                message="Attachment is required"
              />
            </Box>
          )}

          <NewStepperTitle title="Feedback form" />

          <Cell p="s-24">
            <VStack space="s-16">
              <Box pb="s-16">
                <Text variant="tile" use="div" mb="s-16">
                  1. What ultimately made you decide to leave the company and is there
                  anything Revolut could have done to keep you?
                </Text>
                <LapeNewTextArea
                  name="resignation_text"
                  label="Enter here"
                  rows={2}
                  hasError={isFormSubmitted && !values.resignation_text}
                  errorMessage="This field is mandatory"
                />
              </Box>
              <Box pb="s-16">
                <Text variant="tile">
                  2. All in all, how would you rate your time at Revolut?
                </Text>
                <LapeNewRadioButtons
                  name="positive_experience"
                  options={[
                    {
                      label: ExperienceVariants.positive,
                      value: true,
                    },
                    {
                      label: ExperienceVariants.negative,
                      value: false,
                    },
                  ]}
                  renderRadio={getRenderRadioWithError({
                    hasError: isFormSubmitted && hasExperienceErr,
                  })}
                />
                {isFormSubmitted && hasExperienceErr && (
                  <Box mt="s-16" ml="-s-16">
                    <BottomInputMessage
                      hasError={isFormSubmitted && hasExperienceErr}
                      message="This field is mandatory"
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <Text variant="tile">
                  3. How likely would you recommend Revolut as a place to work to a
                  friend?
                </Text>
                <HStack display="flex" align="center" space="s-12" pt="s-24" pb="s-16">
                  {Array(10)
                    .fill(null)
                    .map((_, idx) => idx + 1)
                    .map(score => {
                      const variant = recommendRevolut === score ? 'primary' : 'secondary'
                      return (
                        <Button
                          key={score}
                          variant={variant}
                          onClick={e => {
                            const rateValue = Number(e.currentTarget.innerText)
                            setRecommendRevolut(rateValue)
                            values.recommend_revolut = rateValue
                          }}
                        >
                          {score}
                        </Button>
                      )
                    })}
                </HStack>
                {isFormSubmitted && hasRecommendErr && (
                  <Box mt="s-8" ml="-s-16">
                    <BottomInputMessage
                      hasError={isFormSubmitted && hasRecommendErr}
                      message="This field is mandatory"
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <Text variant="tile">
                  4. Do you want to be added to the Revolut Alumni Network?
                </Text>
                <LapeNewRadioButtons
                  name="revolut_alumni"
                  options={[
                    {
                      label: AddToAlumniNetworkVariants.yes,
                      value: true,
                    },
                    {
                      label: AddToAlumniNetworkVariants.no,
                      value: false,
                    },
                  ]}
                  renderRadio={getRenderRadioWithError({
                    hasError: isFormSubmitted && hasRevolutAlumniErr,
                  })}
                />
                {isFormSubmitted && hasRevolutAlumniErr && (
                  <Box mt="s-16" ml="-s-16">
                    <BottomInputMessage
                      hasError={isFormSubmitted && hasRevolutAlumniErr}
                      message="This field is mandatory"
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <Text variant="tile">
                  5. I consent to my responses being anonymously disclosed to management
                  for the purpose of improving the employee experience at Revolut
                </Text>
                <LapeNewRadioButtons
                  name="anonymous_disclosure"
                  options={[
                    {
                      label: AnonymousDisclosureVariants.accept,
                      value: true,
                    },
                    {
                      label: AnonymousDisclosureVariants.refuse,
                      value: false,
                    },
                  ]}
                  renderRadio={getRenderRadioWithError({
                    hasError: isFormSubmitted && hasDisclosureErr,
                  })}
                />
                {isFormSubmitted && hasDisclosureErr && (
                  <Box mt="s-16" ml="-s-16">
                    <BottomInputMessage
                      hasError={isFormSubmitted && hasDisclosureErr}
                      message="This field is mandatory"
                    />
                  </Box>
                )}
              </Box>
            </VStack>
          </Cell>

          <NewStepperTitle title="Your next steps" />
          <Cell p="s-24">
            <Flex flexDirection="column">
              <Text mb="s-8" variant="tile">
                What's next for you?
              </Text>
              <LapeNewRadioButtons
                name="employee_next_steps"
                options={nextStepsOptions.map(step => {
                  return { label: step, value: step }
                })}
                renderRadio={getRenderRadioWithError({
                  hasError: isFormSubmitted && hasNextStepsErr,
                  boxProps: { my: 's-8' },
                })}
              />
              {isFormSubmitted && hasNextStepsErr && (
                <Box mt="s-8" ml="-s-16">
                  <BottomInputMessage
                    hasError={isFormSubmitted && hasNextStepsErr}
                    message="This field is mandatory"
                  />
                </Box>
              )}
            </Flex>
          </Cell>
          <Box mb="s-64" />
        </AutoStepper>
      </PageBody>
      <PageActions>
        <Box maxWidth={375}>
          <HStack space="s-16">
            <Button
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.REASONS, {
                id,
                employeeId,
              })}
              variant="secondary"
            >
              Go back
            </Button>
            <NewSaveButtonWithPopup
              onClick={handleSubmit}
              successText="Your resignation form was successfully submitted"
              onAfterSubmit={() => {
                navigateReplace(getProfileBackUrl(Number(employeeId)))
              }}
              hideWhenNoChanges={false}
            >
              Submit
            </NewSaveButtonWithPopup>
          </HStack>
        </Box>
      </PageActions>
      <ValidationErrorPopup
        open={openValidationErrorPopup}
        close={() => setOpenValidationErrorPopup(false)}
      />
    </>
  )
}

export default SubmitResignationForm
