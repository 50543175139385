import React from 'react'
import {
  AddContact,
  ArrowExchange,
  BarChart,
  Document,
  DocumentsPair,
  Laptop,
  LocationArrowOutline,
  People,
  Premium,
  Resort,
  Check,
  Services,
  RepairTool,
  LocationPin,
  Globe,
  Target,
  Trophy,
  Wealth,
  Timeline,
  HelpChatOutline,
} from '@revolut/icons'

export const features = [
  {
    id: 'premium',
    description: (
      <>
        The modern all-in-one HR solution for all your HR, Recruitment and Performance
        needs.
        <br />
        Spend less time on processes and more time on people.
      </>
    ),
    icon: Premium,
  },
  {
    id: 'hr',
    title: 'HR management',
    description:
      'Covers all core HR needs: Employee records, Contracts, Time off, Documents automation, Benefits, Compensation management',
    icon: People,
  },
  {
    id: 'recruitment',
    title: 'Recruitment',
    description:
      'Covers all aspects of the recruitment product: Job adverts, Candidate tracking, interviewing, interview scheduling, offers',
    icon: AddContact,
  },
  {
    id: 'performance',
    title: 'Performance',
    description:
      'Provides advances performance capabilities: Competencies, Promotions, KPIs',
    icon: BarChart,
  },
]

export const featureBreakdown = [
  {
    title: 'HR management',
    features: [
      {
        title: 'Employees',
        icon: People,
      },
      {
        title: 'Lifecycle',
        icon: ArrowExchange,
      },
      {
        title: 'Timeoff',
        icon: Resort,
      },
      {
        title: 'Contracts',
        icon: Document,
      },
      {
        title: 'Documents',
        icon: DocumentsPair,
      },
    ],
  },
  {
    title: 'Recruitment',
    features: [
      {
        title: 'Requisitions',
        icon: AddContact,
      },
      {
        title: 'Job postings',
        icon: Laptop,
      },
      {
        title: 'Careers page',
        icon: LocationArrowOutline,
      },
      {
        title: 'Candidates',
        icon: Check,
      },
    ],
  },
  {
    title: 'Organisation',
    features: [
      {
        title: 'Teams',
        icon: Services,
      },
      {
        title: 'Functions',
        icon: RepairTool,
      },
      {
        title: 'Locations',
        icon: LocationPin,
      },
      {
        title: 'Legal entities',
        icon: Globe,
      },
    ],
  },
  {
    title: 'Performance',
    features: [
      {
        title: 'Goals',
        icon: Target,
      },
      {
        title: 'KPIs',
        icon: Trophy,
      },
      {
        title: 'Performance',
        icon: Wealth,
      },
      {
        title: 'Roadmaps',
        icon: Timeline,
      },
    ],
  },
  {
    title: 'Administration and support',
    features: [
      {
        title: 'Customer support',
        icon: HelpChatOutline,
      },
      {
        title: 'Access management',
        icon: People,
      },
    ],
  },
]
