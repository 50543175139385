import React from 'react'
import { Box } from '@revolut/ui-kit'
import CandidateData from '@src/features/InterviewTool/CandidateData'
import {
  InterviewRoundInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'
import SideBar from '@components/SideBar/SideBar'
import ChangeRound from '@src/pages/Forms/Candidate/InterviewProgress/components/ChangeRound/ChangeRound'

type Props = {
  isOpen: boolean
  onClose: () => void
  onShowCV?: () => void
  showOpenPostings?: boolean
  round: InterviewRoundInterface
  canViewCandidate?: boolean
  interviewer?: InterviewToolInterviewer
}

const CandidateInfoSidebar = ({
  isOpen,
  onClose,
  onShowCV,
  showOpenPostings,
  round,
  interviewer,
  canViewCandidate = false,
}: Props) => {
  // we cannot fetch the whole candidate object due to permissions, that's why we take it from round
  const candidate = round.candidate

  return (
    <SideBar isOpen={isOpen} onClose={onClose} title="Candidate info">
      <Box>
        <CandidateData
          round={round}
          candidate={candidate}
          opportunityId={round?.opportunity_id}
          interviewer={interviewer}
          permissions={{
            canViewCandidate,
            canAddRound: false,
            canEditCandidateSidebar: false,
            canEditPersonalDetails: false,
            canViewAppliedJobs: false,
            canOpenAppliedJobs: false,
            canEditRound: false,
          }}
          onShowCV={onShowCV}
        />
        {showOpenPostings && canViewCandidate && (
          <Box mt="s-16">
            <ChangeRound candidateId={candidate.id} activeRoundId={round.id} readOnly />
          </Box>
        )}
      </Box>
    </SideBar>
  )
}

export default CandidateInfoSidebar
