import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import FilterSelect from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { OptionInterface } from '@src/interfaces/selectors'
import Chip from '../../components/Chip/Chip'
import Icon from '../../components/Icon/Icon'
import { PerformanceReviewerSelector } from '@src/interfaces/performance'

const Container = styled.div`
  display: flex;
  align-items: center;
`
const ReviewerFilterChip = styled(Chip)`
  max-width: initial;
`
const FilterIcon = styled(Icon)`
  height: 18px;
  color: ${props => props.theme.colors.blue};
`
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`
const EmptyFilterDescription = styled.div`
  margin-left: 8px;
  color: ${props => props.theme.colors.blue};
  font-size: 14px;
`
const CustomFilters = styled.div`
  position: relative;
  display: flex;
  margin-left: 16px;
`
const Highlighter = styled.button<{
  selectedFilter?: ClientRect
  container?: ClientRect
}>`
  position: absolute;
  background-color: rgba(0, 117, 235, 0.06);
  border-radius: 16px;
  transition: all ease-in-out 200ms;
  left: ${({ selectedFilter, container }) =>
    selectedFilter && container ? selectedFilter.left - container.left : '0'}px;
  width: ${({ selectedFilter }) => (selectedFilter ? selectedFilter.width : '0')}px;
  height: ${({ selectedFilter }) => (selectedFilter ? selectedFilter.height : '0')}px;
`
const CustomFilter = styled.button`
  padding: 5px 8px;
  color: ${props => props.theme.colors.blue};

  &:hover {
    opacity: 0.8;
  }
`
const FilterButton = styled.button`
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
`
const ChipContainer = styled.div``

interface ScorecardFiltersProps {
  className?: string
  selector: () => Promise<{ options: PerformanceReviewerSelector[] }>
  onFilterChange: (options: OptionInterface[]) => void
  cycleId?: number | string
  filters?: OptionInterface[]
}

enum Filters {
  Self = 'Self',
  LineManager = 'Manager',
  Team = 'Team',
  Others = 'Others',
}

export const ScorecardFilters = ({
  className,
  filters,
  cycleId,
  selector,
  onFilterChange,
}: ScorecardFiltersProps) => {
  const popoverAnchor = useRef<HTMLDivElement>(null)
  const [openFilter, setOpenFilter] = useState(false)
  const [customFilters, setCustomFilters] = useState<{
    [key in Filters]?: PerformanceReviewerSelector[]
  }>({})
  const [selectedCustomFilter, setSelectedCustomFilter] = useState<DOMRect>()
  const LineManagerRef = useRef<HTMLButtonElement>(null)
  const TeamRef = useRef<HTMLButtonElement>(null)
  const SelfRef = useRef<HTMLButtonElement>(null)
  const customFiltersRef = useRef<HTMLDivElement>(null)
  const OtherRef = useRef<HTMLButtonElement>(null)
  const customFiltersContainerRect = useMemo(
    () => customFiltersRef?.current?.getBoundingClientRect(),
    [customFiltersRef?.current, selectedCustomFilter],
  )

  const customFiltersRefs = (customFilter: Filters) => {
    switch (customFilter) {
      case Filters.Self:
        return SelfRef?.current
      case Filters.LineManager:
        return LineManagerRef?.current
      case Filters.Team:
        return TeamRef?.current
      case Filters.Others:
        return OtherRef?.current
    }

    return undefined
  }

  const populateCustomFilters = async (reviews: PerformanceReviewerSelector[]) => {
    const selfFilters = reviews.filter(rev => rev.reviewer_relation === 'self')
    const lineManagerFilter = reviews.filter(
      rev => rev.reviewer_relation === 'line_manager',
    )
    const teamsFilters = reviews.filter(rev => rev.reviewer_relation === 'team_mate')
    const otherFilters = reviews.filter(rev => rev.reviewer_relation === 'other')
    setCustomFilters({
      [Filters.Self]: selfFilters.length ? selfFilters : undefined,
      [Filters.LineManager]: lineManagerFilter.length ? lineManagerFilter : undefined,
      [Filters.Team]: teamsFilters.length ? teamsFilters : undefined,
      [Filters.Others]: otherFilters.length ? otherFilters : undefined,
    })
  }

  useEffect(() => {
    if (cycleId !== undefined) {
      setCustomFilters({})
      setSelectedCustomFilter(undefined)
      fetchSelector()
    }
  }, [cycleId])

  const fetchSelector = async () => {
    const result = await selector()
    populateCustomFilters(result.options)
  }

  const handleCustomFilterClick = (filter: Filters) => {
    setSelectedCustomFilter(customFiltersRefs(filter)?.getBoundingClientRect())
    onFilterChange(customFilters[filter] as OptionInterface[])
  }

  const handleOpenFilter = () => {
    setOpenFilter(true)
  }

  const handleCloseFilter = () => {
    setOpenFilter(false)
  }

  const handleFilterChange = (chosenFilters: OptionInterface[]) => {
    handleClearFilter()
    setSelectedCustomFilter(undefined)
    onFilterChange(chosenFilters)
  }

  const handleClearFilter = () => {
    onFilterChange([])
    setSelectedCustomFilter(undefined)
    handleCloseFilter()
  }

  return (
    <Container className={className}>
      {popoverAnchor.current && (
        <FilterSelect
          open={openFilter}
          anchorRef={popoverAnchor}
          onClose={handleCloseFilter}
          value={filters}
          onChange={handleFilterChange}
          selector={selector}
        />
      )}
      <ChipContainer ref={popoverAnchor}>
        {filters && filters.length ? (
          <ReviewerFilterChip
            onRemove={handleClearFilter}
            onClick={handleOpenFilter}
            label={
              filters.length === 1 && !selectedCustomFilter
                ? filters[0].name
                : `Selected · ${filters.length}`
            }
          />
        ) : (
          <FilterContainer>
            <FilterButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                handleOpenFilter()
              }}
            >
              <FilterIcon type="FilterList" />
              <EmptyFilterDescription>All reviews</EmptyFilterDescription>
            </FilterButton>
          </FilterContainer>
        )}
      </ChipContainer>
      {!(filters?.length === 1 && !selectedCustomFilter) && (
        <CustomFilters ref={customFiltersRef}>
          <Highlighter
            onClick={e => {
              e.preventDefault()
              handleClearFilter()
            }}
            selectedFilter={selectedCustomFilter}
            container={customFiltersContainerRect}
          />
          {customFilters[Filters.Self] && (
            <CustomFilter
              ref={SelfRef}
              onClick={e => {
                e.preventDefault()
                handleCustomFilterClick(Filters.Self)
              }}
            >
              {Filters.Self}
            </CustomFilter>
          )}
          {customFilters[Filters.LineManager] && (
            <CustomFilter
              ref={LineManagerRef}
              onClick={e => {
                e.preventDefault()
                handleCustomFilterClick(Filters.LineManager)
              }}
            >
              {Filters.LineManager}
            </CustomFilter>
          )}
          {customFilters[Filters.Team] && (
            <CustomFilter
              ref={TeamRef}
              onClick={e => {
                e.preventDefault()
                handleCustomFilterClick(Filters.Team)
              }}
            >
              {Filters.Team}
            </CustomFilter>
          )}
          {customFilters[Filters.Others] && (
            <CustomFilter
              ref={OtherRef}
              onClick={e => {
                e.preventDefault()
                handleCustomFilterClick(Filters.Others)
              }}
            >
              {Filters.Others}
            </CustomFilter>
          )}
        </CustomFilters>
      )}
    </Container>
  )
}
