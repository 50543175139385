import React, { useState, createRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Cell,
  Text,
  Box,
  List,
  Flex,
  Input,
  Checkbox,
  Group,
  CheckboxGroup,
  VStack,
  RadioGroup,
  Radio,
  COLORS,
  Button,
  Popup,
  Header,
} from '@revolut/ui-kit'
import { BrokenHeart, InfoOutline } from '@revolut/icons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import {
  EmployeeResignationInterface,
  Reason,
  Subreason,
} from '@src/interfaces/employeeResignation'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import BottomInputMessage from '@src/components/BottomInputMessage/BottomInputMessage'
import { useFormSubmit, useReasons } from './hooks'
import { ToldManagerVariants } from '../../constants'
import ValidationErrorPopup from '../../Components/ValidationErrorPopup'
import { RouteParams } from '../../types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const customReasonInputRef = createRef<HTMLInputElement>()

type Props = {
  resignation?: EmployeeResignationInterface
}
const ReasonsForm = ({ resignation }: Props) => {
  const { values } = useLapeContext<EmployeeResignationInterface>()
  const { employeeId } = useParams<RouteParams>()

  const [toldManager, setToldManager] = useState<string | null>(() => {
    if (resignation?.told_manager) {
      return values.told_manager ? ToldManagerVariants.yes : ToldManagerVariants.no
    }
    return null
  })
  const [openValidationErrorPopup, setOpenValidationErrorPopup] = useState<boolean>(false)
  const [isRegrettablePopupShown, setIsRegrettablePopupShown] = useState<boolean>(false)
  const [newResignationId, setNewResignationId] = useState<string>()

  useEffect(() => {
    if (resignation) {
      values.selected_reasons = resignation.selected_reasons
      values.told_manager = resignation.told_manager
    }
  }, [resignation])

  const {
    reasonsOptions,
    selectedReasons,
    selectedSubreasons,
    reasonsMeta,
    emptySubreasonsListsKeys,
    setSelectedReasons,
    setSelectedSubreasons,
  } = useReasons(values.selected_reasons)

  const reasonsOptionsList = Object.keys(reasonsOptions)
  const hasErrors =
    !toldManager || reasonsMeta.isEmpty || !!emptySubreasonsListsKeys.length

  const { isFormSubmitted, handleSubmit } = useFormSubmit({
    selectedReasons,
    selectedSubreasons,
    toldManager,
    hasErrors,
    setNewResignationId,
    showErrorPopup: () => setOpenValidationErrorPopup(true),
    showIsRegrettablePopup: () => setIsRegrettablePopupShown(true),
  })
  const reasonsListError = isFormSubmitted && reasonsMeta.isEmpty

  const getHandleCustomSubreasonCheck =
    (reason: Reason, inputRef: React.RefObject<HTMLInputElement>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked
      if (checked) {
        inputRef.current?.focus()
      }
      selectedSubreasons[reason].custom.checked = checked
      setSelectedSubreasons({ ...selectedSubreasons })
    }
  const getHandleCustomSubreasonInput =
    (reason: Reason) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value
      selectedSubreasons[reason].custom.text = text
      setSelectedSubreasons({ ...selectedSubreasons })
    }

  return (
    <>
      <PageBody>
        <AutoStepper>
          <Box mb="s-8">
            <Cell>
              <Flex flexDirection="column">
                <Box mb="s-8">
                  <Text variant="primary">
                    We're really sorry to hear that you are planning to leave Revolut
                  </Text>
                </Box>
                <List variant="compact">
                  <List.Item useIcon={<BrokenHeart color={COLORS.red} size={16} />}>
                    Before submitting your request to exit the business, we recommend that
                    you discuss this with your line manager and/or functional manager.
                  </List.Item>
                  <List.Item
                    useIcon={<InfoOutline color={COLORS['grey-tone-50']} size={16} />}
                  >
                    Please note that the answers to the questionnaire below will only be
                    shared with HR and your line manager. You can find more information
                    about the company exit policy here.
                  </List.Item>
                </List>
              </Flex>
            </Cell>
          </Box>
          <NewStepperTitle title="Motivation" />
          <Cell>
            <Flex flexDirection="column">
              <Box mb="s-4" mt="s-8">
                <Text variant="h5">Reasons to leave</Text>
              </Box>
              <Box mb="s-16">
                <Text variant="secondary" color={COLORS['grey-tone-50']}>
                  Please tell us about all the reasons that you’ve decided to submit your
                  resignation. Select up to three picks that are most relevant for your
                  situation. In next section of the form you’ll be able to specify
                  details.
                </Text>
              </Box>
              {!!reasonsOptionsList.length && (
                <Group>
                  <CheckboxGroup
                    value={selectedReasons.values}
                    onChange={(newReasonsList: Reason[]) => {
                      setSelectedReasons({
                        ...selectedReasons,
                        values: newReasonsList,
                      })
                    }}
                  >
                    {group => (
                      <VStack space="s-16">
                        {reasonsOptionsList.map(reason => {
                          return (
                            <Checkbox
                              key={reason}
                              // @ts-ignore: ui-kit typing issue
                              {...group.getInputProps<{ value: ReasonInterface }>({
                                value: reason,
                              })}
                              disabled={
                                !selectedReasons.values.includes(reason) &&
                                reasonsMeta.isMaxReasons
                              }
                              aria-invalid={reasonsListError}
                            >
                              <Text variant="secondary">{reason}</Text>
                            </Checkbox>
                          )
                        })}
                        <Box ml="-s-16" mt="-s-16">
                          <Input
                            ref={customReasonInputRef}
                            label={`${'\u00A0'}Other`}
                            value={selectedReasons.custom.text}
                            onChange={e => {
                              selectedReasons.custom.text = e.currentTarget.value
                              setSelectedReasons({ ...selectedReasons })
                            }}
                            renderPrefix={() => (
                              <Checkbox
                                onChange={e => {
                                  const checked = e.target.checked
                                  if (checked) {
                                    customReasonInputRef.current?.focus()
                                  }
                                  selectedReasons.custom.checked = checked
                                  setSelectedReasons({ ...selectedReasons })
                                }}
                                aria-invalid={reasonsListError}
                                disabled={
                                  !selectedReasons.custom.checked &&
                                  reasonsMeta.isMaxReasons
                                }
                                checked={selectedReasons.custom.checked}
                              />
                            )}
                          />
                        </Box>
                      </VStack>
                    )}
                  </CheckboxGroup>
                </Group>
              )}
            </Flex>
          </Cell>
          {reasonsListError && (
            <Box mt="s-16" ml="-s-16">
              <BottomInputMessage
                hasError={reasonsListError}
                message="Choose at least one answer"
              />
            </Box>
          )}

          <NewStepperTitle title="Details of your resignation reasons" />
          {selectedReasons.values.length ? (
            selectedReasons.values.map(reason => {
              const hasError =
                isFormSubmitted && emptySubreasonsListsKeys.includes(reason)
              const subreasonsOptions = reasonsOptions[reason] || []
              if (!subreasonsOptions.length) {
                return null
              }
              return (
                <Box key={reason} mb="s-16">
                  <Cell>
                    <Flex flexDirection="column">
                      <Box mb="s-4" mt="s-8">
                        <Text variant="h5">{reason}</Text>
                      </Box>
                      <Box mb="s-16">
                        <Text variant="secondary" color={COLORS['grey-tone-50']}>
                          Please select all the answers that apply. To learn more about
                          how we manage responsabilities for all the roles at Revolut
                          please check the following Confluence page.
                        </Text>
                      </Box>
                      <Group>
                        <CheckboxGroup
                          value={selectedSubreasons[reason]?.values || []}
                          onChange={newSubreasons => {
                            selectedSubreasons[reason].values = newSubreasons
                            setSelectedSubreasons({ ...selectedSubreasons })
                          }}
                        >
                          {group => {
                            const subreasonsInputRef = createRef<HTMLInputElement>()
                            const handleCustomSubreasonCheck =
                              getHandleCustomSubreasonCheck(reason, subreasonsInputRef)
                            const handleCustomSubreasonInput =
                              getHandleCustomSubreasonInput(reason)
                            return (
                              <VStack space="s-16">
                                {subreasonsOptions.map((subreason: Subreason) => {
                                  return (
                                    <Checkbox
                                      key={subreason}
                                      // @ts-ignore: ui-kit typing issue
                                      {...group.getInputProps<{
                                        value: Subreason
                                      }>({
                                        value: subreason,
                                      })}
                                      aria-invalid={hasError}
                                    >
                                      <Text variant="secondary">{subreason}</Text>
                                    </Checkbox>
                                  )
                                })}
                                <Box ml="-s-16" mt="-s-16">
                                  <Input
                                    ref={subreasonsInputRef}
                                    label={`${'\u00A0'}Other`}
                                    value={selectedSubreasons[reason]?.custom?.text || ''}
                                    renderPrefix={() => (
                                      <Checkbox
                                        onChange={handleCustomSubreasonCheck}
                                        aria-invalid={hasError}
                                        checked={
                                          !!selectedSubreasons[reason]?.custom?.checked
                                        }
                                      />
                                    )}
                                    onChange={handleCustomSubreasonInput}
                                  />
                                </Box>
                              </VStack>
                            )
                          }}
                        </CheckboxGroup>
                      </Group>
                    </Flex>
                  </Cell>
                  {hasError && (
                    <Box mt="s-16" ml="-s-16">
                      <BottomInputMessage
                        hasError={hasError}
                        message="Choose at least one answer"
                      />
                    </Box>
                  )}
                </Box>
              )
            })
          ) : (
            <Cell>
              <Text>
                Once you choose the reasons you’ve decided to trigger resignation process,
                in this section you’ll find additional form where you’ll be able to
                provide us with more information.
              </Text>
            </Cell>
          )}
          <NewStepperTitle title="Actions" />
          <Cell>
            <Flex flexDirection="column">
              <Text variant="tile">
                Have you discussed your reasons to leave with your LM/FM?
              </Text>
              <Box mt="s-16">
                <RadioGroup value={toldManager} onChange={setToldManager}>
                  {group => (
                    <VStack space="s-16">
                      <Radio
                        {...group.getInputProps({ value: ToldManagerVariants.yes })}
                        aria-invalid={isFormSubmitted && !toldManager}
                      >
                        <Text variant="secondary">{ToldManagerVariants.yes}</Text>
                      </Radio>
                      <Radio
                        {...group.getInputProps({ value: ToldManagerVariants.no })}
                        aria-invalid={isFormSubmitted && !toldManager}
                      >
                        <Text variant="secondary">{ToldManagerVariants.no}</Text>
                      </Radio>
                    </VStack>
                  )}
                </RadioGroup>
                {isFormSubmitted && !toldManager && (
                  <Box mt="s-16" ml="-s-16">
                    <BottomInputMessage
                      hasError={isFormSubmitted && !toldManager}
                      message="This field is mandatory"
                    />
                  </Box>
                )}
              </Box>
            </Flex>
          </Cell>
          <Box mb="s-64" />
        </AutoStepper>
      </PageBody>
      <PageActions>
        <Button onClick={handleSubmit}>Continue</Button>
      </PageActions>
      <ValidationErrorPopup
        open={openValidationErrorPopup}
        close={() => setOpenValidationErrorPopup(false)}
      />
      <Popup
        variant="bottom-sheet"
        open={isRegrettablePopupShown}
        onClose={() => setIsRegrettablePopupShown(false)}
      >
        <Header variant="compact">
          <Header.Title>
            <Text>How do you want to proceed now?</Text>
          </Header.Title>
          <Header.Description>
            <Box pt="s-12">
              If you are willing to negotiate, you can send only this form and wait for HR
              or your LM to discuss the potential solutions for you to remain in the
              company.
            </Box>
            <Box pt="s-12">
              If you want to trigger your notice period right now, you need to submit your
              official resignation.
            </Box>
          </Header.Description>
        </Header>
        <VStack space="s-16">
          <Button
            mt="s-8"
            size="md"
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: employeeId })}
          >
            I want to wait and negotiate
          </Button>
          <Button
            variant="secondary"
            size="md"
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.SUBMIT, {
              id: newResignationId,
              employeeId,
            })}
          >
            I want to submit my resignation
          </Button>
        </VStack>
      </Popup>
    </>
  )
}

export default ReasonsForm
