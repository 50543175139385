import React from 'react'
import { Box, themeTransition } from '@revolut/ui-kit'
import { css } from 'styled-components'
import { colorGetter } from '@src/styles/colors'

const boxWithHighlightOnHover = css`
  :hover {
    background-color: ${colorGetter('grey-tone-10')};
  }
  transition: ${themeTransition('background')};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`

interface MainHeaderButtonProps {
  children: React.ReactNode
}

export const MainHeaderButton = ({ children }: MainHeaderButtonProps) => {
  return (
    <Box css={boxWithHighlightOnHover} size={36} radius="widget">
      {children}
    </Box>
  )
}
