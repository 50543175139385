import React from 'react'
import { Box, Button, Header, Popup, Text } from '@revolut/ui-kit'

type Props = {
  open: boolean
  close: () => void
}

const ValidationErrorPopup = ({ open, close }: Props) => {
  return (
    <Popup variant="bottom-sheet" open={open} onClose={close}>
      <Header variant="compact">
        <Header.Title>
          <Text>Incomplete form</Text>
        </Header.Title>
        <Header.Description>
          <Box pt="s-12">
            Some of the mandatory fields have been left unanswered. Please fill them to
            continue.
          </Box>
        </Header.Description>
      </Header>
      <Button mt="s-8" size="md" onClick={close}>
        Go back to missing fields
      </Button>
    </Popup>
  )
}

export default ValidationErrorPopup
