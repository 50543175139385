import React, { useEffect, useState } from 'react'
import SectionTitle from '../components/SectionTitle'
import { Message } from '../components/Message'
import {
  Box,
  Cell,
  Flex,
  Spacer,
  Token,
  VStack,
  Text,
  ItemSkeleton,
} from '@revolut/ui-kit'
import { CultureValueInterface } from '@src/interfaces/cultureValues'
import CultureValuesTable from '@src/pages/Performance/CultureValues/CultureValuesTable'
import {
  valuesBehavioursCountColumn,
  valuesCategoryColumn,
  valuesNameColumn,
} from '@src/constants/columns/cultureValues'
import { cultureValuesRequestsNew, deleteValue } from '@src/api/cultureValues'
import { ValuesTab } from '../Performance/ScoreCardPreview/Values'
import { CardTypes, ValueBasedCardInterface } from '@src/interfaces/performance'
import { getDeleteOrgUnitActionColumn } from '@src/constants/columns/common'

export const ValuesPreview = () => {
  const [selectedValue, setSelectedValue] = useState<CultureValueInterface>()
  const [cards, setCards] = useState<Record<number, ValueBasedCardInterface>>({})

  useEffect(() => {
    if (selectedValue && !cards[selectedValue.id]) {
      cultureValuesRequestsNew.get({ id: String(selectedValue.id) }).then(({ data }) => {
        setCards({
          ...cards,
          [data.id]: {
            name: data.name,
            description: data.description,
            type: CardTypes.companyValueCard,
            rating: null,
            value_id: data.id,
            sections: data.behaviours.map(behaviour => ({
              behaviour_id: behaviour.id!,
              description: behaviour.description,
              text: behaviour.name,
              justification: null,
            })),
          },
        })
      })
    }
  }, [selectedValue])

  const valuesPreviewSkeleton = (
    <VStack space="s-12">
      <ItemSkeleton>
        <ItemSkeleton.Content />
      </ItemSkeleton>
      <ItemSkeleton>
        <ItemSkeleton.Content />
      </ItemSkeleton>
      <ItemSkeleton>
        <ItemSkeleton.Content />
      </ItemSkeleton>
      <ItemSkeleton>
        <ItemSkeleton.Content />
      </ItemSkeleton>
    </VStack>
  )

  return (
    <>
      <Flex gap="s-16">
        <VStack width="60%">
          <SectionTitle
            title="Confirm your values"
            subtitle="You can check the scorecard preview by clicking on the row in the table"
          />
          <Message
            storageKey="onboarding_values_preview_message"
            title="We have created Values just for you"
            description="Feel free to take a look and make any adjustments to suit your preferences"
          />
          <Spacer height="s-16" />
          <CultureValuesTable
            showQuickFilters
            createVariant="popup"
            createRow={table => ({
              highlight: value => (selectedValue === value ? Token.color.greyTone8 : ''),
              linkToForm: value => setSelectedValue(value),
              cells: [
                {
                  ...valuesNameColumn,
                  width: 150,
                },
                {
                  ...valuesCategoryColumn,
                  width: 150,
                },
                {
                  ...valuesBehavioursCountColumn,
                  title: 'Defined behaviours',
                  textAlign: 'right',
                  width: 100,
                },
                {
                  ...getDeleteOrgUnitActionColumn({
                    handleDetete: async (value: CultureValueInterface) => {
                      const result = await deleteValue(value.id)
                      table.refresh()
                      return result
                    },
                    props: {
                      confirmMessage: 'Are you sure you want to delete this value',
                    },
                  }),
                  width: 70,
                },
              ],
            })}
          />
        </VStack>
        <Box width="40%">
          {selectedValue && (
            <Cell>
              <VStack>
                <Text variant="caption" color={Token.color.greyTone50}>
                  Values preview
                </Text>
                {cards[selectedValue.id] ? (
                  <ValuesTab values={cards[selectedValue.id]} />
                ) : (
                  valuesPreviewSkeleton
                )}
              </VStack>
            </Cell>
          )}
        </Box>
      </Flex>
    </>
  )
}
