import React from 'react'
import { Button } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  CandidateOwnershipInterface,
  DataOwnershipInterface,
  VendorOwnershipInterface,
} from '@src/interfaces/ownership'
import { selectorKeys } from '../api'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '../routes'

export const ownershipCategoryColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'category',
  dataPoint: 'category',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Category',
}

export const ownershipItemsColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'items',
  dataPoint: 'items',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Items',
}

export const ownershipDescriptionColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const ownershipTransferToColumn: ColumnInterface<{}> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Transfer to',
}

export const ownershipActionColumn: ColumnInterface<{}> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
}

export const vendorOwnershipNameColumn: ColumnInterface<VendorOwnershipInterface> = {
  type: CellTypes.text,
  idPoint: 'vendor_name',
  dataPoint: 'vendor_name',
  sortKey: 'vendor_name',
  filterKey: 'vendor_name',
  selectorsKey: selectorKeys.vendors_names,
  title: 'Vendor name',
}

export const vendorOwnershipLegalEntityColumn: ColumnInterface<VendorOwnershipInterface> =
  {
    type: CellTypes.text,
    idPoint: 'legal_entity',
    dataPoint: 'legal_entity',
    sortKey: 'legal_entity',
    filterKey: 'legal_entity',
    selectorsKey: selectorKeys.vendors_legal_entities,
    title: 'Vendor legal entity',
  }

export const vendorOwnershipStatusColumn: ColumnInterface<VendorOwnershipInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.vendors_statuses,
  title: 'Status',
}

export const vendorOwnershipRevolutLegalEntityColumn: ColumnInterface<VendorOwnershipInterface> =
  {
    type: CellTypes.text,
    idPoint: 'revolut_entity',
    dataPoint: 'revolut_entity',
    sortKey: 'revolut_entity',
    filterKey: 'revolut_entity',
    selectorsKey: selectorKeys.vendors_revolut_entities,
    title: 'Revolut legal entity',
  }

export const vendorOwnershipActionColumn: ColumnInterface<VendorOwnershipInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    return (
      <Button
        use="a"
        href={data.vendor_link}
        display="inline-block"
        size="xs"
        variant="secondary"
        px="s-16"
        target="_blank"
      >
        Go to Backoffice
      </Button>
    )
  },
}

export const dataOwnershipDependencyColumn: ColumnInterface<DataOwnershipInterface> = {
  type: CellTypes.text,
  idPoint: 'dependency',
  dataPoint: 'dependency',
  sortKey: 'dependency',
  filterKey: 'dependency',
  selectorsKey: selectorKeys.data_dependencies,
  title: 'Dependency',
}

export const dataOwnershipResourceColumn: ColumnInterface<DataOwnershipInterface> = {
  type: CellTypes.text,
  idPoint: 'object_name',
  dataPoint: 'object_name',
  sortKey: 'object_name',
  filterKey: 'object_name',
  selectorsKey: selectorKeys.data_objects_names,
  title: 'Resource',
}

export const dataOwnershipInstructionsColumn: ColumnInterface<DataOwnershipInterface> = {
  type: CellTypes.text,
  idPoint: 'ownership_transfer_instruction',
  dataPoint: 'ownership_transfer_instruction',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Instructions',
}

export const dataOwnershipActionColumn: ColumnInterface<DataOwnershipInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    return (
      <Button
        use="a"
        href={data.object_link}
        display="inline-block"
        size="xs"
        variant="secondary"
        px="s-16"
        target="_blank"
      >
        Go to {data.data_interface}
      </Button>
    )
  },
}

export const candidateOwnershipNameColumn: ColumnInterface<CandidateOwnershipInterface> =
  {
    type: CellTypes.text,
    idPoint: 'candidate.id',
    dataPoint: 'candidate.full_name',
    sortKey: 'candidate',
    filterKey: 'candidate',
    selectorsKey: selectorKeys.interviews_candidates,
    dynamicHyperlinks: data =>
      pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: data.candidate.id }),
    title: 'Name',
  }

export const candidateOwnershipRecruiterNameColumn: ColumnInterface<CandidateOwnershipInterface> =
  {
    type: CellTypes.text,
    idPoint: 'recruiter.id',
    dataPoint: 'recruiter.full_name',
    sortKey: 'recruiter',
    filterKey: 'recruiter',
    selectorsKey: selectorKeys.employee,
    title: 'Recruiter',
  }

export const candidateOwnershipSpecialisationColumn: ColumnInterface<CandidateOwnershipInterface> =
  {
    type: CellTypes.text,
    idPoint: 'specialisation.id',
    dataPoint: 'specialisation.name',
    sortKey: 'specialisation',
    filterKey: 'specialisation',
    selectorsKey: selectorKeys.specialisations,
    title: 'Role (Specialisation)',
  }

export const candidateOwnershipHiringManagerNameColumn: ColumnInterface<CandidateOwnershipInterface> =
  {
    type: CellTypes.text,
    idPoint: 'hiring_manager.id',
    dataPoint: 'hiring_manager.full_name',
    sortKey: 'hiring_manager',
    filterKey: 'hiring_manager',
    selectorsKey: selectorKeys.employee,
    title: 'Hiring manager',
  }
