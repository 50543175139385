import React from 'react'
import HiringPipelinesCommonTable, {
  HiringPipelinesCommonTableProps,
} from '@src/pages/Recruitment/RecruitmentSubTabs/HiringPipelines/HiringPipelinesCommonTable'

const AllHiringPipelines = (props: Omit<HiringPipelinesCommonTableProps, 'type'>) => {
  return <HiringPipelinesCommonTable type="all" {...props} />
}

export default AllHiringPipelines
