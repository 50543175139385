import { VStack } from '@revolut/ui-kit'
import React from 'react'
import SectionTitle from '../components/SectionTitle'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { GroupWithRequired } from './common'

export const PerformanceReviewers = () => {
  return (
    <VStack>
      <SectionTitle
        title="Who should be invited to provide review feedback?"
        subtitle="We recommend 360 reviews, however you can disable some review types"
      />
      <GroupWithRequired
        title="Manager reviews"
        description="Review feedback from managers are the foundation of the performance result"
      >
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Self reviews',
            description:
              'Allows the employee to submit a review of their own performance and behaviours',
          }}
          name="enable_self_reviews"
        />
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Peer reviews',
            description: 'Allow peers to review each other',
          }}
          name="enable_peer_reviews"
        />
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Upwards reviews',
            description:
              'Direct and functional reports are allowed to submit anonymous reviews of their managers',
          }}
          name="enable_upwards_reviews"
        />
      </GroupWithRequired>
    </VStack>
  )
}
