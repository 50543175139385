import React, { useState } from 'react'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import ScorecardRecommendation from '@components/ScorecardRecommendation/ScorecardRecommendation'
import * as yup from 'yup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Box } from '@revolut/ui-kit'
import PerformanceEditSummarySidebar from '@src/pages/Forms/EmployeePerformance/PerformanceEditSummarySidebar'
import { AnytimeFeedbackSidebar } from '@components/AnytimeFeedback/AnytimeFeedbackSidebar'
import { useGetFeedbackSidebarData } from '@src/utils/performance'
import { EmployeePerformanceActionsBar } from './EmployeePerformanceActionsBar'
import { useSummarySidebarContext } from '@src/features/EmployeePerformance/SummarySidebarProvider'

export const validator = {
  review_data: yup.object().shape({
    peer_extra_section: yup.object().shape({
      employee_project_performance: yup.object().shape({
        value: yup.string().required(),
      }),
      keeper_test_section: yup.object().shape({
        questions: yup.array().of(
          yup.object().shape({
            value: yup.string().required('Justification is mandatory'),
          }),
        ),
      }),
    }),
  }),
}

const PeerRecommendation = () => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const [isOpenSidebar, setIsOpenSidebar] = useState(true)

  const [isFeedbackSidebarOpen, setIsFeedbackSidebarOpen] = useState(false)
  const { feedbackList, refetchFeedbackList, employeeId } = useGetFeedbackSidebarData()
  const reviewsSidebarContext = useSummarySidebarContext()

  if (!values.review_data.peer_extra_section) {
    return null
  }

  const getOpenSidebarHandler = (cb: () => void) => () => {
    setIsFeedbackSidebarOpen(false)
    setIsOpenSidebar(false)
    reviewsSidebarContext && reviewsSidebarContext.hideSummarySidebar()

    cb()
  }

  return (
    <>
      <Box width="100%">
        <EmployeePerformanceActionsBar
          onFeedbackRequested={{
            handler: getOpenSidebarHandler(() => setIsFeedbackSidebarOpen(true)),
            feedbackCount: feedbackList?.length,
          }}
          onSummaryRequested={getOpenSidebarHandler(() => setIsOpenSidebar(true))}
          onReviewsRequested={
            reviewsSidebarContext
              ? getOpenSidebarHandler(reviewsSidebarContext.showSummarySidebar)
              : undefined
          }
        />
        <ScorecardRecommendation
          data={values.review_data.peer_extra_section}
          type="peer"
        />
      </Box>
      <PerformanceEditSummarySidebar
        isOpen={isOpenSidebar}
        onClose={() => {
          setIsOpenSidebar(false)
        }}
      />
      {feedbackList && (
        <AnytimeFeedbackSidebar
          isOpen={isFeedbackSidebarOpen}
          handleClose={() => setIsFeedbackSidebarOpen(false)}
          feedbackList={feedbackList}
          refetchFeedbackList={refetchFeedbackList}
          employeeId={employeeId}
        />
      )}
    </>
  )
}

export default PeerRecommendation
