import { API } from '@src/constants/api'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { AxiosPromise } from 'axios'
import { api, apiWithoutHandling } from '.'
import { EmployeeContractsInterface } from '@src/interfaces/employees'
import { ContractsStatsInterface, ContractTypeInterface } from '@src/interfaces/contracts'

export const getContracts = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<EmployeeContractsInterface>
> =>
  api.get(API.CONTRACTS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getContractsStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<ContractsStatsInterface> =>
  api.get(`${API.CONTRACTS}/stats`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getContractTypes = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ContractTypeInterface>> =>
  api.get(API.CONTRACT_TYPES, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const contractTypesRequests: RequestInterfaceNew<ContractTypeInterface> = {
  get: async ({ id }) => api.get(`${API.CONTRACT_TYPES}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.CONTRACT_TYPES}/${id}`, data),
  submit: async data => apiWithoutHandling.post(`${API.CONTRACT_TYPES}`, data),
  delete: async ({ id }) => api.delete(`${API.CONTRACT_TYPES}/${id}`),
}
