import { RevolutTheme } from '@src/styles/theme'
import { FaqStatus, FaqTopicStatus } from '@src/interfaces/faq'

export const faqStatusToColor = (theme: RevolutTheme, status?: FaqStatus) => {
  switch (status) {
    case 'active':
      return theme.colors.green
    case 'archived':
      return theme.colors['grey-tone-50']
    default:
      return theme.colors.foreground
  }
}

export const faqTopicStatusToColor = (theme: RevolutTheme, status?: FaqTopicStatus) => {
  switch (status) {
    case 'active':
      return theme.colors.green
    case 'archived':
      return theme.colors['grey-tone-50']
    default:
      return theme.colors.foreground
  }
}
