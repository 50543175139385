import React, { useEffect, useState } from 'react'
import { Button, Cell, Color, Flex, MoreBar, Text, VStack } from '@revolut/ui-kit'
import { ArrowSend, Cross, Download, ShareIOs } from '@revolut/icons'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  cancelImportBonuses,
  confirmImportBonuses,
  exportBonusesSessionData,
  getBonusesSessionData,
  useImportBonusesSessionStats,
} from '@src/api/importData'
import { useTable } from '@components/Table/hooks'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@components/Table/AdjustableTable'
import Stat from '@components/Stat/Stat'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import {
  ImportBonusesInterface,
  ImportBonusesMetadata,
} from '@src/interfaces/importBonuses'
import SettingsButtons from '@src/features/SettingsButtons'
import { RowInterface } from '@src/interfaces/data'
import { saveFileByBlob } from '@src/utils'
import { PermissionTypes } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'
import SuccessWidget from '@src/components/SuccessWidget/SuccessWidget'
import {
  importBonusesActionColumn,
  importBonusesAmountColumn,
  importBonusesCategoryColumn,
  importBonusesCurrencyColumn,
  importBonusesDateColumn,
  importBonusesDetailsColumn,
  importBonusesEmployeeColumn,
  importBonusesPeriodColumn,
  importBonusesTaxColumn,
  importBonusesTypeColumn,
} from '@src/constants/columns/importBonuses'
import { ImportSessionStatus } from '@src/interfaces/importData'
import { TableNames } from '@src/constants/table'

const row: RowInterface<ImportBonusesInterface> = {
  highlight: (data, theme) => {
    return Object.keys(data.errors || {}).length
      ? theme.colors['red-action-background']
      : ''
  },
  cells: [
    {
      ...importBonusesEmployeeColumn,
      width: 100,
    },
    {
      ...importBonusesCategoryColumn,
      width: 100,
    },
    {
      ...importBonusesPeriodColumn,
      width: 100,
    },
    {
      ...importBonusesAmountColumn,
      width: 80,
    },
    {
      ...importBonusesCurrencyColumn,
      width: 80,
    },
    {
      ...importBonusesTaxColumn,
      width: 100,
    },
    {
      ...importBonusesTypeColumn,
      width: 100,
    },
    {
      ...importBonusesDateColumn,
      width: 100,
    },
    {
      ...importBonusesDetailsColumn,
      width: 160,
    },
    {
      ...importBonusesActionColumn,
      width: 100,
    },
  ],
}

export const ReviewData = () => {
  const permissions = useSelector(selectPermissions)
  const params = useParams<{ id: string }>()

  const [importPending, setImportPending] = useState(false)
  const [exportSessionDataPending, setExportSessionDataPending] = useState(false)
  const [cancelSessionPending, setCancelSessionPending] = useState(false)
  const [status, setStatus] = useState<ImportSessionStatus>()

  const table = useTable<ImportBonusesInterface, undefined, ImportBonusesMetadata>({
    getItems: getBonusesSessionData(params.id),
  })

  const stats = useImportBonusesSessionStats(params.id)

  useEffect(() => {
    if (table.metadata?.status.id) {
      setStatus(table.metadata.status.id)
    }
  }, [table.metadata?.status.id])

  const isValid = status === 'valid'
  const isInProgress = status === 'in_progress'
  const isCompleted = status === 'completed'
  const isFailed = status === 'failed'
  const showStatus = isInProgress || isCompleted || isFailed
  const canImport = permissions?.includes(
    PermissionTypes.AddEmployeeBonusBulkUploadSession,
  )
  const backUrl = ROUTES.PEOPLE.COMPENSATION.BONUSES

  const onExecuteImport = async () => {
    try {
      setImportPending(true)
      const response = await confirmImportBonuses(params.id)
      setStatus(response.data.status.id)
      table.refresh()
    } finally {
      setImportPending(false)
    }
  }

  const onExportSessionData = () => {
    setExportSessionDataPending(true)
    exportBonusesSessionData(params.id)
      .then(response => {
        saveFileByBlob(
          response.data,
          'session_data.csv',
          response.headers['content-type'],
        )
      })
      .finally(() => setExportSessionDataPending(false))
  }

  const onCancelSession = () => {
    setCancelSessionPending(true)
    cancelImportBonuses(params.id)
      .then(() => {
        navigateReplace(backUrl)
      })
      .catch(() => {
        setCancelSessionPending(false)
      })
  }

  return (
    <PageWrapper>
      <PageHeader title="Import data" subtitle="Bonuses" backUrl={backUrl} />
      {showStatus ? (
        <>
          {isInProgress && (
            <SuccessWidget
              title="Task in progress"
              type="pending"
              text="Please wait for the data to upload, this can take several minutes. You can
 refresh this page to update the task status."
              maxWidth={720}
            />
          )}
          {isCompleted && (
            <SuccessWidget
              title="Upload successful"
              type="success"
              text={
                <VStack gap="s-16">
                  <Text>Bonuses upload is completed</Text>
                  <Button
                    onClick={() => navigateTo(backUrl)}
                    useIcon={ArrowSend}
                    variant="secondary"
                    size="sm"
                  >
                    Go back to bonuses page
                  </Button>
                </VStack>
              }
              maxWidth={720}
            />
          )}
          {isFailed && (
            <SuccessWidget
              title="Task failed"
              type="error"
              text="There was an error with the upload"
              maxWidth={720}
            />
          )}
        </>
      ) : (
        <Cell>
          <Flex flexDirection="column" width="100%">
            <Flex mb="s-24" gap="s-32">
              <Stat label="Bonuses to import" val={stats?.data?.total_count} />
              <Stat label="Errors" val={stats.data?.invalid_count} color={Color.RED} />
            </Flex>

            <SettingsButtons mb="s-16">
              <MoreBar.Action
                onClick={onExecuteImport}
                pending={importPending}
                disabled={!isValid || !canImport}
                useIcon={ShareIOs}
                variant="accent"
              >
                Execute import
              </MoreBar.Action>
              <MoreBar.Action
                onClick={onExportSessionData}
                pending={exportSessionDataPending}
                useIcon={Download}
              >
                Download this data
              </MoreBar.Action>
              <MoreBar.Action
                onClick={onCancelSession}
                pending={cancelSessionPending}
                useIcon={Cross}
                variant="negative"
              >
                Cancel
              </MoreBar.Action>
            </SettingsButtons>

            <Flex style={{ position: 'relative' }} flex="1 0">
              <AdjustableTable
                name={TableNames.ImportBonuses}
                useWindowScroll
                dataType="Bonus"
                row={row}
                {...table}
              />
            </Flex>
          </Flex>
        </Cell>
      )}
    </PageWrapper>
  )
}
