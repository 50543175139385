import React, { useMemo } from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import {
  InterviewFeedbackStatus,
  InterviewResult,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface SaveToDraftButtonProps {
  afterSubmitUrl: string
}

const SubmitButton = ({ afterSubmitUrl }: SaveToDraftButtonProps) => {
  const { values, submit } = useLapeContext<InterviewFeedbackInterface>()

  const onClick = () => {
    const currentStatus = values.status
    values.status = InterviewFeedbackStatus.completed

    return submit().catch(error => {
      values.status = currentStatus
      throw error
    })
  }

  const archive = useMemo(
    () =>
      values.interview_stage.interview_type === 'cv_screening' &&
      (values.result === InterviewResult.NoHire ||
        values.result === InterviewResult.StrongNoHire),
    [values.interview_stage.interview_type, values.result],
  )

  return (
    <NewSaveButtonWithPopup
      successText="Interview scorecard saved successfully"
      onClick={onClick}
      afterSubmitUrl={
        archive
          ? pathToUrl(ROUTES.FORMS.ARCHIVE_OPPORTUNITY.GENERAL, {
              id: values.interview_stage.interview_round.candidate.id,
            })
          : afterSubmitUrl
      }
      hideWhenNoChanges={false}
      useValidator
    >
      {values.status === InterviewFeedbackStatus.pending ||
      values.status === InterviewFeedbackStatus.draft
        ? 'Submit'
        : 'Save Changes'}
    </NewSaveButtonWithPopup>
  )
}

export default connect(SubmitButton)
