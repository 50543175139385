import { useLapeContext } from '@src/features/Form/LapeForm'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { GoalsInterface } from '@src/interfaces/goals'
import { AddDashboardForm } from '@src/pages/Forms/GoalForm/Form/SidebarForms/AddDashboardForm'
import { BaseWidget } from '@src/pages/Forms/GoalForm/Form/Widgets/BaseWidget'
import { DashboardsList } from './DashboardsList'
import { useSidebar } from '@src/pages/Forms/GoalForm/common/SidebarProvider'
import React, { useEffect } from 'react'

export const DashboardsWidget = ({
  forceDashboard,
}: {
  forceDashboard?: AnalyticsDashboardInterface
}) => {
  const { values } = useLapeContext<GoalsInterface>()
  const sidebar = useSidebar()

  const showSidebar = (dashboard?: AnalyticsDashboardInterface) => {
    const index = dashboard && values.dashboards?.findIndex(d => d === dashboard)

    sidebar.show({
      children: (
        <AddDashboardForm
          initialDashboard={dashboard}
          onSave={selected => {
            if (typeof index === 'number' && index >= 0) {
              values.dashboards = [
                ...values.dashboards.slice(0, index),
                selected,
                ...values.dashboards.slice(index + 1),
              ]
            } else {
              values.dashboards = [...(values.dashboards || []), selected]
            }

            sidebar.hide()
          }}
        />
      ),
      title: 'Select dashboard',
      isOpen: true,
    })
  }

  useEffect(() => {
    if (forceDashboard) {
      showSidebar(forceDashboard)
    }
  }, [])

  return (
    <BaseWidget
      title="Link supporting analytics"
      desciption="(Optional) Are there performance analytics which help to track this goal?"
      icon="ViewGrid"
      emptyState={{
        description: 'No dashboards or reports added',
        ctaLabel: 'Add link',
        ctaHandler: showSidebar,
        icon: 'ViewGrid',
      }}
    >
      {values.dashboards?.length ? (
        <DashboardsList onAddDashboard={showSidebar} onViewRequested={showSidebar} />
      ) : null}
    </BaseWidget>
  )
}
