import React, { useState } from 'react'
import { CalendarDate } from '@revolut/icons'
import { Box, Button } from '@revolut/ui-kit'
import LapeNewDatePicker from '@src/components/Inputs/LapeFields/LapeNewDatePicker'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { pushError } from '@src/store/notifications/actions'
import { SubmitPopup } from '../Components/SubmitPopup'

type Props = {
  onSubmit: (date: string) => Promise<void>
}
export const SetDateButton = ({ onSubmit }: Props) => {
  const [isShown, setIsShown] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const { values } = useLapeContext<{ resignation_date: string }>()

  return (
    <>
      <Button
        size="sm"
        variant="secondary"
        useIcon={CalendarDate}
        onClick={() => setIsShown(true)}
      >
        Complete termination form
      </Button>
      <SubmitPopup
        open={isShown}
        pending={isPending}
        title="Please enter the resignation effective date"
        subtitle='Once that’s done, resignation status will be changed to "Completed"'
        onClose={() => setIsShown(false)}
        content={
          <Box mt="s-16">
            <LapeNewDatePicker required label="Choose date" name="resignation_date" />
          </Box>
        }
        onSubmit={async () => {
          setIsPending(true)
          try {
            await onSubmit(values.resignation_date)
          } catch (error) {
            pushError({ error })
            return Promise.reject(error)
          } finally {
            setIsPending(false)
            setIsShown(false)
          }
          return Promise.resolve()
        }}
        successPopupText="Employee’s resignation was successfully completed"
      />
    </>
  )
}
