import styled, { css } from 'styled-components'
import { colorGetter } from '@src/styles/colors'
import { Color, Token } from '@revolut/ui-kit'

export const StatisticsTitle = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: ${colorGetter(Color.GREY_TONE_50)};
`
export const StatisticsValueCss = css`
  font-size: 20px;
  line-height: 20px;
`

export const StatisticsValue = styled.div`
  ${StatisticsValueCss};
`

export const StatValue = styled(StatisticsValue)`
  color: ${props => props.color};
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
`

const CircleCSS = css`
  text-decoration: none;
  color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
  background: ${Token.color.widgetBackground};
  cursor: pointer;
  padding: 8px;
  box-shadow: 0 4px 4px rgba(172, 172, 172, 0.25);
  border-radius: 50%;
  transition: all 150ms;
  &:hover {
    color: ${colorGetter(Color.GREY_TONE_50)};
  }
`

export const IconButtonWithBorders = styled.button.attrs({ type: 'button' })`
  ${CircleCSS}
`

export const PercentageWrapper = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
  min-height: 28px;
`
