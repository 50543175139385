import React from 'react'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import upperFirst from 'lodash/upperFirst'
import { css } from 'styled-components'
import { colorGetter, getColor } from '@src/styles/colors'
import { ImportKPIsData } from '@src/interfaces/importKPIs'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import WithError from '@components/ColumnInserts/WithError/WithError'
import { Token } from '@revolut/ui-kit'

export enum SpecialColumns {
  action = 'action',
  sql_query = 'sql_query',
  errors = 'errors',
}

const ErrorWrapperCSS = css`
  // sorry for important there, otherwise cell color is overwritten when you hover over the row
  background: ${colorGetter('red-opaque-10')} !important;
`

const checkForRowErrors = (errors: ImportKPIsData['errors']) => errors && !isEmpty(errors)

export const uploadKpiRowActionColumn: ColumnCellInterface<ImportKPIsData> = {
  type: CellTypes.insert,
  idPoint: SpecialColumns.action,
  dataPoint: SpecialColumns.action,
  title: 'Action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  colors: (data, theme) => {
    if (checkForRowErrors(data.errors)) {
      return getColor(theme, 'error')
    }
    return Token.color.foreground
  },
  insert: ({ data }) => {
    return checkForRowErrors(data.errors)
      ? 'Error'
      : upperFirst(data[SpecialColumns.action] || '-')
  },
  width: 120,
}

// Field names are not known at compile time, they are determined from the api response
export const getKPIUploadColumnConfig = (
  fieldName: string,
): ColumnCellInterface<ImportKPIsData> => ({
  type: CellTypes.insert,
  idPoint: fieldName,
  dataPoint: fieldName,
  title: startCase(fieldName),
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  notHoverable: true,
  wrapperCss: data => {
    if (data.errors?.[fieldName]) {
      return ErrorWrapperCSS
    }
    return css``
  },
  insert: ({ data }) => {
    const error = data.errors?.[fieldName]
    const fieldData = data[fieldName]

    if (error) {
      return <WithError error={error}>{fieldData}</WithError>
    }
    return fieldData
  },
  width: 120,
})
