import {
  HStack,
  Icon,
  TabBar,
  Text,
  TextButton,
  Token,
  VStack,
  chain,
  createChain,
} from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import React, { useState } from 'react'
import { ParentBadge } from './ParentBadge'

const SectionTitile = ({ title }: { title: string }) => {
  return (
    <Text use="h6" color={Token.color.greyTone50}>
      {title}
    </Text>
  )
}

export const GoalsPreviewRightSide = ({ cycle }: { cycle: ReviewCyclesInterface }) => {
  const { values } = useLapeContext<GoalsInterface>()
  const [tab, setTab] = useState<'details' | 'log' | undefined>('details')
  const parent = values.parent
  const logs = []

  const newLineChain = createChain(<br />)

  return (
    <VStack space="s-24">
      <TabBar variant="segmented" value={tab} onChange={setTab}>
        <TabBar.Item to="details">Details</TabBar.Item>
        <TabBar.Item disabled to="log">
          {chain('Update log', logs.length)}
        </TabBar.Item>
      </TabBar>
      {parent ? (
        <VStack space="s-12">
          <SectionTitile title="Parent goal" />
          <ParentBadge id={parent.id} name={parent.name} cycle={cycle} />
        </VStack>
      ) : null}
      <VStack space="s-12">
        <SectionTitile title="Description" />
        <Text>{newLineChain(values.description.split('\n'))}</Text>
      </VStack>
      {values.dashboards && values.dashboards.length ? (
        <VStack space="s-12">
          <SectionTitile title="Related performance analytics" />
          {values.dashboards.map(dashboard => (
            <TextButton
              key={dashboard.id}
              use="a"
              href={dashboard.content_url}
              rel="noreferrer noopener"
              target="_blank"
            >
              <HStack align="center" space="s-8">
                <Text>{dashboard.name}</Text>
                <Icon name="16/LinkExternal" size={16} />
              </HStack>
            </TextButton>
          ))}
        </VStack>
      ) : null}
    </VStack>
  )
}
