import React from 'react'
import { Avatar, chain, Color, ErrorWidget, Item, Text } from '@revolut/ui-kit'
import { Profile } from '@revolut/icons'
import { useParams } from 'react-router-dom'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  DependantInterface,
  EmployeeBenefitInterface,
  ExistingDependantInterface,
} from '@src/interfaces/benefits'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getBenefitStatusColor } from '@src/constants/columns/rewards'
import { useTheme } from '@src/styles/theme'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { formatToOrdinal } from '@src/utils/format'
import { PageBody } from '@src/components/Page/PageBody'

export const useEmployeeBenefitsParams = () =>
  useParams<{
    tab: string
    employeeId: string
    id: string
    packageId?: string
  }>()

export const getBackUrl = (id: number | string) =>
  pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.BENEFITS, { id })

interface MainScreensWrapperProps {
  tabbar?: React.ReactNode
}

export const MainScreensWrapper: React.FC<MainScreensWrapperProps> = ({
  tabbar,
  children,
}) => {
  const { initialValues } = useLapeContext<EmployeeBenefitInterface>()
  const { employeeId } = useEmployeeBenefitsParams()
  const theme = useTheme()

  const backUrl = getBackUrl(employeeId)

  return (
    <PageWrapper>
      <PageHeader
        title={chain(
          initialValues.template?.category.name,
          initialValues.template?.name,
          <Text color={getBenefitStatusColor(initialValues.status!.id, theme)}>
            {initialValues.status?.name}
          </Text>,
        )}
        subtitle={<EmployeeUserWithAvatar id={employeeId} />}
        backUrl={backUrl}
      >
        {tabbar}
      </PageHeader>
      {children}
    </PageWrapper>
  )
}

export const CutOffWarning = () => {
  const {
    initialValues: { template },
  } = useLapeContext<EmployeeBenefitInterface>()

  const cutOffWarning = (() => {
    if (
      template?.cut_off_period?.id === 'monthly' &&
      template?.cut_off_period_day_of_month
    ) {
      return `Cut-off date for this benefit is ${formatToOrdinal(
        template.cut_off_period_day_of_month,
      )} day of each month`
    }

    return null
  })()

  return (
    <ActionWidget
      title={cutOffWarning}
      text="Any changes (enrolment, opting out or adding dependants) made before this date will be processed in the same month. Any changes made after this date will processed in the following month."
      mt="s-16"
      hide={cutOffWarning == null}
    />
  )
}

interface DependantItemProps {
  dependant: DependantInterface | ExistingDependantInterface
  onClick: () => void
}

export const DependantItem = ({ onClick, dependant }: DependantItemProps) => {
  return (
    <Item onClick={onClick} use="button" type="button">
      <Item.Avatar>
        <Avatar useIcon={Profile} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          {dependant.form_data.first_name} {dependant.form_data.last_name}
        </Item.Title>
      </Item.Content>
    </Item>
  )
}

export const NoDependantsItem = () => {
  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon={Profile} color={Color.GREY_TONE_20} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title color={Color.GREY_TONE_50}>No dependants added</Item.Title>
      </Item.Content>
    </Item>
  )
}

interface DependantNotFoundPageProps {
  backUrl: string
}

export const DependantNotFoundPage = ({ backUrl }: DependantNotFoundPageProps) => {
  return (
    <PageWrapper>
      <PageHeader pb="s-8" title="Dependant's details" backUrl={backUrl} />
      <PageBody>
        <ErrorWidget>
          <ErrorWidget.Image src="https://assets.revolut.com/assets/3d-images/3D087@2x.png" />
          <ErrorWidget.Title>Dependant not found</ErrorWidget.Title>
        </ErrorWidget>
      </PageBody>
    </PageWrapper>
  )
}
