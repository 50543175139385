import { useCareersApplicationSettings } from '@src/api/recruitment/careers'
import CareersApplicationGeneral from '@src/pages/Careers/CareersApplication/CareersApplicationGeneral'
import CareersApplicationResume from '@src/pages/Careers/CareersApplication/CareersApplicationResume'
import CareersApplicationDiversity from '@src/pages/Careers/CareersApplication/CareersApplicationDiversity'
import React, { useMemo } from 'react'
import { CareersPosition, PredefinedSectionKeys } from '@src/interfaces/careers'
import { OptionInterface } from '@src/interfaces/selectors'
import CareersApplicationEducation from '@src/pages/Careers/CareersApplication/CareersApplicationEducation'
import CareersApplicationWorkExperience from '@src/pages/Careers/CareersApplication/CareersApplicationWorkExperience'

export type PredefinedSectionsProps = {
  position?: CareersPosition
  countries?: OptionInterface[]
  locked?: boolean
}

export const useGetPredefinedSectionsConfig = () => {
  const { data: applicationSettings } = useCareersApplicationSettings()

  return useMemo(() => {
    const config = new Map<
      PredefinedSectionKeys,
      { visible: boolean; progressIncluded: boolean; optional: boolean }
    >()

    config.set(PredefinedSectionKeys.resume, {
      visible: !!applicationSettings?.enable_resume_field,
      optional: !!applicationSettings?.is_resume_field_optional,
      progressIncluded:
        !!applicationSettings?.enable_resume_field &&
        !applicationSettings?.is_resume_field_optional,
    })
    config.set(PredefinedSectionKeys.links, {
      visible: !!applicationSettings?.enable_portfolio_link_field,
      optional: !!applicationSettings?.is_portfolio_link_optional,
      progressIncluded:
        !!applicationSettings?.enable_portfolio_link_field &&
        !applicationSettings?.is_portfolio_link_optional,
    })
    config.set(PredefinedSectionKeys.general, {
      visible: true,
      optional: false,
      progressIncluded: true,
    })
    config.set(PredefinedSectionKeys.work_experience, {
      visible: !!applicationSettings?.enable_work_experience_field,
      progressIncluded: !!applicationSettings?.enable_work_experience_field,
      optional: false,
    })
    config.set(PredefinedSectionKeys.education, {
      visible: !!applicationSettings?.enable_education_field,
      progressIncluded: !!applicationSettings?.enable_education_field,
      optional: false,
    })
    config.set(PredefinedSectionKeys.diversity, {
      visible: !!applicationSettings?.enable_diversity_field,
      progressIncluded: false,
      optional: true,
    })

    return config
  }, [applicationSettings])
}

export const useGetCareersPredefinedSections = ({
  locked,
  countries,
  position,
}: PredefinedSectionsProps) => {
  const config = useGetPredefinedSectionsConfig()
  const nodes: { key: PredefinedSectionKeys; node: React.ReactNode }[] = []

  if (
    config.get(PredefinedSectionKeys.resume)?.visible ||
    config.get(PredefinedSectionKeys.links)?.visible
  ) {
    nodes.push({
      key: PredefinedSectionKeys.resume,
      node: (
        <CareersApplicationResume key={PredefinedSectionKeys.resume} locked={locked} />
      ),
    })
  }

  if (config.get(PredefinedSectionKeys.general)?.visible) {
    nodes.push({
      key: PredefinedSectionKeys.general,
      node: (
        <CareersApplicationGeneral
          key={PredefinedSectionKeys.general}
          locked={locked}
          countries={countries}
          position={position}
        />
      ),
    })
  }

  if (config.get(PredefinedSectionKeys.work_experience)?.visible) {
    nodes.push({
      key: PredefinedSectionKeys.work_experience,
      node: (
        <CareersApplicationWorkExperience key={PredefinedSectionKeys.work_experience} />
      ),
    })
  }

  if (config.get(PredefinedSectionKeys.education)?.visible) {
    nodes.push({
      key: PredefinedSectionKeys.education,
      node: <CareersApplicationEducation key={PredefinedSectionKeys.education} />,
    })
  }

  if (config.get(PredefinedSectionKeys.diversity)?.visible) {
    nodes.push({
      key: PredefinedSectionKeys.diversity,
      node: (
        <CareersApplicationDiversity
          key={PredefinedSectionKeys.diversity}
          locked={locked}
        />
      ),
    })
  }

  return nodes
}
