import { Avatar, Cell, IconName, Item, VStack } from '@revolut/ui-kit'
import React, { PropsWithChildren } from 'react'

export const CellWithItem = ({
  icon,
  title,
  description,
  children,
}: PropsWithChildren<{
  icon: IconName
  title: string
  description?: string
}>) => (
  <Cell width="100%">
    <VStack space="s-16" width="100%">
      <Item p={0}>
        <Item.Avatar>
          <Avatar useIcon={icon} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{title}</Item.Title>
          {description ? <Item.Description>{description}</Item.Description> : null}
        </Item.Content>
      </Item>
      {children}
    </VStack>
  </Cell>
)
