import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import PublicPageLayout from '@components/CommonSC/PublicPageLayout'
import CareersJobList from '@src/pages/Careers/CareersJobList'
import Header from '@src/pages/Careers/Header'
import Footer from '@src/pages/Careers/Footer'
import { Flex } from '@revolut/ui-kit'
import CareersApplication from '@src/pages/Careers/CareersApplication'
import CareersPosition from '@src/pages/Careers/CareersPosition/CareersPosition'
import { useCareersSettings } from '@src/api/recruitment/careers'
import PageLoading from '@components/PageLoading/PageLoading'
import { connect } from 'lape'
import { useCareersAnalytics } from '@src/pages/Careers/hooks/useCareersAnalytics'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const Router = () => {
  useCareersAnalytics()
  const { data: settings, isLoading } = useCareersSettings()

  if (isLoading) {
    return <PageLoading />
  }

  if (settings && !settings.publish_career_website) {
    return <InternalRedirect to={PUBLIC_ROUTES.PAGE_404} />
  }

  return (
    <Flex flexDirection="column" minHeight="100%">
      <Header />
      <PublicPageLayout
        noPadding
        maxWidth="1000px"
        m="auto"
        px="s-8"
        style={{ flexGrow: 1 }}
      >
        <Switch>
          <Route exact path={PUBLIC_ROUTES.CAREERS.JOB_LIST} component={CareersJobList} />
          <Route
            exact
            path={PUBLIC_ROUTES.CAREERS.APPLICATION}
            component={CareersApplication}
          />
          <Route
            exact
            path={PUBLIC_ROUTES.CAREERS.POSITION}
            component={CareersPosition}
          />
        </Switch>
      </PublicPageLayout>
      <Footer />
    </Flex>
  )
}

export default connect(Router)
