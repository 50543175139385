import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Box, Color, Flex, Link, Text } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { useGetPerfReviewFeedbackRequests } from '@src/api/performance'
import { TabsInterface } from '@src/interfaces/data'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ExtraPermissionsInterface, ReviewCategory } from '@src/interfaces/performance'
import { ProbationCheckpoints } from '@src/interfaces/probationReview'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

interface GenericOverviewTabsInterface
  extends Omit<TabsInterface<any>, 'visibilityCheck'> {
  checkpointNumber?: number
}

interface GenericOverviewProps {
  category: ReviewCategory
  checkpoints?: ProbationCheckpoints
  cycleId: string
  data: EmployeeInterface
  extraPermissions?: ExtraPermissionsInterface[]
  refetch?: () => Promise<any>
  tabs: GenericOverviewTabsInterface[]
}

const PageTitle = {
  [ReviewCategory.Probation]: 'Probation Review of',
  [ReviewCategory.PIP_V2]: 'PIP Review of',
}

export const GenericOverview = ({
  category,
  checkpoints,
  cycleId,
  data,
  extraPermissions,
  refetch,
  tabs,
}: GenericOverviewProps) => {
  const { data: requestsResponse } = useGetPerfReviewFeedbackRequests(
    data.id,
    cycleId,
    filterSortPageIntoQuery(undefined, [
      {
        filters: [
          {
            name: 'category',
            id: category,
          },
        ],
        columnName: 'category',
      },
    ]),
  )

  const requests = requestsResponse?.results || []
  const cycle = data.performance_cycles?.find(el => el.id === cycleId)

  return (
    <PageWrapper>
      <PageHeader
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        title={`${PageTitle[category]} ${data.display_name}`}
        subtitle={
          <>
            <Box>
              <Link
                color={Color.FOREGROUND}
                to={pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, {
                  id: data.position.id,
                })}
                use={InternalLink}
                target="_blank"
              >
                {data.position.name}
              </Link>
              <Text color={Color.GREY_TONE_50}> at </Text>
              <Link
                color={Color.FOREGROUND}
                to={pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, {
                  id: data.team.id,
                })}
                use={InternalLink}
                target="_blank"
              >
                {data.team.name}
              </Link>
            </Box>
            {cycle && (
              <Box>
                <Text color={Color.GREY_TONE_50}>
                  From {formatDate(cycle?.start_date_time)} -{' '}
                  {formatDate(cycle?.end_date_time)}
                </Text>
              </Box>
            )}
          </>
        }
        backUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
          id: data.id,
        })}
      >
        <Flex alignItems="flex-start" my="s-24">
          <TabBarNavigation tabs={tabs} />
        </Flex>
      </PageHeader>

      <Switch>
        {tabs.map(tab => (
          <Route exact key={tab.path} path={tab.path}>
            <tab.component
              category={category}
              checkpoints={checkpoints}
              checkpointNumber={tab?.checkpointNumber}
              cycleId={cycleId}
              data={data}
              employeeId={data.id}
              extraPermissions={extraPermissions}
              refetch={refetch}
              requests={requests}
            />
          </Route>
        ))}
        <InternalRedirect to={tabs[0].path} />
      </Switch>
    </PageWrapper>
  )
}
