import { ROUTES } from '@src/constants/routes'
import TeamsTable from '@src/pages/Organisation/OrganisationSubTabs/Teams/TeamsTable'
import DepartmentsTable from '@src/pages/Organisation/OrganisationSubTabs/Departments/DepartmentsTable'
import { PermissionTypes } from '@src/store/auth/types'
import FunctionsTable from '@src/pages/Organisation/OrganisationSubTabs/Functions/FunctionsTable'
import RolesTable from '@src/pages/Organisation/OrganisationSubTabs/Roles/RolesTable'
import SpecialisationsTable from '@src/pages/Organisation/OrganisationSubTabs/Specialisations/SpecialisationsTable'
import { HomeSectionId, TabBarWithPinningTabInterface } from '@src/interfaces/tabPinning'

export const organisationSubtabs: TabBarWithPinningTabInterface[] = [
  {
    id: HomeSectionId.teams,
    title: 'Teams',
    path: ROUTES.ORGANISATION.TEAMS,
    url: ROUTES.ORGANISATION.TEAMS,
    permission: PermissionTypes.ViewTeams,
    component: TeamsTable,
  },
  {
    id: HomeSectionId.departments,
    title: 'Departments',
    path: ROUTES.ORGANISATION.DEPARTMENTS,
    url: ROUTES.ORGANISATION.DEPARTMENTS,
    permission: PermissionTypes.ViewDepartment,
    component: DepartmentsTable,
  },
  {
    id: HomeSectionId.specialisations,
    title: 'Specialisations',
    path: ROUTES.FUNCTION.SPECIALISATIONS,
    url: ROUTES.FUNCTION.SPECIALISATIONS,
    permission: PermissionTypes.ViewRoles,
    component: SpecialisationsTable,
  },
  {
    id: HomeSectionId.roles,
    title: 'Roles',
    path: ROUTES.FUNCTION.ROLES,
    url: ROUTES.FUNCTION.ROLES,
    permission: PermissionTypes.ViewRoles,
    component: RolesTable,
  },
  {
    id: HomeSectionId.functions,
    title: 'Functions',
    path: ROUTES.FUNCTION.FUNCTIONS,
    url: ROUTES.FUNCTION.FUNCTIONS,
    permission: PermissionTypes.ViewFunction,
    component: FunctionsTable,
  },
  {
    id: HomeSectionId.company,
    title: 'Company',
    url: ROUTES.FORMS.COMPANY.KPI.GOALS,
  },
]
