import React from 'react'
import { Item, VStack, Avatar, Text, Action, Flex } from '@revolut/ui-kit'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import { Archive, Profile } from '@revolut/icons'
import ExistingCandidatesMatching from '@components/ExistingCandidatesSidebar/ExistingCandidatesMatching'
import { pathToUrlWithBaseUrl, newTabToWithBaseUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Link } from 'react-router-dom'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'

const MAX_ITEMS_SHOWN = 5

export type ExistingCandidatesContentProps = {
  items?: CandidateInterface[]
  maxCount?: number
}

const ExistingCandidatesContent = ({
  items,
  maxCount = MAX_ITEMS_SHOWN,
}: ExistingCandidatesContentProps) => {
  const { sendAnalyticsEvent } = useAnalytics()

  if (!items?.length) {
    return null
  }

  const ids = items.map(item => item.id)

  return (
    <VStack gap="s-16">
      {items.slice(0, maxCount).map(item => {
        const archived = item.active_interview_round?.state === 'archived'

        return (
          <Item
            key={item.id}
            use="button"
            variant="disclosure"
            type="button"
            onClick={() => {
              sendAnalyticsEvent(AnalyticsEvents.existing_candidate_profile_visit, {
                candidateId: item.id,
              })
              newTabToWithBaseUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                id: item.id,
              })
            }}
          >
            <Item.Avatar>
              <Avatar
                color={archived ? 'foreground' : undefined}
                useIcon={archived ? Archive : Profile}
              />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{item.full_name}</Item.Title>
              <Item.Description>
                <Text>
                  Current stage:{' '}
                  {item.active_interview_round?.latest_interview_stage?.title || '-'}{' '}
                  <br />
                  Interviewing for:{' '}
                  {item.active_interview_round?.specialisation?.name || '-'}
                </Text>

                <ExistingCandidatesMatching fields={item.matching_fields} />
              </Item.Description>
            </Item.Content>
          </Item>
        )
      })}
      {ids.length > maxCount && (
        <Flex justifyContent="center">
          <Action
            use={Link}
            // @ts-expect-error object works fine here, but UI kit expects string
            to={{
              pathname: `${pathToUrlWithBaseUrl(
                ROUTES.RECRUITMENT.CANDIDATES,
              )}?id=${ids.join(',')}`,
            }}
            target="_blank"
          >
            Show all candidates ({ids.length})
          </Action>
        </Flex>
      )}
    </VStack>
  )
}

export default ExistingCandidatesContent
