import React, { useEffect, useMemo, useState } from 'react'
import { isArray, sumBy } from 'lodash'
import { Flex, TableWidget } from '@revolut/ui-kit'
import { getQueryRunResult, getQueryRunResultColumns } from '@src/api/dataAnalytics'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import {
  ReportInterface,
  RunReportResponseInterface,
} from '@src/interfaces/dataAnalytics'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { orderingToSort } from '@src/pages/Forms/DataAnalyticsReportForm/hooks/useTableSettings'

interface TableProps {
  entity: EntityTypes.department | EntityTypes.team
  entityData: TeamInterface | DepartmentInterface
  reportData: ReportInterface
  run: RunReportResponseInterface
  tableName: TableNames
}

export const TableReport = ({
  entity,
  entityData,
  reportData,
  run,
  tableName,
}: TableProps) => {
  const contextFilters = reportData.output_format.context_filters?.find(
    filter => filter.context_type?.id === entity,
  )
  const initialFilter = contextFilters?.column_name
    ? [
        {
          filters: [{ id: `${entityData.id}`, name: `${entityData.id}` }],
          columnName: contextFilters.column_name,
          nonResettable: true,
        },
      ]
    : []
  const initialSort = orderingToSort(reportData.output_format.ordering || [])
  const resultFileds = reportData.output_format.query_column_mappings
    .map(item => item.name)
    .join(',')

  const [tableSummary, setTableSummary] = useState<
    { value: string | null; count: number }[]
  >([])

  const table = useTable(
    {
      getItems: getQueryRunResult(run.query_run?.id, reportData.query.id, resultFileds),
    },
    initialFilter,
    initialSort,
  )

  const tableRows = useMemo(() => {
    const cells = reportData.output_format.query_column_mappings.map(
      (key, index) =>
        ({
          type: CellTypes.insert,
          idPoint: key.name,
          dataPoint: key.name,
          sortKey: key.name,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: key.alias || key.name,
          width: 300,
          insert: ({ data }) => {
            if (isArray(data)) {
              return typeof data[index] === 'boolean'
                ? data[index].toString()
                : data[index]
            }
            return typeof data[key.name] === 'boolean'
              ? data[key.name].toString()
              : data[key.name]
          },
        } as ColumnCellInterface<any>),
    )
    return { cells }
  }, [])

  const fetchSummary = async () => {
    const runResult = await getQueryRunResultColumns(
      run.query_run.id!,
      reportData.query.id,
      reportData.output_format.summary_column!,
      initialFilter,
    )
    if (runResult.data) {
      setTableSummary(runResult.data)
    }
  }

  useEffect(() => {
    if (run.query_run?.id && reportData.output_format.summary_column) {
      fetchSummary()
    } else {
      setTableSummary([])
    }
  }, [])

  return (
    <TableWidget>
      <TableWidget.Info>
        {reportData.output_format.summary_column && (
          <Flex gap="s-32">
            {tableSummary.map(item => (
              <Stat
                key={item.value}
                label={item.value === null ? '(blank)' : item.value}
                val={item.count}
              />
            ))}
            <Stat label="total" val={sumBy(tableSummary, 'count')} />
          </Flex>
        )}
      </TableWidget.Info>
      <TableWidget.Table>
        <AdjustableTable
          {...table}
          count={table.count}
          hideCount
          name={tableName}
          row={tableRows}
          useWindowScroll
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
