import React, { useState } from 'react'
import {
  BarSkeleton,
  Group,
  Header,
  ItemSkeleton,
  Layout,
  PlanWidget,
  Skeleton,
  Tag,
  VStack,
  Text,
  PlanWidgetSkeleton,
  Subheader,
  Item,
  TabBar,
  Footnote,
  Button,
  Token,
  Box,
  TextSkeleton,
  chain,
  Link,
} from '@revolut/ui-kit'

import { CalendarRecurring, Check, Lightning } from '@revolut/icons'
import pluralize from 'pluralize'
import { formatDate, formatMoney } from '@src/utils/format'
import { featureBreakdown, features } from './config'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { ROUTES, PUBLIC_ROUTES } from '@src/constants/routes'
import { useSubscriptionPlanContext } from './SubscriptionPlanProvider'
import {
  changeMerchantApiPaymentMethod,
  createMerchantApiOrder,
  getMerchantApiConfig,
  useAvailableSubscriptionPlans,
  useDiscounts,
  useSubsciptionInfo,
} from '@src/api/plans'
import { pathToUrl } from '@src/utils/router'
import { TermsAndConditionsDisclaimer } from '@src/pages/SignUp/common'

export const SelectPlan = () => {
  const { plans } = useAvailableSubscriptionPlans()
  const { selectedPlan, setSelectedPlan, setOrderAndConfig } =
    useSubscriptionPlanContext()
  const { info } = useSubsciptionInfo()
  const { discounts, isLoading: isDiscountsLoading } = useDiscounts()

  const [createOrderPending, setCreateOrderPending] = useState(false)

  const noPaymentMethod = info?.revolut_merchant_api_payment_method === null

  const onPlanSelect = () => {
    if (selectedPlan && !createOrderPending) {
      setCreateOrderPending(true)

      Promise.all([
        getMerchantApiConfig(),
        createMerchantApiOrder(selectedPlan.subscription_plan.id),
      ])
        .then(([configResponse, orderResponse]) => {
          setOrderAndConfig({
            order: orderResponse.data,
            config: configResponse.data,
            type: 'subscribe',
          })
          navigateTo(ROUTES.PLANS.CHECKOUT)
        })
        .catch(() => {
          setCreateOrderPending(false)
        })
    }
  }

  const onAddPaymentMethod = () => {
    if (selectedPlan && !createOrderPending) {
      setCreateOrderPending(true)

      Promise.all([getMerchantApiConfig(), changeMerchantApiPaymentMethod()])
        .then(([configResponse, orderResponse]) => {
          setOrderAndConfig({
            order: orderResponse.data,
            config: configResponse.data,
            type: 'change-payment-method',
          })
          navigateTo(ROUTES.PLANS.CHECKOUT)
        })
        .catch(() => {
          setCreateOrderPending(false)
        })
    }
  }

  const subscribedPlan = info?.subscription_plan?.id
  const isCurrentPlanSelected =
    selectedPlan && subscribedPlan === selectedPlan.subscription_plan.id
  const appliedDiscount = discounts?.find(
    d =>
      d.subscription_plan.id === selectedPlan?.subscription_plan.id &&
      d.status.id === 'active',
  )
  const planPrice = selectedPlan
    ? formatMoney(
        Number(selectedPlan.subscription_plan.monthly_fee),
        selectedPlan.subscription_plan.currency,
      )
    : null
  const discountPrice =
    selectedPlan && appliedDiscount
      ? formatMoney(
          Number(appliedDiscount.monthly_fee),
          selectedPlan.subscription_plan.currency,
        )
      : null
  const finalPrice = discountPrice || planPrice

  return (
    <>
      <Layout.Main>
        <Header variant="item">
          <Header.BackButton onClick={() => goBack(ROUTES.MAIN)} aria-label="Back" />
          <Header.Title>{subscribedPlan ? 'Plan' : 'Pricing plan'}</Header.Title>
          {subscribedPlan ? null : (
            <Header.Description>
              Select a plan that fits your organisation and provide billing information
              that will be used in case you will decide to stick with Revolut People after
              trial period.
            </Header.Description>
          )}
        </Header>

        {!!plans?.length && plans.length > 1 && selectedPlan?.subscription_plan.id ? (
          <TabBar
            value={`${selectedPlan.subscription_plan.id}`}
            onChange={tab =>
              setSelectedPlan(
                plans.find(plan => `${plan.subscription_plan.id}` === (tab as string)),
              )
            }
            mb="s-16"
          >
            {plans.map(plan => (
              <TabBar.Item
                to={`${plan.subscription_plan.id}`}
                key={plan.subscription_plan.id}
              >
                {plan.subscription_plan.name}
              </TabBar.Item>
            ))}
          </TabBar>
        ) : null}

        {selectedPlan ? (
          <>
            <PlanWidget variant="small">
              <PlanWidget.Action>
                {isCurrentPlanSelected ? (
                  <Tag
                    useIcon={Check}
                    variant="faded"
                    bg={Token.color.groupedBackground}
                    color={Token.color.foreground}
                  >
                    Active
                  </Tag>
                ) : selectedPlan.subscription_plan.tag ? (
                  <Tag useIcon={Lightning} color={Token.color.blue}>
                    {selectedPlan.subscription_plan.tag}
                  </Tag>
                ) : null}
              </PlanWidget.Action>
              <PlanWidget.Title>{selectedPlan.subscription_plan.name}</PlanWidget.Title>
              <PlanWidget.Description>
                <VStack space="s-4">
                  {isDiscountsLoading || !finalPrice ? (
                    <TextSkeleton />
                  ) : (
                    <Text color={Token.color.blue}>
                      {chain(
                        isCurrentPlanSelected
                          ? null
                          : `${pluralize(
                              'day',
                              selectedPlan.subscription_plan.free_days,
                              true,
                            )} free`,
                        <>
                          {finalPrice !== planPrice ? (
                            <Text color={Token.color.greyTone50} mr="s-4">
                              <s>{planPrice}</s>
                            </Text>
                          ) : null}
                          {finalPrice} per active employee/month
                        </>,
                      )}
                    </Text>
                  )}
                  <Text variant="caption" color={Token.color.greyTone50}>
                    {selectedPlan.subscription_plan.description}
                  </Text>
                </VStack>
              </PlanWidget.Description>
            </PlanWidget>

            {info?.free_days_remaining != null && info?.next_payment_date != null ? (
              <Box mt="s-16">
                <Item useIcon={CalendarRecurring} iconColor={Token.color.greyTone50}>
                  <Item.Content>
                    <Item.Description>
                      {info.free_days_remaining ? (
                        <>
                          You have{' '}
                          <b>{pluralize('day', info.free_days_remaining, true)}</b> of
                          free trial period left.{' '}
                        </>
                      ) : (
                        ''
                      )}
                      Next payment on <b>{formatDate(info.next_payment_date)}</b>.
                      <br />
                      This is an ongoing rolling plan charged monthly. You can cancel at
                      any time. For details about your plan see{' '}
                      <Link
                        href={pathToUrl(PUBLIC_ROUTES.TERMS_AND_CONDITIONS)}
                        target="_blank"
                      >
                        Terms and Conditions
                      </Link>
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Box>
            ) : null}

            <VStack mt="s-16">
              <Subheader variant="default">
                <Subheader.Title>Features for you</Subheader.Title>
              </Subheader>
              <Group>
                {features.map(feature => (
                  <Item
                    useIcon={feature.icon}
                    iconColor={Token.color.greyTone50}
                    key={feature.id}
                  >
                    <Item.Content>
                      {feature.title ? <Item.Title>{feature.title}</Item.Title> : null}
                      {feature.description ? (
                        <Item.Description>{feature.description}</Item.Description>
                      ) : null}
                    </Item.Content>
                  </Item>
                ))}
              </Group>
            </VStack>

            <VStack>
              <Subheader variant="default">
                <Subheader.Title>Feature breakdown</Subheader.Title>
              </Subheader>
              <Group>
                {featureBreakdown.map(featureGroup => (
                  <React.Fragment key={featureGroup.title}>
                    <Subheader variant="nested">
                      <Subheader.Title>{featureGroup.title}</Subheader.Title>
                    </Subheader>
                    {featureGroup.features.map(feature => (
                      <Item
                        useIcon={feature.icon}
                        iconColor={Token.color.greyTone50}
                        key={feature.title}
                      >
                        <Item.Content>
                          <Item.Title>{feature.title}</Item.Title>
                        </Item.Content>
                      </Item>
                    ))}
                  </React.Fragment>
                ))}
              </Group>
            </VStack>
          </>
        ) : (
          <SelectPlanSkeleton />
        )}
      </Layout.Main>

      {selectedPlan && info && !info.subscription_plan ? (
        <Layout.Actions gradient="high">
          <Footnote>
            <TermsAndConditionsDisclaimer />
          </Footnote>
          <Button onClick={onPlanSelect} elevated pending={createOrderPending}>
            Continue
          </Button>
        </Layout.Actions>
      ) : null}

      {isCurrentPlanSelected && noPaymentMethod ? (
        <Layout.Actions gradient="high">
          <Footnote>
            <TermsAndConditionsDisclaimer />
          </Footnote>
          <Button onClick={onAddPaymentMethod} elevated pending={createOrderPending}>
            Add payment method
          </Button>
        </Layout.Actions>
      ) : null}
    </>
  )
}

const SelectPlanSkeleton = () => {
  return (
    <>
      <BarSkeleton mb="s-16" />
      <PlanWidgetSkeleton variant="small" />
      <Skeleton height={16} width={160} mt="s-32" mb="s-16" />
      <Group>
        {[...Array(5)].map((_, index) => (
          <ItemSkeleton key={index} />
        ))}
      </Group>
    </>
  )
}
