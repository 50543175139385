import { ImportFieldType } from '@src/interfaces/bulkDataImport'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { ImportCandidatesDataInterface } from '@src/interfaces/importCandidates'
import { EmployeeEmails } from '@src/interfaces/selectors'

const originOptions = [
  { id: 'application', name: 'Application Form' },
  { id: 'sourcing', name: 'Sourcing Form' },
]

export const fields: ImportFieldType<ImportCandidatesDataInterface>[] = [
  {
    field: 'full_name',
    type: 'input',
    label: 'Full name',
  },
  {
    type: 'radio-select',
    field: 'specialisation',
    selector: selectorKeys.specialisations,
    label: 'Specialisation',
    valueKey: 'name',
  },
  {
    field: 'expected_seniority',
    type: 'radio-select',
    selector: selectorKeys.seniority,
    label: 'Expected seniority',
    valueKey: 'name',
  },
  {
    field: 'origin',
    type: 'radio-select',
    selector: () => Promise.resolve(originOptions),
    label: 'Origin',
    valueKey: 'id',
  },
  {
    field: 'email',
    type: 'input',
    label: 'Email',
  },
  {
    field: 'country',
    type: 'radio-select',
    selector: selectorKeys.countries,
    label: 'Country',
    valueKey: 'name',
  },
  {
    field: 'current_company',
    type: 'input',
    label: 'Current company',
  },
  {
    type: 'radio-select',
    field: 'recruiter',
    selector: selectorKeys.employee_emails,
    label: 'Recruiter',
    valueKey: 'name',
  },
  {
    field: 'hiring_manager',
    type: 'radio-select',
    selector: selectorKeys.employee_emails,
    label: 'Hiring manager',
    valueKey: 'name',
  },
  {
    field: 'job_posting',
    type: 'radio-select',
    selector: selectorKeys.job_postings,
    label: 'Job posting',
    valueKey: 'name',
  },
  {
    field: 'phone',
    type: 'input',
    label: 'Phone',
  },
  {
    field: 'linkedin_url',
    type: 'input',
    label: 'LinkedIn',
  },
  {
    field: 'preferred_work_locations',
    type: 'multi-select',
    selector: selectorKeys.location,
    label: 'Locations',
    valueKey: 'name',
  },
  {
    field: 'sourcer',
    type: 'radio-select',
    selector: selectorKeys.employee_emails,
    label: 'Sourcer',
    valueKey: 'name',
  },
  {
    field: 'notes',
    type: 'input',
    label: 'notes',
  },
]

export const useOptions = () => {
  const { options: employeeOptions } = useFetchOptions<EmployeeEmails>(
    selectorKeys.employee_emails,
    true,
  )
  const { options: specialisationOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.specialisations,
    true,
  )
  const { options: seniorityOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.seniority,
    true,
  )
  const { options: jobPostingOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.job_postings,
    true,
  )
  return {
    employeeOptions,
    specialisationOptions,
    seniorityOptions,
    originOptions: originOptions.map(value => ({
      label: value.name,
      value,
      key: value.id,
    })),
    jobPostingOptions,
  }
}
