import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Button, Flex } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { teamNameColumn } from '@src/constants/columns/team'
import {
  recruitmentRoleColumn,
  recruitmentTeamOwner,
} from '@src/constants/columns/recruitmentGroups'
import { RecruitmentGroupsInterface } from '@src/interfaces/recruitmentGroups'
import { recruitmentGroupsRequests } from '@src/api/recruitmentGroups'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const RowNotifications: RowInterface<RecruitmentGroupsInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.RECRUITMENT_GROUPS.GENERAL, { id })),
  cells: [
    {
      ...teamNameColumn,
      width: 382,
    },
    {
      ...recruitmentTeamOwner,
      width: 250,
    },
    {
      ...recruitmentRoleColumn,
      width: 1000,
    },
  ],
}

const RecruitmentGroupsTable = () => {
  const table = useTable<RecruitmentGroupsInterface>(recruitmentGroupsRequests)
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddRecruitmentgroups)

  return (
    <TableWrapper>
      <Flex mb="s-24">
        <Stat label="Total" val={table?.loading ? undefined : table?.count} mr="s-32" />
      </Flex>
      {canAdd && (
        <Flex mb="s-16">
          <Button
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.RECRUITMENT_GROUPS.GENERAL)}
            mr="s-16"
            useIcon={Plus}
            size="sm"
            variant="secondary"
          >
            Add Recruitment Group
          </Button>
        </Flex>
      )}
      <AdjustableTable<RecruitmentGroupsInterface>
        name={TableNames.RecruitmentGroups}
        useWindowScroll
        dataType="Recruitment Group"
        row={RowNotifications}
        {...table}
        noDataMessage="Recruitment will appear here."
      />
    </TableWrapper>
  )
}

export default RecruitmentGroupsTable
