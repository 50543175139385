import React from 'react'
import { Statuses } from '@src/interfaces'
import SideBar from '@components/SideBar/SideBar'
import { Box, Flex, Group, Item, Radio, Text, Token, VStack } from '@revolut/ui-kit'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import upperFirst from 'lodash/upperFirst'
import { getStatusThemeColor } from '@components/CommonSC/General'
import { AvatarType } from '@src/interfaces/employees'

interface SwitchItemsSidebarItemInterface {
  id: number
  category?: string
  status?: Statuses
  avatar: {
    id?: number
    name?: string
    img?: AvatarType
  }
}

type SwitchItemsSidebarProps = {
  title: string
  subtitle: string
  items: SwitchItemsSidebarItemInterface[]
  isOpen: boolean
  onClose: () => void
  selectedIndex: number
  onSelect: (item: SwitchItemsSidebarItemInterface) => void
}

export const SwitchItemsSidebar = ({
  isOpen,
  onClose,
  items,
  selectedIndex,
  onSelect,
  title,
  subtitle,
}: SwitchItemsSidebarProps) => {
  if (!isOpen) {
    return null
  }

  return (
    <SideBar
      variant="wide"
      title={title}
      subtitle={subtitle}
      onClose={onClose}
      isOpen={isOpen}
    >
      <Group>
        {items.map((item, index) => (
          <Item
            key={item.id}
            use="button"
            aria-pressed={index === selectedIndex}
            onClick={() => onSelect(item)}
          >
            <Item.Content>
              <Flex alignItems="center" gap="s-16">
                <Radio checked={index === selectedIndex} onChange={() => {}} />
                <Box width="s-40">
                  <UserWithAvatar
                    name={item.avatar?.name}
                    id={item.id}
                    avatar={item.avatar?.img}
                    status={undefined}
                    size={40}
                    compact
                  />
                </Box>
                <VStack>
                  <Text variant="primary">{item.avatar?.name}</Text>
                  {item.category && (
                    <Text variant="caption" color={Token.color.greyTone50}>
                      {upperFirst(item.category)}
                    </Text>
                  )}
                </VStack>
              </Flex>
            </Item.Content>
            {item.status && (
              <Item.Side>
                <Text variant="caption" color={getStatusThemeColor(item.status)}>
                  {upperFirst(item.status)}
                </Text>
              </Item.Side>
            )}
          </Item>
        ))}
      </Group>
    </SideBar>
  )
}
