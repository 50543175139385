import { SkillLevels } from '@src/interfaces/roles'
import { RevolutTheme } from '@src/styles/theme'
import { Color } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'

const getColors = (theme: RevolutTheme) => ({
  None: getColor(theme, Color.GREY_TONE_2),
  Intermediate: getColor(theme, Color.GREEN_OPAQUE_10),
  Advanced: getColor(theme, Color.GREEN_OPAQUE_40),
  Expert: getColor(theme, Color.GREEN_OPAQUE_80),
})

export const competencyToColor = (competency: SkillLevels, theme: RevolutTheme) => {
  const colors = getColors(theme)

  switch (competency) {
    case SkillLevels.None:
      return colors.None

    case SkillLevels.Intermediate:
      return colors.Intermediate

    case SkillLevels.Advanced:
      return colors.Advanced

    case SkillLevels.Expert:
      return colors.Expert

    default:
      return null
  }
}

export const getNormalizedSeniorityName = (name: string) => {
  const normalizedNames = {
    Director: 'Dir',
    'Senior Director': 'SR Dir',
    'Vice President': 'VP',
    'Senior Vice President': 'SVP',
  }

  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  return normalizedNames[name] || name
}
