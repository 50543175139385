import React, { useEffect, useRef } from 'react'
import {
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewerRelation,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card, CardField } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  getRoundedRating,
  GradeOption,
  onPrefillWithGrade,
  ratingOptions,
  usePrefillSkillsWithPreviousQuarter,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { getNormalizedCards } from '@src/utils/performance'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { LoadingCard } from '@src/pages/Forms/EmployeePerformanceLayout/components/LoadingCard'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'

export const ManagerSkillsCard = connect(
  ({ selectedField, setSelectedField, onHelpClick }: CommonCardProps) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const ref = useRef<HTMLDivElement>(null)
    const statusPopup = useStatusPopup()

    const canViewManagerSkills = !!values?.review_data?.manager_skills

    useEffect(() => {
      if (
        errors.review_data?.manager_skills?.cards &&
        !errors.review_data?.deliverables?.cards &&
        !errors.review_data?.functional_skills?.cards
      ) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data])

    const { prefillCompleted } = usePrefillSkillsWithPreviousQuarter(
      values,
      values?.review_data?.manager_skills?.cards,
    )

    if (!canViewManagerSkills) {
      return null
    }

    const onManagerSkillsSkip = (value?: string) => {
      set(values, 'review_data.manager_skills.skipped_section_justification', value)
    }

    const managerSkillsCards = getNormalizedCards(
      values.review_data?.manager_skills?.cards || [],
    )
    const managerSkillsFields = managerSkillsCards.map((card, ind) => ({
      field: `review_data.manager_skills.cards.${ind}`,
      title: card.name,
      grades: ratingOptions,
      cardIndex: ind,
    }))

    const onSelectGrade = (grade: GradeOption, field: CardField) => {
      const currentRating = get(values, `${field.field}.rating`)
      set(values, `${field.field}.rating`, grade.key)
      try {
        onPrefillWithGrade(
          values,
          grade,
          PerformanceReviewTypes.managerSkills,
          field.cardIndex,
        )
      } catch (e) {
        set(values, `${field.field}.rating`, currentRating)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Could not recalculate rating</StatusPopup.Title>
          </StatusPopup>,
        )
      }
    }

    if (!prefillCompleted) {
      return <LoadingCard />
    }

    return (
      <Card
        data={values}
        type={CardContentTypes.MANAGER_SKILLS}
        title="Manager skills"
        icon="People"
        fields={managerSkillsFields}
        selectedField={selectedField}
        setSelectedField={setSelectedField}
        isGradeSelectedRule={(field, grade) => {
          const ratingValue = get(values, field)?.rating
          return !!ratingValue && getRoundedRating(ratingValue) === grade.key
        }}
        onSelectGrade={onSelectGrade}
        allowSkip={
          values.category === ReviewCategory.Performance &&
          values.reviewer_relation !== ReviewerRelation.FunctionalManager
        }
        onSkip={onManagerSkillsSkip}
        justification={values?.review_data?.manager_skills?.skipped_section_justification}
        headerRef={ref}
        onHelpClick={() => onHelpClick?.(HelpTabs.Skills)}
      />
    )
  },
)
