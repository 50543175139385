import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { InputGroup, Link } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { DocumentInterface, DocumentRequestInterface } from '@src/interfaces/documents'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  requestDocumentByTemplateRequests,
  requestDocumentRequest,
} from '@src/api/documents'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { DOCUMENT_LABEL_REQUEST_SERVICE_DESK } from '@src/constants/externalLinks'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import useIsCommercial from '@src/hooks/useIsCommercial'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

enum FormId {
  manual = 'manual',
  byTemplate = 'byTemplate',
}
enum FormOptionName {
  manual = 'Request documents manually',
  byTemplate = 'Request documents by template rule',
}
type FormSelectorValueType = IdAndName<FormId>
const MANUAL_REQUEST_OPTION = {
  label: FormOptionName.manual,
  value: { id: FormId.manual, name: FormOptionName.manual },
}
const REQUEST_BY_TEMPLATE_OPTION = {
  label: FormOptionName.byTemplate,
  value: { id: FormId.byTemplate, name: FormOptionName.byTemplate },
}

const General = () => {
  const params = useParams<{ employeeId?: string }>()
  const { values } = useLapeContext<DocumentInterface>()
  const [selectorValue, setSelectorValue] = useState<FormSelectorValueType>()

  const isCommercial = useIsCommercial()

  const employeeId = params.employeeId || `${values.employee?.id}`

  const backUrl = values.employee
    ? pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS, {
        id: values.employee.id,
      })
    : ROUTES.PEOPLE.EMPLOYEES

  const handleChange = (value: FormSelectorValueType | null) => {
    if (value) {
      setSelectorValue(value)
    }
  }
  const handleSubmit = () => {
    if (selectorValue?.id === FormId.manual) {
      return requestDocumentRequest
        .submit(
          {
            name: values.name,
            issue_date_time: values.issue_date_time,
            issuer: values.issuer,
            category: values.category,
            description: values.description,
            employee: values.employee,
          },
          {},
        )
        .then(() => {
          workspaceLocalStorage.removeItem(
            pathToUrl(ROUTES.FORMS.DOCUMENT_REQUEST.GENERAL, { employeeId }),
          )
        })
    }
    if (selectorValue?.id === FormId.byTemplate) {
      const requestByTemplateValues = values as unknown as DocumentRequestInterface
      return requestDocumentByTemplateRequests
        .submit(
          {
            document_rule: requestByTemplateValues.document_rule,
          },
          { employeeId },
        )
        .then(() => {
          workspaceLocalStorage.removeItem(
            pathToUrl(ROUTES.FORMS.DOCUMENT_REQUEST.GENERAL, { employeeId }),
          )
        })
    }
    throw new Error('Request type is not selected')
  }

  useEffect(() => {
    if (isCommercial) {
      handleChange(MANUAL_REQUEST_OPTION.value)
    }
  }, [isCommercial])

  useEffect(() => {
    if (!values.issue_date_time) {
      values.issue_date_time = new Date().toISOString()
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader title="Request document from employee" backUrl={backUrl} />

      <PageBody>
        <AutoStepper>
          {!params.employeeId && (
            <>
              <NewStepperTitle title="Employee" />
              <InputGroup>
                <LapeRadioSelectInput
                  name="employee"
                  selector={selectorKeys.employee}
                  label="Employee"
                />
              </InputGroup>
            </>
          )}
          <HideIfCommercial>
            <NewStepperTitle title="Method" />
            <InputGroup>
              <RadioSelectInput<FormSelectorValueType>
                label="Request method"
                onChange={handleChange}
                value={selectorValue}
                searchable={false}
                options={[MANUAL_REQUEST_OPTION, REQUEST_BY_TEMPLATE_OPTION]}
              />
            </InputGroup>
          </HideIfCommercial>
          {selectorValue?.id === FormId.manual && (
            <>
              <NewStepperTitle title="Details" />
              <InputGroup>
                <LapeNewInput name="name" label="File Name" required />
                <LapeDatePickerInput name="issue_date_time" label="Issue Date" required />
                <LapeRadioSelectInput
                  label="Issuer"
                  name="issuer"
                  selector={selectorKeys.employee}
                />
                <LapeRadioSelectInput
                  name="category"
                  label="Category"
                  message={
                    !isCommercial ? (
                      <>
                        Please make sure you select the appropriate document label for the
                        file you are uploading. If you cannot find a label that describes
                        precisely the document type, you can request one on the Revoluters
                        service desk{' '}
                        <Link target="_blank" href={DOCUMENT_LABEL_REQUEST_SERVICE_DESK}>
                          here
                        </Link>
                      </>
                    ) : undefined
                  }
                  selector={selectorKeys.document_categories}
                />
                <LapeNewTextArea
                  name="description"
                  label="Description"
                  message="Add some description to explain what the document is about."
                  rows={3}
                />
              </InputGroup>
            </>
          )}
          {selectorValue?.id === FormId.byTemplate && (
            <>
              <NewStepperTitle title="Details" />
              <InputGroup>
                <LapeRadioSelectInput
                  label="Rule"
                  name="document_rule"
                  selector={selectorKeys.document_template_rules_individual_requesting}
                />
              </InputGroup>
            </>
          )}
        </AutoStepper>
      </PageBody>
      {selectorValue?.id && (
        <PageActions>
          <NewSaveButtonWithPopup
            disabled={!values.employee}
            successText="Documents requested"
            afterSubmitUrl={backUrl}
            onClick={handleSubmit}
            useValidator
          />
        </PageActions>
      )}
    </PageWrapper>
  )
}

export default General
