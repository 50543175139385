import React from 'react'
import HubApp from '@src/features/HubApp/HubApp'
import { APPLICATIONS, globalSettingsAppTabsFilter } from '@src/constants/hub'
import { useGetEmployeeSettings } from '@src/api/settings'

const Employees = () => {
  const { data: employeeSettings } = useGetEmployeeSettings()

  const tabs = APPLICATIONS.employees.tabs.filter(tab =>
    globalSettingsAppTabsFilter(tab.homeSectionId, { employeeSettings }),
  )

  return <HubApp app={{ ...APPLICATIONS.employees, tabs }} />
}

export default Employees
