import React, { useState } from 'react'
import {
  Box,
  chain,
  Flex,
  HStack,
  IconButton,
  Separator,
  Text,
  Token,
  Widget,
} from '@revolut/ui-kit'
import styled from 'styled-components'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { getCVScreeningSession } from '@src/pages/Forms/CVScreening/utils'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router-dom'

type Props = {
  currentNumber: number
  total: number
  children: React.ReactNode
  onNext: () => void
  onPrev: () => void
  disablePrev?: boolean
}

const Wrap = styled(Widget)`
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
`

const CvScreeningHeader = ({
  currentNumber,
  total,
  children,
  onNext,
  onPrev,
  disablePrev,
}: Props) => {
  const params = useParams<{ id: string }>()
  const session = getCVScreeningSession()
  const [openConfirmation, setOpenConfirmation] = useState(false)

  const onClickSummary = () => {
    navigateTo(ROUTES.FORMS.CV_SCREENING_SUMMARY, { back: true })
  }

  return (
    <Wrap>
      <ConfirmationDialog
        variant="compact"
        label="Are you sure you want to finish CV screening?"
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onConfirm={() => {
          if (!session.candidateIds?.length || session.candidateIds?.length === 1) {
            navigateTo(
              session.referrerPath ||
                pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: params.id }),
            )
            return
          }

          navigateTo(session.referrerPath || ROUTES.RECRUITMENT.CANDIDATES)
        }}
        onReject={() => setOpenConfirmation(false)}
        body="You will be redirected to the candidates table"
      />
      <Flex justifyContent="space-between" alignItems="center" p="s-16">
        <HStack gap="s-8" align="center">
          <IconButton
            useIcon="Cross"
            size={16}
            onClick={() => setOpenConfirmation(true)}
          />
          <Text variant="primary">
            {chain(
              'CV Screening',
              <Text color={Token.color.greyTone50}>
                {currentNumber}/{total}
              </Text>,
            )}
          </Text>
        </HStack>

        <HStack gap="s-8">
          <IconButton
            useIcon="ChevronLeft"
            size={24}
            onClick={onPrev}
            disabled={disablePrev}
            color={disablePrev ? Token.color.greyTone10 : undefined}
          />
          <IconButton useIcon="ChevronRight" size={24} onClick={onNext} />
          <IconButton useIcon="ViewListSmall" size={24} onClick={onClickSummary} />
        </HStack>
      </Flex>
      <Separator />
      <Box p="s-16">{children}</Box>
    </Wrap>
  )
}

export default CvScreeningHeader
