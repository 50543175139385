import React, { useEffect, useState } from 'react'
import {
  AnytimeFeedbackInterface,
  FeedbackCategory,
} from '@src/interfaces/anytimeFeedback'
import { Flex, TabBar } from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import { EmployeeInterface } from '@src/interfaces/employees'
import { AnytimeFeedbackFormSubtitle } from '@src/pages/Forms/FormTitles'
import { AnytimeFeedbackCard } from './AnytimeFeedbackCard'
import { getEmployeeWithManagerFilter } from '@src/api/employees'
import { useTheme } from '@src/styles/theme'

type Props = {
  feedbackList: AnytimeFeedbackInterface[]
  refetchFeedbackList: () => void
  isOpen: boolean
  handleClose: () => void
  employeeId: string
  openTab?: tabsCategory
}

export enum tabsCategory {
  All = 'all',
  Anytime = 'anytime',
  OneToOne = 'one_to_one',
  Performance = 'performance',
}

export const AnytimeFeedbackSidebar = ({
  feedbackList,
  refetchFeedbackList,
  isOpen,
  handleClose,
  employeeId,
  openTab = tabsCategory.All,
}: Props) => {
  const [employeeData, setEmployeeData] = useState<EmployeeInterface>()
  const [currentTabId, setCurrentTabId] = useState<tabsCategory>(openTab)
  const theme = useTheme()
  useEffect(() => {
    if (employeeId && !isNaN(parseFloat(employeeId))) {
      getEmployeeWithManagerFilter(employeeId, 'line_manager__id').then(res => {
        setEmployeeData(res.data)
      })
    }
  }, [employeeId])

  useEffect(() => {
    if (openTab) {
      setCurrentTabId(openTab)
    }
  }, [openTab])

  const tabs = [
    {
      title: 'All',
      id: tabsCategory.All,
      list: feedbackList,
      type: FeedbackCategory.Anytime,
    },
    {
      title: 'Feedback',
      id: tabsCategory.Anytime,
      list: feedbackList.filter(item => item.category === FeedbackCategory.Anytime),
      type: FeedbackCategory.Anytime,
    },
    {
      title: '1:1 notes',
      id: tabsCategory.OneToOne,
      list: feedbackList.filter(item => item.category === FeedbackCategory.OneToOne),
      type: FeedbackCategory.OneToOne,
    },
  ]

  const currentTab = tabs.find(tab => tab.id === currentTabId)

  return (
    <>
      <SideBar
        variant="wide"
        title="Feedback"
        zIndex={theme.zIndex.sideBar}
        subtitle={
          employeeData && (
            <Flex alignItems="flex-start" mt="s-8">
              <AnytimeFeedbackFormSubtitle data={employeeData} />
            </Flex>
          )
        }
        onClose={handleClose}
        isOpen={isOpen}
      >
        <TabBar value={currentTabId} variant="segmented" mb="s-16">
          {tabs.map(tab => (
            <TabBar.Item
              key={tab.id}
              onClick={() => setCurrentTabId(tab.id)}
              aria-selected={currentTabId === tab.id}
            >
              {`${tab.title} (${tab.list.length})`}
            </TabBar.Item>
          ))}
        </TabBar>
        {employeeData &&
          currentTab?.list.map(feedback => (
            <AnytimeFeedbackCard
              key={feedback.id}
              feedback={feedback}
              refreshFeedbackCard={refetchFeedbackList}
              employeeData={employeeData}
              type={currentTab.type}
            />
          ))}
      </SideBar>
    </>
  )
}
