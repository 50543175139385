import { api, apiWithoutHandling } from '@src/api/index'
import { filterSortPageIntoQuery, groupFiltersIntoURLQuery } from '@src/utils/table'
import { TalentStatsInterface } from '@src/interfaces/functions'
import { RequestInterface } from '@src/interfaces'
import { API } from '@src/constants/api'
import {
  TalentBulkGradeUpdateInterface,
  TalentPerformanceBulkGradeUpdateResponse,
  TalentPerformanceCommentInterface,
  TalentPerformanceDelegateInterface,
  TalentPerformanceDelegateResponse,
  TalentPerformanceInterface,
  TalentPerformanceStatsInterface,
  TalentPerformanceRelationsInterface,
} from '@src/interfaces/talent/performance'
import { getCommentsAPI } from '@src/api/comments'
import { FilterByInterface } from '@src/interfaces/data'
import { useFetch } from '@src/utils/reactQuery'

export const getTalentStats = (columnName: string, value?: string) => {
  return api.get<TalentStatsInterface>(`/talent/stats`, {
    params: filterSortPageIntoQuery(
      [],
      [
        {
          columnName,
          filters: [{ id: `${value}`, name: `${value}` }],
        },
      ],
    ),
  })
}

export const useGetCalibrarionStats = (filters: FilterByInterface[]) => {
  return useFetch<TalentPerformanceStatsInterface>(
    `${API.PERFORMANCE}/employeesCalibratedGrades/stats`,
    'v1',
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
  )
}

export const talentPerformanceRequests: RequestInterface<
  TalentPerformanceInterface,
  TalentPerformanceStatsInterface
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.PERFORMANCE}/employeesCalibratedGrades`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ filters, page }) =>
    api.get(`${API.PERFORMANCE}/employeesCalibratedGrades/stats`, {
      params: filterSortPageIntoQuery(undefined, filters, page),
    }),
  getItem: async id => api.get(`${API.PERFORMANCE}/employeesCalibratedGrades/${id}`),
  patchItem: async (data, id) =>
    api.patch(`${API.PERFORMANCE}/employeesCalibratedGrades/${id}`, data),
  deleteItem: async id =>
    api.delete(`${API.PERFORMANCE}/employeesCalibratedGrades/${id}`),
  postItem: async data => api.post(`${API.PERFORMANCE}/employeesCalibratedGrades`, data),
  getExport: async (exportType, filterQuery) =>
    api.get(`${API.PERFORMANCE}/employeesCalibratedGrades/${exportType}`, {
      params: filterQuery,
      responseType: 'blob',
    }),
}

export const updateTalentRank = (
  id: number | string,
  beforeRowId: number,
  afterRowId: number,
  filters: FilterByInterface[],
) =>
  api.put(
    `${API.PERFORMANCE}/employeesCalibratedGrades/${id}/rankUpdate`,
    {
      item_before_id: beforeRowId,
      item_after_id: afterRowId,
    },
    { params: groupFiltersIntoURLQuery(filters) },
  )

export const updateCalibratedGrade = (
  id: number,
  data: Partial<TalentPerformanceInterface>,
  filters: FilterByInterface[],
) =>
  api.patch<TalentPerformanceInterface>(
    `${API.PERFORMANCE}/employeesCalibratedGrades/${id}`,
    data,
    { params: groupFiltersIntoURLQuery(filters) },
  )

export const getTalentCommentsAPI = (id: number | null) =>
  getCommentsAPI({
    baseUrl: `${API.PERFORMANCE}/employeesCalibratedGrades/${id}/comments`,
  })

export const getCommentsCount = (filters: FilterByInterface[]) =>
  api.get<TalentPerformanceCommentInterface[]>(
    `${API.PERFORMANCE}/employeesCalibratedGrades/comments`,
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
  )

export const bulkGradeUpdate = (
  data: TalentBulkGradeUpdateInterface[],
  filters?: FilterByInterface[],
) => {
  return apiWithoutHandling.put<{ results: TalentPerformanceBulkGradeUpdateResponse[] }>(
    `${API.PERFORMANCE}/employeesCalibratedGrades/bulkGradeUpdate`,
    data,
    {
      params: groupFiltersIntoURLQuery(filters),
    },
  )
}

export const delegateCalibration = (
  data: TalentPerformanceDelegateInterface,
  filters: FilterByInterface[],
) => {
  return apiWithoutHandling.put<{ results: TalentPerformanceDelegateResponse[] }>(
    `${API.PERFORMANCE}/employeesCalibratedGrades/delegateCalibration`,
    data,
    { params: filterSortPageIntoQuery(undefined, filters) },
  )
}

export const useGetTalentByComment = (itemId: number) =>
  useFetch<TalentPerformanceRelationsInterface>({
    url: itemId ? `${API.PERFORMANCE}/employeesCalibratedGrades/comment/${itemId}` : null,
    withoutHandling: true,
  })

export const getTalentByComment = (itemId: number) =>
  api.get<TalentPerformanceRelationsInterface>(
    `${API.PERFORMANCE}/employeesCalibratedGrades/comment/${itemId}`,
  )
