import React, { useState, useRef, useEffect } from 'react'
import {
  mq,
  Text,
  Flex,
  Box,
  Button,
  Token,
  ButtonProps,
  TextWidget,
  TextSkeleton,
  DetailsCell,
} from '@revolut/ui-kit'
import { CalendarDate, PlayOutline } from '@revolut/icons'
import styled, { css } from 'styled-components'
import { connect } from 'lape'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { OnboardingData, onboardingSteps } from '@src/interfaces/onboarding'
import { DynamicGroupIDs } from '@src/interfaces/customFields'
import { Grid } from '@src/components/CommonSC/Grid'
import { formatWithoutTimezone } from '@src/utils/format'
import HTMLContent from '@components/HTMLContent/HTMLContent'
import { useQuery } from '@src/utils/queryParamsHooks'
import { SESSION_STORAGE } from '@src/constants/api'

export const WrapperCss = css`
  margin: 0 auto;
  max-width: 1000px;
  @media ${mq('*-lg')} {
    padding: ${Token.size.s16};
  }
`

interface SectionTitleProps {
  title: string
  subtitle?: string
}

export const SectionTitle = ({ title, subtitle }: SectionTitleProps) => {
  return (
    <Flex
      mt={{ all: '80px', lg: '160px' }}
      mb={{ all: '16px', lg: '80px' }}
      flexDirection="column"
      alignItems="center"
    >
      <Text variant="h2" fontSize="h0" color={Token.color.foreground} textAlign="center">
        {title}
      </Text>
      {subtitle && (
        <Text
          variant="h4"
          color={Token.color.greyTone50}
          mt={{ all: 's-16', lg: 's-24' }}
          textAlign="center"
        >
          {subtitle}
        </Text>
      )}
    </Flex>
  )
}

interface CardProps {
  title: React.ReactNode
  text: React.ReactNode
  icon: React.ReactNode
}

export const Card = ({ title, text, icon }: CardProps) => {
  return (
    <Flex
      flexDirection="column"
      p="s-24"
      borderRadius={24}
      alignItems="flex-start"
      bg={Token.color.background}
    >
      {icon}
      <Text fontSize="h5" variant="h6" color={Token.color.foreground} mt="s-16">
        {title}
      </Text>
      <Text fontSize="primary" color={Token.color.greyTone50} mt="s-8">
        {text}
      </Text>
    </Flex>
  )
}

const VideoPlayer = styled.video`
  object-fit: cover;
  border-radius: 4px;
  outline: none;
  background-position: 100px 100px;
  width: 100%;
  height: auto;
`

const VideoContainer = styled(Box)`
  position: relative;
`

const VideoOverlay = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  color: white;
  padding: 32px 0;
  text-align: center;
  z-index: 1;
`

export const Video = () => {
  const [isPlayingVideo, setIsPlayingVideo] = useState(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  return (
    <VideoContainer>
      {isPlayingVideo ? null : (
        <VideoOverlay>
          <Text variant="h5" fontSize="h0">
            Our values
          </Text>
          <Text fontSize="h5" mt="s-8">
            See what we’re all about!
          </Text>
          <Button
            variant="text"
            useEndIcon={PlayOutline}
            mt="s-32"
            onClick={() => {
              videoRef.current?.play()
            }}
          >
            Play video
          </Button>
        </VideoOverlay>
      )}
      <VideoPlayer
        ref={videoRef}
        controls={isPlayingVideo}
        onPlay={() => setIsPlayingVideo(true)}
        poster="https://cdn.revolut.com/media/revoluters/nik.jpg"
        style={{ borderRadius: '12px' }}
      >
        <source
          src="https://cdn.revolut.com/media/careers/values/values.mp4"
          type="video/mp4"
        />
      </VideoPlayer>
    </VideoContainer>
  )
}

export const useOnboardingPreviewMode = (): {
  config?: { enable_diversity?: boolean }
  cleanup: () => void
} => {
  const { query, deleteQueryParam } = useQuery()

  useEffect(() => {
    if (query.preview) {
      const config: { enable_diversity?: boolean } = {}

      config.enable_diversity = !!query.diversity
      sessionStorage.setItem(SESSION_STORAGE.ONBOARDING_PREVIEW, JSON.stringify(config))

      deleteQueryParam('preview')
      deleteQueryParam('diversity')
    }
  }, [])

  const config = sessionStorage.getItem(SESSION_STORAGE.ONBOARDING_PREVIEW)
  const parsedConfig = config ? JSON.parse(config) : undefined

  return {
    config: parsedConfig,
    cleanup: () => sessionStorage.removeItem(SESSION_STORAGE.ONBOARDING_PREVIEW),
  }
}

interface NextButtonProps extends ButtonProps {
  onClickInPreviewMode: () => void
  afterSubmit?: (data: object) => void
}

export const NextButton = connect(
  ({
    onClick,
    afterSubmit,
    children,
    onClickInPreviewMode,
    ...props
  }: NextButtonProps) => {
    const { submit, isSubmitting } = useLapeContext<{ id?: number }>()
    const formValidator = useFormValidator()
    const isPreview = !!useOnboardingPreviewMode().config

    const formSubmit = () => {
      return submit().then(result => {
        afterSubmit?.(result)
        return result
      })
    }

    const onSubmit = formValidator?.validate
      ? formValidator.validate(formSubmit)
      : formSubmit

    const buttonOnClick = isPreview ? onClickInPreviewMode : onClick || onSubmit

    return (
      <Button onClick={buttonOnClick} pending={isSubmitting} {...props}>
        {children || 'Next'}
      </Button>
    )
  },
)

export const BackButton = (props: ButtonProps) => {
  return (
    <Button {...props} variant="secondary">
      Back
    </Button>
  )
}

export interface TabProps {
  data: OnboardingData
  dynamicGroups: DynamicGroupIDs
  onComplete: (type: onboardingSteps) => void
  setDiversityFaqOpen: (open: boolean) => void
  nextRoute?: string
  prevRoute?: string
  isPreview?: boolean
}

const StartPageVideo = styled.video`
  border-radius: 12px;
  outline: none;
  width: 100%;
  height: 100%;
`

interface StartPageViewProps {
  name: string
  joiningDateTime: string
  title?: string | null
  videoUrl?: string | null
  text?: string | null
}

const getYouTubeId = (url: string) => {
  const regExp =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  const match = url.match(regExp)
  return match ? match[1] : null
}

const getVimeoId = (url: string) => {
  const regExp =
    /(?:vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/[^\/]+\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?))/
  const match = url.match(regExp)
  return match ? match[1] : null
}

const getGDriveId = (url: string) => {
  const regExp =
    /(?:drive\.google\.com\/file\/d\/|drive\.google\.com\/open\?id=|drive\.google\.com\/uc\?id=|drive\.google\.com\/a\/[^\/]+\/file\/d\/)([^\/&?]+)(?:\/[^\/]*)?$/
  const match = url.match(regExp)
  return match ? match[1] : null
}

interface StartPageVideoPlayerProps {
  url?: string | null
}

const StartPageVideoPlayer = ({ url }: StartPageVideoPlayerProps) => {
  if (!url) {
    return null
  }

  const youTubeId = getYouTubeId(url)
  if (youTubeId) {
    return (
      <iframe
        title="YouTube video player"
        src={`https://www.youtube.com/embed/${youTubeId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        style={{ width: '100%', aspectRatio: '16 / 9' }}
      />
    )
  }

  const vimeoId = getVimeoId(url)
  if (vimeoId) {
    return (
      <iframe
        title="Vimeo video player"
        src={`https://player.vimeo.com/video/${vimeoId}`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{ width: '100%', aspectRatio: '16 / 9' }}
      />
    )
  }

  const gdriveId = getGDriveId(url)
  if (gdriveId) {
    return (
      <iframe
        title="GDrive video player"
        src={`https://drive.google.com/file/d/${gdriveId}/preview`}
        allow="autoplay"
        style={{ width: '100%', aspectRatio: '16 / 9' }}
      />
    )
  }

  return (
    <StartPageVideo controls>
      <source src={url} type="video/mp4" title="Video" />
    </StartPageVideo>
  )
}

export const StartPageView = ({
  title,
  videoUrl,
  text,
  name,
  joiningDateTime,
}: StartPageViewProps) => {
  return (
    <Grid gap={32} pb="s-24" pt="s-12">
      {title ? <Text variant="h1">{title}</Text> : <TextSkeleton variant="h1" />}

      <StartPageVideoPlayer url={videoUrl} />

      {text ? (
        <TextWidget>
          <TextWidget.Title>Dear {name}</TextWidget.Title>
          <TextWidget.Content>
            <HTMLContent html={text} />
          </TextWidget.Content>
        </TextWidget>
      ) : null}

      <DetailsCell>
        <DetailsCell.Title alignSelf="center">
          <Text variant="primary" color={Token.color.foreground}>
            Your expected joining date as per our records is{' '}
            {formatWithoutTimezone(joiningDateTime)}
          </Text>
        </DetailsCell.Title>
        <DetailsCell.Content alignSelf="center">
          <CalendarDate color={Token.color.blue} />
        </DetailsCell.Content>
      </DetailsCell>
    </Grid>
  )
}
