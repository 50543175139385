import React from 'react'
import styled from 'styled-components'
import StepperTitle from '@components/Stepper/StepperTitle'
import { useTheme } from '@src/styles/theme'
import { colorGetter } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

const Container = styled.div`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-right: 24px;
`
const LITitle = styled.span`
  color: ${props => props.theme.colors.foreground};
`
const LI = styled.div`
  position: relative;
  line-height: 20px;
  padding-left: 20px;

  &::before {
    content: '•';
    position: absolute;
    left: 0;
    color: ${props => props.theme.colors.foreground};
  }
`
const UL = styled.div<{ color?: string; bold?: boolean }>`
  display: grid;
  margin-bottom: 22px;
  grid-gap: 16px;

  & > ${LI}::before {
    color: ${props => props.color};
  }

  & > ${LI} > ${LITitle} {
    color: ${props => props.color};
    font-weight: ${props => (props.bold ? 'bold' : 'inherit')};
  }
`

const ReviewDirections = styled.div`
  margin-left: 8px;
`

const Disclaimer = styled.div`
  color: ${props => props.theme.colors.foreground};
`

const Wrapper = styled.div`
  max-width: 740px;
  font-size: 14px;
  line-height: 24px;
  color: ${colorGetter(Color.GREY_TONE_50)};
`

interface PerformanceTutorialProps {
  className?: string
  name: string
}

export const PerformanceTutorial = ({ className, name }: PerformanceTutorialProps) => {
  const theme = useTheme()

  return (
    <Container className={className}>
      <>
        <StepperTitle title="Scoring system" />
        <Wrapper>
          <p>
            Please accurately evaluate based on the delivery and display of values of the
            employee. Read the criteria for each item thoroughly while hovering over them.
            In general:
          </p>
          <UL>
            <LI>
              <LITitle>Unsatisfactory: </LITitle>
              The employee failed considerably in delivering what was expected at their
              role and seniority level. They have shown little potential for them to
              improve in the next cycle.
            </LI>
            <LI>
              <LITitle>Average Minus: </LITitle>
              The employee has consistently failed to perform at the expected level for
              their role and seniority. They’ve shown that with the guidance they have the
              potential to improve in the next cycle.
            </LI>
            <LI>
              <LITitle>Average Plus: </LITitle>
              The employee has consistently performed at the expected level for their role
              and seniority. This is a good outcome: we expect most of the company to
              perform at this level.
            </LI>
            <LI>
              <LITitle>Strong: </LITitle>
              The employee has consistently exceeded expectations at their role and
              seniority level.
            </LI>
            <LI>
              <LITitle>Exceptional: </LITitle>
              The employee performed almost flawlessly at their role and seniority level.
              We expect only 10% of the company to deliver at this level.
            </LI>
          </UL>
        </Wrapper>
      </>
      <>
        <StepperTitle
          title={`Please review ${name || 'employee'} using form on the right`}
        />
        <Wrapper>
          <ReviewDirections>
            <UL color={theme.colors['grey-tone-50']} bold>
              <LI>
                <LITitle>Evaluate accurately, not nicely. </LITitle>
                Accurate and candid feedback fosters self-development. Being “nice”,
                giving high scores, and not mentioning improvement points does more harm
                than good.
              </LI>
              <LI>
                <LITitle>Be specific and use evidence </LITitle>— Avoid vague terms like
                “strong” or “bad.” Instead, give evidence of how they didn’t meet
                expectations by not delivering Y on time or doing a fantastic work in X.
              </LI>
              <LI>
                <LITitle>Be constructive </LITitle>— When pointing out an area for
                improvement, elaborate what they need to do to improve in detail. What
                should they do more or less of?
              </LI>
            </UL>
          </ReviewDirections>
          <Disclaimer>
            At Revolut we do not tolerate bullying, harassment or discrimination. Reviews
            with no comments, evidence, or feedback will have to be re-done.
          </Disclaimer>
        </Wrapper>
      </>
    </Container>
  )
}
