import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { FaqTopicInterface } from '@src/interfaces/faq'
import { selectorKeys } from '@src/constants/api'
import { faqTopicStatusToColor } from '@src/apps/General/HelpCenter/helpers'

// TODO: [RHR-3191] add selectors when implemented on BE

export const faqTopicNameColumn: ColumnInterface<FaqTopicInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'title',
  sortKey: 'title',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Topic name',
}

export const faqTopicDescriptionColumn: ColumnInterface<FaqTopicInterface> = {
  type: CellTypes.text,
  idPoint: 'category.id',
  dataPoint: 'category.description',
  sortKey: 'category__description',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const faqTopicUpdatedOnColumn: ColumnInterface<FaqTopicInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'update_date_time',
  dataPoint: 'update_date_time',
  sortKey: 'update_date_time',
  filterKey: 'update_date_time',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'Updated on',
}

export const faqTopicStatusColumn: ColumnInterface<FaqTopicInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.faq_topic_statuses,
  title: 'Status',
  colors: (data, theme) => faqTopicStatusToColor(theme, data.status.id),
}
