import React from 'react'
import {
  Flex,
  Header,
  DetailsCell,
  Box,
  Group,
  Cell,
  Color,
  Text,
  VStack,
} from '@revolut/ui-kit'
import AvatarSnippet from '@components/AvatarSnippet/AvatarSnippet'
import {
  InterviewFeedbackStatus,
  InterviewStageRowInterface,
  InterviewStageRowWithoutRoundInterface,
  InterviewStageWithoutRoundInterface,
  ScheduleSidebarModeType,
} from '@src/interfaces/interviewTool'
import { formatDate } from '@src/utils/format'
import { InterviewResultIcon } from '@components/ColumnInserts/InterviewResult/InterviewResultIcon'
import { Grid } from '@components/CommonSC/Grid'
import StageActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StageActions'
import { Statuses } from '@src/interfaces'
import { PerformanceRatingTitle } from '@src/constants/performance'
import ScheduledDetails from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduledDetails'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { useGetSchedulingInterview } from '@src/api/recruitment/interviewerScheduling'
import ScheduledStatus from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduledStatus'
import SideBar from '@components/SideBar/SideBar'

type Props = {
  onClose: () => void
  stage?: InterviewStageRowInterface
  canAddFeedback: boolean
  stages: InterviewStageWithoutRoundInterface[]
  roundId?: number
  canCancel: boolean
  onRefresh: () => void
  currentStageId?: number
  onOpenSidebar: (
    data: InterviewStageRowWithoutRoundInterface,
    mode?: ScheduleSidebarModeType,
  ) => void
  isOpen?: boolean
  canViewEditOffer: boolean
  disableActions?: boolean
  candidateId: number
}

// cast InterviewFeedbackStatus to Statuses
function isInterviewFeedbackStatusIsStatus(
  value: Statuses | InterviewFeedbackStatus,
): value is Statuses {
  return Object.values(Statuses).includes(value as Statuses)
}

const CandidateShortSummarySidebar = ({
  isOpen,
  onClose,
  stage,
  stages,
  roundId,
  onRefresh,
  currentStageId,
  canCancel,
  canAddFeedback,
  onOpenSidebar,
  canViewEditOffer,
  disableActions,
  candidateId,
}: Props) => {
  const { data: interview, isLoading: isInterviewLoading } = useGetSchedulingInterview(
    isOpen ? stage?.id : undefined,
    roundId,
  )

  if (!isOpen || !roundId || !stage) {
    return null
  }

  const title = stage.title
  const feedbacks = stage.interview_feedbacks

  return (
    <SideBar
      variant="wide"
      title={title}
      isOpen
      onClose={onClose}
      subtitle={<ScheduledStatus status={stage.scheduling_status} />}
      headerContent={
        !disableActions ? (
          <Header.Bar>
            <StageActions
              stages={stages}
              stage={stage}
              roundId={roundId}
              canCancel={canCancel}
              canAddFeedback={canAddFeedback}
              onRefresh={onRefresh}
              currentStageId={currentStageId}
              onOpenSidebar={onOpenSidebar}
              canViewEditOffer={canViewEditOffer}
              menuType="primary"
              candidateId={candidateId}
            />
          </Header.Bar>
        ) : undefined
      }
    >
      <Group>
        <DetailsCell>
          <DetailsCell.Title>Result</DetailsCell.Title>
          <DetailsCell.Content>
            {stage.average_rating ? PerformanceRatingTitle[stage.average_rating] : '-'}
          </DetailsCell.Content>
        </DetailsCell>
        {interview && (
          <>
            <ScheduledDetails interview={interview} isLoading={isInterviewLoading} />
            {interview.interviewer && (
              <DetailsCell>
                <DetailsCell.Title>Lead interviewer</DetailsCell.Title>
                <DetailsCell.Content>
                  <UserWithAvatar
                    full_name={interview.interviewer.name}
                    id={interview.interviewer.id}
                    avatar={interview.interviewer.avatar}
                  />
                </DetailsCell.Content>
              </DetailsCell>
            )}
            {!!interview?.additional_interviewers?.length && (
              <DetailsCell>
                <DetailsCell.Title>Additional interviewers</DetailsCell.Title>
                <DetailsCell.Content>
                  <VStack gap="s-16" align="end">
                    {interview.additional_interviewers.map(item => (
                      <UserWithAvatar
                        key={item.id}
                        full_name={item.name}
                        id={item.id}
                        avatar={item.avatar}
                      />
                    ))}
                  </VStack>
                </DetailsCell.Content>
              </DetailsCell>
            )}
          </>
        )}
        {!!feedbacks?.length && (
          <>
            <DetailsCell>
              <DetailsCell.Title>Recommendations</DetailsCell.Title>
            </DetailsCell>
            <Cell display="block">
              <Grid gap={21}>
                {feedbacks?.map(feedback => (
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-start"
                    key={feedback.id}
                  >
                    <AvatarSnippet
                      fullName={feedback.interviewer?.full_name}
                      badge={
                        isInterviewFeedbackStatusIsStatus(feedback.status)
                          ? feedback.status
                          : undefined
                      }
                      text={formatDate(feedback.updated_date_time)}
                      avatar={feedback.interviewer?.avatar}
                    />
                    <Box css={{ textAlign: 'right' }}>
                      <InterviewResultIcon
                        result={feedback.result}
                        status={feedback.status}
                        showLabel
                      />
                      {feedback.rating && (
                        <Text color={Color.GREY_TONE_50} display="block" mt="s-4">
                          {PerformanceRatingTitle[feedback.rating]}
                        </Text>
                      )}
                    </Box>
                  </Flex>
                ))}
              </Grid>
            </Cell>
          </>
        )}
      </Group>
    </SideBar>
  )
}

export default CandidateShortSummarySidebar
