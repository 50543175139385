import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { Flex, InputGroup, Text, Widget } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import LapeEmployeeSelector from '@components/Inputs/LapeFields/LapeEmployeeSelector'

export const TypeOfInterview: RadioOption[] = [
  { value: 'employees', label: 'Employees' },
  { value: 'groups', label: 'Groups' },
]

type EligibleInterviewersProps = {
  isDisabled?: boolean
  isRequired: boolean
}

const EligibleInterviewers = ({ isDisabled, isRequired }: EligibleInterviewersProps) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  if (!values.interviewer_type) {
    values.interviewer_type = {
      id: 'groups',
      name: 'Groups',
    }
    values.interviewers_groups = []
  }
  return (
    <InputGroup>
      <Widget p="s-16">
        <Text use="p" fontSize="primary" pb="s-6">
          Select type of interviewers
        </Text>
        <Flex justifyContent="space-between" width="40%" height={42} my="s-16">
          <LapeNewRadioButtons
            disabled={isDisabled}
            name="interviewer_type"
            options={TypeOfInterview}
            labelProps={{ pr: '26px' }}
            useMappingAsValue
            onAfterChange={() => {
              if (
                values?.interviewer_type?.id === 'employees' &&
                values?.interviewers_groups?.length
              ) {
                values.interviewers_groups = []
              }
              if (
                values?.interviewer_type?.id === 'groups' &&
                values?.interviewers?.length
              ) {
                values.interviewers = []
              }
            }}
          />
        </Flex>
      </Widget>
      {values?.interviewer_type?.id === 'groups' ? (
        <LapeNewMultiSelect<HiringProcessInterface>
          disabled={isDisabled}
          name="interviewers_groups"
          placeholder="Select groups"
          selector={selectorKeys.dynamic_groups}
          required={isRequired}
        />
      ) : (
        <LapeEmployeeSelector
          required={isRequired}
          label="Select interviewer"
          name="interviewers"
          disabled={isDisabled}
        />
      )}
    </InputGroup>
  )
}

export default EligibleInterviewers
