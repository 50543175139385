import React from 'react'
import { Avatar, Button, Flex, Item, VStack } from '@revolut/ui-kit'
import { CandidateSettingsInterface } from '@src/interfaces/settings'
import {
  GmailIntegration,
  SchedulingEmailTemplate,
} from '@src/pages/Settings/Candidates/General'
import {
  BooleanRadioSwitch,
  LapeBooleanRadioSwitch,
} from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { PermissionTypes } from '@src/store/auth/types'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import { selectPermissions } from '@src/store/auth/selectors'
import { useGlobalSettings } from '@src/api/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useSelector } from 'react-redux'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { LogoGoogle } from '@revolut/icons'
import { RECRUITMENT_GMAIL_INTEGRATION_PLAYBOOK } from '@src/constants/externalLinks'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'

const CandidatesCommunication = () => {
  const { values } = useLapeContext<CandidateSettingsInterface>()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCandidatePreferences)
  const {
    settings: { applicants_tracking_enabled },
  } = useGlobalSettings()
  return (
    <VStack gap="s-16">
      <SectionTitle
        title="Do you want to enable Google Workspace integration?"
        subtitle="Integrate with Gmail and Google Calendar to enable the sending of emails from People Revolut, sync candidates' replies back and schedule interviews."
      />
      <BooleanRadioSwitch
        value={values.enable_emails}
        onChange={val => {
          values.enable_emails = val
        }}
        disabled={disableEdit}
        variant="horizontal"
        yesLabel={
          <Flex>
            Yes
            <Recommended />
          </Flex>
        }
        yesDescription="I want to enable the integration with Google Workspace"
        noDescription="I do not want to setup email integration at the moment"
      />
      {values.enable_emails && (
        <>
          <Item>
            <Item.Avatar>
              <Avatar useIcon={LogoGoogle} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Integrate with Google Workspace</Item.Title>
              <Item.Description>
                To set up the integration download the playbook and follow the
                instructions. Proceed with the next steps once you have configured the
                integration in your Google Workspace Console.
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <Button
                size="sm"
                variant="secondary"
                use="a"
                href={RECRUITMENT_GMAIL_INTEGRATION_PLAYBOOK}
                target="_blank"
                rel="noreferrer noopener"
              >
                Download playbook
              </Button>
            </Item.Side>
          </Item>

          <SectionTitle
            title="Do you want to enable recruitment team email?"
            subtitle="Authorise Revolut People to send emails to candidates from your recruitment team email (i.e. “recruitment-no-reply@my-company.com”)"
          />
          <GmailIntegration
            title={
              <>
                Connect your recruitment team’s Gmail account
                <Recommended />
              </>
            }
          />
          <VStack space="s-16">
            <LapeNewInput
              label="Recruitment team email"
              name="no_reply_email"
              required
              disabled
              message="Every recruiter will be able to send emails from this email address"
            />
            <LapeNewInput
              label="Recruitment team email displayed name"
              name="no_reply_name"
              required
              disabled={!applicants_tracking_enabled || disableEdit}
            />
          </VStack>
          <SectionTitle title="Do you want to enable call scheduling?" />
          <LapeBooleanRadioSwitch
            name="enable_scheduling"
            variant="horizontal"
            disabled={!applicants_tracking_enabled || disableEdit}
            yesLabel={
              <>
                Yes <Recommended />
              </>
            }
            yesDescription="I want to enable scheduling meetings with candidates through the platform so recruiters can book interviews using various scheduling methods, manage & track calls."
            noDescription="I do not want to setup scheduling through the platform at the moment. Candidates interviews will be managed outside of the product."
          />
          {values.enable_scheduling && (
            <>
              <SectionTitle
                title="Do you want to review your default scheduling templates?"
                subtitle="We created default scheduling email templates for you. Those templates will be sent alongside the invitation to an interview for each scheduling type. You can always edit an individual email while sending an interview invite."
              />
              <SchedulingEmailTemplate />
            </>
          )}
        </>
      )}
    </VStack>
  )
}

export default CandidatesCommunication
