import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { cloneDeep, get, isEmpty, set } from 'lodash'
import { QueryObserverResult } from 'react-query'
import { produce } from 'immer'

import {
  applyImportTimeOffBalancesSession,
  editImportTimeOffBalancesSessionRow,
  getTimeOffBalancesUploadSessionTable,
  useGetImporTimeOffBalanceSessionData,
} from '@src/api/importData'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { ProcessingFileState } from '@src/features/BulkDataImport/ProcessingFileState'
import { PageBody } from '@src/components/Page/PageBody'
import SuccessWidget from '@src/components/SuccessWidget/SuccessWidget'
import { PageActions } from '@src/components/Page/PageActions'
import { Button, TableWidget, Token } from '@revolut/ui-kit'
import { goBack } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { useTable } from '@src/components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useOptions } from './common'
import { WrapperCss } from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'
import {
  ImportTimeOffBalancesInputOnChange,
  ImportTimeOffBalancesInputSelectCellOptions,
  ImportTimeOffBalancesSelectInputOnChange,
  importTimeOffBalancesActionColumn,
  importTimeOffBalancesBalanceColumn,
  importTimeOffBalancesEmployeeColumn,
  importTimeOffBalancesPolicyColumn,
} from '@src/constants/columns/importTimeOffBalances'
import {
  ImportTimeOffBalancesInterface,
  ImportTimeOffBalancesSessionInterface,
} from '@src/interfaces/importTimeOffBalances'

const getRow = (
  sessionId: string,
  onInputRowChange: ImportTimeOffBalancesInputOnChange,
  onSelectRowChange: ImportTimeOffBalancesSelectInputOnChange,
  onDeleteRowSuccess: () => void,
  options: ImportTimeOffBalancesInputSelectCellOptions,
): RowInterface<ImportTimeOffBalancesInterface> => ({
  highlight: data => (isEmpty(data.errors) ? '' : Token.color.redActionBackground),
  cells: [
    {
      ...importTimeOffBalancesEmployeeColumn(onSelectRowChange, options.employees),
      width: 150,
    },
    {
      ...importTimeOffBalancesPolicyColumn(onSelectRowChange, options.policies),
      width: 150,
    },
    {
      ...importTimeOffBalancesBalanceColumn(onInputRowChange),
      wrapperCss: () => WrapperCss,
      notHoverable: true,
      width: 120,
    },
    {
      ...importTimeOffBalancesActionColumn(onDeleteRowSuccess, sessionId),
      width: 150,
    },
  ],
})

interface CustomActionsInterface {
  customActions?: (options: {
    refetch: () => void
    disabled: boolean
    onSubmit: () => Promise<any>
  }) => React.ReactNode
}

interface TimeOffBalancesSessionProps extends CustomActionsInterface {
  customSuccessActions?: React.ReactNode
}

export const TimeOffBalancesSession = ({
  customSuccessActions,
  customActions,
}: TimeOffBalancesSessionProps) => {
  const params = useParams<{ id: string }>()

  const { data, refetch } = useGetImporTimeOffBalanceSessionData(params.id)

  if (!data) {
    return <PageLoading />
  }

  if (
    data.state.id === 'pending' ||
    data.state.id === 'processing_file' ||
    data.state.id === 'applying'
  ) {
    return <ProcessingFileState />
  }

  if (data.state.id === 'success') {
    return (
      <>
        <PageBody>
          <SuccessWidget
            title="Upload successful"
            type="success"
            text="Time off balance data imported successfully"
            maxWidth="100%"
          />
        </PageBody>

        {customSuccessActions || (
          <PageActions>
            <Button onClick={() => goBack(ROUTES.PEOPLE.TIME_OFF.REQUESTS)} elevated>
              Done
            </Button>
          </PageActions>
        )}
      </>
    )
  }

  if (data.state.id === 'failure') {
    return (
      <PageBody>
        <SuccessWidget
          title="Task failed!"
          type="error"
          text="There was an error with the upload"
          maxWidth="100%"
        />
      </PageBody>
    )
  }

  return <Table refetchSessionData={refetch} customActions={customActions} />
}

interface TableProps extends CustomActionsInterface {
  refetchSessionData: () => Promise<
    QueryObserverResult<ImportTimeOffBalancesSessionInterface, Error>
  >
}

const Table = ({ refetchSessionData, customActions }: TableProps) => {
  const [submitPending, setSubmitPending] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)

  const params = useParams<{ id: string }>()

  const table = useTable({
    getItems: getTimeOffBalancesUploadSessionTable(params.id),
  })

  const options = useOptions()

  const onDeleteRow = () => {
    table.refresh()
  }

  const setTableRowData = (row: ImportTimeOffBalancesInterface) => {
    table.setData(
      produce(table.data, draft => draft.map(r => (r.id === row.id ? row : r))),
    )
  }

  const updateTableRow = (row: ImportTimeOffBalancesInterface) => {
    setTableRowData(row)
    setTableLoading(true)

    editImportTimeOffBalancesSessionRow(params.id, row.id, row)
      .then(response => setTableRowData(response.data))
      .finally(() => setTableLoading(false))
  }

  const onSelectRowChange: ImportTimeOffBalancesSelectInputOnChange = (
    row,
    option,
    field,
  ) => {
    const initialData = get(row.data, field)

    const optionValue = option?.name

    if (initialData !== optionValue) {
      const updatedRow = set(cloneDeep(row), `data.${field}`, optionValue)

      updateTableRow(updatedRow)
    }
  }

  const onInputRowChange: ImportTimeOffBalancesInputOnChange = (row, value, field) => {
    const initialData = get(row.data, field)

    if (initialData === value || (!initialData && !value)) {
      return
    }
    const updatedRow = set(cloneDeep(row), `data.${field}`, value)
    updateTableRow(updatedRow)
  }

  const onSubmit = async () => {
    setSubmitPending(true)

    try {
      await applyImportTimeOffBalancesSession(params.id)
      await refetchSessionData()
    } finally {
      setSubmitPending(false)
    }
  }

  const disabled = table.data.some(r => !isEmpty(r.errors)) || table.loading

  return (
    <>
      <TableWidget>
        <TableWidget.Table>
          <AdjustableTable
            name={TableNames.UploadTimeOffBalances}
            {...table}
            loading={table.loading || tableLoading}
            useWindowScroll
            row={getRow(
              params.id,
              onInputRowChange,
              onSelectRowChange,
              onDeleteRow,
              options,
            )}
            hideCountAndButtonSection
            lockFirstColumn={false}
            rowHeight="medium"
          />
        </TableWidget.Table>
      </TableWidget>

      {table.data?.length
        ? customActions?.({
            refetch: refetchSessionData,
            disabled,
            onSubmit,
          }) || (
            <PageActions>
              <Button
                onClick={onSubmit}
                pending={submitPending}
                disabled={disabled}
                elevated
              >
                Continue
              </Button>
            </PageActions>
          )
        : null}
    </>
  )
}
