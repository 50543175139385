import React, { useEffect, useState } from 'react'
import { Popup, Button, Flex, Link, Box, Text, Header } from '@revolut/ui-kit'
import isFuture from 'date-fns/isFuture'
import isPast from 'date-fns/isPast'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

import { EmployeeInterface } from '@src/interfaces/employees'
import { checkEmployeeConflictingFields } from '@src/api/changelog'
import { difference } from '@src/utils/form'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { accessWillBeResetMessage } from './common'
import { DatePickerInputProps } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

interface EffectiveDatePopupProps {
  onClick: () => void
  onClose: () => void
  onCheckingConflictsChange?: (state: boolean) => void
  datePickerProps?: DatePickerInputProps
}

const EffectiveDatePopup = ({
  onClick,
  onClose,
  onCheckingConflictsChange,
  datePickerProps,
}: EffectiveDatePopupProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const { values, initialValues, isSubmitting } = useLapeContext<EmployeeInterface>()
  const [checkingConflicts, setCheckingConflicts] = useState(!!values.user_id)
  const [conflicts, setConflicts] = useState<
    {
      effective_date_time: string
      field_name: string
      id: number
      status: string
    }[]
  >([])
  const willTakeEffectIn = values.effective_date_time
    ? formatDistanceToNow(new Date(values.effective_date_time), { addSuffix: true })
    : 'now'

  useEffect(() => {
    if (values.id) {
      checkFutureConflicts()
    }
  }, [values.effective_date_time])

  useEffect(() => {
    onCheckingConflictsChange?.(checkingConflicts)
  }, [checkingConflicts])

  const checkFutureConflicts = async () => {
    try {
      const data: Partial<EmployeeInterface> = { ...difference(values, initialValues) }
      if (values.effective_date_time) {
        data.effective_date_time = values.effective_date_time
      }
      const result = await checkEmployeeConflictingFields(values.id, data)

      if (result.data) {
        setConflicts(result.data)
      }
    } finally {
      setCheckingConflicts(false)
    }
  }

  if (checkingConflicts) {
    return null
  }

  const permissionsWillBeRemoved =
    values?.team?.id !== initialValues?.team?.id ||
    values?.specialisation?.id !== initialValues?.specialisation?.id ||
    values?.location?.id !== initialValues?.location?.id

  return (
    <Popup open onClose={onClose} variant="bottom-sheet">
      <Header variant="item">
        <Header.Title>Set effective date for changes</Header.Title>
        <Header.Description>
          {!isCommercial && permissionsWillBeRemoved && (
            <Text my="s-16" display="inline-block" color="red">
              {accessWillBeResetMessage}
            </Text>
          )}
          You can set a date from which the changes you have made will be made active.
          <br />
          By default today’s date is set as the effective date.
        </Header.Description>
      </Header>

      <LapeDatePickerInput
        name="effective_date_time"
        label="Effective date"
        message={`Take effect ${willTakeEffectIn}`}
        required
        {...datePickerProps}
      />

      {conflicts?.length !== 0 &&
        values.effective_date_time &&
        isFuture(new Date(values.effective_date_time)) && (
          <Box color="red" mt="s-16">
            There are already scheduled changes for the fields that are being changed.
            Please cancel those changes before scheduling another:
            <Link
              to={`${pathToUrl(ROUTES.FORMS.EMPLOYEE.CHANGELOG, {
                id: values.id,
              })}?id=${conflicts.map(d => d.id).join(',')}`}
              use={InternalLink}
              target="_blank"
              display="block"
            >
              View Conflicted Changes
            </Link>
          </Box>
        )}

      {conflicts?.length !== 0 &&
        (!values.effective_date_time || isPast(new Date(values.effective_date_time))) && (
          <Box color="red" mt="s-16">
            The are changes that intersect with your change, please review them! Clicking
            submit will overwrite all intersected changes in the past:
            <Link
              href={`${pathToUrl(ROUTES.FORMS.EMPLOYEE.CHANGELOG, {
                id: values.id,
              })}?id=${conflicts.map(d => d.id).join(',')}`}
              target="_blank"
              display="block"
            >
              View Conflicted Changes
            </Link>
          </Box>
        )}

      <Flex mt="s-16">
        <Button onClick={onClose} variant="secondary" mr="s-16" autoFocus>
          Cancel
        </Button>
        <Button
          onClick={onClick}
          pending={isSubmitting}
          disabled={
            !!conflicts?.length &&
            !!values.effective_date_time &&
            isFuture(new Date(values.effective_date_time))
          }
        >
          Save changes
        </Button>
      </Flex>
    </Popup>
  )
}

export default EffectiveDatePopup
