import React from 'react'
import { Button } from '@revolut/ui-kit'

import { PageActions } from '@src/components/Page/PageActions'
import { goBack } from '@src/actions/RouterActions'
import { ImportSessionInterface } from '@src/interfaces/bulkDataImport'

export interface BulkDataImportActionsProps {
  onSubmit: () => Promise<void>
  submitPending: boolean
  sessionData: ImportSessionInterface
  disableActions: boolean
  backUrl?: string
}

export const BulkDataImportActions = ({
  onSubmit,
  sessionData,
  disableActions,
  submitPending,
  backUrl,
}: BulkDataImportActionsProps) => {
  return (
    <PageActions>
      {sessionData.state.id === 'ready_for_review' ? (
        <Button
          onClick={onSubmit}
          pending={submitPending}
          disabled={disableActions}
          elevated
        >
          Upload data
        </Button>
      ) : (
        <Button onClick={() => goBack(backUrl)} elevated>
          Done
        </Button>
      )}
    </PageActions>
  )
}
