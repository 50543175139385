import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { hiringProcessSettings } from '@src/api/settings'
import HiringProcessGeneral from '@src/pages/Settings/HiringProcess/General'
import ScreenCallScorecards from '@src/pages/Settings/HiringProcess/ScreenCallScorecards'
import CountryHiring from '@src/pages/Settings/HiringProcess/CountryHiring/CountryHiring'

const routes = [
  {
    title: 'Hiring process settings',
    path: ROUTES.SETTINGS.HIRING_PROCESS.GENERAL,
    url: ROUTES.SETTINGS.HIRING_PROCESS.GENERAL,
    canView: [
      PermissionTypes.ViewHiringProcessPreferences,
      PermissionTypes.ChangeHiringProcessPreferences,
    ],
    component: HiringProcessGeneral,
  },
  {
    title: 'Screen call scorecards',
    path: ROUTES.SETTINGS.HIRING_PROCESS.SCREEN_CALL_SCORECARDS,
    url: ROUTES.SETTINGS.HIRING_PROCESS.SCREEN_CALL_SCORECARDS,
    canView: [
      PermissionTypes.ViewHiringProcessPreferences,
      PermissionTypes.ChangeHiringProcessPreferences,
    ],
    component: ScreenCallScorecards,
  },
  {
    title: 'Hiring countries',
    path: ROUTES.SETTINGS.HIRING_PROCESS.COUNTRY_HIRING,
    url: ROUTES.SETTINGS.HIRING_PROCESS.COUNTRY_HIRING,
    canView: [
      PermissionTypes.ViewHiringProcessPreferences,
      PermissionTypes.ChangeHiringProcessPreferences,
    ],
    component: CountryHiring,
  },
]

export const HiringProcessSettings = () => {
  return <SettingsForm routes={routes} api={hiringProcessSettings} />
}
