import { useSelector } from 'react-redux'
import React from 'react'
import { BottomNav, Box, SideNav } from '@revolut/ui-kit'
import { matchPath, NavLink, useLocation } from 'react-router-dom'
import isArray from 'lodash/isArray'

import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { UserCompanyAvatar } from '@src/features/UserAvatarWithMenu/UserCompanyAvatar'
import { useGetCompanyPreferences } from '@src/api/settings'
import { SideBarPinnedApps } from '@src/features/MainLayout/SideBar/SideBarPinnedApps'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { useMainSectionsConfig } from '@src/hooks/useMainSectionsConfig'
import { SideBarEvent } from '@src/features/MainLayout/SideBar/SideBarEvent'

const SideBar = () => {
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const location = useLocation()
  const { data: companyPreferences } = useGetCompanyPreferences()
  const showCompanyHeader = !!companyPreferences?.enable_branding
  const mainSectionsConfig = useMainSectionsConfig()

  const filteredSideBar = mainSectionsConfig.filter(section => {
    return isArray(section.canView)
      ? section.canView.some(permission => permissions.includes(permission))
      : section.canView
  })

  const selectedPath = filteredSideBar.find(
    tab =>
      !!matchPath(location.pathname, {
        path: tab.selectedPaths,
        exact: true,
      }),
  )

  const isPeopleRoute = selectedPath?.path?.[0] === ROUTES.PEOPLE.ANY
  const employeeProfileRoute = matchPath<{ id: string }>(location.pathname, {
    path: [ROUTES.FORMS.EMPLOYEE.ANY],
    exact: true,
  })
  const isOwnProfileView =
    isPeopleRoute && employeeProfileRoute?.params?.id === String(user.id)

  return (
    <>
      <Box hide="*-md" width={{ md: 72, xxl: 240 }} mb="-s-16">
        <SideNav collapseOn="xxl" collapsedLabelLocation="under">
          <SideNav.Header
            aria-selected={isOwnProfileView}
            onClick={() =>
              navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: user?.id }))
            }
          >
            <SideNav.Avatar css={{ position: 'relative' }}>
              <UserCompanyAvatar user={user} showCompanyLogo={showCompanyHeader} />
              <SideBarEvent />
            </SideNav.Avatar>
            {showCompanyHeader && companyPreferences?.brand_name ? (
              <>
                <SideNav.Title>{companyPreferences?.brand_name}</SideNav.Title>
                <SideNav.Description>{user.full_name}</SideNav.Description>
              </>
            ) : (
              <SideNav.Title>{user.full_name}</SideNav.Title>
            )}
          </SideNav.Header>
          <SideNav.Items>
            {filteredSideBar.map(sideBarItem => (
              <SideNav.Item
                // @ts-expect-error UI kit actually has this
                exact
                key={sideBarItem.name}
                data-testid={sideBarItem.name}
                to={sideBarItem.url || ''}
                use={NavLink}
                useIcon={sideBarItem.icon}
                aria-selected={
                  isPeopleRoute
                    ? !isOwnProfileView && selectedPath?.name === sideBarItem.name
                    : selectedPath?.name === sideBarItem.name
                }
              >
                {sideBarItem.name}
              </SideNav.Item>
            ))}
          </SideNav.Items>
          <SideBarPinnedApps />
        </SideNav>
      </Box>
      <BottomNav width="100%" hide="md-*">
        {filteredSideBar.map(sideBarItem => (
          <BottomNav.Item
            // @ts-expect-error UI kit actually has this
            exact
            key={sideBarItem.name}
            data-testid={sideBarItem.name}
            to={sideBarItem.url || ''}
            use={NavLink}
            useIcon={sideBarItem.icon}
            aria-label={sideBarItem.name}
            aria-selected={selectedPath?.name === sideBarItem.name}
          />
        ))}
      </BottomNav>
    </>
  )
}

export default SideBar
