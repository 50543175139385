import React from 'react'
import { format, getHours, isSameDay } from 'date-fns'
import { Icon, IconName, Token } from '@revolut/ui-kit'

import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import {
  EmployeeTimeOffRequestCalendar,
  EmployeeTimeOffRequestsCalendarInterface,
  PublicHolidayCalendarItem,
} from '@src/interfaces/timeOff'
import { AFTERNOON } from '@src/features/TimeOffCalendarTable/constants'

export enum PolicyCategoryId {
  Holiday = 1,
  Sick = 2,
  Compassionate = 3,
  TOIL = 4,
  Other = 5,
  Parental = 6,
  Maternity = 7,
  Paternity = 8,
}

export const approvalStatusIdToIconColor = (id: ApprovalStatuses | undefined) => {
  switch (id) {
    case ApprovalStatuses.Approved:
      return Token.color.green
    case ApprovalStatuses.Pending:
      return Token.color.orange
    case ApprovalStatuses.Rejected:
    case ApprovalStatuses.Cancelled:
      return Token.color.red
    default:
      return Token.color.greyTone20
  }
}

export const policyCategoryToIcon = (category?: PolicyCategoryId): IconName => {
  switch (category) {
    case PolicyCategoryId.Holiday:
      return 'Resort'
    case PolicyCategoryId.Sick:
      return 'MedicalBed'
    case PolicyCategoryId.Compassionate:
      return 'Heart'
    case PolicyCategoryId.TOIL:
      return 'Resort'
    case PolicyCategoryId.Other:
      return 'Premium'
    case PolicyCategoryId.Parental:
    case PolicyCategoryId.Maternity:
    case PolicyCategoryId.Paternity:
      return 'Children'
    default:
      return 'Activities'
  }
}

export const requestToIcon = ({
  request,
  isNonWorking,
  publicHoliday,
  canSeePolicy,
}: {
  request?: EmployeeTimeOffRequestCalendar | undefined
  isNonWorking?: boolean
  publicHoliday?: PublicHolidayCalendarItem
  canSeePolicy?: boolean
}) => {
  const nonWorking = isNonWorking || !!publicHoliday
  const color = nonWorking
    ? Token.color.greyTone20
    : approvalStatusIdToIconColor(request?.approval_status?.id)
  const iconProps = { color, size: 16 }

  if (!nonWorking && !request?.id) {
    return null
  }
  if (isNonWorking) {
    return <Icon name="CrossSmall" {...iconProps} />
  }
  if (publicHoliday) {
    return <Icon name="Bank" {...iconProps} />
  }
  if (request?.id && !canSeePolicy) {
    return <Icon name="16/Canceled" {...iconProps} />
  }
  return <Icon name={policyCategoryToIcon(request?.category.id)} {...iconProps} />
}

const approvalStatusIdToBorderColor = (id: ApprovalStatuses | undefined) => {
  switch (id) {
    case ApprovalStatuses.Approved:
      return Token.color.green_20
    case ApprovalStatuses.Pending:
      return Token.color.orange
    case ApprovalStatuses.Rejected:
    case ApprovalStatuses.Cancelled:
      return Token.color.red_20
    default:
      return Token.color.greyTone8
  }
}

const approvalStatusIdToBgColor = (id: ApprovalStatuses | undefined) => {
  switch (id) {
    case ApprovalStatuses.Approved:
      return Token.color.green_20
    case ApprovalStatuses.Pending:
      return Token.color.background
    case ApprovalStatuses.Rejected:
    case ApprovalStatuses.Cancelled:
      return Token.color.red_20
    default:
      return Token.color.background
  }
}

export const defaultBoxProps = {
  borderRadius: '10px',
  borderWidth: 1,
  borderStyle: 'solid',
  alignItems: 'center',
  justifyContent: 'center',
  bg: Token.color.widgetBackground,
  borderColor: Token.color.greyTone8,
}

export const getCellBoxProps = ({
  request,
  isNonWorking,
  publicHoliday,
}: {
  request?: EmployeeTimeOffRequestCalendar | undefined
  isNonWorking?: boolean
  publicHoliday?: PublicHolidayCalendarItem
  canSeePolicy?: boolean
}) => {
  if (!request && !isNonWorking && !publicHoliday) {
    return defaultBoxProps
  }
  const approvalId = request?.approval_status?.id
  const nonWorking = isNonWorking || !!publicHoliday
  const nonPendingApproval = approvalId && approvalId !== ApprovalStatuses.Pending
  const noBorder = nonWorking || nonPendingApproval

  let borderWidth = defaultBoxProps.borderWidth

  if (noBorder) {
    borderWidth = 0
  }
  return {
    ...defaultBoxProps,
    borderWidth,
    borderColor: approvalStatusIdToBorderColor(approvalId),
    bg: nonWorking ? Token.color.greyTone5 : approvalStatusIdToBgColor(approvalId),
  }
}

export const getRequestDayInfo = (request?: EmployeeTimeOffRequestCalendar) => {
  if (!request) {
    return undefined
  }

  switch (request.unit.id) {
    case 'day': {
      return {
        isAllDay:
          request?.from_time_period?.id === 'all_day' &&
          request?.to_time_period?.id === 'all_day',
        isMorning:
          request.from_time_period?.id === 'morning' ||
          request.to_time_period?.id === 'morning',
        isAfternoon:
          request.from_time_period?.id === 'afternoon' ||
          request.to_time_period?.id === 'afternoon',
      }
    }
    case 'hour': {
      return {
        isAllDay:
          getHours(new Date(request.to_date_time)) -
            getHours(new Date(request.from_date_time)) >=
          8,
        isMorning:
          getHours(new Date(request.to_date_time)) < AFTERNOON ||
          getHours(new Date(request.to_date_time)) - AFTERNOON <=
            AFTERNOON - getHours(new Date(request.from_date_time)),
        isAfternoon:
          getHours(new Date(request.from_date_time)) > AFTERNOON ||
          AFTERNOON - getHours(new Date(request.from_date_time)) <
            getHours(new Date(request.to_date_time)) - AFTERNOON,
      }
    }
    default:
      return undefined
  }
}

export const getRequestDayTextPeriod = (request?: EmployeeTimeOffRequestCalendar) => {
  if (!request) {
    return ''
  }
  if (request.unit.id === 'hour') {
    return `${format(new Date(request.from_date_time), 'kk:mm')}-${format(
      new Date(request.to_date_time),
      'kk:mm',
    )}`
  }

  const requestDayInfo = getRequestDayInfo(request)

  if (requestDayInfo?.isAllDay) {
    return 'All day'
  }
  if (requestDayInfo?.isMorning) {
    return 'Morning'
  }
  if (requestDayInfo?.isAfternoon) {
    return 'Afternoon'
  }
  return ''
}

export const getTimeOffCalendarInfoByDay = (
  requestsCalendar: EmployeeTimeOffRequestsCalendarInterface,
  day: Date,
) => {
  const request = requestsCalendar.time_off_requests.find(timeOffRequest =>
    isSameDay(day, new Date(`${timeOffRequest.day}T00:00:00`)),
  )
  const publicHoliday = requestsCalendar.public_holidays.find(holiday =>
    isSameDay(day, new Date(`${holiday.day}T00:00:00`)),
  )
  const isNonWorking = requestsCalendar.non_working_days.some(nonWorkingDay =>
    isSameDay(day, new Date(`${nonWorkingDay}T00:00:00`)),
  )

  return { request, isNonWorking, publicHoliday }
}
