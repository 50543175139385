import React from 'react'
import pluralize from 'pluralize'

import { MoreBar, TableWidget } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { ServiceDeskCategoryInterface } from '@src/interfaces/faq'
import { TableNames } from '@src/constants/table'
import { serviceDeskCategoryTableRequests } from '@src/api/faq'
import { useTable } from '@src/components/Table/hooks'
import Stat from '@components/Stat/Stat'
import {
  sdCategoryNameColumn,
  sdCategoryLinkColumn,
  sdCategoryFaqCategoryNameColumn,
  sdCategorySubcategoriesCountColumn,
} from '@src/constants/columns/faqSDCategories'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@components/InternalLink/InternalLink'

const ROW: RowInterface<ServiceDeskCategoryInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.APPS.HELP_CENTER.SD_CATEGORY.PREVIEW, { id })),
  cells: [
    {
      ...sdCategoryNameColumn,
      width: 200,
    },
    {
      ...sdCategorySubcategoriesCountColumn,
      width: 50,
    },
    {
      ...sdCategoryFaqCategoryNameColumn,
      width: 200,
    },
    {
      ...sdCategoryLinkColumn,
      width: 200,
    },
  ],
}

export const FaqSDCategoriesTable = () => {
  const table = useTable<ServiceDeskCategoryInterface>(serviceDeskCategoryTableRequests)

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat
          label={pluralize('Category', table.count)}
          val={table.loading ? undefined : table.count}
        />
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          <MoreBar.Action
            useIcon={Plus}
            use={InternalLink}
            to={pathToUrl(ROUTES.APPS.HELP_CENTER.SD_CATEGORY.EDIT)}
          >
            Add category
          </MoreBar.Action>
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.FaqSDCategories}
          useWindowScroll
          row={ROW}
          {...table}
          dataType="Category"
          noDataMessage="All categories will appear here"
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
