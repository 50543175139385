import React from 'react'
import { formatDistanceToNow, isFuture, isPast } from 'date-fns'
import { Text } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { CheckpointType, ProbationCheckpoint } from '@src/interfaces/probationReview'
import { getDateDistance } from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { ReviewCategory } from '@src/interfaces/performance'

export const getStepState = (startDate: string, endDate: string) => {
  if (isFuture(new Date(startDate))) {
    return 'default'
  }

  if (isPast(new Date(endDate))) {
    return 'done'
  }

  return 'pending'
}

export const getStepDescription = (checkpoint: ProbationCheckpoint) => {
  if (isPast(new Date(checkpoint.checkpoint_deadline))) {
    return (
      <Text>{`Closed ${formatDate(
        checkpoint.checkpoint_deadline,
        'd MMMM yyyy',
      )} - ${formatDistanceToNow(new Date(checkpoint.checkpoint_deadline))} ago`}</Text>
    )
  }

  if (isFuture(new Date(checkpoint.checkpoint_date_time))) {
    return (
      <Text>
        {`Opens ${formatDate(checkpoint.checkpoint_date_time, 'd MMMM yyyy')} - `}
        {getDateDistance(checkpoint.checkpoint_date_time)}
      </Text>
    )
  }

  return <>-</>
}

const num = ['First', 'Second', 'Third']

export const getStepTitle = (
  checkpoint: ProbationCheckpoint,
  category: ReviewCategory,
) => {
  if (checkpoint?.checkpoint_type === CheckpointType.Goals) {
    return `${category === ReviewCategory.Probation ? 'Probation' : 'PIP'} goal setting`
  }
  if (checkpoint?.checkpoint_type === CheckpointType.Review) {
    const checkpointNum = num[checkpoint.checkpoint_number - 1]
    return checkpointNum
      ? `${checkpointNum} checkpoint`
      : `Checkpoint ${checkpoint.checkpoint_number}`
  }
  if (checkpoint?.checkpoint_type === CheckpointType.Recommendation) {
    return 'Manager recommendation'
  }
  return 'Committee result'
}
