import React, { useState } from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { chain, HStack, MoreBar, VStack, Text } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import Candidates from '@src/pages/Forms/HiringPipelineOverview/Candidates'
import TextWithMoreCount from '@components/TextWithMoreCount/TextWithMoreCount'
import upperFirst from 'lodash/upperFirst'
import Requisitions from '@src/pages/Forms/HiringPipelineOverview/Requisitions'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Upload } from '@revolut/icons'
import HiringPipelineShareAccess from '@src/pages/Forms/HiringPipelineOverview/HiringPipelineShareAccess'
import { useGetHiringPipeline } from '@src/api/recruitment/hiringPipelines'
import { getJobPostingStatusColor } from '@src/pages/Forms/JobPosting/utils'
import { useTheme } from '@src/styles/theme'
import { useGetRequisitionSettings } from '@src/api/settings'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const HiringPipelineOverviewPage = () => {
  const theme = useTheme()
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useGetHiringPipeline(id)
  const [shareOpened, setShareOpened] = useState(false)

  const { data: requisitionSettings } = useGetRequisitionSettings()

  const tabs = [
    {
      path: ROUTES.FORMS.HIRING_PIPELINE_OVERVIEW.CANDIDATES,
      to: pathToUrl(ROUTES.FORMS.HIRING_PIPELINE_OVERVIEW.CANDIDATES, { id }),
      title: 'Candidates',
      component: Candidates,
      canView: true,
    },
    {
      path: ROUTES.FORMS.HIRING_PIPELINE_OVERVIEW.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.HIRING_PIPELINE_OVERVIEW.REQUISITIONS, {
        id,
      }),
      title: 'Requisitions',
      component: Requisitions,
      canView: requisitionSettings?.enabled,
    },
  ].filter(i => i.canView)

  return (
    <PageWrapper>
      <PageHeader
        title={chain(
          data?.job_posting?.name,
          data?.job_posting?.status_display ? (
            <Text color={getJobPostingStatusColor(data.job_posting.status, theme)}>
              {upperFirst(data.job_posting.status_display)}
            </Text>
          ) : undefined,
        )}
        backUrl={pathToUrl(ROUTES.RECRUITMENT.HIRING_PIPELINES.MY)}
        subtitle={
          data?.job_posting?.recruiter && (
            <HStack align="center">
              {chain(
                <EmployeeUserWithAvatar id={data.job_posting.recruiter.id} />,
                'Recruiter',
              )}
            </HStack>
          )
        }
        isLoading={isLoading}
      >
        <VStack gap="s-16">
          <TextWithMoreCount
            items={data?.job_posting?.locations?.map(item => item.name) || []}
            expandable
            variant="primary"
            visibleCount={5}
          />
          <TabBarNavigation tabs={tabs} />
          <MoreBar>
            <MoreBar.Action useIcon={Upload} onClick={() => setShareOpened(true)}>
              Share access
            </MoreBar.Action>
          </MoreBar>
        </VStack>
      </PageHeader>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component data={data} />
          </Route>
        ))}
        <InternalRedirect to={tabs[0].path} />
      </Switch>
      <HiringPipelineShareAccess
        id={id}
        isOpen={shareOpened}
        onClose={() => setShareOpened(false)}
      />
    </PageWrapper>
  )
}

export default connect(HiringPipelineOverviewPage)
