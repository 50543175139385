import { CommunicationGroupInterface } from './communicationGroup'
import { CulturePercentile } from './culture'
import { EmployeeOptionInterface } from './employees'
import { FieldOptions } from './index'

export enum FunctionType {
  generic = 'generic',
  executive = 'executive',
}

export interface FunctionInterface {
  id: number
  name: string
  function_type?: FunctionType
  owner?: EmployeeOptionInterface
  documentation_link: string
  headcount: number
  nips?: number
  playbook_count: number
  requisition_headcount: number
  roadmap_progress_percent: number
  kpi_performance_percent: number
  passing_probation_percent: number
  role_count: number
  specialisation_count: number
  budget_currency: string
  total_current_spent: number
  field_options: FieldOptions
  kpi_weight_mode: {
    id: string
    name: string
  }
  culture_enps: number | null
  culture_values: number | null
  culture_compliance: number | null
  culture_compliance_percentile: CulturePercentile | null
  culture_enps_percentile: CulturePercentile | null
  culture_values_percentile: CulturePercentile | null
  culture_manager_enps: number | null
  culture_job_satisfaction: number | null
  communication_group: CommunicationGroupInterface | null
  linked_communication_groups: CommunicationGroupInterface[] | null
  executive?: boolean // only FE field. BE ignores it
  average_score?: number | null
}

export interface FunctionOptionInterface {
  id: number
  name: string
  status?: string
  function_type?: FunctionType
}

export interface TalentStatsInterface {
  above_expectations_percentage: number
  below_expectations_percentage: number
  exceptional_percentage: number
  meets_expectations_percentage: number
  missing_employee_count: number
  neutral_percentage: number
  unsatisfactory_and_below_expectations_percentage: number
  nips: number
  above_expectations_and_exceptional_percentage: number
  unsatisfactory_percentage: number
}
