import React, { useMemo, useEffect } from 'react'
import { useGetSelectors } from '@src/api/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { Cell, Color, InputGroup, Text } from '@revolut/ui-kit'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { DepartmentInterface } from '@src/interfaces/deparment'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { useGetOrganisationSettings, useGetRoadmapSettings } from '@src/api/settings'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { SectionOptions } from '@src/interfaces/customFields'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

const General = () => {
  const { values } = useLapeContext<DepartmentInterface>()
  const currentUser = useSelector(selectUser)

  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: jiraProjects = [] } = useGetSelectors<IdAndName<number>>(
    roadmapSettings?.jira_epics_enabled ? selectorKeys.jira_projects : null,
  )

  const jiraProjectsOptions = useMemo(
    () =>
      jiraProjects.map(project => ({
        label: project.name,
        value: { id: project.name },
      })),
    [jiraProjects],
  )

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: currentUser.id }
    }
  }, [])

  const { data: settings } = useGetOrganisationSettings()
  const viewSalariesEnabled = !!settings?.enable_team_owners_view_salaries

  return (
    <>
      <PageBody>
        <NewStepperSectionCustomFields
          sectionId={SectionOptions.Departments}
          departmentId={values.id}
        >
          <NewStepperTitle title="General Information" />
          <InputGroup>
            <LapeNewInput label="Name" name="name" required />
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employees_for_teams}
            />
            <LapeRadioSelectInput
              name="kpi_committee"
              label="KPI Committee"
              selector={selectorKeys.employees_for_teams}
            />
            <LapeNewInput
              label="Performance dashboard link"
              name="performance_dashboard_link"
            />
          </InputGroup>
          <NewStepperTitle
            title="Mission"
            subtitle="A short summary explaining the goals and the scope of the department."
          />
          <InputGroup>
            <LapeNewTextArea label="Mission" name="mission" required rows={3} />
          </InputGroup>
          {viewSalariesEnabled ? (
            <>
              <NewStepperTitle title="Advanced settings" />
              <LapeNewSwitch
                name="settings.team_owners_can_view_salaries"
                itemTypeProps={{ title: 'Team owners can view salaries' }}
              />
            </>
          ) : null}
          {roadmapSettings?.jira_epics_enabled ? (
            <>
              <NewStepperTitle title="Integrations" />
              <InputGroup>
                <Cell flexDirection="column" p="s-12">
                  <Text alignSelf="flex-start" variant="h6">
                    JIRA Roadmaps
                  </Text>
                  <Text
                    alignSelf="flex-start"
                    color={Color.GREY_TONE_50}
                    variant="caption"
                  >
                    You can add any JIRA project keys which you are a member of and plan
                    to select from when adding roadmaps or creating a KPI. Epics from
                    those projects will then be available to you.
                  </Text>
                </Cell>
                <LapeNewMultiSelect<{ id: string }>
                  name="jira_projects"
                  message="These are the Jira projects you will be able to select from"
                  onChange={options => {
                    values.jira_projects = options.map(option => option.value.id)
                  }}
                  options={jiraProjectsOptions}
                  placeholder="Jira Projects for Roadmaps"
                  value={
                    values.jira_projects?.map(project => ({
                      label: project,
                      value: { id: project },
                    })) || []
                  }
                />
              </InputGroup>
            </>
          ) : null}
        </NewStepperSectionCustomFields>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          previewUrl={ROUTES.FORMS.DEPARTMENT.SUMMARY}
        />
      </PageActions>
    </>
  )
}

export default General
