import React from 'react'
import { useTable } from '@components/Table/hooks'
import { kpisRulesRequests } from '@src/api/kpiRules'
import { RowInterface, SortByInterface } from '@src/interfaces/data'
import { KPIRuleInterface } from '@src/interfaces/KpiTemplates'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ownerFullNameColumn } from '@src/constants/columns/employee'
import { updatedAtColumn } from '@src/constants/columns/dates'
import {
  kpiRuleApplicationColumn,
  kpiRuleDescriptionColumn,
  kpiRuleNameColumn,
  kpiRuleTemplateColumn,
} from '@src/constants/columns/kpi'
import { Flex, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const Rows: RowInterface<KPIRuleInterface> = {
  linkToForm: d => navigateTo(pathToUrl(ROUTES.FORMS.KPI_RULE.GENERAL, { id: d.id })),
  cells: [
    {
      ...kpiRuleNameColumn,
      width: 250,
    },
    {
      ...kpiRuleDescriptionColumn,
      width: 500,
    },
    {
      ...kpiRuleTemplateColumn,
      width: 200,
    },
    {
      ...ownerFullNameColumn,
      title: 'Owner',
      width: 200,
    },
    {
      ...kpiRuleApplicationColumn,
      width: 140,
    },
    {
      ...updatedAtColumn,
      title: 'Updated',
      width: 150,
    },
  ],
}

const Rules = () => {
  const initialSortBy: SortByInterface[] = []
  const table = useTable<KPIRuleInterface>(kpisRulesRequests, [], initialSortBy)

  return (
    <TableWrapper>
      <Flex mb="s-16">
        <MoreBar>
          <MoreBar.Action
            useIcon={Plus}
            onClick={() => {
              navigateTo(pathToUrl(ROUTES.FORMS.KPI_RULE.GENERAL))
            }}
          >
            Add New Rule
          </MoreBar.Action>
        </MoreBar>
      </Flex>
      <AdjustableTable<KPIRuleInterface>
        name={TableNames.KpiTemplatesRules}
        row={Rows}
        useWindowScroll
        dataType="KPI rule"
        {...table}
        noDataMessage="KPI rules will appear here."
      />
    </TableWrapper>
  )
}

export default Rules
