import { RequestInterfaceNew, TableRequestInterface } from '@src/interfaces'
import { CRMInterface, SendEngagementEmailInterface } from '@src/interfaces/CRM'
import { API } from '@src/constants/api'
import { api, apiWithoutHandling } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { AxiosResponse } from 'axios'
import { FileInterface } from '@src/interfaces/files'
import { isFile } from '@src/utils/files'
import { uploadFile } from '@src/api/files'

export const crmTableRequests: TableRequestInterface<CRMInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.CRM, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

const uploadAttachments = async (data: Partial<SendEngagementEmailInterface>) => {
  let files: AxiosResponse<FileInterface>[] | undefined
  if (data.attachments) {
    files = await Promise.all(
      data.attachments.map(file =>
        isFile(file)
          ? uploadFile(file, 'candidate_email')
          : new Promise<AxiosResponse<FileInterface>>(resolve => {
              resolve({ data: file } as AxiosResponse<FileInterface>)
            }),
      ),
    )
  }
  return files
}

export const bulkEngagementEmailsRequests: RequestInterfaceNew<SendEngagementEmailInterface> =
  {
    get: async ({ id }) => api.get(`/interviews/candidates/${id}/emails`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`/interviews/candidates/${id}/emails`, data),
    submit: async ({ add_booking_link, calendar, ...data }) => {
      const files = await uploadAttachments(data)
      // if there's no booking link the flow is the same as the candidate bulk emails
      const endpoint = add_booking_link
        ? '/candidateBulkScheduling'
        : '/candidateBulkEmails'
      const commonPayload = {
        ...data,
        attachments:
          files?.map(file => ({
            id: file?.data?.id,
          })) || [],
      }
      const payload = add_booking_link
        ? {
            ...commonPayload,
            calendar_id: calendar?.calendar_id,
            calendar_name: calendar?.summary,
          }
        : commonPayload
      return apiWithoutHandling.post<SendEngagementEmailInterface>(
        endpoint,
        payload as SendEngagementEmailInterface,
      )
    },
  }
