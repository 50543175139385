import { API } from '@src/constants/api'
import { RequestInterfaceNew, tableRequests } from '@src/interfaces'
import { api, apiWithoutHandling } from '.'
import { ReferCandidateInterface } from '@src/interfaces/referCandidate'
import { FileInterface } from '@src/interfaces/files'
import { AxiosResponse } from 'axios'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { uploadFile } from '@src/api/files'
import { ApplicationDataInterface } from '@src/interfaces/applicationQuestions'

export const referCandidateRequests: RequestInterfaceNew<ReferCandidateInterface> = {
  get: async ({ id }) => api.get(`${API.INTERVIEWS}/referral/${id}`),
  update: async (data, { id }) => api.patch(`${API.INTERVIEWS}/referral/${id}`, data),
  submit: async data => {
    let file: AxiosResponse<FileInterface> | undefined
    if (data.resume) {
      file = await uploadFile(data.resume.id as File, 'candidate_resume')
    }

    return apiWithoutHandling
      .post(`${API.INTERVIEWS}/referral`, {
        ...data,
        links: data.links?.filter(item => item),
        resume: { id: file?.data?.id },
      })
      .catch(error => {
        if (file?.data?.id) {
          apiWithoutHandling.delete(`${API.FILES}/${file.data.id}`)
        }
        throw error
      })
  },
}

export const referCandidateTableRequests: tableRequests<
  ReferCandidateInterface,
  undefined
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.INTERVIEWS}/referral`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const getAdditionalQuestions = () =>
  api.get<ApplicationDataInterface>(`${API.INTERVIEWS}/referral/getAdditionalQuestions`)
