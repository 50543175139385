import { css, DefaultTheme } from 'styled-components'
import 'styled-components'
import { useTheme as useRevolutTheme, UnifiedTheme } from '@revolut/ui-kit'
import { colorGetter } from '@src/styles/colors'

declare module 'styled-components' {
  export interface DefaultTheme extends UnifiedTheme {
    zIndex: {
      belowMain: number
      main: number
      aboveMain: number
      sideBar: number
      sideHelp: number
      pageActions: number
      header: number
      tooltip: number
      popup: number
    }
    legacyColors: {
      darkRed: string
      hoverPurple: string
    }
  }
}

export interface RevolutTheme extends DefaultTheme, UnifiedTheme {}

const CSSWithTheme = css`
  color: ${colorGetter('primary')};
`
const CSSSimple = css``
export type CssPropType = typeof CSSWithTheme | typeof CSSSimple

export const defaultTheme: RevolutTheme = {
  ...UnifiedTheme,
  legacyColors: {
    hoverPurple: '#e42c91',
    darkRed: '#d32f2f',
  },
  zIndex: {
    belowMain: 0,
    main: 1,
    aboveMain: 10,
    sideHelp: 15,
    sideBar: 100,
    pageActions: 99,
    header: 100,
    tooltip: 200,
    popup: 100000,
  },
}

export const useTheme = (): RevolutTheme => useRevolutTheme<RevolutTheme>()
