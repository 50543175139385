import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import { colorGetter, getColor } from '@src/styles/colors'
import { Color, Token } from '@revolut/ui-kit'

interface SwitchProps {
  /** Flag for switching on/off the toggle */
  checked: boolean
  /** Callback for when you click the switch */
  onChange: MouseEventHandler
  className?: string
  /** Label of the switch placed to the right */
  label?: string
  disabled?: boolean
}

const Container = styled.label<{ disabled?: boolean }>`
  position: relative;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
`
const Switcher = styled.span<{ checked: boolean }>`
  width: 40px;
  height: 24px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: ${props => (props.checked ? 'flex-end' : 'flex-start')};
  transition: all 300ms;
  background: ${props =>
    props.checked ? props.theme.colors.blue : getColor(props.theme, Color.GREY_TONE_50)};
  border-radius: 32px;
`
const Circle = styled.span<{ checked: boolean }>`
  transition: all 300ms;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${Token.color.widgetBackground};
  position: absolute;
  top: 0;
  left: ${props => (props.checked ? '18px' : '2px')};
  bottom: 0;
  margin: auto;
`

export const Label = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${colorGetter(Color.GREY_TONE_50)};
  margin-left: 16px;
`

const Input = styled.input`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
`

export const Switch = ({
  checked,
  onChange,
  className,
  label,
  disabled,
}: SwitchProps) => {
  return (
    <Container className={className} disabled={disabled}>
      <Switcher checked={checked}>
        <Circle checked={checked} />
      </Switcher>
      <Input
        disabled={disabled}
        type="checkbox"
        checked={checked}
        onClick={onChange}
        readOnly
      />
      {label && <Label>{label}</Label>}
    </Container>
  )
}
