import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  HStack,
  Portal,
  Side,
  Spacer,
  Subheader,
  StatusPopup,
  Switch,
  Text,
  Widget,
} from '@revolut/ui-kit'
import SideBar from '@src/components/SideBar/SideBar'
import SendEmailCommon from '@src/pages/Forms/SendEmail/SendEmailCommon'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import { sendOffer } from '@src/api/offerCreation'
import { candidateEmailsRequests } from '@src/api/hiringProcess'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import useGetPrefilledPlaceholdersEmail from '@src/pages/Forms/SendEmail/useGetPrefilledPlaceholdersEmail'

type Props = {
  onRefresh: () => void
}

const SendOfferAndEmailSidebar = ({ onRefresh }: Props) => {
  const { candidateId, id: offerId } = useParams<{ candidateId: string; id: string }>()
  const { submit } = useLapeContext<SendCandidateEmailInterface>()
  const [sendEmail, setSendEmail] = useState(true)
  const [loading, setLoading] = useState(false)
  const [offerSuccess, setOfferSuccess] = useState<boolean | null>(null)
  const [emailSuccess, setEmailSuccess] = useState<boolean | null>(null)

  const { loading: loadingFetchEmail, fetchEmail: getPrefilledPlaceholdersEmail } =
    useGetPrefilledPlaceholdersEmail()

  const fetchEmail = (templateId: number) => {
    return getPrefilledPlaceholdersEmail(templateId, +candidateId)
  }

  const onSend = async () => {
    setLoading(true)
    try {
      await sendOffer(+offerId)
      if (sendEmail) {
        try {
          await submit()
          setEmailSuccess(true)
        } catch (e) {
          setEmailSuccess(false)
        }
      } else {
        setOfferSuccess(true)
      }
    } catch (e) {
      setOfferSuccess(false)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    onRefresh()
    setOfferSuccess(null)
    setEmailSuccess(null)
  }

  return (
    <>
      <SideBar
        variant="wide"
        title="Send offer & email to candidate"
        subtitle="Offer will be sent via DocuSign in an outstanding email. You can send an additional email to the candidate and congratulate them."
        onClose={onClose}
      >
        <Box pt="-s-16">
          <Subheader variant="nested">
            <Subheader.Title>Additional email</Subheader.Title>
          </Subheader>
        </Box>
        <Widget p="s-16">
          <HStack use="label">
            <Text>Do you want to send an additional email to candidate?</Text>
            <Spacer />
            <Switch
              value="showEmailBody"
              checked={sendEmail}
              onChange={() => setSendEmail(!sendEmail)}
            />
          </HStack>
        </Widget>
        {sendEmail && (
          <Box pt="s-16">
            <SendEmailCommon fetchEmail={fetchEmail} insideSidebar actions={null} />
          </Box>
        )}
        <Side.Actions horizontal>
          <Button onClick={onSend} disabled={loading || loadingFetchEmail}>
            Send
          </Button>
        </Side.Actions>
      </SideBar>
      <Portal>
        <StatusPopup
          variant="success"
          open={offerSuccess || emailSuccess}
          onClose={onClose}
          // @ts-ignore
          labelButtonClose="Close success popup"
        >
          <StatusPopup.Title>
            {sendEmail
              ? 'Your email and offer are sent to the candidate'
              : 'Offer sent to candidate via docusign'}
          </StatusPopup.Title>
        </StatusPopup>
        <StatusPopup
          variant="error"
          open={offerSuccess === false}
          onClose={onClose}
          // @ts-ignore
          labelButtonClose="Close error popup"
        >
          <StatusPopup.Title>Something went wrong</StatusPopup.Title>
          <StatusPopup.Description>
            Please refresh the page and try again
          </StatusPopup.Description>
        </StatusPopup>
        <StatusPopup
          variant="error"
          open={emailSuccess === false}
          onClose={onClose}
          // @ts-ignore
          labelButtonClose="Close error popup"
        >
          <StatusPopup.Title>Something went wrong</StatusPopup.Title>
          <StatusPopup.Description>
            Offer was sent but something went wrong sending email
          </StatusPopup.Description>
          <StatusPopup.Actions>
            <Button onClick={onClose}>Return to main page</Button>
            <Button
              variant="secondary"
              onClick={() => {
                navigateTo(
                  pathToUrl(ROUTES.FORMS.CANDIDATE.EMAILS, {
                    id: candidateId,
                  }),
                )
              }}
            >
              Navigate to send email
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>
      </Portal>
    </>
  )
}

const SendOfferAndEmailSidebarForm = ({ onRefresh }: Props) => {
  const { candidateId } = useParams<{ candidateId: string }>()
  return (
    <Form
      api={candidateEmailsRequests}
      forceParams={{
        new: 'new',
        candidateId,
      }}
    >
      <SendOfferAndEmailSidebar onRefresh={onRefresh} />
    </Form>
  )
}

export default SendOfferAndEmailSidebarForm
