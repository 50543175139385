import React from 'react'
import { Item, Spacer, Text } from '@revolut/ui-kit'

const CareersPageIntro = () => {
  return (
    <Item>
      <Item.Content>
        <Item.Title>What is Careers page?</Item.Title>
        <Item.Description>
          <Text use="p">
            The Careers Page, facilitated by the{' '}
            <Text fontWeight={500}>Revolut People Recruitment</Text>, is a primary place
            for showcasing Job Postings. For many potential candidates, it's their first
            introduction to your company, offering insights into the your culture, values
            and current vacancies.
          </Text>
          <Spacer height={16} />
          <Text use="p">
            The Careers Page is a dedicated page provided by the Revolut People platform
            to post Job Postings. It is often the first point of contact for prospective
            employees, providing them with key insights into the company's culture, and
            current job openings.
          </Text>
          <Spacer height={16} />
          <Text use="p">
            Our Careers Page does more than just list jobs. After you finalise the initial
            setup, it will be able to showcase the company's ethos, values and benefits.
            It can also provide a glimpse into your company through a well managed Job
            Postings content and list of relevant links.
          </Text>
          <Spacer height={16} />
          <Text use="p">
            For potential applicants the page offers the ability to easily filter, browse,
            preview, and apply for job postings. It's designed to be practical and
            user-centric, allowing candidates to easily find positions that match their
            skills, experience, and career aspirations.
          </Text>
          <Spacer height={16} />
          <Text use="p">
            In the following steps you will define what content will be present to
            candidates on your dedicated Careers Page.
          </Text>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

export default CareersPageIntro
