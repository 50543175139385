import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import {
  Box,
  Color,
  InputGroup,
  SelectOptionItemType,
  Text,
  VStack,
} from '@revolut/ui-kit'
import {
  ReviewCyclesInterface,
  ReviewCyclesPreviewInterface,
} from '@src/interfaces/reviewCycles'
import {
  reviewCyclesRequestsNew,
  useGetReviewCycleTemplates,
} from '@src/api/reviewCycles'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import Form from '@src/features/Form/Form'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router'
import { formatPeriod } from '@src/utils/format'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { navigateReplace } from '@src/actions/RouterActions'

export const getFormTitle = (values: ReviewCyclesInterface) => {
  return values.id ? (
    <VStack space="s-8">
      <Text>Edit Review Cycle</Text>
      <Text variant="caption" color="grey-tone-50">
        {values.name}{' '}
        {values.start_date_time && values.end_date_time
          ? `(${formatPeriod(values.start_date_time, values.end_date_time)})`
          : ''}
      </Text>
    </VStack>
  ) : (
    'New Review Cycle'
  )
}

const GeneralInfo = connect(() => {
  const { values, initialValues } = useLapeContext<ReviewCyclesInterface>()
  const params = useParams<{ id: string }>()
  const { data: cycleTemplates } = useGetReviewCycleTemplates()
  const eligibleCycles = cycleTemplates?.map(cycle => {
    return {
      value: { ...cycle },
      label: cycle.name,
    }
  })
  const filteredCycles = eligibleCycles?.filter(cycle => cycle.value.enabled)
  const [selectedCycle, setSelectedCycle] = useState<ReviewCyclesPreviewInterface>()

  useEffect(() => {
    const defaultCycle = filteredCycles?.[0] ? filteredCycles[0] : eligibleCycles?.[0]
    if (!values.id && defaultCycle) {
      setSelectedCycle(defaultCycle.value)
      values.name = defaultCycle.value.name
      values.template_id = defaultCycle.value.template_id
      values.start_date_time = defaultCycle.value.start_date
      values.end_date_time = defaultCycle.value.end_date
    }
  }, [cycleTemplates])

  const renderOption = (item: SelectOptionItemType<ReviewCyclesPreviewInterface>) => {
    return (
      <Box color={item.value.enabled ? 'auto' : Color.BLACK_OPAQUE_40}>
        {item.value.name}
      </Box>
    )
  }

  return (
    <PageWrapper>
      <PageHeader
        title={
          values.id ? (
            <VStack space="s-8">
              <Text>Edit Review Cycle</Text>
              <Text variant="caption" color="grey-tone-50">
                {initialValues.name}{' '}
                {initialValues.start_date_time && initialValues.end_date_time
                  ? `(${formatPeriod(
                      initialValues.start_date_time,
                      initialValues.end_date_time,
                    )})`
                  : ''}
              </Text>
            </VStack>
          ) : (
            <VStack space="s-8">
              <Text>New Review Cycle</Text>
              <Text variant="caption" color="grey-tone-50">
                Create a new performance review cycle
              </Text>
            </VStack>
          )
        }
        backUrl={
          values.id
            ? pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, params)
            : ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES
        }
      />

      <>
        <PageBody>
          {values.id && (
            <VStack space="s-4" mb="s-32">
              <Text variant="primary">General Info</Text>
              <Text variant="caption" color="grey-tone-50">
                When should this cycle start and how long does it last?
              </Text>
            </VStack>
          )}
          <InputGroup>
            {values.id ? (
              <LapeNewInput required name="name" label="Cycle name" />
            ) : (
              <InputGroup variant="horizontal">
                {eligibleCycles && (
                  <RadioSelectInput<ReviewCyclesPreviewInterface>
                    label="Which cycle do you want to create?"
                    options={eligibleCycles}
                    value={selectedCycle}
                    onChange={cycle => {
                      if (cycle) {
                        setSelectedCycle(cycle)
                        values.name = cycle.name
                        values.template_id = cycle.template_id
                        values.start_date_time = cycle.start_date
                        values.end_date_time = cycle.end_date
                      }
                    }}
                  >
                    {renderOption}
                  </RadioSelectInput>
                )}
                <LapeNewInput required name="name" label="Cycle name" />
              </InputGroup>
            )}
            <InputGroup variant="horizontal">
              <LapeDatePickerInput
                required
                name="start_date_time"
                label="Cycle Start Date"
                disabled={!values.id}
              />
              <LapeDatePickerInput
                required
                name="end_date_time"
                label="Cycle End Date"
                disabled={!values.id}
              />
            </InputGroup>
          </InputGroup>
        </PageBody>
        <PageActions>
          <NewSaveButtonWithPopup
            onAfterSubmit={data => {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, { id: data.id }),
              )
            }}
            successText={
              values.id ? 'Performance cycle updated' : 'New performance cycle created'
            }
            useValidator
          >
            {values.id ? 'Save changes' : 'Create'}
          </NewSaveButtonWithPopup>
        </PageActions>
      </>
    </PageWrapper>
  )
})

const GeneralInfoForm = () => {
  return (
    <Form api={reviewCyclesRequestsNew} disableDataCleanup>
      <GeneralInfo />
    </Form>
  )
}

export default GeneralInfoForm
