import React from 'react'
import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'
import { CellTypes, RowInterface } from '@src/interfaces/data'
import get from 'lodash/get'
import upperFirst from 'lodash/upperFirst'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import isEmpty from 'lodash/isEmpty'
import { colorGetter } from '@src/styles/colors'
import { Box, Color, Ellipsis, Flex, Text, Token } from '@revolut/ui-kit'
import { useTheme } from '@src/styles/theme'
import Loader from '@components/CommonSC/Loader'
import { noScrollBar } from '@components/CommonSC/General'
import Tooltip from '@components/Tooltip/Tooltip'

interface Props<T> {
  rows: RowInterface<T>
  data: T[]
  hasScrollableRow?: boolean
  isLoading?: boolean
  maxHeight?: number | string
  idPath?: string
  noDataMessage?: string | null
}

const headerHeight = 22

export const Table = styled(Flex)<{ hasScrollableRow?: boolean }>`
  flex-direction: column;
  position: relative;
  border-collapse: collapse;
  max-height: 100%;
  min-height: 100%;
  ${props => (props.hasScrollableRow ? 'width: min-content;' : '')}
`

export const TableHeader = styled(Flex)`
  flex: 0 0 auto;
  background: ${Token.color.greyTone5};
  border-radius: 13px 13px 0 0;
  border: 1px solid ${Token.color.greyTone8};
`

export const HeaderCell = styled.div<{ width?: number }>`
  text-transform: uppercase;
  position: relative;
  text-align: left;
  font-size: 12px;
  line-height: ${headerHeight}px;
  white-space: nowrap;
  font-weight: 500;
  width: ${props => props.width}px;
  color: ${colorGetter(Color.GREY_TONE_50)};
  padding: 9px 0 9px 16px;
`

export const TableCell = styled.div<{ width?: number }>`
  position: relative;
  text-align: left;
  font-size: 12px;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: ${props => props.width}px;
  padding: 9px 0 9px 16px;
  align-content: center;
  display: flex;
  color: ${props => props.theme.colors.foreground};
`

export const TableBody = styled.div<{ maxHeight?: string }>`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 13px 13px;
  background: ${Token.color.widgetBackground};
  ${({ maxHeight }) =>
    maxHeight
      ? `
        overflow-y: auto; 
        max-height: calc(${maxHeight} - ${headerHeight}px);
      `
      : ''}
`
export const TableRow = styled.div<ColorProps>`
  display: flex;
  border-bottom: 1px solid ${Token.color.greyTone8};
  border-left: 1px solid ${Token.color.greyTone8};
  border-right: 1px solid ${Token.color.greyTone8};
  ${color}
`

const NewStaticTable = <T extends {}>({
  rows,
  data,
  hasScrollableRow,
  maxHeight,
  isLoading,
  idPath = 'id',
  noDataMessage = null,
}: Props<T>) => {
  const theme = useTheme()

  const renderRows = () =>
    data.map((target, rowIndex) => (
      <TableRow key={rowIndex}>
        {rows.cells.map(column => {
          let content = get(target, column.dataPoint)

          if (column.type === CellTypes.insert) {
            const dataPath = `[${rowIndex}].${column.dataPoint}`
            content = column.insert!({
              data: target,
              theme,
              children: content,
              dataPath,
              idPath,
              parentIndexes: [rowIndex],
            })
          }
          return (
            <TableCell
              width={column.width}
              key={column.title}
              data-testid={`${column.title}-${rowIndex}`}
            >
              <Text overflow="hidden">{isNil(content) ? '-' : content}</Text>
            </TableCell>
          )
        })}
      </TableRow>
    ))

  return (
    <Table hasScrollableRow={hasScrollableRow} css={noScrollBar}>
      <TableHeader>
        {rows.cells.map((column, i) => (
          <HeaderCell key={i} width={column.width}>
            <Tooltip
              placement="top"
              text={upperFirst(column.title)}
              hide={column.headerTooltip == null}
            >
              <Text width="100%" use="p">
                <Ellipsis>{column.title}</Ellipsis>
              </Text>
            </Tooltip>
          </HeaderCell>
        ))}
      </TableHeader>
      <TableBody
        css={noScrollBar}
        maxHeight={isNumber(maxHeight) ? `${maxHeight}px` : maxHeight}
      >
        {isLoading || isEmpty(data) ? (
          <Box py="s-32">
            {isLoading ? (
              <Loader />
            ) : (
              <Text use="p" textAlign="center">
                {noDataMessage}
              </Text>
            )}
          </Box>
        ) : (
          renderRows()
        )}
      </TableBody>
    </Table>
  )
}

export default NewStaticTable
