import React from 'react'
import { Flex } from '@revolut/ui-kit'

const COL_WIDTH = {
  all: 320,
  sm: 400,
}

type Props = {
  left: React.ReactNode
  right: React.ReactNode
  leftFlex?: number
  rightFlex?: number
}
export const TwoColumnsLayout = ({ left, right, leftFlex = 1, rightFlex = 1 }: Props) => {
  return (
    <Flex gap="s-16" flexWrap="wrap">
      <Flex flex={leftFlex} minWidth={COL_WIDTH} flexDirection="column" gap="s-16">
        {left}
      </Flex>
      <Flex flex={rightFlex} minWidth={COL_WIDTH} flexDirection="column" gap="s-16">
        {right}
      </Flex>
    </Flex>
  )
}
