import React, { useState } from 'react'
import { KpiInterface } from '@src/interfaces/kpis'
import { Button, ButtonProps, Popup, Header, Text } from '@revolut/ui-kit'
import { Check } from '@revolut/icons'
import { kpiBulkApprove } from '@src/api/kpis'

interface Props extends ButtonProps {
  kpis: KpiInterface[]
  approvalStep: string
  onAfterSubmit: () => void
  reviewCycleId: number | string
}

export const ApproveAllButton = ({
  kpis,
  approvalStep,
  onAfterSubmit,
  reviewCycleId,
  ...props
}: Props) => {
  const [isApproving, setIsApproving] = useState<boolean>()
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        {...props}
        pending={isApproving}
        useIcon={Check}
        variant="secondary"
        size="sm"
        onClick={() => setIsPopupOpen(true)}
      >
        Approve all
      </Button>

      <Popup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet">
          <Header.Title>Approving KPIs</Header.Title>
        </Header>
        <Text use="p" variant="caption" color="grey-tone-50">
          Are you sure you want to approve all KPIs? This can't be undone.
        </Text>
        <Popup.Actions>
          <Button
            elevated
            pending={isApproving}
            onClick={() => {
              setIsApproving(true)
              kpiBulkApprove(kpis, approvalStep, reviewCycleId)
                .then(() => {
                  onAfterSubmit()
                  setIsPopupOpen(false)
                })
                .finally(() => setIsApproving(false))
            }}
          >
            Approve all
          </Button>
          <Button variant="secondary" onClick={() => setIsPopupOpen(false)}>
            Cancel
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}
