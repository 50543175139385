import React, { useState } from 'react'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  ColumnCellInterface,
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import { Statuses } from '@src/interfaces'
import { getColor } from '@src/styles/colors'
import { Action, Box, Color, Flex, MoreBar, Widget } from '@revolut/ui-kit'
import {
  jobDescriptionActionColumn,
  jobDescriptionGenericNameColumn,
  jobDescriptionLocationsColumn,
  jobDescriptionPublishedStatusColumn,
  jobDescriptionRecruiterColumn,
  jobDescriptionSpecialisationColumn,
  jobDescriptionStatusColumn,
  jobDescriptionUsedInRequisitionsCountNameColumn,
} from '@src/constants/columns/jobDescription'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useTable } from '@components/Table/hooks'
import { jobPostingsRequests } from '@src/api/jobPosting'
import Stat from '@components/Stat/Stat'
import SearchTable from '@components/Table/SearchTable/SearchTable'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

const Row = (
  setOpenJobPostingId?: (id: null | number) => void,
  specialisationId?: number,
): RowInterface<JobPostingInterface> => ({
  linkToForm: data => {
    setOpenJobPostingId
      ? setOpenJobPostingId(data.id)
      : navigateTo(
          pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
            specId: data?.specialisation?.id,
            id: data?.id,
          }),
        )
  },
  highlight: (data, theme) =>
    data.status === PublishingStatuses.closed ? getColor(theme, Color.GREY_TONE_5) : '',
  cells: [
    {
      ...jobDescriptionGenericNameColumn,
      width: 250,
    },
    !specialisationId
      ? {
          ...jobDescriptionSpecialisationColumn,
          width: 250,
        }
      : undefined,
    {
      ...jobDescriptionRecruiterColumn,
      width: 200,
    },
    {
      ...jobDescriptionLocationsColumn,
      width: 250,
    },
    {
      ...jobDescriptionUsedInRequisitionsCountNameColumn,
      width: 200,
    },
    {
      ...jobDescriptionPublishedStatusColumn,
      width: 250,
    },
    {
      ...jobDescriptionStatusColumn,
      width: 250,
    },
    setOpenJobPostingId
      ? ({
          ...jobDescriptionActionColumn,
          width: 230,
          insert: ({ data }) => (
            <Flex>
              <Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.JOB_POSTING.GENERAL, {
                  specId: data?.specialisation?.id,
                  id: data?.id,
                })}
              >
                Edit
              </Action>
              <Box ml="s-8">
                <Action onClick={() => setOpenJobPostingId(data.id)}>View</Action>
              </Box>
            </Flex>
          ),
        } as ColumnCellInterface<JobPostingInterface>)
      : undefined,
  ].filter(i => i) as ColumnCellInterface<JobPostingInterface>[],
})

type Props = {
  specialisationId?: number
  setOpenJobPostingId?: (id: number | null) => void
  children?: React.ReactNode
}

const JobPostingsTable = ({ setOpenJobPostingId, specialisationId, children }: Props) => {
  const user = useSelector(selectUser)
  const [showArchivedPostings, setShowArchivedPostings] = useState(false)
  const [showMyPostings, setShowMyPostings] = useState(false)
  const [showCopywriterPending, setShowCopywriterPending] = useState(false)

  const initialFilterBy = [
    specialisationId
      ? {
          columnName: 'specialisation__id',
          filters: [{ id: specialisationId, name: `${specialisationId}` }],
          nonResettable: true,
        }
      : undefined,
    {
      columnName: 'approval_status',
      filters: [
        { id: Statuses.pending, name: Statuses.pending },
        { id: Statuses.approved, name: Statuses.approved },
      ],
    },
  ].filter(i => i) as FilterByInterface[]

  const initialSortBy = [
    {
      sortBy: 'id',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const table = useTable<JobPostingInterface>(
    jobPostingsRequests,
    initialFilterBy,
    initialSortBy,
  )

  const onSwitchMyPostings = () => {
    table.onFilterChange([
      {
        columnName: 'recruiter__id',
        filters: showMyPostings
          ? []
          : [
              {
                id: user.id,
                name: String(user.id),
              },
            ],
      },
    ])

    setShowMyPostings(!showMyPostings)
  }

  const onSwitchArchived = () => {
    table.onFilterChange([
      {
        columnName: 'approval_status',
        filters: showArchivedPostings
          ? [
              {
                id: 'approved',
                name: 'approved',
              },
              {
                id: 'pending',
                name: 'pending',
              },
            ]
          : [
              {
                id: 'archived',
                name: 'archived',
              },
            ],
      },
    ])

    setShowArchivedPostings(!showArchivedPostings)
  }

  const onSwitchCopywriterPending = () => {
    table.onFilterChange([
      {
        columnName: 'approval_step',
        filters: showCopywriterPending
          ? []
          : [
              {
                id: 'copywriter',
                name: 'copywriter',
              },
            ],
      },
    ])

    setShowCopywriterPending(!showCopywriterPending)
  }

  return (
    <Widget p="s-16" width="100%">
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-8" justifyContent="space-between">
          <Stat label="Total" val={table?.loading ? undefined : table?.count} mr="s-32" />
          <SearchTable
            placeholder="Search by title or specialisation"
            mb="s-24"
            onFilter={table.onFilterChange}
          />
        </Flex>
        <Box mb="s-16">
          <MoreBar>
            {children}
            <SwitchButton checked={showMyPostings} onClick={onSwitchMyPostings}>
              My postings
            </SwitchButton>
            <SwitchButton checked={showArchivedPostings} onClick={onSwitchArchived}>
              Show archived postings
            </SwitchButton>
            <HideIfCommercial>
              <SwitchButton
                checked={showCopywriterPending}
                onClick={onSwitchCopywriterPending}
              >
                Show copywriter pending
              </SwitchButton>
            </HideIfCommercial>
          </MoreBar>
        </Box>

        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<JobPostingInterface>
            name={TableNames.JobPostings}
            useWindowScroll
            dataType="Job postings"
            row={Row(setOpenJobPostingId, specialisationId)}
            {...table}
            noDataMessage="Job postings will appear here."
          />
        </Flex>
      </Flex>
    </Widget>
  )
}

export default JobPostingsTable
