import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Avatar, Box } from '@revolut/ui-kit'
import { AvatarType } from '@src/interfaces/employees'
import { getAvatarUrl } from '@src/utils/employees'

interface Props {
  id?: number
  name?: string
  avatar?: AvatarType
}

const EmployeeContainer = styled(Link)`
  padding-top: 5px;
  height: 37px;
  padding-bottom: 5px;
  color: ${props => props.theme.colors.foreground};
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover > div {
    text-decoration: underline;
  }
`

const Employee = ({ id, name, avatar }: Props) => {
  return (
    <EmployeeContainer
      onClick={e => e.stopPropagation()}
      to={getLocationDescriptor(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id }))}
    >
      <Box mr="s-8">
        <Avatar style={{ flexShrink: 0 }} size={24} image={getAvatarUrl(avatar)}>
          {!avatar ? name?.[0] : undefined}
        </Avatar>
      </Box>

      <Box>{name}</Box>
    </EmployeeContainer>
  )
}

export default Employee
