import React, { useState } from 'react'
import {
  Button,
  Cell,
  Color,
  Flex,
  InputGroup,
  Link,
  Side,
  StatusPopup,
  Text,
  TextWidget,
} from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'
import { css } from 'styled-components'

import { selectorKeys } from '@src/constants/api'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import {
  DIVERSITY_DATA,
  EMPLOYEE_ENGAGEMENT_SERVICE_DESK_LINK,
  ENVIRONMENTAL_HEALTH_SAFETY_SERVICE_DESK_LINK,
  REVOLUT_FEEDBACK_SERVICE_DESK_LINK,
  SLACK_BAME_AT_REVOLUT,
  SLACK_PARENTS_CARERS_AT_REVOLUT,
  SLACK_PRIDE_AT_REVOLUT,
  WELLBEING_AND_MENTAL_HEALTH,
} from '@src/constants/externalLinks'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import SideBar from '@components/SideBar/SideBar'
import { DiversityInterface } from '@src/interfaces/employees'
import { useLapeContext } from '@src/features/Form/LapeForm'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { pushError } from '@src/store/notifications/actions'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import RadioSelectOption from '@components/Inputs/RadioSelectInput/RadioSelectOption'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useEmployeeDetailsOnAfterSubmit } from '@src/pages/EmployeeProfile/Forms/hooks'

export const validator = {}

const Warning = css`
  margin-top: 12px;
  margin-bottom: 16px;
`

interface DiversityFormFieldsProps {
  isOnboarding?: boolean
}

export const DiversityFormFields = ({
  isOnboarding = false,
}: DiversityFormFieldsProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  return (
    <InputGroup>
      <LapeRadioSelectInput
        name="age_range"
        label="Age group"
        selector={selectorKeys.diversity_age_ranges}
      />
      <LapeRadioSelectInput
        name="gender"
        label="Gender identity"
        selector={selectorKeys.diversity_genders}
        message={
          isOnboarding || isCommercial
            ? null
            : 'Your personal gender is the gender that you identify with.'
        }
      />
      <LapeRadioSelectInput
        name="sexual_orientation"
        label="Sexual orientation"
        selector={selectorKeys.diversity_sexual_orientation}
        message={
          isOnboarding || isCommercial ? null : (
            <>
              Revolut actively supports LGBTQIA+ individuals, you can join the guild as a
              member or an ally and find more info{' '}
              <Link
                href={SLACK_PRIDE_AT_REVOLUT}
                target="_blank"
                rel="noopener norefferer"
              >
                here.
              </Link>
            </>
          )
        }
      />
      <LapeNewMultiSelect
        name="carer_types"
        placeholder="Parent/carer"
        selector={selectorKeys.diversity_carer_types}
        message={
          isOnboarding || isCommercial ? null : (
            <>
              Revolut actively supports Parents &amp; Carers, you can join the guild as a
              member or an ally and find more info{' '}
              <Link
                href={SLACK_PARENTS_CARERS_AT_REVOLUT}
                target="_blank"
                rel="noopener norefferer"
              >
                here.
              </Link>
            </>
          )
        }
        required
      />
      <LapeNewMultiSelect
        name="disabilities"
        placeholder="Disabilities"
        selector={selectorKeys.diversity_disabilities}
        message={
          isOnboarding || isCommercial ? null : (
            <>
              You can complete a self-assessment{' '}
              <Link
                href={ENVIRONMENTAL_HEALTH_SAFETY_SERVICE_DESK_LINK}
                target="_blank"
                rel="noopener norefferer"
              >
                here
              </Link>{' '}
              to improve the ergonomics of your workstation. Our qualified Mental Health
              First Aiders can also provide confidential &amp; indepedent support, you can
              find them{' '}
              <Link
                href={WELLBEING_AND_MENTAL_HEALTH}
                target="_blank"
                rel="noopener norefferer"
              >
                here.
              </Link>
            </>
          )
        }
        required
      />
      <LapeNewMultiSelect
        name="ethnicities"
        placeholder="Ethnicities"
        selector={selectorKeys.diversity_ethnicities}
        message={
          isOnboarding || isCommercial ? null : (
            <>
              Revolut actively supports BAME individuals, you can join the guild as a
              member or an ally and find more info{' '}
              <Link
                href={SLACK_BAME_AT_REVOLUT}
                target="_blank"
                rel="noopener norefferer"
              >
                here.
              </Link>
            </>
          )
        }
        required
      />
      <LapeRadioSelectInput<{ id: number; name: string; subtitle?: string }>
        name="socioeconomic_background"
        label="Socio-economic background"
        selector={selectorKeys.diversity_socioeconomic_backgrounds}
        message="What was the occupation of the main earner in your household when you were around 14 years of age?"
        required
      >
        {option => (
          <RadioSelectOption label={option.label} subtitle={option.value.subtitle} />
        )}
      </LapeRadioSelectInput>
      <LapeRadioSelectInput
        name="educational_background"
        label="Educational background"
        message="Your highest level of qualification"
        selector={selectorKeys.diversity_educational_backgrounds}
      />
      <LapeRadioSelectInput
        name="religion"
        label="Religion"
        selector={selectorKeys.diversity_religions}
      />
    </InputGroup>
  )
}

export const getIncompleteFields = (values: DiversityInterface) => {
  const incompleteFields = []

  if (values.age_range == null) {
    incompleteFields.push('age_range')
  }
  if (values.gender == null) {
    incompleteFields.push('gender')
  }
  if (values.sexual_orientation == null) {
    incompleteFields.push('sexual_orientation')
  }
  if (values.socioeconomic_background == null) {
    incompleteFields.push('socioeconomic_background')
  }
  if (values.educational_background == null) {
    incompleteFields.push('educational_background')
  }
  if (values.religion == null) {
    incompleteFields.push('religion')
  }
  if (values.carer_types == null || values.carer_types.length === 0) {
    incompleteFields.push('carer_types')
  }
  if (values.disabilities == null || values.disabilities.length === 0) {
    incompleteFields.push('disabilities')
  }
  if (values.ethnicities == null || values.ethnicities.length === 0) {
    incompleteFields.push('ethnicities')
  }
  return incompleteFields
}

interface IncompletePopupProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  onReject: () => void
  onExited: () => void
  loading: boolean
}

export const IncompletePopup = ({
  open,
  onClose,
  onConfirm,
  onReject,
  onExited,
  loading,
}: IncompletePopupProps) => {
  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      label="Are you sure you want to leave this page?"
      body={
        <Text textStyle="caption" color={Color.GREY_TONE_50}>
          If you leave the form, the questions that have not been answered will be
          automatically saved as 'Prefer not to say'. Thank you for submitting your
          diversity details!
        </Text>
      }
      onConfirm={onConfirm}
      onReject={onReject}
      onExited={onExited}
      loading={loading}
      yesMessage="Confirm"
      noMessage="Cancel"
    />
  )
}

interface FAQDetailCellProps {
  number: string
  title: React.ReactNode
  content: React.ReactNode
}

const FAQDetailCell = ({ number, title, content }: FAQDetailCellProps) => {
  return (
    <Cell>
      <Flex flexDirection="column" gap="s-8" style={{ textAlign: 'left' }}>
        <Text variant="primary">
          {number}. {title}
        </Text>
        <Text variant="caption">{content}</Text>
      </Flex>
    </Cell>
  )
}

interface DiversityFAQSidebarProps {
  isOpen: boolean
  onClose: () => void
}

export const DiversityFAQSidebar = ({ isOpen, onClose }: DiversityFAQSidebarProps) => {
  const isCommercial = useIsCommercial()

  return (
    <SideBar title="FAQs" isOpen={isOpen} onClose={onClose}>
      <Flex flexDirection="column" height={0}>
        <Flex flexDirection="column" gap="s-16" pb="s-32">
          <FAQDetailCell
            number="1"
            title="Why do we collect this data?"
            content={`We're collecting diversity data to help support ${
              isCommercial ? "our company's" : "Revolut's"
            } approach and progress on diversity and inclusion. It will help us to better understand our makeup, and how representative we are of our customer base, as well as any barriers faced by different groups within our organisation.`}
          />
          <FAQDetailCell
            number="2"
            title="I am concerned about the confidentiality of this data"
            content="Your diversity data is kept strictly confidential. Where we report on this data, we will do so using aggregate data only and will not report on an individual basis."
          />
          <FAQDetailCell
            number="3"
            title="What if I dont want to share this information?"
            content="If you still feel uncomfortable answering some of these questions, we recommend choosing 'Prefer not to say' instead of leaving the section blank."
          />
          <FAQDetailCell
            number="4"
            title="Can I change my answer later?"
            content={`You can amend your data ${
              isCommercial ? '' : 'on Revoluters '
            }at any time.`}
          />
          <HideIfCommercial>
            <FAQDetailCell
              number="5"
              title="I have other concerns, who can I talk to?"
              content={
                <>
                  If you have any concerns or questions, please{' '}
                  <Link
                    href={EMPLOYEE_ENGAGEMENT_SERVICE_DESK_LINK}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    contact the Engagement team
                  </Link>{' '}
                  who will be happy to discuss this with you.
                </>
              }
            />
          </HideIfCommercial>
        </Flex>
      </Flex>
    </SideBar>
  )
}

export const useIncompletePopupState = () => {
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false)
  const [shouldTriggerErrors, setShouldTriggerErrors] = useState(false)
  const formValidator = useSafeFormValidator()
  const { values } = useLapeContext<DiversityInterface>()
  const incompleteFields = getIncompleteFields(values)

  const onClose = () => {
    setConfirmationPopupOpen(false)
    setShouldTriggerErrors(true)
  }

  const onExited = () => {
    if (shouldTriggerErrors) {
      setShouldTriggerErrors(false)
      formValidator.forceErrors(
        incompleteFields.reduce(
          (total, field) => ({ ...total, [field]: 'Please provide missing information' }),
          {},
        ),
      )
    }
  }

  return {
    incompleteFields,
    onExited,
    onClose,
    confirmationPopupOpen,
    setConfirmationPopupOpen,
  }
}

export type Props = {
  isSidebarContent?: boolean
  refreshData?: () => void
}
const Diversity = ({ isSidebarContent, refreshData }: Props) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const [faqOpen, setFaqOpen] = useState(!isCommercial)
  const { isSubmitting, submit } = useLapeContext<DiversityInterface>()
  const {
    incompleteFields,
    onExited,
    onClose,
    confirmationPopupOpen,
    setConfirmationPopupOpen,
  } = useIncompletePopupState()

  const [successPopupOpen, setSuccessPopupOpen] = useState(false)

  const onConfirm = () => {
    submit()
      .then(() => setSuccessPopupOpen(true))
      .catch(pushError)
  }

  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <>
      <PageBody>
        <HideIfCommercial>
          <Button
            onClick={() => setFaqOpen(true)}
            useIcon={InfoOutline}
            size="sm"
            variant="secondary"
            mb="s-8"
          >
            FAQs
          </Button>

          <TextWidget css={Warning}>
            <TextWidget.Title>
              We respect your privacy: this information is visible only to you and all
              fields are optional
            </TextWidget.Title>
            <TextWidget.Content color={Color.GREY_TONE_50}>
              By filling in this form you consent to Revolut using the diversity
              information you submit as set out{' '}
              <Link href={DIVERSITY_DATA} rel="noopener norefferer" target="_blank">
                here
              </Link>
            </TextWidget.Content>
          </TextWidget>
        </HideIfCommercial>
        <DiversityFormFields />
        <HideIfCommercial>
          <Flex alignItems="center" mt="s-16" color={Color.GREY_TONE_50}>
            <InfoOutline />
            <Text ml="s-12">
              If any field lacks the terms which describe you best, you can request a new
              option to be added via the Diversity &amp; Inclusion service desk{' '}
              <Link
                href={REVOLUT_FEEDBACK_SERVICE_DESK_LINK}
                target="_blank"
                rel="noopener norefferer"
              >
                here.
              </Link>
            </Text>
          </Flex>
        </HideIfCommercial>
      </PageBody>

      <ActionsWrapper>
        {incompleteFields.length > 0 ? (
          <Button onClick={() => setConfirmationPopupOpen(true)}>Save changes</Button>
        ) : (
          <NewSaveButtonWithPopup
            successText="Diversity information successfully updated"
            onAfterSubmit={onAfterSubmit}
          />
        )}
      </ActionsWrapper>

      <DiversityFAQSidebar isOpen={faqOpen} onClose={() => setFaqOpen(false)} />

      <IncompletePopup
        open={confirmationPopupOpen}
        onConfirm={onConfirm}
        onReject={onClose}
        onClose={onClose}
        onExited={onExited}
        loading={isSubmitting}
      />

      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)
          onAfterSubmit()
        }}
      >
        <StatusPopup.Title>Diversity information successfully updated</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

export default Diversity
