import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { InviteTeam } from './InviteTeam'

export const PlatformOnboarding = () => {
  return (
    <Switch>
      <Route path={ROUTES.PLATFORM_ONBOARDING.INVITE_TEAM} exact>
        <InviteTeam />
      </Route>
    </Switch>
  )
}
