import React from 'react'
import { getColor } from '@src/styles/colors'
import { Color, Text, TextProps } from '@revolut/ui-kit'
import { KeeperGrade, KeeperGradeToString } from '@src/interfaces/performance'
import { RevolutTheme, useTheme } from '@src/styles/theme'

interface Props extends TextProps {
  grade?: KeeperGrade | null
  withPending?: boolean
  inverse?: boolean
  className?: string
}

const getColorsAndGrade = (theme: RevolutTheme, grade?: KeeperGrade | null) => {
  switch (grade) {
    case KeeperGrade.No:
      return {
        color: getColor(theme, Color.BACKGROUND),
        background: getColor(theme, Color.RED),
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.ProbablyNo:
      return {
        color: getColor(theme, Color.FOREGROUND),
        background: getColor(theme, Color.GREY_50_OPAQUE_10),
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.Yes:
      return {
        color: getColor(theme, Color.BACKGROUND),
        background: getColor(theme, Color.TEAL),
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.StrongYes:
      return {
        color: getColor(theme, Color.BACKGROUND),
        background: getColor(theme, Color.TEAL),
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.YesAtAnyCost:
      return {
        color: getColor(theme, Color.BACKGROUND),
        background: getColor(theme, Color.TEAL),
        grade: KeeperGradeToString[grade],
      }
    default:
      return null
  }
}

const getInverseColorsAndGrade = (theme: RevolutTheme, grade?: KeeperGrade | null) => {
  switch (grade) {
    case KeeperGrade.No:
      return {
        color: getColor(theme, Color.RED),
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.ProbablyNo:
      return {
        color: getColor(theme, Color.RED),
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.Yes:
      return {
        color: getColor(theme, Color.FOREGROUND),
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.StrongYes:
      return {
        color: getColor(theme, Color.GREEN),
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.YesAtAnyCost:
      return {
        color: getColor(theme, Color.GREEN),
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.Pass:
      return {
        color: getColor(theme, Color.GREEN),
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.Fail:
      return {
        color: getColor(theme, Color.RED),
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    default:
      return null
  }
}

const KeeperGradeLabel = ({
  grade,
  withPending,
  inverse,
  className,
  ...props
}: Props) => {
  const theme = useTheme()
  const data = inverse
    ? getInverseColorsAndGrade(theme, grade)
    : getColorsAndGrade(theme, grade)

  if (!data) {
    if (withPending) {
      return (
        <Text className={className} color={Color.GREY_TONE_20} {...props}>
          Pending
        </Text>
      )
    }

    return null
  }

  return (
    <Text
      className={className}
      backgroundColor={data.background}
      color={data.color}
      {...props}
    >
      {data.grade}
    </Text>
  )
}

export default KeeperGradeLabel
