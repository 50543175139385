import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageWrapper } from '@components/Page/Page'
import { SelectTable } from '@src/pages/Forms/CompensationBandsBulkEdit/SelectTable'

const BACK_URL = ROUTES.PEOPLE.COMPENSATION.BANDS

export const CompensationBandsBulkEdit = () => {
  return (
    <PageWrapper>
      <PageHeader backUrl={BACK_URL} title="Bulk Edit" />
      <PageBody maxWidth="100%">
        <SelectTable />
      </PageBody>
    </PageWrapper>
  )
}
