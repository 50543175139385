import { CellTypes, RowInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import React from 'react'
import { Flex } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { ratingToColor } from '@src/utils/performance'
import { InterviewSkillRatingInterface } from '@src/interfaces/interviewTool'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { TableNames } from '@src/constants/table'

type Props = {
  data?: InterviewSkillRatingInterface[]
}

const roleRow: RowInterface<InterviewSkillRatingInterface> = {
  cells: [
    {
      title: 'Skills',
      type: CellTypes.text,
      idPoint: 'id',
      dataPoint: 'name',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      noResize: true,
      width: 437,
    },
    {
      title: 'Rating',
      type: CellTypes.insert,
      idPoint: 'rating',
      dataPoint: 'rating',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      noResize: true,
      width: 250,
      background: (data, theme) => {
        return ratingToColor(theme, data.rating) || 'inherit'
      },
      insert: ({ data }) => (data.rating ? PerformanceRatingTitle[data.rating] : '-'),
    },
  ],
}

const SummaryTable = ({ data }: Props) => {
  if (!data) {
    return null
  }

  return (
    <Flex flexDirection="column" width="100%" mb="s-16">
      <AdjustableTable<InterviewSkillRatingInterface>
        name={TableNames.HiringScorecardSummary}
        row={roleRow}
        data={data}
        count={data.length}
        noReset
        hideCount
      />
    </Flex>
  )
}

export default SummaryTable
