import React from 'react'
import HiringPipelinesCommonTable, {
  HiringPipelinesCommonTableProps,
} from '@src/pages/Recruitment/RecruitmentSubTabs/HiringPipelines/HiringPipelinesCommonTable'

const SharedHiringPipelines = (props: Omit<HiringPipelinesCommonTableProps, 'type'>) => {
  return <HiringPipelinesCommonTable type="shared" {...props} />
}

export default SharedHiringPipelines
