import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import { Route, Switch as RouteSwitch } from 'react-router-dom'
import SendArchivalEmail from '@src/pages/Forms/ArchiveOpportunity/SendArchivalEmail/SendArchivalEmail'
import General from '@src/pages/Forms/ArchiveOpportunity/General/General'
import { ArchivingProvider } from '@src/pages/Forms/ArchiveOpportunity/common'

const ArchiveOpportunity = () => {
  return (
    <RouteSwitch>
      <ArchivingProvider>
        <Route exact path={ROUTES.FORMS.ARCHIVE_OPPORTUNITY.GENERAL}>
          <General />
        </Route>
        <Route exact path={ROUTES.FORMS.ARCHIVE_OPPORTUNITY.EMAIL}>
          <SendArchivalEmail />
        </Route>
      </ArchivingProvider>
    </RouteSwitch>
  )
}

export default connect(ArchiveOpportunity)
