import React from 'react'
import { Group, DetailsCell, themeTextStyle, Box, Color } from '@revolut/ui-kit'
import { css } from 'styled-components'

import { formatWithoutTimezone } from '@src/utils/format'
import { EmployeeChangeRequestInterface } from '@src/interfaces/employeeChangeRequest'
import { EmployeeInterface } from '@src/interfaces/employees'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { accessWillBeResetMessage } from '@src/features/Popups/common'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export type EmployeeDetails = Pick<
  EmployeeInterface,
  'id' | 'full_name' | 'avatar' | 'employee_type'
> &
  Pick<EmployeeInterface, 'line_manager' | 'quality_control' | 'team' | 'mentor'>

interface DetailsProps {
  request: EmployeeChangeRequestInterface
}

export const Details = ({ request }: DetailsProps) => {
  return (
    <>
      <Group>
        <DetailsCell>
          <DetailsCell.Title>Requested on</DetailsCell.Title>
          <DetailsCell.Content>
            {formatWithoutTimezone(request.requested_on)}
          </DetailsCell.Content>
        </DetailsCell>
        <DetailsCell>
          <DetailsCell.Title>Requested by</DetailsCell.Title>
          <DetailsCell.Content>{request.requested_by.full_name}</DetailsCell.Content>
        </DetailsCell>
        <DetailsCell>
          <DetailsCell.Title>Effective date of change(s)</DetailsCell.Title>
          <DetailsCell.Content>
            {formatWithoutTimezone(request.effective_date)}
          </DetailsCell.Content>
        </DetailsCell>
      </Group>

      <Box mt="s-16">
        <DetailsCell>
          <DetailsCell.Title>Justification</DetailsCell.Title>
          <DetailsCell.Note>{request.justification}</DetailsCell.Note>
        </DetailsCell>
      </Box>
    </>
  )
}

interface AccessLostWidgetProps {
  request: EmployeeChangeRequestInterface
}

export const AccessLostWidget = ({ request }: AccessLostWidgetProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  return !isCommercial &&
    request.change_requests.some(change => change.field.field_name === 'team') ? (
    <ActionWidget
      title="Access will be reset"
      text={accessWillBeResetMessage}
      mb="s-16"
    />
  ) : null
}

export const backUrl = (id: number) => pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id })

export const HeaderAvatarCss = css`
  ${themeTextStyle('secondary')}
`

export const TableWrapper: React.FC = ({ children }) => {
  return (
    <Box
      border="1px solid"
      borderColor={Color.GREY_TONE_5}
      overflow="hidden"
      borderRadius={12}
      mb="s-16"
    >
      <Box m="-1px">{children}</Box>
    </Box>
  )
}
