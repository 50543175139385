import React, { useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { Box, Copyable, Flex, Group, Subheader } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import Loader from '@components/CommonSC/Loader'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import JobDescription from '@src/pages/Forms/JobPosting/Components/JobPosting'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import { pathToUrl, pathToFullUrlWithBaseUrl } from '@src/utils/router'
import { PUBLIC_ROUTES, ROUTES } from '@src/constants/routes'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { useGetJobDescriptionApprovals } from '@src/api/jobPosting'
import SettingsButtons, {
  ApproveButton,
  RejectButton,
  ArchiveButton,
  EditButton,
} from '@src/features/SettingsButtons'
import { handleError } from '@src/api'
import { EntityPermissions } from '@src/store/auth/types'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import RecruitersAssigned from '@src/pages/Forms/JobPosting/Components/RecruitersAssigned'
import { useGetJobPostingSettings, useGlobalSettings } from '@src/api/settings'
import kebabCase from 'lodash/kebabCase'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import OpenInNewTab, { extractHostname } from '@src/components/OpenInNewTab/OpenInNewTab'
import PublishStatus from '@src/pages/Forms/JobPosting/Preview/PublishStatus'
import useIsCommercial from '@src/hooks/useIsCommercial'

type CopyUrlProps = {
  id?: string
  name: string
  route: string
  url?: string
}

const CopyUrl = ({ id, name, route, url }: CopyUrlProps) => {
  const safeUrl = !url
    ? pathToFullUrlWithBaseUrl(route, { id: `${kebabCase(name)}-${id}` })
    : url
  return (
    <Copyable
      value={safeUrl}
      onCopy={() => {
        pushNotification({
          value: 'Copied to clipboard',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      }}
      labelButtonCopy="Copy"
    >
      <OpenInNewTab link={safeUrl} label={extractHostname(safeUrl)} />
    </Copyable>
  )
}

const Preview = () => {
  const [isApproving, setIsApproving] = useState<boolean>(false)
  const { values, loading } = useLapeContext<JobPostingInterface>()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const {
    settings: { career_page_enabled },
  } = useGlobalSettings()
  const isCommercial = useIsCommercial()

  const published =
    values.status !== PublishingStatuses.closed &&
    values.status !== PublishingStatuses.unpublished
  const showUrls = published && (!isCommercial || career_page_enabled)
  const enableApprovals = jobPostingSettings?.enable_approvals
  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useGetJobDescriptionApprovals(enableApprovals ? values?.id : undefined)
  const canChange = !!values.field_options?.actions?.includes?.(EntityPermissions.Change)
  const params = useParams<{ id: string; specId: string }>()

  const canApprove =
    values?.approval_status === ApprovalStatuses.Pending &&
    values?.field_options?.actions?.includes(EntityPermissions.Approve)

  if (loading) {
    return (
      <Flex>
        <Loader />
      </Flex>
    )
  }

  return (
    <PageBody maxWidth={782}>
      <SettingsButtons mb="s-24">
        {canApprove && (
          <>
            <ApproveButton
              isVisible={enableApprovals}
              onBeforeSubmit={() => setIsApproving(true)}
              onAfterSubmit={() => {
                setIsApproving(false)
                refetchApproval()
              }}
              onSubmitFailed={handleError}
              statusFieldName="approval_status"
            />
            <RejectButton
              isVisible={enableApprovals}
              onBeforeSubmit={() => setIsApproving(true)}
              onAfterSubmit={() => {
                setIsApproving(false)
                refetchApproval()
              }}
              onSubmitFailed={handleError}
              statusFieldName="approval_status"
              dialog={{
                title: 'Reason for rejection',
                placeholder: 'a couple of words',
                fieldName: 'rejection_comment',
              }}
            />
          </>
        )}
        <EditButton
          route={pathToUrl(ROUTES.FORMS.JOB_POSTING.GENERAL, params)}
          isVisible={canChange}
        />
        <ArchiveButton
          entityPermissions={EntityPermissions.Change}
          isVisible={!!values?.id}
          statusFieldName="approval_status"
        />
      </SettingsButtons>
      <Box mb="s-16">
        <PublishStatus
          id={values.id}
          publishStatus={values.status}
          statusDisplay={values.status_display}
          onChange={({ status, status_display }) => {
            values.status = status
            values.status_display = status_display
          }}
        />
      </Box>
      {values.id && enableApprovals && (
        <ApprovalFlow
          isLoading={isApprovalLoading || isApproving}
          steps={approvalSteps || null}
        />
      )}
      <FormPreview<JobPostingInterface> data={values} title="General details">
        <Group>
          <FormPreview.Item title="Publishing status" field="status_display" />
          <FormPreview.Item
            title="Recruiter"
            field="recruiter.name"
            to={() =>
              pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, {
                id: values.recruiter?.id,
              })
            }
          />
          <FormPreview.Item
            title="Specialisation"
            field="specialisation.name"
            to={() =>
              pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                id: values.specialisation?.id,
              })
            }
          />
          {showUrls && values.public_id && (
            <>
              <FormPreview.Item<JobPostingInterface>
                title="Job description URL"
                insert={({ name, public_id, careers_position_url }) => (
                  <CopyUrl
                    name={name}
                    id={public_id}
                    route={PUBLIC_ROUTES.CAREERS.POSITION}
                    url={careers_position_url}
                  />
                )}
              />
              <FormPreview.Item<JobPostingInterface>
                title="Application form URL"
                insert={({ name, public_id, apply_url }) => (
                  <CopyUrl
                    name={name}
                    id={public_id}
                    route={PUBLIC_ROUTES.CAREERS.APPLICATION}
                    url={apply_url}
                  />
                )}
              />
            </>
          )}
        </Group>
      </FormPreview>
      <Subheader variant="nested">
        <Subheader.Title>Job posting</Subheader.Title>
      </Subheader>
      <JobDescription id={values.id} locations={values.locations} />
      <RecruitersAssigned data={values.recruiter_locations} />
    </PageBody>
  )
}

export default connect(Preview)
