import React, { useEffect, useState } from 'react'
import findIndex from 'lodash/findIndex'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { Box, BoxProps, InputGroup } from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'

interface Props extends BoxProps {
  seniorities?: SeniorityInterface[]
  seniorityMinValue?: SeniorityInterface
  seniorityMaxValue?: SeniorityInterface
  onChangeMin: (seniority: SeniorityInterface) => void
  onChangeMax: (seniority: SeniorityInterface) => void
  onFilter?: (minIndex: number, maxIndex: number) => void
  prefill?: boolean
  disabled?: boolean
  message?: string
}

const SenioritiesRange = ({
  seniorities,
  seniorityMinValue,
  seniorityMaxValue,
  onChangeMin,
  onChangeMax,
  onFilter,
  disabled,
  message,
  prefill = true,
  ...boxProps
}: Props) => {
  const [minSeniorities, setMinSeniorities] = useState<SeniorityInterface[]>([])
  const [maxSeniorities, setMaxSeniorities] = useState<SeniorityInterface[]>([])

  const adjustSenioritySelectors = (
    minSeniority?: SeniorityInterface,
    maxSeniority?: SeniorityInterface,
    sen = seniorities,
  ) => {
    if (sen) {
      let minIndex = findIndex(sen, ['id', minSeniority?.id])
      let maxIndex = findIndex(sen, ['id', maxSeniority?.id])
      if (minIndex === -1) {
        minIndex = 0
      }
      if (maxIndex === -1) {
        maxIndex = sen.length - 1
      }

      setMinSeniorities(sen.slice(0, maxIndex + 1))
      setMaxSeniorities(sen.slice(minIndex, sen.length))
      onFilter && onFilter(minIndex, maxIndex)
    }
  }

  useEffect(() => {
    if (seniorities) {
      if (!seniorityMinValue && !seniorityMaxValue && prefill) {
        onChangeMin(seniorities[0])
        onChangeMax(seniorities[seniorities.length - 1])
      }

      adjustSenioritySelectors(
        seniorityMinValue || seniorities[0],
        seniorityMaxValue || seniorities[seniorities.length - 1],
      )
    }
  }, [seniorities, seniorityMinValue, seniorityMaxValue])

  const handleMinChange = (option: SeniorityInterface | null) => {
    if (option) {
      onChangeMin(option)
    }
  }

  const handleMaxChange = (option: SeniorityInterface | null) => {
    if (option) {
      onChangeMax(option)
    }
  }

  return (
    <Box mb="s-24" {...boxProps}>
      <InputGroup variant="horizontal">
        <RadioSelectInput<SeniorityInterface>
          label="Lowest Seniority"
          options={minSeniorities.map(option => ({
            label: option.name,
            value: option,
          }))}
          value={seniorityMinValue || null}
          onChange={handleMinChange}
          disabled={disabled}
          message={message}
          clearable={false}
        />
        <RadioSelectInput<SeniorityInterface>
          label="Highest Seniority"
          options={maxSeniorities.map(option => ({
            label: option.name,
            value: option,
          }))}
          value={seniorityMaxValue || null}
          onChange={handleMaxChange}
          disabled={disabled}
          message={message}
          clearable={false}
        />
      </InputGroup>
    </Box>
  )
}

export default SenioritiesRange
