import React from 'react'
import { InfoOutline } from '@revolut/icons'
import { HStack, Text } from '@revolut/ui-kit'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import Tooltip from '@components/Tooltip/Tooltip'
import { getScheduleString, mapStatusToColor } from '@src/apps/People/Engagement/helpers'
import pluralize from 'pluralize'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

export const surveyNameColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.text,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.engagement_survey_titles,
  title: 'Name',
}

export const surveyRecurrenceColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  idPoint: 'sent_every.id',
  dataPoint: 'sent_every.name',
  sortKey: 'sent_every',
  filterKey: 'sent_every',
  selectorsKey: selectorKeys.engagement_survey_recurrence,
  title: 'Recurrence',
  insert: ({ data }) => {
    const isPeriodical = data.sent_every.id !== 'manual'

    return (
      <HStack space="s-8" align="center">
        <Text>{isPeriodical ? 'Periodical' : 'Ad-hoc'}</Text>
        {isPeriodical && (
          <Tooltip placement="right" text={getScheduleString(data)}>
            <InfoOutline size={14} />
          </Tooltip>
        )}
      </HStack>
    )
  },
}

export const surveyDeadlineColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  filterType: FilterType.range,
  idPoint: 'response_deadline_days',
  dataPoint: 'response_deadline_days',
  sortKey: 'response_deadline_days',
  filterKey: 'response_deadline_days',
  selectorsKey: selectorKeys.none,
  title: 'Deadline',
  insert: ({ data }) => {
    if (!data.response_deadline_days) {
      return '-'
    }
    return (
      <Text>{`${data.response_deadline_days} ${pluralize(
        'day',
        data.response_deadline_days,
      )}`}</Text>
    )
  },
}

export const surveyAudienceColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  filterType: FilterType.range,
  idPoint: 'audience_size',
  dataPoint: 'audience_size',
  sortKey: 'audience_size',
  filterKey: 'audience_size',
  selectorsKey: selectorKeys.none,
  title: 'Audience',
  insert: ({ data }) => {
    if (!data.audience_size) {
      return '-'
    }
    return (
      <Text>{`${data.audience_size} ${pluralize('employee', data.audience_size)}`}</Text>
    )
  },
}

export const surveyUpdatedByColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.insert,
  idPoint: 'updated_by.id',
  dataPoint: 'updated_by.full_name',
  sortKey: 'updated_by__full_name',
  filterKey: 'updated_by__id',
  selectorsKey: selectorKeys.employee,
  title: 'Updated by',
  insert: ({ data }) => <UserWithAvatar {...data.updated_by} />,
}

export const surveySentOnColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.date,
  idPoint: 'sent_on',
  dataPoint: 'sent_on',
  sortKey: 'sent_on',
  filterKey: 'sent_on',
  selectorsKey: selectorKeys.none,
  title: 'Sent on',
}

export const surveyUpdatedOnColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.date,
  idPoint: 'update_date_time',
  dataPoint: 'update_date_time',
  sortKey: 'update_date_time',
  filterKey: 'update_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Updated on',
}

export const surveyStatusColumn: ColumnInterface<EngagementSurveyInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  colors: mapStatusToColor,
  selectorsKey: selectorKeys.engagement_survey_statuses,
  title: 'Status',
}
