import React from 'react'
import { connect } from 'lape'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useParams } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import SendOnlineTest, {
  SendOnlineTestProps,
} from '@src/pages/Forms/SendOnlineTest/SendOnlineTest'
import Form from '@src/features/Form/Form'
import { sendOnlineTestInvite } from '@src/api/hiringProcess'
import { getCandidateInterviewStage } from '@src/api/recruitment/interviews'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewStageInterface } from '@src/interfaces/interviewTool'
import { ErrorWidget, Flex } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'

const SendOnlineTestIndex = ({
  candidateId,
  insideSidebar,
  ...props
}: SendOnlineTestProps) => {
  const params = useParams<{ candidateId?: string }>()
  const backUrl = pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
    id: candidateId || params.candidateId,
  })
  const { values } = useLapeContext<InterviewStageInterface>()

  if (values.scheduling_status === 'test_completed') {
    return (
      <Flex width="100%" height="80vh" justifyContent="center" alignItems="center">
        <ErrorWidget>
          <ErrorWidget.Image />
          <ErrorWidget.Title>You cannot send the invite</ErrorWidget.Title>
          <ErrorWidget.Description>
            The candidate already completed the test for this stage
          </ErrorWidget.Description>
          <ErrorWidget.Action
            onClick={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                  id: candidateId || params.candidateId,
                }),
              )
            }
          >
            Go to the candidate profile
          </ErrorWidget.Action>
        </ErrorWidget>
      </Flex>
    )
  }

  const title = values.scheduling_status === 'test_sent' ? 'Re-send test' : 'Send test'

  if (insideSidebar) {
    return <SendOnlineTest {...props} insideSidebar />
  }

  return (
    <PageWrapper>
      <PageHeader pb="s-8" title={title} backUrl={backUrl} />
      <SendOnlineTest {...props} />
    </PageWrapper>
  )
}

const SendOnlineTestForm = (props: SendOnlineTestProps) => (
  <Form<InterviewStageInterface>
    api={{
      get: ({ roundId, stageId }) => getCandidateInterviewStage(+roundId!, +stageId!),
      submit: (data, { roundId, stageId }) =>
        sendOnlineTestInvite(+roundId!, +stageId!, data.online_test?.id),
      update: (data, { roundId, stageId }) =>
        sendOnlineTestInvite(+roundId!, +stageId!, data.online_test?.id),
    }}
    forceParams={
      props.roundId && props.stageId
        ? {
            roundId: String(props.roundId),
            stageId: String(props.stageId),
          }
        : undefined
    }
    isExistingForm
  >
    <SendOnlineTestIndex {...props} />
  </Form>
)

export default connect(SendOnlineTestForm)
