import React, { useState } from 'react'
import styled from 'styled-components'
import ScorecardSection from '@components/ScorecardGeneral/ScorecardSection'
import ScorecardValueBasedSection from '@src/components/ScorecardGeneral/ScorecardValueBasedSection'
import {
  ChangeScorecardInterface,
  DeliverableJustificationPreview,
  PerformanceRating,
  PerformanceReviewTypes,
  PreviousReviewInterface,
  ReviewCategory,
  ScorecardError,
  SkillCardInterface,
  SkippedJustificationsInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { relationToString } from '@src/features/Scorecard/constants'
import { Widget, Text, TextArea, Flex, Box } from '@revolut/ui-kit'
import { connect } from 'lape'
import HelpSidebar from '@src/pages/Forms/EmployeePerformance/components/HelpSidebar'
import PrefillNotification from './PrefillNotification'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { ShowPreviousValuesContext } from '@components/ScorecardGeneral/utils'
import { LOCAL_STORAGE } from '@src/constants/api'
import { AnytimeFeedbackSidebar } from '@components/AnytimeFeedback/AnytimeFeedbackSidebar'
import { useGetFeedbackSidebarData } from '@src/utils/performance'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { EmployeePerformanceActionsBar } from '@src/pages/Forms/EmployeePerformance/EmployeePerformanceActionsBar'
import { useSummarySidebarContext } from '@src/features/EmployeePerformance/SummarySidebarProvider'
import { DeliverablesJustifications } from '@components/ScorecardGeneral/DeliverablesJustifications'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

const Container = styled.div<{
  width?: string
}>`
  width: ${props => props.width || '628px'};
  color: ${props => props.theme.colors.foreground};
  position: relative;
  z-index: 0;
`

const Content = styled.div`
  counter-reset: title;
`

export interface ScorecardGeneralProps {
  title?: string
  subTitle?: React.ReactNode
  helpSection?: React.ReactNode
  description?: string | React.ReactNode
  cards: SkillCardInterface[] | ValueBasedCardInterface[]
  disabled?: boolean
  className?: string
  type: PerformanceReviewTypes
  onChange?: (params: ChangeScorecardInterface) => void
  onSkip?: (value?: string) => void
  allowSkip?: boolean
  justification?: string
  justifications?: SkippedJustificationsInterface[]
  deliverablesJustifications?: DeliverableJustificationPreview[]
  viewMode?: boolean
  rating?: PerformanceRating | null
  errors?: ScorecardError[] | null
  touched?: boolean
  previousReviews?: PreviousReviewInterface[]
  category: ReviewCategory
  children?: React.ReactNode
  width?: string
  hideJustification?: boolean
}

const countSuperpowers = (cards: ValueBasedCardInterface[]) => {
  return cards.reduce((count, card) => {
    count += card.sections.filter(section => section.value === 'superpower').length
    return count
  }, 0)
}

const ScorecardGeneral = ({
  title,
  subTitle,
  helpSection,
  description,
  cards,
  disabled,
  type,
  className,
  onChange,
  onSkip,
  allowSkip,
  justification,
  justifications,
  deliverablesJustifications,
  viewMode,
  rating,
  errors,
  touched,
  previousReviews,
  category,
  children,
  width,
  hideJustification = false,
}: ScorecardGeneralProps) => {
  const canViewPreviousRatings =
    [PerformanceReviewTypes.cultureFit, PerformanceReviewTypes.skills].includes(type) &&
    !!previousReviews?.length

  const isValueBasedBehaviour = [
    PerformanceReviewTypes.cultureValuesFit,
    PerformanceReviewTypes.managerValuesSkills,
  ].includes(type)

  const [skipSection, setSkipSection] = useState(justification !== undefined)
  const [showPreviousRatings, setShowPreviousRatings] = useState(
    canViewPreviousRatings &&
      workspaceLocalStorage.getItem(LOCAL_STORAGE.SHOW_PREVIOUS_RATINGS) === 'true',
  )
  const [isHelpOpen, setHelpOpen] = useState(isValueBasedBehaviour)
  const [resultsLoading, setResultsLoading] = useState<(number | string)[]>([])

  const superpowersCount = isValueBasedBehaviour
    ? countSuperpowers(cards as ValueBasedCardInterface[])
    : 0

  const handleSkip = (skip: boolean) => {
    setSkipSection(skip)
    onSkip?.(
      skip ? 'I don’t have sufficient knowledge to assess this section.' : undefined,
    )
  }

  const [isFeedbackSidebarOpen, setIsFeedbackSidebarOpen] = useState(false)
  const { feedbackList, refetchFeedbackList, employeeId } = useGetFeedbackSidebarData()

  const summarySidebarContext = useSummarySidebarContext()

  const renderTitle = () => {
    if (viewMode && rating) {
      return (
        <Flex alignItems="center">
          <Text fontWeight={500} fontSize="h5">
            {title}
          </Text>
          <Text fontSize="h5" px="s-8" color="grey-tone-20">
            ·
          </Text>
          {rating && <PerformanceRatingLabelTag rating={rating} fontSize="h5" />}
        </Flex>
      )
    }

    const onSkipRequested = allowSkip
      ? { handler: () => handleSkip(!skipSection), checked: skipSection }
      : undefined

    const onPreviousRatingsRequested = canViewPreviousRatings
      ? {
          handler: () => {
            workspaceLocalStorage.setItem(
              LOCAL_STORAGE.SHOW_PREVIOUS_RATINGS,
              String(!showPreviousRatings),
            )
            setShowPreviousRatings(!showPreviousRatings)
          },
          checked: showPreviousRatings,
        }
      : undefined

    const getSidebarOpenHandler = (cb: () => void) => () => {
      setIsFeedbackSidebarOpen(false)
      summarySidebarContext?.hideSummarySidebar()
      setHelpOpen(false)
      cb()
    }

    return (
      <EmployeePerformanceActionsBar
        onReviewsRequested={
          summarySidebarContext
            ? getSidebarOpenHandler(summarySidebarContext.showSummarySidebar)
            : undefined
        }
        onSkipRequested={onSkipRequested}
        onFeedbackRequested={{
          handler: getSidebarOpenHandler(() => setIsFeedbackSidebarOpen(true)),
          feedbackCount: feedbackList?.length,
        }}
        onPreviousRatingsRequested={onPreviousRatingsRequested}
        onHelpRequested={
          helpSection !== undefined
            ? getSidebarOpenHandler(() => setHelpOpen(!isHelpOpen))
            : undefined
        }
      />
    )
  }

  const hideCards = viewMode && rating === PerformanceRating.skipped

  return (
    <ShowPreviousValuesContext.Provider value={showPreviousRatings}>
      <Container className={className} width={width}>
        {renderTitle()}
        {!viewMode && <PrefillNotification type={type} />}
        {viewMode &&
          !!justifications?.length &&
          justifications.map((value, i) => (
            <Widget p="s-16" mb="s-16" mt="s-32" key={i} data-testid="skip-section">
              <Text use="div" fontSize="primary" fontWeight={500}>
                {value.review.reviewer.display_name} (
                {relationToString(value.review.reviewer_relation, true)}) skipped this
                section
              </Text>
              <Text color="grey-tone-50">“{value.value}”</Text>
            </Widget>
          ))}
        {skipSection && (
          <>
            <Text
              use="div"
              color="grey-tone-50"
              pt="s-40"
              pb="s-16"
              fontSize="caption"
              fontWeight={500}
            >
              You skipped this section
            </Text>
            <TextArea
              label="Reason"
              required
              onChange={({ target }) => {
                // @ts-ignore value exists
                onSkip?.(target.value)
              }}
              value={justification}
            />
          </>
        )}
        {!skipSection && !hideCards && (
          <>
            {(description || subTitle) && (
              <Widget mt="s-16" p="s-16">
                {subTitle && <Text variant="primary">{subTitle}</Text>}
                {description && (
                  <Text mt="s-8" use="p">
                    {description}
                  </Text>
                )}
              </Widget>
            )}
            {type === PerformanceReviewTypes.deliverables && (
              <Box mt="s-16">
                <DeliverablesJustifications
                  viewMode={viewMode}
                  viewJustifications={deliverablesJustifications}
                />
              </Box>
            )}
            <>
              {isValueBasedBehaviour ? (
                <Content>
                  {cards.map((card, cardIndex) => (
                    <ScorecardValueBasedSection
                      card={card as ValueBasedCardInterface}
                      cardIndex={cardIndex}
                      category={category}
                      errors={errors?.[cardIndex]}
                      key={`${(card as ValueBasedCardInterface).value_id}`}
                      onChange={onChange}
                      sectionErrors={errors}
                      superpowersCount={superpowersCount}
                      touched={touched}
                      type={type}
                      valueId={(card as ValueBasedCardInterface).value_id}
                      viewMode={viewMode}
                    />
                  ))}
                </Content>
              ) : (
                <Content>
                  {cards.map((card, cardIndex) => (
                    <ScorecardSection
                      skillId={`${
                        (card as SkillCardInterface).skill_id ||
                        (card as SkillCardInterface).criteria_key
                      }`}
                      cardIndex={cardIndex}
                      key={`${
                        (card as SkillCardInterface).skill_id ||
                        (card as SkillCardInterface).criteria_key
                      }`}
                      disabled={disabled}
                      type={type}
                      category={category}
                      onChange={onChange}
                      viewMode={viewMode}
                      errors={errors?.[cardIndex]}
                      sectionErrors={errors}
                      touched={touched}
                      previousReviews={showPreviousRatings ? previousReviews : undefined}
                      card={card as SkillCardInterface}
                      resultLoading={
                        !!(card as SkillCardInterface).skill_id &&
                        resultsLoading.includes((card as SkillCardInterface).skill_id!)
                      }
                      onSetResultLoading={setResultsLoading}
                      titleTooltip={(card as SkillCardInterface).titleTooltip}
                      hideJustification={hideJustification}
                      showErrorLabel
                    />
                  ))}
                  {children}
                </Content>
              )}
            </>
          </>
        )}
        {!viewMode && (
          <HideIfCommercial>
            <HelpSidebar
              isOpen={isHelpOpen}
              helpSection={helpSection}
              onExit={() => setHelpOpen(false)}
            />
          </HideIfCommercial>
        )}
      </Container>
      {feedbackList && (
        <AnytimeFeedbackSidebar
          isOpen={isFeedbackSidebarOpen}
          handleClose={() => setIsFeedbackSidebarOpen(false)}
          feedbackList={feedbackList}
          refetchFeedbackList={refetchFeedbackList}
          employeeId={employeeId}
        />
      )}
    </ShowPreviousValuesContext.Provider>
  )
}

export default connect(ScorecardGeneral)
