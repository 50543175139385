import React, { ChangeEvent, useEffect, useState } from 'react'
import toNumber from 'lodash/toNumber'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import isNaN from 'lodash/isNaN'
import { FilterOption } from '@src/interfaces/data'
import { Button, Dropdown, DropdownProps, Input, InputGroup } from '@revolut/ui-kit'
import NewGrid from '@components/CommonSC/NewGrid'

interface FilterNumberRangeProps
  extends Pick<DropdownProps, 'anchorRef' | 'fitInAnchor'> {
  open: boolean
  onClose: () => void
  multiplier?: number
  onChange: (options: FilterOption[]) => void
  value?: FilterOption[]
}

type InputValue = string | number | undefined

export const FilterNumberRange = ({
  value,
  onChange,
  multiplier,
  onClose,
  open,
  anchorRef,
  fitInAnchor,
}: FilterNumberRangeProps) => {
  const [from, setFrom] = useState<InputValue>(get(value, '[0].id', ''))
  const [to, setTo] = useState<InputValue>(get(value, '[1].id', ''))

  useEffect(() => {
    if (multiplier) {
      setFrom(from ? `${toNumber(from) * multiplier}` : '')
      setTo(to ? `${toNumber(to) * multiplier}` : '')
    }
  }, [multiplier])

  const onFromChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!isNaN(toNumber(e.target.value)) || '-.'.includes(e.target.value)) {
      setFrom(e.target.value)
    }
  }
  const onToChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!isNaN(toNumber(e.target.value)) || '-.'.includes(e.target.value)) {
      setTo(e.target.value)
    }
  }

  const onBlur = () => {
    if (from) {
      setFrom(`${toNumber(from)}`)
    }

    if (to) {
      setTo(`${toNumber(to)}`)
    }
  }

  const onClear = () => {
    onChange([])
    onClose()
  }

  const onApply = () => {
    const filters = []

    if (isNil(to) && isNil(from)) {
      onClear()
      return
    }

    let resultFrom = toNumber(from)

    if (multiplier) {
      resultFrom /= multiplier
    }
    filters.push({ id: resultFrom || '', name: `${resultFrom || ''}` })

    let resultTo = toNumber(to)
    if (multiplier) {
      resultTo /= multiplier
    }
    filters.push({ id: resultTo || '', name: `${resultTo || ''}` })

    if (resultTo || resultFrom) {
      onChange(filters)
    }
  }

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Enter') {
      onApply()
    } else if (e.key === 'Escape') {
      onClose()
    }
  }

  return (
    <Dropdown
      open={open}
      anchorRef={anchorRef}
      onClose={onClose}
      maxHeight={null}
      fitInAnchor={fitInAnchor}
    >
      <Dropdown.Group sticky="top" p="s-16" onKeyDown={onKeyDown}>
        <InputGroup>
          <Input
            type="number"
            value={from}
            label="From"
            onChange={onFromChange}
            onBlur={onBlur}
          />
          <Input
            type="number"
            value={to}
            label="To"
            onChange={onToChange}
            onBlur={onBlur}
          />
        </InputGroup>
      </Dropdown.Group>
      <Dropdown.Group sticky="bottom" p="s-16" onKeyDown={onKeyDown}>
        <NewGrid gridAutoFlow="column" gridColumnGap="1rem">
          <Button size="sm" width="100%" onClick={onClear} variant="secondary">
            Clear
          </Button>
          <Button size="sm" width="100%" onClick={onApply}>
            Apply
          </Button>
        </NewGrid>
      </Dropdown.Group>
    </Dropdown>
  )
}
