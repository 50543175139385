import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  HStack,
  Header,
  Item,
  MoreBar,
  Popup,
  Text,
  Token,
  Tooltip,
  VStack,
  chain,
  useTooltip,
} from '@revolut/ui-kit'
import { ArrowRepeat, Time, TimeOutline } from '@revolut/icons'
import {
  CandidateInterface,
  SnoozeCandidateInterface,
} from '@src/interfaces/interviewTool'
import { formatDate, formatWithoutTimezone } from '@src/utils/format'
import { utcToZonedTime } from 'date-fns-tz'
import { getCurrentTimezone } from '@src/utils/timezones'
import {
  snoozeCandidateApi,
  bulkSnoozeCandidateApi,
  unsnoozeCandidate,
  bulkUnsnoozeCandidate,
} from '@src/api/recruitment/interviews'
import { selectorKeys } from '@src/constants/api'
import { MultipleEmployees } from '@src/components/ColumnInserts/MultipleEmployees/MultipleEmployees'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { EmployeeInterface } from '@src/interfaces/employees'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import pluralize from 'pluralize'
import BulkStatusPopup from '@src/components/BulkStatusPopup/BulkStatusPopup'

type SnoozeContentProps = {
  activeSnoozing?: SnoozeCandidateInterface
  bulk: boolean
  candidateIds?: number[]
  onAfterSubmit?: () => void
  onClose: () => void
}

const getInitialDate = (until?: string) => {
  const to = until ? new Date(utcToZonedTime(until, getCurrentTimezone())) : new Date()
  // if there's no initial values then set it to 2 weeks from now
  if (!until) {
    to.setDate(to.getDate() + 14)
  }
  return to
}

const SnoozeFormContent = ({
  activeSnoozing,
  bulk,
  candidateIds,
  onAfterSubmit,
  onClose,
}: SnoozeContentProps) => {
  const user = useSelector(selectUser)
  const [success, setSuccess] = useState<boolean | null>(null)
  const { values, submit } = useLapeContext<SnoozeCandidateInterface>()
  useEffect(() => {
    if (!values.snooze_until) {
      values.snooze_until =
        activeSnoozing?.snooze_until ?? formatDate(getInitialDate(), 'yyyy-MM-dd')
    }
    if (!values.notify_employees) {
      values.notify_employees = activeSnoozing?.notify_employees ?? [user]
    }
    if (!values.comment) {
      values.comment = activeSnoozing?.comment
    }
    if (candidateIds) {
      values.candidate_ids = candidateIds
    }
  }, [])
  const handleClose = () => {
    onAfterSubmit?.()
    onClose()
  }
  const handleSnooze = async () => {
    if (values.snooze_until) {
      values.snooze_until = formatDate(values.snooze_until, 'yyyy-MM-dd')
    }
    await submit()
    if (bulk) {
      setSuccess(true)
    }
  }
  return (
    <>
      <Box bg={Token.color.widgetBackground} radius="widget" p="s-16">
        <VStack gap="s-16">
          <LapeDatePickerInput
            name="snooze_until"
            disabledDays={{ before: new Date() }}
          />
          <LapeNewMultiSelect
            name="notify_employees"
            placeholder="Select employees to be notified when snoozing period is over"
            selector={selectorKeys.employee}
          />
          <LapeNewInput name="comment" placeholder="Comment" />
        </VStack>
      </Box>
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <NewSaveButtonWithPopup
          disabled={!values.snooze_until}
          successText="Candidate snoozed successfully"
          onClick={handleSnooze}
          onAfterSubmit={() => {
            if (!bulk) {
              handleClose()
            }
          }}
          hideWhenNoChanges={false}
          noPopup={bulk}
        >
          Snooze
        </NewSaveButtonWithPopup>
      </Popup.Actions>
      {bulk && <BulkStatusPopup isSuccess={success} onClose={handleClose} />}
    </>
  )
}

type SnoozePopupProps = SnoozeContentProps & {
  candidateId?: number
  candidateIds?: number[]
}

const SnoozeForm = (props: SnoozePopupProps) => {
  return (
    <Form
      api={props.candidateIds ? bulkSnoozeCandidateApi : snoozeCandidateApi}
      forceParams={{
        id: props.candidateId?.toString(),
      }}
    >
      <SnoozeFormContent {...props} />
    </Form>
  )
}

export const SnoozePopup = ({
  bulk,
  candidateId,
  candidateIds,
  activeSnoozing,
  onAfterSubmit,
  onClose,
}: SnoozePopupProps) => {
  const candidatesLabel = pluralize('candidate', candidateIds?.length ?? 1)
  const candidateCountLabel = candidateIds?.length ? candidateIds.length : 'This'
  return (
    <Popup open variant="bottom-sheet" onClose={onClose}>
      <Header variant="compact">
        <Header.Title>Snooze {candidatesLabel}</Header.Title>
        <Header.Description>
          If snoozed, {candidateCountLabel} {candidatesLabel} won’t be visible in the
          Candidates table by default. You can see all the snoozed candidates in the table
          by using filters
        </Header.Description>
      </Header>
      <SnoozeForm
        activeSnoozing={activeSnoozing}
        bulk={bulk}
        candidateId={candidateId}
        candidateIds={candidateIds}
        onAfterSubmit={onAfterSubmit}
        onClose={onClose}
      />
    </Popup>
  )
}

type UnsnoozePopupProps = {
  bulk: boolean
  candidateId?: number
  candidateIds?: number[]
  onAfterSubmit?: () => void
  onClose: () => void
}

export const UnsnoozePopup = ({
  bulk,
  candidateId,
  candidateIds,
  onAfterSubmit,
  onClose,
}: UnsnoozePopupProps) => {
  const [success, setSuccess] = useState<boolean | null>(null)
  const handleClose = () => {
    onAfterSubmit?.()
    onClose()
  }
  const handleUnsnooze = async () => {
    if (candidateIds?.length) {
      await bulkUnsnoozeCandidate(candidateIds)
    } else if (candidateId) {
      await unsnoozeCandidate(candidateId)
    }
    if (bulk) {
      setSuccess(true)
    } else {
      handleClose()
    }
  }
  const candidatesLabel = pluralize('candidate', candidateIds?.length ?? 1)
  const candidateCountLabel = candidateIds?.length ? candidateIds.length : 'This'
  const actionLabel = bulk
    ? 'will be queued to be unsnoozed'
    : 'will be unsnoozed immediately'
  return (
    <>
      <ConfirmationDialog
        body={`Are you sure? ${candidateCountLabel} ${candidatesLabel} ${actionLabel}. No notifications will be sent`}
        label={`Unsnooze ${candidatesLabel}`}
        open
        noMessage="Close"
        yesMessage="Unsnooze"
        onClose={onClose}
        onConfirm={handleUnsnooze}
        onReject={onClose}
      />
      {bulk && <BulkStatusPopup isSuccess={success} onClose={handleClose} />}
    </>
  )
}

type SnoozeActionProps = {
  candidate: CandidateInterface
  onAfterSubmit: () => void
}

export const SnoozeAction = ({ candidate, onAfterSubmit }: SnoozeActionProps) => {
  const [open, setOpen] = useState(false)
  const isSnoozed = !!candidate.active_snoozing?.snooze_until
  const handleClose = () => {
    setOpen(false)
  }
  const PopupComponent = isSnoozed ? UnsnoozePopup : SnoozePopup
  return (
    <>
      <MoreBar.Action
        useIcon={isSnoozed ? ArrowRepeat : TimeOutline}
        onClick={() => {
          setOpen(true)
        }}
      >
        {isSnoozed ? 'Unsnooze' : 'Snooze'}
      </MoreBar.Action>
      {open && (
        <PopupComponent
          bulk={false}
          candidateId={candidate.id}
          onAfterSubmit={onAfterSubmit}
          onClose={handleClose}
        />
      )}
    </>
  )
}

type BulkSnoozeActionProps = {
  disabled: boolean
  unsnooze: boolean
  onClick: () => void
}

type BulkSnoozePopupProps = {
  candidateIds: number[]
  unsnooze: boolean
  onAfterSubmit: () => void
  onClose: () => void
  open: boolean
}

export const BulkSnoozeAction = ({
  onClick,
  disabled,
  unsnooze,
}: BulkSnoozeActionProps) => {
  const tooltip = useTooltip()
  return (
    <>
      <MoreBar.Action
        {...tooltip.getAnchorProps()}
        useIcon={unsnooze ? ArrowRepeat : TimeOutline}
        aria-disabled={disabled}
        onClick={() => {
          if (!disabled) {
            onClick()
          }
        }}
      >
        {unsnooze ? 'Unsnooze' : 'Snooze'}
      </MoreBar.Action>
      {disabled && (
        <Tooltip {...tooltip.getTargetProps()}>
          You can only bulk {unsnooze ? 'unsnooze' : 'snooze'} candidate(s) that are{' '}
          {unsnooze ? 'snoozed' : 'unsnoozed'}
        </Tooltip>
      )}
    </>
  )
}

export const BulkSnoozePopup = ({
  candidateIds,
  unsnooze,
  onAfterSubmit,
  open,
  onClose,
}: BulkSnoozePopupProps) => {
  const PopupComponent = unsnooze ? UnsnoozePopup : SnoozePopup

  if (!open) {
    return null
  }

  return (
    <PopupComponent
      bulk
      candidateIds={candidateIds}
      onAfterSubmit={onAfterSubmit}
      onClose={onClose}
    />
  )
}

type SnoozeCommentProps = {
  comment: string
  date: string
  snoozedBy: EmployeeInterface
}

const SnoozeComment = ({ comment, date, snoozedBy }: SnoozeCommentProps) => {
  return (
    <VStack gap="s-8">
      <Text>Comment: </Text>
      <VStack gap="s-8" m="s-8">
        <HStack align="center">
          {chain(
            <UserWithAvatar {...snoozedBy} status={snoozedBy.status.id} />,
            formatWithoutTimezone(date, true),
          )}
        </HStack>
        <Text>{comment}</Text>
      </VStack>
    </VStack>
  )
}

type SnoozeBannerProps = {
  candidate: CandidateInterface
  onAfterSubmit: () => void
}

export const SnoozeBanner = ({ candidate, onAfterSubmit }: SnoozeBannerProps) => {
  const [openEdit, setOpenEdit] = useState(false)
  const [openUnsnooze, setOpenUnsnooze] = useState(false)
  if (!candidate?.active_snoozing) {
    return null
  }
  const activeSnoozing = candidate.active_snoozing
  const snoozedUntil = formatDate(activeSnoozing.snooze_until)
  return (
    <>
      <Item>
        <Item.Avatar>
          <Avatar useIcon={<Time color={Token.color.blue} />} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>This candidate is snoozed until {snoozedUntil}</Item.Title>
          <Item.Description>
            <VStack gap="s-8">
              {activeSnoozing.notify_employees?.length && (
                <>
                  <Text>Notify when unsnoozed:</Text>
                  <MultipleEmployees
                    maxEmployees={3}
                    employees={activeSnoozing.notify_employees}
                  />
                </>
              )}
              {activeSnoozing.comment && (
                <SnoozeComment
                  comment={activeSnoozing.comment}
                  date={activeSnoozing.creation_date_time!}
                  snoozedBy={activeSnoozing.snoozed_by!}
                />
              )}
            </VStack>
          </Item.Description>
          <Item.Actions>
            <MoreBar>
              <MoreBar.Action
                variant="accent"
                onClick={() => {
                  setOpenEdit(true)
                }}
              >
                Edit
              </MoreBar.Action>
              <MoreBar.Action
                onClick={() => {
                  setOpenUnsnooze(true)
                }}
              >
                Unsnooze
              </MoreBar.Action>
            </MoreBar>
          </Item.Actions>
        </Item.Content>
      </Item>
      {openEdit && (
        <SnoozePopup
          activeSnoozing={activeSnoozing}
          bulk={false}
          candidateId={candidate.id}
          onAfterSubmit={onAfterSubmit}
          onClose={() => {
            setOpenEdit(false)
          }}
        />
      )}
      {openUnsnooze && (
        <UnsnoozePopup
          bulk={false}
          candidateId={candidate.id}
          onAfterSubmit={onAfterSubmit}
          onClose={() => {
            setOpenUnsnooze(false)
          }}
        />
      )}
    </>
  )
}
