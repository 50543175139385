import React, { useEffect, useState } from 'react'
import {
  Group,
  Subheader,
  Box,
  Cell,
  Item,
  Checkbox,
  Color,
  Button,
  SliderWidget,
  Slider,
  Text,
  StatusPopup,
} from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { PageBody } from '@src/components/Page/PageBody'
import {
  CutOffWarning,
  DependantItem,
  NoDependantsItem,
  useEmployeeBenefitsParams,
} from './common'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  EmployeeBenefitInterface,
  ExistingBenefitPackage,
} from '@src/interfaces/benefits'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import {
  DependantsNumberPreviewItem,
  EmployeeContributionPreviewItem,
  EmployerContributionPreviewItem,
} from '@src/pages/Forms/Benefits/common'
import UploadedFile, {
  UploadedFileToDownload,
} from '@src/components/Inputs/FileUploader/UploadedFile'
import InputErrorMessage from '@src/components/InputErrorMessage/InputErrorMessage'
import { getColor } from '@src/styles/colors'
import { useTheme } from '@src/styles/theme'
import { PageActions } from '@src/components/Page/PageActions'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl, history } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { enrolToBenefit } from '@src/api/benefits'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import FileUploader from '@src/components/Inputs/FileUploader/FileUploader'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { FileGroup } from '@src/components/Inputs/FileUploader/FileGroup'

export const PackageSelect = () => {
  const { initialValues, values, dirty, reset } =
    useLapeContext<EmployeeBenefitInterface>()
  const theme = useTheme()
  const params = useEmployeeBenefitsParams()
  const formValidator = useSafeFormValidator()

  const [consentChecked, setConsentChecked] = useState(false)
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)

  const hasEnrollmentData = values.enrolment != null
  const consentError = hasTriedToSubmit && !consentChecked
  const isRejected = initialValues.status?.id === 'rejected'
  const isOptedOut = initialValues.status?.id === 'opted_out'
  const isReEnrolAttempt = isRejected || isOptedOut

  const options = initialValues.template!.packages.map(p => ({
    label: p.name,
    value: p as ExistingBenefitPackage,
  }))

  useEffect(() => {
    if (values.selected_package != null) {
      return
    }
    /** Preselect the package if only one exists */
    if (!hasEnrollmentData && options.length === 1 && values.selected_package == null) {
      values.selected_package = options[0].value
    }
    if (hasEnrollmentData) {
      const benefitPackage = values.template.packages.find(
        p => p.id! === values.enrolment?.selected_package?.id,
      )
      /** Select already enrolled package for editing */
      if (benefitPackage) {
        values.selected_package = benefitPackage
        /** Preselect the package if only one exists - when user opted out and now wants to enrol */
      } else if (options.length === 1) {
        values.selected_package = options[0].value
      }
    }
    /** When the enrolment was rejected or opted out, and we want to enrol again, we move the previously submitted documents
     * from `values.enrolment.documents` to `values.documents` for resubmission, that's just how the BE works */
    if (
      isReEnrolAttempt &&
      values.enrolment?.documents.length &&
      !values.documents?.length
    ) {
      values.documents = [...values.enrolment.documents]
    }

    if (
      values.template?.employee_can_edit_contribution &&
      values.selected_package &&
      !values.employee_contribution
    ) {
      values.employee_contribution = values.selected_package.employee_min_contribution
    }
  }, [])

  const goToPreview = () => {
    window.scrollTo(0, 0)
    navigateReplace(pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT.PREVIEW, params), {
      /** This way when we go back to "preview", clicking on header back
       * button navigates user out of the form, instead of intermediary screen */
      history: history.location?.state?.history.slice(0, -1),
    })
  }

  const enrol = () =>
    enrolToBenefit({
      id: params.id,
      employeeId: params.employeeId,
      data: values,
    })
      .then(response => {
        reset({ ...response.data, field_options: initialValues.field_options })
        setShowSuccessPopup(true)
      })
      .catch(error => {
        setSubmitting(false)
        throw error
      })

  const onEnrol = () => {
    if (consentChecked || hasEnrollmentData) {
      setSubmitting(true)
      formValidator.validate(enrol)()
    } else {
      setHasTriedToSubmit(true)
      formValidator.forceErrors({ consent: true })
    }
  }

  return (
    <>
      <PageBody>
        <CutOffWarning />

        <Subheader variant="nested">
          <Subheader.Title>Select package</Subheader.Title>
        </Subheader>
        <LapeRadioSelectInput
          name="selected_package"
          label="Package"
          options={options}
          onChange={value => {
            if (value) {
              values.selected_package = value

              if (values.template.employee_can_edit_contribution) {
                values.employee_contribution = value.employee_min_contribution
              } else {
                values.employee_contribution = null
              }
              /** Different packages have different dependant requirements, so changing the package should reset the dependants */
              if (
                value.id !== initialValues.enrolment?.selected_package.id &&
                values.enrolment?.dependants
              ) {
                values.initialDependants = values.enrolment.dependants
                values.enrolment.dependants = []
                values.dependants = []
              }
              /** If swiching back to initial package, set the dependants info back to form state */
              if (
                value.id === initialValues.enrolment?.selected_package.id &&
                values.enrolment &&
                values.initialDependants
              ) {
                values.enrolment.dependants = values.initialDependants
                values.initialDependants = undefined
              }
            }
          }}
        />

        {values.selected_package ? (
          <>
            <Box mt="s-8">
              <FormPreview data={values.selected_package}>
                <Group>
                  <FormPreview.Item title="Package cover" field="cover" />
                  <EmployeeContributionPreviewItem
                    benefit={values.template}
                    benefitPackage={values.selected_package}
                  />
                  <EmployerContributionPreviewItem
                    benefit={values.template}
                    benefitPackage={values.selected_package}
                  />
                  <FormPreview.Item
                    title="Dependants allowed"
                    field="dependants_allowed"
                    type="boolean"
                  />
                  {values.selected_package?.dependants_allowed ? (
                    <DependantsNumberPreviewItem
                      benefitPackage={values.selected_package}
                    />
                  ) : null}
                </Group>
              </FormPreview>
            </Box>

            {values.selected_package?.dependants_allowed ? (
              <Box mt="s-16">
                <Subheader variant="nested">
                  <Subheader.Title>Add dependants</Subheader.Title>
                </Subheader>
                <Group>
                  {!values.dependants?.length && !values.enrolment?.dependants?.length ? (
                    <NoDependantsItem />
                  ) : null}
                  {values.enrolment?.dependants.map(dependant => (
                    <DependantItem
                      onClick={() =>
                        navigateTo(
                          pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.PREVIEW, {
                            employeeId: params.employeeId,
                            id: params.id,
                            dependantId: dependant.id,
                          }),
                        )
                      }
                      dependant={dependant}
                      key={dependant.id}
                    />
                  ))}
                  {values.dependants?.map((dependant, index) => (
                    <DependantItem
                      onClick={() =>
                        navigateTo(
                          `${pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.NEW, {
                            employeeId: params.employeeId,
                            id: params.id,
                          })}?index=${index}`,
                        )
                      }
                      dependant={dependant}
                      key={index}
                    />
                  ))}
                  <Item>
                    <Button
                      onClick={() =>
                        navigateTo(
                          pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.NEW, {
                            employeeId: params.employeeId,
                            id: params.id,
                          }),
                        )
                      }
                      variant="secondary"
                      size="sm"
                      useIcon={Plus}
                    >
                      Add dependant
                    </Button>
                  </Item>
                </Group>
              </Box>
            ) : null}

            {values.template.enrolment_documents_required ? (
              <Box mt="s-16">
                <Subheader variant="nested">
                  <Subheader.Title>Enrolment documents required</Subheader.Title>
                </Subheader>

                <Cell mb="s-16">
                  Download the documents provided below and after filling and signing
                  them, upload them using the attachment field at the bottom of this
                  section.
                </Cell>

                <Group>
                  {values.template.enrolment_documents?.map(document => (
                    <UploadedFileToDownload
                      id={document.id}
                      title={document.name}
                      size={document.size}
                      key={document.id}
                    />
                  ))}
                </Group>

                <Box mt="s-16">
                  <Subheader variant="nested">
                    <Subheader.Title>Attach enrolment documents</Subheader.Title>
                  </Subheader>
                  <FileUploader
                    value={values.files}
                    onChange={event => {
                      if (Array.isArray(event)) {
                        values.files = event
                      }
                      if (event == null) {
                        values.files = []
                      }
                    }}
                    multiple
                  />
                  {values.documents && values.documents.length > 0 ? (
                    <FileGroup mt="s-16">
                      {values.documents.map(document => (
                        <UploadedFile
                          title={document.name}
                          onDelete={() => {
                            values.documents = values.documents.filter(
                              doc => doc.id !== document.id,
                            )
                          }}
                          size={document.size}
                          key={document.id}
                        />
                      ))}
                    </FileGroup>
                  ) : null}
                  {!isReEnrolAttempt &&
                  values.enrolment?.documents &&
                  values.enrolment.documents.length > 0 ? (
                    <FileGroup mt="s-16">
                      {values.enrolment.documents.map(document => (
                        <UploadedFile
                          title={document.name}
                          onDelete={() => {
                            values.enrolment!.documents =
                              values.enrolment!.documents.filter(
                                doc => doc.id !== document.id,
                              )
                          }}
                          size={document.size}
                          key={document.id}
                        />
                      ))}
                    </FileGroup>
                  ) : null}
                </Box>
              </Box>
            ) : null}

            {values.template.employee_can_edit_contribution ? (
              <Box mt="s-16">
                <SliderWidget>
                  <SliderWidget.Title>
                    <Text>Employee contribution</Text>
                    <Text color={Color.BLUE}>{`${values.employee_contribution}%`}</Text>
                  </SliderWidget.Title>
                  <SliderWidget.Description>
                    Allowed contribution range:{' '}
                    {`${values.selected_package.employee_min_contribution}-${values.selected_package.employee_max_contribution}%`}
                  </SliderWidget.Description>
                  <Slider
                    step={0.1}
                    min={+values.selected_package.employee_min_contribution!}
                    max={+values.selected_package.employee_max_contribution!}
                    onChange={value => {
                      values.employee_contribution = `${value}`
                    }}
                    value={+values.employee_contribution!}
                    variant="slider"
                  />
                  <SliderWidget.Legend
                    min={`${values.selected_package.employee_min_contribution}%`}
                    max={`${values.selected_package.employee_max_contribution}%`}
                  />
                </SliderWidget>
              </Box>
            ) : null}

            {hasEnrollmentData ? null : (
              <Box mt="s-16" data-name="consent">
                <Item
                  style={{
                    backgroundColor: consentError
                      ? getColor(theme, Color.INPUT_ERROR_FOCUS)
                      : undefined,
                  }}
                >
                  <Item.Prefix height="max-content">
                    <Checkbox
                      checked={consentChecked}
                      onChange={e => setConsentChecked(e.currentTarget.checked)}
                    />
                  </Item.Prefix>
                  <Item.Content>
                    <Item.Title>
                      <Text fontWeight={400}>
                        I confirm that by submitting this information, I acknowledge that
                        Revolut will process my personal data to provide me with chosen
                        benefits. This will require Revolut to share my personal data with
                        third party benefits providers. I acknowledge that more
                        information may be found in Revolut's Global Employee Privacy
                        Notice.
                      </Text>
                    </Item.Title>
                  </Item.Content>
                </Item>
                {consentError ? (
                  <InputErrorMessage message="This field is mandatory" />
                ) : null}
              </Box>
            )}
          </>
        ) : null}
      </PageBody>

      <PageActions>
        {dirty && (!hasEnrollmentData || isReEnrolAttempt) ? (
          <Button onClick={onEnrol} pending={submitting} elevated>
            Submit
          </Button>
        ) : null}
        {hasEnrollmentData && !isReEnrolAttempt ? (
          <NewSaveButtonWithPopup
            onAfterSubmit={(result: EmployeeBenefitInterface) => {
              reset({ ...result, field_options: initialValues.field_options })
              goToPreview()
            }}
            useValidator
          />
        ) : null}
      </PageActions>

      <StatusPopup
        variant="success"
        open={showSuccessPopup}
        onClose={goToPreview}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>Success</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}
