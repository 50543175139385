import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { CountryHiringInterface } from '@src/interfaces/settings'
import { selectorKeys } from '@src/constants/api'
import { Color, Text, TextButton } from '@revolut/ui-kit'
import { ROUTES } from '../routes'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

type CountryHiringColumn = ColumnInterface<CountryHiringInterface>

export const countriesCountryHiringColumn: CountryHiringColumn = {
  type: CellTypes.text,
  idPoint: 'country.name',
  dataPoint: 'country.name',
  sortKey: 'country__name',
  filterKey: 'country',
  selectorsKey: selectorKeys.countries,
  title: 'Country',
  dynamicHyperlinks: ({ id }) =>
    pathToUrl(ROUTES.SETTINGS.HIRING_PROCESS.COUNTRY_HIRING_FORM, { id }),
}

export const countriesHiringLocationColumn: CountryHiringColumn = {
  type: CellTypes.insert,
  idPoint: 'country.locations',
  dataPoint: 'country.locations',
  sortKey: null,
  filterKey: 'country__locations',
  selectorsKey: selectorKeys.location,
  title: 'Locations',
  insert: ({ data }) =>
    (data.country.locations ?? []).map(location => location.name).join(', '),
}

export const countriesHiringEmploymentRiskColumn: CountryHiringColumn = {
  type: CellTypes.text,
  idPoint: 'employment_legal_risk',
  dataPoint: 'employment_legal_risk',
  sortKey: 'employment_legal_risk',
  filterKey: 'employment_legal_risk',
  selectorsKey: selectorKeys.country_hiring_preference_employment_legal_risks,
  title: 'Employment risk',
}

export const countriesHiringPreferredSpecialisationsColumn: CountryHiringColumn = {
  type: CellTypes.insert,
  idPoint: 'preferred_specialisations',
  dataPoint: 'preferred_specialisations',
  sortKey: null,
  filterKey: 'preferred_specialisations',
  selectorsKey: selectorKeys.specialisations,
  title: 'Preferred specialisations',
  textAlign: 'right',
  insert: ({ data }) => {
    if (data?.all_specialisations_preferred) {
      return 'All'
    }
    return data?.preferred_specialisations?.length ?? 0
  },
}

type CountryHiringEnabledProps = {
  hiringEnabled: boolean
}

export const CountryHiringEnabled = ({ hiringEnabled }: CountryHiringEnabledProps) => {
  return (
    <Text color={hiringEnabled ? Color.GREEN : Color.RED}>
      {hiringEnabled ? 'Active' : 'Disabled'}
    </Text>
  )
}

export const countriesHiringHiringColumn: CountryHiringColumn = {
  type: CellTypes.insert,
  idPoint: 'hiring_enabled',
  dataPoint: 'hiring_enabled',
  sortKey: 'hiring_enabled',
  filterKey: 'hiring_enabled',
  selectorsKey: selectorKeys.yes_no_uppercase_value_options,
  title: 'Hiring',
  insert: ({ data }) => <CountryHiringEnabled hiringEnabled={data.hiring_enabled} />,
}

export const countriesHiringActionsColumn: CountryHiringColumn = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'actions',
  insert: ({ data }) => (
    <TextButton
      to={pathToUrl(ROUTES.SETTINGS.HIRING_PROCESS.COUNTRY_HIRING_FORM, { id: data.id })}
      use={InternalLink}
    >
      Edit
    </TextButton>
  ),
}
