import React from 'react'
import { PerformanceRating, Ratings } from '@src/interfaces/performance'
import styled from 'styled-components'
import Tooltip, { placement as TooltipPlacement } from '@components/Tooltip/Tooltip'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { useTheme } from '@src/styles/theme'
import { relationToString } from '@src/features/Scorecard/constants'
import { DeliverableRating } from '@src/interfaces/deliverables'
import { ratingToColor } from '@src/utils/performance'
import Icon from '@components/Icon/Icon'
import RatingLabel, {
  RatingLabelTypes,
} from '@src/features/EmployeePerformance/RatingLabel'
import { colorGetter, getColor } from '@src/styles/colors'
import { Box, Color } from '@revolut/ui-kit'

interface Props {
  ratings: (Ratings | DeliverableRating)[]
  totalRating: PerformanceRating
  placement: TooltipPlacement
  justifyContent?: string
  className?: string
  withCount?: boolean
  onClick?: () => void
  type?: RatingLabelTypes
  showInfoIcon?: boolean
  children?: React.ReactNode
}

const TooltipBody = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 15px;
  grid-row-gap: 8px;
  align-items: center;
  white-space: nowrap;
  border-radius: 4px;
  box-shadow: 0 14px 28px rgba(25, 28, 31, 0.04), 0 8px 16px rgba(25, 28, 31, 0.04),
    0px -1px 0px rgba(25, 28, 31, 0.04);
  min-width: max-content;
`

const PersonalRating = styled.div<{ background: string | null }>`
  min-width: 100px;
  font-size: 14px;
  padding: 6px;
  background: ${({ background, theme }) =>
    background ?? getColor(theme, Color.GREY_50_OPAQUE_10)};
  text-align: center;
  border-radius: 4px;
`

const Name = styled.div`
  font-size: 11px;
`

const Relation = styled.span`
  color: ${colorGetter(Color.GREY_TONE_50)};
  padding-left: 6px;
`

const Underlined = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const RatingCount = styled.span`
  font-size: 10px;
  margin-left: 8px;
`

const RatingIcon = styled(Icon)<{ color?: string | null }>`
  color: ${props => props.theme.colors['grey-tone-50']};
  margin-left: 6px;
`

const RatingTooltip = ({
  ratings,
  totalRating,
  placement,
  justifyContent,
  className,
  withCount,
  onClick,
  showInfoIcon,
  type,
  children,
}: Props) => {
  const theme = useTheme()
  const title = onClick ? (
    <Underlined onClick={onClick}>{PerformanceRatingTitle[totalRating]}</Underlined>
  ) : (
    PerformanceRatingTitle[totalRating]
  )

  const renderContent = () => {
    if (children) {
      return children
    }

    return (
      <>
        {withCount && ratings.length > 1 ? (
          <RatingLabel rating={totalRating} type={type}>
            {title} <RatingCount>{ratings.length}</RatingCount>
          </RatingLabel>
        ) : (
          <RatingLabel rating={totalRating} type={type}>
            {title}
          </RatingLabel>
        )}
        {showInfoIcon && <RatingIcon type="Info" size="tiny" />}
      </>
    )
  }

  if (!ratings?.length) {
    return (
      <Box>
        <RatingLabel rating={totalRating} noUnderline type={type}>
          {title}
        </RatingLabel>
      </Box>
    )
  }

  return (
    <Tooltip
      body={
        <TooltipBody>
          {ratings.map((rating, i) => {
            return (
              <React.Fragment key={i}>
                <PersonalRating background={ratingToColor(theme, rating.value)}>
                  <RatingLabel rating={rating.value} type={RatingLabelTypes.Inverse} />
                </PersonalRating>
                <Name>
                  {rating.review?.reviewer?.display_name}
                  {rating.review?.probation_checkpoint && (
                    <Relation>CP{rating.review.probation_checkpoint.number}</Relation>
                  )}
                  {rating.review.reviewer_relation && (
                    <Relation>
                      ({relationToString(rating.review.reviewer_relation, true)})
                    </Relation>
                  )}
                </Name>
              </React.Fragment>
            )
          })}
        </TooltipBody>
      }
      placement={placement}
      justifyContent={justifyContent}
      backgroundColor={theme.colors.background}
      className={className}
      hide={!ratings.length}
    >
      {renderContent()}
    </Tooltip>
  )
}

export default RatingTooltip
