import { PageHeader } from '@components/Page/Header/PageHeader'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageWrapper } from '@components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import { InputGroup } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageActions } from '@components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { connect } from 'lape'
import { userRequest } from '@src/api/accessManagement'
import {
  AssignGroupAccessManagementInterface,
  UserAccessManagementInterface,
} from '@src/interfaces/accessManagement'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { useGetAccessManagementSettings } from '@src/api/settings'

const AssignUserToPermissionGroup = () => {
  const { userId } = useParams<{ userId: string }>()
  const { values } = useLapeContext<AssignGroupAccessManagementInterface>()
  const backUrl = pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_USER.GROUPS, { id: userId })
  const [user, setUser] = useState<UserAccessManagementInterface>()
  const { data: settings } = useGetAccessManagementSettings()

  const isGroupSource = values.source?.id === 'group'

  useEffect(() => {
    const fetchUser = async () => {
      const resp = await userRequest.get({ id: userId })
      setUser(resp.data)
      values.user = resp.data
    }

    fetchUser()

    if (!values.start_date_time) {
      values.start_date_time = new Date().toISOString()
    }

    if (!values.end_date_time) {
      values.end_date_time = null
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader
        title={!values.id ? 'Assign new permissions group' : 'Edit permission group'}
        subtitle={
          user?.user_type === 'employee' ? (
            <UserWithAvatar {...user.employee} />
          ) : (
            user?.email
          )
        }
        backUrl={backUrl}
      />
      <PageBody>
        <AutoStepper>
          <InputGroup>
            <LapeRadioSelectInput
              name="group"
              label="Group"
              selector={selectorKeys.groups}
              disabled={isGroupSource}
            />
            {settings?.enable_limited_time_access ? (
              <InputGroup variant="horizontal">
                <LapeDatePickerInput
                  disabledDays={{ before: new Date() }}
                  defaultValue={new Date()}
                  required
                  name="start_date_time"
                  label="From Date"
                  disabled={isGroupSource}
                />
                <LapeDatePickerInput
                  disabledDays={
                    values.start_date_time
                      ? { before: new Date(values.start_date_time) }
                      : undefined
                  }
                  required
                  name="end_date_time"
                  label="To Date"
                  disabled={isGroupSource}
                />
              </InputGroup>
            ) : null}
            <LapeNewTextArea
              name="reason"
              label="Notes"
              rows={2}
              required
              disabled={isGroupSource}
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      {isGroupSource ? null : (
        <PageActions>
          <NewSaveButtonWithPopup
            afterSubmitUrl={backUrl}
            useValidator
            successText="User has been assigned to the group successfully"
          />
        </PageActions>
      )}
    </PageWrapper>
  )
}

export default connect(AssignUserToPermissionGroup)
