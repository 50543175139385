import React from 'react'
import { ROUTES } from '@src/constants/routes'
import JobPostingsGeneral from '@src/pages/Settings/JobPosting/General'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import ApplicationForm from '@src/pages/Settings/JobPosting/ApplicationForm/ApplicationForm'

export const JobPostingSettings = () => {
  useSetDocumentTitle('Settings', 'Job postings')
  const permissions = useSelector(selectPermissions)
  const canViewJobPostingPreferences = permissions.includes(
    PermissionTypes.ViewJobPostingPreferences,
  )
  const canViewApplicationFormSections = permissions.includes(
    PermissionTypes.ViewApplicationFormPreferences,
  )
  const tabs = [
    {
      title: 'Job postings',
      path: ROUTES.SETTINGS.JOB_POSTING.GENERAL,
      url: ROUTES.SETTINGS.JOB_POSTING.GENERAL,
      canView: canViewJobPostingPreferences,
      component: JobPostingsGeneral,
    },
    {
      title: 'Application forms',
      path: ROUTES.SETTINGS.JOB_POSTING.APPLICATION_FORM_SETTINGS,
      url: ROUTES.SETTINGS.JOB_POSTING.APPLICATION_FORM_SETTINGS,
      canView: canViewApplicationFormSections,
      component: ApplicationForm,
    },
  ].filter(tab => tab.canView)
  return (
    <PageWrapper>
      <PageHeader title="Job postings settings" backUrl={ROUTES.SETTINGS.ALL} />
      <TabBarNavigation tabs={tabs} />
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}
