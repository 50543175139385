import React from 'react'
import capitalize from 'lodash/capitalize'
import { Box, Color, HStack, IconButton, Text } from '@revolut/ui-kit'
import { ExclamationMark, LinkExternal } from '@revolut/icons'

import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { KeyPersonResponsibilityAssignmentInterface } from '@src/interfaces/keyPerson'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { getResponsibilityAssignmentStatusColor } from '@src/apps/People/KeyPersons/helpers'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '../routes'
import { selectorKeys } from '../api'
import { getStatusColor } from '@components/CommonSC/General'

export const keyPersonsResponsibilitiesAssignmentsEmployeeColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.id',
    dataPoint: 'employee.full_name',
    sortKey: 'employee__full_name',
    filterKey: 'employee__id',
    selectorsKey: selectorKeys.employee,
    title: 'Employee',
    insert: ({ data }) =>
      data.employee && data.employee.status !== 'terminated' ? (
        <UserWithAvatar name={data.employee.full_name} avatar={data.employee.avatar} />
      ) : (
        <Box ml="s-4">
          <HStack space="s-4" align="center">
            <Box>
              <ExclamationMark size={15} color={Color.RED} />
            </Box>
            {data.employee && (
              <UserWithAvatar
                name={data.employee.full_name}
                avatar={data.employee.avatar}
              />
            )}
          </HStack>
        </Box>
      ),
  }

export const keyPersonsResponsibilitiesAssignmentsEmployeeStatusColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.status',
    dataPoint: 'employee.status',
    sortKey: 'employee__status',
    filterKey: 'employee__status',
    selectorsKey: selectorKeys.employee_status_pending,
    title: 'Status',
    insert: ({ data }) =>
      data.employee ? (
        <Text color={getStatusColor(data.employee.status)}>
          {capitalize(data.employee.status?.replace('_', ' '))}
        </Text>
      ) : (
        '-'
      ),
  }

export const keyPersonsResponsibilitiesAssignmentsResponsibilityColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'key_person_type.id',
    dataPoint: 'key_person_type.name',
    sortKey: 'key_person_type__name',
    filterKey: 'key_person_type__id',
    selectorsKey: selectorKeys.key_person_types_name,
    title: 'Responsibility',
  }

export const keyPersonsResponsibilitiesAssignmentsResponsibilityCategoryColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'key_person_type.category.id',
    dataPoint: 'key_person_type.category.name',
    sortKey: 'key_person_type__category__name',
    filterKey: 'key_person_type__category__id',
    selectorsKey: selectorKeys.key_person_types_categories,
    title: 'Responsibility category',
  }

export const keyPersonsResponsibilitiesAssignmentsUnitColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'entity.id',
    dataPoint: 'entity.name',
    sortKey: 'entity__name',
    filterKey: 'entity__id',
    selectorsKey: selectorKeys.entity,
    title: 'Unit',
  }

export const keyPersonsResponsibilitiesAssignmentsStartDateColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.date,
    idPoint: 'start_date',
    dataPoint: 'start_date',
    sortKey: 'start_date',
    filterType: FilterType.date,
    filterKey: 'start_date',
    selectorsKey: selectorKeys.none,
    title: 'Start date',
  }

export const keyPersonsResponsibilitiesAssignmentsEndDateColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.date,
    idPoint: 'end_date',
    dataPoint: 'end_date',
    sortKey: 'end_date',
    filterType: FilterType.date,
    filterKey: 'end_date',
    selectorsKey: selectorKeys.none,
    title: 'End date',
  }

export const keyPersonsResponsibilitiesAssignmentsPeriodColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'assignment_period.id',
    dataPoint: 'assignment_period.name',
    sortKey: 'assignment_period__name',
    filterKey: 'assignment_period__id',
    selectorsKey: selectorKeys.key_risk_profile_assignment_period,
    title: 'Period',
  }

export const keyPersonsResponsibilitiesAssignmentsSuccessionColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'id',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Succession',
    insert: ({ data }) =>
      data.succession_page_reverse_link ? (
        <IconButton
          color={Color.BLUE}
          size={16}
          useIcon={LinkExternal}
          onClick={e => {
            e.stopPropagation()
            navigateTo(
              pathToUrl(ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.DETAILS, {
                id: data.succession_page_reverse_link,
              }),
            )
          }}
        />
      ) : (
        '-'
      ),
  }

export const keyPersonsResponsibilitiesAssignmentsStatusColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'assignment_status.id',
    dataPoint: 'assignment_status.name',
    sortKey: 'assignment_status__name',
    filterKey: 'assignment_status',
    selectorsKey: selectorKeys.key_risk_profile_assignment_statuses,
    title: 'Assignment status',
    colors: (data, theme) =>
      getResponsibilityAssignmentStatusColor(theme, data.assignment_status?.id),
  }

export const keyPersonsResponsibilitiesAssignmentsActionsColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'id',
    sortKey: 'name',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  }
