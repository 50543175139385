import React from 'react'
import { useParams } from 'react-router-dom'

import { performanceReviewLayoutRequests } from '@src/api/performanceReview'
import { connect } from 'lape'
import { ReviewCategory, ReviewScorecardInterface } from '@src/interfaces/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import PerformanceCycleClosed from '@components/ScorecardGeneral/PerformanceCycleClosed'
import Form from '@src/features/EmployeePerformance/Form'
import PerformancePage from '@src/pages/Forms/EmployeePerformance/components/PerformancePage'
import PerformanceErrorWidget from '@src/pages/Forms/EmployeePerformance/components/PerformanceErrorWidget'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { usePutFirstScorecardOpening } from '@src/utils/performance'
import { ScorecardContent } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import PerformanceHeaderCompact from '@src/pages/Forms/EmployeePerformance/components/PerformanceHeader'

const EmployeePerformance = connect(() => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  usePutFirstScorecardOpening(values, ReviewCategory.Performance)

  if (!performanceSettings) {
    return <PageLoading />
  }

  if (!values.review_data) {
    return <PerformanceErrorWidget />
  }

  return (
    <PerformancePage showHeader={false}>
      {!values.current_user_can_edit_review ? (
        <>
          <PerformanceHeaderCompact />
          <PerformanceCycleClosed employeeId={values.reviewed_employee.id!} />
        </>
      ) : (
        <ScorecardContent />
      )}
    </PerformancePage>
  )
})

export const EmployeePerformanceLayout = connect(() => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()

  return (
    <Form
      api={performanceReviewLayoutRequests}
      storageKey={`${ReviewCategory.Performance}-${id}-${employeeId}`}
    >
      <EmployeePerformance />
    </Form>
  )
})
