import React, { useMemo } from 'react'
import { useTableReturnType } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import {
  SummarySubheader,
  SummaryTableContainer,
  summaryTableProps,
} from '@src/features/Summary/common'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useParams } from 'react-router'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { chain, Flex, Widget } from '@revolut/ui-kit'
import { LeadershipPositionInterface, LeadershipStats } from '@src/interfaces/leadership'
import {
  getLeadershipPositionActionColumn,
  leadershipEmployeeNameColumn,
  leadershipPositionNameColumn,
  leadershipPositionStatusColumn,
  leadershipSeniorityColumn,
  leadershipSpecialisationColumn,
} from '@src/constants/columns/leadership'
import { navigateToLeadershipForm } from '@src/pages/Forms/DepartmentForm/Leadership/Leadership'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { withoutFilterAndSort } from '@src/utils/table'
import Stat from '@components/Stat/Stat'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import { PermissionTypes } from '@src/store/auth/types'
import { TableNames } from '@src/constants/table'

export interface LeadershipSummaryProps {
  table: useTableReturnType<LeadershipPositionInterface, LeadershipStats>
  department: DepartmentInterface
}

const getRow = (
  onEdit: (rowData: LeadershipPositionInterface) => void,
  canAssign: boolean,
): RowInterface<LeadershipPositionInterface> => ({
  cells: withoutFilterAndSort([
    {
      ...leadershipPositionNameColumn,
      width: 180,
    },
    {
      ...leadershipEmployeeNameColumn,
      width: 220,
    },
    {
      ...leadershipSpecialisationColumn,
      width: 180,
    },
    {
      ...leadershipSeniorityColumn,
      width: 120,
    },
    {
      ...leadershipPositionStatusColumn,
      width: 120,
    },
    ...(canAssign
      ? [
          {
            ...getLeadershipPositionActionColumn(onEdit),
            width: 120,
          },
        ]
      : []),
  ]),
})

const LeadershipSummary = ({ table, department }: LeadershipSummaryProps) => {
  const params = useParams<{ id?: string }>()
  const canAssign = !!department.field_options.permissions?.includes(
    PermissionTypes.CanAssignLeadershipPositionsDepartment,
  )

  const onEdit = (rowData: LeadershipPositionInterface) =>
    navigateToLeadershipForm(department, rowData)

  const row = useMemo(() => getRow(onEdit, canAssign), [department, canAssign])

  if (!params.id) {
    return null
  }
  const hasPerformancePermission = !!department.field_options.permissions?.includes(
    PermissionTypes.ViewEmployeePerformance,
  )

  return (
    <>
      <SummarySubheader formUrl={pathToUrl(ROUTES.FORMS.DEPARTMENT.LEADERSHIP, params)}>
        <Flex alignItems="center">
          {chain('Leadership', department.leadership_positions_count)}
        </Flex>
      </SummarySubheader>
      <Widget p="s-16">
        <Flex mb="s-16">
          {hasPerformancePermission && (
            <Stat
              label="NIPS"
              val={table.stats && formatPercentage(table.stats.nips)}
              color={getPercentColor(table.stats && table.stats.nips * 100)}
              mr="s-24"
            />
          )}
          <Stat
            label="Occupied positions"
            val={table.stats?.occupied_positions}
            mr="s-24"
          />
          <Stat
            label="Unassigned positions"
            color={table.stats?.unassigned_positions ? 'red' : undefined}
            val={table.stats?.unassigned_positions}
            mr="s-24"
          />
        </Flex>
        <Flex flexDirection="column" width="100%">
          <SummaryTableContainer>
            <AdjustableTable<LeadershipPositionInterface, LeadershipStats>
              name={TableNames.LeadershipSummary}
              dataType="Leadership position"
              row={row}
              {...table}
              noDataMessage="No leadership positions"
              onRowClick={canAssign ? onEdit : undefined}
              {...summaryTableProps}
              hideCountAndButtonSection
            />
          </SummaryTableContainer>
        </Flex>
      </Widget>
    </>
  )
}

export default LeadershipSummary
