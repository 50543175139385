import { ActionButton, Flex, Icon, TableWidget, TextButton, Token } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { selectorKeys } from '@src/constants/api'
import { analyticsDashboardNameColumn } from '@src/constants/columns/analyticsDashboard'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { CellTypes } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import React from 'react'

export const DashboardsList = ({
  onAddDashboard,
  onViewRequested,
}: {
  onAddDashboard: () => void
  onViewRequested: (dashboard: AnalyticsDashboardInterface) => void
}) => {
  const { values } = useLapeContext<GoalsInterface>()

  return values.dashboards?.length ? (
    <TableWidget style={{ padding: 0 }}>
      <TableWidget.Actions>
        <ActionButton useIcon="16/Plus" onClick={() => onAddDashboard()}>
          Add dashboard
        </ActionButton>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable<AnalyticsDashboardInterface & { actions_column?: never }>
          name={TableNames.RoadmapsMain}
          data={values.dashboards}
          count={values.dashboards.length}
          dataType="Dashboard"
          row={{
            cells: [
              {
                ...analyticsDashboardNameColumn,
                width: 300,
              },
              {
                type: CellTypes.insert,
                idPoint: 'actions_column',
                dataPoint: 'actions_column',
                sortKey: null,
                filterKey: null,
                selectorsKey: selectorKeys.none,
                insert: ({ data }) => {
                  return (
                    <Flex gap="s-8" justifyContent="flex-end">
                      <TextButton
                        onClick={() => onViewRequested(data)}
                        color={Token.color.lightBlue}
                      >
                        <Icon name="Pencil" size={16} color={Token.color.greyTone50} />
                      </TextButton>
                      <TextButton
                        onClick={() => {
                          values.dashboards = values.dashboards.filter(
                            dashboard => dashboard.id !== data.id,
                          )
                        }}
                      >
                        <Icon name="Delete" size={16} color={Token.color.greyTone50} />
                      </TextButton>
                    </Flex>
                  )
                },
                title: '',
                width: 40,
              },
            ],
          }}
        />
      </TableWidget.Table>
    </TableWidget>
  ) : null
}
