import React from 'react'
import PageLoading from '@components/PageLoading/PageLoading'
import TeamDeprecated from '@src/pages/Forms/TeamForm'
import { TeamLayout } from '@src/pages/Team/Layout'
import { useIsNewOrgLayout } from '@src/pages/Team/helpers'

export const Team = () => {
  const isNewLayout = useIsNewOrgLayout()

  if (isNewLayout === undefined) {
    return <PageLoading />
  }

  return isNewLayout ? <TeamLayout /> : <TeamDeprecated />
}
