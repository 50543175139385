import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Icon from '@components/Icon/Icon'
import Tooltip from '@components/Tooltip/Tooltip'
import { RadioButton } from '@components/Inputs/RadioButtons/RadioButtons'
import { focusElemByKeyboard, getListIndicator, ListType } from '@src/utils'
import Menu from '@components/Modals/Menu/Menu'
import { colorGetter, getColor } from '@src/styles/colors'
import { Box, Color, Token } from '@revolut/ui-kit'
import { LockClosed } from '@revolut/icons'
import Loader from '@components/CommonSC/Loader'

export interface RadioOption {
  id: string | number | boolean
  name: React.ReactNode
  text?: string
  summary?: string
  disabled?: boolean
  color?: string
}

interface RadioSelectProps {
  id: string
  onChange: (option: RadioOption) => void
  options: RadioOption[]
  value: RadioOption
  width?: number
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right'
  tooltipTitle?: string
  tooltipText?: string
  disabled?: boolean
  listStyle?: 'letters'
  onClick?: () => void
  className?: string
  placeholder?: string
  italicPlaceholder?: boolean
  locked?: boolean
  loading?: boolean
}

const Container = styled.div<{
  error?: string | boolean
  disabled?: boolean
  open: boolean
}>`
  position: relative;
  display: grid;
  background-color: inherit;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  min-height: 38px;
  height: 100%;
  padding: 0 13px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
  border: 1px solid ${props => (props.open ? props.theme.colors.blue : 'transparent')};
`

const TriggerButton = styled.button`
  outline: none;
  border: 0;
  background-color: inherit;
  width: 100%;
  overflow: hidden;
  text-transform: capitalize;
  height: 100%;

  &:disabled {
    color: ${Token.color.foreground};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
  }
`

const TriggerButtonIcon = styled(Icon)<{ active?: boolean }>`
  margin-left: 6px;
  color: ${props =>
    getColor(props.theme, props.active ? Color.FOREGROUND : Color.GREY_20_OPAQUE_90)};

  &:hover {
    color: ${colorGetter(Color.GREY_TONE_50)};
  }
`

const Content = styled.div<{
  width?: number
  isTopDirection: boolean
  containerHeight: number
}>`
  position: absolute;
  box-shadow: 0 6px 20px rgba(25, 28, 31, 0.1), 0 0 8px rgba(25, 28, 31, 0.1);
  width: ${props => props.width}px;
  border-radius: 4px;
  display: grid;
  padding: 5px 0;
  max-height: 300px;
  overflow: auto;
  grid-template-columns: 1fr;
  grid-row-gap: 6px;
  z-index: ${props => props.theme.zIndex.popup};
  background-color: ${props => props.theme.colors.background};
  ${props =>
    props.isTopDirection
      ? `top: 0`
      : `bottom: calc(100% + ${props.containerHeight}px + 16px)`};
`

const Dot = styled.div`
  background-color: inherit;
  position: absolute;
  bottom: -4px;
  left: 0;
`

const ButtonSpan = styled.div`
  margin-left: 8px;
`

const ButtonName = styled.span`
  text-transform: capitalize;
`

const ButtonNameSummary = styled.span`
  color: ${colorGetter(Color.GREY_TONE_50)};
`

const ButtonText = styled.div<{ disabled?: boolean }>`
  white-space: pre-wrap;
  color: ${({ theme, disabled }) =>
    getColor(theme, disabled ? Color.GREY_20_OPAQUE_90 : Color.GREY_TONE_50)};
  margin-top: 3px;
`

const ButtonTextItem = styled.div`
  display: flex;
`
const ButtonTextLetter = styled.span`
  padding-right: 8px;
`

const Placeholder = styled.span<{ italic?: boolean }>`
  color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
  font-style: ${props => (props.italic ? 'italic' : 'normal')};
`

const Cut = styled.span<{ color?: string }>`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.color || 'inherit'};
`

const OverflowTooltip = styled(Tooltip)`
  height: 100%;
  width: 100%;
`

const TriggerButtonWrap = styled.span`
  width: 100%;
  height: 100%;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LoaderStyled = styled(Loader)`
  color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
`

interface PositionInterface {
  y?: number
  height?: number
  containerHeight?: number
}

export const RadioSelect = ({
  id,
  options,
  onChange,
  value,
  width,
  tooltipPlacement = 'left',
  tooltipTitle,
  tooltipText,
  disabled,
  listStyle,
  onClick,
  className,
  locked,
  placeholder = 'Select',
  italicPlaceholder = false,
  loading,
}: RadioSelectProps) => {
  const container = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)
  const optionsRef = useRef<HTMLDivElement[]>([])

  const [position, setPosition] = useState<PositionInterface>({})

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    if (open) {
      handleClose()
    } else if (onClick) {
      onClick()
    } else {
      setOpen(true)
    }
  }

  useEffect(() => {
    if (open && contentRef.current && container.current) {
      const contentRect = contentRef.current.getBoundingClientRect()
      const containerWidth = container.current.getBoundingClientRect()

      setPosition({
        y: containerWidth.y,
        height: contentRect.height,
        containerHeight: containerWidth.height,
      })
    }

    if (open) {
      setActiveIndex(-1)
    }
  }, [open])

  const trigger = (
    <TriggerButton
      data-testid={id}
      type="button"
      onClick={handleOpen}
      disabled={disabled}
    >
      <OverflowTooltip
        placement={tooltipPlacement}
        title={tooltipTitle}
        text={tooltipText}
        hide={open || (!tooltipTitle && !tooltipText)}
        listStyle={listStyle}
      >
        <TriggerButtonWrap>
          <Cut color={value.color}>
            {value.name || (
              <Placeholder italic={italicPlaceholder}>{placeholder}</Placeholder>
            )}
          </Cut>
          {!disabled && (
            <TriggerButtonIcon active={Boolean(value.id)} type="ArrowDown" size="tiny" />
          )}
          {locked && (
            <Box ml="s-8">
              <LockClosed size={16} color={Color.FOREGROUND} opacity={0.3} />
            </Box>
          )}
        </TriggerButtonWrap>
      </OverflowTooltip>
    </TriggerButton>
  )

  const height = position.height || 0
  const y = position.y || 0

  const isTopDirection = window.innerHeight - height - 16 > y || height > y

  return (
    <Container
      ref={container}
      open={open}
      className={className}
      onKeyDown={e => {
        focusElemByKeyboard(e, activeIndex, setActiveIndex, optionsRef, options)

        if (e.key === 'Enter' && open) {
          onChange(options[activeIndex])
          handleClose()
        }

        if (e.key === 'Escape' && open) {
          handleClose()
        }
      }}
    >
      {loading ? <LoaderStyled size="medium" /> : trigger}
      {container?.current && open && (
        <Menu
          anchor={container.current}
          onClose={() => {
            setOpen(false)
          }}
          align="left"
        >
          <Dot>
            <Content
              width={width}
              ref={contentRef}
              containerHeight={position.containerHeight || 0}
              isTopDirection={isTopDirection}
            >
              {options.map((option, idx) => {
                return (
                  <RadioButton
                    type="list"
                    onChange={() => {
                      onChange(option)
                      handleClose()
                    }}
                    key={`${option.id}`}
                    inputRef={el => {
                      optionsRef.current[idx] = el
                    }}
                    alignTop
                    label={
                      option.name && (
                        <ButtonSpan>
                          <ButtonName>
                            {option.name}
                            {option.summary && (
                              <ButtonNameSummary> {option.summary}</ButtonNameSummary>
                            )}
                          </ButtonName>
                          {option.text && (
                            <ButtonText disabled={option.disabled}>
                              {listStyle === 'letters'
                                ? option.text.split('\\n').map((optionText, i) => (
                                    <ButtonTextItem key={i}>
                                      <ButtonTextLetter>
                                        {getListIndicator(i, ListType.letters)}
                                      </ButtonTextLetter>
                                      {optionText}
                                    </ButtonTextItem>
                                  ))
                                : option.text.charAt(0).toUpperCase() +
                                  option.text.slice(1)}
                            </ButtonText>
                          )}
                        </ButtonSpan>
                      )
                    }
                    disabled={option.disabled}
                    checked={value.id === option.id}
                    name={`option_${id}_${option.id}`}
                  />
                )
              })}
            </Content>
          </Dot>
        </Menu>
      )}
    </Container>
  )
}
