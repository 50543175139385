import React from 'react'
import { Box, Color, Text, Flex } from '@revolut/ui-kit'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { PerformanceRating, Ratings } from '@src/interfaces/performance'
import styled from 'styled-components'
import RatingTooltip from '@src/pages/EmployeeProfile/Preview/Performance/Common/RatingTooltip'
import { colorGetter } from '@src/styles/colors'
import IndividualContributorTooltip from '@src/pages/Forms/EmployeePerformance/components/IndividualContributorTooltip'
import { placement as TooltipPlacement } from '@components/Tooltip/Tooltip'

const Tooltip = styled(RatingTooltip)`
  color: ${colorGetter(Color.TEAL)};
`

interface SummaryRatingProps {
  rating?: PerformanceRating
  ratings?: Ratings[]
  tooltipPlacement?: TooltipPlacement
}

export const SummaryRating = ({
  rating,
  ratings,
  tooltipPlacement = 'left',
}: SummaryRatingProps) => {
  if (rating && ratings) {
    return (
      <Tooltip ratings={ratings} totalRating={rating} placement={tooltipPlacement}>
        <PerformanceRatingLabelTag rating={rating} fontSize="caption" />
      </Tooltip>
    )
  }
  if (rating) {
    return <PerformanceRatingLabelTag rating={rating} fontSize="caption" />
  }
  return null
}

type Props = {
  title: string
  rating?: PerformanceRating | null
  children: React.ReactNode
  tooltipRatings?: Ratings[]
  showIndividualContributorTooltip?: boolean
}

const SummaryCard = ({
  title,
  rating,
  tooltipRatings,
  children,
  showIndividualContributorTooltip,
}: Props) => {
  const renderContent = () => {
    if (tooltipRatings && rating) {
      return (
        <Flex data-testid="card-result">
          <SummaryRating rating={rating} ratings={tooltipRatings} />
          {showIndividualContributorTooltip && <IndividualContributorTooltip />}
        </Flex>
      )
    }

    if (rating) {
      return (
        <Flex data-testid="card-result">
          <SummaryRating rating={rating} />
          {showIndividualContributorTooltip && <IndividualContributorTooltip />}
        </Flex>
      )
    }

    return null
  }
  return (
    <Box data-testid={`card-table-${title}`}>
      <Flex justifyContent="space-between" alignItems="center" mb="s-16">
        <Text color={Color.GREY_TONE_50} fontWeight={500} fontSize="caption">
          {title}
        </Text>
        {renderContent()}
      </Flex>
      {children}
    </Box>
  )
}

export default SummaryCard
