import React, { useEffect, useState } from 'react'
import { TeamInterface } from '@src/interfaces/teams'
import {
  BaseChartInner,
  timeRangeTabs,
} from '@components/Charts/BaseChart/BaseChartInner'
import { connect } from 'lape'
import { PerformanceTimeRange } from '@src/constants/api'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { getCXSupportLoad } from '@src/api/cx'
import { CXSupportLoadInterface } from '@src/interfaces/businessDebts'
import {
  cxComplaintCreatedAtColumn,
  cxSupportTagColumn,
  cxSupportTicketIdColumn,
} from '@src/constants/columns/cx'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { getBarMonthFilter } from '@src/utils/graph'
import {
  Button,
  Flex,
  HStack,
  RadioSelect,
  SelectOptionItemType,
  Spacer,
  Text,
  useDropdown,
  Widget,
} from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { NoHeliosPermissions } from '@src/features/FormTabs/CX/components/NoAccessWidgets'
import CXTable from '@src/features/FormTabs/CX/components/CXTable'
import useChartTableSwitcher, {
  ChartTableTabs,
} from '@src/features/TabBarSwitcher/useChartTableSwitcher'
import { TableTypes } from '@src/interfaces/table'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { SupportLoadBreakdownBy } from '@src/interfaces/cx'
import { teamCXSupportLoadGraph } from '@src/api/teams'
import { departmentCXSupportLoadGraph } from '@src/api/department'
import { ChevronDown } from '@revolut/icons'
import { TableNames } from '@src/constants/table'

interface StructureProps {
  data: TeamInterface | DepartmentInterface
  isTeam?: boolean
}

const ROW: RowInterface<CXSupportLoadInterface> = {
  cells: [
    {
      ...cxSupportTicketIdColumn,
      width: 200,
    },
    {
      ...cxSupportTagColumn,
      width: 600,
    },
    {
      ...cxComplaintCreatedAtColumn,
      width: 200,
    },
  ],
}

export const defaultBreakdown = SupportLoadBreakdownBy.Tag
export const defaultTimeRange = PerformanceTimeRange.week

const NetContacts = ({ data, isTeam }: StructureProps) => {
  const [chartData, setChartData] = useState<PerformanceChartCycles>()
  const [chartLoading, setChartLoading] = useState<boolean>(true)
  const [breakdownBy, setBreakdownBy] = useState<SupportLoadBreakdownBy>(defaultBreakdown)

  const canViewComments = data.field_options.permissions?.includes(
    PermissionTypes.ViewSupportLoadComments,
  )
  const { tabBar, currentTab, setCurrentTab } = useChartTableSwitcher()
  const { tabBar: chartRangeTabBar, currentTab: chartRange } = useTabBarSwitcher({
    tabs: timeRangeTabs,
    capitalize: true,
    defaultTab: defaultTimeRange,
    onTabChange: tab => fetchChartData(tab, breakdownBy),
  })
  const [filter, setFilter] = useState<FilterByInterface | undefined>()

  const initialFilterBy = [
    {
      filters: [{ id: data.id, name: `${data.id}` }],
      columnName: isTeam ? 'team__id' : 'department__id',
      nonResettable: true,
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'creation_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  useEffect(() => {
    fetchChartData(defaultTimeRange, defaultBreakdown)
  }, [])

  const fetchChartData = async (
    timeRange: PerformanceTimeRange,
    breakdown: SupportLoadBreakdownBy,
  ) => {
    setChartLoading(true)
    const request = isTeam ? teamCXSupportLoadGraph : departmentCXSupportLoadGraph
    try {
      const result = await request(data.id, timeRange, breakdown)
      setChartData(result.data)
    } finally {
      setChartLoading(false)
    }
  }

  const tableTab = canViewComments ? (
    <CXTable<CXSupportLoadInterface>
      name={TableNames.CXNetContacts}
      dataType="item"
      row={ROW}
      filter={filter}
      type={TableTypes.NewStepper}
      noDataMessage="No net contacts data found"
      getItems={getCXSupportLoad}
      initialFilter={initialFilterBy}
      initialSort={initialSortBy}
    />
  ) : (
    <NoHeliosPermissions />
  )

  return (
    <Widget
      display="flex"
      p="s-16"
      width="100%"
      flex={currentTab === ChartTableTabs.Chart ? '1 0' : ''}
      style={{ position: 'relative' }}
    >
      <Flex flexDirection="column" flex="1 0" width="100%">
        <Flex mb="s-24" alignItems="center">
          <Stat
            label="Support tickets (last 28 days)"
            val={data.cx_support_load_last_30_days}
          />
        </Flex>
        <HStack mb="s-16" space="s-8">
          {tabBar}
          {chartRangeTabBar}
          <Spacer />
          <BreakdownSelect
            onSelect={val => {
              setBreakdownBy(val)
              if (chartRange) {
                fetchChartData(chartRange, val)
              }
            }}
          />
        </HStack>
        {currentTab === ChartTableTabs.Table && tableTab}
        {currentTab === ChartTableTabs.Chart && (
          <Flex flexDirection="column" flex="1 0" style={{ position: 'relative' }}>
            <BaseChartInner
              loading={chartLoading}
              data={chartData}
              isNew
              id={data.id}
              graphTimeRange={chartRange}
              highlightHoveredCells
              onClick={bar => {
                if (!canViewComments) {
                  return
                }
                setCurrentTab(ChartTableTabs.Table)
                setFilter(getBarMonthFilter(bar.data.progress_datetime))
              }}
            />
          </Flex>
        )}
      </Flex>
    </Widget>
  )
}

interface BreakdownSelectProps {
  onSelect: (value: SupportLoadBreakdownBy) => void
}
const breakdownOptions: SelectOptionItemType<SupportLoadBreakdownBy>[] = [
  { value: SupportLoadBreakdownBy.Category, label: 'Breakdown by CX category', key: 2 },
  { value: SupportLoadBreakdownBy.Tag, label: 'Breakdown by tags', key: 1 },
]

const BreakdownSelect = ({ onSelect }: BreakdownSelectProps) => {
  const dropdown = useDropdown()
  const targetProps = dropdown.getTargetProps<HTMLElement>()
  const [option, setOption] = useState<SelectOptionItemType<SupportLoadBreakdownBy>>(
    breakdownOptions.find(opt => opt.value === defaultBreakdown) || breakdownOptions[0],
  )
  return (
    <>
      <Button {...dropdown.getAnchorProps()} size="sm" variant="secondary">
        <Flex>
          {option.label}
          <Text pl="s-4">
            <ChevronDown />
          </Text>
        </Flex>
      </Button>
      <RadioSelect<SupportLoadBreakdownBy>
        {...targetProps}
        onChange={val => {
          if (val) {
            setOption(
              breakdownOptions.find(opt => opt.value === val) || breakdownOptions[0],
            )
            onSelect(val)
          }
          targetProps?.onClose()
        }}
        options={breakdownOptions}
        value={option.value}
        labelList="Breakdown options"
      />
    </>
  )
}

export default connect(NetContacts)
