import {
  CompetencyInterface,
  CompetencyMatrixInterface,
  CompetencyMatrixSkill,
  RoleInterface,
  SkillLevels,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import { SkillInterface } from '@src/interfaces/skills'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { RevolutTheme } from '@src/styles/theme'
import { competencyToColor } from '@src/utils/competency'
import produce from 'immer'
import { useGetSeniorityList } from '@src/api/seniority'
import { SORT_DIRECTION } from '@src/interfaces/data'

export const useGetMatrixValidatorAndSeniorities = (
  instance: RoleInterface | SpecialisationInterface,
  isSkillOptional?: boolean,
  deliverablesRequired?: boolean,
): {
  matrixValidated: boolean
  seniorities: SeniorityInterface[]
} => {
  const { data: senioritiesResponse } = useGetSeniorityList({
    sortBy: [{ sortBy: 'level', direction: SORT_DIRECTION.DESC }],
  })
  const seniorities = senioritiesResponse?.results || []

  if (
    !seniorities.length ||
    !instance.functional_competency_matrix ||
    !instance.seniority_min ||
    !instance.seniority_max
  ) {
    return { matrixValidated: false, seniorities }
  }

  const seniorityMin = instance.seniority_min.level
  const seniorityMax = instance.seniority_max.level
  const seniorityLevels: number[] = []

  const fromInd = seniorities.findIndex(el => el.level === seniorityMin)
  const toInd = seniorities.findIndex(el => el.level === seniorityMax) + 1
  seniorityLevels.push(...seniorities.slice(fromInd, toInd).map(el => el.level))

  if (deliverablesRequired) {
    const isDeliverablesValid = seniorityLevels.every(
      seniorityLevel =>
        'deliverables_competencies' in instance &&
        instance.deliverables_competencies?.find(
          competency => competency.seniority_level === seniorityLevel,
        )?.competency_level,
    )

    if (!isDeliverablesValid) {
      return { matrixValidated: false, seniorities }
    }
  }

  if (!instance.functional_competency_matrix?.length && isSkillOptional) {
    return { matrixValidated: true, seniorities }
  }

  const isValid = instance.functional_competency_matrix.every(item => {
    return (
      seniorityLevels.every(seniorityLevel => {
        return item.competencies?.find(
          competency => competency.seniority_level === seniorityLevel,
        )?.competency_level
      }) && item.skill?.id
    )
  })
  return { matrixValidated: isValid, seniorities }
}
export const CompetencyLevels = [
  {
    id: SkillLevels.None,
    name: 'Non Existent',
  },
  {
    id: SkillLevels.Basic,
    name: 'Basic',
  },
  {
    id: SkillLevels.Intermediate,
    name: 'Intermediate',
  },
  {
    id: SkillLevels.Advanced,
    name: 'Advanced',
  },
  {
    id: SkillLevels.Expert,
    name: 'Expert',
  },
]
export const getCompetencyBackground = (
  seniorityLevel: number,
  data: CompetencyMatrixInterface,
  theme: RevolutTheme,
) => {
  const competency = data.competencies?.find(
    item => item.seniority_level === seniorityLevel,
  )?.competency_level

  if (competency) {
    return competencyToColor(competency, theme)
  }

  return null
}
export const getSkillLevelDescription = (
  level: SkillLevels,
  skill?: SkillInterface | CompetencyMatrixSkill,
) => {
  if (!skill || !('mastery_level_definition_basic' in skill)) {
    return ''
  }

  switch (level) {
    case SkillLevels.None:
      return 'The skill is not required for this seniority level'

    case SkillLevels.Basic:
      return skill.mastery_level_definition_basic?.join('\\n') || ''

    case SkillLevels.Intermediate:
      return skill.mastery_level_definition_intermediate?.join('\\n') || ''

    case SkillLevels.Advanced:
      return skill.mastery_level_definition_advanced?.join('\\n') || ''

    case SkillLevels.Expert:
      return skill.mastery_level_definition_expert?.join('\\n') || ''

    default:
      return ''
  }
}
export const getNormalizedSeniorityName = (name: string) => {
  const normalizedNames = {
    'Senior Director': 'SR Director',
    'Vice President': 'VP',
    'Senior Vice President': 'SVP',
  }

  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  return normalizedNames[name] || name
}
export const getCompetencyOptions = ({
  skillset,
  competencies,
  seniorityId,
  seniorities,
}: {
  skillset?: CompetencyMatrixSkill | SkillInterface
  competencies?: CompetencyInterface[]
  seniorityId: number
  seniorities: SeniorityInterface[]
}) => {
  if (!competencies) {
    return []
  }

  const level = seniorities.find(seniority => seniority.id === seniorityId)!.level
  const disabledLevels: { id: SkillLevels; name: string }[] = []

  if (level > 0) {
    for (let i = 0; i < level; i += 1) {
      const seniority = seniorities.find(item => item.level === i)
      const prevLevel = competencies.find(
        item => item.seniority_id === seniority?.id,
      )?.competency_level
      const prevLevelIndex = CompetencyLevels.findIndex(lev => lev.id === prevLevel)

      disabledLevels.push(
        ...CompetencyLevels.filter((_, index) => index < prevLevelIndex),
      )
    }
  }

  for (let i = level + 1; i <= seniorities.length; i += 1) {
    const seniority = seniorities.find(item => item.level === i)
    const nextLevel = competencies.find(
      item => item.seniority_id === seniority?.id,
    )?.competency_level
    const nextLevelIndex = CompetencyLevels.findIndex(lev => lev.id === nextLevel)

    if (nextLevelIndex !== -1) {
      disabledLevels.push(
        ...CompetencyLevels.filter((_, index) => index > nextLevelIndex),
      )
    }
  }

  return CompetencyLevels.map(levelItem => ({
    id: levelItem.id,
    name: levelItem.name,
    text: getSkillLevelDescription(levelItem.id, skillset),
    disabled: Boolean(disabledLevels.find(({ id }) => id === levelItem.id)),
  }))
}
export const getNormalizedFuncCompetencyMatrix = (
  values?: Partial<SpecialisationInterface>,
) => {
  if (!values?.functional_competency_matrix) {
    return undefined
  }

  return produce(values.functional_competency_matrix, draft => {
    draft.forEach(competencyItem => {
      const minLevel = values.seniority_min?.level
      const maxLevel = values.seniority_max?.level

      competencyItem.competencies?.sort((a, b) => a.seniority_level! - b.seniority_level!)

      const min = competencyItem.competencies?.findIndex(
        item => item.seniority_level === minLevel,
      )

      if (min !== -1) {
        competencyItem.competencies?.splice(0, min)
      }

      const max = competencyItem.competencies?.findIndex(
        item => item.seniority_level === maxLevel,
      )

      if (max && max !== -1) {
        competencyItem.competencies?.splice(max + 1)
      }
    })
  })
}
export const getNormalizedDeliverablesCompetencyMatrix = (
  values?: Partial<SpecialisationInterface>,
) => {
  if (!values?.deliverables_competencies) {
    return undefined
  }

  const minLevel = values.seniority_min?.level
  const maxLevel = values.seniority_max?.level

  return produce(values.deliverables_competencies, draft => {
    draft.sort((a, b) => a.seniority_level! - b.seniority_level!)

    const min = draft.findIndex(item => item.seniority_level === minLevel)
    if (min !== -1) {
      draft.splice(0, min)
    }

    const max = draft.findIndex(item => item.seniority_level === maxLevel)
    if (max !== -1) {
      draft.splice(max + 1)
    }
  })
}

export type SkillLevelsRange =
  | {
      min: SkillLevels
      max?: SkillLevels
    }
  | {
      min?: SkillLevels
      max: SkillLevels
    }

const skillLevelsSorted = {
  [SkillLevels.None]: 0,
  [SkillLevels.Poor]: 1,
  [SkillLevels.Basic]: 2,
  [SkillLevels.Intermediate]: 3,
  [SkillLevels.Advanced]: 4,
  [SkillLevels.Expert]: 5,
}

const getLevelWithinRange = (
  level: SkillLevels,
  { min = SkillLevels.None, max = SkillLevels.Expert }: SkillLevelsRange,
) => {
  if (
    skillLevelsSorted[level] >= skillLevelsSorted[min] &&
    skillLevelsSorted[level] <= skillLevelsSorted[max]
  ) {
    return level
  }

  return skillLevelsSorted[level] <= skillLevelsSorted[min] ? min : max
}

export const getDefaultCompetencyFor = (
  seniority: SeniorityInterface,
  range?: SkillLevelsRange,
) => {
  const seniorityLevelToSkill = [
    SkillLevels.Basic,
    SkillLevels.Basic,
    SkillLevels.Intermediate,
    SkillLevels.Intermediate,
  ]
  const rawLevel = seniorityLevelToSkill[seniority.level] || SkillLevels.Advanced
  const validatedLevel = range ? getLevelWithinRange(rawLevel, range) : rawLevel

  return {
    seniority_id: seniority.id,
    seniority_name: seniority.name,
    seniority_level: seniority.level,
    competency_level: validatedLevel,
  }
}
