import React from 'react'
import { StatusWidget, Text, Flex, Button, MoreBar, Placeholder } from '@revolut/ui-kit'
import { ArrowThinRight, Chat, QuestionOutline } from '@revolut/icons'

import {
  TIME_OFF_POLICY_PORTAL,
  TIME_OFF_SERVICE_DESK_LINK,
  TIME_OFF_USER_GUIDE,
} from '@src/constants/externalLinks'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

interface EmptyTableStateProps {
  title: string
  description: React.ReactNode
  actions: React.ReactNode
  usePlaceholder?: boolean
}

export const EmptyTableState = ({
  title,
  description,
  actions,
  usePlaceholder,
}: EmptyTableStateProps) => {
  const Wrapper = usePlaceholder ? Placeholder : StatusWidget

  return (
    <Wrapper>
      <Wrapper.Image
        src="https://assets.revolut.com/assets/3d-images/3D074.png"
        srcSet="https://assets.revolut.com/assets/3d-images/3D074@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D074@3x.png 3x"
      />
      <Wrapper.Title>{title}</Wrapper.Title>
      <Wrapper.Description>
        <Text> {description}</Text>
        <Flex gap="s-8" mt="s-16" justifyContent="center">
          {actions}
        </Flex>
      </Wrapper.Description>
    </Wrapper>
  )
}

export const ContactHRTeamButton = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  if (isCommercial) {
    return null
  }

  return (
    <HideIfCommercial>
      <Button
        use="a"
        href={TIME_OFF_SERVICE_DESK_LINK}
        useIcon={Chat}
        size="sm"
        variant="secondary"
        rel="noreferrer noopener"
        target="_blank"
      >
        Contact HR team
      </Button>
    </HideIfCommercial>
  )
}

export const UserGuidesButton = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  if (isCommercial) {
    return null
  }

  return (
    <HideIfCommercial>
      <Button
        use="a"
        href={TIME_OFF_USER_GUIDE}
        useIcon={QuestionOutline}
        size="sm"
        variant="secondary"
        rel="noreferrer noopener"
        target="_blank"
      >
        User Guides
      </Button>
    </HideIfCommercial>
  )
}

export const PolicyPortalButton = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  if (isCommercial) {
    return null
  }

  return (
    <HideIfCommercial>
      <MoreBar.Action
        use="a"
        useIcon={ArrowThinRight}
        href={TIME_OFF_POLICY_PORTAL}
        target="_blank"
        rel="noreferrer noopener"
      >
        Policy Portal
      </MoreBar.Action>
    </HideIfCommercial>
  )
}
