import { ReviewCategory, ReviewStatuses } from '@src/interfaces/performance'
import { ROUTES } from '@src/constants/routes'
import { Pencil, Plus } from '@revolut/icons'
import {
  ProbationCheckpoint,
  ProbationRecommendationInterface,
} from '@src/interfaces/probationReview'
import { Box, VStack } from '@revolut/ui-kit'
import React, { useEffect, useState } from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { getProbationRecommendations } from '@src/api/probationReview'
import { StepReviewersList } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/StepReviewersList'

const ReviewDeliverablesPath: { [key: string]: string } = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_FINAL_RESULT.GENERAL,
}

enum ButtonStates {
  Add = 'Add',
  Edit = 'Edit',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Recommendation',
  [ButtonStates.Edit]: 'Edit Recommendation',
}

const ButtonIcon = {
  [ButtonStates.Add]: Plus,
  [ButtonStates.Edit]: Pencil,
}

export const RecommendationStepReviewers = ({
  checkpoint,
  reviewCategory,
  cycleId,
  employeeId,
}: {
  checkpoint: ProbationCheckpoint
  reviewCategory: ReviewCategory
  cycleId: string
  employeeId: number
}) => {
  const [recommendations, setRecommendations] =
    useState<ProbationRecommendationInterface[]>()

  const fetchRecommendations = async () => {
    const resp = await getProbationRecommendations(employeeId, cycleId)
    setRecommendations(resp.data.results)
  }

  useEffect(() => {
    fetchRecommendations()
  }, [])

  const buttonDisabled = !checkpoint?.can_be_submitted

  const onClickAddReview = () => {
    const id = checkpoint.recommendation?.id
    const cpEmployeeId = checkpoint.recommendation?.reviewed_employee?.id
    const cpCycleId = checkpoint.recommendation?.cycle?.id
    if (id && cpEmployeeId && cpCycleId) {
      navigateTo(
        pathToUrl(ReviewDeliverablesPath[reviewCategory], {
          id,
          employeeId: cpEmployeeId,
          cycleId: cpCycleId,
        }),
      )
    }
  }

  const state =
    !checkpoint?.recommendation ||
    checkpoint.recommendation.status === ReviewStatuses.pending
      ? ButtonStates.Add
      : ButtonStates.Edit

  return (
    <Box>
      <VStack gap="s-8">
        {recommendations && (
          <StepReviewersList
            reviews={recommendations}
            onClickAddReview={onClickAddReview}
            buttonDisabled={buttonDisabled}
            icon={ButtonIcon[state]}
            title={ButtonTitles[state]}
          />
        )}
      </VStack>
    </Box>
  )
}
