import React from 'react'
import { Box, Cell, VStack, Flex, Text, Avatar, HStack, IconName } from '@revolut/ui-kit'

interface PageHeaderTitleProps {
  title: React.ReactNode
  labels: React.ReactNode
  actions: React.ReactNode
  useIcon?: IconName
}

export const PageHeaderTitle = ({
  title,
  labels,
  actions,
  useIcon,
}: PageHeaderTitleProps) => {
  return (
    <Box mt="s-8">
      <Cell pt="s-24" px="s-16" pb="s-16">
        <VStack gap="s-16" overflow="hidden">
          <HStack gap="s-16">
            {useIcon ? <Avatar size={56} useIcon={useIcon} /> : null}
            <Flex flex={1} alignItems="center" gap="s-16">
              <VStack gap="s-4">
                <Text variant="h1" whiteSpace="pre-wrap">
                  {title}
                </Text>
                <Flex flexWrap="wrap" gap={['s-2', 's-8']}>
                  {labels}
                </Flex>
              </VStack>
            </Flex>
          </HStack>
          <Flex gap="s-8">{actions}</Flex>
        </VStack>
      </Cell>
    </Box>
  )
}
