import * as React from 'react'
import styled from 'styled-components'
import Info from './Info'
import { ReactNode } from 'react'
import { Color } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'

const LabelText = styled.div<{ type: 'primary' | 'secondary' }>`
  margin-top: 2px;
  margin-bottom: 8px;
  text-transform: ${props => (props.type === 'secondary' ? 'none' : 'uppercase')};
  font-size: ${props => (props.type === 'secondary' ? '13px' : '11px')};
  line-height: 12px;
  color: ${props =>
    props.type === 'secondary'
      ? props.theme.colors.foreground
      : getColor(props.theme, Color.GREY_TONE_50)};
  overflow: hidden;
  text-overflow: ellipsis;
`

const Optional = styled.span<{ type: 'primary' | 'secondary' }>`
  color: ${({ type, theme }) =>
    type === 'secondary' ? getColor(theme, Color.GREY_20_OPAQUE_90) : 'inherit'};
`

const TopWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`
const Left = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: none;
  font-size: 12px;
  line-height: 12px;
`

export interface TopProps {
  /** Marks field as optional otherwise */
  required?: boolean
  /** hides the optional text for display only fields */
  hideOptional?: boolean
  /** Top tooltip hovarable text */
  tooltipTitle?: string
  /** Content text of the tooltip */
  tooltipText?: string
  /** Tooltip body if you want to use components instead of text */
  tooltipBody?: ReactNode
  /** Component right of the label */
  topInfo?: React.ReactNode
  /** Top link url */
  link?: string
  /** Top link text value */
  linkText?: string
  /** Label of Input */
  label?: ReactNode
  /** Type of the label (style) */
  kind?: 'primary' | 'secondary'
}

const Label = ({
  required,
  hideOptional,
  tooltipText,
  tooltipTitle,
  tooltipBody,
  topInfo,
  link,
  linkText,
  label,
  kind = 'primary',
}: TopProps) => (
  <TopWrapper>
    <Left>
      {label && (
        <LabelText type={kind}>
          {label}
          <Optional type={kind}>{!required && !hideOptional && ' (optional)'}</Optional>
        </LabelText>
      )}
      <Info
        tooltipBody={tooltipBody}
        tooltipText={tooltipText}
        tooltipTitle={tooltipTitle}
      />
    </Left>
    {topInfo}
    {link && linkText && (
      <Anchor href={link} target="_blank">
        {linkText}
      </Anchor>
    )}
  </TopWrapper>
)

export default Label
