import React, { useState } from 'react'
import { Button, ButtonProps, StatusPopup, StatusPopupVariant } from '@revolut/ui-kit'
import { useLocation, useParams } from 'react-router-dom'
import { connect, useLape } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import ConfirmationDialog, {
  ConfirmationDialogInterface,
} from '@src/features/Popups/ConfirmationDialog'
import { useFormValidator } from '../FormValidator'
import Tooltip from '@components/Tooltip/Tooltip'
import { css } from 'styled-components'
import { pathToUrl } from '@src/utils/router'
import { AxiosError } from 'axios'

const tooltipCss = css`
  width: 100%;
`

type ResultType = { id?: number | string }

export interface NewSaveButtonWithPopupProps<T> extends ButtonProps {
  successText?: string
  successDescription?: string
  /** Use this if after submitting the form, user should be taken to preview screen */
  previewUrl?: ((result: T) => string) | string
  afterSubmitUrl?: string
  onAfterSubmit?: (result: T) => void
  onSubmitError?: (e: AxiosError) => void
  errorHandler?: (e: AxiosError) => void
  onClick?: () => Promise<any>
  confirmationDialogue?: Partial<ConfirmationDialogInterface>
  tooltipText?: string
  useValidator?: boolean
  hideWhenNoChanges?: boolean
  noPopup?: boolean
  /** For cases when the form data doesn't have the `id` prop */
  isExistingData?: boolean
  popupVariant?: StatusPopupVariant
  preventUserClose?: boolean
}

interface State<T> {
  result: T
  isPopupOpen: boolean
  afterSubmitCallback: () => void
}

const NewSaveButtonWithPopup = <T extends ResultType>({
  successText = 'Your changes were saved',
  successDescription,
  onAfterSubmit = () => {},
  onSubmitError = () => {},
  errorHandler,
  afterSubmitUrl,
  previewUrl,
  onClick,
  confirmationDialogue,
  tooltipText,
  useValidator = false,
  hideWhenNoChanges = true,
  noPopup = false,
  popupVariant = 'success',
  ...props
}: NewSaveButtonWithPopupProps<T>) => {
  const location = useLocation<{ initialValues: any; returnAs: string }>()
  const params = useParams<{ id?: string }>()
  const [openDialogue, setOpenDialogue] = useState(false)
  const formValidator = useFormValidator()
  // To make sure they have values that they had at the time of submit not after it
  // (the form re-fetches after submit and initialValues are reset so checking !!initialValues.id
  // will always evaluate to true)
  const [popupText, setPopupText] = useState<string>()
  const [popupDescription, setPopupDescription] = useState<string>()

  const [pending, setPending] = useState(false)
  const returnAs = location?.state?.returnAs

  const state = useLape<State<T>>({
    result: {} as T,
    isPopupOpen: false,
    afterSubmitCallback: () => {},
  })

  const { values, dirty, submit, disabled } = useLapeContext<{
    id?: number
  }>()

  const isExistingData = !!values?.id || props.isExistingData

  if ((hideWhenNoChanges && isExistingData && !dirty && !state.isPopupOpen) || disabled) {
    return null
  }

  const submitCallback = () => {
    setPopupText(successText)
    setPopupDescription(successDescription)

    setPending(true)
    const callback = onClick || submit

    return callback()
      .then(res => {
        setPending(false)

        if (noPopup) {
          onAfterSubmit(res)
          navigateToPreview(res)
        } else {
          state.isPopupOpen = true
          state.result = res
          state.afterSubmitCallback = () => {
            onAfterSubmit(res)
            navigateToPreview(res)
          }
        }
      })
      .catch(error => {
        onSubmitError(error)

        if (errorHandler) {
          errorHandler(error)
        } else {
          throw error
        }
      })
      .finally(() => {
        setPending(false)
        setOpenDialogue(false)
      })
  }

  const handleDialogueOpen = () => {
    setOpenDialogue(true)
  }

  const formSubmit =
    useValidator && formValidator?.validate
      ? formValidator.validate(submitCallback)
      : submitCallback

  const onSubmit = pending || props.pending ? () => {} : formSubmit

  const onOpenDialog =
    useValidator && formValidator?.validate
      ? formValidator.validate(() => {
          handleDialogueOpen()
          return Promise.resolve()
        })
      : handleDialogueOpen

  const navigateAfterSubmit = () => {
    if (afterSubmitUrl) {
      goBack(
        afterSubmitUrl,
        returnAs ? { initialValues: { [returnAs]: state.result } } : undefined,
      )
    }
  }

  const navigateToPreview = (res: T) => {
    if (previewUrl) {
      const preview = typeof previewUrl === 'string' ? previewUrl : previewUrl(res)
      const url = pathToUrl(preview, { ...params, id: res.id })
      /** `url === previewUrl` handles case when previewUrl has no ID in URL, e.g. company general settings */
      const action = params.id || url === previewUrl ? goBack : navigateReplace
      action(url)
    }
  }

  return (
    <>
      <ConfirmationDialog
        open={openDialogue}
        onClose={() => setOpenDialogue(false)}
        onConfirm={onSubmit}
        loading={pending || props.pending}
        onReject={() => setOpenDialogue(false)}
        {...confirmationDialogue}
      />
      <Tooltip placement="top" text={tooltipText} css={tooltipCss}>
        <Button
          elevated
          {...props}
          onClick={confirmationDialogue ? onOpenDialog : onSubmit}
          disabled={disabled || props.disabled}
          pending={pending || props.pending}
        >
          {props.children || (isExistingData ? 'Save Changes' : 'Submit')}
        </Button>
      </Tooltip>

      <StatusPopup
        variant={popupVariant}
        open={state.isPopupOpen && !noPopup}
        onClose={() => {
          state.isPopupOpen = false
          state.afterSubmitCallback?.()
          navigateAfterSubmit()
          state.result = {} as T
        }}
        // @ts-expect-error
        labelButtonClose="Close success popup"
        preventUserClose={props.preventUserClose}
      >
        <StatusPopup.Title>{popupText}</StatusPopup.Title>
        {popupDescription && (
          <StatusPopup.Description>{popupDescription}</StatusPopup.Description>
        )}
      </StatusPopup>
    </>
  )
}

export default connect(NewSaveButtonWithPopup)
