import React from 'react'
import { Table, Token, Text } from '@revolut/ui-kit'

const TableCell = ({
  value,
  color,
  background,
}: {
  value: string
  color?: string
  background?: string
}) => {
  const getColor = () => {
    switch (value) {
      case 'Unsatisfactory':
      case 'Avg -':
        return Token.color.error
      case 'Strong':
      case 'Exceptional':
        return Token.color.success
      default:
        return Token.color.foreground
    }
  }
  return (
    <Table.Cell bg={background} borderColor={background}>
      <Text
        variant="small"
        color={color || getColor()}
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
      >
        {value}
      </Text>
    </Table.Cell>
  )
}

type CellProps = { row: { values: typeof data[0] } }

export const columns = [
  {
    Header: '',
    accessor: 'hofGrade',
    width: 70,
    Cell: ({ row }: CellProps) => (
      <TableCell
        value={row.values.hofGrade}
        color={Token.color.foreground}
        background={Token.color.greyTone5}
      />
    ),
  },
  {
    Header: 'Unsatisfactory',
    accessor: 'unsutisfactory',
    Cell: ({ row }: CellProps) => <TableCell value={row.values.unsutisfactory} />,
    width: 70,
  },
  {
    Header: 'Average -',
    accessor: 'avarageMinus',
    Cell: ({ row }: CellProps) => <TableCell value={row.values.avarageMinus} />,
    width: 70,
  },
  {
    Header: 'Average +',
    accessor: 'avaragePlus',
    Cell: ({ row }: CellProps) => <TableCell value={row.values.avaragePlus} />,
    width: 70,
  },
  {
    Header: 'Strong',
    accessor: 'strong',
    Cell: ({ row }: CellProps) => <TableCell value={row.values.strong} />,
    width: 70,
  },
  {
    Header: 'Exceptional',
    accessor: 'exception',
    Cell: ({ row }: CellProps) => <TableCell value={row.values.exception} />,
    width: 70,
  },
]

export const data = [
  {
    hofGrade: 'Unsatisfactory',
    unsutisfactory: 'Unsatisfactory',
    avarageMinus: 'Avg -',
    avaragePlus: 'Avg -',
    strong: 'Avg +',
    exception: 'Avg +',
  },
  {
    hofGrade: 'Average -',
    unsutisfactory: 'Unsatisfactory',
    avarageMinus: 'Avg -',
    avaragePlus: 'Avg +',
    strong: 'Avg +',
    exception: 'Avg +',
  },
  {
    hofGrade: 'Average +',
    unsutisfactory: 'Avg -',
    avarageMinus: 'Avg -',
    avaragePlus: 'Avg +',
    strong: 'Avg +',
    exception: 'Avg +',
  },
  {
    hofGrade: 'Strong',
    unsutisfactory: 'Avg -',
    avarageMinus: 'Avg -',
    avaragePlus: 'Strong',
    strong: 'Strong',
    exception: 'Strong',
  },
  {
    hofGrade: 'Exceptional',
    unsutisfactory: 'Avg -',
    avarageMinus: 'Avg -',
    avaragePlus: 'Strong',
    strong: 'Strong',
    exception: 'Exceptional',
  },
]
