import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
  useWorkspaceLocalStorage = true,
): [T, Dispatch<SetStateAction<T>>] => {
  const storage = useWorkspaceLocalStorage ? workspaceLocalStorage : localStorage

  const [value, setValue] = useState<T>(() => {
    try {
      const item = storage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.warn(error)
      return initialValue
    }
  })

  useEffect(() => {
    storage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
