import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { ColumnInterface, CellTypes } from '@src/interfaces/data'
import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { Progress, TextButton } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { BackgroundJobResult } from '@src/interfaces/backgroundJobs'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const highlightRedStates = ['FAILURE', 'REVOKED', 'REJECTED', 'RETRY']
type ProgressColors = 'orange' | 'green' | 'red'

const ProgressBarCell = ({ data }: { data: BackgroundJobResult }) => {
  let progressColor: ProgressColors = 'orange'
  if (data.progress_percentage === 100) {
    progressColor = 'green'
  }
  if (highlightRedStates.includes(data.state.id)) {
    progressColor = 'red'
  }

  return (
    <Progress
      value={data.progress_percentage / 100}
      color={progressColor}
      my="auto"
      width="100%"
    />
  )
}

export const backgroundJobType: ColumnInterface<BackgroundJobResult> = {
  type: CellTypes.text,
  idPoint: 'label',
  dataPoint: 'label',
  title: 'Background job',
  selectorsKey: selectorKeys.none,
  sortKey: null,
  filterKey: null,
}

export const progressBar: ColumnInterface<BackgroundJobResult> = {
  type: CellTypes.insert,
  idPoint: 'progress_percentage',
  dataPoint: 'progress_percentage',
  title: 'Progress',
  selectorsKey: selectorKeys.none,
  sortKey: 'progress_percentage',
  filterKey: null,
  insert: ({ data }) => <ProgressBarCell data={data} />,
}

export const statusColumn: ColumnInterface<BackgroundJobResult> = {
  type: CellTypes.text,
  idPoint: 'state.name',
  dataPoint: 'state.name',
  title: 'Status',
  selectorsKey: selectorKeys.background_job_states,
  sortKey: null,
  filterKey: 'state',
}

export const startDate: ColumnInterface<BackgroundJobResult> = {
  type: CellTypes.dateTime,
  idPoint: 'date_created',
  dataPoint: 'date_created',
  title: 'Start on',
  selectorsKey: selectorKeys.none,
  sortKey: 'date_created',
  filterKey: null,
}

export const completionDate: ColumnInterface<BackgroundJobResult> = {
  type: CellTypes.dateTime,
  idPoint: 'date_done',
  dataPoint: 'date_done',
  title: 'Completed on',
  selectorsKey: selectorKeys.none,
  sortKey: 'date_done',
  filterKey: null,
}

export const triggeredBy: ColumnInterface<BackgroundJobResult> = {
  type: CellTypes.insert,
  idPoint: 'triggered_by',
  dataPoint: 'triggered_by',
  title: 'Triggered by',
  selectorsKey: selectorKeys.all_employees,
  sortKey: null,
  filterKey: 'triggered_by__id',
  insert: ({ data }) =>
    data.triggered_by?.id ? (
      <UserWithAvatar {...data.triggered_by} status={undefined} />
    ) : (
      '-'
    ),
}

const getActionLink = (data: BackgroundJobResult) => {
  switch (data.slug) {
    case 'apply_employee_bulk_edit_items':
      return data.related_id
        ? pathToUrl(ROUTES.FORMS.BULK_EDIT_EMPLOYEES.TRACK, { id: data.related_id })
        : undefined

    case 'apply_contract_bulk_edit_items':
      return data.related_id
        ? pathToUrl(ROUTES.FORMS.BULK_EDIT_CONTRACTS.TRACK, { id: data.related_id })
        : undefined

    default:
      return data.id ? pathToUrl(ROUTES.FORMS.BACKGROUND_JOB, { id: data.id }) : undefined
  }
}

export const action: ColumnInterface<BackgroundJobResult> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'id',
  title: 'Action',
  selectorsKey: selectorKeys.none,
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => {
    const actionLink = getActionLink(data)

    return actionLink ? (
      <TextButton
        use={InternalLink}
        to={actionLink}
        color={highlightRedStates.includes(data.state.id) ? 'red' : 'blue'}
      >
        Review
      </TextButton>
    ) : null
  },
}
