import React from 'react'
import { ExclamationMark } from '@revolut/icons'
import { Color } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'
import { RevolutTheme } from '@src/styles/theme'
import { PreviewChangelog } from './changelog'
import { getValueWithChangelog } from './utils'

type Props<T> = {
  theme: RevolutTheme
  field?: string
  values: T
  changelog?: PreviewChangelog<T>
}
export const IsRequiredNoticeIcon = <T,>({
  theme,
  field,
  values,
  changelog,
}: Props<T>) => {
  if (getValueWithChangelog({ values, changelog, field })) {
    return null
  }
  return <ExclamationMark color={getColor(theme, Color.WARNING)} size={16} />
}
