import { ActionButton, Box, Cell, ErrorWidget, VStack } from '@revolut/ui-kit'
import { getAllLookerDashboards } from '@src/api/analyticsDashboards'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { ROUTES } from '@src/constants/routes'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { pathToUrl } from '@src/utils/router'
import React, { useState } from 'react'
import { KeyMetricsPreview } from '@src/pages/Forms/GoalForm/common/KeyMetricsPreview'
import { SidebarAction } from './SidebarAction'

export const AddDashboardForm = ({
  initialDashboard = null,
  onSave,
}: {
  initialDashboard?: AnalyticsDashboardInterface | null
  onSave: (dashboard: AnalyticsDashboardInterface) => void
}) => {
  const { entity, navigateWithEntity } = useOrgEntity()
  const [dashboard, setDashboard] = useState<AnalyticsDashboardInterface | null>(
    initialDashboard,
  )
  return (
    <>
      <VStack space="s-16">
        <ActionButton
          useIcon="ArrowThinRight"
          onClick={() =>
            navigateWithEntity(
              pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS, {}),
              {
                backUrl: ROUTES.FORMS.GOAL.NEW,
                entityType: entity?.type,
                entityId: entity?.data.id,
                entityName: entity?.data.name,
              },
            )
          }
        >
          Create new dashboard
        </ActionButton>
        <RadioSelectInput<AnalyticsDashboardInterface>
          label="Select dashboard"
          value={dashboard}
          onChange={setDashboard}
          selector={() =>
            getAllLookerDashboards().then(response => response.data.results)
          }
        />
        {dashboard ? (
          <Cell p="s-12">
            <Box width="100%">
              <FormPreview<AnalyticsDashboardInterface> data={dashboard}>
                <FormPreview.Item title="Dashboard name" field="name" />
                <FormPreview.Details title="Description" field="description" />
                <FormPreview.Item
                  insert={() => <KeyMetricsPreview dashboard={dashboard} />}
                  title="Key-metric"
                />
                <FormPreview.Item
                  type="capitalized"
                  field="dashboard_type"
                  title="Type"
                />
                <FormPreview.Item
                  type="link"
                  field="embed_url"
                  title="Link to external"
                />
                <FormPreview.Item
                  type="date"
                  field="creation_date_time"
                  title="Created on"
                />
                <FormPreview.Item type="status" field="status" title="Status" />

                <FormPreview.Item type="employee" field="owner" title="Owner" />
              </FormPreview>
            </Box>
          </Cell>
        ) : (
          <ErrorWidget>
            <ErrorWidget.Description>
              Select an internal dashboard
            </ErrorWidget.Description>
          </ErrorWidget>
        )}
      </VStack>
      <SidebarAction disabled={!dashboard} onClick={() => onSave(dashboard!)}>
        Save
      </SidebarAction>
    </>
  )
}
