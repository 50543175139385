import React, { useEffect } from 'react'
import { selectorKeys } from '@src/constants/api'
import { InputGroup } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import OnlineTestRadioSelect from '@src/pages/Forms/HiringStage/OnlineTestRadioSelect'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

type StageDetailsProps = {
  disableStageType?: boolean
  requiredDuration?: boolean
  isDisabled?: boolean
  isRole: boolean
  isSpecialisation: boolean
  scorecard?: React.ReactNode
}

const StageDetails = ({
  disableStageType = false,
  requiredDuration = false,
  isDisabled,
  isRole,
  isSpecialisation,
  scorecard,
}: StageDetailsProps) => {
  const { values, errors } = useLapeContext<HiringProcessInterface>()
  const user = useSelector(selectUser)
  useEffect(() => {
    if (!values.owner && user) {
      values.owner = {
        id: user.id,
        full_name: user.full_name,
      }
    }
    if (!values.duration_unit) {
      values.duration_unit = {
        id: 'minutes',
        name: 'Minutes',
      }
    }
  }, [])
  const isOnlineTest = values.stage_type?.id === 'online_test'
  const isCVScreening = values.stage_type?.id === 'cv_screening'
  const isLastStage =
    values?.is_company_standard_round ||
    values?.limit_editing_in_specialisation ||
    isSpecialisation
  return (
    <>
      <InputGroup>
        <LapeNewInput name="title" required label="Stage name" disabled={isDisabled} />
        <LapeRadioSelectInput<HiringProcessInterface>
          name="stage_type"
          label="Stage type"
          selector={selectorKeys.hiring_stage_types}
          disabled={disableStageType ? true : values.id !== undefined}
          message={
            values.id
              ? 'You cannot change the stage type for already existing stages'
              : undefined
          }
        />
        {isOnlineTest ? (
          <>
            <LapeRadioSelectInput<HiringProcessInterface>
              name="test_platform"
              label="Test platform"
              selector={selectorKeys.hiring_stage_test_platforms}
              searchable={false}
              onAfterChange={() => {
                values.online_test = undefined
              }}
            />
            <OnlineTestRadioSelect
              testPlatform={values.test_platform?.id as string | undefined}
              onChange={newOnlineTest => {
                if (newOnlineTest) {
                  values.online_test = {
                    ...values?.online_test,
                    id: newOnlineTest.id,
                    name: newOnlineTest.name,
                  }
                }
              }}
              value={values.online_test}
              error={errors?.online_test}
            />
          </>
        ) : (
          <>
            {!isCVScreening && (
              <>
                <LapeNewMultiSelect<HiringProcessInterface>
                  disabled={isDisabled}
                  name="skills"
                  placeholder="Skills"
                  selector={selectorKeys.approved_functional_skills}
                />
                {scorecard}
                <InputGroup variant="horizontal">
                  <LapeNewInput
                    required={
                      requiredDuration
                        ? true
                        : values?.is_company_standard_round || isLastStage
                    }
                    disabled={isDisabled}
                    name="duration"
                    label="Enter duration"
                    type="number"
                    width="50%"
                  />
                  <LapeRadioSelectInput<HiringProcessInterface>
                    disabled={isDisabled}
                    name="duration_unit"
                    label="Unit"
                    selector={selectorKeys.hiring_stage_duration_units}
                  />
                </InputGroup>
              </>
            )}
          </>
        )}
        <LapeNewInput
          disabled={isDisabled}
          required={values?.is_company_standard_round}
          name="playbook_link"
          label="Playbook link"
        />

        {isRole && (
          <LapeRadioSelectInput<HiringProcessInterface>
            name="role"
            label="Role"
            selector={selectorKeys.role}
          />
        )}
        {isSpecialisation && (
          <LapeRadioSelectInput<HiringProcessInterface>
            name="specialisation"
            label="Specialisation"
            selector={selectorKeys.specialisations}
          />
        )}

        <LapeRadioSelectInput<HiringProcessInterface>
          name="owner"
          label="Owner"
          selector={selectorKeys.employee}
        />

        <LapeNewTextArea name="notes" label="Notes" rows={3} />
      </InputGroup>
    </>
  )
}

export default StageDetails
