import React, { useEffect } from 'react'
import PageLoading from '@components/PageLoading/PageLoading'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import { isWorkspacesEnabled } from '@src/utils'

const SubdomainPermissionsRedirect = () => {
  useEffect(() => {
    const currentURL = new URL(window.location.href)
    const state = currentURL.searchParams.get('state')
    let subdomain

    try {
      const parsedState = state && JSON.parse(state)

      if ('subdomain' in parsedState) {
        subdomain = parsedState.subdomain
      }
    } catch {
      subdomain = null
    }

    // if we are in the tenants env we cannot redirect back as each tenant has different subdomain and Google doesn't support wildcard authorized redirect urls
    // as a solution we define subdomain in the url state, redirect to the main domain (which is authorized), where we check the subdomain and redirect there
    if (subdomain) {
      window.location.href = isWorkspacesEnabled()
        ? `${currentURL.protocol}//${currentURL.hostname}/${subdomain}${ROUTES.GRANT_PERMISSIONS_REDIRECT}${currentURL.search}`
        : `${currentURL.protocol}//${subdomain}.${currentURL.hostname}${ROUTES.GRANT_PERMISSIONS_REDIRECT}${currentURL.search}`
    } else {
      navigateReplace(`${ROUTES.GRANT_PERMISSIONS_REDIRECT}${currentURL.search}`)
    }
  }, [])

  return <PageLoading />
}

export default SubdomainPermissionsRedirect
