import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, BREAKPOINTS, Flex, Text, TextButton, Token, VStack } from '@revolut/ui-kit'

import { RevolutAccountItem } from './AccountItems/RevolutAccountItem'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { GoogleAccountItem } from './AccountItems/GoogleAccountItem'
import HideIfGmailDisabled from '@components/HideIfGmailDisabled/HideIfGmailDisabled'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { GoogleCalendarAccountItem } from './AccountItems/GoogleCalendarAccountItem'
import { canViewLinkedAccounts } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useGlobalSettings } from '@src/api/settings'

type Props = {
  data: EmployeeInterface
  showTitle?: boolean
}
export const LinkedAccountsList = ({ data, showTitle }: Props) => {
  const { id: employeeId } = useParams<{ id: string }>()
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const { settings: globalSettings } = useGlobalSettings()

  const isThisUser = String(user?.id) === employeeId
  const displayMailSync =
    isThisUser && permissions.includes(PermissionTypes.ViewRecruitmentgroups)
  const canViewRevolutAccounts = canViewLinkedAccounts(
    data,
    !!globalSettings.linked_accounts_enabled,
  )
  const canAddRevolutAccounts = !!data?.field_options?.permissions?.includes(
    PermissionTypes.AddEmployeelinkedaccount,
  )
  const hasContent = isThisUser || canViewRevolutAccounts || displayMailSync

  if (!hasContent) {
    return null
  }
  return (
    <Box maxWidth={{ all: '100%', lg: BREAKPOINTS.md, md: BREAKPOINTS.md }}>
      <VStack space="s-16">
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            {showTitle && (
              <Text variant="h6" color={Token.color.greyTone50}>
                Linked Accounts
              </Text>
            )}
          </Box>
          <TextButton
            px="0"
            height="auto"
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.LINKED_ACCOUNTS, {
              id: employeeId,
            })}
          >
            More details
          </TextButton>
        </Flex>
        {canViewRevolutAccounts && (
          <HideIfCommercial>
            <RevolutAccountItem isThisUser={isThisUser} canAdd={canAddRevolutAccounts} />
          </HideIfCommercial>
        )}
        {displayMailSync && (
          <HideIfGmailDisabled>
            <GoogleAccountItem />
          </HideIfGmailDisabled>
        )}
        {isThisUser && <GoogleCalendarAccountItem />}
      </VStack>
    </Box>
  )
}
