import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import DeleteAction, {
  DeleteActionProps,
} from '@components/ColumnInserts/DeleteAction/DeleteAction'
import React from 'react'
import { Dots, Flex, IconButton, Token } from '@revolut/ui-kit'
import { AxiosPromise } from 'axios'
import LapeDeleteOrgUnitButton, {
  LapeDeleteOrgUnitButtonProps,
} from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'

export const getDeleteActionColumn = (
  props: Omit<DeleteActionProps, 'data'>,
): ColumnInterface<Object> => ({
  type: CellTypes.insert,
  dataPoint: '',
  idPoint: 'delete_action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  textAlign: 'right',
  notHoverable: true,
  title: '',
  insert: ({ data }) => (
    <Flex width="100%" justifyContent="flex-end">
      <DeleteAction alignItems="end" mr="s-20" data={data} {...props} />
    </Flex>
  ),
})

export const getDeleteOrgUnitActionColumn = <T extends { id: number }>({
  props,
  handleDetete,
}: {
  props: Omit<LapeDeleteOrgUnitButtonProps, 'deleteApi'>
  handleDetete: (data: T) => AxiosPromise<T>
}): ColumnInterface<T> => ({
  type: CellTypes.insert,
  dataPoint: '',
  idPoint: 'delete_org_unit_action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  textAlign: 'right',
  notHoverable: true,
  title: '',
  insert: ({ data }) => (
    <Flex width="100%" justifyContent="flex-end">
      <LapeDeleteOrgUnitButton
        renderButton={({ onClick, loading }) =>
          loading ? (
            <Dots color={Token.color.greyTone50} duration={900} />
          ) : (
            <IconButton
              useIcon="Delete"
              size={16}
              onClick={onClick}
              color={Token.color.greyTone50}
            />
          )
        }
        checkPermissions={false}
        forcedId={data.id}
        {...props}
        deleteApi={() => handleDetete(data)}
      />
    </Flex>
  ),
})
