import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import * as EmployeePerformanceViewSkills from '@src/pages/Forms/EmployeePerformanceView/Skills'
import * as EmployeePerformanceViewCultureFit from '@src/pages/Forms/EmployeePerformanceView/CultureFit'
import * as EmployeePerformanceViewSummary from '@src/pages/Forms/EmployeePerformanceView/Summary'
import { pathToUrl } from '@src/utils/router'
import { ReviewCategory, ReviewSummaryInterface } from '@src/interfaces/performance'
import PerformanceView from '@src/pages/Forms/EmployeePerformance/components/PerformanceView/PerformanceView'
import { TabsInterface } from '@src/interfaces/data'

const getTabs = (params: {
  id: string
  employeeId: string
}): TabsInterface<ReviewSummaryInterface>[] => [
  {
    title: 'Skills',
    path: ROUTES.FORMS.UPWARDS_REVIEW_VIEW.SKILLS,
    to: pathToUrl(ROUTES.FORMS.UPWARDS_REVIEW_VIEW.SKILLS, params),
    validator: {},
    component: EmployeePerformanceViewSkills.default,
    visibilityCheck: data =>
      !!data?.summary?.manager_values || !!data?.summary?.functional_skills,
  },
  {
    title: 'Culture fit',
    path: ROUTES.FORMS.UPWARDS_REVIEW_VIEW.CULTURE_FIT,
    to: pathToUrl(ROUTES.FORMS.UPWARDS_REVIEW_VIEW.CULTURE_FIT, params),
    validator: {},
    component: EmployeePerformanceViewCultureFit.default,
    visibilityCheck: () => true,
  },
  {
    title: 'Summary',
    path: ROUTES.FORMS.UPWARDS_REVIEW_VIEW.SUMMARY,
    to: pathToUrl(ROUTES.FORMS.UPWARDS_REVIEW_VIEW.SUMMARY, params),
    validator: {},
    component: EmployeePerformanceViewSummary.default,
    visibilityCheck: () => true,
  },
]

const UpwardsReviewViewForm = () => (
  <PerformanceView getTabs={getTabs} category={ReviewCategory.Upwards} />
)

export default connect(UpwardsReviewViewForm)
