import React, { useState } from 'react'
import {
  Color,
  Flex,
  Header,
  Layout,
  Side,
  TabBar,
  Text,
  TextButton,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { Filter, Materials } from '@revolut/icons'
import { useGetConnectionSchema } from '@src/api/dataAnalytics'
import { QueryParameterInterface } from '@src/interfaces/dataAnalytics'
import { SideBarHeaderTitle } from '@src/components/SideBar/SideBarHeaderTitle'
import { DatabaseSchema } from './DatabaseSchema'
import { ParametersSidebar } from './ParametersSidebar'
import useIsCommercial from '@src/hooks/useIsCommercial'

interface Props {
  canEdit: boolean
  canManageConnection: boolean
  connectionId?: number
  isOpen: boolean
  handleClose: () => void
  parameters?: QueryParameterInterface[]
}

export enum tabsCategory {
  Parameters = 'parameters',
  Database = 'database',
}

export const QueryToolkitSidebar = ({
  canEdit,
  canManageConnection,
  connectionId,
  isOpen,
  handleClose,
  parameters,
}: Props) => {
  const [currentTabId, setCurrentTabId] = useState<tabsCategory>(tabsCategory.Parameters)
  const isCommercial = useIsCommercial()

  const { data: databaseSchema, refetch: refetchSchema } = useGetConnectionSchema(
    canManageConnection && connectionId && isCommercial === false ? connectionId : null,
  )

  const tabs = [
    {
      title: 'Parameters',
      id: tabsCategory.Parameters,
      canView: true,
    },
    {
      title: 'Database',
      id: tabsCategory.Database,
      canView: isCommercial === false,
    },
  ].filter(tab => tab.canView)

  return (
    <Layout.SideFill>
      <Side onClose={handleClose} open={isOpen}>
        <Header variant="item">
          <Header.CloseButton aria-label="Close sidebar" onClick={handleClose} />
          <SideBarHeaderTitle data-testid="sidebar-title">
            Query toolkit
          </SideBarHeaderTitle>
        </Header>
        <TabBar value={currentTabId} variant="segmented" mb="s-16">
          {tabs.length > 1 &&
            tabs.map(tab => (
              <TabBar.Item
                aria-selected={currentTabId === tab.id}
                key={tab.id}
                onClick={() => setCurrentTabId(tab.id)}
              >
                {tab.title}
              </TabBar.Item>
            ))}
        </TabBar>
        {currentTabId === tabsCategory.Parameters && (
          <VStack gap="s-16">
            <Text variant="tile">Parameters</Text>
            <Text
              color={Color.GREY_TONE_50}
              variant="caption"
            >{`To add new parameter include it in SQL as follows: {{parameter.name}}`}</Text>
            {parameters && parameters.length > 0 ? (
              parameters.map(parameter => (
                <ParametersSidebar
                  key={parameter.name}
                  disabled={!canEdit}
                  parameter={parameter}
                />
              ))
            ) : (
              <Widget color={Color.GREY_TONE_50} pb="s-32" pt="s-16">
                <VStack align="center" space="s-16" pt="s-16">
                  <Filter />
                  <Text>No parameters added</Text>
                </VStack>
              </Widget>
            )}
          </VStack>
        )}
        {currentTabId === tabsCategory.Database && (
          <>
            <Flex alignItems="center" justifyContent="space-between" gap="s-16" mb="s-16">
              <Text variant="tile">Database explorer</Text>
              <TextButton onClick={() => refetchSchema()}>Refresh Schema</TextButton>
            </Flex>
            {databaseSchema ? (
              <Widget pb="s-16" px="s-16">
                <DatabaseSchema catalogData={Object.values(databaseSchema)[0]} />
              </Widget>
            ) : (
              <Widget color={Color.GREY_TONE_50} pb="s-32" pt="s-16">
                <VStack align="center" space="s-16" pt="s-16">
                  <Materials />
                  <Text>There is no data to display</Text>
                </VStack>
              </Widget>
            )}
          </>
        )}
      </Side>
    </Layout.SideFill>
  )
}
