import { FieldOptions, IdAndName } from '@src/interfaces/index'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { EmployeeOptionInterface, IdStatuses } from '@src/interfaces/employees'
import { ApplicationFormSectionInterface } from '@src/interfaces/applicationForm'
import { SidebarJobDescription } from '@src/interfaces/jobDescription'
import { LocationInterface } from '@src/interfaces/locations'

export interface JobPostingLocationInterface
  extends Pick<
    LocationInterface,
    | 'location_name'
    | 'id'
    | 'posting_compensation_enabled'
    | 'posting_compensation_period'
  > {
  name: string
  type?: LocationType | null
}

export interface RecruiterLocationInterface {
  id: number
  recruiter: EmployeeOptionInterface
  locations: JobPostingLocationInterface[]
}

export enum PublishingStatuses {
  unpublished = 'unpublished',
  published_internally = 'published_internally',
  published_externally = 'published_externally',
  fully_published = 'fully_published',
  closed = 'closed',
}

export interface JobPostingSalaryBandInterface {
  iso_code: string
  name: string
  posting_compensation_period?: 'monthly' | 'yearly'
  symbol?: string
  lower_band: number | null
  upper_band: number | null
}

export interface JobPostingSalaryBandsByLocationInterface {
  [key: string]: JobPostingSalaryBandInterface
}

export interface JobPostingInterface {
  id: number
  name: string
  is_published?: boolean
  is_generated?: boolean
  locations?: JobPostingLocationInterface[]
  specialisation?: {
    id: string | number
    name?: string
  }
  requisitions_count?: number
  sections?: { title: string; content: string }[]
  status: PublishingStatuses
  status_display: string
  title: string
  field_options?: FieldOptions
  approval_status: ApprovalStatuses
  location_source: LocationSource
  recruiter: {
    display_name: string
    full_name: string
    id: number
    name: string
    status: IdStatuses
    email?: string
  }
  application_form_sections: ApplicationFormSectionInterface[]
  recruiter_locations: RecruiterLocationInterface[]
  rejection_reason?: string
  salary_bands_by_location?: JobPostingSalaryBandsByLocationInterface
  public_id?: string
  apply_url?: string
  careers_position_url?: string
}

export interface FullJobPostingInterface extends Omit<JobPostingInterface, 'sections'> {
  sections?: SidebarJobDescription[]
}

export enum LocationSource {
  requisition = 'requisition',
  manual = 'manual',
}

export type LocationType = 'remote' | 'office'

export interface JobPostingLocationTypeInterface {
  id: number
  name: string
  locations: JobPostingLocationInterface[]
  specialisation?: IdAndName
}
