import React from 'react'

import { AxiosPromise } from 'axios'

import PopupChart from '../BaseChart/PopupChart'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { PerformanceTimeRange } from '@src/constants/api'
import { useQuery } from '@src/utils/queryParamsHooks'
import {
  BaseChartInner,
  BaseInnerPropsInterface,
} from '@components/Charts/BaseChart/BaseChartInner'

export type fetchDataType = (
  id: string,
  period: PerformanceTimeRange,
  cycleOffset?: string,
) => AxiosPromise<PerformanceChartCycles>

interface GraphIconChartInterface extends BaseInnerPropsInterface {
  id: number
  children: React.ReactNode
  vertical?: 'left' | 'right'
  fetchData: fetchDataType
}

const GraphIconChart = ({
  id,
  children,
  fetchData,
  vertical,
  ...rest
}: GraphIconChartInterface) => {
  const { query } = useQuery()
  return (
    <PopupChart trigger={children} vertical={vertical}>
      {fullScreen => (
        <BaseChartInner
          id={id}
          cycleOffset={query?.review_cycle__offset}
          fetchData={fetchData}
          showButtons={fullScreen}
          isNew
          {...rest}
        />
      )}
    </PopupChart>
  )
}

export default GraphIconChart
