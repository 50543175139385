import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationsActionColumn,
  notificationsCategoryExtra,
  notificationsDeadline,
  notificationsDocumentTitle,
  notificationsDocumentType,
  notificationsEmployeedColumn,
  notificationsPriorityColumn,
  notificationsReceivedColumn,
} from '@src/constants/columns/notifications'
import { notificationsPendingDocuments } from '@src/api/notifications'

const PendingDocuments = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const isDashboard = type === 'dashboard'

  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationsDocumentTitle,
        width: 200,
      },
      {
        ...notificationsDocumentType,
        width: 200,
      },
      {
        ...notificationsCategoryExtra,
        title: 'Source',
        width: 80,
      },
      {
        ...notificationsEmployeedColumn(isDashboard),
        title: 'Issuer',
        width: isDashboard ? 100 : 240,
      },
      {
        ...notificationsReceivedColumn,
        width: 130,
      },
      {
        ...notificationsDeadline,
        width: 120,
      },
      {
        ...notificationsPriorityColumn,
        width: 100,
      },
      {
        ...notificationsActionColumn,
        width: 130,
      },
    ],
  }

  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsPendingDocuments(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default PendingDocuments
