import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactQuill, { ReactQuillProps } from 'react-quill'
import { css, FlattenInterpolation } from 'styled-components'
import { Box, Flex, IconButton, Checkbox, Text, Token } from '@revolut/ui-kit'
import { SendMessage } from '@revolut/icons'
import useTagging from '@components/Chat/ChatTextEditor/useTagging'
import { checkChatSubmitKey } from '@components/Chat/common'
import { TaggedUsersMap } from '@src/interfaces/chat'
import { DeltaStatic } from 'quill'
import DOMPurify from 'dompurify'

export enum ChatTextEditorVariant {
  Input = 'input',
  InMessage = 'in_message',
  InTable = 'in_table',
}

export interface ChatTextEditorProps {
  onSubmit: (message: string, createTask: boolean) => Promise<any>
  variant: ChatTextEditorVariant
  disableTodolistFeature?: boolean
  placeholder?: string
  readonly?: boolean
  taggedEmployees?: TaggedUsersMap
  defaultText?: string
  children?: (
    getValue: () => string,
    setValue: (value: string) => void,
  ) => React.ReactNode
}

const defaultHeight = 56
const iconWidth = 50

const quillCss = css`
  .ql-editor {
    padding-top: 1rem;
    padding-bottom: 0.8rem;
    line-break: normal;
    word-break: break-word;
    color: ${Token.color.foreground};
  }

  .ql-disabled .ql-editor {
    padding: 0;
  }

  .ql-disabled .ql-editor.ql-blank::before {
    display: none;
  }

  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 2px;
  }

  .ql-toolbar.ql-snow {
    border: 0;
    border-bottom: 1px solid ${Token.color.greyTone10};
  }

  .ql-container.ql-snow {
    border: 0;
    background-color: inherit;
  }

  .ql-container {
    font: inherit;
    font-size: 1rem;
  }

  .ql-container > .ql-editor.ql-blank::before {
    font-style: normal;
    color: ${Token.color.greyTone50};
  }
`

const readonlyWidgetCss = css`
  transition: background-color ease-out 200ms;

  &:hover,
  &:active,
  &:focus-within {
    background: ${Token.color.greyTone10};
  }
`

const inputVariantCss = css`
  .ql-editor {
    min-height: ${defaultHeight}px !important;
    max-height: ${defaultHeight * 2}px;
  }
`

const inTableVariantCss = css`
  .ql-editor {
    padding: 0;
    width: max-content;
    font-size: 13px;
  }
`

const modules: ReactQuillProps['modules'] = {
  toolbar: false,
  clipboard: { matchVisual: false },
}

const getEditorCss = (
  variant: ChatTextEditorVariant,
): FlattenInterpolation<unknown> | undefined => {
  switch (variant) {
    case ChatTextEditorVariant.Input:
      return inputVariantCss
    case ChatTextEditorVariant.InTable:
      return inTableVariantCss
    default:
      return undefined
  }
}

const ChatTextEditor = ({
  onSubmit,
  readonly = false,
  defaultText,
  taggedEmployees,
  variant,
  placeholder = 'Add comment',
  children = () => null,
  disableTodolistFeature,
}: ChatTextEditorProps) => {
  const quillRef = useRef<ReactQuill>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [createTask, setCreateTask] = useState<boolean>(true)

  const { tagSelector, ...tagging } = useTagging({
    quillRef,
    containerRef,
    taggedUsers: taggedEmployees,
  })

  const inMessage = variant === ChatTextEditorVariant.InMessage

  const defaultValue = useMemo(
    () => (defaultText ? DOMPurify.sanitize(defaultText) : undefined),
    [defaultText],
  )

  const onChange: ReactQuillProps['onChange'] = (_, delta, source, editor) => {
    tagging.onChange(delta, source, editor)
  }

  const submit = () => {
    const editor = quillRef.current?.getEditor()
    const text = editor?.getText()

    if (!text || text.match(/^\s*$/)) {
      return
    }
    const transformedValue = tagging.transformValueForSubmit(!inMessage)
    const hasTags = !!transformedValue.match(/\[id: ?\d+]/)

    onSubmit(transformedValue, hasTags ? createTask : false).finally(() => {
      tagging?.onAfterSubmit()
    })
  }

  const setValue = (value: string) => {
    const editor = quillRef.current?.getEditor()
    if (editor) {
      editor.setContents({ ops: [{ insert: value }] } as DeltaStatic)
    }
  }

  // After ui-kit 12.3 version quill editor has problems with focus after the dropdown opening
  useEffect(() => {
    if (tagging.forceFocus) {
      setTimeout(() => quillRef.current?.getEditor()?.focus(), 0)
    }
  }, [tagging.forceFocus])

  useEffect(() => {
    // Autofocus the inMessage editor
    if (inMessage && !readonly) {
      setTimeout(() => {
        const editor = quillRef.current?.getEditor()
        const lastIndex = editor?.getText()?.length
        editor?.setSelection(lastIndex || 0, 0)
      }, 0)
    }
  }, [inMessage, readonly])

  return (
    <>
      {tagging.hasTags && !disableTodolistFeature && (
        <Box pb="s-8">
          <Checkbox
            checked={createTask}
            onChange={e => {
              setCreateTask(e.currentTarget.checked)
            }}
          >
            <Text color="foreground">
              Add as a task to user's to-do list to inform them about this comment
            </Text>
          </Checkbox>
        </Box>
      )}
      <Box
        bg={readonly ? undefined : 'grey-tone-8'}
        css={readonly ? undefined : readonlyWidgetCss}
        ref={containerRef}
        radius="widget"
      >
        {tagSelector}
        <Flex alignItems="flex-start" width="100%" css={getEditorCss(variant)}>
          <Box
            flex={readonly ? '1 0 100%' : `1 0 calc(100% - ${iconWidth}px)`}
            css={quillCss}
          >
            <ReactQuill
              preserveWhitespace
              defaultValue={defaultValue}
              ref={quillRef}
              onChange={onChange}
              theme="snow"
              placeholder={placeholder}
              modules={modules}
              readOnly={readonly}
              onKeyDown={e => {
                if (checkChatSubmitKey(e)) {
                  e.stopPropagation()
                  submit()
                } else {
                  tagging.onKeyDown(e)
                }
              }}
            />
          </Box>
          {!readonly && !inMessage && (
            <Flex
              justifyContent="center"
              alignItems="center"
              width={iconWidth}
              height={defaultHeight}
            >
              <IconButton
                useIcon={SendMessage}
                color="primary"
                onClick={submit}
                aria-label="send message"
              />
            </Flex>
          )}
        </Flex>
      </Box>
      {children(() => tagging.transformValueForSubmit(!inMessage), setValue)}
    </>
  )
}

export default ChatTextEditor
