export enum OrderingDataType {
  Roles = 'roles',
  Specialisation = 'specialisation',
}

export interface UpdateOrderingInterface {
  item_object_ids: (number | string)[]
  target_position_object_id?: number | string
  target_position_number?: number
}
