import React from 'react'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { Group, InputGroup, Item, Switch, TextArea } from '@revolut/ui-kit'
import { useArchiving } from '@src/pages/Forms/ArchiveOpportunity/common'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'

type Props = {
  isLoading?: boolean
}

const ArchiveOpportunityContent = ({ isLoading }: Props) => {
  const {
    reason,
    setReason,
    comments,
    setComments,
    archiveAll,
    setArchiveAll,
    setSendEmail,
    sendEmail,
  } = useArchiving()

  return (
    <InputGroup>
      <RadioSelectInput<OptionInterface>
        value={
          reason?.id
            ? {
                id: reason?.id,
                name: reason?.name,
              }
            : undefined
        }
        onChange={setReason}
        selector={selectorKeys.interview_round_archived_reasons}
        label="Archival reason"
        disabled={isLoading}
      />

      <TextArea
        value={comments}
        onChange={e => {
          setComments(e.currentTarget.value || '')
        }}
        label="Comments (Optional)"
        autosize={false}
        rows={3}
        resize="vertical"
        disabled={isLoading}
      />

      <Group>
        <Item use="label">
          <Item.Content>
            <Item.Title color="grey-tone-50">Archive all opportunities</Item.Title>
          </Item.Content>
          <Item.Side>
            <Switch
              onChange={e => {
                setArchiveAll(e.target.checked)
              }}
              checked={archiveAll}
              data-testid="archive_all_opportunities_switch"
            />
          </Item.Side>
        </Item>
        <Item use="label">
          <Item.Content>
            <Item.Title color="grey-tone-50">Send email</Item.Title>
          </Item.Content>
          <Item.Side>
            <Switch
              onChange={e => {
                setSendEmail(e.target.checked)
              }}
              checked={sendEmail}
              data-testid="send_email_switch"
            />
          </Item.Side>
        </Item>
      </Group>
    </InputGroup>
  )
}

export default ArchiveOpportunityContent
