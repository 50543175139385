import React, { useEffect, useState } from 'react'
import { Box, Flex, TabBar, Text } from '@revolut/ui-kit'
import CompetencyMatrix from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/HiringPanelSummary/CompetencyMatrix'
import {
  InterviewRoundInterface,
  InterviewRoundSummaryInterface,
} from '@src/interfaces/interviewTool'
import { getRoundSummary } from '@src/api/recruitment/interviews'
import HiringPanelForm from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/HiringPanelSummary/HiringPanelForm'
import StagesTable from '@src/pages/Forms/Candidate/InterviewProgress/StagesTable'
import AppliedPostingsTable from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/HiringPanelSummary/AppliedPostingsTable'
import { useFetchStagesTable } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'

type Props = {
  round?: InterviewRoundInterface
  isViewMode?: boolean
}

enum Tabs {
  MatrixTab = 'Competency matrix',
  InterviewsTab = 'Interviews',
  PostingsTab = 'Applied postings',
}

const HiringPanelSummary = ({ round, isViewMode = false }: Props) => {
  const { stages, feedbacks, status } = useFetchStagesTable(round?.id)
  const [tab, setTab] = useState(Tabs.MatrixTab)
  const [summary, setSummary] = useState<InterviewRoundSummaryInterface>()

  const updateSummary = async () => {
    if (round?.id) {
      const resp = await getRoundSummary(round?.id)
      setSummary(resp.data)
    }
  }

  useEffect(() => {
    updateSummary()
  }, [round?.id])

  if (!round) {
    return null
  }

  return (
    <>
      <Text variant="h6" use="div" pb="s-16" color="grey-tone-50">
        Summary of ratings
      </Text>
      <Flex>
        <TabBar mb="s-16">
          {[Tabs.MatrixTab, Tabs.InterviewsTab, Tabs.PostingsTab].map(elm => (
            <TabBar.Item
              key={elm}
              aria-selected={tab === elm}
              onClick={() => setTab(elm)}
            >
              <Text use="div" px="s-20">
                {elm}
              </Text>
            </TabBar.Item>
          ))}
        </TabBar>
      </Flex>
      {tab === Tabs.MatrixTab && <CompetencyMatrix summary={summary} />}
      {tab === Tabs.InterviewsTab && (
        <Box mb="s-32">
          <StagesTable
            round={round}
            hideCount
            disableActions
            canViewEditOffer={false}
            stages={stages}
            feedbacks={feedbacks}
            status={status}
            candidateId={round.candidate.id}
          />
        </Box>
      )}
      {tab === Tabs.PostingsTab && (
        <AppliedPostingsTable candidateId={round.candidate.id} />
      )}
      <Text variant="h6" use="div" pb="s-16" color="grey-tone-50">
        {isViewMode ? 'Scorecard' : 'Fill the scorecard'}
      </Text>
      <HiringPanelForm summary={summary} isViewMode={isViewMode} />
    </>
  )
}

export default HiringPanelSummary
