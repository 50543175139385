import React, { useEffect, useRef } from 'react'
import {
  ReviewCategory,
  ReviewerRelation,
  ReviewScorecardInterface,
  SectionType,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  getRoundedRating,
  GradeOption,
  kpiRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'

export const DeliverablesCard = connect(
  ({ selectedField, setSelectedField, onHelpClick }: CommonCardProps) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const hasKPI = !!values.review_data.kpis_section
    const ref = useRef<HTMLDivElement>(null)

    const deliverablesGrades =
      values.review_data.deliverables?.cards?.[0].sections?.[0]?.type ===
      SectionType.SingleChoice
        ? values.review_data.deliverables.cards[0].sections[0].options.map(option => ({
            key: option.key,
            text: PerformanceRatingTitle[option.key],
            description: [option.text],
          }))
        : []
    const onDeliverablesSkip = (value?: string) => {
      set(values, 'review_data.deliverables.skipped_section_justification', value)
    }

    useEffect(() => {
      if (
        !values.review_data.kpis_section?.recommended_rating &&
        values.review_data.kpis_section?.rating
      ) {
        set(
          values,
          'review_data.kpis_section.recommended_rating',
          getRoundedRating(values.review_data.kpis_section?.rating),
        )
      }
    }, [values.review_data.kpis_section])

    useEffect(() => {
      if (errors.review_data?.deliverables || errors.review_data?.kpis_section) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data?.deliverables, errors.review_data?.kpis_section])

    return (
      <>
        {hasKPI ? (
          <Card
            data={values}
            type={CardContentTypes.KPI}
            title="Goals and deliverables"
            icon="Target"
            fields={[
              {
                field: 'review_data.kpis_section',
                title: 'Contribution and impact',
                grades: kpiRating as GradeOption[],
              },
            ]}
            onSelectGrade={grade => {
              set(values, `review_data.kpis_section.recommended_rating`, grade.key)
            }}
            isGradeSelectedRule={(field, grade) => {
              const ratingValue = get(values, field)?.recommended_rating
              return !!ratingValue && ratingValue === grade.key
            }}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            headerRef={ref}
            onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
          />
        ) : (
          <Card
            data={values}
            type={CardContentTypes.DELIVERABLES}
            title="Goals and deliverables"
            icon="Target"
            fields={[
              {
                field: `review_data.deliverables.cards.0`,
                title: 'Contribution and impact',
                grades: deliverablesGrades,
              },
            ]}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            onSelectGrade={grade => {
              values.review_data.deliverables?.cards?.forEach((_, ind) => {
                set(
                  values,
                  `review_data.deliverables.cards.${ind}.sections.0.value`,
                  grade.key,
                )
              })
            }}
            isGradeSelectedRule={(field, grade) => {
              const ratingValue = get(values, field)?.sections?.[0]?.value
              return !!ratingValue && ratingValue === grade.key
            }}
            allowSkip={
              values.category === ReviewCategory.Performance &&
              values.reviewer_relation !== ReviewerRelation.LineManager
            }
            onSkip={onDeliverablesSkip}
            justification={
              values?.review_data?.deliverables?.skipped_section_justification
            }
            headerRef={ref}
            onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
          />
        )}
      </>
    )
  },
)
