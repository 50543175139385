import { GetRequestData, UseFetchResult } from '@src/interfaces/index'
import { AxiosPromise } from 'axios'
import { EmployeeOptionInterface, AvatarType } from '@src/interfaces/employees'

export enum ChatMessageStatus {
  active = 'active',
  completed = 'completed',
  archived = 'archived',
}

export enum ChatMessageCategory {
  kpi = 'kpi',
}

export type TaggedUsersMap = {
  [id: number]: EmployeeOptionInterface
}

export interface ChatMessageInterface {
  id: number
  object_id: number
  body: string
  tagged_employees: TaggedUsersMap
  category: { id: ChatMessageCategory; name: string }
  created_by: {
    id: number
    full_name: string
    avatar: AvatarType
    job_title: string
  }
  create_task: boolean
  creation_date_time: string
  edit_date_time: null
  status: { id: ChatMessageStatus; name: string }
}

export type ChatMessageErrorInterface = string[]

export interface CommentsAPIInterface {
  useGetComments: (
    showArchived?: boolean,
    disabled?: boolean,
  ) => UseFetchResult<GetRequestData<ChatMessageInterface>>
  useGetComment: (commentId: number | null) => UseFetchResult<ChatMessageInterface>

  addComment: (comment: string, createTask: boolean) => AxiosPromise<ChatMessageInterface>
  archiveComment: (commentId: number) => AxiosPromise<unknown>
  resolveComment: (commentId: number) => AxiosPromise<unknown>
  editComment: (
    commentId: number,
    updatedMsg: string,
  ) => AxiosPromise<ChatMessageInterface>
}
