import React, { useState } from 'react'
import { TeamInterface } from '@src/interfaces/teams'
import { teamCXComplaintsGraph } from '@src/api/teams'
import {
  BaseChartInner,
  timeRangeTabs,
} from '@components/Charts/BaseChart/BaseChartInner'
import { connect } from 'lape'
import { PerformanceTimeRange } from '@src/constants/api'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { getCXComplaints } from '@src/api/cx'
import { CXComplaintsInterface } from '@src/interfaces/businessDebts'
import {
  cxComplaintCreatedAtColumn,
  cxComplaintIdColumn,
  cxSupportTagColumn,
} from '@src/constants/columns/cx'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { departmentCXComplaintsGraph } from '@src/api/department'
import { getBarMonthFilter } from '@src/utils/graph'
import { Button, Flex, Widget } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { InfoOutline } from '@revolut/icons'
import { PermissionTypes } from '@src/store/auth/types'
import { NoHeliosPermissions } from '@src/features/FormTabs/CX/components/NoAccessWidgets'
import CXTable from '@src/features/FormTabs/CX/components/CXTable'
import useChartTableSwitcher, {
  ChartTableTabs,
} from '@src/features/TabBarSwitcher/useChartTableSwitcher'
import { TableTypes } from '@src/interfaces/table'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import NewGrid from '@components/CommonSC/NewGrid'
import { COMPLAINTS_SERVICE_DESK } from '@src/constants/externalLinks'
import { TableNames } from '@src/constants/table'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

interface StructureProps {
  data: TeamInterface | DepartmentInterface
  isTeam?: boolean
}

const ROW: RowInterface<CXComplaintsInterface> = {
  cells: [
    {
      ...cxSupportTagColumn,
      width: 600,
    },
    {
      ...cxComplaintCreatedAtColumn,
      width: 200,
    },
    {
      ...cxComplaintIdColumn,
      width: 200,
    },
  ],
}

const Complaints = ({ data, isTeam }: StructureProps) => {
  const canViewComments = data.field_options.permissions?.includes(
    PermissionTypes.ViewComplaintsComments,
  )
  const { tabBar, currentTab, setCurrentTab } = useChartTableSwitcher()
  const { tabBar: chartRangeTabBar, currentTab: chartRange } = useTabBarSwitcher({
    tabs: timeRangeTabs,
    capitalize: true,
    defaultTab: PerformanceTimeRange.week,
  })
  const [filter, setFilter] = useState<FilterByInterface | undefined>()

  const initialFilterBy = [
    {
      filters: [{ id: data.id, name: `${data.id}` }],
      columnName: isTeam ? 'team__id' : 'department__id',
      nonResettable: true,
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'creation_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const tableTab = canViewComments ? (
    <CXTable<CXComplaintsInterface>
      name={TableNames.CXComplaints}
      dataType="Complaint"
      row={ROW}
      filter={filter}
      type={TableTypes.NewStepper}
      noDataMessage="No Complaints found"
      initialFilter={initialFilterBy}
      initialSort={initialSortBy}
      getItems={getCXComplaints}
    />
  ) : (
    <NoHeliosPermissions />
  )

  return (
    <Widget
      display="flex"
      p="s-16"
      width="100%"
      flex={currentTab === ChartTableTabs.Chart ? '1 0' : ''}
      style={{ position: 'relative' }}
    >
      <Flex flexDirection="column" flex="1 0" width="100%">
        <Flex mb="s-24" alignItems="center">
          <Stat
            label="Total number of Complaints (last 28 days)"
            val={data.cx_complaints_last_30_days}
          />
        </Flex>
        <NewGrid
          width="min-content"
          mb="s-16"
          gridAutoFlow={{ all: 'row', md: 'column' }}
          gridGap="s-8"
        >
          {tabBar}
          {chartRangeTabBar}
          <HideIfCommercial>
            <Button
              target="_blank"
              rel="noreferrer noopener"
              href={COMPLAINTS_SERVICE_DESK}
              use="a"
              mr="s-16"
              useIcon={InfoOutline}
              size="sm"
              variant="secondary"
            >
              Something does not look right?
            </Button>
          </HideIfCommercial>
        </NewGrid>
        {currentTab === ChartTableTabs.Table && tableTab}
        {currentTab === ChartTableTabs.Chart && (
          <Flex flexDirection="column" flex="1 0" style={{ position: 'relative' }}>
            <BaseChartInner
              isNew
              fetchData={isTeam ? teamCXComplaintsGraph : departmentCXComplaintsGraph}
              id={data.id}
              graphTimeRange={chartRange}
              highlightHoveredCells
              onClick={bar => {
                if (!canViewComments) {
                  return
                }
                setCurrentTab(ChartTableTabs.Table)
                setFilter(getBarMonthFilter(bar.data.progress_datetime))
              }}
            />
          </Flex>
        )}
      </Flex>
    </Widget>
  )
}

export default connect(Complaints)
