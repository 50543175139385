import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { Avatar, Banner, VStack } from '@revolut/ui-kit'
import React, { useMemo } from 'react'
import { GoalsTableWidget } from './GoalsTableWidget'
import { useGetSelectors } from '@src/api/selectors'
import { ReviewCyclesSelectorInterface } from '@src/interfaces/reviewCycles'
import { useGetGoalContentTypeFilter } from './useGoalFilters'

const useGetCurrentCyclesId = () => {
  const { data } = useGetSelectors<ReviewCyclesSelectorInterface>(
    selectorKeys.review_cycles,
  )
  return data ? data.find(({ offset }) => offset === '0') : undefined
}

const useInitialFilters = () => {
  const { entity } = useOrgEntity()
  const currentCycle = useGetCurrentCyclesId()
  const contentTypeFilter = useGetGoalContentTypeFilter(entity?.type)

  return useMemo(() => {
    const initialFilters =
      currentCycle && contentTypeFilter && entity
        ? [
            {
              columnName: 'cycle__id',
              filters: [{ id: currentCycle.id, name: currentCycle.name }],
            },
            contentTypeFilter,
          ]
        : null

    if (initialFilters && entity && entity.type !== EntityTypes.company) {
      initialFilters.push({
        columnName: 'object_id',
        filters: [{ id: entity.data.id, name: entity.data.name || '' }],
        nonResettable: true,
      })
    }

    return initialFilters
  }, [currentCycle, contentTypeFilter, entity])
}

export const GoalsTab = () => {
  const { entity } = useOrgEntity()
  const initialFilters = useInitialFilters()

  const getBannerMessageByType = (entityType?: EntityTypes) => {
    switch (entityType) {
      case EntityTypes.department:
        return 'Please add and review the department goals for the upcoming quarter. Goals should include a clear definition of done and a target for the success criteria which tracks progress towards the outcome of the goal. Goals should be set to impact the outcome of our company goals.'
      default:
        return 'text to be defined'
    }
  }

  return (
    <VStack space="s-24">
      {entity ? (
        <Banner>
          <Banner.Avatar>
            <Avatar
              variant="app"
              image={{
                default: `https://assets.revolut.com/assets/3d-images/3D196.png`,
                '2x': 'https://assets.revolut.com/assets/3d-images/3D196@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images/3D196@3x.png',
              }}
              size={56}
            />
          </Banner.Avatar>
          <Banner.Content>
            <Banner.Title>Goal setting and approval</Banner.Title>
            {/* todo: Amend banner content with link to company goals and tag with number of days left for goal setting */}
            <Banner.Description>
              {getBannerMessageByType(entity?.type)}
            </Banner.Description>
          </Banner.Content>
        </Banner>
      ) : null}
      {initialFilters ? <GoalsTableWidget initialFilters={initialFilters} /> : null}
    </VStack>
  )
}
