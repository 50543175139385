import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import {
  Text,
  Button,
  Item,
  VStack,
  Input,
  Group,
  HStack,
  ItemSkeleton,
  StatusWidget,
  InputGroup,
} from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import Form from '@src/features/Form/Form'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { Delete, Pencil, Plus } from '@revolut/icons'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import {
  createKeyPersonCategory,
  deleteKeyPersonCategory,
  editKeyPersonCategory,
  useKeyPersonCategories,
} from '@src/api/keyPerson'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { keyPersonsSettings } from '@src/api/settings'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

const KeyPersonsSettings = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeKeyPersonPreferences)

  const canViewCategories = permissions.includes(
    PermissionTypes.ViewKeyPersonsResponsibilityCategories,
  )
  const canAddCategories = permissions.includes(
    PermissionTypes.AddKeyPersonsResponsibilityCategories,
  )
  const canChangeCategories = permissions.includes(
    PermissionTypes.ChangeKeyPersonsResponsibilityCategories,
  )
  const canDeleteCategories = permissions.includes(
    PermissionTypes.DeleteKeyPersonsResponsibilityCategories,
  )

  const { data: keyPersonCategoriesData, refetch } = useKeyPersonCategories()

  const [editCategoryDialogOpen, setEditCategoryDialogOpen] = useState(false)
  const [editCategoryPending, setEditCategoryPending] = useState(false)
  const [categoryName, setCategoryName] = useState('')
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>()

  const [deleteCategoryDialogOpen, setDeleteCategoryDialogOpen] = useState(false)
  const [deleteCategoryPending, setDeleteCategoryPending] = useState(false)

  return (
    <>
      <PageHeader
        title="Key Persons app settings"
        subtitle="Settings for the key persons functionalities"
        backUrl={ROUTES.SETTINGS.ALL}
      />

      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Responsibilities" />
          {canViewCategories ? (
            <Group>
              <VStack space="s-4">
                <Item>
                  <Item.Content>
                    <Item.Title>Categories</Item.Title>
                    <Item.Description>
                      Each responsibility is attached to a category
                    </Item.Description>
                  </Item.Content>
                  {canAddCategories && (
                    <Item.Side>
                      <Button
                        size="sm"
                        variant="secondary"
                        useIcon={Plus}
                        onClick={() => {
                          setSelectedCategoryId(undefined)
                          setCategoryName('')
                          setEditCategoryDialogOpen(true)
                        }}
                      >
                        Add new
                      </Button>
                    </Item.Side>
                  )}
                </Item>
                {keyPersonCategoriesData?.results ? (
                  keyPersonCategoriesData.results.map(category => (
                    <Item key={category.id}>
                      <Item.Content>
                        <Item.Title>{category.name}</Item.Title>
                      </Item.Content>

                      <Item.Side>
                        <HStack space="s-16">
                          <Button
                            disabled={!canChangeCategories}
                            variant="secondary"
                            size="sm"
                            useIcon={Pencil}
                            aria-label={`Edit category ${category.name}`}
                            onClick={() => {
                              setSelectedCategoryId(category.id)
                              setCategoryName(category.name)
                              setEditCategoryDialogOpen(true)
                            }}
                          />
                          <Button
                            disabled={!canDeleteCategories}
                            variant="negative"
                            size="sm"
                            useIcon={Delete}
                            aria-label={`Delete category ${category.name}`}
                            onClick={() => {
                              setSelectedCategoryId(category.id)
                              setCategoryName(category.name)
                              setDeleteCategoryDialogOpen(true)
                            }}
                          />
                        </HStack>
                      </Item.Side>
                    </Item>
                  ))
                ) : (
                  <VStack space="s-12">
                    <ItemSkeleton />
                    <ItemSkeleton />
                    <ItemSkeleton />
                  </VStack>
                )}
              </VStack>
            </Group>
          ) : (
            <StatusWidget>
              <StatusWidget.Image
                src="https://assets.revolut.com/assets/3d-images/3D173a.png"
                srcSet="https://assets.revolut.com/assets/3d-images/3D173a@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D173a@3x.png 3x"
              />
              <StatusWidget.Title>
                Responsibilities categories list is not available
              </StatusWidget.Title>
              <StatusWidget.Description>
                You cannot see this information due to the permissions restrictions
              </StatusWidget.Description>
            </StatusWidget>
          )}
        </AutoStepper>
        <ConfirmationDialog
          open={editCategoryDialogOpen}
          loading={editCategoryPending}
          onClose={() => setEditCategoryDialogOpen(false)}
          onConfirm={async () => {
            setEditCategoryPending(true)
            try {
              if (selectedCategoryId) {
                await editKeyPersonCategory(selectedCategoryId, categoryName)
              } else {
                await createKeyPersonCategory(categoryName)
              }
              await refetch()
            } finally {
              setEditCategoryPending(false)
              setEditCategoryDialogOpen(false)
            }
          }}
          onReject={() => {
            setEditCategoryDialogOpen(false)
          }}
          label={selectedCategoryId ? 'Edit category' : 'Add new category'}
          body={
            <Input
              label="Category name"
              value={categoryName}
              onChange={e => setCategoryName(e.currentTarget.value)}
            />
          }
          yesMessage="Continue"
          noMessage="Cancel"
        />
        <ConfirmationDialog
          open={deleteCategoryDialogOpen}
          loading={deleteCategoryPending}
          onClose={() => setDeleteCategoryDialogOpen(false)}
          onConfirm={async () => {
            setDeleteCategoryPending(true)
            try {
              if (selectedCategoryId) {
                await deleteKeyPersonCategory(selectedCategoryId)
              }
              await refetch()
            } finally {
              setDeleteCategoryPending(false)
              setDeleteCategoryDialogOpen(false)
            }
          }}
          onReject={() => {
            setDeleteCategoryDialogOpen(false)
          }}
          label={`Delete category`}
          body={<Text>Are you sure you want to delete category {categoryName}?</Text>}
          yesMessage="Continue"
          noMessage="Cancel"
        />

        <NewStepperTitle title="Succession" />
        <InputGroup>
          <InputGroup variant="horizontal">
            <LapeNewInput
              disabled={disableEdit}
              required
              name="succession_plan_renewal_period"
              label="Succession plan renewal period"
              type="number"
            />
            <Input disabled value="Months" />
          </InputGroup>
          <LapeNewInput
            disabled={disableEdit}
            required
            name="succession_minimal_successors_count"
            label="Minimum number of active successors"
            type="number"
          />
        </InputGroup>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText="Settings successfully updated"
        />
      </PageActions>
    </>
  )
}

export const General = connect(() => (
  <Form api={keyPersonsSettings} forceParams={{ id: '1' }}>
    <KeyPersonsSettings />
  </Form>
))
