import React, { useMemo } from 'react'
import { FilterByInterface } from '@src/interfaces/data'
import { ToggleButton } from '@revolut/ui-kit'
import { Grid } from '@components/CommonSC/Grid'

interface CheckMarkFilterProps {
  onFilterChange: (filters: FilterByInterface, reset?: boolean) => void
  columnName: string
  filter: FilterByInterface[]
  filters: {
    key: string
    label: string
    summary?: string
  }[]
}

export const CheckMarkFilter = ({
  filter,
  filters,
  onFilterChange,
  columnName,
}: CheckMarkFilterProps) => {
  const filteredFilters = useMemo(
    () =>
      filter
        ?.find((filterBy: FilterByInterface) => filterBy.columnName === columnName)
        ?.filters.filter(option => option.id !== '' && option.name !== ''),
    [filter],
  )

  return (
    <Grid gap={8} mt="s-16" flow="column" justifyContent="start">
      {filters.map(filterItem => (
        <ToggleButton
          key={filterItem.key}
          onChange={e => {
            if (e.target.checked) {
              onFilterChange(
                {
                  columnName,
                  filters: [
                    ...(filteredFilters || []),
                    { name: filterItem.key, id: filterItem.key },
                  ],
                },
                false,
              )
            } else {
              onFilterChange(
                {
                  columnName,
                  filters:
                    filteredFilters?.filter(item => item.id !== filterItem.key) || [],
                },
                false,
              )
            }
          }}
          checked={!!filteredFilters?.find(item => item.id === filterItem.key)}
        >
          {filterItem.summary !== undefined
            ? `${filterItem.label} · ${filterItem.summary}`
            : filterItem.label}
        </ToggleButton>
      ))}
    </Grid>
  )
}
