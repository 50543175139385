import React from 'react'
import { Group, Box, TransitionCollapse, VStack, Text } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { API, selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeSettingsInterface } from '@src/interfaces/settings'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const Employees = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const { values } = useLapeContext<EmployeeSettingsInterface>()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeEmployeePreferences)

  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle
            title="Profile"
            subtitle="Settings related to the work and personal profiles of employees"
          />
          <VStack gap="s-24">
            {isCommercial && (
              <LapeMultiInput
                title="Create labels"
                name="employee_labels"
                message="Labels allow you to manually select a group of employees to assign actions e.g. First aider, Re-hire"
                valueFieldName={undefined}
                disabled={disableEdit}
                label="Example Label"
                withCell
              />
            )}
            <LapeMultiInput
              title="Allowed email domains"
              description="Email domains that can be used by your employees"
              name="allowed_email_domains"
              valueFieldName={undefined}
              disabled={disableEdit}
              label="example.com"
              withCell
            />

            <Box>
              <Group>
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable importing employees',
                    description:
                      'Enables the ability to import employees in bulk from CSV or XLS files',
                  }}
                  name="enable_import_data"
                  disabled={disableEdit}
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable exporting employees',
                    description:
                      'Enables the export of the employees list as a CSV or XLS file',
                  }}
                  name="enable_export_csv_xls"
                  disabled={disableEdit}
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable Department changes',
                    description:
                      'Allows the HR manager to change the department of an employee',
                  }}
                  name="enable_department_migration"
                  disabled={disableEdit}
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable Employee ID field',
                    description:
                      'Allows using the Employee ID field to display the unique identifier for the employee',
                  }}
                  name="enable_show_employee_id"
                  disabled={disableEdit}
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable Buddy field',
                    description:
                      'Allows setting a buddy to an employee who is supposed to help them with general questions during the onboarding process and afterwards',
                  }}
                  name="enable_buddy"
                  disabled={disableEdit}
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable Job Title field',
                    description:
                      'Allows setting and displaying the job title of the employee in the company ',
                  }}
                  name="enable_job_title"
                  disabled={disableEdit}
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable Notes section',
                    description:
                      'Enables the notes section, allowing HR to add additional information about the profile',
                  }}
                  name="enable_notes"
                  disabled={disableEdit}
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable Bank details section',
                    description:
                      'Enables the Bank Details section in personal profiles to capture necessary information for payroll payments tracking',
                  }}
                  name="enable_bank_details"
                  disabled={disableEdit}
                />
              </Group>
            </Box>
          </VStack>

          <NewStepperTitle
            title="Custom fields"
            subtitle="Settings related to the custom fields functionality"
          />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable custom fields',
                description: (
                  <Text>
                    Custom fields are used to track additional information in the employee
                    profiles. You can create custom fields{' '}
                    <InternalLink to={pathToUrl(ROUTES.SETTINGS.CUSTOM_FIELDS)}>
                      here
                    </InternalLink>
                    .
                  </Text>
                ),
              }}
              name="enable_custom_fields"
              disabled={disableEdit}
            />
            <TransitionCollapse in={values.enable_custom_fields}>
              <Box pb="s-8" px="s-12">
                <LapeNewMultiSelect
                  name="custom_fields_allowed_sections"
                  placeholder="Sections allowed to have custom fields"
                  selector={selectorKeys.all_employee_profile_sub_sections}
                  required
                  message="Select which sections can have custom fields. If empty all sections will be allowed to have custom fields."
                  disabled={disableEdit}
                />
              </Box>
            </TransitionCollapse>
          </Group>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.EMPLOYEE_SETTINGS)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
