import React, { useRef, useState } from 'react'
import {
  Button,
  Item,
  Switch,
  TextWidget,
  StatusPopup,
  useStatusPopup,
  Avatar,
  useTooltip,
  Tooltip,
  Cell,
  VStack,
  Text,
  Token,
} from '@revolut/ui-kit'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { COOKIE } from '@src/constants/api'
import {
  enableTestCycle,
  resetTestCycleData,
  sandboxEmployeeInvitations,
} from '@src/api/reviewCycles'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  hasTestCycleCookie,
  useGetCanEnableTestCycleSandbox,
} from '@src/utils/reviewCycles'
import { cookiesApi } from '@src/utils/cookies'
import { addMinutes } from 'date-fns'
import { ArrowExchange, RepairTool } from '@revolut/icons'
import { SandboxInvitationsForm } from './SandboxInvitationsForm'

const TestingForm = () => {
  const testCycleCookie = hasTestCycleCookie()
  const [checked, setChecked] = useState(testCycleCookie)
  const statusPopup = useStatusPopup()
  const resetTooltip = useTooltip()
  const resetRef = useRef(null)

  const canEnableSandbox = useGetCanEnableTestCycleSandbox()

  if (!canEnableSandbox) {
    return null
  }

  const showLoading = (text: string) => {
    statusPopup.show(
      <StatusPopup variant="loading">
        <StatusPopup.Title>{text}</StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const showError = (text: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{text}</StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const showSuccess = (text: string) => {
    statusPopup.show(
      <StatusPopup variant="success-result">
        <StatusPopup.Title>{text}</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const toggleTestSetting = async () => {
    showLoading(`${checked ? 'Enabling' : 'Disabling'} testing cycle`)
    try {
      // adding cookie to ignore 403s to avoid logout while database is changing to testing
      cookiesApi.set(COOKIE.SANDBOX_DB_CREATING, 'true', {
        expires: addMinutes(new Date(), 2),
      })
      await enableTestCycle(checked)
      showSuccess(
        checked
          ? 'Enabling testing cycle is in progress. Please try to reload Performance Review Cycles page in a few minutes.'
          : 'Successfully disabled testing cycle',
      )
      navigateTo(pathToUrl(ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES))
      // for disabling testing mode once enableTestCycle is done, all BE jobs are ready,
      // so we can stop ignoring errors to prevent logout
      if (!checked) {
        cookiesApi.remove(COOKIE.SANDBOX_DB_CREATING)
      }
    } catch {
      showError(`Could not ${checked ? 'enable' : 'disable'} testing cycle`)
      cookiesApi.remove(COOKIE.SANDBOX_DB_CREATING)
    }
  }

  const onResetData = async () => {
    if (testCycleCookie) {
      showLoading('Resetting test data')
      try {
        await resetTestCycleData()
        showSuccess(
          'Resetting test data is in progress. Please try to reload page in a few minutes.',
        )
      } catch {
        showError(`Could not reset test data`)
      }
    }
  }

  const onSaveClick = () => {
    statusPopup.show(
      <StatusPopup variant="warning">
        <StatusPopup.Title>
          Are you sure you want to {checked ? 'enable' : 'disable'} the testing cycle?
        </StatusPopup.Title>
        {checked && (
          <StatusPopup.Description>
            Please allow up to 5 minutes for the test data to generate. You may experience
            some errors during this time
          </StatusPopup.Description>
        )}
        <StatusPopup.Actions>
          <Button elevated onClick={() => toggleTestSetting()}>
            Continue
          </Button>
          <Button variant="secondary" onClick={() => statusPopup.hide()}>
            Cancel
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  return (
    <>
      <PageBody>
        <VStack space="s-16">
          <TextWidget>
            <TextWidget.Title>Test data</TextWidget.Title>
            <TextWidget.Content color="grey-tone-50">
              Enabling the demo mode will generate a sandbox dataset in parallel with the
              live system data. With this mode active, users invited to participate below
              will interact with the platform using this sandbox data only, to perform
              demonstrations and test activities, preserving the live data.
              <br />
              <br />
              After enabling, please allow a minute for the system to create the dataset.
              After disabling, all sandbox data will be removed after 24 hrs.
            </TextWidget.Content>
          </TextWidget>

          <Cell>
            <VStack space="s-16" width="100%">
              <Text variant="h6" color={Token.color.greyTone50}>
                Add users to participate in demo
              </Text>
              <Form api={sandboxEmployeeInvitations} disableLocalStorageCaching>
                <SandboxInvitationsForm />
              </Form>
            </VStack>
          </Cell>

          <Item use="label" py="s-16">
            <Item.Avatar>
              <Avatar useIcon={RepairTool} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Enable Demo Mode</Item.Title>
            </Item.Content>
            <Item.Side>
              <Switch onChange={() => setChecked(!checked)} checked={checked} />
            </Item.Side>
          </Item>

          <Button
            variant="secondary"
            size="sm"
            useIcon={ArrowExchange}
            onClick={onResetData}
            aria-disabled={!testCycleCookie}
            {...resetTooltip.getAnchorProps()}
            ref={resetRef}
          >
            Reset all test data
            {!testCycleCookie && (
              <Tooltip
                {...resetTooltip.getTargetProps()}
                anchorRef={resetRef}
                maxWidth="210px"
              >
                You can only reset data when Demo Mode is enabled
              </Tooltip>
            )}
          </Button>
        </VStack>
      </PageBody>

      <PageActions>
        {testCycleCookie !== checked && (
          <Button elevated onClick={onSaveClick}>
            Save
          </Button>
        )}
      </PageActions>
    </>
  )
}

export const Sandbox = connect(TestingForm)
