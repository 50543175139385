import React from 'react'
import Form from '@src/features/Form/Form'
import General from '@src/pages/Forms/JobPosting/General/General'
import Preview from '@src/pages/Forms/JobPosting/Preview/Preview'
import ApplicationForm from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationForm'
import Automation from '@src/pages/Forms/JobPosting/Automation/Automation'
import { JobDescriptionFormRequest } from '@src/api/jobPosting'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Box, Flex, chain } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Route, Switch, useParams } from 'react-router-dom'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Status } from '@src/components/CommonSC/General'
import { useGetJobPostingSettings, useGlobalSettings } from '@src/api/settings'
import BookingLinksTable from '@src/features/BookingLinks/Recruiter/BookingLinksTable'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import CommonRequisitionTable from '@src/features/CommonRequisitionTable/CommonRequisitionTable'
import { initialRequisitionStatusFilter } from '@src/interfaces/requisitions'
import { SORT_DIRECTION } from '@src/interfaces/data'

const JobDescriptionTabs = connect(() => {
  const form = useLapeContext<JobPostingInterface>()
  const { values } = form

  const params = useParams<{ id: string; specId: string }>()

  const backUrl = params.specId
    ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.POSTINGS, { id: params.specId })
    : pathToUrl(ROUTES.RECRUITMENT.JOB_POSTINGS)

  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const { settings: globalSettings } = useGlobalSettings()

  const permissions = useSelector(selectPermissions)
  const canViewBookingLinkDefinition =
    globalSettings?.candidates_scheduling_enabled &&
    permissions.includes(PermissionTypes.ViewBookingLinkDefinition)

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.FORMS.JOB_POSTING.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, params),
      component: Preview,
      canView: !!values?.id,
    },
    {
      title: 'Application form',
      path: ROUTES.FORMS.JOB_POSTING.APPLICATION_FORM,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING.APPLICATION_FORM, params),
      component: ApplicationForm,
    },
    {
      title: 'Automation workflow',
      path: ROUTES.FORMS.JOB_POSTING.AUTOMATION,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING.AUTOMATION, params),
      component: Automation,
      canView:
        !!values?.id &&
        jobPostingSettings?.enable_automation_rules &&
        permissions.includes(PermissionTypes.ViewApplicationautomationrule),
    },
    {
      title: 'Booking links',
      path: ROUTES.FORMS.JOB_POSTING.BOOKING_LINKS,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING.BOOKING_LINKS, params),
      component: () => <BookingLinksTable jobPosting={values} />,
      canView: !!values?.id && canViewBookingLinkDefinition,
    },
    {
      title: 'Requisitions',
      path: ROUTES.FORMS.JOB_POSTING.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING.REQUISITIONS, params),
      component: () => (
        <CommonRequisitionTable
          type="jobPosting"
          sortBy={[
            {
              sortBy: 'status',
              direction: SORT_DIRECTION.ASC,
            },
          ]}
          filterBy={[
            {
              filters: initialRequisitionStatusFilter,
              columnName: 'status',
              nonResettable: true,
            },
            {
              columnName: 'requisition_posting__job_posting',
              filters: [{ id: values.id, name: String(values.name) }],
              nonResettable: true,
            },
          ]}
        />
      ),
      canView: !!values?.id,
    },
  ]

  const filteredTabs = tabs.filter(tab =>
    tab.canView === undefined ? true : tab.canView,
  )

  return (
    <PageWrapper>
      <PageHeader
        title={chain(
          values.id ? values.name : `New job posting`,
          values.approval_status && (
            <Status status={values.approval_status}>{values.approval_status}</Status>
          ),
        )}
        backUrl={backUrl}
      >
        <Box pb="s-8" maxWidth="100vw">
          <TabBarNavigation tabs={filteredTabs} />
        </Box>
      </PageHeader>
      <Flex pt="s-8" flex="1 0" flexDirection="column" width="100%" minHeight={0}>
        <Switch>
          {filteredTabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component />
            </Route>
          ))}
        </Switch>
      </Flex>
    </PageWrapper>
  )
})

const JobDescriptionPage = () => {
  return (
    <Form api={JobDescriptionFormRequest} validator={{}}>
      <Switch>
        <Route exact path={ROUTES.FORMS.JOB_POSTING.GENERAL}>
          <General />
        </Route>
        <JobDescriptionTabs />
      </Switch>
    </Form>
  )
}

export default connect(JobDescriptionPage)
