import React from 'react'
import { Group } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API } from '@src/constants/api'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionSettingsInterface } from '@src/interfaces/settings'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import useIsCommercial from '@src/hooks/useIsCommercial'

const RequisitionsGeneral = () => {
  const { values } = useLapeContext<RequisitionSettingsInterface>()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeRequisitionPreferences)
  const isCommercial = useIsCommercial()

  // @TODO: return settings back when tenants will need it
  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle
            title="General settings"
            subtitle="Basic settings for Requisitions app"
          />
          <Group>
            <ApprovalSteps
              title="Enable Requisitions approval flow"
              description=" Enable and customise an approval flow for requisitions allowing to efficiently attain stakeholder alignment. Define custom approval steps by selecting an employee, a dynamic group or specific position within an organisation with defined relationship to an item pending the approval."
              switchField="enable_approvals"
              approvalProcess="requisition_approvals"
              entity="requisition"
              disabled={disableEdit}
              formRef={values}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable confidential Requisitions',
                description:
                  'Allows for the creation of confidential requisitions, intended for highly sensitive or classified positions within the organisation. By default, only the requisition creator can view a confidential requisition. Access for others can be granted as necessary.',
              }}
              name="enable_confidential_button"
              disabled={disableEdit}
            />
            {!isCommercial && (
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable Location validation',
                  description:
                    'When turned on, this setting restricts the list of possible locations for a requisition to those specified in the role for which the requisition is being made.',
                }}
                name="enable_location_validation"
                disabled={disableEdit}
              />
            )}
            {/* <LapeNewSwitch */}
            {/*  itemTypeProps={{ */}
            {/*    title: 'Budget Impact', */}
            {/*    description: 'If the Budget impact section is visible in the requisition', */}
            {/*  }} */}
            {/*  name="enable_budget_impact" */}
            {/*  disabled={disableEdit} */}
            {/* /> */}

            {/* <LapeNewSwitch
            itemTypeProps={{
              title: 'Estimated TTH, Q position and Pipeline',
              description:
                'Show Estimated TTH, Q Position and Pipeline in the requisition table?',
            }}
            name="enable_table_hiring_fields"
            disabled={disableEdit}
          /> */}
            {/* <LapeNewSwitch
            itemTypeProps={{
              title: 'Hiring Progress',
              description: 'Is “Hiring Progress” section visible in the Requisition?',
            }}
            name="enable_hiring_progress"
            disabled={disableEdit}
          /> */}
            {/* <LapeNewSwitch
            itemTypeProps={{
              title: 'Hires',
              description: 'Is “Hires” block visible in the Requisition?',
            }}
            name="enable_hiring_process"
            disabled={disableEdit}
          /> */}
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable editing of “Hired Headcount”',
                description:
                  "The 'Hired Headcount' field displays the number of employees recruited under a particular requisition. Enabling this setting allows for manual adjustments to the 'Hired Headcount' field, allowing to track requisition progress independently of the hiring process.",
              }}
              name="enable_hired_headcount_editing"
              disabled={disableEdit}
            />
            {/* <LapeNewSwitch
            itemTypeProps={{
              title: 'Candidates - Candidates Table',
              description:
                'Enable/Disable Candidates Table visibility on the requisition view',
            }}
            name="enable_candidate_tab"
            disabled={disableEdit}
          /> */}
          </Group>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.REQUISITION_SETTINGS)
            queryClient.invalidateQueries(API.APP_SETTINGS)
          }}
          useValidator
          isExistingData
        />
      </PageActions>
    </>
  )
}

export default RequisitionsGeneral
