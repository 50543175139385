import React from 'react'
import { HStack, Text } from '@revolut/ui-kit'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { FinalGrade, PerformanceRating } from '@src/interfaces/performance'
import { getInverseColorsAndGrade } from '@components/PerformanceGrade/PerformanceGrade'
import Stat from '@components/Stat/Stat'
import { RevolutTheme, useTheme } from '@src/styles/theme'

type Props = {
  cycleName?: string
  deliverablesRating?: PerformanceRating | null
  grade?: FinalGrade | null
  skillsRating?: PerformanceRating | null
  valuesRating?: PerformanceRating | null
}

export const getLatestGrade = (latestGrade: FinalGrade | null, theme: RevolutTheme) => {
  if (!latestGrade) {
    return 'N/A'
  }

  const grade = getInverseColorsAndGrade(theme, latestGrade)
  return grade ? <Text color={grade.color}>{grade.grade}</Text> : undefined
}

export const getLabel = (text: string, cycleName?: string) => {
  return cycleName !== undefined ? `${text} ${cycleName}` : `${text}`
}

export const HeaderStats = ({
  cycleName,
  deliverablesRating,
  grade,
  skillsRating,
  valuesRating,
}: Props) => {
  const theme = useTheme()

  return (
    <HStack gap="s-40" mb="s-32">
      {grade !== undefined && (
        <Stat
          label={getLabel('Latest grade', cycleName)}
          val={getLatestGrade(grade, theme)}
        />
      )}
      {deliverablesRating !== undefined && (
        <Stat
          label={getLabel('Latest deliverables', cycleName)}
          val={deliverablesRating ? PerformanceRatingTitle[deliverablesRating] : 'N/A'}
        />
      )}
      {skillsRating !== undefined && (
        <Stat
          label={getLabel('Latest skills', cycleName)}
          val={skillsRating ? PerformanceRatingTitle[skillsRating] : 'N/A'}
        />
      )}
      {valuesRating !== undefined && (
        <Stat
          label={getLabel('Latest values', cycleName)}
          val={valuesRating ? PerformanceRatingTitle[valuesRating] : 'N/A'}
        />
      )}
    </HStack>
  )
}
