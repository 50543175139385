import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from '../../Icon/Icon'
import IconButton from '../../ButtonIcon/IconButton'
import { getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

const StarsContainer = styled.div`
  display: grid;
  width: auto;
  grid-template-columns: 1em 1em 1em 1em 1em;
  grid-column-gap: 0.3em;
`
const Star = styled(Icon)``
const StarButton = styled(IconButton)<{ active: boolean }>`
  color: ${({ active, theme }) =>
    getColor(theme, active ? Color.YELLOW : Color.GREY_20_OPAQUE_90)};

  &:active {
    color: ${({ active, theme }) =>
      getColor(theme, active ? Color.YELLOW : Color.GREY_20_OPAQUE_90)};
  }
`

export interface FeedbackInputProps {
  /** Value in from 0 to 1 */
  value: number
  /** Flag to disable the input */
  disabled?: boolean
  /** Callback for when you click on input */
  onChange: (value: number) => void
  /** Class name for container */
  className?: string
}

export const FeedbackInput = ({
  value,
  onChange,
  disabled,
  className,
}: FeedbackInputProps) => {
  const [hoveredStar, setHover] = useState(-1)
  const handleChange = (rating: number) => {
    onChange(rating)
  }

  return (
    <StarsContainer
      onMouseLeave={() => {
        setHover(-1)
      }}
      className={className}
    >
      {Array.from({ length: 5 }).map((_, index) => {
        return (
          <StarButton
            active={
              hoveredStar !== -1
                ? index <= hoveredStar
                : !!value && (index + 1) * 0.2 <= value
            }
            key={index}
            disabled={disabled}
            onMouseEnter={() => {
              if (!disabled) {
                setHover(index)
              }
            }}
            onClick={e => {
              e.preventDefault()
              handleChange((index + 1) * 0.2)
            }}
          >
            <Star customSize type="Star" />
          </StarButton>
        )
      })}
    </StarsContainer>
  )
}
