import React, { useMemo } from 'react'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'

import { MultiLineChart } from '@components/MultiLineChart/Chart'
import { Box, Color, Flex, Spinner, StatusWidget } from '@revolut/ui-kit'
import { useEngagementSurveyResultsChart } from '@src/api/engagement'
import {
  EngagementDriverChartDatum,
  EngagementResultsEntities,
  EngagementResultsScope,
} from '@src/interfaces/engagement'
import { ChartTooltip, DataPoint } from '../components/ChartTooltip/ChartTooltip'
import { useChartDataByEntityId } from '../components/ChartTooltip/hooks'

const TABLE_HEIGHT = 300
const MAX_NUM_TICKS = 5

type Props = {
  isAverage: boolean
  scope?: EngagementResultsScope
  entity: EngagementResultsEntities
  scopeId: number
  entityId: number | undefined
}
export const ResultsChart = ({
  isAverage,
  scope,
  entity,
  scopeId,
  entityId: driverId,
}: Props) => {
  const { data, isFetching } =
    useEngagementSurveyResultsChart<EngagementDriverChartDatum>({
      scope,
      entity,
      scopeId,
    })
  const chartData = data?.results || []

  const chartDataByDriver = useMemo(() => {
    return groupBy(chartData, d => d.driver.id)
  }, [chartData])

  const { data: dataBySelectedDriver, xAxis } = useChartDataByEntityId({
    id: driverId,
    data: chartDataByDriver,
    scoreAccessor: d => (isAverage ? d.average_score : d.nps_score),
  })

  if (isFetching || !driverId || !xAxis) {
    return (
      <Box display="flex" height={TABLE_HEIGHT}>
        <Flex flex="1 0" alignItems="center" justifyContent="center">
          <Spinner size={48} color={Color.BLUE} />
        </Flex>
      </Box>
    )
  }

  if (dataBySelectedDriver.length < 2) {
    return (
      <StatusWidget>
        <StatusWidget.Image
          src="https://assets.revolut.com/assets/3d-images/3D086.png"
          srcSet="https://assets.revolut.com/assets/3d-images/3D086@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D086@3x.png 3x"
        />
        <StatusWidget.Title>No results found</StatusWidget.Title>
        <StatusWidget.Description>
          The driver must have participated in at least two surveys
        </StatusWidget.Description>
      </StatusWidget>
    )
  }

  return (
    <Box height={TABLE_HEIGHT}>
      <MultiLineChart<DataPoint>
        role="img"
        dataSet={dataBySelectedDriver}
        showLegend
        alignLegendItemsToLines
        xAxis={{
          key: 'xAxis',
          numTicks: Math.min(MAX_NUM_TICKS, dataBySelectedDriver.length - 1),
          toLabel: xAxisValue => {
            return get(dataBySelectedDriver[xAxisValue], 'label', '')
          },
        }}
        yAxis={{
          numTicks: 6,
          minTickValue: isAverage ? 0 : -100,
          maxTickValue: isAverage ? 5 : 100,
        }}
        lines={{
          [chartDataByDriver[driverId][0].driver.name]: {
            name: chartDataByDriver[driverId][0].driver.name,
            accessor: d => d.score,
            color: Color.GREEN,
          },
        }}
        margin={{ left: 80 }}
        renderDetailedTooltip={(dataPoint: DataPoint) => (
          <ChartTooltip dataPoint={dataPoint} isAverage={isAverage} />
        )}
      />
    </Box>
  )
}
