import React from 'react'
import { connect } from 'lape'
import { Side, VStack } from '@revolut/ui-kit'

import SideBar from '@components/SideBar/SideBar'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import { DocumentDraftInterface } from '@src/interfaces/documents'
import { useLapeContext } from '@src/features/Form/LapeForm'

type NewDocumentFormContentProps = Pick<Props, 'onDocumentCreated' | 'onClose'>
const NewDocumentFormContent = ({
  onDocumentCreated,
  onClose,
}: NewDocumentFormContentProps) => {
  const { reset } = useLapeContext()

  return (
    <>
      <VStack space="s-16">
        <LapeNewInput required name="name" label="Name" />
        <LapeDatePickerInput required name="issue_date_time" label="Issue date" />
        <LapeRadioSelectInput
          name="issuer"
          selector={selectorKeys.employee}
          label="Issuer"
        />
        <LapeRadioSelectInput
          name="category"
          selector={selectorKeys.document_categories}
          label="Category"
        />
        <LapeNewTextArea name="description" label="Description" rows={3} />
      </VStack>
      <Side.Actions>
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          onAfterSubmit={(newDocument: DocumentDraftInterface) => {
            onDocumentCreated(newDocument)
            reset({})
            onClose()
          }}
        >
          Add document
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </>
  )
}

type Props = {
  isOpen: boolean
  onClose: () => void
  onDocumentCreated: (newDocument: DocumentDraftInterface) => void
}
export const NewDocumentSidebar = connect(
  ({ isOpen, onClose, onDocumentCreated }: Props) => {
    return (
      <SideBar variant="wide" title="Add new document" isOpen={isOpen} onClose={onClose}>
        <NewDocumentFormContent onDocumentCreated={onDocumentCreated} onClose={onClose} />
      </SideBar>
    )
  },
)
