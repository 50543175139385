import { RevolutTheme } from '@src/styles/theme'
import {
  KeyPersonResponsibilityAssignmentStatus,
  KeyPersonResponsibilityStatus,
  KeyPersonStatus,
  KeyPersonSuccessionPlanStatus,
} from '@src/interfaces/keyPerson'

export const getStatusColor = (theme: RevolutTheme, status?: KeyPersonStatus) => {
  switch (status) {
    case KeyPersonStatus.pending_completion:
    case KeyPersonStatus.pending_approval:
      return theme.colors.orange
    case KeyPersonStatus.approved:
    case KeyPersonStatus.active:
      return theme.colors.green
    case KeyPersonStatus.cancelled:
    case KeyPersonStatus.rejected:
      return theme.colors.red
    case KeyPersonStatus.archived:
    case KeyPersonStatus.expired:
      return theme.colors['grey-tone-50']
    default:
      return theme.colors.foreground
  }
}

export const getResponsibilityStatusColor = (
  theme: RevolutTheme,
  status?: KeyPersonResponsibilityStatus,
) => {
  switch (status) {
    case 'active':
      return theme.colors.green
    case 'archived':
      return theme.colors['grey-tone-50']
    default:
      return theme.colors.foreground
  }
}

export const getResponsibilityAssignmentStatusColor = (
  theme: RevolutTheme,
  status?: KeyPersonResponsibilityAssignmentStatus,
) => {
  switch (status) {
    case 'active':
      return theme.colors.green
    case 'pending_start':
      return theme.colors.orange
    case 'expired':
    case 'unassigned':
      return theme.colors.red
    case 'archived':
      return theme.colors['grey-tone-50']
    default:
      return theme.colors.foreground
  }
}

export const getSuccessionPlanStatusColor = (
  theme: RevolutTheme,
  status?: KeyPersonSuccessionPlanStatus,
) => {
  switch (status) {
    case 'approved':
    case 'complete':
      return theme.colors.green
    case 'pending_completion':
    case 'pending_approval':
      return theme.colors.orange
    case 'expired':
    case 'rejected':
      return theme.colors.red
    default:
      return theme.colors.foreground
  }
}
