import React, { useState } from 'react'
import { Button, Header, Popup } from '@revolut/ui-kit'

import RadioSwitch from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { DocumentsTemplateTypeId } from '@src/interfaces/documentsTemplates'

type Props = {
  isOpen: boolean
  onClose: () => void
}
export const SelectDocumentsTemplateTypePopup = ({ isOpen, onClose }: Props) => {
  const [selected, setSelected] = useState<DocumentsTemplateTypeId>('request')

  return (
    <Popup open={isOpen} onClose={onClose} variant="bottom-sheet">
      <Header>
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Create a new document template</Header.Title>
        <Header.Description>
          Select which type of template you want to create.
        </Header.Description>
      </Header>
      <RadioSwitch<DocumentsTemplateTypeId>
        value={selected}
        options={[
          {
            id: 'request',
            label: 'Create Document Request Template',
            description: 'e.g. Passports, Proof of Address.',
            value: 'request',
          },
          {
            id: 'esignature',
            label: 'Create eSignature Template',
            description: 'e.g. Contracts, Promotion letters, Offers.',
            value: 'esignature',
          },
        ]}
        onChange={option => {
          if (option) {
            setSelected(option)
          }
        }}
      />
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          elevated
          onClick={() => {
            navigateTo(
              pathToUrl(
                selected === 'request'
                  ? ROUTES.APPS.DOCUMENTS.TEMPLATES.EDIT.BASICS
                  : ROUTES.APPS.DOCUMENTS.E_SIGNATURE_TEMPLATES.EDIT.BASICS,
                undefined,
              ),
            )
          }}
          disabled={!selected}
        >
          Next
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
