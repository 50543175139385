import React, { useMemo } from 'react'
import { BaseChartInner, BaseInnerPropsInterface } from '../BaseChart/BaseChartInner'
import { RevolutTheme, useTheme } from '@src/styles/theme'
import Tooltip from '../../Tooltip/Tooltip'
import { formatMoney, formatNumber } from '@src/utils/format'
import Icon from '../../Icon/Icon'
import { TargetInterface } from '@src/interfaces/chart'
import { PerformanceTimeRange } from '@src/constants/api'

interface BudgetChartInnerProps extends BaseInnerPropsInterface {
  showWarning?: boolean
}

const getWarningLayer =
  (theme: RevolutTheme) =>
  (targets: TargetInterface[], currency?: string) =>
  ({ bars, yScale, xScale }: any) => {
    return (
      <>
        {bars.map((bar: any, i: number) => {
          const cycle = targets.find(
            target =>
              target.start_index <= bar.data.index && target.end_index > bar.data.index,
          )
          if (cycle && bar.data.value < cycle.target) {
            return null
          }

          return (
            <foreignObject
              width="64"
              height="64"
              x={xScale(bar.data.data.progress_datetime) + bar.width / 2 - 32}
              y={yScale(bar.data.value) - 32}
              color={theme.colors.red}
              key={i}
            >
              <Tooltip
                placement={'top'}
                text={`Over budget by ${
                  currency
                    ? formatMoney(bar.data.value - (cycle?.target || 0), currency)
                    : formatNumber(bar.data.value - (cycle?.target || 0))
                }`}
              >
                <Icon type="WarningTriangle" />
              </Tooltip>
            </foreignObject>
          )
        })}
      </>
    )
  }

const BudgetChartInner = ({ showWarning, ...props }: BudgetChartInnerProps) => {
  const theme = useTheme()
  const warningLayer = useMemo(() => getWarningLayer(theme), [theme])

  const graphTimeRange = PerformanceTimeRange.month
  const layers = showWarning ? [warningLayer] : []

  return <BaseChartInner graphTimeRange={graphTimeRange} layers={layers} {...props} />
}

export default BudgetChartInner
