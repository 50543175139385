import { useParams } from 'react-router-dom'
import {
  Canceled,
  Check,
  CrossCircle,
  Question,
  SandWatch,
  StatusClockArrows,
} from '@revolut/icons'
import {
  HirerightAdjudicationStatus,
  ScreeningCheckStatuses,
} from '@src/interfaces/screeningChecksInterface'
import { RevolutTheme } from '@src/styles/theme'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import React from 'react'

export const useRouteParams = () => useParams<{ id: any; employeeId: string }>()

export const getScreeningStatusColor = (
  theme: RevolutTheme,
  status?: ScreeningCheckStatuses,
) => {
  switch (status) {
    case ScreeningCheckStatuses.pending:
    case ScreeningCheckStatuses.pendingApplicant:
    case ScreeningCheckStatuses.requiresAdjudication:
    case ScreeningCheckStatuses.sentToApplicant:
    case ScreeningCheckStatuses.needsAdjudication:
      return theme.colors.orange
    case ScreeningCheckStatuses.inProgress:
    case ScreeningCheckStatuses.inviteExpired:
      return theme.colors['grey-tone-50']
    case ScreeningCheckStatuses.cancelled:
      return theme.colors['deep-grey']
    case ScreeningCheckStatuses.progress:
    case ScreeningCheckStatuses.completed:
    case ScreeningCheckStatuses.Completed:
      return theme.colors.green
    case ScreeningCheckStatuses.failed:
      return theme.colors.red
    default:
      return theme.colors['grey-20']
  }
}

export const getHirerightAdjudicationStatusColor = (
  theme: RevolutTheme,
  status?: HirerightAdjudicationStatus,
) => {
  switch (status) {
    case HirerightAdjudicationStatus.meetsCompanyStandards:
      return theme.colors.green
    case HirerightAdjudicationStatus.clientReviewRequired:
    case HirerightAdjudicationStatus.pendingPotentialConflict:
      return theme.colors.orange
    case HirerightAdjudicationStatus.customStatus:
      return theme.colors['grey-tone-50']
    case HirerightAdjudicationStatus.doesntMeetCompanyStandards:
    case HirerightAdjudicationStatus.canceled:
      return theme.colors.red
    default:
      return theme.colors.foreground
  }
}

export const screeningStatusToIcon = (status?: ScreeningCheckStatuses) => {
  switch (status) {
    case ScreeningCheckStatuses.pending:
    case ScreeningCheckStatuses.pendingApplicant:
    case ScreeningCheckStatuses.sentToApplicant:
    case ScreeningCheckStatuses.inviteExpired:
      return SandWatch
    case ScreeningCheckStatuses.inProgress:
    case ScreeningCheckStatuses.requiresAdjudication:
      return StatusClockArrows
    case ScreeningCheckStatuses.completed:
    case ScreeningCheckStatuses.Completed:
      return Check
    case ScreeningCheckStatuses.cancelled:
      return Canceled
    case ScreeningCheckStatuses.failed:
      return CrossCircle
    default:
      return Question
  }
}

export const backUrl = (employeeId: string) =>
  pathToUrl(ROUTES.FORMS.SCREENING.SELECT, employeeId ? { id: employeeId } : undefined)

export const subtitle = (employeeId: string) =>
  employeeId ? <EmployeeUserWithAvatar id={employeeId} /> : undefined
