import React from 'react'
import { Group, VStack } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalUIKitLink } from '@src/components/InternalLink/InternalLink'

export const Communication = () => {
  const queryClient = useQueryClient()

  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCommunicationSettings)

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="All communication related settings"
          />
          <VStack space="s-24">
            <Group>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable Email notifications',
                  description:
                    'Enables creating email notifications from communications app',
                }}
                name="enable_email_notifications"
                disabled={disableEdit}
              />
              <HideIfCommercial>
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable SMS notifications',
                    description:
                      'Enables sending SMS notifications from the communications app',
                  }}
                  name="enable_sms_notifications"
                  disabled={disableEdit}
                />
              </HideIfCommercial>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable Slack notifications',
                  description: (
                    <>
                      Enables sending the notifications via Slack.{' '}
                      <InternalUIKitLink
                        // @ts-expect-error object works fine here, but UI kit expects string
                        to={pathToUrl(ROUTES.HUB.INTEGRATION.SLACK.ALL)}
                      >
                        Slack integration
                      </InternalUIKitLink>{' '}
                      must be enabled.
                    </>
                  ),
                }}
                name="enable_slack_notifications"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable System notifications',
                  description:
                    'Enables sending system notifications from the communications app',
                }}
                name="enable_system_notifications"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable Announcements',
                  description:
                    'Enables sending announcements from the communications app',
                }}
                name="enable_announcements"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Allow automatic creation of Slack groups and channels',
                  description:
                    'Allows automatic creation of Slack channels and groups based on the audiences defined in the product. It will create slack channels and groups for every team and department by default.',
                }}
                name="enable_automatic_slack_channel_and_group_creation"
                disabled={disableEdit}
              />
              <HideIfCommercial>
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable SQL audience selection type',
                    description: 'Allows using SQL for audience selection',
                  }}
                  name="enable_sql_audience_selection_type"
                  disabled={disableEdit}
                />
              </HideIfCommercial>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable event based delivery types',
                  description:
                    'Allows sending the notifications when selected event happens',
                }}
                name="enable_event_based_delivery_type"
                disabled={disableEdit}
              />
            </Group>
          </VStack>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.COMMUNICATION_SETTINGS)
          }}
          useValidator
          successText="Settings saved"
        />
      </PageActions>
    </>
  )
}
