import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Route, Switch } from 'react-router-dom'
import SelectEmployeeTable from './SelectTable'
import {
  PageHeader,
  PageHeaderRouterAction,
} from '@src/components/Page/Header/PageHeader'
import SelectFieldsPage from './SelectFieldsPage'
import ReviewChanges from './ReviewChanges'
import TrackProgress from './TrackProgress'

const EmployeesBulkEdit = () => {
  const backUrl = ROUTES.PEOPLE.CONTRACTS

  return (
    <>
      <PageHeader
        pb="s-8"
        title="Bulk Edit"
        backUrl={backUrl}
        routerAction={PageHeaderRouterAction.navigate}
      />
      <Switch>
        <Route
          exact
          path={ROUTES.FORMS.BULK_EDIT_CONTRACTS.SELECT}
          component={SelectEmployeeTable}
        />
        <Route
          exact
          path={ROUTES.FORMS.BULK_EDIT_CONTRACTS.EDIT}
          component={SelectFieldsPage}
        />
        <Route
          exact
          path={ROUTES.FORMS.BULK_EDIT_CONTRACTS.REVIEW}
          component={ReviewChanges}
        />
        <Route
          exact
          path={ROUTES.FORMS.BULK_EDIT_CONTRACTS.TRACK}
          component={TrackProgress}
        />
      </Switch>
    </>
  )
}

export default EmployeesBulkEdit
