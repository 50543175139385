import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Calendar,
  Color,
  Dropdown,
  HStack,
  Input,
  InputGroup,
  Link,
  Text,
  TextSkeleton,
  themeSpace,
  useDropdown,
  VStack,
} from '@revolut/ui-kit'
import { ChevronDown, SubtractPeople } from '@revolut/icons'
import { useLocation, useParams } from 'react-router-dom'
import pluralize from 'pluralize'
import { isSameDay } from 'date-fns'
import styled from 'styled-components'
import { toString } from 'lodash'

import {
  useEmployeeTimeOffBalanceSelector,
  useEmployeeTimeOffDuration,
  useEmployeeTimeOffStats,
  useGetTimeOffPolicy,
  useNonWorkingDays,
} from '@src/api/timeOff'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext, useLapeField } from '@src/features/Form/LapeForm'
import {
  EmployeeTimeOffBalanceSelectorOption,
  EmployeeTimeOffRequestInterface,
  TimeOffPeriods,
} from '@src/interfaces/timeOff'
import { selectorKeys } from '@src/constants/api'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeFileUploader from '@src/components/Inputs/LapeFields/LapeFileUploader'
import { FileInterface } from '@src/interfaces/files'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { deleteFile } from '@src/api/files'
import { RouteParams } from '.'
import { formatDate, formatPeriod, formatTime } from '@src/utils/format'
import { localDateToUtc, utcToLocalDate } from '@src/utils/timezones'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { HideInputCalendarIndicatorCss } from '@src/components/Inputs/LapeFields/helpers'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import {
  ContactHRTeamButton,
  UserGuidesButton,
} from '@src/pages/EmployeeProfile/Preview/TimeOff/common'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

const List = styled.ul`
  padding-left: ${themeSpace('s-20')};
  margin: 0;
`

const utcToLocalConvert = (value: string, unit: 'day' | 'hour' | null) => {
  if (unit === 'day') {
    return utcToLocalDate(value)
  }
  return new Date(value)
}

const localToUtcConvert = (value: Date, unit: 'day' | 'hour' | null) => {
  if (unit === 'day') {
    return localDateToUtc(value)
  }
  return value.toISOString()
}

const DateRangePicker = () => {
  const { employeeId } = useParams<RouteParams>()
  const { values } = useLapeContext<EmployeeTimeOffRequestInterface>()

  const { getAnchorProps, getTargetProps } = useDropdown()

  const { data: nonWorkingDays } = useNonWorkingDays(
    employeeId,
    localDateToUtc(new Date(2015, 0, 1)),
    // End of next year
    localDateToUtc(new Date(new Date().getFullYear() + 1, 11, 31)),
  )

  const disabled =
    values?.field_options?.read_only?.includes('from_date_time') ||
    values?.field_options?.read_only?.includes('to_date_time')

  const fromField = useLapeField('from_date_time')
  const toField = useLapeField('to_date_time')
  const error = fromField.error || toField.error
  const errorMessage = error && toString(error)
  const unit = values.unit?.id || values.balance?.unit?.id || null

  const events = useMemo(() => {
    return nonWorkingDays
      ?.filter(event => event.type === 'public_holiday')
      .map(event => ({
        color: 'red' as const,
        date: utcToLocalDate(event.day),
      }))
  }, [nonWorkingDays])

  const disabledDays = useMemo(() => {
    return nonWorkingDays?.map(event => utcToLocalDate(event.day))
  }, [nonWorkingDays])

  const dateRange = (() => {
    if (!values.from_date_time || !values.to_date_time) {
      return ''
    }
    if (isSameDay(new Date(values.from_date_time), new Date(values.to_date_time))) {
      return formatDate(utcToLocalConvert(values.from_date_time, unit))
    }
    return formatPeriod(
      utcToLocalConvert(values.from_date_time, unit),
      utcToLocalConvert(values.to_date_time, unit),
    )
  })()

  return (
    <>
      <Input
        label="Date range"
        value={dateRange}
        type="button"
        disabled={disabled}
        errorMessage={errorMessage}
        aria-invalid={!!errorMessage}
        useIcon={ChevronDown}
        data-name="from_date_time to_date_time"
        data-testid="from_date_time to_date_time"
        {...getAnchorProps()}
      />
      <Dropdown width={343} maxHeight={null} {...getTargetProps()}>
        <Calendar
          variant="range"
          value={
            values.from_date_time && values.to_date_time
              ? {
                  from: utcToLocalConvert(values.from_date_time, unit),
                  to: utcToLocalConvert(values.to_date_time, unit),
                }
              : undefined
          }
          onChange={value => {
            const from = value?.from
            const to = value?.to

            if (unit === 'day') {
              values.from_time_period = allDayOption
              values.to_time_period = allDayOption
            }

            if (from) {
              values.from_date_time = localToUtcConvert(from, unit)
            }
            if (to) {
              values.to_date_time = localToUtcConvert(to, unit)
            }
          }}
          events={events}
          disabledDays={disabledDays}
        />
      </Dropdown>
    </>
  )
}

const TimePickers = () => {
  const { values, initialValues, apiErrors } =
    useLapeContext<EmployeeTimeOffRequestInterface>()
  const [fromTime, setFromTime] = useState(
    initialValues.from_date_time ? formatTime(initialValues.from_date_time) : '09:00',
  )
  const [toTime, setToTime] = useState(
    initialValues.to_date_time ? formatTime(initialValues.to_date_time) : '18:00',
  )

  const fromTimeError = apiErrors.from_date_time
  const toTimeError = apiErrors.to_date_time

  const disabled =
    values?.field_options?.read_only?.includes('from_date_time') ||
    values?.field_options?.read_only?.includes('to_date_time')

  useEffect(() => {
    if (
      values.from_date_time &&
      fromTime &&
      formatTime(values.from_date_time) !== fromTime
    ) {
      const newFromDate = new Date(values.from_date_time)
      const [hours, minutes] = fromTime.split(':')
      newFromDate.setHours(+hours)
      newFromDate.setMinutes(+minutes)
      values.from_date_time = newFromDate.toISOString()
    }

    if (values.to_date_time && toTime && formatTime(values.to_date_time) !== toTime) {
      const newToDate = new Date(values.to_date_time)
      const [hours, minutes] = toTime.split(':')
      newToDate.setHours(+hours)
      newToDate.setMinutes(+minutes)
      values.to_date_time = newToDate.toISOString()
    }
  }, [fromTime, toTime, values.from_date_time, values.to_date_time])

  return (
    <InputGroup variant="horizontal">
      <Input
        label="Start time"
        value={fromTime}
        onChange={e => setFromTime(e.currentTarget.value)}
        type="time"
        css={HideInputCalendarIndicatorCss}
        disabled={disabled}
        aria-invalid={!!fromTimeError}
        errorMessage={fromTimeError}
      />
      <Input
        label="End time"
        value={toTime}
        onChange={e => setToTime(e.currentTarget.value)}
        type="time"
        css={HideInputCalendarIndicatorCss}
        disabled={disabled}
        aria-invalid={!!toTimeError}
        errorMessage={toTimeError}
      />
    </InputGroup>
  )
}

const allDayOption = { id: 'all_day', name: 'All day' } as const

interface GeneralProps {
  recordAbsenceMode?: boolean
}

const General = ({ recordAbsenceMode }: GeneralProps) => {
  const { employeeId } = useParams<RouteParams>()
  const { values } = useLapeContext<EmployeeTimeOffRequestInterface>()

  const [initialAttachmentId, setInitialAttachmentId] = useState<number>()

  const balances = useEmployeeTimeOffBalanceSelector(employeeId)
  const { data: nonWorkingDays } = useNonWorkingDays(
    employeeId,
    values.from_date_time,
    values.to_date_time,
  )
  const publicHolidays = nonWorkingDays
    ?.filter(date => date.type === 'public_holiday')
    .map(date => ({ ...date, day: utcToLocalDate(date.day).toISOString() }))

  const location = useLocation<{ balanceId?: number }>()

  useEffect(() => {
    // We can't save file to LocalStorage that is why I remove it if the data comes from there.
    if (values.attachment && !values.attachment.name) {
      values.attachment = undefined
    }

    // Some users have invalid date range cached in localstorage, which is crashing the form for them
    if (!values.id) {
      values.from_date_time = undefined
      values.to_date_time = undefined
    }

    if (values.id && (values.attachment as FileInterface)?.id) {
      setInitialAttachmentId((values.attachment as FileInterface).id)
    }
  }, [])

  const timeOffPeriods = useFetchOptions<IdAndName<TimeOffPeriods>>(
    selectorKeys.time_off_request_periods,
  )

  const balance = values.balance
  const policy = balance?.policy
  const selectedBalance =
    policy && balances.data?.options.find(option => option.policy.id === policy.id)
  const policyDetails = selectedBalance?.policy

  const timeOffPolicy = useGetTimeOffPolicy(policy?.id)
  const policyApprovers = timeOffPolicy.data?.policy_approvers?.sort(
    (prev, next) => prev.sort_order - next.sort_order,
  )

  useEffect(() => {
    if (!balance && location.state?.balanceId && balances.data?.options) {
      const balanceToSelect = balances.data.options.find(
        option => option.id === location.state.balanceId,
      )

      if (balanceToSelect) {
        values.balance = balanceToSelect
      }
    }
  }, [balance, location, balances.data])

  useEffect(() => {
    if (balance?.unit?.id === 'day') {
      values.from_time_period = allDayOption
      values.to_time_period = allDayOption
      if (values.from_date_time && values.to_date_time) {
        values.from_date_time = localDateToUtc(new Date(values.from_date_time))
        values.to_date_time = localDateToUtc(new Date(values.to_date_time))
      }
    }
    if (balance?.unit?.id === 'hour') {
      values.from_time_period = null
      values.to_time_period = null
    }
  }, [balance])

  const isAttachmentRequired = policyDetails?.is_attachment_required
  const isApprovalRequired = policyDetails?.is_approval_required
  const isCommentRequired = policyDetails?.is_comment_required
  const halfDaysAllowed = policyDetails?.half_days?.id === 'yes'
  const isHourlyRequest = policyDetails?.unit?.id === 'hour'
  const requestInstructions = policyDetails?.request_instructions
  const detailsUrl = policyDetails?.details_url
  const isDatePickerDisabled =
    values.field_options?.read_only.includes('from_date_time') ||
    values.field_options?.read_only.includes('to_date_time')

  const stats = useEmployeeTimeOffStats(employeeId)
  const timeOffDuration = useEmployeeTimeOffDuration(values)

  const widgetTitle = (() => {
    if (timeOffDuration.isLoading || timeOffPolicy.isLoading) {
      return <TextSkeleton variant="h2" width="50%" />
    }
    if (timeOffDuration.data == null) {
      return isApprovalRequired ? 'Request approval chain' : undefined
    }
    if ('error' in timeOffDuration.data || timeOffDuration.data.duration == null) {
      return 'The dates selected are invalid'
    }
    const { duration, unit } = timeOffDuration.data
    if (unit.id === 'day') {
      return `You are requesting leave for ${pluralize(
        unit.name.toLowerCase(),
        duration,
        true,
      )}`
    }
    if (unit.id === 'hour') {
      const hours = Math.floor(duration)
      const minutes = Math.round((duration % 1) * 60)

      return `You are requesting leave for ${pluralize('hour', hours, true)}${
        minutes > 0 ? ` ${pluralize('minute', minutes, true)}` : ''
      }`
    }
    return null
  })()

  const fromAndToAreSameDay =
    !!values.from_date_time &&
    !!values.to_date_time &&
    isSameDay(new Date(values.from_date_time), new Date(values.to_date_time))

  const fromTimePeriodOptions = fromAndToAreSameDay
    ? timeOffPeriods.options
    : timeOffPeriods.options.filter(
        option => option.value.id === 'all_day' || option.value.id === 'afternoon',
      )

  const toTimePeriodOptions = fromAndToAreSameDay
    ? timeOffPeriods.options
    : timeOffPeriods.options.filter(
        option => option.value.id === 'all_day' || option.value.id === 'morning',
      )

  if (balances.isLoading) {
    return <PageLoading />
  }

  if (balances.data?.options.length === 0) {
    return (
      <PageBody>
        <ActionWidget
          title={`You don't have any time off balances open`}
          text="To request time off you need to have assigned time off policies and active balances."
          avatarColor={Color.BLUE}
        >
          <HideIfCommercial>
            <ContactHRTeamButton />
            <UserGuidesButton />
          </HideIfCommercial>
        </ActionWidget>
      </PageBody>
    )
  }

  const hasApprovalChain = !!isApprovalRequired && !!policyApprovers?.length

  const renderApprovalInfo = () => {
    if (timeOffPolicy.isLoading) {
      return null
    }
    if (recordAbsenceMode) {
      return <Text fontWeight={500}>Request will be automatically approved</Text>
    }
    if (hasApprovalChain) {
      return (
        <VStack mt="s-12" space="s-16">
          {policyApprovers.map(approver => {
            if (approver.approver_type.id === 'default_approver') {
              return (
                <HStack key={approver.id} align="center" space="s-4">
                  <UserWithAvatar {...stats.data?.default_approver} />
                  <Text>(default approver)</Text>
                </HStack>
              )
            }
            if (
              approver.approver_type.id === 'dynamic_group' &&
              approver.groups?.length
            ) {
              return (
                <HStack key={approver.id} align="center" space="s-4">
                  <SubtractPeople size={24} color={Color.BLUE_80} />
                  <Text pl="s-4">{approver.groups[0].name}</Text>
                  <Text>(dynamic group)</Text>
                </HStack>
              )
            }
            return null
          })}
        </VStack>
      )
    }
    return <Text fontWeight={500}>Request does not require approval</Text>
  }

  return (
    <>
      <PageBody>
        <InputGroup>
          <LapeRadioSelectInput<EmployeeTimeOffBalanceSelectorOption>
            name="balance"
            label="Policy"
            loading={balances.isLoading}
            options={balances.data?.options.map(opt => ({ label: opt.name, value: opt }))}
            onAfterChange={() => {
              if (values.request_type_balance) {
                delete values.request_type_balance
              }
            }}
          />

          {selectedBalance?.request_type_balances?.length ? (
            <LapeRadioSelectInput
              label="Special request type"
              name="request_type_balance"
              options={selectedBalance.request_type_balances.map(option => ({
                label: option.name,
                value: option,
              }))}
              message="You can choose a special request type if needed. The number of days requested will be deducted from the balance selected at the top"
            />
          ) : null}

          <DateRangePicker />

          {isHourlyRequest && <TimePickers />}

          {halfDaysAllowed ? (
            <InputGroup variant="horizontal">
              <LapeRadioSelectInput
                name="from_time_period"
                label="Start period"
                options={fromTimePeriodOptions}
                loading={timeOffPeriods.asyncState === 'pending'}
                onAfterChange={option => {
                  if (fromAndToAreSameDay && option) {
                    values.to_time_period = option
                  }
                }}
                required
              />
              <LapeRadioSelectInput
                name="to_time_period"
                label="End period"
                options={toTimePeriodOptions}
                loading={timeOffPeriods.asyncState === 'pending'}
                disabled={fromAndToAreSameDay}
              />
            </InputGroup>
          ) : null}

          {policyDetails && !isDatePickerDisabled ? (
            <ActionWidget
              title={widgetTitle}
              text={
                <Box color={Color.FOREGROUND}>
                  <Box data-testid="request-approver">{renderApprovalInfo()}</Box>

                  {requestInstructions || detailsUrl ? (
                    <Box data-testid="instructions" mt="s-8">
                      <Text fontWeight={500}>Instructions:</Text>
                      {requestInstructions ? (
                        <Text ml="s-4">{requestInstructions}</Text>
                      ) : null}
                      {detailsUrl ? (
                        <Text ml="s-4">
                          More information on this policy can be found{' '}
                          <Link
                            href={detailsUrl}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            here
                          </Link>
                          .
                        </Text>
                      ) : null}
                    </Box>
                  ) : null}
                  {publicHolidays?.length ? (
                    <Box data-testid="public-holidays" mt="s-8">
                      <Text fontWeight={500}>Public holidays:</Text>
                      <List>
                        {publicHolidays.map(holiday => (
                          <li key={holiday.day}>
                            {holiday.name} - {formatDate(holiday.day)}
                          </li>
                        ))}
                      </List>
                    </Box>
                  ) : null}
                </Box>
              }
              avatarColor={Color.PRIMARY}
            />
          ) : null}

          <LapeNewTextArea
            name="note"
            label="Note"
            required={isCommentRequired}
            rows={3}
          />

          <LapeFileUploader
            name="attachment"
            required={isAttachmentRequired}
            label={`File attachment${isAttachmentRequired ? ' (mandatory)' : ''}`}
          />
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={data => {
            if (
              initialAttachmentId &&
              ((data as EmployeeTimeOffRequestInterface).attachment as FileInterface)
                ?.id !== initialAttachmentId
            ) {
              deleteFile(initialAttachmentId)
            }
          }}
          previewUrl={ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW}
          useValidator
        >
          {recordAbsenceMode ? 'Record absence' : 'Submit time-off request'}
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}

export default General
