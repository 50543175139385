import { RevolutTheme } from '@src/styles/theme'
import { PerformanceCycleInterface, PerformanceRating } from '@src/interfaces/performance'
import { ProbationResults } from '@src/interfaces/probationReview'
import { getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

export const RatingColor = (theme: RevolutTheme) => ({
  poor: theme.colors.red,
  fail: theme.colors.red,
  poor_plus: theme.colors.red,
  dont_know: getColor(theme, Color.GREY_TONE_50),
  skipped: getColor(theme, Color.GREY_TONE_50),
})

export const SCROLL_ERROR_HASH = '#scroll_error'

export const PerformanceRatingTitle = {
  [PerformanceRating.skipped]: 'Skipped',
  [PerformanceRating.dont_know]: "Don't know",
  [PerformanceRating.poor]: 'Poor',
  [PerformanceRating.poor_plus]: 'Poor +',
  [PerformanceRating.basic_minus]: 'Basic -',
  [PerformanceRating.basic]: 'Basic',
  [PerformanceRating.basic_plus]: 'Basic +',
  [PerformanceRating.intermediate_minus]: 'Intermediate -',
  [PerformanceRating.intermediate]: 'Intermediate',
  [PerformanceRating.intermediate_plus]: 'Intermediate +',
  [PerformanceRating.advanced_minus]: 'Advanced -',
  [PerformanceRating.advanced]: 'Advanced',
  [PerformanceRating.advanced_plus]: 'Advanced +',
  [PerformanceRating.expert_minus]: 'Expert -',
  [PerformanceRating.expert]: 'Expert',
  [PerformanceRating.expert_plus]: 'Expert +',
  [PerformanceRating.improvement_needed]: 'Improvement Focus',
  [PerformanceRating.performing]: 'Performing',
  [PerformanceRating.superpower]: 'Superpower',
  none: 'N/A',
}

export const PerformanceRatingShortTitle = {
  ...PerformanceRatingTitle,
  [PerformanceRating.poor_plus]: 'Poor+',
  [PerformanceRating.basic_minus]: 'Bas-',
  [PerformanceRating.basic_plus]: 'Bas+',
  [PerformanceRating.intermediate_minus]: 'Inter-',
  [PerformanceRating.intermediate]: 'Inter',
  [PerformanceRating.intermediate_plus]: 'Inter+',
  [PerformanceRating.advanced_minus]: 'Adv-',
  [PerformanceRating.advanced]: 'Adv',
  [PerformanceRating.advanced_plus]: 'Adv+',
  [PerformanceRating.expert_minus]: 'Expert-',
  [PerformanceRating.expert_plus]: 'Expert+',
}

export const PerformanceSenioritySublevel = {
  1: 'I',
  2: 'II',
  3: 'III',
}

export const probationResultToString = (result?: ProbationResults) => {
  switch (result) {
    case ProbationResults.Passed:
      return 'Pass'
    case ProbationResults.ChangeSeniority:
      return 'Pass (lower seniority)'
    case ProbationResults.Extend:
      return 'Extend'
    case ProbationResults.Failed:
      return 'Fail'
    default:
      return ''
  }
}

export const PREVIOUS_CYCLE_WIDTH = 75
export const PREVIOUS_CYCLE_OFFSET = 25
export const PREVIOUS_CYCLE_PADDING = 8

export const getQuarterAndYearFromCycle = (cycle?: PerformanceCycleInterface) => {
  const quarter = cycle?.name.slice(0, 2)
  const year = cycle?.name.slice(-2)

  if (quarter && year) {
    return `${quarter} ‘${year}`
  }

  return ''
}

export const DAYS_BEFORE_OPENING_CHECKPOINT = 5
