import React, { useState } from 'react'

import { MoreBar, Box } from '@revolut/ui-kit'
import { Bug, SendMessage } from '@revolut/icons'
import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import {
  EngagementSurveyHistoryInterface,
  EngagementSurveyInterface,
} from '@src/interfaces/engagement'
import {
  engagementSurveyFormRequests,
  engagementSurveyHistoryRequests,
  testEngagementSurvey,
} from '@src/api/engagement'
import {
  surveyHistorySentOnColumn,
  surveyHistorySentByColumn,
  surveyHistoryQuestionsNumColumn,
  surveyHistoryAudienceSizeColumn,
  surveyHistoryParticipationRate,
  surveyHistoryStatusColumn,
  surveyHistoryActionsColumn,
} from '@src/constants/columns/engagementSurveyHistory'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { StartSurveyForm } from '@src/apps/People/Engagement/Survey/Preview/StartSurveyForm'
import { HistoryActions } from '@src/apps/People/Engagement/Survey/Preview/HistoryActions'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

const getRows = (
  refetch: () => void,
): RowInterface<EngagementSurveyHistoryInterface> => ({
  cells: [
    {
      ...surveyHistorySentOnColumn,
      width: 200,
    },
    {
      ...surveyHistorySentByColumn,
      width: 200,
    },
    {
      ...surveyHistoryQuestionsNumColumn,
      width: 200,
    },
    {
      ...surveyHistoryAudienceSizeColumn,
      width: 200,
    },
    {
      ...surveyHistoryParticipationRate,
      width: 200,
    },
    {
      ...surveyHistoryStatusColumn,
      width: 200,
    },
    {
      ...surveyHistoryActionsColumn,
      width: 220,
      insert: ({ data }) => <HistoryActions data={data} refetch={refetch} />,
    },
  ],
})

export const SurveyHistory = () => {
  const { initialValues, values } = useLapeContext<EngagementSurveyInterface>()
  const table = useTable<EngagementSurveyHistoryInterface>(
    engagementSurveyHistoryRequests(initialValues.id!),
    [
      {
        columnName: 'is_test',
        filters: [{ id: 'false', name: 'false' }],
        nonResettable: true,
      },
    ],
  )

  const [isTestPending, setIsTestPending] = useState(false)
  const [isStartSubmitOpen, setIsStartSubmitOpen] = useState(false)

  const updateSurveyStatus = () => {
    engagementSurveyFormRequests
      .get({ id: String(initialValues.id) })
      .then(({ data }) => {
        values.status = data.status
      })
  }
  const refetch = () => {
    updateSurveyStatus()
    table.refresh()
  }

  return (
    <>
      <TableWrapper>
        <Box mb="s-16">
          <MoreBar>
            <MoreBar.Action
              useIcon={SendMessage}
              variant="primary"
              onClick={() => setIsStartSubmitOpen(true)}
            >
              Send
            </MoreBar.Action>
            <MoreBar.Action
              useIcon={Bug}
              pending={isTestPending}
              onClick={async () => {
                setIsTestPending(true)
                try {
                  const { data } = await testEngagementSurvey(initialValues.id!)
                  navigateTo(
                    `${pathToUrl(ROUTES.ENGAGEMENT_PAGE)}?survey_id=${
                      data.id
                    }&is_test=true`,
                  )
                } finally {
                  setIsTestPending(false)
                }
              }}
            >
              Test
            </MoreBar.Action>
          </MoreBar>
        </Box>
        <AdjustableTable<EngagementSurveyHistoryInterface>
          name={TableNames.EngagementSurveyHistory}
          useWindowScroll
          row={getRows(refetch)}
          {...table}
          noDataMessage="Survey history will appear here"
        />
      </TableWrapper>
      <StartSurveyForm
        surveyId={initialValues.id!}
        isOpen={isStartSubmitOpen}
        onClose={() => setIsStartSubmitOpen(false)}
        refetch={refetch}
      />
    </>
  )
}
