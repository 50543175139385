import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@src/styles/theme'
import { TeamInterface } from '@src/interfaces/teams'
import { DepartmentInterface } from '@src/interfaces/deparment'
import Tooltip from '../../Tooltip/Tooltip'
import { CompanyInterface } from '@src/interfaces/company'
import { AuditCyclesInterface } from '@src/interfaces/audit'
import { colorGetter, getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'
import { formatNumberMillions } from '@src/utils/format'
import { EmployeeStats } from '@src/interfaces/employees'

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
`

const CircleContainer = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  &:last-child {
    margin-right: 0;
  }
`

const ColoredCircle = styled.div<{ color: string }>`
  display: inline-block;
  flex-shrink: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${props => props.color};
`

const ColoredText = styled.span<{ color: string }>`
  color: ${props => props.color};
`

const AuditColoredText = styled(ColoredText)`
  margin-left: 2px;
`

interface Props {
  data:
    | TeamInterface
    | DepartmentInterface
    | CompanyInterface
    | AuditCyclesInterface
    | EmployeeStats
}

const AuditCircles = ({ data }: Props) => {
  const theme = useTheme()

  if (
    data.audits_critical === 0 &&
    data.audits_exceptional === 0 &&
    data.audits_recommended === 0 &&
    data.audits_trivial === 0
  ) {
    return <ColoredText color={getColor(theme, Color.GREY_20_OPAQUE_90)}>0</ColoredText>
  }

  return (
    <Tooltip
      placement="bottom"
      text="Red - Critical\nOrange - High\nYellow - Medium\nGrey - Low"
    >
      <Container>
        {!!data.audits_exceptional && (
          <CircleContainer>
            <ColoredCircle color={theme.colors.red} />
            <AuditColoredText color={theme.colors.red}>
              {formatNumberMillions(data.audits_exceptional)}
            </AuditColoredText>
          </CircleContainer>
        )}
        {!!data.audits_critical && (
          <CircleContainer>
            <ColoredCircle color={getColor(theme, Color.WARNING)} />
            <AuditColoredText color={getColor(theme, Color.WARNING)}>
              {formatNumberMillions(data.audits_critical)}
            </AuditColoredText>
          </CircleContainer>
        )}
        {!!data.audits_recommended && (
          <CircleContainer>
            <ColoredCircle color={getColor(theme, Color.ORANGE_OPAQUE_70)} />
            <AuditColoredText color={getColor(theme, Color.ORANGE_OPAQUE_70)}>
              {formatNumberMillions(data.audits_recommended)}
            </AuditColoredText>
          </CircleContainer>
        )}
        {!!data.audits_trivial && (
          <CircleContainer>
            <ColoredCircle color={getColor(theme, Color.GREY_TONE_50)} />
            <AuditColoredText color={getColor(theme, Color.GREY_TONE_50)}>
              {formatNumberMillions(data.audits_trivial)}
            </AuditColoredText>
          </CircleContainer>
        )}
      </Container>
    </Tooltip>
  )
}

export default AuditCircles
