import React from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { BREAKPOINTS, Flex, Progress, Widget, Text, chain } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { useBackgroundJob } from '@src/api/backgroundJobs'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import PageError from '@src/components/PageError'

const state_colors = {
  PENDING: 'orange',
  RECEIVED: 'orange',
  STARTED: 'orange',
  SUCCESS: 'green',
  FAILURE: 'red',
  REVOKED: 'red',
  REJECTED: 'red',
  RETRY: 'red',
  IGNORED: 'red',
}

const ViewBackgroundJob = () => {
  const params = useParams<{ id: string }>()
  const { id } = params

  const { data, isError, error } = useBackgroundJob(Number(id))

  const progressColor = () => {
    if (!data) {
      return 'orange'
    }

    if (data.state.id in state_colors) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      return state_colors[data.state.id]
    }

    return 'orange'
  }

  if (isError) {
    return <PageError title="Something went wrong" text={error.message} />
  }

  if (!data) {
    return <PageLoading />
  }

  return (
    <PageBody pb="s-32" maxWidth={BREAKPOINTS.xl}>
      <PageHeader
        pb="s-8"
        title={chain(
          data.label,
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          <Text color={state_colors[data.state.id]}>{data.state.name}</Text>,
        )}
        subtitle="Background job"
        backUrl={ROUTES.PEOPLE.BACKGROUND_JOBS}
      />
      <Widget display="flex" bg="widget-background" p="s-16" width="100%">
        <Flex flexDirection="column" width="100%">
          <Text variant="h1" fontWeight="bold" mb="s-2">
            {data.progress_percentage}%
          </Text>
          <Text color="gray" mb="s-16">
            Changes completed
          </Text>
          <Progress
            value={data.progress_percentage / 100}
            color={progressColor()}
            mb="s-16"
          />
          <Text color="gray" mb="s-16">
            {data.result}
          </Text>
        </Flex>
      </Widget>
    </PageBody>
  )
}

export default ViewBackgroundJob
