import React from 'react'
import styled from 'styled-components'
import OrderingModeCheckbox from '@src/features/OrderingTableControls/OrderingModeCheckbox'
import { Button } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'

const Wrap = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 16px;
  justify-content: flex-start;
  height: 36px;
`

type Props = {
  disabled: boolean
  orderingMode: boolean
  onChangeMode: (isOrdering: boolean) => void
  moveToTop: () => void
  moveToBottom: () => void
  disabledMoveButtons: boolean
}

const OrderingTableControls = ({
  disabled,
  orderingMode,
  onChangeMode,
  moveToTop,
  moveToBottom,
  disabledMoveButtons,
}: Props) => {
  return (
    <Wrap>
      <OrderingModeCheckbox
        disabled={disabled}
        checked={orderingMode}
        onChange={onChangeMode}
      />
      {orderingMode && (
        <>
          <Tooltip
            placement="top"
            text="You should select at least one row to move"
            hide={!disabledMoveButtons}
          >
            <Button
              variant="secondary"
              size="sm"
              disabled={disabledMoveButtons}
              onClick={e => {
                // to avoid clearing selectedIds on click outside
                e.stopPropagation()
                moveToTop()
              }}
            >
              Move to top
            </Button>
          </Tooltip>
          <Tooltip
            placement="top"
            text="You should select at least one row to move"
            hide={!disabledMoveButtons}
          >
            <Button
              variant="secondary"
              size="sm"
              disabled={disabledMoveButtons}
              onClick={e => {
                e.stopPropagation()
                moveToBottom()
              }}
            >
              Move to bottom
            </Button>
          </Tooltip>
        </>
      )}
    </Wrap>
  )
}

export default OrderingTableControls
