import React from 'react'
import { Header, Popup } from '@revolut/ui-kit'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import ArchivingCandidateSidebar from '@components/ArchivingCandidateSidebar/ArchivingCandidateSidebar'

type Props = {
  candidate: CandidateInterface
  onClose: () => void
  onLoading: (loading: boolean) => void
  onSuccess: () => void
}

export const CVScreeningArchive = ({
  onClose,
  onLoading,
  candidate,
  onSuccess,
}: Props) => {
  return (
    <Popup size="md" open onClose={onClose}>
      <Header variant="compact">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Archive candidate</Header.Title>
        <Header.Description>
          This will also cancel all the scheduled interviews for the archived opportunity
        </Header.Description>
      </Header>
      <ArchivingCandidateSidebar
        candidate={candidate}
        open
        onClose={onClose}
        onLoading={onLoading}
        onAfterArchive={onSuccess}
        noSuccessPopup
        noSidebar
        showInvalidPlaceholdersWarning
      />
    </Popup>
  )
}
