import React from 'react'
import pluralize from 'pluralize'

import { TableWrapper } from '@components/Table/TableWrapper'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { FaqInterface } from '@src/interfaces/faq'
import { TableNames } from '@src/constants/table'
import { faqTableRequests } from '@src/api/faq'
import { useTable } from '@src/components/Table/hooks'
import {
  faqCountriesColumn,
  faqMaintainerColumn,
  faqStatusColumn,
  faqTitleColumn,
  faqTopicColumn,
  faqUpdatedOnColumn,
} from '@src/constants/columns/faq'
import Stat from '@components/Stat/Stat'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { Box, MoreBar } from '@revolut/ui-kit'
import { Plus, Upload } from '@revolut/icons'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

const ROW: RowInterface<FaqInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.APPS.HELP_CENTER.QUESTION.PREVIEW, { id: String(id) })),
  cells: [
    {
      ...faqTitleColumn,
      width: 400,
    },
    {
      ...faqTopicColumn,
      width: 140,
    },
    {
      ...faqCountriesColumn,
      width: 200,
    },
    {
      ...faqUpdatedOnColumn,
      width: 150,
    },
    {
      ...faqMaintainerColumn,
      width: 180,
    },
    {
      ...faqStatusColumn,
      width: 100,
    },
  ],
}

export const HelpCenterFaqTable = () => {
  const permissions = useSelector(selectPermissions)
  const table = useTable<FaqInterface>(faqTableRequests, [
    { columnName: 'status', filters: [{ id: 'active', name: 'active' }] },
  ])
  const canAdd = permissions.includes(PermissionTypes.AddFaq)

  return (
    <TableWrapper gap="s-16">
      <Stat
        label={pluralize('Question', table.count)}
        val={table.loading ? undefined : table.count}
      />
      <Box>
        <MoreBar>
          {canAdd && (
            <>
              <MoreBar.Action
                useIcon={Plus}
                use={InternalLink}
                to={pathToUrl(ROUTES.APPS.HELP_CENTER.QUESTION.EDIT)}
              >
                Add question
              </MoreBar.Action>
              <MoreBar.Action
                useIcon={Upload}
                use={InternalLink}
                to={pathToUrl(ROUTES.APPS.HELP_CENTER.FAQ_IMPORT.UPLOAD)}
              >
                Bulk upload
              </MoreBar.Action>
            </>
          )}
        </MoreBar>
      </Box>
      <AdjustableTable
        name={TableNames.SupportFaq}
        useWindowScroll
        row={ROW}
        {...table}
        dataType="Question"
        noDataMessage="All frequently asked questions will appear here"
      />
    </TableWrapper>
  )
}
