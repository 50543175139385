import { Filter } from '@revolut/icons'
import { ButtonProps, FilterButton } from '@revolut/ui-kit'
import React, { useRef, useState } from 'react'
import NewFilterSelect, {
  FilterSelectType,
  NewFilterInputProps,
} from '@components/Inputs/Filters/FilterSelect/NewFilterSelect'
import { FilterDateRange } from '@components/Inputs/Filters/FilterDateRange/FilterDateRange'
import { ButtonFilterType } from '@components/ButtonFilters/ButtonFilters'
import { ButtonFilterTitle } from '@components/ButtonFilters/ButtonFilterTitle'

export interface ButtonFilterProps
  extends Omit<NewFilterInputProps, 'onClose' | 'open' | 'type'> {
  title: string
  useIcon: ButtonProps['useIcon']
  type: ButtonFilterType
}

const ButtonFilter = ({
  title,
  onChange,
  value,
  useIcon,
  type,
  renderOption,
  ...props
}: ButtonFilterProps) => {
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)

  return (
    <>
      <FilterButton
        ref={anchorRef}
        useIcon={useIcon || Filter}
        onClick={() => setOpen(!open)}
        onClear={value?.length ? () => onChange([]) : undefined}
        active={!!value?.length}
        aria-expanded={open}
      >
        <ButtonFilterTitle type={type} title={title} value={value} />
      </FilterButton>

      {type === 'DateRange' && (
        <FilterDateRange
          onChange={o => {
            onChange(o)
            setOpen(false)
          }}
          value={value}
          open={open}
          onClose={() => setOpen(false)}
          anchorRef={anchorRef}
        />
      )}

      {(type === FilterSelectType.MultiSelect ||
        type === FilterSelectType.SingleSelect) && (
        <NewFilterSelect
          anchorRef={anchorRef}
          onChange={onChange}
          open={open}
          onClose={() => setOpen(false)}
          value={value}
          type={type as FilterSelectType}
          renderOption={renderOption}
          {...props}
        />
      )}
    </>
  )
}

export default ButtonFilter
