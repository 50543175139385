import React, { useEffect, useState, useMemo } from 'react'
import * as Sentry from '@sentry/react'
import {
  Box,
  Fixed,
  Flex,
  Group,
  Header,
  MoreBar,
  Side,
  Cell,
  Text,
  Image,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { Document, Download } from '@revolut/icons'

import { useTheme } from '@src/styles/theme'
import { DocumentInterface } from '@src/interfaces/documents'
import { downloadUploadedAsBlob, newDocumentRequests } from '@src/api/documents'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Loader from '@src/components/CommonSC/Loader'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { saveFile } from '@src/utils'
import { SideBarHeaderTitle } from '@src/components/SideBar/SideBarHeaderTitle'

interface AttachedDocumentProps {
  id: number
  document?: DocumentInterface
  renderDetails?: () => React.ReactNode
  renderActions?: () => React.ReactNode
}

export const AttachedDocument = ({
  id,
  document,
  renderDetails,
  renderActions,
}: AttachedDocumentProps) => {
  const [file, setFile] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    setFile(null)
    setIsLoading(true)

    if (document == null) {
      return
    }
    // TODO: RHR-4126 remove Sentry call when issue is identified
    if (document.id === id && document.file_name) {
      setIsError(false)
      downloadUploadedAsBlob(id)
        .then(response => setFile(response))
        .catch(error => {
          setIsError(true)
          Sentry.captureException(error)
        })
        .finally(() => setIsLoading(false))
    }

    if (document.id === id && !document.file_name) {
      setIsLoading(false)
    }
  }, [id, document])

  const renderDocument = () => {
    if (!file || !document) {
      return null
    }

    if (document.file_media_type === 'application/pdf') {
      return (
        <iframe
          src={`${file}#view=fitH&toolbar=0&navpanes=0`}
          width="100%"
          height="100%"
          frameBorder="0"
          title="Document"
        />
      )
    }

    return <Image src={file} />
  }

  const showDownload = Boolean(file && document?.file_name)
  const hasFileActionsBar = Boolean(renderActions || showDownload)

  return (
    <>
      {isLoading && <Loader />}
      <VStack gap="s-24">
        {!isLoading && file == null ? (
          <VStack space="s-16">
            {renderActions && <MoreBar>{renderActions()}</MoreBar>}
            <Cell>
              <Flex
                flexDirection="column"
                alignItems="center"
                flex="1"
                py="s-32"
                gap="s-24"
              >
                <Document color={Token.color.greyTone20} />
                {isError ? (
                  <Text color={Token.color.greyTone10}>
                    There was an issue loading documents. An error report has been sent.
                  </Text>
                ) : (
                  <Text color={Token.color.greyTone20}>No documents attached</Text>
                )}
              </Flex>
            </Cell>
          </VStack>
        ) : (
          <Box>
            {hasFileActionsBar && (
              <Box mb="s-16">
                <MoreBar>
                  {showDownload && (
                    <MoreBar.Action
                      onClick={() => saveFile(file!, document!.file_name)}
                      useIcon={Download}
                    >
                      Download
                    </MoreBar.Action>
                  )}
                  {renderActions?.()}
                </MoreBar>
              </Box>
            )}
            <Box style={{ height: 'calc(100vh - 230px)' }}>{renderDocument()}</Box>
          </Box>
        )}
        {renderDetails?.()}
      </VStack>
    </>
  )
}

type DocumentSidebarProps = {
  id: number
  employeeId: number
  isOpen: boolean
  onClose: () => void
}

const DocumentSidebar = ({ id, employeeId, isOpen, onClose }: DocumentSidebarProps) => {
  const theme = useTheme()
  const [document, setDocument] = useState<DocumentInterface>()

  const renderKey = useMemo(() => Math.random(), [id, employeeId])

  if (!isOpen) {
    return null
  }

  return (
    <Fixed
      right={0}
      top={0}
      zIndex={theme.zIndex.sideBar}
      p="s-16"
      height="100vh"
      key={renderKey}
    >
      <Flex height="calc(100vh - 32px)">
        <Side open onClose={onClose} variant="wide" resizable height="100%">
          <Header variant="item">
            <Header.CloseButton aria-label="Close" />
            <SideBarHeaderTitle>Document preview</SideBarHeaderTitle>
            <Header.Bar>
              <MoreBar>
                <MoreBar.Action
                  onClick={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.DOCUMENT.GENERAL, { id, employeeId }),
                    )
                  }
                >
                  Edit
                </MoreBar.Action>
              </MoreBar>
            </Header.Bar>
          </Header>
          <Box mt="-16px">
            <FormPreview
              title="Details"
              data={document}
              api={() =>
                newDocumentRequests.get({
                  id: `${id}`,
                  employeeId: `${employeeId}`,
                })
              }
              onDataLoaded={setDocument}
            >
              <Group>
                <FormPreview.Item title="File name" field="name" />
                <FormPreview.Item
                  title="Issue date"
                  field="issue_date_time"
                  type="date"
                />
                <FormPreview.Item title="Issued by" field="issuer.name" />
                <FormPreview.Item
                  title="Signing date"
                  field="signing_date_time"
                  type="date"
                />
                <FormPreview.Item title="Category" field="category.name" />
              </Group>

              <Box my="s-16">
                <FormPreview.Details title="Description" field="description" />
              </Box>
            </FormPreview>
          </Box>

          <AttachedDocument id={id} document={document} />
        </Side>
      </Flex>
    </Fixed>
  )
}

export default DocumentSidebar
