import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'

import { Scorecard } from '@src/features/Scorecard/Scorecard'
import { useQuery } from '@src/utils/queryParamsHooks'
import { ScorecardInterface } from '@src/interfaces/scorecard'
import { postScorecard, putScorecardForEditing } from '@src/api/performance'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { Queries } from '@src/constants/api'
import { PerformanceSelector } from '@src/interfaces/performance'
import { EmployeeInterface } from '@src/interfaces/employees'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectUser } from '@src/store/auth/selectors'
import { PerformanceTutorial } from '../PerformanceTutorial'

const Wrap = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 767px auto;
`

const PositionedScorecard = styled(Scorecard)`
  box-shadow: -9px 5px 29px 0 ${props => props.theme.colors['grey-tone-2']};
`

type Props = {
  data: EmployeeInterface
  onCancelEditing: () => void
  selectedPeriod?: PerformanceSelector
  scorecardFilter?: OptionInterface
  scorecardLoading: boolean
  setFilter: (filter: OptionInterface) => void
  onSubmit: () => void
}

const PipReviewForm = ({
  selectedPeriod,
  data,
  scorecardFilter,
  scorecardLoading,
  onCancelEditing,
  setFilter,
  onSubmit,
}: Props) => {
  const user = useSelector(selectUser)
  const { query, deleteQueryParam } = useQuery(true)

  const handleCancelReview = () => {
    deleteQueryParam(Queries.RequestId)
    deleteQueryParam(Queries.CycleId)
    onCancelEditing()
  }

  const handleSubmitReview = async (d: ScorecardInterface) => {
    if (query.editing_id) {
      const result = await putScorecardForEditing(query.editing_id, d)

      if (result.data) {
        pushNotification({
          value: `Review for ${data.full_name} successfully updated.`,
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
        deleteQueryParam(Queries.EditingId)
        setFilter({
          name: `${result.data.reviewed_by.full_name} - ${format(
            new Date(result.data.updated_date),
            'd MMM yyyy hh:mm a',
          )}`,
          id: result.data.id,
        })
        onSubmit()
      }
      return result
    }

    const realData = {
      ...d,
      reviewed_person: {
        id: data.id,
      },
      reviewed_by: {
        id: user.id,
      },
      verdict: 'pass',
    }
    const result = await postScorecard(realData as ScorecardInterface)
    if (result.data) {
      pushNotification({
        value: `Review for ${data.full_name} successfully submitted.`,
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
      deleteQueryParam(Queries.RequestId)
      onSubmit()
    }
    return result
  }

  return (
    <Wrap>
      <PerformanceTutorial name={data.first_name} />
      <PositionedScorecard
        editing
        setEditing={() => {
          onCancelEditing()
        }}
        requestId={query.request_id}
        editingId={query.editing_id}
        onSubmit={handleSubmitReview}
        cycleId={selectedPeriod?.id !== undefined ? String(selectedPeriod.id) : undefined}
        onCancel={handleCancelReview}
        forcedFilter={scorecardFilter}
        performanceType={selectedPeriod ? selectedPeriod.category : undefined}
        employee={data}
        loading={scorecardLoading}
        fromDate={selectedPeriod && selectedPeriod.start_date_time}
        toDate={selectedPeriod && selectedPeriod.end_date_time}
      />
    </Wrap>
  )
}

export default PipReviewForm
