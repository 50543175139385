import React, { useRef, useState } from 'react'
import { Box, Button, Color, Flex, H4, Skeleton, VStack } from '@revolut/ui-kit'
import { ChevronDown, ChevronUp } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import FilterSelect, {
  FilterSelectType,
} from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { IdAndName } from '@src/interfaces'

type Props = {
  label: string
  value: IdAndName | undefined
  setValue: (value: IdAndName | undefined) => void
  options: IdAndName[]
  isFetching: boolean
}
export const EntitySelector = ({
  label,
  value,
  setValue,
  options,
  isFetching,
}: Props) => {
  const [open, setOpen] = useState(false)
  const selectRef = useRef<HTMLButtonElement>(null)

  if (isFetching || !value) {
    return (
      <VStack>
        <Skeleton width={72} height="s-20" my="s-4" />
        <Skeleton width={36} height="s-12" my="s-4" ml="s-2" />
      </VStack>
    )
  }

  return (
    <Stat
      label={label}
      val={
        <Box>
          <Button
            ref={selectRef}
            onClick={() => setOpen(!open)}
            variant="text"
            height="fit-content"
            p={0}
          >
            <Flex alignItems="center" color="foreground">
              <H4
                use="span"
                fontWeight="bold"
                color={value ? Color.FOREGROUND : Color.GREY_TONE_50}
                whiteSpace="pre-line"
                textAlign="left"
              >
                {value?.name || 'N/A'}
              </H4>
              {value && (
                <Box>{open ? <ChevronUp size={24} /> : <ChevronDown size={24} />}</Box>
              )}
            </Flex>
          </Button>
          <FilterSelect
            open={open}
            selector={() => Promise.resolve({ options })}
            anchorRef={selectRef}
            onClose={() => {
              setOpen(false)
            }}
            value={[value]}
            onChange={newOptions => {
              const [option] = newOptions

              if (option) {
                setValue(option)
                setOpen(false)
              }
            }}
            type={FilterSelectType.SingleSelect}
            width={450}
          />
        </Box>
      }
    />
  )
}
