import React from 'react'
import { EntityTypes } from '@src/constants/api'
import {
  auditAssigneeColumn,
  auditCreationDateColumn,
  auditDueDateColumn,
  auditIssueLinkColumn,
  auditLatenessColumn,
  auditParentLinkColumn,
  auditSeverityColumn,
  auditSummaryColumn,
  auditTeamNameColumn,
  auditTypeColumn,
} from '@src/constants/columns/audit'
import {
  AuditInterface,
  AuditSeverities,
  AuditStatuses,
  AuditTypes,
} from '@src/interfaces/audit'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { FindingsTab } from '@src/features/Findings/FindingsTab'
import { Color } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'

interface StructureProps {
  data: DepartmentInterface
}

const row: RowInterface<AuditInterface> = {
  highlight: (data, theme) => {
    if (
      data.severity_v2?.id === AuditSeverities.Critical ||
      data.severity_v2?.id === AuditSeverities.High
    ) {
      return getColor(theme, Color.RED_OPAQUE_20)
    }
    return ''
  },
  cells: [
    {
      ...auditIssueLinkColumn,
      width: 100,
    },
    {
      ...auditTypeColumn,
      width: 100,
    },
    {
      ...auditSummaryColumn,
      width: 280,
    },
    {
      ...auditAssigneeColumn,
      width: 200,
    },
    {
      ...auditTeamNameColumn,
      width: 200,
    },
    {
      ...auditParentLinkColumn,
      width: 120,
    },
    {
      ...auditDueDateColumn,
      width: 140,
    },
    {
      ...auditCreationDateColumn,
      width: 140,
    },
    {
      ...auditSeverityColumn,
      width: 140,
    },
    {
      ...auditLatenessColumn,
      width: 140,
    },
  ],
}

const initialFilterBy = [
  {
    filters: [
      { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
      { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
    ],
    columnName: 'status',
    nonResettable: true,
  },
  {
    filters: [{ id: AuditTypes.AUDIT, name: AuditTypes.AUDIT }],
    columnName: 'category',
    nonResettable: true,
  },
]

const initialSortBy = [
  {
    sortBy: 'severity_v2',
    direction: SORT_DIRECTION.ASC,
  },
  {
    sortBy: 'team_name',
    direction: SORT_DIRECTION.DESC,
  },
  {
    sortBy: 'due_date',
    direction: SORT_DIRECTION.DESC,
  },
]

export const Findings = ({ data }: StructureProps) => {
  return (
    <FindingsTab
      data={data}
      entity={EntityTypes.department}
      initialFilter={initialFilterBy}
      initialSort={initialSortBy}
      row={row}
    />
  )
}

export default Findings
