import React, { useState } from 'react'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import {
  Box,
  DragAndDrop,
  HStack,
  Icon,
  Item,
  ItemSkeleton,
  MoreButton,
  Text,
  Tooltip,
  VStack,
  move,
  useTooltip,
} from '@revolut/ui-kit'
import { getDuration } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { OptionInterface } from '@src/interfaces/selectors'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

type HiringProcessRoundInterviewersProps = {
  interviewerType: OptionInterface
  interviewers?: EmployeeOptionInterface[]
  interviewersGroups?: OptionInterface[]
}

export const HiringProcessRoundInterviewers = ({
  interviewerType,
  interviewers,
  interviewersGroups,
}: HiringProcessRoundInterviewersProps) => {
  if (interviewerType?.id === 'employees') {
    return (
      <HStack space="s-4">
        {interviewers?.map(item => (
          <UserWithAvatar key={item.id} {...item} compact />
        ))}
      </HStack>
    )
  }
  if (interviewerType?.id === 'groups') {
    return <Text>{interviewersGroups?.map(({ name }) => name).join(', ')}</Text>
  }
  return null
}

type HiringProcessRoundDetailsProps = {
  disableDelete?: boolean
  disableDeleteLabel?: string
  hiringProcessRound: HiringProcessInterface
  loading?: boolean
  sortable?: DragAndDrop.DefaultSortableItemState<{}>
  onDeleteRound?: (hiringProcessRound: HiringProcessInterface) => void
  onEditRound?: (hiringProcessRound: HiringProcessInterface) => void
  onShowRoundDetails?: (hiringProcessRound: HiringProcessInterface) => void
}

const HiringProcessRoundDetails = ({
  disableDelete,
  disableDeleteLabel,
  hiringProcessRound,
  loading = false,
  sortable,
  onDeleteRound,
  onEditRound,
  onShowRoundDetails,
}: HiringProcessRoundDetailsProps) => {
  const tooltip = useTooltip()
  if (loading) {
    return <ItemSkeleton />
  }
  return (
    <Item
      use="label"
      onClick={e => {
        e.preventDefault()
        onShowRoundDetails?.(hiringProcessRound)
      }}
      ref={sortable?.setNodeRef}
      style={
        sortable
          ? {
              transform: sortable.transform
                ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                : undefined,
              transition: sortable.transition || 'none',
              opacity: sortable.isDragging ? 0 : undefined,
            }
          : undefined
      }
    >
      <Item.Avatar {...sortable?.attributes} {...sortable?.listeners}>
        <Icon name="Drag" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          {hiringProcessRound.title || hiringProcessRound.company_hiring_stage?.name}
        </Item.Title>
        <Item.Description>
          <VStack gap="s-8" pt="s-8">
            {hiringProcessRound.stage_type && (
              <HStack align="center" gap="s-8">
                <Icon name="Info" size={16} />
                <Box>
                  <Text>Stage type: </Text>
                  <Text>{hiringProcessRound.stage_type.name}</Text>
                </Box>
              </HStack>
            )}
            {hiringProcessRound.stage_type.id !== 'cv_screening' && (
              <HStack align="center" gap="s-8">
                <Icon name="Time" size={16} />
                <Box>
                  <Text>Duration: </Text>
                  <Text>
                    {getDuration(
                      hiringProcessRound.duration,
                      hiringProcessRound.duration_unit.name,
                    )}
                  </Text>
                </Box>
              </HStack>
            )}
            {hiringProcessRound.scorecard_template?.name && (
              <HStack align="center" gap="s-8">
                <Icon name="Document" size={16} />
                <Box>
                  <Text>Scorecard: </Text>
                  <Text>{hiringProcessRound.scorecard_template?.name}</Text>
                </Box>
              </HStack>
            )}
            {!!(
              hiringProcessRound.interviewers?.length ||
              hiringProcessRound.interviewers_groups?.length
            ) && (
              <HStack align="center" gap="s-8">
                <Icon name="Profile" size={16} />
                <HStack gap="s-4">
                  <Box>
                    <Text>Interviewers: </Text>
                  </Box>
                  <HiringProcessRoundInterviewers
                    interviewerType={hiringProcessRound.interviewer_type}
                    interviewers={hiringProcessRound.interviewers}
                    interviewersGroups={hiringProcessRound.interviewers_groups}
                  />
                </HStack>
              </HStack>
            )}
          </VStack>
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <MoreButton
            variant="icon"
            label="More actions"
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <MoreButton.Action
              useIcon="Pencil"
              onClick={e => {
                e.stopPropagation()
                onEditRound?.(hiringProcessRound)
              }}
            >
              Edit stage
            </MoreButton.Action>
            <MoreButton.Action
              aria-disabled={disableDelete}
              useIcon="Delete"
              onClick={e => {
                e.stopPropagation()
                if (!disableDelete) {
                  onDeleteRound?.(hiringProcessRound)
                }
              }}
              {...tooltip.getAnchorProps()}
            >
              Remove stage
              {disableDelete && disableDeleteLabel ? (
                <Tooltip placement="bottom" {...tooltip.getTargetProps()}>
                  {disableDeleteLabel}
                </Tooltip>
              ) : null}
            </MoreButton.Action>
          </MoreButton>
        </Item.Value>
      </Item.Side>
    </Item>
  )
}

type HiringProcessDetailsProps = {
  hiringProcessRounds: HiringProcessInterface[]
  loading?: boolean
  onDeleteRound: (hiringProcessRound: HiringProcessInterface) => void
  onEditRound: (hiringProcessRound: HiringProcessInterface) => void
  onOrderChange: (newHiringProcessRounds: HiringProcessInterface[]) => void
  onShowRoundDetails?: (hiringProcessRound: HiringProcessInterface) => void
}

const HiringProcessDetails = ({
  hiringProcessRounds,
  loading = false,
  onDeleteRound,
  onEditRound,
  onOrderChange,
  onShowRoundDetails,
}: HiringProcessDetailsProps) => {
  const [activeId, setActiveId] = useState<string | number | null>(null)
  const activeHiringProcessIndex =
    activeId !== null
      ? hiringProcessRounds.findIndex(({ id }) => String(id) === activeId)
      : -1
  const activeHiringProcess =
    activeHiringProcessIndex >= 0
      ? hiringProcessRounds[activeHiringProcessIndex]
      : undefined
  const handleOrderChange = (startIndex: number, endIndex: number) => {
    if (startIndex !== endIndex) {
      onOrderChange(move(hiringProcessRounds, startIndex, endIndex))
    }
    setActiveId(null)
  }
  const disableCVScreeningDelete =
    hiringProcessRounds.filter(({ stage_type }) => stage_type.id === 'cv_screening')
      .length === 1
  return (
    <VStack gap="s-8">
      <DragAndDrop.Provider
        onDragStart={event => {
          setActiveId(event.active.id)
        }}
        onDragEnd={event => {
          if (event.over) {
            handleOrderChange(
              event?.active?.data?.current?.sortable.index ?? 0,
              event?.over?.data?.current?.sortable.index ?? 0,
            )
          }
        }}
        onDragCancel={() => {
          setActiveId(null)
        }}
      >
        <DragAndDrop.Sortable
          id="sortable"
          items={hiringProcessRounds.map(({ id }) => String(id))}
        >
          {sortable => {
            const currentProcessIndex = hiringProcessRounds.findIndex(
              ({ id }) => String(id) === sortable.id,
            )
            const currentProcess = hiringProcessRounds[currentProcessIndex]
            return (
              <HiringProcessRoundDetails
                key={currentProcess.id}
                disableDelete={
                  currentProcess.stage_type.id === 'cv_screening' &&
                  disableCVScreeningDelete
                }
                disableDeleteLabel="Cannot delete cv screening stage"
                hiringProcessRound={currentProcess}
                loading={loading}
                sortable={sortable}
                onDeleteRound={onDeleteRound}
                onEditRound={onEditRound}
                onShowRoundDetails={onShowRoundDetails}
              />
            )
          }}
        </DragAndDrop.Sortable>
        <DragAndDrop.DragOverlay>
          {activeHiringProcess && (
            <>
              <HiringProcessRoundDetails
                key={activeHiringProcess.id}
                disableDelete
                hiringProcessRound={activeHiringProcess}
              />
            </>
          )}
        </DragAndDrop.DragOverlay>
      </DragAndDrop.Provider>
    </VStack>
  )
}

export default HiringProcessDetails
