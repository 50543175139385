import { Statuses } from '@src/interfaces'
import { QueryRunStatuses } from '@src/interfaces/dataAnalytics'
import { Token } from '@revolut/ui-kit'

export const isExecuted = (status?: QueryRunStatuses) =>
  status === QueryRunStatuses.Success || status === QueryRunStatuses.Error

export const isRunning = (status?: QueryRunStatuses) =>
  status === QueryRunStatuses.Running || status === QueryRunStatuses.Queued

export const reportStatusToColor = (status: Statuses) => {
  switch (status) {
    case 'active':
      return Token.color.green
    case 'archived':
      return Token.color.greyTone50
    default:
      return Token.color.foreground
  }
}
