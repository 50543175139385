import React from 'react'
import { Box, Cell, Fixed, Group, Header, Portal, Side, Subheader } from '@revolut/ui-kit'
import { useTheme } from '@src/styles/theme'

type Props = {
  isOpen: boolean
  helpSection?: React.ReactNode
  onExit?: () => void
}

const HelpSidebar = ({ isOpen, helpSection, onExit }: Props) => {
  const theme = useTheme()

  if (!isOpen) {
    return null
  }
  return (
    <Portal>
      <Fixed
        right={0}
        top={0}
        zIndex={theme.zIndex.sideBar}
        p="s-16"
        height="100vh"
        overflow="auto"
      >
        <Side open={isOpen} onClose={onExit} variant="medium" height="100%">
          <Header variant="item">
            <Header.CloseButton aria-label="Close" />
            <Header.Title>Help and instructions</Header.Title>
          </Header>
          <Box minHeight="100%">
            {helpSection}

            <Subheader variant="nested">
              <Subheader.Title>Need help?</Subheader.Title>
            </Subheader>

            <Group>
              <Cell
                use="button"
                variant="disclosure"
                onClick={e => {
                  e.preventDefault()
                  window.open(
                    'https://revolut.atlassian.net/servicedesk/customer/portal/73/group/-1',
                    '_blank',
                  )
                }}
              >
                Raise a performance review question with the team
              </Cell>
              <Cell
                use="button"
                variant="disclosure"
                onClick={e => {
                  e.preventDefault()
                  window.open(
                    'https://revolut.atlassian.net/servicedesk/customer/portal/137',
                    '_blank',
                  )
                }}
              >
                Report a bug / error in the platform
              </Cell>
            </Group>
          </Box>
        </Side>
      </Fixed>
    </Portal>
  )
}

export default HelpSidebar
