import React, { useEffect, useState } from 'react'
import { VStack } from '@revolut/ui-kit'
import { ReviewCategory, ReviewScorecardInterface } from '@src/interfaces/performance'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import SidebarSkeleton from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SidebarSkeleton'
import ReviewersFilter from '@src/pages/EmployeeProfile/Preview/Performance/Summary/ReviewersFilter'
import { useFetchPerformanceSummary } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NoReviewsWidget from '@src/pages/EmployeeProfile/Preview/Performance/Common/NoReviewsWidget'
import { SummaryReviewTables } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewTables'
import { BarRaiserSummary } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserSummary'
import { EmployeeInterface } from '@src/interfaces/employees'
import { SummaryFeedbackSection } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryFeedbackSection'

export const CompletedReviews = ({ employee }: { employee?: EmployeeInterface }) => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const [filters, setFilters] = useState<OptionInterface[]>()

  const cycleId = values.cycle?.id ? String(values.cycle?.id) : undefined
  const employeeId = values.reviewed_employee.id
  const category = ReviewCategory.Performance

  const { deleteQueryParam } = useQuery()

  const {
    data,
    isLoading,
    reviews,
    refresh: refreshSummary,
  } = useFetchPerformanceSummary(cycleId, employeeId, category)

  useEffect(() => {
    if (!category || cycleId === undefined) {
      return
    }

    deleteQueryParam(Queries.CheckpointNumber)
    deleteQueryParam(Queries.ReviewerId)
    setFilters(undefined)
  }, [cycleId, employeeId])

  const handleReviewerFilterChange = (options?: OptionInterface[]) => {
    setFilters(options)
    refreshSummary(options, undefined, false)
  }

  return (
    <VStack space="s-24" mt="s-16">
      <ReviewersFilter
        data={reviews}
        onFilterChange={handleReviewerFilterChange}
        showFilters
      />

      {isLoading ? (
        <SidebarSkeleton />
      ) : (
        <>
          {!data?.reviews.length ? (
            <NoReviewsWidget filtersApplied={!!filters?.length} />
          ) : (
            <>
              {data.summary && (
                <>
                  <SummaryReviewTables data={data.summary} completedReviews />
                  <BarRaiserSummary data={data.summary} completedReviews />
                  {employee && (
                    <SummaryFeedbackSection employee={employee} summary={data.summary} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </VStack>
  )
}
