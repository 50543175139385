import React from 'react'
import { Color, Text, TextProps } from '@revolut/ui-kit'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { PerformanceRating } from '@src/interfaces/performance'
import styled from 'styled-components'

const Wrap = styled.span`
  display: inline-block;
  vertical-align: middle;
`
type Props = {
  rating?: PerformanceRating | null
  fontSize?: TextProps['fontSize']
  fontWeight?: number
  emptyMessage?: string
}

const PerformanceRatingLabelTag = ({
  rating,
  fontSize,
  fontWeight = 500,
  emptyMessage,
  ...props
}: Props) => {
  let color

  switch (rating) {
    case PerformanceRating.poor:
    case PerformanceRating.poor_plus:
      color = Color.RED
      break

    case PerformanceRating.dont_know:
    case PerformanceRating.skipped:
    case null:
      color = Color.GREY_TONE_50
      break

    default:
      color = Color.FOREGROUND
  }

  return (
    <Wrap {...props}>
      <Text color={color} fontWeight={fontWeight} fontSize={fontSize}>
        {rating ? PerformanceRatingTitle[rating] : emptyMessage}
      </Text>
    </Wrap>
  )
}

export default PerformanceRatingLabelTag
