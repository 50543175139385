import React, { useState } from 'react'
import {
  BottomSheet,
  Box,
  Button,
  Color,
  Flex,
  Header,
  Input,
  InputGroup,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'
import { get } from 'lodash'
import { format } from 'date-fns'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { IdAndName } from '@src/interfaces'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import { TableActionButton } from '@src/components/Button/TableActionButton'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { deleteImportTimeOffRequestsSessionRow } from '@src/api/importData'
import EditableCell from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'
import {
  ImportTimeOffRequestDataInterface,
  ImportTimeOffRequestsInterface,
} from '@src/interfaces/importTimeOffRequests'
import { EmployeeEmails } from '@src/interfaces/selectors'
import { getStatusColor } from '@src/components/CommonSC/General'
import { DatePickerInput } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import { HideInputCalendarIndicatorCss } from '@src/components/Inputs/LapeFields/helpers'
import { getTimeFromString, localDateToUtc, utcToLocalDate } from '@src/utils/timezones'
import { DropdownSelectCell } from '@src/components/Table/AdvancedCells/DropdownSelectCell/DropdownSelectCell'

type Option = EmployeeEmails | IdAndName<string>

export type ImportTimeOffRequestsInputSelectCellOptions = {
  employees: EmployeeEmails[]
  policies: IdAndName<string>[]
  approvalStatuses: IdAndName<string>[]
  units: IdAndName<string>[]
  periods: IdAndName<string>[]
}

type ImportTimeOffRequestsSelectInputField =
  | 'employee'
  | 'policy'
  | 'from_time_period'
  | 'to_time_period'
  | 'approval_status'
  | 'approver'
  | 'unit'

type ImportTimeOffRequestsInputField = 'note' | 'duration'

type ImportTimeOffRequestsDateField =
  | 'from_date_time'
  | 'to_date_time'
  | 'approval_date_time'
  | 'requested_on'

export type ImportTimeOffRequestsSelectInputOnChange = (
  row: ImportTimeOffRequestsInterface,
  option: Option | null,
  field: ImportTimeOffRequestsSelectInputField,
) => void

type ImportTimeOffRequestSelectInputColumn = (
  onChange: ImportTimeOffRequestsSelectInputOnChange,
  options: Option[],
) => ColumnInterface<ImportTimeOffRequestsInterface>

export type ImportTimeOffRequestsInputOnChange = (
  row: ImportTimeOffRequestsInterface,
  value: string | undefined,
  field: ImportTimeOffRequestsInputField | ImportTimeOffRequestsDateField,
) => void

type ImportTimeOffRequestDateColumn = (
  onChange: ImportTimeOffRequestsInputOnChange,
) => ColumnInterface<ImportTimeOffRequestsInterface>

type ImportTimeOffRequestInputColumn = (
  onChange: ImportTimeOffRequestsInputOnChange,
) => ColumnInterface<ImportTimeOffRequestsInterface>

interface ImportTimeOffRequestsSelectInputProps {
  onChange: ImportTimeOffRequestsSelectInputOnChange
  options: Option[]
  field: ImportTimeOffRequestsSelectInputField
  data: ImportTimeOffRequestsInterface
}

const ImportTimeOffRequestsSelectInput = ({
  data,
  onChange,
  options,
  field,
}: ImportTimeOffRequestsSelectInputProps) => {
  const fieldValue = data.data[field]

  const label = (() => {
    if (fieldValue && (field === 'employee' || field === 'approver')) {
      const foundOption = options?.find(opt => opt.name === fieldValue)
      return foundOption && 'full_name' in foundOption
        ? foundOption.full_name
        : fieldValue
    }

    if (fieldValue) {
      const foundOption = options?.find(
        opt => opt.name === fieldValue || opt.id === fieldValue,
      )
      return foundOption?.name || fieldValue
    }

    return '-'
  })()

  return (
    <CellWithError data={data} field={field}>
      <RadioSelectInput
        onChange={option => onChange(data, option, field)}
        options={options.map(value => ({
          label: 'full_name' in value ? value.full_name : value.name,
          value,
        }))}
        renderInput={(open, setOpen, ref) => (
          <DropdownSelectCell
            label={label}
            open={open}
            onClick={() => setOpen(!open)}
            ref={ref}
          />
        )}
      />
    </CellWithError>
  )
}

interface CellWithErrorProps {
  data: ImportTimeOffRequestsInterface
  field: keyof ImportTimeOffRequestDataInterface
}

const CellWithError: React.FC<CellWithErrorProps> = ({ data, field, children }) => {
  const tooltip = useTooltip()

  const errors = get(data.errors, field)?.join('\n')

  return (
    <Flex alignItems="center" {...tooltip.getAnchorProps()}>
      <Box flex="1" color={errors ? Color.RED : undefined}>
        {children}
      </Box>
      {errors ? (
        <>
          <Box width={18}>
            <ExclamationTriangle
              color={Color.ERROR}
              aria-label={`${field} error`}
              size={18}
            />
          </Box>
          <Tooltip {...tooltip.getTargetProps()} maxWidth={400}>
            {errors}
          </Tooltip>
        </>
      ) : null}
    </Flex>
  )
}

interface ImportTimeOffRequestsInputCellProps {
  data: ImportTimeOffRequestsInterface
  field: ImportTimeOffRequestsInputField
  onChange: ImportTimeOffRequestsInputOnChange
}

const InputCell = ({ data, field, onChange }: ImportTimeOffRequestsInputCellProps) => {
  const [value, setValue] = useState(data.data[field] || '')

  return (
    <CellWithError data={data} field={field}>
      <EditableCell
        type={TableCellInputType.text}
        value={value}
        onChange={val => setValue(val as string)}
        onBlur={val => onChange(data, val as string, field)}
      />
    </CellWithError>
  )
}

const fieldToHeaderTitle = {
  from_date_time: 'From date',
  to_date_time: 'To date',
  approval_date_time: 'Approval date',
  requested_on: 'Requested on',
}

interface ImportTimeOffRequestDatePickerProps extends ImportTimeOffRequestsDateCellProps {
  onClose: () => void
}

const ImportTimeOffRequestDatePicker = ({
  data,
  field,
  onChange,
  onClose,
}: ImportTimeOffRequestDatePickerProps) => {
  const needsTimeValue =
    data.data.unit === 'hour' && (field === 'from_date_time' || field === 'to_date_time')
  const fieldValue = data.data[field]

  const [date, setDate] = useState(() => (fieldValue ? utcToLocalDate(fieldValue) : null))
  const [time, setTime] = useState(
    () => (fieldValue && getTimeFromString(fieldValue)) || '00:00',
  )

  const onSave = () => {
    const newValue = (() => {
      if (date) {
        const dateInUTC = localDateToUtc(date)

        if (needsTimeValue) {
          const newDate = new Date(dateInUTC)
          const [hours, minutes] = time.split(':')
          newDate.setUTCHours(+hours)
          newDate.setUTCMinutes(+minutes)
          return newDate.toISOString()
        }
        return dateInUTC
      }
      return undefined
    })()
    onChange(data, newValue, field)
    onClose()
  }

  return (
    <BottomSheet onClose={onClose} open>
      <Header>
        <Header.Title>{fieldToHeaderTitle[field]}</Header.Title>
      </Header>

      <InputGroup>
        <DatePickerInput label="Date" value={date} onChange={value => setDate(value)} />
        {needsTimeValue ? (
          <Input
            label="Time"
            value={time}
            onChange={e => setTime(e.currentTarget.value)}
            type="time"
            css={HideInputCalendarIndicatorCss}
            message="In UTC time"
          />
        ) : null}
      </InputGroup>

      <BottomSheet.Actions>
        <Button onClick={onSave} elevated>
          Save
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
interface ImportTimeOffRequestsDateCellProps {
  data: ImportTimeOffRequestsInterface
  field: ImportTimeOffRequestsDateField
  onChange: ImportTimeOffRequestsInputOnChange
}

const ImportTimeOffRequestDateCell = ({
  data,
  field,
  onChange,
}: ImportTimeOffRequestsDateCellProps) => {
  const [popupOpen, setPopupOpen] = useState(false)

  const fieldValue = data.data[field]
  const dateLabel = fieldValue ? utcToLocalDate(fieldValue) : null

  return (
    <>
      <CellWithError data={data} field={field}>
        <DropdownSelectCell
          label={dateLabel ? format(dateLabel, 'd MMM yyyy') : '-'}
          onClick={() => setPopupOpen(true)}
          open={false}
        />
      </CellWithError>

      {popupOpen && (
        <ImportTimeOffRequestDatePicker
          data={data}
          field={field}
          onChange={onChange}
          onClose={() => setPopupOpen(false)}
        />
      )}
    </>
  )
}

export const importTimeOffRequestsEmployeeColumn: ImportTimeOffRequestSelectInputColumn =
  (onChange, options) => ({
    type: CellTypes.insert,
    idPoint: 'employee',
    dataPoint: 'employee',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employee',
    insert: ({ data }) => (
      <ImportTimeOffRequestsSelectInput
        data={data}
        onChange={onChange}
        options={options}
        field="employee"
      />
    ),
  })

export const importTimeOffRequestsDurationColumn: ImportTimeOffRequestInputColumn = (
  onChange,
): ColumnInterface<ImportTimeOffRequestsInterface> => ({
  type: CellTypes.insert,
  idPoint: 'duration',
  dataPoint: 'duration',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Duration',
  insert: ({ data }) => <InputCell data={data} onChange={onChange} field="duration" />,
})

export const importTimeOffRequestsNoteColumn: ImportTimeOffRequestInputColumn = (
  onChange,
): ColumnInterface<ImportTimeOffRequestsInterface> => ({
  type: CellTypes.insert,
  idPoint: 'note',
  dataPoint: 'note',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Note',
  insert: ({ data }) => <InputCell data={data} onChange={onChange} field="note" />,
})

export const importTimeOffRequestsPolicyColumn: ImportTimeOffRequestSelectInputColumn = (
  onChange,
  options,
) => ({
  type: CellTypes.insert,
  idPoint: 'policy',
  dataPoint: 'policy',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Policy',
  insert: ({ data }) => (
    <ImportTimeOffRequestsSelectInput
      data={data}
      onChange={onChange}
      options={options}
      field="policy"
    />
  ),
})

export const importTimeOffRequestsFromTimePeriodColumn: ImportTimeOffRequestSelectInputColumn =
  (onChange, options) => ({
    type: CellTypes.insert,
    idPoint: 'from_time_period',
    dataPoint: 'from_time_period',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'From time period',
    insert: ({ data }) => (
      <ImportTimeOffRequestsSelectInput
        data={data}
        onChange={onChange}
        options={options}
        field="from_time_period"
      />
    ),
  })

export const importTimeOffRequestsToTimePeriodColumn: ImportTimeOffRequestSelectInputColumn =
  (onChange, options) => ({
    type: CellTypes.insert,
    idPoint: 'to_time_period',
    dataPoint: 'to_time_period',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'To time period',
    insert: ({ data }) => (
      <ImportTimeOffRequestsSelectInput
        data={data}
        onChange={onChange}
        options={options}
        field="to_time_period"
      />
    ),
  })

export const importTimeOffRequestsFromDateColumn: ImportTimeOffRequestDateColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'from_date_time',
    dataPoint: 'from_date_time',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'From date',
    insert: ({ data }) => (
      <ImportTimeOffRequestDateCell
        data={data}
        onChange={onChange}
        field="from_date_time"
      />
    ),
  })

export const importTimeOffRequestsToDateColumn: ImportTimeOffRequestDateColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'to_date_time',
    dataPoint: 'to_date_time',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'To date',
    insert: ({ data }) => (
      <ImportTimeOffRequestDateCell
        data={data}
        onChange={onChange}
        field="to_date_time"
      />
    ),
  })

export const importTimeOffRequestsApprovalStatusColumn: ImportTimeOffRequestSelectInputColumn =
  (onChange, options) => ({
    colors: data => getStatusColor(data.data.approval_status),
    type: CellTypes.insert,
    idPoint: 'approval_status',
    dataPoint: 'approval_status',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Approval status',
    insert: ({ data }) => (
      <ImportTimeOffRequestsSelectInput
        data={data}
        onChange={onChange}
        options={options}
        field="approval_status"
      />
    ),
  })

export const importTimeOffRequestsApprovalDateColumn: ImportTimeOffRequestDateColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'approval_date_time',
    dataPoint: 'approval_date_time',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Approval date',
    insert: ({ data }) => (
      <ImportTimeOffRequestDateCell
        data={data}
        onChange={onChange}
        field="approval_date_time"
      />
    ),
  })

export const importTimeOffRequestsApproverColumn: ImportTimeOffRequestSelectInputColumn =
  (onChange, options) => ({
    type: CellTypes.insert,
    idPoint: 'approver',
    dataPoint: 'approver',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Approver',
    insert: ({ data }) => (
      <ImportTimeOffRequestsSelectInput
        data={data}
        onChange={onChange}
        options={options}
        field="approver"
      />
    ),
  })

export const importTimeOffRequestsRequestedOnDateColumn: ImportTimeOffRequestDateColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'requested_on',
    dataPoint: 'requested_on',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Requested on',
    insert: ({ data }) => (
      <ImportTimeOffRequestDateCell
        data={data}
        onChange={onChange}
        field="requested_on"
      />
    ),
  })

export const importTimeOffRequestsUnitColumn: ImportTimeOffRequestSelectInputColumn = (
  onChange,
  options,
) => ({
  type: CellTypes.insert,
  idPoint: 'unit',
  dataPoint: 'unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Unit',
  insert: ({ data }) => (
    <ImportTimeOffRequestsSelectInput
      data={data}
      onChange={onChange}
      options={options}
      field="unit"
    />
  ),
})

interface DeleteRowActionProps {
  data: ImportTimeOffRequestsInterface
  onDeleteSuccess: () => void
  sessionId: string
}

const DeleteRowAction = ({ data, onDeleteSuccess, sessionId }: DeleteRowActionProps) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [deletePending, setDeletePending] = useState(false)

  const onDeleteConfirm = async () => {
    setDeletePending(true)

    try {
      await deleteImportTimeOffRequestsSessionRow(sessionId, data.id)
      setConfirmationOpen(false)
      setDeletePending(false)

      onDeleteSuccess()
    } catch {
      setDeletePending(false)
    }
  }

  return (
    <>
      <TableActionButton
        onClick={e => {
          e.stopPropagation()
          setConfirmationOpen(true)
        }}
        color={Color.RED}
      >
        Remove
      </TableActionButton>

      <ConfirmationDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={onDeleteConfirm}
        loading={deletePending}
        onReject={() => setConfirmationOpen(false)}
        yesMessage="Confirm"
        noMessage="Cancel"
        label="Are you sure you want to remove this request?"
        body=""
      />
    </>
  )
}

export const importTimeOffRequestsActionColumn = (
  onDeleteSuccess: () => void,
  sessionId: string,
): ColumnInterface<ImportTimeOffRequestsInterface> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => (
    <DeleteRowAction
      data={data}
      onDeleteSuccess={onDeleteSuccess}
      sessionId={sessionId}
    />
  ),
})
