import React from 'react'
import { Box, Text, Token, mq } from '@revolut/ui-kit'
import styled from 'styled-components'

import Icon from '@src/components/Icon/Icon'
import { Grid } from '@src/components/CommonSC/Grid'
import { Card } from '../common'

const PrideImage = styled(Box)`
  background-image: url('https://cdn.revolut.com/media/revoluters/pride.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  color: ${Token.color.widgetBackground};
  padding: 32px 120px;
  min-height: 560px;
  text-align: center;

  @media ${mq('*-lg')} {
    padding: 24px;
  }
`

const StyledGrid = styled(Grid)`
  grid-gap: 16px;

  @media ${mq('md')} {
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
`

export const Pride = () => {
  return (
    <>
      <PrideImage mt={{ all: '80px', lg: '160px' }}>
        <Text variant="h5" fontSize="h0">
          Pride @ Revolut
        </Text>
        <Text fontSize="h5" mt="s-16">
          Pride Guilds’ mission is to create a sense of community for Lesbian, Gay,
          Bisexual and Transgender (LGBT+) Revoluters and their allies.
          <br />
          <br />
          The aim is to celebrate and create an inclusive work environment where everyone
          feels confident bringing their whole selves to work. The guild also ran a full
          month of activities for the entire company during the Pride month 2021 in order
          to meaningfully celebrate Pride both internally and by engaging our external
          customers.
        </Text>
      </PrideImage>
      <StyledGrid mt={{ all: 's-16', md: 's-32' }}>
        <Card
          title="BAME"
          text={
            <>
              The Guild has been very active in active in raising and discussing ideas how
              we can support our our staff when needed; they also organise insightful
              Fireside chats and launched a company-wide Coffee Roulette to help us meet
              new Revoluters globally.
              <br />
              <br />
              The Guild has also pushed for the implementation of the Global External
              Statements policy to make sure we address impactful cultural events, and
              express support to the communities we serve.
            </>
          }
          icon={<Icon type="PrideBame" />}
        />
        <Card
          title="Women"
          text={
            <>
              The Women’s Guild welcomes everyone identifying as a woman at Revolut as
              well as allies. Besides the annual International Women’s Day and Women in
              Engineering Day events internally it also participates in engagements with
              our external customers.
              <br />
              <br />
              The Guild is active in identifying and addressing various points of
              improvement across the business; it also works closely with the Diversity
              and Inclusion function to build up the Women in Leadership program and is an
              active forum for discussions.
            </>
          }
          icon={<Icon type="PrideWomen" />}
        />
        <Card
          title="Parents"
          text={
            <>
              Parents &amp; Carers Guild provides a place for you to share experiences and
              get information on issues related to your specific family responsibilities.
              <br />
              <br />
              Whether you are expecting a child or taking care of an elder parent, this
              group is here to support you through all stages of your family life.
            </>
          }
          icon={<Icon type="PrideParents" />}
        />
        <Card
          title="Wellbeing"
          text={
            <>
              The Wellbeing Guild is a safe space and a platform for us to check in and
              grow: thanks to this Guild, we now have an internal network of Mental Health
              First Aiders in various locations across the globe.
              <br />
              <br />
              All Revoluters can also benefit from the regularly updated Wellbeing
              resources, quarterly newsletter, and attend webinars with our trainers; this
              is also where our peers choose to share their wellbeing stories to encourage
              more people to start this empowering journey.
            </>
          }
          icon={<Icon type="PrideWellbeing" />}
        />
      </StyledGrid>
    </>
  )
}
