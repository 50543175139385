import { ErrorWidget, Flex, Icon, IconName, Token, Widget } from '@revolut/ui-kit'
import { CellWithItem } from '@src/pages/Forms/GoalForm/common/CellWithItem'
import React, { PropsWithChildren } from 'react'

export const BaseWidget = (
  props: PropsWithChildren<{
    icon: IconName
    title: string
    desciption?: string
    emptyState: {
      description: string
      ctaLabel: string
      ctaHandler: () => void
      icon?: IconName
    }
  }>,
) => {
  return (
    <CellWithItem icon={props.icon} title={props.title} description={props.desciption}>
      {props.children ? (
        props.children
      ) : (
        <Widget>
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            p="s-24"
          >
            <Icon name={props.emptyState.icon} color={Token.color.greyTone20} />
            <ErrorWidget.Description color={Token.color.greyTone20}>
              {props.emptyState.description}
            </ErrorWidget.Description>
            <ErrorWidget.Action onClick={() => props.emptyState.ctaHandler()}>
              {props.emptyState.ctaLabel}
            </ErrorWidget.Action>
          </Flex>
        </Widget>
      )}
    </CellWithItem>
  )
}
