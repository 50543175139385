import React from 'react'
import { Jira } from '@revolut/icons'
import { Color, Tag } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import {
  OffboardingQueueInterface,
  OffboardingTicketsInterface,
} from '@src/interfaces/offboarding'
import { selectorKeys } from '../api'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { getStatusColor } from '@src/components/CommonSC/General'
import { getColor } from '@src/styles/colors'

export const offboardingQueueEmployeeColumn: ColumnInterface<OffboardingQueueInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.id',
    dataPoint: 'employee.full_name',
    sortKey: 'employee__full_name',
    filterKey: 'employee__id',
    selectorsKey: selectorKeys.employee,
    title: 'Employee',
    insert: ({ data }) => (
      <UserWithAvatar
        {...data.employee}
        /** To disable link to profile */
        id={undefined}
      />
    ),
  }

export const offboardingQueueSpecialisationColumn: ColumnInterface<OffboardingQueueInterface> =
  {
    type: CellTypes.text,
    idPoint: 'specialisation.id',
    dataPoint: 'specialisation.name',
    sortKey: 'position__name',
    filterKey: 'position__id',
    selectorsKey: selectorKeys.specialisations,
    title: 'Role (Specialisation)',
  }

export const offboardingQueueSeniorityColumn: ColumnInterface<OffboardingQueueInterface> =
  {
    type: CellTypes.text,
    idPoint: 'seniority.id',
    dataPoint: 'seniority.name',
    sortKey: 'seniority__name',
    filterKey: 'seniority__id',
    selectorsKey: selectorKeys.seniority,
    title: 'Seniority',
  }

export const offboardingQueueLocationColumn: ColumnInterface<OffboardingQueueInterface> =
  {
    type: CellTypes.text,
    idPoint: 'location.id',
    dataPoint: 'location.name',
    sortKey: 'location__name',
    filterKey: 'location__id',
    selectorsKey: selectorKeys.location,
    title: 'Location',
  }

export const offboardingQueueLMColumn: ColumnInterface<OffboardingQueueInterface> = {
  type: CellTypes.insert,
  idPoint: 'line_manager.id',
  dataPoint: 'line_manager.full_name',
  sortKey: 'line_manager__full_name',
  filterKey: 'line_manager__id',
  selectorsKey: selectorKeys.manager,
  title: 'LM',
  insert: ({ data }) => <UserWithAvatar {...data.line_manager} compact />,
}

export const offboardingQueueLastDayInOfficeColumn: ColumnInterface<OffboardingQueueInterface> =
  {
    type: CellTypes.dateInUtc,
    filterType: FilterType.shortDate,
    idPoint: 'termination_date_time',
    dataPoint: 'termination_date_time',
    sortKey: 'termination_date_time',
    filterKey: 'termination_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Last day in office',
  }

export const offboardingQueueLastDayOfContractColumn: ColumnInterface<OffboardingQueueInterface> =
  {
    type: CellTypes.dateInUtc,
    filterType: FilterType.shortDate,
    idPoint: 'last_day_of_contract',
    dataPoint: 'last_day_of_contract',
    sortKey: 'last_day_of_contract',
    filterKey: 'last_day_of_contract',
    selectorsKey: selectorKeys.none,
    title: 'Last day of contract',
  }

export const offboardingQueueAccessRetentionApprovalStatusColumn: ColumnInterface<OffboardingQueueInterface> =
  {
    type: CellTypes.text,
    idPoint: 'access_retention_approval_status.id',
    dataPoint: 'access_retention_approval_status.name',
    sortKey: 'access_retention_approval_status',
    filterKey: 'access_retention_approval_status',
    colors: (data, theme) =>
      data.access_retention_approval_status
        ? getStatusColor(data.access_retention_approval_status.id)
        : getColor(theme, Color.FOREGROUND),
    selectorsKey: selectorKeys.termination_access_retention_approval_statuses,
    title: 'Access extension',
  }

export const offboardingQueueStatusColumn: ColumnInterface<OffboardingQueueInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  colors: (data, theme) => {
    if (data.status.id === 'pending') {
      return getColor(theme, Color.ORANGE)
    }
    if (data.status.id === 'completed') {
      return getColor(theme, Color.LIGHT_GREEN)
    }
    return getColor(theme, Color.FOREGROUND)
  },
  selectorsKey: selectorKeys.termination_statuses,
  title: 'Offboarding status',
}

export const offboardingQueueHRManagerColumn: ColumnInterface<OffboardingQueueInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'hr_manager.id',
    dataPoint: 'hr_manager.full_name',
    sortKey: 'hr_manager__full_name',
    filterKey: 'hr_manager__id',
    selectorsKey: selectorKeys.employee,
    title: 'HR Manager',
    insert: ({ data }) => (
      <UserWithAvatar
        {...data.hr_manager}
        /** To disable link to profile */
        id={undefined}
      />
    ),
  }

export const offboardingTicketsIssueColumn: ColumnInterface<OffboardingTicketsInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: 'key',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Issue ID',
    insert: ({ data }) => (
      <Tag variant="faded" useIcon={Jira}>
        {data.key}
      </Tag>
    ),
  }

export const offboardingTicketsTitleColumn: ColumnInterface<OffboardingTicketsInterface> =
  {
    type: CellTypes.text,
    idPoint: 'title',
    dataPoint: 'title',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Title',
  }

export const offboardingTicketsDescriptionColumn: ColumnInterface<OffboardingTicketsInterface> =
  {
    type: CellTypes.text,
    idPoint: 'description',
    dataPoint: 'description',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Description',
  }

export const offboardingTicketsOwnerColumn: ColumnInterface<OffboardingTicketsInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'assignee.id',
    dataPoint: 'assignee.name',
    sortKey: 'assignee__full_name',
    filterKey: 'assignee__id',
    selectorsKey: selectorKeys.employee,
    title: 'Owner',
    insert: ({ data }) => <UserWithAvatar {...data.assignee} />,
  }

export const offboardingTicketsStatusColumn: ColumnInterface<OffboardingTicketsInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status_category.id',
    dataPoint: 'status_category.name',
    sortKey: 'status_category',
    filterKey: 'status_category',
    selectorsKey: selectorKeys.jira_status_categories,
    colors: (data, theme) => {
      switch (data.status_category.id) {
        case 'Done':
          return theme.colors.green
        case 'In Progress':
        case 'To Do':
          return theme.colors.orange
        default:
          return theme.colors.foreground
      }
    },
    title: 'Status',
  }
