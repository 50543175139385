import React from 'react'
import RoadmapForm from './RoadmapForm'
import { roadmapsRequestsNew } from '@src/api/roadmaps'
import Form from '@src/features/Form/Form'

export const Roadmap = () => {
  return (
    <Form api={roadmapsRequestsNew}>
      <RoadmapForm />
    </Form>
  )
}

export default Roadmap
