import React from 'react'
import { Modal } from '../Modal'
import styled, { keyframes } from 'styled-components'
import Icon from '../../Icon/Icon'
import { colorGetter } from '@src/styles/colors'
import { Color, Token } from '@revolut/ui-kit'

const OpacityAppear = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`

const ZoomAppear = keyframes`
  0% {
    transform: scale(0.6)
  }
  
  100% {
    transform: scale(1)
  }
`

const Curtain = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  animation-name: ${OpacityAppear};
  animation-fill-mode: forwards;
  animation-duration: 200ms;
  animation-timing-function: ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
`

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

const DialogWindow = styled.div<{ windowPadding?: string; windowMaxWidth?: string }>`
  background-color: ${Token.color.widgetBackground};
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  pointer-events: all;
  padding: ${props => props.windowPadding || '40px 50px'};
  animation-name: ${ZoomAppear};
  animation-fill-mode: forwards;
  animation-duration: 100ms;
  animation-timing-function: ease-in-out;
  position: relative;
  max-width: ${props => props.windowMaxWidth || 'auto'};
`

const Content = styled.div`
  opacity: 0;
  animation-name: ${OpacityAppear};
  animation-fill-mode: forwards;
  animation-delay: 100ms;
  animation-duration: 100ms;
  animation-timing-function: ease-in-out;
`

const Wrapper = styled.div<{ allowAbovePopup: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${props => (props.allowAbovePopup ? 'auto' : props.theme.zIndex.popup)};
`

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: ${colorGetter(Color.GREY_TONE_50)};
  z-index: ${props => props.theme.zIndex.main};

  &:hover {
    color: ${colorGetter(Color.BLACK_OPAQUE_90)};
  }
`

interface DialogProps {
  /** Inside content of the Dialog popup */
  children: React.ReactNode
  /** Callback for closing request */
  onClose?: () => void
  className?: string
  /** Open/Close trigger */
  open: boolean
  showCloseButton?: boolean
  allowAbovePopup?: boolean
  windowPadding?: string
  windowMaxWidth?: string
}

const Dialog = ({
  open,
  onClose,
  children,
  className,
  showCloseButton,
  windowPadding,
  windowMaxWidth,
  allowAbovePopup = false,
}: DialogProps) => {
  if (!open) {
    return null
  }

  return (
    <Modal>
      <Wrapper allowAbovePopup={allowAbovePopup}>
        <Curtain data-testid="dialog-curtain" onClick={onClose} />
        <Container>
          <DialogWindow
            className={className}
            windowPadding={windowPadding}
            windowMaxWidth={windowMaxWidth}
          >
            {showCloseButton && (
              <CloseButton type="button" onClick={onClose}>
                <Icon type="Close" size="tiny" />
              </CloseButton>
            )}
            <Content>{children}</Content>
          </DialogWindow>
        </Container>
      </Wrapper>
    </Modal>
  )
}

export default Dialog
