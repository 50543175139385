import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Text, Button, InputGroup, Widget, Token } from '@revolut/ui-kit'
import { EyeHide, EyeShow } from '@revolut/icons'

import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { StartPageView } from '@src/pages/Onboarding/common'
import SideBar from '@components/SideBar/SideBar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { LifecycleSettingsInterface } from '@src/interfaces/settings'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'

export const LifecycleWelcome = () => {
  const permissions = useSelector(selectPermissions)
  const { values } = useLapeContext<LifecycleSettingsInterface>()

  const [showPreview, setShowPreview] = useState(true)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeLifecycleSettings)

  return (
    <>
      <SectionTitle
        title="How do you want to greet your employees on the onboarding welcome page?"
        subtitle="This will be the first page the employee views when they land on the onboarding portal. It usually contains a presentation of the company and some details about the onboarding process."
      />
      <Button
        onClick={() => setShowPreview(!showPreview)}
        variant="secondary"
        size="sm"
        mb="s-16"
        useIcon={showPreview ? EyeHide : EyeShow}
      >
        {showPreview ? 'Hide preview' : 'Show preview'}
      </Button>
      <Widget p="s-16" mb="s-64">
        <InputGroup>
          <LapeNewInput
            label="Welcome page title"
            name="welcome_page_title"
            message="This will be the title of the welcome page. The first page the employee will see landing on the onboarding page."
            required
            disabled={disableEdit}
          />
          <LapeNewInput
            label="Welcome page video URL"
            name="welcome_page_video_url"
            message="This will be used as the onboarding video for new joiners."
            disabled={disableEdit}
          />
          <LapeHTMLEditor
            placeholder="Welcome page text"
            name="welcome_page_text"
            message="This will be the content displayed on the welcome page"
            required
            height={300}
            readOnly={disableEdit}
          />
        </InputGroup>
      </Widget>
      <SideBar
        title={<Text color={Token.color.greyTone20}>Preview</Text>}
        onClose={() => setShowPreview(false)}
        isOpen={showPreview}
        variant="wide"
      >
        <StartPageView
          title={values.welcome_page_title}
          videoUrl={values.welcome_page_video_url}
          text={values.welcome_page_text}
          name="{employee_name}"
          joiningDateTime={new Date().toISOString()}
        />
      </SideBar>
    </>
  )
}
