import { Shield, Switches } from '@revolut/icons'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'

export const companyPreferencesItemConfig = {
  path: ROUTES.SETTINGS.GENERAL.PREVIEW,
  title: 'Company preferences',
  description: 'Company details, company logo and more',
  icon: Switches,
  canView: [
    PermissionTypes.ViewCompanySettings,
    PermissionTypes.CanManageCompanyLogo,
    PermissionTypes.ChangeCompanyPreferences,
  ],
}

export const securitySettingsItemConfig = {
  path: ROUTES.SETTINGS.SECURITY,
  title: 'Security settings',
  description: 'Login option, 2-factor authentication and single sign on',
  icon: Shield,
  canView: [PermissionTypes.ViewSecuritySettings],
}
