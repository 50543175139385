import React, { useMemo, useState } from 'react'
import { Bar, Flex, MoreBar, Widget } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import SearchTable from '@components/Table/SearchTable/SearchTable'
import {
  FetchDataQueryInterface,
  FilterByInterface,
  RowInterface,
  SortByInterface,
  SORT_DIRECTION,
  Stats,
} from '@src/interfaces/data'
import { requisitionsRequests } from '@src/api/requisitions'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { Statuses } from '@src/interfaces'
import {
  requisitionCandidatesColumn,
  requisitionHeadcountColumn,
  requisitionIdColumn,
  requisitionPotentialStartDateColumn,
  requisitionQueuePosition,
  requisitionStatusColumn,
  createRequisitionTitleColumn,
} from '@src/constants/columns/requisition'
import { specialisationRoleNameColumn } from '@src/constants/columns/role'
import { teamDepartmentColumn, teamNameColumn } from '@src/constants/columns/team'
import { seniorityNameRequisitionsColumn } from '@src/constants/columns/seniority'
import { locationNameRequisitionColumn } from '@src/constants/columns/location'
import { lineManagerColumn, recruiterNameColumn } from '@src/constants/columns/employee'
import ReferCandidateButton from '@components/ReferCandidateButton/ReferCandidateButton'
import AddRequisitionButton from '@src/features/CommonRequisitionTable/AddRequisitionButton'
import { TableNames } from '@src/constants/table'
import { withFavourites } from '../FavouritesFilter/withFavourites'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'
import { useTable } from '@components/Table/hooks'
import ShowConfidentialFilter, { getConfidentialFilter } from './ShowConfidentialFilter'
import { useGetRequisitionSettings } from '@src/api/settings'
import { TalentType } from '@src/interfaces/talent/talent'
import { AllowedExportMenu } from '@src/features/ExportMenu/AllowedExportMenu'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { ViewReferrals } from '@src/pages/EmployeeProfile/Preview/components/Buttons/Actions/ViewReferrals'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useIsNewOrgLayout } from '@src/pages/Team/helpers'

export const requisitionSortBy = [
  {
    sortBy: 'pipeline_queue_position',
    direction: SORT_DIRECTION.DESC,
  },
  {
    sortBy: 'seniority__level',
    direction: SORT_DIRECTION.ASC,
  },
  {
    sortBy: 'priority',
    direction: SORT_DIRECTION.DESC,
  },
]

type Type =
  | 'team'
  | 'department'
  | 'main'
  | 'role'
  | 'function'
  | 'employee'
  | 'jobPosting'

type Props = {
  filterBy?: FilterByInterface[]
  sortBy?: SortByInterface[]
  statsData?: FetchDataQueryInterface
  newItemInitialValues?: Partial<RequisitionInterface>
  type: Type
  enableFavourites?: boolean
  navigation?: React.ReactElement
  employee?: EmployeeInterface
}

const ROW = (isConfidential: boolean): RowInterface<RequisitionInterface> => ({
  linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id })),
  disabled: data => data.status === Statuses.rejected || data.status === Statuses.closed,
  cells: [
    {
      ...requisitionIdColumn,
      width: 90,
    },
    {
      ...createRequisitionTitleColumn(isConfidential),
      width: 200,
    },
    {
      ...specialisationRoleNameColumn,
      width: 160,
    },
    {
      ...teamDepartmentColumn,
      width: 130,
    },
    {
      ...teamNameColumn,
      width: 130,
    },
    {
      ...seniorityNameRequisitionsColumn,
      width: 100,
    },
    {
      ...locationNameRequisitionColumn,
      width: 100,
    },
    {
      ...lineManagerColumn,
      width: 130,
    },
    {
      ...requisitionHeadcountColumn,
      width: 100,
    },
    {
      ...recruiterNameColumn,
      width: 130,
    },
    {
      ...requisitionPotentialStartDateColumn,
      width: 130,
    },
    {
      ...requisitionQueuePosition,
      width: 100,
    },
    {
      ...requisitionCandidatesColumn,
      width: 80,
    },
    {
      ...requisitionStatusColumn,
      width: 100,
    },
  ],
})

type UseRequisitionReturnType = {
  filterBy?: FilterByInterface[]
  sortBy?: SortByInterface[]
  statsData?: FetchDataQueryInterface
  isConfidential?: boolean
}

export const useRequisitionTable = ({
  filterBy = [],
  sortBy,
  statsData,
}: UseRequisitionReturnType) => {
  const table = useTable<RequisitionInterface, Stats>(
    {
      ...requisitionsRequests,
      getStats: fetchQuery =>
        requisitionsRequests.getStats!({
          ...(statsData ?? {}),
          ...fetchQuery,
        }),
    },
    filterBy,
    sortBy,
  )
  return table
}

const CommonRequisitionTable = ({
  filterBy = [],
  sortBy,
  statsData,
  newItemInitialValues = {},
  type,
  enableFavourites = false,
  navigation,
  employee,
}: Props) => {
  const [isConfidential, setIsConfidential] = useState(false)
  const initialFilterBy = useMemo(() => [...filterBy, getConfidentialFilter(false)], [])
  const table = useRequisitionTable({
    filterBy: initialFilterBy,
    sortBy,
    statsData: {
      ...(statsData ?? {}),
      filters: [...(statsData?.filters ?? []), getConfidentialFilter(isConfidential)],
    },
  })
  const { data: requisitionSettings } = useGetRequisitionSettings()
  const { FavouritesFilter } = useFavouritesFilter('requisition')
  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)
  const isNewLayout = useIsNewLayout()
  const isNewOrgLayout = useIsNewOrgLayout()

  const isHiringColumnsEnabled = requisitionSettings?.enable_table_hiring_fields

  const hiddenCells: Partial<Record<keyof RequisitionInterface, boolean>> = {
    [requisitionPotentialStartDateColumn.idPoint]: !isHiringColumnsEnabled,
    [requisitionQueuePosition.idPoint]: !isHiringColumnsEnabled,
    [requisitionCandidatesColumn.idPoint]: !isHiringColumnsEnabled,
    [teamDepartmentColumn.idPoint]: type !== 'function',
    [teamNameColumn.idPoint]: type === 'team',
    [lineManagerColumn.idPoint]: type === 'employee',
  }

  return (
    <Widget display="flex" p="s-16" width="100%">
      <Flex flexDirection="column" width="100%">
        {((isNewLayout && type === 'employee') ||
          (isNewOrgLayout && (type === 'team' || type === 'department'))) &&
          navigation}
        <Flex flexWrap="wrap" justifyContent="space-between" mb="s-24">
          <Bar>
            <Stat
              mr="s-16"
              label="Total Headcount"
              val={table?.stats?.requisition_total_headcount}
            />
            <Stat
              label="Remaining Headcount"
              val={table?.stats?.requisition_remaining_headcount}
            />
          </Bar>
          <Bar>
            <SearchTable
              placeholder="Search by title, team, or specialisation"
              onFilter={table.onFilterChange}
              ml={0}
            />
            <ShowConfidentialFilter
              disabled={table.loading}
              onClick={showConfidential => {
                setIsConfidential(showConfidential)
                table.onFilterChange(getConfidentialFilter(showConfidential))
              }}
            />
          </Bar>
        </Flex>
        <Flex mb="s-16" justifyContent="space-between">
          <MoreBar>
            <AddRequisitionButton newItemInitialValues={newItemInitialValues} />
            <ReferCandidateButton />
            {employee && <ViewReferrals data={employee} />}
            <AllowedExportMenu
              fileName="Requisitions"
              request={requisitionsRequests.getExport}
              filterQuery={filterQuery}
              type={type as TalentType}
            />
          </MoreBar>
          {enableFavourites ? (
            <Bar>
              <FavouritesFilter table={table} />
            </Bar>
          ) : null}
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<RequisitionInterface>
            name={TableNames.CommonRequisition}
            useWindowScroll
            dataType="Requisition"
            row={ROW(isConfidential)}
            hiddenCells={hiddenCells}
            {...table}
            noDataMessage="Requisitions will appear here."
          />
        </Flex>
      </Flex>
    </Widget>
  )
}

export default withFavourites(CommonRequisitionTable)
