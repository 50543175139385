import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@src/styles/theme'
import { Colored } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'
import Icon from '@components/Icon/Icon'
import Tooltip from '@components/Tooltip/Tooltip'

const LockTooltip = styled(Tooltip)`
  justify-content: flex-start;
  width: 20px;
`

const GradeLockIcon = () => {
  const theme = useTheme()
  return (
    <LockTooltip
      placement="top"
      text="You can't see your own grades before the publishing date"
    >
      <Colored color={getColor(theme, Color.GREY_20_OPAQUE_90)}>
        <Icon type="Lock" size="small" />
      </Colored>
    </LockTooltip>
  )
}

export default GradeLockIcon
