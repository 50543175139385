import React from 'react'
import { useTable } from '@components/Table/hooks'
import {
  InterviewFeedbackStatus,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'
import { getInterviewFeedbacks } from '@src/api/recruitment/interviews'
import { FilterByInterface, RowInterface, SortByInterface } from '@src/interfaces/data'
import {
  interviewCandidate,
  interviewDateColumn,
  interviewNameColumn,
  interviewRatingColumn,
  interviewRoleSeniority,
  interviewScorecardColumn,
  interviewStatus,
} from '@src/constants/columns/interviews'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { Cell, Flex, Token } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'

const rows: RowInterface<InterviewFeedbackInterface> = {
  highlight: data => {
    if (
      data.status === InterviewFeedbackStatus.pending ||
      data.status === InterviewFeedbackStatus.draft
    ) {
      return Token.color.orange_5
    }
    return ''
  },
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, {
        id: data.id,
      }),
    ),
  cells: [
    {
      ...interviewStatus,
      width: 140,
    },
    {
      ...interviewCandidate,
      width: 290,
    },
    {
      ...interviewRoleSeniority,
      width: 300,
    },
    {
      ...interviewNameColumn,
      width: 160,
    },
    {
      ...interviewDateColumn,
      width: 220,
    },
    {
      ...interviewRatingColumn,
      width: 120,
    },
    {
      ...interviewScorecardColumn,
      width: 150,
    },
  ],
}

interface Props {
  filterBy: FilterByInterface[]
  sortBy: SortByInterface[]
}

const Interviews = ({ filterBy, sortBy }: Props) => {
  const table = useTable<InterviewFeedbackInterface>(
    { getItems: getInterviewFeedbacks },
    filterBy,
    sortBy,
  )

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<InterviewFeedbackInterface>
            name={TableNames.Interviews}
            useWindowScroll
            dataType="Interviews"
            row={rows}
            {...table}
            noDataMessage="Interviews will appear here."
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default Interviews
