import React, { useEffect, useState } from 'react'
import { Box } from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { useParams } from 'react-router-dom'
import { getCandidate } from '@src/api/recruitment/interviews'
import { ArchiveButton, useArchiving } from '@src/pages/Forms/ArchiveOpportunity/common'
import ArchiveOpportunityContent from '@src/pages/Forms/ArchiveOpportunity/General/ArchiveOpportunityContent'

const General = () => {
  const params = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const backUrl = pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
    id: params.id,
  })

  const { reason, setReason, setComments, setRoundId, sendEmail } = useArchiving()

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const { data } = await getCandidate(params.id)
      const roundData = data.active_interview_round

      if (roundData) {
        setRoundId(data.active_interview_round?.id)
        if (roundData.archived_comment) {
          setComments(roundData.archived_comment)
        }
        if (roundData.archived_reason) {
          setReason(roundData.archived_reason)
        }
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [params.id])

  return (
    <PageWrapper>
      <PageHeader
        title="Archive opportunity"
        subtitle="This will also cancel all the scheduled interviews for the archived opportunity"
        backUrl={backUrl}
      />
      <PageBody>
        <Box mb="s-32">
          <ArchiveOpportunityContent isLoading={isLoading} />
        </Box>
      </PageBody>
      <PageActions>
        <ArchiveButton
          candidateId={+params.id}
          disabled={!reason || isLoading}
          pending={isLoading}
          sendEmail={sendEmail}
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(General)
