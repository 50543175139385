import React from 'react'
import { Item, List, Spacer, Text, Token } from '@revolut/ui-kit'
import { Dot } from '@revolut/icons'

const CandidatesIntro = () => {
  return (
    <Item>
      <Item.Content>
        <Item.Title>What are candidates?</Item.Title>
        <Item.Description>
          <Text use="p">
            Each candidate follows their own distinct journey. Track their individual
            paths and witness your recruitment success story come to life.
          </Text>
          <Spacer height={16} />
          <Text use="p">
            With <Text fontWeight={500}>Revolut People Recruitment</Text>, candidates can
            be sourced, added by your internal recruitment team, or emerge through
            applications to your custom job postings. The moment their information is
            captured, an interview round is created, linking the candidate to a specific
            specialisation and its corresponding hiring process.
          </Text>
          <Spacer height={16} />
          <Text use="p">
            All of that essential data is consolidated on the candidate profile, enabling
            you to track candidate’s progress and jump into action at every stage of the
            recruitment process!
          </Text>
          <Text use="p">Within each profile, you’ll find:</Text>
          <List variant="compact">
            <List.Item useIcon={Dot} color={Token.color.greyTone50}>
              <Text fontWeight={500}>Candidate level data:</Text> Unique to each
              candidate, personal information and details about their previous experiences
            </List.Item>
            <List.Item useIcon={Dot} color={Token.color.greyTone50}>
              <Text fontWeight={500}>Interview Round Level Data:</Text> Distinctive
              details tied to a specific recruitment pipeline.
            </List.Item>
          </List>
          <Spacer height={16} />
          <Text use="p">
            It's essential to note that a candidate can participate in multiple pipelines
            concurrently (meaning they can apply for several roles within the company).
            However, only one interview round is designated as the "main" and remains
            active at any given moment.
          </Text>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

export default CandidatesIntro
