import * as React from 'react'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { Box, Input, InputProps } from '@revolut/ui-kit'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import { useNewChangelogProps } from '@src/utils/form'
import { getDateFromString, getTime } from '@src/utils/timezones'
import { useFormValidator } from '@src/features/Form/FormValidator'

const getFallbackDate = () => format(new Date(), 'yyyy-MM-dd')

const validateTime = (time: string) => {
  const hoursMinutes = time.split(':')
  const INVALID_TIME = 'Invalid time format'
  if (hoursMinutes.length !== 2) {
    return INVALID_TIME
  }
  if (parseInt(hoursMinutes[0], 10) > 23) {
    return INVALID_TIME
  }
  if (parseInt(hoursMinutes[1], 10) > 59) {
    return INVALID_TIME
  }
  return undefined
}

interface Props extends Omit<InputProps, 'value' | 'onChange'> {
  name: string
  value?: string
  disabled?: boolean
  onChange?: (e: FormEvent<HTMLInputElement>) => void
  onAfterChange?: (e: ChangeEvent<HTMLInputElement>) => void
  /** Marks field as optional otherwise */
  required?: boolean
  /** Hides the optional text for display only fields */
  hideOptional?: boolean
  hasError?: boolean
}

const LapeNewTimeInput = ({ name, hideOptional, onAfterChange, ...props }: Props) => {
  const lapeProps = useLapeField(name)
  const formValidator = useFormValidator()
  const changelog = useNewChangelogProps(name)
  const { value, onChange, hidden } = lapeProps

  const [internalValue, setInternalValue] = useState<string>('')
  const [error, setError] = useState<string>()

  useEffect(() => {
    if (value) {
      const valueToDate = new Date(value.replace(/([+,-])\d{2}:\d{2}(:\d{2})?|(Z)/g, ''))
      setInternalValue(getTime(valueToDate))
    }
  }, [value])

  if (hidden) {
    return null
  }

  const commonInputProps = getCommonInputProps(
    { ...props, hideOptional, name },
    lapeProps,
    !!formValidator?.validated,
  )

  return (
    <Input
      {...props}
      renderAction={(...args) => (
        <>
          {props.renderAction && <Box mr="s-8">{props.renderAction(...args)}</Box>}
          {changelog.renderAction && changelog.renderAction()}
        </>
      )}
      type="time"
      onKeyPress={e => {
        const badSymbol = e.key !== ':'
        const isNotNumber = isNaN(parseInt(e.key, 10))
        if (badSymbol && isNotNumber) {
          e.preventDefault()
        }
      }}
      onChange={(e: FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value
        const err = validateTime(val)
        setError(err)
        setInternalValue(val)

        if (!err) {
          onChange(`${getDateFromString(value) || getFallbackDate()}T${val}`)
        }
      }}
      value={internalValue}
      {...commonInputProps}
      message={commonInputProps.message || error}
    />
  )
}

export default connect(LapeNewTimeInput)
