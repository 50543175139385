import React from 'react'
import { Delete, LikeDislike, Paragraph } from '@revolut/icons'
import { Avatar, Color, DragAndDrop, IconButton, Item, VStack } from '@revolut/ui-kit'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import { getQuestionDescription } from '@src/apps/People/Engagement/helpers'
import DragIcon from '@src/components/DragIcon'

type Props = {
  question: EngagementQuestionInterface
  onDelete?: () => void
  sortable?: DragAndDrop.DefaultSortableItemState<{}>
}
export const QuestionItem = ({ question, onDelete, sortable }: Props) => {
  return (
    <VStack>
      <Item
        p="s-8"
        ref={sortable?.setNodeRef}
        {...sortable?.attributes}
        {...sortable?.listeners}
        style={
          sortable
            ? {
                transform: sortable.transform
                  ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                  : undefined,
                transition: sortable.transition || 'none',
                opacity: sortable.isDragging ? 0 : undefined,
                zIndex: 10,
              }
            : undefined
        }
      >
        <Item.Prefix>
          <DragIcon />
        </Item.Prefix>
        <Item.Avatar>
          <Avatar
            useIcon={question.type.id === 'open_ended' ? <Paragraph /> : <LikeDislike />}
          />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{question.question_text}</Item.Title>
          <Item.Description>{getQuestionDescription(question)}</Item.Description>
        </Item.Content>
        <Item.Side>
          <IconButton useIcon={Delete} color={Color.GREY_TONE_10} onClick={onDelete} />
        </Item.Side>
      </Item>
    </VStack>
  )
}
