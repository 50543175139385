import React from 'react'
import { InputGroup, Button, Popup, Header } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { EditQuestion } from './EditQuestion'
import SideBar from '@components/SideBar/SideBar'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { selectorKeys } from '@src/constants/api'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { OptionInterface } from '@src/interfaces/selectors'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

type EditSectionProps = {
  idx: number | null
  onClose: () => void
  onSave?: () => Promise<void>
  renderInPopup?: boolean
}

export const EditSection = ({
  idx,
  onClose,
  onSave,
  renderInPopup = false,
}: EditSectionProps) => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const showForFetchState = useFetchOptions<OptionInterface>(
    selectorKeys.custom_scorecard_sections_show_options,
  )
  const section = idx === null ? undefined : values.custom_scorecard_sections?.[idx]
  const showForOptions = performanceSettings
    ? showForFetchState.options.filter(option => {
        return performanceSettings.enable_functional_management
          ? true
          : ![
              'performance_fm_scorecards',
              'probation_fm_scorecards',
              'pip_fm_scorecards',
            ].includes(String(option.value.id))
      })
    : []

  if (idx === null || !section) {
    return null
  }

  const onClickAddQuestion = () => {
    section.questions.push({ question_type: null, options: [] })
  }

  const renderSave = () => {
    const button = <NewSaveButtonWithPopup onClick={onSave}>Save</NewSaveButtonWithPopup>
    return renderInPopup ? <Popup.Actions>{button}</Popup.Actions> : button
  }

  const form = (
    <>
      <InputGroup>
        <LapeNewInput
          name={`custom_scorecard_sections.${idx}.title`}
          label="Section title"
          required
        />
        <LapeNewInput
          name={`custom_scorecard_sections.${idx}.description`}
          label="Section description"
        />
        <LapeNewMultiSelect
          placeholder="Show for"
          name={`custom_scorecard_sections.${idx}.show_for`}
          required
          options={showForOptions}
        />
      </InputGroup>
      {section.questions.map((_, questionIdx) => (
        <EditQuestion key={questionIdx} sectionIdx={idx} questionIdx={questionIdx} />
      ))}
      <Button
        useIcon={Plus}
        variant="secondary"
        size="sm"
        onClick={onClickAddQuestion}
        mt="s-16"
        mb="s-40"
      >
        Add question
      </Button>
      {}
      {!!onSave && renderSave()}
    </>
  )

  const currentScorecard =
    !!values.custom_scorecard_sections && values.custom_scorecard_sections[idx]
  const isNew = !!currentScorecard && !currentScorecard.id

  return renderInPopup ? (
    <Popup open onClose={onClose} size="md">
      <Header displayMode="inline">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>
          {isNew
            ? 'Add new scorecard questions section'
            : 'Edit scorecard questions section'}
        </Header.Title>
      </Header>
      {form}
    </Popup>
  ) : (
    <SideBar
      variant="wide"
      isOpen
      onClose={onClose}
      title="Edit section"
      sideProps={{ resizable: true }}
    >
      {form}
    </SideBar>
  )
}
