import React from 'react'
import Icon from '../../Icon/Icon'
import Tooltip from '../../Tooltip/Tooltip'
import styled from 'styled-components'
import { KpiInterface } from '../../../interfaces/kpis'

export interface Props {
  data: KpiInterface
}

const Warning = styled.div`
  display: grid;
  align-items: center;
  justify-content: start;
  color: ${props => props.theme.colors.red};
`
const Checkmark = styled.div`
  display: grid;
  align-items: center;
  justify-content: start;
  color: ${props => props.theme.colors.green};
`
const Align = styled.span`
  padding: 0 5px;
`

const KPIWarning = ({ data }: Props) => {
  if (data.id === -1) {
    return null
  }
  if (data.extra && data.extra.error) {
    return (
      <Warning>
        <Tooltip placement="left" text={data.extra.error}>
          <Icon type="WarningTriangle" size="tiny" />
        </Tooltip>
      </Warning>
    )
  }
  if (!data.sql_query) {
    return <Align>-</Align>
  }
  return (
    <Checkmark>
      <Icon type="Check" size="tiny" />
    </Checkmark>
  )
}

export default KPIWarning
