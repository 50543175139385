import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  FinalGrade,
  KeeperGrade,
  ManagerRecommendationInterface,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { RadioButton } from '@components/Inputs/RadioButtons/RadioButtons'
import { getListIndicator, ListType } from '@src/utils'
import { selectorKeys } from '@src/constants/api'
import BottomText from '@components/Inputs/partials/BottomText'
import Tooltip from '@components/Tooltip/Tooltip'
import { colorGetter } from '@src/styles/colors'
import { Box, Color, Flex, Skeleton, Text, Widget, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import AutoStepper from '@components/Stepper/AutoStepper'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import RecommendationGrade from '@src/pages/Forms/EmployeePerformance/components/RecommendationGrade'
import { getSelectors } from '@src/api/selectors'
import { ScorecardRecommendationBanner } from '@components/ScorecardRecommendation/ScorecardRecommendationBanners'
import { useLocation, useParams } from 'react-router-dom'
import { RadioTooltip } from '@components/ScorecardGeneral/ScorecardRadioSection'
import RecommendationSummary from '@src/pages/Forms/EmployeePerformance/RecommendationSummary'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { IdAndName } from '@src/interfaces'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import ExpandableText from '@components/ExpandableText/ExpandableText'

const Label = styled.div`
  padding: 13px;
`

const LabelText = styled.div`
  line-height: 20px;
  padding-left: 15px;
  text-transform: none;
  color: ${props => props.theme.colors.foreground};
`

const Letter = styled.span`
  margin-left: -15px;
  color: ${colorGetter(Color.GREY_TONE_50)};
`

const Amount = styled.div`
  position: absolute;
  font-size: 12px;
  left: -11px;
  top: 14px;
  color: ${props => props.theme.colors.foreground};
`

export type RecommendationType = 'lm' | 'fm' | 'peer' | 'pip'

interface ListInterface {
  title: string
  items: {
    text: string
  }[]
}

export const getJustificationViewList = (reviews: ManagerRecommendationInterface[]) => {
  return reviews.reduce((ids: ListInterface[], review) => {
    if (review.employee_project_performance.justification) {
      const checkpointNum = review.checkpoint_number
      const title = review.reviewer?.full_name
        ? `${review.reviewer?.full_name}${checkpointNum ? ` (CP${checkpointNum})` : ''}`
        : ''
      ids.push({
        items: [
          {
            text: review.employee_project_performance.justification,
          },
        ],
        title,
      })
    }
    return ids
  }, [])
}

interface Props {
  data: ManagerRecommendationInterface
  reviews?: ManagerRecommendationInterface[]
  viewMode?: boolean
  type?: RecommendationType
  lmData?: ManagerRecommendationInterface
  fmData?: ManagerRecommendationInterface
  peersData?: ManagerRecommendationInterface[]
  pipData?: ManagerRecommendationInterface[]
}

const ScorecardRecommendation = ({
  data,
  reviews,
  viewMode = false,
  type,
  lmData,
  fmData,
  peersData,
  pipData,
}: Props) => {
  const user = useSelector(selectUser)
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()
  const { hash } = useLocation()
  const topRef = useRef<HTMLDivElement>(null)
  const bottomRef = useRef<HTMLDivElement>(null)

  const tooltipAnchor = useRef<HTMLDivElement[]>([])
  const form = useLapeContext<ReviewScorecardInterface>()
  const { errors, submitFailed, change } = form
  const [options, setOptions] = useState<RadioSelectOption<IdAndName<string>>[]>([])
  const performanceOptions = data.employee_project_performance.options

  useEffect(() => {
    if (hash === '#a') {
      topRef?.current?.scrollIntoView()
    }
    if (hash === '#b') {
      bottomRef?.current?.scrollIntoView()
    }
  }, [hash, options.length])

  useEffect(() => {
    const fetchOptions = async () => {
      const result = await getSelectors(selectorKeys.yes_no_range_options)
      if (result && result.data) {
        const opts = result.data.options.map(value => ({
          label: value.name,
          value: {
            ...value,
            id: String(value.id),
          },
        }))
        setOptions(opts)
      }
    }

    fetchOptions()
  }, [])

  useEffect(() => {
    // We should reset submitFailed state when we enter this page to avoid showing validation errors
    if (submitFailed) {
      form.submitFailed = false
    }
  }, [])

  const scrollRef = useRef<HTMLTextAreaElement>(null)
  useEffect(() => {
    if (
      type === 'pip' &&
      errors.review_data?.pip_extra_section?.employee_project_performance
    ) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [errors.review_data])

  const onRadioChange = (value: FinalGrade) => {
    let key

    switch (type) {
      case 'lm':
        key = 'review_data.line_manager_extra_section.employee_project_performance.value'
        break

      case 'fm':
        key =
          'review_data.functional_manager_extra_section.employee_project_performance.value'
        break

      case 'peer':
        key = 'review_data.peer_extra_section.employee_project_performance.value'
        break

      case 'pip':
        key = 'review_data.pip_extra_section.employee_project_performance.value'
        break
    }

    if (key) {
      change(key, value)
    }
  }

  const onKeeperChange = (value: string, num: number) => {
    let key

    switch (type) {
      case 'lm':
        key = `review_data.line_manager_extra_section.keeper_test_section.questions.${num}.value`
        break

      case 'fm':
        key = `review_data.functional_manager_extra_section.keeper_test_section.questions.${num}.value`
        break

      case 'peer':
        key = `review_data.peer_extra_section.keeper_test_section.questions.${num}.value`
        break

      case 'pip':
        key = `review_data.pip_extra_section.pip_recommendation.${num}.value`
        break
    }

    if (key) {
      change(key, value)
    }
  }

  if (!options.length) {
    return (
      <Widget p="s-16" width="100%" maxWidth={624} height={120} mt="s-16">
        <Skeleton height={16} mb="s-16" />
        <Skeleton height={16} mb="s-16" />
        <Skeleton height={16} mb="s-16" />
      </Widget>
    )
  }

  const renderError = () => {
    if (!submitFailed) {
      return null
    }

    let isError = false

    switch (type) {
      case 'lm': {
        if (
          errors.review_data?.line_manager_extra_section?.employee_project_performance
            ?.value
        ) {
          isError = true
        }
        break
      }

      case 'fm': {
        if (
          errors.review_data?.functional_manager_extra_section
            ?.employee_project_performance?.value
        ) {
          isError = true
        }
        break
      }

      case 'peer': {
        if (errors.review_data?.peer_extra_section?.employee_project_performance?.value) {
          isError = true
        }
        break
      }

      case 'pip': {
        if (errors.review_data?.pip_extra_section?.employee_project_performance?.value) {
          isError = true
        }
        break
      }
    }

    if (!isError) {
      return null
    }

    return <BottomText error="Please select one of the options" />
  }
  const renderKeeperError = () => {
    if (!submitFailed) {
      return null
    }

    const errorsByTypes = {
      lm: errors.review_data?.line_manager_extra_section?.keeper_test_section,
      fm: errors.review_data?.functional_manager_extra_section?.keeper_test_section,
      peer: errors.review_data?.peer_extra_section?.keeper_test_section,
      pip: errors.review_data?.pip_extra_section?.keeper_test_section,
    }

    const isError = type ? errorsByTypes[type] : false

    if (!isError) {
      return null
    }

    return <BottomText error="Please select one of the options" />
  }

  const renderJustification = () => {
    let textAreaName: string = ''

    switch (type) {
      case 'lm':
        textAreaName =
          'review_data.line_manager_extra_section.employee_project_performance.justification'
        break

      case 'fm':
        textAreaName =
          'review_data.functional_manager_extra_section.employee_project_performance.justification'
        break

      case 'peer':
        textAreaName =
          'review_data.peer_extra_section.employee_project_performance.justification'
        break

      case 'pip':
        textAreaName =
          'review_data.pip_extra_section.employee_project_performance.justification'
        break
    }

    if (viewMode && reviews) {
      return <ExpandableText list={getJustificationViewList(reviews)} />
    }
    return (
      <LapeNewTextArea
        label="Justification"
        name={textAreaName}
        elementRef={scrollRef}
        required
      />
    )
  }

  return (
    <Box mb="s-40" ref={topRef}>
      <AutoStepper maxWidth={624} mt="s-8">
        <AutoStepperTitle
          title={data.employee_project_performance.name}
          rightContent={
            viewMode &&
            reviews?.length === 1 && (
              <Text fontSize="h5" fontWeight={500}>
                <RecommendationGrade
                  value={reviews[0].employee_project_performance.value!}
                  marginLeft="auto"
                />
              </Text>
            )
          }
          subtitle={<ScorecardRecommendationBanner />}
          variant="letter"
        />
        {performanceOptions.map((option, optionIdx) => {
          const items = reviews?.filter(
            item => item.employee_project_performance.value === option.key,
          )
          return (
            <Widget key={optionIdx} p="s-16" mb="s-16">
              <Flex>
                <Tooltip
                  placement="top"
                  hide={!viewMode || !items?.length}
                  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                  anchor={tooltipAnchor[optionIdx]?.current || undefined}
                  key={option.key}
                  body={
                    <RadioTooltip
                      reviewerList={
                        items?.map(item => ({
                          review: {
                            reviewer: item.reviewer || user,
                            checkpointNum: item.checkpoint_number,
                          },
                        })) || []
                      }
                    >
                      {option.key}
                    </RadioTooltip>
                  }
                >
                  <RadioButton
                    label={
                      <div>
                        {viewMode && !!items?.length && <Amount>{items.length}</Amount>}

                        <Label>
                          <RecommendationGrade
                            value={option.key}
                            fontWeight={500}
                            pb="s-8"
                          />
                          {option.items.map((item, i) => (
                            <LabelText key={i}>
                              <Letter>{getListIndicator(i, ListType.letters)}.</Letter>{' '}
                              {item}
                            </LabelText>
                          ))}
                        </Label>
                      </div>
                    }
                    checked={
                      viewMode
                        ? !!items?.length
                        : data.employee_project_performance.value === option.key
                    }
                    disabled={viewMode}
                    onChange={() => onRadioChange(option.key)}
                    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                    inputRef={tooltipAnchor[optionIdx]}
                    alignTop
                  />
                </Tooltip>
              </Flex>
            </Widget>
          )
        })}
        {renderError()}
        {renderJustification()}
        {data.keeper_test_section && (
          <>
            <AutoStepperTitle title={data.keeper_test_section.name} variant="letter" />
            {reviews && viewMode ? (
              <RecommendationSummary
                lmData={lmData}
                fmData={fmData}
                peersData={peersData}
                id={id}
                employeeId={+employeeId}
                isViewMode
                onlyKeeper
                hideBanner
              />
            ) : (
              <Widget p="s-16" pl="s-32" ref={bottomRef}>
                {data.keeper_test_section.questions.map((question, num) => {
                  return (
                    <React.Fragment key={num}>
                      <Box>
                        <Letter>{getListIndicator(num, ListType.letters)}. </Letter>
                        {question.name}
                      </Box>
                      <Box pt="s-12" pb="s-16">
                        <RadioSelectInput
                          label="Select"
                          options={options}
                          onChange={value => {
                            if (value) {
                              onKeeperChange(value.id, num)
                            }
                          }}
                          value={
                            options?.find(option => option.value.id === question.value)
                              ?.value || null
                          }
                          disabled={viewMode}
                          searchable={false}
                        />
                      </Box>
                    </React.Fragment>
                  )
                })}
                {renderKeeperError()}
              </Widget>
            )}
          </>
        )}
        {data?.pip_recommendation && (
          <>
            <AutoStepperTitle title="Recommendations" variant="letter" />
            {reviews && viewMode ? (
              <VStack space="s-8">
                {pipData?.map((el, ind) => (
                  <RecommendationSummary
                    key={ind}
                    employeeId={+employeeId}
                    hideBanner
                    id={id}
                    isViewMode
                    onlyKeeper
                    pipData={[el]}
                    type={type}
                  />
                ))}
              </VStack>
            ) : (
              <Widget p="s-16" pl="s-32" ref={bottomRef}>
                {data.pip_recommendation.map((question, num) => {
                  const questionOptions = question.options.map(item => ({
                    key: item.id,
                    label: item.name,
                    value: {
                      ...item,
                      id: item.id,
                    },
                  }))

                  return (
                    <React.Fragment key={num}>
                      <Box>
                        <Letter>{getListIndicator(num, ListType.letters)}. </Letter>
                        {question.text}
                      </Box>
                      <Box pt="s-12" pb="s-16">
                        <RadioSelectInput
                          disabled={viewMode}
                          label="Select"
                          options={questionOptions}
                          onChange={value => {
                            if (value) {
                              onKeeperChange(value.id, num)
                            }
                          }}
                          searchable={false}
                          value={
                            questionOptions?.find(
                              (option: { key: KeeperGrade | null }) =>
                                option.key === question.value,
                            )?.value || null
                          }
                        />
                      </Box>
                    </React.Fragment>
                  )
                })}
              </Widget>
            )}
          </>
        )}
      </AutoStepper>
    </Box>
  )
}

export default ScorecardRecommendation
