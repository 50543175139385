import HubApp from '@src/features/HubApp/HubApp'
import { APPLICATIONS, globalSettingsAppTabsFilter } from '@src/constants/hub'
import React from 'react'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'

const KPIs = () => {
  const { data: performanceSettings } = useGetPerformanceSettings()

  const tabs = APPLICATIONS.kpis.tabs.filter(tab =>
    globalSettingsAppTabsFilter(tab.homeSectionId, { performanceSettings }),
  )

  return <HubApp app={{ ...APPLICATIONS.kpis, tabs }} />
}

export default KPIs
