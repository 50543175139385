import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { OfferChecklistTableInterface } from '@src/interfaces/offerCreation'
import { selectorKeys } from '@src/constants/api'
import { Check, ExclamationTriangle, InfoOutline } from '@revolut/icons'
import { COLORS, Text } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'
import { Color, HStack } from '@revolut/ui-kit'

export const offerChecklistCriteriaColumn: ColumnInterface<OfferChecklistTableInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Criteria',
    insert: ({ data }) => (
      <HStack gap="s-4">
        {data.criteria}
        {data.tooltip && (
          <Tooltip placement="bottom" body={data.tooltip}>
            <InfoOutline size={15} color={Color.GREY_TONE_50} />
          </Tooltip>
        )}
      </HStack>
    ),
  }

export const offerChecklistCheckColumn: ColumnInterface<OfferChecklistTableInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Check',
  insert: ({ data }) => {
    if (typeof data.check !== 'boolean') {
      return <Text data-testid="check-undefined">-</Text>
    }

    return data.check ? (
      <Check color={COLORS.green} size={16} data-testid="check-true" />
    ) : (
      <ExclamationTriangle color={COLORS.red} size={16} data-testid="check-false" />
    )
  },
}

export const offerChecklistValueColumn: ColumnInterface<OfferChecklistTableInterface> = {
  type: CellTypes.text,
  idPoint: 'offerValue',
  dataPoint: 'offerValue',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Offer value',
}

export const offerChecklistReqColumn: ColumnInterface<OfferChecklistTableInterface> = {
  type: CellTypes.text,
  idPoint: 'reqValue',
  dataPoint: 'reqValue',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Benchmark/Requisition',
}
