import React from 'react'
import { InputGroup, Side, Box, Subheader } from '@revolut/ui-kit'
import { connect } from 'lape'
import SideBar from '@components/SideBar/SideBar'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeMultiInput from '@components/Inputs/LapeFields/LapeMultiInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '../Form/LapeForm'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import { useGetHiringProcessSettings } from '@src/api/settings'
import RightToWorkInput from '@src/pages/Settings/HiringProcess/components/RightToWorkInputField'
import GeneralQuestion from '@src/pages/Settings/HiringProcess/components/GeneralQuestionField'

export type PersonalDataSidebarProps = {
  isOpen: boolean
  onClose: () => void
  onRefresh: () => void
}

const PersonalDataSidebar = ({
  onClose,
  isOpen,
  onRefresh,
}: PersonalDataSidebarProps) => {
  const { values, errors } = useLapeContext<CandidateInterface>()
  // const canViewSalary = (values?.field_options?.actions ?? []).includes('view_salary')
  // const canEditSalary = (values?.field_options?.actions ?? []).includes('edit_salary')
  const { data: hiringProcessSettings } = useGetHiringProcessSettings()
  return (
    <SideBar
      title="Personal info"
      variant="wide"
      onClose={onClose}
      isOpen={isOpen}
      data-testid="personal-data-sidebar"
    >
      <InputGroup>
        <LapeNewInput
          name="full_name"
          label="Full name"
          data-testid="input-name"
          required
        />
        <LapeNewInput label="Email" name="email" data-testid="input-email" required />
        <LapeNewInput
          label="Current company"
          name="headline"
          data-testid="input-headline"
          required
        />
        <LapeRadioSelectInput<OptionInterface>
          name="country"
          label="Current country"
          selector={selectorKeys.countries}
        />
        <LapeNewInput
          name="phone"
          label="Phone"
          message="Please provide a mobile number in the international format(including country code)"
          data-testid="input-phone"
          required
        />
      </InputGroup>
      <Box my="s-16">
        <InputGroup>
          <LapeMultiInput
            title="Add additional link eg LinkedIn, Github, portfolio, website etc (optional)"
            name="links"
            valueFieldName={undefined}
          />
        </InputGroup>
      </Box>
      <InputGroup>
        <LapeNewInput name="current_position" label="Current position" />
        <LapeNewInput
          type="number"
          name="years_of_experience"
          label="Years of experience"
        />
        {/* {canViewSalary && (
          <>
            <Grid columns="repeat(2, 50%)" columnGap="s-8">
              <Box>
                <LapeRadioSelectInput
                  disabled={
                    !canEditSalary || values.candidate_declined_to_disclose_current_salary
                  }
                  label="Currency salary currency"
                  name="local_currency"
                  selector={selectorKeys.currencies}
                />
              </Box>
              <Box>
                <LapeNewInput
                  hideOptional
                  label="Current salary (annual)"
                  type="number"
                  disabled={
                    !canEditSalary || values.candidate_declined_to_disclose_current_salary
                  }
                  name="local_current_salary"
                />
              </Box>
            </Grid>
            <LapeNewInput
              name="additional_information_about_current_salary"
              label="Additional information about current compensation (optional)"
              type="text"
              disabled={!canEditSalary}
            />
            <LapeSingleCheckbox
              name="candidate_declined_to_disclose_current_salary"
              description="Candidate declined to provide desired salary information"
              disabled={!canEditSalary}
            />
          </>
        )} */}
        {hiringProcessSettings?.enable_right_to_work_collecting && (
          <>
            <RightToWorkInput
              error={errors.right_to_work}
              name="right_to_work"
              rightToWork={values.right_to_work}
              onChangeRightToWork={v => {
                values.right_to_work = v
              }}
            />
            <Subheader variant="nested">
              <Subheader.Title>Eligible to relocate</Subheader.Title>
            </Subheader>
            <GeneralQuestion
              error={errors.is_eligible_to_relocate}
              name="is_eligible_to_relocate"
              value={values.is_eligible_to_relocate}
              onChange={value => {
                values.is_eligible_to_relocate = value
              }}
            />
            <Subheader variant="nested">
              <Subheader.Title>Ready to relocate</Subheader.Title>
            </Subheader>
            <GeneralQuestion
              error={errors.is_ready_to_relocate}
              name="is_ready_to_relocate"
              value={values.is_ready_to_relocate}
              onChange={value => {
                values.is_ready_to_relocate = value
              }}
            />
          </>
        )}
      </InputGroup>
      <Side.Actions horizontal>
        <NewSaveButtonWithPopup
          successText="Candidate updated successfully"
          onAfterSubmit={() => {
            onRefresh()
            onClose()
          }}
          useValidator
        />
      </Side.Actions>
    </SideBar>
  )
}

export default connect(PersonalDataSidebar)
