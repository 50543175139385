import * as React from 'react'
import { Color, Flex, FlexProps } from '@revolut/ui-kit'

interface TableWrapperProps extends FlexProps {
  useWrapper?: boolean
}

/** @deprecated Use UI Kit TableWidget */
export const TableWrapper = ({
  children,
  useWrapper = true,
  ...rest
}: TableWrapperProps) => {
  if (!useWrapper) {
    return <>{children}</>
  }

  return (
    <Flex
      p="s-16"
      bg={Color.WIDGET_BACKGROUND}
      flexDirection="column"
      radius="card"
      width="100%"
      {...rest}
    >
      {children}
    </Flex>
  )
}
