import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { RequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { DocumentInterface } from '@src/interfaces/documents'
import { AxiosPromise } from 'axios'
import { RunQueryResponse } from '@src/interfaces/kpis'
import { OptionInterface } from '@src/interfaces/selectors'
import { saveFileByBlob } from '@src/utils'

export const documentTemplatesRequests: RequestInterface<DocumentsTemplatesInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.DOCUMENTS}/templates`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.DOCUMENTS}/templates/${id}`),
  patchItem: async (data, id) => api.patch(`${API.DOCUMENTS}/templates/${id}`, data),
  deleteItem: async id => api.delete(`${API.DOCUMENTS}/templates/${id}`),
  postItem: async data => api.post(`${API.DOCUMENTS}/templates`, data),
  putItem: async (data, id) => api.put(`${API.DOCUMENTS}/templates/${id}`, data),
}

// TODO: will be deprecated, remove after enabling revamped templates
export const documentTemplatesFormRequest: RequestInterfaceNew<DocumentsTemplatesInterface> =
  {
    get: async ({ id }) => {
      return api.get(`${API.DOCUMENTS}/templates/${id}`)
    },
    update: async (data, { id }) => {
      const { file, ...rest } = data
      if (file) {
        const formData = new FormData()
        if (file) {
          formData.append('file', file, file.name)
        }
        await apiWithoutHandling.patch(`${API.DOCUMENTS}/templates/${id}`, rest)
        return api.post(`${API.DOCUMENTS}/templates/${id}/fileForm`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': `attachment`,
          },
        })
      }
      return apiWithoutHandling.patch(`${API.DOCUMENTS}/templates/${id}`, rest)
    },
    submit: async data => {
      const { file, ...rest } = data
      const formData = new FormData()
      if (file) {
        formData.append('file', file, file.name)
      }
      const result = await apiWithoutHandling.post(`${API.DOCUMENTS}/templates`, rest)
      return api
        .post(`${API.DOCUMENTS}/templates/${result.data.id}/fileForm`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': `attachment`,
          },
        })
        .catch(e => {
          api.delete(`${API.DOCUMENTS}/templates/${result.data.id}`)
          throw e
        })
    },
    delete: async (_, { id }) => api.delete(`${API.DOCUMENTS}/templates/${id}`),
  }

export const documentsTemplateFormRequests: RequestInterfaceNew<DocumentsTemplatesInterface> =
  {
    get: async ({ id }) => api.get(`${API.DOCUMENTS}/templates/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.DOCUMENTS}/templates/${id}`, data),
    submit: async data => apiWithoutHandling.post(`${API.DOCUMENTS}/templates`, data),
    delete: async (_, { id }) => api.delete(`${API.DOCUMENTS}/templates/${id}`),
  }

export const attachFileToTemplate = (templateId: number, file: File) => {
  const formData = new FormData()
  formData.append('file', file, file.name)

  return apiWithoutHandling.post<DocumentsTemplatesInterface>(
    `${API.DOCUMENTS}/templates/${templateId}/fileForm`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'attachment',
      },
    },
  )
}

export const downloadDocumentTemplateFile = async (id: number) => {
  const response = await api.get(`${API.DOCUMENTS}/templates/${id}/file`, {
    responseType: 'arraybuffer',
  })
  const infoResponse = await api.get<DocumentInterface>(
    `${API.DOCUMENTS}/templates/${id}`,
  )
  saveFileByBlob(response.data as BinaryType, infoResponse?.data.file_name)
}

export const downloadTemplateFileAsBlob = async (id: number | string) => {
  const response = await api.get<Blob>(`${API.DOCUMENTS}/templates/${id}/file`, {
    responseType: 'blob',
  })
  return URL.createObjectURL(response.data)
}

export const runDocumentRuleSQL = (
  query: string,
  document_template?: OptionInterface,
  database?: { id: number | string },
): AxiosPromise<RunQueryResponse> =>
  api.post(`${API.DOCUMENTS}/templateRules/runQuery`, {
    query,
    document_template,
    query_db: database,
  })
