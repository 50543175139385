import React, { useEffect, useState } from 'react'

import { API, selectorKeys } from '@src/constants/api'
import { LookerModelAccessInterface } from '@src/interfaces/accessRequest'
import { Box, Button, InputGroup, chain } from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Statuses } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import styled from 'styled-components'
import { Status } from '@components/CommonSC/General'
import { connect } from 'lape'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import { LapeCopyButton } from '@src/pages/Forms/FormButtons'
import { Grid } from '@components/CommonSC/Grid'
import addDays from 'date-fns/addDays'
import {
  actionsLookerAccessRequest,
  formLookerAccessRequest,
} from '@src/api/accessRequests'
import { PageWrapper } from '@components/Page/Page'
import { PermissionTypes } from '@src/store/auth/types'
import AsyncButton from '@src/features/Form/Buttons/AsyncButton'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { api } from '@src/api'
import Tooltip from '@components/Tooltip/Tooltip'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { employeesRequests } from '@src/api/employees'
import { useParams } from 'react-router-dom'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

const CustomTooltip = styled(Tooltip)`
  width: 140px;
`

const LookerModel = () => {
  const { values } = useLapeContext<LookerModelAccessInterface>()
  const [openDialog, setOpenDialog] = useState(false)
  const params = useParams<{ id?: string; employeeId: string }>()
  const [loading, setLoading] = useState(true)
  const canRevoke = !!values.field_options?.permissions?.includes?.(
    PermissionTypes.RevokeLookermodeluseraccessrequests,
  )

  useEffect(() => {
    fetchEmployee()
    if (!values?.from_date_time) {
      values.from_date_time = new Date().toISOString()
    }
  }, [])

  const fetchEmployee = async () => {
    try {
      if (params.employeeId) {
        const result = await employeesRequests.getItem(params.employeeId)
        if (result.data) {
          values.employee = result.data
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.ACCESS_REQUESTS, {
    id: params.employeeId,
  })

  const handleRevokeAccess = async () => {
    try {
      setLoading(true)
      const result = await api.post(
        `${API.DATA_ACCESS}${API.LOOKER_ACCESS_REQUESTS}/${values.id}/revoke`,
      )
      if (result?.data) {
        values.status = { id: Statuses.revoked, name: 'Revoked' }
        setOpenDialog(false)
        pushNotification({
          value: 'The access was revoked.',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <PageWrapper>
      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleRevokeAccess}
        loading={loading}
        onReject={() => setOpenDialog(false)}
        label={`Revoke this access?`}
        body={
          <div>
            The access is about to be permanently revoked.
            <Box mt="s-8" color="red">
              <b>Warning: You can't undo this action.</b>
            </Box>
          </div>
        }
      />
      <PageHeader
        title={chain(
          values?.id ? 'Looker model request' : 'New Looker model request',
          !!values?.status && (
            <Status status={values.status.id}>{values.status.name}</Status>
          ),
        )}
        subtitle={values?.employee?.full_name || ''}
        backUrl={backUrl}
      />
      <PageBody>
        <AutoStepper>
          <Grid
            mb="s-32"
            flow="column"
            justifyContent="start"
            alignItems="center"
            gap={16}
          >
            <AsyncButton
              submit={() => actionsLookerAccessRequest.approve(values)}
              variant="secondary"
              hide={
                !values?.field_options?.permissions?.includes(
                  PermissionTypes.ApproveLookermodeluseraccessrequests,
                ) || values?.status?.id !== Statuses.pending
              }
              size="sm"
              afterSubmitUrl={backUrl}
              notification={{
                path: ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_MODEL,
                updateMsg: 'Access request successfully approved.',
              }}
            >
              Approve
            </AsyncButton>
            <AsyncButton
              submit={() => actionsLookerAccessRequest.reject(values)}
              variant="negative"
              hide={
                !values?.field_options?.permissions?.includes(
                  PermissionTypes.RejectLookermodeluseraccessrequests,
                ) || values?.status?.id !== Statuses.pending
              }
              size="sm"
              afterSubmitUrl={backUrl}
              notification={{
                path: ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_MODEL,
                updateMsg: 'Access request successfully rejected.',
              }}
              dialog={{
                title: 'Reasons for rejection',
                placeholder: 'Please provide reasons for rejection',
                fieldName: 'rejection_reason',
              }}
            >
              Reject
            </AsyncButton>
            <AsyncButton
              submit={() => actionsLookerAccessRequest.cancel(values)}
              variant="negative"
              hide={
                !values?.field_options?.permissions?.includes(
                  PermissionTypes.CancelLookermodeluseraccessrequests,
                ) || values?.status?.id !== Statuses.pending
              }
              size="sm"
              afterSubmitUrl={backUrl}
              notification={{
                path: ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_MODEL,
                updateMsg: 'Access request successfully cancel.',
              }}
            >
              Cancel
            </AsyncButton>
            {values?.status?.id === Statuses.approved && (
              <CustomTooltip
                placement="right"
                text="You don't have permissions for this action."
                hide={canRevoke}
              >
                <Button
                  size="sm"
                  variant="negative"
                  disabled={!canRevoke}
                  onClick={() => setOpenDialog(true)}
                >
                  Revoke Access
                </Button>
              </CustomTooltip>
            )}
            <AsyncButton
              submit={() => formLookerAccessRequest.delete!(values, {})}
              variant="negative"
              hide={
                !values?.field_options?.permissions?.includes(
                  PermissionTypes.DeleteLookermodeluseraccessrequests,
                )
              }
              size="sm"
              afterSubmitUrl={backUrl}
              notification={{
                path: ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_MODEL,
                updateMsg: 'Access request successfully deleted.',
              }}
            >
              Delete
            </AsyncButton>
            <LapeCopyButton
              type="access-request"
              cleanFields={['specialisation', 'role']}
              replaceValues={{ from_date_time: new Date() }}
              afterSubmitUrl={pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_MODEL, {
                employeeId: params.employeeId,
              })}
            />
          </Grid>
          <NewStepperTitle title="Request Information" />
          <InputGroup>
            <LapeRadioSelectInput<LookerModelAccessInterface>
              name="lookml_model"
              label="Model"
              selector={selectorKeys.looker_lookml_models}
            />
            <LapeRadioSelectInput<LookerModelAccessInterface>
              name="permission_set"
              label="Permission"
              selector={selectorKeys.looker_permission_sets}
            />
            <InputGroup variant="horizontal">
              <LapeDatePickerInput
                disabledDays={{ before: new Date() }}
                defaultValue={new Date()}
                required
                name="from_date_time"
                label="From Date"
              />
              <LapeDatePickerInput
                disabledDays={{
                  before: new Date(),
                  after: values.from_date_time
                    ? addDays(new Date(values.from_date_time), 365)
                    : undefined,
                }}
                required
                name="to_date_time"
                label="To Date"
              />
            </InputGroup>
            <NewStepperTitle title="Provide reason" />
            <LapeNewTextArea name="reason" label="Reason for Request" required rows={3} />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Access Request received"
          afterSubmitUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.ACCESS_REQUESTS, {
            id: values?.employee?.id,
            employeeId: params.employeeId,
          })}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(LookerModel)
