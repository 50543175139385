import { API } from '@src/constants/api'
import { EngagementConsentInterface } from '@src/interfaces/engagementConsent'
import { useFetch } from '@src/utils/reactQuery'
import { api } from '.'

export const useGetEngagementConsent = (token: string) =>
  useFetch<EngagementConsentInterface>(`${API.CANDIDATE_CONSENT_LINKS}/${token}`)

export const submitOptIn = (token: string) =>
  api.patch(`${API.CANDIDATE_CONSENT_LINKS}/${token}/optIn`)
