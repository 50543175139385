export interface FeedbackInterface {
  id?: number
  user?: {
    id: number
    email: string
    employee_id: string
  }
  user_email?: string
  status: { id: 'completed' | 'rejected' }
  score?: number
  text?: string
  creation_date_time: string
}

export interface FeedbackSubmitInterface {
  status: { id: 'completed' | 'rejected' }
  score?: number
  text?: string
  topic: { id: 'platform' | 'onboarding' | 'help_center' }
}

export enum FeedbackActionsTypes {
  SET_FEEDBACK_OPEN = '@@feedback/SET_FEEDBACK_OPEN',
  SET_LOADING = '@@feedback/OPEN_FEEDBACK',
  CLOSE_FEEDBACK = '@@feedback/CLOSE_FEEDBACK',
  SUBMIT_FEEDBACK = '@@feedback/SUBMIT_FEEDBACK',
}

export interface FeedbackState {
  readonly open?: boolean
  readonly loading?: boolean
}
