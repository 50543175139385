import { Cell, Color, Flex } from '@revolut/ui-kit'
import { getBackgroundJobStats, getBackgroundJobs } from '@src/api/backgroundJobs'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import {
  action,
  backgroundJobType,
  completionDate,
  highlightRedStates,
  progressBar,
  startDate,
  statusColumn,
  triggeredBy,
} from '@src/constants/columns/backgroundJobs'
import { TableNames } from '@src/constants/table'
import { BackgroundJobResult, BackgroundJobStats } from '@src/interfaces/backgroundJobs'
import { RowInterface } from '@src/interfaces/data'
import { getColor } from '@src/styles/colors'
import React from 'react'

const rows: RowInterface<BackgroundJobResult> = {
  highlight: (data, theme) => {
    if (highlightRedStates.includes(data.state.id)) {
      return getColor(theme, Color.RED_ACTION_BACKGROUND)
    }

    return ''
  },
  cells: [
    {
      ...backgroundJobType,
      width: 200,
    },
    {
      ...progressBar,
      width: 200,
    },
    {
      ...statusColumn,
      width: 100,
    },
    {
      ...startDate,
      width: 200,
    },
    {
      ...completionDate,
      width: 200,
    },
    {
      ...triggeredBy,
      width: 200,
    },
    {
      ...action,
      width: 100,
    },
  ],
}

const BackgroundJobs = () => {
  const table = useTable<BackgroundJobResult, BackgroundJobStats>({
    getItems: getBackgroundJobs,
    getStats: getBackgroundJobStats,
  })

  const total = table.stats?.total
  const in_progress = table.stats
    ? table.stats.pending + table.stats.received + table.stats.started
    : undefined
  const done = table.stats?.success
  const error = table.stats
    ? table.stats.failure + table.stats.rejected + table.stats.retry + table.stats.revoked
    : undefined

  return (
    <Cell p="s-16">
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-16">
          <Stat label="Total" val={total} mr="s-32" />
          <Stat label="In progress" val={in_progress} mr="s-32" color="orange" />
          <Stat label="Done" val={done} mr="s-32" color="green" />
          {!!error && <Stat label="Error" val={error} color="red" />}
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.BackgroundJobs}
            useWindowScroll
            dataType="Background job"
            row={rows}
            {...table}
            noDataMessage="Background jobs will appear here."
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default BackgroundJobs
