import { ActionButton, Icon, TableWidget, TextButton, Token } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { selectorKeys } from '@src/constants/api'
import {
  roadmapDueDate,
  roadmapGenericNameColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
} from '@src/constants/columns/roadmap'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import React from 'react'

export const RoadmapsList = ({
  onAddRoadmap,
  viewMode,
}: {
  onAddRoadmap: () => void
  viewMode: boolean
}) => {
  const { values } = useLapeContext<GoalsInterface>()

  const cells: ColumnCellInterface<RoadmapInterface>[] = viewMode
    ? [
        {
          ...roadmapGenericNameColumn,
          sortKey: null,
          filterKey: null,
          width: 200,
        },
        {
          ...roadmapPriorityColumn,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          ...roadmapProgressColumn,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          ...roadmapDueDate,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
      ]
    : [
        {
          type: CellTypes.text,
          idPoint: 'review_cycle.id',
          dataPoint: 'review_cycle.name',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: 'Cycle',
          width: 100,
        },
        {
          ...roadmapGenericNameColumn,
          sortKey: null,
          filterKey: null,
          width: 200,
        },
        {
          ...roadmapPriorityColumn,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          ...roadmapDueDate,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          type: CellTypes.insert,
          idPoint: 'actions_column',
          dataPoint: 'actions_column',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          textAlign: 'right',
          insert: ({ data }) => {
            return (
              <TextButton
                onClick={() => {
                  values.roadmaps = values.roadmaps.filter(roadmap => roadmap !== data)
                }}
              >
                <Icon name="Delete" size={16} color={Token.color.greyTone50} />
              </TextButton>
            )
          },
          title: '',
          width: 50,
        },
      ]

  return values.roadmaps?.length ? (
    <TableWidget style={{ padding: 0 }}>
      {viewMode ? null : (
        <TableWidget.Actions>
          <ActionButton useIcon="16/Plus" onClick={onAddRoadmap}>
            Add roadmap
          </ActionButton>
        </TableWidget.Actions>
      )}
      <TableWidget.Table>
        <AdjustableTable<RoadmapInterface & { actions_column?: never }>
          hideCountAndButtonSection={viewMode}
          name={TableNames.RoadmapsMain}
          data={values.roadmaps}
          count={values.roadmaps.length}
          dataType="Roadmap"
          row={{
            cells,
          }}
        />
      </TableWidget.Table>
    </TableWidget>
  ) : null
}
