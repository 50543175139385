import React, { useMemo } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { RoleInterface } from '@src/interfaces/roles'
import { rolesRequests } from '@src/api/roles'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  roleGenericNameColumn,
  roleHeadcountColumn,
  roleOwnerColumn,
  rolePerfReviewColumn,
  roleRequisitionsColumn,
  roleStatusColumn,
} from '@src/constants/columns/role'
import { Button, Flex } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { Plus } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import { FunctionInterface } from '@src/interfaces/functions'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'
import { useGetOrganisationSettings } from '@src/api/settings'

interface StructureProps {
  data: FunctionInterface
}

const row: RowInterface<RoleInterface> = {
  cells: [
    {
      ...roleGenericNameColumn,
      width: 300,
    },
    {
      ...roleOwnerColumn,
      width: 390,
    },
    {
      ...roleHeadcountColumn,
      width: 150,
    },
    {
      ...roleRequisitionsColumn,
      width: 150,
    },
    {
      ...rolePerfReviewColumn,
      width: 230,
    },
    {
      ...roleStatusColumn,
      width: 220,
    },
  ],
}

const Structure = ({ data }: StructureProps) => {
  const { data: orgSettings } = useGetOrganisationSettings()
  const initialFilter = [
    {
      filters: [{ name: data.name, id: data.id }],
      columnName: 'function__id',
      nonResettable: true,
    },
    {
      filters: [
        { id: 'pending', name: 'pending' },
        { id: 'approved', name: 'approved' },
        { id: 'draft', name: 'draft' },
      ],
      columnName: 'status',
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'headcount',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const hiddenCells = useMemo(
    () => ({
      [rolePerfReviewColumn.idPoint]: !data?.field_options?.no_access?.includes(
        'average_performance_review_label',
      ),
      [roleStatusColumn.idPoint]: !(orgSettings?.enable_roles_approvals === true),
    }),
    [orgSettings?.enable_roles_approvals, data?.field_options?.no_access],
  )

  const table = useTable<RoleInterface>(rolesRequests, initialFilter, initialSortBy)

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {}), {
      initialValues: { function: { name: data.name, id: data.id } },
    })
  }

  const handleRowEdit = (role: RoleInterface) => {
    navigateTo(pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, { id: role.id }))
  }

  return (
    <TableWrapper>
      <Flex mb="s-24">
        <Stat mr="s-16" label="Roles" val={table.loading ? undefined : table.count} />
      </Flex>
      <Flex mb="s-16">
        <Button
          onClick={handleNewRow}
          mr="s-16"
          useIcon={Plus}
          size="sm"
          variant="secondary"
        >
          Add Role
        </Button>
      </Flex>
      <AdjustableTable<RoleInterface>
        name={TableNames.FunctionRoles}
        useWindowScroll
        dataType="Role"
        row={row}
        {...table}
        onRowClick={handleRowEdit}
        noDataMessage="All roles will appear here."
        hiddenCells={hiddenCells}
      />
    </TableWrapper>
  )
}

export default Structure
