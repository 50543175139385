import React from 'react'
import { Box, Flex } from '@revolut/ui-kit'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'

import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { requisitionsRequestsNew } from '@src/api/requisitions'
import { connect } from 'lape'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import * as CommonCandidatesTable from '@src/features/CommonCandidatesTable/CommonCandidatesTable'
import { PermissionTypes } from '@src/store/auth/types'
import {
  RequisitionFormSubtitle,
  RequisitionFormTitle,
} from '@src/pages/Forms/RequisitionForm/RequisitionFormTitles'
import { pathToUrl } from '@src/utils/router'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import InterviewProcess from '@src/pages/Forms/RequisitionForm/InterviewProcess/InterviewProcess'
import Form from '@src/features/Form/Form'
import General from '@src/pages/Forms/RequisitionForm/General/General'
import Preview from '@src/pages/Forms/RequisitionForm/Preview/Preview'
import Changelog from '@src/pages/Forms/RequisitionForm/Changelog/Changelog'
import Hires from '@src/pages/Forms/RequisitionForm/Hires/Hires'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetRequisitionSettings, useGlobalSettings } from '@src/api/settings'
import JobPosting from '@src/pages/Forms/RequisitionForm/JobPosting/JobPosting'

const Requisition = connect(() => {
  const { data: requisitionSettings } = useGetRequisitionSettings()
  const {
    settings: { job_postings_enabled },
  } = useGlobalSettings()
  const form = useLapeContext<RequisitionInterface>()
  const { values, initialValues } = form

  const params = useParams<{ id: string }>()

  const isFormRoute = !!useRouteMatch(ROUTES.FORMS.REQUISITION.SETTINGS) || !params.id

  const defaultBackUrl = ROUTES.RECRUITMENT.REQUISITIONS

  if (isFormRoute) {
    return (
      <PageWrapper>
        <PageHeader
          pb="s-8"
          title={params.id ? 'Edit requisition' : 'Create a new requisition'}
          backUrl={
            params.id ? pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, params) : defaultBackUrl
          }
          subtitle={params.id ? <RequisitionFormSubtitle data={values} /> : null}
        />
        <General />
      </PageWrapper>
    )
  }

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.FORMS.REQUISITION.ROLE,
      to: pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, params),
      component: Preview,
    },
    {
      title: 'Interview Process',
      path: ROUTES.FORMS.REQUISITION.INTERVIEW_PROCESS,
      to: pathToUrl(ROUTES.FORMS.REQUISITION.INTERVIEW_PROCESS, params),
      component: InterviewProcess,
      canView: false, // !!values?.specialisation?.id,
    },
    {
      title: 'Candidates',
      path: ROUTES.FORMS.REQUISITION.CANDIDATES,
      to: pathToUrl(ROUTES.FORMS.REQUISITION.CANDIDATES, params),
      component: CommonCandidatesTable.default,
      canView:
        !!values?.field_options?.permissions?.includes(
          PermissionTypes.ViewSpecialisationCandidates,
        ) && requisitionSettings?.enable_candidate_tab,
    },
    {
      title: 'Changelog',
      path: ROUTES.FORMS.REQUISITION.CHANGELOG,
      to: pathToUrl(ROUTES.FORMS.REQUISITION.CHANGELOG, params),
      component: Changelog,
    },
    {
      title: 'Hires',
      path: ROUTES.FORMS.REQUISITION.HIRES,
      to: pathToUrl(ROUTES.FORMS.REQUISITION.HIRES, params),
      component: Hires,
      canView:
        !!values?.field_options.permissions?.includes(
          PermissionTypes.ViewRequisitionHires,
        ) && requisitionSettings?.enable_hiring_process,
    },
    {
      title: 'Job postings',
      path: ROUTES.FORMS.REQUISITION.JOB_POSTING,
      to: pathToUrl(ROUTES.FORMS.REQUISITION.JOB_POSTING, params),
      component: JobPosting,
      canView: job_postings_enabled && values?.specialisation,
    },
  ]

  const filteredTabs = tabs.filter(tab =>
    tab.canView === undefined ? true : tab.canView,
  )

  return (
    <PageWrapper>
      <PageHeader
        title={
          <RequisitionFormTitle data={{ ...values, status: initialValues.status! }} />
        }
        subtitle={<RequisitionFormSubtitle data={values} />}
        backUrl={defaultBackUrl}
      >
        <Box pb="s-8" maxWidth="100vw">
          <TabBarNavigation tabs={filteredTabs} />
        </Box>
      </PageHeader>
      <Flex pt="s-8" flex="1 0" flexDirection="column" width="100%" minHeight={0}>
        <Switch>
          {filteredTabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component data={values} type="requisition" />
            </Route>
          ))}
        </Switch>
      </Flex>
    </PageWrapper>
  )
})

const RequisitionPage = () => {
  return (
    <Form api={requisitionsRequestsNew}>
      <Requisition />
    </Form>
  )
}

export default connect(RequisitionPage)
