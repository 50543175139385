import React, { useMemo, useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Badge, Box, Token } from '@revolut/ui-kit'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { DocumentsBulkRequestStatus } from '@src/interfaces/documents'

import { Basics } from './Basics'
import { Review } from './Review'

const tabsOrder = [
  {
    title: 'Basics',
    key: 'basics' as const,
    path: ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.BASICS,
    component: Basics,
  },
  {
    title: 'Review',
    key: 'review' as const,
    path: ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.REVIEW,
    component: Review,
  },
]

export const EditDocumentsBulkRequest = () => {
  const { id } = useParams<{ id: string }>()
  const [status, setStatus] = useState<DocumentsBulkRequestStatus>()
  const [name, setName] = useState<string>()
  const isRequestCreated = Boolean(id && status)

  const getTabProps = (path: string) => {
    if (path === ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.BASICS) {
      return {
        disabled: isRequestCreated,
        completed: isRequestCreated,
      }
    }
    if (path === ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.REVIEW) {
      return { disabled: !status?.id, pending: status?.id === 'validation_in_progress' }
    }
    return { disabled: false, completed: false }
  }

  const tabs = useMemo(
    () =>
      tabsOrder.map((tab, idx) => {
        const { disabled, pending, completed } = getTabProps(tab.path)

        return {
          ...tab,
          disabled,
          url: pathToUrl(tab.path),
          preTitle: (
            <Box mr="s-8">
              <Badge
                size={16}
                bg={
                  completed
                    ? Token.color.green
                    : pending
                    ? Token.color.warning
                    : Token.color.foreground
                }
              >
                {idx + 1}
              </Badge>
            </Box>
          ),
        }
      }),
    [status],
  )
  return (
    <>
      <PageHeader
        title={name || 'Bulk request documents'}
        subtitle={name ? 'Bulk request documents' : undefined}
        backUrl={ROUTES.APPS.DOCUMENTS.BULK_REQUEST.LIST}
        routerAction={PageHeaderRouterAction.navigate}
      />
      <PageBody>
        <TabBarNavigation tabs={tabs} mb="s-16" />
        <Switch>
          {tabs.map((tab, idx) => (
            <Route exact key={tab.key} path={tab.path}>
              <tab.component
                nextTabPath={tabs[idx + 1]?.path}
                setStatus={setStatus}
                setName={setName}
              />
            </Route>
          ))}
        </Switch>
      </PageBody>
    </>
  )
}
