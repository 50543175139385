import React, { useState } from 'react'
import { MoreBar, chain, Text, Group } from '@revolut/ui-kit'
import { Archive, Cross } from '@revolut/icons'

import {
  archiveEmployeePolicyAssignment,
  employeePolicyAssignmentRequest,
} from '@src/api/timeOff'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeTimeOffPolicyAssignmentInterface } from '@src/interfaces/timeOff'
import { navigateTo } from '@src/actions/RouterActions'
import { Statuses } from '@src/interfaces'
import { PermissionTypes } from '@src/store/auth/types'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { getStatusColor } from '@src/components/CommonSC/General'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import {
  getExitUrl,
  HeaderSubtitle,
  PreviewItems,
  usePolicyAssignmentParams,
} from './common'
import SettingsButtons, { DeleteButton } from '@src/features/SettingsButtons'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'

export const Preview = () => {
  const params = usePolicyAssignmentParams()
  const { values, initialValues, reset } =
    useLapeContext<EmployeeTimeOffPolicyAssignmentInterface>()

  const [changeStatusPending, setChangeStatusPending] = useState(false)
  const [archiveWarningOpen, setArchiveWarningOpen] = useState(false)

  const canArchive =
    values.status?.id === Statuses.active &&
    values.field_options?.permissions?.includes(
      PermissionTypes.ArchiveTimeOffPolicyAssignments,
    )
  const canUnassign =
    values.status?.id === Statuses.active &&
    values.field_options?.permissions?.includes(
      PermissionTypes.UnassignTimeOffPolicyAssignments,
    )
  const canDelete = values.field_options?.permissions?.includes(
    PermissionTypes.DeleteTimeOffPolicyAssignments,
  )
  const isUnassigned = values?.status?.id === Statuses.unassigned

  const onChangeStatus = async () => {
    setChangeStatusPending(true)
    archiveEmployeePolicyAssignment(values.id, params.employeeId)
      .then(result => reset({ ...result.data, field_options: values.field_options }))
      .finally(() => {
        setChangeStatusPending(false)
        setArchiveWarningOpen(false)
      })
  }

  const exitUrl = getExitUrl(params.employeeId)

  return (
    <>
      <PageHeader
        title={chain(
          initialValues.policy?.name,
          <Text color={getStatusColor(initialValues.status?.id)}>
            {initialValues.status?.name}
          </Text>,
        )}
        backUrl={exitUrl}
        subtitle={<HeaderSubtitle />}
      />

      <SettingsButtons mb="s-24">
        <EditButton
          route={pathToUrl(
            ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.GENERAL,
            params,
          )}
          isVisible={!isUnassigned}
        />
        {canArchive && (
          <MoreBar.Action
            onClick={() => setArchiveWarningOpen(true)}
            useIcon={Archive}
            variant="negative"
            pending={changeStatusPending}
          >
            Archive
          </MoreBar.Action>
        )}
        {canUnassign && (
          <MoreBar.Action
            onClick={() =>
              navigateTo(
                pathToUrl(
                  ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_UNASSIGNMENT.GENERAL,
                  params,
                ),
              )
            }
            useIcon={Cross}
            variant="negative"
          >
            Unassign
          </MoreBar.Action>
        )}
        {canDelete && (
          <DeleteButton
            title="Policy assignment"
            deleteApi={employeePolicyAssignmentRequest.delete!}
            backUrl={exitUrl}
            dialogProps={{
              label:
                'You are deleting the policy from the employee time-off policies list',
              body: `Balance linked to this policy will be closed instantly. If you want to unassign the policy or make it expire at a future date, click 'Unassign' button.`,
              yesMessage: 'Confirm',
              noMessage: 'Cancel',
            }}
          />
        )}
      </SettingsButtons>

      {isUnassigned ? (
        <PageBody>
          <FormPreview data={initialValues}>
            <Group>
              <PreviewItems />
              <FormPreview.Divider />
              <FormPreview.Item field="unassigned_on" title="Unassigned on" type="date" />
              <FormPreview.Item<EmployeeTimeOffPolicyAssignmentInterface>
                field="unassigned_by.name"
                title="Unassigned by"
                to={policy =>
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                    id: policy.unassigned_by?.id,
                  })
                }
              />
            </Group>
          </FormPreview>
        </PageBody>
      ) : (
        <PageBody>
          <FormPreview data={initialValues}>
            <Group>
              <PreviewItems />
            </Group>
          </FormPreview>
        </PageBody>
      )}

      <ConfirmationDialog
        open={archiveWarningOpen}
        onClose={() => setArchiveWarningOpen(false)}
        onConfirm={onChangeStatus}
        loading={changeStatusPending}
        onReject={() => setArchiveWarningOpen(false)}
        label="Policy archive"
        body="If an open balance is linked to this policy, it will be closed instantly. The employee will not be able to use this policy for future requests. Alternatively, you can simply set the policy assignment end date to make it expire at a future date."
        yesMessage="Confirm"
        noMessage="Cancel"
      />
    </>
  )
}
