import React from 'react'
import { Skeleton } from '@revolut/ui-kit'
import { ProgressionSection } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary'
import { useGetEmployee } from '@src/api/employees'
import { PerformanceTimeline } from '@src/pages/Forms/PromotionNominationForm/PerformanceTimeline'

export const PerformanceHistorySection = ({ employeeId }: { employeeId: number }) => {
  const { data: employee, isLoading } = useGetEmployee(employeeId)

  if (isLoading) {
    return <Skeleton />
  }

  if (employee) {
    return (
      <>
        <ProgressionSection data={employee} sticky renderHeader={false} />
        <PerformanceTimeline employee={employee} />
      </>
    )
  }

  return null
}
