import React, { useRef, useState } from 'react'
import {
  Box,
  chain,
  Color,
  Dropdown,
  ExpandableCell,
  Flex,
  Group,
  HStack,
  Link,
  MoreBar,
  ProgressStep,
  ProgressSteps,
  Text,
  VStack,
} from '@revolut/ui-kit'
import {
  ArrowExchange,
  Check,
  CrossVoid,
  ExclamationTriangle,
  LinkExternal,
  Time,
} from '@revolut/icons'
import { format } from 'date-fns'

import { PageBody } from '@components/Page/PageBody'
import SideBar from '@components/SideBar/SideBar'
import { FormPreview } from '@components/FormPreview/FormPreview'
import {
  ManualScreeningChecksInterface,
  ScreeningCheckStatuses,
} from '@src/interfaces/screeningChecksInterface'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import SettingsButtons, { DeleteButton } from '@src/features/SettingsButtons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getScreeningStatusColor, useRouteParams } from '../common'
import { PermissionTypes } from '@src/store/auth/types'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { useTheme } from '@src/styles/theme'
import {
  approveManualScreeningStage,
  manualScreeningRequests,
  rejectManualScreeningStage,
  useManualScreeningApprovals,
  useManualScreeningStatusTimeline,
} from '@src/api/screening'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'
import { ApprovalBanner } from '@src/pages/Forms/ScreeningForm/Components/ApprovalBanner'

export const ManualScreeningPreview = () => {
  const params = useRouteParams()
  const { values, reset, initialValues } =
    useLapeContext<Partial<ManualScreeningChecksInterface>>()

  const theme = useTheme()
  const anchorRef = useRef(null)
  const formValidator = useSafeFormValidator()

  const [statusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false)
  const [statusPending, setStatusPending] = useState<boolean>(false)
  const [isTimelineSidebarOpen, setIsTimelineSidebarOpen] = useState<boolean>(false)

  const { data: screeningStatusTimeline, refetch: refetchStatusTimeline } =
    useManualScreeningStatusTimeline(params.id, params.employeeId)

  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useManualScreeningApprovals(params.employeeId, params.id)

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.SCREENING, {
    id: params.employeeId,
  })

  const changeState = async (status: ScreeningCheckStatuses) => {
    if (values.id) {
      setStatusPending(true)
      setStatusDropdownOpen(false)
      try {
        const result = await manualScreeningRequests.update(
          { ...values, status: { id: status, name: status } },
          { employeeId: params.employeeId, id: `${values.id}` },
        )
        refetchStatusTimeline()
        refetchApproval()
        reset(result.data)
      } finally {
        setStatusPending(false)
      }
    }
  }

  const canEdit = values.field_options?.permissions?.includes(
    PermissionTypes.ChangeScreeningCheck,
  )

  const onChangeState = (status: ScreeningCheckStatuses) => {
    return formValidator.validate(() => changeState(status))
  }

  const showChangeStatusActions =
    values.status?.id === ScreeningCheckStatuses.progress ||
    values.status?.id === ScreeningCheckStatuses.pending ||
    values.status?.id === ScreeningCheckStatuses.needsAdjudication

  return (
    <>
      <PageHeader
        title={chain(
          values.check_type?.name,
          <Text color={getScreeningStatusColor(theme, values.status?.id)}>
            {values.status?.name}
          </Text>,
        )}
        subtitle={<EmployeeUserWithAvatar id={params.employeeId!} />}
        backUrl={backUrl}
      />

      <PageBody>
        <SettingsButtons mb="s-16">
          {showChangeStatusActions ? (
            <MoreBar.Action
              onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
              ref={anchorRef}
              useIcon={ArrowExchange}
              pending={statusPending}
            >
              Change status
              <Dropdown
                open={statusDropdownOpen}
                anchorRef={anchorRef}
                onClose={() => setStatusDropdownOpen(false)}
              >
                {values.status?.id === ScreeningCheckStatuses.pending && (
                  <Dropdown.Item
                    onClick={onChangeState(ScreeningCheckStatuses.progress)}
                    use="button"
                    useIcon={Time}
                  >
                    Mark as In progress
                  </Dropdown.Item>
                )}
                {values.status?.id === ScreeningCheckStatuses.needsAdjudication && (
                  <>
                    <Dropdown.Item
                      onClick={onChangeState(ScreeningCheckStatuses.completed)}
                      use="button"
                      useIcon={Check}
                      color={Color.GREEN}
                    >
                      Mark as Completed
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={onChangeState(ScreeningCheckStatuses.failed)}
                      use="button"
                      useIcon={CrossVoid}
                      color={Color.RED}
                    >
                      Mark as Failed
                    </Dropdown.Item>
                  </>
                )}
                {values.status?.id === ScreeningCheckStatuses.progress && (
                  <>
                    <Dropdown.Item
                      onClick={onChangeState(ScreeningCheckStatuses.needsAdjudication)}
                      use="button"
                      useIcon={ExclamationTriangle}
                    >
                      Mark as Needs adjudication
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={onChangeState(ScreeningCheckStatuses.completed)}
                      use="button"
                      useIcon={Check}
                      color={Color.GREEN}
                    >
                      Mark as Completed
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={onChangeState(ScreeningCheckStatuses.failed)}
                      use="button"
                      useIcon={CrossVoid}
                      color={Color.RED}
                    >
                      Mark as Failed
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown>
            </MoreBar.Action>
          ) : null}
          <MoreBar.Action useIcon={Time} onClick={() => setIsTimelineSidebarOpen(true)}>
            Show status history
          </MoreBar.Action>
          <EditButton
            isVisible={canEdit}
            route={pathToUrl(ROUTES.FORMS.SCREENING.MANUAL.GENERAL, params)}
          />
          <DeleteButton
            deleteApi={manualScreeningRequests.delete!}
            title="Screening check"
            backUrl={backUrl}
          />
        </SettingsButtons>

        {approvalSteps === undefined || approvalSteps?.length ? (
          <Box mb="s-16">
            <ApprovalFlow
              isLoading={isApprovalLoading || false}
              steps={approvalSteps || null}
            />
          </Box>
        ) : null}

        <ApprovalBanner<ManualScreeningChecksInterface>
          text={
            'Please review screening check results on provider’s page and approve or reject screening results. ' +
            'Once approved, the screening status will be changed to ‘Completed’. ' +
            'If rejected, status will be changed to ‘Failed’.'
          }
          isVisible={values.status?.id === ScreeningCheckStatuses.needsAdjudication}
          approve={approveManualScreeningStage}
          reject={rejectManualScreeningStage}
          refetchApproval={refetchApproval}
        />

        <FormPreview title="Screening details" data={initialValues}>
          <Group>
            <FormPreview.Item title="Source" field="check_type.name" />
            <FormPreview.Item title="Triggered by" field="triggered_by" type="employee" />
            <FormPreview.Item
              title="Triggered on"
              field="created_date_time"
              type="date"
            />
            <FormPreview.Item title="Last update" field="update_date_time" type="date" />
            <FormPreview.Item
              title="Completed on"
              field="completion_date_time"
              type="date"
            />
            {initialValues.link_to_proof && (
              <FormPreview.Item<ManualScreeningChecksInterface>
                title="Proof"
                field="link_to_proof"
                insert={data => (
                  <Link href={data.link_to_proof!} target="_blank">
                    <HStack space="s-8" align="center">
                      <LinkExternal size={16} />
                      <Text>Open</Text>
                    </HStack>
                  </Link>
                )}
              />
            )}
          </Group>
        </FormPreview>
        <SideBar
          isOpen={isTimelineSidebarOpen}
          onClose={() => setIsTimelineSidebarOpen(false)}
        >
          <Flex flexDirection="column">
            {screeningStatusTimeline?.length ? (
              <ExpandableCell.Note>
                <ProgressSteps variant="vertical">
                  {screeningStatusTimeline.map(step => (
                    <ProgressStep
                      state={step.date ? 'done' : 'pending'}
                      color={
                        step.event.id === ScreeningCheckStatuses.requiresAdjudication
                          ? Color.ORANGE
                          : undefined
                      }
                      key={step.event.id}
                    >
                      <ProgressStep.Title>{step.event.name}</ProgressStep.Title>
                      {step.employee ? (
                        <ProgressStep.Description>
                          By {step.employee?.full_name}
                        </ProgressStep.Description>
                      ) : null}
                      <ProgressStep.Caption>
                        {step.date ? (
                          <VStack color={Color.FOREGROUND}>
                            <Text>{format(new Date(step.date), 'MMM d')}</Text>
                            <Text>{format(new Date(step.date), 'HH:mm')}</Text>
                          </VStack>
                        ) : null}
                      </ProgressStep.Caption>
                    </ProgressStep>
                  ))}
                </ProgressSteps>
              </ExpandableCell.Note>
            ) : null}
          </Flex>
        </SideBar>
      </PageBody>
    </>
  )
}
