import React, { useContext } from 'react'
import styled from 'styled-components'
import {
  PerformanceRatingShortTitle,
  PerformanceRatingTitle,
  PREVIOUS_CYCLE_WIDTH,
} from '@src/constants/performance'
import { PerformanceReviewTypes, SkillCardInterface } from '@src/interfaces/performance'
import Button from '@components/Button/Button'
import { PreviousBlock } from '@components/ScorecardGeneral/Common'
import Tooltip from '@components/Tooltip/Tooltip'
import { getColor } from '@src/styles/colors'
import { Color, Skeleton, Text, Ellipsis } from '@revolut/ui-kit'
import { ShowPreviousValuesContext } from '@components/ScorecardGeneral/utils'

const InterviewScorecardButton = styled(Button)`
  border-radius: 8px;
  padding: 6px 16px;
  font-size: 14px;
  color: ${props =>
    props.disabled ? getColor(props.theme, Color.GREY_20_OPAQUE_90) : ''};
  background-color: ${props => props.disabled && props.theme.colors['grey-tone-2']};
  border: ${props =>
    props.disabled ? `1px solid ${getColor(props.theme, Color.GREY_20_OPAQUE_90)}` : ''};

  &:active {
    color: ${props =>
      props.disabled ? getColor(props.theme, Color.GREY_20_OPAQUE_90) : ''};
  }
`

const PreviousCycles = styled.div`
  ${PreviousBlock};
  line-height: 1.5;
`

const PreviousCycle = styled.div`
  width: ${PREVIOUS_CYCLE_WIDTH}px;
  display: flex;
  justify-content: center;
`

const PreviousRating = styled.span`
  overflow: hidden;
  line-height: 1.8;
`

const DefaultSectionResult = styled.div<{ column?: boolean }>`
  margin-top: 16px;
  padding-right: 16px;
  display: ${props => (props.column ? 'block' : 'flex')};
  justify-content: space-between;
  align-items: center;
  position: relative;
`

const InterviewScorecardSectionResult = styled.div``

const InterviewScorecardSectionTitle = styled.div`
  font-size: 15px;
  margin-bottom: 8px;
  margin-top: 20px;
  font-weight: bold;
`

const InterviewScorecardButtons = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  justify-content: start;
  grid-column-gap: 8px;
`

type Props = {
  type?: PerformanceReviewTypes
  card: SkillCardInterface
  disabled?: boolean
  loading?: boolean
}

const CardResult = ({ type, card, disabled, loading }: Props) => {
  const showPreviousRatings = useContext(ShowPreviousValuesContext)
  let ratingTitle = PerformanceRatingTitle[card.rating!] || card.rating

  switch (type) {
    case PerformanceReviewTypes.interviewScorecard:
      return (
        <InterviewScorecardSectionResult>
          <InterviewScorecardSectionTitle>Section Result</InterviewScorecardSectionTitle>
          <InterviewScorecardButtons>
            <InterviewScorecardButton styles="tertiary" square disabled={disabled}>
              Poor
            </InterviewScorecardButton>
            <InterviewScorecardButton styles="tertiary" square disabled={disabled}>
              Basic
            </InterviewScorecardButton>
            <InterviewScorecardButton styles="tertiary" square disabled={disabled}>
              Intermediate
            </InterviewScorecardButton>
            <InterviewScorecardButton styles="tertiary" square disabled={disabled}>
              Advanced
            </InterviewScorecardButton>
            <InterviewScorecardButton styles="tertiary" square disabled={disabled}>
              Expert
            </InterviewScorecardButton>
          </InterviewScorecardButtons>
        </InterviewScorecardSectionResult>
      )
    case PerformanceReviewTypes.deliverables:
      return null
    default:
      return (
        <DefaultSectionResult
          column={type === PerformanceReviewTypes.preview}
          data-testid="section-result"
        >
          <Text fontWeight={500} fontSize="caption">
            Section Result
          </Text>
          <Text fontWeight={500} fontSize="caption">
            {loading ? <Skeleton height={16} width={100} /> : ratingTitle}
          </Text>
          {showPreviousRatings && (card as SkillCardInterface).previous_ratings && (
            <PreviousCycles>
              {card.previous_ratings!.map(item => {
                const previousRatingTitle =
                  PerformanceRatingShortTitle[item.value] || item.value
                const previousLongRatingTitle =
                  PerformanceRatingTitle[item.value] || item.value

                return (
                  <PreviousCycle key={item.review_id}>
                    <PreviousRating>
                      <Tooltip placement="top" text={previousLongRatingTitle}>
                        <Text fontWeight={500} fontSize="caption">
                          <Ellipsis>{previousRatingTitle}</Ellipsis>
                        </Text>
                      </Tooltip>
                    </PreviousRating>
                  </PreviousCycle>
                )
              })}
            </PreviousCycles>
          )}
        </DefaultSectionResult>
      )
  }
}

export default CardResult
