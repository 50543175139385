import React from 'react'
import { useSelector } from 'react-redux'
import pluralize from 'pluralize'

import { Box, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { TableWrapper } from '@components/Table/TableWrapper'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { FaqTopicInterface } from '@src/interfaces/faq'
import { TableNames } from '@src/constants/table'
import { faqTopicTableRequests } from '@src/api/faq'
import { useTable } from '@src/components/Table/hooks'
import Stat from '@components/Stat/Stat'
import {
  faqTopicDescriptionColumn,
  faqTopicNameColumn,
  faqTopicStatusColumn,
  faqTopicUpdatedOnColumn,
} from '@src/constants/columns/faqTopic'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'
import { InternalLink } from '@components/InternalLink/InternalLink'

const ROW: RowInterface<FaqTopicInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.APPS.HELP_CENTER.TOPIC.PREVIEW, { id: String(id) })),
  cells: [
    {
      ...faqTopicNameColumn,
      width: 200,
    },
    {
      ...faqTopicDescriptionColumn,
      width: 400,
    },
    {
      ...faqTopicUpdatedOnColumn,
      width: 200,
    },
    {
      ...faqTopicStatusColumn,
      width: 150,
    },
  ],
}

export const HelpCenterFaqTopicsTable = () => {
  const permissions = useSelector(selectPermissions)
  const table = useTable<FaqTopicInterface>(faqTopicTableRequests, [
    { columnName: 'status', filters: [{ id: 'active', name: 'active' }] },
  ])
  const canAdd = permissions.includes(PermissionTypes.AddFaqTopic)

  return (
    <TableWrapper gap="s-16">
      <Stat
        label={pluralize('Topic', table.count)}
        val={table.loading ? undefined : table.count}
      />
      <Box>
        <MoreBar>
          {canAdd && (
            <MoreBar.Action
              useIcon={Plus}
              use={InternalLink}
              to={pathToUrl(ROUTES.APPS.HELP_CENTER.TOPIC.EDIT)}
            >
              Add topic
            </MoreBar.Action>
          )}
        </MoreBar>
      </Box>
      <AdjustableTable
        name={TableNames.SupportFaq}
        useWindowScroll
        row={ROW}
        {...table}
        dataType="Topic"
        noDataMessage="All topics will appear here"
      />
    </TableWrapper>
  )
}
