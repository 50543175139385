import React, { useEffect, useState, PropsWithChildren } from 'react'
import { connect } from 'lape'
import {
  EligibleGroupInterface,
  ReviewCycleStatus,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import {
  deleteEligibleGroup,
  reviewCyclesRequestsNew,
  useGetEligibleGroupsInfo,
} from '@src/api/reviewCycles'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router'
import {
  Text,
  TableWidget,
  Action,
  Flex,
  HStack,
  VStack,
  Box,
  Button,
  MoreBar,
  useStatusPopup,
  StatusPopup,
  DetailsCellSkeleton,
  ButtonSkeleton,
  Token,
  BoxProps,
  ItemSkeleton,
} from '@revolut/ui-kit'
import { getFormTitle } from '@src/pages/Forms/ReviewCycle/GeneralInfoForm'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import {
  eligibleEmployeeContractColumn,
  eligibleEmployeeLocationColumn,
  eligibleEmployeeStartedAtColumn,
  eligibleEmployeeTypeColumn,
  employeeNameColumn,
  employeeStatusColumn,
} from '@src/constants/columns/employee'
import { EmployeeInterface } from '@src/interfaces/employees'
import { SummaryTableContainer } from '@src/features/Summary/common'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import NoDataWidget from '@src/pages/Forms/ReviewCycle/components/NoDataWidget'
import { ArrowExchange, Plus } from '@revolut/icons'
import { EligibleGroupsFiltersSidebar } from './components/EligibleGroupsFiltersSidebar'
import styled from 'styled-components'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import pluralize from 'pluralize'
import { triggerCycleSync } from '@src/api/supportTool/eligibleEmployees'
import { useGetGlobalEligibleGroupsInfo } from '@src/api/performanceSettings'
import { RequestInterfaceNew } from '@src/interfaces'

const ShadowGradient = styled.div`
  position: absolute;
  top: 169px;
  left: 1px;
  right: 1px;
  height: 70px;
  background: linear-gradient(0deg, #ffffff 18.23%, rgba(255, 255, 255, 0) 100%);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`

const ROW: RowInterface<EmployeeInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.id })),
  cells: [
    {
      ...employeeNameColumn,
      width: 250,
    },
    {
      ...eligibleEmployeeTypeColumn,
      width: 90,
    },
    {
      ...eligibleEmployeeContractColumn,
      width: 100,
    },
    {
      ...eligibleEmployeeLocationColumn,
      width: 90,
    },
    {
      ...eligibleEmployeeStartedAtColumn,
      width: 110,
    },
    {
      ...employeeStatusColumn,
      width: 90,
    },
  ],
}

export const EligibleEmployees = connect(() => {
  const params = useParams<{ id: string }>()
  const statusPopup = useStatusPopup()
  const [cycleData, setCycleData] = useState<ReviewCyclesInterface>()
  const [syncPending, setSyncPending] = useState(false)
  useEffect(() => {
    reviewCyclesRequestsNew.get({ id: params.id }).then(res => setCycleData(res.data))
  }, [params.id])

  const handleSync = async () => {
    setSyncPending(true)
    try {
      const result = await triggerCycleSync(params.id)
      statusPopup.show(
        <StatusPopup variant="success-optional">
          <StatusPopup.Title>{result.data.result}</StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setSyncPending(false)
    }
  }

  const showSyncAction = cycleData?.status === ReviewCycleStatus.ongoing

  return (
    <PageWrapper>
      {cycleData && (
        <PageHeader
          title={getFormTitle(cycleData)}
          backUrl={pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, params)}
        />
      )}

      <PageBody>
        {showSyncAction && (
          <MoreBar>
            <MoreBar.Action
              useIcon={ArrowExchange}
              onClick={handleSync}
              disabled={syncPending}
            >
              Sync eligibility now
            </MoreBar.Action>
          </MoreBar>
        )}
        <Text variant="primary" mb="s-8" mt="s-24">
          Who should be reviewed in this performance cycle?
        </Text>
        <Text variant="caption" color="grey-tone-50" mb="s-32">
          Select the eligibility criteria
        </Text>
        <EligibleEmployeesWidget cycleId={params.id} />
      </PageBody>
    </PageWrapper>
  )
})

interface FormMutator {
  submit: (
    group: EligibleGroupInterface | Omit<EligibleGroupInterface, 'id'>,
  ) => Promise<void>
  delete: (groupId: number) => Promise<void>
}

interface EligibleEmployeesWidgetProps {
  cycleId?: string
  api?: RequestInterfaceNew<EligibleGroupInterface>
  configureFormMutator?: (groups: EligibleGroupInterface[]) => FormMutator
  disabled?: boolean
  allowDeleteAll?: boolean
  variant?: 'item' | 'table'
}

const WidgetWrapper = ({
  variant,
  children,
  ...boxProps
}: PropsWithChildren<BoxProps & Pick<EligibleEmployeesWidgetProps, 'variant'>>) => {
  return variant === 'table' ? (
    <Box {...boxProps}>{children}</Box>
  ) : (
    <Box
      border={`1px solid ${Token.color.greyTone10}`}
      borderRadius={Token.radius.r16}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

export const EligibleEmployeesWidget = ({
  cycleId,
  api,
  configureFormMutator,
  disabled = false,
  allowDeleteAll = false,
  variant = 'table',
}: EligibleEmployeesWidgetProps) => {
  const useGetGroups = cycleId
    ? useGetEligibleGroupsInfo.bind(null, cycleId)
    : useGetGlobalEligibleGroupsInfo
  const { data: groups, isLoading, refetch, isRefetching } = useGetGroups()
  const [openSidebar, setOpenSidebar] = useState(false)
  const [currentGroup, setCurrentGroup] = useState<EligibleGroupInterface>()
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [deletePending, setDeletePending] = useState(false)
  const formApi =
    configureFormMutator && groups ? configureFormMutator(groups.results) : undefined

  const handleDeleteGroup = async () => {
    try {
      setDeletePending(true)
      if (currentGroup?.id) {
        const deleteCb = formApi ? formApi.delete : deleteEligibleGroup
        await deleteCb(currentGroup.id)
        pushNotification({
          value: 'Group was deleted',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
        setCurrentGroup(undefined)
        refetch()
      }
    } finally {
      setDeletePending(false)
      setShowDeletePopup(false)
    }
  }

  const pendingUI =
    variant === 'table' ? (
      <VStack space="s-16">
        <DetailsCellSkeleton height={370} />
        <ButtonSkeleton width={190} />
      </VStack>
    ) : (
      <VStack space="s-16">
        <ItemSkeleton />
        <ItemSkeleton />
        <ButtonSkeleton width={190} />
      </VStack>
    )

  const loadedUi = (
    <VStack space="s-16" pl="s-16" pr="s-16">
      {groups?.results.length ? (
        groups?.results.map(group => (
          <WidgetWrapper variant={variant} key={group.id}>
            <GroupTableWidget
              data={group}
              hideRows={variant === 'item'}
              onEdit={
                disabled
                  ? undefined
                  : item => {
                      setCurrentGroup(item)
                      setOpenSidebar(true)
                    }
              }
              onDelete={
                disabled || (!allowDeleteAll && groups?.results.length === 1)
                  ? undefined
                  : item => {
                      setCurrentGroup(item)
                      setShowDeletePopup(true)
                    }
              }
            />
          </WidgetWrapper>
        ))
      ) : variant === 'table' ? (
        <Box mb="s-16">
          <NoDataWidget text="No employees selected as eligible" />
        </Box>
      ) : null}
      {!disabled && (
        <Button
          variant="secondary"
          size="sm"
          useIcon={Plus}
          onClick={() => setOpenSidebar(true)}
        >
          Add employee group
        </Button>
      )}
    </VStack>
  )

  return (
    <>
      {isLoading || isRefetching ? pendingUI : loadedUi}

      <ConfirmationDialog
        open={showDeletePopup}
        onClose={() => {
          setCurrentGroup(undefined)
          setShowDeletePopup(false)
        }}
        onConfirm={handleDeleteGroup}
        onReject={() => {
          setCurrentGroup(undefined)
          setShowDeletePopup(false)
        }}
        label="Delete this group?"
        body="Deleting a group will permanently remove it"
        loading={deletePending}
        submitDisabled={deletePending}
        yesMessage="Delete"
        noMessage="Cancel"
      />

      <EligibleGroupsFiltersSidebar
        isOpen={openSidebar}
        handleClose={() => {
          setOpenSidebar(false)
          setCurrentGroup(undefined)
        }}
        groupData={currentGroup}
        refetchGroups={refetch}
        api={api}
        submit={formApi ? formApi.submit : undefined}
      />
    </>
  )
}

const GroupTableWidget = ({
  data,
  onEdit,
  onDelete,
  hideRows = false,
}: {
  data: EligibleGroupInterface
  onEdit?: (group: EligibleGroupInterface) => void
  onDelete?: (group: EligibleGroupInterface) => void
  hideRows?: boolean
}) => {
  return (
    <TableWidget>
      <VStack space="s-8" mb={hideRows ? undefined : 's-16'}>
        <Flex justifyContent="space-between">
          <Text variant="primary">{data.name}</Text>
          {onEdit || onDelete ? (
            <HStack space="s-8">
              {!!onEdit && (
                <Action
                  onClick={() => {
                    onEdit(data)
                  }}
                >
                  Edit
                </Action>
              )}
              {!!onDelete && (
                <Action
                  onClick={() => {
                    onDelete(data)
                  }}
                >
                  Delete
                </Action>
              )}
            </HStack>
          ) : null}
        </Flex>
        <Flex justifyContent="space-between">
          {data.filters && (
            <Text variant="caption" color="grey-tone-50">
              {pluralize('filter', Object.keys(data.filters).length, true)} selected
            </Text>
          )}
          <Text variant="caption" color="grey-tone-50">
            {data.sign === 'exclude'
              ? 'Set as ineligible'
              : `Scorecards: ${data.should_create_scorecards ? 'enabled' : 'disabled'}`}
          </Text>
        </Flex>
      </VStack>
      {hideRows ? null : (
        <TableWidget.Status>
          {pluralize('employees', data.preview?.count || 0, true)} selected
        </TableWidget.Status>
      )}
      {hideRows ? null : (
        <TableWidget.Table>
          <Flex
            flexDirection="column"
            width="100%"
            maxHeight="240px"
            style={{ position: 'relative' }}
          >
            <SummaryTableContainer>
              <AdjustableTable<EmployeeInterface>
                name={TableNames.EligibleGroups}
                row={ROW}
                hideCountAndButtonSection
                rowHeight="medium"
                data={data.preview?.results || []}
                count={data.preview?.count || 0}
                noDataMessage="No eligible employees found"
                disabledFiltering
              />
            </SummaryTableContainer>
            {data.preview?.count !== undefined && data.preview?.count > 5 && (
              <ShadowGradient />
            )}
          </Flex>
        </TableWidget.Table>
      )}
    </TableWidget>
  )
}
