import React from 'react'
import { Button } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

const EditButton = () => {
  const params = useParams<{
    id: string
    employeeId: string
  }>()
  const user = useSelector(selectUser)
  const { values } = useLapeContext<ReviewSummaryInterface>()

  const ownReview = values.reviews?.find(review => review.reviewer.id === user.id)

  if (!ownReview || !values.current_user_can_edit_review) {
    return null
  }

  const url = pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE_LAYOUT, {
    id: ownReview.id,
    employeeId: params.employeeId,
  })

  const handleClick = () => {
    navigateTo(url)
  }

  if (!url) {
    return null
  }

  return (
    <Button
      onClick={handleClick}
      data-testid="btn-edit"
      width={167}
      elevated
      variant="secondary"
    >
      Edit Review
    </Button>
  )
}

export default connect(EditButton)
