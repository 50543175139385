import React, { useState } from 'react'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Color, Flex, Switch, Text } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { ReferralBonusInterface } from '@src/interfaces/referralBonus'
import { referralBonusRequests } from '@src/api/referralBonus'
import {
  referralBonusAmountColumn,
  referralBonusCurrencyColumn,
  referralBonusFunctionColumn,
  referralBonusLocationColumn,
  referralBonusSeniorityColumn,
  referralBonusSpecialisationColumn,
  referralBonusTaxColumn,
  referralBonusTypeColumn,
} from '@src/constants/columns/referralBonus'
import { getColor } from '@src/styles/colors'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const RowNotifications: RowInterface<ReferralBonusInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.REFERRAL_BONUSES.GENERAL, { id })),
  highlight: (data, theme) => (data.amount ? '' : getColor(theme, Color.RED_OPAQUE_20)),
  cells: [
    {
      ...referralBonusSpecialisationColumn,
      width: 400,
    },
    {
      ...referralBonusFunctionColumn,
      width: 230,
    },
    {
      ...referralBonusSeniorityColumn,
      width: 200,
    },
    {
      ...referralBonusLocationColumn,
      width: 200,
    },
    {
      ...referralBonusTaxColumn,
      width: 120,
    },
    {
      ...referralBonusCurrencyColumn,
      width: 100,
    },
    {
      ...referralBonusTypeColumn,
      width: 120,
    },
    {
      ...referralBonusAmountColumn,
      width: 600,
    },
  ],
}

const ReferralBonusTable = () => {
  const sort = [
    {
      sortBy: 'role__function__name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const table = useTable<ReferralBonusInterface>(referralBonusRequests, [], sort)
  const [withoutRoles, setWithoutRoles] = useState(
    table.filterBy.find(filter => filter.columnName === 'amount__isnull')?.filters?.[0]
      ?.id === 'True',
  )

  return (
    <TableWrapper>
      <Flex mb="s-24">
        <Stat label="Total" val={table?.loading ? undefined : table?.count} mr="s-32" />
      </Flex>
      <Flex mb="s-16">
        <Switch
          onChange={event => {
            table.onFilterChange({
              filters: [
                {
                  name: !withoutRoles ? 'True' : '',
                  id: !withoutRoles ? 'True' : '',
                },
              ],
              columnName: 'amount__isnull',
            })
            setWithoutRoles(event.target.checked)
          }}
          checked={withoutRoles}
        >
          <Text>Show rows without bonus</Text>
        </Switch>
      </Flex>
      <AdjustableTable<ReferralBonusInterface>
        name={TableNames.ReferralBonus}
        useWindowScroll
        dataType="Referral bonus"
        row={RowNotifications}
        {...table}
        noDataMessage="Referral bonuses will appear here."
      />
    </TableWrapper>
  )
}

export default ReferralBonusTable
