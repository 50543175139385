import { AppIconGrid, Spacer, Subheader, useMatchBreakpoint } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import React from 'react'
import HubAppIcon from '@src/features/HubAppIcon/HubAppIcon'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useHubApps } from '@src/pages/Hub/hooks'

const Discover = () => {
  const size = useMatchBreakpoint('md') ? 76 : 60
  const apps = useHubApps(false)

  return (
    <PageBody>
      {apps.length > 0 ? (
        apps.map(appGroup => (
          <React.Fragment key={appGroup.id}>
            <Subheader variant="nested">
              <Subheader.Title>{appGroup.title}</Subheader.Title>
            </Subheader>
            <AppIconGrid size={size}>
              {appGroup.items.map(app => (
                <HubAppIcon size={size} app={app} disabled key={app.id} />
              ))}
            </AppIconGrid>
            <Spacer size="s-24" />
          </React.Fragment>
        ))
      ) : (
        <ActionWidget
          title="You have access to all available apps"
          text="Any application you don't have access to will appear hear"
          avatarColor="grey-tone-50"
        />
      )}
    </PageBody>
  )
}

export default Discover
