import React from 'react'
import JobPostingsComponentTable from '@src/features/JobPostingsTable/JobPostingsTable'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const JobPostingsTable = () => {
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddJobDescriptions)

  return (
    <JobPostingsComponentTable>
      {canAdd && (
        <MoreBar.Action
          use={InternalLink}
          to={pathToUrl(ROUTES.FORMS.JOB_POSTING.GENERAL)}
          useIcon={Plus}
        >
          Add Job posting
        </MoreBar.Action>
      )}
    </JobPostingsComponentTable>
  )
}

export default JobPostingsTable
