import {
  PerformanceScores,
  ScorecardFields,
  ScorecardFieldTypes,
  scorecardRadioHeaders,
  ScorecardRadioValues,
  ScorecardSections,
} from '@src/interfaces/scorecard'
import FinalTextAreaField from '../../components/Inputs/FinalFields/FinalTextAreaField'
import FinalRadioScores from '../../components/Inputs/FinalFields/FinalRadioScores'
import React from 'react'
import trim from 'lodash/trim'
import get from 'lodash/get'
import { EmployeeInterface } from '@src/interfaces/employees'
import Tooltip from '../../components/Tooltip/Tooltip'
import { AllSectionReviewsTooltip } from './Tooltips/AllSectionReviewsTooltip'
import { performanceToColor, performanceToString } from './constants'
import styled from 'styled-components'
import { useForm, useFormState } from 'react-final-form'
import { formatPercentage } from '@src/utils/format'
import { colorGetter } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

interface FieldsRouterProps {
  field: ScorecardFields
  editing?: boolean
  reviewedUser: EmployeeInterface
  name: string
}

interface HeaderRouterProps {
  section: ScorecardSections
  editing: boolean
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
`

const FeedbackHeaderContainer = styled.div``

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
`

const SectionSubtitle = styled.div`
  font-size: 13px;
  margin-top: 8px;
  white-space: pre-wrap;
  margin-bottom: 16px;
  color: ${colorGetter(Color.GREY_TONE_50)};
`

const Performance = styled.div<{ performance?: PerformanceScores }>`
  color: ${props => {
    return performanceToColor(props.theme, props.performance)
  }};
  font-size: 20px;
`

const FeedbackPerformance = styled(Performance)`
  font-size: 10px;
  margin-top: 4px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const CustomFinalTextAreaField = styled(FinalTextAreaField)`
  overflow-y: hidden;
  font-size: 13px;
`

const Headers = styled.div`
  display: flex;
`
const Header = styled.div`
  width: 66px;
  font-size: 9px;
  color: ${colorGetter(Color.GREY_TONE_50)};
  text-align: center;
  align-self: flex-end;
`

const Pointer = styled.div<{ active: boolean }>`
  cursor: ${props => (props.active ? 'pointer' : 'auto')};
`

const feedbackPerformancePlaceholder = `Main 3 Deliverables and Impact: ...

Speed (what went well and what could be better): ...

Quality: ...

Get it Done: ...

Think Deeper: ...

Never Settle: ...

Deliver Wow: ...

Dream Team: ...

Additional feedback: ...`

const feedbackProbationPlaceholder = `Goals: ...

Skillset: ...

Values: ...

Additional feedback: ...`

export const HeaderRouter = ({ section, editing }: HeaderRouterProps) => {
  const scorecardType = section.fields?.[0]?.card_type
  switch (scorecardType) {
    case ScorecardFieldTypes.thumbs:
      return (
        <HeaderContainer>
          <TitleContainer>
            <SectionTitle>{section.title}</SectionTitle>
            <Tooltip
              body={<AllSectionReviewsTooltip section={section} />}
              hide={!section.score_label}
              placement="left"
            >
              {!editing && (
                <FeedbackPerformance performance={section.score_label}>
                  <Pointer active={!section.score_label}>
                    {performanceToString(section.score_label, true)}
                    {section.score ? ` · ${formatPercentage(section.score)}` : ''}
                  </Pointer>
                </FeedbackPerformance>
              )}
            </Tooltip>
          </TitleContainer>
          <Headers>
            <Header>{scorecardRadioHeaders[ScorecardRadioValues.unsatisfactory]}</Header>
            <Header>
              {scorecardRadioHeaders[ScorecardRadioValues.below_expectations]}
            </Header>
            <Header>
              {scorecardRadioHeaders[ScorecardRadioValues.meets_expectations]}
            </Header>
            <Header>
              {scorecardRadioHeaders[ScorecardRadioValues.above_expectations]}
            </Header>
            <Header>{scorecardRadioHeaders[ScorecardRadioValues.exceptional]}</Header>
            <Header>{scorecardRadioHeaders[ScorecardRadioValues.neutral]}</Header>
          </Headers>
        </HeaderContainer>
      )
    default:
      return (
        <FeedbackHeaderContainer>
          <SectionTitle>{section.title}</SectionTitle>
          {!!section.sub_title && (
            <SectionSubtitle>{trim(section.sub_title, ' \n')}</SectionSubtitle>
          )}
        </FeedbackHeaderContainer>
      )
  }
}

export const FieldsRouter = (props: FieldsRouterProps) => {
  const { change } = useForm()
  const { values } = useFormState()
  const feedbackPlaceholder =
    values.category === 'performance'
      ? feedbackPerformancePlaceholder
      : feedbackProbationPlaceholder
  const minHeight = values.category === 'performance' ? 300 : 150

  const handleFeedbackFocus = () => {
    if (!get(values, `${props.name}.value`)) {
      change(`${props.name}.value`, feedbackPlaceholder)
    }
  }
  if (!props.field) {
    return null
  }
  switch (props.field.card_type) {
    case ScorecardFieldTypes.textfield:
      return (
        <CustomFinalTextAreaField
          label={props.field.text}
          required
          onFocus={handleFeedbackFocus}
          placeholder={feedbackPlaceholder}
          name={`${props.name}.value`}
          minHeight={minHeight}
        />
      )
    case ScorecardFieldTypes.thumbs:
      return (
        <FinalRadioScores
          required
          editing={props.editing}
          label={props.field.text}
          info={props.field.info}
          displayName={props.reviewedUser.first_name || 'reviewee'}
          name={`${props.name}.value`}
        />
      )
    default:
      return null
  }
}
