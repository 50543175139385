import React from 'react'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Flex, Widget } from '@revolut/ui-kit'
import { offersTableRequests } from '@src/api/offerCreation'
import { OfferCreationInterface } from '@src/interfaces/offerCreation'
import {
  offerCandidateNameColumn,
  offerRecruiterColumn,
  offerRequisitionColumn,
  offerSeniorityColumn,
  offerSigningStatusColumn,
  offerSpecialisationColumn,
  offerStatusColumn,
  offerTeamColumn,
} from '@src/constants/columns/offerForm/offers'
import { TableNames } from '@src/constants/table'

const row: RowInterface<OfferCreationInterface> = {
  linkToForm: ({ id, candidate }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.OFFER_CREATION.PREVIEW, { id, candidateId: candidate?.id }),
    ),
  cells: [
    {
      ...offerCandidateNameColumn,
      width: 220,
    },
    {
      ...offerRequisitionColumn,
      width: 90,
    },
    {
      ...offerSpecialisationColumn,
      width: 250,
    },
    {
      ...offerSeniorityColumn,
      width: 150,
    },
    {
      ...offerTeamColumn,
      width: 250,
    },
    {
      ...offerRecruiterColumn,
      width: 250,
    },
    {
      ...offerStatusColumn,
      width: 150,
    },
    {
      ...offerSigningStatusColumn,
      width: 180,
    },
  ],
}

const OffersTable = () => {
  const initialSortBy = [
    {
      sortBy: 'update_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const table = useTable<OfferCreationInterface>(
    offersTableRequests,
    undefined,
    initialSortBy,
  )

  return (
    <Widget p="s-16" width="100%">
      <Flex style={{ position: 'relative' }} flex="1 0">
        <AdjustableTable<OfferCreationInterface>
          name={TableNames.Offers}
          useWindowScroll
          dataType="Offer"
          row={row}
          {...table}
          noDataMessage="Offers will appear here"
        />
      </Flex>
    </Widget>
  )
}

export default OffersTable
