import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { recruitmentGroupsRequestsNew } from '@src/api/recruitmentGroups'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RecruitmentGroupsInterface } from '@src/interfaces/recruitmentGroups'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { Box, InputGroup } from '@revolut/ui-kit'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

const General = () => {
  const { values } = useLapeContext<RecruitmentGroupsInterface>()

  const backUrl = ROUTES.APPS.HIRING_PROCESS.RECRUITMENT_GROUPS
  return (
    <PageWrapper>
      <PageHeader
        title={values?.id ? values?.team?.name : 'New Recruitment Group'}
        backUrl={backUrl}
      />
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Recruitment group details" />
          <Box mb="s-32">
            <InputGroup>
              <LapeRadioSelectInput
                name="team"
                selector={selectorKeys.team}
                label="Team"
              />
              <LapeNewMultiSelect
                name="specialisations"
                selector={selectorKeys.specialisations}
                placeholder="Specialisations"
                required
              />
            </InputGroup>
          </Box>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Recruitment group saved successfully"
          afterSubmitUrl={backUrl}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={recruitmentGroupsRequestsNew}>
    <General />
  </Form>
))
