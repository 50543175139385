import React, { createContext, useContext, useMemo } from 'react'
import { Provider, useMatchMedia } from '@revolut/ui-kit'

import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LocalStorageKeys } from '@src/store/auth/types'
import { defaultTheme } from '@src/styles/theme'
import { env, Environments } from '@src/constants/api'
import { useRouteMatch } from 'react-router-dom'
import { ROUTES, WORKSPACES } from '@src/constants/routes'

type ThemeMode = 'light' | 'dark'

export type ThemeSetting = ThemeMode | 'auto'

type ThemeContextValue = {
  themeSettingValue: ThemeSetting
  theme: ThemeMode
  setTheme: (value: ThemeSetting) => void
  loginTheme: ThemeMode
  setLoginTheme: (value: ThemeMode) => void
}

const ThemeContext = createContext<ThemeContextValue | null>(null)

export const useAppTheme = () => {
  const context = useContext(ThemeContext)
  if (context === null) {
    throw new Error('useAppTheme must be used within an UIKitWithThemeProvider')
  }
  return context
}

export const UIKitWithThemeProvider: React.FC = ({ children }) => {
  const [themeSettingValue, setThemeSettingValue] = useLocalStorage<ThemeSetting>(
    LocalStorageKeys.THEME,
    env === Environments.local || env === Environments.development ? 'dark' : 'light',
    false,
  )
  const [loginTheme, setLoginTheme] = useLocalStorage<'light' | 'dark'>(
    LocalStorageKeys.LOGIN_THEME,
    'dark',
  )

  const prefersDarkTheme = useMatchMedia(
    themeSettingValue === 'auto' ? '(prefers-color-scheme: dark)' : null,
  )

  const isLoginThemeRoute = !!useRouteMatch({
    path: [ROUTES.LOGIN.MAIN, ROUTES.SIGNUP.MAIN, WORKSPACES.ANY],
  })

  const contextValue = useMemo<ThemeContextValue>(() => {
    return {
      themeSettingValue,
      theme:
        themeSettingValue === 'auto'
          ? prefersDarkTheme
            ? 'dark'
            : 'light'
          : themeSettingValue,
      setTheme: (mode: ThemeSetting) => {
        setThemeSettingValue(mode)
      },
      loginTheme,
      setLoginTheme: (mode: ThemeMode) => {
        setLoginTheme(mode)
      },
    }
  }, [
    themeSettingValue,
    setThemeSettingValue,
    setLoginTheme,
    loginTheme,
    prefersDarkTheme,
  ])

  return (
    <ThemeContext.Provider value={contextValue}>
      <Provider
        features={{ revolut10: true }}
        theme={defaultTheme}
        mode={isLoginThemeRoute ? loginTheme : contextValue.themeSettingValue}
      >
        {children}
      </Provider>
    </ThemeContext.Provider>
  )
}
