import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, Group, RadioSelect, TextButton, Token } from '@revolut/ui-kit'
import SummaryCard from '@src/pages/Forms/EmployeePerformance/components/SummaryCard'
import {
  ReviewCategory,
  ReviewDataInterface,
  ReviewDataSectionInterface,
  ReviewDataValueBasedSectionInterface,
  ReviewScorecardInterface,
  ReviewSummaryDataInterface,
  SkillSummary,
} from '@src/interfaces/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Grid } from '@components/CommonSC/Grid'
import { getCleanValuesBeforeSave } from '@src/utils/performance'
import { postPerformanceReviewForSummary } from '@src/api/performanceReview'
import { useLape } from 'lape'
import { FormPreview } from '@components/FormPreview/FormPreview'
import RecommendationSummary from '@src/pages/Forms/EmployeePerformance/RecommendationSummary'
import SidebarSkeleton from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SidebarSkeleton'
import HistoryTooltip, {
  HistoryTooltipData,
  HistoryTypes,
} from '@components/Stepper/HistoryTooltip'
import { getEmployeeFieldChangelog } from '@src/api/employees'
import { Statuses } from '@src/interfaces'
import { differenceInDays, format, startOfToday } from 'date-fns'
import PerformanceTable from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceTable'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { getSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { Caption } from '@revolut/ui-kit'
import { ChevronDown } from '@revolut/icons'
import produce from 'immer'
import { Widget } from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'

const getFieldsForChangelog = async (
  id: number,
  field: string,
): Promise<HistoryTooltipData[]> => {
  const result = await getEmployeeFieldChangelog(id, field)

  const FORMAT = 'd MMM yyyy'

  let currentFlag = false

  if (result.data?.results) {
    return (
      result.data?.results.reverse().map(c => {
        if (c.status?.id === Statuses.pending) {
          if (c.effective_date_time) {
            const isChangeTimePassed =
              differenceInDays(new Date(c.effective_date_time), startOfToday()) <= 0

            return {
              type: HistoryTypes.Future,
              name: `${c.field_display_value} ${
                isChangeTimePassed ? 'on' : 'scheduled for'
              } ${format(new Date(c.effective_date_time), FORMAT)} by ${
                c.created_by.display_name
              }`,
            }
          }
          return {
            type: HistoryTypes.Future,
            name: `${c.field_display_value} on ${format(
              new Date(c.updated_date_time),
              FORMAT,
            )} (pending)`,
          }
        }

        if (!currentFlag && c.status?.id === Statuses.completed) {
          currentFlag = true
          return {
            type: HistoryTypes.Current,
            name: `${c.field_display_value} on ${format(
              new Date(c.effective_date_time),
              FORMAT,
            )} by ${c.created_by.display_name} (current)`,
          }
        }

        return {
          type: HistoryTypes.Past,
          name: `${c.field_display_value} on ${format(
            new Date(c.effective_date_time),

            FORMAT,
          )} by ${c.created_by.display_name}`,
        }
      }) || []
    )
  }
  return []
}

type Props = {
  isOpen: boolean
  onClose: () => void
}

const PerformanceEditSummarySidebar = ({ isOpen, onClose }: Props) => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [seniorityId, setSeniorityId] = useState<number | undefined | null>(
    values.reviewed_employee?.seniority?.id,
  )
  const [seniorities, setSeniorities] = useState<SeniorityInterface[]>()
  const state = useLape<{ data?: ReviewDataInterface }>({
    data: undefined,
  })

  const employeeId = values?.reviewed_employee?.id
  const cycleId = values?.cycle?.id

  useEffect(() => {
    if (!employeeId || !cycleId) {
      return
    }
    const fetchSeniorities = async () => {
      const response = await getSelectors(selectorKeys.seniority)
      const sortedSeniorities = (response.data.options as SeniorityInterface[]).sort(
        (a, b) => a.level - b.level,
      )
      setSeniorities(sortedSeniorities)
    }

    fetchSeniorities()
  }, [employeeId, cycleId])

  const changeSeniority = (seniority: number | null | undefined) => {
    setSeniorityId(seniority)
    setDropdownOpen(false)
  }

  const fetchSummary = async () => {
    let cleanValues = values

    if (values.category === ReviewCategory.Performance) {
      cleanValues = getCleanValuesBeforeSave(values)
    }

    const result = await postPerformanceReviewForSummary(cleanValues, values.category)
    state.data = result.data
  }

  useEffect(() => {
    fetchSummary()
  }, [])

  const { data } = state

  // TODO: this mapping should be done on BE
  const getSection = (
    section: ReviewDataSectionInterface | ReviewDataValueBasedSectionInterface,
  ): SkillSummary => {
    return produce(section, draft => {
      draft.cards?.forEach(card => {
        if (card?.justification) {
          // @ts-ignore hack
          card.justification = [
            { value: card.justification, review: { reviewer: values.reviewer } },
          ]
        } else {
          card.justification = undefined
        }
      })
    }) as SkillSummary
  }

  const summaryData: ReviewSummaryDataInterface = {
    overall_feedbacks: [],
    culture_skills: data?.culture_skills
      ? getSection(data?.culture_skills)
      : ({} as SkillSummary),
    culture_values: data?.culture_values ? getSection(data.culture_values) : undefined,
    manager_values: data?.manager_values ? getSection(data.manager_values) : undefined,
    deliverables: data?.deliverables ? getSection(data?.deliverables) : undefined,
    functional_skills: data?.functional_skills
      ? getSection(data?.functional_skills)
      : undefined,
    kpis_section: data?.kpis_section,
    ratings: [],
    rating: data?.rating!,
  }

  return (
    <SideBar title="Summary" variant="wide" isOpen={isOpen} onClose={onClose}>
      <Box width="100%">
        {!!values.reviewed_employee && (
          <Box mb="s-16">
            <FormPreview data={values.reviewed_employee} title="Current position details">
              <Group>
                <FormPreview.Item
                  title="Role (Specialisation)"
                  insert={() => (
                    <Grid flow="column" gap={4} alignItems="center">
                      {values.reviewed_employee?.specialisation?.name}
                      <HistoryTooltip
                        color={Token.color.greyTone50}
                        request={() =>
                          getFieldsForChangelog(values.reviewed_employee.id!, 'position')
                        }
                        placement="left"
                      />
                    </Grid>
                  )}
                />
                <FormPreview.Item
                  title="Seniority"
                  insert={() => {
                    const { seniority, specialisation_seniority_sublevel } =
                      values.reviewed_employee || {}
                    return (
                      <Grid flow="column" gap={4} alignItems="center">
                        {`${seniority?.name || '-'}${
                          specialisation_seniority_sublevel
                            ? ` (${specialisation_seniority_sublevel.name})`
                            : ''
                        }`}
                        <HistoryTooltip
                          color={Token.color.greyTone50}
                          request={() =>
                            getFieldsForChangelog(
                              values.reviewed_employee.id!,
                              'seniority',
                            )
                          }
                          placement="left"
                        />
                      </Grid>
                    )
                  }}
                />
              </Group>
            </FormPreview>
          </Box>
        )}
        <Widget p="s-16">
          {data ? (
            <Grid gap={32}>
              {values?.reviewed_employee?.id && (
                <Box>
                  <Flex mb="s-4" alignItems="center" justifyContent="space-between">
                    <Caption fontWeight={500} color="grey-tone-50">
                      Ratings
                    </Caption>
                    <>
                      <TextButton
                        ref={anchorRef}
                        onClick={() => setDropdownOpen(!isDropdownOpen)}
                        aria-haspopup="listbox"
                        aria-expanded={isDropdownOpen}
                      >
                        <Grid alignItems="center" flow="column" gap={4}>
                          Compare with{' '}
                          {seniorities?.find(s => s.id === seniorityId)?.name ||
                            'seniority'}
                          <ChevronDown />
                        </Grid>
                      </TextButton>
                      <RadioSelect<number | undefined>
                        anchorRef={anchorRef}
                        open={isDropdownOpen}
                        onClose={() => setDropdownOpen(false)}
                        options={
                          seniorities?.map(s => ({
                            label: s.name,
                            value: s.id,
                            key: s.id,
                          })) || []
                        }
                        value={seniorityId}
                        onChange={changeSeniority}
                        labelList="Seniorities"
                      />
                    </>
                  </Flex>
                  <PerformanceTable
                    data={summaryData}
                    isSidebar
                    cycleId={`${values?.cycle?.id}`}
                    employeeId={values?.reviewed_employee?.id}
                    employeeSeniorityId={seniorityId as number}
                    category={ReviewCategory.Performance}
                  />
                </Box>
              )}

              {(data.line_manager_extra_section ||
                data.functional_manager_extra_section ||
                data.peer_extra_section) && (
                <SummaryCard title="Recommendation">
                  <RecommendationSummary
                    employeeId={values.reviewed_employee.id}
                    id={values.id}
                    lmData={data.line_manager_extra_section}
                    fmData={data.functional_manager_extra_section}
                    peersData={
                      data.peer_extra_section ? [data.peer_extra_section] : undefined
                    }
                    isViewMode={false}
                  />
                </SummaryCard>
              )}
            </Grid>
          ) : (
            <SidebarSkeleton />
          )}
        </Widget>
      </Box>
    </SideBar>
  )
}

export default PerformanceEditSummarySidebar
