import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  PerformanceRating,
  SkillSummary,
  SummarySkillCardInterface,
} from '@src/interfaces/performance'
import { selectorKeys } from '@src/constants/api'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { SkillLevels } from '@src/interfaces/roles'
import { Token } from '@revolut/ui-kit'
import { KpiRowInterface } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewTables'
import { RecommendationQuestionInterface } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserSummary'

const getCellBackground = (rating?: PerformanceRating | SkillLevels | null) => {
  switch (rating) {
    case PerformanceRating.basic:
    case PerformanceRating.basic_plus:
    case PerformanceRating.performing:
      return Token.color.teal_5
    case PerformanceRating.poor:
    case PerformanceRating.poor_plus:
    case PerformanceRating.improvement_needed:
      return Token.color.orange_5
    case PerformanceRating.intermediate_minus:
    case PerformanceRating.intermediate:
    case PerformanceRating.intermediate_plus:
    case PerformanceRating.superpower:
    case SkillLevels.Intermediate:
      return Token.color.teal_30
    case PerformanceRating.advanced_minus:
    case PerformanceRating.advanced:
    case PerformanceRating.advanced_plus:
    case SkillLevels.Advanced:
      return Token.color.teal_70
    case PerformanceRating.expert_minus:
    case PerformanceRating.expert:
    case SkillLevels.Expert:
      return Token.color.teal
    default:
      return Token.color.background
  }
}

export const performanceSummaryDeliverablesNameColumn: ColumnInterface<SkillSummary> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  insert: () => 'Goals and deliverables',
}

export const performanceSummaryRatingColumn: ColumnInterface<
  SkillSummary | KpiRowInterface | SummarySkillCardInterface
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Latest rating',
  background: data => getCellBackground(data.rating),
}

export const performanceSummaryRecommendedRatingColumn: ColumnInterface<
  SkillSummary | KpiRowInterface
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Latest rating',
  insert: ({ data }) =>
    PerformanceRatingTitle[
      'recommended_rating' in data ? data.recommended_rating : 'none'
    ],
  background: data => getCellBackground(data.rating),
}

export const performanceSummaryExpectedRatingColumn: ColumnInterface<
  SkillSummary | KpiRowInterface | SummarySkillCardInterface
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Expected rating',
  insert: ({ data }) => PerformanceRatingTitle[data.rating_expectation || 'none'],
  background: data => getCellBackground(data.rating_expectation),
}

export const performanceSummarySkillsNameColumn: ColumnInterface<
  SummarySkillCardInterface | RecommendationQuestionInterface
> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const performanceSummaryBarRaiserReviewerColumn: ColumnInterface<RecommendationQuestionInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'LM',
  }
