import * as React from 'react'
import styled from 'styled-components'
import { colorGetter } from '@src/styles/colors'
import { Color, Token } from '@revolut/ui-kit'

const Wrapper = styled.div`
  display: grid;
  margin-bottom: 16px;
`
const A = styled.a`
  align-self: center;
  color: #0075eb;
  text-decoration: none;
`
const Title = styled.div`
  color: ${Token.color.foreground};
  font-size: 24px;
  line-height: 32px;
  margin: 4px 0 4px;
`

const Subtitle = styled.div<{ longSubtitle?: boolean }>`
  max-width: ${props => (props.longSubtitle ? '100%' : '624px')};
  font-size: 14px;
  margin-bottom: 8px;
  color: ${colorGetter(Color.GREY_TONE_50)};
`

const LinkButton = styled.a`
  display: grid;
  align-items: center;
  padding: 8px 12px;
  height: 40px;
  justify-items: center;
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
  background: #ffffff;
  text-decoration: none;
  margin-top: 16px;
  font-size: 13px;
  line-height: 16px;
  border: 2px solid ${colorGetter(Color.GREY_20_OPAQUE_50)};
  border-radius: 4px;
  justify-self: start;
`

const Required = styled.span`
  color: ${props => props.theme.colors.red};
`

interface Link {
  url: string
  label: string
}

interface Props {
  title: string
  titleStat?: React.ReactNode
  subtitle?: React.ReactNode
  required?: boolean
  button?: React.ReactNode
  subtitleLink?: Link
  link?: Link
  longSubtitle?: boolean
}

const StepperTitle = ({
  title,
  link,
  button,
  subtitle,
  required,
  subtitleLink,
  titleStat,
  longSubtitle,
}: Props) => {
  return (
    <Wrapper>
      <Title>
        {title}
        {titleStat}
        {required && <Required>*</Required>}
      </Title>
      {button}
      {link && (
        <LinkButton href={link.url} target="_blank">
          {link.label}
        </LinkButton>
      )}
      {(subtitle || subtitleLink) && (
        <Subtitle longSubtitle={longSubtitle}>
          {subtitle && <>{subtitle} </>}
          {subtitleLink && (
            <A target="_blank" href={subtitleLink.url}>
              {subtitleLink.label}
            </A>
          )}
        </Subtitle>
      )}
    </Wrapper>
  )
}

export default StepperTitle
