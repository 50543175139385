import React, { useCallback, useEffect, useState } from 'react'
import { selectorKeys } from '@src/constants/api'
import { SkillInterface, SkillType, SkillTypeTitle } from '@src/interfaces/skills'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { rolesRequests } from '@src/api/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { InputGroup, Link, MoreBar, Text } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import Instructions from '@src/pages/Forms/SkillForm/Instructions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import SettingsButtons from '@src/features/SettingsButtons'
import { PageBody } from '@components/Page/PageBody'
import { InfoOutline } from '@revolut/icons'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeMultiInput from '@components/Inputs/LapeFields/LapeMultiInput'
import { PageActions } from '@components/Page/PageActions'
import SaveDraftButton from '@src/pages/Forms/SkillForm/Buttons/SaveDraftButton'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import { FunctionOptionInterface, FunctionType } from '@src/interfaces/functions'
import { RadioSelectInputProps } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { navigateReplace } from '@src/actions/RouterActions'
import SkillExamples from '@src/pages/Forms/SkillForm/Examples'
import { PROBLEM_SOLVING_PLAYBOOK } from '@src/constants/externalLinks'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { useGetSkillsSettings } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'

export const SkillForm = ({
  hidePlaybookInput = false,
  typeOptions,
}: {
  hidePlaybookInput?: boolean
  typeOptions?: SkillType[]
}) => {
  const isCommercial = useIsCommercial()
  const { values } = useLapeContext<SkillInterface>()
  const { data: skillsSettings } = useGetSkillsSettings()

  const user = useSelector(selectUser)

  useEffect(() => {
    if (!values.id) {
      if (!values.owner) {
        values.owner = { id: user.id, name: user.full_name }
      }
      if (!values.function) {
        setDefaultFunction()
      }
      if (!values.skill_type) {
        values.skill_type = SkillType.Functional
      }
    }
  }, [])

  const setDefaultFunction = async () => {
    if (user.position) {
      const result = await rolesRequests.getItem(user.position.id)
      if (result.data) {
        values.function = {
          name: result?.data?.function?.name,
          id: result?.data?.function?.id,
        }
      }
    }
  }

  const executiveFunctionFilter: RadioSelectInputProps<FunctionOptionInterface>['filter'] =
    useCallback(
      optionValue => {
        return optionValue.function_type === FunctionType.executive
      },
      [values.skill_type],
    )

  const skillTypeOptions: RadioOption[] = [
    {
      value: SkillType.Functional,
      label: SkillTypeTitle[SkillType.Functional],
      tooltip:
        'Function-specific skills with a single definition that are primarily used and controlled by one function.',
    },
    {
      value: SkillType.Executive,
      label: SkillTypeTitle[SkillType.Executive],
      tooltip:
        'Cross-functional skills with a common definition that are used throughout all areas of the organisation.',
    },
    {
      value: SkillType.Culture,
      label: SkillTypeTitle[SkillType.Culture],
    },
  ].filter(option => (typeOptions ? typeOptions.includes(option.value) : true))

  return (
    <AutoStepper>
      <>
        <>
          <NewStepperTitle title="General info" />
          <InputGroup>
            <LapeNewInput label="Skill name" name="name" required />
            <LapeNewTextArea
              label="Description"
              name="description"
              required
              rows={2}
              message="A short description that will help managers conducting performance reviews understand what this skill means"
            />
            {!hidePlaybookInput && (
              <LapeNewInput
                label="Link to playbook"
                name="playbook_url"
                required
                message={
                  isCommercial ? undefined : (
                    <>
                      Please link to the confluence playbook that defines further
                      information for this skill.{' '}
                      <Link target="_blank" href={PROBLEM_SOLVING_PLAYBOOK}>
                        View example of a skill playbook
                      </Link>
                    </>
                  )
                }
              />
            )}
            <LapeRadioSelectInput
              name="owner"
              label="Skill owner"
              selector={selectorKeys.employee}
            />
            <Text pt="s-16" variant="caption" color="grey-tone-50">
              Skill category
            </Text>
            <LapeNewRadioButtons
              name="skill_type"
              options={skillTypeOptions}
              variant="cell"
            />
            <LapeRadioSelectInput<FunctionOptionInterface>
              name="function"
              label="Function"
              selector={selectorKeys.functions}
              filter={
                [SkillType.Executive, SkillType.Culture].includes(values.skill_type)
                  ? executiveFunctionFilter
                  : undefined
              }
            />
            {!skillsSettings?.behaviours_assessment_enabled && (
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Set this skill to be assessed in upwards reviews',
                  description:
                    'If upwards reviews are enabled without value-based behaviours, this skill will be present in the performance cycle upwards review scorecards',
                }}
                name="is_upwards_review_skill"
              />
            )}
          </InputGroup>
        </>
        <>
          <NewStepperTitle title="Skill mastery" />
          <InputGroup>
            <LapeMultiInput
              title="Poor"
              data-name="poor"
              valueFieldName={undefined}
              withCell
              name="mastery_level_definition_poor"
              description={`
           Used in interviews to filter out under-qualified candidates. 
           Ideally contains 2 points.
          `}
            />
            <LapeMultiInput
              title="Basic"
              valueFieldName={undefined}
              withCell
              name="mastery_level_definition_basic"
              description={`
            This is the minimum working expectations for this skill, often 
            aligned with Junior-Mid seniorities. Ideally contains 2-3 points.
          `}
            />
            <LapeMultiInput
              title="Intermediate"
              data-name="intermediate"
              valueFieldName={undefined}
              withCell
              name="mastery_level_definition_intermediate"
              description={`
            This the typical competency of an employee within the company, 
            often aligned with Mid-Senior seniorities. Ideally contains 3-5 points.
          `}
            />
            <LapeMultiInput
              title="Advanced"
              data-name="advanced"
              valueFieldName={undefined}
              withCell
              name="mastery_level_definition_advanced"
              description={`
            This represents higher than average knowledge and an expertise in a 
            particular skill, often aligned with Senior-Lead seniorities. 
            Ideally contains 2-3 points.
          `}
            />
            <LapeMultiInput
              title="Expert"
              data-name="expert"
              valueFieldName={undefined}
              withCell
              name="mastery_level_definition_expert"
              description={`
            Define what an expert of this skill will look like. 
            At least have 2 points.
          `}
            />
          </InputGroup>
        </>
      </>
    </AutoStepper>
  )
}

const General = () => {
  const { values } = useLapeContext<SkillInterface>()
  const [isHelpOpen, setHelpOpen] = useState(false)
  const [examplesOpen, setExamplesOpen] = useState<boolean>(true)

  return (
    <>
      <PageBody>
        <SettingsButtons mb="s-8">
          <MoreBar.Action
            useIcon={InfoOutline}
            data-testid="open_instructions_button"
            onClick={() => {
              setExamplesOpen(false)
              setHelpOpen(true)
            }}
          >
            Show instructions
          </MoreBar.Action>
          <MoreBar.Action
            useIcon={InfoOutline}
            data-testid="open_examples_button"
            onClick={() => {
              setHelpOpen(false)
              setExamplesOpen(true)
            }}
          >
            Show examples
          </MoreBar.Action>
        </SettingsButtons>
        <SkillForm />
      </PageBody>
      <PageActions>
        <SaveDraftButton />
        <NewSaveButtonWithPopup
          useValidator
          onAfterSubmit={res =>
            navigateReplace(pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, { id: res.id }))
          }
        />
      </PageActions>
      <Instructions isOpen={isHelpOpen} onClose={() => setHelpOpen(false)} />
      <SkillExamples
        isOpen={examplesOpen}
        onCopy={(prop, value) => {
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          values[prop] = value
        }}
        onClose={() => setExamplesOpen(false)}
      />
    </>
  )
}

export default General
