import React, { useState } from 'react'
import { MoreBar, Color } from '@revolut/ui-kit'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { changeInterviewRound } from '@src/api/recruitment/interviews'

type Props = {
  candidateId: number
  round: InterviewRoundInterface
  onRefresh: () => void
}

const ChangeRoundWidget = ({ candidateId, round, onRefresh }: Props) => {
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)
    try {
      await changeInterviewRound(candidateId, round.id)
      onRefresh()
    } finally {
      setLoading(false)
    }
  }

  return (
    <ActionWidget
      title={`You are previewing the candidate's interview process for ${round.specialisation?.name}`}
      text="Do you want to mark this specialisation as the main one for the candidate? Doing this will switch the current active interview process and enable all actions on the profile e.g. interview scheduling, changing stages for this specialisation"
      avatarColor={Color.BLUE}
    >
      <MoreBar>
        <MoreBar.Action
          variant="accent"
          onClick={onClick}
          pending={loading}
          disabled={loading}
        >
          Mark as main
        </MoreBar.Action>
      </MoreBar>
    </ActionWidget>
  )
}

export default ChangeRoundWidget
