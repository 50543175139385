import React, { useState } from 'react'
import { InputGroup, Avatar, Flex, Item, VStack, Button } from '@revolut/ui-kit'
import { Target, CheckSuccess, Flag, Pencil, Plus } from '@revolut/icons'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import {
  ProbationTemplateInterface,
  ProbationTemplatePreviewRequest,
} from '@src/interfaces/probationTemplate'
import { useParams } from 'react-router-dom'
import { TemplateBanner } from '@src/pages/Forms/ProbationTemplate/TemplateBanner'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageHeader } from '@components/Page/Header/PageHeader'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { TimelinePreview } from '@src/pages/Forms/ProbationTemplate/TimelinePreview'
import { navigateReplace } from '@src/actions/RouterActions'
import { EmployeeRulesSidebarForm } from '@src/pages/Forms/ProbationTemplate/EmployeeRulesSidebarForm'
import { EmployeeRulesList } from '@src/pages/Forms/ProbationTemplate/EmployeeRulesList'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { useGetProbationTemplateData } from '@src/pages/Forms/ProbationTemplate/common'
import { isEmpty } from 'lodash'

const General = () => {
  const { values } = useLapeContext<ProbationTemplateInterface>()
  const params = useParams<{ id: string }>()
  const [rulesSidebarOpen, setRulesSidebarOpen] = useState(false)
  const hasRulesSet = !isEmpty(values.rules)

  const {
    previewData,
    lastTimelineInd,
    monthsOptions,
    weeksOptions,
    numberOfCheckpointsOptions,
    onMonthsChange,
    recalculatePreview,
    loading,
  } = useGetProbationTemplateData()

  return (
    <>
      <PageHeader
        pb="s-8"
        title={`${params.id ? 'Edit' : 'New'} Probation Template`}
        subtitle={params.id ? values.name : 'Create a new probation cycle template'}
        backUrl={ROUTES.PERFORMANCE.PROBATION.TEMPLATES}
      />
      <PageBody>
        {!values.id && <TemplateBanner />}
        <AutoStepper>
          <NewStepperTitle title="General info" />
          <InputGroup>
            <LapeNewInput name="name" label="Template name" required />
            <InputGroup variant="horizontal">
              <LapeRadioSelectInput<{ id: number }>
                name="amount_of_months"
                label="Duration of probation (months)"
                message={
                  previewData?.total_weeks && `Total weeks: ${previewData?.total_weeks}`
                }
                options={monthsOptions}
                value={
                  monthsOptions.find(el => el.value.id === values.amount_of_months)?.value
                }
                onChange={onMonthsChange}
                disabled={loading}
                required
              />
            </InputGroup>
            <LapeNewInput name="policy_document_link" label="Policy document link" />

            <NewStepperTitle
              title="Timeline"
              subtitle={
                <>
                  Define the number of checkpoints and their review periods.
                  <br />
                  All templates have steps for goal setting and final recommendation.
                </>
              }
              mb="s-4"
            />

            <Item>
              <Flex alignItems="center" justifyContent="space-between" width="100%">
                <Flex alignItems="center" width="140%">
                  <Avatar
                    useIcon={Target}
                    color="deep-grey"
                    backgroundColor="grey-tone-10"
                  />
                  <Item.Title ml="s-16">Goal setting deadline</Item.Title>
                </Flex>
                <LapeRadioSelectInput<{ id: number }>
                  name={`timelines[0].weeks_due_date`}
                  label="Time after joining"
                  options={weeksOptions}
                  value={
                    weeksOptions.find(
                      el => el.value.id === values.timelines?.[0].weeks_due_date,
                    )?.value
                  }
                  onAfterChange={option => {
                    if (option) {
                      recalculatePreview({
                        ...previewData,
                        goals_setting_week_after_start: option.id,
                      } as ProbationTemplatePreviewRequest)
                    }
                  }}
                  disabled={loading}
                />
              </Flex>
            </Item>
            <Item>
              <Flex alignItems="center" justifyContent="space-between" width="100%">
                <Flex alignItems="center" width="140%">
                  <Avatar
                    useIcon={CheckSuccess}
                    color="deep-grey"
                    backgroundColor="grey-tone-10"
                  />
                  <Item.Title ml="s-16">Number of checkpoints</Item.Title>
                </Flex>
                <LapeRadioSelectInput<{ id: number }>
                  name="num_checkpoints"
                  label="Number"
                  options={numberOfCheckpointsOptions}
                  value={
                    numberOfCheckpointsOptions.find(
                      el => el.value.id === values.num_checkpoints,
                    )?.value
                  }
                  onChange={option => {
                    if (option) {
                      values.num_checkpoints = option?.id
                      recalculatePreview({
                        ...previewData,
                        num_checkpoints: option.id,
                      } as ProbationTemplatePreviewRequest)
                    }
                  }}
                  disabled={loading}
                />
              </Flex>
            </Item>
            <Item>
              <Flex alignItems="center" justifyContent="space-between" width="100%">
                <Flex alignItems="center" width="140%">
                  <Avatar
                    useIcon={Flag}
                    color="deep-grey"
                    backgroundColor="grey-tone-10"
                  />
                  <VStack>
                    <Item.Title ml="s-16">Final decision deadline</Item.Title>
                    <Item.Description ml="s-16">
                      Consider the required notice period
                    </Item.Description>
                  </VStack>
                </Flex>
                <LapeRadioSelectInput<{ id: number }>
                  name={`timelines[${values.timelines?.[lastTimelineInd]}].weeks_due_date`}
                  label="Time before probation end"
                  options={weeksOptions}
                  value={
                    weeksOptions.find(
                      el =>
                        el.value.id ===
                        values.timelines?.[lastTimelineInd].weeks_due_date,
                    )?.value
                  }
                  onAfterChange={option => {
                    if (option) {
                      recalculatePreview({
                        ...previewData,
                        final_decision_week_before_end: option.id,
                      } as ProbationTemplatePreviewRequest)
                    }
                  }}
                  disabled={loading}
                />
              </Flex>
            </Item>

            {previewData?.checkpoints_timeline && (
              <TimelinePreview
                timelines={previewData.checkpoints_timeline}
                totalWeeks={previewData.total_weeks}
                isLoading={loading}
              />
            )}

            <NewStepperTitle
              title="Employee rules"
              subtitle="Define filters as rules to determine which new joiners should receive this template"
              mb="s-4"
            />
            <Button
              variant="secondary"
              size="sm"
              useIcon={hasRulesSet ? Pencil : Plus}
              onClick={() => setRulesSidebarOpen(!rulesSidebarOpen)}
            >
              {hasRulesSet ? 'Edit rules' : 'Set rules'}
            </Button>
            <EmployeeRulesList rules={values.rules} />
            <EmployeeRulesSidebarForm
              isOpen={rulesSidebarOpen}
              handleClose={() => setRulesSidebarOpen(false)}
            />

            <NewStepperTitle title="Additional settings" />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allow cycle extension as an option for final decision',
                description:
                  'Allows the final decision to select a new probation end date as a cycle extension',
              }}
              name="allow_extension"
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText={
            values.id
              ? 'Template was successfully updated'
              : 'Template was successfully created'
          }
          onAfterSubmit={res => {
            navigateReplace(
              pathToUrl(ROUTES.FORMS.PROBATION.TEMPLATE_VIEW, { id: res.id }),
            )
          }}
        >
          {values.id ? 'Save changes' : 'Create'}
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}

export default connect(General)
