import { AxiosPromise } from 'axios'
import { API } from '../constants/api'
import { api } from './index'
import { filterSortPageIntoQuery } from '../utils/table'
import { RevolutersInterface, RevolutersStatisticsDTO } from '../interfaces/revoluters'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { ExportRequest, GetRequestInterface } from '@src/interfaces'

export const getRevolutersItems = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<RevolutersInterface>> =>
  api.get(
    API.EMPLOYEES,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v3',
  )

export const getRevolutersExport: ExportRequest = (exportType, filterQuery) =>
  api.get(
    `${API.REVOLUTERS}/${exportType}`,
    { params: filterQuery, responseType: 'blob' },
    'v2',
  )

export const getRevolutersStatistics = async () =>
  api.get<RevolutersStatisticsDTO>(`${API.REVOLUTERS_STATISTICS}`)
