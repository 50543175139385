import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { General } from './General'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PageWrapper } from '@src/components/Page/Page'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import Regimes from '@src/pages/People/PeopleSubTabs/TimeOffPage/Regimes'
import PublicHolidays from '@src/pages/People/PeopleSubTabs/TimeOffPage/PublicHolidays'
import { PermissionTypes } from '@src/store/auth/types'
import { PageBody } from '@src/components/Page/PageBody'
import { TimeOffCategories } from '@src/pages/People/PeopleSubTabs/TimeOffPage/Categories'

export const TimeOffSettings = () => {
  useSetDocumentTitle('Settings', 'Timeoff')

  const tabs = [
    {
      to: ROUTES.SETTINGS.TIME_OFF.GENERAL,
      path: ROUTES.SETTINGS.TIME_OFF.GENERAL,
      component: General,
      title: 'General',
      canView: true,
    },
    {
      to: ROUTES.SETTINGS.TIME_OFF.REGIMES,
      path: ROUTES.SETTINGS.TIME_OFF.REGIMES,
      component: Regimes,
      title: 'Regimes',
      canView: [PermissionTypes.ViewTimeOffRegimes],
    },
    {
      to: ROUTES.SETTINGS.TIME_OFF.HOLIDAYS,
      path: ROUTES.SETTINGS.TIME_OFF.HOLIDAYS,
      component: PublicHolidays,
      title: 'Public Holidays',
      canView: [PermissionTypes.ViewPublicHolidays],
    },
    {
      to: ROUTES.SETTINGS.TIME_OFF.CATEGORIES,
      path: ROUTES.SETTINGS.TIME_OFF.CATEGORIES,
      component: TimeOffCategories,
      title: 'Categories',
      canView: [PermissionTypes.ViewTimeOffPolicyCategory],
    },
  ]
  const filteredTabs = tabs.filter(tab => tab.canView)

  return (
    <PageWrapper>
      <PageHeader
        title="Time off app settings"
        subtitle="Settings for the time off functionalities"
        backUrl={ROUTES.SETTINGS.ALL}
      />

      <TabBarNavigation tabs={filteredTabs} />

      <PageBody>
        <Switch>
          {filteredTabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component />
            </Route>
          ))}
        </Switch>
      </PageBody>
    </PageWrapper>
  )
}
