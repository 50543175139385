import React, { useEffect, useMemo, useRef, useState } from 'react'
import { add as addDate } from 'date-fns'
import styled from 'styled-components'
import { selectorKeys } from '@src/constants/api'
import { LocationInterface, RequisitionInterface } from '@src/interfaces/requisitions'
import { getRequisitionRecruiter } from '@src/api/recruitmentGroups'
import { teamsRequests } from '@src/api/teams'
import {
  checkAddRequisitionPermitted,
  getRequisitionTitlePosting,
  getStartDateOffset,
  useGetHiringEnabledLocations,
} from '@src/api/requisitions'
import { Color, InputGroup, List, Text } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ROUTES } from '@src/constants/routes'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { EmployeeInterface, IdStatuses, NameIdInterface } from '@src/interfaces/employees'
import SenioritiesSelect from '@src/pages/Forms/RequisitionForm/General/SenioritiesSelect'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { PermissionTypes } from '@src/store/auth/types'
import {
  changelogRequisitionRequests,
  fieldChangelogRequisitionRequests,
} from '@src/api/changelog'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import PipelineSidebar from '@src/pages/Forms/RequisitionForm/General/PipelineSidebar'
import MrtWidget from '@src/pages/Forms/RequisitionForm/Mrt/MrtWidget'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import set from 'lodash/set'
import BudgetImpact from '@src/pages/Forms/RequisitionForm/General/BudgetImpact'
import { employeesRequestsNew } from '@src/api/employees'
import { useGetSelectors } from '@src/api/selectors'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { useGetOrganisationSettings, useGetRequisitionSettings } from '@src/api/settings'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { getSpecialisationPreferredHiringLocations } from '@src/api/specialisations'
import { useGetHiringRiskLocations } from '@src/pages/Forms/RequisitionForm/utils'
import { SectionOptions } from '@src/interfaces/customFields'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import useIsCommercial from '@src/hooks/useIsCommercial'

export const BulletList = styled(List)`
  margin: 0px;
  padding-inline-start: 24px;
  list-style-type: disc;
`

export const getRequisitionInitialValues = (user: EmployeeInterface) => ({
  line_manager: {
    id: user.id,
    full_name: user.full_name,
    status: user.status?.id as IdStatuses,
  },
  headcount: 1,
})

export const changelogApiRequisition = {
  form: changelogRequisitionRequests,
  field: fieldChangelogRequisitionRequests,
}

export const getLocationColor = (
  name: string,
  highRiskLocations: Set<string>,
  mediumRiskLocations: Set<string>,
) => {
  if (highRiskLocations.has(name)) {
    return Color.RED
  }

  if (mediumRiskLocations.has(name)) {
    return Color.WARNING
  }

  return undefined
}

const General = () => {
  const mounted = useRef(false)
  const form = useLapeContext<RequisitionInterface>()
  const { values, dirty, errors } = form
  const [isSideHelpOpen, setIsSideHelpOpen] = useState(false)
  const [startDateOffset, setStartDateOffset] = useState<number>()
  const { data: enabledLocationsOptions } = useGetHiringEnabledLocations()
  const { data: locationsSelector } = useGetSelectors(selectorKeys.location)
  const { highRiskLocations, mediumRiskLocations } = useGetHiringRiskLocations()

  useEffect(() => {
    values.main_location = (values.locations ?? [])[0]
  }, [values.locations])

  const [requisitionTitles, setRequisitionTitles] = useState<
    JobPostingInterface[] | null
  >(null)
  const { data: specialisations } = useGetSelectors<SpecialisationInterface>(
    selectorKeys.approved_pending_specialisations,
  )

  const { data: organisationSettings } = useGetOrganisationSettings()
  const { data: requisitionSettings } = useGetRequisitionSettings()
  const isCommercial = useIsCommercial()

  const enableMinStartDate =
    requisitionSettings?.enable_minimum_potential_start_date_validation

  const enableLocationLimitations = organisationSettings?.enable_location_limitations

  const allLocationsOptions = useMemo(() => {
    if (!locationsSelector) {
      return []
    }

    const sorted = locationsSelector.map(opt => {
      const risk = enabledLocationsOptions?.find(
        item => item.id === opt.id,
      )?.country_employment_legal_risk

      return {
        label: opt.name,
        value: {
          ...opt,
          country_employment_legal_risk: risk,
          color: getLocationColor(opt.name, highRiskLocations, mediumRiskLocations),
        } as LocationInterface,
        disabled: !enabledLocationsOptions?.find(item => item.id === opt.id),
      }
    })

    sorted.sort((a, b) => (a.disabled === b.disabled ? 0 : b.disabled ? -1 : 1))

    return sorted
  }, [
    locationsSelector,
    enabledLocationsOptions,
    enableLocationLimitations,
    highRiskLocations,
    mediumRiskLocations,
  ])

  useEffect(() => {
    if (!values.seniority_max?.id) {
      return
    }

    const fetchStartDateOffset = async () => {
      const { data } = await getStartDateOffset(values.seniority_max!.id)
      setStartDateOffset(data.minimum_start_date_offset_days)
    }

    if (enableMinStartDate) {
      setStartDateOffset(undefined)
      fetchStartDateOffset()
    }
  }, [values.seniority_max?.id, enableMinStartDate])

  const fetchRequisitionTitles = async (resetTitle?: boolean) => {
    if (!values.specialisation?.id) {
      return
    }

    const resp = await getRequisitionTitlePosting(+values.specialisation.id)
    if (resp.data.publish_postings && resp.data.options.length) {
      if (resetTitle) {
        values.requisition_title = ''
      }
      setRequisitionTitles(resp.data.options)
    } else {
      setRequisitionTitles(null)
      if (resetTitle) {
        updateTitle()
      }
    }
  }

  useEffect(() => {
    fetchRequisitionTitles(mounted.current)

    if (!mounted.current) {
      mounted.current = true
    }
  }, [values.specialisation?.id])

  useEffect(() => {
    if (
      !dirty ||
      !values.specialisation?.id ||
      !values.seniority_max?.id ||
      !values.main_location?.id
    ) {
      return
    }

    updateDefaultRecruiter()
  }, [values.specialisation?.id, values.seniority_max?.id, values.main_location?.id])

  const canEditBackfilledEmployees =
    !values.id ||
    values.field_options?.permissions?.includes(PermissionTypes.ViewBackfillsRequisitions)

  const updateTitle = () => {
    const val = values
    const specialisation = val.specialisation ? val.specialisation.name : ''
    const department = ''
    values.requisition_title = specialisation + department
  }

  const updateDefaultRecruiter = () => {
    if (values.main_location) {
      getRequisitionRecruiter(
        +values.specialisation.id,
        values.seniority_max!.id,
        values.main_location.id,
      ).then(recruiter => {
        if (recruiter) {
          values.recruiter = recruiter
        }
      })
    }
  }

  const onBackfillChanged = () => {
    if (!values.backfill) {
      values.headcount = 1
      return
    }
    values.headcount = values.backfill_employees?.length || 0
  }

  const onBackfillEmployeeChange = async () => {
    values.headcount = values.backfill_employees?.length || 0

    if (
      values.id ||
      !values.backfill_employees ||
      values.backfill_employees?.length !== 1 ||
      (values.specialisation && values.seniority_min && values.team)
    ) {
      return
    }

    employeesRequestsNew
      .get({ id: String(values.backfill_employees[0].id) })
      .then(res => {
        const employee = res.data
        if (!values.specialisation) {
          const employeeSpecialisation = specialisations?.find(
            specialisation => specialisation.id === employee.specialisation?.id,
          )
          if (employeeSpecialisation) {
            values.specialisation = employeeSpecialisation
          }
        }
        if (!values.seniority_min && !values.seniority_max) {
          values.seniority_min = employee.seniority
          values.seniority_max = employee.seniority
        }
        if (!values.team) {
          values.team = employee.team
        }
      })
  }

  const onTeamChanged = async (option: NameIdInterface | null) => {
    updateTitle()
    if (option?.id) {
      teamsRequests.getItem(option.id).then(result => {
        if (result && result.data && result.data.team_owner) {
          values.line_manager = result.data.team_owner
        }
      })

      try {
        await checkAddRequisitionPermitted(option.id)
      } catch (e) {
        if (e?.response?.status === 403) {
          set(
            errors,
            'team',
            'You don’t have permission to create requisitions for this department, please ask the head of department of this team to delegate this permission to you',
          )
        }
      }
    }
  }

  const availableStartWorkingDate = useMemo(() => {
    if (enableMinStartDate) {
      return addDate(
        values.creation_date_time ? new Date(values.creation_date_time) : new Date(),
        { days: startDateOffset },
      )
    }

    return new Date()
  }, [values.creation_date_time, startDateOffset, enableMinStartDate])

  const title = requisitionTitles ? (
    <LapeRadioSelectInput
      name="requisition_title"
      label="Select the posting title"
      value={requisitionTitles.find(item => item.name === values.requisition_title)}
      options={requisitionTitles.map(item => ({
        label: item.name,
        value: item,
      }))}
      onChange={option => {
        if (option) {
          values.requisition_title = option.name
        }
      }}
    />
  ) : (
    <LapeNewInput required name="requisition_title" label="Requisition title" />
  )

  const backfillEmployees = values.backfill && canEditBackfilledEmployees && (
    <LapeNewMultiSelect
      placeholder="Employee(s) being backfilled"
      name="backfill_employees"
      selector={selectorKeys.backfill_employee}
      message="Backfilled employees are only visible to the approvers of requisition and cost control team"
      required
      variant="grey"
      onAfterChange={onBackfillEmployeeChange}
    />
  )

  const location = (
    <>
      <LapeNewMultiSelect<LocationInterface>
        name="locations"
        placeholder="Locations"
        required
        message={
          enableLocationLimitations ? (
            <>
              {isCommercial ? (
                <>
                  Selecting any location other than the default ones will be highlighted
                </>
              ) : (
                <>
                  Selecting any location other than the default one will be flagged to
                  Cost Control. Selecting high employment risk locations will require
                  additional approval from Legal. Please justify your reasons for
                  selecting these locations in the creation reason field to enable
                  smoother approval.
                </>
              )}
            </>
          ) : null
        }
        options={allLocationsOptions}
        disableSorting
        clearable
      >
        {option => (
          <>
            {option.label}
            {option.disabled && (
              <Text variant="caption" use="div">
                The location is disabled for hiring
              </Text>
            )}
          </>
        )}
      </LapeNewMultiSelect>
      {(!!highRiskLocations.size || !!mediumRiskLocations.size) && (
        <ActionWidget
          aria-label="cost-control-warning"
          title="Some of the locations you selected are not part of the default locations list. Please provide justification for adding them in the “Creation reason” field below."
          text={
            <>
              {!!mediumRiskLocations.size && (
                <Text color={Color.WARNING} display="block">
                  {isCommercial
                    ? 'The following locations are not in the default locations list for the selected specialisation'
                    : 'Will be flagged to Cost Control'}
                  : {Array.from(mediumRiskLocations).join(', ')}
                </Text>
              )}
              {!!highRiskLocations.size && (
                <Text color={Color.RED} display="block">
                  {isCommercial
                    ? 'The following locations are considered having high employment risk by the admin of the Recruitment app'
                    : 'Will require additional Legal approval'}
                  : {Array.from(highRiskLocations).join(', ')}
                </Text>
              )}
            </>
          }
        />
      )}
    </>
  )

  const confidential = requisitionSettings?.enable_confidential_button && (
    <InputGroup>
      <LapeNewSwitch
        name="is_confidential"
        label="Confidential"
        disabled={!!values.status}
        itemTypeProps={{
          title: 'Confidential',
        }}
      />
      {values.is_confidential && (
        <ActionWidget
          avatarColor="grey-tone-50"
          title="What does this mean?"
          text={
            <BulletList variant="compact" use="ol">
              <List.Item>
                This requisition is confidential and visible only to its creator.
              </List.Item>
              <List.Item>
                To allow others to view and access it, assign permission through the
                'Manage permissions' button.
              </List.Item>
              <List.Item>
                Notifications are <strong>not</strong> shared for confidential
                requisitions.
              </List.Item>
              <List.Item>
                Confidential requisitions are excluded from any statistics and reports
                unless access is granted.
              </List.Item>
              <List.Item>
                No public posting will be created or published for this requisition.
              </List.Item>
            </BulletList>
          }
        />
      )}
    </InputGroup>
  )

  return (
    <>
      <PipelineSidebar
        isOpen={isSideHelpOpen}
        onClose={() => setIsSideHelpOpen(false)}
        specialisationId={values.specialisation?.id}
      />
      <PageBody>
        <NewStepperSectionCustomFields
          sectionId={SectionOptions.Requisitions}
          requisitionId={values.id}
        >
          {requisitionSettings?.enable_is_mrt_jira_ticket_url_confirmed_editing && (
            <MrtWidget />
          )}
          <AutoStepperTitle title="Requisition details" />
          <InputGroup>
            {title}
            <LapeNewRadioButtons
              name="backfill"
              options={[
                { label: 'New hire', value: false },
                { label: 'Backfill (Replacement)', value: true },
              ]}
              defaultOptionIndex={0}
              variant="cell"
              onAfterChange={onBackfillChanged}
            />
            {backfillEmployees}
            {!values.backfill && (
              <InputGroup variant="horizontal">
                <LapeNewInput name="headcount" label="Headcount" type="number" required />
                {values.id && (
                  <LapeNewInput
                    name="hired_headcount"
                    label="Hired Headcount"
                    type="number"
                    required
                    disabled={!requisitionSettings?.enable_hired_headcount_editing}
                  />
                )}
              </InputGroup>
            )}
            <LapeDatePickerInput
              required
              label="Ideal start date"
              name="potential_start_date"
              disabledDays={{ before: availableStartWorkingDate }}
              initialMonth={availableStartWorkingDate}
              /** UI Kit `DateInput` doesn't update `initialMonth` when `availableStartWorkingDate` changes, need to force rerender */
              key={availableStartWorkingDate.toDateString()}
            />
            {confidential}
            <LapeRadioSelectInput
              name="recruiter"
              label="Recruiter"
              selector={selectorKeys.employee}
            />
            {values.owner && (
              <LapeNewTextArea name="owner.full_name" label="Created By" disabled />
            )}
          </InputGroup>
          <AutoStepperTitle title="Position details" />
          <InputGroup>
            <LapeRadioSelectInput
              name="specialisation"
              label="Role (Specialisation) hiring for"
              options={
                specialisations?.map(option => ({
                  label: option.name,
                  value: option,
                })) || []
              }
              onAfterChange={async val => {
                if (!val?.id) {
                  return
                }

                const resp = await getSpecialisationPreferredHiringLocations(val.id)

                if (resp.data) {
                  values.locations = resp.data
                }
              }}
              referenceUrl={
                values.specialisation?.id
                  ? getLocationDescriptor(
                      pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                        id: values.specialisation.id,
                      }),
                    )
                  : undefined
              }
            />
            <SenioritiesSelect />
          </InputGroup>
          <AutoStepperTitle title="Team & Location" />
          <InputGroup>
            <LapeRadioSelectInput<NameIdInterface>
              name="team"
              required
              onAfterChange={onTeamChanged}
              label="Team"
              selector={selectorKeys.team}
            />
            <LapeRadioSelectInput
              name="line_manager"
              label="Line manager"
              selector={selectorKeys.employees_for_teams}
            />
            {location}
          </InputGroup>
          {!!requisitionSettings?.enable_budget_impact && (
            <>
              <AutoStepperTitle title="Budget impact" />
              <BudgetImpact />
            </>
          )}
          <AutoStepperTitle title="Comments" />
          <InputGroup>
            <LapeNewTextArea
              name="description"
              required
              label="Creation reason / message for recruiter"
            />
          </InputGroup>
        </NewStepperSectionCustomFields>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup previewUrl={ROUTES.FORMS.REQUISITION.ROLE} useValidator />
      </PageActions>
    </>
  )
}

export default General
