import React from 'react'
import { Link } from 'react-router-dom'
import {
  differenceInDays,
  formatDistanceToNow,
  isFuture,
  isToday,
  isPast,
  isYesterday,
  isTomorrow,
} from 'date-fns'
import {
  Button,
  chain,
  Color,
  Flex,
  Link as UILink,
  Text,
  TextButton,
} from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { ROUTES } from '@src/constants/routes'
import { ReviewCategory, ReviewStatuses } from '@src/interfaces/performance'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { SortedTimelineEventsInterface } from '.'
import { EmployeeInterface } from '@src/interfaces/employees'
import isSameDay from 'date-fns/isSameDay'

const getDateDistance = (date: string) => {
  if (!date) {
    return <>-</>
  }

  const dayDiff = differenceInDays(new Date(date), new Date())
  return (
    <Text color={dayDiff <= 3 ? Color.ORANGE : Color.GREY_TONE_50}>
      {`in ${formatDistanceToNow(new Date(date))}`}
    </Text>
  )
}

export const getStepState = (
  startDate: string,
  endDate: string,
  meetingType?: string,
) => {
  if (meetingType === 'line_manager' || meetingType === 'functional_manager') {
    return 'pending'
  }

  if (isFuture(new Date(startDate))) {
    return 'default'
  }

  if (isPast(new Date(endDate))) {
    return 'done'
  }

  return 'pending'
}

export const getStepDescription = (
  employeeId: number,
  event: SortedTimelineEventsInterface,
  userId: number,
  onViewNotesClick?: () => void,
) => {
  if (
    event.meetingType === 'line_manager' ||
    event.meetingType === 'functional_manager'
  ) {
    let dayReference = ''
    if (isSameDay(new Date(), new Date(event.end_period))) {
      dayReference = 'today'
    } else if (isYesterday(new Date(event.end_period))) {
      dayReference = 'yesterday'
    } else if (isTomorrow(new Date(event.end_period))) {
      dayReference = 'tomorrow'
    }

    return (
      <>
        <Text use="p">
          {`${formatDate(event.start_period, 'EEE, d MMM yyyy')} - `}
          <Text color={Color.ORANGE}>{dayReference}</Text>
        </Text>
        <TextButton onClick={onViewNotesClick}>View notes</TextButton>
      </>
    )
  }

  if (isPast(new Date(event.end_period))) {
    return (
      <Text>{`Closed ${formatDate(
        event.end_period,
        'd MMMM yyyy',
      )} - ${formatDistanceToNow(new Date(event.end_period))} ago`}</Text>
    )
  }

  if (isFuture(new Date(event.start_period)) && event.type !== 'managers_meetings') {
    return (
      <Text>
        {`Opens ${formatDate(event.start_period, 'd MMMM yyyy')} - `}
        {getDateDistance(event.start_period)}
      </Text>
    )
  }

  if (event.type === 'managers_meetings') {
    if (isToday(new Date(event.start_period))) {
      return (
        <>
          <Text use="p">
            {`${formatDate(event.start_period, 'd MMMM yyyy')} - `}
            <Text color={Color.ORANGE}>today</Text>
          </Text>
          <UILink href={event?.link} rel="noreferrer noopener" target="_blank">
            Join meeting
          </UILink>
        </>
      )
    }

    if (isFuture(new Date(event.start_period))) {
      return (
        <Text>
          {`${formatDate(event.start_period, 'd MMMM yyyy')} - `}
          {getDateDistance(event.start_period)}
        </Text>
      )
    }
  }

  if (event.type === 'promotion_nominations') {
    if (isFuture(new Date(event.start_period))) {
      return (
        <Text>
          {`Opens ${formatDate(event.start_period, 'd MMMM yyyy')} - `}
          {getDateDistance(event.start_period)}
        </Text>
      )
    }

    return (
      <Text>
        {`Closes ${formatDate(event.end_period, 'd MMMM yyyy')} - `}
        {getDateDistance(event.end_period)}
      </Text>
    )
  }

  if (event.type === 'kpi_reviews') {
    return (
      <>
        <Text use="p">
          {`Closes ${formatDate(event.end_period, 'd MMMM yyyy')} - `}
          {getDateDistance(event.end_period)}
        </Text>
        <UILink
          target="_blank"
          to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.PERSONAL, {
            id: employeeId,
          })}
          use={Link}
        >
          View KPIs
        </UILink>
      </>
    )
  }

  if (event.type === 'reviews') {
    const pendingReview =
      event?.reviewers?.filter(
        item => item.status === ReviewStatuses.pending && item.reviewer.id === userId,
      ) || []

    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column">
          <Text>
            {`Closes ${formatDate(event.end_period, 'd MMMM yyyy')} - `}
            {getDateDistance(event.end_period)}
          </Text>
          <UILink
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
              id: employeeId,
            })}
            use={Link}
          >
            View feedback
          </UILink>
        </Flex>
        {pendingReview?.length > 0 &&
          pendingReview[0].category === ReviewCategory.Performance && (
            <Button
              size="sm"
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL, {
                id: pendingReview[0].id,
                employeeId,
              })}
              use={InternalLink}
              useIcon={Plus}
            >
              Add review
            </Button>
          )}
      </Flex>
    )
  }

  return <>-</>
}

export const getStepTitle = (
  event: SortedTimelineEventsInterface,
  employee: EmployeeInterface,
  cycleName?: string,
) => {
  if (event?.category) {
    return `1:1 with your ${event.category.replace(/_|-|\./g, ' ')}`
  }
  if (event?.meetingType === 'line_manager') {
    return chain(`1:1 with ${employee.line_manager.full_name}`, 'LM')
  }
  if (event?.meetingType === 'functional_manager') {
    return chain(`1:1 with ${employee.quality_control?.full_name}`, 'FM')
  }
  // removing current cycle name part, for example "Q2 2023 Goal setting" --> "Goal setting"
  const re = new RegExp(`${cycleName} `, 'g')

  return event?.review_name ? event?.review_name.replace(re, '') : '-'
}
