import React from 'react'
import { Button, InputGroup, Side } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ApplicationFormSettingsInterface } from '@src/interfaces/settings'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import ApplicationFormEditQuestion from '@src/pages/Settings/JobPosting/ApplicationForm/ApplicationFormEditQuestion'
import { Plus } from '@revolut/icons'
import { ApplicationFormEnumQuestionOptions } from '@src/interfaces/applicationForm'
import SideBar from '@components/SideBar/SideBar'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

type Props = {
  idx: number | null
  onClose: () => void
}

const ApplicationFormEditSection = ({ idx, onClose }: Props) => {
  const { values } = useLapeContext<ApplicationFormSettingsInterface>()
  const section = idx === null ? undefined : values.global_sections?.[idx]
  const permissions = useSelector(selectPermissions)
  const disableAdd = !permissions.includes(PermissionTypes.AddApplicationFormPreferences)
  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeApplicationFormPreferences,
  )

  if (idx === null || !section) {
    return null
  }

  const onClickAddQuestion = () => {
    section.questions.push({
      question_type: ApplicationFormEnumQuestionOptions[0],
      options: [],
    })
  }

  return (
    <SideBar variant="wide" isOpen onClose={onClose} title="Edit section">
      <InputGroup>
        <LapeNewInput
          name={`global_sections.${idx}.title`}
          data-testid={`global_sections.${idx}.title`}
          label="Section title"
          required
          disabled={disableEdit}
        />
        <LapeNewInput
          name={`global_sections.${idx}.description`}
          data-testid={`global_sections.${idx}.description`}
          label="Section description"
          disabled={disableEdit}
        />
      </InputGroup>
      {section.questions.map((_, questionIdx) => (
        <ApplicationFormEditQuestion
          key={questionIdx}
          sectionIdx={idx}
          questionIdx={questionIdx}
        />
      ))}
      <Button
        useIcon={Plus}
        variant="secondary"
        size="sm"
        onClick={onClickAddQuestion}
        mt="s-16"
        mb="s-40"
        disabled={disableAdd}
      >
        Add question
      </Button>
      <Side.Actions>
        <Button aria-label="Confirm section" onClick={onClose}>
          Confirm
        </Button>
      </Side.Actions>
    </SideBar>
  )
}

export default ApplicationFormEditSection
