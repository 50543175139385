import React from 'react'
import { FinalGrade, KeeperGrade } from '@src/interfaces/performance'
import styled from 'styled-components'
import Tooltip, { placement as TooltipPlacement } from '@components/Tooltip/Tooltip'
import { useTheme } from '@src/styles/theme'
import PerformanceGrade from '@components/PerformanceGrade/PerformanceGrade'
import { Text, VStack } from '@revolut/ui-kit'
import { HStack } from '@revolut/ui-kit'
import KeeperGradeLabel from '@components/KeeperGradeLabel/KeeperGradeLabel'

interface Props {
  grades: { value?: FinalGrade | KeeperGrade | null; reviewer: string }[]
  placement: TooltipPlacement
  children?: React.ReactNode
}

const TooltipBody = styled.div`
  padding: 10px;
  white-space: nowrap;
  border-radius: 4px;
  box-shadow: 0 14px 28px rgba(25, 28, 31, 0.04), 0 8px 16px rgba(25, 28, 31, 0.04),
    0px -1px 0px rgba(25, 28, 31, 0.04);
`

const GradeLabel = styled(PerformanceGrade)`
  min-width: 100px;
  font-size: 14px;
  padding: 6px;
  text-align: center;
  border-radius: 4px;
`

const KeeperLabel = styled(KeeperGradeLabel)`
  min-width: 100px;
  font-size: 14px;
  padding: 6px;
  text-align: center;
  border-radius: 4px;
`

const RecommendationTooltip = ({ grades, placement, children }: Props) => {
  const theme = useTheme()

  if (!grades?.length) {
    return <>{children}</>
  }

  const renderValue = (value?: FinalGrade | KeeperGrade | null) => {
    if (!value) {
      return (
        <Text color="foreground" variant="caption">
          -
        </Text>
      )
    }

    if (Object.values<string>(FinalGrade).includes(value)) {
      return <GradeLabel grade={value as FinalGrade} />
    }

    if (Object.values<string>(KeeperGrade).includes(value)) {
      return <KeeperLabel grade={value as KeeperGrade} />
    }

    return (
      <Text color="foreground" variant="caption">
        -
      </Text>
    )
  }

  return (
    <Tooltip
      body={
        <TooltipBody>
          <VStack gap="s-8">
            {grades.map((item, i) => {
              return (
                <HStack gap="s-16" key={i} align="center">
                  {renderValue(item.value)}
                  <Text>{item.reviewer}</Text>
                </HStack>
              )
            })}
          </VStack>
        </TooltipBody>
      }
      placement={placement}
      backgroundColor={theme.colors.background}
    >
      {children}
    </Tooltip>
  )
}

export default RecommendationTooltip
