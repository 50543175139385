import React from 'react'
import { Group, Box, Text, VStack, Color } from '@revolut/ui-kit'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API, selectorKeys } from '@src/constants/api'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { APPROVAL_PROCESS_GROUPS_API } from '@src/api/settings'

export const KPI = () => {
  const queryClient = useQueryClient()
  const { values } = useLapeContext<PerformanceSettingsInterface>()

  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangePerformancePreferences)

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="Settings that apply to all categories of KPIs"
          />
          <VStack space="s-24">
            <Group>
              <VStack space="s-8" p="s-16">
                <Text variant="primary">KPI types</Text>
                <Text variant="caption" color={Color.GREY_TONE_50}>
                  What types of KPIs are allowed
                </Text>
              </VStack>

              <Box pb="s-8" px="s-12">
                <LapeNewMultiSelect
                  name="allowed_kpi_types"
                  placeholder="Allowed KPI types"
                  selector={selectorKeys.allowed_kpi_categories}
                  message="This will restrict what kind of KPIs can be added in the system."
                  required
                  disabled={disableEdit}
                />
              </Box>
              <Box pb="s-8" px="s-12">
                <LapeNewMultiSelect
                  name="allowed_kpi_strategies"
                  placeholder="Allowed KPI strategies"
                  selector={selectorKeys.allowed_kpi_strategies}
                  message="This will restrict the strategies possible for measuring the progress of a KPI."
                  required
                  disabled={disableEdit}
                />
              </Box>
            </Group>
            <LapeMultiInput
              title="Measuring units"
              name="kpi_measuring_units"
              valueFieldName={undefined}
              description="What unit will the KPI progress be measured in. Ex: percent (%), dollars ($), etc."
              label="E.g. percent (%)"
              withCell
              disabled={disableEdit}
            />
            <Group>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Allow Function and Role KPIs',
                  description: 'Allow to set KPIs for functional level units',
                }}
                name="enable_function_role_specialisation_kpis"
                disabled={disableEdit}
              />

              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enforce the parent KPI field as mandatory',
                  description:
                    'Enabling this will require all created KPIs to be connected to a higher level parent KPI (e.g. a department KPI set as the parent for a team KPI).',
                }}
                name="enable_mandatory_parent_kpi"
              />
            </Group>
          </VStack>

          <NewStepperTitle
            title="Employee KPIs"
            subtitle="Approval settings for Employee KPIs"
          />
          <ApprovalSteps
            title="Enable Employee KPIs approval"
            description="Whenever a new Employee KPI is created or an existing one is changed it asks for approval"
            switchField="enable_employee_kpi_approvals"
            approvalProcess="employee_kpis_approvals"
            entity="employee KPI"
            disabled={disableEdit}
            formRef={values}
          />

          <NewStepperTitle title="Team KPIs" subtitle="Approval settings for Team KPIs" />
          <ApprovalSteps
            title="Enable Team KPIs approval"
            description="Whenever a new Team KPI is created or an existing one is changed it asks for approval"
            switchField="enable_team_kpi_approvals"
            approvalProcess="team_kpis_approvals"
            entity="team KPI"
            disabled={disableEdit}
            formRef={values}
          />

          <NewStepperTitle
            title="Department KPIs"
            subtitle="Approval settings for Department KPIs"
          />
          <ApprovalSteps
            title="Enable Department KPIs approval"
            description="Whenever a new Department KPI is created or an existing one is changed it asks for approval"
            switchField="enable_department_kpi_approvals"
            approvalProcess="department_kpis_approvals"
            entity="department KPI"
            disabled={disableEdit}
            formRef={values}
          />

          <NewStepperTitle
            title="Company KPIs"
            subtitle="Approval settings for Company KPIs"
          />
          <ApprovalSteps
            title="Enable Company KPIs approval"
            description="Whenever a new Company KPI is created or an existing one is changed it asks for approval"
            switchField="enable_company_kpi_approvals"
            approvalProcess="company_kpis_approvals"
            entity="company KPI"
            disabled={disableEdit}
            formRef={values}
          />

          {values.enable_function_role_specialisation_kpis ? (
            <>
              <NewStepperTitle
                title="Function KPIs"
                subtitle="Approval settings for Function KPIs"
              />
              <ApprovalSteps
                title="Enable Function KPIs approval"
                description="Whenever a new Function KPI is created or an existing one is changed it asks for approval "
                switchField="enable_function_kpi_approvals"
                approvalProcess="function_kpis_approvals"
                entity="function KPI"
                disabled={disableEdit}
                formRef={values}
              />

              <NewStepperTitle
                title="Role KPIs"
                subtitle="Approval settings for Role KPIs"
              />
              <ApprovalSteps
                title="Enable Role KPIs approval"
                description="Whenever a new Role KPI is created or an existing one is changed it asks for approval"
                switchField="enable_role_kpi_approvals"
                approvalProcess="role_kpis_approvals"
                entity="role KPI"
                disabled={disableEdit}
                formRef={values}
              />
            </>
          ) : null}
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.PERFORMANCE_SETTINGS)
            queryClient.invalidateQueries(APPROVAL_PROCESS_GROUPS_API)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
