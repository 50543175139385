import {
  differenceInDays,
  Duration,
  format,
  formatDistance,
  getYear,
  intervalToDuration,
  isDate,
  startOfToday,
} from 'date-fns'
import round from 'lodash/round'
import { decl } from '@src/utils/string'
import pluralize from 'pluralize'
import {
  meetingRecurrence,
  rruleInterface,
  rruleRecurrence,
} from '@src/interfaces/meetingsTracker'
import isString from 'lodash/isString'

export const formatPeriod = (rowFrom: string | Date, rowTo: string | Date) => {
  const fromDate = (isDate(rowFrom) ? rowFrom : new Date(rowFrom)) as Date
  const toDate = (isDate(rowTo) ? rowTo : new Date(rowTo)) as Date
  if (isDate(fromDate) && isDate(toDate)) {
    const sameYear = getYear(fromDate) === getYear(toDate)
    if (sameYear) {
      return `${format(fromDate, 'd MMM')} - ${format(toDate, 'd MMM yyyy')}`
    }
    return `${format(fromDate, 'd MMM yyyy')} - ${format(toDate, 'd MMM yyyy')}`
  }
  return 'Invalid date period'
}

export const formatPeriodWithDays = (rowFrom?: string | null, rowTo?: string | null) => {
  if (rowFrom && !rowTo) {
    const fromDate = new Date(rowFrom)
    return `${format(fromDate, 'd MMM yyyy')} - End date not set`
  }
  if (!rowFrom && rowTo) {
    const toDate = new Date(rowTo)
    return `Start date not set - ${format(toDate, 'd MMM yyyy')}`
  }
  if (rowFrom && rowTo) {
    const fromDate = new Date(rowFrom)
    const toDate = new Date(rowTo)
    const period = formatPeriod(fromDate, toDate)
    const days = differenceInDays(toDate, fromDate)
    return `${period} (${pluralize('day', days, true)})`
  }
  return 'Dates not set'
}

export const formatDateTimePeriod = (timeFrom: string, timeTo: string) => {
  const fromDate = new Date(timeFrom)
  const toDate = new Date(timeTo)
  const date = format(fromDate, 'd MMM')
  const weekday = format(fromDate, 'EEE').slice(0, 3)
  const fromTime = format(fromDate, 'HH:mm')
  const toTime = format(toDate, 'HH:mm')
  return `${weekday} ${date}, ${fromTime} - ${toTime}`
}

export const formatDateWithWeekday = (date: string | Date, fmt?: string) =>
  format(new Date(date), fmt || 'EEE d MMM')

export const getTotalTimeFromMinutes = (minutesAmount: number) => {
  const hours = Math.floor(minutesAmount / 60)
  const minutes = hours === 0 ? minutesAmount : minutesAmount % (hours * 60)
  return `${hours} h ${minutes} min`
}

export const formatMoney = (
  money?: number | string | null,
  currency: string = 'GBP',
  locale: string = 'en-US',
) => {
  if (!money) {
    money = 0
  }
  return money.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: money > 1000 ? 0 : 2,
  })
}

export const formatMoneyWithCode = (
  money?: number | string | null,
  currency?: string,
) => {
  if (!currency) {
    return '-'
  }
  if (!money) {
    money = 0
  }
  return `${money.toLocaleString()} ${currency}`
}

export const formatMoneyMillions = (
  money?: number | string,
  currency: string = 'GBP',
  locale: string = 'en-US',
) => {
  if (!money) {
    money = 0
  }
  money = parseFloat(money as string)
  if (money >= 1000000) {
    return `${(money / 1000000).toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })}M`
  }
  if (money >= 10000) {
    return `${(money / 1000).toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}k`
  }

  return money.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
}

export const formatNumber = (
  num?: number,
  precision?: number,
  locale: string = 'en-US',
) => {
  if (!num) {
    num = 0
  }

  const maximumFractionDigits = precision ?? (num > 1000 ? 0 : 2)

  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits,
  }).format(num)
}

export const formatPercentage = (num: number | null, precision?: number) => {
  if (num === null) {
    return 'N/A'
  }
  return `${round(num * 100, precision)}%`
}

export const formatNumberMillions = (
  num?: number,
  precision: number = 2,
  locale: string = 'en-US',
) => {
  if (!num) {
    num = 0
  }
  if (num >= 1000000) {
    return `${new Intl.NumberFormat(locale, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num / 1000000)}M`
  }
  if (num >= 10000) {
    return `${new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num / 1000)}k`
  }
  if (num >= 1000) {
    return `${new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }).format(num / 1000)}k`
  }
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: precision,
  }).format(num)
}

export const formatListLetters = (i: number) =>
  (i + 10 + 10 * Math.floor(i / 26)).toString(36)

export const formatDate = (date: string | Date, fmt?: string) =>
  format(new Date(date), fmt || 'd MMM yyyy')

export const formatTimeAgo = (date: string | Date) => {
  return formatDistance(new Date(date), new Date(), { addSuffix: true })
}

export const formatWithoutTimezone = (date: string, withTime?: boolean) => {
  const dt = new Date(date)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
  const schema = withTime ? 'd MMM yyyy HH:mm' : 'd MMM yyyy'
  return format(dtDateOnly, schema)
}

export const formatDateDistance = (date: Date) => {
  const diff = differenceInDays(date, startOfToday())
  const diffMonths = Math.ceil(diff / 30)
  if (diff <= 30) {
    const diffWeeks = Math.ceil(diff / 7) || 1
    if (diffWeeks > 3) {
      return '1 month'
    }
    return `${diffWeeks} ${decl(diffWeeks, 'week', 'weeks')}`
  }
  if (diffMonths >= 6) {
    return '6 months +'
  }
  return `${diffMonths} ${decl(diffMonths, 'month', 'months')}`
}

export const formatToOrdinal = (number: number) => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = number % 100
  return number + (s[(v - 20) % 10] || s[v] || s[0])
}

export const formatDateTime = (date: string | null) =>
  date ? format(new Date(date), 'd MMM yyyy, HH:mm') : ''

export const getDatesDuration = (
  startDate: string | Date,
  endDate: string | Date,
): Duration => {
  return intervalToDuration({
    start: new Date(startDate),
    end: new Date(endDate),
  })
}

export const formatFileSize = (size: number) => {
  if (size < 1024) {
    return `${size} bytes`
  }
  if (size >= 1024 && size < 1048576) {
    return `${(size / 1024).toFixed(1)}KB`
  }
  if (size >= 1048576) {
    return `${(size / 1048576).toFixed(1)}MB`
  }

  return ''
}

// new Date(undefined) is different from new Date() https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#passing_a_non-date_non-string_non-number_value
export const formatTime = (value?: string) =>
  format(value ? new Date(value) : new Date(), 'HH:mm')

export const formatRRule = (rrule?: string) => {
  if (!rrule || !rrule.length) {
    return meetingRecurrence.Custom
  }

  // formatting string of type "RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR;INTERVAL=2"
  // to { FREQ: "WEEKLY", BYDAY: ['MO', 'TU', 'WE', 'TH', 'FR'], INTERVAL: "2" }
  let rruleParams: rruleInterface = {}
  rrule
    .replace('RRULE:', '')
    .split(';')
    .map(paramString => {
      const [param, value] = paramString.split('=')
      return { [param]: value }
    })
    .forEach(el => {
      const [key, value] = Object.entries(el)[0]
      const splittedValue = value?.split(',')
      rruleParams = {
        ...rruleParams,
        [key]: splittedValue?.length > 1 ? splittedValue : value,
      }
    })

  let isRepeatedOnWeekdays = false
  const workingDays = 'FR,MO,TH,TU,WE'
  const weekDays = 'FR,MO,SA,SU,TH,TU,WE'
  if (
    Array.isArray(rruleParams.BYDAY) &&
    (rruleParams.BYDAY.length === 5 || rruleParams.BYDAY.length === 7)
  ) {
    const sortedByday = [...rruleParams.BYDAY].sort().join()
    if (sortedByday === workingDays || sortedByday === weekDays) {
      isRepeatedOnWeekdays = true
    }
  }

  let recurrence = meetingRecurrence.Custom

  if (rruleParams.FREQ === rruleRecurrence.DAILY && rruleParams.INTERVAL === '1') {
    return meetingRecurrence.Daily
  }

  if (rruleParams.FREQ === rruleRecurrence.WEEKLY && isRepeatedOnWeekdays) {
    return meetingRecurrence.Daily
  }

  if (rruleParams.FREQ === rruleRecurrence.WEEKLY && rruleParams.INTERVAL === '2') {
    return meetingRecurrence.BiWeekly
  }

  if (rruleParams.FREQ === rruleRecurrence.WEEKLY && isString(rruleParams.BYDAY)) {
    return meetingRecurrence.Weekly
  }

  if (rruleParams.FREQ === rruleRecurrence.MONTHLY && rruleParams.INTERVAL === '1') {
    return meetingRecurrence.Monthly
  }

  return recurrence
}
