import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { Preview } from '@src/apps/People/Payroll/PayCycle/PayCycleReport/Preview'
import { UploadPayments } from '@src/apps/People/Payroll/PayCycle/PayCycleBulkUpload'
import { UploadedPaymentsTable } from '@src/apps/People/Payroll/PayCycle/PayCycleBulkUpload/UploadedPaymentsTable'
import { Status } from '@src/apps/People/Payroll/PayCycle/PayCycleBulkUpload/Status'
import { PayCycleReportsTable } from '@src/apps/People/Payroll/PayCycle/PayCycleReportsTable/PayCycleReportsTable'
import TimelineEdit from './General/TimelineEdit'
import { UploadFile } from './General/UploadFile'

export const PayCycle = () => (
  <Switch>
    <Route path={ROUTES.APPS.PAYROLL.PAY_CYCLE.STATUS}>
      <Status />
    </Route>
    <Route path={ROUTES.APPS.PAYROLL.PAY_CYCLE.PREVIEW}>
      <Preview />
    </Route>
    <Route path={ROUTES.APPS.PAYROLL.PAY_CYCLE.TIMELINE}>
      <TimelineEdit />
    </Route>
    <Route path={ROUTES.APPS.PAYROLL.PAY_CYCLE.UPLOAD}>
      <UploadPayments />
    </Route>
    <Route path={ROUTES.APPS.PAYROLL.PAY_CYCLE.UPLOAD_FILE}>
      <UploadFile />
    </Route>
    <Route path={ROUTES.APPS.PAYROLL.PAY_CYCLE.REVIEW}>
      <UploadedPaymentsTable />
    </Route>
    <Route path={ROUTES.APPS.PAYROLL.PAY_CYCLE.REPORTS}>
      <PayCycleReportsTable />
    </Route>
  </Switch>
)
