import React from 'react'
import { Group } from '@revolut/ui-kit'
import { connect } from 'lape'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SendEmailCommon from '@src/pages/Forms/SendEmail/SendEmailCommon'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { getCandidate } from '@src/api/recruitment/interviews'
import { useParams } from 'react-router-dom'
import { candidateEmailsRequests } from '@src/api/hiringProcess'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ArchiveButton, useArchiving } from '@src/pages/Forms/ArchiveOpportunity/common'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface, WhenToSend } from '@src/interfaces/hiringProccess'
import useGetPrefilledPlaceholdersEmail from '@src/pages/Forms/SendEmail/useGetPrefilledPlaceholdersEmail'

const SendArchivalEmailForm = () => {
  const { id } = useParams<{ id: string }>()
  const { reason, roundId } = useArchiving()
  const { values } = useLapeContext<SendCandidateEmailInterface>()

  const { loading: loadingFetchEmail, fetchEmail: getPrefilledPlaceholdersEmail } =
    useGetPrefilledPlaceholdersEmail()

  const fetchEmail = (templateId: number) => {
    return getPrefilledPlaceholdersEmail(templateId, +id)
  }

  const backUrl = pathToUrl(ROUTES.FORMS.ARCHIVE_OPPORTUNITY.GENERAL, {
    id,
  })

  return (
    <PageWrapper>
      <PageHeader title="Send archival email" backUrl={backUrl} />
      <SendEmailCommon
        fetchEmail={fetchEmail}
        actions={
          <ArchiveButton
            candidateId={+id}
            sendEmail
            step="email"
            scheduled={values.when_to_send?.id !== WhenToSend.NOW}
            disabled={!reason || !roundId}
            pending={loadingFetchEmail}
          />
        }
      >
        <FormPreview<CandidateInterface> api={() => getCandidate(id)}>
          <Group>
            <FormPreview.Item title="Candidate" field="full_name" />
            <FormPreview.Item title="Candidate email" field="email" />
            <FormPreview.Item<CandidateInterface>
              title="Interviewing for"
              field="active_interview_round.specialisation.name"
              to={data =>
                pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                  id: data.active_interview_round?.specialisation?.id,
                })
              }
            />
            <FormPreview.Item title="Archival reason" insert={() => reason?.name} />
          </Group>
        </FormPreview>
      </SendEmailCommon>
    </PageWrapper>
  )
}

const SendArchivalEmail = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <Form api={candidateEmailsRequests} forceParams={{ new: 'new', candidateId: id }}>
      <SendArchivalEmailForm />
    </Form>
  )
}

export default connect(SendArchivalEmail)
