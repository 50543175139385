import React from 'react'
import MainHeader from '@src/features/MainHeader/MainHeader'
import Apps from '@src/pages/Hub/Apps/Apps'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import Integrations from '@src/pages/Hub/Integrations/Integrations'
import { Route, Switch } from 'react-router-dom'
import Discover from '@src/pages/Hub/Discover/Discover'
import useIsCommercial from '@src/hooks/useIsCommercial'

export const getTabs = (isCommercial: boolean) => [
  {
    title: 'My apps',
    path: ROUTES.HUB.APPS,
    url: ROUTES.HUB.APPS,
    canView: true,
    component: Apps,
  },
  {
    title: 'Discover',
    path: ROUTES.HUB.DISCOVER,
    url: ROUTES.HUB.DISCOVER,
    canView: !isCommercial,
    component: Discover,
  },
  {
    title: 'Integrations',
    path: ROUTES.HUB.INTEGRATIONS,
    url: ROUTES.HUB.INTEGRATIONS,
    canView: true,
    component: Integrations,
  },
]

const HubLandingPage = () => {
  const isCommercial = useIsCommercial()
  const filteredTabs = getTabs(!!isCommercial).filter(tab => tab.canView)

  return (
    <PageWrapper pt="s-8" pb="s-24" pl={0}>
      <MainHeader title="Hub" tabs={filteredTabs} withGlobalSearch />
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

export default HubLandingPage
