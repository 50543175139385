import React, { useState } from 'react'
import { Box, Button, Flex, InputGroup, HStack, TextArea, Text } from '@revolut/ui-kit'
import { InterviewScorecardSectionQuestionType } from '@src/interfaces/interviewScorecardTemplates'

type BulkOptionProps<T extends number | string> = {
  values: InterviewScorecardSectionQuestionType<T>[]
  onConfirm: (values: InterviewScorecardSectionQuestionType<T>[]) => void
  onCancel: () => void
}

const BulkOption = <T extends number | string>({
  values,
  onConfirm,
  onCancel,
}: BulkOptionProps<T>) => {
  const [bulkContent, setBulkContent] = useState(
    values.map(({ text }) => text).join('\n'),
  )

  const handleConfirm = () => {
    onConfirm(
      bulkContent
        .split('\n')
        .filter(Boolean)
        .map(text => ({ text })),
    )
  }

  const bulkCount = bulkContent.split('\n').filter(Boolean).length

  return (
    <Box>
      <InputGroup>
        <TextArea
          label="Type or paste in options, one per line"
          autosize={false}
          rows={10}
          resize="vertical"
          onChange={event => setBulkContent(event.currentTarget.value)}
          value={bulkContent}
        />
        <Flex justifyContent="space-between" alignItems="center">
          <HStack space="s-8">
            <Button
              aria-label="Confirm bulk"
              onClick={() => handleConfirm()}
              variant="secondary"
              size="sm"
            >
              Confirm
            </Button>
            <Button
              aria-label="Cancel bulk"
              onClick={() => onCancel()}
              variant="secondary"
              size="sm"
            >
              Cancel
            </Button>
          </HStack>
          <Text>Total: {bulkCount} options</Text>
        </Flex>
      </InputGroup>
    </Box>
  )
}

export default BulkOption
