import React from 'react'
import { Text, Token } from '@revolut/ui-kit'
import ActionWidget from '@components/ActionWidget/ActionWidget'

export const BarRaiserBanner = () => {
  return (
    <ActionWidget
      avatarColor={Token.color.red}
      title="This is not the final grade"
      text={
        <Text variant="caption">
          This is only a suggestion, and NOT the final grade the employee will get. This
          will be considered along with the scorecard rating as inputs into the overall
          grade
        </Text>
      }
      bg={Token.color.greyTone2}
    />
  )
}
