import isNumber from 'lodash/isNumber'
import { Color } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'
import { RevolutTheme } from '@src/styles/theme'
import { IdAndName } from '@src/interfaces'
import { BenchmarkId, EngagementResultsScope } from '@src/interfaces/engagement'
import { OptionInterface } from '@src/interfaces/selectors'
import { FilterByInterface, FilterOption } from '@src/interfaces/data'
import {
  ANSWER_SCORE_KEY,
  CREATION_DATE_TIME_KEY,
  DRIVER_ID_KEY,
  QUESTION_ID_KEY,
  QUESTION_TYPE_KEY,
  SURVEY_ID_KEY,
} from '@src/apps/People/Engagement/Results/constants'

export const trendValueToColor = (theme: RevolutTheme, num?: number | null) => {
  if (!isNumber(num)) {
    return getColor(theme, Color.GREY_TONE_50)
  }

  if (num > 0) {
    return getColor(theme, Color.GREEN)
  }
  if (num < 0) {
    return getColor(theme, Color.RED)
  }

  return getColor(theme, Color.GREY_TONE_50)
}

export const benchmarkToColor = (
  theme: RevolutTheme,
  benchmark: IdAndName<BenchmarkId> | null,
) => {
  if (!benchmark) {
    return getColor(theme, Color.FOREGROUND)
  }
  switch (benchmark.id) {
    case 'bottom_5':
    case 'bottom_10':
      return getColor(theme, Color.RED)
    case 'bottom_25':
      return getColor(theme, Color.ORANGE)
    case 'middle':
      return getColor(theme, Color.FOREGROUND)
    case 'top_25':
      return getColor(theme, Color.LIGHT_GREEN)
    case 'top_5':
      return getColor(theme, Color.GREEN)
    default:
      return getColor(theme, Color.FOREGROUND)
  }
}

export enum ScoreGrade {
  LOW = 'LOW',
  MID = 'MID',
  HIGH = 'HIGH',
}

export const scoreToGrade = (score: number) => {
  if (score <= 2) {
    return ScoreGrade.LOW
  }
  if (score === 3) {
    return ScoreGrade.MID
  }
  if (score >= 4) {
    return ScoreGrade.HIGH
  }
  return undefined
}

export const scoreToColor = (theme: RevolutTheme, score: number) => {
  switch (scoreToGrade(score)) {
    case ScoreGrade.LOW:
      return getColor(theme, Color.RED)
    case ScoreGrade.MID:
      return getColor(theme, Color.YELLOW)
    case ScoreGrade.HIGH:
      return getColor(theme, Color.GREEN)
    default:
      return getColor(theme, Color.FOREGROUND)
  }
}

export const getFilters =
  ({
    defaultDriverId,
    defaultQuestionId,
    defaultSurveyId,
  }: {
    defaultDriverId?: string
    defaultQuestionId?: string
    defaultSurveyId?: string
  }) =>
  ({
    textFilter,
    scoreFilter,
    driversFilter,
    questionsFilter,
    dateFilter,
    questionTypeFilter,
    defaultFilters = [],
  }: {
    textFilter?: string
    scoreFilter?: OptionInterface[]
    driversFilter?: OptionInterface[]
    questionsFilter?: OptionInterface[]
    questionTypeFilter?: OptionInterface[]
    dateFilter?: FilterOption[]
    defaultFilters?: FilterByInterface[]
  }) => {
    const filters = []

    if (textFilter) {
      filters.push({
        columnName: 'answer_text',
        filters: [{ id: textFilter, name: textFilter }],
      })
    }
    if (scoreFilter) {
      const score = scoreFilter.reduce((acc, value) => {
        if (value) {
          return acc ? `${acc},${value.id}` : String(value.id)
        }
        return acc
      }, '')
      if (score) {
        filters.push({
          columnName: ANSWER_SCORE_KEY,
          filters: [{ id: score, name: score }],
        })
      }
    }
    if (questionTypeFilter) {
      const questionType = questionTypeFilter.reduce((acc, value) => {
        if (value) {
          return acc ? `${acc},${value.id}` : String(value.id)
        }
        return acc
      }, '')
      if (questionType) {
        filters.push({
          columnName: QUESTION_TYPE_KEY,
          filters: [{ id: questionType, name: questionType }],
        })
      }
    }
    if (defaultDriverId || driversFilter) {
      filters.push({
        columnName: DRIVER_ID_KEY,
        filters: [
          ...(defaultDriverId ? [{ id: defaultDriverId, name: defaultDriverId }] : []),
          ...(driversFilter
            ? driversFilter.map(({ id }) => ({ id: String(id), name: String(id) }))
            : []),
        ],
      })
    }
    if (defaultQuestionId || questionsFilter) {
      filters.push({
        columnName: QUESTION_ID_KEY,
        filters: [
          ...(defaultQuestionId
            ? [{ id: defaultQuestionId, name: defaultQuestionId }]
            : []),
          ...(questionsFilter
            ? questionsFilter.map(({ id }) => ({ id: String(id), name: String(id) }))
            : []),
        ],
      })
    }
    if (dateFilter?.length === 2) {
      const dateRange = `${dateFilter[0].id},${dateFilter[1].id}`
      filters.push({
        columnName: CREATION_DATE_TIME_KEY,
        filters: [{ id: dateRange, name: dateRange }],
      })
    }
    if (defaultSurveyId) {
      filters.push({
        columnName: SURVEY_ID_KEY,
        filters: [{ id: defaultSurveyId, name: defaultSurveyId }],
      })
    }

    return [...filters, ...defaultFilters]
  }

export const scopeToString = (scope: EngagementResultsScope) => {
  switch (scope) {
    case EngagementResultsScope.Departments:
      return 'department'
    case EngagementResultsScope.Company:
      return 'company'
    case EngagementResultsScope.Employees:
      return 'manager'
    case EngagementResultsScope.Functions:
      return 'function'
    case EngagementResultsScope.Roles:
      return 'role'
    case EngagementResultsScope.Specialisations:
      return 'specialisation'
    case EngagementResultsScope.Teams:
      return 'team'
    default:
      return ''
  }
}
