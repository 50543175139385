import React from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ReviewCategory,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PerformanceHeaderNavigationBar } from './PerformanceHeaderNavigationBar'
import { Link } from '@revolut/ui-kit'
import { useGetNotCompletedReviews } from '@src/api/performanceReview'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

const PerformanceHeader = () => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const user = useSelector(selectUser)
  const reviews = useGetNotCompletedReviews(user.id)

  const isSummary = (
    review: ReviewScorecardInterface | ReviewSummaryInterface,
  ): review is ReviewSummaryInterface =>
    (review as ReviewSummaryInterface).summary !== undefined

  const data = isSummary(values) ? values.reviews[0] : values

  const getBackUrl = () => {
    switch (data?.category) {
      case ReviewCategory.Upwards:
        return pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.UPWARDS, {
          id: data?.reviewed_employee?.id,
        })
      default:
        return pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
          id: data?.reviewed_employee?.id,
        })
    }
  }

  const backUrl = getBackUrl()

  const getTitle = () => {
    switch (data.category) {
      case ReviewCategory.Upwards:
        return 'Upwards Review'
      case ReviewCategory.Probation:
        return `Probation Review · CP${data.probation_checkpoint?.number}`
      case ReviewCategory.PIP_V2:
      case ReviewCategory.PIP:
        return 'PIP Checkpoint'
      default:
        return 'Performance Review'
    }
  }

  return (
    <PageHeader
      title={
        <>
          {reviews?.data && <PerformanceHeaderNavigationBar reviews={reviews.data} />}
          <Link
            href={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
              id: data.reviewed_employee?.id,
            })}
            color="inherit"
            target="_blank"
          >
            {data.reviewed_employee?.full_name}
          </Link>
        </>
      }
      subtitle={`${data.cycle ? `${data.cycle.name} ` : ''}${getTitle()}`}
      backUrl={backUrl}
    />
  )
}

export default connect(PerformanceHeader)
