import React from 'react'
import { Flex, Text, Widget } from '@revolut/ui-kit'
import { ReportOutputFormat, QueryColumnInterface } from '@src/interfaces/dataAnalytics'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'

interface Props {
  options: RadioSelectOption<QueryColumnInterface>[]
  outputFormat: ReportOutputFormat
  tableSummary: { value: string | null; count: number }[]
}

export const SummarizeSettings = ({ options, outputFormat, tableSummary }: Props) => {
  return (
    <Flex flexDirection="column" gap="s-16">
      <Text>Select a column to use to summarise the table</Text>
      <RadioSelectInput
        clearable
        label="Choose a column"
        onChange={val => {
          if (val) {
            outputFormat.summary_column = val.name
          } else {
            outputFormat.summary_column = null
          }
        }}
        options={options}
        value={
          options.find(option => option.value.name === outputFormat.summary_column)
            ?.value || null
        }
      />

      {outputFormat && !!tableSummary.length && (
        <>
          <Text>These values will be shown in the table summary</Text>
          <Widget p="s-16">
            <Flex flexDirection="column" gap="s-16">
              {tableSummary.map(item => (
                <Text key={item.value}>{item.value}</Text>
              ))}
            </Flex>
          </Widget>
        </>
      )}
    </Flex>
  )
}
