import React, { useState } from 'react'
import { Cell, Color, Flex, Link as UIKitLink, MoreBar, Text } from '@revolut/ui-kit'
import { Pencil, Plus } from '@revolut/icons'

import { EmployeeInterface } from '@src/interfaces/employees'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeTimeOffBalancesInterface } from '@src/interfaces/timeOff'
import { useTable } from '@components/Table/hooks'
import { getEmployeeTimeOffBalances } from '@src/api/timeOff'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  timeOffBalanceAccruedCountColumn,
  timeOffBalanceBookedCountColumn,
  timeOffBalanceCarriedOverCountColumn,
  timeOffBalanceEndOfCycleCountColumn,
  timeOffBalancePolicyNameColumn,
  timeOffBalanceStatusColumn,
  timeOffBalanceTakenCountColumn,
} from '@src/constants/columns/timeOff'
import {
  canAddTimeOffAbsences,
  canAddTimeOffBalanceAdjustments,
  canAddTimeOffRequests,
} from '../ProfileSummary/common'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { AdjustBalanceSidebar } from '@components/TimeOff/AdjustBalanceSidebar'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { Statuses } from '@src/interfaces'
import { TIME_OFF_SERVICE_DESK_LINK } from '@src/constants/externalLinks'
import {
  ContactHRTeamButton,
  EmptyTableState,
  PolicyPortalButton,
  UserGuidesButton,
} from './common'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

const ROW: RowInterface<EmployeeTimeOffBalancesInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.TIME_OFF_BALANCE.PREVIEW, { id: data.id })),
  cells: [
    {
      ...timeOffBalancePolicyNameColumn,
      width: 400,
    },
    {
      ...timeOffBalanceEndOfCycleCountColumn,
      width: 150,
    },
    {
      ...timeOffBalanceBookedCountColumn,
      width: 100,
    },
    {
      ...timeOffBalanceTakenCountColumn,
      width: 100,
    },
    {
      ...timeOffBalanceAccruedCountColumn,
      width: 100,
    },
    {
      ...timeOffBalanceCarriedOverCountColumn,
      width: 100,
    },
    {
      ...timeOffBalanceStatusColumn,
      width: 100,
    },
  ],
}

const showAllBalancesOffFilter = [
  {
    columnName: 'status',
    filters: [
      { id: Statuses.open, name: Statuses.open },
      { id: Statuses.pending, name: Statuses.pending },
    ],
  },
]

const showAllBalancesOnFilter = [
  {
    columnName: 'status',
    filters: [],
  },
]

interface Props {
  data: EmployeeInterface
}

const EmployeeTimeOffBalances = ({ data }: Props) => {
  const table = useTable(
    { getItems: getEmployeeTimeOffBalances(data.id) },
    showAllBalancesOffFilter,
  )

  const [adjustBalanceSidebarOpen, setAdjustBalanceSidebarOpen] = useState(false)
  const [showAllBalances, setShowAllBalances] = useState(false)

  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const canAdjustBalance = canAddTimeOffBalanceAdjustments(data)

  const canAddRequest = canAddTimeOffRequests(data)
  const canAddAbsence = canAddTimeOffAbsences(data)

  const emptyState = table.data.length === 0

  return (
    <>
      <Cell>
        <Flex flexDirection="column" width="100%">
          <Flex mb="s-16">
            <MoreBar>
              {canAddRequest && (
                <MoreBar.Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.GENERAL, {
                    employeeId: data.id,
                  })}
                  useIcon={Plus}
                  disabled={emptyState}
                >
                  Request time off
                </MoreBar.Action>
              )}
              {canAddAbsence && (
                <MoreBar.Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_ABSENCE.GENERAL, {
                    employeeId: data.id,
                  })}
                  useIcon={Plus}
                  disabled={emptyState}
                >
                  Record absence
                </MoreBar.Action>
              )}
              {canAdjustBalance ? (
                <MoreBar.Action
                  onClick={() => setAdjustBalanceSidebarOpen(true)}
                  useIcon={Pencil}
                  disabled={emptyState}
                >
                  Adjust balance
                </MoreBar.Action>
              ) : null}
              <SwitchButton
                checked={showAllBalances}
                onClick={() => {
                  const nextState = !showAllBalances
                  setShowAllBalances(nextState)
                  table.onFilterChange(
                    nextState ? showAllBalancesOnFilter : showAllBalancesOffFilter,
                  )
                }}
              >
                Show all balances
              </SwitchButton>
              <PolicyPortalButton />
            </MoreBar>
          </Flex>

          <Flex style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable
              name={TableNames.EmployeeTimeOffBalances}
              useWindowScroll
              row={ROW}
              emptyState={
                <EmptyTableState
                  title="No time off policies assigned yet"
                  description={
                    <>
                      Please reach out to the HR Team in order to get the applicable time
                      off policies assigned to your profile.
                      <br />
                      Once the relevant policies are assigned you will be able to see your
                      available balance and you will be able to submit time off requests.
                    </>
                  }
                  actions={
                    <>
                      <ContactHRTeamButton />
                      <UserGuidesButton />
                    </>
                  }
                />
              }
              {...table}
            />
          </Flex>

          {emptyState || isCommercial ? null : (
            <Text
              variant="caption"
              color={Color.GREY_TONE_50}
              display="block"
              mt="s-16"
              mb="s-8"
            >
              If you think one of your balances is incorrect or if you need request a new
              policy to be assigned to your profile please click{' '}
              <UIKitLink
                href={TIME_OFF_SERVICE_DESK_LINK}
                target="_blank"
                rel="noreferrer noopener"
              >
                here
              </UIKitLink>
              .
            </Text>
          )}
        </Flex>
      </Cell>

      <AdjustBalanceSidebar
        employeeId={data.id}
        isOpen={adjustBalanceSidebarOpen}
        onClose={() => setAdjustBalanceSidebarOpen(false)}
        onAfterSubmit={() => {
          setAdjustBalanceSidebarOpen(false)
          table.refresh()
        }}
      />
    </>
  )
}

export default EmployeeTimeOffBalances
