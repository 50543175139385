import React from 'react'
import styled from 'styled-components'
import { formatNumber } from '@src/utils/format'
import Tooltip from '../../Tooltip/Tooltip'
import { Color } from '@revolut/ui-kit'
import { colorGetter } from '@src/styles/colors'

const Wrapper = styled.div<{ large?: boolean }>`
  position: relative;
  border-radius: 16px;
  background: ${colorGetter(Color.GREY_20_OPAQUE_90)};
  height: ${props => (props.large ? 16 : 8)}px;
  margin: 4px 0;
  width: 100%;
`

const Done = styled.div<{ done: number; progress: number }>`
  border-radius: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${props => props.done + props.progress}%;
  background: ${colorGetter(Color.GREEN_OPAQUE_60)};
`
const InProgress = styled.div<{ done: number; progress: number }>`
  border-radius: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${props => props.done}%;
  width: ${props => props.progress}%;
  background: ${props => props.theme.colors.blue};
`

const FlexTooltip = styled(Tooltip)`
  flex: 1;
`

interface Props {
  todo: number
  progress: number
  done: number
  large?: boolean
}

const RoadmapProgress = ({ todo, progress, done, large }: Props) => {
  return (
    <FlexTooltip
      placement="bottom"
      text={`Done = ${formatNumber(done)}%\\nIn Progress = ${formatNumber(
        progress,
      )}%\\nTo Do = ${formatNumber(todo)}%`}
    >
      <Wrapper large={large}>
        {!!done && <Done done={done} progress={progress} />}
        {!!progress && <InProgress done={done} progress={progress} />}
      </Wrapper>
    </FlexTooltip>
  )
}

export default RoadmapProgress
