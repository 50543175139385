import React, { useEffect, useState } from 'react'
import { Archive, Download, Unarchive } from '@revolut/icons'
import { Header, MoreBar, InputGroup, Side, Text, chain, Color } from '@revolut/ui-kit'
import { connect } from 'lape'
import styled from 'styled-components'
import { upperFirst } from 'lodash'

import { selectorKeys } from '@src/constants/api'
import { getSelectors } from '@src/api/selectors'
import { LocationInterface } from '@src/interfaces/locations'
import { Line } from '@components/CommonSC/Line'
import LapeFileUploader from '@components/Inputs/LapeFields/LapeFileUploader'
import Button from '@components/Button/Button'
import Tooltip from '@components/Tooltip/Tooltip'
import LoadingButton from '@components/Button/LoadingButton'
import {
  changeLocationStatus,
  downloadMapFile,
  newLocationRequests,
} from '@src/api/locations'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import { RadioOption } from '@src/components/Inputs/NewRadioButtons/NewRadioButtons'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { EntityPermissions } from '@src/store/auth/types'
import { useLocationRegions } from '@src/api/timeOff'
import { useGetLocationSettings } from '@src/api/settings'
import NewMultiSelect from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import SideBar from '@components/SideBar/SideBar'
import { goBack } from '@src/actions/RouterActions'
import LapeDeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'

const ActionButton = styled(Button)`
  padding: 0;
  min-width: 0;
`

const FileContainer = styled.div`
  font-weight: 600;
  font-size: 15px;
  display: grid;
  white-space: nowrap;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;
  grid-gap: 10px;
`
interface Props {
  isOpen?: boolean
  onAfterSave?: () => void
  onClose?: () => void
  useSidebar?: boolean
}

export const General = ({ isOpen, onAfterSave, onClose, useSidebar }: Props) => {
  const { data: locationSettings } = useGetLocationSettings()
  const { values, isSubmitting, reset } = useLapeContext<LocationInterface>()
  const [types, setTypes] = useState<RadioOption[]>([])
  const [showUpload, setShowUpload] = useState(!values.id || !values.file_name)
  const [archivePending, setArchivePending] = useState(false)

  const isRemote = values.type?.name === 'Remote'
  const isOffice = values.type?.name === 'Office'

  useEffect(() => {
    fetchTypes()
    // We can't save file to LocalStorage that is why I remove it if the data comes from there.
    if (values.file && !values.file.name) {
      values.file = undefined
      setShowUpload(true)
    }
  }, [])

  useEffect(() => {
    setShowUpload(!values.id || !values.file_name)
  }, [isSubmitting, values.file_name])

  useEffect(() => {
    if (!values.posting_compensation_enabled) {
      values.posting_compensation_period = undefined
    }
  }, [values.posting_compensation_enabled])

  const { data: regionsData } = useLocationRegions(values.country?.id)

  const fetchTypes = async () => {
    const results = await getSelectors(selectorKeys.location_types)
    if (results.data?.options) {
      setTypes(
        results.data?.options.map(option => ({ label: option.name, value: option.id })),
      )
      if (!values.type) {
        values.type = results.data?.options?.find?.(option => option.name === 'Office')!
      }
    }
  }

  const backUrl = ROUTES.APPS.LOCATIONS

  const canChange = values.field_options?.actions?.includes(EntityPermissions.Change)
  const canDelete = values.field_options?.actions?.includes(EntityPermissions.Delete)
  const canPerformAction = canChange || canDelete

  const renderActions = () => (
    <NewSaveButtonWithPopup
      afterSubmitUrl={useSidebar ? undefined : backUrl}
      disabled={!!values.id && !canChange}
      onAfterSubmit={onAfterSave}
      successText="Location successfully saved"
      useValidator
    />
  )

  const renderButtons = () => {
    if (!values.file_name) {
      return <FileContainer>There is no map. Please upload a map.</FileContainer>
    }
    return (
      <FileContainer>
        {values.file_name}
        <Tooltip placement="top" text="Download the map">
          <LoadingButton
            customIcon={<Download size={20} />}
            onSubmit={() => downloadMapFile(values.id)}
          >
            Download
          </LoadingButton>
        </Tooltip>
      </FileContainer>
    )
  }

  const renderContent = () => {
    return (
      <AutoStepper>
        <NewStepperTitle title="Details" />
        <InputGroup>
          <LapeNewInput label="Location name" name="location_name" required />
          {locationSettings?.enable_location_hr_fields ? (
            <>
              <LapeRadioSelectInput
                label="People partner"
                name="hr_manager"
                selector={selectorKeys.employee}
              />
              <LapeNewMultiSelect
                placeholder="People specialists"
                name="people_specialists"
                required
                selector={selectorKeys.employee}
              />
            </>
          ) : null}
          {types.length ? (
            <LapeNewRadioButtons
              name="type"
              useMappingAsValue
              options={types}
              variant="cell"
            />
          ) : null}
          <LapeNewTextArea
            label="Description"
            name="description"
            rows={3}
            message="Add some details or notes about this location"
          />
        </InputGroup>

        <NewStepperTitle title="Settings" />
        <InputGroup>
          <LapeRadioSelectInput
            label="Country"
            name="country"
            selector={selectorKeys.countries}
            required
            onAfterChange={() => {
              delete values.time_off_region
            }}
          />

          {locationSettings?.location_labels?.length ? (
            <NewMultiSelect
              placeholder="Labels (optional)"
              selector={selectorKeys.location_labels}
              value={values?.location_labels?.map(label => ({
                label,
                value: { id: label },
              }))}
              onChange={labels => {
                values.location_labels = labels.map(({ value }) => value.id)
              }}
            />
          ) : null}

          {!!regionsData?.options?.length && (
            <LapeRadioSelectInput
              label="Region"
              name="time_off_region"
              selector={() => Promise.resolve(regionsData.options)}
              required
            />
          )}
          <LapeRadioSelectInput
            label="Currency"
            name="currency"
            selector={selectorKeys.currencies}
            message="Select location currency"
          />
          <LapeRadioSelectInput
            label="Time Zone"
            name="timezone"
            selector={selectorKeys.timezones}
          />
          {locationSettings?.enable_location_hr_fields ? (
            <>
              <HideIfCommercial>
                <LapeNewInput
                  name="probation_notice_period"
                  label="Probation notice period (number of days)"
                  hideOptional
                  type="number"
                />
              </HideIfCommercial>
              <ActionWidget
                title="Display compensation bands in job postings"
                text="Turn on this setting only for locations where displaying compensation is a legal requirements. This feature will add a mandatory section when developing the job posting and will include the compensation band to published postings"
                avatar={<LapeNewSwitch name="posting_compensation_enabled" />}
              />
              {values.posting_compensation_enabled && (
                <>
                  <Text color="grey-tone-50">
                    Compensation period (for displaying in job postings)
                  </Text>
                  <LapeNewRadioButtons
                    name="posting_compensation_period"
                    options={[
                      {
                        label: 'Monthly',
                        value: 'monthly',
                      },
                      {
                        label: 'Annually',
                        value: 'yearly',
                      },
                    ]}
                    variant="cell"
                  />
                </>
              )}
            </>
          ) : null}
        </InputGroup>

        {isRemote && (
          <HideIfCommercial>
            <NewStepperTitle title="Remote location information" />
            <InputGroup>
              <LapeNewInput
                label="Jurisdiction"
                name="jurisdiction"
                message="Select location jurisdiction"
              />
              <LapeNewInput label="City" name="city" />
            </InputGroup>
          </HideIfCommercial>
        )}

        {isOffice && (
          <>
            <NewStepperTitle title="Office Information" />
            <InputGroup>
              {locationSettings?.enable_location_hr_fields && (
                <LapeRadioSelectInput
                  label="Workspace manager"
                  name="workspace_manager"
                  selector={selectorKeys.employee}
                  message="Select the office manager"
                />
              )}
              <LapeNewInput label="Office email" name="office_email" required />
              <LapeNewInput label="Phone Number" name="phone_number" required />

              <Line />

              <LapeNewInput
                label="Address line 1"
                name="address_line_1"
                message="Flat / House no"
                required
              />
              <LapeNewInput
                label="Address line 2"
                name="address_line_2"
                message="Street"
                required
              />
              <LapeNewInput
                label="Address line 3"
                name="address_line_3"
                message="Street"
              />
              <InputGroup variant="horizontal">
                <LapeNewInput label="County" name="county" required />
                <LapeNewInput label="City" name="city" required />
              </InputGroup>
              <LapeNewInput label="Post code/ZIP code" name="post_code" required />
              <LapeNewInput
                label="Google maps link"
                name="google_maps_link"
                message="Paste the link to the location in Google Maps"
              />
            </InputGroup>

            <HideIfCommercial>
              <NewStepperTitle title="Office Map" />
              <InputGroup>
                {showUpload && <LapeFileUploader name="file" label="File attachment" />}
                {!showUpload && renderButtons()}
                {values.id && !showUpload && (
                  <ActionButton styles="text" onClick={() => setShowUpload(true)}>
                    {!values.file_name ? 'Upload' : 'Reupload'} map
                  </ActionButton>
                )}
              </InputGroup>
            </HideIfCommercial>
          </>
        )}
      </AutoStepper>
    )
  }

  const renderSettingsButtons = () => {
    return (
      <>
        {values.id && canPerformAction && (
          <MoreBar>
            {values.status === 'archived' && canChange && (
              <MoreBar.Action
                onClick={() => onChangeStatus('unarchive')}
                useIcon={Unarchive}
                pending={archivePending}
              >
                Unarchive
              </MoreBar.Action>
            )}
            {values.status === 'active' && canChange && (
              <MoreBar.Action
                onClick={() => onChangeStatus('archive')}
                useIcon={Archive}
                variant="negative"
                pending={archivePending}
              >
                Archive
              </MoreBar.Action>
            )}
            <LapeDeleteOrgUnitButton
              deleteApi={newLocationRequests.delete!}
              displayName="location"
              onAfterDelete={() => goBack(backUrl)}
            />
          </MoreBar>
        )}
      </>
    )
  }

  const onChangeStatus = (status: 'archive' | 'unarchive') => {
    setArchivePending(true)

    changeLocationStatus(values.id, status)
      .then(response => reset(response.data))
      .finally(() => setArchivePending(false))
  }

  const title = values.id
    ? chain(
        values.location_name,
        <Text color={values.status === 'active' ? Color.GREEN : Color.GREY_TONE_50}>
          {upperFirst(values.status)}
        </Text>,
      )
    : 'New location'

  if (!useSidebar) {
    return (
      <PageWrapper>
        <PageHeader title={title} backUrl={backUrl} />
        <PageBody>
          {renderSettingsButtons()}
          {renderContent()}
        </PageBody>
        <PageActions>{renderActions()}</PageActions>
      </PageWrapper>
    )
  }

  return (
    <SideBar
      isOpen={isOpen}
      headerContent={<Header.Bar>{renderSettingsButtons()}</Header.Bar>}
      onClose={onClose}
      title={title}
      variant="wide"
    >
      {renderContent()}
      <Side.Actions>{renderActions()}</Side.Actions>
    </SideBar>
  )
}

export default connect(() => (
  <Form api={newLocationRequests}>
    <General />
  </Form>
))
