import React from 'react'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { probationTemplateRequests } from '@src/api/probationTemplate'
import ProbationTemplateGeneral from './General'

const ProbationTemplate = () => {
  return (
    <PageWrapper>
      <ProbationTemplateGeneral />
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={probationTemplateRequests} disableDataCleanup>
    <ProbationTemplate />
  </Form>
))
