import React from 'react'
import { MoreBar, TableWidget } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { CustomFieldsInterface, SectionOptions } from '@src/interfaces/customFields'
import {
  customFieldCreatedByColumn,
  customFieldCreatedDateColumn,
  customFieldGroups,
  customFieldInputTypeColumn,
  customFieldMandatoryColumn,
  customFieldNameColumn,
  customFieldPlaceholderColumn,
  customFieldSectionColumn,
  customFieldStatusColumn,
  customFieldSubSectionColumn,
  customFieldTooltipColumn,
} from '@src/constants/columns/customFields'
import { canAddCustomField } from '@src/store/auth/selectors'
import { customFieldsRequestTable } from '@src/api/customFields'
import { navigateTo } from '@src/actions/RouterActions'
import { TableNames } from '@src/constants/table'

interface CustomFieldsTableProps {
  sectionId?: SectionOptions
}

const Row: RowInterface<CustomFieldsInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.CUSTOM_FIELDS.GENERAL, { id: data.id })),
  cells: [
    {
      ...customFieldNameColumn,
      width: 100,
    },
    {
      ...customFieldCreatedByColumn,
      width: 100,
    },
    {
      ...customFieldGroups,
      width: 100,
    },
    {
      ...customFieldSectionColumn,
      width: 100,
    },
    {
      ...customFieldSubSectionColumn,
      width: 100,
    },
    {
      ...customFieldInputTypeColumn,
      width: 100,
    },
    {
      ...customFieldMandatoryColumn,
      width: 100,
    },
    {
      ...customFieldPlaceholderColumn,
      width: 100,
    },
    {
      ...customFieldTooltipColumn,
      width: 100,
    },
    {
      ...customFieldCreatedDateColumn,
      width: 100,
    },
    {
      ...customFieldStatusColumn,
      width: 100,
    },
  ],
}

const CustomFieldsTable = ({ sectionId }: CustomFieldsTableProps) => {
  const showAddButton = useSelector(canAddCustomField)

  const filterBy = sectionId
    ? [
        {
          filters: [{ name: sectionId, id: sectionId }],
          columnName: 'section__id',
          nonResettable: true,
        },
      ]
    : []

  const table = useTable<CustomFieldsInterface>(
    { getItems: customFieldsRequestTable },
    filterBy,
  )

  return (
    <TableWidget>
      <TableWidget.Info>
        {showAddButton && (
          <MoreBar>
            <MoreBar.Action
              onClick={() => {
                navigateTo(
                  `${pathToUrl(ROUTES.FORMS.CUSTOM_FIELDS.GENERAL)}${
                    sectionId ? `?section_id=${sectionId}` : ''
                  }`,
                )
              }}
              useIcon="Plus"
            >
              Add custom field
            </MoreBar.Action>
          </MoreBar>
        )}
      </TableWidget.Info>
      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.CustomFields}
          useWindowScroll
          row={Row}
          {...table}
          noDataMessage="Custom fields will appear here."
          hiddenCells={{
            [customFieldSectionColumn.idPoint]: !!sectionId,
            [customFieldSubSectionColumn.idPoint]:
              !!sectionId && sectionId !== SectionOptions.EmployeeProfile,
          }}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export default CustomFieldsTable
