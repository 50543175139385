import React, { useState } from 'react'
import { ActionButton, Box, HStack, Image } from '@revolut/ui-kit'
import { uploadFile } from '@src/api/files'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { ERROR_DEFAULT_DURATION } from '@src/constants/notifications'
import { Clip } from '@revolut/icons'
import { CareerPageSettingsInterface } from '@src/interfaces/settings'
import { PreferencesLogoUpload } from '@src/pages/Settings/General/PreferencesLogoUpload'
import { AxiosResponse } from 'axios'
import { FileInterface } from '@src/interfaces/files'
import { useLapeContext } from '@src/features/Form/LapeForm'

type CompanyLogoInputProps = {
  disabled: boolean
}

const CompanyLogoInput = ({ disabled }: CompanyLogoInputProps) => {
  const { values } = useLapeContext<CareerPageSettingsInterface>()
  const [uploading, setUploading] = useState(false)
  const handleSave = (file: File) => {
    return uploadFile(file, 'career_page_logo')
  }
  const handleSuccess = (result: AxiosResponse<FileInterface, any>) => {
    if (result?.data) {
      const { id, name, url } = result.data
      values.company_logo = { id, name, url }
    } else {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Failed to upload career logo',
        duration: ERROR_DEFAULT_DURATION,
      })
    }
    setUploading(false)
  }
  return (
    <>
      <HStack gap="s-8" mt="s-8">
        <ActionButton
          useIcon={Clip}
          onClick={() => {
            setUploading(true)
          }}
          disabled={disabled}
        >
          Upload logo
        </ActionButton>
      </HStack>
      {values.company_logo?.url && (
        <Box p="s-8">
          <Image src={values.company_logo?.url} width="auto" />
        </Box>
      )}
      <PreferencesLogoUpload
        circularCrop={false}
        open={uploading}
        onClose={() => setUploading(false)}
        onUpload={handleSave}
        onSuccess={handleSuccess}
      />
    </>
  )
}

export default CompanyLogoInput
