import React, { useState } from 'react'
import { LocationDescriptor } from 'history'
import { COOKIE, PerformanceTimeRange } from '@src/constants/api'
import {
  EpicOption,
  KpiInterface,
  KPIPerformanceTypes,
  KpiTargetEpics,
  KpiTargets,
} from '@src/interfaces/kpis'
import {
  Color,
  Flex,
  Text,
  useTooltip,
  Tooltip as UIKitTooltip,
  DetailsSkeleton,
  MoreBar,
} from '@revolut/ui-kit'
import { KPITypes } from '@src/constants/table'
import omit from 'lodash/omit'
import isNil from 'lodash/isNil'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { roundFloat } from '@src/utils/numbers'
import {
  RadioSelectInputProps,
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import find from 'lodash/find'
import { Statuses } from '@src/interfaces'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { refreshEntityKPIs } from '@src/api/kpis'
import { useTableReturnType } from '@components/Table/hooks'
import { Stats } from '@src/interfaces/data'
import { getLocalDateTime, getLocalDistanceTime } from '@src/utils/roadmap'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useLocation } from 'react-router-dom'
import { cookiesApi } from '@src/utils/cookies'
import { addMinutes } from 'date-fns'
import Cookies from 'js-cookie'
import { Retry } from '@revolut/icons'

export const getMonthsByRange = (range: PerformanceTimeRange) => {
  if (range === PerformanceTimeRange.week) {
    return '-3,0'
  }
  if (range === PerformanceTimeRange.day) {
    return '0,0'
  }
  return '-11,1'
}

export const fixChangelogTargets = (
  data: Partial<KpiInterface>,
  currentData: KpiInterface,
): Partial<KpiInterface> =>
  ({
    ...data,
    targets: data.targets?.map((target, index) => {
      const currentTargetData = currentData?.targets?.[index]

      if (
        target.review_cycle &&
        currentTargetData?.review_cycle &&
        target.review_cycle.id === currentTargetData?.review_cycle.id
      ) {
        if (
          isNil(target.initial_value) &&
          isNil(target.target) &&
          !target.kpi_goal &&
          !target.owner &&
          !target.parent_kpi
        ) {
          return {} as Partial<KpiTargets>
        }
        return {
          initial_value: target.initial_value,
          target: target.target,
          kpi_goal: target.kpi_goal,
          parent_kpi: target.parent_kpi,
          owner: target.owner,
        }
      }
      return target
    }),
    target_epics: data.target_epics?.map((target, index) => {
      const currentTargetData = currentData?.target_epics?.[index]

      if (target.review_cycle.id === currentTargetData?.review_cycle.id) {
        if (!target.epics && !target.owner && !target.parent_kpi) {
          return {} as Partial<KpiTargetEpics>
        }
        return {
          epics: target.epics,
          parent_kpi: target.parent_kpi,
          owner: target.owner,
        }
      }
      return target
    }),
  } as Partial<KpiInterface>)

export const fixPreviousValuesTargets = (
  previousValues: KpiInterface,
  currentData: KpiInterface,
): KpiInterface => ({
  ...previousValues,
  targets: previousValues.targets?.map(target => ({
    ...target,
    status: find(currentData.targets, t => t.id === target.id)?.status || Statuses.opened,
  })),
  target_epics: previousValues.target_epics?.map(target => ({
    ...target,
    status:
      find(currentData.target_epics, t => t.id === target.id)?.status || Statuses.opened,
  })),
})

export const getEntity = (data: Partial<KpiInterface>): string | undefined => {
  switch (data.kpi_type?.id) {
    case KPITypes.department_kpi:
      return data.department?.name
    case KPITypes.team_kpi:
      return data.team?.name
    case KPITypes.function_kpi:
      return data.function?.name
    case KPITypes.role_kpi:
      return data.role?.name
    case KPITypes.employee_kpi:
      return '-'
    case KPITypes.company_kpi:
      return 'Revolut'
    default:
      return undefined
  }
}

export const getLinkForKPIEntity = (
  data: Partial<KpiInterface>,
): LocationDescriptor | undefined => {
  switch (data.kpi_type?.id) {
    case KPITypes.team_kpi:
      return getLocationDescriptor(
        pathToUrl(ROUTES.FORMS.TEAM.KPI, { id: data.team?.id }),
      )
    case KPITypes.department_kpi:
      return getLocationDescriptor(
        pathToUrl(ROUTES.FORMS.DEPARTMENT.KPI, { id: data.department?.id }),
      )
    case KPITypes.company_kpi:
      return getLocationDescriptor(pathToUrl(ROUTES.FORMS.COMPANY.KPI.COMPANY))
    case KPITypes.function_kpi:
      return getLocationDescriptor(
        pathToUrl(ROUTES.FORMS.FUNCTION.KPI, { id: data.function?.id }),
      )
    case KPITypes.role_kpi:
      return getLocationDescriptor(
        pathToUrl(ROUTES.FORMS.ROLE.KPI, { id: data.role?.id }),
      )
    default:
      return undefined
  }
}

export const getKpiTypeName = (kpiType?: KPITypes) => {
  switch (kpiType) {
    case KPITypes.department_kpi:
      return 'Department'
    case KPITypes.team_kpi:
      return 'Team'
    case KPITypes.company_kpi:
      return 'Company'
    case KPITypes.employee_kpi:
      return 'Employee'
    case KPITypes.function_kpi:
      return 'Function'
    case KPITypes.role_kpi:
      return 'Role'
    default:
      return '-'
  }
}

export const getInheritanceLevel = (data: Partial<KpiInterface>): string => {
  if (data.kpi_type?.id === KPITypes.employee_kpi && !data.is_inherited) {
    return 'Employee'
  }
  const type = data.parent?.kpi_type?.id
  return getKpiTypeName(type)
}

export const renderParentSelectorOption: RadioSelectInputProps<KpiInterface>['children'] =
  item => {
    const entity =
      item.value.type === KPITypes.company_goal
        ? 'Revolut'
        : (item.value && getEntity(item.value)) || 'Unknown'
    const entityType =
      item.value.type === KPITypes.company_goal
        ? 'Company Goal'
        : getKpiTypeName(item.value?.kpi_type?.id)
    const subtitle = `[${entityType}] ${entity}`

    return (
      <Text>
        {item.value?.name}
        <Text use="div" variant="caption" color="grey-tone-50">
          {subtitle}
        </Text>
      </Text>
    )
  }

export const renderDeliverablesSelectorOption = (
  option: RadioSelectOption<EpicOption>,
  isEmployee: boolean,
) => {
  return (
    <Text>
      {option.value?.name}
      {isEmployee && (
        <Text use="div" variant="caption" color="grey-tone-50">
          [{option.value?.type?.name || ''}] {option.value?.unitName || ''}
        </Text>
      )}
    </Text>
  )
}

export const transformParentKpiToIndividual = (
  parent: KpiInterface,
  employee: { id: number; full_name: string },
): Partial<KpiInterface> => ({
  ...omit(parent, ['created_by', 'id']),
  owner: employee as any,
  team: null,
  department: null,
  function: null,
  role: null,
  is_company: false,
  is_employee: true,
  is_inherited: true,
  kpi_type: { id: KPITypes.employee_kpi, name: KPITypes.employee_kpi },
  kpi_category: { id: KPITypes.cascaded_kpi, name: KPITypes.cascaded_kpi },
  parent,
})

export const autoCalculateKPIWeights = (
  kpis: KpiInterface[],
): { [kpiId: string]: number } => {
  const businessKpis = kpis.filter(
    kpi =>
      kpi.kpi_performance_type.id === KPIPerformanceTypes.business && !kpi.enforce_weight,
  )

  let sumOfEnforced = kpis.reduce(
    (prev, curr) => prev + (curr.enforce_weight && curr?.weight ? curr.weight : 0),
    0,
  )

  const totalMinusEnforced = 100 - sumOfEnforced
  const numKpis = businessKpis.length
  const weight = roundFloat(totalMinusEnforced / numKpis, 2)

  // To balance the weights when they don't sum up to totalMinusEnforced
  const remainder = roundFloat(totalMinusEnforced - numKpis * weight, 2)
  const partSize = remainder > 0 ? 0.01 : -0.01
  const numParts = remainder ? roundFloat(remainder / partSize, 2) : 0

  const weightsResult: { [kpiId: string]: number } = {}

  businessKpis.forEach((kpi, key) => {
    const newWeight = key < numParts ? weight + partSize : weight
    kpi.weight = newWeight
    weightsResult[kpi.id] = newWeight
  })

  return weightsResult
}

export const calculateRelevantKPISectionPerformance = (
  kpis: KpiInterface[],
  calibrated?: boolean,
): { performance: number | null; notDoneMandatory: boolean } => {
  const entitiesPerformance = new Map<string, number>()
  let notDoneMandatory: boolean = false

  for (let kpi of kpis) {
    const weight =
      kpi.weight && kpi.weight > 0 && kpi.weight <= 100 ? kpi.weight / 100 : 0
    const entity = getEntity(kpi)
    const performance = calibrated ? kpi.calibrated_progress : kpi.performance || 0

    if (isNil(performance)) {
      return { performance: null, notDoneMandatory: false }
    }
    const isMandatory = kpi.kpi_performance_type?.id === KPIPerformanceTypes.mandatory

    if (entity && weight && !isMandatory) {
      if (entitiesPerformance.has(entity)) {
        const current = entitiesPerformance.get(entity)
        entitiesPerformance.set(entity, (current || 0) + performance * weight)
      } else {
        entitiesPerformance.set(entity, performance * weight)
      }
    } else if (isMandatory && entity) {
      if (performance < 1) {
        notDoneMandatory = true
      }
    }
  }

  let sum = 0
  entitiesPerformance.forEach(value => {
    sum += value
  })
  return {
    performance: sum / entitiesPerformance.size,
    notDoneMandatory,
  }
}

export const useRenderRefreshKPIsTableNote = (
  table: useTableReturnType<KpiInterface, Stats>,
  canRefreshKpis?: boolean,
) => {
  const [refreshKpisPending, setRefreshKpisPending] = useState(false)
  const dateTooltip = useTooltip()
  const timeoutTooltip = useTooltip()
  const user = useSelector(selectUser)
  const location = useLocation()
  const refreshSelfKpiTimeout = Cookies.get(COOKIE.REFRESH_SELF_KPI_TIMEOUT)
  const isUserKpiPage =
    location.pathname ===
    pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.PERSONAL, { id: user?.id })
  const disableRefresh = isUserKpiPage && !!refreshSelfKpiTimeout

  const handleRefreshKPIs = async () => {
    if (!disableRefresh) {
      try {
        setRefreshKpisPending(true)
        await refreshEntityKPIs(table.fetchQuery)
        table.refresh()
        if (isUserKpiPage) {
          cookiesApi.set(COOKIE.REFRESH_SELF_KPI_TIMEOUT, 'true', {
            expires: addMinutes(new Date(), 5),
          })
        }
        pushNotification({
          value:
            'The refresh for KPIs has been scheduled and will occur in the background.',
          duration: 5000,
          type: NotificationTypes.success,
        })
      } finally {
        setRefreshKpisPending(false)
      }
    }
  }

  const refreshNote = (
    <Flex mt="s-16" alignItems="center" gap="s-6">
      {refreshKpisPending ? (
        <DetailsSkeleton width="35%" padding={0} />
      ) : (
        <>
          {!!table?.stats?.refresh_date_time && (
            <>
              <Text variant="caption" color={Color.GREY_50} style={{ cursor: 'default' }}>
                Progress last updated{' '}
                <Text {...dateTooltip.getAnchorProps()}>
                  {getLocalDistanceTime(table.stats.refresh_date_time)} ago
                </Text>
              </Text>
              <UIKitTooltip {...dateTooltip.getTargetProps()}>
                {getLocalDateTime(table.stats.refresh_date_time)}
              </UIKitTooltip>
            </>
          )}
        </>
      )}
    </Flex>
  )

  const refreshButton = (
    <MoreBar.Action
      onClick={handleRefreshKPIs}
      useIcon={Retry}
      pending={refreshKpisPending}
      {...timeoutTooltip.getAnchorProps()}
      aria-disabled={disableRefresh}
    >
      Refresh KPIs
      {disableRefresh && (
        <UIKitTooltip {...timeoutTooltip.getTargetProps()}>
          Please wait for 5 mins to send the request again
        </UIKitTooltip>
      )}
    </MoreBar.Action>
  )

  return { refreshNote, refreshButton: canRefreshKpis ? refreshButton : null }
}
