import React from 'react'
import HelpSections from '@src/pages/Forms/EmployeePerformance/components/HelpSections/HelpSections'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Link } from '@revolut/ui-kit'
import { ManagementBehavioursHelp } from './ManagementBehavioursHelp'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

interface Props {
  isManagerBehaviour?: boolean
}

const SkillsHelp = ({ isManagerBehaviour }: Props) => {
  const { values } = useLapeContext<ReviewScorecardInterface>()

  return isManagerBehaviour ? (
    <ManagementBehavioursHelp />
  ) : (
    <HelpSections
      sections={[
        <React.Fragment key={0}>
          This scorecard has been generated based on the competency matrix for the{' '}
          {values.reviewed_employee.specialisation?.name} specialisation defined{' '}
          <Link
            to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX, {
              id: values.reviewed_employee.specialisation?.id,
            })}
            use={InternalLink}
            target="_blank"
          >
            here
          </Link>
        </React.Fragment>,
        `The criteria for higher mastery levels (e.g. Intermediate) will only appear if all criteria of the lower levels (e.g. Basic) is confirmed. Start at Basic and work up.`,
        `If there are certain skills you think you are not in a position to evaluate, please select the 'Don't Know' option.`,
        `Please provide as much justification as you can in the fields below each section, to support your assessment.`,
      ]}
    />
  )
}

export default SkillsHelp
