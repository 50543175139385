import React, { useRef, useState } from 'react'
import {
  FilterButton as FilterButtonComponent,
  RadioSelect,
  TextButton,
} from '@revolut/ui-kit'
import { OptionInterface } from '@src/interfaces/selectors'
import { Filter } from '@revolut/icons'

type Props = {
  options: OptionInterface[]
  label: string
  onChange: (option?: OptionInterface) => void
  value?: OptionInterface | null
  clearable?: boolean
  disabled?: boolean
  searchable?: boolean
  type?: 'button' | 'textButton'
  anchor?: React.RefObject<HTMLElement | null>
  width?: string | number
}

const FilterButtonRadioSelect = ({
  options,
  label,
  onChange,
  value,
  clearable = true,
  searchable,
  disabled,
  type,
  anchor,
  width,
}: Props) => {
  const anchorRef = useRef<HTMLSpanElement>(null)
  const [isOpen, setOpen] = useState(false)
  const hasValue = !!value

  return (
    <>
      <span ref={anchorRef}>
        {type === 'textButton' ? (
          <TextButton
            onClick={() => setOpen(!isOpen)}
            disabled={disabled}
            data-testid="filter-button"
          >
            Change
          </TextButton>
        ) : (
          <FilterButtonComponent
            useIcon={Filter}
            onClick={() => setOpen(!isOpen)}
            onClear={
              hasValue && clearable
                ? () => {
                    onChange(undefined)
                    setOpen(false)
                  }
                : undefined
            }
            active={hasValue}
            aria-haspopup="listbox"
            aria-expanded={isOpen}
            disabled={disabled}
          >
            {label} {hasValue ? ` · ${value?.name}` : ''}
          </FilterButtonComponent>
        )}
      </span>
      <RadioSelect<OptionInterface>
        anchorRef={anchor || anchorRef}
        open={isOpen}
        type="instant"
        onClose={() => setOpen(false)}
        options={options.map(person => ({
          key: person.id,
          label: person.name,
          value: person,
        }))}
        value={value}
        onChange={val => {
          onChange(val || undefined)
          setOpen(false)
        }}
        labelList={label}
        labelSearch="Search"
        labelSelectAll="Select all"
        labelNoResults="No results found"
        searchable={searchable}
        width={width}
        maxWidth={width}
      />
    </>
  )
}

export default FilterButtonRadioSelect
