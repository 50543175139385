import React, { useState } from 'react'
import { TabBar } from '@revolut/ui-kit'
import UseExistingScorecard from '@src/pages/OnboardingChecklist/HiringProcess/components/UseExistingScorecard'
import CreateNewScorecard from '@src/pages/OnboardingChecklist/HiringProcess/components/CreateNewScorecard'

type DefineScorecardProps = {
  disableNext: boolean
  onCancel: () => void
  onNext: () => void
}

const DefineScorecard = ({ disableNext, onCancel, onNext }: DefineScorecardProps) => {
  const [tab, setTab] = useState<'useExisting' | 'createNew' | undefined>('useExisting')
  return (
    <>
      <TabBar variant="segmented" value={tab} onChange={setTab}>
        <TabBar.Item to="useExisting">Use existing</TabBar.Item>
        <TabBar.Item to="createNew">Create new</TabBar.Item>
      </TabBar>
      {tab === 'useExisting' && (
        <UseExistingScorecard
          disableNext={disableNext}
          onCancel={onCancel}
          onNext={onNext}
        />
      )}
      {tab === 'createNew' && <CreateNewScorecard onCancel={onCancel} onNext={onNext} />}
    </>
  )
}

export default DefineScorecard
