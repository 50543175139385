import React, { useState } from 'react'
import { Avatar, abbreviate, Icon, Relative, Circle } from '@revolut/ui-kit'
import { getAvatarUrl } from '@src/utils/employees'
import { UserAvatarUpload } from '@src/features/UserAvatarUpload/UserAvatarUpload'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

interface FormTitleAvatarProps {
  data: EmployeeInterface
  refreshData: () => void
}

export const FormTitleAvatar = ({ data, refreshData }: FormTitleAvatarProps) => {
  const user = useSelector(selectUser)
  const [avatarUploadOpen, setAvatarUploadOpen] = useState(false)
  const [isMouseOver, setMouseOver] = useState(false)

  if (user.id !== data.id) {
    return (
      <Avatar
        size={56}
        image={getAvatarUrl(data.avatar, 'md')}
        label={abbreviate(data.full_name)}
      />
    )
  }

  return (
    <>
      <Relative
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <Avatar
          size={56}
          image={getAvatarUrl(data.avatar, 'md')}
          label={abbreviate(data.full_name)}
        />
        {isMouseOver ? (
          <Circle
            bg="modal-overlay"
            size={38}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              cursor: 'pointer',
            }}
            onClick={() => setAvatarUploadOpen(true)}
          >
            <Icon name="Camera" color="white" size={24} />
          </Circle>
        ) : null}
      </Relative>
      <UserAvatarUpload
        open={avatarUploadOpen}
        onClose={() => setAvatarUploadOpen(false)}
        onSuccess={refreshData}
      />
    </>
  )
}
