import React, { useEffect, useMemo, useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { ColoredPercent } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import TeamSummary from '@src/pages/Forms/TeamForm/Summary/Summary'
import TeamKpi from '@src/pages/Forms/TeamForm/KPI'
import TeamRoadmap from '@src/pages/Forms/TeamForm/Roadmap/Roadmap'
import TeamRequisitions from '@src/pages/Forms/TeamForm/Requisitions/Requisitions'
import TeamCX from '@src/pages/Forms/TeamForm/CX'
import AuditCircles from '@components/ColumnInserts/AuditCircles/AuditCircles'
import TeamAudit from '@src/pages/Forms/TeamForm/Findings/Findings'
import Talent from '@src/pages/Forms/TeamForm/Talent/Talent'
import { EntityPermissions, FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import RiskCircles from '@components/ColumnInserts/RiskCircles/RiskCircles'
import TeamRisk from '@src/pages/Forms/TeamForm/Risk/Risk'
import TeamAccessRequests from '@src/pages/Forms/TeamForm/DataAccess/DataAcess'
import TeamBudget from '@src/pages/Forms/TeamForm/Budget/Budget'
import Loader from '@components/CommonSC/Loader'
import { useGetTeam } from '@src/api/teams'
import { pathToUrl } from '@src/utils/router'
import { Dot } from '@src/pages/Forms/FormTitles'
import Tooltip from '@components/Tooltip/Tooltip'
import { TalentStatsInterface } from '@src/interfaces/functions'
import { getTalentStats } from '@src/api/talent'
import { ColoredText, Status } from '@src/components/CommonSC/General'
import CXCircles from '@src/components/ColumnInserts/CXCircles/CXCircles'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Box, Flex, Link, Token } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PageWrapper } from '@components/Page/Page'
import { Statuses } from '@src/interfaces'
import { getTalentStatsQuickSummary } from '@src/pages/Forms/CommonTalentTab/TalentStats'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import upperFirst from 'lodash/upperFirst'
import { useTeamBugCountsTickets, useTeamCXBugCountsTickets } from '@src/api/cx'
import { AuditCyclesInterface } from '@src/interfaces/audit'
import CXBugs from '@src/features/FormTabs/CX/CXBugs'
import Bugs from '@src/features/FormTabs/CX/Bugs'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { FormErrorGuard } from '@src/features/Form/FormErrorGuard'
import Page404 from '@src/pages/Page404/Page404'
import { Engagement } from './Engagement/Engagement'
import { useSelector } from 'react-redux'
import {
  selectFeatureFlags,
  selectPermissions,
  selectUser,
} from '@src/store/auth/selectors'
import {
  useGetOrganisationSettings,
  useGlobalSettings,
  useGetRoadmapSettings,
  useGetRequisitionSettings,
} from '@src/api/settings'
import { overallScoreToColor } from '@src/apps/People/Engagement/helpers'
import { MeetingsTracker } from '@src/pages/Forms/MeetingsTracker/MeetingsTracker'
import { MeetingTrackerEntity } from '@src/interfaces/meetingsTracker'
import { CXBugsReport } from '@src/features/FormTabs/CX/CXBugsReport'
import { BugsReport } from '@src/features/FormTabs/CX/BugsReport'
import { EntityTypes } from '@src/constants/api'
import { AnalyticsDashboards } from './AnalyticsDashboards/AnalyticsDashboards'
import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalsTab } from '@src/features/Goals/GoalsTab'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const Team = () => {
  const params = useParams<{ id: string }>()
  const backUrl = ROUTES.ORGANISATION.TEAMS
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const user = useSelector(selectUser)

  const { data, isLoading, isError, error, refetch } = useGetTeam(params.id)
  const [stats, setStats] = useState<TalentStatsInterface>()
  const entity = useMemo<OrgEntityInterface | undefined>(
    () => (data ? { type: EntityTypes.teams, data } : undefined),
    [data],
  )

  const { data: settings } = useGetOrganisationSettings()
  const {
    settings: { compensation_enabled, engagement_enabled },
  } = useGlobalSettings()
  const cxEnabled = !!settings?.customer_experience?.enabled
  const bugTrackingEnabled = !!settings?.bugs_tracking?.enabled
  const findingsEnabled = !!settings?.findings?.enabled
  const riskEnabled = !!settings?.risk?.enabled
  const dataAccessEnabled = !!settings?.data_access_requests?.enabled
  const budgetManagementEnabled = !!settings?.enable_budget_management
  const approvalsEnabled = !!settings?.enable_teams_approvals

  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: requisitionSettings } = useGetRequisitionSettings()

  const bugCounts = useTeamBugCountsTickets(bugTrackingEnabled ? data?.id : undefined)
  const CXBugCounts = useTeamCXBugCountsTickets(bugTrackingEnabled ? data?.id : undefined)

  const getDataBugsCircles = (): AuditCyclesInterface => {
    return {
      audits_exceptional: bugCounts.data?.bug_critical || 0,
      audits_critical: bugCounts.data?.bug_high || 0,
      audits_recommended: bugCounts.data?.bug_medium || 0,
      audits_trivial: bugCounts.data?.bug_low || 0,
    }
  }

  const getDataCXBugsCircles = (): AuditCyclesInterface => {
    return {
      audits_exceptional: CXBugCounts.data?.blocker || 0,
      audits_critical: CXBugCounts.data?.critical || 0,
      audits_recommended:
        (CXBugCounts.data?.major || 0) + (CXBugCounts.data?.medium || 0),
      audits_trivial:
        (CXBugCounts.data?.minor || 0) + (CXBugCounts.data?.improvement || 0),
    }
  }

  useEffect(() => {
    if (params.id) {
      getTalentStats('team__id', params.id).then(res => {
        if (res.data) {
          setStats(res.data)
        }
      })
    }
  }, [])

  const teamNameWithLink = useMemo(() => {
    if (!data) {
      return null
    }
    const { name, department } = data

    if (!name || !department) {
      return null
    }

    const regex = RegExp(`^(?<head>.*)(?<dpt>${department.name})(?<tail>.*)$`)
    const match = name.match(regex)
    if (!match || !match.groups?.dpt) {
      return null
    }
    return (
      <>
        {match.groups?.head || ''}
        <Link
          use={InternalLink}
          to={pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, { id: department.id })}
        >
          {match.groups?.dpt}
        </Link>
        {match.groups?.tail || ''}
      </>
    )
  }, [data?.name, data?.department?.name])

  if (isLoading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  if (isError) {
    return <FormErrorGuard error={error} />
  }

  if (!data || !params.id) {
    return <Page404 />
  }

  const isTeamOwner = user.id === data.team_owner?.id
  const canViewMeetingsTab =
    (isTeamOwner || permissions.includes(PermissionTypes.ViewMeeting)) &&
    featureFlags.includes(FeatureFlags.OneToOnes) &&
    !featureFlags.includes(FeatureFlags.CommercialProduct)

  const tabs = [
    {
      title: 'Summary',
      path: ROUTES.FORMS.TEAM.SUMMARY,
      to: pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, params),
      component: TeamSummary,
    },
    {
      title: 'Goals',
      quickSummary: <ColoredPercent percent={0} />, // todo: set a proper data
      path: ROUTES.FORMS.TEAM.GOALS,
      to: pathToUrl(ROUTES.FORMS.TEAM.GOALS, params),
      component: GoalsTab,
      canView: !!data.id && featureFlags.includes(FeatureFlags.CanAddGoals),
    },
    {
      title: 'KPI',
      quickSummary: <ColoredPercent percent={data.kpi_performance_percent * 100} />,
      path: ROUTES.FORMS.TEAM.KPI,
      to: pathToUrl(ROUTES.FORMS.TEAM.KPI, params),
      component: TeamKpi,
    },
    {
      title: 'Analytics',
      path: ROUTES.FORMS.TEAM.ANALYTICS_DASHBOARDS,
      to: pathToUrl(ROUTES.FORMS.TEAM.ANALYTICS_DASHBOARDS, params),
      component: AnalyticsDashboards,
      canView: !!data.id && !featureFlags.includes(FeatureFlags.CommercialProduct),
      quickSummary: <QuickSummaryCount count={data.dashboard_count} />,
    },
    {
      title: 'Roadmap',
      quickSummary: <ColoredPercent percent={data.roadmap_progress_percent * 100} />,
      path: ROUTES.FORMS.TEAM.ROADMAP,
      to: pathToUrl(ROUTES.FORMS.TEAM.ROADMAP, params),
      component: TeamRoadmap,
      canView: !!roadmapSettings?.enabled,
    },
    {
      title: 'Talent',
      path: ROUTES.FORMS.TEAM.TALENT.ANY,
      to: pathToUrl(ROUTES.FORMS.TEAM.TALENT.GENERAL, params),
      quickSummary: getTalentStatsQuickSummary({
        headcount: data?.headcount,
        nips: stats?.nips,
      }),
      component: Talent,
      canView: !!data?.id,
    },
    {
      title: 'Meetings' as const,
      path: ROUTES.FORMS.TEAM.MEETINGS,
      to: pathToUrl(ROUTES.FORMS.TEAM.MEETINGS, params),
      component: MeetingsTracker,
      canView: !!data.id && canViewMeetingsTab,
    },
    {
      title: 'Requisitions',
      quickSummary: <QuickSummaryCount count={data.requisition_remaining_headcount} />,
      path: ROUTES.FORMS.TEAM.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.TEAM.REQUISITIONS, params),
      component: TeamRequisitions,
      canView: requisitionSettings?.enabled,
    },
    {
      title: 'CX',
      path: ROUTES.FORMS.TEAM.CX.ANY,
      to: pathToUrl(ROUTES.FORMS.TEAM.CX.ALL, params),
      quickSummary: <CXCircles data={data} />,
      component: TeamCX,
      canView: cxEnabled,
    },
    {
      path: ROUTES.FORMS.TEAM.CX_BUGS,
      to: pathToUrl(ROUTES.FORMS.TEAM.CX_BUGS, params),
      title: 'Product Audits',
      component: CXBugs,
      quickSummary: <AuditCircles data={getDataCXBugsCircles()} />,
      canView: bugTrackingEnabled,
    },
    // tab with Table UI Report Result
    {
      title: 'Product Audits New',
      path: ROUTES.FORMS.TEAM.CX_BUGS_V2,
      to: pathToUrl(ROUTES.FORMS.TEAM.CX_BUGS_V2, params),
      component: CXBugsReport,
      canView: bugTrackingEnabled && featureFlags.includes(FeatureFlags.ReportingApp),
    },
    {
      path: ROUTES.FORMS.TEAM.BUGS,
      to: pathToUrl(ROUTES.FORMS.TEAM.BUGS, params),
      title: 'Technical Bugs',
      component: Bugs,
      quickSummary: <AuditCircles data={getDataBugsCircles()} />,
      canView: bugTrackingEnabled,
    },
    // tab with Table UI Report Result
    {
      path: ROUTES.FORMS.TEAM.BUGS_V2,
      to: pathToUrl(ROUTES.FORMS.TEAM.BUGS_V2, params),
      title: 'Technical Bugs New',
      canView: bugTrackingEnabled && featureFlags.includes(FeatureFlags.ReportingApp),
      component: BugsReport,
    },
    {
      title: 'Findings',
      quickSummary: <AuditCircles data={data} />,
      path: ROUTES.FORMS.TEAM.AUDIT,
      to: pathToUrl(ROUTES.FORMS.TEAM.AUDIT, params),
      component: TeamAudit,
      canView: findingsEnabled,
    },
    {
      title: 'Engagement',
      path: ROUTES.FORMS.TEAM.ENGAGEMENT.ANY,
      to: pathToUrl(ROUTES.FORMS.TEAM.ENGAGEMENT.DRIVERS, params),
      component: Engagement,
      canView:
        engagement_enabled &&
        !!data.id &&
        permissions.includes(PermissionTypes.ViewEngagementTabs),
      quickSummary: (
        <QuickSummaryCount
          show0InGrey
          count={data.average_score}
          color={overallScoreToColor(data.average_score)}
        />
      ),
    },
    {
      title: 'Risk',
      path: ROUTES.FORMS.TEAM.RISK.ANY,
      to: pathToUrl(ROUTES.FORMS.TEAM.RISK.SUMMARY, params),
      quickSummary: <RiskCircles data={data} />,
      component: TeamRisk,
      canView:
        riskEnabled &&
        data.field_options.permissions?.includes(EntityPermissions.ViewKri),
    },
    {
      title: 'Data Access',
      path: ROUTES.FORMS.TEAM.ACCESS_REQUESTS,
      to: pathToUrl(ROUTES.FORMS.TEAM.ACCESS_REQUESTS, params),
      component: TeamAccessRequests,
      canView:
        dataAccessEnabled &&
        data?.field_options?.permissions?.includes(
          PermissionTypes.ViewTeamAccessRequests,
        ),
    },
    {
      title: 'Compensation',
      path: ROUTES.FORMS.TEAM.BUDGET,
      to: pathToUrl(ROUTES.FORMS.TEAM.BUDGET, params),
      component: TeamBudget,
      canView:
        compensation_enabled &&
        budgetManagementEnabled &&
        data?.field_options?.permissions?.includes(
          EntityPermissions.ViewCompensationReviews,
        ),
    },
  ]

  const filteredTabs = tabs.filter(organisationSubtab => {
    if (organisationSubtab.canView === undefined) {
      return true
    }

    return organisationSubtab.canView
  })

  return (
    <OrgEntityProvider entity={entity}>
      <PageWrapper>
        <PageHeader
          title={
            <>
              {!data.name ? 'New Team' : teamNameWithLink || data.name}
              {!!data.performance && (
                <>
                  <Dot>·</Dot>
                  <Box display="inline-block">
                    <Tooltip placement="right" text="Overall Performance">
                      <ColoredPercent percent={data.performance * 100} />
                    </Tooltip>
                  </Box>
                </>
              )}
              {data.status === Statuses.archived && (
                <>
                  <Dot>·</Dot>
                  <Box display="inline-block">
                    <ColoredText color={Token.color.greyTone20}>Archived</ColoredText>
                  </Box>
                </>
              )}
              {data.approval_status !== ApprovalStatuses.Approved &&
                (approvalsEnabled ||
                  data.approval_status !== ApprovalStatuses.Pending) && (
                  <>
                    <Dot>·</Dot>
                    <Status status={data.approval_status}>
                      {upperFirst(data.approval_status)}
                    </Status>
                  </>
                )}
            </>
          }
          subtitle={
            <>
              <Link
                color="inherit"
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                  id: data.team_owner?.id,
                })}
                target="_blank"
              >
                Team owner: {data.team_owner?.full_name}
              </Link>
              {!teamNameWithLink && !!data.department && (
                <Link
                  use={InternalLink}
                  color="inherit"
                  to={pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, {
                    id: data.department.id,
                  })}
                  target="_blank"
                >
                  , Department: {data.department.name}
                </Link>
              )}
            </>
          }
          backUrl={backUrl}
        >
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={filteredTabs} />
          </Box>
        </PageHeader>
        <Flex pt="s-8" flex="1 0" flexDirection="column" width="100%">
          <Switch>
            {tabs.map(tab => (
              <Route exact path={tab.path} key={tab.path}>
                <tab.component
                  data={data!}
                  isTeam
                  refetch={refetch}
                  meetingEntityType={MeetingTrackerEntity.team}
                  entity={EntityTypes.team}
                />
              </Route>
            ))}
          </Switch>
        </Flex>
      </PageWrapper>
    </OrgEntityProvider>
  )
}

export default Team
