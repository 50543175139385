import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@src/styles/theme'
import { Color } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'

const ColoredText = styled.span<{ color: string }>`
  color: ${props => props.color};
`

interface Props {
  value: number
}

const QualityScore = ({ value }: Props) => {
  const theme = useTheme()

  if (value === null) {
    return <ColoredText color={getColor(theme, Color.GREY_20_OPAQUE_90)}>N/A</ColoredText>
  }
  if (value < 50) {
    return <ColoredText color={theme.colors.red}>Low</ColoredText>
  }
  if (value < 70) {
    return <ColoredText color={theme.colors.warning}>Medium</ColoredText>
  }
  if (value >= 70) {
    return <ColoredText color={theme.colors.green}>High</ColoredText>
  }
  return <ColoredText color={getColor(theme, Color.GREY_20_OPAQUE_90)}>N/A</ColoredText>
}

export default QualityScore
