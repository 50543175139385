import React from 'react'
import { Spacer, VStack } from '@revolut/ui-kit'

import { LayoutTabProps } from '@src/pages/EmployeeProfile/Layout/common/types'
import {
  canViewTimeOffBalances,
  canViewTimeOffRequests,
} from '../../Preview/ProfileSummary/common'
import { BalancesWidget } from './BalancesWidget'
import { RequestsWidget } from './RequestsWidget'
import { ApprovalsWidget } from '@src/pages/EmployeeProfile/Layout/TimeOff/ApprovalsWidget'

export const TimeOffLayoutTab = ({ data }: LayoutTabProps) => {
  return (
    <VStack space="s-16">
      {canViewTimeOffRequests(data) && <ApprovalsWidget data={data} />}
      {canViewTimeOffBalances(data) && <BalancesWidget data={data} />}
      <RequestsWidget data={data} />
      <Spacer height="30vh" />
    </VStack>
  )
}
