import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, Text, TextButton, Dropdown } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  InterviewRoundSummaryInterface,
  InterviewRoundSummaryRatingInterface,
  InterviewRoundSummaryRatingNormalizedInterface,
} from '@src/interfaces/interviewTool'
import { RowInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { ratingToColor } from '@src/utils/performance'
import { PerformanceRatingTitle } from '@src/constants/performance'
import styled from 'styled-components'
import { performanceToString } from '@src/features/Scorecard/constants'
import { SeniorityInterface } from '@src/interfaces/seniority'
import ShevronToggle from '@components/Atoms/ShevronToggle'
import { getSelectors } from '@src/api/selectors'
import {
  interviewCompetencyMatrixCriteriaColumn,
  interviewCompetencyMatrixExpectationColumn,
  interviewCompetencyMatrixInterviewerColumn,
  interviewCompetencyMatrixRatingColumn,
} from '@src/constants/columns/interviewCompetencyMatrix'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

type Props = {
  summary?: InterviewRoundSummaryInterface
}

const Container = styled.div`
  display: grid;
  grid-column-gap: 16px;
`

const getRow = (
  expectation?: SeniorityInterface,
): RowInterface<
  InterviewRoundSummaryRatingNormalizedInterface | InterviewRoundSummaryRatingInterface
> => ({
  noChildrenRequest: true,
  isOpen: () => true,
  cells: [
    {
      ...interviewCompetencyMatrixCriteriaColumn,
      width: 240,
    },
    {
      ...interviewCompetencyMatrixInterviewerColumn,
      width: 120,
    },
    {
      ...interviewCompetencyMatrixRatingColumn,
      width: 170,
    },
    {
      ...interviewCompetencyMatrixExpectationColumn,
      width: 170,
      background: (data, theme) => {
        if ('children' in data) {
          return 'inherit'
        }
        const found = data.competencies?.find(
          competency => competency.seniority_id === expectation?.id,
        )
        return ratingToColor(theme, found?.competency_level) || 'inherit'
      },
      insert: ({ data }) => {
        if ('children' in data) {
          return ''
        }

        const found = data.competencies?.find(
          competency => competency.seniority_id === expectation?.id,
        )

        return found?.competency_level
          ? PerformanceRatingTitle[found?.competency_level]
          : ''
      },
    },
  ],
})

const CompetencyMatrix = ({ summary }: Props) => {
  const [expectation, setExpectation] = useState<SeniorityInterface>()
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)
  const [seniorities, setSeniorities] = useState<SeniorityInterface[]>()

  useEffect(() => {
    const fetchSeniorities = async () => {
      const response = await getSelectors(selectorKeys.seniority)
      const sortedSeniorities = (response.data.options as SeniorityInterface[]).sort(
        (a, b) => a.level - b.level,
      )
      setSeniorities(sortedSeniorities)
    }
    fetchSeniorities()
  }, [])

  useEffect(() => {
    setExpectation(summary?.suggested_seniority)
  }, [summary?.suggested_seniority])

  if (!summary) {
    return null
  }

  return (
    <TableWrapper mb="s-32">
      <Box padding="s-16" backgroundColor="action-background" mb="s-16" radius="widget">
        <Container style={{ gridTemplateColumns: 'auto auto auto' }}>
          <Text variant="h6">System recommendation</Text>
          <Text variant="h6">Suggested Grade</Text>
          <Text variant="h6">Expectation</Text>
          <Text variant="h5">{summary?.suggested_seniority?.name || '-'}</Text>
          <Text variant="h5">
            {summary?.suggested_grade
              ? performanceToString(summary.suggested_grade)
              : '-'}
          </Text>
          <TextButton
            ref={ref}
            width="100%"
            onClick={() => setIsOpen(!isOpen)}
            color="foreground"
          >
            <Flex alignItems="center">
              <Text mr="s-6" variant="h5" color="grey-tone-50">
                {expectation?.name || '-'}
              </Text>
              <Box use="span" pl="s-6">
                <ShevronToggle size={24} isOpen={isOpen} />
              </Box>
            </Flex>
          </TextButton>
          <Dropdown open={isOpen} anchorRef={ref} onClose={() => setIsOpen(false)}>
            {seniorities?.map(seniority => (
              <Dropdown.Item
                use="button"
                key={seniority.id}
                onClick={() => {
                  setExpectation(seniority)
                  setIsOpen(false)
                }}
              >
                {seniority.name}
              </Dropdown.Item>
            ))}
          </Dropdown>
        </Container>
      </Box>
      <AdjustableTable<InterviewRoundSummaryRatingNormalizedInterface>
        name={TableNames.HiringScorecardCompetencyMatrix}
        row={getRow(expectation)}
        data={summary?.skill_ratings_normalized || []}
        count={summary?.skill_ratings_normalized.length || 0}
        noReset
        hideCountAndButtonSection
      />
    </TableWrapper>
  )
}

export default CompetencyMatrix
