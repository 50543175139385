import React from 'react'
import capitalize from 'lodash/capitalize'
import { Avatar, Color, Item } from '@revolut/ui-kit'
import { AccountActions } from '@revolut/icons'

import { useTheme } from '@src/styles/theme'
import { TablePreview } from '@components/TablePreview/TablePreview'
import { AllScreeningChecksInterface } from '@src/interfaces/screeningChecksInterface'
import { getAllEmployeeScreeningChecks } from '@src/api/screening'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import Icon from '@components/Icon/Icon'
import {
  getScreeningStatusColor,
  screeningStatusToIcon,
} from '@src/pages/Forms/ScreeningForm/common'
import { formatDate } from '@src/utils/format'
import { CommonPreviewProps } from './types'

export const ScreeningCheck = ({ employeeId, hideTableRoute }: CommonPreviewProps) => {
  const theme = useTheme()

  return (
    <TablePreview<AllScreeningChecksInterface>
      title="Screening"
      api={{ getItems: getAllEmployeeScreeningChecks(employeeId) }}
      tableRoute={
        hideTableRoute
          ? undefined
          : pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.SCREENING, { id: employeeId })
      }
      createNewRoute={pathToUrl(ROUTES.FORMS.SCREENING.SELECT, { employeeId })}
      emptyState={{
        title: 'No screening records added',
        icon: AccountActions,
      }}
      row={screening => {
        return (
          <Item
            use="button"
            onClick={() =>
              navigateTo(
                pathToUrl(
                  screening.type.id === 'hireright'
                    ? ROUTES.FORMS.SCREENING.HIRERIGHT.PREVIEW
                    : ROUTES.FORMS.SCREENING.MANUAL.PREVIEW,
                  {
                    employeeId,
                    id: screening.screening_id,
                  },
                ),
              )
            }
            key={screening.id}
          >
            <Item.Avatar>
              <Avatar
                useIcon={
                  screening.type.id === 'hireright' ? (
                    <Icon type="HireRight" size="medium" />
                  ) : (
                    AccountActions
                  )
                }
              >
                <Avatar.Badge
                  useIcon={screeningStatusToIcon(screening.status?.id)}
                  bg={getScreeningStatusColor(theme, screening.status?.id) as Color}
                />
              </Avatar>
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{capitalize(screening.screening_name)}</Item.Title>
              <Item.Description>{screening.type.name}</Item.Description>
            </Item.Content>
            <Item.Side>
              <Item.Value color={getScreeningStatusColor(theme, screening.status?.id)}>
                {capitalize(screening.status?.name)}
              </Item.Value>
              {screening.completion_date_time && (
                <Item.Value variant="secondary">
                  On {formatDate(screening.completion_date_time)}
                </Item.Value>
              )}
            </Item.Side>
          </Item>
        )
      }}
    />
  )
}
