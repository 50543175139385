import { FileInterface } from '@src/interfaces/files'

export const isFile = (file: FileInterface | File): file is File => {
  return (file as FileInterface).id === undefined
}

export const getPreviewSrc = (url?: string | null, type?: string) => {
  switch (type) {
    case 'pdf':
      return `${url}#view=fitH&toolbar=0&navpanes=0`

    case 'doc':
    case 'docx':
      return `https://docs.google.com/viewer?url=${encodeURIComponent(
        url!,
      )}&embedded=true`
    default:
      return null
  }
}

export const getPreviewLink = (url?: string | null, type?: string) => {
  switch (type) {
    case 'doc':
    case 'docx':
      return `https://docs.google.com/viewer?url=${encodeURIComponent(url!)}`
    default:
      return url
  }
}
