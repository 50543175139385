import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import capitalize from 'lodash/capitalize'
import {
  AccessRequestInterface,
  AccessTypes,
  TeamAccessRequestInterface,
} from '@src/interfaces/accessRequest'
import { Statuses } from '@src/interfaces'
import { getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

export const accessRequestDatabaseColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'database.id',
  dataPoint: 'database.name',
  sortKey: 'database_name',
  filterKey: 'database_name',
  selectorsKey: selectorKeys.employee_access_request_databases_names,
  title: 'Name',
}

export const accessRequestApproverColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'approved_by.id',
  dataPoint: 'approved_by.full_name',
  sortKey: 'approved_by__full_name',
  filterKey: 'approved_by__id',
  selectorsKey: selectorKeys.employee,
  title: 'Approver',
}

export const accessRequestTool: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'tool.id',
  dataPoint: 'tool.name',
  sortKey: 'tool__name',
  filterKey: 'tool__id',
  selectorsKey: selectorKeys.employee_access_request_tools,
  title: 'Tool',
}

export const accessRequestAccessType: ColumnInterface<AccessRequestInterface> = {
  type: CellTypes.insert,
  idPoint: 'access_type.id',
  dataPoint: 'access_type',
  sortKey: 'access_type__name',
  filterKey: 'access_type__id',
  selectorsKey: selectorKeys.employee_access_request_types,
  insert: ({ data }) => {
    switch (data.access_type.id) {
      case AccessTypes.LOOKER_FOLDER:
        return 'Folder'
      case AccessTypes.LOOKER_MODEL:
        return 'Model'
      case AccessTypes.METABASE_TEAM:
      case AccessTypes.METABASE_USER:
        return 'Database'
      default:
        return '-'
    }
  },
  title: 'Access Type',
}

export const accessRequestPermissionColumn: ColumnInterface<any> = {
  type: CellTypes.insert,
  idPoint: 'permission',
  dataPoint: 'permission',
  sortKey: 'permission',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (data.access_type?.id === 'looker_folder_user_access_request') {
      return 'Edit'
    }
    switch (data.permission) {
      case 'read':
        return 'View'
      case 'write':
        return 'SQL'
      default:
        return data.permission ? capitalize(data.permission) : '-'
    }
  },
  title: 'Permission',
}

export const accessRequestSourceColumn: ColumnInterface<AccessRequestInterface> = {
  type: CellTypes.insert,
  idPoint: 'source',
  dataPoint: 'source',
  sortKey: 'source',
  filterKey: 'source',
  selectorsKey: selectorKeys.employee_access_request_sources,
  insert: ({ data }) => {
    switch (data.source) {
      case 'team_access_request':
        return 'Team Request'
      case 'user_access_request':
        return 'User Request'
      default:
        return ''
    }
  },
  title: 'Source',
}

export const accessRequestDateFromColumn: ColumnInterface<
  AccessRequestInterface | TeamAccessRequestInterface
> = {
  type: CellTypes.date,
  idPoint: 'from_date_time',
  dataPoint: 'from_date_time',
  sortKey: 'from_date_time',
  filterKey: 'from_date_time',
  selectorsKey: selectorKeys.none,
  title: 'From date',
}

export const accessRequestApprover: ColumnInterface<TeamAccessRequestInterface> = {
  type: CellTypes.text,
  idPoint: 'approved_by.id',
  dataPoint: 'approved_by.full_name',
  sortKey: 'approved_by__full_name',
  filterKey: 'approved_by__id',
  selectorsKey: selectorKeys.employee,
  title: 'Approver',
}

export const accessRequestUsingPercent: ColumnInterface<TeamAccessRequestInterface> = {
  type: CellTypes.insert,
  filterType: FilterType.percentRange,
  idPoint: 'team_role_usage_perc',
  dataPoint: 'team_role_usage_perc',
  sortKey: 'team_role_usage_perc',
  filterKey: 'team_role_usage_perc',
  selectorsKey: selectorKeys.none,
  insert: ({ data }) =>
    data.team_role_usage_perc ? `${data.team_role_usage_perc}%` : '-',
  title: 'Usage',
}

export const accessRequestUsedCount: ColumnInterface<TeamAccessRequestInterface> = {
  type: CellTypes.text,
  filterType: FilterType.range,
  idPoint: 'used_count',
  dataPoint: 'used_count',
  sortKey: 'used_count',
  filterKey: 'used_count',
  selectorsKey: selectorKeys.none,
  title: 'Used Count',
}

export const accessRequestDaysSinceViewed: ColumnInterface<TeamAccessRequestInterface> = {
  type: CellTypes.text,
  filterType: FilterType.range,
  idPoint: 'days_since_used',
  dataPoint: 'days_since_used',
  sortKey: 'days_since_used',
  filterKey: 'days_since_used',
  selectorsKey: selectorKeys.none,
  title: 'Days Since Used',
}

export const accessRequestDateToColumn: ColumnInterface<AccessRequestInterface> = {
  type: CellTypes.date,
  idPoint: 'to_date_time',
  dataPoint: 'to_date_time',
  sortKey: 'to_date_time',
  filterKey: 'to_date_time',
  selectorsKey: selectorKeys.none,
  title: 'To date',
}

export const accessRequestReasonColumn: ColumnInterface<
  AccessRequestInterface | TeamAccessRequestInterface
> = {
  type: CellTypes.text,
  idPoint: 'reason',
  dataPoint: 'reason',
  sortKey: 'reason',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Reason',
}

export const accessRequestStatusColumn: ColumnInterface<
  AccessRequestInterface | TeamAccessRequestInterface
> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.access_request_statuses,
  colors: (data, theme) => {
    switch (data.status) {
      case Statuses.approved:
        return theme.colors.green
      case Statuses.pending:
        return theme.colors.warning
      case Statuses.rejected:
        return theme.colors.red
      case Statuses.expired:
        return getColor(theme, Color.GREY_TONE_50)
      default:
        return theme.colors.foreground
    }
  },
  title: 'Status',
}

export const accessRequestReviewStatus: ColumnInterface<TeamAccessRequestInterface> = {
  type: CellTypes.text,
  idPoint: 'review_status.id',
  dataPoint: 'review_status.name',
  sortKey: 'review_status',
  filterKey: 'review_status',
  selectorsKey: selectorKeys.data_access_review_statuses,
  colors: (data, theme) => {
    switch (data.review_status?.name) {
      case 'Extended':
        return theme.colors.green
      case 'Not extended':
        return theme.colors.warning
      default:
        return theme.colors.foreground
    }
  },
  title: 'Review Status',
}
