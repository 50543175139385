import React, { useEffect, useState } from 'react'
import {
  Box,
  Caption,
  CodeInput,
  Color,
  HStack,
  Link,
  Text,
  TextButton,
  Token,
  useMultipleCodeInput,
  Widget,
} from '@revolut/ui-kit'
import { ExclamationMarkOutline } from '@revolut/icons'
import { z } from 'zod'

import { Grid } from '@components/CommonSC/Grid'
import { get2FAHint, getEmailHint, resend2FACode, submit2FACode } from '@src/api/login'
import { API, COOKIE, env, Environments } from '@src/constants/api'
import { CredentialsLoginInterface, WhoAmIInterface } from '@src/interfaces/auth'
import { cookiesApi } from '@src/utils/cookies'
import { api } from '@src/api'
import {
  setFeatureFlagsAction,
  setPermissionsAction,
  setUserAction,
} from '@src/store/auth/actions'
import Cookies from 'js-cookie'
import { removeSignupStateCookie } from '@src/pages/SignUp/common'
import { Authenticated } from '@src/store/auth/constants'
import { useDispatch } from 'react-redux'
import { MSW_MOCK } from '@api-mocks/localstorage'
import { useLocation } from 'react-router-dom'
import { navigateReplace } from '@src/actions/RouterActions'
import { LocalStorageKeys } from '@src/store/auth/types'
import { useQuery } from '@src/utils/queryParamsHooks'
import { ROUTES } from '@src/constants/routes'
import { InternalUIKitLink } from '@src/components/InternalLink/InternalLink'

export const saveLoginData = (
  data: Pick<
    CredentialsLoginInterface,
    'permissions' | 'token' | 'employee' | 'email' | 'authenticated'
  >,
) => {
  cookiesApi.set(COOKIE.AUTHENTICATED, data.authenticated)
}

export const useLoginDevInterface = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const validateToken = async () => {
    const data = await api.get<WhoAmIInterface>(API.WHOAMI)
    if (data?.data) {
      dispatch(setUserAction(data.data.employee))
      dispatch(setPermissionsAction(data.data.permissions))
      dispatch(setFeatureFlagsAction(data.data.feature_flags))
      /**
       * Casting here because /whoami `employee` model is different from login `employee` model.
       * Good enough for development purposes since /whoami employee model just has many more properties
       */
      saveLoginData(data.data as any)
    }
  }

  const loginDevInterface = async (token: string) => {
    removeSignupStateCookie()
    cookiesApi.set(COOKIE.AUTHENTICATED, Authenticated.authenticated)
    cookiesApi.set(COOKIE.API_TOKEN, token)
    setLoading(true)
    try {
      await validateToken()
    } finally {
      setLoading(false)
    }

    window.location?.reload()
  }

  const onSubmitDevInterface = async (
    apiEndpoint: string,
    wssEndpoint: string,
    enableMsw: boolean,
    enableTheme: boolean,
    mswMocks: MSW_MOCK[],
    authToken: string,
  ) => {
    if (!Cookies.get(COOKIE.ORIGINAL_TOKEN)) {
      cookiesApi.set(COOKIE.ORIGINAL_TOKEN, Cookies.get(COOKIE.API_TOKEN) || '')
    }
    localStorage.setItem('api_endpoint', apiEndpoint)
    localStorage.setItem('wss_endpoint', wssEndpoint)
    localStorage.setItem('enable_msw', enableMsw ? 'true' : 'false')
    localStorage.setItem('enable_theme', enableTheme ? 'true' : 'false')
    localStorage.setItem('msw_mocks', JSON.stringify(mswMocks))
    await loginDevInterface(authToken)
  }

  return { onSubmitDevInterface, loginDevInterface, loading }
}

export const useDevSetPipeline = () => {
  const location = useLocation()
  const { onSubmitDevInterface } = useLoginDevInterface()

  const origin = localStorage.getItem(LocalStorageKeys.ORIGIN_DEV_SUBDOMAINS)
  const isDevSubdomains = env === Environments.developmentSubdomains

  useEffect(() => {
    if (location.hash?.startsWith('#devapi:') && isDevSubdomains) {
      const devApi = location.hash.replace('#devapi:', '')
      const url = new URL(devApi)
      localStorage.setItem(LocalStorageKeys.ORIGIN_DEV_SUBDOMAINS, url.origin)
      // to remove the hash
      navigateReplace(`${location.pathname}${location.search}`)
    }
  }, [location.hash, isDevSubdomains])

  useEffect(() => {
    const login = async () => {
      localStorage.removeItem(LocalStorageKeys.ORIGIN_DEV_SUBDOMAINS)
      await onSubmitDevInterface(
        `${origin}/employee/api/`,
        `${origin}/ws/v1/user/`,
        false,
        false,
        [],
        '',
      )
    }

    if (origin && isDevSubdomains) {
      login()
    }
  }, [origin, isDevSubdomains])
}

interface NonFieldErrorsProps {
  nonFieldErrors?: string[]
}

export const NonFieldErrors = ({ nonFieldErrors = [] }: NonFieldErrorsProps) => {
  return nonFieldErrors.length > 0 ? (
    <Widget bg={Color.RED_ACTION_BACKGROUND} p="s-16" mt="s-8">
      <HStack gap="s-8" align="center">
        <ExclamationMarkOutline color={Color.RED} />
        {nonFieldErrors.map(error => (
          <Text color={Color.RED} key={error}>
            {error}
          </Text>
        ))}
      </HStack>
    </Widget>
  ) : null
}

const CONFIRMATION_CODE_REGEXP = /^[0-9]{6}$/
const CONFIRMATION_CODE_LENGTH = 6

interface VerificationCodeFormProps {
  token: string
  onSuccess: () => void
  // indicates if only email will be used to send verification code, otherwise either email or sms could have been used.
  emailOnly?: boolean
}

export const VerificationCodeForm = ({
  token,
  onSuccess,
  emailOnly = false,
}: VerificationCodeFormProps) => {
  const { query } = useQuery<{ code?: string }>()
  const [loadingResend, setLoadingResend] = useState(false)
  const [value, setValue] = useState(query.code || '')
  const [loading, setLoading] = useState(false)
  const [hint, setHint] = useState('')
  const [method, setMethod] = useState<'email' | 'sms'>('email')
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (query.code?.length === CONFIRMATION_CODE_LENGTH) {
      setValue(query.code)
      setLoading(true)
      onSubmit(query.code)
    }
  }, [query.code])

  useEffect(() => {
    if (emailOnly) {
      getEmailHint(token).then(({ data }) => {
        setHint(data.hint)
        setMethod(data.method)
      })
      return
    }
    get2FAHint(token).then(({ data }) => {
      setHint(data.hint)
      setMethod(data.method)
    })
  }, [emailOnly])

  const onResendCode = () => {
    setLoadingResend(true)
    resend2FACode(token)
      .catch(e => {
        setLoadingResend(false)
        setError(
          e?.code?.[0] ||
            e?.data?.code?.[0] ||
            e?.response?.data?.detail ||
            'Error has occurred',
        )
      })
      .finally(() => {
        setLoadingResend(false)
      })
  }

  const onChangeCode = (val: string) => {
    const newVal = val.trimEnd()
    if (!loading) {
      setValue(newVal)
      if (newVal.length === CONFIRMATION_CODE_LENGTH) {
        setLoading(true)
        onSubmit(val)
      }
    }
  }

  const onSubmit = (code: string) => {
    submit2FACode({ code }, token)
      .then(onSuccess)
      .catch(e => {
        setLoading(false)
        setValue('')
        setError(
          e?.code?.[0] ||
            e?.data?.code?.[0] ||
            e?.response?.data?.detail ||
            'Error has occurred',
        )
      })
  }

  const inputsProps = useMultipleCodeInput({
    size: CONFIRMATION_CODE_LENGTH,
    value,
    onChange: onChangeCode,
    autoFocus: true,
  })

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault()
    const pastedText = e.clipboardData.getData('text')
    if (loading || !pastedText) {
      return
    }
    const sanitizedText = pastedText.replace('-', '')
    if (sanitizedText.match(CONFIRMATION_CODE_REGEXP)) {
      onChangeCode(sanitizedText)
    }
  }

  return (
    <Grid alignContent="start">
      <Text variant="h1" mt="90px">
        Enter your verification code
      </Text>
      <Text variant="h1" color={Color.GREY_TONE_50}>
        {hint}
      </Text>
      <Text color={Color.GREY_TONE_20} variant="primary" mt="128px">
        Enter 6-digit code from {method === 'sms' ? 'SMS' : 'email'}
      </Text>
      <Box mt="s-8">
        <Grid alignItems="center" flow="column" gap={8}>
          {inputsProps.map((inputProps, index) => (
            <React.Fragment key={inputProps.key}>
              {index === 3 && (
                <Text mr={{ all: '1px', md: '2px' }} color={Color.GREY_TONE_50}>
                  –
                </Text>
              )}
              <Box
                key={`${inputProps.key}-input`}
                mr={index === inputsProps.length - 1 ? 0 : { all: '1px', md: '2px' }}
                minWidth={46}
              >
                <CodeInput
                  type="number"
                  value={value[index]?.trim() || ''}
                  onPaste={handlePaste}
                  disabled={loading}
                  aria-label="Code input"
                  {...inputProps}
                />
              </Box>
            </React.Fragment>
          ))}
        </Grid>
        {error && (
          <Text use="div" color={Color.ERROR} pt="s-8">
            {error}
          </Text>
        )}
      </Box>
      <Text mt="s-20" color={Color.GREY_TONE_20}>
        Code hasn't arrived?{' '}
        {loadingResend ? (
          <Text>New code sent</Text>
        ) : (
          <TextButton onClick={onResendCode}>Re-send code</TextButton>
        )}
      </Text>
    </Grid>
  )
}

export const PASSWORDS_DONT_MATCH_ERROR = 'New password does not match'

export const GetRevolutPeopleCaption = () => {
  if (
    env === Environments.productionCommercialRoot ||
    env === Environments.developmentCommercialRoot
  ) {
    return (
      <Caption color={Token.color.greyTone50} textAlign="center">
        You don't have an account?{' '}
        <Link href={ROUTES.SIGNUP.MAIN}>Get Revolut People</Link>
      </Caption>
    )
  }

  return null
}

export const OnboardingLoginCaption = () => {
  return (
    <Caption color={Token.color.greyTone50}>
      Are you an employee doing your onboarding?{' '}
      <InternalUIKitLink
        // @ts-expect-error
        to={ROUTES.LOGIN.ONBOARDING}
      >
        Login here
      </InternalUIKitLink>
    </Caption>
  )
}

const INVALID_WORKSPACE_ERROR =
  'Workspace is invalid. Only letters, numbers and hyphens are allowed.'

export const validateWorkspace = (workspace?: string) =>
  z
    .string()
    .refine(
      value => /^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])*$/.test(value),
      INVALID_WORKSPACE_ERROR,
    )
    .safeParse(workspace)
