import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Cell, Flex } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EngagementDriverInterface } from '@src/interfaces/engagement'
import { engagementDriversTableRequests } from '@src/api/engagement'
import {
  engagementDriverName,
  engagementDriverOwner,
  engagementDriverCreatedOn,
  engagementDriverLastUpdateOn,
  engagementDriverStatus,
} from '@src/constants/columns/engagementDrivers'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import AdjustableTable from '@components/Table/AdjustableTable'
import Stat from '@components/Stat/Stat'
import pluralize from 'pluralize'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<EngagementDriverInterface> = {
  cells: [
    {
      ...engagementDriverName,
      width: 400,
    },
    {
      ...engagementDriverOwner,
      width: 250,
    },
    {
      ...engagementDriverCreatedOn,
      width: 250,
    },
    {
      ...engagementDriverLastUpdateOn,
      width: 250,
    },
    {
      ...engagementDriverStatus,
      width: 250,
    },
  ],
}

export const DriversTable = () => {
  const permissions = useSelector(selectPermissions)
  const table = useTable<EngagementDriverInterface>(engagementDriversTableRequests)

  const canAdd = permissions.includes(PermissionTypes.ChangeEngagement)

  return (
    <Cell p="s-16">
      <Flex width="100%">
        <Flex flexDirection="column" width="100%">
          <Stat
            label={pluralize('Driver', table.count)}
            val={table.loading ? undefined : table.count}
          />
          {canAdd && (
            <Flex my="s-16">
              <Button
                onClick={() => {
                  navigateTo(pathToUrl(ROUTES.APPS.ENGAGEMENT.DRIVER.EDIT))
                }}
                mr="s-16"
                useIcon={Plus}
                size="sm"
                variant="secondary"
              >
                Add new driver
              </Button>
            </Flex>
          )}

          <Flex style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable<EngagementDriverInterface>
              name={TableNames.EngagementDrivers}
              useWindowScroll
              row={ROW}
              {...table}
              onRowClick={(row: { id: number }) => {
                navigateTo(
                  pathToUrl(ROUTES.APPS.ENGAGEMENT.DRIVER.PREVIEW, { id: row.id }),
                )
              }}
              noDataMessage="All engagement drivers will appear here"
            />
          </Flex>
        </Flex>
      </Flex>
    </Cell>
  )
}
