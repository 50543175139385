import {
  Flex,
  Text,
  VStack,
  Widget,
  Link as UIKitLink,
  TextSkeleton,
} from '@revolut/ui-kit'
import React from 'react'
import kebabCase from 'lodash/kebabCase'
import { CareersLocationsVariant, CareersPosition } from '@src/interfaces/careers'
import CareersPositionLocationsList from '@src/pages/Careers/CareersPosition/CareersPositionLocationsList'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { Link } from 'react-router-dom'

interface Props {
  positions: CareersPosition[]
  loading: boolean
  error: boolean
}

const CareersPositions = ({ positions, loading, error }: Props) => {
  if (loading) {
    return (
      <VStack gap="s-16" mt="s-16" aria-label="positions-skeleton">
        <Widget p="s-16">
          <TextSkeleton variant="h4" width="40%" />
          <TextSkeleton width="60%" />
        </Widget>
        <Widget p="s-16">
          <TextSkeleton variant="h4" width="30%" />
          <TextSkeleton width="50%" />
        </Widget>
      </VStack>
    )
  }

  if (error) {
    return (
      <ActionWidget
        mt="s-16"
        title="Something went wrong"
        text="Please refresh the page"
      />
    )
  }

  if (positions.length === 0) {
    return (
      <Widget p="s-16" mt="s-16">
        <Text variant="h4" display="block">
          No results found
        </Text>
        <Text color="grey-tone-50">No results found matching your criteria</Text>
      </Widget>
    )
  }

  return (
    <VStack gap="s-16" mt="s-16" aria-label="positions">
      {positions.map(position => (
        <UIKitLink
          use={Link}
          to={getLocationDescriptor(
            pathToUrl(PUBLIC_ROUTES.CAREERS.POSITION, {
              id: `${kebabCase(position.text)}-${position.id}`,
            }),
          )}
          key={position.id}
          style={{ color: 'inherit', fontSize: 'inherit', textDecoration: 'none' }}
          aria-label="position-link"
        >
          <Widget borderRadius={12} p="s-16">
            <VStack gap="s-8">
              <Text variant="h4">{position.text}</Text>
              {!!position.locations?.length && (
                <Flex>
                  <CareersPositionLocationsList
                    locations={position.locations}
                    variant={CareersLocationsVariant.Small}
                  />
                </Flex>
              )}
            </VStack>
          </Widget>
        </UIKitLink>
      ))}
    </VStack>
  )
}

export default CareersPositions
