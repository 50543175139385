import { Avatar, HStack, Item, Text, Token } from '@revolut/ui-kit'
import { getStatusColor } from '@src/components/CommonSC/General'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import React from 'react'
import { getTargets } from '../../common/utils'
import { useGetGoal } from '@src/api/goals'
import { getAvatarUrl } from '@src/utils/employees'

export const ParentBadge = ({
  id,
  name,
  cycle,
}: {
  id: number
  name: string
  cycle: ReviewCyclesInterface
}) => {
  const { data: parent } = useGetGoal(id)

  const getCurrentProgress = () => {
    const currentKpi = parent?.kpis.find(
      kpi => getTargets(kpi)[0].review_cycle?.id === cycle.id,
    )
    const currentTarget = currentKpi ? getTargets(currentKpi)[0] : undefined

    return currentTarget
      ? typeof currentTarget.calibrated_progress !== 'undefined'
        ? currentTarget.calibrated_progress
        : currentTarget.progress
      : null
  }

  const currentProgress = getCurrentProgress()

  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon="Target" color={Token.color.accent} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{name}</Item.Title>
        <Item.Description>{cycle.name}</Item.Description>
      </Item.Content>
      <Item.Side>
        <HStack space="s-16" align="center">
          <Text color={getStatusColor(parent?.status?.id)}>{parent?.status?.name}</Text>
          {typeof currentProgress === 'number' ? (
            <Text>{currentProgress * 100}%</Text>
          ) : null}
          {parent?.owner.avatar ? (
            <Avatar src={getAvatarUrl(parent.owner.avatar)} />
          ) : null}
        </HStack>
      </Item.Side>
    </Item>
  )
}
