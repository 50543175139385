import React, { useState } from 'react'
import {
  Box,
  InputGroup,
  ItemSkeleton,
  Link,
  TabBar,
  Text,
  Token,
  Widget,
} from '@revolut/ui-kit'
import LapeMultiInput from '@components/Inputs/LapeFields/LapeMultiInput'
import SectionHead from '@src/pages/Careers/CareersApplication/components/SectionHead'
import { Document, LogoLinkedIn } from '@revolut/icons'
import { MY_LINKEDIN_PROFILE } from '@src/constants/externalLinks'
import { useGetPredefinedSectionsConfig } from '@src/pages/Careers/hooks/useCareersPredefinedSections'
import { PredefinedSectionKeys } from '@src/interfaces/careers'

// we load a massive pdf-dist library there
const UploadCVComponent = React.lazy(
  () => import('@src/pages/Forms/NewCandidate/UploadCV'),
)

enum Tabs {
  CV = 'CV',
  Linkedin = 'Linkedin',
}

type Props = {
  locked?: boolean
}

const CareersApplicationResume = ({ locked }: Props) => {
  const config = useGetPredefinedSectionsConfig()

  const [tab, setTab] = useState<Tabs>(Tabs.CV)

  return (
    <Widget p="s-16">
      <SectionHead title="Resume" locked={locked} />
      {config.get(PredefinedSectionKeys.resume)?.visible && (
        <TabBar
          variant="segmented fit"
          mb="s-16"
          defaultValue={Tabs.CV}
          onChange={val => {
            if (val) {
              setTab(val as Tabs)
            }
          }}
        >
          <TabBar.Item to={Tabs.CV} useIcon={Document}>
            Apply with CV
          </TabBar.Item>
          <TabBar.Item to={Tabs.Linkedin} useIcon={LogoLinkedIn}>
            Linkedin Profile
          </TabBar.Item>
        </TabBar>
      )}

      <InputGroup>
        {config.get(PredefinedSectionKeys.resume)?.visible && (
          <>
            {tab === Tabs.Linkedin ? (
              <Box>
                <Text variant="bullet" mb="s-16" display="block">
                  How to access Linkedin profile?
                </Text>
                <Text color={Token.color.greyTone50} variant="secondary">
                  1. Click{' '}
                  <Link
                    use="a"
                    href={MY_LINKEDIN_PROFILE}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </Link>{' '}
                  to open your profile. If the link doesn't work, navigate to "Me" -&gt;
                  "View Profile". <br />
                  2. Click the More button in the introduction section. <br />
                  3. Select Save to PDF from the dropdown. <br />
                  4. Attach your Linkedin profile below.
                </Text>
              </Box>
            ) : null}
            <React.Suspense fallback={<ItemSkeleton />}>
              <UploadCVComponent
                required={!config.get(PredefinedSectionKeys.resume)?.optional}
                disabled={locked}
                name="file"
                label="CV / Resume (Please attach an english version of your resume)"
              />
            </React.Suspense>
          </>
        )}
        {config.get(PredefinedSectionKeys.links)?.visible && (
          <LapeMultiInput
            title={`Add additional link eg LinkedIn, Github, portfolio, website etc. ${
              config.get(PredefinedSectionKeys.links)?.optional ? '(optional)' : ''
            }`}
            name="links"
            valueFieldName={undefined}
            required={!config.get(PredefinedSectionKeys.links)?.optional}
            disabled={locked}
          />
        )}
      </InputGroup>
    </Widget>
  )
}

export default CareersApplicationResume
