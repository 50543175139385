import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { min, max, bisector } from 'd3-array'
import { scaleLinear } from '@visx/scale'
import { Group } from '@visx/group'
import { GridRows } from '@visx/grid'
import { ParentSizeModern } from '@visx/responsive'
import { LinePath, Line, Bar } from '@visx/shape'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { localPoint } from '@visx/event'
import { useTooltipInPortal, defaultStyles, useTooltip } from '@visx/tooltip'
import { AccessorForArrayItem } from '@visx/shape/lib/types'
import { Color, Relative, themeColor } from '@revolut/ui-kit'
import { Legend, LegendContainer } from './Legend'
import { ChartLines, XAxis, YAxis, XYAxisPoints } from './types'
import {
  getDefaultTickLabelPropsX,
  getDefaultTickLabelPropsY,
  getLinesData,
  getYPointsByIndex,
} from './helpers'

const defaultMargin = {
  top: 20,
  bottom: 30,
  left: 30,
  right: 20,
}

const tooltipStyles = {
  ...defaultStyles,
  background: 'none',
  boxShadow: 'none',
}

const GraphContainer = styled.svg<{ width: number; height: number }>(
  ({ width, height }) => ({
    width,
    height,
  }),
)

interface ChartInnerProps<T>
  extends React.AriaAttributes,
    Pick<React.HTMLAttributes<HTMLDivElement>, 'role'> {
  width: number
  height: number
  dataSet: T[]
  lines: ChartLines<T>
  xAxis: XAxis
  yAxis?: YAxis
  margin?: Partial<{
    left: number
    right: number
    top: number
    bottom: number
  }>
  renderTooltip?: (data: T) => React.ReactNode
  renderDetailedTooltip?: (data: T) => React.ReactNode
  showLegend?: boolean
  alignLegendItemsToLines?: boolean
}

const ChartInner = <T,>({
  width,
  height,
  dataSet,
  lines,
  xAxis,
  yAxis,
  margin = defaultMargin,
  renderTooltip,
  renderDetailedTooltip,
  showLegend,
  alignLegendItemsToLines,
}: ChartInnerProps<T>) => {
  const theme = useTheme()
  const { top: mt, bottom: mb, left: ml, right: mr } = { ...defaultMargin, ...margin }

  const {
    tooltipLeft = ml,
    tooltipTop = mt,
    showTooltip,
    hideTooltip,
    tooltipData,
  } = useTooltip<{ datum: T; activePoints: XYAxisPoints }>()
  const { containerRef, TooltipInPortal } = useTooltipInPortal({ detectBounds: true })
  const tooltipContainerRef = useRef<HTMLDivElement>(null)
  const legendContainerRef = useRef<HTMLDivElement>(null)

  const legendWidth = legendContainerRef?.current?.clientWidth || 0
  const graphHeight = height - mt - mb
  const graphWidth = width - ml - mr - legendWidth

  useEffect(() => {
    hideTooltip()
  }, [lines, dataSet])

  const xScale = useMemo(
    () =>
      scaleLinear({
        range: [0, graphWidth],
        domain: [0, xAxis.maxValue ?? (dataSet.length - 1 || 1)],
      }),
    [graphWidth, dataSet.length, xAxis.maxValue],
  )

  const linesData = useMemo(() => getLinesData(dataSet, lines), [dataSet, lines])

  const yData = useMemo(() => {
    return linesData.reduce<number[]>((acc, value) => {
      return acc.concat(value.data)
    }, [])
  }, [linesData])

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [graphHeight, 0],
        domain: [
          yAxis?.minTickValue ?? min(yData) ?? 0,
          yAxis?.maxTickValue ?? max(yData) ?? 0,
        ],
      }),
    [height, yData, yAxis?.maxTickValue, yAxis?.minTickValue],
  )

  const xAccessor = useCallback<AccessorForArrayItem<number, number>>(
    (_, index) => xScale(index),
    [xScale],
  )
  const yAccessor = useCallback<AccessorForArrayItem<number, number>>(
    d => yScale(d),
    [yScale],
  )

  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  const bisectX = bisector<T, number>(d => d[xAxis.key]).center

  const handleTooltip = useCallback(
    (e: React.MouseEvent<SVGRectElement> | React.TouchEvent<SVGRectElement>) => {
      if (!linesData.length) {
        return
      }
      const point = localPoint(e) || { x: 0, y: 0 }

      const xAxisValue = xScale.invert(point.x - ml)
      const datumIndex = bisectX(dataSet, xAxisValue, 0, dataSet.length)
      const datum = dataSet[datumIndex]

      if (!datum) {
        return
      }
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      const xDataValue = dataSet[datumIndex][xAxis.key]
      const xDataValuePos = xScale(xDataValue) + ml

      const activePoints: XYAxisPoints = getYPointsByIndex(
        linesData,
        yScale,
        datumIndex,
      ).map(yPoint => ({ ...yPoint, left: xDataValuePos }))

      let tooltipTopPos

      if (activePoints.length === 1) {
        tooltipTopPos = activePoints[0].top
      } else if (activePoints.length > 1) {
        tooltipTopPos =
          graphHeight / 2 - (tooltipContainerRef?.current?.clientHeight ?? 0) / 2
      }
      showTooltip({
        tooltipLeft: xDataValuePos,
        tooltipTop: tooltipTopPos,
        tooltipData: { datum, activePoints },
      })
    },
    [xScale, yScale, dataSet, linesData],
  )

  if (width === 0) {
    return null
  }
  return (
    <Relative>
      <GraphContainer width={width} height={height} ref={containerRef}>
        <Group left={ml} top={mt}>
          <GridRows scale={yScale} width={graphWidth} height={graphHeight} numTicks={6} />
          <AxisLeft
            hideAxisLine
            hideTicks
            scale={yScale}
            numTicks={yAxis?.numTicks}
            tickLabelProps={() => getDefaultTickLabelPropsY(theme)}
            tickFormat={
              yAxis?.toLabel
                ? y => yAxis.toLabel!(y.valueOf()) || y.toString()
                : undefined
            }
          />
          <AxisBottom
            hideAxisLine
            hideTicks
            scale={xScale}
            top={graphHeight}
            tickLabelProps={() => getDefaultTickLabelPropsX(theme)}
            tickFormat={
              xAxis.toLabel ? x => xAxis.toLabel!(x.valueOf()) || x.toString() : undefined
            }
            numTicks={xAxis.numTicks || dataSet.length - 1}
          />
          {linesData.map(line => {
            return (
              <LinePath
                key={line.name}
                data={line.data}
                stroke={themeColor(line.color)({ theme })}
                strokeWidth={2}
                x={xAccessor}
                y={yAccessor}
              />
            )
          })}
        </Group>
        <g>
          <Line
            from={{ x: tooltipLeft, y: mt }}
            to={{ x: tooltipLeft, y: graphHeight + mt }}
            stroke={themeColor(Color.GREY_TONE_20)({ theme })}
            strokeWidth={1}
            pointerEvents="none"
          />
          {!!tooltipData &&
            tooltipData.activePoints.map(({ name, top, left, color }) => {
              return (
                <g key={name}>
                  <circle
                    cx={left}
                    cy={top + mt}
                    r={6}
                    fill={themeColor(color)({ theme })}
                    strokeWidth={0}
                    pointerEvents="none"
                  />
                  <circle
                    cx={left}
                    cy={top + mt}
                    r={3}
                    fill={themeColor(color)({ theme })}
                    stroke="white"
                    strokeWidth={2}
                    pointerEvents="none"
                  />
                </g>
              )
            })}
        </g>
        <Bar
          x={ml}
          y={mt}
          width={graphWidth}
          height={graphHeight}
          fill="transparent"
          rx={14}
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
        />
        {renderTooltip && tooltipData && tooltipData.activePoints.length > 1 && (
          <TooltipInPortal left={tooltipLeft} top={tooltipTop} style={tooltipStyles}>
            <div ref={tooltipContainerRef}>{renderTooltip(tooltipData.datum)}</div>
          </TooltipInPortal>
        )}
        {renderDetailedTooltip && tooltipData && tooltipData.activePoints.length === 1 && (
          <TooltipInPortal left={tooltipLeft} top={tooltipTop} style={tooltipStyles}>
            <div ref={tooltipContainerRef}>
              {renderDetailedTooltip(tooltipData.datum)}
            </div>
          </TooltipInPortal>
        )}
      </GraphContainer>
      {showLegend && (
        <LegendContainer top={mt - 10} ref={legendContainerRef}>
          <Legend
            linesData={linesData}
            yScale={yScale}
            theme={theme}
            alignLegendItemsToLines={alignLegendItemsToLines}
          />
        </LegendContainer>
      )}
    </Relative>
  )
}

type Props<T> = Omit<ChartInnerProps<T>, 'width' | 'height'>

export const MultiLineChart = <T,>(props: Props<T>) => (
  <ParentSizeModern>
    {({ width, height }) => {
      return <ChartInner width={width} height={height} {...props} />
    }}
  </ParentSizeModern>
)
