import React, { useState } from 'react'
import { connect } from 'lape'
import { Flex, Sticky, VStack, Widget } from '@revolut/ui-kit'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import {
  CardContentTypes,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import styled from 'styled-components'
import { DeliverablesCard } from './Cards/DeliverablesCard'
import { SkillsCard } from './Cards/SkillsCard'
import { CultureValuesCard } from './Cards/CultureValuesCard'
import { BarRaiserView } from './BarRaiserView'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { RecommendationType } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { AdditionalQuestions } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/AdditionalQuestions'
import { Deliverables } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { Skills } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Skills'
import { CultureValues } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/CultureValues'
import Filters from '@src/pages/Forms/EmployeePerformance/components/Filters'
import { useParams } from 'react-router-dom'
import { Feedback } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Feedback'
import { PageActions } from '@components/Page/PageActions'
import EditButton from '@src/pages/Forms/EmployeePerformanceViewLayout/EditButton'

const StyledSticky = styled(Sticky)`
  max-height: calc(100vh - 80px);
  overflow: scroll;
`

const renderRightColumContent = (selectedField?: SelectedFieldInterface) => {
  if (!selectedField?.type) {
    return null
  }

  switch (selectedField.type) {
    case CardContentTypes.KPI:
      return <KPI isViewMode />
    case CardContentTypes.DELIVERABLES:
      return <Deliverables isViewMode />
    case CardContentTypes.SKILLS:
      return <Skills isViewMode selectedField={selectedField} />
    case CardContentTypes.VALUES:
      return <CultureValues isViewMode selectedField={selectedField} />
    default:
      return null
  }
}

const getRecommendations = (values: ReviewSummaryInterface) => {
  const lmRecommendation = values.summary?.line_manager_extra_section
  const fmRecommendation = values.summary?.functional_manager_extra_section
  const peerRecommendations = values.summary?.peer_extra_sections

  const recommendations = []

  if (lmRecommendation) {
    recommendations.push({
      recommendation: lmRecommendation,
      type: 'lm' as RecommendationType,
    })
  }
  if (fmRecommendation) {
    recommendations.push({
      recommendation: fmRecommendation,
      type: 'fm' as RecommendationType,
    })
  }
  if (peerRecommendations?.length) {
    peerRecommendations.forEach(peerRecommendation => {
      recommendations.push({
        recommendation: peerRecommendation,
        type: 'peer' as RecommendationType,
      })
    })
  }

  return recommendations
}

export const ScorecardContentView = connect(() => {
  const params = useParams<{ id: string }>()
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const [selectedField, setSelectedField] = useState<SelectedFieldInterface | undefined>()
  const cycleId = params.id

  const recommendations = getRecommendations(values)

  return (
    <Flex flexDirection="column" width="100%" flex={1} minHeight={0}>
      <TwoColumnsLayout
        leftFlex={1.4}
        left={
          <VStack space="s-16">
            <Widget p="s-16" pb={0}>
              <Filters
                cycleId={cycleId}
                onFilterChange={() => setSelectedField(undefined)}
              />
            </Widget>
            <DeliverablesCard
              selectedField={selectedField}
              setSelectedField={setSelectedField}
            />
            <SkillsCard
              selectedField={selectedField}
              setSelectedField={setSelectedField}
            />
            <CultureValuesCard
              selectedField={selectedField}
              setSelectedField={setSelectedField}
            />
            {!!recommendations.length && (
              <BarRaiserView recommendations={recommendations} />
            )}
            <Feedback
              isViewMode
              recommendationData={recommendations?.[0]?.recommendation}
              reviews={recommendations?.map(el => el.recommendation)}
            />
            <AdditionalQuestions data={values.summary} isViewMode />
            <PageActions maxWidth="100%">
              <Flex justifyContent="center">
                <EditButton />
              </Flex>
            </PageActions>
          </VStack>
        }
        right={
          <StyledSticky top={70}>{renderRightColumContent(selectedField)}</StyledSticky>
        }
      />
    </Flex>
  )
})
