import React, { useMemo } from 'react'
import { Box, Cell, chain, Flex, Link } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import {
  SummarySubheader,
  SummaryTableContainer,
  summaryTableProps,
} from '@src/features/Summary/common'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTableReturnType } from '@components/Table/hooks'
import { RowInterface, Stats } from '@src/interfaces/data'
import { EntityTypes } from '@src/constants/api'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router'
import { Statuses } from '@src/interfaces'
import {
  requisitionHeadcountColumn,
  requisitionPotentialStartDateColumn,
  requisitionStatusColumn,
  createRequisitionTitleColumn,
} from '@src/constants/columns/requisition'
import { teamNameColumn } from '@src/constants/columns/team'
import { seniorityNameRequisitionsColumn } from '@src/constants/columns/seniority'
import { locationNameRequisitionColumn } from '@src/constants/columns/location'
import { withoutFilterAndSort } from '@src/utils/table'
import { TableNames } from '@src/constants/table'

export interface HiringSummaryProps {
  table: useTableReturnType<RequisitionInterface, Stats>
  entityType: EntityTypes
}

const getRow = (entityType: EntityTypes): RowInterface<RequisitionInterface> => ({
  disabled: data => data.status === Statuses.rejected || data.status === Statuses.closed,
  cells: withoutFilterAndSort([
    {
      ...createRequisitionTitleColumn(false),
      width: 140,
    },
    {
      ...seniorityNameRequisitionsColumn,
      width: 100,
    },
    ...(entityType === EntityTypes.team
      ? []
      : [
          {
            ...teamNameColumn,
            width: 130,
          },
        ]),
    {
      ...requisitionHeadcountColumn,
      width: 100,
    },
    {
      ...locationNameRequisitionColumn,
      width: 100,
    },
    {
      ...requisitionPotentialStartDateColumn,
      width: 130,
    },
    {
      ...requisitionStatusColumn,
      width: 100,
    },
  ]),
})

const getFormUrl = (entityType: EntityTypes, params: { id?: string }): string => {
  switch (entityType) {
    case EntityTypes.department:
      return pathToUrl(ROUTES.FORMS.DEPARTMENT.REQUISITIONS, params)
    case EntityTypes.team:
      return pathToUrl(ROUTES.FORMS.TEAM.REQUISITIONS, params)
    default:
      return ''
  }
}

const maxItemsToShow = 5

const HiringSummary = ({ table, entityType }: HiringSummaryProps) => {
  const params = useParams<{ id?: string }>()
  const formUrl = getFormUrl(entityType, params)

  const row = useMemo(() => getRow(entityType), [entityType])

  return (
    <>
      <SummarySubheader formUrl={formUrl}>Hiring</SummarySubheader>
      <Cell>
        <Flex flexDirection="column" width="100%">
          <Flex mb="s-16">
            <Stat
              mr="s-16"
              label="Total Headcount"
              val={table?.stats?.requisition_total_headcount}
            />
            <Stat
              label="Remaining Headcount"
              val={table?.stats?.requisition_remaining_headcount}
            />
          </Flex>

          <SummaryTableContainer>
            <AdjustableTable
              name={TableNames.HiringSummary}
              row={row}
              {...table}
              data={table.data.slice(0, maxItemsToShow)}
              {...summaryTableProps}
              hideCountAndButtonSection
            />
          </SummaryTableContainer>
          {table.data.length > maxItemsToShow && (
            <Box pt="s-12">
              <Link fontWeight={500} use="a" href={formUrl} target="_blank">
                {chain('Show all', table.count)}
              </Link>
            </Box>
          )}
        </Flex>
      </Cell>
    </>
  )
}

export default HiringSummary
