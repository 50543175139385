import { Box, Color, Text } from '@revolut/ui-kit'
import { isBefore, startOfToday } from 'date-fns'
import { formatDate, formatDateDistance } from '@src/utils/format'
import React from 'react'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTheme } from '@src/styles/theme'
import { getColor } from '@src/styles/colors'

type Props = {
  idealStartDate?: string
  estimatedStartDate?: string
}

const StartDate = ({ idealStartDate, estimatedStartDate }: Props) => {
  const theme = useTheme()

  if (!estimatedStartDate) {
    return (
      <Tooltip text="Not enough historical data to calculate the TTH" placement="top">
        <Text use="div" width="100%">
          -
        </Text>
      </Tooltip>
    )
  }

  if (isBefore(new Date(estimatedStartDate), startOfToday())) {
    return <>-</>
  }

  const estDate = new Date(estimatedStartDate)
  const color =
    idealStartDate && isBefore(new Date(idealStartDate), estDate)
      ? getColor(theme, Color.RED)
      : getColor(theme, Color.FOREGROUND)

  return (
    <Tooltip
      body={
        <Box p="s-16">
          <Text use="div" color="background" whiteSpace="nowrap">
            Estimated TTH: {estimatedStartDate ? formatDate(estimatedStartDate) : '-'}
          </Text>
          <Text use="div" color="background" whiteSpace="nowrap">
            Ideal start date: {idealStartDate ? formatDate(idealStartDate) : '-'}
          </Text>
        </Box>
      }
      placement="top"
    >
      <Text color={color} use="div" width="100%">
        {formatDateDistance(new Date(estimatedStartDate))}
      </Text>
    </Tooltip>
  )
}

export default StartDate
