import React, { useEffect, useMemo, useState } from 'react'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import {
  Box,
  Cell,
  CheckboxSelect,
  DropdownController,
  Group,
  HStack,
  SelectOptionType,
  Subheader,
  Tag,
  TagBar,
  Text,
  Token,
} from '@revolut/ui-kit'
import { Plus, PriceTag } from '@revolut/icons'
import { OptionInterface } from '@src/interfaces/selectors'
import { matchSorter } from 'match-sorter'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { updateCandidateTags } from '@src/api/recruitment/interviews'

const mapTagToSelectOptionType = (value: OptionInterface) => ({
  key: value.id,
  label: value.name,
  value: value.name,
})

const createNewTag = (name: string, index = 0) =>
  ({
    key: `new-option-${index}`,
    label: name,
    value: name,
  } as SelectOptionType<string>)

type TagsCheckboxSelectProps = {
  tags?: string[]
  options: SelectOptionType<string>[]
  optionsLoading: boolean
  onChangeTags: (newTags: string[]) => void
}

const TagsCheckboxSelect = ({
  tags,
  options,
  optionsLoading,
  onChangeTags,
  ...props
}: TagsCheckboxSelectProps) => {
  const [searchTextState, setSearchTextState] = useState('')
  const [currentOptions, setCurrentOptions] =
    useState<SelectOptionType<string>[]>(options)
  useEffect(() => {
    setCurrentOptions(options)
  }, [options])
  const handleSearch = (
    searchInput: string,
    searchOptions: SelectOptionType<string>[],
  ) => {
    const searchResults = matchSorter(searchOptions, searchInput, {
      keys: ['label'],
    })
    if (searchResults.length) {
      return (searchResults[0]?.label as string).trim() === searchInput
        ? searchResults
        : [createNewTag(searchInput, currentOptions.length), ...searchResults]
    }
    return [createNewTag(searchInput, currentOptions.length)]
  }
  const handleSearchText = (searchInput: string) => {
    setSearchTextState(searchInput)
    if (!options.length) {
      setCurrentOptions(searchInput ? [createNewTag(searchInput)] : [])
    }
  }
  return (
    <CheckboxSelect
      labelApply="Add"
      labelEmptyState="Start typing to add your first tag"
      labelList="Tags"
      labelNoResults="No results found"
      labelSearch="Search"
      labelSelectAll="Select all"
      loadingState={optionsLoading ? 'pending' : 'ready'}
      options={currentOptions}
      searchable
      searchText={searchTextState}
      value={tags}
      onChange={onChangeTags}
      onSearch={handleSearch}
      onSearchText={handleSearchText}
      {...props}
    >
      {option => {
        if (typeof option?.key === 'string') {
          return (
            <HStack gap="s-8" align="center">
              <Plus size={16} color={Token.color.blue} />
              <Tag variant="faded">{option.label}</Tag>
            </HStack>
          )
        }
        return option.label
      }}
    </CheckboxSelect>
  )
}

type CandidateTagsProps = {
  candidate: CandidateInterface
}

type OptionNames = {
  [key: string]: OptionInterface | { name: string }
}

const CandidateTags = ({ candidate }: CandidateTagsProps) => {
  const {
    data: optionsData,
    refetch: refetchOptions,
    isLoading: optionsLoading,
  } = useGetSelectors(selectorKeys.candidate_tags)

  const optionNames: OptionNames = useMemo(() => {
    return (
      optionsData?.reduce((acc, option) => {
        return {
          ...acc,
          [option.name]: option,
        }
      }, {}) ?? {}
    )
  }, [optionsData])

  const options = useMemo(() => {
    return optionsData?.map(mapTagToSelectOptionType) ?? []
  }, [optionsData])

  const getOptionFromName = (tagName: string) => optionNames[tagName]

  const getOptionsFromTagNames = (values: string[]) => {
    let hasNewTags = false
    const asOptions = values.map(name => {
      const asOption = getOptionFromName(name)
      if (!asOption) {
        hasNewTags = true
        return { name }
      }
      return asOption
    })
    return { hasNewTags, asOptions }
  }

  const [tags, setTags] = useState(candidate.tags?.map(({ name }) => name) ?? [])

  useEffect(() => {
    setTags(candidate.tags?.map(({ name }) => name) ?? [])
  }, [candidate.tags])

  const handleChangeTags = async (value: string[]) => {
    const oldTags = [...tags]
    setTags(value)
    const { hasNewTags, asOptions } = getOptionsFromTagNames(value)
    try {
      await updateCandidateTags(candidate.id, asOptions)
    } catch {
      setTags(oldTags)
    } finally {
      if (hasNewTags) {
        refetchOptions()
      }
    }
  }
  const handleRemoveTag = async (value: string) => {
    handleChangeTags(tags.filter(tag => tag !== value))
  }
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Tags</Subheader.Title>
      </Subheader>
      <Group>
        <Cell>
          <TagBar>
            <DropdownController>
              {dropdown => (
                <>
                  <TagBar.Action
                    variant="status"
                    useIcon={PriceTag}
                    bg={Token.color.blue}
                    color={Token.color.white}
                    {...dropdown.getAnchorProps()}
                  >
                    Add tag
                  </TagBar.Action>
                  <TagsCheckboxSelect
                    tags={tags}
                    options={options}
                    optionsLoading={optionsLoading}
                    onChangeTags={handleChangeTags}
                    {...dropdown.getTargetProps()}
                  />
                </>
              )}
            </DropdownController>
            {tags.map(tag => (
              <TagBar.Action
                key={tag}
                useIcon={undefined}
                variant="faded"
                onClear={() => {
                  handleRemoveTag(tag)
                }}
                onClick={() => {
                  handleRemoveTag(tag)
                }}
              >
                {tag}
              </TagBar.Action>
            ))}
            {!tags.length && (
              <Text color={Token.color.greyTone50}>
                This candidate doesn't have any tags assigned yet
              </Text>
            )}
          </TagBar>
        </Cell>
      </Group>
    </Box>
  )
}

export default CandidateTags
