import React, { useContext, useState } from 'react'
import { connect } from 'lape'
import { MoreBar, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { ShareAndroid } from '@revolut/icons'
import { avtivateQuery } from '@src/api/dataAnalytics'
import { Statuses } from '@src/interfaces'
import { QueryInterface, QueryRunStatuses } from '@src/interfaces/dataAnalytics'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EntityPermissions } from '@src/store/auth/types'
import { QueryContext } from '../QueryContextProvider'

export const PublishButton = connect(() => {
  const { queryRunStatus } = useContext(QueryContext)
  const { dirty, reset, values } = useLapeContext<QueryInterface>()
  const statusPopup = useStatusPopup()
  const [pending, setPending] = useState(false)

  const canPublish = values.field_options?.actions?.includes(EntityPermissions.Change)
  const isPublishable =
    values.is_publishable || (!dirty && queryRunStatus === QueryRunStatuses.Success)

  const activate = async () => {
    setPending(true)

    try {
      const { data } = await avtivateQuery(values.id)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>{`New version of ${data.name} created`}</StatusPopup.Title>
        </StatusPopup>,
      )

      reset(data)
    } catch (e) {
      statusPopup.show(
        <StatusPopup onClose={statusPopup.hide} variant="error">
          <StatusPopup.Title>New version was not created</StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setPending(false)
    }
  }

  if (!values.id || !canPublish || values.status !== Statuses.draft) {
    return null
  }

  return (
    <MoreBar.Action
      disabled={!isPublishable}
      onClick={activate}
      pending={pending}
      useIcon={ShareAndroid}
    >
      Activate New Version
    </MoreBar.Action>
  )
})
