import React, { Suspense, memo, useMemo } from 'react'
import { Box, Color, ErrorWidget, Flex, Skeleton, Spinner } from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'
import { getQueryRunResult } from '@src/api/dataAnalytics'
import { selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { IdAndName } from '@src/interfaces'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import { QueryRunResultInterface } from '@src/interfaces/dataAnalytics'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { useChartSettings } from '../hooks/useChartSettings'
import { Tabs } from '../hooks/useTabSwitchers'

const BarChartComponent = React.lazy(() => import('./Visualization/BarChart'))
const LineChartComponent = React.lazy(() => import('./Visualization/LineChart'))
const ScatterChartComponent = React.lazy(() => import('./Visualization/ScatterChart'))

interface VisualizationResultProps {
  runId: number
  tab?: Tabs
  queryId: number
}

interface ChartContentProps {
  chartType?: IdAndName
  data: QueryRunResultInterface
  xValue?: IdAndName
  yValue?: IdAndName
}

export const ChartContent = ({ chartType, data, xValue, yValue }: ChartContentProps) => {
  switch (chartType?.name) {
    case 'Bar':
      return <BarChartComponent data={data} xValue={xValue} yValue={yValue} />
    case 'Area':
    case 'Line':
      return (
        <LineChartComponent
          data={data}
          enableArea={chartType.name === 'Area'}
          xValue={xValue}
          yValue={yValue}
        />
      )
    case 'Scatter':
      return <ScatterChartComponent data={data} xValue={xValue} yValue={yValue} />
    default:
      return null
  }
}

export const VisualizationResult = memo(
  ({ runId, queryId, tab }: VisualizationResultProps) => {
    const table = useTable({
      getItems: getQueryRunResult(runId, queryId),
    })

    const { chartSettings, chartType, xValue, yValue } = useChartSettings(table.columns)

    const tableRows = useMemo(() => {
      const cells = table.columns.map(
        key =>
          ({
            type: CellTypes.insert,
            idPoint: key.name,
            dataPoint: key.name,
            sortKey: null,
            filterKey: null,
            selectorsKey: selectorKeys.none,
            title: key.name,
            width: 300,
            insert: ({ data }) => {
              return typeof data[key.name] === 'boolean'
                ? data[key.name].toString()
                : data[key.name]
            },
          } as ColumnCellInterface<any>),
      )

      return { cells }
    }, [table.columns])

    if (table.loading) {
      return (
        <Flex alignItems="center" flex="1 0" justifyContent="center">
          <Spinner size={48} color={Color.BLUE} />
        </Flex>
      )
    }

    if (table.fetchError) {
      return (
        <Flex
          alignItems="center"
          flex="1 0"
          flexDirection="column"
          justifyContent="center"
        >
          <ErrorWidget>
            <ErrorWidget.Title>
              <ExclamationTriangle color={Color.RED} />
            </ErrorWidget.Title>
            <ErrorWidget.Description>{table.fetchError.message}</ErrorWidget.Description>
          </ErrorWidget>
        </Flex>
      )
    }

    return (
      <>
        {tab === Tabs.ChartTab ? (
          <Flex gap="s-16" height="100%" width="100%">
            {chartSettings}
            <Suspense fallback={<Skeleton />}>
              <ChartContent
                chartType={chartType}
                data={table.data}
                xValue={xValue}
                yValue={yValue}
              />
            </Suspense>
          </Flex>
        ) : (
          <Box overflow="auto" width="100%">
            <AdjustableTable
              hideCountAndButtonSection
              name={TableNames.ReportingAppQueriesRunResult}
              noDataMessage="No data to display"
              row={tableRows}
              {...table}
            />
          </Box>
        )}
      </>
    )
  },
)
