import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { Box, InputGroup } from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { BenchmarkInterface } from '@src/interfaces/benchmarks'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useSubsenioritySelector } from '@src/hooks/useSubsenioritySelector'
import { useGetOrganisationSettings } from '@src/api/settings'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

const General = () => {
  const { id } = useParams<{ id?: string }>()
  const { values, initialValues } = useLapeContext<BenchmarkInterface>()

  const { data: settings } = useGetOrganisationSettings()

  useEffect(() => {
    if (!id && values.is_restricted == null) {
      values.is_restricted = false
    }
  }, [])

  const subsenioritySelector = useSubsenioritySelector<BenchmarkInterface>(
    values,
    initialValues,
  )

  return (
    <>
      <PageHeader
        title={id ? 'Edit compensation band' : 'New compensation band'}
        subtitle={id ? initialValues.name : null}
        backUrl={
          id
            ? pathToUrl(ROUTES.FORMS.BENCHMARK.PREVIEW, { id })
            : ROUTES.PEOPLE.COMPENSATION.BANDS
        }
      />

      <PageBody>
        <InputGroup>
          <LapeRadioSelectInput
            name="specialisation"
            label="Specialisation"
            selector={selectorKeys.specialisations}
          />
          <LapeRadioSelectInput
            name="seniority"
            label="Seniority"
            selector={selectorKeys.seniority}
          />
          {!!settings?.enable_multiple_levels_per_seniority && (
            <LapeRadioSelectInput
              name="specialisation_seniority_sublevel"
              label="Seniority Level"
              selector={subsenioritySelector}
            />
          )}
          <LapeRadioSelectInput
            name="location"
            label="Location"
            selector={selectorKeys.location}
          />
          <LapeRadioSelectInput
            name="benchmark_type"
            label="Type"
            selector={selectorKeys.benchmark_types}
          />
          <HideIfCommercial>
            <LapeNewMultiSelect
              name="tags"
              placeholder="Tags"
              selector={selectorKeys.benchmark_tags}
              required
            />
          </HideIfCommercial>
          <InputGroup variant="horizontal">
            <Box flex={1}>
              <LapeRadioSelectInput
                name="currency"
                label="Currency"
                selector={selectorKeys.currencies}
              />
            </Box>
            <Box flex={1}>
              <LapeNewInput name="lower_band" label="Lower band" required />
            </Box>
            <Box flex={1}>
              <LapeNewInput name="upper_band" label="Upper band" required />
            </Box>
          </InputGroup>
          <LapeNewTextArea
            name="note"
            label="Additional notes"
            rows={3}
            required
            message="Please provide additional information about the benchmark data"
          />
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Benchmark saved successfully"
          previewUrl={ROUTES.FORMS.BENCHMARK.PREVIEW}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default General
