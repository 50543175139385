import React from 'react'
import { Box, Text } from '@revolut/ui-kit'
import DOMPurify from 'dompurify'
import styled from 'styled-components'

const StyledText = styled(Text)`
  p {
    margin: 0;
  }
  ol,
  ul {
    padding: 0 0 0 40px;
    margin: 0;
  }
`

type Props = {
  data?: string
}

const HeadCard = ({ data }: Props) => {
  if (!data) {
    return null
  }

  return (
    <Box mb="s-24">
      <StyledText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} />
    </Box>
  )
}

export default HeadCard
