import { NotificationTypes } from '@src/store/notifications/types'
import { CheckSuccess, ExclamationMark, ExclamationTriangle, Info } from '@revolut/icons'
import React from 'react'
import { useTheme } from '@src/styles/theme'
import { typeToHighlightColor } from '@components/Notification/common'

interface NotificationIconProps {
  type: NotificationTypes
}

export const NotificationIcon = ({ type }: NotificationIconProps) => {
  const theme = useTheme()

  if (type === NotificationTypes.error) {
    return <ExclamationMark color={typeToHighlightColor(theme)[type]} />
  }
  if (type === NotificationTypes.success) {
    return <CheckSuccess color={typeToHighlightColor(theme)[type]} />
  }
  if (type === NotificationTypes.warning) {
    return <ExclamationTriangle color={typeToHighlightColor(theme)[type]} />
  }

  return <Info color={typeToHighlightColor(theme)[type]} />
}
