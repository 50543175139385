import React from 'react'
import upperFirst from 'lodash/upperFirst'
import { chain, Link, Text } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { InlineStatus } from '@src/pages/Forms/FormTitles'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

type RequisitionFormTitleProps = {
  data: RequisitionInterface
}

export const RequisitionFormTitle = ({ data }: RequisitionFormTitleProps) => {
  return (
    <>
      {data.is_confidential && <Text color="orange">[Confidential] </Text>}
      {data.status
        ? chain(
            data.requisition_title,
            <InlineStatus status={data.status}>{upperFirst(data.status)}</InlineStatus>,
          )
        : data.requisition_title}
    </>
  )
}

export const RequisitionFormSubtitle = ({ data }: RequisitionFormTitleProps) => {
  return (
    <>
      {data.specialisation?.id && (
        <Link
          href={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
            id: data.specialisation.id,
          })}
          target="_blank"
          color="inherit"
        >
          {data.specialisation.name}
        </Link>
      )}
      {!!data.specialisation?.id && !!data.team?.name && (
        <Text color="grey-20"> at </Text>
      )}
      {data.team?.name && (
        <Link
          to={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: data.team.id })}
          use={InternalLink}
          target="_blank"
          color="inherit"
        >
          {data.team.name}
        </Link>
      )}
    </>
  )
}
