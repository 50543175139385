import { getCandidateInterviewStages } from '@src/api/recruitment/interviews'
import {
  InterviewStageRowWithoutRoundInterface,
  InterviewStageWithoutRoundInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { SORT_DIRECTION } from '@src/interfaces/data'

export type StagesLoadingStatusType = 'success' | 'error' | 'loading' | 'idle'

export const useFetchStagesTable = (roundId?: number) => {
  const [status, setStatus] = useState<StagesLoadingStatusType>('idle')
  const [stages, setStages] = useState<InterviewStageWithoutRoundInterface[]>([])
  const [feedbacks, setFeedbacks] = useState<InterviewStageRowWithoutRoundInterface[]>([])

  const sortBy = [
    {
      sortBy: 'interview_number',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
    {
      sortBy: 'interview_number_for_type',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  const filters = [
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_enabled',
      nonResettable: true,
    },
  ]

  const fetchData = async () => {
    if (!roundId) {
      return
    }

    setStatus('loading')

    try {
      // hack because BE doesn't return sensible response to create expandable table
      const data = await getCandidateInterviewStages({ filters, sortBy }, roundId)

      let results: InterviewStageRowWithoutRoundInterface[] = data.data.results.map(
        item => ({
          ...item,
          recommendations: item.interview_feedbacks.reduce<InterviewToolInterviewer[]>(
            (acc, feedback) => {
              if (feedback.result && feedback.interviewer) {
                acc.push(feedback.interviewer)
              }

              return acc
            },
            [],
          ),
          children:
            item.interview_type === 'cv_screening'
              ? []
              : item.interview_feedbacks.map(feedback => ({
                  ...feedback,
                  title: feedback.interviewer?.display_name,
                })),
        }),
      )

      setStages(data.data.results)
      setFeedbacks(results)
      setStatus('success')
    } catch (e) {
      setStages([])
      setFeedbacks([])
      Sentry.captureException('[Hiring Stages table]: cannot convert data')
      setStatus('error')
      throw e
    }
  }

  useEffect(() => {
    fetchData()
  }, [roundId])

  return { stages, feedbacks, status, refetch: fetchData }
}
