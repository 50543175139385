import React, { createContext, useContext, useMemo, useState } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { useSelector } from 'react-redux'

import { overrideAPIMapWithTenant } from '@src/constants/api'
import { history as defaultHistory, updateHistory } from '@src/utils/router'
import { PUBLIC_PREFIX, ROUTES, SIGNUP, WORKSPACES } from '@src/constants/routes'
import { LocalStorageKeys } from '@src/store/auth/types'
import { selectAuthenticated } from '@src/store/auth/selectors'
import { navigateReplace } from '@src/actions/RouterActions'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { isWorkspacesEnabled } from '@src/utils'

interface WorkspaceContextProps {
  workspace: string | undefined
  workspaces: string[]
  saveActiveWorkspace: () => void
  removeWorkspace: (workspace: string) => void
  saveWorkspace: (workspace: string) => void
}

interface WorkspaceActionState {
  workspace?: string
  action: 'redirect-to-main' | 'redirect-to-login' | 'redirect-to-workspace' | null
}

const WorkspaceContext = createContext<WorkspaceContextProps | null>(null)

export const useWorkspaceContext = () => {
  return useContext(WorkspaceContext)
}

const notAllowedWorkspaces = [
  WORKSPACES.MAIN,
  WORKSPACES.FIND,
  ROUTES.MAIN,
  PUBLIC_PREFIX,
  SIGNUP,
  ROUTES.LOGIN.MAIN,
  ROUTES.GRANT_PERMISSIONS_REDIRECT,
]

const getWorkspace = () => {
  const pathname = window.location.pathname
  const secondsSubrouteIndex = pathname.indexOf('/', 1)
  const firstSubroute =
    pathname.substring(0, secondsSubrouteIndex > 0 ? secondsSubrouteIndex : undefined) ||
    '/'
  return notAllowedWorkspaces.includes(firstSubroute)
    ? undefined
    : firstSubroute.replace('/', '')
}

const WorkspaceContextProvider: React.FC = ({ children }) => {
  const authenticated = useSelector(selectAuthenticated)

  const [workspaces, setWorkspaces] = useLocalStorage<string[]>(
    LocalStorageKeys.WORKSPACES,
    [],
    false,
  )

  const [{ workspace, action }] = useState<WorkspaceActionState>(() => {
    const activeWorkspace = localStorage.getItem(LocalStorageKeys.ACTIVE_WORKSPACE)

    const isRootPath = window.location.pathname === '/'
    const isLoginPath =
      window.location.pathname.startsWith(ROUTES.LOGIN.MAIN) &&
      window.location.pathname !== ROUTES.LOGIN.REDIRECT

    if (activeWorkspace && authenticated && (isRootPath || isLoginPath)) {
      return { workspace: activeWorkspace, action: 'redirect-to-main' }
    }
    if (activeWorkspace && !authenticated && (isRootPath || isLoginPath)) {
      return { workspace: activeWorkspace, action: 'redirect-to-login' }
    }

    if (isLoginPath) {
      return { action: 'redirect-to-workspace' }
    }

    return { workspace: getWorkspace(), action: null }
  })

  const history = useMemo(
    () =>
      typeof window === 'undefined'
        ? ({} as any)
        : createBrowserHistory({ basename: workspace }),
    [workspace],
  )

  updateHistory(history)

  if (workspace) {
    overrideAPIMapWithTenant(workspace)
  }

  if (action === 'redirect-to-main') {
    navigateReplace(ROUTES.MAIN)
  }

  if (action === 'redirect-to-login') {
    navigateReplace(ROUTES.LOGIN.MAIN)
  }

  if (action === 'redirect-to-workspace') {
    navigateReplace(WORKSPACES.MAIN)
  }

  const saveWorkspace = (ws: string) => {
    if (!workspaces.includes(ws)) {
      setWorkspaces([ws, ...workspaces])
    }
  }

  const saveActiveWorkspace = () => {
    if (workspace) {
      localStorage.setItem(LocalStorageKeys.ACTIVE_WORKSPACE, workspace)
      saveWorkspace(workspace)
    }
  }

  const removeWorkspace = (ws: string) => {
    setWorkspaces(workspaces.filter(w => w !== ws))
  }

  const value = useMemo(
    () => ({
      workspace,
      workspaces,
      saveActiveWorkspace,
      removeWorkspace,
      saveWorkspace,
    }),
    [workspace, workspaces, saveActiveWorkspace, removeWorkspace, saveWorkspace],
  )

  return (
    <WorkspaceContext.Provider value={value}>
      <Router history={history}>{children}</Router>
    </WorkspaceContext.Provider>
  )
}

const WorkspaceContextFeatureGuard: React.FC = ({ children }) => {
  const workspacesEnabled = isWorkspacesEnabled()

  if (workspacesEnabled) {
    return <WorkspaceContextProvider>{children}</WorkspaceContextProvider>
  }

  return <Router history={defaultHistory}>{children}</Router>
}

export { WorkspaceContextFeatureGuard as WorkspaceContextProvider }
