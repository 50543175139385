import React from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { Flex, MoreBar, Widget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { OfferPlaceholderInterface } from '@src/interfaces/offerTemplates'
import { offerPlaceholdersTableRequests } from '@src/api/offerTemplates'
import {
  offerPlaceholderLabelColumn,
  offerPlaceholderNameColumn,
  offerPlaceholderPredefinedColumn,
  offerPlaceholderTypeColumn,
} from '@src/constants/columns/offerForm/offerPlaceholders'
import { Plus } from '@revolut/icons'
import SettingsButtons from '@src/features/SettingsButtons'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'

const rows: RowInterface<OfferPlaceholderInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.OFFER_PLACEHOLDER.GENERAL, {
        id: data.id,
      }),
    ),
  cells: [
    {
      ...offerPlaceholderNameColumn,
      width: 140,
    },
    {
      ...offerPlaceholderLabelColumn,
      width: 290,
    },
    {
      ...offerPlaceholderPredefinedColumn,
      width: 300,
    },
    {
      ...offerPlaceholderTypeColumn,
      width: 160,
    },
  ],
}

const OfferPlaceholders = () => {
  const table = useTable<OfferPlaceholderInterface>(offerPlaceholdersTableRequests)
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddOfferFormTemplate)

  return (
    <Widget p="s-16">
      {canAdd && (
        <SettingsButtons mb="s-16">
          <MoreBar.Action
            useIcon={Plus}
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.OFFER_PLACEHOLDER.GENERAL)}
          >
            Create new
          </MoreBar.Action>
        </SettingsButtons>
      )}
      <Flex flexDirection="column" width="100%">
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<OfferPlaceholderInterface>
            name={TableNames.OfferPlaceholders}
            useWindowScroll
            dataType="Offer placeholders"
            row={rows}
            {...table}
            noDataMessage="Offer placeholders will appear here."
          />
        </Flex>
      </Flex>
    </Widget>
  )
}

export default OfferPlaceholders
