import React, {
  CSSProperties,
  useMemo,
  useState,
  MouseEvent,
  useEffect,
  useRef,
  ReactNode,
} from 'react'
import styled from 'styled-components'
import { createPortal } from 'react-dom'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import {
  closestCenter,
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  useDroppable,
  MouseSensor,
  DragEndEvent,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import SortableRow from '@components/SortableList/SortableRow'
import SortableRowContent from '@components/SortableList/SortableRowContent'
import { DragStartEvent } from '@dnd-kit/core'
import {
  multiSelect,
  wasMultiSelectKeyUsed,
  wasToggleInSelectionGroupKeyUsed,
} from '@components/SortableList/utils'
import { colorGetter, getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'
import { useTheme } from '@src/styles/theme'
import { Virtuoso } from 'react-virtuoso'

interface Props<T> {
  count: number
  onLoadMore?: () => void
  data: T[]
  onRowClick?: (data: T, parentIndexes: number[]) => void
  renderItem: (index: number, style: CSSProperties) => React.ReactNode
  onUpdate: (
    sourceIds: (number | string)[],
    activeIndex: number,
    targetIndex: number,
  ) => void
  onChangeSelected?: (ids: (number | string)[]) => void
  activeOrderingRow?: number | string | null
  selectedIds: (number | string)[]
  onScroll?: (node: ReactNode) => void
  useWindowScroll?: boolean
}

const SelectionCount = styled.div`
  top: -3px;
  left: -3px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  font-size: 16px;
  background-color: ${colorGetter(Color.BLUE)};
  color: ${colorGetter(Color.BACKGROUND)};
  z-index: 10;
`

const SortableWrap = styled.div`
  min-height: 100%;
`

const SortableList = <T extends { id?: number | string }>({
  count,
  onLoadMore,
  renderItem,
  onScroll,
  data,
  onRowClick,
  onUpdate,
  onChangeSelected,
  selectedIds,
  activeOrderingRow,
  useWindowScroll,
}: Props<T>) => {
  const theme = useTheme()
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const [activeId, setActiveId] = useState<number | string | null>(null)

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        // this is needed to be able to select row by clicking
        distance: 2,
      },
    }),
  )

  const virtualListHeight = useMemo(
    () => scrollRef?.current?.getBoundingClientRect()?.height,
    [scrollRef?.current],
  )

  useEffect(() => {
    onChangeSelected?.(selectedIds)
  }, [selectedIds])

  const unselectAll = () => {
    onChangeSelected?.([])
  }

  const handleClickOutside = (e: Event) => {
    if (!scrollRef?.current?.contains(e.target as Node)) {
      unselectAll()
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const getIndex = (id: number | string) => data.findIndex(item => item.id === id)
  const activeIndex = activeId !== null ? getIndex(activeId) : -1

  const onEndReached = () => {
    if (!onLoadMore) {
      return
    }

    if (data.length < count) {
      onLoadMore()
    }
  }

  const { setNodeRef: dropRef } = useDroppable({
    id: 'droppable',
  })

  const multiSelectTo = (newId: number | string) => {
    const updated = multiSelect(ids, selectedIds, newId)

    if (updated == null) {
      return
    }

    onChangeSelected?.(updated)
  }

  const toggleSelection = (id: number | string) => {
    const wasSelected: boolean = selectedIds.includes(id)

    let newTaskIds: (number | string)[] = []

    if (!wasSelected) {
      // Row was not previously selected
      // add this row to the selected list
      newTaskIds = [...selectedIds, id]
    } else if (selectedIds.length > 1) {
      // Row was part of a selected group
      // clear selection from the current row
      newTaskIds = selectedIds.filter(item => item !== id)
    }

    onChangeSelected?.(newTaskIds)
  }

  const toggleSelectionInGroup = (id: number | string) => {
    const index: number | string = selectedIds.indexOf(id)

    // if not selected - add it to the selected items
    if (index === -1) {
      onChangeSelected?.([...selectedIds, id])
      return
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedIds]
    shallow.splice(index, 1)
    onChangeSelected?.(shallow)
  }

  const performAction = (event: MouseEvent | KeyboardEvent, id: number | string) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(id)
      return
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(id)
      return
    }

    toggleSelection(id)
  }

  const onClick = (event: MouseEvent<HTMLDivElement>, id: number | string) => {
    if (event.defaultPrevented) {
      return
    }

    // if no main button clicked
    if (event.button !== 0) {
      return
    }

    event.preventDefault()

    performAction(event, id)
  }

  const onDragStart = ({ active }: DragStartEvent) => {
    setActiveId(+active.id)
    const selected = selectedIds.find((id): boolean => id === +active.id)

    if (!selected) {
      unselectAll()
    }
  }

  const onDragEnd = ({ over }: DragEndEvent) => {
    if (!over) {
      setActiveId(null)
      return
    }

    const targetIndex = getIndex(+over.id)
    if (activeIndex !== targetIndex) {
      const sourceIds = selectedIds.length ? selectedIds : [activeId!]
      onUpdate(sourceIds, activeIndex, targetIndex)
    }

    setActiveId(null)
    unselectAll()
  }

  const ids = data.map(item => item.id!)

  const getItemStyle = (id: number | string) => {
    const style: CSSProperties = {}
    const isSelected = selectedIds.includes(id)
    const isGhosting: boolean = isSelected && activeId !== null && activeId !== id

    if (id === activeId) {
      style.opacity = 0
    }

    if (isGhosting) {
      style.opacity = 0.6
    }

    if (isSelected) {
      style.backgroundColor = getColor(theme, Color.BLUE_OPAQUE_5)
    }

    if (activeOrderingRow === id) {
      style.backgroundColor = getColor(theme, Color.BLUE_OPAQUE_20)
    }

    return style
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={() => setActiveId(null)}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
    >
      <SortableWrap
        ref={ref => {
          scrollRef.current = ref
          dropRef(ref)
        }}
      >
        <SortableContext
          items={ids.map(id => String(id))}
          strategy={verticalListSortingStrategy}
        >
          <Virtuoso
            data={data}
            style={{ height: virtualListHeight || '100vh' }}
            onScroll={onScroll}
            overscan={400}
            // https://github.com/petyosi/react-virtuoso/issues/341
            initialItemCount={data.length - 1}
            endReached={onEndReached}
            itemContent={index => {
              const id = data[index].id!

              return (
                <SortableRow<T>
                  key={id}
                  index={index}
                  row={data[index]}
                  onClick={e => !onRowClick && onClick(e, id)}
                >
                  {renderItem(index, getItemStyle(id))}
                </SortableRow>
              )
            }}
            useWindowScroll={useWindowScroll}
          />
        </SortableContext>
      </SortableWrap>
      {createPortal(
        <DragOverlay>
          {activeId !== null ? (
            <SortableRowContent dragOverlay>
              {renderItem(activeIndex, {
                backgroundColor: getColor(theme, Color.BLUE_OPAQUE_10),
                boxShadow: `0px 0px 8px 0px ${getColor(theme, Color.BLUE_OPAQUE_30)}`,
              })}
              {!!selectedIds.length && (
                <SelectionCount>{selectedIds.length}</SelectionCount>
              )}
            </SortableRowContent>
          ) : null}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  )
}

export default SortableList
