import React, { useState } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { AuditInterface, AuditSeverities, AuditStatuses } from '@src/interfaces/audit'
import { useTheme } from '@src/styles/theme'
import {
  auditBugLinkColumn,
  auditBugsLatenessColumn,
  auditCreationDateColumn,
  auditDueDateColumn,
  auditSeverityV1Column,
  auditSummaryColumn,
  auditTeamNameColumn,
  bugAssigneeColumn,
} from '@src/constants/columns/audit'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { Color, Flex, HStack, Switch, Text, Widget } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TeamInterface } from '@src/interfaces/teams'
import { getDepartmentCXBugTickets, getTeamCXBugTickets } from '@src/api/cx'
import { selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'

interface StructureProps {
  data: TeamInterface | DepartmentInterface
  isTeam?: boolean
}

const ROW = (isTeam?: boolean): RowInterface<AuditInterface> => {
  const row: RowInterface<AuditInterface> = {
    highlight: (data, theme) => {
      if (
        data.severity?.id === AuditSeverities.Blocker ||
        data.severity?.id === AuditSeverities.Critical ||
        data.severity?.id === AuditSeverities.Major
      ) {
        return getColor(theme, Color.RED_OPAQUE_20)
      }
      return ''
    },
    cells: [
      {
        ...auditBugLinkColumn,
        selectorsKey: selectorKeys.cx_bug_id,
        width: 100,
      },
      {
        ...auditSummaryColumn,
        width: 280,
      },
      {
        ...bugAssigneeColumn,
        selectorsKey: selectorKeys.cx_bug_assignee_name,
        width: 200,
      },
      {
        ...auditDueDateColumn,
        width: 140,
      },
      {
        ...auditCreationDateColumn,
        width: 140,
      },
      {
        ...auditSeverityV1Column,
        selectorsKey: selectorKeys.cx_bug_severity,
        width: 140,
      },
      {
        ...auditBugsLatenessColumn,
        selectorsKey: selectorKeys.cx_bug_lateness,
        width: 140,
      },
    ],
  }

  if (!isTeam) {
    row.cells.splice(3, 0, {
      ...auditTeamNameColumn,
      width: 200,
    })
  }

  return row
}

const Bugs = ({ data, isTeam }: StructureProps) => {
  const theme = useTheme()
  const [showHistory, setShowHistory] = useState(false)

  const getItems = isTeam
    ? getTeamCXBugTickets(data.id)
    : getDepartmentCXBugTickets(data.id)

  const initialFilterBy = [
    {
      filters: [
        { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
        { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
      ],
      columnName: 'status',
      nonResettable: true,
    },
  ]

  const initialHistoryFilterBy = [
    {
      filters: [
        { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
        { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
        { id: AuditStatuses.DONE, name: AuditStatuses.DONE },
      ],
      columnName: 'status',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'severity_sorter',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'team_name',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'due_date',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const table = useTable<AuditInterface>({ getItems }, initialFilterBy, initialSortBy)

  const historyTable = useTable<AuditInterface>(
    { getItems },
    initialHistoryFilterBy,
    initialSortBy,
  )

  let blockerNumber = 0
  let criticalNumber = 0
  let majorNumber = 0
  let mediumNumber = 0
  let minorNumber = 0
  let improvementNumber = 0

  const currentTable = showHistory ? historyTable : table

  currentTable.data.forEach(d => {
    switch (d.severity?.id) {
      case AuditSeverities.Blocker:
        blockerNumber += 1
        break
      case AuditSeverities.Critical:
        criticalNumber += 1
        break
      case AuditSeverities.Major:
        majorNumber += 1
        break
      case AuditSeverities.Medium:
        mediumNumber += 1
        break
      case AuditSeverities.Minor:
        minorNumber += 1
        break
      case AuditSeverities.Improvement:
        improvementNumber += 1
        break
      default:
    }
  })

  const totalNumber =
    blockerNumber +
    criticalNumber +
    majorNumber +
    mediumNumber +
    minorNumber +
    improvementNumber

  const onClickShowHistory = () => {
    setShowHistory(!showHistory)
  }

  const renderTable = () => {
    if (showHistory) {
      return (
        <AdjustableTable<AuditInterface>
          name={TableNames.CXBugs}
          useWindowScroll
          dataType="Audit ticket"
          row={ROW(isTeam)}
          onRowClick={openUrl}
          {...historyTable}
          noDataMessage="All audit tickets relevant to your team will appear here."
        />
      )
    }

    return (
      <AdjustableTable<AuditInterface>
        name={TableNames.CXBugs}
        useWindowScroll
        dataType="Audit ticket"
        row={ROW(isTeam)}
        onRowClick={openUrl}
        {...table}
        noDataMessage="All audit tickets relevant to your team will appear here."
      />
    )
  }

  const openUrl = (rowData: AuditInterface) => {
    window.open(rowData.issue_link, '_blank')
  }

  return (
    <Widget display="flex" p="s-16" width="100%" minHeight={0}>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-24">
          <Stat
            label="Blocker"
            val={
              currentTable.loading ? undefined : (
                <Text color={theme.colors.red}>{blockerNumber}</Text>
              )
            }
            ml="s-32"
          />
          <Stat
            label="Critical"
            val={
              currentTable.loading ? undefined : (
                <Text color={theme.colors.red}>{criticalNumber}</Text>
              )
            }
            ml="s-32"
          />
          <Stat
            label="Major"
            val={
              currentTable.loading ? undefined : (
                <Text color={theme.colors.warning}>{majorNumber}</Text>
              )
            }
            ml="s-32"
          />
          <Stat
            label="Medium"
            val={
              currentTable.loading ? undefined : (
                <Text color={getColor(theme, Color.ORANGE_OPAQUE_70)}>
                  {mediumNumber}
                </Text>
              )
            }
            ml="s-32"
          />
          <Stat
            label="Minor"
            val={
              currentTable.loading ? undefined : (
                <Text color={getColor(theme, Color.GREY_TONE_50)}>{minorNumber}</Text>
              )
            }
            ml="s-32"
          />
          <Stat
            label="Improvement"
            val={
              currentTable.loading ? undefined : (
                <Text color={getColor(theme, Color.GREY_TONE_50)}>
                  {improvementNumber}
                </Text>
              )
            }
            ml="s-32"
          />
          <Stat
            label="Total"
            val={
              currentTable.loading ? undefined : (
                <Text color={theme.colors.foreground}>{totalNumber}</Text>
              )
            }
            ml="s-32"
          />
        </Flex>
        <Flex mb="s-16">
          <HStack space="s-16">
            <Switch
              value="Show Historical data"
              checked={showHistory}
              onChange={onClickShowHistory}
            >
              <Text>Show Historical data</Text>
            </Switch>
          </HStack>
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          {renderTable()}
        </Flex>
      </Flex>
    </Widget>
  )
}

export default Bugs
