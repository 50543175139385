import { BarChart, ViewListMedium } from '@revolut/icons'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'

export enum Tabs {
  ChartTab = 'Chart',
  TableTab = 'Table',
}

const tabsIcons = {
  [Tabs.ChartTab]: BarChart,
  [Tabs.TableTab]: ViewListMedium,
}

export const useTabSwitchers = () => {
  const { tabBar, currentTab, setCurrentTab } = useTabBarSwitcher({
    tabs: [Tabs.TableTab, Tabs.ChartTab],
    defaultTab: Tabs.TableTab,
    highlightSelected: false,
    useIcons: tabsIcons,
  })

  return {
    tabBar,
    currentTab,
    setCurrentTab,
  }
}
