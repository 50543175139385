import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import { Drag } from '@revolut/icons'
import { Color } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import React from 'react'

export const dragIconColumn: ColumnCellInterface<{}> = {
  width: 55,
  type: CellTypes.insert,
  title: '',
  insert: () => <Drag color={Color.GREY_TONE_20} />,
  dataPoint: '',
  idPoint: '',
  filterKey: null,
  sortKey: null,
  selectorsKey: selectorKeys.none,
}

export const positionNumberColumn: ColumnCellInterface<{}> = {
  width: 95,
  type: CellTypes.insert,
  idPoint: 'pipeline_queue_position',
  dataPoint: 'pipeline_queue_position',
  sortKey: 'pipeline_queue_position',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Q Position',
}
