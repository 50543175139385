import React, { PropsWithChildren } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { performanceReviewRequests } from '@src/api/performanceReview'
import * as KPI from '@src/pages/Forms/EmployeePerformance/KPI'
import * as CultureFit from '@src/pages/Forms/EmployeePerformance/CultureFit'
import * as Skills from '@src/pages/Forms/EmployeePerformance/Skills'
import * as Summary from '@src/pages/Forms/EmployeePerformance/Summary'
import * as Recommendation from '@src/pages/Forms/EmployeePerformance/Recommendation'
import * as FunctionalManagerRecommendation from '@src/pages/Forms/EmployeePerformance/FunctionalManagerRecommendation'
import * as PeerRecommendation from '@src/pages/Forms/EmployeePerformance/PeerRecommendation'
import * as Deliverables from '@src/pages/Forms/EmployeePerformance/Deliverables'
import { connect } from 'lape'
import { Bar, Flex, Sticky } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import PerformanceValidationDialog from '@src/features/Popups/PerformanceValidationDialog'
import {
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewScorecardInterface,
  ReviewerRelation,
} from '@src/interfaces/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Component from '@src/pages/Forms/EmployeePerformance/components/Component'
import SaveDraftButton from '@src/pages/Forms/EmployeePerformance/components/SaveDraftButton'
import NextButton from '@src/pages/Forms/EmployeePerformance/components/NextButton'
import SubmitButton from '@src/pages/Forms/EmployeePerformance/components/SumbitButton'
import Navigation from '@src/pages/Forms/EmployeePerformance/components/Navigation'
import PerformanceCycleClosed from '@components/ScorecardGeneral/PerformanceCycleClosed'
import { TabsInterface } from '@src/interfaces/data'
import Form from '@src/features/EmployeePerformance/Form'
import PerformancePage from '@src/pages/Forms/EmployeePerformance/components/PerformancePage'
import PerformanceErrorWidget from '@src/pages/Forms/EmployeePerformance/components/PerformanceErrorWidget'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { usePutFirstScorecardOpening } from '@src/utils/performance'
import { SummarySidebarProvider } from '@src/features/EmployeePerformance/SummarySidebarProvider'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const getTabs = (
  params: {
    id: string
    employeeId?: string
  },
  isCultureValues?: boolean,
  isManagerReview?: boolean,
): TabsInterface<ReviewScorecardInterface>[] => [
  {
    title: isManagerReview ? 'KPI' : 'Deliverables',
    path: ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL, params),
    component: isManagerReview ? KPI.default : Deliverables.default,
    validator: isManagerReview ? KPI.validator : Deliverables.validator,
    invalidTabMessage: () =>
      isManagerReview ? null : (
        <PerformanceValidationDialog type={PerformanceReviewTypes.deliverables} />
      ),
    visibilityCheck: () => true,
  },
  {
    title: 'Skills',
    path: ROUTES.FORMS.EMPLOYEE_PERFORMANCE.SKILLS,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE.SKILLS, params),
    component: Skills.default,
    validator: Skills.validator,
    invalidTabMessage: () => (
      <PerformanceValidationDialog type={PerformanceReviewTypes.skills} />
    ),
    visibilityCheck: data => !!data?.review_data?.functional_skills,
  },
  {
    title: 'Culture Fit',
    path: ROUTES.FORMS.EMPLOYEE_PERFORMANCE.CULTURE_FIT,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE.CULTURE_FIT, params),
    component: CultureFit.default,
    validator: CultureFit.validator,
    invalidTabMessage: () => (
      <PerformanceValidationDialog
        type={
          isCultureValues
            ? PerformanceReviewTypes.cultureValuesFit
            : PerformanceReviewTypes.cultureFit
        }
      />
    ),
    visibilityCheck: (_, options) => !!options?.performanceSettings?.enable_values,
  },
  {
    title: 'Recommendation',
    path: ROUTES.FORMS.EMPLOYEE_PERFORMANCE.RECOMMENDATION,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE.RECOMMENDATION, params),
    component: Recommendation.default,
    validator: Recommendation.validator,
    invalidTabMessage: () => (
      <PerformanceValidationDialog type={PerformanceReviewTypes.recommendation} />
    ),
    visibilityCheck: data => !!data?.review_data?.line_manager_extra_section,
  },
  {
    title: 'Recommendation',
    path: ROUTES.FORMS.EMPLOYEE_PERFORMANCE.RECOMMENDATION,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE.RECOMMENDATION, params),
    component: FunctionalManagerRecommendation.default,
    validator: FunctionalManagerRecommendation.validator,
    invalidTabMessage: () => (
      <PerformanceValidationDialog type={PerformanceReviewTypes.recommendation} />
    ),
    visibilityCheck: data => !!data?.review_data?.functional_manager_extra_section,
  },
  {
    title: 'Recommendation',
    path: ROUTES.FORMS.EMPLOYEE_PERFORMANCE.RECOMMENDATION,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE.RECOMMENDATION, params),
    component: PeerRecommendation.default,
    validator: PeerRecommendation.validator,
    invalidTabMessage: () => (
      <PerformanceValidationDialog type={PerformanceReviewTypes.recommendation} />
    ),
    visibilityCheck: data => !!data?.review_data?.peer_extra_section,
  },
  {
    title: 'Summary',
    path: ROUTES.FORMS.EMPLOYEE_PERFORMANCE.SUMMARY,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE.SUMMARY, params),
    component: Summary.default,
    validator: Summary.validator,
    invalidTabMessage: () => (
      <PerformanceValidationDialog type={PerformanceReviewTypes.summary} />
    ),
    visibilityCheck: () => true,
  },
]

const EmptyWrapper = ({ children }: PropsWithChildren<unknown>) => <>{children}</>

const EmployeePerformance = connect(() => {
  const params = useParams<{ id: string; employeeId?: string }>()
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  usePutFirstScorecardOpening(values, ReviewCategory.Performance)

  if (!performanceSettings) {
    return <PageLoading />
  }

  if (!values.review_data) {
    return <PerformanceErrorWidget />
  }

  const isCultureValues = !!values.review_data?.culture_values
  const isManagerReview = !!values.review_data.kpis_section
  const tabs = getTabs(params, isCultureValues, isManagerReview).filter(tab =>
    tab.visibilityCheck(values, { performanceSettings }),
  )

  const shouldWrapWithSummaryProvider =
    values.category === ReviewCategory.Performance &&
    values.reviewer_relation &&
    [
      ReviewerRelation.FunctionalManager,
      ReviewerRelation.LineManager,
      ReviewerRelation.HeadOfFunction,
    ].includes(values.reviewer_relation)

  const SummaryProvider = shouldWrapWithSummaryProvider
    ? SummarySidebarProvider
    : EmptyWrapper

  return (
    <PerformancePage>
      {!values.current_user_can_edit_review ? (
        <PerformanceCycleClosed employeeId={values.reviewed_employee.id!} />
      ) : (
        <>
          <Navigation tabs={tabs} />
          <Flex flex="1 0">
            <SummaryProvider
              hideCategoryFilter
              category={values.category}
              employeeSeniorityId={3}
              cycleId={String(values.cycle?.id)}
              employeeId={values.reviewed_employee.id}
              cycleName={values.cycle?.name}
            >
              <Switch>
                {tabs.map(tab => (
                  <Route exact path={tab.path} key={tab.path}>
                    {shouldWrapWithSummaryProvider ? (
                      <SummarySidebarProvider
                        hideCategoryFilter
                        category={values.category}
                        employeeSeniorityId={values.reviewed_employee.seniority?.id}
                        cycleId={String(values.cycle?.id)}
                        employeeId={values.reviewed_employee.id}
                        cycleName={values.cycle?.name}
                      >
                        <Component tab={tab} />
                      </SummarySidebarProvider>
                    ) : (
                      <Component tab={tab} />
                    )}
                  </Route>
                ))}
                <InternalRedirect to={ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL} />
              </Switch>
            </SummaryProvider>
          </Flex>
          <Sticky bottom={24}>
            <Flex justifyContent="center" width={628}>
              <Bar>
                <SaveDraftButton tabs={tabs} type="employee-performance" />
                <SubmitButton
                  tabs={tabs}
                  type="employee-performance"
                  notificationMessage="Performance review submitted"
                />
                <NextButton tabs={tabs} />
              </Bar>
            </Flex>
          </Sticky>
        </>
      )}
    </PerformancePage>
  )
})

const EmployeePerformanceForm = () => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()

  return (
    <Form
      api={performanceReviewRequests}
      storageKey={`${ReviewCategory.Performance}-${id}-${employeeId}`}
    >
      <EmployeePerformance />
    </Form>
  )
}

export default connect(EmployeePerformanceForm)
