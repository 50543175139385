import { AxiosPromise } from 'axios'
import {
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
} from '@src/interfaces'
import { api, apiWithoutHandling } from './index'
import { API } from '@src/constants/api'
import {
  filterSortPageIntoQuery,
  groupFiltersIntoURLQuery,
  groupSortByIntoURLQuery,
} from '@src/utils/table'
import {
  AccessRequestInterface,
  LookerModelAccessInterface,
  TeamAccessRequestInterface,
} from '@src/interfaces/accessRequest'
import { FetchDataQueryInterface, Stats } from '@src/interfaces/data'
import { EmployeeDocumentInterface } from '@src/interfaces/documents'

export const accessRequests: RequestInterface<AccessRequestInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.DATA_ACCESS}${API.ACCESS_REQUESTS}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.DATA_ACCESS}${API.ACCESS_REQUESTS}/${id}`),
  patchItem: async (data, id) =>
    api.patch(`${API.DATA_ACCESS}${API.ACCESS_REQUESTS}/${id}`, data),
  deleteItem: async id => api.delete(`${API.DATA_ACCESS}${API.ACCESS_REQUESTS}/${id}`),
  postItem: async data => api.post(`${API.DATA_ACCESS}${API.ACCESS_REQUESTS}`, data),
  putItem: async (data, id) =>
    api.put(`${API.DATA_ACCESS}${API.ACCESS_REQUESTS}/${id}`, data),
}

export const formAccessRequest: RequestInterfaceNew<AccessRequestInterface> = {
  get: async ({ id }) => api.get(`${API.DATA_ACCESS}${API.ACCESS_REQUESTS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.DATA_ACCESS}${API.ACCESS_REQUESTS}/${id}`, data),
  submit: async data =>
    apiWithoutHandling.post(`${API.DATA_ACCESS}${API.ACCESS_REQUESTS}`, data),
  delete: async ({ id }) => api.delete(`${API.DATA_ACCESS}${API.ACCESS_REQUESTS}/${id}`),
}

export const formLookerAccessRequest: RequestInterfaceNew<LookerModelAccessInterface> = {
  get: async ({ id }) => api.get(`${API.DATA_ACCESS}${API.LOOKER_ACCESS_REQUESTS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(
      `${API.DATA_ACCESS}${API.LOOKER_ACCESS_REQUESTS}/${id}`,
      data,
    ),
  submit: async data =>
    apiWithoutHandling.post(`${API.DATA_ACCESS}${API.LOOKER_ACCESS_REQUESTS}`, data),
  delete: async ({ id }) =>
    api.delete(`${API.DATA_ACCESS}${API.LOOKER_ACCESS_REQUESTS}/${id}`),
}

export const actionsLookerAccessRequest = {
  approve: async (data: Partial<LookerModelAccessInterface>) =>
    api.post(`${API.DATA_ACCESS}${API.LOOKER_ACCESS_REQUESTS}/${data?.id}/approve`, data),
  cancel: async (data: Partial<LookerModelAccessInterface>) =>
    api.post(`${API.DATA_ACCESS}${API.LOOKER_ACCESS_REQUESTS}/${data?.id}/cancel`, data),
  reject: async (data: Partial<LookerModelAccessInterface>) =>
    api.post(`${API.DATA_ACCESS}${API.LOOKER_ACCESS_REQUESTS}/${data?.id}/reject`, data),
  revoke: async (data: Partial<LookerModelAccessInterface>) =>
    api.post(`${API.DATA_ACCESS}${API.LOOKER_ACCESS_REQUESTS}/${data?.id}/revoke`, data),
}

export const formLookerFolderAccessRequest: RequestInterfaceNew<LookerModelAccessInterface> =
  {
    get: async ({ id }) =>
      api.get(`${API.DATA_ACCESS}${API.LOOKER_FOLDER_ACCESS_REQUESTS}/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(
        `${API.DATA_ACCESS}${API.LOOKER_FOLDER_ACCESS_REQUESTS}/${id}`,
        data,
      ),
    submit: async data =>
      apiWithoutHandling.post(
        `${API.DATA_ACCESS}${API.LOOKER_FOLDER_ACCESS_REQUESTS}`,
        data,
      ),
    delete: async ({ id }) =>
      api.delete(`${API.DATA_ACCESS}${API.LOOKER_FOLDER_ACCESS_REQUESTS}/${id}`),
  }

export const actionsLookerFolderAccessRequest = {
  approve: async (data: Partial<LookerModelAccessInterface>) =>
    api.post(
      `${API.DATA_ACCESS}${API.LOOKER_FOLDER_ACCESS_REQUESTS}/${data?.id}/approve`,
      data,
    ),
  cancel: async (data: Partial<LookerModelAccessInterface>) =>
    api.post(
      `${API.DATA_ACCESS}${API.LOOKER_FOLDER_ACCESS_REQUESTS}/${data?.id}/cancel`,
      data,
    ),
  reject: async (data: Partial<LookerModelAccessInterface>) =>
    api.post(
      `${API.DATA_ACCESS}${API.LOOKER_FOLDER_ACCESS_REQUESTS}/${data?.id}/reject`,
      data,
    ),
  revoke: async (data: Partial<LookerModelAccessInterface>) =>
    api.post(
      `${API.DATA_ACCESS}${API.LOOKER_FOLDER_ACCESS_REQUESTS}/${data?.id}/revoke`,
      data,
    ),
}

export const teamAccessRequests: RequestInterface<TeamAccessRequestInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.DATA_ACCESS}${API.TEAM_ACCESS_REQUESTS}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.DATA_ACCESS}${API.TEAM_ACCESS_REQUESTS}/${id}`),
  patchItem: async (data, id) =>
    api.patch(`${API.DATA_ACCESS}${API.TEAM_ACCESS_REQUESTS}/${id}`, data),
  deleteItem: async id =>
    api.delete(`${API.DATA_ACCESS}${API.TEAM_ACCESS_REQUESTS}/${id}`),
  postItem: async data => api.post(`${API.DATA_ACCESS}${API.TEAM_ACCESS_REQUESTS}`, data),
  putItem: async (data, id) =>
    api.put(`${API.DATA_ACCESS}${API.TEAM_ACCESS_REQUESTS}/${id}`, data),
}

export const formTeamAccessRequest: RequestInterfaceNew<TeamAccessRequestInterface> = {
  get: async ({ id }) => api.get(`${API.DATA_ACCESS}${API.TEAM_ACCESS_REQUESTS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.DATA_ACCESS}${API.TEAM_ACCESS_REQUESTS}/${id}`, data),
  submit: async data =>
    apiWithoutHandling.post(`${API.DATA_ACCESS}${API.TEAM_ACCESS_REQUESTS}`, data),
  delete: async ({ id }) =>
    api.delete(`${API.DATA_ACCESS}${API.TEAM_ACCESS_REQUESTS}/${id}`),
}

export const getEmployeeAccessRequests = (
  requestData: FetchDataQueryInterface,
  employeeId: number,
): AxiosPromise<GetRequestInterface<AccessRequestInterface>> => {
  const { sortBy, filters, page } = requestData
  const filter_by = groupFiltersIntoURLQuery(filters)
  let ordering = ''
  if (sortBy) {
    ordering = groupSortByIntoURLQuery(sortBy)
  }

  return api.get(`${API.EMPLOYEES}/${employeeId}${API.EMPLOYEE_ACCESS_REQUESTS}`, {
    params: {
      page,
      ordering,
      ...filter_by,
    },
  })
}

export const getEmployeeDocuments = (
  requestData: FetchDataQueryInterface,
  employeeId?: number,
): AxiosPromise<GetRequestInterface<EmployeeDocumentInterface>> => {
  const { sortBy, filters, page } = requestData
  return api.get(`${API.EMPLOYEES}/${employeeId}/documents`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })
}

export const getTeamAccessRequests = (
  requestData: FetchDataQueryInterface,
  teamId: number,
): AxiosPromise<GetRequestInterface<TeamAccessRequestInterface>> => {
  const { sortBy, filters, page } = requestData
  const filter_by = groupFiltersIntoURLQuery(filters)
  let ordering = ''
  if (sortBy) {
    ordering = groupSortByIntoURLQuery(sortBy)
  }

  return api.get(`${API.TEAMS}/${teamId}${API.TEAM_ACCESS_REQUESTS}`, {
    params: {
      page,
      ordering,
      ...filter_by,
    },
  })
}

export const extendTeamAccessRequest = (id: number) =>
  api.post(`${API.DATA_ACCESS}${API.TEAM_ACCESS_REQUESTS}/${id}/extend`)

export const dontExtendTeamAccessRequest = (id: number) =>
  api.post(`${API.DATA_ACCESS}${API.TEAM_ACCESS_REQUESTS}/${id}/dontExtend`)

export const getTeamAccessStatsRequest = (id: number) => (): AxiosPromise<Stats> =>
  api.get(`${API.TEAMS}/${id}${API.TEAM_ACCESS_REQUESTS}/stats`, {})
