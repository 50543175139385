import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Text,
  Avatar,
  Banner,
  Flex,
  Token,
  List,
  Bullet,
  Button,
  ActionButton,
  VStack,
} from '@revolut/ui-kit'
import {
  ExclamationTriangle,
  EyeHide,
  EyeShow,
  Lightbulb,
  LinkExternal,
} from '@revolut/icons'

import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import SideBar from '@components/SideBar/SideBar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { LifecycleSettingsInterface } from '@src/interfaces/settings'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

const previewSections = {
  Bio: 'Personal details, such as date of birth, legal sex, nationalities, languages and so forth',
  Address: 'Employee location: country, city, address',
  'Personal contact info': 'Email address, phone number',
  'Bank details':
    'Information that is necessary for making payments: bank name, name on account, currency, and all related',
  'Emergency contact info': 'Name, relationship, email, mobile number',
}

const getEnabledPreviewSections = (diversityEnabled: boolean) => ({
  ...(diversityEnabled
    ? {
        'Diversity info':
          'Age group, gender identity, sexual orientation, parent/carer, disability, ethnicities, socio-economic background, educational background, religion',
      }
    : null),
})

export const LifecycleRequiredInfo = () => {
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeLifecycleSettings)
  const previewListBottomRef = useRef<HTMLDivElement>(null)
  const { values } = useLapeContext<LifecycleSettingsInterface>()

  const [showPreview, setShowPreview] = useState(true)

  const enabledSections = getEnabledPreviewSections(values.enable_diversity)

  return (
    <>
      <Banner mb="s-24">
        <Banner.Avatar>
          <Avatar useIcon={Lightbulb} />
        </Banner.Avatar>
        <Banner.Content>
          <Banner.Title>
            By default, we ask some information from your new joiners
          </Banner.Title>
          <Banner.Description>
            On the right, you can see the information we will already ask your employees
            when they onboard into your company.
          </Banner.Description>
        </Banner.Content>
      </Banner>
      <Button
        onClick={() => setShowPreview(!showPreview)}
        variant="secondary"
        size="sm"
        mb="s-16"
        useIcon={showPreview ? EyeHide : EyeShow}
      >
        {showPreview ? 'Hide preview' : 'Show preview'}
      </Button>
      <SectionTitle
        title="Do you want to ask employees about their diversity information?"
        subtitle="Such include age group, gender identity, sexual orientation, parent/carer and more. All this information will only be visible to the employee and anonymised reports."
      />
      <LapeBooleanRadioSwitch
        name="enable_diversity"
        variant="horizontal"
        noLabel={
          <Flex>
            No
            <Recommended />
          </Flex>
        }
        yesDescription="Ask employees about their diversity information"
        noDescription="Employees should not be able to add their diversity details"
        disabled={disableEdit}
        onAfterChange={value => {
          if (value) {
            setTimeout(
              () => previewListBottomRef.current?.scrollIntoView({ behavior: 'smooth' }),
              0,
            )
          }
        }}
      />
      <Banner mt="s-16">
        <Banner.Avatar>
          <Avatar useIcon={ExclamationTriangle} color={Token.color.yellow} />
        </Banner.Avatar>
        <Banner.Content>
          <Banner.Description>
            Please consider seeking legal advice on the legal implications of gathering
            diversity data before enabling this functionality.
          </Banner.Description>
        </Banner.Content>
      </Banner>
      <SideBar
        title={<Text color={Token.color.greyTone20}>Preview</Text>}
        onClose={() => setShowPreview(false)}
        isOpen={showPreview}
        variant="wide"
      >
        <VStack space="s-16">
          <ActionButton
            use={InternalLink}
            to={pathToUrl(
              ROUTES.ONBOARDING.START,
              { id: String(user.id) },
              {
                preview: 'true',
                ...(values.enable_diversity ? { diversity: 'true' } : null),
              },
            )}
            useIcon={LinkExternal}
            target="_blank"
          >
            Preview onboarding in a new tab
          </ActionButton>
          <List use="ol">
            {Object.entries({ ...previewSections, ...enabledSections }).map(
              ([section, content], index) => {
                const isHighlighted = section in enabledSections

                return (
                  <List.Item
                    key={section}
                    useIcon={
                      <Bullet
                        bg={isHighlighted ? Token.color.blue : Token.color.greyTone50}
                        borderColor={
                          isHighlighted ? Token.color.blue : Token.color.greyTone50
                        }
                        color={Token.color.white}
                      >
                        {index + 1}
                      </Bullet>
                    }
                  >
                    {section}
                    {!!content && (
                      <Text
                        use="div"
                        mt="s-4"
                        variant="caption"
                        color={Token.color.greyTone50}
                      >
                        {content}
                      </Text>
                    )}
                  </List.Item>
                )
              },
            )}
          </List>
          <div ref={previewListBottomRef} />
        </VStack>
      </SideBar>
    </>
  )
}
