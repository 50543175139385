import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Cell, Flex, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { RowInterface } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@src/components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { DataRetentionSettingInterface } from '@src/interfaces/dataRetentionSettings'
import { dataRetentionSettingsRequests } from '@src/api/dataRetentionSettings'
import {
  dataRetentionCountryColumn,
  dataRetentionSuccessfulColumn,
  dataRetentionUnsuccessfulColumn,
} from '@src/constants/columns/dataRetentionSettings'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<DataRetentionSettingInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.DATA_RETENTION_SETTINGS.PREVIEW, { id })),
  cells: [
    {
      ...dataRetentionCountryColumn,
      width: 200,
    },
    {
      ...dataRetentionUnsuccessfulColumn,
      width: 200,
    },
    {
      ...dataRetentionSuccessfulColumn,
      width: 200,
    },
  ],
}

const DataRetentionSettingsTable = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddDataRetentionPeriod)

  const table = useTable<DataRetentionSettingInterface>(dataRetentionSettingsRequests)

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        {canAdd && (
          <Box mb="s-16">
            <MoreBar>
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.DATA_RETENTION_SETTINGS.GENERAL)}
                useIcon={Plus}
              >
                Create new
              </MoreBar.Action>
            </MoreBar>
          </Box>
        )}
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.DataRetentionSettings}
            useWindowScroll
            row={ROW}
            {...table}
            noDataMessage="Data retention settings will appear here"
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default DataRetentionSettingsTable
