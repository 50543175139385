import { connect } from 'lape'
import set from 'lodash/set'
import get from 'lodash/get'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useParams } from 'react-router-dom'
import { goBack } from '@src/actions/RouterActions'
import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { Archive, Unarchive } from '@revolut/icons'
import { successNotification } from '@src/actions/NotificationActions'
import { FieldOptions, Statuses } from '@src/interfaces'
import { pathToUrl } from '@src/utils/router'
import { SettingsButtonsInstanceProps } from '@src/features/SettingsButtons'
import { EntityPermissions } from '@src/store/auth/types'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'

interface ArchiveButtonProps extends SettingsButtonsInstanceProps {
  afterSubmitUrl?: string
  onBeforeArchive?: () => void
  onAfterArchive?: () => void
  onError?: () => void
  notification?: {
    updateMsg: string
    path?: string
  }
  dialogText?: string
  showDialog?: boolean
  statusFieldName?: string
  unArchiveStatus?: Statuses
}

const LapeArchiveButton = ({
  afterSubmitUrl,
  onBeforeArchive,
  onAfterArchive,
  onError,
  isVisible = true,
  globalPermissions,
  entityPermissions = EntityPermissions.Archive,
  notification,
  dialogText,
  showDialog,
  statusFieldName = 'status',
  unArchiveStatus = Statuses.pending,
}: ArchiveButtonProps) => {
  const { values, submit } = useLapeContext<{
    status: Statuses
    field_options: FieldOptions
    id: number
    name?: string
  }>()
  const [loading, setLoading] = useState(false)
  const params = useParams()
  const isArchived = get(values, statusFieldName) === Statuses.archived

  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
  const permissions = useSelector(selectPermissions)

  const canArchive =
    values.field_options?.actions?.includes(entityPermissions) ||
    globalPermissions?.some(p => permissions.includes(p))

  const archive = async (status = Statuses.archived) => {
    onBeforeArchive?.()
    const fallbackStatus = get(values, statusFieldName)
    set(values, statusFieldName, status)
    setLoading(true)

    try {
      const result = await submit()
      if (afterSubmitUrl) {
        goBack(afterSubmitUrl)
      }
      if (notification && !isArchived) {
        successNotification(
          notification.updateMsg,
          notification.path
            ? pathToUrl(notification.path, { ...params, id: result?.id })
            : undefined,
        )
      } else if (notification) {
        successNotification('Successfully unarchived.')
      }
      onAfterArchive?.()
      return result
    } catch (e) {
      pushNotification({
        type: NotificationTypes.error,
        value: get(e?.response?.data, statusFieldName)?.[0] || 'Archive was unsuccessful',
        duration: SUCCESS_DEFAULT_DURATION,
      })
      set(values, statusFieldName, fallbackStatus)
      onError?.()
    } finally {
      setLoading(false)
    }

    return null
  }

  if (!isVisible || !canArchive) {
    return null
  }

  if (isArchived) {
    return (
      <MoreBar.Action
        useIcon={Unarchive}
        pending={loading}
        onClick={() => archive(unArchiveStatus)}
      >
        Unarchive
      </MoreBar.Action>
    )
  }

  return (
    <>
      {showDialog ? (
        <ConfirmationDialog
          open={isDialogOpen}
          onClose={() => setDialogOpen(false)}
          onConfirm={() => {
            archive()
            setDialogOpen(false)
          }}
          loading={loading}
          onReject={() => setDialogOpen(false)}
          label={`Archiving${values.name ? ` "${values.name}"` : ''}`}
          body={dialogText}
        />
      ) : null}
      <MoreBar.Action
        useIcon={Archive}
        variant="negative"
        pending={loading}
        onClick={showDialog ? () => setDialogOpen(true) : () => archive()}
      >
        Archive
      </MoreBar.Action>
    </>
  )
}

export default connect(LapeArchiveButton)
