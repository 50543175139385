import React, { useState } from 'react'
import { Button, Item, MoreBar, Portal, Side, Tooltip, useTooltip } from '@revolut/ui-kit'
import { Info, Warning, Questionnaire } from '@revolut/icons'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import SideBar from '@src/components/SideBar/SideBar'
import pluralize from 'pluralize'
import StageCounts from '@src/features/CommonCandidatesTable/StageCounts'
import { useGetStages } from '@src/api/recruitment/candidates'
import { InterviewSpecialisationInterface } from '@src/interfaces/interviewTool'
import { useGetOnlineTestInfo, useGetTestPlatforms } from '@src/api/hiringProcess'
import SendOnlineTestPreview from '@src/pages/Forms/SendOnlineTest/SendOnlineTestPreview'
import { bulkSendOnlineTest } from '@src/api/recruitment/interviews'
import BulkStatusPopup from '@src/components/BulkStatusPopup/BulkStatusPopup'

type SendOnlineTestActionProps = {
  disabled: boolean
  onClick: () => void
}

export const SendOnlineTestAction = ({
  disabled,
  onClick,
}: SendOnlineTestActionProps) => {
  const tooltip = useTooltip()
  return (
    <>
      <MoreBar.Action
        {...tooltip.getAnchorProps()}
        useIcon={Questionnaire}
        aria-disabled={disabled}
        onClick={() => {
          if (!disabled) {
            onClick()
          }
        }}
      >
        Send online test
      </MoreBar.Action>
      {disabled && (
        <Tooltip {...tooltip.getTargetProps()}>
          You can only send online test in bulk only for candidate(s) from the same
          process
        </Tooltip>
      )}
    </>
  )
}

type SendOnlineTestSidebarProps = {
  candidateIds: number[]
  candidates: CandidateInterface[]
  specialisationIds: number[]
  specialisations: InterviewSpecialisationInterface[]
  open: boolean
  onClose: () => void
}

const SendOnlineTestSidebar = ({
  candidateIds,
  candidates,
  specialisationIds,
  specialisations,
  open,
  onClose,
}: SendOnlineTestSidebarProps) => {
  const [pending, setPending] = useState(false)
  const [success, setSuccess] = useState<boolean | null>(null)
  const [testId, setTestId] = useState<number | undefined>()

  const { data: testPlatformsData, isLoading: loadingTestPlatform } =
    useGetTestPlatforms(candidateIds)

  const testPlatforms = testPlatformsData?.filter(Boolean)

  const { data: onlineTestInfo, isError: onlineTestInfoError } = useGetOnlineTestInfo(
    open ? testId : undefined,
  )

  const { data: stagesData, isError: stagesError } = useGetStages(
    open ? candidateIds : undefined,
    specialisationIds,
  )
  const stages = stagesData?.filter(({ stage_type }) => stage_type !== 'Total') ?? []

  const handleClose = () => {
    setSuccess(null)
    setTestId(undefined)
    onClose()
  }

  const handleSubmit = async () => {
    if (!testId) {
      return
    }
    setPending(true)
    try {
      await bulkSendOnlineTest({
        candidate_ids: candidateIds,
        test_id: testId,
      })
      setSuccess(true)
      setTestId(undefined)
    } catch (e) {
      setSuccess(false)
    } finally {
      setPending(false)
    }
  }

  const renderForm = () => {
    if (stagesError || onlineTestInfoError) {
      return (
        <Item useIcon={Warning}>
          <Item.Content>
            <Item.Title>Something went wrong</Item.Title>
            <Item.Description>Please refresh the page and try again</Item.Description>
          </Item.Content>
        </Item>
      )
    }
    return (
      <>
        {!stagesError && specialisationIds.length === 1 && (
          <>
            <StageCounts stages={stages} />
            <SendOnlineTestPreview
              loadingTestPlatform={loadingTestPlatform}
              values={{
                online_test: onlineTestInfo,
                test_platform: testPlatforms![0],
                specialisation: specialisations[0],
              }}
              onChange={newOnlineTest => newOnlineTest && setTestId(newOnlineTest.id)}
            />
            <Side.Actions horizontal>
              <Button variant="secondary" onClick={handleClose} autoFocus>
                Cancel
              </Button>
              <Button
                elevated
                disabled={pending || !testId}
                onClick={handleSubmit}
                pending={pending}
              >
                Send test
              </Button>
            </Side.Actions>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <SideBar
        variant="wide"
        isOpen={open}
        onClose={handleClose}
        title={`Send online test for ${pluralize('candidate', candidates?.length, true)}`}
      >
        <>
          {specialisationIds.length !== 1 || testPlatforms?.length !== 1 ? (
            <Item useIcon={Info}>
              <Item.Content>
                You can only send online test in bulk only for candidate(s) from the same
                process
              </Item.Content>
            </Item>
          ) : (
            renderForm()
          )}
        </>
      </SideBar>
      <Portal>
        <BulkStatusPopup isSuccess={success} onClose={handleClose} />
      </Portal>
    </>
  )
}

export default SendOnlineTestSidebar
