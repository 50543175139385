import React from 'react'
import { connect } from 'lape'
import { BREAKPOINTS } from '@revolut/ui-kit'
import { ReportInterface } from '@src/interfaces/dataAnalytics'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DownloadOutputFormat } from './components/DownloadOutputFormat'
import { UiOutputFormat } from './components/UiOutputFormat'

export const FormatForm = connect(() => {
  const { values } = useLapeContext<ReportInterface>()

  return (
    <PageBody maxWidth={BREAKPOINTS.xxl}>
      {values.query && values.report_type === 'download' && <DownloadOutputFormat />}
      {values.query && values.report_type === 'ui' && <UiOutputFormat />}
    </PageBody>
  )
})
