import React from 'react'
import { Statuses } from '@src/interfaces'
import Interviews from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Interviews'
import { SORT_DIRECTION } from '@src/interfaces/data'

const PendingInterviews = () => {
  const initialFilterBy = [
    {
      filters: [
        { id: Statuses.pending, name: Statuses.pending },
        { id: Statuses.draft, name: Statuses.draft },
        { id: Statuses.scheduled, name: Statuses.scheduled },
      ],
      columnName: 'status',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'status',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'scheduled_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  return <Interviews filterBy={initialFilterBy} sortBy={initialSortBy} />
}

export default PendingInterviews
