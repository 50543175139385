import React, { useState } from 'react'
import { useTable, useTableReturnType } from '@components/Table/hooks'
import { Stats } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import {
  onRoadmapsRowClick,
  roadmapsRow,
  SummarySubheader,
  SummaryTableContainer,
  summaryTableProps,
} from '@src/features/Summary/common'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Box, Cell, chain, Flex, Link, TabBar, Text } from '@revolut/ui-kit'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useParams } from 'react-router'
import { formatPercentage } from '@src/utils/format'
import { TableNames } from '@src/constants/table'
import { roadmapsRequests } from '@src/api/roadmaps'
import Stat from '@components/Stat/Stat'
import {
  getRoadmapFilters as getDepartmentRoadmapFilters,
  roadmapSorting as departmentRoadmapSorting,
  RoadmapType,
  tabs,
} from '@src/pages/Forms/DepartmentForm/Roadmap/Roadmap'
import { commonRoadmapSorting } from '@src/features/Roadmaps/table'
import { getTeamRoadmapFilters } from '@src/pages/Forms/TeamForm/Roadmap/Roadmap'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'

interface RoadmapSummaryProps {
  formUrl: string
  percent: number
  onTabChange?: (tab: RoadmapType) => void
  roadmapType?: RoadmapType
  table: useTableReturnType<RoadmapInterface, Stats>
}

const RoadmapSummary = ({
  formUrl,
  percent,
  onTabChange,
  roadmapType,
  table,
}: RoadmapSummaryProps) => {
  const maxItemsToShow = 5

  return (
    <>
      <SummarySubheader formUrl={formUrl}>Roadmap</SummarySubheader>
      <Cell>
        <Flex flexDirection="column" width="100%">
          <Flex justifyContent="space-between" mb="s-16">
            <Stat
              label="Progress"
              val={formatPercentage(percent)}
              color={getPercentColor(percent * 100)}
              mr="s-32"
            />
            {onTabChange && (
              <TabBar variant="segmented" behaviour="scroll">
                {tabs.map(tab => (
                  <TabBar.Item
                    key={tab.id}
                    aria-selected={tab.id === roadmapType}
                    onClick={() => {
                      onTabChange(tab.id)
                    }}
                  >
                    <Box px="s-8">
                      <Text>{tab.title}</Text>
                    </Box>
                  </TabBar.Item>
                ))}
              </TabBar>
            )}
          </Flex>
          <SummaryTableContainer>
            <AdjustableTable<RoadmapInterface>
              name={TableNames.RoadmapSummary}
              row={roadmapsRow}
              onRowClick={onRoadmapsRowClick}
              {...table}
              data={table.data.slice(0, maxItemsToShow)}
              noDataMessage="All your items will appear here."
              {...summaryTableProps}
              hideCountAndButtonSection
            />
          </SummaryTableContainer>
          {table.data.length > maxItemsToShow && (
            <Box pt="s-12">
              <Link fontWeight={500} use="a" href={formUrl} target="_blank">
                {chain('Show all', table.count)}
              </Link>
            </Box>
          )}
        </Flex>
      </Cell>
    </>
  )
}

export const DepartmentRoadmapSummary = ({ data }: { data: DepartmentInterface }) => {
  const params = useParams<{ id?: string }>()
  const [roadmapType, setRoadmapType] = useState<RoadmapType>(RoadmapType.Department)
  const departmentTable = useTable<RoadmapInterface, Stats>(
    roadmapsRequests,
    getDepartmentRoadmapFilters(data, RoadmapType.Department),
    departmentRoadmapSorting,
  )
  const teamsTable = useTable<RoadmapInterface, Stats>(
    roadmapsRequests,
    getDepartmentRoadmapFilters(data, RoadmapType.Team),
    departmentRoadmapSorting,
  )
  const table = roadmapType === RoadmapType.Department ? departmentTable : teamsTable

  const formUrl = pathToUrl(ROUTES.FORMS.DEPARTMENT.ROADMAP, params)
  const percent = table?.stats?.avg_done || 0

  return (
    <RoadmapSummary
      formUrl={formUrl}
      percent={percent}
      onTabChange={tab => {
        setRoadmapType(tab)
      }}
      roadmapType={roadmapType}
      table={table}
    />
  )
}

export const TeamRoadmapSummary = ({ data }: { data: TeamInterface }) => {
  const params = useParams<{ id?: string }>()
  const table = useTable<RoadmapInterface, Stats>(
    roadmapsRequests,
    getTeamRoadmapFilters(data),
    commonRoadmapSorting,
  )

  const formUrl = pathToUrl(ROUTES.FORMS.TEAM.ROADMAP, params)
  const percent = table?.stats?.avg_done || 0

  return <RoadmapSummary formUrl={formUrl} percent={percent} table={table} />
}
