import React from 'react'
import { ROUTES } from '@src/constants/routes'
import General from '@src/pages/Settings/Offers/General'
import { PermissionTypes } from '@src/store/auth/types'
import { offersSettings } from '@src/api/settings'
import { SettingsForm } from '../common/SettingsForm'

const routes = [
  {
    title: 'Offers settings',
    description: 'Settings governing the way offers are handled',
    path: ROUTES.SETTINGS.OFFERS,
    url: ROUTES.SETTINGS.OFFERS,
    canView: [
      PermissionTypes.ViewOfferFormPreferences,
      PermissionTypes.ChangeOfferFormPreferences,
    ],
    component: General,
  },
]

export const OffersSettings = () => {
  return <SettingsForm routes={routes} api={offersSettings} />
}
