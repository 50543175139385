import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'
import { selectUser } from '@src/store/auth/selectors'
import { pathToUrl } from '@src/utils/router'
import { ArrowThinRight, Document } from '@revolut/icons'
import { Button } from '@revolut/ui-kit'
import { useResignation } from '@src/api/employeeResignation'
import { FormPreview } from '@components/FormPreview/FormPreview'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useResignationPermissions } from '../../../Forms/EmployeeResignation/hooks'
import { InternalLink } from '@components/InternalLink/InternalLink'

type Props = {
  resignationId: number
}
export const ResignationNotice = ({ resignationId }: Props) => {
  const { id: employeeId } = useParams<{ id: string }>()
  const user = useSelector(selectUser)

  const { data: resignation } = useResignation(employeeId, String(resignationId))
  const { canApprove, canAdd, canView, canEdit } = useResignationPermissions(resignation)

  const isThisUser = String(user?.id) === employeeId
  const isEmployeeNotice = isThisUser && canView && canEdit && !canApprove
  const isHrNotice = canView && canApprove

  if (!canAdd || !resignation || resignation.status.id === 'cancelled') {
    return null
  }
  if (isEmployeeNotice) {
    return (
      <FormPreview<EmployeeResignationInterface> title="Resignation">
        <ActionWidget
          title="You have successfully submitted your intent to leave"
          text="HR or LM manager will reach you shortly to discuss further steps."
        >
          <Button
            variant="secondary"
            size="sm"
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.REASONS_PREVIEW, {
              employeeId,
              id: resignation.id,
            })}
            useIcon={Document}
          >
            Open resignation
          </Button>
        </ActionWidget>
      </FormPreview>
    )
  }
  if (isHrNotice) {
    return (
      <FormPreview<EmployeeResignationInterface> title="Resignation">
        <ActionWidget
          title="Employee triggered resignation process"
          text={
            <>
              Please go to resignation dashboard to see the details.
              {resignation.notice_period && (
                <>
                  <br />
                  Notice period as per contract: {resignation.notice_period}
                </>
              )}
            </>
          }
        >
          <Button
            variant="secondary"
            size="sm"
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.DASHBOARD, {
              employeeId,
              id: resignation.id,
            })}
            useIcon={ArrowThinRight}
          >
            Open resignation dashboard
          </Button>
        </ActionWidget>
      </FormPreview>
    )
  }
  return null
}
