import React from 'react'
import { useSelector } from 'react-redux'
import { Group, HStack, TableWidget, Token } from '@revolut/ui-kit'
import { CalendarWeek, ListBullet } from '@revolut/icons'

import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  getEmployeeTimeOffRequests,
  useEmployeeTimeOffRequestYearOptions,
  useEmployeeTimeOffStats,
} from '@src/api/timeOff'
import { getDefaultYearOption, TimeOffYearSelect } from '@src/features/TimeOff/YearSelect'
import Stat from '@components/Stat/Stat'
import { useTable } from '@components/Table/hooks'
import { TableNames } from '@src/constants/table'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeTimeOffRequestInterface } from '@src/interfaces/timeOff'
import {
  timeOffRequestApprovalColumn,
  timeOffRequestApprovedOnColumn,
  timeOffRequestApproverColumn,
  timeOffRequestDurationColumn,
  timeOffRequestEndDateColumn,
  timeOffRequestNameColumn,
  timeOffRequestRequestedOnColumn,
  timeOffRequestStartDateColumn,
  timeOffRequestStatusColumn,
} from '@src/constants/columns/timeOff'
import { canViewTimeOffRequests } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { selectUser } from '@src/store/auth/selectors'
import { useGetTeam } from '@src/api/teams'
import { PermissionTypes } from '@src/store/auth/types'
import { TeamCalendarView } from './TeamCalendarView'

const ROW: RowInterface<EmployeeTimeOffRequestInterface> = {
  cells: [
    {
      ...timeOffRequestNameColumn,
      width: 120,
    },
    {
      ...timeOffRequestStartDateColumn,
      width: 120,
    },
    {
      ...timeOffRequestEndDateColumn,
      width: 120,
    },
    {
      ...timeOffRequestDurationColumn,
      width: 100,
    },
    {
      ...timeOffRequestRequestedOnColumn,
      width: 150,
    },
    {
      ...timeOffRequestApprovedOnColumn,
      width: 150,
    },
    {
      ...timeOffRequestApproverColumn,
      width: 150,
    },
    {
      ...timeOffRequestApprovalColumn,
      width: 120,
    },
    {
      ...timeOffRequestStatusColumn,
      width: 120,
    },
  ],
}

type Props = {
  data: EmployeeInterface
}
export const RequestsWidget = ({ data }: Props) => {
  const user = useSelector(selectUser)
  const isSelfView = user.id === data.id
  const canViewAllRequests = canViewTimeOffRequests(data)

  const { data: teamData } = useGetTeam(data.team?.id)
  const canViewTeamRequestsPolicy = !!teamData?.field_options.permissions?.includes(
    PermissionTypes.ViewTimeOffRequestPolicy,
  )

  const { tabBar: viewModeTabBar, currentTab: viewModeCurrentTab } = useTabBarSwitcher({
    tabs: ['calendar', 'table'],
    useIcons: { calendar: CalendarWeek, table: ListBullet },
    defaultTab: 'calendar',
    highlightSelected: false,
  })
  const { tabBar: periodTabBar, currentTab: periodCurrentTab } = useTabBarSwitcher({
    tabs: ['Week', 'Month', 'Year'],
    defaultTab: isSelfView ? 'Year' : 'Week',
    highlightSelected: false,
  })

  const requestYearOptions = useEmployeeTimeOffRequestYearOptions(
    canViewAllRequests ? data.id : undefined,
  )
  const isTableView = viewModeCurrentTab === 'table'

  const table = useTable(
    { getItems: getEmployeeTimeOffRequests(data.id) },
    [
      {
        columnName: 'year',
        filters: canViewAllRequests ? [getDefaultYearOption()] : [],
        nonResettable: true,
      },
    ],
    undefined,
    { disableQuery: true, disable: !canViewAllRequests },
  )
  const stats = useEmployeeTimeOffStats(canViewAllRequests ? data.id : undefined)

  return (
    <Group>
      <HStack p="s-16" space="s-16">
        {canViewAllRequests && viewModeTabBar}
        {!isTableView && periodTabBar}
      </HStack>
      <TableWidget>
        <TableWidget.Actions>
          {isTableView && (
            <HStack gap="s-32">
              <TimeOffYearSelect
                api={requestYearOptions}
                onChange={option => {
                  table.onFilterChange(
                    {
                      columnName: 'year',
                      filters: [{ id: option.id, name: `${option.id}` }],
                    },
                    false,
                  )
                }}
              />
              <Stat
                label="Pending approval"
                val={stats.data?.pending_request_count}
                color={Token.color.warning}
              />
            </HStack>
          )}
        </TableWidget.Actions>
        {isTableView && canViewAllRequests ? (
          <TableWidget.Table>
            <AdjustableTable
              name={TableNames.EmployeeTimeOffRequests}
              useWindowScroll
              row={{
                ...ROW,
                linkToForm: row =>
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
                      employeeId: data.id,
                      id: row.id,
                    }),
                  ),
              }}
              // TODO (RHR-4551):
              //  According to the visibility rules, we should hide the policy name column based on the permission value,
              //  but for now we assume that if a user has PermissionTypes.ViewTimeOffRequests,
              //  he is allowed to see the requests' policies as well (this is how it happened historically).
              //
              //  Before we enable the permission check,
              //  this must be fixed on the permission assignment level for all related users/roles.
              //
              // hiddenCells={{
              //   [timeOffRequestNameColumn.idPoint]:
              //     !data.field_options.permissions?.includes(
              //       PermissionTypes.ViewTimeOffRequestPolicy,
              //     ),
              // }}
              {...table}
            />
          </TableWidget.Table>
        ) : (
          <TeamCalendarView
            data={data}
            period={periodCurrentTab}
            canViewPolicy={canViewTeamRequestsPolicy}
          />
        )}
      </TableWidget>
    </Group>
  )
}
