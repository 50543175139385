import { RoadmapInterface, RoadmapPriorities } from '@src/interfaces/roadmaps'
import { useTheme, Color } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'
import { RevolutTheme } from '@src/styles/theme'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const usePriorityColor = (priority: RoadmapPriorities) => {
  const theme = useTheme<RevolutTheme>()
  switch (priority) {
    case RoadmapPriorities.TRIVIAL:
      return getColor(theme, Color.GREY_TONE_50)
    case RoadmapPriorities.MINOR:
      return theme.colors.green
    case RoadmapPriorities.MEDIUM:
      return theme.colors.warning
    case RoadmapPriorities.HIGH:
      return theme.colors.red
    case RoadmapPriorities.MAJOR:
    case RoadmapPriorities.CRITICAL:
    case RoadmapPriorities.COMPANY:
    case RoadmapPriorities.BLOCKER:
      return theme.legacyColors.darkRed
    default:
      return theme.colors.foreground
  }
}

export const getRoadmapEntityName = ({
  team,
  employee,
  department,
}: RoadmapInterface): string => {
  return team?.name || employee?.display_name || department?.name || ''
}

export const getRoadmapBackUrl = ({
  team,
  employee,
  department,
}: RoadmapInterface): string => {
  if (team) {
    return pathToUrl(ROUTES.FORMS.TEAM.ROADMAP, { id: team.id })
  }
  if (employee) {
    return pathToUrl(ROUTES.FORMS.EMPLOYEE.ROADMAP, { id: employee.id })
  }
  if (department) {
    return pathToUrl(ROUTES.FORMS.DEPARTMENT.ROADMAP, { id: department.id })
  }
  return ''
}
