import React from 'react'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { chain } from '@revolut/ui-kit'
import { Route, Switch } from 'react-router-dom'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { applicationCandidateRequests } from '@src/api/applicationCandidate'
import { formatDate } from '@src/utils/format'
import CandidateFormPreview from '@src/features/CandidateFormPreview/CandidateFormPreview'
import { ApplicationCandidateInterface } from '@src/interfaces/applicationCandidate'

const ApplicationCandidate = () => {
  const { values } = useLapeContext<ApplicationCandidateInterface>()
  const backUrl = ROUTES.RECRUITMENT.CANDIDATES

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title={
          values.id
            ? chain('Application form', values.candidate?.full_name)
            : 'Create a new application'
        }
        subtitle={
          values.full_name && values.created_date_time ? (
            <>
              Submitted by {values.full_name}, on {formatDate(values.created_date_time)}
            </>
          ) : undefined
        }
        backUrl={backUrl}
      />
      <Switch>
        <Route exact path={ROUTES.FORMS.APPLICATION_CANDIDATE.PREVIEW}>
          <CandidateFormPreview />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={applicationCandidateRequests}>
    <ApplicationCandidate />
  </Form>
))
