import Tooltip from '@components/Tooltip/Tooltip'
import Icon from '@components/Icon/Icon'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { colorGetter } from '@src/styles/colors'
import { Box, Color, Flex } from '@revolut/ui-kit'
import Dialog from '@components/Modals/Dialog/Dialog'

const dropdownHeight = 410

const Wrapper = styled.div`
  cursor: initial;
  position: relative;
  font-size: 14px;
  flex: 0;
  height: 100%;
  display: flex;
  align-items: start;
  transition: opacity 100ms linear;
  color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
`

const ChartDropdown = styled.div<{
  vertical?: 'left' | 'right'
  horizontal?: 'top' | 'bottom'
}>`
  font-size: 14px;
  position: absolute;
  bottom: 32px;
  ${({ horizontal }) => (horizontal === 'top' ? 'bottom: -20px' : 'top: -15px')};
  ${({ vertical }) => (vertical === 'right' ? `left: 0` : `right: 0`)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 100ms linear;
  background: ${colorGetter(Color.BACKGROUND)};
  width: 500px;
  flex-grow: 0;
  height: ${dropdownHeight}px;
  z-index: ${props => props.theme.zIndex.popup};
  border: 1px solid ${colorGetter(Color.GREY_20_OPAQUE_90)};
  border-radius: 8px;
`

const PopupChartStyled = styled(Dialog)`
  animation-name: none;
`

export const ChartInsideTooltipContext = React.createContext<{
  onChartClick: () => void
}>({
  onChartClick: () => {},
})

const HiringPipelineChart: React.FC = ({ children }) => {
  const [hoveredPosition, updateHover] = useState<'top' | 'bottom' | undefined>(undefined)
  const [openBigChart, setOpenBigChart] = useState(false)

  const showChart = (e: React.MouseEvent) => {
    const position =
      window.innerHeight - e.clientY - 40 > dropdownHeight ? 'bottom' : 'top'
    updateHover(position)
  }

  const context = useMemo(
    () => ({
      onChartClick: () => setOpenBigChart(true),
    }),
    [setOpenBigChart],
  )

  return (
    <ChartInsideTooltipContext.Provider value={context}>
      <Flex onClick={e => e.stopPropagation()}>
        <PopupChartStyled
          open={openBigChart}
          onClose={() => setOpenBigChart(false)}
          showCloseButton
        >
          <Box width="70vw" height="70vh">
            {children}
          </Box>
        </PopupChartStyled>
        <Tooltip
          placement={hoveredPosition || 'top'}
          hide={!hoveredPosition}
          noArrow
          body={
            <ChartDropdown horizontal={hoveredPosition}>
              <Box width={480} height={500}>
                {children}
              </Box>
            </ChartDropdown>
          }
        >
          <Wrapper onMouseEnter={showChart}>
            <Icon type="Graph" size="small" />
          </Wrapper>
        </Tooltip>
      </Flex>
    </ChartInsideTooltipContext.Provider>
  )
}

export default HiringPipelineChart
