import React from 'react'
import { Subheader, DetailsCellSkeleton, Box, Group } from '@revolut/ui-kit'

const CandidateDataSkeleton = () => {
  return (
    <Box mb="s-16">
      <Subheader variant="nested">
        <Subheader.Title>Interview details</Subheader.Title>
      </Subheader>
      <Group>
        {new Array(8).fill(0).map((_, idx) => (
          <DetailsCellSkeleton key={idx} />
        ))}
      </Group>
    </Box>
  )
}

export default CandidateDataSkeleton
