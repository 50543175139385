import React from 'react'
import { Box, Button, Caption, Color, H1, Paragraph } from '@revolut/ui-kit'

type ExpiredLinkProps = {
  recruiterEmail?: string
}

const ExpiredLink = ({ recruiterEmail }: ExpiredLinkProps) => {
  return (
    <Box mt="s-40" color={Color.FOREGROUND} px="s-16">
      <H1 mb="s-8">This link has expired </H1>
      <Paragraph mb="s-4">
        Please contact the recruiter to receive a new link and book a call
      </Paragraph>
      {recruiterEmail && (
        <>
          <Caption mb="s-16">{recruiterEmail}</Caption>
          <Button variant="secondary" size="sm" use="a" href={`mailto:${recruiterEmail}`}>
            Contact recruiter
          </Button>
        </>
      )}
    </Box>
  )
}

export default ExpiredLink
