import React from 'react'
import { Box } from '@revolut/ui-kit'
import CommonCandidatesTable from '@src/features/CommonCandidatesTable/CommonCandidatesTable'
import { HiringPipelineInterface } from '@src/interfaces/hiringPipelines'

interface Props {
  data?: HiringPipelineInterface
}

const Candidates = ({ data }: Props) => {
  if (!data) {
    return null
  }

  return (
    <Box mt="s-16">
      <CommonCandidatesTable type="hiringPipeline" data={data} />
    </Box>
  )
}

export default Candidates
