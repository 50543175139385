import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { chain, Text } from '@revolut/ui-kit'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import { budgetPoolsRequest } from '@src/api/budgetPools'
import { General } from './General'
import { Preview } from './Preview'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { BudgetPoolInterface } from '@src/interfaces/budgetPools'
import { getStatusColor } from '@src/components/CommonSC/General'

const BudgetPool = () => {
  const { values } = useLapeContext<BudgetPoolInterface>()

  const title = values.id
    ? chain(
        values?.name,
        <Text color={getStatusColor(values.status?.id)}>{values.status?.name}</Text>,
      )
    : 'Add new budget pool'

  return (
    <PageWrapper>
      <PageHeader
        title={title}
        backUrl={ROUTES.PEOPLE.COMPENSATION.BUDGET_POOLS}
        subtitle={values.id ? 'Budget pool' : null}
      />

      <Switch>
        <Route exact path={ROUTES.FORMS.BUDGET_POOL.GENERAL} component={General} />
        <Route exact path={ROUTES.FORMS.BUDGET_POOL.PREVIEW} component={Preview} />
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={budgetPoolsRequest}>
    <BudgetPool />
  </Form>
))
