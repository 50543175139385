import React, { useCallback } from 'react'
import {
  createChain,
  Color,
  Text,
  ContextMenuController,
  DragAndDrop,
  Dropdown,
  move,
  TabBar,
  TabBarProps,
} from '@revolut/ui-kit'
import { Pin, Unpin } from '@revolut/icons'
import { matchPath, useLocation } from 'react-router-dom'

import { getPrevLocationDescriptor } from '@src/actions/RouterActions'
import { usePinnedTabs } from '@src/features/TabPinning/pinnedTabs'
import { getColor } from '@src/styles/colors'
import { useTheme } from '@src/styles/theme'
import { TabBarWithPinningTabConfig } from '@src/interfaces/tabPinning'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import TabBarLink from '@components/TabBarLink/TabBarLink'

interface TabBarWithPinningProps extends TabBarProps {
  tabs: TabBarWithPinningTabConfig[]
  isHomeTab?: boolean
}

export const TabBarWithPinning = ({
  tabs,
  isHomeTab,
  ...props
}: TabBarWithPinningProps) => {
  const { sendAnalyticsEvent } = useAnalytics()
  const location = useLocation()
  const { pinnedTabs, setPinnedTabs } = usePinnedTabs()
  const theme = useTheme()

  const handlePinning = (tabId: string, pin: boolean) => {
    if (pin) {
      sendAnalyticsEvent(AnalyticsEvents.pin_tab, { tab_id: tabId })
      setPinnedTabs(prev => [...prev, tabId])
      return
    }

    sendAnalyticsEvent(AnalyticsEvents.unpin_tab, { tab_id: tabId })
    setPinnedTabs(prev => prev.filter(pinnedTabId => pinnedTabId !== tabId))
  }

  const onDragEnd = useCallback(
    event => {
      if (event.over) {
        /** Offset by -1 is because the first tab "Dashboard" should not be treated as part of the group, it should always stay as 1st tab */
        const startIndex = event.active.data.current.sortable.index - 1
        const endIndex = event.over.data.current.sortable.index - 1

        if (startIndex !== endIndex && startIndex !== -1 && endIndex !== -1) {
          sendAnalyticsEvent(AnalyticsEvents.reorder_tabs)
          setPinnedTabs(prev =>
            move(
              /** Filter on visible tabs only, otherwise the ordering breaks, e.g. if some tabs are in pinned apps localstorage, but they were removed and are not visible */
              prev.filter(tab => tabs.find(t => t.id === tab)),
              startIndex,
              endIndex,
            ),
          )
        }
      }
    },
    [tabs],
  )

  return (
    <DragAndDrop.Provider onDragEnd={onDragEnd}>
      <TabBar
        behaviour="scroll"
        maxWidth="min-content"
        data-testid="tab-bar-with-pinning"
        {...props}
      >
        <DragAndDrop.Sortable
          id="pinned-tabs"
          type="tab"
          items={tabs.map(tab => tab.id || tab.title)}
        >
          {sortable => {
            const tab = tabs.find(t => t.id === sortable.id || t.title === sortable.id)

            if (!tab) {
              return null
            }

            const match = tab.path
              ? !!matchPath(location.pathname, { path: tab.path, exact: true })
              : undefined

            return (
              <ContextMenuController key={tab.id}>
                {contextMenu => {
                  const isPinned = !!tab.id && pinnedTabs.includes(tab.id)
                  const targetProps = contextMenu.getTargetProps()
                  const color = targetProps.open ? getColor(theme, Color.BLUE) : undefined

                  return (
                    <>
                      <TabBarLink
                        aria-selected={match === undefined ? undefined : match}
                        exact
                        to={tab.urlDescriptor || getPrevLocationDescriptor(tab.url)}
                        {...(tab.id === 'dashboard' || !tab.id
                          ? {}
                          : { ...contextMenu.getAnchorProps() })}
                        /** We only want to reorder in the Home tab and only if there are pinned tab, so if only 1 dashboard tab then don't reorder */
                        {...(!isHomeTab || tabs.length === 1
                          ? { style: { color } }
                          : {
                              ...sortable.props,
                              style: { ...sortable.props.style, color },
                            })}
                      >
                        {createChain(
                          <Text color={Color.GREY_20_OPAQUE_90} px="s-4">
                            ·
                          </Text>,
                        )(tab.title, tab.quickSummary)}
                      </TabBarLink>
                      <Dropdown {...targetProps} width={160}>
                        <Dropdown.Item
                          use="button"
                          useIcon={isPinned ? Pin : Unpin}
                          onClick={() => {
                            if (tab.id) {
                              handlePinning(tab.id, !isPinned)
                            }
                          }}
                        >
                          {isPinned ? 'Unpin tab' : 'Pin tab'}
                        </Dropdown.Item>
                      </Dropdown>
                    </>
                  )
                }}
              </ContextMenuController>
            )
          }}
        </DragAndDrop.Sortable>
        <DragAndDrop.DragOverlay>
          {overlay => {
            const tab = tabs.find(t => t.id === overlay.active.id)

            return tab ? (
              <TabBar>
                <TabBarLink {...overlay.props}>{tab.title}</TabBarLink>
              </TabBar>
            ) : null
          }}
        </DragAndDrop.DragOverlay>
      </TabBar>
    </DragAndDrop.Provider>
  )
}
