import React, { useState } from 'react'
import { CareerPageSettingsInterface, FunctionGroup } from '@src/interfaces/settings'
import {
  ActionButton,
  Box,
  CircleButton,
  DragAndDrop,
  Flex,
  IconButton,
  Input,
  InputGroup,
  VStack,
  move,
} from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import { OptionInterface } from '@src/interfaces/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewMultiSelect from '@src/components/Inputs/NewMultiSelect/NewMultiSelect'
import { IdAndName } from '@src/interfaces'

type FunctionGroupInputProps = {
  available?: boolean
  disabled: boolean
  id: number
  name: string
  nameError?: string
  functions: IdAndName[]
  functionError?: string
  functionSelector: () => Promise<OptionInterface[]>
  sortable?: DragAndDrop.DefaultSortableItemState<{}>
  onDelete: () => void
  onChange: (functionGroup: FunctionGroup) => void
}

const FunctionGroupInput = ({
  available,
  disabled,
  id,
  functions,
  name,
  nameError,
  functionSelector,
  functionError,
  sortable,
  onDelete,
  onChange,
}: FunctionGroupInputProps) => {
  return (
    <Flex
      data-testid={`group_${id}`}
      gap="s-8"
      ref={sortable?.setNodeRef}
      style={{
        transform: sortable?.transform
          ? `translate3d(${sortable?.transform.x}px, ${sortable?.transform.y}px, 0)`
          : undefined,
        transition: sortable?.transition || 'none',
        opacity: sortable?.isDragging ? 0 : undefined,
      }}
    >
      <Box width={24} mt="s-16">
        {!available && (
          <IconButton useIcon="Drag" {...sortable?.attributes} {...sortable?.listeners} />
        )}
      </Box>
      <VStack width="100%" gap="s-8">
        <Input
          data-testid={`group-name-${id}`}
          required
          label="Displayed group name"
          value={name}
          onChange={event => {
            onChange({
              id,
              name: event.currentTarget.value,
              functions,
            })
          }}
          aria-invalid={!!nameError}
          errorMessage={nameError}
          disabled={disabled}
        />
        <NewMultiSelect
          data-testid={`group-functions-${id}`}
          label="Functions"
          placeholder="Select functions"
          value={functions.map(f => ({ ...f, label: f.name, value: f }))}
          onChange={values => {
            onChange({
              id,
              name,
              functions: values.map(({ value }) => value),
            })
          }}
          hasError={!!functionError}
          message={functionError}
          selector={functionSelector}
          disabled={disabled}
        />
      </VStack>
      <Box width={48} mt="s-4">
        {!available && (
          <CircleButton
            aria-label={`delete function group ${id}`}
            onClick={onDelete}
            variant="widget-action"
            useIcon="Delete"
            disabled={disabled}
          />
        )}
      </Box>
    </Flex>
  )
}

type JobPostingGroupsInputProps = {
  disabled: boolean
}

const NEW_FUNCTION_GROUP = {
  name: '',
  functions: [],
}

const useFunctionsSelector = (functionGroups: FunctionGroup[]) => {
  const { data } = useGetSelectors(selectorKeys.functions)
  const functionGroupIds = functionGroups
    .filter(({ functions }) => functions && functions.length)
    .flatMap(({ functions }) => functions)
    .map(({ id }) => id)
  const availableFunctions = data
    ? data.filter(({ id }) => !functionGroupIds.includes(id as number))
    : []
  return {
    availableFunctions,
    functionsSelector: () => {
      return Promise.resolve(availableFunctions)
    },
  }
}

const JobPostingGroupsInput = ({ disabled }: JobPostingGroupsInputProps) => {
  const { values, errors } = useLapeContext<CareerPageSettingsInterface>()
  const functionGroups = values.function_groups
  const currentFunctionGroups = functionGroups?.length ? functionGroups : []

  const [activeId, setActiveId] = useState<string | number | null>(null)
  const activeFunctionGroupIndex =
    activeId !== null
      ? currentFunctionGroups.findIndex(({ id }) => String(id) === activeId)
      : -1
  const activeFunctionGroup =
    activeFunctionGroupIndex >= 0
      ? currentFunctionGroups[activeFunctionGroupIndex]
      : undefined
  const activeFunctionGroupErrors =
    errors?.function_groups?.[activeFunctionGroup?.id ?? activeFunctionGroupIndex]

  const { availableFunctions, functionsSelector } =
    useFunctionsSelector(currentFunctionGroups)

  const handleAdd = () => {
    const newFunctionGroups = [
      ...currentFunctionGroups,
      { ...NEW_FUNCTION_GROUP, id: currentFunctionGroups.length },
    ].map((f, id) => ({ ...f, id }))
    values.function_groups = newFunctionGroups
  }

  const handleDelete = (index?: number) => {
    if (index !== null) {
      const newFunctionGroups = currentFunctionGroups
        .filter((_, i) => index !== i)
        .map((f, id) => ({ ...f, id }))
      values.function_groups = newFunctionGroups
    }
  }

  const handleOrderChange = (startIndex: number, endIndex: number) => {
    if (startIndex !== endIndex) {
      values.function_groups = move(currentFunctionGroups, startIndex, endIndex)
    }
    setActiveId(null)
  }

  const handleChange = (index: number, newFunctionGroup: FunctionGroup) => {
    values.function_groups[index] = newFunctionGroup
  }

  return (
    <VStack p="s-16">
      <InputGroup>
        <DragAndDrop.Provider
          onDragStart={event => {
            setActiveId(event.active.id)
          }}
          onDragEnd={event => {
            if (event.over) {
              handleOrderChange(
                event?.active?.data?.current?.sortable.index ?? 0,
                event?.over?.data?.current?.sortable.index ?? 0,
              )
            }
          }}
          onDragCancel={() => {
            setActiveId(null)
          }}
        >
          <DragAndDrop.Sortable
            id="sortable"
            items={currentFunctionGroups.map(({ id }) => String(id))}
          >
            {sortable => {
              const functionGroupIndex = currentFunctionGroups.findIndex(
                ({ id }) => String(id) === sortable.id,
              )
              const currentFunctionGroup = currentFunctionGroups[functionGroupIndex]
              const currentFunctionGroupErrors =
                errors?.function_groups?.[currentFunctionGroup.id ?? functionGroupIndex]
              return (
                <FunctionGroupInput
                  disabled={disabled}
                  id={currentFunctionGroup.id}
                  name={currentFunctionGroup.name}
                  nameError={currentFunctionGroupErrors?.name}
                  functions={currentFunctionGroup.functions}
                  functionError={
                    currentFunctionGroupErrors?.functions as unknown as string
                  }
                  functionSelector={functionsSelector}
                  sortable={sortable}
                  onChange={newFunctionGroup =>
                    handleChange(functionGroupIndex, newFunctionGroup)
                  }
                  onDelete={() => handleDelete(functionGroupIndex)}
                />
              )
            }}
          </DragAndDrop.Sortable>
          <DragAndDrop.DragOverlay>
            {activeFunctionGroup && (
              <FunctionGroupInput
                disabled={disabled}
                id={activeFunctionGroup.id}
                name={activeFunctionGroup.name}
                nameError={activeFunctionGroupErrors?.name}
                functions={activeFunctionGroup.functions}
                functionError={activeFunctionGroupErrors?.functions as unknown as string}
                functionSelector={functionsSelector}
                onDelete={() => handleDelete(activeFunctionGroupIndex)}
                onChange={() => {}}
              />
            )}
          </DragAndDrop.DragOverlay>
        </DragAndDrop.Provider>
        {!!availableFunctions.length && (
          <FunctionGroupInput
            available
            id={-1}
            disabled
            name="Others"
            functions={availableFunctions.map(({ id, name }) => ({
              id: Number(id),
              name,
            }))}
            functionSelector={functionsSelector}
            onDelete={() => {}}
            onChange={() => {}}
          />
        )}
        <ActionButton ml="s-32" useIcon="Plus" onClick={handleAdd}>
          Add function group
        </ActionButton>
      </InputGroup>
    </VStack>
  )
}

export default JobPostingGroupsInput
