import React from 'react'
import { Item, Spacer, Text } from '@revolut/ui-kit'

const HiringProcessIntro = () => {
  return (
    <Item>
      <Item.Content>
        <Item.Title>How does hiring process works in Revolut People?</Item.Title>
        <Item.Description>
          <Text use="p">
            In the contemporary world of recruitment, it's imperative that hiring remains
            fair, consistent, and unbiased.
          </Text>
          <Spacer height={16} />
          <Text use="p">
            The Hiring Process app found within the{' '}
            <strong>Revolut People Recruitment</strong> delivers on those principles – an
            advanced tool tailor-made for those who want a specialisation-centric approach
            to recruitment. The app sets itself apart by ensuring that recruitment is not
            just about experience or degrees, but more about the actual skills that a
            candidate will utilise in their day-to-day tasks for a given specialization.
            At its core, the Hiring Process App prioritizes unbiased recruitment. By
            emphasizing skill assessments that directly correlate with the requirements of
            a particular job role, it ensures that candidates are evaluated based on merit
            and the true value they can bring to a position. For newcomers to the
            platform, the app offers a set of default hiring stages that are meticulously
            crafted by industry professionals to give a holistic view of the recruitment
            process. But the true power of the app lies in its adaptability: users can
            delve deep, customizing these stages and defining their very own hiring
            scorecards. This flexibility ensures that the hiring process can be tweaked
            and refined to align perfectly with a company's unique needs and values.
            Whether you're an HR professional or a business owner, the Hiring Process App
            promises a streamlined, transparent, and bias-free hiring process. As you
            navigate further, you'll be equipped to specify the content and structure of
            your company's individualized hiring protocol. The future of fair and
            skill-centric recruitment awaits you!
          </Text>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

export default HiringProcessIntro
