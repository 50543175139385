import { Color, UnifiedTheme } from '@revolut/ui-kit'
import { RevolutTheme } from '@src/styles/theme'

// Note: this helper ensures a type check that is not present in string literals like theme.colors['grey-20']
export const getColor = (theme: UnifiedTheme, colorName: Color): Color =>
  // @ts-expect-error UI kit team will check this
  theme.colors[colorName]

// For styled components to omit writing: props => props.theme.colors[COLORS[colorName]]
export const colorGetter = (colorName: Color) => (props: { theme: RevolutTheme }) => {
  return getColor(props.theme, colorName)
}

export const CHART_COLORS: Color[] = [
  'blue-opaque-80',
  'green-opaque-90',
  'yellow-opaque-90',
  'indigo-opaque-70',
  'green-opaque-60',
  'cyan-opaque-60',
  'deep-pink-opaque-80',
  'purple-opaque-90',
  'red-opaque-70',
  'indigo',
  'red-opaque-40',
  'blue-opaque-60',
  'terracotta-opaque-50',
  'light-green-opaque-70',
  'cyan',
  'yellow-opaque-50',
  'indigo-opaque-50',
  'terracotta',
  'deep-pink',
  'light-green-opaque-90',
  'blue-opaque-40',
  'yellow-opaque-70',
  'deep-pink-opaque-50',
  'red-opaque-90',
  'green-opaque-40',
  'terracotta-opaque-70',
  'lime-opaque-60',
  'light-green-opaque-50',
  'purple-opaque-70',
  'grey-tone-50',
]
