import React from 'react'
import { ProbationResults } from '@src/interfaces/probationReview'
import { probationResultToString } from '@src/constants/performance'
import { Color, Text } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'

type Props = {
  result?: ProbationResults
  status: Statuses
  className?: string
}

const ProbationResult = ({ result, status, className }: Props) => {
  const isPending = status === Statuses.pending
  let color: string = Color.FOREGROUND

  switch (result) {
    case ProbationResults.Passed:
      color = Color.GREEN
      break
    case ProbationResults.Failed:
      color = Color.RED
      break
  }

  if (isPending) {
    color = Color.GREY_TONE_50
  }

  return (
    <Text className={className} color={color} fontSize="primary" textAlign="right">
      {isPending ? 'Pending' : probationResultToString(result)}
    </Text>
  )
}

export default ProbationResult
