import { Box, Button, Cell, Checkbox, Radius, Text, VStack } from '@revolut/ui-kit'
import React, { useReducer, useState } from 'react'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import NewDatePicker from '@src/components/Inputs/NewDatePicker/NewDatePicker'
import { selectorKeys } from '@src/constants/api'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { pathToUrl } from '@src/utils/router'
import { BulkEditData, BulkEditRequest } from '@src/interfaces/bulkEdit'
import { IdAndName } from '@src/interfaces'
import { PageActions } from '@src/components/Page/PageActions'
import { useLocation } from 'react-router-dom'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { FormErrorGuard } from '@src/features/Form/FormErrorGuard'
import { navigateTo } from '@src/actions/RouterActions'
import { AxiosPromise } from 'axios'

type SelectedOptionsType = {
  [key: string]: IdAndName | null
}

type StateType = {
  [key: string]: boolean
}

type Field = {
  id: string
  name: string
  selector: selectorKeys
}

type Props = {
  fields: Field[]
  createBulkEdit: (data: BulkEditRequest) => AxiosPromise
  nextStepRoute: string
}

const SelectFields = ({ fields, createBulkEdit, nextStepRoute }: Props) => {
  const { state: bulkEditData } = useLocation<BulkEditData>()

  const [effectiveDateTime, setEffectiveDateTime] = useState<string>(
    new Date().toISOString(),
  )

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const initialState: StateType = fields.reduce((acc, { id }) => {
    return {
      ...acc,
      [id]: false,
    }
  }, {})

  const toggleReducer = (
    state: typeof initialState,
    action: keyof typeof initialState,
  ) => {
    return {
      ...state,
      [action]: !state[action],
    }
  }

  const [toEdit, dispatchToEdit] = useReducer(toggleReducer, initialState)

  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionsType>(
    fields.reduce((acc, { id }) => {
      return {
        ...acc,
        [id]: null,
      }
    }, {}),
  )

  const prepareData = () => {
    const data: BulkEditRequest = {
      object_ids: bulkEditData.selectedIds,
      effective_date_time: effectiveDateTime,
      fields: {},
    }

    Object.keys(toEdit).forEach(key => {
      if (toEdit[key]) {
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        data.fields[key] = {
          id: Number(selectedOptions[key]?.id),
        }
      }
    })

    return data
  }

  const onContinueClick = async () => {
    setIsLoading(true)
    try {
      const response = await createBulkEdit(prepareData())
      const { id } = response.data
      navigateTo(pathToUrl(nextStepRoute, { id }))
    } catch (err) {
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const nothingToEdit = Object.keys(toEdit).every(key => !toEdit[key])

  const nothingSelected = Object.keys(toEdit)
    .filter(key => toEdit[key])
    .some(key => !selectedOptions[key])

  const buttonDisabled = nothingToEdit || nothingSelected

  if (!bulkEditData || bulkEditData?.selectedIds?.length === 0) {
    return null
  }

  if (error) {
    return <FormErrorGuard error={error} />
  }

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <>
      <AutoStepper>
        <NewStepperTitle title="Select fields to change" />
        <Text color="gray">
          Chosen value will override the current value for all selected items.
        </Text>
        <Box mt="s-16" />
        <Box borderRadius={Radius.WIDGET} backgroundColor="widget-background">
          {fields.map(({ id, name, selector }) => (
            <Cell key={id}>
              <VStack gap="s-8" width="100%">
                <Checkbox checked={toEdit[id]} onChange={() => dispatchToEdit(id)}>
                  {name}
                </Checkbox>
                {toEdit[id] && (
                  <RadioSelectInput<IdAndName>
                    selector={selector}
                    value={selectedOptions[id]}
                    onChange={selection => {
                      setSelectedOptions(prev => ({
                        ...prev,
                        [id]: selection,
                      }))
                    }}
                    label={`Select new ${name.toLowerCase()}`}
                    labelSearch={`Search for a ${name.toLowerCase()}`}
                  />
                )}
              </VStack>
            </Cell>
          ))}
        </Box>
        <NewStepperTitle title="Select effective date for these changes" />
        <NewDatePicker
          value={effectiveDateTime}
          onChange={date => {
            setEffectiveDateTime(date?.toISOString() || '')
          }}
          disabledDays={{ before: new Date() }}
        />
      </AutoStepper>
      <PageActions>
        <Button
          disabled={buttonDisabled}
          pending={isLoading}
          onClick={onContinueClick}
          size="md"
          type="submit"
        >
          Continue
        </Button>
      </PageActions>
    </>
  )
}

export default SelectFields
