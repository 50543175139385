import React, { useEffect, useState } from 'react'
import Summary from '@src/pages/Forms/Candidate/InterviewProgress/Summary'
import { Route, Switch, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import {
  CandidateInterface,
  CandidateOpenedSidebarType,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { getCandidate, getInterviewRound } from '@src/api/recruitment/interviews'
import { connect } from 'lape'
import { PageWrapper } from '@components/Page/Page'
import { Box, Flex } from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import Title from '@src/pages/Forms/Candidate/InterviewProgress/components/Title'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import Emails from '@src/pages/Forms/Candidate/Emails/Emails'
import Comments from '@src/pages/Forms/Candidate/Comments/Comments'
import { useGetCandidateEmailThreads, useGetCandidateStats } from '@src/api/hiringProcess'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import Timeline from '@src/pages/Forms/Candidate/Timeline/Timeline'
import Forms from '@src/pages/Forms/Candidate/Forms/Forms'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import Documents from '@src/pages/Forms/Candidate/Documents/Documents'
import AnonymizedCandidate from './AnonymizedCandidate/AnonymizedCandidate'
import { useGetCandidateSettings } from '@src/api/settings'
import CandidateInformation, {
  ShowCandidateInformation,
} from '@src/pages/Forms/Candidate/CandidateInformation'
import PersonalDataSidebarForm from '@src/features/InterviewTool/PersonalDataSidebarForm'
import CandidateDataSidebar from '@src/features/InterviewTool/CandidateDataSidebar'
import { PermissionTypes } from '@src/store/auth/types'
import CVPreviewSidebar from '@src/features/CVPreviewSidebar/CVPreviewSidebar'
import CandidateSendEmailSidebar from '@src/components/CandidateSendEmailSidebar/CandidateSendEmailSidebar'
import CandidateSubtitle from '@src/pages/Forms/Candidate/CandidateSubtitle'

const Candidate = () => {
  const [candidate, setCandidate] = useState<CandidateInterface>()
  const [round, setRound] = useState<InterviewRoundInterface>()
  const [showCandidateInformation, setShowCandidateInformation] = useState(true)
  const [openedSidebar, setOpenedSidebar] = useState<
    CandidateOpenedSidebarType | undefined
  >()

  const [loading, setLoading] = useState(true)
  const params = useParams<{ id: string }>()
  const { data: stats, refetch: refreshStats } = useGetCandidateStats(params.id)
  const { data: candidateSettings } = useGetCandidateSettings()
  useSetDocumentTitle(candidate?.full_name, 'Recruitment')

  const {
    data: emailData,
    refetch: refetchEmails,
    isLoading: loadingEmails,
  } = useGetCandidateEmailThreads(candidate?.id)

  const fetchData = async () => {
    try {
      setLoading(true)
      const candidateResult = await getCandidate(params.id)
      setCandidate(candidateResult.data)

      if (candidateResult.data.active_interview_round) {
        await fetchRound(candidateResult.data.active_interview_round.id)
      }
    } finally {
      setLoading(false)
    }
  }

  const fetchRound = async (roundId: number) => {
    setLoading(true)

    try {
      const roundResult = await getInterviewRound(roundId)
      setRound(roundResult.data)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [params.id])

  const previewStageMode = candidate?.active_interview_round?.id !== round?.id
  const canAddRound = previewStageMode
    ? false
    : !!round?.field_options?.permissions?.includes(PermissionTypes.AddInterviewRound)
  const canEditRound = previewStageMode
    ? false
    : !!round?.field_options?.permissions?.includes(PermissionTypes.ChangeInterviewRound)

  const tabs = [
    {
      title: 'Overview',
      path: ROUTES.FORMS.CANDIDATE.SUMMARY,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, params),
      component: (
        <Summary
          candidate={candidate}
          canEditRound={canEditRound}
          previewStageMode={previewStageMode}
          round={round}
          refresh={fetchData}
          isLoading={loading}
          refreshStats={refreshStats}
          openedSidebar={openedSidebar}
          onOpenSidebar={type => {
            setShowCandidateInformation(false)
            setOpenedSidebar(type)
          }}
          showCandidateInformationAction={
            <ShowCandidateInformation
              show={showCandidateInformation}
              onClick={() => setShowCandidateInformation(!showCandidateInformation)}
            />
          }
        />
      ),
      canView: true,
    },
    {
      title: 'Documents',
      path: ROUTES.FORMS.CANDIDATE.DOCUMENTS,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.DOCUMENTS, params),
      component: (
        <Documents
          refreshStats={refreshStats}
          onOpenSidebar={() => {
            setShowCandidateInformation(false)
          }}
        />
      ),
      canView: true,
      quickSummary: <QuickSummaryCount count={stats?.file_count} />,
    },
    {
      title: 'Forms',
      path: ROUTES.FORMS.CANDIDATE.FORMS,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.FORMS, params),
      component: <Forms />,
      canView: true,
      quickSummary: <QuickSummaryCount count={stats?.form_count} />,
    },
    {
      title: 'Emails',
      path: ROUTES.FORMS.CANDIDATE.EMAILS,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.EMAILS, params),
      component: (
        <Emails
          candidateId={candidate?.id}
          emailData={emailData?.results ?? []}
          loadingEmails={loadingEmails}
          onOpenSidebar={type => {
            setShowCandidateInformation(false)
            setOpenedSidebar(type)
          }}
        />
      ),
      canView: !!candidateSettings?.enable_emails,
      quickSummary: <QuickSummaryCount count={stats?.thread_count} />,
    },
    {
      title: 'Comments',
      path: ROUTES.FORMS.CANDIDATE.COMMENTS,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.COMMENTS, params),
      component: <Comments roundId={round?.id} refreshStats={refreshStats} />,
      canView: true,
      quickSummary: <QuickSummaryCount count={stats?.comment_count} />,
    },
    {
      title: 'Timeline',
      path: ROUTES.FORMS.CANDIDATE.TIMELINE,
      to: pathToUrl(ROUTES.FORMS.CANDIDATE.TIMELINE, params),
      component: <Timeline roundId={round?.id} />,
      canView: true,
      quickSummary: <QuickSummaryCount count={stats?.timeline_event_count} />,
    },
  ].filter(tab => tab.canView)

  if (candidate?.is_anonymised) {
    return <AnonymizedCandidate />
  }

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={
            <Title
              name={candidate?.full_name}
              state={round?.state}
              isConfidential={candidate?.is_confidential}
            />
          }
          isLoading={loading}
          subtitle={<CandidateSubtitle candidate={candidate} round={round} />}
          backUrl={ROUTES.RECRUITMENT.CANDIDATES}
        >
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={tabs} />
          </Box>
        </PageHeader>

        <Flex flexDirection="column" width="100%" minHeight={0}>
          <Switch>
            {tabs.map(tab => (
              <Route exact path={tab.path} key={tab.path}>
                {tab.component}
              </Route>
            ))}
          </Switch>

          <CandidateInformation
            candidate={candidate}
            canAddRound={canAddRound}
            canEditRound={canEditRound}
            round={round}
            loading={loading}
            previewStageMode={previewStageMode}
            open={showCandidateInformation}
            onClose={() => {
              setShowCandidateInformation(!showCandidateInformation)
            }}
            onOpenSidebar={type => {
              setShowCandidateInformation(false)
              setOpenedSidebar({ type })
            }}
            onPreviewRound={fetchRound}
          />
          {openedSidebar?.type === 'personalData' && (
            <PersonalDataSidebarForm
              isOpen
              onClose={() => {
                setShowCandidateInformation(true)
                setOpenedSidebar(undefined)
              }}
              onRefresh={fetchData}
            />
          )}
          {openedSidebar?.type === 'candidate' && (
            <CandidateDataSidebar
              isOpen
              onExit={() => {
                setShowCandidateInformation(true)
                setOpenedSidebar(undefined)
              }}
              round={round}
              refresh={fetchData}
              canAddRound={canAddRound}
              canEditRound={canEditRound}
            />
          )}
          {openedSidebar?.type === 'cv' && (
            <CVPreviewSidebar
              isOpen
              id={candidate?.id}
              onClose={() => {
                setShowCandidateInformation(true)
                setOpenedSidebar(undefined)
              }}
            />
          )}
          {candidate && openedSidebar?.type === 'sendEmail' && (
            <CandidateSendEmailSidebar
              isOpen
              onClose={() => {
                setOpenedSidebar(undefined)
              }}
              candidateIds={[candidate.id]}
              onRefetch={refetchEmails}
            />
          )}
        </Flex>
      </PageWrapper>
    </>
  )
}

export default connect(Candidate)
