import { ChangelogApi } from '@src/interfaces/data'
import React, { useEffect, useMemo, useState } from 'react'
import omit from 'lodash/omit'
import { useQuery } from '@src/utils/queryParamsHooks'
import { mergeFormValues, useChangelog } from '@src/utils/form'
import { PendingChangelogOptions } from '@src/interfaces/changelog'
import { RevolutTheme } from '@src/styles/theme'
import get from 'lodash/get'
import HistoryTooltip from '@components/Stepper/HistoryTooltip'
import { getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'
import { getHistoryRequest } from '@src/api/changelog'

export type PreviewChangelog<T> = {
  data?: Partial<T>
  values?: T
  api?: ChangelogApi
  previousValues?: T
  showPendingChanges?: boolean
}

export const usePreviewChangelog = <T extends {}>({
  api,
  data,
}: {
  api: ChangelogApi
  data: T
}) => {
  const [changelogState, setChangelogState] = useState<PreviewChangelog<T>>({})

  const { deleteQueryParam } = useQuery()

  const changelogFromUrl = useChangelog(api)

  useEffect(() => {
    if (changelogFromUrl.data) {
      setChangelogState({
        data: changelogFromUrl.data,
        values: mergeFormValues(data, changelogFromUrl.data),
        api,
      })
    }
  }, [changelogFromUrl.data, api])

  const onClose = () => {
    deleteQueryParam('changes')
    deleteQueryParam('change')
    setChangelogState({})
  }

  const state = useMemo(
    () => ({
      state: changelogState,
      onClose,
    }),
    [changelogState],
  )

  return state
}

export const usePreviewPendingChanges = <T extends { id: number }>({
  api,
  pendingChangesRequest,
  data,
  showPendingChanges,
  disabled,
  fieldsToOmit = ['field_options', 'status', 'status_display'],
  transformChangelogData = c => c,
  transformPreviousValues = (prev, curr) =>
    mergeFormValues(curr, omit(prev, fieldsToOmit)),
}: PendingChangelogOptions<T> & { data: T }): PreviewChangelog<T> => {
  const [changelogData, setChangelogData] = useState<Partial<T>>()
  const [previousValues, setPreviousValues] = useState<T>()

  useEffect(() => {
    const fetchChanges = async () => {
      const response = await pendingChangesRequest(data.id)
      setPreviousValues(transformPreviousValues(response.data.previous_values, data))
      setChangelogData(transformChangelogData(response.data.fields_to_change, data))
    }
    if (!disabled) {
      fetchChanges()
    }
  }, [pendingChangesRequest, disabled])

  return useMemo(
    () => ({
      data: changelogData,
      values: data,
      previousValues,
      showPendingChanges,
      api,
    }),
    [changelogData, previousValues, data, showPendingChanges, api],
  )
}

export const getChangelogIcon = <T,>(
  theme: RevolutTheme,
  data?: T,
  field?: string,
  changelog?: PreviewChangelog<T>,
  changelogActive?: boolean,
) => {
  if (!data || !field || !changelog?.api) {
    return null
  }

  const topLevelField = field.split('.')[0]
  const hasChanged = get(changelog.data, field) !== undefined

  if (hasChanged && (changelog.showPendingChanges || changelogActive)) {
    return (
      <HistoryTooltip
        isNew
        color={getColor(theme, Color.WARNING)}
        request={getHistoryRequest(data, topLevelField, changelog.api)}
        iconSize="tiny"
      />
    )
  }
  if (changelogActive) {
    return (
      <HistoryTooltip
        isNew
        color={getColor(theme, Color.LIGHT_BLUE_OPAQUE_60)}
        request={getHistoryRequest(data, topLevelField, changelog.api)}
        iconSize="tiny"
      />
    )
  }

  return null
}
