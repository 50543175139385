import React from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CareerPageSettingsInterface } from '@src/interfaces/settings'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { API } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { Box, Cell, Item, VStack } from '@revolut/ui-kit'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { pathToUrl } from '@src/utils/router'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import { InternalUIKitLink } from '@src/components/InternalLink/InternalLink'
import styled from 'styled-components'
import CompanyLogoInput from '@src/pages/Settings/CareerPage/CompanyLogoInput'
import LinksInput from '@src/pages/Settings/CareerPage/LinksInput'
import JobPostingGroupsInput from '@src/pages/Settings/CareerPage/JobPostingGroupsInput'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import CareersPagePreview from '@src/pages/Careers/Preview'

export const CareerLink = styled(InternalUIKitLink)`
  text-decoration: underline;
`

const CareerPageGeneral = () => {
  const { values } = useLapeContext<CareerPageSettingsInterface>()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCareerPagePreferences)
  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="Publish"
            subtitle={
              <>
                You’ll be able to access page at{' '}
                <CareerLink
                  // @ts-expect-error object works fine here, but UI kit expects string
                  to={pathToUrl(PUBLIC_ROUTES.CAREERS.JOB_LIST)}
                  target="_blank"
                  color="inherit"
                >
                  {window.location.hostname}
                  {PUBLIC_ROUTES.CAREERS.JOB_LIST}
                </CareerLink>
              </>
            }
          />
          <Cell p="0">
            <VStack width="100%">
              <LapeNewSwitch
                required
                itemTypeProps={{
                  title: 'Publish careers page',
                  description:
                    'Make the page visible for candidates. All the published Job Postings will appear there',
                }}
                name="publish_career_website"
                disabled={disableEdit}
              />
              <Box pl="s-16" pb="s-16">
                <CareersPagePreview />
              </Box>
            </VStack>
          </Cell>
          <NewStepperTitle
            title="Compliance"
            subtitle="Make sure you’re compliant with regulations"
          />
          <Item use="label">
            <Item.Content>
              <Item.Title>Data Privacy notice</Item.Title>
              <Item.Description>
                Upload a link to your Data Privacy Notice for candidates. It will be
                placed in the footer of the application page and added to each job
                posting. Please be advised that a Privacy Notice explaining how personal
                data is processed might be mandated by law in certain geographies.
              </Item.Description>
              <Box mt="s-16">
                <LapeNewInput
                  name="data_privacy_notice_url"
                  placeholder="Link"
                  disabled={disableEdit}
                />
              </Box>
            </Item.Content>
          </Item>

          <NewStepperTitle
            title="Personalisation"
            subtitle="Personalise the Careers page using your brand assets"
          />
          <Box mt="s-24">
            <Cell p="0">
              <VStack width="100%">
                <Item use="label">
                  <Item.Content>
                    <Item.Title>Slogan</Item.Title>
                    <Item.Description>
                      One or two sentences. i.e. “Join us to build the fastest rocketship
                      to Mars!”
                    </Item.Description>
                    <Box mt="s-16">
                      <LapeNewTextArea
                        required
                        name="slogan"
                        placeholder="Slogan"
                        disabled={disableEdit}
                        maxCharacters={140}
                      />
                    </Box>
                  </Item.Content>
                </Item>
                <Item use="label" onClick={e => e.preventDefault()}>
                  <Item.Content>
                    <Item.Title>Company logo</Item.Title>
                    <Item.Description>
                      Upload a logo to be used at the header of the page
                    </Item.Description>
                    <CompanyLogoInput disabled={disableEdit} />
                  </Item.Content>
                </Item>
                <Item use="label">
                  <Item.Content>
                    <Item.Title>Custom links</Item.Title>
                    <Item.Description>
                      Add up to 4 links to the header of the page. For example, your
                      company’s blog or “About us” page.
                      <br />
                      When adding custom links, keep in mind that they will be displayed
                      from left to right on the final page.
                      <br />
                      The order is determined by how they appear in the customisation
                      settings.
                    </Item.Description>
                  </Item.Content>
                </Item>
                <LinksInput disabled={disableEdit} />
                <Item use="label">
                  <Item.Content>
                    <Item.Title>About company</Item.Title>
                    <Item.Description>
                      This text that will be added to each job posting’s “About the
                      company” section
                    </Item.Description>
                    <Box mt="s-16">
                      <LapeHTMLEditor
                        required
                        name="about_the_company"
                        placeholder={`Example:
At our company, we are driven by a single goal: to be the benchmark of excellence in our industry. Since our founding, we've been at the forefront of innovation, committed to not only providing exceptional products and services but also to making a positive impact on our community and the environment.

We want exceptional candidates that love great companies.`}
                        disabled={disableEdit}
                        maxCharacters={5000}
                      />
                    </Box>
                  </Item.Content>
                </Item>
              </VStack>
            </Cell>
          </Box>
          <NewStepperTitle
            title="Job postings grouping"
            subtitle="Decide how your Job Postings will be grouped on the Careers page"
          />
          <Box mt="s-24">
            <Cell p="0">
              <VStack width="100%">
                <LapeNewSwitch
                  required
                  itemTypeProps={{
                    title: 'Enable custom grouping by function',
                    description: (
                      <>
                        By default all the Job Postings are grouped by their corresponding
                        Functions. Activate this option if you wish to define custom
                        groupings that combine multiple Functions under a single custom
                        group. Any functions not included in these custom groupings will
                        be categorised under 'Others'.
                      </>
                    ),
                  }}
                  name="group_by_function"
                  disabled={disableEdit}
                  onAfterChange={groupByFunction => {
                    if (!groupByFunction) {
                      values.function_groups = []
                    }
                  }}
                />
                {values.group_by_function && (
                  <JobPostingGroupsInput disabled={disableEdit} />
                )}
              </VStack>
            </Cell>
          </Box>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.CAREER_PAGE_SETTINGS)
            queryClient.invalidateQueries(API.POSTING_CAREER_PAGE_SETTINGS)
          }}
          useValidator
          isExistingData
        />
      </PageActions>
    </>
  )
}

export default CareerPageGeneral
