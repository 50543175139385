import { Button, Flex, Text } from '@revolut/ui-kit'
import React from 'react'
import { PageActions } from '@src/components/Page/PageActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'

type Props = {
  closeRoute: string
}

const TrackProgressPageActions = ({ closeRoute }: Props) => {
  return (
    <PageActions>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="s-16"
        maxWidth="60%"
        my="auto"
      >
        <Button elevated use={InternalLink} to={closeRoute} size="md" variant="primary">
          Close bulk edit task
        </Button>
        <Button
          use={InternalLink}
          to={ROUTES.PEOPLE.BACKGROUND_JOBS}
          size="md"
          variant="secondary"
        >
          Go to background jobs
        </Button>
        <Text textAlign="center" color="gray">
          You can leave this page now and check the progress of the task in background
          jobs page at any time.
        </Text>
      </Flex>
    </PageActions>
  )
}

export default TrackProgressPageActions
