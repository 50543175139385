import React from 'react'
import { Flex, MoreBar, StatusWidget, TableWidget } from '@revolut/ui-kit'

import { useTable } from '@src/components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  paygroupCountry,
  paygroupEntity,
  paygroupHeadcount,
  paygroupOwner,
  paygroupPayFrequency,
  paygroupStatus,
} from '@src/constants/columns/paygroups'
import Stat from '@src/components/Stat/Stat'
import { Plus } from '@revolut/icons'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getPaygroups } from '@src/api/payroll'
import { PaygroupInterface } from '@src/interfaces/payroll'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const Row: RowInterface<PaygroupInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.APPS.PAYROLL.PAY_GROUP.PREVIEW, {
        id: data.id,
      }),
    ),
  cells: [
    {
      ...paygroupCountry,
      width: 150,
    },
    {
      ...paygroupEntity,
      width: 150,
    },
    {
      ...paygroupHeadcount,
      width: 70,
    },
    {
      ...paygroupPayFrequency,
      width: 70,
    },
    {
      ...paygroupOwner,
      width: 150,
    },
    {
      ...paygroupStatus,
      width: 100,
    },
  ],
}

export const PaygroupTable = () => {
  const permissions = useSelector(selectPermissions)

  const table = useTable({
    getItems: getPaygroups,
  })

  const canAdd = permissions.includes(PermissionTypes.AddPaygroup)
  const addButton = (
    <MoreBar.Action
      useIcon={Plus}
      use={InternalLink}
      to={pathToUrl(ROUTES.APPS.PAYROLL.PAY_GROUP.EDIT)}
    >
      Add
    </MoreBar.Action>
  )

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat label="Total" val={table.loading ? undefined : table.count} />
      </TableWidget.Info>
      <TableWidget.Actions>
        {canAdd && <MoreBar>{addButton}</MoreBar>}
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.PayGroups}
          row={Row}
          useWindowScroll
          {...table}
          emptyState={
            <Flex flexDirection="column" alignItems="center" my="s-64">
              <StatusWidget>
                <StatusWidget.Image
                  src="https://assets.revolut.com/assets/3d-images/3D183.png"
                  srcSet="https://assets.revolut.com/assets/3d-images/3D183@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D183@3x.png 3x"
                />
                <StatusWidget.Title>No pay groups</StatusWidget.Title>
              </StatusWidget>
              {canAdd && addButton}
            </Flex>
          }
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
