import React from 'react'
import {
  ChangeScorecardInterface,
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewScorecardInterface,
  ScorecardError,
  SkillCardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import ScorecardSection from '@components/ScorecardGeneral/ScorecardSection'
import {
  CardContentTypes,
  cardHasMissingValues,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import set from 'lodash/set'
import { getNormalizedCards, getUpdatedRatingCard } from '@src/utils/performance'

interface Props {
  isViewMode: boolean
  selectedField: SelectedFieldInterface
}

export const ManagerSkills = connect(({ isViewMode, selectedField }: Props) => {
  const { values, errors, submitFailed, fields } =
    useLapeContext<ReviewScorecardInterface>()

  const skillsCards = getNormalizedCards(values.review_data?.manager_skills?.cards || [])

  if (selectedField.cardIndex === undefined) {
    return null
  }

  const card = skillsCards[selectedField.cardIndex]

  if (!card) {
    return null
  }

  const allErrors = submitFailed
    ? (errors?.review_data?.manager_skills?.cards as ScorecardError[])
    : undefined

  const touched = Object.values(fields).some(state => state.touched)
  const missingValues = cardHasMissingValues(
    CardContentTypes.MANAGER_SKILLS,
    card as SkillCardInterface,
  )

  const onChange = async ({
    cardIndex,
    sectionIndex,
    optionIndex,
    value,
  }: ChangeScorecardInterface) => {
    const path = `review_data.manager_skills.cards.${cardIndex}`
    set(values, `${path}.sections.${sectionIndex}.items.${optionIndex}.value`, value)

    const updatedCard =
      selectedField.cardIndex !== undefined &&
      (await getUpdatedRatingCard(
        values.id,
        values.category,
        values.reviewed_employee.id!,
        values.review_data?.manager_skills?.cards?.[selectedField.cardIndex],
      ))

    if (updatedCard) {
      set(values, `${selectedField.field}.rating`, updatedCard.rating)
    }
  }

  return (
    <ScorecardSection
      mt={0}
      noWidgetMargin
      skillId={`${
        (card as SkillCardInterface).skill_id || (card as SkillCardInterface).criteria_key
      }`}
      cardIndex={selectedField.cardIndex}
      key={`${
        (card as SkillCardInterface).skill_id || (card as SkillCardInterface).criteria_key
      }`}
      disabled={isViewMode}
      type={PerformanceReviewTypes.managerSkills}
      category={ReviewCategory.Performance}
      onChange={onChange}
      viewMode={isViewMode}
      errors={allErrors?.[selectedField.cardIndex]}
      sectionErrors={allErrors}
      touched={!!allErrors?.[selectedField.cardIndex] || touched}
      // previousReviews: there are no them in the BE
      card={card as SkillCardInterface}
      onSetResultLoading={() => {}}
      titleTooltip="This skill is added due to the employee being a people manager"
      showErrorLabel={missingValues}
      hideCountAndPrefill
      showExceeding
    />
  )
})
