import React, { useState } from 'react'
import { Action, MoreBar, TableWidget } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { getAnalyticsDashboards } from '@src/api/analyticsDashboards'
import { navigateTo } from '@src/actions/RouterActions'
import { EntityTypes } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { analyticsDashboardDetailsColumn } from '@src/constants/columns/analyticsDashboard'
import { TableNames } from '@src/constants/table'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { FilterByInterface, RowInterface, SortByInterface } from '@src/interfaces/data'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { pathToUrl } from '@src/utils/router'
import { DetailsSideBar } from './DetailsSideBar'

interface FindingsTabProps {
  canAdd?: boolean
  data?: TeamInterface | DepartmentInterface
  entity: EntityTypes
  initialFilter?: FilterByInterface[]
  initialSort?: SortByInterface[]
  row: RowInterface<GenericAnalyticsDashboardInterface>
}

export const AnalyticsDashboardsTab = ({
  canAdd,
  data,
  entity,
  initialFilter,
  initialSort,
  row,
}: FindingsTabProps) => {
  const [sideBarId, setSideBarId] = useState<number>()
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [showAll, setShowAll] = useState<boolean>()

  const table = useTable<GenericAnalyticsDashboardInterface>(
    { getItems: getAnalyticsDashboards(entity, data?.id) },
    initialFilter,
    initialSort,
  )

  const tableRow: RowInterface<GenericAnalyticsDashboardInterface> = {
    ...row,
    cells: [
      ...row.cells,
      {
        ...analyticsDashboardDetailsColumn,
        width: 40,
        insert: ({ data: dashboardData }) => (
          <Action
            onClick={e => {
              e.stopPropagation()

              setSideBarOpen(!sideBarOpen)
              setSideBarId(dashboardData.id)
            }}
          >
            Details
          </Action>
        ),
      },
    ],
  }

  const onSwitch = () => {
    table.onFilterChange([
      {
        columnName: 'company_related',
        filters: showAll
          ? [
              {
                id: 'true',
                name: 'true',
              },
            ]
          : [],
      },
    ])

    setShowAll(!showAll)
  }

  return (
    <>
      <TableWidget>
        <TableWidget.Actions>
          <MoreBar>
            {canAdd && (
              <MoreBar.Action
                onClick={() => {
                  const locationState = {
                    entityType: entity,
                    ...(data && { entityId: data.id, entityName: data.name }),
                  }

                  navigateTo(
                    pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS, {}),
                    locationState,
                  )
                }}
                useIcon={Plus}
              >
                Add dashboard
              </MoreBar.Action>
            )}
          </MoreBar>
        </TableWidget.Actions>
        {entity === EntityTypes.company && (
          <TableWidget.Filters>
            <SwitchButton checked={showAll} onClick={onSwitch}>
              Show all Dashboards
            </SwitchButton>
          </TableWidget.Filters>
        )}
        <TableWidget.Table>
          <AdjustableTable<GenericAnalyticsDashboardInterface>
            dataType="Dashboards"
            name={TableNames.AnalyticsDashboards}
            row={tableRow}
            useWindowScroll
            {...table}
          />
        </TableWidget.Table>
      </TableWidget>
      <DetailsSideBar
        dashboardId={sideBarId}
        isOpen={sideBarOpen}
        setIsOpen={setSideBarOpen}
      />
    </>
  )
}
