import React from 'react'
import { Flex, FlexProps, BREAKPOINTS } from '@revolut/ui-kit'

export const PageBody: React.FC<FlexProps> = props => (
  <Flex
    maxWidth={{ all: '100%', md: BREAKPOINTS.md }}
    flex="1 0"
    flexDirection="column"
    pt="s-8"
    mb="s-24"
    {...props}
  />
)
