import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Button, FilterButton, Flex } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { DynamicGroupInerface } from '@src/interfaces/dynamicGroups'
import {
  dynamicGroupsCreatedDateColumn,
  dynamicGroupsDescriptionColumn,
  dynamicGroupsGroupNameColumn,
  dynamicGroupsOwnerColumn,
  dynamicGroupsStatusColumn,
  dynamicGroupsTypeColumn,
} from '@src/constants/columns/dynamicGroups'
import { getDynamicGroups } from '@src/api/dynamicGroups'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const Row: RowInterface<DynamicGroupInerface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL, { id: data.id })),
  cells: [
    {
      ...dynamicGroupsGroupNameColumn,
      width: 240,
    },
    {
      ...dynamicGroupsTypeColumn,
      width: 150,
    },
    {
      ...dynamicGroupsDescriptionColumn,
      width: 500,
    },
    {
      ...dynamicGroupsCreatedDateColumn,
      width: 150,
    },
    {
      ...dynamicGroupsOwnerColumn,
      width: 250,
    },
    {
      ...dynamicGroupsStatusColumn,
      width: 150,
    },
  ],
}

const DynamicGroupsTable = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_DYNAMIC_GROUPS_ASSIGNED_TO_ME,
    false,
  )

  const user = useSelector(selectUser)

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const table = useTable<DynamicGroupInerface>(
    {
      getItems: getDynamicGroups,
    },
    getInitialFilters(),
  )
  const permissions = useSelector(selectPermissions)
  const canAddGroup = permissions.includes(PermissionTypes.AddDynamicGroups)

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <TableWrapper>
      <Flex mb="s-16" justifyContent="space-between">
        {canAddGroup && (
          <Button
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL)}
            mr="s-16"
            useIcon={Plus}
            size="sm"
            variant="secondary"
          >
            Add Group
          </Button>
        )}
        <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
          My Groups
        </FilterButton>
      </Flex>
      <AdjustableTable<DynamicGroupInerface>
        name={TableNames.DynamicGroups}
        useWindowScroll
        dataType="Group"
        row={Row}
        {...table}
        noDataMessage="Groups will appear here."
      />
    </TableWrapper>
  )
}

export default DynamicGroupsTable
