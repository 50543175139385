import React from 'react'
import { Route, Switch } from 'react-router-dom'

import HubApp from '@src/features/HubApp/HubApp'
import { APPLICATIONS, globalSettingsAppTabsFilter } from '@src/constants/hub'
import { useGetDocumentsSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'

import { DocumentsBulkRequest } from './BulkRequest'
import { DocumentsTemplate } from './Template'

const Documents = () => {
  const { data: documentsSettings } = useGetDocumentsSettings()

  const tabs = APPLICATIONS.documents.tabs.filter(tab =>
    globalSettingsAppTabsFilter(tab.homeSectionId, { documentsSettings }),
  )

  return (
    <Switch>
      <Route path={ROUTES.APPS.DOCUMENTS.BULK_REQUEST.ANY}>
        <DocumentsBulkRequest />
      </Route>
      <Route
        path={[
          ROUTES.APPS.DOCUMENTS.TEMPLATES.EDIT.ANY,
          ROUTES.APPS.DOCUMENTS.E_SIGNATURE_TEMPLATES.EDIT.ANY,
        ]}
      >
        <DocumentsTemplate />
      </Route>
      <Route path={ROUTES.APPS.DOCUMENTS.ANY}>
        <HubApp app={{ ...APPLICATIONS.documents, tabs }} />
      </Route>
    </Switch>
  )
}

export default Documents
