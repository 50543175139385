import React from 'react'
import { Link } from 'react-router-dom'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import kebabCase from 'lodash/kebabCase'
import { Button } from '@revolut/ui-kit'

type Props = {
  positionTitle: string
  positionId: string
}

const CareersPositionApplyButton = ({ positionTitle, positionId }: Props) => {
  return (
    <Button
      use={Link}
      // @ts-expect-error object works fine here, but UI kit expects string
      to={getLocationDescriptor(
        pathToUrl(PUBLIC_ROUTES.CAREERS.APPLICATION, {
          id: `${kebabCase(positionTitle)}-${positionId}`,
        }),
      )}
      variant="black"
      size="sm"
    >
      Apply for this role
    </Button>
  )
}

export default CareersPositionApplyButton
