import { Pencil } from '@revolut/icons'
import {
  ActionButton,
  Group,
  HStack,
  ProgressStep,
  ProgressStepSkeleton,
  ProgressSteps,
  Text,
  chain,
} from '@revolut/ui-kit'
import { usePayCycle } from '@src/api/payroll'
import { getStatusColor } from '@src/components/CommonSC/General'
import SideBar from '@src/components/SideBar/SideBar'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { Statuses } from '@src/interfaces'
import { formatDate, formatPeriod } from '@src/utils/format'
import React from 'react'
import { toLower } from 'lodash'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PayCycleInterface, PayCyclePreviewInterface } from '@src/interfaces/payroll'

type Props = {
  isOpen: boolean
  onClose: () => void
  payCycleId?: string
  cyclePreview?: PayCyclePreviewInterface
}

type Step = {
  id: string
  name: string
  value: Date
  state?: 'default' | 'pending' | 'done' | 'failed' | undefined
}

const formatSteps = (steps: Step[]) => {
  const sortedSteps = steps.sort(
    (a, b) => new Date(a.value).getTime() - new Date(b.value).getTime(),
  )

  let foundPending = false

  return sortedSteps
    .map(step => {
      if (!step.value) {
        return false
      }
      if (!foundPending && new Date(step.value) > new Date()) {
        foundPending = true
        return {
          ...step,
          state: 'pending' as const,
        }
      }
      if (!foundPending) {
        return {
          ...step,
          state: 'done' as const,
        }
      }
      return step
    })
    .filter(Boolean)
}

const TimelineSidebar = ({ isOpen, onClose, payCycleId, cyclePreview }: Props) => {
  const { data: newCycle, isLoading } = usePayCycle(payCycleId || null)
  const cycle = (newCycle || cyclePreview) as PayCycleInterface

  const steps: Step[] = !cycle
    ? []
    : formatSteps([
        { id: 'start_date', name: 'Pay Cycle Starts', value: cycle.start_date },
        {
          id: 'pay_period_second_start_date',
          name: 'Second Start Date',
          value: cycle.pay_period_second_start_date,
        },
        { id: 'end_date', name: 'End Date', value: cycle.end_date },
        { id: 'cut_off_date', name: 'Cut Off Date', value: cycle.cut_off_date },
        { id: 'submission_date', name: 'Submission Date', value: cycle.submission_date },
        {
          id: 'pay_authorities_date',
          name: 'Pay Authorities Date',
          value: cycle.pay_authorities_date,
        },
        { id: 'pay_date', name: 'Pay Date', value: cycle.pay_date },
        {
          id: 'pay_date_second_date',
          name: 'Pay Date Second Date',
          value: cycle.pay_date_second_date,
        },
      ])

  const editDisabled = cycle?.status?.id === 'open' || cycle?.status?.id === 'closed'

  return (
    <>
      <SideBar
        variant="wide"
        title="Timeline"
        subtitle={
          !!cycle &&
          !!cycle.status &&
          chain(
            formatPeriod(cycle.start_date, cycle.end_date),
            <Text color={getStatusColor(cycle?.status?.id as Statuses)}>
              {cycle.status.name}
            </Text>,
          )
        }
        onClose={onClose}
        isOpen={isOpen}
      >
        <HStack>
          <Tooltip
            text={
              editDisabled
                ? `Pay schedule for ${toLower(
                    cycle?.status.name,
                  )} cycles cannot be changed`
                : undefined
            }
            placement="right"
          >
            {!!payCycleId && (
              <ActionButton
                use={InternalLink}
                to={pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.TIMELINE, { id: payCycleId })}
                useIcon={Pencil}
                disabled={editDisabled}
                mb="s-16"
              >
                Edit
              </ActionButton>
            )}
          </Tooltip>
        </HStack>

        <Group>
          <ProgressSteps variant="vertical-compact">
            {isLoading ? (
              <>
                <ProgressStepSkeleton />
                <ProgressStepSkeleton />
                <ProgressStepSkeleton />
              </>
            ) : (
              steps.map(step => (
                <ProgressStep
                  state={step.state}
                  key={step.id}
                  data-testid={`${step.id}-step-container`}
                >
                  <ProgressStep.Title>{step.name}</ProgressStep.Title>
                  <ProgressStep.Description>
                    {formatDate(step.value)}
                  </ProgressStep.Description>
                </ProgressStep>
              ))
            )}
          </ProgressSteps>
        </Group>
      </SideBar>
    </>
  )
}

export default TimelineSidebar
