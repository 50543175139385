import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLape } from 'lape'
import * as yup from 'yup'
import { Box, MoreBar } from '@revolut/ui-kit'
import { postPerformanceReviewForSummary } from '@src/api/performanceReview'
import { SCROLL_ERROR_HASH } from '@src/constants/performance'
import {
  ReviewDataInterface,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import AutoStepper from '@components/Stepper/AutoStepper'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import PageLoading from '@components/PageLoading/PageLoading'
import PreviousOverallFeedback from '@components/ScorecardGeneral/PreviousOverallFeedback'
import ScorecardRecommendation from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { useLapeContext } from '@src/features/Form/LapeForm'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import ProbationEditSummarySidebar from '@src/pages/Forms/Probation/ProbationEditSummarySidebar'
import { overallFeedbackValidation } from '@src/utils/performance'

export const validator = {
  review_data: yup.object().shape({
    overall_feedback: yup
      .object()
      .shape<any>({
        pros: yup.array().of(yup.string()),
        cons: yup.array().of(yup.string()),
      })
      .test(
        'overall_feedback',
        'Overall feedback is obligatory. You cannot submit review without it.',
        overallFeedbackValidation,
      ),
  }),
}

const Summary = () => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, errors, change } = form
  const state = useLape<{ data?: ReviewDataInterface }>({
    data: undefined,
  })
  const [prosElement, setProsElement] = useState<HTMLTextAreaElement | null>(null)
  const [isOpenSidebar, setIsOpenSidebar] = useState(true)
  const { hash } = useLocation()

  const fetchSummary = async () => {
    let cleanValues = values

    // TODO: this should not be required
    // @ts-ignore
    cleanValues.review_data.deliverables = null
    const result = await postPerformanceReviewForSummary(cleanValues, values.category)
    state.data = result.data
  }

  useEffect(() => {
    fetchSummary()

    if (!values.review_data.overall_feedback) {
      change('review_data.overall_feedback', {
        pros: [],
        cons: [],
      })
    }

    if (values.visible_to_reviewee === null) {
      change('visible_to_reviewee', false)
    }
  }, [])

  useEffect(() => {
    if (hash === SCROLL_ERROR_HASH && errors.review_data?.overall_feedback) {
      prosElement && prosElement.focus()
    }
  }, [hash])

  if (!state.data) {
    return <PageLoading />
  }

  return (
    <>
      <Box>
        <MoreBar>
          <MoreBar.Action
            onClick={() => {
              setIsOpenSidebar(true)
            }}
          >
            Open summary sidebar
          </MoreBar.Action>
        </MoreBar>

        {values.review_data.pip_extra_section && (
          <ScorecardRecommendation
            data={values.review_data.pip_extra_section!}
            type="pip"
          />
        )}
        <AutoStepper width={628}>
          <AutoStepperTitle noAutoStep title="Overall feedback" variant="letter" />
        </AutoStepper>
        <OverallFeedback
          cons={values.review_data.overall_feedback?.cons}
          pros={values.review_data.overall_feedback?.pros}
          onGetProsElement={elem => {
            setProsElement(elem)
          }}
          onChangePros={val => {
            change('review_data.overall_feedback.pros', val.split('\n'))
          }}
          onChangeCons={val => {
            change('review_data.overall_feedback.cons', val.split('\n'))
          }}
        />
        <PreviousOverallFeedback data={values.review_data.previous_reviews_by_cycle} />
      </Box>
      <ProbationEditSummarySidebar
        data={state.data}
        isOpen={isOpenSidebar}
        onClose={() => {
          setIsOpenSidebar(false)
        }}
      />
    </>
  )
}

export default Summary
