import React, { useState } from 'react'
import { Group, Box, MoreBar, Input } from '@revolut/ui-kit'
import { Archive, Unarchive, CrossSmall } from '@revolut/icons'

import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { BenchmarkInterface } from '@src/interfaces/benchmarks'
import { formatMoney } from '@src/utils/format'
import { PageBody } from '@src/components/Page/PageBody'
import SettingsButtons, {
  ApproveButton,
  DeleteButton,
} from '@src/features/SettingsButtons'
import {
  approveBenchmark,
  benchmarkRequests,
  changeBenchmarkStatus,
  getBenchmarkCommentsAPI,
  rejectBenchmark,
  useBenchmarkApprovals,
} from '@src/api/benchmarks'
import { ROUTES } from '@src/constants/routes'
import { EntityPermissions } from '@src/store/auth/types'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { Statuses } from '@src/interfaces'
import { handleError } from '@src/api'
import CommentsSection from '@src/features/Comments/CommentsSection'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'
import { pathToUrl } from '@src/utils/router'
import { useGetOrganisationSettings } from '@src/api/settings'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

const Preview = () => {
  const { values, initialValues, reset } = useLapeContext<BenchmarkInterface>()

  const [isRejectPending, setIsRejectPending] = useState(false)
  const [changeStatusPending, setChangeStatusPending] = useState(false)
  const [rejectionComment, setRejectionComment] = useState('')
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)

  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useBenchmarkApprovals(values.id)

  const { data: settings } = useGetOrganisationSettings()

  const commentsApi = getBenchmarkCommentsAPI(values.id)
  const getComments = commentsApi.useGetComments(false)

  const onApprove = async () => {
    const response = await approveBenchmark(values.id)
    reset(response.data)
  }

  const onReject = async () => {
    setIsRejectPending(true)

    try {
      const response = await rejectBenchmark(values.id, rejectionComment)
      reset(response.data)
    } finally {
      setRejectionComment('')
      setRejectDialogOpen(false)
      refetchApproval()
      setIsRejectPending(false)
      getComments.refetch()
    }
  }

  const onChangeStatus = async () => {
    setChangeStatusPending(true)
    changeBenchmarkStatus(values.id, values.is_archived ? 'unarchive' : 'archive')
      .then(result => reset(result.data))
      .finally(() => setChangeStatusPending(false))
  }

  const isPendingApproval = values.status?.id === Statuses.pending_approval

  const canApproveOrReject =
    isPendingApproval &&
    values.field_options?.actions?.includes(EntityPermissions.Approve)

  return (
    <>
      <PageBody>
        <SettingsButtons mb="s-32">
          <ApproveButton
            submit={onApprove}
            onAfterSubmit={refetchApproval}
            onSubmitFailed={handleError}
            statusFieldName="approval_status"
            isVisible={canApproveOrReject}
          />
          {canApproveOrReject && (
            <MoreBar.Action
              onClick={() => setRejectDialogOpen(true)}
              useIcon={CrossSmall}
              variant="negative"
              pending={isRejectPending}
            >
              Reject
            </MoreBar.Action>
          )}
          <EditButton
            route={pathToUrl(ROUTES.FORMS.BENCHMARK.GENERAL, { id: values.id })}
          />
          <DeleteButton
            title="compensation band"
            deleteApi={benchmarkRequests.delete!}
            backUrl={ROUTES.PEOPLE.COMPENSATION.BANDS}
          />
          <MoreBar.Action
            onClick={onChangeStatus}
            useIcon={values.is_archived ? Unarchive : Archive}
            variant={values.is_archived ? 'primary' : 'negative'}
            pending={changeStatusPending}
          >
            {values.is_archived ? 'Unarchive' : 'Archive'}
          </MoreBar.Action>
        </SettingsButtons>

        {approvalSteps === undefined || approvalSteps?.length ? (
          <Box mb="s-16">
            <ApprovalFlow isLoading={isApprovalLoading} steps={approvalSteps || null} />
          </Box>
        ) : null}

        <FormPreview data={initialValues}>
          <Group>
            <FormPreview.Item title="Specialisation" field="specialisation.name" />
            <FormPreview.Item title="Seniority" field="seniority.name" />
            <FormPreview.Item
              title="Seniority Level"
              field="specialisation_seniority_sublevel.name"
              hidden={!settings?.enable_multiple_levels_per_seniority}
            />
            <FormPreview.Item title="Location" field="location.name" />
            <FormPreview.Item title="Type" field="benchmark_type.name" />
            <HideIfCommercial>
              <FormPreview.Item<BenchmarkInterface>
                title="Tags"
                insert={data => data.tags?.map(tag => tag.name).join(', ') || '-'}
              />
            </HideIfCommercial>
            <FormPreview.Item<BenchmarkInterface>
              title="Lower band"
              insert={data => formatMoney(data.lower_band, data.currency.iso_code)}
            />
            <FormPreview.Item<BenchmarkInterface>
              title="Upper band"
              insert={data => formatMoney(data.upper_band, data.currency.iso_code)}
            />
          </Group>

          <Box mt="s-16">
            <FormPreview.Details title="Additional notes" field="note" />
          </Box>
        </FormPreview>

        <Box mt="s-16">
          <CommentsSection api={commentsApi} disableTodolistFeature />
        </Box>
      </PageBody>

      <ConfirmationDialog
        open={rejectDialogOpen}
        onClose={() => setRejectDialogOpen(false)}
        onConfirm={onReject}
        loading={isRejectPending}
        onReject={() => setRejectDialogOpen(false)}
        label="Please enter rejection reason"
        body={
          <Input
            label="Add reason here"
            value={rejectionComment}
            onChange={e => setRejectionComment(e.currentTarget.value)}
          />
        }
        yesMessage="Reject"
        noMessage="Go back"
      />
    </>
  )
}

export default Preview
