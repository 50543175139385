import { API } from '@src/constants/api'
import { GetRequestInterface, IdAndName, tableRequests } from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import {
  ExternalEmployeeInterface,
  ImportExternalEmployeesInterface,
  ImportExternalEmployeesMetadata,
} from '@src/interfaces/importExternalEmployees'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { AxiosPromise } from 'axios'
import { api, apiWithoutHandling } from '.'
import {
  ImportKPIsData,
  ImportKPIsMeta,
  ImportKPIsSessionStats,
} from '@src/interfaces/importKPIs'
import { useFetch } from '@src/utils/reactQuery'
import {
  ImportDepartmentBudgetInterface,
  ImportDepartmentBudgetMetadata,
  ImportDepartmentBudgetSessionInterface,
} from '@src/interfaces/importDepartmentBudget'
import { getChangePercent } from './compensation'
import {
  ImportBonusesInterface,
  ImportBonusesMetadata,
  ImportBonusesSessionInterface,
} from '@src/interfaces/importBonuses'
import { CompensationBonusInterface } from '@src/interfaces/compensation'
import { ImportSessionStats } from '@src/interfaces/importData'
import {
  PayCyclePaymentsBulkUploadInterface,
  PayCyclePaymentsBulkUploadStatsInterface,
} from '@src/interfaces/payroll'
import { uploadFile } from './files'
import {
  ImportDocumentsItemInterface,
  ImportDocumentsSessionInterface,
  ImportDocumentsSessionStatsInterface,
} from '@src/interfaces/importDocuments'
import {
  ImportTimeOffRequestsInterface,
  ImportTimeOffRequestsSessionInterface,
} from '@src/interfaces/importTimeOffRequests'
import {
  ImportTimeOffBalancesInterface,
  ImportTimeOffBalancesSessionInterface,
} from '@src/interfaces/importTimeOffBalances'
import {
  ImportBandsInterface,
  ImportBandsMetadataInterface,
  ImportBandsSessionInterface,
} from '@src/interfaces/importCompensationBands'

/** External employees */

export const importExternalEmployees = (file: File) => {
  return api.post<ImportExternalEmployeesInterface>('/employees/bulkUpload', file, {
    headers: {
      'Content-Type': 'text/csv',
    },
  })
}

export const confirmImportExternalEmployees = (sessionId: number) =>
  api.put(`/employees/bulkUpload/${sessionId}`)

export const getExternalEmployeesForSession = (
  requestData: FetchDataQueryInterface,
  sessionId: number,
): AxiosPromise<
  GetRequestInterface<ExternalEmployeeInterface, ImportExternalEmployeesMetadata>
> => {
  const { sortBy, filters, page } = requestData
  return api.get(`/employees/bulkUpload/${sessionId}`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })
}

export const exportSessionData = (sessionId: number) => {
  return api.get<string>(`/employees/bulkUpload/${sessionId}/csv`)
}

/** KPIs */

export const importKPIs = (file: File) => {
  const formData = new FormData()
  formData.append('file', file, file.name)

  return api.post('/kpis/bulkUpload', file, {
    headers: {
      'Content-Type': 'text/csv',
    },
  })
}

export const useGetKPIsUploadSessionStats = (sessionId: number | string) => {
  return useFetch<ImportKPIsSessionStats>(`/kpis/bulkUpload/${sessionId}/stats`)
}

export const getKPIsUploadSessionDataRequest = (
  sessionId: number | string,
): tableRequests<ImportKPIsData, undefined, ImportKPIsMeta>['getItems'] => {
  return () => api.get(`/kpis/bulkUpload/${sessionId}`)
}

export const confirmKPIsUpload = (sessionId: number | string) => {
  return api.put(`/kpis/bulkUpload/${sessionId}`)
}

export const deleteKPIsUploadSession = (sessionId: number | string) => {
  return api.delete(`/kpis/bulkUpload/${sessionId}`)
}

export const kpiUploadTemplateURL =
  'https://storage.googleapis.com/revoluters-public-files/kpis_bulk_upload_template.csv'

/** Compensation reviews */

export const importDepartmentBudget = (id: string | number, file: File) => {
  const formData = new FormData()
  formData.append('file', file, file.name)

  return api.post(
    `${API.DEPARTMENT_COMPENSATION_REVIEWS}/${id}/employeeCompensationReviews/bulkUpload`,
    file,
    {
      headers: {
        'Content-Type': 'text/csv',
      },
    },
  )
}

export const confirmImportDepartmentBudget = (id: string, sessionId: string) =>
  api.put<ImportDepartmentBudgetSessionInterface>(
    `${API.DEPARTMENT_COMPENSATION_REVIEWS}/${id}/employeeCompensationReviews/bulkUpload/${sessionId}`,
  )

const getDataWithChanges = (
  data: ImportDepartmentBudgetInterface[],
): ImportDepartmentBudgetInterface[] => {
  return data.map(row => ({
    ...row,
    salary_change:
      row.salary_amount_new == null || row.salary_amount == null
        ? null
        : Number(row.salary_amount_new) - row.salary_amount,
    salary_change_percent:
      row.salary_amount == null || row.salary_amount_new == null
        ? null
        : getChangePercent(row.salary_amount, Number(row.salary_amount_new)),
    bonus_change:
      row.bonus_amount_new == null
        ? null
        : Number(row.bonus_amount_new) - (row.bonus_amount || 0),
  }))
}

export const getDepermentBudgetSessionData =
  (
    id: number | string,
    sessionId: number | string,
  ): tableRequests<
    ImportDepartmentBudgetInterface,
    undefined,
    ImportDepartmentBudgetMetadata
  >['getItems'] =>
  () =>
    api
      .get<
        GetRequestInterface<
          ImportDepartmentBudgetInterface,
          ImportDepartmentBudgetMetadata
        >
      >(
        `${API.DEPARTMENT_COMPENSATION_REVIEWS}/${id}/employeeCompensationReviews/bulkUpload/${sessionId}`,
      )
      .then(response => ({
        ...response,
        data: {
          ...response.data,
          results: getDataWithChanges(response.data.results),
        },
      }))

export const exportDepermentBudgetSessionData = (id: string, sessionId: string) => {
  return api.get<string>(
    `${API.DEPARTMENT_COMPENSATION_REVIEWS}/${id}/employeeCompensationReviews/bulkUpload/${sessionId}/csv`,
  )
}

export const cancelImportDepartmentBudget = (id: string, sessionId: string) =>
  api.delete(
    `${API.DEPARTMENT_COMPENSATION_REVIEWS}/${id}/employeeCompensationReviews/bulkUpload/${sessionId}`,
  )

export const useImportDepartmentBudgetSessionStats = (id: string, sessionId?: string) => {
  return useFetch<ImportSessionStats>(
    sessionId
      ? `${API.DEPARTMENT_COMPENSATION_REVIEWS}/${id}/employeeCompensationReviews/bulkUpload/${sessionId}/stats`
      : null,
  )
}

export const departmentBudgetUploadTemplateURL =
  'https://storage.googleapis.com/revoluters-public-files/employee_compensation_review_bulk_upload_template.csv'

/** Bonuses */

export const importBonuses = (file: File) => {
  const formData = new FormData()
  formData.append('file', file, file.name)

  return api.post(`${API.EMPLOYEE_BONUSES}/bulkUpload`, file, {
    headers: {
      'Content-Type': 'text/csv',
    },
  })
}

export const getBonusesSessionData =
  (
    id: number | string,
  ): tableRequests<
    ImportBonusesInterface,
    undefined,
    ImportBonusesMetadata
  >['getItems'] =>
  () =>
    api.get<GetRequestInterface<ImportBonusesInterface, ImportBonusesMetadata>>(
      `${API.EMPLOYEE_BONUSES}/bulkUpload/${id}`,
    )

export const useImportBonusesSessionStats = (id: string) => {
  return useFetch<ImportSessionStats>(`${API.EMPLOYEE_BONUSES}/bulkUpload/${id}/stats`)
}

export const confirmImportBonuses = (id: string) =>
  api.put<ImportBonusesSessionInterface>(`${API.EMPLOYEE_BONUSES}/bulkUpload/${id}`)

export const cancelImportBonuses = (id: string) =>
  api.delete(`${API.EMPLOYEE_BONUSES}/bulkUpload/${id}`)

export const exportBonusesSessionData = (id: string) => {
  return api.get<string>(`${API.EMPLOYEE_BONUSES}/bulkUpload/${id}/csv`)
}

export const bonusesUploadTemplateURL =
  'https://storage.googleapis.com/revoluters-public-files/employee_bonus_bulk_upload_template.csv'

export const getEmployeeBonuses = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<CompensationBonusInterface>
> => {
  return api.get(API.EMPLOYEE_BONUSES, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })
}

/** Payroll cycles */

export const uploadPayrollCyclePayments = (payCycleId: number | string, file: File) => {
  const formData = new FormData()
  formData.append('file', file, file.name)

  return api.post(`${API.PAYROLL_CYCLES}/${payCycleId}/payments/bulkUpload`, file, {
    headers: {
      'Content-Type': 'text/csv',
    },
  })
}

export const uploadPaycycleFile = (payCycleId: number | string, file: File) => {
  const formData = new FormData()
  formData.append('file', file, file.name)

  return api.post(`${API.PAYROLL_CYCLES}/${payCycleId}/fileForm`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': `attachment`,
    },
  })
}

export const triggerPayrollCyclePaymentsUpload = (
  payCycleId: number | string,
  sessionId: number | string,
) => {
  return api.put(`${API.PAYROLL_CYCLES}/${payCycleId}/payments/bulkUpload/${sessionId}`)
}

export const getPayrollCyclePaymentsUploadProgress = (
  payCycleId: number | string,
  sessionId: number | string,
) =>
  api.get<PayCyclePaymentsBulkUploadInterface>(
    `${API.PAYROLL_CYCLES}/${payCycleId}/payments/bulkUpload/${sessionId}`,
  )

export const usePayrollCyclePaymentsUploadStats = (
  payCycleId: number | string,
  sessionId: number | string,
) => {
  return useFetch<PayCyclePaymentsBulkUploadStatsInterface>(
    sessionId
      ? `${API.PAYROLL_CYCLES}/${payCycleId}/payments/bulkUpload/${sessionId}/stats`
      : null,
  )
}

/** Employees */

export const getImportExternalEmployeesTemplateUrl = async () => {
  const res = await api.get<Blob>(`/employees/bulkUpload/template`, {
    responseType: 'blob',
  })
  return URL.createObjectURL(res.data)
}

/** Documents */

export const importDocumentsFile = async (
  sessionId: number,
  file: File,
  fileNameOverride?: string,
) => {
  const uploadResult = await uploadFile(
    file,
    'document_upload',
    true,
    undefined,
    fileNameOverride,
  )

  return api.post<{ id: number }>(`${API.DOCUMENTS_UPLOADS_BULK}/${sessionId}/addFile`, {
    id: uploadResult.data.id,
  })
}

export const createDocumentUploadSession = () =>
  api.post<{ id: number }>(API.DOCUMENTS_UPLOADS_BULK)

export const getDocumentUploadSessionTable =
  (sessionId: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<ImportDocumentsItemInterface>
  > => {
    return api.get(`${API.DOCUMENTS_UPLOADS_BULK}/${sessionId}/items`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
  }

export const getDocumentUploadSessionTableStats =
  (sessionId: string) =>
  ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<ImportDocumentsSessionStatsInterface>(
      `${API.DOCUMENTS_UPLOADS_BULK}/${sessionId}/stats`,
      { params: filterSortPageIntoQuery(sortBy, filters, page) },
    )

export const updateDocumentUploadSessionItem = (
  sessionId: string,
  itemId: number,
  data: Partial<ImportDocumentsItemInterface>,
) =>
  apiWithoutHandling.patch<ImportDocumentsItemInterface>(
    `${API.DOCUMENTS_UPLOADS_BULK}/${sessionId}/items/${itemId}`,
    data,
  )

export const deleteDocumentUploadSessionItem = (sessionId: string, itemId: number) =>
  apiWithoutHandling.delete<ImportDocumentsItemInterface>(
    `${API.DOCUMENTS_UPLOADS_BULK}/${sessionId}/items/${itemId}`,
  )

export const createDocumentUploadSessionDocuments = (sessionId: string) =>
  apiWithoutHandling.post(`${API.DOCUMENTS_UPLOADS_BULK}/${sessionId}/createDocuments`)

export const useGetDocumentUploadSessionData = (sessionId: string) => {
  const data = useFetch<ImportDocumentsSessionInterface>(
    `${API.DOCUMENTS_UPLOADS_BULK}/${sessionId}`,
    undefined,
    undefined,
    true,
    {
      refetchInterval: session => (session?.state.id === 'applying' ? 1000 : false),
    },
  )

  return data
}

export const useDocumentSessionSelector = (sessionId: string) => {
  const result = useFetch<{ options: IdAndName[] }>(
    `${API.DOCUMENTS_UPLOADS_BULK}/${sessionId}/selector`,
  )
  return { options: result.data?.options || [], refetch: result.refetch }
}

export const bulkDeleteDocuments = (sessionId: string, data: { id: number }[]) =>
  apiWithoutHandling.post(`${API.DOCUMENTS_UPLOADS_BULK}/${sessionId}/bulkDelete`, data)

export const bulkUpdateDocuments = (
  sessionId: string,
  data: {
    id: number
    employee?: { id: number }
    category?: { id: number }
    name?: string
  }[],
) =>
  apiWithoutHandling.post(`${API.DOCUMENTS_UPLOADS_BULK}/${sessionId}/bulkUpdate`, data)

/** Time off requests */

export const importTimeOffRequestsFile = async (file: File) => {
  const uploadResult = await uploadFile(file, 'time_off_request_bulk_upload', true, api)

  return api.post<{ id: number }>(API.TIME_OFF_REQUESTS_BULK_UPLOAD, {
    file: { id: uploadResult.data.id },
  })
}

export const getTimeOffRequestsTemplateUrl = async () => {
  const res = await api.get<Blob>(`${API.TIME_OFF_REQUESTS_BULK_UPLOAD}/template`, {
    responseType: 'blob',
  })
  return URL.createObjectURL(res.data)
}

export const useGetImporTimeOffRequestsSessionData = (id: string) => {
  const data = useFetch<ImportTimeOffRequestsSessionInterface>(
    `${API.TIME_OFF_REQUESTS_BULK_UPLOAD}/${id}`,
    undefined,
    undefined,
    true,
    {
      refetchInterval: session =>
        session?.state.id === 'pending' ||
        session?.state.id === 'processing_file' ||
        session?.state.id === 'applying'
          ? 1000
          : false,
    },
  )

  return data
}

export const getTimeOffRequestsUploadSessionTable =
  (id: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<ImportTimeOffRequestsInterface>
  > => {
    return api.get(`${API.TIME_OFF_REQUESTS_BULK_UPLOAD}/${id}/items`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
  }

export const deleteImportTimeOffRequestsSessionRow = (sessionId: string, rowId: number) =>
  api.delete(`${API.TIME_OFF_REQUESTS_BULK_UPLOAD}/${sessionId}/items/${rowId}`)

export const editImportTimeOffRequestsSessionRow = (
  sessionId: string,
  rowId: number,
  data: ImportTimeOffRequestsInterface,
) =>
  api.patch<ImportTimeOffRequestsInterface>(
    `${API.TIME_OFF_REQUESTS_BULK_UPLOAD}/${sessionId}/items/${rowId}`,
    data,
  )

export const applyImportTimeOffRequestsSession = (sessionId: string) =>
  api.post(`${API.TIME_OFF_REQUESTS_BULK_UPLOAD}/${sessionId}/apply`)

/** Time off balances */

export const importTimeOffBalancesFile = async (file: File) => {
  const uploadResult = await uploadFile(file, 'time_off_balance_bulk_upload', true, api)

  return api.post<{ id: number }>(API.TIME_OFF_BALANCES_BULK_UPLOAD, {
    file: { id: uploadResult.data.id },
  })
}

export const getTimeOffBalancesTemplateUrl = async () => {
  const res = await api.get<Blob>(`${API.TIME_OFF_BALANCES_BULK_UPLOAD}/template`, {
    responseType: 'blob',
  })
  return URL.createObjectURL(res.data)
}

export const useGetImporTimeOffBalanceSessionData = (id: string) => {
  const data = useFetch<ImportTimeOffBalancesSessionInterface>(
    `${API.TIME_OFF_BALANCES_BULK_UPLOAD}/${id}`,
    undefined,
    undefined,
    true,
    {
      refetchInterval: session =>
        session?.state.id === 'pending' ||
        session?.state.id === 'processing_file' ||
        session?.state.id === 'applying'
          ? 1000
          : false,
    },
  )

  return data
}

export const getTimeOffBalancesUploadSessionTable =
  (id: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<ImportTimeOffBalancesInterface>
  > => {
    return api.get(`${API.TIME_OFF_BALANCES_BULK_UPLOAD}/${id}/items`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
  }

export const deleteImportTimeOffBalancesSessionRow = (sessionId: string, rowId: number) =>
  api.delete(`${API.TIME_OFF_BALANCES_BULK_UPLOAD}/${sessionId}/items/${rowId}`)

export const editImportTimeOffBalancesSessionRow = (
  sessionId: string,
  rowId: number,
  data: ImportTimeOffBalancesInterface,
) =>
  api.patch<ImportTimeOffBalancesInterface>(
    `${API.TIME_OFF_BALANCES_BULK_UPLOAD}/${sessionId}/items/${rowId}`,
    data,
  )

export const applyImportTimeOffBalancesSession = (sessionId: string) =>
  api.post(`${API.TIME_OFF_BALANCES_BULK_UPLOAD}/${sessionId}/apply`)

export const getCompensationBandsTemplateUrl = async () => {
  const res = await api.get<Blob>(`${API.BENCHMARKS}/bulkUpload/template`, {
    responseType: 'blob',
  })
  return URL.createObjectURL(res.data)
}

export const importCompensationBands = (file: File) => {
  const formData = new FormData()
  formData.append('file', file, file.name)

  return api.post(`${API.BENCHMARKS}/bulkUpload`, file, {
    headers: {
      'Content-Type': 'text/csv',
    },
  })
}

export const confirmImportCompensationBands = (id: string) =>
  api.put<ImportBandsSessionInterface>(`${API.BENCHMARKS}/bulkUpload/${id}`)

export const getCompensationBandsUploadSession =
  (id: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<ImportBandsInterface, ImportBandsMetadataInterface>
  > => {
    return api.get(`${API.BENCHMARKS}/bulkUpload/${id}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
  }

export const exportCompensationBandsUploadSessionData = (id: string) => {
  return api.get<string>(`${API.BENCHMARKS}/bulkUpload/${id}/csv`)
}

export const useGetCompensationBandsUploadSessionData = (id: string) => {
  const data = useFetch<ImportBandsSessionInterface>(
    `${API.BENCHMARKS}/bulkUpload/${id}`,
    undefined,
    undefined,
    true,
    {
      refetchInterval: session => (session?.status.id === 'in_progress' ? 5000 : false),
    },
  )

  return data
}
