import { API } from '@src/constants/api'
import { DiversitySurveyInterface } from '@src/interfaces/diversitySurvey'
import { api, apiWithoutHandling } from '@src/api'
import { decodeToken } from '@src/utils/decodeToken'
import { OptionInterface } from '@src/interfaces/selectors'

export const getGenderSelector = async () => {
  const res = await api.get<OptionInterface[]>(API.APPLICANT_GENDERS)
  return res.data
}

export const getEthnicitySelector = async () => {
  const res = await api.get<OptionInterface[]>(API.APPLICANT_ETHNICITIES)
  return res.data
}

export const getOrientationSelector = async () => {
  const res = await api.get<OptionInterface[]>(API.APPLICANT_SEXUAL_ORIENTATIONS)
  return res.data
}

export const getDisabilititySelector = async () => {
  const res = await api.get<OptionInterface[]>(API.APPLICANT_DISABILITIES)
  return res.data
}

export const submitDiversitySurvey = (data: DiversitySurveyInterface, token: string) =>
  apiWithoutHandling.post(API.APPLICANT_DIVERSITY_SURVEY, data, {
    headers: {
      apitoken: decodeToken(token),
    },
  })
