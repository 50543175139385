import React from 'react'
import { Cell, Group, Text, textChain, VStack } from '@revolut/ui-kit'
import { CareersApplicationEducationInterface } from '@src/interfaces/careers'
import { formatStatus } from '@src/utils/string'
import CollapsibleSubheader from '@components/CollapsibleSubheader/CollapsibleSubheader'
import { formatExperienceDateRange } from '@src/utils/hiring'

type Props = {
  data?: CareersApplicationEducationInterface[]
}

const EducationInfo = ({ data }: Props) => {
  if (!data?.length) {
    return null
  }

  return (
    <CollapsibleSubheader title="Education" openByDefault>
      <Group>
        <Cell>
          <VStack gap="s-4">
            {data.map((item, i) => (
              <Text display="block" key={i}>
                {textChain(
                  item.university?.name,
                  item.major?.name,
                  formatStatus(item.degree),
                  item.gpa ? `${item.gpa} gpa` : undefined,
                  formatExperienceDateRange(item.start_date, item.end_date),
                )}
              </Text>
            ))}
          </VStack>
        </Cell>
      </Group>
    </CollapsibleSubheader>
  )
}

export default EducationInfo
