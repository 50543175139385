import { useMemo } from 'react'

import { LocalStorageKeys } from '@src/store/auth/types'
import { TabBarNavigationNames } from '@src/constants/tabBarNavigation'
import { ConfigurableTabBarNavigationInterface, TabBarSettingsInterface } from './types'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

export const useTabBarSettings = (
  name: TabBarNavigationNames,
): TabBarSettingsInterface | undefined => {
  return useMemo(() => {
    try {
      const storedSettings = JSON.parse(
        workspaceLocalStorage.getItem(
          LocalStorageKeys.NAVIGATION_TABS_SETTINGS.replace('{}', name),
        ) || '{}',
      )
      if (!storedSettings?.reordered?.length) {
        return undefined
      }
      return storedSettings
    } catch (error) {
      console.warn(error)
      return undefined
    }
  }, [name])
}

export const getTabBarBySettings = (
  name: TabBarNavigationNames,
  settings: TabBarSettingsInterface | undefined,
  tabs: ConfigurableTabBarNavigationInterface[],
): ConfigurableTabBarNavigationInterface[] => {
  if (!settings?.reordered) {
    return tabs
  }
  let orderedTabs: ConfigurableTabBarNavigationInterface[] = []
  let unorderedTabs: ConfigurableTabBarNavigationInterface[] = []

  settings.reordered.forEach(tabKey => {
    const tab = tabs.find(t => t.key === tabKey)
    if (tab) {
      orderedTabs.push(tab)
    }
  })
  if (settings.reordered.length < tabs.length) {
    unorderedTabs = tabs.filter(t => !orderedTabs.some(ot => ot.key === t.key))
  }
  const reorderedTabs = [...orderedTabs, ...unorderedTabs]

  if (!reorderedTabs.length) {
    console.warn('Could not load tabs configuration settings')

    workspaceLocalStorage.removeItem(
      LocalStorageKeys.NAVIGATION_TABS_SETTINGS.replace('{}', name),
    )

    return tabs
  }
  return reorderedTabs
}
