import React, { MouseEvent, useState } from 'react'
import { Action, ActionProps } from '@revolut/ui-kit'

interface LoadingActionProps extends ActionProps {
  onSubmit: () => Promise<any>
}

const LoadingAction = ({ onSubmit, ...rest }: LoadingActionProps) => {
  const [loading, setLoading] = useState(false)
  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    try {
      setLoading(true)
      await onSubmit()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Action style={{ fontSize: 13 }} {...rest} pending={loading} onClick={handleClick} />
  )
}

export default LoadingAction
