import { CheckSuccess, RadiobuttonOff } from '@revolut/icons'
import { TabBar } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import React, { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import TabBarLink from '@components/TabBarLink/TabBarLink'

type ProgressTabBarProps = {
  tabs: { title: string; route: string }[]
  canNavigate?: boolean
  checkAll?: boolean
}

const ProgressTabBar = ({
  tabs,
  canNavigate = true,
  checkAll = false,
}: ProgressTabBarProps) => {
  const location = useLocation()
  const { id } = useParams<{ id?: string }>()

  const routeWithId = (route: string) => pathToUrl(route, { id: id || 'new' })

  const tabsForRender = useMemo(() => {
    const activeTabIndex = tabs.findIndex(
      tab => routeWithId(tab.route) === location.pathname,
    )
    return tabs.map((tab, index) => ({
      title: tab.title,
      route: tab.route,
      isCompleted: checkAll || index < activeTabIndex,
    }))
  }, [location])

  return (
    <TabBar my="s-12">
      {tabsForRender.map(tab => (
        <TabBarLink
          key={tab.title}
          name={tab.title}
          useIcon={
            tab.isCompleted ? <CheckSuccess color="green" size={20} /> : RadiobuttonOff
          }
          to={canNavigate && tab.isCompleted ? routeWithId(tab.route) : '#'}
          aria-selected={routeWithId(tab.route) === location.pathname}
          disabled={routeWithId(tab.route) !== location.pathname && !canNavigate}
        >
          {tab.title}
        </TabBarLink>
      ))}
    </TabBar>
  )
}

export default ProgressTabBar
