import { Color, Flex, IconButton } from '@revolut/ui-kit'
import React from 'react'
import { ThumbsOptions } from '@src/interfaces/interviewTool'
import { LikeDislike, SocialDislike, SocialLike } from '@revolut/icons'
import { RevolutTheme, useTheme } from '@src/styles/theme'
import { getColor } from '@src/styles/colors'
import Tooltip from '@components/Tooltip/Tooltip'
import { IconButtonProps } from '@revolut/ui-kit/types/dist/components/IconButton/IconButton'

interface Props extends Omit<IconButtonProps, 'onClick' | 'useIcon'> {
  value: ThumbsOptions
  onClick: (value: ThumbsOptions) => void
  disabled?: boolean
  selected?: boolean
}

const Icon = {
  [ThumbsOptions.Yes]: SocialLike,
  [ThumbsOptions.YesWithHelp]: LikeDislike,
  [ThumbsOptions.No]: SocialDislike,
}

const TooltipText = {
  [ThumbsOptions.Yes]: 'Yes',
  [ThumbsOptions.YesWithHelp]: 'Yes, with help',
  [ThumbsOptions.No]: 'No',
}

const getColors = (value: ThumbsOptions, theme: RevolutTheme, selected?: boolean) => {
  let color
  let bgColor

  if (selected) {
    switch (value) {
      case ThumbsOptions.Yes:
        color = getColor(theme, Color.TEAL)
        bgColor = getColor(theme, Color.TEAL_5)
        break
      case ThumbsOptions.YesWithHelp:
        color = getColor(theme, Color.FOREGROUND)
        break
      case ThumbsOptions.No:
        color = getColor(theme, Color.RED)
        bgColor = getColor(theme, Color.RED_5)
        break
    }
  }

  return { color, bgColor }
}

const ThumbsButton = ({ value, onClick, selected, disabled, ...buttonProps }: Props) => {
  const theme = useTheme()

  const { color, bgColor } = getColors(value, theme, selected)

  return (
    <Tooltip placement="top" text={TooltipText[value]}>
      <Flex
        borderStyle="solid"
        borderColor={color || 'grey-tone-20'}
        borderWidth={1}
        borderRadius={4}
        width={28}
        height={28}
        justifyContent="center"
        alignItems="center"
        backgroundColor={bgColor}
      >
        <IconButton
          useIcon={Icon[value]}
          size={14}
          tapArea={28}
          color={color || 'grey-tone-50'}
          onClick={() => onClick(value)}
          disabled={disabled}
          {...buttonProps}
        />
      </Flex>
    </Tooltip>
  )
}

export default ThumbsButton
