import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { DepartmentInterface } from '@src/interfaces/deparment'
import {
  ColoredPercent,
  getFunctionNIPSColor,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import React from 'react'
import AuditCircles from '../../components/ColumnInserts/AuditCircles/AuditCircles'
import { TeamInterface } from '@src/interfaces/teams'
import { formatMoney, formatNumber } from '@src/utils/format'
import CultureCircles from '@components/ColumnInserts/CultureCircles/CultureCircles'
import { getColor } from '@src/styles/colors'
import CXCircles from '@src/components/ColumnInserts/CXCircles/CXCircles'
import { Color } from '@revolut/ui-kit'
import { getStatusColor } from '@src/components/CommonSC/General'
import { CommunicationIcons } from '@src/features/CommunicationGroups/CommunicationIcons'
import { FunctionInterface } from '@src/interfaces/functions'

export const departmentGenericNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const departmentNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'department.id',
  dataPoint: 'department.name',
  sortKey: 'department__name',
  filterKey: 'department__id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const departmentHeadcountColumn: ColumnInterface<
  DepartmentInterface | TeamInterface
> = {
  type: CellTypes.text,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: 'headcount',
  filterKey: 'headcount',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  colors: (data: DepartmentInterface | TeamInterface, theme) =>
    getColor(theme, data.headcount ? Color.FOREGROUND : Color.GREY_20_OPAQUE_90),
  title: 'Headcount',
}

export const nipsColumn: ColumnInterface<{ nips?: number }> = {
  type: CellTypes.insert,
  idPoint: 'nips',
  dataPoint: 'nips',
  sortKey: 'nips',
  filterKey: 'nips',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.none,
  insert: ({ data, theme }) => {
    if (data.nips !== undefined) {
      const NIPS = formatNumber(data.nips * 100, 1)
      return <ColoredPercent percent={NIPS} color={getFunctionNIPSColor(theme, NIPS)} />
    }

    return null
  },
  title: 'Nips',
}

export const departmentRequisitionsColumn: ColumnInterface<
  DepartmentInterface | TeamInterface
> = {
  type: CellTypes.text,
  idPoint: 'requisition_remaining_headcount',
  dataPoint: 'requisition_remaining_headcount',
  sortKey: 'requisition_remaining_headcount',
  filterKey: 'requisition_remaining_headcount',
  filterType: FilterType.range,
  colors: (data: DepartmentInterface | TeamInterface, theme) =>
    getColor(
      theme,
      data.requisition_remaining_headcount ? Color.FOREGROUND : Color.GREY_20_OPAQUE_90,
    ),
  selectorsKey: selectorKeys.none,
  title: 'Reqs',
}

export const departmentPerformanceColumn: ColumnInterface<
  DepartmentInterface | TeamInterface
> = {
  type: CellTypes.insert,
  idPoint: 'performance',
  insert: ({ data }) => <ColoredPercent percent={data.performance * 100} />,
  dataPoint: 'performance',
  sortKey: 'performance',
  filterKey: 'performance',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.performance,
  title: 'Performance',
}

export const departmentKPIColumn: ColumnInterface<DepartmentInterface | TeamInterface> = {
  type: CellTypes.insert,
  idPoint: 'kpi_performance_percent',
  insert: ({ data }) => <ColoredPercent percent={data.kpi_performance_percent * 100} />,
  dataPoint: 'kpi_performance_percent',
  sortKey: 'kpi_performance_percent',
  filterKey: 'kpi_performance_percent',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.kpi_performance_percent,
  title: 'KPI',
}

export const departmentRoadmapColumn: ColumnInterface<
  DepartmentInterface | TeamInterface
> = {
  type: CellTypes.insert,
  idPoint: 'roadmap_progress_percent',
  insert: ({ data }) => <ColoredPercent percent={data.roadmap_progress_percent * 100} />,
  dataPoint: 'roadmap_progress_percent',
  sortKey: 'roadmap_progress_percent',
  filterKey: 'roadmap_progress_percent',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.roadmap_progress_percent,
  title: 'Roadmap',
}

export const departmentCXColumn: ColumnInterface<DepartmentInterface | TeamInterface> = {
  type: CellTypes.insert,
  idPoint: 'cx_complaints_last_30_days',
  insert: ({ data }) => <CXCircles data={data} />,
  dataPoint: 'cx_complaints_last_30_days',
  sortKey: 'cx_complaints_last_30_days',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'CX',
}

export const departmentAuditColumn: ColumnInterface<DepartmentInterface | TeamInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'audit',
    dataPoint: 'audit',
    sortKey: 'audits_critical',
    filterKey: null,
    insert: ({ data }) => {
      return <AuditCircles data={data} />
    },
    selectorsKey: selectorKeys.none,
    title: 'Findings',
  }

export const departmentBudgetColumn: ColumnInterface<
  DepartmentInterface | TeamInterface
> = {
  type: CellTypes.insert,
  idPoint: 'total_current_spent',
  dataPoint: 'total_current_spent',
  sortKey: 'total_current_spent',
  filterKey: 'total_current_spent',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.team,
  insert: ({ data }) => {
    if (typeof data.total_current_spent !== 'number') {
      return '-'
    }

    return formatMoney(data.total_current_spent, data.budget_currency)
  },
  textAlign: 'right',
  title: 'Salary/mo',
}

export const departmentStatusColumn: ColumnInterface<DepartmentInterface> = {
  type: CellTypes.text,
  idPoint: 'approval_status',
  dataPoint: 'approval_status',
  sortKey: 'approval_status',
  filterKey: 'approval_status',
  selectorsKey: selectorKeys.department_approval_status,
  title: 'Status',
  colors: data => getStatusColor(data.approval_status),
}

export const departmentCultureColumn: ColumnInterface<
  DepartmentInterface | TeamInterface
> = {
  type: CellTypes.insert,
  idPoint: 'culture_enps',
  dataPoint: 'culture_enps',
  sortKey: 'percentile_score',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => <CultureCircles data={data} />,
  title: 'Culture',
}

export const departmentNameRevolutersColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'department.id',
  dataPoint: 'department.name',
  sortKey: 'team__department__name',
  filterKey: 'team__department__id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const departmentCommunicationColumn: ColumnInterface<
  DepartmentInterface | TeamInterface | FunctionInterface
> = {
  type: CellTypes.insert,
  idPoint: 'communication_group.id',
  dataPoint: 'communication_group.id',
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => <CommunicationIcons group={data.communication_group} />,
  selectorsKey: selectorKeys.none,
  title: 'Communication',
}
