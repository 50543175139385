import React from 'react'
import { HStack, TextButton, Text, Color } from '@revolut/ui-kit'
import { Chat, EyeHide } from '@revolut/icons'
import { EngagementResultInterface } from '@src/interfaces/engagement'
import { useQuery } from '@src/utils/queryParamsHooks'
import { QueryParams } from '../constants'

type Props = {
  data: EngagementResultInterface
}
export const InsertCommentsNode = ({ data }: Props) => {
  const query = useQuery()

  if (!data.can_show_details) {
    return <EyeHide size={14} color={Color.GREY_TONE_50} />
  }
  const hasNoComments = !data.number_of_answers_with_comment

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (hasNoComments) {
      return
    }
    if (data.driver && data.question) {
      query.changeQueryParam(QueryParams.questionComments, String(data.question.id))
    } else if (data.driver) {
      query.changeQueryParam(QueryParams.driverComments, String(data.driver.id))
    }
  }

  return (
    <TextButton color={hasNoComments ? Color.GREY_20 : undefined} onClick={handleClick}>
      <HStack align="center">
        <Text use="div" mr="s-8">
          {data.number_of_answers_with_comment}
        </Text>
        <Chat size={16} />
      </HStack>
    </TextButton>
  )
}
