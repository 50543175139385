import React from 'react'
import { Link as A } from '@revolut/ui-kit'
import { Link } from '@revolut/icons'

interface Props {
  to: string
}

const LinkIcon = ({ to }: Props) => {
  return (
    <A
      onClick={e => e.stopPropagation()}
      href={to}
      target="_blank"
      display="block"
      width={16}
      rel="noreferrer noopener"
    >
      <Link color="blue" hoverColor="blue-opaque-70" size={16} />
    </A>
  )
}

export default LinkIcon
