import { Token } from '@revolut/ui-kit'
import { IconComponentType } from '@revolut/icons'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'
import { GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import { APPLICATIONS } from '@src/constants/hub'
import { ROUTES } from '@src/constants/routes'
import { useGlobalSettings } from '@src/api/settings'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { OnboardingCheckpointCategory } from '@src/interfaces/onboardingChecklist'
import { useGetOnboardingCheckpoints } from '@src/api/onboardingChecklist'

export interface OnboardingChecklistSectionConfig {
  path: string
  title: string
  category: OnboardingCheckpointCategory
  canView: PermissionTypes[]
  description?: React.ReactNode
  icon?: IconComponentType
  customIcon?: React.ReactNode
  image?: string
  bg?: string
  customImage?: {
    useIcon?: ResolveIconType
    color?: string
    image?: string
    bg?: string
  }
  globalSetting?: GlobalSettings
  popup: {
    description: string
  }
}

export const upgradeYourPlanConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.PLANS.SELECT_PLAN,
  title: 'Complete payment details',
  category: 'upgradeToPaidPlan',
  canView: [],
  customImage: {
    useIcon: 'Card',
    color: Token.color.background,
    bg: Token.color.foreground,
  },
  description: 'Provide your billing information to begin your onboarding journey',
  popup: { description: '' },
}

export const initialConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.INITIAL.INTRO,
  title: 'Initial setup',
  category: 'initialSetup',
  canView: [PermissionTypes.ViewOrganisationPreferences],
  image: 'settings',
  bg: 'deep-grey',
  description:
    'Configure the general settings and tell us which parts of the platform you want to use',
  popup: {
    description:
      'Your initial setup is completed. Don’t worry, you can always update it later.',
  },
}

export const employeesConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.EMPLOYEES.INTRO,
  title: 'Import employees data',
  category: 'importEmployees',
  canView: [PermissionTypes.AddEmployeeUpload],
  image: APPLICATIONS.employees.image,
  bg: APPLICATIONS.employees.bg,
  description: 'Import basic information about your employees.',
  popup: {
    description:
      'Your initial setup is completed. Don’t worry, you can always update it later.',
  },
}

export const teamsConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.TEAMS.INTRO,
  title: 'Department & Teams',
  category: 'departmentsTeams',
  canView: [PermissionTypes.ViewOrganisationPreferences],
  image: APPLICATIONS.teams.image,
  bg: APPLICATIONS.teams.bg,
  description: 'Configure departments & teams details',
  popup: {
    description:
      'Your team structure is set and ready to use. Don’t worry, you can always update it later in Teams app.',
  },
}

export const rolesConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.ROLES.INTRO,
  title: 'Roles',
  category: 'roles',
  canView: [PermissionTypes.ViewOrganisationPreferences],
  image: APPLICATIONS.positions.image,
  bg: APPLICATIONS.positions.bg,
  description: 'Configure the roles details',
  popup: {
    description:
      'Your Organisation structure is set and ready to use. Don’t worry, you can always update it later in Organisation app.',
  },
}

export const locationsConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.LOCATIONS.INTRO,
  title: 'Countries and Legal Entities',
  category: 'countries',
  canView: [PermissionTypes.ViewOrganisationPreferences],
  image: APPLICATIONS.locations.image,
  bg: APPLICATIONS.locations.bg,
  description: 'Configure countries and legal entities',
  popup: {
    description:
      'Your Legal Entities & Countries are set and ready to use. Don’t worry, you can always update it later.',
  },
}

export const requisitionsConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.INTRO,
  title: 'Requisitions',
  category: 'requisitions',
  canView: [PermissionTypes.ViewRequisitionPreferences],
  image: APPLICATIONS.requisitions.image,
  bg: APPLICATIONS.requisitions.bg,
  globalSetting: GlobalSettings.RequisitionsEnabled,
  description: 'Configure open positions within the company.',
  popup: {
    description:
      'Requisitions are set and ready to use. Don’t worry, you can always update it later in Requisitions app',
  },
}

export const jobPostingsConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.INTRO,
  category: 'jobPostings',
  title: 'Job postings',
  canView: [PermissionTypes.ViewJobPostingPreferences],
  image: APPLICATIONS.jobPostings.image,
  bg: APPLICATIONS.jobPostings.bg,
  globalSetting: GlobalSettings.JobPostingsEnabled,
  description: 'Configure job postings and their associated application forms',
  popup: {
    description:
      'Job postings are set and ready to use. Don’t worry, you can always update it later in Job postings app',
  },
}

export const performanceConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.INTRO,
  category: 'performance',
  title: 'Performance',
  canView: [PermissionTypes.ViewPerformancePreferences],
  image: APPLICATIONS.performanceReview.image,
  bg: APPLICATIONS.performanceReview.bg,
  description:
    'Set up your performance cycle timeline and configure your assessment style',
  popup: {
    description:
      'Your performance is set and ready to use. Don’t worry, you can always update it later in Performance app',
  },
}
export const kpiConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.KPI.INTRO,
  category: 'goals',
  title: 'KPIs',
  canView: [PermissionTypes.ViewPerformancePreferences],
  image: APPLICATIONS.kpis.image,
  bg: APPLICATIONS.kpis.bg,
  description:
    'Create goals and targets in the organisation from company to individual level',
  popup: {
    description:
      'Your KPIs are set and ready to use. Don’t worry, you can always update it later in the KPIs app',
  },
}

export const skillsAndValuesConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.SKILLS.INTRO,
  category: 'skills',
  title: 'Skills and Values',
  canView: [PermissionTypes.ViewSkillsPreferences],
  image: APPLICATIONS.skills.image,
  bg: APPLICATIONS.skills.bg,
  description:
    'Manage the skills which define your roles and add your company’s cultural values',
  popup: {
    description:
      'Your skills and values are set and ready to use. Don’t worry, you can always update it later in the Skills & Values app',
  },
}

export const roadmapsConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.ROADMAPS.INTRO,
  category: 'roadmaps',
  title: 'Roadmaps',
  canView: [PermissionTypes.ViewPerformancePreferences],
  image: APPLICATIONS.roadmaps.image,
  bg: APPLICATIONS.roadmaps.bg,
  description: 'Build delivery roadmaps to guide your organisational projects',
  popup: {
    description:
      'Your Roadmaps are set and ready to use. Don’t worry, you can always update it later in the Roadmaps app',
  },
}

export const probationConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.PROBATION.INTRO,
  category: 'probation',
  title: 'Probation',
  canView: [PermissionTypes.ViewProbationEmployees],
  image: 'probation',
  bg: APPLICATIONS.performanceReview.bg,
  description: 'Define probation rules',
  popup: {
    description:
      'Your probation cycles are set and ready to use. Don’t worry, you can always update it later in the Performance app',
  },
}

export const documentsConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.DOCUMENTS.INTRO,
  category: 'documents',
  title: 'Documents',
  canView: [PermissionTypes.UploadDocuments],
  image: APPLICATIONS.documents.image,
  bg: APPLICATIONS.documents.bg,
  description: 'Import your existing employees documents',
  popup: {
    // TODO: update when designers add copy
    description: '',
  },
}

export const timeOffConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.INTRO,
  category: 'timeOff',
  title: 'Time off',
  canView: [
    PermissionTypes.ViewTimeOffPolicies,
    PermissionTypes.ViewTimeOffRegimes,
    PermissionTypes.ViewTimeOffPolicyCategory,
  ],
  image: APPLICATIONS.timeOff.image,
  bg: APPLICATIONS.timeOff.bg,
  description: 'Set up yearly time off and import absences',
  popup: {
    description:
      'Your time off structure is set and ready to use. Don’t worry, you can always update it later in time off app',
  },
}

export const contractsConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.CONTRACTS.INTRO,
  category: 'contracts',
  title: 'Contracts setup',
  canView: [PermissionTypes.ViewContractsPreferences],
  image: APPLICATIONS.contracts.image,
  bg: APPLICATIONS.contracts.bg,
  description: 'Configure your contracts',
  popup: {
    description: "We've imported all your contracts!",
  },
}

export const candidatesConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.INTRO,
  category: 'candidates',
  title: 'Candidates',
  canView: [PermissionTypes.ViewCandidatePreferences],
  image: APPLICATIONS.candidates.image,
  bg: APPLICATIONS.candidates.bg,
  globalSetting: GlobalSettings.CandidatesEnabled,
  description:
    'Set the re-application period for candidates, establish communication methods, and upload existing candidate profiles',
  popup: {
    description:
      'Candidates are set and ready to use. Don’t worry, you can always update it later in Candidates app',
  },
}

export const hiringProcessConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.HIRING_PROCESS.INTRO,
  category: 'hiring',
  title: 'Hiring process',
  canView: [PermissionTypes.ViewHiringProcessPreferences],
  image: APPLICATIONS.hiringProcess.image,
  bg: APPLICATIONS.hiringProcess.bg,
  description: 'Manage your hiring process',
  popup: {
    description:
      'Hiring Process is set and ready to use. Don’t worry, you can always update it later',
  },
}

export const careersPageConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.CAREERS_PAGE.INTRO,
  category: 'careers',
  title: 'Careers website',
  canView: [PermissionTypes.ViewCareerPagePreferences],
  image: APPLICATIONS.careerPageSettings.image,
  bg: APPLICATIONS.careerPageSettings.bg,
  description: 'Create, personalise and publish your custom careers page',
  popup: {
    description:
      'Careers page is set and ready to use. Don’t worry, you can always update it later',
  },
}

export const lifecycleConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.LIFECYCLE.INTRO,
  category: 'lifecycle',
  title: 'Lifecycle setup',
  canView: [PermissionTypes.ViewLifecycleSettings],
  image: APPLICATIONS.lifecycle.image,
  bg: APPLICATIONS.lifecycle.bg,
  description: 'Configure your lifecycle process',
  popup: {
    description:
      'Your lifecycle is set and ready to use. Don’t worry, you can always update it later in the lifecycle app',
  },
}

export const payrollConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.PAYROLL.INTRO,
  category: 'payroll',
  title: 'Payroll setup',
  canView: [PermissionTypes.ViewPayrollPreferences],
  image: APPLICATIONS.payroll.image,
  bg: APPLICATIONS.payroll.bg,
  description: 'Configure your payroll process',
  popup: {
    description:
      'Your payroll is set and ready to use. Don’t worry, you can always update it later in the payroll app',
  },
}

export const finishConfig: OnboardingChecklistSectionConfig = {
  path: ROUTES.ONBOARDING_CHECKLIST.FINISH.SETTINGS,
  category: 'finish',
  title: 'Finish Onboarding',
  canView: [PermissionTypes.ViewOrganisationPreferences],
  customImage: {
    image: 'https://assets.revolut.com/assets/categories/Rocket.png',
  },
  description: `All set to launch? Confirm your setup, invite your team, and let's start the journey together!`,
  popup: {
    description: '',
  },
}

export const onboardingCheckpointsOrder: {
  category: OnboardingCheckpointCategory
  config: OnboardingChecklistSectionConfig | null
}[] = [
  { category: 'upgradeToPaidPlan', config: upgradeYourPlanConfig },
  { category: 'initialSetup', config: initialConfig },
  { category: 'importEmployees', config: employeesConfig },
  { category: 'departmentsTeams', config: teamsConfig },
  { category: 'roles', config: rolesConfig },
  { category: 'countries', config: locationsConfig },
  { category: 'timeOff', config: timeOffConfig },
  { category: 'documents', config: documentsConfig },
  { category: 'contracts', config: contractsConfig },
  { category: 'lifecycle', config: lifecycleConfig },
  { category: 'payroll', config: payrollConfig },
  { category: 'performance', config: performanceConfig },
  { category: 'skills', config: skillsAndValuesConfig },
  { category: 'goals', config: kpiConfig },
  { category: 'roadmaps', config: roadmapsConfig },
  { category: 'probation', config: probationConfig },
  { category: 'requisitions', config: requisitionsConfig },
  { category: 'jobPostings', config: jobPostingsConfig },
  { category: 'hiring', config: hiringProcessConfig },
  { category: 'careers', config: careersPageConfig },
  { category: 'candidates', config: candidatesConfig },
  { category: 'finish', config: finishConfig },
]

export const onboardingCheckpointsSectionConfig = {
  initialSetup: {
    title: 'General Setup',
    config: [upgradeYourPlanConfig, initialConfig],
  },
  organisation: {
    title: 'Organization',
    config: [employeesConfig, teamsConfig, rolesConfig, locationsConfig],
  },
  people: {
    title: 'People configuration',
    config: [
      timeOffConfig,
      documentsConfig,
      contractsConfig,
      lifecycleConfig,
      payrollConfig,
    ],
  },
  performance: {
    title: 'Performance',
    config: [
      performanceConfig,
      skillsAndValuesConfig,
      kpiConfig,
      roadmapsConfig,
      probationConfig,
    ],
  },
  recruitment: {
    title: 'Recruitment',
    config: [
      requisitionsConfig,
      jobPostingsConfig,
      hiringProcessConfig,
      careersPageConfig,
      candidatesConfig,
    ],
  },
  finish: {
    title: 'Ready to take off?',
    config: [finishConfig],
  },
}

export const useFilterConfigs = () => {
  const permissions = useSelector(selectPermissions)
  const { settings } = useGlobalSettings()
  const { checkpoints } = useGetOnboardingCheckpoints()

  return (configs: OnboardingChecklistSectionConfig[]) => {
    return configs.filter(config => {
      /** Entering payment method is the primary step, they should always see it */
      if (config.category === 'upgradeToPaidPlan') {
        return true
      }

      const currentCheckpointData = checkpoints?.find(c => c.category === config.category)
      const isVisible = currentCheckpointData
        ? currentCheckpointData.state.id !== 'hidden'
        : false
      const hasConfigDefined = !!onboardingCheckpointsOrder.find(
        c => c.category === config.category && !!c.config,
      )
      const hasPermissions = config.canView.every(permission =>
        permissions.includes(permission),
      )
      const settingIsEnabled = config.globalSetting
        ? // @ts-ignore
          settings[config.globalSetting]
        : true
      return hasPermissions && settingIsEnabled && isVisible && hasConfigDefined
    })
  }
}

export const useNextConfig = (
  config: OnboardingChecklistSectionConfig,
): OnboardingChecklistSectionConfig | undefined => {
  const filterConfigs = useFilterConfigs()
  const currentConfigIndex = onboardingCheckpointsOrder.findIndex(
    c => c.category === config.category,
  )
  const nextConfigs = onboardingCheckpointsOrder
    .slice(currentConfigIndex + 1)
    .map(c => c.config)
    .filter(Boolean)
  return filterConfigs(nextConfigs)[0]
}
