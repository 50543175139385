import React, { useEffect, useState } from 'react'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  employeeNameColumn,
  employeeStatusColumn,
  employeeTypeColumn,
  lineManagerColumn,
  qualityControlRevolutersColumn,
} from '@src/constants/columns/employee'
import { teamNameColumn } from '@src/constants/columns/team'
import { specialisationRoleNameColumn } from '@src/constants/columns/role'
import { seniorityNameRevolutersColumn } from '@src/constants/columns/seniority'
import { locationNameColumn } from '@src/constants/columns/location'
import { startedAtColumn } from '@src/constants/columns/dates'
import {
  getRevolutersExport,
  getRevolutersItems,
  getRevolutersStatistics,
} from '@src/api/revoluters'
import { RevolutersInterface, RevolutersStatisticsDTO } from '@src/interfaces/revoluters'
import { FilterButton, MoreBar, TableWidget, TextButton } from '@revolut/ui-kit'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { Pencil, Plus, ShareIOs } from '@revolut/icons'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectAuth, selectPermissions, selectUser } from '@src/store/auth/selectors'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { departmentNameRevolutersColumn } from '@src/constants/columns/department'
import SearchTable from '@src/components/Table/SearchTable/SearchTable'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetEmployeeSettings } from '@src/api/settings'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'
import { withFavourites } from '@src/features/FavouritesFilter/withFavourites'
import { StatFilters } from '@components/StatFilters/StatFilters'
import { capitalize } from 'lodash'
import useIsCommercial from '@src/hooks/useIsCommercial'
import {
  SHOW_EXTERNAL_STAT_FILTERS,
  StatFilterId,
  getDefaultStatsFilter,
  getEmployeeTypeFilters,
  hasEmployeeTypeFilter,
} from './filters'

export const STATUSES: { [key in StatFilterId]: string } = {
  active_employees: 'active',
  pending_employees: 'pending',
  onboarding_employees: 'onboarding',
  hired_employees: 'hired',
  active_employees_external: 'active',
}

const Row: RowInterface<RevolutersInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.id })),
  cells: [
    {
      ...employeeNameColumn,
      width: 200,
    },
    {
      ...employeeTypeColumn,
      width: 110,
    },
    {
      ...departmentNameRevolutersColumn,
      width: 160,
    },
    {
      ...teamNameColumn,
      width: 220,
    },
    {
      ...specialisationRoleNameColumn,
      width: 200,
    },
    {
      ...seniorityNameRevolutersColumn,
      width: 110,
    },
    {
      ...lineManagerColumn,
      width: 200,
    },
    {
      ...qualityControlRevolutersColumn,
      width: 200,
    },
    {
      ...locationNameColumn,
      width: 120,
    },
    {
      ...startedAtColumn,
      width: 120,
    },
    {
      ...employeeStatusColumn,
      width: 120,
    },
  ],
}

const EmployeeTable = () => {
  const user = useSelector(selectUser)
  const { subscriptionState } = useSelector(selectAuth)
  const isAccountClosing = subscriptionState === 'closing'

  const [selectedStatFilter, setSelectedStatFilter] = useState<StatFilterId | undefined>(
    getDefaultStatsFilter(),
  )
  const [includeExternal, setIncludeExternal] = useState(!hasEmployeeTypeFilter())

  const [showMyReports, setShowMyReports] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_REPORTS,
    false,
  )
  const { data: employeeSettings } = useGetEmployeeSettings()
  const { FavouritesFilter, initialFilter: initialFavouritesFilter } =
    useFavouritesFilter('employee')
  const isCommercial = useIsCommercial()

  const getFilterByLineManager = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'line_manager__id',
    nonResettable: true,
  })

  const sortBy: any = [
    {
      sortBy: 'status',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
    {
      sortBy: 'employee_type',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
    {
      sortBy: 'full_name',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  const generateFilters = (id?: StatFilterId) => {
    const filters = []

    if (id) {
      filters.push(getEmployeeTypeFilters(id, includeExternal))
      const status = STATUSES[id]
      filters.push({
        filters: [
          {
            name: capitalize(status),
            id: status,
          },
        ],
        columnName: 'status',
        nonResettable: false,
      })
    }

    if (showMyReports) {
      filters.push(getFilterByLineManager(true))
    }

    if (initialFavouritesFilter) {
      filters.push(initialFavouritesFilter)
    }

    return filters
  }

  const table = useTable<RevolutersInterface>(
    { getItems: getRevolutersItems },
    generateFilters(getDefaultStatsFilter()),
    sortBy,
  )
  const onFilterChanged: typeof table.onFilterChange = (...args) => {
    const [filter] = args
    const isStatusOrTypeFilter = ({ columnName }: FilterByInterface) =>
      columnName === 'employee_type' || columnName === 'status'
    if (
      (Array.isArray(filter) && filter.find(isStatusOrTypeFilter)) ||
      (!Array.isArray(filter) && isStatusOrTypeFilter(filter))
    ) {
      setSelectedStatFilter(undefined)
    }

    return table.onFilterChange(...args)
  }
  const permissions = useSelector(selectPermissions)
  const [stats, setStats] = useState<RevolutersStatisticsDTO>()

  const canAdd = permissions.includes(PermissionTypes.AddEmployees)
  const canImportExternalEmployees = permissions.includes(
    PermissionTypes.AddEmployeeUploadingSession,
  )
  const canImportEmployees = permissions.includes(PermissionTypes.AddEmployeeUpload)

  const canExport = !!employeeSettings?.enable_export_csv_xls
  const canBulkEdit = permissions.includes(PermissionTypes.BulkEditEmployees)

  const onToggleMyReports = () => {
    setShowMyReports(!showMyReports)
    table.onFilterChange(getFilterByLineManager(!showMyReports))
  }

  useEffect(() => {
    fetchEmployeeStatistics()
  }, [])

  useEffect(() => {
    statFiltersOnClick(selectedStatFilter)
  }, [includeExternal])

  const fetchEmployeeStatistics = async () => {
    const result = await getRevolutersStatistics()

    if (result.data) {
      setStats(result.data)
    }
  }

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const StatFiltersConfig = [
    {
      id: 'active_employees',
      title: 'Active',
      value: stats?.active_employees,
    },
    {
      id: 'pending_employees',
      title: 'Pending',
      value: stats?.pending_employees,
    },
    {
      id: 'onboarding_employees',
      title: 'Onboarding',
      value: stats?.onboarding_employees,
    },
    {
      id: 'hired_employees',
      title: 'Hired',
      value: stats?.hired_employees,
    },
    {
      id: 'active_employees_external',
      title: 'External',
      value: stats?.active_employees_external,
    },
  ]

  const statFiltersOnClick = (id?: StatFilterId) => {
    const filters = generateFilters(id)
    table.onFilterChange(filters)
    setSelectedStatFilter(id)
    return filters
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <StatFilters
          filters={StatFiltersConfig}
          onClick={id => statFiltersOnClick(id as StatFilterId)}
          selectedFilter={selectedStatFilter}
        />
      </TableWidget.Info>
      <TableWidget.Search>
        <SearchTable
          placeholder="Search by name or title"
          onFilter={table.onFilterChange}
        />
      </TableWidget.Search>
      <TableWidget.Actions>
        <MoreBar>
          {canAdd && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.NEW)}
              useIcon={Plus}
              disabled={isAccountClosing}
            >
              Add Employee
            </MoreBar.Action>
          )}
          {!!employeeSettings?.enable_import_data && (
            <>
              {canImportExternalEmployees && (
                <MoreBar.Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.EXTERNAL_EMPLOYEES.UPLOAD_FILE)}
                  useIcon={ShareIOs}
                  disabled={isAccountClosing}
                >
                  Import external employees
                </MoreBar.Action>
              )}
              {canImportEmployees && (
                <MoreBar.Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.EMPLOYEES.UPLOAD_FILE)}
                  useIcon={ShareIOs}
                  disabled={isAccountClosing}
                >
                  Import employees
                </MoreBar.Action>
              )}
            </>
          )}
          {canExport && (
            <ExportMenu
              fileName="Employees"
              request={getRevolutersExport}
              filterQuery={filterQuery}
            />
          )}
          {canBulkEdit && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.BULK_EDIT_EMPLOYEES.SELECT)}
              useIcon={Pencil}
            >
              Edit
            </MoreBar.Action>
          )}
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Filters>
        {isCommercial &&
          selectedStatFilter &&
          SHOW_EXTERNAL_STAT_FILTERS.includes(selectedStatFilter) && (
            <FilterButton
              onClick={() => {
                setIncludeExternal(prev => !prev)
              }}
              active={includeExternal}
            >
              {includeExternal ? 'Exclude external' : 'Include external'}
            </FilterButton>
          )}
        <FilterButton onClick={onToggleMyReports} active={showMyReports}>
          My reports
        </FilterButton>
        <FavouritesFilter table={table} />
      </TableWidget.Filters>
      <TableWidget.Table>
        <AdjustableTable<RevolutersInterface>
          name={TableNames.Employees}
          useWindowScroll
          dataType="Employee"
          row={Row}
          {...table}
          onFilterChange={onFilterChanged}
          noDataMessage={
            showMyReports ? (
              <>
                No reports found.{' '}
                <TextButton onClick={onToggleMyReports}>
                  Click here to remove this filter.
                </TextButton>
              </>
            ) : (
              'Employees will appear here.'
            )
          }
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export default withFavourites(EmployeeTable)
