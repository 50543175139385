import React from 'react'
import { VStack, Link, Cell, Text, Flex, Button } from '@revolut/ui-kit'
import { LinkExternal } from '@revolut/icons'
import { CONTACT_PERFORMANCE_TEAM_LINK, KPI_FAQ_LINK } from '@src/constants/externalLinks'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import SideBar from '@components/SideBar/SideBar'
import { StyledLI, StyledUL } from '@components/CommonSC/General'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const InstructionsSidebar = ({ isOpen, onClose }: Props) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  return (
    <SideBar isOpen={isOpen} onClose={onClose} title="KPI step-by-step">
      <Flex pb="s-24">
        <HideIfCommercial>
          <Button
            useIcon={LinkExternal}
            size="sm"
            variant="secondary"
            use="a"
            href={KPI_FAQ_LINK}
            target="_blank"
          >
            View FAQs
          </Button>
        </HideIfCommercial>
      </Flex>
      <VStack space="s-16">
        <ActionWidget
          data-testid="warning_on_creation"
          title="After creating this KPI, you may not have the permission to edit it."
          text={
            isCommercial ? undefined : (
              <>
                Please contact your KPI Champion or the{' '}
                <Link href={CONTACT_PERFORMANCE_TEAM_LINK}>Performance Service Desk</Link>{' '}
                for assistance if required.
              </>
            )
          }
        />
        <Cell display="block">
          <Text variant="primary" pb="s-8" use="p">
            1. Define your KPI
          </Text>
          <Text use="p" pl="s-8">
            <StyledUL lineHeight="22px">
              <StyledLI>
                Create a name which is clear for anyone to understand, and a description
                which is clear and concise.
              </StyledLI>
              <StyledLI>
                Think about which higher level KPI (department or team for example) you
                are supporting.
              </StyledLI>
            </StyledUL>
          </Text>
        </Cell>
        <Cell display="block">
          <Text variant="primary" pb="s-8" use="p">
            2. If your KPI is SQL, input the query
          </Text>
          <Text use="p" pl="s-8">
            <StyledUL lineHeight="22px">
              <StyledLI>
                Make sure you select either the Helios or People Ops database with the
                blue selector.
              </StyledLI>
              <StyledLI>Provide your query into the space provided.</StyledLI>
              <StyledLI>
                Remember that this page is not a SQL testing environment, so complex
                queries may time out.
              </StyledLI>
            </StyledUL>
          </Text>
        </Cell>
        <Cell display="block">
          <Text variant="primary" pb="s-8" use="p">
            3. Set your targets
          </Text>
          <Text use="p" pl="s-8">
            <StyledUL lineHeight="22px">
              <StyledLI>
                A KPI can have targets across multiple quarters, so first select the
                review cycle you want to apply this target to.
              </StyledLI>
              <StyledLI>
                If the KPI is SQL, then define the appropriate direction
                (increase/decrease for example), as well as your initial and target
                values.
              </StyledLI>
              <StyledLI>
                If the KPI is roadmap, you will need to select the linked epics with the
                deliverables input.
              </StyledLI>
            </StyledUL>
          </Text>
        </Cell>
        <Cell display="block">
          <Text variant="primary" pb="s-8" use="p">
            4. Select the parent KPI
          </Text>
          <Text use="p" pl="s-8">
            <StyledUL lineHeight="22px">
              <StyledLI>
                All KPIs should have a relationship with a higher level KPI.
              </StyledLI>
              <StyledLI>
                This ensures that goals within organisational areas are aligned.
              </StyledLI>
              <StyledLI>
                Use the input to search and select the appropriate parent KPI which your
                KPI is supporting.
              </StyledLI>
              <StyledLI>
                This should be one level higher and linked to the business goal of the
                KPI.
              </StyledLI>
            </StyledUL>
          </Text>
        </Cell>
        <Cell display="block">
          <Text variant="primary" pb="s-8" use="p">
            5. Submit for approval
          </Text>
          <Text use="p" pl="s-8">
            <StyledUL lineHeight="22px">
              <StyledLI>
                All KPIs should have a relationship with a higher level KPI. This ensures
                that goals within organisational areas are aligned.
              </StyledLI>
              <StyledLI>
                Use the input to search and select the appropriate parent KPI which your
                KPI is supporting. This should be one level higher and linked to the
                business goal of the KPI.
              </StyledLI>
            </StyledUL>
          </Text>
        </Cell>
      </VStack>
    </SideBar>
  )
}

export default InstructionsSidebar
