import React, { useState } from 'react'
import { connect } from 'lape'
import capitalize from 'lodash/capitalize'
import { Avatar, MoreBar, Text, Token } from '@revolut/ui-kit'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import Form from '@src/features/Form/Form'
import { documentsBulkRequestFormRequests } from '@src/api/documents'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocumentsBulkRequestInterface } from '@src/interfaces/documents'
import ActionWidget from '@components/ActionWidget/ActionWidget'

import {
  getDocumentsBulkRequestStatusColor,
  getRefreshRequestData,
  isReadyToSubmit,
} from './common'

const Preview = connect(() => {
  const { values, reset } = useLapeContext<DocumentsBulkRequestInterface>()

  const [isRefreshPending, setIsRefreshPending] = useState(false)

  const canSubmit = isReadyToSubmit(values.status)

  const ReviewAndSubmitButton = (
    <MoreBar.Action
      disabled={!canSubmit}
      useIcon="ArrowThinRight"
      use={InternalLink}
      to={pathToUrl(ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.REVIEW, {
        id: values.id,
      })}
    >
      Review and submit
    </MoreBar.Action>
  )

  const refreshRequestData = getRefreshRequestData(values.id, d => reset(d))
  const onRefreshStatus = () => {
    setIsRefreshPending(true)
    refreshRequestData().then(() => setTimeout(() => setIsRefreshPending(false), 200))
  }

  const RefreshStatusButton = (
    <MoreBar.Action useIcon="Retry" onClick={onRefreshStatus} pending={isRefreshPending}>
      Refresh status
    </MoreBar.Action>
  )

  return (
    <>
      <PageHeader
        title={values.name}
        subtitle={
          <Text color={getDocumentsBulkRequestStatusColor(values.status)}>
            {capitalize(values.status.name)}
          </Text>
        }
        backUrl={ROUTES.APPS.DOCUMENTS.BULK_REQUEST.LIST}
        routerAction={PageHeaderRouterAction.navigate}
      />
      <PageBody>
        {values.status.id === 'validation_pending' && (
          <ActionWidget
            title="Request is created and waiting for validation"
            text="Validation process can take a while.
            When it is finished, you will be able to proceed to the Review step and submit the request."
            avatar={
              <Avatar
                progress={0.6}
                useIcon="16/SandWatch"
                color={Token.color.orange}
                progressColor={Token.color.orange}
              />
            }
          >
            <MoreBar>
              {RefreshStatusButton}
              {ReviewAndSubmitButton}
            </MoreBar>
          </ActionWidget>
        )}
        {values.status.id === 'validation_in_progress' && (
          <ActionWidget
            title="Eligible employees are being validated for this request"
            text='Validation process can take a while.
            When it is finished, you will be able to proceed to the "Review" step and submit the request.'
            avatar={
              <Avatar
                progress={0.6}
                useIcon="16/SandWatch"
                color={Token.color.orange}
                progressColor={Token.color.orange}
              />
            }
          >
            <MoreBar>
              {RefreshStatusButton}
              {ReviewAndSubmitButton}
            </MoreBar>
          </ActionWidget>
        )}
        {canSubmit && (
          <ActionWidget
            title="Request validation is completed"
            text="You can review eligible employees and submit the request"
            avatar={<Avatar useIcon="Check" color={Token.color.blue} />}
          >
            <MoreBar>{ReviewAndSubmitButton}</MoreBar>
          </ActionWidget>
        )}
        {values.status.id === 'submit_in_progress' && (
          <ActionWidget
            title="Request is waiting to complete"
            avatar={
              <Avatar useIcon="16/SandWatch" progress={0.6} color={Token.color.blue} />
            }
          >
            <MoreBar>{RefreshStatusButton}</MoreBar>
          </ActionWidget>
        )}
        {values.status.id === 'submit_completed' && (
          <ActionWidget
            title="Request is successfully completed"
            avatar={<Avatar useIcon="Check" color={Token.color.green} />}
          />
        )}
      </PageBody>
    </>
  )
})

export const PreviewDocumentsBulkRequest = () => (
  <Form api={documentsBulkRequestFormRequests}>
    <Preview />
  </Form>
)
