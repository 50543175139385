import React from 'react'
import { Header, InputGroup, Flex } from '@revolut/ui-kit'

import { selectorKeys } from '@src/constants/api'
import { EMPLOYEE_PROFILE_SUB_SECTIONS } from '@src/interfaces/employees'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { onboardingSteps } from '@src/interfaces/onboarding'
import { navigateTo } from '@src/actions/RouterActions'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { PageBody } from '@src/components/Page/PageBody'
import { BackButton, NextButton, TabProps } from '../common'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { SectionOptions } from '@src/interfaces/customFields'

const Payroll = ({ dynamicGroups, onComplete, nextRoute, prevRoute }: TabProps) => {
  return (
    <>
      <PageBody pb="s-24">
        <Header variant="main" mb="s-24">
          <Header.Title>Payroll</Header.Title>
          <Header.Subtitle>
            Please fill in your bank details so that we can add you to our payroll.
          </Header.Subtitle>
        </Header>

        <NewStepperSectionCustomFields
          sectionId={SectionOptions.EmployeeProfile}
          subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BANK_DETAILS}
          dynamicGroups={dynamicGroups}
        >
          <InputGroup>
            <LapeNewInput name="bank_name" label="Bank name" required />
            <LapeNewInput
              name="account_name"
              label="Name on account"
              message="Your name as it appears on your bank statements"
              required
            />
            <LapeRadioSelectInput
              name="bank_currency"
              selector={selectorKeys.currencies}
              label="Account currency"
            />
          </InputGroup>
        </NewStepperSectionCustomFields>
      </PageBody>

      <PageActions>
        <Flex gap="s-16">
          <BackButton onClick={() => (prevRoute ? navigateTo(prevRoute) : undefined)} />
          <NextButton
            onClickInPreviewMode={() => {
              if (nextRoute) {
                navigateTo(nextRoute)
              }
            }}
            afterSubmit={() => {
              if (nextRoute) {
                navigateTo(nextRoute)
                onComplete(onboardingSteps.payroll)
              }
            }}
            elevated
          />
        </Flex>
      </PageActions>
    </>
  )
}

export default Payroll
