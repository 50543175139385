import {
  VStack,
  Text,
  Token,
  ActionButton,
  HStack,
  IconName,
  TextArea,
  Spacer,
  Popup,
  Button,
  Color,
  Icon,
} from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import React, { ReactNode, useState } from 'react'

type StatusTab = {
  status: Statuses
  label: ReactNode
  icon: IconName
  iconColor?: Color
}

export const StatusUpdate = ({
  onCancel,
  onSubmit,
  currentStatus,
}: {
  onCancel: () => void
  onSubmit: (status: Statuses, comment: string) => void
  currentStatus: Statuses
}) => {
  const [status, setStatus] = useState<Statuses>()
  const [comment, setComment] = useState<string>()
  const tabs = [
    {
      status: Statuses.on_track,
      icon: 'Wealth' as IconName,
      label: 'On track',
    },
    {
      status: Statuses.at_risk,
      icon: 'ExclamationTriangle' as IconName,
      iconColor: Token.color.orange,
      label: 'At risk',
    },
    {
      status: Statuses.delayed,
      icon: 'CrossCircle' as IconName,
      iconColor: Token.color.red,
      label: 'Delayed',
    },
    {
      status: Statuses.on_hold,
      icon: 'Pause' as IconName,
      iconColor: Token.color.greyTone50,
      label: 'On hold',
    },
    {
      status: Statuses.complete,
      icon: 'CheckSuccess' as IconName,
      label: 'Complete',
    },
  ]
  return (
    <VStack>
      <Text variant="h5">Update target status</Text>
      <Spacer height="s-8" />
      <Text variant="caption" color={Token.color.greyTone50}>
        What’s the status of this goal?
      </Text>
      <Spacer height="s-24" />
      <HStack space="s-8">
        {tabs.map(tab => (
          <StatusSwitch
            key={tab.status}
            disabled={tab.status === currentStatus}
            tab={tab}
            onSelect={setStatus}
            selected={status === tab.status}
          />
        ))}
      </HStack>
      <Spacer height="s-16" />
      <TextArea
        value={comment}
        onChange={ev => setComment((ev.target as HTMLTextAreaElement).value)}
        rows={3}
        label="Update summary"
      />
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          elevated
          disabled={!comment || !status}
          onClick={() => onSubmit(status!, comment!)}
        >
          Submit
        </Button>
      </Popup.Actions>
    </VStack>
  )
}

function StatusSwitch({
  tab,
  onSelect,
  selected,
  disabled,
}: {
  tab: StatusTab
  onSelect: (status: Statuses) => void
  selected: boolean
  disabled: boolean
}) {
  return selected || disabled || !tab.iconColor ? (
    <ActionButton
      disabled={disabled}
      variant={selected ? 'accent' : undefined}
      useIcon={tab.icon}
      onClick={() => onSelect(tab.status)}
      size="xs"
    >
      {tab.label}
    </ActionButton>
  ) : (
    <ActionButton onClick={() => onSelect(tab.status)} size="xs">
      <HStack space="s-8" align="center">
        <Icon name={tab.icon} size={16} color={tab.iconColor} />
        <Text>{tab.label}</Text>
      </HStack>
    </ActionButton>
  )
}
