import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { Box, useTheme } from '@revolut/ui-kit'

import {
  ReCaptchaHandle,
  ReCaptchaInstance,
  ReCaptchaKeyInterface,
} from '@src/interfaces/reCaptcha'
import useLoadScript from '@src/hooks/useLoadScript'
import BottomInputMessage from '@components/BottomInputMessage/BottomInputMessage'
import { useGetRecaptchaKey } from '@src/api/reCaptcha'

const RECAPTCHA_URL = 'https://www.google.com/recaptcha/enterprise.js'

declare global {
  interface Window {
    grecaptcha?: ReCaptchaInstance
  }
}

interface ReCaptchaProps {
  onVerify: (token: string) => void
  name?: string
  error?: string
}

interface ReCaptchaContentsProps extends ReCaptchaProps {
  reCaptchaKey: ReCaptchaKeyInterface
}

const ReCaptchaContents = forwardRef<ReCaptchaHandle, ReCaptchaContentsProps>(
  ({ onVerify, name, error, reCaptchaKey }, ref) => {
    const captchaRef = useRef<HTMLDivElement>(null)
    const theme = useTheme()
    const executionResolve = useRef<(token: string | PromiseLike<string>) => void>()

    const isInvisible = reCaptchaKey.type === 'v2 Invisible'

    useImperativeHandle(ref, () => ({
      reset() {
        executionResolve.current = undefined
        window.grecaptcha?.enterprise.reset()
      },
      verify() {
        // if this method is used with visible captcha - it should do nothing
        if (!isInvisible) {
          return Promise.resolve(undefined)
        }
        return execute()
      },
      scrollIntoView() {
        captchaRef?.current?.scrollIntoView({ block: 'center' })
      },
    }))

    const handleLoad = useCallback(() => {
      const wrapper = document.createElement('div')
      captchaRef.current?.appendChild(wrapper)

      window.grecaptcha?.enterprise.ready(() => {
        window.grecaptcha?.enterprise.render(wrapper, {
          sitekey: reCaptchaKey.site_key,
          callback: token => {
            executionResolve.current?.(token)
            return onVerify(token)
          },
          theme: theme.mode === 'dark' ? 'dark' : 'light',
          size: isInvisible ? 'invisible' : 'normal',
        })
      })
    }, [onVerify, captchaRef, reCaptchaKey, executionResolve])

    useLoadScript(RECAPTCHA_URL, handleLoad)

    const execute = async () => {
      const promise: Promise<string> = new Promise(resolve => {
        /**
         * reCaptcha api doesn't actually return a promise (docs are a lie).
         * We need to resolve this promise in a "callback" method instead, when the token is parsed and returned.
         */
        executionResolve.current = resolve
        window.grecaptcha?.enterprise.execute()
      })
      return promise
    }

    return (
      <>
        <Box
          ref={captchaRef}
          data-name={name}
          overflow="hidden"
          borderRadius={3}
          width={302}
          height={isInvisible ? 0 : 76}
        />
        <BottomInputMessage hasError={!!error} message={error} />
      </>
    )
  },
)

export const ReCaptcha = forwardRef<ReCaptchaHandle, ReCaptchaProps>((props, ref) => {
  const { data: reCaptchaKey } = useGetRecaptchaKey()

  if (!reCaptchaKey) {
    return null
  }

  return <ReCaptchaContents ref={ref} reCaptchaKey={reCaptchaKey} {...props} />
})

ReCaptcha.displayName = 'ReCaptcha'
