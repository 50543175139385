import React, { useMemo, useState } from 'react'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import getStartOfMonth from 'date-fns/startOfMonth'

import { Box, Color, Flex } from '@revolut/ui-kit'
import { CellTypes, ColumnCellInterface, FilterByInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  isToday,
  getDaysInMonth,
  startOfISOWeek,
  differenceInDays,
  addMonths,
  addWeeks,
  endOfISOWeek,
  endOfMonth as getEndOfMonth,
} from 'date-fns'
import { EmployeeTimeOffRequestsCalendarInterface } from '@src/interfaces/timeOff'
import { TimeOffTableWeekCell } from './TimeOffTableWeekCell'
import { TimeOffTableMonthCell } from '@src/features/TimeOffCalendarTable/TimeOffTableMonthCell'
import {
  DAYS_IN_WEEK,
  EMPLOYEE_STATUS_FILTER_KEY,
  END_DATE_FILTER_KEY,
  MONTH_CELL_PADDING,
  MONTH_CELL_WIDTH,
  START_DATE_FILTER_KEY,
  TableCalendarTabs,
  WeekMonthTabs,
  WEEK_CELL_PADDING,
  WEEK_CELL_WIDTH,
} from '@src/features/TimeOffCalendarTable/constants'
import { useQuery } from '@src/utils/queryParamsHooks'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { CalendarWeek, ListBullet } from '@revolut/icons'

export const useCurrentWeekRow = (
  currentDay: Date,
  cellsBefore?: ColumnCellInterface<EmployeeTimeOffRequestsCalendarInterface>[],
  canSeePolicy?: boolean,
) => {
  const cells = useMemo(() => {
    const res = cellsBefore || []
    const startOfWeek = startOfISOWeek(currentDay)

    for (let dayIdx = 0; dayIdx < DAYS_IN_WEEK; dayIdx++) {
      const day = addDays(startOfWeek, dayIdx)

      res.push({
        type: CellTypes.insert,
        dataPoint: 'id',
        idPoint: 'id',
        selectorsKey: selectorKeys.none,
        filterKey: null,
        sortKey: null,
        width: WEEK_CELL_WIDTH + WEEK_CELL_PADDING * 2,
        title: format(day, 'MMM, d'),
        padding: `${WEEK_CELL_PADDING}px`,
        renderCustomHeader: () => {
          const wrapperProps = isToday(day)
            ? {
                bg: Color.BLUE,
                color: Color.BACKGROUND,
                px: 's-8',
                borderRadius: 100,
              }
            : {}

          return (
            <Flex width={140} justifyContent="center" alignItems="center">
              <Box {...wrapperProps}>{format(day, 'MMM, d')}</Box>
            </Flex>
          )
        },
        insert: ({ data }) => {
          return (
            <TimeOffTableWeekCell
              day={day}
              requestsCalendar={data}
              canSeePolicy={canSeePolicy}
            />
          )
        },
      })
    }
    return res
  }, [currentDay, canSeePolicy])

  return { cells }
}

export const useCurrentMonthRow = (
  currentDay: Date,
  cellsBefore?: ColumnCellInterface<EmployeeTimeOffRequestsCalendarInterface>[],
  canSeePolicy?: boolean,
) => {
  const cells = useMemo(() => {
    const res = cellsBefore || []
    const startOfMonth = getStartOfMonth(currentDay)
    const daysInMonth = getDaysInMonth(currentDay)

    for (let dayIdx = 0; dayIdx < daysInMonth; dayIdx++) {
      const day = addDays(startOfMonth, dayIdx)

      res.push({
        type: CellTypes.insert,
        dataPoint: 'id',
        idPoint: 'id',
        selectorsKey: selectorKeys.none,
        filterKey: null,
        sortKey: null,
        width: MONTH_CELL_WIDTH + MONTH_CELL_PADDING * 2,
        title: String(dayIdx + 1),
        padding: `${MONTH_CELL_PADDING}px`,
        renderCustomHeader: () => {
          const wrapperProps = isToday(day)
            ? {
                bg: Color.BLUE,
                color: Color.BACKGROUND,
                px: 's-4',
                borderRadius: 100,
              }
            : {}

          return (
            <Flex width={36} justifyContent="center" alignItems="center">
              <Box {...wrapperProps}>{dayIdx + 1}</Box>
            </Flex>
          )
        },
        insert: ({ data }) => (
          <TimeOffTableMonthCell
            day={day}
            requestsCalendar={data}
            canSeePolicy={canSeePolicy}
          />
        ),
      })
    }
    return res
  }, [currentDay, canSeePolicy])

  return { cells }
}

export const useCalendarFilters = () => {
  const { query, deleteQueryParam } = useQuery()

  const hasCalendarViewFilters =
    query[START_DATE_FILTER_KEY] && query[END_DATE_FILTER_KEY]
  const isMonthView = hasCalendarViewFilters
    ? differenceInDays(new Date(query.end_date), new Date(query.start_date)) >
      DAYS_IN_WEEK
    : false

  return {
    isMonthView,
    isCalendarView: hasCalendarViewFilters,
    clearCalendarFilters: () => {
      deleteQueryParam(START_DATE_FILTER_KEY)
      deleteQueryParam(END_DATE_FILTER_KEY)
      deleteQueryParam(EMPLOYEE_STATUS_FILTER_KEY)
    },
  }
}

const tabsIcons = {
  [TableCalendarTabs.Table]: ListBullet,
  [TableCalendarTabs.Calendar]: CalendarWeek,
}

export const useTableCalendarSwitcher = (
  onTabChange?: (tab: TableCalendarTabs) => void,
) => {
  return useTabBarSwitcher({
    tabs: [TableCalendarTabs.Calendar, TableCalendarTabs.Table],
    useIcons: tabsIcons,
    highlightSelected: false,
    defaultTab: TableCalendarTabs.Calendar,
    onTabChange,
  })
}

export const useWeekMonthSwitcher = (isMonthView: boolean) => {
  return useTabBarSwitcher({
    tabs: [WeekMonthTabs.Week, WeekMonthTabs.Month],
    highlightSelected: false,
    defaultTab: isMonthView ? WeekMonthTabs.Month : WeekMonthTabs.Week,
  })
}

export const useTimeOffCalendarControls = () => {
  const { query } = useQuery()

  const [currentDay, setCurrentDay] = useState(
    query.start_date ? new Date(query.start_date) : new Date(),
  )

  const onClickNextWeek = () => {
    setCurrentDay(addWeeks(currentDay, 1))
  }

  const onClickPrevWeek = () => {
    setCurrentDay(addWeeks(currentDay, -1))
  }

  const onClickNextMonth = () => {
    setCurrentDay(addMonths(currentDay, 1))
  }

  const onClickPrevMonth = () => {
    setCurrentDay(addMonths(currentDay, -1))
  }

  const onClickToday = () => {
    setCurrentDay(new Date())
  }

  return {
    currentDay,
    onClickNextWeek,
    onClickPrevWeek,
    onClickNextMonth,
    onClickPrevMonth,
    onClickToday,
  }
}

export const useTimeOffCalendarFilters = (
  isWeekView: boolean,
  currentDay: Date,
  initialFilters?: FilterByInterface[],
) => {
  const startOfWeek = startOfISOWeek(currentDay)
  const endOfWeek = endOfISOWeek(currentDay)

  const startOfMonth = getStartOfMonth(currentDay)
  const endOfMonth = getEndOfMonth(currentDay)

  const startDateFilter = format(isWeekView ? startOfWeek : startOfMonth, 'yyyy-MM-dd')
  const endDateFilter = format(isWeekView ? endOfWeek : endOfMonth, 'yyyy-MM-dd')

  const getFilters = () => {
    return [
      {
        filters: [
          {
            id: startDateFilter,
            name: startDateFilter,
          },
        ],
        columnName: START_DATE_FILTER_KEY,
        nonResettable: true,
      },
      {
        filters: [
          {
            id: endDateFilter,
            name: endDateFilter,
          },
        ],
        columnName: END_DATE_FILTER_KEY,
        nonResettable: true,
      },
      {
        filters: [
          {
            id: 'active',
            name: 'active',
          },
        ],
        columnName: EMPLOYEE_STATUS_FILTER_KEY,
        nonResettable: true,
      },
      ...(initialFilters || []),
    ]
  }

  return {
    startOfWeek,
    endOfWeek,
    getFilters,
  }
}
