import React from 'react'
import KPIPage from '@src/pages/Forms/DepartmentForm/KPI/KPI'
import { useTable } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import {
  FilterByInterface,
  SORT_DIRECTION,
  SortByInterface,
  Stats,
} from '@src/interfaces/data'
import { kpisRequests } from '@src/api/kpis'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { Statuses } from '@src/interfaces'

interface StructureProps {
  data: DepartmentInterface
}

export const getKpiFilters = (data: DepartmentInterface): FilterByInterface[] => [
  {
    filters: [{ name: `${data.id}`, id: data.id }],
    columnName: 'department__id',
    nonResettable: true,
    nonInheritable: true,
  },
  // TODO uncomment it when BE is fixed
  // {
  //   filters: [{ name: 'true', id: 'true' }],
  //   columnName: 'include_unlinked',
  //   nonInheritable: true,
  //   nonResettable: true,
  // },
  {
    filters: [
      { name: Statuses.pending, id: Statuses.pending },
      { name: Statuses.approved, id: Statuses.approved },
      { name: Statuses.future, id: Statuses.future },
      { name: Statuses.completed, id: Statuses.completed },
    ],
    columnName: 'target_status',
  },
  {
    filters: [{ name: `0`, id: 0 }],
    columnName: 'review_cycle__offset',
    nonResettable: true,
  },
]

export const kpiSorting: SortByInterface[] = [
  {
    sortBy: 'weight',
    direction: SORT_DIRECTION.ASC,
    nonResettable: true,
  },
  {
    sortBy: 'has_children',
    direction: SORT_DIRECTION.ASC,
  },
]

const KPI = ({ data }: StructureProps) => {
  const table = useTable<KpiInterface, Stats>(
    kpisRequests,
    getKpiFilters(data),
    kpiSorting,
  )

  return (
    <KPIPage
      data={data}
      table={table}
      warnAboutMandatoryKPIs={!!table?.stats?.mandatory_kpi_incomplete}
    />
  )
}

export default KPI
