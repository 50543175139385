import React from 'react'
import { FilterByInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { initialRequisitionStatusFilter } from '@src/interfaces/requisitions'
import CommonRequisitionTable from '@src/features/CommonRequisitionTable/CommonRequisitionTable'
import { withFavourites } from '@src/features/FavouritesFilter/withFavourites'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'

const RequisitionTable = () => {
  const { initialFilter } = useFavouritesFilter('requisition')
  const getInitialFilters = (): FilterByInterface[] => {
    const filters = []
    if (initialFilter) {
      filters.push(initialFilter)
    }
    return [
      ...filters,
      {
        filters: initialRequisitionStatusFilter,
        columnName: 'status',
        nonResettable: true,
      },
    ]
  }
  return (
    <CommonRequisitionTable
      filterBy={getInitialFilters()}
      sortBy={[
        {
          sortBy: 'pipeline_queue_position',
          direction: SORT_DIRECTION.DESC,
        },
        {
          sortBy: 'status',
          direction: SORT_DIRECTION.ASC,
        },
      ]}
      type="main"
      enableFavourites
    />
  )
}

export default withFavourites(RequisitionTable)
