import React from 'react'
import { useParams } from 'react-router'
import { InputGroup } from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { ROUTES } from '@src/constants/routes'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RegimeInterface } from '@src/interfaces/timeOff'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'

const General = () => {
  const { id } = useParams<{ id?: string }>()
  const { values } = useLapeContext<RegimeInterface>()

  return (
    <>
      <PageHeader
        title={id ? 'Edit time off regime' : 'New time off regime'}
        backUrl={
          id
            ? pathToUrl(ROUTES.FORMS.TIME_OFF_REGIMES.PREVIEW, { id })
            : ROUTES.PEOPLE.TIME_OFF.REGIMES
        }
      />

      <PageBody>
        <InputGroup>
          <LapeNewInput name="name" label="Name" required />
          <LapeNewMultiSelect
            name="groups"
            placeholder="Groups"
            selector={selectorKeys.dynamic_groups}
            required
          />
          <LapeRadioSelectInput<RegimeInterface['default_approver']>
            name="default_approver"
            label="Default approver"
            onAfterChange={option => {
              if (
                option?.id === 'line_manager' &&
                values.default_approver_group != null
              ) {
                values.default_approver_group = null
              }
            }}
            selector={selectorKeys.time_off_regime_default_approvers}
          />
          {values.default_approver?.id === 'dynamic_group' && (
            <LapeRadioSelectInput
              name="default_approver_group"
              label="Default approver group"
              selector={selectorKeys.dynamic_groups}
            />
          )}
          <LapeRadioSelectInput
            name="working_days_start"
            label="Working days start"
            selector={selectorKeys.days_of_the_week}
          />
          <LapeRadioSelectInput
            name="working_days_end"
            label="Working days end"
            selector={selectorKeys.days_of_the_week}
          />
          <LapeRadioSelectInput
            name="public_holidays"
            label="Public holidays"
            selector={selectorKeys.time_off_regime_public_holidays}
          />
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Regime saved successfully"
          previewUrl={ROUTES.FORMS.TIME_OFF_REGIMES.PREVIEW}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default General
