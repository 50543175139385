import React, { useState } from 'react'
import {
  InterviewStatuses,
  InterviewToolInterviewer,
  InterviewType,
  LatestInterviewStageInterface,
} from '@src/interfaces/interviewTool'
import { Box, Flex, Spinner, Text, TextButton } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { createFeedback } from '@src/api/recruitment/interviewFeedback'
import { useLocation } from 'react-router-dom'
import {
  clearCVScreeningSession,
  navigateToCVScreening,
  setCVScreeningSession,
} from '@src/pages/Forms/CVScreening/utils'

type Props = {
  interview?: LatestInterviewStageInterface
  candidateId: number
}

const getButtonLabel = (type: InterviewType, status: InterviewStatuses) => {
  if (type === 'cv_screening') {
    return 'Screen candidate'
  }

  switch (status) {
    case InterviewStatuses.feedback_submitted:
      return 'Edit feedback'

    default:
      return 'Add feedback'
  }
}

const AddInterviewFeedbackAction = ({ interview, candidateId }: Props) => {
  const location = useLocation()
  const user = useSelector(selectUser)
  const [loading, setLoading] = useState(false)

  if (!interview) {
    return <Text>-</Text>
  }

  const navigateToScorecard = async () => {
    setLoading(true)

    try {
      const resp = await createFeedback(interview.id, {
        id: user.id,
      } as InterviewToolInterviewer)

      const scorecard = resp.data

      if (scorecard) {
        navigateTo(
          pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, { id: scorecard.id }),
        )
      }
    } finally {
      setLoading(false)
    }
  }

  const startCVScreening = () => {
    clearCVScreeningSession()
    setCVScreeningSession({ candidateIds: [candidateId] })
    navigateToCVScreening(candidateId, location)
  }

  return (
    <TextButton
      fontWeight={500}
      onClick={e => {
        e.stopPropagation()

        if (interview.interview_type === 'cv_screening') {
          startCVScreening()
        } else {
          navigateToScorecard()
        }
      }}
      disabled={loading}
    >
      <Flex alignItems="center">
        {getButtonLabel(interview.interview_type, interview.scheduling_status)}
        {loading && (
          <Box ml="s-4">
            <Spinner size={12} color="blue" />
          </Box>
        )}
      </Flex>
    </TextButton>
  )
}

export default AddInterviewFeedbackAction
