import React, { useMemo, useRef, useState } from 'react'
import { connect } from 'lape'
import {
  Box,
  BoxProps,
  Color,
  Flex,
  H4,
  HStack,
  IconButton,
  Link,
  Spacer,
  Spinner,
  Text,
  TextButton,
  Token,
  Tooltip,
  TransitionFade,
  Widget,
  useTooltip,
} from '@revolut/ui-kit'
import DOMPurify from 'dompurify'
import { useGetJobDescription } from '@src/api/jobPosting'
import styled, { css } from 'styled-components'
import { getNormalizedLocations } from '@src/pages/Forms/JobPosting/utils'
import { JobPostingLocationInterface } from '@src/interfaces/jobPosting'
import { Bank, Copy, Laptop } from '@revolut/icons'
import { pushNotification } from '@src/store/notifications/actions'
import {
  SUCCESS_DEFAULT_DURATION,
  ERROR_DEFAULT_DURATION,
} from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { SidebarJobDescription } from '@src/interfaces/jobDescription'

const copyHTMLContent = async (element: HTMLElement) => {
  try {
    if (element.textContent) {
      const htmlBlob = new Blob([element.outerHTML], { type: 'text/html' })
      const plainBlob = new Blob([element.textContent], { type: 'text/plain' })
      const clipboardItem = new ClipboardItem({
        'text/html': htmlBlob,
        'text/plain': plainBlob,
      })
      await navigator.clipboard.write([clipboardItem])
      pushNotification({
        value: 'Copied to clipboard',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    }
  } catch {
    pushNotification({
      value: 'Failed Copying to clipboard',
      duration: ERROR_DEFAULT_DURATION,
      type: NotificationTypes.error,
    })
  }
}

interface JobDescriptionProps {
  id?: number
  locations?: JobPostingLocationInterface[]
}

const sectionCss = css`
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${Token.color.foreground};
  }

  p {
    margin: 16px 0;
  }
`

const CopyBreak = styled.br`
  display: none;
`

type LocationsProps = Pick<BoxProps, 'mt'> & {
  icon: React.ReactElement
  locations: string[]
  title: string
}

const Locations = ({ icon, locations, mt, title }: LocationsProps) => {
  return (
    <Box mt={mt}>
      <Box
        use="span"
        display="inline-block"
        style={{
          verticalAlign: 'text-top',
        }}
        mr="s-8"
      >
        {icon}
      </Box>
      <Text use="b" variant="h6">
        {title}:{' '}
      </Text>
      <Text variant="caption" mt="s-4" color="grey-tone-50" textAlign="center">
        {locations.join(' | ')}
      </Text>
    </Box>
  )
}

type SectionProps = {
  fontFamily: string
  section: SidebarJobDescription
}

const Section = ({ fontFamily, section }: SectionProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const tooltip = useTooltip()
  const [displayCopy, setDisplayCopy] = useState(false)
  const isClosing = (title?: string) => title === 'Closing'
  const link = section?.section_url || section.section?.section_url
  const linkTitle = section?.section_url_title || section.section?.section_url_title
  return (
    <Box
      py="s-8"
      ref={contentRef}
      style={{
        fontFamily,
      }}
      onMouseEnter={() => {
        setDisplayCopy(true)
      }}
      onMouseLeave={() => {
        setDisplayCopy(false)
      }}
    >
      <Flex justifyContent="space-between">
        {section.title && !isClosing(section.title) && (
          <Text use="b" variant="primary" mb="s-12">
            {section.title}
          </Text>
        )}
        <Spacer height="s-24" />
        <TransitionFade in={displayCopy}>
          <Box>
            <IconButton
              color={Color.BLUE}
              onClick={() => {
                if (contentRef.current) {
                  copyHTMLContent(contentRef.current)
                }
              }}
              useIcon={<Copy />}
              {...tooltip.getAnchorProps()}
            />
            <Tooltip {...tooltip.getTargetProps()}>Copy section content</Tooltip>
          </Box>
        </TransitionFade>
      </Flex>
      <Text
        css={sectionCss}
        use="pre"
        style={{
          fontFamily,
          textAlign: 'justify',
          whiteSpace: 'pre-wrap',
        }}
        variant="caption"
        color={isClosing(section.title) ? Token.color.foreground : Token.color.greyTone50}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(section.content),
        }}
      />
      {!!link && (
        <Link href={link} target="_blank" rel="noopener noreferrer">
          {linkTitle || 'See more'}
        </Link>
      )}
      <CopyBreak />
    </Box>
  )
}

const JobPosting = ({ locations, id }: JobDescriptionProps) => {
  const { data, isLoading } = useGetJobDescription(id)
  const contentRef = useRef<HTMLDivElement>(null)

  const fontFamily = useMemo(() => {
    // this is to get the actual value of Token.font.brand
    // we need the actual value for copy functionality
    return window.getComputedStyle(document.body).getPropertyValue('--rui-font-brand')
  }, [])

  if (isLoading) {
    return (
      <Widget p="s-16">
        <Spinner />
      </Widget>
    )
  }

  const loc = getNormalizedLocations(locations || data?.locations || [])

  return (
    <Widget p="s-16" data-testid="job-description">
      <Flex>
        <Spacer />
        <TextButton
          onClick={() => {
            if (contentRef.current) {
              copyHTMLContent(contentRef.current)
            }
          }}
        >
          <HStack space="s-8">
            <Text>Copy posting content</Text>
            <Copy />
          </HStack>
        </TextButton>
      </Flex>
      <Box
        py="s-24"
        ref={contentRef}
        style={{
          fontFamily,
        }}
      >
        <H4 mb="s-20" textAlign="center">
          {data?.name || '<Role name>'}
        </H4>
        <Text
          use="div"
          variant="caption"
          mt="s-4"
          color={Color.GREY_TONE_50}
          textAlign="center"
        >
          {!!loc.office.length && (
            <Locations icon={<Bank size={16} />} locations={loc.office} title="Office" />
          )}
          {!!loc.remote.length && (
            <Locations
              mt="s-8"
              icon={<Laptop size={16} />}
              locations={loc.remote}
              title="Remote"
            />
          )}
          {!loc.remote.length && !loc.office.length && (
            <Text variant="caption" mt="s-4">{`<Location will be displayed here>`}</Text>
          )}
        </Text>
        <CopyBreak />
        {data?.sections?.map(section => (
          <Section key={section.id} section={section} fontFamily={fontFamily} />
        ))}
      </Box>
    </Widget>
  )
}

export default connect(JobPosting)
