import React from 'react'
import { Button, FilterButton, TableWidget } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { EmployeeInterface } from '@src/interfaces/employees'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { getAllEmployeeScreeningChecks } from '@src/api/screening'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import {
  AllScreeningChecksInterface,
  AllScreeningChecksStatsInterface,
} from '@src/interfaces/screeningChecksInterface'
import {
  screeningChecksNameColumn,
  screeningChecksSourceColumn,
  screeningChecksCreatedByColumn,
  screeningChecksLastUpdateColumn,
  screeningChecksCompletedOnColumn,
  screeningChecksStatusColumn,
  screeningChecksCreatedOnColumn,
  screeningChecksApproverColumn,
} from '@src/constants/columns/ScreeningChecks'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useAssignedToMeFilter } from '@src/features/Screening/hooks'

const ROW: RowInterface<AllScreeningChecksInterface> = {
  cells: [
    {
      ...screeningChecksNameColumn,
      width: 220,
    },
    {
      ...screeningChecksSourceColumn,
      width: 150,
    },
    {
      ...screeningChecksApproverColumn,
      width: 240,
    },
    {
      ...screeningChecksCreatedByColumn,
      width: 240,
    },
    {
      ...screeningChecksCreatedOnColumn,
      width: 180,
    },
    {
      ...screeningChecksLastUpdateColumn,
      width: 180,
    },
    {
      ...screeningChecksCompletedOnColumn,
      width: 140,
    },
    {
      ...screeningChecksStatusColumn,
      width: 180,
    },
  ],
}

interface Props {
  data: EmployeeInterface
}

export const Screening = ({ data }: Props) => {
  const table = useTable<AllScreeningChecksInterface, AllScreeningChecksStatsInterface>(
    {
      getItems: getAllEmployeeScreeningChecks(data.id),
    },
    undefined,
  )

  const handleTriggerScreeningCheck = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.SCREENING.SELECT, { employeeId: data.id }))
  }

  const handleRowClick = (check: AllScreeningChecksInterface) => {
    if (check.type.id === 'hireright') {
      navigateTo(
        pathToUrl(ROUTES.FORMS.SCREENING.HIRERIGHT.PREVIEW, {
          employeeId: check.employee.id,
          id: check.screening_id,
        }),
      )
    } else if (check.type.id === 'manual') {
      navigateTo(
        pathToUrl(ROUTES.FORMS.SCREENING.MANUAL.PREVIEW, {
          employeeId: check.employee.id,
          id: check.screening_id,
        }),
      )
    }
  }

  const { showAssignedToMe, onToggleAssignedToMe } = useAssignedToMeFilter<
    AllScreeningChecksInterface,
    AllScreeningChecksStatsInterface
  >(table)

  return (
    <TableWidget>
      <TableWidget.Actions>
        <Button
          onClick={handleTriggerScreeningCheck}
          mr="s-16"
          useIcon={Plus}
          size="sm"
          variant="secondary"
        >
          Add new
        </Button>
      </TableWidget.Actions>
      <TableWidget.Filters>
        <FilterButton onClick={onToggleAssignedToMe} active={showAssignedToMe}>
          Assigned to me
        </FilterButton>
      </TableWidget.Filters>
      <TableWidget.Table>
        <AdjustableTable<AllScreeningChecksInterface, AllScreeningChecksStatsInterface>
          name={TableNames.EmployeeScreening}
          useWindowScroll
          dataType="Screening Check"
          row={ROW}
          {...table}
          noDataMessage="Employee has no screening checks"
          onRowClick={handleRowClick}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
