import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { RightToWorkInterface } from '@src/interfaces/employees'
import { RevolutTheme } from '@src/styles/theme'

export const rightToWorkCountryColumn: ColumnInterface<RightToWorkInterface> = {
  type: CellTypes.text,
  idPoint: 'country.id',
  dataPoint: 'country.name',
  sortKey: 'country__name',
  filterKey: 'country__id',
  selectorsKey: selectorKeys.countries,
  title: 'Country',
}

export const rightToWorkDocumentType: ColumnInterface<RightToWorkInterface> = {
  type: CellTypes.text,
  idPoint: 'document_type.id',
  dataPoint: 'document_type.name',
  sortKey: 'document_type',
  filterKey: 'document_type',
  selectorsKey: selectorKeys.identification_document_types,
  title: 'Document type',
}

export const rightToWorkValidFrom: ColumnInterface<RightToWorkInterface> = {
  type: CellTypes.date,
  idPoint: 'document_valid_from_date',
  dataPoint: 'document_valid_from_date',
  sortKey: 'document_valid_from_date',
  filterKey: 'document_valid_from_date',
  selectorsKey: selectorKeys.none,
  title: 'Valid from',
}

export const rightToWorkValidUntil: ColumnInterface<RightToWorkInterface> = {
  type: CellTypes.date,
  idPoint: 'document_expiry_date',
  dataPoint: 'document_expiry_date',
  sortKey: 'document_expiry_date',
  filterKey: 'document_expiry_date',
  selectorsKey: selectorKeys.none,
  title: 'Valid until',
}

export const rightToWorkType: ColumnInterface<RightToWorkInterface> = {
  type: CellTypes.text,
  idPoint: 'right_to_work_type.id',
  dataPoint: 'right_to_work_type.name',
  sortKey: 'right_to_work_type',
  filterKey: 'right_to_work_type',
  selectorsKey: selectorKeys.right_to_work_types,
  title: 'Type of right to work',
}

export const rightToWorkIsSponsoredByCompany: ColumnInterface<RightToWorkInterface> = {
  type: CellTypes.insert,
  idPoint: 'is_sponsored_by_company',
  dataPoint: 'is_sponsored_by_company',
  sortKey: 'is_sponsored_by_company',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Sponsored',
  insert: ({ data }) => {
    if (data.is_sponsored_by_company) {
      return 'Yes'
    }
    if (data.is_sponsored_by_company === false) {
      return 'No'
    }
    return '-'
  },
}

export const rightToWorkLastVerifiedOn: ColumnInterface<RightToWorkInterface> = {
  type: CellTypes.date,
  idPoint: 'last_verified_on_date',
  dataPoint: 'last_verified_on_date',
  sortKey: 'last_verified_on_date',
  filterKey: 'last_verified_on_date',
  selectorsKey: selectorKeys.none,
  title: 'Last verified on',
}

export const rightToWorkUpdatedOn: ColumnInterface<RightToWorkInterface> = {
  type: CellTypes.date,
  idPoint: 'updated_date_time',
  dataPoint: 'updated_date_time',
  sortKey: 'updated_date_time',
  filterKey: 'updated_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Updated on',
}

export const getRightToWorkStatusColor = (
  data: RightToWorkInterface,
  theme: RevolutTheme,
) => {
  switch (data.status?.id) {
    case 'incomplete':
      return theme.colors.orange
    case 'complete':
      return theme.colors.green
    case 'expired':
      return theme.colors['grey-tone-50']
    default:
      return theme.colors.foreground
  }
}

export const rightToWorkStatus: ColumnInterface<RightToWorkInterface> = {
  type: CellTypes.text,
  colors: getRightToWorkStatusColor,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.right_to_work_statuses,
  title: 'Status',
}
