import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  JobPostingInterface,
  LocationSource,
  RecruiterLocationInterface,
} from '@src/interfaces/jobPosting'
import { getStatusColor } from '@components/CommonSC/General'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import Tooltip from '@components/Tooltip/Tooltip'
import styled from 'styled-components'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

const StyledTooltip = styled(Tooltip)`
  justify-content: flex-start;
`

export const jobDescriptionGenericNameColumn: ColumnInterface<JobPostingInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
      specId: data?.specialisation?.id,
      id: data?.id,
    }),
  selectorsKey: selectorKeys.job_postings,
  title: 'Title',
}

export const jobDescriptionSpecialisationColumn: ColumnInterface<JobPostingInterface> = {
  type: CellTypes.text,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Specialisation',
}

export const jobDescriptionRecruiterColumn: ColumnInterface<JobPostingInterface> = {
  type: CellTypes.insert,
  idPoint: 'recruiter',
  dataPoint: 'recruiter',
  sortKey: 'recruiter__display_name',
  filterKey: 'recruiter__id',
  selectorsKey: selectorKeys.employee,
  title: 'Recruiter',
  insert: ({ data }) => <UserWithAvatar {...data.recruiter} />,
}

export const jobDescriptionLocationsColumn: ColumnInterface<JobPostingInterface> = {
  type: CellTypes.insert,
  idPoint: 'locations',
  dataPoint: 'locations',
  sortKey: null,
  filterKey: 'locations__id',
  selectorsKey: selectorKeys.location,
  title: 'Locations',
  insert: ({ data }) => {
    let source = ''
    switch (data.location_source) {
      case LocationSource.manual:
        source = '(Selected manually)'
        break

      case LocationSource.requisition:
        source = '(From requisitions)'
        break
    }
    return (
      <StyledTooltip
        placement="top"
        text={data.locations?.map(item => item.name)?.join(', ')}
        hide={!data.locations?.length}
      >
        {data.locations?.length || 0} {source}
      </StyledTooltip>
    )
  },
}

export const jobDescriptionUsedInRequisitionsCountNameColumn: ColumnInterface<JobPostingInterface> =
  {
    type: CellTypes.text,
    idPoint: 'requisitions_count',
    dataPoint: 'requisitions_count',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Open requisitions',
  }

export const jobDescriptionPublishedStatusColumn: ColumnInterface<JobPostingInterface> = {
  type: CellTypes.text,
  idPoint: 'status_display',
  dataPoint: 'status_display',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.postings_status,
  title: 'Publishing status',
}

export const jobDescriptionStatusColumn: ColumnInterface<JobPostingInterface> = {
  type: CellTypes.text,
  idPoint: 'approval_status',
  dataPoint: 'approval_status',
  sortKey: 'approval_status',
  filterKey: 'approval_status',
  selectorsKey: selectorKeys.posting_approval_status,
  title: 'Approval status',
  colors: data => getStatusColor(data.approval_status),
}

export const jobDescriptionActionColumn: ColumnInterface<JobPostingInterface> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
}

export const recruiterLocationName: ColumnInterface<RecruiterLocationInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Recruiter',
  insert: ({ data }) => <UserWithAvatar {...data.recruiter} />,
}

export const recruiterLocationLocations: ColumnInterface<RecruiterLocationInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Locations',
  insert: ({ data }) => data.locations.map(item => item.location_name).join(', '),
}
