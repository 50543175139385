import React from 'react'
import styled from 'styled-components'
import { Profile } from '@revolut/icons'
import { Box, Flex } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import { FinalGrade } from '@src/interfaces/performance'
import {
  CommitteeResultInterface,
  ProbationCheckpoint,
} from '@src/interfaces/probationReview'
import AvatarSnippet from '@components/AvatarSnippet/AvatarSnippet'
import PerformanceGrade from '@components/PerformanceGrade/PerformanceGrade'
import ProbationResult from '@components/ProbationResult/ProbationResult'
import { formatDate } from '@src/utils/format'

const ResultContent = styled(ProbationResult)`
  font-weight: 600;
`

type Props = {
  checkpoint: ProbationCheckpoint
  decision?: CommitteeResultInterface
}

const CommitteeReviewers = ({ checkpoint, decision }: Props) => {
  return (
    <Flex justifyContent="space-between" mt="s-20" mb="s-20">
      <AvatarSnippet
        avatar={decision?.reviewer.avatar}
        badge={checkpoint.decision ? Statuses.completed : Statuses.pending}
        fullName={
          checkpoint.decision?.reviewer
            ? checkpoint.decision.reviewer.full_name
            : 'Committee team'
        }
        icon={checkpoint.decision?.reviewer ? undefined : Profile}
        text={formatDate(checkpoint.checkpoint_date_time)}
      />
      <Flex alignItems="flex-end" flexDirection="column" style={{ flexShrink: 0 }}>
        <ResultContent
          result={decision?.result}
          status={decision?.result ? Statuses.completed : Statuses.pending}
        />
        <Box>
          <PerformanceGrade grade={decision?.final_grade?.id as FinalGrade} inverse />
        </Box>
      </Flex>
    </Flex>
  )
}

export default CommitteeReviewers
