import React from 'react'
import { Group, Box, VStack } from '@revolut/ui-kit'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API } from '@src/constants/api'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { APPROVAL_PROCESS_GROUPS_API } from '@src/api/settings'

export const Organisation = () => {
  const queryClient = useQueryClient()

  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeOrganisationPreferences)
  const findingsHidden = !values.findings?.visible
  const riskHidden = !values.risk?.visible
  const bugsTrackingHidden = !values.bugs_tracking?.visible
  const cxHidden = !values.customer_experience?.visible
  const karmaHidden = !values.karma?.visible
  const dataAccessRequestHidden = !values.data_access_requests?.visible

  const generalSettingsHidden =
    cxHidden &&
    bugsTrackingHidden &&
    findingsHidden &&
    riskHidden &&
    dataAccessRequestHidden

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="Settings that apply to teams, departments and company"
          />
          <Box mt="s-24">
            <Group>
              {generalSettingsHidden ? null : (
                <>
                  <LapeNewSwitch
                    itemTypeProps={{
                      title: 'Enable Customer Experience functionality',
                      description:
                        'Enables tracking metrics related to customer experience and displaying them in the team/department profiles and tables',
                    }}
                    name="customer_experience.enabled"
                    disabled={disableEdit}
                    hidden={cxHidden}
                  />
                  <LapeNewSwitch
                    itemTypeProps={{
                      title: 'Enable Bugs tracking functionality',
                      description:
                        'Enables tracking product bugs per team and displaying them in the team/department profiles and tables',
                    }}
                    name="bugs_tracking.enabled"
                    disabled={disableEdit}
                    hidden={bugsTrackingHidden}
                  />
                  <LapeNewSwitch
                    itemTypeProps={{
                      title: 'Enable Findings functionality',
                      description:
                        'Enables tracking findings related to the team/department, this can be security, audit, technical or other sort of findings and issues related to that particular unit.',
                    }}
                    name="findings.enabled"
                    disabled={disableEdit}
                    hidden={findingsHidden}
                  />
                  <LapeNewSwitch
                    itemTypeProps={{
                      title: 'Enable Risks functionality',
                      description:
                        'Allows displaying risks related to the team/department in the system and the controls to mitigate them',
                    }}
                    name="risk.enabled"
                    disabled={disableEdit}
                    hidden={riskHidden}
                  />
                  <LapeNewSwitch
                    itemTypeProps={{
                      title: 'Enable Data Access functionality',
                      description:
                        'Allows team/department owners to configure automatic access to data for certain groups of employees part of the team.',
                    }}
                    name="data_access_requests.enabled"
                    disabled={disableEdit}
                    hidden={dataAccessRequestHidden}
                  />
                </>
              )}
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable download actions for talent and requisition tables',
                  description:
                    'Allows the download of company, department, and team data as csv or xlsx files',
                }}
                name="enable_download_actions_for_department_data"
                disabled={disableEdit}
              />
            </Group>
          </Box>

          <NewStepperTitle title="Teams" subtitle="Settings that apply to all teams" />
          <Box mt="s-24">
            <VStack space="s-16">
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Allow team owners to view salaries',
                  description:
                    'Allows the team owners to view the salaries of the people in their team',
                }}
                name="enable_team_owners_view_salaries"
                disabled={disableEdit}
              />
              <ApprovalSteps
                title="Enable team approval"
                description="Whenever a new team is created or an existing one is changed it asks for approval"
                switchField="enable_teams_approvals"
                approvalProcess="teams_approval_steps"
                entity="team"
                disabled={disableEdit}
                formRef={values}
              />
            </VStack>
          </Box>

          <NewStepperTitle
            title="Departments"
            subtitle="Settings that apply to all departments"
          />
          <VStack mt="s-24" space="s-16">
            <Group>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable Leadership functionality',
                  description:
                    'Enables managing who holds the key positions in the department and displaying this to other users',
                }}
                name="enable_leadership"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable Budget Management functionality',
                  description:
                    'Allows tracking department spending and compensation management for the talent',
                }}
                name="enable_budget_management"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable Karma functionality',
                  description:
                    'Allows measuring the impact of the findings and risks on the company by using Karma points',
                }}
                name="karma.enabled"
                disabled={disableEdit}
                hidden={karmaHidden}
              />
            </Group>

            <ApprovalSteps
              title="Enable Departments approval"
              description="Whenever a new department is created or an existing one is changed it asks for approval"
              switchField="enable_departments_approvals"
              approvalProcess="departments_approval_steps"
              entity="department"
              disabled={disableEdit}
              formRef={values}
            />
          </VStack>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.ORGANISATION_SETTINGS)
            queryClient.invalidateQueries(APPROVAL_PROCESS_GROUPS_API)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
