import React, { useState } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Flex, MoreBar, Widget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { OfferTemplateInterface } from '@src/interfaces/offerTemplates'
import { offerTemplatesTableRequests } from '@src/api/offerTemplates'
import { Plus } from '@revolut/icons'
import SettingsButtons from '@src/features/SettingsButtons'
import {
  offerTemplateCreatedByColumn,
  offerTemplateCreatedOnColumn,
  offerTemplateNameColumn,
} from '@src/constants/columns/offerForm/offerTemplates'
import OfferPreviewSidebar from '@components/OfferPreviewSidebar/OfferPreviewSidebar'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import SearchTable from '@components/Table/SearchTable/SearchTable'

const rows: RowInterface<OfferTemplateInterface> = {
  cells: [
    {
      ...offerTemplateNameColumn,
      width: 140,
    },
    {
      ...offerTemplateCreatedByColumn,
      width: 300,
    },
    {
      ...offerTemplateCreatedOnColumn,
      width: 160,
    },
  ],
}

const OfferTemplates = () => {
  const [openTemplateId, setOpenTemplateId] = useState<number>()
  const table = useTable<OfferTemplateInterface>(offerTemplatesTableRequests)
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddOfferFormTemplate)

  return (
    <>
      <OfferPreviewSidebar
        templateId={openTemplateId}
        onClose={() => setOpenTemplateId(undefined)}
      />
      <Widget p="s-16">
        <Flex flexWrap="wrap" justifyContent="right">
          <SearchTable
            placeholder="Search by name"
            mb="s-24"
            onFilter={table.onFilterChange}
          />
        </Flex>
        {canAdd && (
          <SettingsButtons mb="s-16">
            <MoreBar.Action
              useIcon={Plus}
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.OFFER_TEMPLATE.GENERAL)}
            >
              Create new
            </MoreBar.Action>
          </SettingsButtons>
        )}
        <Flex flexDirection="column" width="100%">
          <Flex style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable<OfferTemplateInterface>
              name={TableNames.OfferTemplates}
              useWindowScroll
              dataType="Offer templates"
              row={rows}
              {...table}
              noDataMessage="Offer templates will appear here."
              onRowClick={data => setOpenTemplateId(data.id)}
            />
          </Flex>
        </Flex>
      </Widget>
    </>
  )
}

export default OfferTemplates
