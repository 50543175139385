import React from 'react'
import { chain, Flex, Text, Token, Tooltip, useTooltip, VStack } from '@revolut/ui-kit'
import {
  KeeperGradeToString,
  KeeperQuestionInterface,
  ManagerRecommendationInterface,
  ReviewSummaryDataInterface,
} from '@src/interfaces/performance'
import { RecommendationType } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { RowInterface } from '@src/interfaces/data'
import {
  performanceSummaryBarRaiserReviewerColumn,
  performanceSummarySkillsNameColumn,
} from '@src/constants/columns/performanceSummary'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/Table/AdjustableTable'
import { BarRaiserBanner } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserBanner'

interface Props {
  data: ReviewSummaryDataInterface
  completedReviews?: boolean
}

interface RecommendationQuestionReviewInterface {
  recommendation: ManagerRecommendationInterface
  type: RecommendationType
}

export interface RecommendationQuestionInterface extends KeeperQuestionInterface {
  reviews: RecommendationQuestionReviewInterface[] | null
  questionIdx: number
}

const PeerReviewsWithTooltip = ({
  reviews,
  questionIdx,
}: {
  reviews?: RecommendationQuestionReviewInterface[] | null
  questionIdx: number
}) => {
  const tooltip = useTooltip()

  if (!reviews?.length) {
    return <Text variant="secondary">-</Text>
  }

  return (
    <Text variant="caption" textDecoration="underline" {...tooltip.getAnchorProps()}>
      {chain('View', reviews.length)}
      <Tooltip {...tooltip.getTargetProps()} placement="top">
        <VStack>
          {reviews.map(review => {
            const questionValue =
              review.recommendation.keeper_test_section.questions[questionIdx].value
            const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
            return (
              <Flex key={review.recommendation.reviewer?.id}>
                {chain(
                  <Text variant="small" fontWeight="bold">
                    {gradeValue}
                  </Text>,
                  <Text variant="small">
                    {review.recommendation.reviewer?.full_name}
                  </Text>,
                )}
              </Flex>
            )
          })}
        </VStack>
      </Tooltip>
    </Text>
  )
}

const getRow: (
  completedReviews: boolean,
) => RowInterface<RecommendationQuestionInterface> = completedReviews => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      title: 'Questions',
      width: 100,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const lmReview = data.reviews?.find(review => review.type === 'lm')
        const questionValue =
          lmReview?.recommendation.keeper_test_section.questions[data.questionIdx].value
        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        return lmReview ? gradeValue : '-'
      },
      width: 60,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const fmReview = data.reviews?.find(review => review.type === 'fm')
        const questionValue =
          fmReview?.recommendation.keeper_test_section.questions[data.questionIdx].value
        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        return fmReview ? gradeValue : '-'
      },
      title: 'FM',
      width: 60,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const peerReviews = data.reviews?.filter(review => review.type === 'peer')
        const questionValue =
          peerReviews?.[0]?.recommendation.keeper_test_section.questions[data.questionIdx]
            .value
        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        return completedReviews ? (
          <PeerReviewsWithTooltip reviews={peerReviews} questionIdx={data.questionIdx} />
        ) : (
          gradeValue
        )
      },
      title: 'Peer',
      width: 60,
    },
  ],
})

const getRecommendations = (data: ReviewSummaryDataInterface) => {
  const lmRecommendation = data.line_manager_extra_section
  const fmRecommendation = data.functional_manager_extra_section
  const peerRecommendations = data.peer_extra_sections

  if (!lmRecommendation && !fmRecommendation && !peerRecommendations?.length) {
    return null
  }

  return [
    ...(lmRecommendation
      ? [
          {
            recommendation: lmRecommendation,
            type: 'lm' as RecommendationType,
          },
        ]
      : []),
    ...(fmRecommendation
      ? [
          {
            recommendation: fmRecommendation,
            type: 'fm' as RecommendationType,
          },
        ]
      : []),
    ...(peerRecommendations
      ? [
          ...peerRecommendations.map(item => ({
            recommendation: item,
            type: 'peer' as RecommendationType,
          })),
        ]
      : []),
  ]
}

export const BarRaiserSummary = ({ data, completedReviews = false }: Props) => {
  const recommendations = getRecommendations(data)

  if (!recommendations?.length) {
    return null
  }

  const tableData = recommendations[0].recommendation.keeper_test_section.questions.map(
    (question, idx) => ({
      ...question,
      reviews: recommendations,
      questionIdx: idx,
    }),
  )

  return (
    <VStack space="s-16">
      <Text variant="h6" color={Token.color.greyTone50}>
        Bar raiser
      </Text>
      <BarRaiserBanner />
      <Flex style={{ position: 'relative' }} flex="1 0">
        <AdjustableTable
          name={TableNames.PerformanceSummaryBarRaiser}
          row={getRow(completedReviews)}
          count={tableData.length}
          data={tableData}
          hideCountAndButtonSection
        />
      </Flex>
    </VStack>
  )
}
