import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import {
  CandidateInterface,
  CandidateOpenedSidebarType,
  InterviewFeedbackStatus,
  InterviewRoundState,
  InterviewStatuses,
} from '@src/interfaces/interviewTool'
import { selectorKeys } from '@src/constants/api'
import React from 'react'
import {
  Box,
  Color,
  Tag,
  TagBar,
  Text,
  TextButton,
  textChain,
  Token,
} from '@revolut/ui-kit'
import isSameDay from 'date-fns/isSameDay'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { formatDate } from '@src/utils/format'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import AddInterviewFeedbackAction from '@components/AddInterviewFeedbackAction/AddInterviewFeedbackAction'
import { formatDistanceToNowStrict } from 'date-fns'
import upperFirst from 'lodash/upperFirst'
import { getRequisitionsSelectorOptions } from '@src/api/requisitions'
import Tooltip from '@components/Tooltip/Tooltip'
import { formatExperienceDateRange } from '@src/utils/hiring'
import TextWithMoreCount from '@components/TextWithMoreCount/TextWithMoreCount'
import styled from 'styled-components'

const StyledTooltip = styled(Tooltip)`
  justify-content: flex-start;
`

export const getInterviewStatusColor = (
  status?: InterviewStatuses | InterviewFeedbackStatus,
) => {
  switch (status) {
    case InterviewStatuses.pending_scheduling:
    case InterviewStatuses.pending_candidate_response:
    case InterviewStatuses.awaiting_interviewer_availability:
    case InterviewStatuses.awaiting_feedback:
    case InterviewStatuses.pending_cv_screening:
      return Color.ORANGE

    case InterviewStatuses.interviewer_rejected:
    case InterviewStatuses.scheduling_expired:
    case InterviewStatuses.interview_cancelled:
      return Color.RED

    default:
      return Color.FOREGROUND
  }
}

export const getInterviewStateColor = (state?: InterviewRoundState) => {
  switch (state) {
    case InterviewRoundState.archived:
      return Color.GREY_TONE_50

    case InterviewRoundState.hired:
      return Color.GREEN

    default:
      return Color.FOREGROUND
  }
}

export const candidateNameColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'full_name',
  sortKey: 'full_name',
  filterKey: 'id',
  selectorsKey: selectorKeys.interviews_candidates,
  dynamicHyperlinks: data => pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: data.id }),
  title: 'Name',
}

export const candidateSpecialisationColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.text,
  idPoint: 'active_interview_round.specialisation.id',
  dataPoint: 'active_interview_round.specialisation.name',
  sortKey: 'active_specialisation__name',
  filterKey: 'active_specialisation',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role (Specialisation)',
}

export const candidateSeniorityColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.text,
  idPoint: 'active_interview_round.seniority.id',
  dataPoint: 'active_interview_round.seniority.name',
  sortKey: 'active_interview_round__seniority__level',
  filterKey: 'active_interview_round__seniority',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const candidateTagsColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.insert,
  idPoint: 'tags',
  dataPoint: 'tags',
  sortKey: null,
  filterKey: 'tags',
  selectorsKey: selectorKeys.candidate_tags,
  title: 'Tags',
  notHoverable: true,
  insert: ({ data }) => {
    if (!data.tags?.length) {
      return null
    }
    return (
      <TagBar>
        {data.tags.map(tag => (
          <Tag key={tag.id} variant="faded">
            {tag.name}
          </Tag>
        ))}
      </TagBar>
    )
  },
}

export const candidateRecruiterColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.insert,
  idPoint: 'recruiter.id',
  dataPoint: 'recruiter.display_name',
  sortKey: 'recruiter__full_name',
  filterKey: 'recruiter',
  selectorsKey: selectorKeys.employee,
  title: 'Recruiter',
  insert: ({ data }) => (data.recruiter ? <UserWithAvatar {...data.recruiter} /> : '-'),
}

export const candidateInterviewStageColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.text,
  idPoint: 'active_interview_round.latest_interview_stage.interview_type',
  dataPoint: 'active_interview_round.latest_interview_stage.title',
  sortKey: 'active_interview_round__latest_interview_stage__title',
  filterKey: 'active_interview_round__latest_interview_stage__hiring_stage',
  selectorsKey: selectorKeys.specialisation_hiring_stages,
  title: 'Interview Stage',
}

export const candidateLastActivityDateColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.insert,
  idPoint: 'active_interview_round.latest_interview_stage_updated_date_time',
  dataPoint: 'active_interview_round.latest_interview_stage_updated_date_time',
  sortKey: 'active_interview_round__latest_interview_stage_updated_date_time',
  filterKey: 'active_interview_round__latest_interview_stage_updated_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Stage changed',
  insert: ({ data }) => {
    const date = data.active_interview_round?.latest_interview_stage_updated_date_time
    if (!date) {
      return '-'
    }

    return (
      <Box>
        <Text>
          {isSameDay(new Date(), new Date(date))
            ? 'Today'
            : formatDistanceToNowStrict(new Date(date), { addSuffix: true })}
        </Text>
        <Text pl="s-4" color={Color.GREY_TONE_50}>
          ({formatDate(date)})
        </Text>
      </Box>
    )
  },
}

export const candidateStatusColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.insert,
  idPoint: 'active_interview_round.latest_interview_stage.scheduling_status',
  dataPoint: 'active_interview_round.latest_interview_stage.scheduling_status_display',
  sortKey: 'active_interview_round__latest_interview_stage__scheduling_status',
  filterKey: 'active_interview_round__latest_interview_stage__scheduling_status',
  selectorsKey: selectorKeys.interview_stage_scheduling_statuses,
  title: 'Status',
  insert: ({ data }) => {
    return (
      <Text
        color={getInterviewStatusColor(
          data.active_interview_round?.latest_interview_stage?.scheduling_status,
        )}
      >
        {data.active_interview_round?.latest_interview_stage?.scheduling_status_display ||
          '-'}
      </Text>
    )
  },
}

export const candidateStateColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.insert,
  idPoint: 'active_interview_round.state',
  dataPoint: 'active_interview_round.state',
  sortKey: 'active_interview_round__state',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Candidate Status',
  insert: ({ data }) => {
    return (
      <Text color={getInterviewStateColor(data.active_interview_round?.state)}>
        {upperFirst(data.active_interview_round?.state) || '-'}
      </Text>
    )
  },
}

export const candidateOriginColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.text,
  idPoint: 'origin',
  dataPoint: 'origin',
  sortKey: 'origin',
  filterKey: 'origin',
  selectorsKey: selectorKeys.candidate_origin_choices,
  title: 'Origin',
}

export const candidateActionColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    const interview = data.active_interview_round?.latest_interview_stage
    const status = interview?.scheduling_status

    switch (status) {
      case InterviewStatuses.not_started:
      case InterviewStatuses.pending_scheduling:
        return (
          <TextButton
            fontWeight={500}
            onClick={e => {
              e.stopPropagation()

              navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: data.id }), {
                sidebar: {
                  type: 'schedule',
                  mode: 'scheduling',
                } as CandidateOpenedSidebarType,
              })
            }}
          >
            Schedule
          </TextButton>
        )

      case InterviewStatuses.interview_scheduled:
      case InterviewStatuses.awaiting_feedback:
      case InterviewStatuses.feedback_submitted:
      case InterviewStatuses.pending_cv_screening:
        return <AddInterviewFeedbackAction interview={interview} candidateId={data.id} />

      default:
        return '-'
    }
  },
}

export const candidateInterviewerColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.insert,
  idPoint: 'latest_interviewer',
  dataPoint: 'latest_interviewer',
  sortKey: 'latest_interviewer',
  filterKey: 'latest_interviewer',
  selectorsKey: selectorKeys.employee,
  title: 'Interviewer',
  insert: ({ data }) =>
    data.latest_interviewer ? <UserWithAvatar {...data.latest_interviewer} /> : '-',
}

export const candidateNextInterviewColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'latest_event_date_time',
  dataPoint: 'latest_event_date_time',
  sortKey: 'latest_event_date_time',
  filterKey: 'latest_event_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Next interview',
}

export const candidateOfferStartDateColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.date,
  idPoint: 'last_offer_sent.anticipated_start_date',
  dataPoint: 'last_offer_sent.anticipated_start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start date',
}

export const candidateOfferLocationColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.text,
  idPoint: 'last_offer_sent.location.id',
  dataPoint: 'last_offer_sent.location.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Offer location',
}

export const candidateOfferTeamColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.text,
  idPoint: 'last_offer_sent.team.id',
  dataPoint: 'last_offer_sent.team.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Team',
}

export const candidateOfferApprovalStatusColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.text,
  idPoint: 'last_offer_sent.status',
  dataPoint: 'last_offer_sent.status',
  sortKey: 'last_offer_sent__status',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Approval status',
}

export const createCandidateRequisitionColumn = (
  isConfidential?: boolean,
): ColumnInterface<CandidateInterface> => ({
  type: CellTypes.text,
  idPoint: 'active_interview_round.requisition.requisition_title',
  dataPoint: 'active_interview_round.requisition.requisition_title',
  sortKey: 'active_interview_round__requisition',
  filterKey: 'active_interview_round__requisition',
  selectorsKey: () => getRequisitionsSelectorOptions(isConfidential),
  title: 'Requisition',
})

export const candidateWorkExperienceColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.insert,
  idPoint: 'work_experiences',
  dataPoint: 'work_experiences',
  sortKey: 'current_work_experience__company__name',
  filterKey: 'work_experiences__company',
  selectorsKey: selectorKeys.work_experience_companies,
  title: 'Company',
  insert: ({ data }) =>
    data.work_experiences?.length ? (
      <StyledTooltip
        placement="top"
        useFormatting
        text={data.work_experiences
          .map(item =>
            textChain(
              formatExperienceDateRange(item.start_date, item.end_date),
              item.company?.name || item.other_company_name,
            ),
          )
          .join('\n')}
      >
        <TextWithMoreCount
          hideSuffix
          visibleCount={3}
          items={data.work_experiences.reduce<React.ReactNode[]>((acc, item) => {
            const name = item.company?.name || item.other_company_name

            if (name) {
              acc.push(
                <Text color={item.end_date ? Token.color.greyTone50 : undefined}>
                  {name}
                </Text>,
              )
            }

            return acc
          }, [])}
        />
      </StyledTooltip>
    ) : (
      '-'
    ),
}

export const candidateYearsOfExperienceColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.text,
  idPoint: 'years_of_experience',
  dataPoint: 'years_of_experience',
  sortKey: 'years_of_experience',
  filterKey: 'years_of_experience',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.range,
  title: 'Years of exp',
}

export const candidateSnoozedUntilDateColumn: ColumnInterface<CandidateInterface> = {
  type: CellTypes.date,
  filterType: FilterType.shortDate,
  idPoint: 'active_snoozing.snooze_until',
  dataPoint: 'active_snoozing.snooze_until',
  sortKey: 'active_snoozing__snooze_until',
  filterKey: 'active_snoozing__snooze_until',
  selectorsKey: selectorKeys.none,
  title: 'Snoozed until',
}
