import React, { useState, useEffect } from 'react'
import { BREAKPOINTS, Button, TabBar } from '@revolut/ui-kit'
import { addMonths } from 'date-fns'

import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { checkIsExpired, Form, Header } from './common'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GroupAccessManagementInterface } from '@src/interfaces/accessManagement'
import Users from './Users'
import { PageActions } from '@src/components/Page/PageActions'
import { localDateToUtc } from '@src/utils/timezones'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router-dom'

const DEFAULT_CERTIFICATION_DATE_VALIDITY_IN_MONTHS = 3

type RecertifyTab = 'users' | 'details'

export const Recertify = () => {
  const params = useParams()

  const [recertifyTab, setRecertifyTab] = useState<RecertifyTab>('users')

  const { values } = useLapeContext<GroupAccessManagementInterface>()

  useEffect(() => {
    const isExpired = checkIsExpired(values.settings?.certification_expiration_date_time)

    if (isExpired && values.settings?.certification_expiration_date_time) {
      values.settings.certification_expiration_date_time = localDateToUtc(
        addMonths(new Date(), DEFAULT_CERTIFICATION_DATE_VALIDITY_IN_MONTHS),
      )
    }
  }, [])

  return (
    <>
      <Header name={values.name}>
        <ActionWidget
          title="The certification for this permission group has expired!"
          text={
            recertifyTab === 'users'
              ? 'Please review who is part of the group and decide if the access should stay in place or be removed'
              : 'Set a new date in the future when this should be reviewed again!'
          }
          maxWidth={BREAKPOINTS.md}
          mb="s-16"
        />
        <TabBar
          value={recertifyTab}
          onChange={tab => setRecertifyTab(tab as RecertifyTab)}
          mb="s-16"
        >
          <TabBar.Item to="users">Users</TabBar.Item>
          <TabBar.Item to="details">Details</TabBar.Item>
        </TabBar>
      </Header>

      {recertifyTab === 'users' && (
        <>
          <Users />
          <PageActions>
            <Button onClick={() => setRecertifyTab('details')} elevated>
              Continue
            </Button>
          </PageActions>
        </>
      )}
      {recertifyTab === 'details' && (
        <>
          <Form />
          <PageActions>
            <NewSaveButtonWithPopup
              successText="Group was recertified"
              onAfterSubmit={() =>
                navigateReplace(
                  pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.DETAILS, params),
                )
              }
              useValidator
            />
          </PageActions>
        </>
      )}
    </>
  )
}
