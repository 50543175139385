import { Button, ButtonProps, Side } from '@revolut/ui-kit'
import React, { PropsWithChildren } from 'react'

export const SidebarAction = ({ children, ...props }: PropsWithChildren<ButtonProps>) => {
  return (
    <Side.Actions alignSelf="center">
      <Button elevated width="350px" {...props}>
        {children}
      </Button>
    </Side.Actions>
  )
}
