import React from 'react'
import { Button, HStack, Popup, Text } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

type Props = {
  open: boolean
  pending: boolean
  title: string
  subtitle?: string
  onClose: () => void
  onSubmit: () => Promise<void>
  content?: React.ReactNode
  successPopupText?: string
}
export const SubmitPopup = ({
  open,
  pending,
  title,
  subtitle,
  onClose,
  onSubmit,
  content,
  successPopupText,
}: Props) => {
  return (
    <Popup open={open} onClose={onClose} variant="bottom-sheet">
      <Text variant="h5" use="div" mb="s-16">
        {title}
      </Text>
      <Text use="p" variant="caption" color="grey-tone-50">
        {subtitle}
      </Text>
      {content}
      <Popup.Actions>
        <HStack space="s-16">
          <Button variant="secondary" size="md" onClick={onClose} disabled={pending}>
            Cancel
          </Button>
          {successPopupText ? (
            <NewSaveButtonWithPopup
              pending={pending}
              successText={successPopupText}
              onClick={onSubmit}
            >
              Confirm
            </NewSaveButtonWithPopup>
          ) : (
            <Button elevated size="md" pending={pending} onClick={onSubmit}>
              Confirm
            </Button>
          )}
        </HStack>
      </Popup.Actions>
    </Popup>
  )
}
