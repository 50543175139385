import React from 'react'
import { IconButton } from '@revolut/ui-kit'
import { QuestionOutline } from '@revolut/icons'
import { useSelector } from 'react-redux'

import { MainHeaderButton } from './MainHeaderButton'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useGetHelpCenterSettings } from '@src/api/settings'
import { useOpenHelpCenter } from '@components/HelpCenter/hooks'

export const HelpCenter = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const { data } = useGetHelpCenterSettings(isCommercial)

  const { openHelpCenter } = useOpenHelpCenter()

  if (isCommercial || !data?.help_section_enabled) {
    return null
  }

  return (
    <MainHeaderButton>
      <IconButton
        useIcon={QuestionOutline}
        aria-label="Help Center"
        size={24}
        onClick={openHelpCenter}
        color="grey-tone-50"
      />
    </MainHeaderButton>
  )
}
