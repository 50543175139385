import React from 'react'
import { Tag } from '@revolut/ui-kit'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  CultureValueInterface,
  CultureValueTypeTitle,
} from '@src/interfaces/cultureValues'
import { RevolutTheme } from '@src/styles/theme'
import TableCellLink from '@components/TableCellLink/TableCellLink'
import { pathToUrl } from '@src/utils/router'

export const valuesNameColumn: ColumnInterface<CultureValueInterface> = {
  type: CellTypes.insert,
  title: 'Value name',
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return (
      <TableCellLink
        to={getLocationDescriptor(pathToUrl(ROUTES.FORMS.VALUE.PREVIEW, { id: data.id }))}
      >
        {data.name}
      </TableCellLink>
    )
  },
}

export const valuesCategoryColumn: ColumnInterface<CultureValueInterface> = {
  type: CellTypes.insert,
  title: 'Category',
  idPoint: 'category',
  dataPoint: 'category',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return <Tag variant="faded">{CultureValueTypeTitle[data.category]}</Tag>
  },
}

export const valuesBehavioursCountColumn: ColumnInterface<CultureValueInterface> = {
  type: CellTypes.text,
  title: 'Number of behaviours',
  idPoint: 'behaviours_count',
  dataPoint: 'behaviours_count',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
}

export const valuesPlaybookLinkColumn: ColumnInterface<CultureValueInterface> = {
  type: CellTypes.link,
  idPoint: 'playbook_link',
  dataPoint: 'playbook_link',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Link to playbook',
}

export const valuesStatusColumn: ColumnInterface<CultureValueInterface> = {
  type: CellTypes.insert,
  title: 'Active',
  idPoint: 'is_enabled',
  dataPoint: 'is_enabled',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  colors: (data: CultureValueInterface, theme: RevolutTheme) => {
    return data.is_enabled ? theme.colors.green : theme.colors['grey-tone-50']
  },
  insert: ({ data }) => {
    return data.is_enabled ? 'Enabled' : 'Disabled'
  },
}
