import React, { useEffect, useState } from 'react'
import {
  Text,
  Avatar,
  Group,
  HStack,
  Item,
  ItemSkeleton,
  StatusWidget,
  TextButton,
  VStack,
  Color,
} from '@revolut/ui-kit'
import { ChevronDown, ChevronUp, EyeHide, EyeShow, Shield } from '@revolut/icons'

import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { formatDate } from '@src/utils/format'
import { getResponsibilityAssignmentStatusColor } from '@src/apps/People/KeyPersons/helpers'
import SideBar from '@components/SideBar/SideBar'
import { getEmployeeKeyPersonResponsibilitiesAssignments } from '@src/api/keyPerson'
import { KeyPersonResponsibilityAssignmentInterface } from '@src/interfaces/keyPerson'
import { RevolutTheme, useTheme } from '@src/styles/theme'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'

const ResponsibilityItem = ({
  theme,
  responsibility,
}: {
  theme: RevolutTheme
  responsibility: KeyPersonResponsibilityAssignmentInterface
}) => {
  const validFrom = responsibility.start_date
    ? `from ${formatDate(responsibility.start_date)} `
    : ''
  const validUntil = responsibility.end_date
    ? `until ${formatDate(responsibility.end_date)}`
    : ''

  return (
    <Item
      key={responsibility.id}
      use="button"
      onClick={() =>
        navigateTo(
          pathToUrl(ROUTES.APPS.KEY_PERSONS.RESPONSIBILITY.PREVIEW, {
            id: String(responsibility.key_person_type.id),
          }),
        )
      }
    >
      <Item.Avatar>
        <Avatar useIcon={Shield} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{responsibility.key_person_type.name}</Item.Title>
        <Item.Description>
          <VStack>
            <Text>{responsibility.entity?.name}</Text>
            {validFrom || validUntil ? (
              <Text>{`Valid ${validFrom}${validUntil}`}</Text>
            ) : null}
          </VStack>
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Title
          color={getResponsibilityAssignmentStatusColor(
            theme,
            responsibility.assignment_status?.id,
          )}
        >
          {responsibility.assignment_status?.name}
        </Item.Title>
        <Item.Description>{responsibility.assignment_period.name}</Item.Description>
      </Item.Side>
    </Item>
  )
}

type Props = {
  employeeId?: number
  isOpen: boolean
  onClose: () => void
}
export const AssignedResponsibilitiesSidebar = ({
  employeeId,
  isOpen,
  onClose,
}: Props) => {
  const theme = useTheme()

  const [isResponsibilitiesLoading, setIsResponsibilitiesLoading] = useState(false)
  const [responsibilities, setResponsibilities] = useState<
    KeyPersonResponsibilityAssignmentInterface[]
  >([])
  const [isArchivedShown, setIsArchivedShown] = useState<boolean>(false)

  useEffect(() => {
    if (employeeId) {
      setIsResponsibilitiesLoading(true)
      getEmployeeKeyPersonResponsibilitiesAssignments(employeeId)
        .then(({ data }) => {
          setResponsibilities(data)
        })
        .finally(() => setIsResponsibilitiesLoading(false))
    }
  }, [employeeId])

  const activeResponsibilities = responsibilities.filter(
    ({ assignment_status }) => assignment_status?.id !== 'archived',
  )
  const archivedResponsibilities = responsibilities.filter(
    ({ assignment_status }) => assignment_status?.id === 'archived',
  )

  return (
    <SideBar
      variant="wide"
      title="Assigned responsibilities"
      subtitle={employeeId ? <EmployeeUserWithAvatar id={employeeId} /> : undefined}
      isOpen={isOpen}
      onClose={onClose}
    >
      <>
        {isResponsibilitiesLoading ? (
          <Group>
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </Group>
        ) : (
          <VStack space="s-24">
            {activeResponsibilities.length ? (
              <Group>
                {activeResponsibilities.map(responsibility => (
                  <ResponsibilityItem
                    key={responsibility.id}
                    theme={theme}
                    responsibility={responsibility}
                  />
                ))}
              </Group>
            ) : (
              <StatusWidget>
                <StatusWidget.Image
                  image={{
                    default: 'https://assets.revolut.com/assets/3d-images/3D086.png',
                    '2x': 'https://assets.revolut.com/assets/3d-images/3D086@2x.png',
                    '3x': 'https://assets.revolut.com/assets/3d-images/3D086@3x.png',
                  }}
                />
                <StatusWidget.Title>
                  No active responsibilities assigned
                </StatusWidget.Title>
                {!!archivedResponsibilities.length && (
                  <StatusWidget.Action
                    useIcon={isArchivedShown ? EyeHide : EyeShow}
                    onClick={() => setIsArchivedShown(!isArchivedShown)}
                  >
                    {isArchivedShown ? 'Hide archived' : 'Show archived'}
                  </StatusWidget.Action>
                )}
              </StatusWidget>
            )}
            {archivedResponsibilities.length ? (
              <VStack space="s-16">
                {!!activeResponsibilities.length && !!archivedResponsibilities.length && (
                  <TextButton onClick={() => setIsArchivedShown(!isArchivedShown)}>
                    <HStack align="center" space="s-4">
                      <Text>{isArchivedShown ? 'Hide archived' : 'Show archived'}</Text>
                      {isArchivedShown ? (
                        <ChevronUp color={Color.BLUE} size={16} />
                      ) : (
                        <ChevronDown color={Color.BLUE} size={16} />
                      )}
                    </HStack>
                  </TextButton>
                )}
                {isArchivedShown && (
                  <Group>
                    {archivedResponsibilities.map(responsibility => (
                      <ResponsibilityItem
                        key={responsibility.id}
                        theme={theme}
                        responsibility={responsibility}
                      />
                    ))}
                  </Group>
                )}
              </VStack>
            ) : null}
          </VStack>
        )}
      </>
    </SideBar>
  )
}
