import React, { useMemo, useState } from 'react'
import {
  Button,
  Color,
  Input,
  InputGroup,
  SelectOptionItemType,
  Side,
  Subheader,
} from '@revolut/ui-kit'
import omit from 'lodash/omit'
import { arrayErrorsToFormError } from '@src/utils/form'
import SideBar from '@src/components/SideBar/SideBar'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { DatePickerInput } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import { localDateToUtc } from '@src/utils/timezones'
import { selectorKeys } from '@src/constants/api'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import NewMultiSelect from '@src/components/Inputs/NewMultiSelect/NewMultiSelect'
import HTMLEditor from '@src/components/HTMLEditor/HTMLEditor'
import { AxiosPromise } from 'axios'
import { ImportFieldType } from '@src/interfaces/bulkDataImport'
import { CustomFieldsRouter } from '@src/components/Stepper/LapeCustomFieldsRouter'
import { CustomFieldsForSectionInterface } from '@src/interfaces/customFields'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { OptionInterface, SelectorType } from '@src/interfaces/selectors'

interface RowEditSidebarProps<T> {
  fields: ImportFieldType<T>[]
  fieldValues: {
    hasErrors: boolean
    visibleFields: string[]
    hiddenFields: string[]
    extraFields: string[]
  }
  rowData?: ImportInterface<T>
  sessionId: number
  onClose: () => void
  apiEndpoint: string
  onEditImportSessionRow: (
    apiEndpoint: string,
    sessionId: number,
    rowId: number,
    data: ImportInterface<T>,
  ) => AxiosPromise<ImportInterface<T>>
  onTableRefresh: () => void
  customFields: CustomFieldsForSectionInterface[]
}

type StringMultiSelectProps = {
  placeholder: string
  selector: SelectorType
  values: string
  valueKey: string
  onChange: (value: string) => void
}

type OptionNames = {
  [key: string]: SelectOptionItemType<OptionInterface>
}

const StringMultiSelect = ({
  placeholder,
  selector,
  values,
  valueKey,
  onChange,
  ...props
}: StringMultiSelectProps) => {
  const { options } = useFetchOptions<OptionInterface>(selector)

  const optionNames = useMemo(() => {
    return options.reduce((acc, option) => {
      return {
        ...acc,
        [option.value.name]: option,
      }
    }, {}) as OptionNames
  }, [options])

  const createErrorValue = (value: string) => ({
    key: value,
    label: value,
    value: {
      id: value,
      name: value,
      color: Color.RED,
    },
  })

  const mapToValues = () => {
    const split = values.split(/\s?;\s?/)
    if (!options) {
      return []
    }
    return split.map(value => {
      return optionNames[value] ?? createErrorValue(value)
    })
  }

  if (!options.length) {
    return null
  }
  return (
    <NewMultiSelect
      {...props}
      placeholder={placeholder}
      options={options}
      value={mapToValues()}
      onChange={newValues => {
        onChange(
          newValues
            .map(({ value }) => {
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              return value[valueKey]
            })
            .join(';'),
        )
      }}
    />
  )
}

type HTMLFieldProps = {
  label: string
  value: string
  onChange: (val?: string) => void
}

const HTMLField = ({ label, value, onChange, ...props }: HTMLFieldProps) => {
  return (
    <InputGroup>
      <Subheader variant="nested">
        <Subheader.Title>{label}</Subheader.Title>
      </Subheader>
      <HTMLEditor
        nonResizable
        height={300}
        value={value}
        onChange={val => {
          if (val !== value) {
            onChange(val)
          }
        }}
        {...props}
      />
    </InputGroup>
  )
}

const RowEditSidebar = <T,>({
  fields,
  fieldValues,
  rowData,
  sessionId,
  apiEndpoint,
  onClose,
  onEditImportSessionRow,
  onTableRefresh,
  customFields,
}: RowEditSidebarProps<T>) => {
  const [savingRowChanges, setSavingRowChanges] = useState(false)
  const [sidebarData, setSidebarData] = useState<ImportInterface<T>>()

  const { forceErrors } = useSafeFormValidator()

  if (rowData && !sidebarData) {
    setSidebarData(rowData)
  }

  const onSaveRowChanges = async () => {
    if (sidebarData) {
      setSavingRowChanges(true)

      try {
        const { data } = await onEditImportSessionRow(
          apiEndpoint,
          sessionId,
          sidebarData.id,
          sidebarData,
        )
        onTableRefresh()

        if (Object.keys(data.errors)?.length) {
          setSidebarData(data)
          forceErrors(arrayErrorsToFormError(data.errors))
        } else {
          onClose()
        }
      } finally {
        setSavingRowChanges(false)
      }
    }
  }

  const onChangeRowValue = (field: string, value?: string | number | null) => {
    if (sidebarData) {
      setSidebarData({
        ...sidebarData,
        errors: omit(sidebarData.errors, field) as Partial<Record<keyof T, string[]>>,
        data: { ...sidebarData.data, [field]: value },
      })
    }
  }

  const getErrorProps = (field: string) => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const fieldErrors = sidebarData?.errors[field]

    if (fieldErrors?.length) {
      return {
        hasError: true,
        message: fieldErrors.join('\n'),
        'aria-invalid': true,
      }
    }

    return {}
  }

  return (
    <SideBar title="Edit" isOpen={!!sidebarData} onClose={onClose} variant="wide">
      {sidebarData && (
        <InputGroup>
          {fields
            .filter(f => fieldValues.visibleFields.includes(f.field as string))
            .map(field => {
              const fieldKey = field.field as string
              if (field.type === 'input') {
                return (
                  <Input
                    label={field.label}
                    // @ts-ignore TODO: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                    value={sidebarData?.data[fieldKey]}
                    onChange={e => onChangeRowValue(fieldKey, e.currentTarget.value)}
                    {...getErrorProps(fieldKey)}
                    data-name={fieldKey}
                    key={fieldKey}
                  />
                )
              }

              if (field.type === 'radio-select') {
                const isEmployeeField = field.selector === selectorKeys.employee_emails

                return (
                  <RadioSelectInput
                    label={field.label}
                    selector={field.selector}
                    value={{
                      name: null,
                      full_name: null,
                      // @ts-ignore TODO: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                      [field.valueKey]: sidebarData?.data[fieldKey] || null,
                    }}
                    onChange={e => onChangeRowValue(fieldKey, e?.[field.valueKey])}
                    labelPath={isEmployeeField ? 'full_name' : 'name'}
                    valueKey={field.valueKey}
                    allowCreateNewOption={field.allowCreateNewOption}
                    {...getErrorProps(fieldKey)}
                    inputProps={{ 'data-name': fieldKey }}
                    key={fieldKey}
                  />
                )
              }

              if (field.type === 'date' || field.type === 'date-no-timezone') {
                const formatDate = (date: Date) =>
                  field.type === 'date-no-timezone'
                    ? localDateToUtc(date).split('Z')[0]
                    : localDateToUtc(date)
                return (
                  <DatePickerInput
                    label={field.label}
                    // @ts-ignore TODO: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                    value={sidebarData?.data[fieldKey]}
                    onChange={e => onChangeRowValue(fieldKey, e ? formatDate(e) : '')}
                    {...getErrorProps(fieldKey)}
                    data-name={fieldKey}
                    key={fieldKey}
                  />
                )
              }

              if (field.type === 'multi-select') {
                return (
                  <StringMultiSelect
                    placeholder={field.label}
                    selector={field.selector}
                    // @ts-ignore TODO: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                    values={sidebarData?.data[fieldKey] ?? ''}
                    valueKey={field.valueKey}
                    onChange={newValue => {
                      onChangeRowValue(fieldKey, newValue)
                    }}
                    {...getErrorProps(fieldKey)}
                    data-name={fieldKey}
                    key={fieldKey}
                  />
                )
              }
              if (field.type === 'html') {
                return (
                  <HTMLField
                    label={field.label}
                    // @ts-ignore TODO: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                    value={sidebarData?.data[fieldKey]}
                    onChange={e => {
                      // @ts-ignore TODO: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                      if (e !== sidebarData?.data[fieldKey]) {
                        onChangeRowValue(fieldKey, e)
                      }
                    }}
                    {...getErrorProps(fieldKey)}
                    data-name={fieldKey}
                  />
                )
              }
              return null
            })}
          {fieldValues.extraFields.map(field => {
            const customField = customFields.find(cf => cf.field_name === field)

            return customField ? (
              <CustomFieldsRouter
                field={customField}
                key={customField.id}
                /** @ts-ignore TODO: data can now have property of any name, would need to refactor a lot */
                value={sidebarData?.data[field]}
                onChange={value => {
                  onChangeRowValue(customField.field_name, value)
                }}
              />
            ) : (
              <Input
                label={field}
                /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                value={sidebarData?.data[field]}
                onChange={e => onChangeRowValue(field, e.currentTarget.value)}
                {...getErrorProps(field)}
                data-name={field}
                key={field}
              />
            )
          })}
        </InputGroup>
      )}
      <Side.Actions>
        <Button onClick={onSaveRowChanges} pending={savingRowChanges}>
          Save
        </Button>
      </Side.Actions>
    </SideBar>
  )
}

export const BulkDataImportSessionSidebar = <T,>(props: RowEditSidebarProps<T>) => {
  return (
    <FormValidatorProvider>
      <RowEditSidebar {...props} />
    </FormValidatorProvider>
  )
}
