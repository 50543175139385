import React, { useEffect, useState } from 'react'
import { chain, InputGroup, Text, TextSkeleton } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { LinkedAccountsInterface } from '@src/interfaces/linkedAccounts'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import AutoStepper from '@components/Stepper/AutoStepper'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { mapAccountStatusToProps } from '@src/utils/linkedAccounts'
import { navigateReplace } from '@src/actions/RouterActions'
import { REVOLUT_ACCOUNT_ID } from '@src/pages/Forms/LinkRevolutAccountForm/constants'
import { OptionInterface } from '@src/interfaces/selectors'
import { Statuses } from '@src/interfaces'
import { linkedFormRequests } from '@src/api/linkedAccounts'
import { get, omit } from 'lodash'
import { LapePhoneInput } from '@components/Inputs/LapeFields/LapePhoneInput'

export const AccountForm = () => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()
  const { values, reset } = useLapeContext<Partial<LinkedAccountsInterface>>()
  const [isPending, setIsPending] = useState<boolean>(false)

  const backUrl = values.id
    ? pathToUrl(ROUTES.FORMS.LINKED_ACCOUNT.PREVIEW, { id, employeeId })
    : pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.LINKED_ACCOUNTS, { id: employeeId })
  const { statusText } = mapAccountStatusToProps(values.status || Statuses.no_account)

  // TODO: we should not allow editing existing accounts after successful linking;
  //  this must be checked by permissions which currently is not implemented on the BE,
  //  so we temporarily rely on the account status here
  const isEditUnavailable = values.id && values.status === Statuses.linked

  useEffect(() => {
    if (!values.id || values.status === Statuses.archived) {
      values.account_type = { id: REVOLUT_ACCOUNT_ID } as OptionInterface
      values.status = Statuses.pending
    }
    if (values.status === Statuses.no_account || values.status === Statuses.pending) {
      values.personal_phone_number = ''
      values.personal_email = ''
      values.description = ''
    } else if (values.status === Statuses.failed) {
      reset(values)
    }
  }, [])

  const handleSubmit = async () => {
    // temporarily filling legacy field value for bw compat
    values.personal_phone_number =
      get(values, ['phone_country_code', 'code'], '') +
      get(values, 'phone_number_short', '')

    const valuesToSubmit = omit(values, ['phone_country_code', 'phone_number_short'])

    const getSubmitAction = () =>
      values.id
        ? linkedFormRequests
            .update(valuesToSubmit, { employeeId, id })
            .then(({ data }) => data)
        : linkedFormRequests
            .submit(valuesToSubmit, { employeeId })
            .then(({ data }) => data)

    if (values.status === Statuses.no_account) {
      setIsPending(true)
      values.status = Statuses.pending
      try {
        return await getSubmitAction()
      } catch (e) {
        setIsPending(false)
        values.status = Statuses.no_account
        throw e
      }
    }
    return getSubmitAction()
  }

  if (isEditUnavailable) {
    return null
  }

  return (
    <PageWrapper>
      <PageHeader
        title={
          isPending ? (
            <TextSkeleton size={16} />
          ) : (
            chain(
              'Revolut account',
              <Text
                variant="h1"
                color={
                  mapAccountStatusToProps(values.status || Statuses.no_account).color
                }
              >
                {statusText}
              </Text>,
            )
          )
        }
        subtitle="Please enter the below details to link your account."
        backUrl={backUrl}
      />
      <PageBody>
        <AutoStepper>
          <InputGroup>
            <LapePhoneInput
              prefixName="phone_country_code"
              phoneName="phone_number_short"
              message="This is the phone number under which the account is registered. Please provide it exactly as it's presented in the account details"
              required
            />
            <LapeNewTextArea
              name="personal_email"
              label="Email address as shown in the account"
              message="This is the personal email under which the account is registered"
              required
            />
            <LapeNewTextArea
              name="description"
              label="Notes"
              message="If you would like to add any information, please leave it in the above box. "
              required
              rows={2}
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Linked account successfully saved"
          onClick={handleSubmit}
          onAfterSubmit={res => {
            navigateReplace(
              pathToUrl(ROUTES.FORMS.LINKED_ACCOUNT.PREVIEW, {
                employeeId,
                id: String(res.id),
              }),
            )
          }}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}
