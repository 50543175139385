import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Cell,
  DragAndDrop,
  Flex,
  InputGroup,
  VStack,
  Text,
  TextButton,
  Token,
} from '@revolut/ui-kit'
import { Minus, Plus } from '@revolut/icons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ApproverId,
  PolicyApprovers,
  TimeOffPolicyInterface,
} from '@src/interfaces/timeOff'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import Tooltip from '@components/Tooltip/Tooltip'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import InputErrorMessage from '@components/InputErrorMessage/InputErrorMessage'
import DragIcon from '@src/components/DragIcon'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

export type ApprovalChainFieldErrors = {
  noApproversSelected?: boolean
  emptyFieldValueIndexes?: number[]
}

type Props = {
  errors?: ApprovalChainFieldErrors
  setErrors: (newValue?: ApprovalChainFieldErrors) => void
  showDescription?: boolean
  sortByInitial?: boolean
}
export const PolicyApprovalChainInput = ({
  errors,
  setErrors,
  showDescription = true,
  sortByInitial = true,
}: Props) => {
  const { initialValues, values } = useLapeContext<TimeOffPolicyInterface>()
  const [approversFormatted, setApproversFormatted] = useState(false)

  useEffect(() => {
    values.policy_approvers =
      (sortByInitial ? initialValues : values).policy_approvers
        ?.sort((prev, next) => prev.sort_order - next.sort_order)
        ?.map((approver, idx) => ({ ...approver, id: idx })) || []
    setApproversFormatted(true)
  }, [])

  const approvers = values.policy_approvers || []

  const dynamicGroupsOptionsData = useFetchOptions<IdAndName>(
    selectorKeys.dynamic_groups,
    undefined,
    'label',
  )
  const dynamicGroupsOptions = dynamicGroupsOptionsData.options.map(({ value }) => ({
    value,
    label: value.name,
  }))

  const approversOptionsData = useFetchOptions<IdAndName<ApproverId>>(
    selectorKeys.time_off_policy_default_approvers,
    undefined,
    'label',
  )
  const approversOptions = approversOptionsData.options.map(({ value }) => {
    if (value.id === 'default_approver') {
      const name = `Line Manager`
      return {
        label: name,
        value: { ...value, name },
      }
    }
    return {
      value,
      label: value.name,
    }
  })

  const [activeId, setActiveId] = useState<string | number | null>(null)
  const activeApproverIdx = activeId
    ? approvers.findIndex(({ id }) => String(id) === activeId)
    : -1
  const activeApprover = activeApproverIdx >= 0 ? approvers[activeApproverIdx] : undefined

  if (!approversFormatted) {
    return null
  }
  return (
    <Cell>
      <Box width="100%">
        <VStack>
          <Text variant="h6" color={Token.color.greyTone50} mb="s-16">
            Approvers
          </Text>
          {showDescription && (
            <Text color={Token.color.greyTone50} mb="s-16">
              Order of the items below will indicate the order of the approvals. At least
              one approver must be added. The default approver for each employee can vary
              based on the settings of the time off regime to which they are assigned. You
              can configure this{' '}
              <TextButton
                use={InternalLink}
                to={pathToUrl(ROUTES.SETTINGS.TIME_OFF.REGIMES)}
              >
                here
              </TextButton>
              .
            </Text>
          )}
          {approvers.length ? (
            <>
              <InputGroup>
                <DragAndDrop.Provider
                  onDragStart={event => {
                    setActiveId(event.active.id)
                  }}
                  onDragEnd={({ over }) => {
                    const overIdx = approvers.findIndex(
                      approver => String(approver.id) === over?.id,
                    )
                    const approversWithoutDragged = approvers.filter(
                      ({ id }) => String(id) !== activeId,
                    )
                    if (overIdx >= 0 && activeApprover) {
                      values.policy_approvers = [
                        ...approversWithoutDragged.slice(0, overIdx),
                        activeApprover,
                        ...approversWithoutDragged.slice(overIdx, approvers.length - 1),
                      ].map((approver, idx) => ({ ...approver, sort_order: idx }))
                    }
                    setActiveId(null)
                    setErrors(undefined)
                  }}
                >
                  <DragAndDrop.Sortable
                    id="sortable"
                    items={approvers.map(({ id }) => String(id))}
                  >
                    {sortable => {
                      const approverIdx = approvers.findIndex(
                        ({ id }) => String(id) === sortable.id,
                      )
                      const approver = approvers[approverIdx]

                      if (!approver) {
                        return null
                      }
                      return (
                        <Flex
                          gap="s-8"
                          alignItems="center"
                          ref={sortable?.setNodeRef}
                          {...sortable?.attributes}
                          {...sortable?.listeners}
                          style={
                            sortable
                              ? {
                                  transform: sortable.transform
                                    ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                                    : undefined,
                                  transition: sortable.transition || 'none',
                                  opacity: sortable.isDragging ? 0 : undefined,
                                  zIndex: 10,
                                }
                              : undefined
                          }
                        >
                          <DragIcon />
                          <Flex flex="1 0">
                            <LapeRadioSelectInput
                              height="100%"
                              label={
                                approverIdx === 0 ? 'Select approver' : 'Next approver'
                              }
                              searchable={false}
                              name={`policy_approvers[${approverIdx}].approver_type`}
                              options={approversOptions}
                            />
                          </Flex>
                          {approver.approver_type?.id === 'dynamic_group' && (
                            <Flex flexDirection="column" width="55%">
                              <LapeRadioSelectInput
                                label="Dynamic group"
                                name={`policy_approvers[${approverIdx}].groups[0]`}
                                options={dynamicGroupsOptions}
                                hasError={errors?.emptyFieldValueIndexes?.includes(
                                  approverIdx,
                                )}
                                onAfterChange={newValue => {
                                  if (
                                    newValue &&
                                    errors?.emptyFieldValueIndexes?.length
                                  ) {
                                    setErrors({
                                      ...errors,
                                      emptyFieldValueIndexes:
                                        errors.emptyFieldValueIndexes.filter(
                                          idx => idx !== approverIdx,
                                        ),
                                    })
                                  }
                                }}
                              />
                              {errors?.emptyFieldValueIndexes?.includes(approverIdx) && (
                                <InputErrorMessage message="This field is mandatory" />
                              )}
                            </Flex>
                          )}
                          <Tooltip
                            placement="bottom"
                            hide={approvers.length !== 1}
                            text="At least one approver should be added"
                          >
                            <Button
                              size="sm"
                              variant="secondary"
                              useIcon={Minus}
                              radius="round"
                              onClick={() => {
                                values.policy_approvers = approvers.filter(
                                  (_, idx) => idx !== approverIdx,
                                )
                              }}
                              disabled={approvers.length === 1}
                            />
                          </Tooltip>
                        </Flex>
                      )
                    }}
                  </DragAndDrop.Sortable>
                  <DragAndDrop.DragOverlay>
                    {activeApprover && (
                      <Flex gap="s-8" alignItems="center">
                        <DragIcon />
                        <Flex flex="1 0">
                          <LapeRadioSelectInput
                            label="Select approver"
                            searchable={false}
                            name={`policy_approvers[${activeApproverIdx}].approver_type`}
                          />
                        </Flex>
                        {activeApprover.approver_type?.id === 'dynamic_group' && (
                          <Flex width="55%">
                            <LapeRadioSelectInput
                              label="Dynamic group"
                              name={`policy_approvers[${activeApproverIdx}].groups[0]`}
                            />
                          </Flex>
                        )}
                        <Button
                          size="sm"
                          variant="secondary"
                          useIcon={Minus}
                          radius="round"
                        />
                      </Flex>
                    )}
                  </DragAndDrop.DragOverlay>
                </DragAndDrop.Provider>
              </InputGroup>
              <Box>
                <Button
                  mt="s-16"
                  width={160}
                  variant="secondary"
                  useIcon={<Plus size={16} />}
                  onClick={() => {
                    values.policy_approvers![approvers.length] = {
                      id: approvers.length,
                      approver_type: approversOptions[0]?.value,
                      sort_order: approvers.length,
                    }
                  }}
                >
                  Add approver
                </Button>
              </Box>
            </>
          ) : (
            <Box width="100%">
              <Flex flex="1 0">
                <Flex flexDirection="column" width="100%">
                  <LapeRadioSelectInput
                    label="Select approver"
                    searchable={false}
                    name="policy_approvers[0].approver_type"
                    onChange={newValue => {
                      if (newValue) {
                        delete errors?.noApproversSelected
                        setErrors(errors)

                        values.policy_approvers = [
                          {
                            id: 0,
                            approver_type: newValue as IdAndName<ApproverId>,
                            sort_order: 0,
                          },
                        ] as PolicyApprovers
                      }
                    }}
                    hasError={errors?.noApproversSelected}
                    options={approversOptions}
                  />
                  {errors?.noApproversSelected && (
                    <InputErrorMessage message="This field is mandatory" />
                  )}
                </Flex>
              </Flex>
            </Box>
          )}
        </VStack>
      </Box>
    </Cell>
  )
}
