import React, { useEffect, useState } from 'react'
import { Box, Color, Flex, Text } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import Tickets from '@src/pages/Forms/ProbationOverview/ProbationGoals/Tickets'
import {
  addProbationGoal,
  deleteProbationGoal,
  getProbationGoals,
} from '@src/api/probationReview'
import {
  JiraIssueInterface,
  ProbationCheckpoints,
  ProbationGoalInterface,
} from '@src/interfaces/probationReview'
import ActionCard from '@components/ActionCard/ActionCard'
import { PageBody } from '@src/components/Page/PageBody'
import ApproveGoalsWidget from '@src/pages/Forms/ProbationOverview/ProbationGoals/ApproveGoalsWidget'
import { Statuses } from '@src/interfaces'
import Loader from '@components/CommonSC/Loader'
import GoalsJiraDisabled from '@src/pages/EmployeeProfile/Preview/Performance/Goals/GoalsJiraDisabled'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'

type Props = {
  data: EmployeeInterface
  cycleId: string
  checkpoints?: ProbationCheckpoints
  refetch: () => Promise<any>
}

const ProbationGoals = ({ data, cycleId, checkpoints, refetch }: Props) => {
  const user = useSelector(selectUser)
  const [loading, setLoading] = useState(true)
  const [tickets, setTickets] = useState<ProbationGoalInterface[]>([])
  const { data: performanceSettings } = useGetPerformanceSettings()
  const jiraIntegrationEnabled =
    performanceSettings?.enable_probation_and_pip_goals_via_jira

  const probationCycle = data.performance_cycles?.find(cycle => cycle.id === cycleId)

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true)

      try {
        const result = await getProbationGoals(data.id, cycleId)
        const results = [...result.data.results]

        setTickets(results)
      } finally {
        setLoading(false)
      }
    }

    fetchTickets()
  }, [cycleId])

  const onDelete = async (id: number) => {
    if (id) {
      await deleteProbationGoal(data.id, id, cycleId)
    }

    const updated = tickets.filter(item => item.id !== id)
    setTickets(updated)
  }

  const onChange = async (option?: JiraIssueInterface) => {
    if (!option) {
      return
    }

    const resp = await addProbationGoal(
      data.id,
      {
        employee: data.id,
        cycle: {
          id: cycleId,
        },
        jira_issue: +option.id,
        jira_issue_key: option.key,
        jira_issue_type: option.fields.issuetype.name,
        jira_issue_summary: option.fields.summary,
        jira_issue_status: option.fields.status.name,
        jira_issue_duedate: option.fields.duedate,
        jira_issue_updated: option.fields.updated,
      },
      cycleId,
    )
    const updated = [...tickets]
    updated.push(resp.data)

    setTickets(updated)
  }

  const isLineManager = user.id === data.line_manager?.id

  if (loading) {
    return (
      <Flex height="100%" alignItems="center" justifyContent="center">
        <Loader />
      </Flex>
    )
  }

  const checkpoint = checkpoints?.checkpoints?.[0]
  const isPendingApproval = checkpoint?.goals?.status === Statuses.pending_approval

  return (
    <PageBody maxWidth={676}>
      {isPendingApproval && checkpoint && (
        <ApproveGoalsWidget
          mb="s-16"
          employee={data}
          refetch={refetch}
          checkpoint={checkpoint}
        />
      )}
      {!jiraIntegrationEnabled && (
        <Box mb="s-24">
          <ActionCard
            title="Setting goals"
            text="Goals for probation cycles must follow the SMART framework. Please ensure each goal is measurable with clearly defined success criteria. Goals will be reviewed by HR prior to the start of the probation."
          />
        </Box>
      )}
      <Text color={Color.GREY_TONE_50} fontWeight={500} use="div">
        Probation goals
      </Text>
      {!tickets?.length && jiraIntegrationEnabled && (
        <ActionCard
          title="No goals set"
          text="It is mandatory for the line manager to set probation goals before starting to assess the employee"
        />
      )}

      {jiraIntegrationEnabled ? (
        <Tickets
          onDelete={onDelete}
          onChange={onChange}
          canEdit={isLineManager}
          tickets={tickets}
        />
      ) : (
        <GoalsJiraDisabled
          category={ReviewCycleCategory.Probation}
          reviewCycleId={cycleId}
          reviewCycleEndDate={probationCycle?.end_date_time}
          reviewCycleStartDate={probationCycle?.start_date_time}
          canManageGoals={isLineManager}
          employeeId={data.id}
        />
      )}
    </PageBody>
  )
}

export default ProbationGoals
