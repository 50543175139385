import React, { useEffect, useState } from 'react'
import { BaseWidget } from '../BaseWidget'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { useSidebar } from '@src/pages/Forms/GoalForm/common/SidebarProvider'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import AddGoalTargetForm from '@src/pages/Forms/GoalForm/Form/SidebarForms/AddGoalTargetForm'
import { TargetsList } from './TargetsList'
import { mapKpi } from './helpers'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { IdAndName } from '@src/interfaces'

export const TargetsWidget = ({
  initialCycle,
  viewMode = false,
}: {
  initialCycle?: ReviewCyclesInterface
  viewMode?: boolean
}) => {
  const { values } = useLapeContext<GoalsInterface>()
  const [sidebarIndex, setSidebarIndex] = useState<number>()
  const { getEntityProps } = useOrgEntity()
  const sidebar = useSidebar()

  useEffect(() => {
    if (sidebar.isOpen()) {
      // this is required to re-render sidebar when parent changed in the main form
      showSidebar(undefined, sidebarIndex)
    }
  }, [values.parent])

  const showSidebar = (kpi?: Partial<KpiInterface>, index?: number) => {
    const defaultFormData = {
      ...getEntityProps(),
      owner: values.owner as EmployeeOptionInterface & { team: IdAndName },
    }
    setSidebarIndex(index)

    const shouldHideCascaded = () => {
      // cascaded target can be the only
      // so we need to hide it sometimes
      const hasMultipleTargets = values.kpis?.length > 1
      const addingNewTargetWhenAtLeastOneExists =
        values.kpis?.length === 1 && index === undefined

      return hasMultipleTargets || addingNewTargetWhenAtLeastOneExists
    }

    sidebar.show({
      variant: 'wide',
      children: (
        <AddGoalTargetForm
          hideCascaded={shouldHideCascaded()}
          onPatched={result => {
            if (values.kpis.find(({ id }) => id === result.id)) {
              values.kpis = values.kpis.map(item =>
                item.id === result.id ? result : item,
              )
            } else {
              values.kpis.push(result)
            }
            sidebar.hide()
          }}
          contentType={values.content_type?.model}
          onParentChanged={parent => {
            values.parent = parent
          }}
          initialCycle={initialCycle}
          parent={values.parent}
          initialValues={kpi || defaultFormData}
          onSave={async updated => {
            if (typeof index === 'undefined') {
              values.kpis = [...(values.kpis || []), mapKpi(updated, values)]
            } else {
              values.kpis = [
                ...(index !== 0 ? values.kpis.slice(0, index) : []),
                mapKpi(updated, values),
                ...values.kpis.slice(index + 1),
              ]
            }

            if (updated.update_type === UpdateTypes.roadmap) {
              const epics = updated.target_epics[0].epics
              const targetEpicsKeys = epics.map(({ key }) => key)

              values.roadmaps = [
                ...(values.roadmaps || []).filter(
                  roadmap => !targetEpicsKeys.includes(roadmap.key!),
                ),
                ...epics.map(epic => ({
                  ...epic,
                  ...getEntityProps(),
                })),
              ] as GoalsInterface['roadmaps']
            }
            sidebar.hide()
          }}
        />
      ),
      title: index ? 'Edit target' : 'Add target',
    })
  }

  return (
    <BaseWidget
      title={viewMode ? 'Targets' : 'Add a target'}
      desciption={
        viewMode
          ? undefined
          : 'What are the success criteria and how is progress measured?'
      }
      icon="TurboTransfer"
      emptyState={{
        description: 'No targets added',
        ctaLabel: 'Add target',
        ctaHandler: showSidebar,
        icon: 'TurboTransfer',
      }}
    >
      {values.kpis?.length ? (
        <TargetsList
          onSelected={showSidebar}
          viewMode={viewMode}
          onAddRequested={showSidebar}
        />
      ) : null}
    </BaseWidget>
  )
}
