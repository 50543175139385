import React, { useEffect, useState } from 'react'
import { Box, Button, Fixed, Flex } from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import { useTheme } from '@src/styles/theme'
import { connect } from 'lape'
import { useGetJobPostings } from '@src/api/jobPosting'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { Statuses } from '@src/interfaces'
import ErrorBoundary from '@src/features/ErrorBoundary/ErrorBoundary'
import JobPostingSidebarContent from '@src/pages/Forms/JobPosting/Components/JobPostingSidebarContent'

export interface CriteriaSidebarFormProps {
  isOpen: boolean
  specialisationId?: string | number
  onClose: () => void
  selectedJobPostingId?: number
  isSelector?: boolean
  onChange?: (jobPosting?: JobPostingInterface) => void
  postingIds?: number[]
  title?: string
  subtitle?: string
}

const JobDescriptionSidebar = ({
  isOpen,
  onClose,
  onChange,
  selectedJobPostingId,
  specialisationId,
  postingIds,
  isSelector = true,
  title = 'View other job postings',
  subtitle = 'You can search other postings belonging to the same role(specialisation) and use their job posting',
}: CriteriaSidebarFormProps) => {
  const [jobPosting, setJobPosting] = useState<JobPostingInterface>()
  const [jobPostings, setJobPostings] = useState<JobPostingInterface[]>()
  const filters = postingIds
    ? [
        {
          columnName: 'id',
          filters: postingIds.map(item => ({
            id: item,
            name: String(item),
          })),
        },
      ]
    : [
        {
          columnName: 'specialisation__id',
          filters: specialisationId
            ? [{ id: specialisationId as string, name: specialisationId as string }]
            : [],
        },
        {
          columnName: 'approval_status',
          filters: [
            { id: Statuses.pending, name: Statuses.pending },
            { id: Statuses.approved, name: Statuses.approved },
          ],
        },
      ]

  const { data: postings, isLoading } = useGetJobPostings(filters)
  const theme = useTheme()

  useEffect(() => {
    if (postings?.length) {
      if (postingIds) {
        setJobPostings(postingIds.map(id => postings.find(result => result.id === id)!))
      } else {
        setJobPostings(postings)
      }
      if (!selectedJobPostingId) {
        setJobPosting(postings[0])
      } else {
        const foundPosting = postings.find(d => d.id === selectedJobPostingId)
        if (foundPosting) {
          setJobPosting(foundPosting)
        } else {
          setJobPosting(postings[0])
        }
      }
    }
  }, [postings])

  const handleChangeJobPosting = () => {
    onChange?.(jobPosting)
    onClose?.()
  }

  return (
    <>
      <SideBar
        isOpen={isOpen}
        variant="wide"
        title={title}
        subtitle={subtitle}
        onClose={onClose}
      >
        <ErrorBoundary>
          <JobPostingSidebarContent
            jobPosting={jobPosting}
            jobPostings={jobPostings}
            setJobPosting={setJobPosting}
            loading={isLoading}
            isSelector={isSelector}
          />
        </ErrorBoundary>
      </SideBar>
      {isOpen && onChange && (
        <Fixed bottom={36} right={15} zIndex={theme.zIndex.sideBar}>
          <Flex width={584} justifyContent="center">
            <Box width={200}>
              <Button elevated onClick={handleChangeJobPosting}>
                Change job posting
              </Button>
            </Box>
          </Flex>
        </Fixed>
      )}
    </>
  )
}

export default connect(JobDescriptionSidebar)
