import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  ActionButton,
  ActionWidget,
  Group,
  InputGroup,
  Side,
  VStack,
} from '@revolut/ui-kit'
import capitalize from 'lodash/capitalize'
import {
  ContractorInterface,
  EMPLOYEE_PROFILE_SUB_SECTIONS,
  EmployeeInterface,
  EmployeeType,
  ProbationTemplateOptionInterface,
} from '@src/interfaces/employees'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { useLapeContext } from '@src/features/Form/LapeForm'
import AutoStepper from '@components/Stepper/AutoStepper'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'

import { UpdateButton } from '../common'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetOrganisationSettings } from '@src/api/settings'
import { updateProbationEndDate } from '@src/utils/employees'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { renderTemplatesSelectorOption } from '@src/pages/Forms/ProbationTemplate/common'
import { useGetProbationTemplates } from '@src/api/probationTemplate'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useEmployeeDetailsOnAfterSubmit } from '../hooks'
import { PermissionTypes } from '@src/store/auth/types'
import { selectorKeys } from '@src/constants/api'

const ViewContractsButton = ({ employeeId }: { employeeId?: string }) => {
  const contractsUrl = `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, {
    id: employeeId,
  })}#contracts`

  return (
    <ActionButton onClick={() => navigateTo(contractsUrl)}>View Contracts</ActionButton>
  )
}

export interface Props {
  data: EmployeeInterface
  refreshData?: () => void
  type: EmployeeType
  onEmployeeTypeChange: (type: EmployeeType, id?: number) => void
  dynamicGroups: DynamicGroupIDs
  isSidebarContent?: boolean
}

const Position = ({
  dynamicGroups,
  onEmployeeTypeChange,
  type,
  data,
  refreshData,
  isSidebarContent,
}: Props) => {
  const params = useParams<{ id?: string }>()
  const form = useLapeContext<EmployeeInterface | ContractorInterface>()
  const { values, initialValues } = form

  const { data: organisationSettings } = useGetOrganisationSettings()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: templates } = useGetProbationTemplates({
    seniority_id: values.seniority?.id,
    contract_type: values.contract_type?.id,
    employee_type: type,
    specialisation_id: values.specialisation?.id,
    team_id: values.team?.id,
    location_id: values.location?.id,
    entity_id: values.entity?.id,
  })

  useEffect(() => {
    if (values.contract_type?.employee_type) {
      onEmployeeTypeChange(values.contract_type.employee_type, values.id)
    }
  }, [values.contract_type])

  useEffect(() => {
    if (!values.probation_template && templates) {
      values.probation_template = templates?.find(option => option.is_eligible)
    }
  }, [templates])

  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  const hasEditablePositionDetails = data.field_options.permissions?.includes(
    PermissionTypes.HasEditablePositionDetails,
  )

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.WORK}
            dynamicGroups={dynamicGroups}
          >
            <VStack gap="s-16" mb="s-24">
              {type === 'internal' && (
                <ActionWidget>
                  <ActionWidget.Content>
                    When a contract in the employee's Contracts tab becomes active, this
                    section is automatically updated with the details of the new contract.
                  </ActionWidget.Content>
                  <ActionWidget.Actions>
                    <ViewContractsButton employeeId={params.id} />
                  </ActionWidget.Actions>
                </ActionWidget>
              )}
              <FormPreview data={initialValues}>
                <Group>
                  <FormPreview.Item title="Contract type" field="contract_type.name" />
                  <FormPreview.Item
                    title="Employee type"
                    insert={() => capitalize(type)}
                  />
                  <FormPreview.Item title="Status" field="status.name" />
                  <FormPreview.Item
                    title="Role (Specialisation)"
                    field="specialisation.name"
                  />
                  {type === 'internal' && (
                    <>
                      <FormPreview.Item title="Seniority" field="seniority.name" />
                      {!!organisationSettings?.enable_multiple_levels_per_seniority && (
                        <FormPreview.Item
                          title="Sub-seniority level"
                          field="specialisation_seniority_sublevel.name"
                        />
                      )}
                      <FormPreview.Item title="Job title" field="job_title" />
                    </>
                  )}
                  {!hasEditablePositionDetails && (
                    <>
                      <FormPreview.Item title="Location" field="location.name" />
                      <FormPreview.Item title="Entity" field="entity.name" />
                    </>
                  )}
                </Group>
              </FormPreview>
            </VStack>

            <InputGroup>
              {hasEditablePositionDetails && (
                <>
                  <LapeRadioSelectInput
                    name="location"
                    label="Location"
                    selector={selectorKeys.location}
                  />
                  <LapeRadioSelectInput
                    name="entity"
                    label="Entity"
                    selector={selectorKeys.entity}
                  />
                </>
              )}
              <LapeDatePickerInput
                name="joining_date_time"
                label="Start date"
                message="According to the contract. This is provisional until the employee starts."
                onAfterChange={value => {
                  if (value != null && values.probation_template?.amount_of_months) {
                    updateProbationEndDate(
                      value,
                      values.probation_template.amount_of_months,
                      values,
                    )
                  }
                }}
                required
              />

              {performanceSettings?.enable_probation && (
                <>
                  <LapeRadioSelectInput
                    name="probation_template"
                    label="Select probation template"
                    options={templates?.map(item => ({
                      label: item.name,
                      value: item,
                    }))}
                    onAfterChange={(option: ProbationTemplateOptionInterface | null) => {
                      if (option && values.joining_date_time && option.amount_of_months) {
                        updateProbationEndDate(
                          values.joining_date_time,
                          option.amount_of_months,
                          values,
                        )
                      }
                    }}
                    disableOptionRule={option => !option.value.is_eligible}
                    clearable
                  >
                    {renderTemplatesSelectorOption}
                  </LapeRadioSelectInput>
                  <LapeDatePickerInput
                    name="end_of_probation_date_time"
                    label="Probation end date"
                    message="Mandatory for internal employees only, according to the contract and provisional until the employee starts. Not applicable for external employees."
                    disabledDays={{
                      before: new Date(values.joining_date_time),
                    }}
                    disabled={Boolean(values.probation_template)}
                    required
                  />
                </>
              )}
            </InputGroup>
          </NewStepperSectionCustomFields>
        </AutoStepper>
      </PageBody>
      <ActionsWrapper>
        <UpdateButton
          form={form}
          employeeTypeChanged={type !== data?.employee_type}
          onAfterSubmit={onAfterSubmit}
        />
      </ActionsWrapper>
    </>
  )
}

export default Position
