import { departmentRequestsNew } from '@src/api/department'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { useParams } from 'react-router-dom'
import DepartmentSettings from './General'

const SettingsPage = () => {
  const params = useParams<{ id: string }>()
  const isEditing = params.id !== undefined
  const backUrl = isEditing
    ? pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, { id: String(params?.id) })
    : ROUTES.ORGANISATION.DEPARTMENTS

  const title = isEditing ? 'Edit Department' : 'New Department'

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={backUrl} />
      <Form api={departmentRequestsNew}>
        <DepartmentSettings />
      </Form>
    </PageWrapper>
  )
}
export default SettingsPage
