import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import { pathToUrl } from '@src/utils/router'
import { ReviewCategory, ReviewSummaryInterface } from '@src/interfaces/performance'
import * as ProbationDeliverablesView from '@src/pages/Forms/Probation/DeliverablesView'
import * as EmployeePerformanceViewSkills from '@src/pages/Forms/EmployeePerformanceView/Skills'
import * as EmployeePerformanceViewCultureFit from '@src/pages/Forms/EmployeePerformanceView/CultureFit'
import * as EmployeePerformanceViewSummary from '@src/pages/Forms/EmployeePerformanceView/Summary'
import PerformanceView from '../EmployeePerformance/components/PerformanceView/PerformanceView'
import { TabsInterface } from '@src/interfaces/data'
import * as KPIDeliverables from '@src/pages/Forms/Probation/KPI'

const getTabs = (
  params: {
    id: string
    employeeId?: string
  },
  isKPIDeliverables?: boolean,
): TabsInterface<ReviewSummaryInterface>[] => [
  {
    title: 'Deliverables',
    path: ROUTES.FORMS.PROBATION_REVIEW_VIEW.DELIVERABLES,
    to: pathToUrl(ROUTES.FORMS.PROBATION_REVIEW_VIEW.DELIVERABLES, params),
    validator: {},
    component: isKPIDeliverables
      ? KPIDeliverables.default
      : ProbationDeliverablesView.default,
    visibilityCheck: () => true,
  },
  {
    title: 'Skills',
    path: ROUTES.FORMS.PROBATION_REVIEW_VIEW.SKILLS,
    to: pathToUrl(ROUTES.FORMS.PROBATION_REVIEW_VIEW.SKILLS, params),
    validator: {},
    component: EmployeePerformanceViewSkills.default,
    visibilityCheck: data => !!data?.summary?.functional_skills,
  },
  {
    title: 'Culture fit',
    path: ROUTES.FORMS.PROBATION_REVIEW_VIEW.CULTURE_FIT,
    to: pathToUrl(ROUTES.FORMS.PROBATION_REVIEW_VIEW.CULTURE_FIT, params),
    validator: {},
    component: EmployeePerformanceViewCultureFit.default,
    visibilityCheck: () => true,
  },
  {
    title: 'Summary',
    path: ROUTES.FORMS.PROBATION_REVIEW_VIEW.SUMMARY,
    to: pathToUrl(ROUTES.FORMS.PROBATION_REVIEW_VIEW.SUMMARY, params),
    validator: {},
    component: EmployeePerformanceViewSummary.default,
    visibilityCheck: () => true,
  },
]

const ProbationViewForm = () => (
  <PerformanceView getTabs={getTabs} category={ReviewCategory.Probation} />
)

export default connect(ProbationViewForm)
