import React from 'react'
import { Box, TableWidget } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import { SubscriptionInvoiceInterface } from '@src/interfaces/plans'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  subscriptionInvoicesDateColumn,
  subscriptionInvoicesOrderNumberColumn,
  subscriptionInvoicesPeriodColumn,
  subscriptionInvoicesStatusColumn,
  subscriptionInvoicesTotalPriceColumn,
} from '@src/constants/columns/plans'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useTable } from '@src/components/Table/hooks'
import { tenantInvoicesRequests } from '@src/api/tenants'
import { accountInvoiceActionColumn } from '@src/constants/columns/settings'

export const Invoices = () => {
  const params = useParams<{ id: string }>()

  const table = useTable(tenantInvoicesRequests(params.id))

  const row: RowInterface<SubscriptionInvoiceInterface> = {
    linkToForm: data =>
      navigateTo(
        pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INVOICE, {
          id: params.id,
          invoiceId: data.id,
        }),
      ),
    cells: [
      {
        ...subscriptionInvoicesPeriodColumn,
        width: 150,
      },
      {
        ...subscriptionInvoicesDateColumn,
        width: 100,
      },
      {
        ...subscriptionInvoicesOrderNumberColumn,
        width: 400,
      },
      {
        ...subscriptionInvoicesStatusColumn,
        width: 100,
      },
      {
        ...subscriptionInvoicesTotalPriceColumn,
        width: 100,
      },
      {
        ...accountInvoiceActionColumn(params.id),
        width: 100,
      },
    ],
  }

  return (
    <Box mt="s-16">
      <TableWidget>
        <TableWidget.Table>
          <AdjustableTable
            name={TableNames.AccountInvoices}
            {...table}
            row={row}
            useWindowScroll
            dataType="Invoice"
            noDataMessage="All invoices will appear here"
          />
        </TableWidget.Table>
      </TableWidget>
    </Box>
  )
}
