import React from 'react'

import { Flex } from '@revolut/ui-kit'
import { TeamInterface } from '@src/interfaces/teams'
import { TimeOffCalendar } from '@src/features/TimeOffCalendarTable/TimeOffCalendar'
import {
  useCalendarFilters,
  useTableCalendarSwitcher,
  useWeekMonthSwitcher,
} from '@src/features/TimeOffCalendarTable/hooks'
import { getTeamTimeOffCalendar } from '@src/api/timeOff'
import { TableNames } from '@src/constants/table'
import { TimeOffTable } from './TimeOffTable'
import { TableCalendarTabs } from '@src/features/TimeOffCalendarTable/constants'
import { TableWrapper } from '@components/Table/TableWrapper'
import { useIsNewOrgLayout } from '@src/pages/Team/helpers'
import { PermissionTypes } from '@src/store/auth/types'

enum WeekMonthTabs {
  Week = 'Week',
  Month = 'Month',
}

interface TimeOffProps {
  data: TeamInterface
  navigation?: React.ReactElement
}
export const TeamTimeOff = ({ data, navigation }: TimeOffProps) => {
  const { isMonthView, clearCalendarFilters } = useCalendarFilters()
  const weekMonthSwitcher = useWeekMonthSwitcher(isMonthView)

  const isNewLayout = useIsNewOrgLayout()

  const tableCalendarSwitcher = useTableCalendarSwitcher(tab => {
    if (tab === TableCalendarTabs.Table) {
      clearCalendarFilters()
    }
  })

  // Currently, we don't have a special permission that regulates non-approved requests visibility.
  // Before the "team time-off calendar view" has been added, it was fine,
  // since anybody with team-level permission for the time-off tab was implied to be allowed to see pending requests as well.
  // Now we need to double-check the permission as the tab can be visible in case a user checks his team's calendar
  //
  // This should be probably fixed by adding a separate permission set for regulating the approvals view.
  const canViewApprovals = !!data?.field_options.permissions?.includes(
    PermissionTypes.ViewTimeOff,
  )

  const initialFilters = canViewApprovals
    ? []
    : [
        {
          columnName: 'approval_status',
          filters: [{ id: 'approved', name: 'approved' }],
          nonResettable: true,
        },
      ]

  return (
    <TableWrapper>
      {isNewLayout && navigation}

      <Flex mb="s-24" gap="s-8">
        {tableCalendarSwitcher.tabBar}
        {tableCalendarSwitcher.currentTab === TableCalendarTabs.Calendar
          ? weekMonthSwitcher.tabBar
          : null}
      </Flex>

      {tableCalendarSwitcher.currentTab === TableCalendarTabs.Table && (
        <TimeOffTable
          data={data}
          initialFilters={initialFilters}
          canViewApprovals={canViewApprovals}
        />
      )}
      {tableCalendarSwitcher.currentTab === TableCalendarTabs.Calendar && (
        <TimeOffCalendar
          tableName={TableNames.TeamTimeOffCalendar}
          data={data}
          weekMonthTab={weekMonthSwitcher.currentTab || WeekMonthTabs.Week}
          getItems={getTeamTimeOffCalendar(data.id)}
          initialFilters={initialFilters}
        />
      )}
    </TableWrapper>
  )
}
