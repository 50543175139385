import { CustomFieldTypes } from '@src/constants/customFields'
import { OptionInterface } from './selectors'
import { IdAndName, Statuses } from '.'
import { TableFilter } from '@src/features/AudienceSelection/AudienceTable'

export type DynamicGroupIDs = { id: number }[]

export type DynamicGroups = { id: number; name: string }[]

export enum SectionOptions {
  EmployeeProfile = '4fb95bbb-c887-4fcf-a3be-873f1463b607',
  Benefits = '77b8615f-c846-42fb-bcf5-ae2726297ae9',
  Roles = '593eb8ea-71ed-4877-99e6-0c41179bc00b',
  Departments = 'a8ebffe4-a0c6-4da8-9f67-5017651385ca',
  Requisitions = 'b5f71740-d938-4333-92be-47b61049d89a',
}
interface CustomFieldsBaseInterface {
  id: number
  uid: string
  created_by: OptionInterface
  dynamic_groups: DynamicGroups
  name: string
  section: IdAndName<SectionOptions>
  sub_section: IdAndName | null
  input_type: { id: CustomFieldTypes }
  mandatory: boolean
  placeholder: string
  tooltip: string
  field_name: string
  status: { id: Statuses; name: string }
}

export interface CustomFieldsInterface extends CustomFieldsBaseInterface {
  validation_checks?: {
    validation_choices?: string[]
    validation_regex?: string
    validation_error?: string
  }
  table_filters?: TableFilter | null
}

export interface CustomFieldsForSectionInterface extends CustomFieldsBaseInterface {
  validation_checks?: {
    validation_choices?: IdAndName[]
    validation_regex?: string
    validation_error?: string
  }
}

export type CustomFieldsEntityInterface = Record<
  string,
  number | string | OptionInterface | OptionInterface[]
>
