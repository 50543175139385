import React from 'react'

import { BarChart } from '@revolut/icons'
import { Color, HStack, TextButton, Tooltip, useTooltip } from '@revolut/ui-kit'
import {
  EngagementResultInterface,
  EngagementResultsAverageScoreInterface,
  EngagementResultsScoresInterface,
} from '@src/interfaces/engagement'
import { mapScoreToColor, NON_ANSWERED_KEY, SCORE_KEYS } from '../constants'
import { PreviewBar } from './PreviewBar'
import {
  getTotalAnswers,
  getTotalAnswersFromAverage,
} from '@src/apps/People/Engagement/Results/components/DistributionChart/helpers'
import { Summary as RatingSummary } from './RatingTooltipContent/Summary'
import { Distribution as RatingDistribution } from './RatingTooltipContent/Distribution'
import { Summary as OpenEndedSummary } from './OpenEndedTooltipContent/Summary'
import { Distribution as OpenEndedDistribution } from './OpenEndedTooltipContent/Distribution'

type Distribution = {
  color: Color
  width: number
}

const getDistribution = ({
  scores,
  unanswered,
}: {
  scores: EngagementResultsScoresInterface
  unanswered: number
}): Distribution[] => {
  const total = getTotalAnswers({ scores, unanswered })

  return total
    ? SCORE_KEYS.map(scoreKey => {
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        const scoreValue = scoreKey === NON_ANSWERED_KEY ? unanswered : scores[scoreKey]
        return {
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          color: mapScoreToColor[scoreKey],
          width: scoreValue / total,
        }
      })
    : []
}

const getAvgDistribution = ({
  averageScore,
}: {
  averageScore: EngagementResultsAverageScoreInterface
}): Distribution[] => {
  const total = getTotalAnswersFromAverage(averageScore)

  return total
    ? [
        {
          color: Color.RED,
          width: averageScore.detractors / total,
        },
        {
          color: Color.YELLOW,
          width: averageScore.passives / total,
        },
        {
          color: Color.GREEN,
          width: averageScore.promoters / total,
        },
        {
          color: Color.GREY_20,
          width: averageScore.unanswered / total,
        },
      ]
    : []
}

type Props = {
  data: EngagementResultInterface
  isAverage?: boolean
}
export const DistributionChart = ({ data, isAverage }: Props) => {
  const tooltip = useTooltip()

  const scores = data.scores_distribution
  const averageScore = {
    detractors: data.detractors,
    passives: data.passives,
    promoters: data.promoters,
    unanswered: data.unanswered,
  }
  const isOpenEnded = data.question?.type.id === 'open_ended'

  const distribution = getDistribution({
    scores,
    unanswered: data.unanswered,
  })
  const avgDistribution = getAvgDistribution({ averageScore })

  if (isOpenEnded) {
    return (
      <>
        <HStack space="s-8" align="center" {...tooltip.getAnchorProps()}>
          <BarChart size={16} color={Color.BLUE} />
          <TextButton>Hover to see details</TextButton>
        </HStack>
        {isAverage ? (
          <Tooltip {...tooltip.getTargetProps()}>
            <OpenEndedSummary distribution={data.open_ended_distribution} />
          </Tooltip>
        ) : (
          <Tooltip
            {...tooltip.getTargetProps({ placement: 'bottom' })}
            p={0}
            radius="cell"
          >
            <OpenEndedDistribution distribution={data.open_ended_distribution} />
          </Tooltip>
        )}
      </>
    )
  }
  return distribution.length ? (
    <>
      <PreviewBar
        tooltip={tooltip}
        distribution={isAverage ? avgDistribution : distribution}
      />
      {isAverage ? (
        <Tooltip {...tooltip.getTargetProps()}>
          <RatingSummary averageScore={averageScore} />
        </Tooltip>
      ) : (
        <Tooltip {...tooltip.getTargetProps({ placement: 'bottom' })} p={0} radius="cell">
          <RatingDistribution scores={scores} unanswered={data.unanswered} />
        </Tooltip>
      )}
    </>
  ) : null
}
