import { TeamInterface } from './teams'
import { ReviewCyclesInterface } from './reviewCycles'
import {
  EmployeeOptionInterface,
  EmployeeType,
  IdStatuses,
  LocationInterface,
} from './employees'
import { SeniorityInterface } from './seniority'
import { RoleInterface } from './roles'
import { Statuses } from './index'
import { OptionInterface } from '@src/interfaces/selectors'
import { PerformanceScores } from './scorecard'
import { TalentPerformanceInterface } from '@src/interfaces/talent/performance'

export enum BudgetTypes {
  EMPLOYEE_BUDGET = 'employee_budget',
  TEAM_BUDGET = 'team_budget',
  DEPARTMENT_BUDGET = 'department_budget',
}

export interface CompanyBudgetInterface {
  id: number
  is_requisition: boolean
  job_title: string
  position: RoleInterface
  line_manager: EmployeeOptionInterface
  location: LocationInterface
  seniority: SeniorityInterface
  team: TeamInterface
  review_cycle: ReviewCyclesInterface
  performance_label: OptionInterface
  total_current_spent: number
  total_estimated_spend: number
  children_type: BudgetTypes
  type: BudgetTypes
  entity_id: number
  entity_name: string
  entity_owner_id: number
  entity_owner: string
  budget_currency: string
  children: {
    id: number
    name: string
    status: string
  }[]
  expense_date: string
  status: Statuses
  joining_date: string
  performance: number
  specialisation: OptionInterface | null
}

export interface BudgetInterface {
  id: number
  annual_expense: number
  creation_date_time: string
  employee_type: EmployeeType
  expense_currency: string
  full_name: string
  is_requisition: boolean
  job_title: string
  joining_date_time: string
  latest_performance: number | null
  latest_performance_label: OptionInterface
  latest_performance_cycle: string | null
  line_manager_avatar: string | null
  line_manager_id: number
  line_manager_name: string
  line_manager_status: IdStatuses
  location_id: number
  location_name: string
  monthly_expense: number
  performance: number
  latest_calibrated_grade?: TalentPerformanceInterface
  performance_label: OptionInterface
  position_id: number
  position_name: string
  quality_control_avatar: string
  quality_control_id: number
  quality_control_name: string
  quality_control_status: IdStatuses
  seniority_id: number
  seniority_name: string
  specialisation_seniority_sublevel_name: string
  specialisation_id: number
  specialisation_name: string
  status: PerformanceScores
  team_id: number
}

export interface BudgetRowInterface {
  name: string
  type: 'generic' | 'combined'
  headcount: number | null
  cost: number | null
  info: string
}
