import React, { useEffect, useState } from 'react'
import { Box, Text, Widget } from '@revolut/ui-kit'
import CheckboxCard from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/CheckboxCard'
import SummaryCard from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/SummaryCard/SummaryCard'
import {
  InterviewFeedbackInterface,
  InterviewFeedbackSkillSectionInterface,
  InterviewFeedbackSummaryInterface,
  InterviewType,
} from '@src/interfaces/interviewTool'
import Section from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/Section'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import RadioCard from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/RadioCard'
import { getThumbsQuestionPoints } from '@src/utils/interview'
import SectionHeader from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/SectionHeader'
import ThumbsCard from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/ThumbsCard'
import { LevelsCard } from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/LevelsCard'
import ResultEvaluationSection from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/ResultEvaluationSection'
import { FormError, useLapeContext } from '@src/features/Form/LapeForm'
import { generateSummary } from '@src/api/recruitment/interviewFeedback'
import DropdownCard from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/DropdownCard'
import { Grid } from '@components/CommonSC/Grid'
import { useGetHiringProcessSettings } from '@src/api/settings'
import LocationsQuestionSection from '@src/pages/Settings/HiringProcess/components/LocationsQuestionSection'
import SalaryQuestionSection, {
  SalaryQuestionSectionValues,
} from '@src/pages/Settings/HiringProcess/components/SalaryQuestionSection'
import {
  getHiringSectionIndexes,
  getPredefinedSectionIndex,
} from '@src/pages/Settings/HiringProcess/utils'
import { setTimeCodeHandler } from '@src/utils/hiring'
import RightToWorkQuestionSection from '@src/pages/Settings/HiringProcess/components/RightToWorkQuestionSection'

type Props = {
  isSidebar?: boolean
  isViewMode?: boolean
  stageType?: InterviewType | null
}

const InterviewFeedbackForm = ({ isSidebar, isViewMode, stageType }: Props) => {
  const { values, errors } = useLapeContext<InterviewFeedbackInterface>()
  const [summary, setSummary] = useState<InterviewFeedbackSummaryInterface>()

  const isScreenCall = stageType === 'screen_call'
  const { data: settings } = useGetHiringProcessSettings(!isScreenCall)

  const data = values?.scorecard.sections
  const seniorities = values?.scorecard.seniority_section
  const passScore = values.pass_score
  const maxScore = values.maximum_points
  const type = values.scorecard.scorecard_type
  const feedbackId = values.id

  const points =
    data?.reduce((sum, s) => {
      switch (s.internal_data_type) {
        case 'dropdown_question_interview_section':
        case 'options_question_interview_section': {
          sum += s.answer?.points || 0
          break
        }
        case 'checkbox_questions_interview_section': {
          sum +=
            s.questions.reduce((acc, val) => (val.answer ? acc + val.points : acc), 0) ||
            0
          break
        }

        case 'thumbs_questions_interview_section': {
          sum +=
            s.questions.reduce((acc, val) => acc + getThumbsQuestionPoints(val), 0) || 0
          break
        }
      }

      return sum
    }, 0) || 0

  const updateSummary = async () => {
    if (!feedbackId) {
      return
    }
    const resp = await generateSummary(feedbackId, values)
    setSummary(resp.data)
  }

  const getDependencies = () => {
    const list = [
      values.result,
      values.rating,
      values.evaluated_seniority?.level,
      values.scorecard.seniority_section?.answer,
    ]
    if (
      values.scorecard.scorecard_type === 'general' ||
      values.scorecard.scorecard_type === 'cv_screening'
    ) {
      return [...list, points]
    }

    const answers = (
      values.scorecard.sections?.filter(val => {
        return val.internal_data_type === 'skill_interview_section'
      }) as InterviewFeedbackSkillSectionInterface[]
    ).flatMap(section =>
      section.sections?.flatMap(sec => sec.items?.flatMap(item => item.value)),
    )

    return [...list, ...answers]
  }

  useEffect(() => {
    updateSummary()
  }, getDependencies())

  const getLevelsCardErrors = (index: number) => {
    const sectionError = errors?.scorecard?.sections?.[index] as
      | FormError<InterviewFeedbackSkillSectionInterface>
      | undefined

    return {
      sections: sectionError?.levels_questions?.map(
        error => error?.questions?.find(question => question?.answer)?.answer || '',
      ),
    }
  }

  const { predefinedSectionsLength, summaryIndex } = getHiringSectionIndexes(
    isScreenCall,
    settings,
    data,
  )

  return (
    <>
      {isScreenCall && (
        <Grid gap={16} mb="s-16">
          {settings?.enable_location_collecting && (
            <LocationsQuestionSection<InterviewFeedbackInterface>
              optional={settings.is_location_optional}
              disabled={isViewMode}
              location={values.preferred_location}
              country={values.current_country}
              onChangeCountry={val => {
                if (!isViewMode) {
                  values.current_country = val
                  delete errors.current_country
                }
              }}
              onChangeLocation={val => {
                if (!isViewMode) {
                  values.preferred_location = val
                  delete errors.preferred_location
                }
              }}
              errors={errors}
              names={{
                location: 'preferred_location',
                country: 'current_country',
              }}
            />
          )}
          {settings?.enable_compensation_collecting && (
            <SalaryQuestionSection
              titleIndexNumber={getPredefinedSectionIndex(
                settings?.enable_location_collecting,
              )}
              optional={settings.is_compensation_optional}
              values={{
                current_salary_frequency: values.current_salary_frequency,
                current_salary_amount: values.current_salary_amount,
                current_salary_currency: values.current_salary_currency,
                additional_information_about_current_salary:
                  values.additional_information_about_current_salary,
                candidate_declined_to_disclose_current_salary:
                  values.candidate_declined_to_disclose_current_salary,
                expected_salary_frequency: values.expected_salary_frequency,
                expected_salary_amount: values.expected_salary_amount,
                expected_salary_currency: values.expected_salary_currency,
                additional_information_about_expected_salary:
                  values.additional_information_about_expected_salary,
                candidate_declined_to_disclose_expected_salary:
                  values.candidate_declined_to_disclose_expected_salary,
              }}
              onChange={obj => {
                if (!isViewMode) {
                  Object.assign(values, obj)

                  Object.keys(obj).forEach(k => {
                    const key = k as keyof SalaryQuestionSectionValues
                    if (obj[key]) {
                      delete errors[key]
                    }
                  })
                }
              }}
              disabled={isViewMode}
              errors={errors}
            />
          )}
          {settings?.enable_right_to_work_collecting && (
            <RightToWorkQuestionSection
              titleIndexNumber={getPredefinedSectionIndex(
                settings?.enable_location_collecting,
                settings?.enable_compensation_collecting,
              )}
              optional={settings.is_compensation_optional}
              rightToWork={values.right_to_work}
              isEligibleToRelocate={values.is_eligible_to_relocate}
              isReadyToRelocate={values.is_ready_to_relocate}
              onChangeIsEligibleToRelocate={v => {
                values.is_eligible_to_relocate = v
              }}
              onChangeIsReadyToRelocate={v => {
                values.is_ready_to_relocate = v
              }}
              onChangeRightToWork={v => {
                values.right_to_work = v
              }}
              disabled={isViewMode}
              errors={{
                right_to_work: errors?.right_to_work,
                is_eligible_to_relocate: errors?.is_eligible_to_relocate,
                is_ready_to_relocate: errors?.is_ready_to_relocate,
              }}
            />
          )}
        </Grid>
      )}

      {data?.map((card, index) => {
        const titleIndexNumber = predefinedSectionsLength + index + 1
        return (
          <React.Fragment key={index}>
            {card.internal_data_type === 'checkbox_questions_interview_section' && (
              <CheckboxCard
                name={`scorecard.sections.${index}.questions`}
                notesName={`scorecard.sections.${index}.notes`}
                data={card.questions}
                isViewMode={isViewMode}
                isOptional={card.optional}
                title={card.title}
                subtitle={card.sub_title}
                titleIndexNumber={titleIndexNumber}
              />
            )}
            {card.internal_data_type === 'text_question_interview_section' && (
              <Section
                index={titleIndexNumber}
                title={card.title}
                isOptional={card.optional}
                subtitle={card.sub_title}
                key={index}
                onAddTimeCode={setTimeCodeHandler(
                  `scorecard.sections.${index}.answer`,
                  values,
                )}
              >
                <LapeNewTextArea
                  name={`scorecard.sections.${index}.answer`}
                  data-testid={`scorecard.sections.${index}.answer`}
                  label={card.placeholder}
                  rows={3}
                  readOnly={isViewMode}
                  required
                />
              </Section>
            )}
            {card.internal_data_type === 'options_question_interview_section' && (
              <RadioCard
                answerName={`scorecard.sections.${index}.answer`}
                notesName={`scorecard.sections.${index}.notes`}
                data={card.options}
                isViewMode={isViewMode}
                isOptional={card.optional}
                title={card.title}
                subtitle={card.sub_title}
                titleIndexNumber={titleIndexNumber}
              />
            )}
            {card.internal_data_type === 'dropdown_question_interview_section' && (
              <DropdownCard
                answerName={`scorecard.sections.${index}.answer`}
                notesName={`scorecard.sections.${index}.notes`}
                placeholder={card.placeholder}
                data={card.options}
                isViewMode={isViewMode}
                isOptional={card.optional}
                title={card.title}
                subtitle={card.sub_title}
                titleIndexNumber={titleIndexNumber}
              />
            )}
            {card.internal_data_type === 'thumbs_questions_interview_section' && (
              <ThumbsCard
                name={`scorecard.sections.${index}.questions`}
                notesName={`scorecard.sections.${index}.notes`}
                data={card.questions}
                isViewMode={isViewMode}
                isOptional={card.optional}
                title={card.title}
                subtitle={card.sub_title}
                titleIndexNumber={titleIndexNumber}
              />
            )}
            {card.internal_data_type === 'skill_interview_section' && (
              <LevelsCard
                sections={card.sections || []}
                skills={card.skills}
                title={card.title}
                subTitle={card.sub_title}
                notes={card.notes}
                name={`scorecard.sections.${index}.sections`}
                isViewMode={isViewMode}
                index={index}
                errors={getLevelsCardErrors(index)}
                titleIndexNumber={titleIndexNumber}
              />
            )}
          </React.Fragment>
        )
      })}
      <SummaryCard
        points={points}
        onlyTotal={isSidebar}
        titleIndexNumber={summaryIndex}
        passScore={passScore}
        maxScore={maxScore}
        type={type}
        summary={summary}
      />
      <Widget p="s-16">
        <Box mb="s-32">
          <ResultEvaluationSection
            titleIndexNumber={summaryIndex + 1}
            levelsPoints={values.scorecard.levels_points}
            type={type}
            seniorities={seniorities}
            suggestedLevel={summary?.suggested_level}
            suggestedResult={summary?.hire_recommendation}
            isViewMode={isViewMode}
            deviationJustificationRequired={summary?.deviation_justification_required}
          />
        </Box>
        <SectionHeader title={`${summaryIndex + 2}. Overall impressions`} />
        <LapeNewTextArea
          name="overall_impressions"
          data-testid="overall_impressions"
          label="Enter a short summary of your assessment of the candidate"
          rows={3}
          readOnly={isViewMode}
          required
        />
        <Box mt="s-32">
          <SectionHeader
            title={
              <>
                <Text color="foreground">
                  {summaryIndex + 3}. Feedback for the candidate
                </Text>
                <Text color="grey-tone-50"> (Optional)</Text>
              </>
            }
            subtitle={
              <Text color="orange">
                Please note that this feedback can be shared directly with the candidate
                by the recruiter
              </Text>
            }
          />
          <LapeNewTextArea
            name="notes_for_candidate"
            data-testid="notes_for_candidate"
            label="Feedback for candidate"
            rows={3}
            readOnly={isViewMode}
          />
        </Box>
      </Widget>
    </>
  )
}

export default InterviewFeedbackForm
