import React, { useEffect } from 'react'
import { connect } from 'lape'
import { KpiGoals, KpiInterface, KpiReviewCycle, KpiTargets } from '@src/interfaces/kpis'
import {
  ReviewCycleCategory,
  ReviewCyclesInterface,
  ReviewCycleStatus,
} from '@src/interfaces/reviewCycles'
import CycleSelector, {
  cycleStatusText,
} from '@src/features/FormTabs/Kpi/KPITargets/common/CycleSelector'
import { InputGroup, Text } from '@revolut/ui-kit'
import { strategyLabelsMap } from '@src/features/FormTabs/Kpi/KPITargets/ValueTargets/common'
import { useLapeContext } from '@src/features/Form/LapeForm'
import ParentSelector from '@src/features/FormTabs/Kpi/KPITargets/common/ParentSelector'
import { selectorKeys } from '@src/constants/api'
import { probationCycleCategory } from '@src/features/FormTabs/Kpi/KPITargets/common/useAvailableCycles'
import { KPIValueTargetLocationState } from '@src/features/FormTabs/Kpi/KPITargets/common/types'
import Form from '@src/features/Form/Form'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { PageBody } from '@components/Page/PageBody'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { kpiTargetsRequestsNew } from '@src/api/kpis'
import { useGetPerformanceSelector } from '@src/api/performance'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { successNotification } from '@src/actions/NotificationActions'
import pick from 'lodash/pick'
import { goBack } from '@src/actions/RouterActions'
import useKPISidebars from '@src/pages/Forms/KpiForm/common/useKPISidebars'
import SettingsButtons from '@src/features/SettingsButtons'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { KPITypes } from '@src/constants/table'

interface Props {
  locationState: KPIValueTargetLocationState
}

type TargetInitialValuesParams = {
  kpi: KpiInterface
  defaultCycle?: ReviewCyclesInterface | KpiReviewCycle
  probationId?: string
  isTemplate?: boolean
}

// TODO: Remove this when BE is fixed
const transformCycle = (
  reviewCycle: ReviewCyclesInterface | KpiReviewCycle,
): KpiReviewCycle => {
  return pick(reviewCycle, ['id', 'name', 'offset', 'category', 'status'])
}

export const getInitialValuesValueTarget = ({
  kpi,
  defaultCycle,
  probationId,
  isTemplate,
}: TargetInitialValuesParams): KpiTargets => {
  const numTargets = kpi.targets.length
  const lastTarget = numTargets ? kpi.targets[numTargets - 1] : null

  const canBeTopdown = !!kpi.team || kpi.department

  return {
    kpi: { id: kpi.id },
    initial_value: lastTarget?.target || null,
    target: null,
    parent_kpi: null,
    owner: canBeTopdown ? kpi.owner : undefined,
    is_top_down: false,
    kpi_goal: lastTarget?.kpi_goal || KpiGoals.increase,
    review_cycle: probationId || !defaultCycle ? undefined : transformCycle(defaultCycle),
    is_probation: isTemplate && defaultCycle?.category === probationCycleCategory,
    employee_cycle: probationId
      ? {
          id: probationId,
          name: 'Probation',
        }
      : undefined,
  }
}

const ValueTargetsForm = connect(({ locationState }: Props) => {
  const { kpi, backUrl, availableCycles, canEditCycle, readonly } = locationState
  const { sidebars, buttons } = useKPISidebars()

  const form = useLapeContext<KpiTargets>()
  const { values, initialValues } = form

  const disabled =
    initialValues?.review_cycle?.status === ReviewCycleStatus.closed || readonly

  const isInMaintainMode = [
    KpiGoals.keep_target,
    KpiGoals.keep_target_down,
    KpiGoals.keep_target_up,
  ].includes(values.kpi_goal)

  const { data: employeeAvailableCycles } = useGetPerformanceSelector(kpi.owner.id)

  const getAvailableCycles = () => {
    if (kpi.type === KPITypes.employee_kpi) {
      return employeeAvailableCycles || availableCycles
    }
    return availableCycles
  }

  useEffect(() => {
    if (readonly || initialValues?.review_cycle?.status === ReviewCycleStatus.closed) {
      form.disabled = true
    } else {
      form.disabled = false
    }
  }, [readonly])

  const { options: kpiGoalOptions } = useFetchOptions<IdAndName<KpiGoals>>(
    selectorKeys.kpi_goals,
  )
  const noKpiGoalOption =
    !!kpiGoalOptions.length &&
    !!values.kpi_goal &&
    !kpiGoalOptions.find(opt => opt.value.id === values.kpi_goal)

  return (
    <>
      {sidebars}
      <PageWrapper>
        <PageHeader
          backUrlLocationState={locationState.kpiLocationState}
          title="Set target"
          subtitle={
            values.review_cycle?.status && (
              <Text fontSize="primary" color="foreground">
                {cycleStatusText[values.review_cycle.status]}
              </Text>
            )
          }
          backUrl={backUrl || pathToUrl(ROUTES.FORMS.KPI.GENERAL, { id: kpi.id })}
          variant="narrow"
        />
        <PageBody>
          <SettingsButtons mb="s-16">{buttons}</SettingsButtons>
          <InputGroup>
            <CycleSelector
              disabled={disabled || !canEditCycle}
              value={values.employee_cycle || values.review_cycle}
              reviewCycles={getAvailableCycles()}
              onSelect={cycle => {
                if (
                  cycle.category === probationCycleCategory ||
                  cycle.category === ReviewCycleCategory.PIP
                ) {
                  values.employee_cycle = {
                    id: String(cycle.id),
                    name: cycle.name,
                  }
                  values.review_cycle = undefined
                } else {
                  values.review_cycle = transformCycle(cycle)
                  values.employee_cycle = undefined
                  values.is_probation = false
                }
              }}
            />
            <LapeRadioSelectInput
              name="kpi_goal"
              clearable={false}
              options={kpiGoalOptions}
              label="KPI Direction"
              value={
                values.kpi_goal
                  ? { id: values.kpi_goal, name: strategyLabelsMap[values.kpi_goal] }
                  : null
              }
              onChange={option => {
                if (option) {
                  values.kpi_goal = option.id
                }
              }}
              message={noKpiGoalOption ? 'KPI direction needs to be changed' : undefined}
              hasError={noKpiGoalOption}
            />
            <LapeNewInput
              type="number"
              name="initial_value"
              required
              label={isInMaintainMode ? 'Lower limit' : 'Initial value'}
            />
            <LapeNewInput
              type="number"
              name="target"
              required
              label={isInMaintainMode ? 'Upper limit' : 'Target'}
            />
            <ParentSelector
              kpi={kpi}
              cycleOffset={values.employee_cycle ? '0,-1' : values.review_cycle?.offset}
            />
            {(!!kpi.team || !!kpi.department) && (
              <LapeSingleCheckbox
                name="is_top_down"
                label="Mark as top down"
                description="
                Assign point of contact at target level"
              />
            )}
            {values.is_top_down && (
              <LapeRadioSelectInput<KpiInterface['owner']>
                name="owner"
                label="Owner"
                selector={selectorKeys.employee}
                clearable={false}
              />
            )}
          </InputGroup>
        </PageBody>
        <PageActions>
          <NewSaveButtonWithPopup
            noPopup
            useValidator
            disabled={
              values.employee_cycle ? !values.employee_cycle : !values.review_cycle
            }
            onAfterSubmit={() => {
              goBack(
                pathToUrl(ROUTES.FORMS.KPI.GENERAL, { id: kpi.id }),
                locationState.kpiLocationState,
                true,
              )
              successNotification('Target saved')
            }}
          >
            Save Target
          </NewSaveButtonWithPopup>
        </PageActions>
      </PageWrapper>
    </>
  )
})

export default (props: Props) => {
  return (
    <Form api={kpiTargetsRequestsNew}>
      <ValueTargetsForm {...props} />
    </Form>
  )
}
