import React, { useEffect, useState } from 'react'
import { Button, Flex, MoreBar } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { AutomationInterface } from '@src/interfaces/jobPostings/automation'
import Section from '@components/CommonSC/Section'
import { Pause, Play, Plus } from '@revolut/icons'
import { Grid } from '@components/CommonSC/Grid'
import { IdAndName, Statuses } from '@src/interfaces'
import { JobDescriptionFormRequest } from '@src/api/jobPosting'
import { useParams } from 'react-router-dom'
import { ApplicationFormSectionInterface } from '@src/interfaces/applicationForm'
import ConditionSection from '@src/pages/Forms/JobPostingAutomationRule/ConditionSection'
import { activateRule, getGeneralSections } from '@src/api/jobPostings/automation'
import { InputGroup } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { AxiosResponse } from 'axios'
import { JobPostingInterface } from '@src/interfaces/jobPosting'

const defaultCondition = {
  condition: undefined,
  join_condition: undefined,
  section: undefined,
  answer: undefined,
  question: undefined,
}

const Details = () => {
  const { jobPostingId } = useParams<{ jobPostingId: string }>()
  const { values, initialValues } = useLapeContext<AutomationInterface>()
  const active = values.status?.id === Statuses.active
  const [sections, setSections] = useState<ApplicationFormSectionInterface[]>([])

  const fetchJobPosting = async () => {
    const response: [
      AxiosResponse<ApplicationFormSectionInterface[]>,
      AxiosResponse<JobPostingInterface>,
    ] = await Promise.all([
      getGeneralSections(+jobPostingId),
      JobDescriptionFormRequest.get({ id: jobPostingId }),
    ])

    setSections([
      ...(response[0].data || []),
      ...(response[1].data.application_form_sections || []),
    ])
  }

  useEffect(() => {
    fetchJobPosting()

    if (!values.job_posting) {
      values.job_posting = { id: +jobPostingId }
    }

    if (!values.status) {
      values.status = {
        id: Statuses.active,
      }
    }
  }, [])

  useEffect(() => {
    if (!values.conditions?.length) {
      values.conditions = [{ ...defaultCondition }]
    }
  }, [values.conditions])

  const onAddCondition = () => {
    if (values.conditions) {
      values.conditions.push({ ...defaultCondition })
    } else {
      values.conditions = [{ ...defaultCondition }]
    }
  }

  const onDeleteCondition = (idx: number) => {
    values.conditions = values.conditions.filter((_, i) => i !== idx)
  }

  const onActivate = async () => {
    const resp = await activateRule(values.id, !active)
    values.status = resp.data.status
    // to avoid showing Save button, as we already saved this
    initialValues.status = { ...(values.status as IdAndName<Statuses>) }
  }

  return (
    <>
      <PageBody>
        {values.id && (
          <Flex mb="s-24">
            <MoreBar>
              <MoreBar.Action useIcon={active ? Pause : Play} onClick={onActivate}>
                {active ? 'Inactivate' : 'Activate'} rule
              </MoreBar.Action>
            </MoreBar>
          </Flex>
        )}
        <Grid gap={16}>
          <LapeNewInput name="name" label="Automation name" required />
          <Section>
            <Section.Title>When</Section.Title>
            <LapeRadioSelectInput
              label="Select"
              name="when"
              selector={selectorKeys.automation_rule_when}
            />
          </Section>
          {values.conditions?.map((_, idx) => (
            <ConditionSection
              key={idx}
              idx={idx}
              sections={sections}
              onDelete={onDeleteCondition}
            />
          ))}
          <Button useIcon={Plus} size="sm" variant="secondary" onClick={onAddCondition}>
            Add condition
          </Button>
          <Section>
            <Section.Title>Do this</Section.Title>
            <InputGroup>
              <LapeRadioSelectInput
                label="Select action"
                name="action"
                selector={selectorKeys.automation_rule_action}
              />
              {values.action?.id === 'archive' && (
                <LapeRadioSelectInput
                  label="Archival reason"
                  name="archived_reason"
                  selector={selectorKeys.interview_round_archived_reasons}
                />
              )}
              {values.action?.id === 'assign_recruiter' && (
                <LapeRadioSelectInput
                  label="Recruiter"
                  name="recruiter"
                  selector={selectorKeys.employee}
                />
              )}
            </InputGroup>
          </Section>
        </Grid>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup<AutomationInterface>
          successText="Automation rule saved successfully"
          onAfterSubmit={resp => {
            navigateReplace(
              pathToUrl(ROUTES.FORMS.JOB_POSTING_AUTOMATION.DETAILS, {
                jobPostingId,
                id: resp.id,
              }),
            )
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default connect(Details)
