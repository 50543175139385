import React, { useEffect } from 'react'
import ScorecardGeneral from '@components/ScorecardGeneral/ScorecardGeneral'
import {
  ChangeScorecardInterface,
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewScorecardInterface,
  ScorecardError,
} from '@src/interfaces/performance'
import {
  assessButtonsValidation,
  assessBehaviourButtonsValidation,
  getNormalizedCards,
  getUpdatedRatingCard,
  improvementFeedbackValidation,
} from '@src/utils/performance'
import * as yup from 'yup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import CultureFitHelp from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CultureFitHelp'
import { Statuses } from '@src/interfaces'
import { Text, List } from '@revolut/ui-kit'
import { Gift, StarEmpty } from '@revolut/icons'
import { useGetSkillsSettings } from '@src/api/settings'
import { AssessBehaviourButtonTypes } from '@components/AssessButtons/AssessBehaviourButtons'

export const validator = {
  review_data: yup.object().shape({
    culture_skills: yup
      .object()
      .nullable()
      .shape({
        skipped_section_justification: yup
          .string()
          .test('no-empty', 'Justification should not be empty', text =>
            typeof text?.trim() === 'string' ? text.trim().length > 0 : true,
          ),
        cards: yup.array().when('skipped_section_justification', {
          is: undefined,
          then: yup.array().of(
            yup.object().shape({
              sections: yup.array().of(
                yup
                  .object()
                  .shape<any>({
                    items: yup.array().of(
                      yup.object().shape({
                        value: yup.string().nullable(),
                      }),
                    ),
                  })
                  .test(
                    'assess-buttons',
                    'Please complete Culture Fit before submitting.',
                    assessButtonsValidation as any,
                  ),
              ),
            }),
          ),
          otherwise: yup.array(),
        }),
      }),
    culture_values: yup
      .object()
      .nullable()
      .shape({
        skipped_section_justification: yup
          .string()
          .test('no-empty', 'Justification should not be empty', text =>
            typeof text?.trim() === 'string' ? text.trim().length > 0 : true,
          ),
        cards: yup.array().when('skipped_section_justification', {
          is: undefined,
          then: yup.array().of(
            yup.object().shape({
              sections: yup.array().of(
                yup
                  .object()
                  .shape<any>({
                    items: yup.array().of(
                      yup.object().shape({
                        value: yup.string().nullable(),
                      }),
                    ),
                  })
                  .test(
                    'assess-buttons',
                    'Please complete Culture Fit before submitting.',
                    assessBehaviourButtonsValidation as any,
                  ),
              ),
            }),
          ),
          otherwise: yup.array(),
        }),
      })
      .test(
        'improvement-focus',
        'You should select at least 2 behaviours as "Improvement focus" across all values',
        improvementFeedbackValidation as any,
      ),
  }),
}

const CultureFit = () => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, errors, fields, change, submitFailed } = form
  const { data: skillsPreferences } = useGetSkillsSettings()

  useEffect(() => {
    if (form.validation?.review_data?.fields?.culture_values && skillsPreferences) {
      form.validation.review_data.fields.culture_values =
        form.validation.review_data.fields.culture_values.meta({ skillsPreferences })
    }
  }, [skillsPreferences, form.validation?.review_data])

  const initialType = values.review_data?.culture_values
    ? PerformanceReviewTypes.cultureValuesFit
    : PerformanceReviewTypes.cultureFit

  const cultureValuesCards = values?.review_data?.culture_values?.cards || []

  const normalizedCards = getNormalizedCards(
    values?.review_data?.culture_skills?.cards || [],
  )

  useEffect(() => {
    if (values.review_data.prefilled && values.status !== Statuses.completed) {
      Object.values(fields).forEach(state => {
        state.touched = true
      })
      form.submitFailed = true
      return
    }

    // We should reset submitFailed state when we enter this page to avoid showing validation errors
    if (submitFailed) {
      form.submitFailed = false
    }
  }, [])

  // pre-filling with "Performing" as a default value
  useEffect(() => {
    if (
      initialType === PerformanceReviewTypes.cultureValuesFit &&
      // if first found section has value, then all cards were already pre-filled
      !values.review_data.culture_values?.cards?.[0].sections[0].value
    ) {
      values.review_data.culture_values?.cards?.forEach(card => {
        card.sections.forEach(section => {
          if (!section.value) {
            section.value = AssessBehaviourButtonTypes.neutral
          }
        })
      })
    }
  }, [values.review_data.culture_values?.cards])

  if (!normalizedCards) {
    return null
  }

  const onSkip = (value?: string) => {
    if (initialType === PerformanceReviewTypes.cultureValuesFit) {
      values.review_data.culture_values!.skipped_section_justification = value
    } else {
      values.review_data.culture_skills.skipped_section_justification = value
    }
  }

  const onChange = async ({
    cardIndex,
    sectionIndex,
    optionIndex,
    value,
  }: ChangeScorecardInterface) => {
    const path = `review_data.culture_skills.cards.${cardIndex}`
    change(`${path}.sections.${sectionIndex}.items.${optionIndex}.value`, value)

    const updatedCard = await getUpdatedRatingCard(
      values.id,
      values.category,
      values.reviewed_employee.id!,
      values.review_data?.culture_skills?.cards?.[cardIndex],
    )
    if (updatedCard) {
      change(`${path}.rating`, updatedCard.rating)
    }
  }

  const onChangeCultureValues = async ({
    cardIndex,
    sectionIndex,
    value,
  }: ChangeScorecardInterface) => {
    change(
      `review_data.culture_values.cards.${cardIndex}.sections.${sectionIndex}.value`,
      value,
    )
  }

  const description = (
    <>
      <Text>
        Cultural fit is assessed based on behaviours rated from an “improvement focus” up
        to a “superpower”. Please select the most appropriate rating considering strengths
        and opportunities to develop.
      </Text>
      {skillsPreferences?.company_values_validation_enabled && (
        <List variant="compact" mt="s-8">
          <List.Item useIcon={<Gift color="green" size={16} />}>
            You can select <Text fontWeight="500">up to 3 superpower</Text> ratings
          </List.Item>
          <List.Item useIcon={<StarEmpty color="orange" size={16} />}>
            You must select <Text fontWeight="500">at least 2 improvement focus</Text>{' '}
            ratings
          </List.Item>
        </List>
      )}
    </>
  )

  return (
    <ScorecardGeneral
      allowSkip={values.category === ReviewCategory.Performance}
      cards={
        initialType === PerformanceReviewTypes.cultureValuesFit
          ? cultureValuesCards
          : normalizedCards
      }
      category={values.category}
      description={
        initialType === PerformanceReviewTypes.cultureValuesFit ? description : null
      }
      errors={(() => {
        if (submitFailed) {
          return initialType === PerformanceReviewTypes.cultureValuesFit
            ? (errors?.review_data?.culture_values?.cards as ScorecardError[])
            : (errors?.review_data?.culture_skills?.cards as ScorecardError[])
        }
        return null
      })()}
      justification={
        initialType === PerformanceReviewTypes.cultureValuesFit
          ? values?.review_data?.culture_values?.skipped_section_justification
          : values?.review_data?.culture_skills?.skipped_section_justification
      }
      helpSection={
        <CultureFitHelp
          isCultureValues={initialType === PerformanceReviewTypes.cultureValuesFit}
        />
      }
      onChange={
        initialType === PerformanceReviewTypes.cultureValuesFit
          ? onChangeCultureValues
          : onChange
      }
      onSkip={onSkip}
      subTitle={
        initialType === PerformanceReviewTypes.cultureValuesFit
          ? 'Value Based Behaviours'
          : null
      }
      title="Culture Fit"
      previousReviews={values?.review_data?.previous_reviews_by_cycle}
      touched={Object.values(fields).some(state => state.touched)}
      type={initialType}
    />
  )
}

export default CultureFit
