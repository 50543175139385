import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { LapeFormInterface } from '@src/features/Form/LapeForm'
import cloneDeep from 'lodash/cloneDeep'
import { ApiHandlerInterface, IdAndName } from '@src/interfaces'
import { EmailSenderType } from '@src/interfaces/emailTemplates'
import {
  getCandidate,
  getCandidateInterviewStages,
  getInterviewRound,
} from '@src/api/recruitment/interviews'
import { useEffect, useMemo, useState } from 'react'
import {
  CandidateInterface,
  InterviewFeedbackInterface,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { useExtensionApiContext } from '@src/utils/extension'
import set from 'lodash/set'
import get from 'lodash/get'
import { useGetCandidateSettings } from '@src/api/settings'
import { getMessageFromError } from '@src/store/notifications/actions'
import { formatDate } from '@src/utils/format'
import { SORT_DIRECTION } from '@src/interfaces/data'

export const updateStagesAfterDelete = <
  T extends { hiring_process_rounds?: HiringProcessInterface[] },
>(
  context: LapeFormInterface<T>,
  stageId: number,
) => {
  const { values, initialValues, reset } = context
  values.hiring_process_rounds = values?.hiring_process_rounds?.filter(round => {
    return round.id !== stageId
  })

  // we don't need to save the form and show Save button
  reset(values, {
    ...initialValues,
    hiring_process_rounds: cloneDeep(values.hiring_process_rounds),
  })
}

export const useGetTypesOfSender = (user?: string) => {
  const { data: candidateSettings } = useGetCandidateSettings()
  const options: (IdAndName<EmailSenderType> & { subtitle?: string })[] = [
    {
      id: 'user',
      name: user || 'Sender’s email',
    },
    {
      id: 'generic',
      name: candidateSettings?.no_reply_email || 'No Reply',
    },
  ]

  return options.map(value => ({
    key: value.id,
    label: value.name,
    value,
  }))
}

export const getLinkedinUrl = (links?: string[]) =>
  links?.find(link => link.includes('linkedin'))

const fetchCandidateData = async ({
  candidateId,
  apiHandler,
  setCandidate,
  setRound,
  setLoading,
  setStages,
}: {
  candidateId: string | number
  apiHandler?: ApiHandlerInterface
  setCandidate: (candidate: CandidateInterface) => void
  setRound: (round: InterviewRoundInterface) => void
  setStages: (stages: InterviewStageWithoutRoundInterface[]) => void
  setLoading: (loading: boolean) => void
}) => {
  setLoading(true)

  const candidateResult = await getCandidate(candidateId, apiHandler)
  setCandidate(candidateResult.data)

  if (candidateResult.data.active_interview_round) {
    const roundResult = await getInterviewRound(
      candidateResult.data.active_interview_round.id,
      apiHandler,
    )
    setRound(roundResult.data)

    const sortBy = [
      {
        sortBy: 'interview_number',
        direction: SORT_DIRECTION.DESC,
        nonResettable: true,
      },
      {
        sortBy: 'interview_number_for_type',
        direction: SORT_DIRECTION.DESC,
        nonResettable: true,
      },
    ]

    const stagesResult = await getCandidateInterviewStages(
      { sortBy },
      candidateResult.data.active_interview_round.id,
      apiHandler,
    )
    setStages(stagesResult.data.results)
  }

  setLoading(false)
}

export const useFetchCandidateData = (candidateId: string) => {
  const apiHandler = useExtensionApiContext()
  const [stages, setStages] = useState<InterviewStageWithoutRoundInterface[]>([])
  const [round, setRound] = useState<InterviewRoundInterface>()
  const [candidate, setCandidate] = useState<CandidateInterface>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchCandidateData({
      candidateId,
      apiHandler,
      setCandidate,
      setRound,
      setStages,
      setLoading,
    })
  }, [candidateId])

  return { stages, round, candidate, loading }
}

type CandidateDataType = {
  candidate?: CandidateInterface
  round?: InterviewRoundInterface
  stages?: InterviewStageWithoutRoundInterface[]
  loaded?: boolean
  error?: string
}

const PRELOADED_CANDIDATES_COUNT = 3

export const useFetchMultipleCandidates = (
  currentCandidateId: string,
  candidateIds: (number | string)[],
  defaultErrorMessage?: string,
) => {
  const apiHandler = useExtensionApiContext()
  const currentCandidateIndex = candidateIds.findIndex(id => id === currentCandidateId)
  const trimmedCandidateIds = useMemo(
    () =>
      candidateIds.slice(
        currentCandidateIndex,
        currentCandidateIndex + PRELOADED_CANDIDATES_COUNT,
      ),
    [candidateIds, currentCandidateIndex],
  )

  const [candidates, setCandidates] = useState<Map<string, CandidateDataType>>(new Map())

  const setCandidateData = (
    candidateId: string | number,
    setterFn: (candidateData: CandidateDataType) => CandidateDataType,
  ) => {
    setCandidates(prev => {
      const candidatesData = new Map(prev)
      const candidateData = { ...(candidatesData.get(String(candidateId)) || {}) }
      candidatesData.set(String(candidateId), setterFn(candidateData))
      return candidatesData
    })
  }

  const fetchData = (forceUpdate = false) => {
    trimmedCandidateIds.forEach(async candidateId => {
      if (!candidates.has(String(candidateId)) || forceUpdate) {
        try {
          await fetchCandidateData({
            candidateId,
            apiHandler,
            setLoading: response => {
              setCandidateData(candidateId, candidateData => ({
                ...candidateData,
                loaded: !response,
              }))
            },
            setCandidate: response => {
              setCandidateData(candidateId, candidateData => ({
                ...candidateData,
                candidate: response,
              }))
            },
            setRound: response => {
              setCandidateData(candidateId, candidateData => ({
                ...candidateData,
                round: response,
              }))
            },
            setStages: response => {
              setCandidateData(candidateId, candidateData => ({
                ...candidateData,
                stages: response,
              }))
            },
          })
        } catch (e) {
          setCandidateData(candidateId, candidateData => ({
            ...candidateData,
            error: getMessageFromError(e, defaultErrorMessage),
            loaded: true,
          }))
        }
      }
    })

    const tempCandidates = new Map(candidates)

    for (let key of tempCandidates.keys()) {
      if (!trimmedCandidateIds.includes(key)) {
        tempCandidates.delete(key)
      }
    }

    setCandidates(tempCandidates)
  }

  const refresh = () => {
    fetchData(true)
  }

  useEffect(() => {
    fetchData()
  }, [trimmedCandidateIds])

  return { candidates, refresh }
}

export const setTimeCodeHandler = <T extends InterviewFeedbackInterface>(
  notesName?: string,
  values?: T,
) => {
  if (!notesName || !values) {
    return undefined
  }

  return (time: string) => {
    set(values, notesName, `${get(values, notesName)}${time}`)
  }
}

export const formatExperienceDateRange = (startDate: string, endDate?: string | null) => {
  const formattedEndDate = endDate ? formatDate(endDate, 'MMM yyyy') : 'Present'
  return `${formatDate(startDate, 'MMM yyyy')} - ${formattedEndDate}`
}

export const hasEmailNonPrefilledPlaceholders = (str?: string) => str?.match(/{([^}]+)}/g)
