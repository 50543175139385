import { VStack, Widget } from '@revolut/ui-kit'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import React, { useMemo } from 'react'
import SectionTitle from '../components/SectionTitle'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import { CYCLE_DURATION } from '@src/interfaces/settings'
import { RecommendedLabel } from '../components/Recommended'

export const PerformanceFrequency = () => {
  const { data } = useGetSelectors(selectorKeys.cycle_durations)
  const options = useMemo(() => {
    if (data && data.length) {
      const descriptionsById = {
        quarterly:
          'Conducted every three months, these reviews offer a more frequent opportunity for feedback and goal adjustments',
        biannually:
          'Held twice a year, these reviews provide a midpoint check-in to assess progress and set goals for the next period',
        annually:
          "Traditional annual reviews are conducted once a year. They offer a comprehensive overview of an employee's performance over the past year",
      }
      return data.map(option => ({
        value: option,
        description: descriptionsById[option.id as CYCLE_DURATION],
        label:
          option.id === 'quarterly' ? (
            <RecommendedLabel label={option.name} />
          ) : (
            option.name
          ),
      }))
    }
    return []
  }, [data])

  return (
    <VStack>
      <SectionTitle
        title="How often do you want to do performance reviews?"
        subtitle="Select how often the company will set goals and review employee performance"
      />
      <Widget>
        <LapeNewRadioButtons
          hightlightSelected
          name="cycle_duration"
          options={options}
          variant="group-items"
        />
      </Widget>
    </VStack>
  )
}
