import React from 'react'
import { useSelector } from 'react-redux'
import { Flex, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { RowInterface } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@src/components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@src/components/Table/TableWrapper'
import { getContractTypes } from '@src/api/contracts'
import { ContractTypeInterface } from '@src/interfaces/contracts'
import {
  contractTypeDescriptionColumn,
  contractTypeEmployeeTypeColumn,
  contractTypeNameColumn,
} from '@src/constants/columns/contracts'

const ROW: RowInterface<ContractTypeInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.CONTRACT_TYPE.GENERAL, { id })),
  cells: [
    {
      ...contractTypeNameColumn,
      width: 300,
    },
    {
      ...contractTypeEmployeeTypeColumn,
      width: 200,
    },
    {
      ...contractTypeDescriptionColumn,
      width: 500,
    },
  ],
}

export const ContractTypes = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddContractType)

  const table = useTable({ getItems: getContractTypes })

  return (
    <TableWrapper>
      <Flex mb="s-24">
        <Stat label="Total" val={table?.loading ? undefined : table?.count} />
      </Flex>
      {canAdd && (
        <Flex mb="s-16">
          <MoreBar>
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.CONTRACT_TYPE.GENERAL)}
              useIcon={Plus}
            >
              Add contract type
            </MoreBar.Action>
          </MoreBar>
        </Flex>
      )}
      <AdjustableTable
        name={TableNames.ContractTypes}
        useWindowScroll
        row={ROW}
        {...table}
        noDataMessage="Contract types will appear here"
      />
    </TableWrapper>
  )
}
