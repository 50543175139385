import { Download } from '@revolut/icons'
import { MoreBar, Spinner } from '@revolut/ui-kit'
import { getPayGroupExport } from '@src/api/payroll'
import { ExportTypes } from '@src/constants/export'
import { saveFileByBlob } from '@src/utils'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'

export const BaseReportDownload = ({ id }: { id: string }) => {
  const [loading, setLoading] = useState(false)

  const onExportItem = () => {
    if (!loading) {
      setLoading(true)
      getPayGroupExport(id)(ExportTypes.xlsx)
        .then((response: AxiosResponse<string>) => {
          saveFileByBlob(
            response.data,
            `BaseReport.xlsx`,
            response.headers['content-type'],
          )
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <MoreBar.Action
      useIcon={loading ? <Spinner size={16} /> : Download}
      onClick={onExportItem}
      aria-disabled={loading}
    >
      {loading
        ? 'Fetching report... This might take a few minutes'
        : 'Download base report'}
    </MoreBar.Action>
  )
}
