import {
  ChatMessageInterface,
  ChatMessageStatus,
  CommentsAPIInterface,
  TaggedUsersMap,
} from '@src/interfaces/chat'
import { useFetch } from '@src/utils/reactQuery'
import { ApiVersion, GetRequestData } from '@src/interfaces'
import { api } from '@src/api/index'
import { API } from '@src/constants/api'

export interface CommentsAPIOptions {
  baseUrl: string
  apiVersion?: ApiVersion
}

export const getCommentsAPI = ({
  baseUrl,
  apiVersion = 'v1',
}: CommentsAPIOptions): CommentsAPIInterface => {
  return {
    useGetComments: (showArchived, disabled) => {
      return useFetch<GetRequestData<ChatMessageInterface>>(
        disabled ? null : baseUrl,
        apiVersion,
        showArchived
          ? {}
          : {
              params: {
                status: `${ChatMessageStatus.active},${ChatMessageStatus.completed}`,
              },
            },
      )
    },
    useGetComment: id => {
      return useFetch<ChatMessageInterface>(id ? `${baseUrl}/${id}` : null, apiVersion)
    },
    addComment: (comment, createTask) => {
      return api.post<ChatMessageInterface>(
        baseUrl,
        {
          body: comment,
          create_task: createTask,
        },
        undefined,
        apiVersion,
      )
    },
    editComment: (commentId, updatedMsg) => {
      return api.patch<ChatMessageInterface>(
        `${baseUrl}/${commentId}`,
        {
          body: updatedMsg,
        },
        undefined,
        apiVersion,
      )
    },
    archiveComment: commentId => {
      return api.post(`${baseUrl}/${commentId}/archive`, undefined, undefined, apiVersion)
    },
    resolveComment: commentId => {
      return api.post(
        `${baseUrl}/${commentId}/complete`,
        undefined,
        undefined,
        apiVersion,
      )
    },
  }
}

export const useGetInboxTaggedEmployees = () => {
  return useFetch<TaggedUsersMap>(`${API.NOTIFICATIONS}/taggedEmployees`, 'v2')
}
