import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { useParams } from 'react-router-dom'
import Settings from './Settings'

const FunctionsSettingsPage = () => {
  const params = useParams<{ id: string }>()

  const isEditing = params.id !== undefined

  const backUrl = isEditing
    ? pathToUrl(ROUTES.FORMS.FUNCTION.SUMMARY, { id: String(params?.id) })
    : ROUTES.FUNCTION.FUNCTIONS

  const title = isEditing ? 'Edit Function' : 'New Function'

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={backUrl} />
      <Settings />
    </PageWrapper>
  )
}

export default FunctionsSettingsPage
