import React from 'react'
import PageLoading from '@components/PageLoading/PageLoading'
import DepartmentDeprecated from '@src/pages/Forms/DepartmentForm'
import { DepartmentLayout } from '@src/pages/Department/Layout'
import { useIsNewOrgLayout } from '@src/pages/Team/helpers'

export const Department = () => {
  const isNewLayout = useIsNewOrgLayout()

  if (isNewLayout === undefined) {
    return <PageLoading />
  }
  return isNewLayout ? <DepartmentLayout /> : <DepartmentDeprecated />
}
