import {
  FetchDataQueryInterface,
  FilterByInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { ExportRequest, GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery, groupFiltersIntoURLQuery } from '@src/utils/table'
import {
  ManuallyAddedPayCycleInterface,
  PayCycleDetailedReportInterface,
  PayCycleInterface,
  PayCyclePaymentInterface,
  PayCyclePaymentStatsInterface,
  PayCyclePreviewInterface,
  PayCycleReportInterface,
  PayCycleReportsStatsInterface,
  PayCycleSettlementInterface,
  PayCyclesStatsInterface,
  PaygroupInterface,
  PaygroupStatus,
} from '@src/interfaces/payroll'
import { useFetch } from '@src/utils/reactQuery'
import { useMemo } from 'react'
import { formatPeriod } from '@src/utils/format'
import { getCommentsAPI } from './comments'

export const payCycleReportsTableRequests = {
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<PayCycleInterface>>(`${API.PAYROLL}/cycles`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<PayCyclesStatsInterface>(`${API.PAYROLL}/cycles/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const usePaygroups = () =>
  useFetch<GetRequestInterface<PaygroupInterface>>(API.PAY_GROUPS)

export const usePaygroup = (id?: number | string) => {
  const fetchResults = useFetch<PaygroupInterface>(id ? `${API.PAY_GROUPS}/${id}` : null)

  const allSettlementProfiles = fetchResults.data?.payroll_elements.map(
    pe => pe.settlement_profile,
  )

  const nonNullSettlementProfiles = allSettlementProfiles?.filter(
    profile => profile !== null,
  ) as string[] | undefined

  const uniqueSettlementProfiles = [...new Set(nonNullSettlementProfiles)]

  const selectableSettlementProfiles = uniqueSettlementProfiles.map(profile => ({
    id: profile,
    name: profile,
  }))

  return { ...fetchResults, selectableSettlementProfiles }
}

export const closePayCycle = (id: string) =>
  api.post<PayCycleInterface>(`${API.PAYROLL}/cycles/${id}/close`)

export const usePayCycle = (id: string | null) =>
  useFetch<PayCycleInterface>(id ? `${API.PAYROLL}/cycles/${id}` : null)

export const usePayCycleFiles = (id: string) =>
  useFetch<PayCycleInterface>(`${API.PAYROLL}/cycles/${id}/file`)

export const getPayCycleExport =
  (id: string): ExportRequest =>
  (exportType, filterQuery) =>
    api.get(`${API.PAYROLL}/cycles/${id}/reports/${exportType}`, {
      params: filterQuery,
      responseType: 'blob',
    })

export const getPayGroupExport =
  (id: string): ExportRequest =>
  (exportType, filterQuery) =>
    api.get(`${API.PAYROLL}/payGroups/${id}/reports/${exportType}`, {
      params: filterQuery,
      responseType: 'blob',
    })

export const usePayCycles = (paygroupId?: string, sortAsc?: boolean) => {
  const fetchResults = useFetch<GetRequestInterface<PayCycleInterface>>(
    `${API.PAYROLL}/cycles`,
    'v1',
    {
      params: filterSortPageIntoQuery(
        [
          {
            sortBy: 'start_date',
            direction: sortAsc ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC,
          },
        ],
        paygroupId
          ? [
              {
                filters: [{ id: paygroupId, name: paygroupId }],
                columnName: 'pay_group_id',
                nonResettable: true,
              },
            ]
          : [],
        1,
      ),
    },
  )

  const selectablePaycycles = useMemo(() => {
    return fetchResults.data?.results.map(option => ({
      id: option.id,
      name: formatPeriod(new Date(option.start_date), new Date(option.end_date)),
    }))
  }, [fetchResults.data])

  return { ...fetchResults, selectablePaycycles }
}

export const getPaygroups = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<PaygroupInterface>> =>
  api.get(API.PAY_GROUPS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const paygroupRequests: RequestInterfaceNew<PaygroupInterface> = {
  get: async ({ id }) => api.get(`${API.PAY_GROUPS}/${id}`),
  submit: async data => apiWithoutHandling.post(API.PAY_GROUPS, data),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.PAY_GROUPS}/${id}`, data),
  delete: async ({ id }) => api.delete(`${API.PAY_GROUPS}/${id}`),
}

export const getPaycyclePreview = (
  data: PaygroupInterface & { number_of_schedules: number },
) =>
  apiWithoutHandling.post<PayCyclePreviewInterface[]>(
    `${API.PAY_GROUPS}/payScheduleDates`,
    data,
  )

export const paycycleRequests: RequestInterfaceNew<PayCycleInterface> = {
  get: async ({ id }) => api.get(`${API.PAYROLL}/cycles/${id}`),
  submit: async data => apiWithoutHandling.post(`${API.PAYROLL}/cycles`, data),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.PAYROLL}/cycles/${id}`, data),
  delete: async ({ id }) => api.delete(`${API.PAYROLL}/cycles/${id}`),
}

export const getPayCycleReportsTableRequests = (id: string) => ({
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<PayCycleReportInterface>>(
      `${API.PAYROLL}/cycles/${id}/reports`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
  getStats: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<PayCycleReportsStatsInterface>(`${API.PAYROLL}/cycles/${id}/reports/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getPayCycleDetailedReport = (id: string, employeeId: string) =>
  api.get<PayCycleDetailedReportInterface>(
    `${API.PAYROLL}/cycles/${id}/reports/${employeeId}`,
  )

export const useEmployeePayrollData = (id: string, employeeId: string) =>
  useFetch<PayCycleDetailedReportInterface>(
    `${API.PAYROLL}/cycles/${id}/reports/${employeeId}`,
  )

export const updatePaygroupStatus = (
  newStatus: PaygroupStatus,
  id: number,
): AxiosPromise<PaygroupInterface> => {
  return api.patch(`${API.PAY_GROUPS}/${id}`, { status: newStatus })
}

export const addPayCycleFormRequests: RequestInterfaceNew<ManuallyAddedPayCycleInterface> =
  {
    get: async ({ id }) => api.get(`${API.PAYROLL}/cycles/${id}`),
    submit: async data => apiWithoutHandling.post(`${API.PAYROLL}/cycles`, data),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.PAYROLL}/cycles/${id}`, data),
  }

export const createSettlement = (data: Partial<PayCycleSettlementInterface>) =>
  api.post<PayCycleSettlementInterface>(`${API.PAYROLL}/cycles/settlements`, data)

export const paymentsTableRequests = {
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<PayCyclePaymentInterface>>(
      `${API.PAYROLL}/cycles/payments`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
  getStats: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<PayCyclePaymentStatsInterface>(`${API.PAYROLL}/cycles/payments/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const createPayment = (data: Partial<PayCyclePaymentInterface>) =>
  api.post<PayCyclePaymentInterface>(`${API.PAYROLL}/cycles/payments`, data)

export const paymentFormRequests: RequestInterfaceNew<PayCyclePaymentInterface> = {
  submit: data => {
    return api.post<PayCyclePaymentInterface>(`${API.PAYROLL}/cycles/payments`, data)
  },
  get: ({ id }) => {
    return api.get(`${API.PAYROLL}/cycles/payments/${id}`)
  },
  update: (_diff, _params, data) => {
    // there is no way to update a payment at the moment, create a new one instead
    return api.post<PayCyclePaymentInterface>(`${API.PAYROLL}/cycles/payments`, data)
  },
}

export const deletePayment = (id: string) =>
  api.delete(`${API.PAYROLL}/cycles/payments/${id}`)

export const bulkDeletePayments = (
  params: ReturnType<typeof groupFiltersIntoURLQuery> & {
    id?: string
    excluded_ids?: string
  },
) =>
  api.delete(`${API.PAYROLL}/cycles/payments/delete`, {
    params,
  })

export const usePayment = (id?: number) =>
  useFetch<PayCyclePaymentInterface>(id ? `${API.PAYROLL}/cycles/payments/${id}` : null)

export const useFilteredPaymentsSelector = (filters: FilterByInterface[]) =>
  useFetch<{ options: PayCyclePaymentInterface[] }>(
    `${API.PAYROLL}/cycles/payments/selector`,
    'v1',
    {
      params: groupFiltersIntoURLQuery(filters),
    },
  )

export const getPayCycleCommentsAPI = (payCycleId: string | number) =>
  getCommentsAPI({
    baseUrl: `${API.PAYROLL}/cycles/${payCycleId}/comments`,
  })

export const getPayCycleReportsCommentsAPI = (
  payCycleId: string | number,
  reportId: string | number,
) =>
  getCommentsAPI({
    baseUrl: `${API.PAYROLL}/cycles/${payCycleId}/reports/${reportId}/comments`,
  })

export const paygroupsBulkEdit = (data: PaygroupInterface[]) =>
  apiWithoutHandling.patch(API.PAY_GROUPS, data)
