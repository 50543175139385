import React from 'react'
import { SelectOptionItemType } from '@revolut/ui-kit'
import { get } from 'lodash'
import { format } from 'date-fns'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { utcToLocalDate } from '@src/utils/timezones'
import { IdAndName } from '@src/interfaces'
import { EmployeeEmails } from '@src/interfaces/selectors'
import { CustomFieldsForSectionInterface } from '@src/interfaces/customFields'
import { ImportCell } from './bulkDataImport'
import { CustomFieldTypes } from '@src/constants/customFields'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { ImportEmployeeDataInterface } from '@src/interfaces/importEmployees'

export type ImportEmployeeInterface = ImportInterface<ImportEmployeeDataInterface>

export type ImportEmployeeCellOptions = {
  employeeOptions: SelectOptionItemType<EmployeeEmails>[]
  contractTypeOptions: SelectOptionItemType<IdAndName<string, string>>[]
  countryOptions: SelectOptionItemType<IdAndName<string, string>>[]
  employeeTypeOptions: SelectOptionItemType<IdAndName<string, string>>[]
  contractTermOptions: SelectOptionItemType<IdAndName<string, string>>[]
  salaryPaymentFrequencyOptions: SelectOptionItemType<IdAndName<string, string>>[]
  employeeStatusOptions: SelectOptionItemType<IdAndName<string, string>>[]
  salaryTimeUnitOptions: SelectOptionItemType<IdAndName<string, string>>[]
  contractWorkModalityOptions: SelectOptionItemType<IdAndName<string, string>>[]
  contractNoticePeriodTimeUnitOptions: SelectOptionItemType<IdAndName<string, string>>[]
}

const getCustomFieldDisplayValue = (
  data: ImportEmployeeInterface,
  field: string,
  customField?: CustomFieldsForSectionInterface,
) => {
  const value = get(data.data, field)

  switch (customField?.input_type?.id) {
    case CustomFieldTypes.Number:
    case CustomFieldTypes.Text:
      return value
    case CustomFieldTypes.Dropdown:
      return value.name
    case CustomFieldTypes.Date:
      return format(utcToLocalDate(value), 'd MMM yyyy')
    case CustomFieldTypes.MultipleSelector:
      return Array.isArray(value) ? value.map(val => val.name).join(', ') : value
    default:
      return '-'
  }
}

export const importEmployeesFirstNameColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'first_name',
  dataPoint: 'first_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'First name',
  insert: ({ data }) => <ImportCell field="first_name" data={data} />,
}

export const importEmployeesMiddleNameColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'middle_name',
  dataPoint: 'middle_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Middle name',
  insert: ({ data }) => <ImportCell field="middle_name" data={data} />,
}

export const importEmployeesLastNameColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'last_name',
  dataPoint: 'last_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Last name',
  insert: ({ data }) => <ImportCell field="last_name" data={data} />,
}

export const importEmployeesPreferredNameColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'preferred_name',
    dataPoint: 'preferred_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Preferred name',
    insert: ({ data }) => <ImportCell field="preferred_name" data={data} />,
  }

export const importEmployeesEmailColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Email',
  insert: ({ data }) => <ImportCell field="email" data={data} />,
}

export const importEmployeesAccessLevelColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'access_level',
    dataPoint: 'access_level',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.groups,
    title: 'Access level',
    insert: ({ data }) => <ImportCell field="access_level" data={data} />,
  }

export const importEmployeesTeamColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Team',
  insert: ({ data }) => <ImportCell field="team" data={data} />,
}

export const importEmployeesDepartmentColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'department',
  dataPoint: 'department',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Department',
  insert: ({ data }) => <ImportCell field="department" data={data} />,
}

export const importEmployeesSpecialisationColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'specialisation',
    dataPoint: 'specialisation',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Specialisation',
    insert: ({ data }) => <ImportCell field="specialisation" data={data} />,
  }

export const importEmployeesJobTitleColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'job_title',
  dataPoint: 'job_title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Job title',
  insert: ({ data }) => <ImportCell field="job_title" data={data} />,
}

export const importEmployeesTypeColumn = (
  options: ImportEmployeeCellOptions,
): ColumnInterface<ImportEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'employee_type',
  dataPoint: 'employee_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Type',
  insert: ({ data }) => (
    <ImportCell
      field="employee_type"
      data={data}
      options={options.employeeTypeOptions}
      type="option"
    />
  ),
})

export const importEmployeesSeniorityColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
  insert: ({ data }) => <ImportCell field="seniority" data={data} />,
}

export const importEmployeesLineManagerColumn = (
  options: ImportEmployeeCellOptions,
): ColumnInterface<ImportEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'line_manager',
  dataPoint: 'line_manager',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Line manager',
  insert: ({ data }) => (
    <ImportCell
      field="line_manager"
      data={data}
      options={options?.employeeOptions}
      type="employee"
    />
  ),
})

export const importEmployeesFunctionalManagerColumn = (
  options: ImportEmployeeCellOptions,
): ColumnInterface<ImportEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'functional_manager',
  dataPoint: 'functional_manager',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Functional manager',
  insert: ({ data }) => (
    <ImportCell
      field="functional_manager"
      data={data}
      options={options?.employeeOptions}
      type="employee"
    />
  ),
})

export const importEmployeesLocationColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'location',
  dataPoint: 'location',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Location',
  insert: ({ data }) => <ImportCell field="location" data={data} />,
}

export const importEmployeesCountryColumn = (
  options: ImportEmployeeCellOptions,
): ColumnInterface<ImportEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'country',
  dataPoint: 'country',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Country',
  insert: ({ data }) => (
    <ImportCell field="country" data={data} options={options?.countryOptions} />
  ),
})

export const importEmployeesEntityColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'entity',
  dataPoint: 'entity',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Entity',
  insert: ({ data }) => <ImportCell field="entity" data={data} />,
}

export const importEmployeesStartDateColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'start_date',
  dataPoint: 'start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start date',
  insert: ({ data }) => <ImportCell field="start_date" data={data} type="date" />,
}

export const importEmployeesProbationEndDateColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'probation_end_date',
    dataPoint: 'probation_end_date',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Probation end date',
    insert: ({ data }) => (
      <ImportCell field="probation_end_date" data={data} type="date" />
    ),
  }

export const importEmployeesSalaryAmountColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'salary_amount',
    dataPoint: 'salary_amount',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary amount',
    insert: ({ data }) => <ImportCell field="salary_amount" data={data} />,
  }

export const importEmployeesSalaryTimeUnitColumn = (
  options: ImportEmployeeCellOptions,
): ColumnInterface<ImportEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'salary_time_unit',
  dataPoint: 'salary_time_unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Salary time unit',
  insert: ({ data }) => (
    <ImportCell
      field="salary_time_unit"
      data={data}
      options={options.salaryTimeUnitOptions}
      type="option"
    />
  ),
})

export const importEmployeesWorkModalityColumn = (
  options: ImportEmployeeCellOptions,
): ColumnInterface<ImportEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'work_modality',
  dataPoint: 'work_modality',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Work modality',
  insert: ({ data }) => (
    <ImportCell
      field="work_modality"
      data={data}
      options={options.contractWorkModalityOptions}
      type="option"
    />
  ),
})

export const importEmployeesContractTypeColumn = (
  options: ImportEmployeeCellOptions,
): ColumnInterface<ImportEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'contract_type',
  dataPoint: 'contract_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Contract type',
  insert: ({ data }) => (
    <ImportCell
      field="contract_type"
      data={data}
      options={options.contractTypeOptions}
      type="option"
    />
  ),
})

export const importEmployeesContractTermColumn = (
  options: ImportEmployeeCellOptions,
): ColumnInterface<ImportEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'contract_term',
  dataPoint: 'contract_term',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Contract term',
  insert: ({ data }) => (
    <ImportCell
      field="contract_term"
      data={data}
      options={options.contractTermOptions}
      type="option"
    />
  ),
})

export const importEmployeesSalaryCurrencyColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'salary_currency',
    dataPoint: 'salary_currency',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary currency',
    insert: ({ data }) => <ImportCell field="salary_currency" data={data} />,
  }

export const importEmployeesSalaryPaymentFrequencyColumn = (
  options: ImportEmployeeCellOptions,
): ColumnInterface<ImportEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'salary_payment_frequency',
  dataPoint: 'salary_payment_frequency',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Salary payment frequency',
  insert: ({ data }) => (
    <ImportCell
      field="salary_payment_frequency"
      data={data}
      options={options.salaryPaymentFrequencyOptions}
      type="option"
    />
  ),
})

export const importEmployeesWeeklyWorkingHoursColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'weekly_working_hours',
    dataPoint: 'weekly_working_hours',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Weekly working hours',
    insert: ({ data }) => <ImportCell field="weekly_working_hours" data={data} />,
  }

export const importEmployeesFullTimeEquivalentColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'full_time_equivalent',
    dataPoint: 'full_time_equivalent',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Full time equivalent',
    insert: ({ data }) => <ImportCell field="full_time_equivalent" data={data} />,
  }

export const importEmployeesNoticePeriodAfterProbationColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'notice_period_after_probation',
    dataPoint: 'notice_period_after_probation',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Notice period after probation',
    insert: ({ data }) => (
      <ImportCell field="notice_period_after_probation" data={data} />
    ),
  }

export const importEmployeesNoticePeriodAfterProbationUnitColumn = (
  options: ImportEmployeeCellOptions,
): ColumnInterface<ImportEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'notice_period_after_probation_unit',
  dataPoint: 'notice_period_after_probation_unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Notice period after probation unit',
  insert: ({ data }) => (
    <ImportCell
      field="notice_period_after_probation_unit"
      data={data}
      options={options.contractNoticePeriodTimeUnitOptions}
      type="option"
    />
  ),
})
export const importEmployeesNoticePeriodDuringProbationColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'notice_period_during_probation',
    dataPoint: 'notice_period_during_probation',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Notice period during probation',
    insert: ({ data }) => (
      <ImportCell field="notice_period_during_probation" data={data} />
    ),
  }

export const importEmployeesNoticePeriodDuringProbationUnitColumn = (
  options: ImportEmployeeCellOptions,
): ColumnInterface<ImportEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'notice_period_during_probation_unit',
  dataPoint: 'notice_period_during_probation_unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Notice period during probation unit',
  insert: ({ data }) => (
    <ImportCell
      field="notice_period_during_probation_unit"
      data={data}
      options={options.contractNoticePeriodTimeUnitOptions}
      type="option"
    />
  ),
})

export const importEmployeesPersonalEmailColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'personal_email',
    dataPoint: 'personal_email',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Personal email',
    insert: ({ data }) => <ImportCell field="personal_email" data={data} />,
  }

export const importEmployeesPhoneCountryCodeColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'phone_country_code',
    dataPoint: 'phone_country_code',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Phone country code',
    insert: ({ data }) => <ImportCell field="phone_country_code" data={data} />,
  }

export const importEmployeesPhoneNumberShortColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'phone_number_short',
    dataPoint: 'phone_number_short',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Phone number short',
    insert: ({ data }) => <ImportCell field="phone_number_short" data={data} />,
  }

export const importEmployeesAddressLine1Column: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'address_line_1',
    dataPoint: 'address_line_1',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Address line 1',
    insert: ({ data }) => <ImportCell field="address_line_1" data={data} />,
  }

export const importEmployeesAddressLine2Column: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'address_line_2',
    dataPoint: 'address_line_2',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Address line 2',
    insert: ({ data }) => <ImportCell field="address_line_2" data={data} />,
  }

export const importEmployeesAddressLine3Column: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'address_line_3',
    dataPoint: 'address_line_3',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Address line 3',
    insert: ({ data }) => <ImportCell field="address_line_3" data={data} />,
  }

export const importEmployeesLegalSexColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'legal_sex',
  dataPoint: 'legal_sex',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Legal sex',
  insert: ({ data }) => <ImportCell field="legal_sex" data={data} />,
}

export const importEmployeesNationalInsuranceNumberColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'national_insurance_number',
    dataPoint: 'national_insurance_number',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'National insurance number',
    insert: ({ data }) => <ImportCell field="national_insurance_number" data={data} />,
  }

export const importEmployeesEmployeePensionContributionColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee_pension_contribution',
    dataPoint: 'employee_pension_contribution',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employee pension contribution',
    insert: ({ data }) => (
      <ImportCell field="employee_pension_contribution" data={data} />
    ),
  }

export const importEmployeesEmployerPensionContributionColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employer_pension_contribution',
    dataPoint: 'employer_pension_contribution',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employer pension contribution',
    insert: ({ data }) => (
      <ImportCell field="employer_pension_contribution" data={data} />
    ),
  }

export const importEmployeesBankNameColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'bank_name',
  dataPoint: 'bank_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bank name',
  insert: ({ data }) => <ImportCell field="bank_name" data={data} />,
}

export const importEmployeesAccountNameColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'account_name',
    dataPoint: 'account_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Account name',
    insert: ({ data }) => <ImportCell field="account_name" data={data} />,
  }

export const importEmployeesBankCurrencyColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'bank_currency',
    dataPoint: 'bank_currency',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Bank currency',
    insert: ({ data }) => <ImportCell field="bank_currency" data={data} />,
  }

export const importEmployeesBankAccountNumberColumn: ColumnInterface<ImportEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'bank_account_number',
    dataPoint: 'bank_account_number',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Bank account number',
    insert: ({ data }) => <ImportCell field="bank_account_number" data={data} />,
  }

export const importEmployeesSortCodeColumn: ColumnInterface<ImportEmployeeInterface> = {
  type: CellTypes.insert,
  idPoint: 'sort_code',
  dataPoint: 'sort_code',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Sort code',
  insert: ({ data }) => <ImportCell field="sort_code" data={data} />,
}

export const importEmployeesStatusColumn = (
  options: ImportEmployeeCellOptions,
): ColumnInterface<ImportEmployeeInterface> => ({
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data }) => (
    <ImportCell
      field="status"
      data={data}
      options={options.employeeStatusOptions}
      type="option"
    />
  ),
})

export const importEmployeesExtraFieldColumn = (
  field: string,
  customFields: CustomFieldsForSectionInterface[],
): ColumnInterface<ImportEmployeeInterface> => {
  const customField = customFields?.find(cf => cf.field_name === field)

  return {
    type: CellTypes.insert,
    idPoint: field,
    dataPoint: field,
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: customField ? customField.name : field,
    insert: ({ data }) => (
      <ImportCell
        field={field}
        data={data}
        renderDisplayValue={() => getCustomFieldDisplayValue(data, field, customField)}
      />
    ),
  }
}
