import React from 'react'
import styled, { css } from 'styled-components'
import { useTheme } from '@src/styles/theme'
import { colorGetter, getColor } from '@src/styles/colors'
import { Box, Color, Flex, Text, Token } from '@revolut/ui-kit'
import { LegendItemInterface } from '@src/interfaces/chart'

interface LegendInterface {
  isBelow: boolean
  hasTarget?: boolean
  hasForecast?: boolean
  legendItems?: LegendItemInterface[]
  colors: string[]

  targetLayerTitle?: string
  targetLayerColor?: Color
}

const ScrollCSS = css`
  overflow-y: auto;
`

export const LegendWrapperBigBottom = styled.div`
  height: 20vh;
  max-height: 170px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 90%;
  border-radius: 1rem;
  width: 100%;
  padding: 16px 24px;
  background: ${colorGetter('segmented-background')};
`

export const LegendWrapperBigRight = styled.div<{ height?: number }>`
  max-height: ${props => (props.height ? `${props.height}px` : 'calc(100% - 34px)')};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 1rem;
  width: 246px;
  padding: 16px 24px;
  margin-left: 20px;
  background: ${colorGetter('segmented-background')};
`

export const LegendWrapper = styled.div`
  position: absolute;
  left: 16px;
  bottom: 0;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 22px;
`
export const LegendItem = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  align-items: center;
  font-size: 12px;
  color: ${Token.color.foreground};
`
export const Square = styled.div<{ color: string; hasBorder?: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 10%;
  background-color: ${props => props.color};
  border: ${props =>
    props.hasBorder
      ? `1px dashed ${getColor(props.theme, 'grey-50-opaque-70')}`
      : 'none'};
`

export const LegendTargetItem = ({
  title = 'Target',
  color = Color.GREY_TONE_50,
}: {
  title?: string
  color?: Color
}) => {
  const theme = useTheme()

  return (
    <LegendItem>
      <svg width="25" height="10">
        <path
          d="M 0 5 L 25 5"
          fill="none"
          stroke={getColor(theme, color)}
          strokeLinecap="round"
          strokeWidth="1"
          strokeDasharray="5,5"
        />
      </svg>
      {title}
    </LegendItem>
  )
}

export const Legend = ({
  hasTarget,
  hasForecast,
  targetLayerTitle,
  targetLayerColor,
  legendItems,
  isBelow,
  colors,
}: LegendInterface) => {
  const theme = useTheme()

  if (!legendItems) {
    return (
      <LegendWrapper>
        <LegendItem>
          <Square color={theme.colors.blue} />
          Historical
        </LegendItem>
        {hasForecast && (
          <LegendItem>
            <Square hasBorder color={getColor(theme, Color.GREY_20_OPAQUE_20)} />
            Forecast
          </LegendItem>
        )}
        {hasTarget && (
          <LegendTargetItem title={targetLayerTitle} color={targetLayerColor} />
        )}
      </LegendWrapper>
    )
  }

  if (legendItems.length === 0) {
    return null
  }

  const Container = isBelow ? LegendWrapperBigBottom : LegendWrapperBigRight

  return (
    <>
      {hasTarget && (
        <LegendWrapper>
          <LegendTargetItem title={targetLayerTitle} color={targetLayerColor} />
        </LegendWrapper>
      )}
      <Container>
        <Text
          fontSize="small"
          lineHeight="14px"
          whiteSpace="nowrap"
          pb="s-8"
          color="grey-tone-50"
        >
          Legend
        </Text>
        <Box maxHeight="100%" css={ScrollCSS}>
          {legendItems.map((legend, key) => (
            <Flex pt="s-12" key={legend.id}>
              <Box
                flex="0 0 14px"
                borderRadius="10%"
                height={14}
                mr="10px"
                mt="s-2"
                bg={colors[key]}
              />
              <Text>{legend.label}</Text>
            </Flex>
          ))}
        </Box>
      </Container>
    </>
  )
}
