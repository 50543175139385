import React from 'react'
import { Button, Color, FilterButton, TableWidget } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { getAllScreeningChecks, getAllScreeningChecksStats } from '@src/api/screening'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import {
  AllScreeningChecksInterface,
  AllScreeningChecksStatsInterface,
} from '@src/interfaces/screeningChecksInterface'
import {
  screeningChecksCompletedOnColumn,
  screeningChecksCreatedByColumn,
  screeningChecksApproverColumn,
  screeningChecksCreatedOnColumn,
  screeningChecksEmployeeColumn,
  screeningChecksLastUpdateColumn,
  screeningChecksNameColumn,
  screeningChecksSourceColumn,
  screeningChecksStatusColumn,
} from '@src/constants/columns/ScreeningChecks'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import { StatFilters } from '@components/StatFilters/StatFilters'
import { useAssignedToMeFilter } from '@src/features/Screening/hooks'

const ROW: RowInterface<AllScreeningChecksInterface> = {
  cells: [
    {
      ...screeningChecksEmployeeColumn,
      width: 220,
    },
    {
      ...screeningChecksNameColumn,
      width: 220,
    },
    {
      ...screeningChecksSourceColumn,
      width: 150,
    },
    {
      ...screeningChecksApproverColumn,
      width: 240,
    },
    {
      ...screeningChecksCreatedByColumn,
      width: 240,
    },
    {
      ...screeningChecksCreatedOnColumn,
      width: 180,
    },
    {
      ...screeningChecksLastUpdateColumn,
      width: 180,
    },
    {
      ...screeningChecksCompletedOnColumn,
      width: 140,
    },
    {
      ...screeningChecksStatusColumn,
      width: 180,
    },
  ],
}

const statsConfig: StatsConfig<AllScreeningChecksStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Color.FOREGROUND,
  },
  {
    key: 'pending_adjudication',
    title: 'Pending adjudication',
    filterKey: 'pending_adjudication',
    color: Color.ORANGE,
  },
  {
    key: 'in_progress',
    title: 'In progress',
    filterKey: 'in_progress',
    color: Color.ORANGE,
  },
  {
    key: 'pending_completion',
    title: 'Pending completion',
    filterKey: 'pending_completion',
    color: Color.ORANGE,
  },
  {
    key: 'completed',
    title: 'Completed',
    filterKey: 'completed',
    color: Color.GREEN,
  },
  {
    key: 'canceled',
    title: 'Cancelled',
    filterKey: 'canceled',
    color: Color.RED,
  },
  {
    key: 'expired',
    title: 'Expired',
    filterKey: 'expired',
    color: Color.GREY_TONE_50,
  },
  {
    key: 'failed',
    title: 'Failed',
    filterKey: 'failed',
    color: Color.RED,
  },
  {
    key: 'other',
    title: 'Other',
    filterKey: 'other',
    color: Color.GREY_TONE_50,
  },
]

export const ScreeningsTable = () => {
  const initialSortBy = [
    {
      sortBy: 'seniority',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const table = useTable<AllScreeningChecksInterface, AllScreeningChecksStatsInterface>(
    {
      getItems: getAllScreeningChecks,
      getStats: getAllScreeningChecksStats,
    },
    undefined,
    initialSortBy,
  )
  const statFiltersProps = useSelectableTableStats<
    AllScreeningChecksInterface,
    AllScreeningChecksStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
    totalKey: 'total',
  })

  const handleTriggerScreeningCheck = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.SCREENING.SELECT))
  }

  const handleRowClick = (check: AllScreeningChecksInterface) => {
    if (check.type.id === 'hireright') {
      navigateTo(
        pathToUrl(ROUTES.FORMS.SCREENING.HIRERIGHT.PREVIEW, {
          employeeId: check.employee.id,
          id: check.screening_id,
        }),
      )
    } else if (check.type.id === 'manual') {
      navigateTo(
        pathToUrl(ROUTES.FORMS.SCREENING.MANUAL.PREVIEW, {
          employeeId: check.employee.id,
          id: check.screening_id,
        }),
      )
    }
  }

  const { showAssignedToMe, onToggleAssignedToMe } = useAssignedToMeFilter<
    AllScreeningChecksInterface,
    AllScreeningChecksStatsInterface
  >(table)

  return (
    <TableWidget>
      <TableWidget.Info>
        <StatFilters {...statFiltersProps} />
      </TableWidget.Info>
      <TableWidget.Actions>
        <Button
          onClick={handleTriggerScreeningCheck}
          mr="s-16"
          useIcon={Plus}
          size="sm"
          variant="secondary"
        >
          Add new
        </Button>
      </TableWidget.Actions>
      <TableWidget.Filters>
        <FilterButton onClick={onToggleAssignedToMe} active={showAssignedToMe}>
          Assigned to me
        </FilterButton>
      </TableWidget.Filters>
      <TableWidget.Table>
        <AdjustableTable<AllScreeningChecksInterface, AllScreeningChecksStatsInterface>
          name={TableNames.Screenings}
          useWindowScroll
          dataType="Screening Check"
          row={ROW}
          {...table}
          noDataMessage="Screening checks will appear here"
          onRowClick={handleRowClick}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
