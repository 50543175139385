import React, { useState } from 'react'
import { Button } from '@revolut/ui-kit'
import { Inbox } from '@revolut/icons'
import { connect } from 'lape'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { CustomFieldsInterface } from '@src/interfaces/customFields'
import { Statuses } from '@src/interfaces'
import { successNotification } from '@src/actions/NotificationActions'

const mapStatus = (status: Statuses) => ({ id: status, name: status })

export const ArchiveButton = connect(() => {
  const { values, submit } = useLapeContext<CustomFieldsInterface>()
  const [pending, setPending] = useState(false)

  const onClick = (status: Statuses) => {
    setPending(true)
    values.status = mapStatus(status)
    submit()
      .then(() =>
        successNotification(
          status === Statuses.archived
            ? 'Custom field successfully archived'
            : 'Custom field successfully set to active',
        ),
      )
      .finally(() => setPending(false))
  }

  if (!values.id) {
    return null
  }

  if (values.status.id === Statuses.archived) {
    return (
      <Button
        variant="secondary"
        size="sm"
        pending={pending}
        onClick={() => onClick(Statuses.active)}
      >
        Make active
      </Button>
    )
  }

  return (
    <Button
      variant="secondary"
      size="sm"
      useIcon={Inbox}
      pending={pending}
      onClick={() => onClick(Statuses.archived)}
    >
      Archive
    </Button>
  )
})
