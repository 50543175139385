import React, { useRef, useState } from 'react'
import {
  ActionButton,
  ActionMenu,
  Avatar,
  Button,
  Color,
  Item,
  ItemSkeleton,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { CheckSuccess, IndustrialGear, Warning } from '@revolut/icons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import { updateJobDescription } from '@src/api/jobPosting'
import { useGetJobPostingSettings, useGlobalSettings } from '@src/api/settings'
import PublishButton from '../Components/PublishButton'
import { useCareersSettings } from '@src/api/recruitment/careers'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useGetOpenRequisitionsByJobPosting } from '@src/api/requisitions'

type PublishStatusProps = {
  id: number
  publishStatus: PublishingStatuses
  requisitionCount: number
  statusDisplay: string
  onChange: (jobPosting: Pick<JobPostingInterface, 'status' | 'status_display'>) => void
}

const PublishStatusIcon = ({
  publishStatus,
  requisitionCount,
}: Pick<PublishStatusProps, 'publishStatus' | 'requisitionCount'>) => {
  if (!requisitionCount) {
    return <Avatar color={Color.RED} useIcon={Warning} />
  }
  switch (publishStatus) {
    case PublishingStatuses.unpublished:
    case PublishingStatuses.closed:
      return <Avatar color={Color.ORANGE} useIcon={Warning} />
    case PublishingStatuses.published_internally:
    case PublishingStatuses.published_externally:
    case PublishingStatuses.fully_published:
      return <Avatar color={Color.GREEN} useIcon={CheckSuccess} />
    default:
      return null
  }
}

const PublishMenu = ({
  id,
  publishStatus,
  onChange,
}: Pick<PublishStatusProps, 'id' | 'publishStatus' | 'onChange'>) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const statusPopup = useStatusPopup()
  const onSuccess = () => {
    statusPopup.show(
      <StatusPopup variant="success-result">
        <StatusPopup.Title>Updated job posting status</StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const onError = (msg: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{msg}</StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const handleChangeStage = async (status: PublishingStatuses) => {
    setLoading(true)
    try {
      const res = await updateJobDescription({ status }, { id: String(id) })
      onChange({
        status: res.data.status,
        status_display: res.data.status_display,
      })
      onSuccess()
    } catch (error) {
      const errorsData = error?.response?.data
      const statusError = errorsData?.status?.[0]
      const compensationBandsError = errorsData?.salary_bands_by_location
      onError(
        statusError || compensationBandsError || 'Error updating job posting status',
      )
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <ActionButton
        ref={anchorRef}
        onClick={() => setOpen(!open)}
        size="sm"
        pending={loading}
        disabled={loading}
      >
        Publish settings
      </ActionButton>
      <ActionMenu open={open} anchorRef={anchorRef} onClose={() => setOpen(false)}>
        <ActionMenu.Group>
          <ActionMenu.Item
            disabled={publishStatus === PublishingStatuses.fully_published}
            onClick={() => {
              handleChangeStage(PublishingStatuses.fully_published)
            }}
          >
            Internally & externally
          </ActionMenu.Item>
          <ActionMenu.Item
            disabled={publishStatus === PublishingStatuses.published_internally}
            onClick={() => {
              handleChangeStage(PublishingStatuses.published_internally)
            }}
          >
            Internally only
          </ActionMenu.Item>
          <ActionMenu.Item
            disabled={publishStatus === PublishingStatuses.published_externally}
            onClick={() => {
              handleChangeStage(PublishingStatuses.published_externally)
            }}
          >
            Externally only
          </ActionMenu.Item>
          <ActionMenu.Item
            color={Color.RED}
            disabled={publishStatus === PublishingStatuses.closed}
            onClick={() => {
              handleChangeStage(PublishingStatuses.closed)
            }}
          >
            Unpublish
          </ActionMenu.Item>
        </ActionMenu.Group>
      </ActionMenu>
    </>
  )
}

const PublishStatus = ({
  id,
  publishStatus,
  statusDisplay,
  onChange,
}: Omit<PublishStatusProps, 'requisitionCount'>) => {
  const { isLoading: loadingGlobalSettings } = useGlobalSettings()
  const isCommercial = useIsCommercial()
  const { data, isLoading: loadingCareerSettings } = useCareersSettings()
  const { data: jobPostingSettings, isLoading: loadingJobPostingSettings } =
    useGetJobPostingSettings()
  const { data: requisitions, isLoading: loadingRequisitions } =
    useGetOpenRequisitionsByJobPosting(id)
  const requisitionCount = requisitions?.count ?? 0
  if (
    loadingGlobalSettings ||
    loadingCareerSettings ||
    loadingJobPostingSettings ||
    loadingRequisitions
  ) {
    return <ItemSkeleton />
  }

  if (isCommercial && !data?.publish_career_website) {
    return (
      <Item>
        <Item.Avatar>
          <Avatar color={Color.RED} useIcon={Warning} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Careers website is not published</Item.Title>
          <Item.Description>
            The application form is currently unavailable to candidates. <br /> Please
            publish Careers website in the settings to start receiving applications.
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            useIcon={<IndustrialGear />}
            to={pathToUrl(ROUTES.SETTINGS.CAREER_PAGE)}
            use={InternalLink}
          >
            Go to Settings
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }
  const getDescription = () => {
    if (!requisitionCount) {
      return 'Job posting requires requisition(s) to be published'
    }
    switch (publishStatus) {
      case PublishingStatuses.unpublished:
      case PublishingStatuses.closed:
        return 'Job posting is not visible for candidates'
      case PublishingStatuses.published_internally:
      case PublishingStatuses.published_externally:
      case PublishingStatuses.fully_published:
        return statusDisplay
      default:
        return null
    }
  }
  const isPublished =
    publishStatus !== PublishingStatuses.unpublished &&
    publishStatus !== PublishingStatuses.closed
  const getTitle = () => {
    if (!requisitionCount) {
      return 'No requisitions are connected'
    }
    return isPublished ? 'Published' : 'Not published'
  }
  const getAction = () => {
    if (!requisitionCount) {
      return null
    }
    if (jobPostingSettings?.enable_all_publishing_statuses) {
      return <PublishMenu id={id} publishStatus={publishStatus} onChange={onChange} />
    }
    return <PublishButton />
  }
  return (
    <Item>
      <Item.Avatar>
        <PublishStatusIcon
          publishStatus={publishStatus}
          requisitionCount={requisitionCount}
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{getTitle()}</Item.Title>
        <Item.Description>{getDescription()}</Item.Description>
      </Item.Content>
      <Item.Side>{getAction()}</Item.Side>
    </Item>
  )
}

export default PublishStatus
