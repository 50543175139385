import React from 'react'
import { ReviewScorecardViewInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import { Heart } from '@revolut/icons'
import {
  CardContentTypes,
  CommonCardProps,
  cultureOptions,
  getRoundedRating,
  GradeOption,
  ratingOptions,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get } from 'lodash'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { getCardsForView } from '@src/utils/performance'

export const CultureValuesCard = connect(
  ({ selectedField, setSelectedField }: CommonCardProps) => {
    const { values } = useLapeContext<ReviewScorecardViewInterface>()
    const { data: performanceSettings } = useGetPerformanceSettings()

    const hasCultureValues = !!values.summary?.culture_values
    const canViewValues = performanceSettings?.enable_values

    if (!canViewValues) {
      return null
    }

    const cultureValuesCards = values?.summary?.culture_values?.cards || []
    const cultureValuesFields = cultureValuesCards.map((card, ind) => ({
      field: `summary.culture_values.cards.${ind}`,
      title: card.name,
      grades: cultureOptions as GradeOption[],
      cardIndex: ind,
    }))

    const cultureSkillsCards = getCardsForView(
      values?.summary?.culture_skills?.cards || [],
    )
    const cultureSkillsFields = cultureSkillsCards.map((card, ind) => ({
      field: `summary.culture_skills.cards.${ind}`,
      title: card.name,
      grades: ratingOptions,
      cardIndex: ind,
    }))

    if (!cultureSkillsCards) {
      return null
    }

    const valuesFields = hasCultureValues ? cultureValuesFields : cultureSkillsFields

    return (
      <Card
        data={values}
        type={CardContentTypes.VALUES}
        title="Values"
        icon={Heart}
        fields={valuesFields}
        selectedField={selectedField}
        setSelectedField={setSelectedField}
        isGradeSelectedRule={(field, grade) => {
          const ratingValue = get(values, field)?.rating
          if (!ratingValue) {
            return false
          }
          return hasCultureValues
            ? ratingValue === grade.key
            : getRoundedRating(ratingValue) === grade.key
        }}
        justification={
          hasCultureValues
            ? values?.summary?.culture_values?.skipped_section_justifications
            : values?.summary?.culture_skills?.skipped_section_justifications
        }
        finalRating={
          (hasCultureValues
            ? values.summary?.culture_values?.rating
            : values.summary?.culture_skills?.rating) || undefined
        }
        isViewMode
      />
    )
  },
)
