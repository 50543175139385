import React from 'react'
import { CheckSuccess, RadiobuttonOff } from '@revolut/icons'
import { Box } from '@revolut/ui-kit'
import Icon from '@components/Icon/Icon'
import { useTheme } from '@src/styles/theme'
import { getColor } from '@src/styles/colors'

interface ValidationPreProps {
  isVisible?: boolean
  isValid?: boolean
  isPending?: boolean
}

const ValidationPre = ({ isVisible, isValid, isPending }: ValidationPreProps) => {
  const theme = useTheme()
  if (!isVisible) {
    return null
  }
  return (
    <Box mr="s-8" mb="s-2" height="100%">
      {isValid ? (
        <>
          {isPending ? (
            <Icon type="Time" color={getColor(theme, 'warning')} size="tiny" />
          ) : (
            <CheckSuccess color="success" size={15} />
          )}
        </>
      ) : (
        <RadiobuttonOff size={15} />
      )}
    </Box>
  )
}

export default ValidationPre
