import { css } from 'styled-components'
import {
  PREVIOUS_CYCLE_OFFSET,
  PREVIOUS_CYCLE_PADDING,
  PREVIOUS_CYCLE_WIDTH,
} from '@src/constants/performance'
import { colorGetter } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

export const PreviousBlock = css`
  display: flex;
  position: absolute;
  left: calc(100% + ${PREVIOUS_CYCLE_OFFSET - PREVIOUS_CYCLE_PADDING}px);
`

export const PreviousCycleCoverCss = (count: number) => css`
  position: relative;
  &::after {
    content: '';
    width: ${count ? count * PREVIOUS_CYCLE_WIDTH + PREVIOUS_CYCLE_OFFSET : 0}px;
    height: 100%;
    top: 0;
    position: absolute;
    left: calc(100% - ${PREVIOUS_CYCLE_OFFSET}px);
    z-index: -1;
    border-radius: 16px;
    background-color: ${colorGetter(Color.GREY_TONE_5)};
  }
`
