import React from 'react'
import { useParams } from 'react-router-dom'
import { InputGroup, Side } from '@revolut/ui-kit'
import { ActionWidget, Button, Box } from '@revolut/ui-kit'
import { ArrowThinRight, InfoOutline } from '@revolut/icons'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { selectorKeys } from '@src/constants/api'
import {
  ContractorInterface,
  EmployeeInterface,
  EmployeeType,
  IdStatuses,
} from '@src/interfaces/employees'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'

import { UpdateButton } from '../common'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useGetEmployeeSettings } from '@src/api/settings'
import { useEmployeeDetailsOnAfterSubmit } from '../hooks'

export interface Props {
  data: EmployeeInterface
  refreshData: () => void
  type: EmployeeType
  isSidebarContent?: boolean
}

const Organisation = ({ type, data, refreshData, isSidebarContent }: Props) => {
  const form = useLapeContext<EmployeeInterface | ContractorInterface>()
  const { values } = form
  const { id } = useParams<{ id?: string }>()

  const { data: settings } = useGetEmployeeSettings()

  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <>
      <PageBody>
        {!settings?.enable_department_migration && (
          <Box mb="s-16">
            <ActionWidget>
              <ActionWidget.Title>
                Departamental transfers must be reviewed by cost control
              </ActionWidget.Title>
              <ActionWidget.Avatar>
                <InfoOutline color="red" />
              </ActionWidget.Avatar>
              <ActionWidget.Content>
                When employees move internally from one department to another, this
                impacts the talent and hiring budget for both the new and old departments.
                To ensure this is adequately reviewed by cost control, please use the
                change request feature when submitting such changes.
              </ActionWidget.Content>
              <ActionWidget.Actions>
                <Button
                  size="sm"
                  variant="secondary"
                  useEndIcon={ArrowThinRight}
                  onClick={() => {
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
                        employeeId: id,
                        type: 'organisation',
                      }),
                    )
                  }}
                >
                  Submit change request
                </Button>
              </ActionWidget.Actions>
            </ActionWidget>
          </Box>
        )}

        <InputGroup>
          <LapeRadioSelectInput name="team" label="Team" selector={selectorKeys.team} />
          <LapeRadioSelectInput
            name="line_manager"
            label={type === 'internal' ? 'Line manager' : 'Point of contact 1'}
            selector={selectorKeys.employees_for_teams}
          />
          {type === 'internal' ? (
            <>
              <LapeRadioSelectInput
                name="quality_control"
                label="Functional manager"
                selector={selectorKeys.employees_for_teams}
                optional={values.status?.id !== IdStatuses.pending}
                message="Sets the quality standards and expectations for the employee. Must be different from the Line Manager."
              />
              {!!settings?.enable_buddy && (
                <LapeRadioSelectInput
                  name="mentor"
                  label="Buddy"
                  selector={selectorKeys.all_employees}
                  optional={values.status?.id !== IdStatuses.pending}
                  clearable
                />
              )}
            </>
          ) : (
            <LapeRadioSelectInput
              name="quality_control"
              label="Point of contact 2"
              selector={selectorKeys.employees_for_teams}
              optional
            />
          )}
        </InputGroup>
      </PageBody>
      <ActionsWrapper>
        <UpdateButton
          form={form}
          employeeTypeChanged={type !== data?.employee_type}
          datePickerProps={{
            disabledDays: { before: new Date() },
          }}
          onAfterSubmit={onAfterSubmit}
        />
      </ActionsWrapper>
    </>
  )
}

export default Organisation
