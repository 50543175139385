import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import { Box, chain, Text } from '@revolut/ui-kit'

import Form from '@src/features/Form/Form'
import { benchmarkRequests } from '@src/api/benchmarks'
import { BenchmarkInterface } from '@src/interfaces/benchmarks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { getStatusColor } from '@src/components/CommonSC/General'

import Preview from './Preview'
import General from './General'
import Changelog from './Changelog'
import PageLoading from '@src/components/PageLoading/PageLoading'

const Tabs = () => {
  const { values } = useLapeContext<BenchmarkInterface>()

  const params = useParams<{ id?: string }>()

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.FORMS.BENCHMARK.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.BENCHMARK.PREVIEW, params),
      component: Preview,
    },
    {
      title: 'Changelog',
      path: ROUTES.FORMS.BENCHMARK.CHANGELOG,
      to: pathToUrl(ROUTES.FORMS.BENCHMARK.CHANGELOG, params),
      component: Changelog,
    },
  ]

  return (
    <>
      <PageHeader
        pb="s-8"
        title={chain(
          values?.name,
          <Text color={getStatusColor(values.status.id)}>{values.status.name}</Text>,
        )}
        backUrl={ROUTES.PEOPLE.COMPENSATION.BANDS}
      >
        <Box pb="s-8" maxWidth="100vw">
          <TabBarNavigation tabs={tabs} />
        </Box>
      </PageHeader>

      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </>
  )
}

const Benchmark = () => {
  const { values } = useLapeContext<BenchmarkInterface>()

  if (!values) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.BENCHMARK.GENERAL}>
          <General />
        </Route>
        <Tabs />
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={benchmarkRequests}>
    <Benchmark />
  </Form>
))
