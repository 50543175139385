import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import React from 'react'
import { ProbationInterface } from '@src/interfaces/probation'
import DateDistance from '@components/ColumnInserts/DateDistance/DateDistance'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { CheckpointType } from '@src/interfaces/probationReview'
import {
  ProbationTemplateInterface,
  ProbationTemplateRules,
  ProbationTemplateRulesNames,
} from '@src/interfaces/probationTemplate'
import pluralize from 'pluralize'
import { Box, Link, Token } from '@revolut/ui-kit'
import { LinkExternal } from '@revolut/icons'
import capitalize from 'lodash/capitalize'
import { getProbationTemplateStatusColor } from '@src/utils/performance'
import {
  PerformanceSettingsManagersEntityType,
  PerformanceSettingsManagersWithType,
} from '@src/interfaces/settings'

export const probationEmployeeNameColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.full_name',
  sortKey: 'employee__full_name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.employee,
  insert: ({ data }) => <UserWithAvatar {...data.employee} />,
  title: 'Employee name',
}

export const probationEndDateColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.date,
  idPoint: 'cycle.end_date_time',
  dataPoint: 'cycle.end_date_time',
  sortKey: 'cycle__end_date_time',
  filterKey: 'cycle__end_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Probation end date',
}

export const probationCheckpointColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.insert,
  idPoint: 'checkpoint_type',
  dataPoint: 'checkpoint_type',
  sortKey: 'checkpoint_type',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    switch (data.checkpoint_type) {
      case CheckpointType.Recommendation:
        return 'Mgr recommendation'
      case CheckpointType.Decision:
        return 'Committee review'
      case CheckpointType.Review:
        return `Checkpoint ${data.checkpoint_number}`
      case CheckpointType.Goals:
        return `${data.cycle.category === 'PIP' ? 'PIP' : 'Probation'} goals setting`
      default:
        return '-'
    }
  },
  title: 'Current checkpoint',
}

export const probationPendingActionsColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.insert,
  idPoint: 'pending_actions',
  dataPoint: 'pending_actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => data.pending_actions.join(', '),
  title: 'Pending actions',
}

export const probationCheckpointDeadlineColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.insert,
  idPoint: 'checkpoint_deadline',
  dataPoint: 'checkpoint_deadline',
  sortKey: 'checkpoint_date_time',
  filterKey: 'checkpoint_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Checkpoint deadline',
  insert: ({ data }) => (
    <DateDistance
      date={data?.checkpoint_deadline}
      color={data.status !== 'pending' ? Token.color.foreground : undefined}
    />
  ),
}

export const functionalManagerColumn: ColumnInterface<{
  functional_manager: EmployeeOptionInterface | null
}> = {
  type: CellTypes.insert,
  idPoint: 'functional_manager.id',
  dataPoint: 'functional_manager.full_name',
  sortKey: 'functional_manager__full_name',
  filterKey: 'functional_manager__id',
  selectorsKey: selectorKeys.manager,
  title: 'FM',
  insert: ({ data }) => <UserWithAvatar {...data.functional_manager} />,
}

export const locationHrManagerColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.insert,
  idPoint: 'hr_manager.id',
  dataPoint: 'hr_manager.full_name',
  sortKey: 'hr_manager__full_name',
  filterKey: 'hr_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'HR Manager',
  insert: ({ data }) => <UserWithAvatar {...data.hr_manager} />,
}

export const probationTemplateNameColumn: ColumnInterface<ProbationTemplateInterface> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const probationTemplateDurationColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'amount_of_months',
    dataPoint: 'amount_of_months',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Probation duration',
    insert: ({ data }) => pluralize('month', data.amount_of_months, true),
  }

export const probationTemplateCheckpointsColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.text,
    idPoint: 'num_checkpoints',
    dataPoint: 'num_checkpoints',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Checkpoints',
  }

export const probationTemplatePolicyColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'policy_document_link',
    dataPoint: 'policy_document_link',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Document',
    insert: ({ data }) =>
      data.policy_document_link ? (
        <Link
          href={data.policy_document_link}
          target="_blank"
          rel="noreferrer noopener"
          onClick={e => e.stopPropagation()}
        >
          <LinkExternal size={14} />
        </Link>
      ) : (
        '-'
      ),
  }

export const probationManagersRulesColumn: ColumnInterface<ProbationTemplateInterface> = {
  type: CellTypes.insert,
  idPoint: 'rules',
  dataPoint: 'rules',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Rules applied',
  insert: ({ data }) => {
    if (!data.rules) {
      return null
    }
    return Object.keys(data.rules)
      .map(el => ProbationTemplateRulesNames[el as ProbationTemplateRules])
      .join(', ')
  },
}

export const probationTemplateStatusColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status',
    dataPoint: 'status',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    insert: ({ data }) => (
      <Box color={getProbationTemplateStatusColor(data.status)}>
        {capitalize(data.status)}
      </Box>
    ),
  }

export const probationManagersTypeColumn: ColumnInterface<PerformanceSettingsManagersWithType> =
  {
    type: CellTypes.text,
    idPoint: 'type',
    dataPoint: 'type',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Type',
  }

export const probationManagersNameColumn: ColumnInterface<PerformanceSettingsManagersWithType> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Users',
    insert: ({ data }) => {
      if (data.type === PerformanceSettingsManagersEntityType.Group) {
        return data.name || null
      }
      return <UserWithAvatar {...data} status={undefined} />
    },
  }
