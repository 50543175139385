import React from 'react'
import { Statuses } from '@src/interfaces'
import { SORT_DIRECTION } from '@src/interfaces/data'
import Interviews from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Interviews'

const CompletedInterviews = () => {
  const initialFilterBy = [
    {
      filters: [
        { id: Statuses.completed, name: Statuses.completed },
        { id: Statuses.rejected, name: Statuses.rejected },
      ],
      columnName: 'status',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'scheduled_date_time',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  return <Interviews filterBy={initialFilterBy} sortBy={initialSortBy} />
}

export default CompletedInterviews
