import React, { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import SidebarMultiselect, {
  SidebarOption,
} from '@components/Inputs/SidebarMultiselect/SidebarMultiselect'
import {
  KpiInterface,
  KPIPerformanceTypes,
  KpiTargetEpics,
  KpiTargets,
} from '@src/interfaces/kpis'
import { FilterByInterface } from '@src/interfaces/data'
import {
  bulkAssignEmployeeKPIs,
  getAllRelevantKpis,
  getEmployeeAvailableKPIs,
} from '@src/api/kpis'
import { TextButton } from '@revolut/ui-kit'
import { getEntity } from '@src/utils/kpi'

interface Props {
  isOpen: boolean
  onClose: () => void
  employeeId: number | string
  cycleOffset: number
  onAfterSubmit?: (assignedKPIs: KpiInterface[]) => void
}

const getFilters = (cycleOffset: number, search: string): FilterByInterface[] => [
  {
    filters: [{ name: 'active', id: 'active' }],
    columnName: 'status',
    nonResettable: true,
  },
  {
    filters: [{ name: 'False', id: 'False' }],
    columnName: 'is_employee',
    nonResettable: true,
  },
  {
    filters: [{ name: `${cycleOffset}`, id: cycleOffset }],
    columnName: 'review_cycle__offset',
    nonResettable: true,
  },
  {
    filters: [{ name: KPIPerformanceTypes.business, id: KPIPerformanceTypes.business }],
    columnName: 'kpi_performance_type',
    nonResettable: true,
  },
  {
    filters: [{ name: search, id: search }],
    columnName: 'search',
    nonResettable: true,
  },
]

enum Mode {
  all = 'all',
  relevant = 'relevant',
}

const parseEntityLabel = (data: KpiInterface): SidebarOption['labels'] => {
  const entity = getEntity(data)
  if (entity && entity !== '-') {
    return [{ name: entity }]
  }
  return undefined
}

const parseOptions = (kpis: KpiInterface[]): SidebarOption[] => {
  return kpis.map(kpi => {
    const targets = kpi.future_targets || ([] as KpiTargets[])
    const labels = [
      ...(parseEntityLabel(kpi) || []),
      ...targets.map((target: KpiTargets | KpiTargetEpics) => ({
        name: target.review_cycle?.name || '',
      })),
    ]
    return { name: kpi.name, id: kpi.id, data: kpi, labels }
  })
}

const BulkAssignKPIs = ({
  isOpen,
  onClose,
  employeeId,
  onAfterSubmit,
  cycleOffset,
}: Props) => {
  const [options, setOptions] = useState<SidebarOption[]>([])
  const [pending, setPending] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [currentMode, setMode] = useState<Mode>(Mode.relevant)
  const [searchValue, setSearch] = useState<string>('')
  const [searchPending, setSearchPending] = useState<boolean>()

  useEffect(() => {
    if (!isOpen) {
      setSearch('')
      return
    }
    setMode(Mode.relevant)
    fetchOptions('', Mode.relevant)
  }, [cycleOffset, isOpen])

  // Without memo debounce timer will be reset on searchValue change
  const fetchOptions = useMemo(
    () =>
      debounce(async (search: string, mode: Mode) => {
        if (!search) {
          setPending(true)
        }
        const filters = getFilters(cycleOffset, search)

        const request =
          mode === Mode.relevant
            ? getAllRelevantKpis(employeeId, { filters }).then(resp => resp.data.results)
            : getEmployeeAvailableKPIs(employeeId, { filters }).then(resp => resp.data)

        try {
          const kpis = await request
          setOptions(parseOptions(kpis))
        } finally {
          setPending(false)
          setSearchPending(false)
        }
      }, 300),
    [employeeId, cycleOffset],
  )

  return (
    <SidebarMultiselect<KpiInterface>
      dataType="KPIs"
      options={options}
      onSearch={query => {
        setSearch(query)
        setMode(Mode.all)
        setSearchPending(true)

        fetchOptions(query, Mode.all)
      }}
      optionsPending={pending}
      onSubmit={selectedOptions => {
        setIsSubmitting(true)
        const kpis = selectedOptions.map(option => option.data!)

        bulkAssignEmployeeKPIs(employeeId, kpis)
          .then(() => {
            onAfterSubmit?.(kpis)
            onClose?.()
          })
          .finally(() => setIsSubmitting(false))
      }}
      isOpen={isOpen}
      onClose={onClose}
      submitButtonTitle="Assign KPIs"
      submitPending={isSubmitting}
      searchPending={searchPending}
      emptySearchMessage={
        currentMode === Mode.relevant ? 'Showing relevant kpis' : 'Showing all kpis'
      }
      searchActions={
        !searchValue && (
          <TextButton
            fontWeight={500}
            onClick={() => {
              const newMode = currentMode === Mode.all ? Mode.relevant : Mode.all
              setMode(newMode)
              fetchOptions(searchValue, newMode)
            }}
          >
            {currentMode === Mode.all ? 'Show relevant' : 'Show all'}
          </TextButton>
        )
      }
    />
  )
}

export default BulkAssignKPIs
