import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { HStack, TextButton } from '@revolut/ui-kit'
import { useBonusSidebar } from '@src/features/EditBonusSidebar/hooks'
import { SubmitPopup } from '@src/pages/Forms/EmployeeResignation/Components/SubmitPopup'
import { deleteCompensationBonus } from '@src/api/compensation'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

type Props = {
  id: number
  onDelete: () => void
}
export const BonusActions = ({ id, onDelete }: Props) => {
  const permissions = useSelector(selectPermissions)
  const { openBonusSidebar } = useBonusSidebar()

  const [isDeleteSubmitOpen, setIsDeleteSubmitOpen] = useState(false)
  const [isDeletePending, setIsDeletePending] = useState(false)

  const canEdit = permissions.includes(PermissionTypes.EditEmployeeBonus)
  const canDelete = permissions.includes(PermissionTypes.DeleteEmployeeBonus)

  if (!canEdit && !canDelete) {
    return null
  }
  return (
    <>
      <HStack space="s-16" align="center">
        {canEdit && (
          <TextButton onClick={() => openBonusSidebar(id)} fontWeight={600}>
            Edit
          </TextButton>
        )}
        {canDelete && (
          <TextButton
            variant="error"
            onClick={() => setIsDeleteSubmitOpen(true)}
            fontWeight={600}
          >
            Delete
          </TextButton>
        )}
      </HStack>
      <SubmitPopup
        open={isDeleteSubmitOpen}
        pending={isDeletePending}
        title="Are you sure you want to delete bonus?"
        onClose={() => setIsDeleteSubmitOpen(false)}
        onSubmit={async () => {
          setIsDeletePending(true)

          try {
            await deleteCompensationBonus(id)
            onDelete()
          } finally {
            setIsDeleteSubmitOpen(false)
            setIsDeletePending(false)
          }
        }}
      />
    </>
  )
}
