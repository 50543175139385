import React, { useState } from 'react'
import { RowInterface, Stats } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable, useTableReturnType } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Button, HStack, TableWidget } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { hiringProcessesStagesRequests } from '@src/api/hiringProcess'
import {
  hiringProcessEligibleInterviewsLinkColumn,
  hiringProcessNoteColumn,
  hiringProcessOwnerColumn,
  hiringProcessPlaybookColumn,
  hiringProcessScorecardLinkColumn,
  hiringProcessScorecardPreviewColumn,
  hiringProcessTitleColumn,
  hiringProcessActionColumn,
} from '@src/constants/columns/hiringProcess'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { TableActionButton } from '@src/components/Button/TableActionButton'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'

interface DeleteRowActionProps {
  data: HiringProcessInterface
  onDelete: () => void
}

const DeleteRowAction = ({ data, onDelete }: DeleteRowActionProps) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [deletePending, setDeletePending] = useState(false)

  const onDeleteConfirm = async () => {
    setDeletePending(true)

    try {
      await hiringProcessesStagesRequests.deleteItem(data.id)
      onDelete()
      setConfirmationOpen(false)
    } finally {
      setDeletePending(false)
    }
  }

  return (
    <>
      <TableActionButton
        onClick={e => {
          e.stopPropagation()
          setConfirmationOpen(true)
        }}
        // cannot use Token here
        color="red"
      >
        Delete
      </TableActionButton>
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={onDeleteConfirm}
        loading={deletePending}
        onReject={() => setConfirmationOpen(false)}
        yesMessage="Confirm"
        noMessage="Cancel"
        label="Are you sure you want to delete this hiring stage?"
        body=""
      />
    </>
  )
}

type HiringTableActions = {
  onAdd: () => void
  onEdit: (data: HiringProcessInterface) => void
  onDelete: () => void
  onPreviewScorecard: (data: HiringProcessInterface) => void
}

const Row = (actions?: HiringTableActions): RowInterface<HiringProcessInterface> => {
  const row = {
    linkToForm: actions
      ? undefined
      : ({ id }: HiringProcessInterface) =>
          navigateTo(pathToUrl(ROUTES.FORMS.HIRING_STAGES.GENERAL, { id })),
    cells: [
      {
        ...hiringProcessTitleColumn,
        width: 185,
      },
      {
        ...hiringProcessOwnerColumn,
        width: 210,
      },
      {
        ...hiringProcessPlaybookColumn,
        width: 100,
      },
      {
        ...(actions?.onPreviewScorecard
          ? hiringProcessScorecardPreviewColumn(actions.onPreviewScorecard)
          : hiringProcessScorecardLinkColumn),
        width: 123,
      },
      {
        ...hiringProcessEligibleInterviewsLinkColumn,
        width: 240,
      },
      {
        ...hiringProcessNoteColumn,
        width: 377,
      },
    ],
  }
  if (actions) {
    row.cells.push({
      ...hiringProcessActionColumn,
      width: 150,
      insert: ({ data }) => {
        return (
          <HStack space="s-16">
            <TableActionButton
              onClick={() => {
                actions.onEdit(data)
              }}
            >
              Edit
            </TableActionButton>
            <DeleteRowAction data={data} onDelete={actions.onDelete} />
          </HStack>
        )
      },
    })
  }
  return row
}

type HiringTableProps = {
  actions?: HiringTableActions
  table: useTableReturnType<HiringProcessInterface, Stats, {}>
}

export const HiringTable = ({ actions, table }: HiringTableProps) => {
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddHiringstage)
  return (
    <TableWidget>
      <TableWidget.Actions>
        {canAdd && (
          <Button
            onClick={actions?.onAdd}
            use={!actions?.onAdd ? InternalLink : undefined}
            to={
              !actions?.onAdd ? pathToUrl(ROUTES.FORMS.HIRING_STAGES.GENERAL) : undefined
            }
            data-testid="create-new-stage"
            mr="s-16"
            useIcon={Plus}
            size="sm"
            variant="secondary"
          >
            Create new stage
          </Button>
        )}
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable<HiringProcessInterface>
          name={TableNames.Hiring}
          useWindowScroll
          dataType="Hiring stage"
          row={Row(actions)}
          lockFirstColumn={!!actions}
          lockLastColumn={!!actions}
          {...table}
          noDataMessage="Hiring stages will appear here."
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export default () => {
  const table = useTable<HiringProcessInterface>(hiringProcessesStagesRequests)
  return <HiringTable table={table} />
}
