import React from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ReviewCategory,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useGetNotCompletedReviews } from '@src/api/performanceReview'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { FormTitle } from './FormTitle'
import { PerformanceHeaderNavigationBar } from '@src/pages/Forms/EmployeePerformance/components/PerformanceHeaderNavigationBar'
import { useGetEmployeeWithManagerFilter } from '@src/api/employees'
import { useParams } from 'react-router-dom'
import HelpSidebar from '@src/pages/Forms/EmployeePerformance/components/HelpSidebar'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import {
  CombinedHelp,
  HelpTabs,
} from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'

export const PerformanceHeader = connect(
  ({
    isHelpOpen,
    setHelpOpen,
  }: {
    isHelpOpen?: HelpTabs
    setHelpOpen?: React.Dispatch<React.SetStateAction<HelpTabs | undefined>>
  }) => {
    const { employeeId } = useParams<{ employeeId: any }>()
    const { values } = useLapeContext<ReviewScorecardInterface>()
    const user = useSelector(selectUser)
    const reviews = useGetNotCompletedReviews(user.id)
    const { data: reviewedEmployee } = useGetEmployeeWithManagerFilter(
      employeeId,
      'line_manager__id',
    )

    const isSummary = (
      review: ReviewScorecardInterface | ReviewSummaryInterface,
    ): review is ReviewSummaryInterface =>
      (review as ReviewSummaryInterface).summary !== undefined

    const data = isSummary(values) ? values.reviews[0] : values

    const getBackUrl = () => {
      switch (data?.category) {
        case ReviewCategory.Upwards:
          return pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.UPWARDS, {
            id: data?.reviewed_employee?.id,
          })
        default:
          return pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
            id: data?.reviewed_employee?.id,
          })
      }
    }

    const backUrl = getBackUrl()

    const getTitle = () => {
      switch (data?.category) {
        case ReviewCategory.Upwards:
          return 'Upwards Review'
        case ReviewCategory.Probation:
          return `Probation Review · CP${data.probation_checkpoint?.number}`
        case ReviewCategory.PIP_V2:
        case ReviewCategory.PIP:
          return 'PIP Checkpoint'
        default:
          return 'Performance Review'
      }
    }

    if (!reviewedEmployee) {
      return null
    }

    return (
      <>
        <PageHeader
          title={
            <>
              {reviews?.data && (
                <PerformanceHeaderNavigationBar reviews={reviews.data} noMaxWidth />
              )}
              <FormTitle
                data={reviewedEmployee}
                status={values.status || 'completed'}
                reviewTitle={`${data?.cycle ? `${data.cycle.name} ` : ''}${getTitle()}`}
              />
            </>
          }
          backUrl={backUrl}
        />
        <HideIfCommercial>
          <HelpSidebar
            isOpen={!!isHelpOpen}
            helpSection={<CombinedHelp initialTab={isHelpOpen} />}
            onExit={() => setHelpOpen?.(undefined)}
          />
        </HideIfCommercial>
      </>
    )
  },
)
