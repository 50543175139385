import React, { useEffect } from 'react'
import { Box, VStack, Text, BoxProps } from '@revolut/ui-kit'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Button } from '@src/pages/Landing/components/Button'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Absolute, AspectRatio, Image, useMatchMedia, Token, Flex } from '@revolut/ui-kit'
import { HERO_IMG, HERO_ICONS, HERO_MOBILE } from '@src/pages/Landing/constants'
import { CheckSuccess } from '@revolut/icons'
import { removeSignupStateCookie } from '../common'

const ContentBlockLayout = (props: BoxProps) => (
  <Box
    marginX="auto"
    paddingX={{ all: 's-20', lg: 0 }}
    use="section"
    width="100%"
    maxWidth={{ all: '1000px', xxl: '1432px' }}
    {...props}
  />
)

const BlockContentBaseLayout = styled(ContentBlockLayout)(
  css({
    position: 'relative',
    display: 'flex',
    flex: '1',
    flexDirection: ['column', null, null, 'row'],
    justifyContent: [null, null, null, 'space-between'],
    paddingBottom: 0,
  }),
)

const BlockContentBase = styled(Box)<{
  align?: 'center' | 'start'
}>(({ align }) =>
  css({
    width: '100%',
    alignContent: 'center',
    display: 'grid',
    gap: ['s-16', null, null, 's-24', null, 's-32'],
    height: 'auto',
    marginX: ['auto', null, null, align === 'start' ? 0 : 'auto'],
    minHeight: [null, null, null, 200],
    textAlign: ['start', null, align, align],
    justifyItems: ['start', null, align, align],
    maxWidth: [null, null, null, align === 'start' ? '65%' : undefined],
  }),
)

const BlockBase = styled(Flex)(
  css({
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
)

export const WaitingList = () => {
  useEffect(() => {
    removeSignupStateCookie()
  }, [])

  const isLgBreakpoint = useMatchMedia(Token.media.lg)

  return (
    <BlockBase minHeight={{ all: '100vh' }}>
      <Absolute height="100%" left={0} maxHeight="100vh" width="100%" overflow="hidden">
        <AspectRatio ratio={{ md: 1440 / 657 }} height="100%">
          <Image
            alt=""
            src={HERO_IMG}
            sizes="100vw"
            style={{
              objectFit: 'cover',
              objectPosition: isLgBreakpoint ? '50% 50%' : '50% 100%',
            }}
          />
        </AspectRatio>
      </Absolute>
      <Absolute
        height="100%"
        left={0}
        maxHeight="100vh"
        width="100%"
        overflow="hidden"
        hide="*-lg"
      >
        <AspectRatio ratio={{ md: 1440 / 657 }} height="100%">
          <Image
            alt=""
            src={HERO_ICONS}
            sizes="100vw"
            style={{
              objectFit: 'contain',
              objectPosition: isLgBreakpoint ? '50% 40%' : '50% 100%',
              background:
                'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(25,28,31,1) 100%)',
              backdropFilter: `blur(2px)`,
              filter: `blur(2px)`,
            }}
          />
        </AspectRatio>
      </Absolute>
      <AspectRatio ratio={375 / 220} hide="lg-*" width="100%" mt="s-64">
        <Image alt="" sizes="100vw" src={HERO_MOBILE} style={{ objectFit: 'cover' }} />
      </AspectRatio>
      <BlockContentBaseLayout>
        <BlockContentBase align="center">
          <VStack
            align={{ all: 'start', md: 'center' }}
            space={{ all: 's-8', lg: 's-24' }}
            width="100%"
            mb="s-16"
          >
            <CheckSuccess color={Token.color.blue} />
            <Text
              use="h1"
              variant="h1"
              whiteSpace="pre-wrap"
              color={Token.color.white}
              width="100%"
            >
              Congrats, you´ve been added to the waiting list!
            </Text>
            <Text
              use="p"
              whiteSpace="pre-wrap"
              color={Token.color.white}
              width={{ all: '100%', lg: '60%', xxl: '80%' }}
              variant="primary"
            >
              We'll get back to you as soon as possible. In the meantime, check out our
              website
            </Text>
          </VStack>

          <Button
            variant="primary"
            use={InternalLink}
            to={pathToUrl(ROUTES.MAIN)}
            size="sm"
          >
            Go to website
          </Button>
        </BlockContentBase>
      </BlockContentBaseLayout>
    </BlockBase>
  )
}
