import React from 'react'
import { connect } from 'lape'
import General from '@src/pages/Forms/SkillForm/General'
import Preview from '@src/pages/Forms/SkillForm/Preview'
import { SkillFormTalentTab } from '@src/pages/Forms/SkillForm/SkillFormTalentTab'
import Form from '@src/features/Form/Form'
import { skillRequestsNew } from '@src/api/skills'
import { SkillInterface } from '@src/interfaces/skills'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { Route, Switch, useParams } from 'react-router-dom'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Dot } from '@src/pages/Forms/FormTitles'
import { getStatusThemeColor } from '@components/CommonSC/General'
import upperFirst from 'lodash/upperFirst'
import { Box, Color, Text } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import ScorecardPreview from '@src/pages/Forms/SkillForm/ScorecardPreview'
import { Statuses } from '@src/interfaces'
import SkillSpecialisations from '@src/pages/Forms/SkillForm/SkillSpecialisations'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'

const backUrl = ROUTES.FUNCTION.SKILLS

const getTabs = (id: string, skillTalentEnabled: boolean) => [
  {
    title: 'Preview',
    path: ROUTES.FORMS.SKILL.PREVIEW,
    to: pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, { id }),
    component: Preview,
    canView: !!id,
  },
  {
    title: 'Talent',
    path: ROUTES.FORMS.SKILL.TALENT,
    to: pathToUrl(ROUTES.FORMS.SKILL.TALENT, { id }),
    component: SkillFormTalentTab,
    canView: !!id && skillTalentEnabled,
  },
  {
    title: 'Roles (Specialisations)',
    path: ROUTES.FORMS.SKILL.SPECIALISATIONS,
    to: pathToUrl(ROUTES.FORMS.SKILL.SPECIALISATIONS, { id }),
    component: SkillSpecialisations,
    canView: !!id,
  },
  {
    title: 'Scorecard preview',
    path: ROUTES.FORMS.SKILL.SCORECARD_PREVIEW,
    to: pathToUrl(ROUTES.FORMS.SKILL.SCORECARD_PREVIEW, { id }),
    component: ScorecardPreview,
    canView: true,
  },
  {
    title: 'Settings',
    path: ROUTES.FORMS.SKILL.GENERAL,
    to: pathToUrl(ROUTES.FORMS.SKILL.GENERAL, { id }),
    component: General,
    canView: true,
  },
]

const getStatusColorAndText = (status: Statuses): { text: string; color: Color } => {
  switch (status) {
    case Statuses.expired:
      return { text: 'Outdated', color: 'warning' }
    case Statuses.pending_reapproval:
      return {
        text: 'Re-approval',
        color: 'warning',
      }
    default:
      return {
        text: upperFirst(status),
        color: getStatusThemeColor(status),
      }
  }
}

const SkillForm = connect(() => {
  const { values } = useLapeContext<SkillInterface>()
  const { id } = useParams<{ id: string }>()

  const featureFlags = useSelector(selectFeatureFlags)
  const skillTalentEnabled =
    featureFlags?.includes(FeatureFlags.SkillTalent) &&
    !!values.field_options?.permissions?.includes(PermissionTypes.CanViewSkillTalent)

  const tabs = getTabs(id, skillTalentEnabled).filter(tab => tab.canView)
  const status = getStatusColorAndText(values.status)

  return (
    <PageWrapper>
      <PageHeader
        title={
          <>
            {values.id ? values.name : 'Create a new skill'}
            {values.status && (
              <span>
                <Dot>·</Dot>
                <Text color={status.color}>{status.text}</Text>
              </span>
            )}
          </>
        }
        backUrl={backUrl}
      >
        <Box pb="s-16" maxWidth="100vw">
          <TabBarNavigation tabs={tabs} />
        </Box>
      </PageHeader>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
})

const PageSkill = () => {
  return (
    <Form api={skillRequestsNew}>
      <SkillForm />
    </Form>
  )
}

export default connect(PageSkill)
