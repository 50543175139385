import { Box, VStack } from '@revolut/ui-kit'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import React, { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { CallapsibleRadioSwitch } from '../common/CallapsibleRadioSwitch'
import { Managers } from './Managers'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useGetJiraPreferences } from '@src/api/integrations'
import { ROUTES } from '@src/constants/routes'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

export const ProbationAndPipSettings = () => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangePerformancePreferences)
  const { data: jiraSettings } = useGetJiraPreferences()

  const handleProbationSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    values.enable_probation = e.target.checked
    if (!e.target.checked) {
      values.enforce_probation_committee_review = false
    }
  }

  const handlePIPSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    values.enable_pip = e.target.checked
    if (!e.target.checked) {
      values.enforce_pip_committee_review = false
    }
  }

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="Covers all probation and PIP related settings"
          />
          <VStack space="s-16">
            <CallapsibleRadioSwitch
              itemTypeProps={{
                title: 'Enable Probation',
                description:
                  'Allows newly onboarded employees to go trough a probation period',
              }}
              name="enable_probation"
              disabled={disableEdit}
              onChange={handleProbationSwitch}
            >
              <Box m="-s-16">
                <Managers type="probation" />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enforce Probation committee review',
                    description:
                      'The final decisions at the end of probation is taken by a group of people who will form the probation committee',
                  }}
                  name="enforce_probation_committee_review"
                  disabled={disableEdit}
                />
              </Box>
            </CallapsibleRadioSwitch>
            <CallapsibleRadioSwitch
              itemTypeProps={{
                title: 'Enable PIP',
                description:
                  'Allows putting employees who are not performing well on a performance improvement plan',
              }}
              name="enable_pip"
              disabled={disableEdit}
              onChange={handlePIPSwitch}
            >
              <Box m="-s-16">
                <Managers type="pip" />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enforce PIP committee review',
                    description:
                      'The final decisions at the end of PIP is taken by a group of people who will form the PIP committee',
                  }}
                  name="enforce_pip_committee_review"
                  disabled={disableEdit}
                />
              </Box>
            </CallapsibleRadioSwitch>
            {jiraSettings?.enabled ? (
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Manage Probation and PIP goals via Jira',
                  description: (
                    <>
                      Allows probation and PIP cycle goals to be set via tasks created in
                      Jira. This only works when the Jira integration is configured{' '}
                      <InternalLink to={ROUTES.HUB.INTEGRATION.JIRA}>here</InternalLink>.
                    </>
                  ),
                }}
                name="enable_probation_and_pip_goals_via_jira"
                disabled={disableEdit}
              />
            ) : null}
          </VStack>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          confirmationDialogue={{
            body: 'New settings will be cascaded to all existing future cycles.',
          }}
          useValidator
          successText="Settings saved"
        />
      </PageActions>
    </>
  )
}
