import React, { useEffect } from 'react'
import { connect } from 'lape'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import SendEmailCommon from '@src/pages/Forms/SendEmail/SendEmailCommon'
import { useParams } from 'react-router-dom'
import { InterviewStageInterface } from '@src/interfaces/interviewTool'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { fetchOnlineTestInfo } from '@src/api/hiringProcess'
import SendOnlineTestPreview from '@src/pages/Forms/SendOnlineTest/SendOnlineTestPreview'

export interface SendOnlineTestProps {
  insideSidebar?: boolean
  onSuccess?: () => void
  roundId?: number
  stageId?: number
  candidateId?: number
}

const SendOnlineTest = ({ insideSidebar, onSuccess }: SendOnlineTestProps) => {
  const { values } = useLapeContext<InterviewStageInterface>()
  const { candidateId } = useParams<{ candidateId: string }>()

  const onHandleSuccess = () => {
    if (onSuccess) {
      onSuccess()
    } else {
      navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: candidateId }))
    }
  }

  useEffect(() => {
    const fetchTestInfo = async () => {
      if (values.online_test?.id) {
        const resp = await fetchOnlineTestInfo(values.online_test.id)

        values.online_test = { ...resp.data }
      }
    }

    fetchTestInfo()
  }, [values.online_test?.id])

  return (
    <SendEmailCommon
      onSuccess={onHandleSuccess}
      noEditor
      btnLabel="Send test"
      successText="Test has been sent"
      insideSidebar={insideSidebar}
    >
      <SendOnlineTestPreview
        values={values}
        displayCandidateInfo
        onChange={newOnlineTest => {
          if (newOnlineTest) {
            values.online_test = {
              ...values.online_test,
              id: newOnlineTest.id,
              name: newOnlineTest.name,
            }
          }
        }}
      />
    </SendEmailCommon>
  )
}

export default connect(SendOnlineTest)
