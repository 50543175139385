import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import FilterSelect from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { OptionInterface } from '@src/interfaces/selectors'
import { Bar, ToggleButton } from '@revolut/ui-kit'
import { Filter as FilterIcon } from '@revolut/icons'
import { useQuery } from '@src/utils/queryParamsHooks'
import { isEqual } from 'lodash'

interface Props {
  options: OptionInterface[]
  quickFilters: QuickFilter[]
  onFilterChange?: (options: OptionInterface[] | undefined) => void
  prefix?: string
  isClearable?: boolean
  showFilters?: boolean
  hideDropdown?: boolean
  className?: string
  filterKey: string
  labelAll?: string
}

const Label = styled.div`
  font-size: 13px;
  margin-right: 8px;
`

const ChipContainer = styled.div``
const Container = styled.div`
  display: flex;
  align-items: center;
`

const ClearFilter = styled.button`
  font-size: 14px;
  color: ${props => props.theme.colors.blue};
  margin-left: 8px;
`

export interface QuickFilter {
  key: string
  name: string
  filters: number[]
}

const Filter = ({
  options,
  quickFilters,
  onFilterChange,
  prefix,
  isClearable,
  showFilters,
  hideDropdown,
  className,
  filterKey,
  labelAll = 'All',
}: Props) => {
  const popoverAnchor = useRef<HTMLDivElement>(null)
  const [openFilter, setOpenFilter] = useState(false)
  const { query, changeQueryParam, deleteQueryParam } = useQuery()
  const queryValues = query[filterKey]?.split(',').map(Number)

  const optionsFunc = async (): Promise<{
    options: OptionInterface[]
  }> => {
    return {
      options,
    }
  }

  const toggleFilter = () => {
    setOpenFilter(!openFilter)
  }

  const handleFilterChange = (chosenFilters: OptionInterface[]) => {
    if (!chosenFilters) {
      deleteQueryParam(filterKey)
      return
    }
    changeQueryParam(filterKey, chosenFilters.map(fil => fil.id).join(','))
    if (onFilterChange) {
      onFilterChange(chosenFilters)
    }
    toggleFilter()
  }

  const clearFilter = () => {
    deleteQueryParam(filterKey)
    if (onFilterChange) {
      onFilterChange(undefined)
    }
  }

  const applyQuickFilter = (relation: string, isSelected: boolean) => {
    if (isSelected) {
      clearFilter()
      return
    }

    const filtersList = quickFilters.find(f => f.key === relation)!.filters
    const chosenFilters = options.filter(o => filtersList.includes(+o.id))

    changeQueryParam(filterKey, chosenFilters.map(fil => fil.id).join(','))
    if (onFilterChange) {
      onFilterChange(chosenFilters)
    }
  }

  const label =
    queryValues && queryValues.length
      ? `Selected · ${queryValues.length}`
      : `All reviewers`

  return (
    <Container className={className} data-testid={`filter-${filterKey}`}>
      {prefix && <Label>{prefix}</Label>}
      {showFilters && (
        <Bar>
          <ToggleButton
            onChange={e => {
              e.preventDefault()
              clearFilter()
            }}
            checked={!queryValues}
          >
            {labelAll}
          </ToggleButton>
          {quickFilters.map(quickFilter => {
            if (!quickFilter.filters.length) {
              return null
            }
            const isSelected = isEqual(queryValues?.sort(), quickFilter.filters?.sort())
            return (
              <ToggleButton
                key={quickFilter.key}
                onChange={e => {
                  e.preventDefault()
                  applyQuickFilter(quickFilter.key, isSelected)
                }}
                checked={isSelected}
              >
                {quickFilter.name}
              </ToggleButton>
            )
          })}
          {!hideDropdown && (
            <ChipContainer ref={popoverAnchor}>
              <ToggleButton
                onChange={toggleFilter}
                checked={options.some(o => queryValues?.includes(+o.id))}
                useIcon={FilterIcon}
              >
                {label}
              </ToggleButton>
            </ChipContainer>
          )}
        </Bar>
      )}
      {popoverAnchor.current && (
        <FilterSelect
          anchorRef={popoverAnchor}
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          value={options.filter(o => queryValues?.includes(+o.id))}
          onChange={handleFilterChange}
          selector={optionsFunc}
        />
      )}
      {isClearable && !!queryValues ? (
        <ClearFilter type="button" onClick={clearFilter}>
          Clear Filter
        </ClearFilter>
      ) : null}
    </Container>
  )
}

export default Filter
