import React from 'react'
import { Text } from '@revolut/ui-kit'
import { Color } from '@revolut/ui-kit'
import { formatNumberMillions } from '@src/utils/format'

type Props = {
  count?: number | string | null
  color?: Color
  show0InGrey?: boolean
}

const QuickSummaryCount = ({ count, color, show0InGrey = true }: Props) => {
  if (!count) {
    return <Text color={show0InGrey ? Color.GREY_20_OPAQUE_90 : color}>0</Text>
  }

  return (
    <Text color={color}>
      {typeof count === 'string' ? count : formatNumberMillions(count)}
    </Text>
  )
}

export default QuickSummaryCount
