import React, { useState } from 'react'
import { useTableReturnType } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import {
  RiskIndicatorInterface,
  RiskLevel,
  RiskStatsInterface,
} from '@src/interfaces/risk'
import {
  riskAssigneeColumn,
  riskDueDateColumn,
  riskJiraColumn,
  riskLastBreachColumn,
  riskLevelIndicatorsColumn,
  riskLimit1Column,
  riskLimit2Column,
  riskLimit3Column,
  riskQualityColumn,
  riskReasonIndicatorColumn,
  riskSummaryColumn,
  riskTypeColumn,
  riskValueColumn,
} from '@src/constants/columns/risk'
import Tooltip from '@components/Tooltip/Tooltip'
import { getColor } from '@src/styles/colors'
import { Color, Flex, MoreBar, Switch, Text } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { InfoOutline, Plus } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import { teamNameColumn } from '@src/constants/columns/team'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

interface Props {
  table: useTableReturnType<RiskIndicatorInterface, RiskStatsInterface>
  isEmployee?: boolean
  useWrapper?: boolean
}

const ROW = (isEmployee: boolean): RowInterface<RiskIndicatorInterface> => ({
  highlight: (data, theme) => {
    if (data.risk_level === RiskLevel.high) {
      return getColor(theme, Color.RED_OPAQUE_20)
    }
    return ''
  },
  cells: [
    {
      ...riskLevelIndicatorsColumn,
      width: 130,
    },
    {
      ...riskTypeColumn,
      width: 90,
    },
    {
      ...riskSummaryColumn,
      width: 260,
    },
    {
      ...riskValueColumn,
      width: 130,
    },
    {
      ...riskLimit1Column,
      width: 90,
    },
    {
      ...riskLimit2Column,
      width: 90,
    },
    {
      ...riskLimit3Column,
      width: 90,
    },
    {
      ...riskQualityColumn,
      width: 100,
    },
    {
      ...riskReasonIndicatorColumn,
      width: 100,
    },
    {
      ...riskLastBreachColumn,
      width: 100,
    },
    {
      ...(isEmployee ? teamNameColumn : riskAssigneeColumn),
      width: 90,
    },
    {
      ...riskDueDateColumn,
      width: 90,
    },
    {
      ...riskJiraColumn,
      width: 80,
    },
  ],
})

const Indicators = ({ table, isEmployee = false, useWrapper }: Props) => {
  const [showBCI, setShowBCI] = useState(true)
  const handleNewRow = () => {
    window.open('https://backoffice.revolut.com/risks/risk-indicators', '_blank')
  }

  const onChangeShowClosed = () => {
    if (showBCI) {
      table.onFilterChange({
        filters: [
          { id: 'RAS', name: 'RAS' },
          { id: 'KRI', name: 'KRI' },
        ],
        columnName: 'type',
      })
    } else {
      table.onFilterChange({
        filters: [],
        columnName: 'type',
      })
    }
    setShowBCI(!showBCI)
  }

  return (
    <TableWrapper useWrapper={useWrapper}>
      <Flex mb="s-24">
        <Stat
          label="High"
          val={
            table.stats?.high !== undefined ? (
              <Tooltip
                text="Risk indicators with limit 2 or limit 3 breached. Imediate action is required to reduce financial or reputational loss. Limit 2 breaches are escalated to Executive Risk Committee and Limit 3 breaches are escalated to the Board."
                placement="right"
              >
                <Text color="red">{table.stats?.high}</Text>
              </Tooltip>
            ) : undefined
          }
          mr="s-32"
        />
        <Stat
          label="Medium"
          val={
            table.stats?.medium !== undefined ? (
              <Tooltip
                text="Risk indicators with limit 1 breached. Remediation plan needs to be applied before due date to avoid escalation."
                placement="right"
              >
                <Text color="warning">{table.stats?.medium}</Text>
              </Tooltip>
            ) : undefined
          }
          mr="s-32"
        />
        <Stat
          label="Low"
          val={
            table.stats?.low !== undefined ? (
              <Tooltip text="Risk indicator is not breached" placement="right">
                <Text color="green">{table.stats?.low}</Text>
              </Tooltip>
            ) : undefined
          }
          mr="s-32"
        />
      </Flex>
      <Flex mb="s-16">
        <MoreBar>
          <MoreBar.Action useIcon={Plus} onClick={handleNewRow}>
            Add Risk Indicator
          </MoreBar.Action>
          <MoreBar.Action
            target="_blank"
            rel="noreferrer noopener"
            href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1478372102/Risk+Indicators+KRIs+and+BCIs"
            use="a"
            useIcon={InfoOutline}
          >
            How to manage risk indicators?
          </MoreBar.Action>
        </MoreBar>
        <Switch value="Show BCIsa" checked={showBCI} onChange={onChangeShowClosed}>
          <Text>Show BCIs</Text>
        </Switch>
      </Flex>
      <AdjustableTable<RiskIndicatorInterface, RiskStatsInterface>
        name={TableNames.Indicators}
        useWindowScroll
        dataType="Risk Indicator"
        row={ROW(isEmployee)}
        {...table}
        noDataMessage="This team has no data on their Risk indicators"
      />
    </TableWrapper>
  )
}

export default Indicators
