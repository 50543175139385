import styled from 'styled-components'
import { Color, Space, themeColor, themeRadius, themeSpace } from '@revolut/ui-kit'

export const TooltipContainer = styled.div`
  background-color: ${themeColor(Color.FOREGROUND)};
  border-radius: ${themeRadius('tooltip')};
  padding: ${themeSpace(Space.S_8)};
  color: ${themeColor(Color.BACKGROUND)};
  opacity: 0.9;
`
