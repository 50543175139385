import React from 'react'
import styled from 'styled-components'
import { PerformanceRatingTitle, RatingColor } from '@src/constants/performance'
import { useTheme } from '@src/styles/theme'
import { DeliverableOptions, PerformanceRating } from '@src/interfaces/performance'
import { InterviewResult } from '@src/interfaces/interviewTool'
import { getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

const Wrap = styled.span<{ color: string; underline?: boolean }>`
  color: ${props => props.color || 'inherit'};
  text-decoration: ${props => (props.underline ? 'underline' : 'inherit')};
`

export enum RatingLabelTypes {
  General = 'General',
  Inverse = 'Inverse',
  InverseUnderline = 'InverseUnderline',
}

interface Props {
  type?: RatingLabelTypes
  children?: React.ReactNode
  noUnderline?: boolean
  rating?: PerformanceRating | DeliverableOptions | InterviewResult | null
  className?: string
}

const UNDERLINE_LABELS = [RatingLabelTypes.InverseUnderline]

const RatingLabel = ({
  children,
  rating,
  type = RatingLabelTypes.General,
  noUnderline,
  className,
}: Props) => {
  const theme = useTheme()
  let defaultColor

  switch (type) {
    case RatingLabelTypes.General:
      defaultColor = getColor(theme, Color.TEAL)
      break
    default:
      defaultColor = getColor(theme, Color.FOREGROUND)
  }

  let ratingColor
  let content = null

  if (rating) {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    ratingColor = RatingColor(theme)[rating] || defaultColor
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    content = PerformanceRatingTitle[rating]
  } else {
    ratingColor = getColor(theme, Color.GREY_TONE_50)
  }

  return (
    <Wrap
      color={ratingColor}
      underline={!noUnderline ? UNDERLINE_LABELS.includes(type) : undefined}
      className={className}
    >
      {children || content}
    </Wrap>
  )
}

export default RatingLabel
