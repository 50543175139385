import React, { useState } from 'react'
import pluralize from 'pluralize'
import { Link } from 'react-router-dom'
import { Archive, Dot, Pencil, Unarchive } from '@revolut/icons'
import { Text, chain, Group, ExpandableCell, List, MoreBar, Box } from '@revolut/ui-kit'

import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KeyPersonResponsibilityInterface } from '@src/interfaces/keyPerson'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { getResponsibilityStatusColor } from '@src/apps/People/KeyPersons/helpers'
import {
  archiveKeyPersonResponsibility,
  unarchiveKeyPersonResponsibility,
} from '@src/api/keyPerson'
import { useTheme } from '@src/styles/theme'

export const PreviewResponsibility = () => {
  const theme = useTheme()
  const { initialValues, reset } = useLapeContext<KeyPersonResponsibilityInterface>()

  const [isArchivePending, setIsArchivePending] = useState(false)
  const [isUnarchivePending, setIsUnarchivePending] = useState(false)

  const canEdit = initialValues.field_options?.permissions?.includes(
    PermissionTypes.ChangeKeyPersonType,
  )

  return (
    <>
      <PageHeader
        title={initialValues.name}
        subtitle={chain(
          'Responsibility',
          <Text color={getResponsibilityStatusColor(theme, initialValues.status?.id)}>
            {initialValues.status?.name}
          </Text>,
        )}
        backUrl={ROUTES.APPS.KEY_PERSONS.RESPONSIBILITIES_TABLE}
      />
      <PageBody>
        <Box mb="s-24">
          <MoreBar>
            {canEdit && (
              <MoreBar.Action
                useIcon={Pencil}
                use={Link}
                to={pathToUrl(ROUTES.APPS.KEY_PERSONS.RESPONSIBILITY.EDIT, {
                  id: String(initialValues.id),
                })}
              >
                Edit
              </MoreBar.Action>
            )}
            {initialValues.status?.id === 'active' && (
              <MoreBar.Action
                variant="negative"
                useIcon={Archive}
                pending={isArchivePending}
                onClick={async () => {
                  setIsArchivePending(true)
                  try {
                    const { data } = await archiveKeyPersonResponsibility(
                      initialValues.id!,
                    )
                    reset(data)
                  } finally {
                    setIsArchivePending(false)
                  }
                }}
              >
                Archive
              </MoreBar.Action>
            )}
            {initialValues.status?.id === 'archived' && (
              <MoreBar.Action
                useIcon={Unarchive}
                pending={isUnarchivePending}
                onClick={async () => {
                  setIsUnarchivePending(true)
                  try {
                    const { data } = await unarchiveKeyPersonResponsibility(
                      initialValues.id!,
                    )
                    reset(data)
                  } finally {
                    setIsUnarchivePending(false)
                  }
                }}
              >
                Unarchive
              </MoreBar.Action>
            )}
          </MoreBar>
        </Box>
        <FormPreview data={initialValues}>
          <Group>
            <FormPreview.Item title="Category" field="category.name" />
            <FormPreview.Details title="Description" field="description" />
            <ExpandableCell>
              <ExpandableCell.Title>Eligibility</ExpandableCell.Title>
              <ExpandableCell.Content>
                {pluralize('entity', initialValues.required_for_entities?.length, true)}
              </ExpandableCell.Content>
              {!!initialValues.required_for_entities?.length && (
                <ExpandableCell.Note>
                  <List variant="compact">
                    {initialValues.required_for_entities.map(specialisation => (
                      <List.Item key={specialisation.id} useIcon={Dot}>
                        {specialisation.name}
                      </List.Item>
                    ))}
                  </List>
                </ExpandableCell.Note>
              )}
            </ExpandableCell>
            <ExpandableCell>
              <ExpandableCell.Title>Roles</ExpandableCell.Title>
              <ExpandableCell.Content>
                {pluralize(
                  'specialisation',
                  initialValues.expected_for_specialisations?.length,
                  true,
                )}
              </ExpandableCell.Content>
              {!!initialValues.expected_for_specialisations?.length && (
                <ExpandableCell.Note>
                  <List variant="compact">
                    {initialValues.expected_for_specialisations.map(specialisation => (
                      <List.Item key={specialisation.id} useIcon={Dot}>
                        {specialisation.name}
                      </List.Item>
                    ))}
                  </List>
                </ExpandableCell.Note>
              )}
            </ExpandableCell>
            <FormPreview.Item title="Succession plan" field="succession_plan.name" />
          </Group>
        </FormPreview>
      </PageBody>
    </>
  )
}
