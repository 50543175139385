import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { candidateSettings } from '@src/api/settings'
import CandidatesGeneral from '@src/pages/Settings/Candidates/General'

const routes = [
  {
    title: 'Candidates settings',
    path: ROUTES.SETTINGS.CANDIDATES,
    url: ROUTES.SETTINGS.CANDIDATES,
    canView: [
      PermissionTypes.ViewCandidatePreferences,
      PermissionTypes.ChangeCandidatePreferences,
    ],
    component: CandidatesGeneral,
  },
]

export const CandidatesSettings = () => {
  return <SettingsForm routes={routes} api={candidateSettings} />
}
