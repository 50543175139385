import React from 'react'
import { Flex, FlexProps, BREAKPOINTS } from '@revolut/ui-kit'

export const PageWrapper: React.FC<FlexProps> = props => {
  return (
    <Flex
      width="100%"
      height="100%"
      maxWidth={BREAKPOINTS.xxl}
      bg="layout-background"
      flexDirection="column"
      pl={{ all: 0, md: 's-16' }}
      {...props}
    />
  )
}
