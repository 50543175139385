import { connect } from 'lape'
import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { FieldOptions } from '@src/interfaces'
import { SettingsButtonsInstanceProps } from '@src/features/SettingsButtons'
import { EntityPermissions } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { LockClosed } from '@revolut/icons'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

export interface PermissionTransferButtonValuesInterface {
  field_options?: FieldOptions
  id: number
}

export interface PermissionTransferButtonProps<T> extends SettingsButtonsInstanceProps {
  path: string
  values?: T
}

const PermissionTransferButton = <T extends PermissionTransferButtonValuesInterface>({
  path,
  isVisible = true,
  values,
}: PermissionTransferButtonProps<T>) => {
  let canAssignPermissions = values?.field_options?.actions?.includes(
    EntityPermissions.AccessObjectPermissions,
  )

  if (!values?.id || !isVisible || !canAssignPermissions) {
    return null
  }

  return (
    <MoreBar.Action
      to={pathToUrl(path, { entityId: values.id, tab: 'table' })}
      use={InternalLink}
      useIcon={LockClosed}
    >
      Manage permissions
    </MoreBar.Action>
  )
}

export default connect(PermissionTransferButton)
