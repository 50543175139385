import {
  Box,
  Cell,
  Flex,
  Tag,
  VStack,
  Text,
  HStack,
  MoreBar,
  Token,
  Icon,
  useStatusPopup,
  StatusPopup,
  Popup,
  BottomSheet,
  TextButton,
  chain,
  TextSkeleton,
} from '@revolut/ui-kit'
import { getStatusColor } from '@src/components/CommonSC/General'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  approveGoal,
  calibrateGoal,
  goalsRequests,
  requestChange,
  useGetGoals,
} from '@src/api/goals'
import { IdAndName, Statuses } from '@src/interfaces'
import { GoalsInterface } from '@src/interfaces/goals'
import React, { ReactNode, useState } from 'react'
import { StatusUpdate } from './StatusUpdate'
import { RequestChange } from './RequestChange'
import { SetFinalProgress } from './SetFinalProgress'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { PermissionTypes } from '@src/store/auth/types'
import { captureException } from '@sentry/react'
import { useLocation } from 'react-router-dom'

const HeaderBanner = ({
  title,
  status,
  subtitle,
  actions,
}: {
  title: string
  status: IdAndName<Statuses | ApprovalStatuses>
  subtitle: ReactNode
  actions: ReactNode
}) => {
  const statusBadge = (
    <Tag
      variant="outlined"
      color={getStatusColor(status.id) || Token.color.greyTone50}
      data-testid="goal-status"
    >
      {status.name}
    </Tag>
  )

  return (
    <Box mt="s-8">
      <Cell pt="s-24" px="s-16" pb="s-16">
        <VStack gap="s-16" overflow="hidden">
          <Flex flex={1} alignItems="center" gap="s-16">
            <VStack gap="s-4">
              <Text variant="h1" whiteSpace="pre-wrap">
                {title}
              </Text>
              <HStack space="s-8" align="center">
                {statusBadge}
                {subtitle}
              </HStack>
            </VStack>
          </Flex>
          {actions}
        </VStack>
      </Cell>
    </Box>
  )
}

const searchParamsToObject = (search: URLSearchParams) => {
  return [...search.entries()].reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {} as Record<string, string>)
}

const GoalsSwitch = ({ currentGoalId }: { currentGoalId: number }) => {
  const location = useLocation()
  const search = location.search
  const { data, isLoading } = useGetGoals(search || undefined)
  const goals = data?.results || []
  const searchParams = searchParamsToObject(new URLSearchParams(search))

  const currentIndex = goals.findIndex(g => g.id === currentGoalId)

  const switchTo = (increment: number) => {
    const nextGoal = goals[currentIndex + increment]

    if (nextGoal) {
      const url = pathToUrl(
        ROUTES.FORMS.GOAL.PREVIEW,
        { id: String(nextGoal.id) },
        searchParams,
      )
      navigateReplace(url)
    }
  }

  return (
    <Cell>
      <Flex justifyContent="space-between" width="100%">
        {isLoading ? (
          <TextSkeleton width={150} />
        ) : (
          <Text variant="primary" color={Token.color.greyTone50}>
            {goals.length
              ? chain('Goals', `${currentIndex + 1}/${goals.length}`)
              : chain('Goal', '1/1')}
          </Text>
        )}

        <HStack space="s-8">
          <TextButton
            color={Token.color.greyTone50}
            aria-label="switch to previous goal"
            onClick={() => switchTo(-1)}
            disabled={goals.length === 0 || currentIndex === 0}
          >
            <Icon name="ChevronLeft" />
          </TextButton>
          <TextButton
            aria-label="switch to next goal"
            color={Token.color.greyTone50}
            onClick={() => switchTo(1)}
            disabled={goals.length === 0 || currentIndex === goals.length - 1}
          >
            <Icon name="ChevronRight" />
          </TextButton>
        </HStack>
      </Flex>
    </Cell>
  )
}

type Action = 'approve' | 'request_change' | 'update_status' | 'calibration'
type AvailablePopups = 'status-update' | 'request-change' | 'calibration'

export const GoalsPreviewHeader = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const [pending, setPending] = useState<Action>()
  const statusPopup = useStatusPopup()
  const [shownPopup, setShownPopup] = useState<AvailablePopups>()
  const closePopup = () => setShownPopup(undefined)
  const showSwitch = true

  const { entity } = useOrgEntity()

  const showError = (title: string, description: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{title}</StatusPopup.Title>
        <StatusPopup.Description>{description}</StatusPopup.Description>
      </StatusPopup>,
    )
  }

  const showSuccess = (title: string, description?: string) => {
    statusPopup.show(
      <StatusPopup variant="success">
        <StatusPopup.Title>{title}</StatusPopup.Title>
        {description && <StatusPopup.Description>{description}</StatusPopup.Description>}
      </StatusPopup>,
    )
  }

  const approvalStatus = values.approval_status
  const isPendingApproval = approvalStatus.id !== ApprovalStatuses.Approved
  const isApproved = approvalStatus.id === ApprovalStatuses.Approved

  const isAllowed = (key: keyof typeof PermissionTypes): boolean =>
    !!values.field_options?.permissions?.includes(PermissionTypes[key])

  const accessControl = {
    approve: isAllowed('CanApproveGoal'),
    requestChange: isAllowed('CanRejectGoal'),
    change: isAllowed('CanChangeGoal'),
    calibrate: true,
  }

  const actions = (
    <MoreBar>
      {accessControl.approve && isPendingApproval && (
        <MoreBar.Action
          variant="accent"
          useIcon="Check"
          pending={pending === 'approve'}
          onClick={async () => {
            setPending('approve')
            try {
              await approveGoal(values.id)
              values.approval_status = { id: ApprovalStatuses.Approved, name: 'Approved' }
              showSuccess('Goal approved')
            } catch (err) {
              showError('Failed to approve', 'Something went wrong. Please try again.')
            } finally {
              setPending(undefined)
            }
          }}
        >
          Approve goal
        </MoreBar.Action>
      )}
      {accessControl.calibrate && (
        <MoreBar.Action
          variant="accent"
          useIcon="Pencil"
          pending={pending === 'calibration'}
          onClick={() => setShownPopup('calibration')}
        >
          Set final progress
        </MoreBar.Action>
      )}
      {accessControl.requestChange && approvalStatus.id === ApprovalStatuses.Pending && (
        <MoreBar.Action
          disabled={!!pending}
          onClick={() => setShownPopup('request-change')}
          pending={pending === 'request_change'}
          useIcon="HelpChat"
        >
          Request changes
        </MoreBar.Action>
      )}
      {accessControl.change && isApproved && (
        <MoreBar.Action
          useIcon="Plus"
          variant="accent"
          disabled={!!pending}
          pending={pending === 'update_status'}
          onClick={() => setShownPopup('status-update')}
        >
          Update status
        </MoreBar.Action>
      )}
      {accessControl.change && (
        <MoreBar.Action
          disabled={!!pending}
          pending={!entity}
          useIcon="Pencil"
          onClick={() => {
            navigateTo(pathToUrl(ROUTES.FORMS.GOAL.EDIT, { id: values.id }), { entity })
          }}
        >
          Edit goal
        </MoreBar.Action>
      )}
    </MoreBar>
  )
  const subtitle = (
    <HStack space="s-8" color={Token.color.greyTone50} align="center">
      <HStack space="s-4" align="center">
        <Icon name="Profile" size={16} />
        <Text variant="caption">{values.owner.display_name}</Text>
      </HStack>

      <HStack space="s-4" align="center">
        <Icon name="Services" size={16} />
        <Text variant="caption">{values.content_object.name}</Text>
      </HStack>
    </HStack>
  )
  const backUrl = 'FIXME'

  return (
    <>
      <PageHeader
        backUrl={backUrl}
        title={
          <>
            {showSwitch && <GoalsSwitch currentGoalId={values.id} />}
            <HeaderBanner
              title={values.name}
              status={isPendingApproval ? approvalStatus : values.status}
              actions={actions}
              subtitle={subtitle}
            />
          </>
        }
      />
      <Popup size="md" open={shownPopup === 'status-update'} onClose={closePopup}>
        <StatusUpdate
          currentStatus={values.status.id}
          onCancel={closePopup}
          onSubmit={async nextStatus => {
            closePopup()
            setPending('update_status')
            try {
              // todo: send comment when BE implements
              const response = await goalsRequests.update(
                { status: { id: nextStatus, name: nextStatus } },
                { id: String(values.id) },
              )

              values.status = response.data.status
            } catch (err) {
              showError(
                'Failed to add status update',
                'Something went wrong. Please try again.',
              )
            } finally {
              setPending(undefined)
            }
          }}
        />
      </Popup>
      <BottomSheet open={shownPopup === 'request-change'} onClose={closePopup}>
        <RequestChange
          onCancel={closePopup}
          onSubmit={async comment => {
            closePopup()
            setPending('update_status')
            try {
              await requestChange(values.id, comment)
              values.approval_status = {
                id: ApprovalStatuses.RequiresChanges,
                name: 'Requires changes',
              }
            } catch (err) {
              showError(
                'Failed to request change',
                'Something went wrong. Please try again.',
              )
            } finally {
              setPending(undefined)
            }
          }}
        />
      </BottomSheet>
      <BottomSheet open={shownPopup === 'calibration'} onClose={closePopup}>
        <SetFinalProgress
          onCancel={closePopup}
          onSubmit={async (status, progress, comment) => {
            closePopup()
            setPending('calibration')
            try {
              const { data } = await calibrateGoal(values.id, {
                calibrated_progress: progress,
                status,
                comment,
              })
              values.status = data.status
              values.calibrated_progress = data.calibrated_progress
            } catch (err) {
              captureException(err)
              showError(
                'Failed to submit calibrated progress',
                'Something went wrong. Please try again.',
              )
            } finally {
              setPending(undefined)
            }
          }}
        />
      </BottomSheet>
    </>
  )
}
