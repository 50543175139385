import React from 'react'
import styled from 'styled-components'

import {
  DeliverableCriteriaType,
  DeliverableInterface,
  DeliverableRating,
  DeliverableScorecardInterface,
} from '@src/interfaces/deliverables'
import { FilterByInterface } from '@src/interfaces/data'
import { ratingToColor } from '@src/utils/performance'
import { RatingLabelTypes } from '@src/features/EmployeePerformance/RatingLabel'
import { useTheme } from '@src/styles/theme'
import RatingTooltip from '../Common/RatingTooltip'
import { COMPLEXITY_OPTIONS, QUALITY_OPTIONS, SPEED_OPTIONS } from './constants'

interface Props {
  type: DeliverableCriteriaType
  deliverable: DeliverableInterface
  filter: FilterByInterface[]
  scorecard?: DeliverableScorecardInterface
  ignored?: boolean
  onClickQuality?: (
    deliverable: DeliverableInterface,
    scorecard?: DeliverableScorecardInterface,
    ratings?: DeliverableRating[],
    results?: DeliverableRating[],
  ) => void
}

const Cell = styled.div<{ backgroundColor?: string | null }>`
  padding: 0 16px;
  background-color: ${props => props.backgroundColor};
`

const RatingTooltipStyled: typeof RatingTooltip = styled(RatingTooltip)`
  padding: 0 16px;
  justify-content: start;
  width: 100%;
  box-sizing: border-box;
`

const RatingTooltipCell = styled.div<{ backgroundColor?: string | null }>`
  background-color: ${props => props.backgroundColor};
  width: 100%;
  height: 49px;
  display: flex;
  align-items: center;
`

const Result = styled(Cell)`
  width: 100%;
  height: 49px;
  display: flex;
  align-items: center;
`

const SCORECARDS_BY_KEY = 'scorecards_by'

const AggregatedCell = ({
  type,
  deliverable,
  filter,
  scorecard,
  ignored,
  onClickQuality,
}: Props) => {
  const theme = useTheme()
  const scorecards = deliverable.deliverable_scorecards

  if (ignored) {
    return <Cell>-</Cell>
  }

  const filteredAggregation = deliverable.filtered_aggregation

  if (filteredAggregation) {
    const filteredReviewers = filter
      .find(f => f.columnName === SCORECARDS_BY_KEY)
      ?.filters?.map(f => +f.id)

    const filteredScorecards = scorecards.filter(item => {
      let isFiltered = true

      if (filteredReviewers && item.reviewer) {
        isFiltered = filteredReviewers.includes(item.reviewer.id)
      }

      return !item.is_aggregation && !item.ignored && isFiltered
    })

    const ratings = filteredScorecards
      .filter(item => item[type])
      .map(item => ({
        value: item[type],
        review: item,
      }))

    const onClickRatingTooltip = () => {
      const qualityItemsRatings = filteredScorecards
        .map(item =>
          item.quality_items.map(qualityItem => ({
            id: qualityItem.id,
            value: qualityItem.value,
            review: item,
          })),
        )
        .flat()

      const qualityItemsResults = filteredScorecards.map(item => ({
        value: item.quality_preview,
        review: item,
      }))

      onClickQuality!(deliverable, scorecard, qualityItemsRatings, qualityItemsResults)
    }

    return (
      <RatingTooltipCell
        backgroundColor={ratingToColor(theme, filteredAggregation[type])}
      >
        <RatingTooltipStyled
          ratings={ratings}
          totalRating={filteredAggregation[type]}
          placement={'top'}
          withCount
          onClick={onClickQuality ? onClickRatingTooltip : undefined}
          type={RatingLabelTypes.Inverse}
        />
      </RatingTooltipCell>
    )
  }

  let options

  switch (type) {
    case DeliverableCriteriaType.Complexity:
      options = COMPLEXITY_OPTIONS
      break

    case DeliverableCriteriaType.Quality:
      options = QUALITY_OPTIONS
      break

    case DeliverableCriteriaType.Speed:
      options = SPEED_OPTIONS
      break
  }

  if (scorecard && options) {
    return (
      <Result backgroundColor={ratingToColor(theme, scorecard[type])}>
        {options.find(item => item.id === scorecard[type])?.name}
      </Result>
    )
  }

  return null
}

export default AggregatedCell
