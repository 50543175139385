import React, { useEffect, useState } from 'react'

import {
  getKpiTargetAction,
  getKpiTargetCurrentValueColumn,
  getKpiTargetParentColumn,
  kpiTargetCycleNameColumn,
  kpiTargetInitialValueColumn,
  kpiTargetOwnerColumn,
  kpiTargetProgressColumn,
  kpiTargetStatusColumn,
  kpiTargetStrategyColumn,
  kpiTargetValueColumn,
} from '@src/constants/columns/kpiTargets'
import { RowInterface } from '@src/interfaces/data'
import { KPIFormLocationState, KpiInterface, KpiTargets } from '@src/interfaces/kpis'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getGenericCyclesInfo } from '@src/features/FormTabs/Kpi/KPITargets/common/useAvailableCycles'
import { Flex, Button } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { KpiTargetActionsOptions } from '@components/ColumnInserts/KPITargetActions/KPITargetActions'
import { useLocation } from 'react-router-dom'
import { summaryTableProps } from '@src/features/Summary/common'
import {
  createTarget,
  openTarget,
} from '@src/features/FormTabs/Kpi/KPITargets/targetActions'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { getKPITargetsListRequest, kpiTargetsRequestsNew } from '@src/api/kpis'
import { successNotification } from '@src/store/notifications/actions'
import { useTable } from '@components/Table/hooks'
import findIndex from 'lodash/findIndex'
import { TableNames } from '@src/constants/table'
import { difference } from '@src/utils/form'
import SavingKPIPopup from '@src/features/FormTabs/Kpi/KPITargets/common/SavingKPIPopup'

interface Props {
  readonly?: boolean
  simpleMode?: boolean
}

const getRow = (
  kpi: KpiInterface,
  options: KpiTargetActionsOptions,
  readonly?: boolean,
  simpleMode?: boolean,
): RowInterface<KpiTargets> => ({
  cells: [
    {
      ...kpiTargetCycleNameColumn,
      width: 160,
    },
    simpleMode
      ? undefined
      : {
          ...getKpiTargetParentColumn(kpi),
          width: 80,
        },
    {
      ...kpiTargetStrategyColumn,
      width: 80,
    },
    {
      ...kpiTargetInitialValueColumn,
      width: 80,
    },
    {
      ...getKpiTargetCurrentValueColumn(kpi),
      width: 80,
    },
    {
      ...kpiTargetValueColumn,
      width: 80,
    },
    {
      ...kpiTargetProgressColumn,
      width: 110,
    },
    simpleMode
      ? undefined
      : {
          ...kpiTargetOwnerColumn,
          width: 80,
        },
    simpleMode
      ? undefined
      : {
          ...kpiTargetStatusColumn,
          width: 100,
        },
    readonly && !simpleMode
      ? {
          ...getKpiTargetAction(options, kpi.id),
          width: 200,
        }
      : undefined,
  ].filter(Boolean),
})

const ValueTargetsTable = ({ readonly, simpleMode = false }: Props) => {
  const { values, initialValues, submit } = useLapeContext<KpiInterface>()
  const table = useTable<KpiTargets>({ getItems: getKPITargetsListRequest(values.id) })
  const location = useLocation<KPIFormLocationState>()
  const [pending, setPending] = useState(false)
  const probationId =
    location?.state?.probationId ||
    values.targets?.find(target => target.employee_cycle)?.employee_cycle?.id

  const { data: reviewCyclesData } = useGetReviewCycles({
    disable: !!probationId,
  })

  useEffect(() => {
    values.targets = table.data
  }, [table.data])

  const getAvailableCyclesInfo = (targetIndex: number | null) => {
    const reviewCycles = reviewCyclesData?.results || []
    return getGenericCyclesInfo(reviewCycles, {
      targetIndex,
      allTargets: values.targets,
      probationId,
    })
  }

  // >= 2 (not 1) here, because field 'targets' is always in the difference, and it's in the fieldsToExclude to avoid showing save button when nothing changed
  // this is happening bc we add first target when creating KPI using KPI's endpoint, but all future work with targets goes through target's endpoint,
  // and fields are not fully compatible between these two endpoints
  const hasChanges = Object.keys(difference(values, initialValues)).length >= 2

  const handleAdd = () => {
    const { availableCycles, defaultCycle, canEditCycle } = getAvailableCyclesInfo(null)
    createTarget({
      kpi: values,
      probationId,
      availableCycles,
      defaultCycle,
      canEditCycle,
    })
  }

  const onAdd = async () => {
    if (!hasChanges) {
      handleAdd()
    } else {
      setPending(true)
      try {
        await submit()
        handleAdd()
      } finally {
        setPending(false)
      }
    }
  }

  const handleOpen = (index: number) => {
    const id = values.targets[index]?.id
    if (!id) {
      return
    }
    const { availableCycles, defaultCycle, canEditCycle } = getAvailableCyclesInfo(index)
    openTarget(id, {
      kpi: values,
      probationId,
      availableCycles,
      defaultCycle,
      canEditCycle,
    })
  }

  const onOpen = async (index: number) => {
    if (!hasChanges) {
      handleOpen(index)
    } else {
      setPending(true)
      try {
        await submit()
        handleOpen(index)
      } finally {
        setPending(false)
      }
    }
  }

  const onDelete = (index: number) => {
    const id = values.targets[index]?.id
    if (!id) {
      return
    }
    kpiTargetsRequestsNew.delete!({ id }, { id: `${id}` })
      .then(() => {
        successNotification('Target deleted')
      })
      .then(table.refresh)
  }

  const row = getRow(
    values,
    {
      allTargets: values.targets,
      onDelete,
      onEdit: onOpen,
      onView: onOpen,
    },
    readonly,
    simpleMode,
  )

  return (
    <>
      {!readonly && (
        <Flex>
          <Button
            useIcon={Plus}
            variant="secondary"
            size="sm"
            onClick={onAdd}
            data-testid="set_target_button"
            disabled={!reviewCyclesData && !probationId}
          >
            Set target
          </Button>
        </Flex>
      )}
      <AdjustableTable
        name={TableNames.ValueTargets}
        idPath="uniqueKey"
        noDataMessage="No targets added"
        row={row}
        {...table}
        data={values.targets}
        onRowClick={
          simpleMode
            ? undefined
            : data => {
                const index = findIndex(table.data, t => t.id === data.id)
                if (index >= 0) {
                  onOpen(index)
                }
              }
        }
        {...(readonly ? summaryTableProps : {})}
        hideCountAndButtonSection
      />
      <SavingKPIPopup pending={pending} />
    </>
  )
}

export default ValueTargetsTable
