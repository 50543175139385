import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { ArrowRepeat } from '@revolut/icons'
import { refreshInterviewStages } from '@src/api/recruitment/interviews'

type RefreshInterviewStagesProps = {
  roundId: number
  onRefresh: () => Promise<void>
}

const RefreshInterviewStages = ({ roundId, onRefresh }: RefreshInterviewStagesProps) => {
  const handleClick = async () => {
    await refreshInterviewStages(roundId)
    onRefresh()
  }
  return (
    <MoreBar.Action useIcon={ArrowRepeat} onClick={handleClick}>
      Refresh stages
    </MoreBar.Action>
  )
}

export default RefreshInterviewStages
