import React from 'react'
import { Group } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@components/FormPreview/FormPreview'
import {
  InterviewSpecialisationInterface,
  InterviewStageInterface,
} from '@src/interfaces/interviewTool'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { Color, HStack } from '@revolut/ui-kit'
import OnlineTestPreviewLink from '@src/features/HiringStageSidebarPreview/OnlineTestPreviewLink'
import OnlineTestPreviewDuration from '@src/features/HiringStageSidebarPreview/OnlineTestPreviewDuration'
import upperFirst from 'lodash/upperFirst'
import OnlineTestRadioSelect from '@src/pages/Forms/HiringStage/OnlineTestRadioSelect'
import { OnlineTestInterface } from '@src/interfaces/hiringProccess'
import { IdAndName } from '@src/interfaces'

interface OnlineTestPreviewInterface {
  online_test?: OnlineTestInterface
  test_platform: string
  specialisation: InterviewSpecialisationInterface
}

type Props = {
  values?: InterviewStageInterface | OnlineTestPreviewInterface
  displayCandidateInfo?: boolean
  loadingTestPlatform?: boolean
  onChange: (onlineTest: IdAndName | null) => void
}

const SendOnlineTestPreview = ({
  values,
  displayCandidateInfo,
  loadingTestPlatform = false,
  onChange,
}: Props) => {
  return (
    <>
      <FormPreview<InterviewStageInterface | OnlineTestPreviewInterface> data={values}>
        <Group>
          <FormPreview.Item<InterviewStageInterface>
            title="Test name"
            field="online_test.name"
            insert={data => (
              <HStack gap="s-8">
                {data.online_test?.name}{' '}
                <OnlineTestRadioSelect
                  testPlatform={values?.test_platform}
                  noInput
                  onChange={newOnlineTest => onChange(newOnlineTest)}
                />
              </HStack>
            )}
          />
          <FormPreview.Item
            title="Testing platform"
            field="test_platform"
            type="capitalized"
            loading={loadingTestPlatform}
          />
          <OnlineTestPreviewLink />
          <OnlineTestPreviewDuration />
          {displayCandidateInfo && (
            <>
              <FormPreview.Item
                title="Candidate"
                field="interview_round.candidate.full_name"
              />
              <FormPreview.Item
                title="Candidate email"
                field="interview_round.candidate.email"
              />
            </>
          )}
          <FormPreview.Item<InterviewStageInterface>
            title="Interviewing for"
            field={
              (values as OnlineTestPreviewInterface).specialisation
                ? 'specialisation.name'
                : 'interview_round.specialisation.name'
            }
            to={data =>
              pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                id: (values as OnlineTestPreviewInterface).specialisation
                  ? (values as OnlineTestPreviewInterface).specialisation.id
                  : data.interview_round?.specialisation?.id,
              })
            }
          />
        </Group>
      </FormPreview>
      {values?.test_platform && (
        <ActionWidget
          mt="s-16"
          title={`Test invite by default will be sent from ${upperFirst(
            values?.test_platform,
          )}`}
          avatarColor={Color.BLUE}
        />
      )}
    </>
  )
}

export default SendOnlineTestPreview
