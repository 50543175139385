import React, { useMemo } from 'react'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { CountryHiringInterface } from '@src/interfaces/settings'
import { countryHiringRequestsNew } from '@src/api/settings'
import { Box, chain, VStack } from '@revolut/ui-kit'
import { CountryHiringEnabled } from '@src/constants/columns/countryHiring'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { selectorKeys } from '@src/constants/api'
import { PageBody } from '@src/components/Page/PageBody'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateReplace } from '@src/actions/RouterActions'
import { useGetSelectors } from '@src/api/selectors'
import useIsCommercial from '@src/hooks/useIsCommercial'

const PreferredSpecialisationMultiSelect = () => {
  const { values } = useLapeContext<CountryHiringInterface>()
  const countryName = values?.country?.name
  const commonProps = {
    required: true,
    name: 'preferred_specialisations',
    label: 'Preferred specialisations',
    placeholder: 'Select specialisations',
    message: `Requisitions for this specialisations will include ${countryName} locations by default`,
    selector: selectorKeys.specialisations,
  }
  if (values.all_specialisations_preferred) {
    const all = {
      label: 'All',
      value: {
        id: 'all',
        name: 'All',
      },
    }
    return <LapeNewMultiSelect {...commonProps} disabled value={[all]} options={[all]} />
  }
  return <LapeNewMultiSelect {...commonProps} />
}

const CountryHiringForm = () => {
  const { values } = useLapeContext<CountryHiringInterface>()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeHiringProcessPreferences,
  )
  const { data: employmentRiskSelector } = useGetSelectors(
    selectorKeys.country_hiring_preference_employment_legal_risks,
  )
  const isCommercial = useIsCommercial()

  const employmentRiskOptions = useMemo(
    () =>
      employmentRiskSelector?.map(item => ({
        label: item.name,
        value: item,
      })) || [],
    [employmentRiskSelector],
  )
  const countryName = values?.country?.name
  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title={chain(
          countryName,
          <CountryHiringEnabled hiringEnabled={values.hiring_enabled} />,
        )}
        backUrl={pathToUrl(ROUTES.SETTINGS.HIRING_PROCESS.COUNTRY_HIRING)}
      />
      <PageBody>
        <VStack gap="s-16">
          <LapeNewSwitch
            itemTypeProps={{
              title: `Allow hiring in ${countryName}`,
              description: `Enable locations in ${countryName} to be added to a requisition. Requisitions for low cost roles will be by default populated with ${countryName} locations.`,
            }}
            name="hiring_enabled"
            disabled={disableEdit}
          />
          <LapeNewSwitch
            itemTypeProps={{
              title: 'Prefer all specialisations',
              description: `Enable this setting to recommend ${countryName} locations as preferred hiring locations for each new requisition and specialisation at the company`,
            }}
            name="all_specialisations_preferred"
            disabled={disableEdit}
            onAfterChange={() => {
              if (values.all_specialisations_preferred) {
                values.preferred_specialisations = []
              }
            }}
          />
          <Box>
            <PreferredSpecialisationMultiSelect />
          </Box>
          <Box>
            <LapeRadioSelectInput
              required
              name="employment_legal_risk"
              label="Employment risk"
              value={
                employmentRiskOptions.find(
                  ({ value }) => values.employment_legal_risk === value.id,
                )?.value
              }
              onChange={option => {
                if (option) {
                  values.employment_legal_risk = option.id as string
                }
              }}
              options={employmentRiskOptions}
              message={
                isCommercial
                  ? "If the level of employment risk is 'High' is will be highlighted to the requisition creator during the requisition creation process"
                  : 'Requisitions with high employment risk countries require additional legal approval'
              }
            />
          </Box>
        </VStack>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Country hiring saved successfully"
          onAfterSubmit={() => {
            navigateReplace(pathToUrl(ROUTES.SETTINGS.HIRING_PROCESS.COUNTRY_HIRING))
          }}
        >
          Update changes
        </NewSaveButtonWithPopup>
      </PageActions>
    </PageWrapper>
  )
}

export default () => {
  return (
    <Form api={countryHiringRequestsNew}>
      <CountryHiringForm />
    </Form>
  )
}
