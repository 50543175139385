import React from 'react'
import { connect } from 'lape'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { TabsInterface } from '@src/interfaces/data'
import { ReviewCategory, ReviewSummaryInterface } from '@src/interfaces/performance'
import EmployeePerformanceView from '@src/pages/Forms/EmployeePerformance/components/PerformanceView/PerformanceView'
import * as EmployeePerformanceDeliverablesView from '@src/pages/Forms/Probation/DeliverablesView'
import * as EmployeePerformanceViewSkills from '@src/pages/Forms/EmployeePerformanceView/Skills'
import * as EmployeePerformanceViewCultureFit from '@src/pages/Forms/EmployeePerformanceView/CultureFit'
import * as PipSummary from '@src/pages/Forms/PipView/Summary'

const getTabs = (params: {
  id: string
  employeeId?: string
}): TabsInterface<ReviewSummaryInterface>[] => [
  {
    title: 'Deliverables',
    path: ROUTES.FORMS.PIP_REVIEW_VIEW.DELIVERABLES,
    to: pathToUrl(ROUTES.FORMS.PIP_REVIEW_VIEW.DELIVERABLES, params),
    validator: {},
    component: EmployeePerformanceDeliverablesView.default,
    visibilityCheck: () => true,
  },
  {
    title: 'Skills',
    path: ROUTES.FORMS.PIP_REVIEW_VIEW.SKILLS,
    to: pathToUrl(ROUTES.FORMS.PIP_REVIEW_VIEW.SKILLS, params),
    validator: {},
    component: EmployeePerformanceViewSkills.default,
    visibilityCheck: data => !!data?.summary?.functional_skills,
  },
  {
    title: 'Culture fit',
    path: ROUTES.FORMS.PIP_REVIEW_VIEW.CULTURE_FIT,
    to: pathToUrl(ROUTES.FORMS.PIP_REVIEW_VIEW.CULTURE_FIT, params),
    validator: {},
    component: EmployeePerformanceViewCultureFit.default,
    visibilityCheck: () => true,
  },
  {
    title: 'Summary',
    path: ROUTES.FORMS.PIP_REVIEW_VIEW.SUMMARY,
    to: pathToUrl(ROUTES.FORMS.PIP_REVIEW_VIEW.SUMMARY, params),
    validator: {},
    component: PipSummary.default,
    visibilityCheck: () => true,
  },
]

const PipViewForm = () => (
  <EmployeePerformanceView category={ReviewCategory.PIP_V2} getTabs={getTabs} />
)

export default connect(PipViewForm)
