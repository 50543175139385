import React, { useEffect, useMemo, useState } from 'react'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { Button, Cell, InputGroup, Text } from '@revolut/ui-kit'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import { hasEmailNonPrefilledPlaceholders, useGetTypesOfSender } from '@src/utils/hiring'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmailTemplateInterface } from '@src/interfaces/emailTemplates'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import LapeFileUploader from '@components/Inputs/LapeFields/LapeFileUploader'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useGetCandidateSettings } from '@src/api/settings'
import HideIfGmailDisabled from '@components/HideIfGmailDisabled/HideIfGmailDisabled'

export type Props = {
  userEmail?: string
  onShowPlaceholders?: () => void
  showInvalidPlaceholdersWarning?: boolean
  type?: 'recruitment'
  beforeEditor?: React.ReactNode
}

interface EmailFormInterface
  extends Pick<
    EmailTemplateInterface,
    | 'sender_type'
    | 'recipients_cc'
    | 'recipients_bcc'
    | 'subject'
    | 'email_body'
    | 'attachments'
  > {}

const EmailForm = <T extends EmailFormInterface>({
  userEmail,
  onShowPlaceholders,
  showInvalidPlaceholdersWarning,
  type = 'recruitment',
  beforeEditor,
}: Props) => {
  const { data: candidateSettings } = useGetCandidateSettings()
  const typesOfSenderOptions = useGetTypesOfSender(userEmail)
  const { values } = useLapeContext<T>()
  const [showCCFields, setShowCCFields] = useState(
    !!values.recipients_cc?.length || !!values.recipients_bcc?.length,
  )

  useEffect(() => {
    if (
      !values.attachments?.length ||
      values.attachments.some(attachment => !attachment.name)
    ) {
      values.attachments = null
    }
  }, [])

  useEffect(() => {
    if (
      (type === 'recruitment' && candidateSettings?.enable_emails === false) ||
      candidateSettings?.enable_gmail_integration === false
    ) {
      values.sender_type = 'generic'
    }
  }, [
    candidateSettings?.enable_emails,
    candidateSettings?.enable_gmail_integration,
    type,
  ])

  const { data: recruiters } = useGetSelectors<{ email: string }>(
    selectorKeys.active_employee_emails,
  )

  const nonPrefilledPlaceholders = useMemo(
    () => hasEmailNonPrefilledPlaceholders(values.email_body),
    [values.email_body],
  )

  const emailOptions = useMemo(
    () =>
      recruiters?.map(recruiter => ({
        label: recruiter.email,
        value: { id: recruiter.email },
      })),
    [recruiters],
  )

  const personalEmailsEnabled = type !== 'recruitment' || candidateSettings?.enable_emails

  return (
    <InputGroup>
      {personalEmailsEnabled && (
        <LapeRadioSelectInput
          name="sender_type"
          label="Send as"
          options={typesOfSenderOptions}
          value={
            typesOfSenderOptions.find(item => item.key === values.sender_type)?.value
          }
          onChange={val => {
            if (val) {
              values.sender_type = val.id
            }
          }}
          disabled={!candidateSettings?.enable_gmail_integration}
        >
          {option => (
            <>
              <Text use="p">{option.value.name}</Text>
              <Text use="p" variant="caption" mt="s-4" color="grey-tone-50">
                {option.value.subtitle}
              </Text>
            </>
          )}
        </LapeRadioSelectInput>
      )}
      <HideIfGmailDisabled>
        {personalEmailsEnabled && (
          <>
            {!showCCFields && (
              <Button variant="secondary" size="sm" onClick={() => setShowCCFields(true)}>
                Add cc/bcc
              </Button>
            )}
            {showCCFields && (
              <>
                <LapeNewMultiSelect<{ id: string }>
                  name="recipients_cc"
                  placeholder="Cc"
                  options={emailOptions}
                  allowCustomOptions
                  customOptionLabel="Add email:"
                  value={
                    values.recipients_cc?.map(email => ({
                      label: email,
                      value: { id: email },
                    })) || []
                  }
                  onChange={recipients => {
                    values.recipients_cc = recipients.map(recipient => recipient.value.id)
                  }}
                />
                <LapeNewMultiSelect<{ id: string }>
                  name="recipients_bcc"
                  placeholder="Bcc"
                  options={emailOptions}
                  allowCustomOptions
                  customOptionLabel="Add email:"
                  value={
                    values.recipients_bcc?.map(email => ({
                      label: email,
                      value: { id: email },
                    })) || []
                  }
                  onChange={recipients => {
                    values.recipients_bcc = recipients.map(
                      recipient => recipient.value.id,
                    )
                  }}
                />
              </>
            )}
          </>
        )}
      </HideIfGmailDisabled>
      <LapeNewInput name="subject" label="Subject line" required />
      {beforeEditor}
      <LapeHTMLEditor
        name="email_body"
        placeholder="Email body"
        height={210}
        required
        addMarginToParagraphs
      />
      {!!nonPrefilledPlaceholders?.length && showInvalidPlaceholdersWarning && (
        <ActionWidget
          title="You have non-prefilled placeholders"
          text={`Placeholders ${nonPrefilledPlaceholders.join(
            ', ',
          )} have not been prefilled with actual data. Please replace it manually`}
          data-testid="non_prefilled_placeholders_warning"
        />
      )}
      <InputGroup>
        {onShowPlaceholders && (
          <Cell
            use="button"
            type="button"
            variant="disclosure"
            onClick={onShowPlaceholders}
          >
            View placeholders
          </Cell>
        )}
        {personalEmailsEnabled && (
          <HideIfGmailDisabled>
            <LapeFileUploader name="attachments" label="Attach files" multiple />
          </HideIfGmailDisabled>
        )}
      </InputGroup>
    </InputGroup>
  )
}

export default EmailForm
