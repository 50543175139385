import React, { MouseEvent, useState } from 'react'
import { Button, ButtonProps } from '@revolut/ui-kit'

interface OnLoadingButtonProps extends ButtonProps {
  onSubmit: () => Promise<any>
}

const NewLoadingButton = ({ onSubmit, ...rest }: OnLoadingButtonProps) => {
  const [loading, setLoading] = useState(false)
  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    try {
      setLoading(true)
      await onSubmit()
    } finally {
      setLoading(false)
    }
  }

  return <Button {...rest} pending={loading} onClick={handleClick} />
}

export default NewLoadingButton
