import React from 'react'
import { Color, Cell, Text, RadioGroup, Box, Radio } from '@revolut/ui-kit'
import { getTimeZoneLabel } from '@src/utils/timezones'

interface DaySlotsSingleItemInterface {
  id: string
  event_start_datetime: string
}

export interface DaySlotsSingleInterface {
  id: string
  label: string
  items: DaySlotsSingleItemInterface[]
}

type Props = {
  slots: DaySlotsSingleInterface[]
  onChange: (value: string | null) => void
  value?: string | null
  timeZone: string
}

const DaySingleSlot = ({ slots, onChange, value, timeZone }: Props) => {
  return (
    <Box width="100%">
      <RadioGroup onChange={onChange} value={value}>
        {group =>
          slots.map(day => (
            <Cell
              color={Color.FOREGROUND}
              key={day.label}
              display="block"
              mt="s-16"
              data-testid={`block-${day.label}`}
            >
              <Text
                fontSize="h5"
                display="block"
                mb="s-16"
                aria-label={`day-${day.label}`}
              >
                {day.label}
              </Text>
              {day.items.map(slot => (
                <Box
                  key={slot.id}
                  mb="s-8"
                  borderRadius={10}
                  backgroundColor={Color.GROUPED_BACKGROUND}
                  py="s-12"
                  px="s-16"
                >
                  <Radio
                    {...group.getInputProps({ value: slot.id })}
                    data-testid={`radio-${day.label}-${slot.id}`}
                  >
                    <Text data-testid={`slot-${slot.id}`}>
                      {getTimeZoneLabel(slot.event_start_datetime, timeZone)}
                    </Text>
                  </Radio>
                </Box>
              ))}
            </Cell>
          ))
        }
      </RadioGroup>
    </Box>
  )
}

export default DaySingleSlot
