import React from 'react'
import { EntityTypes } from '@src/constants/api'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { TabReport } from './components/TabReport'

// hardcode until we have interface to configure tab
const reportId = 5

interface CXBugsReportProps {
  data: TeamInterface | DepartmentInterface
  entity: EntityTypes.department | EntityTypes.team
}

export const CXBugsReport = ({ entity, data }: CXBugsReportProps) => {
  return <TabReport data={data} entity={entity} reportId={reportId} />
}
