import React from 'react'
import Form from '@src/features/Form/Form'
import { formHiringProcessStagesSpecialisationRequest } from '@src/api/hiringProcess'

import { connect } from 'lape'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import HiringStageSidebarPreview from '@src/features/HiringStageSidebarPreview/HiringStageSidebarPreview'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { updateStagesAfterDelete } from '@src/utils/hiring'

interface Props {
  id?: number
  onClose: () => void
}

const HiringStageSpecialisationPreviewForm = ({ id, onClose }: Props) => {
  const context = useLapeContext<SpecialisationInterface>()

  if (!id) {
    return null
  }

  const onSuccessDelete = () => {
    updateStagesAfterDelete<SpecialisationInterface>(context, id!)
    onClose()
  }

  return (
    <Form<HiringProcessInterface>
      api={formHiringProcessStagesSpecialisationRequest}
      forceParams={{ id: String(id) }}
      disableLoading
    >
      <HiringStageSidebarPreview
        type="specialisation"
        onSuccessDelete={onSuccessDelete}
        onClose={onClose}
      />
    </Form>
  )
}

export default connect(HiringStageSpecialisationPreviewForm)
