import React from 'react'
import styled from 'styled-components'
import Icon, { Icons } from '../../Icon/Icon'
import { colorGetter } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

const A = styled.a`
  display: flex;
  color: ${colorGetter(Color.BLUE)};

  &:hover {
    color: ${colorGetter(Color.BLUE_OPAQUE_60)};
  }
`

interface IconLink {
  href?: string
  type: Icons
}

export const IconUrl = ({ href, type }: IconLink) => {
  if (!href) {
    return <span>-</span>
  }
  return (
    <A
      href={href}
      onClick={e => {
        e.stopPropagation()
      }}
      target="_blank"
    >
      <Icon type={type} size="tiny" />
    </A>
  )
}
