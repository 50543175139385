import React, { useState } from 'react'
import { Color, Flex, MoreBar } from '@revolut/ui-kit'
import SwitchButton from '@src/components/SwitchButton/SwitchButton'
import AdjustableTable from '@components/Table/AdjustableTable'
import { BookingLinkDefinitionInterface } from '@src/interfaces/bookingLinks'
import {
  bookingLinkDefinitionActionColumn,
  bookingLinkDefinitionInterviewStageColumn,
  bookingLinkDefinitionJobPostingTitleColumn,
  bookingLinkDefinitionLinkColumn,
  bookingLinkDefinitionRecruitersColumn,
  bookingLinkDefinitionSourcerNameColumn,
  bookingLinkDefinitionStatusColumn,
} from '@src/constants/columns/bookingLinks'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { TableNames } from '@src/constants/table'
import { useTable } from '@src/components/Table/hooks'
import { getBookingLinkDefinitions } from '@src/api/bookingLinks'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { EmployeeInterface } from '@src/interfaces/employees'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import BookingLinkSidebarForm from '@src/features/BookingLinks/Recruiter/BookingLinkSidebarForm'
import { PermissionTypes } from '@src/store/auth/types'
import { Plus } from '@revolut/icons'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { TableWrapper } from '@src/components/Table/TableWrapper'
import { useExtensionApiContext } from '@src/utils/extension'
import { getColor } from '@src/styles/colors'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'

const ROW = (
  onEdit?: (bookingLink: BookingLinkDefinitionInterface) => void,
): RowInterface<BookingLinkDefinitionInterface> => {
  return {
    highlight: (data, theme) =>
      data.is_enabled ? '' : getColor(theme, Color.RED_OPAQUE_5),
    cells: [
      {
        ...bookingLinkDefinitionJobPostingTitleColumn,
        width: 120,
      },
      {
        ...bookingLinkDefinitionInterviewStageColumn,
        width: 120,
      },
      {
        ...bookingLinkDefinitionSourcerNameColumn,
        width: 120,
      },
      {
        ...bookingLinkDefinitionRecruitersColumn,
        width: 120,
      },
      {
        ...bookingLinkDefinitionLinkColumn,
        width: 100,
      },
      {
        ...bookingLinkDefinitionStatusColumn,
        width: 70,
      },
      {
        ...bookingLinkDefinitionActionColumn(onEdit),
        width: 70,
      },
    ],
  }
}

const getFilterByUser = (
  setFilter: boolean,
  user: EmployeeInterface,
): FilterByInterface => ({
  filters: setFilter
    ? [
        {
          name: user.display_name,
          id: user.id,
        },
      ]
    : [],
  columnName: 'owner',
  nonResettable: true,
})

type BookingLinksTableProps = {
  jobPosting?: JobPostingInterface
  useSidebar?: boolean
  simplified?: boolean
}

const getInitialFilter = (
  userFilter: FilterByInterface,
  jobPosting?: JobPostingInterface,
) => {
  return [
    userFilter,
    jobPosting
      ? {
          filters: [{ name: 'job_posting', id: jobPosting.id }],
          columnName: 'job_posting',
          nonResettable: true,
        }
      : undefined,
  ].filter(Boolean)
}

const BookingLinksTable = ({
  jobPosting,
  useSidebar = true,
  simplified,
}: BookingLinksTableProps) => {
  const { sendAnalyticsEvent } = useAnalytics()
  const apiHandler = useExtensionApiContext()
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const canEditBookingLinkDefinitions = permissions.includes(
    PermissionTypes.AddBookingLinkDefinition,
  )
  const [myLinks, setMyLinks] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_BOOKING_LINK_DEFINITIONS,
    false,
  )
  const [openSidebar, setOpenSidebar] = useState<{ open: boolean; id: number | null }>({
    open: false,
    id: null,
  })
  const table = useTable<BookingLinkDefinitionInterface>(
    { getItems: getBookingLinkDefinitions(apiHandler) },
    getInitialFilter(getFilterByUser(myLinks, user), jobPosting),
  )
  const { refresh } = table

  const handleToggleMyLinks = () => {
    setMyLinks(!myLinks)
    table.onFilterChange(getFilterByUser(!myLinks, user))
  }

  const renderBookingLinkForm = () => (
    <BookingLinkSidebarForm
      id={openSidebar.id || undefined}
      open
      onClose={() => {
        setOpenSidebar({
          open: false,
          id: null,
        })
      }}
      onAfterSubmit={() => {
        sendAnalyticsEvent(AnalyticsEvents.booking_link_created)
        refresh()
      }}
      useSidebar={useSidebar}
      jobPosting={jobPosting}
    />
  )

  // if we don't use sidebar we just show it instead the content
  if (openSidebar.open && !useSidebar) {
    return renderBookingLinkForm()
  }

  return (
    <>
      <TableWrapper>
        <Flex flexDirection="column" width="100%">
          <Flex mb="s-16" justifyContent="space-between">
            <MoreBar>
              <MoreBar.Action
                useIcon={Plus}
                onClick={() => {
                  setOpenSidebar({
                    open: true,
                    id: null,
                  })
                }}
                disabled={!canEditBookingLinkDefinitions}
              >
                Create booking link
              </MoreBar.Action>
              {!jobPosting && (
                <SwitchButton useMoreBar checked={myLinks} onClick={handleToggleMyLinks}>
                  My links
                </SwitchButton>
              )}
            </MoreBar>
          </Flex>
          <Flex style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable<BookingLinkDefinitionInterface>
              name={TableNames.BookingLinks}
              useWindowScroll
              dataType="Booking links"
              row={ROW(
                canEditBookingLinkDefinitions
                  ? bookingLink => {
                      setOpenSidebar({
                        open: true,
                        id: bookingLink.id,
                      })
                    }
                  : undefined,
              )}
              hiddenCells={{
                [bookingLinkDefinitionJobPostingTitleColumn.idPoint]: !!jobPosting,
                [bookingLinkDefinitionSourcerNameColumn.idPoint]: !!simplified,
                [bookingLinkDefinitionStatusColumn.idPoint]: !!simplified,
              }}
              {...table}
              noDataMessage="Booking links will appear here."
            />
          </Flex>
        </Flex>
      </TableWrapper>
      {openSidebar.open && renderBookingLinkForm()}
    </>
  )
}

export default BookingLinksTable
