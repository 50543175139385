import { Action, Box, Group, InputGroup, ItemSkeleton, Subheader } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import React from 'react'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import ChangeRoundItem from '@src/pages/Forms/Candidate/InterviewProgress/components/ChangeRound/ChangeRoundItem'

type Props = {
  activeItems: InterviewRoundInterface[]
  archivedItems: InterviewRoundInterface[]
  candidateId: number
  activeRoundId?: number
  currentRound?: number
  onRoundClick: (roundId: number) => void
  isLoading: boolean
  readOnly?: boolean
}

const ChangeRoundContent = ({
  activeItems,
  archivedItems,
  candidateId,
  currentRound,
  onRoundClick,
  isLoading,
  readOnly,
  activeRoundId,
}: Props) => {
  const getCommonItemProps = (item: InterviewRoundInterface) => ({
    item,
    activeRoundId,
    currentRound,
    onClick: () => {
      onRoundClick(item.id)
    },
    readOnly,
  })

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>
          Active Jobs {!isLoading && <>({activeItems.length})</>}
        </Subheader.Title>
        <Subheader.Side>
          {!readOnly && (
            <Action
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.ADD_INTERVIEW_ROUND.GENERAL, {
                    candidateId,
                  }),
                )
              }
              disabled={isLoading}
            >
              Add new
            </Action>
          )}
        </Subheader.Side>
      </Subheader>

      {isLoading ? (
        <ItemSkeleton />
      ) : (
        <>
          <InputGroup>
            <Group>
              {activeItems.map(item => (
                <ChangeRoundItem key={item.id} {...getCommonItemProps(item)} />
              ))}
            </Group>
          </InputGroup>

          {!!archivedItems.length && (
            <>
              <Subheader variant="nested">
                <Subheader.Title>Archived Jobs ({archivedItems.length})</Subheader.Title>
              </Subheader>
              <Group>
                {archivedItems.map(item => (
                  <ChangeRoundItem key={item.id} {...getCommonItemProps(item)} archived />
                ))}
              </Group>
            </>
          )}
        </>
      )}
    </Box>
  )
}

export default ChangeRoundContent
