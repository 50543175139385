import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { requisitionSettings } from '@src/api/settings'
import RequisitionsGeneral from '@src/pages/Settings/Requisitions/General'

const routes = [
  {
    title: 'Requisitions settings',
    description: 'Settings related to requisitions setup & management',
    path: ROUTES.SETTINGS.REQUISITIONS.GENERAL,
    url: ROUTES.SETTINGS.REQUISITIONS.GENERAL,
    canView: [
      PermissionTypes.ViewRequisitionPreferences,
      PermissionTypes.ChangeRequisitionPreferences,
    ],
    component: RequisitionsGeneral,
  },
]

export const RequisitionsSettings = () => {
  return <SettingsForm routes={routes} api={requisitionSettings} />
}
