import React from 'react'
import styled from 'styled-components'
import {
  Box,
  Color,
  Flex,
  HStack,
  VStack,
  Text,
  createChain,
  TextProps,
  Token,
} from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'
import { getStatusColor } from '@components/CommonSC/General'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Icon from '@components/Icon/Icon'
import { EmployeeInterface } from '@src/interfaces/employees'
import { colorGetter } from '@src/styles/colors'
import { Avatar } from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { getAvatarUrl } from '@src/utils/employees'

const PendingEmployeeTitleContainerTooltip = styled(Tooltip)`
  display: flex;
  color: ${props => props.theme.colors.foreground};
  align-items: center;
  cursor: pointer;
  font-style: italic;
`
const PendingEmployeeWarningIcon = styled(Icon)`
  margin-left: 3px;
  color: ${colorGetter(Color.GREY_TONE_50)};
`
export const FormTitleObsolete = ({ data }: { data: EmployeeInterface }) => {
  const chainingTextProps = {
    color: Color.GREY_TONE_50,
    variant: 'caption',
  } as TextProps

  const positionChain = createChain(<Text {...chainingTextProps}>at</Text>)
  const locationChain = createChain(<Text {...chainingTextProps}>in</Text>)

  const displayPosition = data.status?.id || data.employee_type || data.specialisation?.id
  const displayLocation = data.team?.name || data.location?.location_name

  return data.full_name === 'Pending Employee' ? (
    <PendingEmployeeTitleContainerTooltip
      placement="bottom"
      text="This employees details won't be fully available until the contract is signed"
    >
      Pending Employee
      <PendingEmployeeWarningIcon type="WarningOutlined" size="small" />
    </PendingEmployeeTitleContainerTooltip>
  ) : (
    <Flex flex={1} height={76} alignItems="center" gap="s-16">
      {data.avatar && data.id && <Avatar size={56} image={getAvatarUrl(data.avatar)} />}
      <VStack>
        <Box>{data.id ? data.full_name : `New Employee`}</Box>
        {data.full_name === 'Pending Employee' ? null : (
          <HStack aria-label="profile subtitle" align="center" space="s-8">
            {positionChain(
              displayPosition ? (
                <>
                  {data.status?.id ? (
                    <Text
                      variant="caption"
                      color={getStatusColor(data.status.id) || Token.color.greyTone50}
                    >
                      {data.status.name}
                    </Text>
                  ) : null}
                  {data.employee_type !== 'internal' ? (
                    <Text variant="caption" color={Token.color.yellow}>
                      External
                    </Text>
                  ) : null}
                  {data.specialisation?.id ? (
                    <Text
                      use={InternalLink}
                      to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                        id: data.specialisation.id,
                      })}
                      target="_blank"
                      color={Color.FOREGROUND}
                      variant="caption"
                      textDecoration="none"
                    >
                      {data.employee_type === 'internal'
                        ? data.job_title || data.specialisation.name
                        : data.specialisation.name}
                    </Text>
                  ) : null}
                </>
              ) : null,
              displayLocation ? (
                <>
                  {locationChain(
                    data.team?.name ? (
                      <Text
                        use={InternalLink}
                        to={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: data.team.id })}
                        target="_blank"
                        color={Color.FOREGROUND}
                        variant="caption"
                        textDecoration="none"
                      >
                        {data.team.name}
                      </Text>
                    ) : null,
                    data.location?.location_name ? (
                      <Text
                        use={InternalLink}
                        to={pathToUrl(ROUTES.FORMS.LOCATION.GENERAL, {
                          id: data.location.id,
                        })}
                        target="_blank"
                        color={Color.FOREGROUND}
                        variant="caption"
                        textDecoration="none"
                      >
                        {data.location.location_name}
                      </Text>
                    ) : null,
                  )}
                </>
              ) : null,
            )}
          </HStack>
        )}
      </VStack>
    </Flex>
  )
}
