import React, { useState } from 'react'
import styled from 'styled-components'
import { Pencil } from '@revolut/icons'
import { colorGetter } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

const InputStyled = styled.input`
  padding: 0 16px 0 4px;
  outline: none;
  border: 0;
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  background-color: transparent;

  &:focus,
  &:hover {
    border: 1px solid ${colorGetter(Color.BLUE)};
  }
`

const PencilIcon = styled(Pencil)`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  display: none;
  pointer-events: none;
`

const InputWrapper = styled.span`
  position: relative;

  &:hover ${PencilIcon} {
    display: block;
  }
`

type Props = {
  disabled?: boolean
  value?: string
  onChange: (id: number, position: number) => void
  id: number
}

const OrderingPositionCell = ({ disabled, value, onChange, id }: Props) => {
  const [val, setVal] = useState(value)

  if (disabled) {
    return <>{value || ''}</>
  }

  const onHandleChange = () => {
    if (val !== undefined && value !== val) {
      onChange(id, +val)
    }
  }

  return (
    <InputWrapper>
      <InputStyled
        onMouseDown={e => {
          e.stopPropagation()
        }}
        onClick={e => {
          e.stopPropagation()
        }}
        onChange={e => {
          if (e.target.value === '0') {
            return
          }

          if (e.target.validity.valid) {
            setVal(e.target.value)
          }
        }}
        onBlur={() => {
          if (val === '') {
            setVal(value)
            return
          }

          onHandleChange()
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            onHandleChange()
          }
        }}
        value={val}
        type="text"
        pattern="[0-9]*"
      />
      <PencilIcon color={Color.BLUE} size={16} />
    </InputWrapper>
  )
}

export default OrderingPositionCell
