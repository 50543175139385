import React from 'react'
import { CompensationBandInterface } from '@src/interfaces/benchmarks'
import CompensationBandBase from '@components/LocationCompensationBand/CompensationBandBase'
import { allCompensationBandsAvailable } from '@components/LocationCompensationBand/utils'
import { Box, Link } from '@revolut/ui-kit'
import { BENCHMARK_SERVICE_DESK } from '@src/constants/externalLinks'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

type Props = {
  bands?: CompensationBandInterface[]
}

const RequisitionCompensationBand = ({ bands }: Props) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  if (allCompensationBandsAvailable(bands)) {
    return null
  }

  return (
    <Box mb="s-16">
      <CompensationBandBase
        text="To comply with legal requirements of the below locations, compensation bands should be displayed."
        bands={bands}
        bottomText={
          isCommercial ? undefined : (
            <Box mt="s-4">
              Kindly raise a ticket in the{' '}
              <Link
                href={BENCHMARK_SERVICE_DESK}
                target="_blank"
                rel="noopener noreferrer"
              >
                Global Reward Service Desk
              </Link>{' '}
              to request a benchmark and the compensation team will enter it in the system
            </Box>
          )
        }
      />
    </Box>
  )
}

export default RequisitionCompensationBand
