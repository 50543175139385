import React from 'react'
import { useTable } from '@components/Table/hooks'
import {
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { fieldChangelogKPIRequests } from '@src/api/changelog'
import {
  changelogChangedBy,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import { KpiInterface } from '@src/interfaces/kpis'
import { Statuses } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const FieldsChangelogKPIRow: RowInterface<ChangelogInterface<KpiInterface>> = {
  highlight: (data, theme) => {
    if (data.status?.id === Statuses.pending) {
      return theme.colors['orange-5']
    }

    return theme.colors.background
  },
  noChildrenRequest: true,
  isNotNested: true,
  cells: [
    {
      ...changelogFieldsChangedFields,
      selectorsKey: selectorKeys.kpi_changelog_fields,
      width: 210,
    },
    {
      ...changelogFieldsNewValue,
      selectorsKey: selectorKeys.kpi_changelog_values,
      width: 205,
    },
    {
      ...changelogChangedBy,
      width: 205,
    },
    {
      ...changelogEffectiveDate,
      title: 'Changed on',
      width: 205,
    },
    {
      ...changelogStatus,
      width: 205,
    },
  ],
}

const Changelog = () => {
  const { values } = useLapeContext<KpiInterface>()
  const initialFilter: FilterByInterface[] = [
    {
      columnName: 'target__id',
      nonResettable: true,
      filters: [{ id: values.id, name: values.full_name }],
    },
  ]

  const initialSort: SortByInterface[] = [
    {
      sortBy: 'effective_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const changelogFieldsTable = useTable<ChangelogInterface<KpiInterface>>(
    fieldChangelogKPIRequests,
    initialFilter,
    initialSort,
  )

  const handleRowClick = (change: ChangelogInterface<KpiInterface>) => {
    navigateReplace(
      `${pathToUrl(ROUTES.FORMS.KPI.GENERAL, { id: values.id })}?change=${change.id}`,
    )
  }

  return (
    <TableWrapper mt="s-24">
      <AdjustableTable<ChangelogInterface<KpiInterface>>
        name={TableNames.KPIChangelog}
        useWindowScroll
        dataType="Data Change"
        row={FieldsChangelogKPIRow}
        {...changelogFieldsTable}
        onRowClick={handleRowClick}
        noDataMessage="There are no changes to display"
      />
    </TableWrapper>
  )
}

export default Changelog
