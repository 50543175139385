import React, { useState } from 'react'
import { VStack, Text, Color, HStack } from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'
import { isArray } from 'lodash'

import { PageBody } from '@src/components/Page/PageBody'
import ImportFile from '@src/pages/Forms/ImportData/common/ImportFile'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { TitleAndSubtitle } from '@src/components/TitleAndSubtitle'
import { saveFile } from '@src/utils'
import { getImportFaqTemplateUrl, importFaqFile } from '@src/api/faq'

export const QuestionsUploadFile = () => {
  const [importPending, setImportPending] = useState(false)
  const [downloadTemplatePending, setDownloadTemplatePending] = useState(false)

  const onFileUpload = (file: File) => {
    setImportPending(true)

    importFaqFile(file)
      .then(response => {
        navigateTo(
          pathToUrl(ROUTES.APPS.HELP_CENTER.FAQ_IMPORT.SESSION, {
            id: response.data.id,
          }),
        )
      })
      .catch(() => setImportPending(false))
  }

  const onDownloadTemplate = () => {
    setDownloadTemplatePending(true)
    getImportFaqTemplateUrl()
      .then(details => saveFile(details, 'FAQs'))
      .finally(() => setDownloadTemplatePending(false))
  }

  return (
    <PageBody>
      <VStack space="s-16">
        <TitleAndSubtitle
          title={
            <HStack align="center" space="s-8">
              <Text>How to guide</Text>
              <InfoOutline size={16} color={Color.GREY_TONE_50} />
            </HStack>
          }
          subtitle={
            <>
              1. Use our FAQs data template by clicking “Download template”
              <br />
              2. Fill in the columns, also adjust or add more columns
              <br />
              3. Upload your file by “Drag &amp; drop or Click to attach document”
            </>
          }
        />

        <ImportFile
          importPending={importPending}
          downloadTemplatePending={downloadTemplatePending}
          supportedFormats={['CSV', 'XLSX']}
          onDownloadTemplate={onDownloadTemplate}
          onFileUpload={file => {
            if (isArray(file)) {
              onFileUpload(file[0])
            } else if (file) {
              onFileUpload(file)
            }
          }}
        />
      </VStack>
    </PageBody>
  )
}
