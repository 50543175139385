import { FileInterface } from '@src/interfaces/files'
import React, { useEffect, useState } from 'react'
import { downloadFile, getFileMetadata } from '@src/api/files'
import { Box, Color, Header, MoreBar, Skeleton } from '@revolut/ui-kit'
import { saveFile } from '@src/utils'
import { Download } from '@revolut/icons'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import SideBar from '@components/SideBar/SideBar'

type PreviewDocumentSidebarProps = {
  file: { id?: number; url?: string | null; name: string }
  onClose: () => void
  downloadParams?: { [key: string]: string }
}

const PreviewDocumentSidebar = ({
  file,
  onClose,
  downloadParams,
}: PreviewDocumentSidebarProps) => {
  const [loading, setLoading] = useState(false)
  const [fileDetails, setFileDetails] = useState<string | undefined>(
    file?.url || undefined,
  )
  const [fileMetadata, setFileMetadata] = useState<FileInterface>()

  const fetchMetadata = async () => {
    if (!file?.id) {
      return
    }

    setLoading(true)

    try {
      const { data: metadata } = await getFileMetadata(file.id)
      if (metadata) {
        const fileData = await downloadFile(file.id)
        setFileDetails(fileData)
      }
      setFileMetadata(metadata)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchMetadata()
  }, [file?.id])

  const getExtFromUrl = () => {
    if (!file?.url) {
      return undefined
    }

    try {
      const url = new URL(file?.url)
      const pathname = url?.pathname?.split('.')

      if (pathname) {
        return pathname[pathname.length - 1]
      }

      return undefined
    } catch (e) {
      return undefined
    }
  }

  const extFromUrl = getExtFromUrl()

  const extension = fileMetadata?.extension || extFromUrl
  const isPreviewable =
    extension === 'pdf' ||
    extension === 'png' ||
    extension === 'jpg' ||
    extension === 'jpeg'

  const getDownloadUrl = () => {
    if (!fileDetails) {
      return undefined
    }

    if (!downloadParams) {
      return fileDetails
    }

    try {
      const url = new URL(fileDetails)
      const params = url.searchParams

      Object.keys(downloadParams).forEach(key => {
        params.append(key, downloadParams[key])
      })

      return url.toString()
    } catch (e) {
      return undefined
    }
  }

  const downloadUrl = getDownloadUrl()

  return (
    <SideBar
      title="Document preview"
      sideProps={{ resizable: true }}
      isOpen
      variant="wide"
      onClose={onClose}
      headerContent={
        <Header.Bar>
          <MoreBar>
            <MoreBar.Action
              onClick={() => {
                if (downloadUrl) {
                  saveFile(downloadUrl, file.name)
                }
              }}
              useIcon={Download}
              disabled={!fileDetails}
            >
              Download
            </MoreBar.Action>
          </MoreBar>
        </Header.Bar>
      }
    >
      {loading ? (
        <Skeleton height={60} borderRadius={8} data-testid="preview-document-skeleton" />
      ) : (
        <Box height="calc(100vh - 230px)">
          {fileDetails && isPreviewable && !loading ? (
            <iframe
              src={`${fileDetails}#view=fitH&toolbar=0&navpanes=0`}
              width="100%"
              height="100%"
              frameBorder="0"
              title="Document"
              data-testid="preview-iframe"
            />
          ) : null}
          {fileDetails && !isPreviewable ? (
            <ActionWidget
              title="The attachment cannot be previewed"
              avatarColor={Color.PRIMARY}
            />
          ) : null}
        </Box>
      )}
    </SideBar>
  )
}

export default PreviewDocumentSidebar
