import {
  DataPoint,
  HStack,
  TableWidget,
  Token,
  Text,
  MoreBar,
  TextSkeleton,
  Icon,
} from '@revolut/ui-kit'
import GraphIconChart from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { useTable } from '@src/components/Table/hooks'
import { fetchGoalGraph, goalsListTableRequests } from '@src/api/goals'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React from 'react'
import {
  CycleFilter,
  CycleFilterType,
} from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { FilterSelectType } from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  goalsCurrentValueColumn,
  goalsInitialValueColumn,
  goalsNameColumn,
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsStrategyColumn,
  goalsTargetColumns,
  goalsTypeColumn,
  goalsUnitColumn,
  goalsWeightColumn,
  golsStatusColumn,
} from '@src/constants/columns/goals'
import SearchTable from '@src/components/Table/SearchTable/SearchTable'
import { selectorKeys } from '@src/constants/api'
import { FilterByInterface } from '@src/interfaces/data'
import { AddGoalAction } from './components/AddGoalAction'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { BulkGoalApproveAction } from './components/BulkGoalApproveAction'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const GoalsTableWidget = ({
  initialFilters,
}: {
  initialFilters: FilterByInterface[]
}) => {
  const { entity } = useOrgEntity()

  const table = useTable(goalsListTableRequests, initialFilters)
  const goalsIdsToApprove = table.data
    .filter(({ approval_status }) => approval_status.id !== ApprovalStatuses.Approved)
    .map(({ id }) => ({ id }))

  return (
    <TableWidget>
      <TableWidget.Info>
        <DataPoint>
          <DataPoint.Value color={Token.color.greyTone50}>
            {entity && table.stats?.overall_progress !== undefined ? (
              <HStack space="s-8" align="center">
                <Text use="h5">{table.stats.overall_progress}%</Text>
                <GraphIconChart
                  id={entity.data.id}
                  vertical="right"
                  fetchData={fetchGoalGraph}
                >
                  <Icon
                    name="BarChart"
                    size={16}
                    style={{ cursor: 'pointer ' }}
                    color={Token.color.greyTone50}
                  />
                </GraphIconChart>
              </HStack>
            ) : (
              <TextSkeleton />
            )}
          </DataPoint.Value>
          <DataPoint.Label>Overall Progress</DataPoint.Label>
        </DataPoint>
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={table.onFilterChange}
          columnName="cycle__id"
          filter={table.filterBy}
          selector={selectorKeys.review_cycles}
          filterInputType={FilterSelectType.SingleSelect}
        />
      </TableWidget.Info>
      <TableWidget.Search>
        <SearchTable
          placeholder="Search by name"
          onFilter={table.onFilterChange}
          ml={0}
        />
      </TableWidget.Search>
      <TableWidget.Actions>
        <MoreBar>
          <BulkGoalApproveAction
            goals={goalsIdsToApprove}
            onApproved={table.refresh}
            title={
              goalsIdsToApprove.length === table.data.length
                ? 'Approve all goals'
                : undefined
            }
          />
          <AddGoalAction />
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable
          childreOpenByDefault
          expandableType="chevron"
          name={TableNames.Goals}
          dataType="Goal"
          {...table}
          row={{
            linkToForm: (data, parentIndexes) => {
              const id =
                parentIndexes && parentIndexes.length
                  ? table.data[parentIndexes[0]]?.id
                  : data.id

              if (id) {
                const url = pathToUrl(
                  ROUTES.FORMS.GOAL.PREVIEW,
                  { id },
                  filterSortPageIntoQuery(undefined, table.filterBy) as Record<
                    string,
                    string
                  >,
                )
                navigateTo(url)
              }
            },
            noChildrenRequest: true,
            cells: [
              {
                ...goalsNameColumn,
                width: 300,
              },
              {
                ...goalsInitialValueColumn,
                width: 50,
              },
              {
                ...goalsCurrentValueColumn,
                width: 50,
              },
              {
                ...goalsTargetColumns,
                width: 50,
              },
              {
                ...goalsUnitColumn,
                width: 50,
              },
              {
                ...goalsStrategyColumn,
                width: 50,
              },
              {
                ...goalsTypeColumn,
                width: 50,
              },
              {
                ...goalsWeightColumn,
                width: 50,
              },
              {
                ...golsStatusColumn,
                width: 100,
              },

              {
                ...goalsProgressColumn,
                width: 50,
              },
              {
                ...goalsOwnerColumn,
                width: 150,
              },
            ],
          }}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
