import React from 'react'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { Color, MoreBar } from '@revolut/ui-kit'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { Questionnaire } from '@revolut/icons'
import {
  clearCVScreeningSession,
  navigateToCVScreening,
  setCVScreeningSession,
} from '@src/pages/Forms/CVScreening/utils'

type Props = {
  candidateId: number | string
  round?: InterviewRoundInterface
}

const CVScreeningNotification = ({ candidateId, round }: Props) => {
  if (
    round?.latest_interview_stage?.interview_type === 'cv_screening' &&
    round?.latest_interview_stage?.scheduling_status === 'pending_cv_screening'
  ) {
    return (
      <ActionWidget
        title="CV screening is pending"
        text="Please screen the candidate"
        avatarColor={Color.BLUE}
      >
        <MoreBar>
          <MoreBar.Action
            useIcon={Questionnaire}
            onClick={() => {
              clearCVScreeningSession()
              setCVScreeningSession({ candidateIds: [candidateId] })
              navigateToCVScreening(candidateId)
            }}
          >
            Screen CV
          </MoreBar.Action>
        </MoreBar>
      </ActionWidget>
    )
  }

  return null
}

export default CVScreeningNotification
