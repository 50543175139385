import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Carousel,
  Cell,
  Flex,
  Group,
  Link,
  MoreBar,
  Subheader,
  themeSpace,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { InfoOutline, LinkExternal, Pencil, Plus } from '@revolut/icons'
import { subDays } from 'date-fns'
import styled from 'styled-components'
import pluralize from 'pluralize'

import { getTimeOffBalanceTransactions, useTimeOffBalanceDetails } from '@src/api/timeOff'
import Loader from '@src/components/CommonSC/Loader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { EmployeeTimeOffBalancesInterface } from '@src/interfaces/timeOff'
import { formatPeriod } from '@src/utils/format'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import {
  formatBalanceCount,
  formatDuration,
  getBalanceColor,
  timeOffBalanceTransactionAmountColumn,
  timeOffBalanceTransactionCreateDateColumn,
  timeOffBalanceTransactionCreatedByColumn,
  timeOffBalanceTransactionDescriptionColumn,
  timeOffBalanceTransactionFieldColumn,
  timeOffBalanceTransactionTypeColumn,
} from '@src/constants/columns/timeOff'
import { useTable } from '@src/components/Table/hooks'
import Stat from '@components/Stat/Stat'
import { getStatusColor } from '@src/components/CommonSC/General'
import { PermissionTypes } from '@src/store/auth/types'
import { navigateTo } from '@src/actions/RouterActions'
import { AdjustBalanceSidebar } from '@src/components/TimeOff/AdjustBalanceSidebar'
import { utcToLocalDate } from '@src/utils/timezones'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { TableNames } from '@src/constants/table'

const row = {
  cells: [
    {
      ...timeOffBalanceTransactionCreateDateColumn,
      width: 150,
    },
    {
      ...timeOffBalanceTransactionTypeColumn,
      width: 150,
    },
    {
      ...timeOffBalanceTransactionAmountColumn,
      width: 150,
    },
    {
      ...timeOffBalanceTransactionFieldColumn,
      width: 150,
    },
    {
      ...timeOffBalanceTransactionCreatedByColumn,
      width: 150,
    },
    {
      ...timeOffBalanceTransactionDescriptionColumn,
      width: 200,
    },
  ],
}

const Ul = styled.ul`
  margin: 0;
  padding: ${themeSpace('s-8')} ${themeSpace('s-20')};
  color: white;
  white-space: nowrap;
`

const TimeOffBalances = () => {
  const { id } = useParams<{ id: string }>()

  const { isLoading, data, refetch } = useTimeOffBalanceDetails(id)
  const table = useTable({ getItems: getTimeOffBalanceTransactions(id) })

  const [adjustBalanceSidebarOpen, setAdjustBalanceSidebarOpen] = useState(false)

  if (isLoading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  if (!data) {
    return null
  }

  const canRequestTimeOff = data.field_options?.permissions?.includes(
    PermissionTypes.RequestTimeOff,
  )
  const canAdjustBalance = data.field_options?.permissions?.includes(
    PermissionTypes.AddAdjustments,
  )

  const requestTypeBalances = data.request_type_balances?.map(balance => ({
    id: balance.id,
    name: balance.request_type.name,
    available: balance.available,
    taken: balance.request_type.amount - balance.available,
  }))

  return (
    <>
      <PageWrapper>
        <PageHeader
          title="Time off balance details"
          subtitle={<UserWithAvatar {...data.employee} asText mb="s-16" />}
          backUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.TIME_OFF.BALANCES, {
            id: data?.employee.id,
          })}
        />

        <PageBody>
          <VStack space="s-24">
            <MoreBar>
              {canRequestTimeOff ? (
                <MoreBar.Action
                  onClick={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.GENERAL, {
                        employeeId: data.employee?.id,
                      }),
                      { balanceId: data.id },
                    )
                  }
                  useIcon={Plus}
                >
                  Request time off
                </MoreBar.Action>
              ) : null}
              {canAdjustBalance ? (
                <MoreBar.Action
                  onClick={() => setAdjustBalanceSidebarOpen(true)}
                  useIcon={Pencil}
                >
                  Adjust balance
                </MoreBar.Action>
              ) : null}
            </MoreBar>
            <Cell>
              <Box maxWidth={676} pl="s-12">
                <Carousel>
                  <Carousel.Item>
                    <Stat
                      px="s-12"
                      label="Available"
                      val={
                        <Flex gap="s-8">
                          {formatBalanceCount(data, 'balance_rounded')}
                          {requestTypeBalances?.length ? (
                            <Tooltip
                              forceShow
                              placement="bottom"
                              body={
                                <Ul>
                                  {requestTypeBalances.map(balance => (
                                    <li key={balance.id}>
                                      {balance.name} -{' '}
                                      {pluralize(
                                        data.unit.name.toLowerCase(),
                                        balance.available,
                                        true,
                                      )}
                                    </li>
                                  ))}
                                </Ul>
                              }
                            >
                              <InfoOutline size={20} color="foreground" />
                            </Tooltip>
                          ) : null}
                        </Flex>
                      }
                      color={getBalanceColor(data, 'balance_rounded')}
                    />
                  </Carousel.Item>
                  <Carousel.Item mx="s-8">
                    <Stat
                      label="Final"
                      val={
                        <Flex gap="s-8">
                          {formatBalanceCount(data, 'balance_end_of_cycle_rounded')}
                          {requestTypeBalances?.length ? (
                            <Tooltip
                              forceShow
                              placement="bottom"
                              body={
                                <Ul>
                                  {requestTypeBalances.map(balance => (
                                    <li key={balance.id}>
                                      {balance.name} -{' '}
                                      {pluralize(
                                        data.unit.name.toLowerCase(),
                                        balance.available,
                                        true,
                                      )}
                                    </li>
                                  ))}
                                </Ul>
                              }
                            >
                              <InfoOutline size={20} color="foreground" />
                            </Tooltip>
                          ) : null}
                        </Flex>
                      }
                      color={getBalanceColor(data, 'balance_end_of_cycle_rounded')}
                    />
                  </Carousel.Item>
                  <Carousel.Item mx="s-8">
                    <Stat label="Booked" val={formatDuration(data, 'booked')} />
                  </Carousel.Item>
                  <Carousel.Item mx="s-8">
                    <Stat
                      label="Taken"
                      val={
                        <Flex gap="s-8">
                          {formatDuration(data, 'taken')}
                          {requestTypeBalances?.length ? (
                            <Tooltip
                              forceShow
                              placement="bottom"
                              body={
                                <Ul>
                                  {requestTypeBalances.map(balance => (
                                    <li key={balance.id}>
                                      {balance.name} -{' '}
                                      {pluralize(
                                        data.unit.name.toLowerCase(),
                                        balance.taken,
                                        true,
                                      )}
                                    </li>
                                  ))}
                                </Ul>
                              }
                            >
                              <InfoOutline size={20} color="foreground" />
                            </Tooltip>
                          ) : null}
                        </Flex>
                      }
                    />
                  </Carousel.Item>
                  <Carousel.Item mx="s-8">
                    <Stat
                      label="Accrued"
                      val={formatDuration(data, 'accrued_rounded')}
                      color={Token.color.greyTone50}
                    />
                  </Carousel.Item>
                  <Carousel.Item mx="s-8">
                    <Stat
                      label="Carried over"
                      val={formatDuration(data, 'carried_over')}
                      color={Token.color.greyTone50}
                    />
                  </Carousel.Item>
                </Carousel>
              </Box>
            </Cell>
            <Group>
              <FormPreview data={data}>
                <Group>
                  <FormPreview.Item title="Policy type" field="policy.category.name" />
                  <FormPreview.Item
                    title="Policy name"
                    insert={() => (
                      <Flex alignItems="center" gap="s-8">
                        {data.policy.name}
                        {data.policy.details_url ? (
                          <Link
                            href={data.policy.details_url}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <LinkExternal />
                          </Link>
                        ) : null}
                      </Flex>
                    )}
                  />
                  <FormPreview.Item<EmployeeTimeOffBalancesInterface>
                    title="Cycle"
                    insert={balance => {
                      const endDate = subDays(
                        utcToLocalDate(balance.cycle_end),
                        1,
                      ).toISOString()
                      return formatPeriod(utcToLocalDate(balance.cycle_start), endDate)
                    }}
                  />
                  <FormPreview.Item<EmployeeTimeOffBalancesInterface>
                    title="Status"
                    field="status.name"
                    color={balance => getStatusColor(balance.status.id)}
                  />
                  <FormPreview.Item<EmployeeTimeOffBalancesInterface>
                    title="Balance type"
                    insert={balance =>
                      balance.balance_type?.id === 'limited'
                        ? 'Tracked (limited)'
                        : balance.balance_type?.name
                    }
                  />
                  <FormPreview.Item title="Balance unit" field="unit.name" />
                </Group>
              </FormPreview>
            </Group>

            <Box>
              <Subheader variant="nested">
                <Subheader.Title>Transaction history</Subheader.Title>
              </Subheader>
              <Box mt="-s-32">
                <AdjustableTable
                  name={TableNames.TimeOffBalances}
                  row={row}
                  {...table}
                  useWindowScroll
                  hideCount
                />
              </Box>
            </Box>
          </VStack>
        </PageBody>
      </PageWrapper>

      <AdjustBalanceSidebar
        employeeId={data.employee.id}
        isOpen={adjustBalanceSidebarOpen}
        onClose={() => setAdjustBalanceSidebarOpen(false)}
        onAfterSubmit={() => {
          setAdjustBalanceSidebarOpen(false)
          table.refresh()
          refetch()
        }}
        balance={data}
      />
    </>
  )
}

export default TimeOffBalances
