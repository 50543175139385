import React from 'react'
import { InputGroup } from '@revolut/ui-kit'
import ApplicationFormWidgetSection, {
  ApplicationFormWidgetSectionInterface,
} from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormWidgetSection'
import SalaryExpectationField from '@src/pages/Settings/HiringProcess/components/SalaryExpectationField'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import { FormError } from '@src/features/Form/LapeForm'

export type SalaryQuestionSectionValues = Pick<
  InterviewFeedbackInterface,
  | 'current_salary_currency'
  | 'expected_salary_currency'
  | 'current_salary_frequency'
  | 'expected_salary_frequency'
  | 'current_salary_amount'
  | 'expected_salary_amount'
  | 'additional_information_about_current_salary'
  | 'additional_information_about_expected_salary'
  | 'candidate_declined_to_disclose_current_salary'
  | 'candidate_declined_to_disclose_expected_salary'
>

interface Props extends Partial<ApplicationFormWidgetSectionInterface> {
  optional?: boolean
  onChange?: (val: SalaryQuestionSectionValues) => void
  values?: SalaryQuestionSectionValues
  errors?: FormError<SalaryQuestionSectionValues>
  disabled?: boolean
}

const SalaryQuestionSection = ({
  optional,
  onChange,
  values,
  errors,
  locked,
  disabled,
  ...props
}: Props) => {
  return (
    <ApplicationFormWidgetSection
      titleIndexNumber={2}
      title="Salary expectations"
      locked={locked}
      {...props}
    >
      <InputGroup>
        {/* TODO: until further legal notice */}
        {/* <SalaryExpectationField<InterviewFeedbackInterface> */}
        {/*  label="Current compensation" */}
        {/*  optional={optional} */}
        {/*  onChangeAmount={val => { */}
        {/*    onChange?.({ */}
        {/*      current_salary_amount: val, */}
        {/*    }) */}
        {/*  }} */}
        {/*  onChangeCurrency={val => { */}
        {/*    onChange?.({ */}
        {/*      current_salary_currency: val, */}
        {/*    }) */}
        {/*  }} */}
        {/*  onChangeFrequency={val => { */}
        {/*    onChange?.({ */}
        {/*      current_salary_frequency: val, */}
        {/*    }) */}
        {/*  }} */}
        {/*  onChangeAdditionalCommentary={val => { */}
        {/*    onChange?.({ */}
        {/*      additional_information_about_current_salary: val, */}
        {/*    }) */}
        {/*  }} */}
        {/*  onChangeDeclineToDisclose={val => { */}
        {/*    onChange?.({ */}
        {/*      candidate_declined_to_disclose_current_salary: val, */}
        {/*    }) */}
        {/*  }} */}
        {/*  amount={values?.current_salary_amount} */}
        {/*  currency={values?.current_salary_currency} */}
        {/*  frequency={values?.current_salary_frequency} */}
        {/*  additionalCommentary={values?.additional_information_about_current_salary} */}
        {/*  additionalCommentaryLabel="Additional information about current compensation
        (optional)" */}
        {/*  declinedToDisclose={values?.candidate_declined_to_disclose_current_salary} */}
        {/*  names={{ */}
        {/*    amount: 'current_salary_amount', */}
        {/*    currency: 'current_salary_currency', */}
        {/*    frequency: 'current_salary_frequency', */}
        {/*    additionalCommentary: 'additional_information_about_current_salary', */}
        {/*    declinedToDisclose: 'candidate_declined_to_disclose_current_salary', */}
        {/*  }} */}
        {/*  errors={errors} */}
        {/*  disabled={locked || disabled} */}
        {/* /> */}
        <SalaryExpectationField<InterviewFeedbackInterface>
          label="Desired compensation"
          optional={optional}
          onChangeAmount={val => {
            onChange?.({
              expected_salary_amount: val,
            })
          }}
          onChangeCurrency={val => {
            onChange?.({
              expected_salary_currency: val,
            })
          }}
          onChangeFrequency={val => {
            onChange?.({
              expected_salary_frequency: val,
            })
          }}
          onChangeAdditionalCommentary={val => {
            onChange?.({
              additional_information_about_expected_salary: val,
            })
          }}
          onChangeDeclineToDisclose={val => {
            onChange?.({
              candidate_declined_to_disclose_expected_salary: val,
            })
          }}
          amount={values?.expected_salary_amount}
          currency={values?.expected_salary_currency}
          frequency={values?.expected_salary_frequency}
          additionalCommentary={values?.additional_information_about_expected_salary}
          additionalCommentaryLabel="Additional information about desired compensation (optional)"
          declinedToDisclose={values?.candidate_declined_to_disclose_expected_salary}
          names={{
            amount: 'expected_salary_amount',
            currency: 'expected_salary_currency',
            frequency: 'expected_salary_frequency',
            additionalCommentary: 'additional_information_about_expected_salary',
            declinedToDisclose: 'candidate_declined_to_disclose_expected_salary',
          }}
          errors={errors}
          disabled={locked || disabled}
        />
      </InputGroup>
    </ApplicationFormWidgetSection>
  )
}

export default SalaryQuestionSection
