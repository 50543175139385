import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import { QueryObserverResult } from 'react-query'

import {
  Button,
  Color,
  HStack,
  List,
  SelectOptionItemType,
  Spinner,
  StatusWidget,
  Text,
  VStack,
} from '@revolut/ui-kit'
import { ArrowThinLeft, ExclamationTriangle } from '@revolut/icons'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { useTable } from '@src/components/Table/hooks'
import SuccessWidget from '@src/components/SuccessWidget/SuccessWidget'
import { ROUTES } from '@src/constants/routes'
import { ImportFaqInterface, ImportFaqSessionInterface } from '@src/interfaces/faq'
import {
  importFaqContentColumn,
  importFaqCountriesColumn,
  importFaqQuestionColumn,
  importFaqTopicColumn,
  importFaqTrainingPhrasesColumn,
} from '@src/constants/columns/importFaq'
import {
  getFaqUploadSessionTable,
  submitFaqBulkUpload,
  useFormattedFaqTopicOptions,
  useGetImportFaqSessionData,
} from '@src/api/faq'
import { ImportSessionStatus } from '@src/interfaces/importData'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { navigateReplace } from '@src/actions/RouterActions'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import Tooltip from '@components/Tooltip/Tooltip'

const getOptionDisplayValue = (
  value: string,
  options?: SelectOptionItemType<IdAndName<string>>[],
) => options?.find(opt => opt.value.id === value)?.label || value

interface CellProps {
  data: ImportFaqInterface
  field: string
  options?: SelectOptionItemType<IdAndName<string>>[]
}
const Cell = ({ data, field, options = [] }: CellProps) => {
  const errors = get(data.errors, field)
  const value = get(data, field)
  const hasError = !!errors?.length

  const displayValue = (() => {
    if (!value) {
      return '-'
    }
    if (field === 'countries' && Array.isArray(value)) {
      return value.map((v: string) => getOptionDisplayValue(v, options)).join(', ') || '-'
    }
    if (field === 'topic_id') {
      return getOptionDisplayValue(
        value,
        options as SelectOptionItemType<IdAndName<string>>[],
      )
    }
    if (field === 'training_phrases' && Array.isArray(value)) {
      return (
        <List variant="compact" py="s-8">
          {value.map((phrase: string) => (
            <List.Item key={phrase}>{phrase}</List.Item>
          ))}
        </List>
      )
    }
    return value
  })()

  return (
    <Tooltip text={errors?.join('\n')} placement="top" hide={!hasError} noArrow>
      <HStack width="100%" space="s-8">
        <Text color={hasError ? Color.RED : undefined}>{displayValue}</Text>
        {hasError ? (
          <ExclamationTriangle
            color={Color.RED}
            size={18}
            aria-label={`${field} field error`}
          />
        ) : null}
      </HStack>
    </Tooltip>
  )
}

const row = (
  countriesOptions: SelectOptionItemType<IdAndName<string>>[],
  topicOptions: SelectOptionItemType<IdAndName<string>>[],
): RowInterface<ImportFaqInterface> => ({
  highlight: (data, theme) => {
    return isEmpty(data.errors) ? '' : theme.colors['red-action-background']
  },
  cells: [
    {
      ...importFaqQuestionColumn,
      width: 200,
      insert: ({ data }) => <Cell field="title" data={data} />,
    },
    {
      ...importFaqTopicColumn,
      width: 150,
      insert: ({ data }) =>
        data.topic_id ? (
          <Cell field="topic_id" data={data} options={topicOptions} />
        ) : (
          <Cell field="topic" data={data} options={topicOptions} />
        ),
    },
    {
      ...importFaqCountriesColumn,
      width: 150,
      insert: ({ data }) => (
        <Cell field="countries" data={data} options={countriesOptions} />
      ),
    },
    {
      ...importFaqContentColumn,
      width: 400,
      insert: ({ data }) => <Cell field="content" data={data} />,
    },
    {
      ...importFaqTrainingPhrasesColumn,
      width: 400,
      insert: ({ data }) => <Cell field="training_phrases" data={data} />,
    },
  ],
})

export const QuestionsBulkUploadSession = () => {
  const params = useParams<{ id: string }>()

  const { data, refetch } = useGetImportFaqSessionData(params.id)

  if (!data) {
    return <PageLoading />
  }

  if (data.status.id === ImportSessionStatus.InProgress) {
    return (
      <PageBody>
        <StatusWidget>
          <StatusWidget.Title>
            <VStack align="center" space="s-16" pt="s-16">
              <Spinner color="blue" size={60} />
              <Text>Upload in progress</Text>
            </VStack>
          </StatusWidget.Title>
          <StatusWidget.Description>
            Please wait while the file is being processed
          </StatusWidget.Description>
        </StatusWidget>
      </PageBody>
    )
  }

  if (data.status.id === ImportSessionStatus.Completed) {
    return (
      <>
        <PageBody>
          <SuccessWidget
            title="Upload successful"
            type="success"
            text="FAQ data imported successfully"
            maxWidth="100%"
          />
        </PageBody>

        <PageActions>
          <Button
            use={InternalLink}
            to={pathToUrl(ROUTES.APPS.HELP_CENTER.FAQ_TABLE)}
            elevated
          >
            Done
          </Button>
        </PageActions>
      </>
    )
  }

  if (data.status.id === ImportSessionStatus.Failed) {
    return (
      <PageBody>
        <SuccessWidget
          title="Task failed!"
          type="error"
          text="There was an error with the upload"
          maxWidth="100%"
        />
      </PageBody>
    )
  }

  return <Table sessionData={data} refetchSessionData={refetch} />
}

interface TableProps {
  sessionData: ImportFaqSessionInterface
  refetchSessionData: () => Promise<QueryObserverResult<ImportFaqSessionInterface, Error>>
}
const Table = ({ sessionData, refetchSessionData }: TableProps) => {
  const table = useTable({
    getItems: getFaqUploadSessionTable(sessionData.id),
  })

  const [submitPending, setSubmitPending] = useState(false)

  const hasErrors = useMemo(() => {
    return table.data?.some(r => !isEmpty(r.errors))
  }, [table.data])

  const { options: countriesOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.countries,
    true,
  )
  const topicsOptions = useFormattedFaqTopicOptions({ titleAsName: true })

  const onSubmit = async () => {
    setSubmitPending(true)

    try {
      await submitFaqBulkUpload(sessionData.id)
      await refetchSessionData()
    } finally {
      setSubmitPending(false)
    }
  }

  return (
    <>
      <AdjustableTable
        {...table}
        name={TableNames.ImportFAQ}
        rowHeight="medium"
        hideCountAndButtonSection
        useWindowScroll
        lockFirstColumn={false}
        row={row(
          countriesOptions,
          topicsOptions as SelectOptionItemType<IdAndName<string>>[],
        )}
      />

      <PageActions>
        {hasErrors ? (
          <Button
            useIcon={ArrowThinLeft}
            onClick={() =>
              navigateReplace(pathToUrl(ROUTES.APPS.HELP_CENTER.FAQ_IMPORT.UPLOAD))
            }
            disabled={table.loading}
            elevated
          >
            Go back
          </Button>
        ) : (
          <Button
            onClick={onSubmit}
            pending={submitPending}
            disabled={table.loading}
            elevated
          >
            Upload data
          </Button>
        )}
      </PageActions>
    </>
  )
}
