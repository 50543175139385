import React, { useState } from 'react'
import { Box, Flex, MoreBar } from '@revolut/ui-kit'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  paymentsEmployeeColumn,
  paymentsPaygroupColumn,
  paymentsCycleColumn,
  paymentsElementColumn,
  paymentsDirectionColumn,
  paymentsAmountColumn,
  paymentsStatusColumn,
} from '@src/constants/columns/payments'
import Stat from '@src/components/Stat/Stat'
import { TableWrapper } from '@src/components/Table/TableWrapper'
import { paymentFormRequests, paymentsTableRequests } from '@src/api/payroll'
import { Coins, Delete, Plus, TurboTransfer } from '@revolut/icons'
import AddChargeSidebar from './AddChargeSidebar'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import pluralize from 'pluralize'
import { PayCyclePaymentInterface } from '@src/interfaces/payroll'
import PreviewPaymentSidebar from './PreviewPaymentSidebar'
import { FilterButtons } from './FilterButtons'
import Form from '@src/features/Form/Form'
import { useTableWithPersistentFilters } from '@src/hooks/useTableWithPersistentFilters'

export const PAYMENTS_PERSISTENT_FILTERS_KEY = 'payments-table-filters'

export const PaymentsTable = () => {
  const [isAddSidebarOpen, setIsAddSidebarOpen] = useState(false)
  const [previewSidebarData, setPreviewSidebarData] =
    useState<PayCyclePaymentInterface | null>(null)
  const table = useTableWithPersistentFilters(
    paymentsTableRequests,
    [],
    [],
    {},
    PAYMENTS_PERSISTENT_FILTERS_KEY,
    ['payroll_settlement__reconcilable'],
  )

  const Row: RowInterface<PayCyclePaymentInterface> = {
    linkToForm: data => {
      setPreviewSidebarData(data)
    },
    cells: [
      { ...paymentsEmployeeColumn, width: 150 },
      { ...paymentsPaygroupColumn, width: 150 },
      { ...paymentsCycleColumn, width: 130 },
      { ...paymentsElementColumn, width: 130 },
      { ...paymentsDirectionColumn, width: 100 },
      { ...paymentsAmountColumn, width: 150 },
      { ...paymentsStatusColumn, width: 150 },
    ],
  }

  return (
    <>
      <TableWrapper>
        <Flex mb="s-16" justifyContent="space-between">
          <Flex gap="s-32">
            <Stat
              label={pluralize('Charge', table.stats?.total)}
              val={table.stats?.total}
            />
            <Stat label="Unpaid/Unreconciled" val={table.stats?.unpaid} color="orange" />
            <Stat label="Pending" val={table.stats?.pending} color="orange" />
            <Stat label="Failed" val={table.stats?.failed} color="red" />
            <Stat label="Completed" val={table.stats?.completed} color="green" />
          </Flex>
          <Box>
            <FilterButtons table={table} />
          </Box>
        </Flex>
        <Box mb="s-16">
          <MoreBar>
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.APPS.PAYROLL.SCHEDULE_PAYMENT)}
              useIcon={TurboTransfer}
              disabled
            >
              Pay
            </MoreBar.Action>
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.APPS.PAYROLL.RECONCILE_PAYMENT)}
              useIcon={Coins}
            >
              Reconcile Charges
            </MoreBar.Action>
            <MoreBar.Action useIcon={Plus} onClick={() => setIsAddSidebarOpen(true)}>
              Add charge
            </MoreBar.Action>
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.APPS.PAYROLL.BULK_DELETE_PAYMENTS)}
              variant="negative"
              useIcon={Delete}
            >
              Delete charges
            </MoreBar.Action>
            {/* TODO: <MoreBar.Action useIcon={Download}>Download charges</MoreBar.Action> */}
          </MoreBar>
        </Box>

        <AdjustableTable
          name={TableNames.Payments}
          row={Row}
          useWindowScroll
          {...table}
        />
      </TableWrapper>

      <Form api={paymentFormRequests}>
        <AddChargeSidebar
          isOpen={isAddSidebarOpen}
          onExit={() => setIsAddSidebarOpen(false)}
          onSuccess={() => table.refresh()}
        />
      </Form>
      <PreviewPaymentSidebar
        data={previewSidebarData}
        onExit={() => setPreviewSidebarData(null)}
        onAfterDelete={() => table.refresh()}
      />
    </>
  )
}
