import React from 'react'
import isString from 'lodash/isString'
import {
  Avatar,
  Flex,
  Text,
  Skeleton,
  themeTextStyle,
  AvatarSize,
  Box,
  AvatarBadgeProps,
  Token,
} from '@revolut/ui-kit'
import { LayoutProps } from 'styled-system'
import TableCellLink from '@components/TableCellLink/TableCellLink'
import { pathToUrl, pathToUrlWithBaseUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { IdStatuses, AvatarType } from '@src/interfaces/employees'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import Tooltip, { placement } from '@components/Tooltip/Tooltip'
import { getInitials, getAvatarUrl } from '@src/utils/employees'
import type { SpaceProps } from '@revolut/ui-kit/types/dist/styles'

const TooltipLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  justify-self: start;
`

const TooltipAvatar = styled(Tooltip)`
  justify-content: flex-start;
  width: 24px;
`

const AvatarAsTextCss = css`
  pointer-events: none;
  color: ${Token.color.foreground};
  ${themeTextStyle('primary')}
`

export interface UserWithAvatarProps extends LayoutProps, SpaceProps {
  avatar?: AvatarType
  name?: string
  id?: string | number
  full_name?: string
  status?: IdStatuses
  isLoading?: boolean
  compact?: boolean
  tooltipPlacement?: placement
  /** Disables the link to the employee's profile */
  asText?: boolean
  size?: AvatarSize
  usePathWithBaseUrl?: boolean
  disableLink?: boolean // asText does the same but also changes styles which is not always needed
  badgeProps?: AvatarBadgeProps
}

const UserWithAvatar = ({
  id,
  name,
  full_name,
  avatar,
  isLoading,
  status,
  compact,
  tooltipPlacement = 'top',
  asText,
  size,
  usePathWithBaseUrl,
  disableLink,
  badgeProps,
  ...flexProps
}: UserWithAvatarProps) => {
  if (isLoading) {
    return (
      <Flex alignItems="center" {...flexProps}>
        <Skeleton height={24} width={150} />
      </Flex>
    )
  }

  const pathToUrlHandler = usePathWithBaseUrl ? pathToUrlWithBaseUrl : pathToUrl
  const linkTarget = usePathWithBaseUrl ? '_blank' : undefined

  const nameString = full_name || name

  if (!nameString || !isString(nameString)) {
    return <>-</>
  }

  const initials = getInitials(nameString)

  const isTerminated = status === IdStatuses.terminated
  const displayName =
    nameString + (status === IdStatuses.terminated ? ' · Deactivated' : '')

  const nameJSX = (
    <Text color={isTerminated ? 'grey-tone-50' : undefined}>{displayName}</Text>
  )
  const avatarJSX = (
    <Box mr="s-8">
      <Avatar
        size={size || 24}
        image={getAvatarUrl(avatar)}
        opacity={isTerminated ? 0.5 : 1}
      >
        {!avatar && initials}
        {badgeProps && <Avatar.Badge {...badgeProps} />}
      </Avatar>
    </Box>
  )

  return (
    <Flex
      alignItems="center"
      data-testid={`avatar-${initials}`}
      css={asText ? AvatarAsTextCss : undefined}
      {...flexProps}
    >
      {compact ? (
        <TooltipAvatar placement={tooltipPlacement} text={displayName}>
          <TooltipLink
            to={getLocationDescriptor(
              pathToUrlHandler(ROUTES.FORMS.EMPLOYEE.PROFILE, { id }),
            )}
            target={linkTarget}
          >
            {avatarJSX}
          </TooltipLink>
        </TooltipAvatar>
      ) : (
        <>
          {avatarJSX}
          {id && !disableLink ? (
            <TableCellLink
              to={getLocationDescriptor(
                pathToUrlHandler(ROUTES.FORMS.EMPLOYEE.PROFILE, { id }),
              )}
              target={linkTarget}
            >
              {nameJSX}
            </TableCellLink>
          ) : (
            nameJSX
          )}
        </>
      )}
    </Flex>
  )
}

export default UserWithAvatar
