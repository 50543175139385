import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { NotificationTypes } from '@src/store/notifications/types'
import { RevolutTheme } from '@src/styles/theme'
import { Flex, Text, IconButton, Token } from '@revolut/ui-kit'
import { Cross } from '@revolut/icons'
import { typeToHighlightColor } from '@components/Notification/common'
import { NotificationIcon } from '@components/Notification/NotificationIcon'

const SHOW_NOTIFICATIONS_FOR = 3000

const OpacityAppear = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  
  100% {
    opacity: 1;
    transform: scale(1.0);
  }
`

const typeToColor = (theme: RevolutTheme) => ({
  success: theme.colors['green-opaque-10'],
  warning: theme.colors['terracotta-opaque-10'],
  error: theme.colors['red-opaque-10'],
  info: theme.colors.background,
})

const Wrapper = styled.div<{ type: NotificationTypes }>`
  margin-top: 16px;
  display: grid;
  max-height: 50vh;
  max-width: 95vw;
  grid-template-columns: auto 1fr auto;
  grid-auto-flow: column;
  gap: 16px;
  padding: 16px;
  animation-name: ${OpacityAppear};
  animation-fill-mode: forwards;
  animation-duration: 200ms;
  animation-timing-function: ease-in-out;
  color: ${Token.color.foreground};
  background: ${({ type, theme }) => typeToColor(theme)[type]};
  border: 1px solid ${({ type, theme }) => typeToHighlightColor(theme)[type]};
  border-radius: 12px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
`

interface DialogProps {
  children: React.ReactNode
  /** Callback for when you click X button */
  onClose?: () => void
  /** Controlling the visibility of the component */
  open: boolean
  /** Type of the notification (success, warning, error) */
  type: NotificationTypes
  /** Duration of time after which the notification auto hides */
  autoHideDuration?: number
}

const Notification = ({
  open,
  onClose,
  children,
  type,
  autoHideDuration,
}: DialogProps) => {
  const [timer, setTimer] = useState<any>()
  useEffect(() => {
    setTimer(
      setTimeout(() => {
        onClose && onClose()
      }, autoHideDuration || SHOW_NOTIFICATIONS_FOR),
    )

    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (!open) {
    return null
  }

  const handleMouseEnter = () => {
    clearTimeout(timer)
  }

  return (
    <Wrapper type={type} onMouseEnter={handleMouseEnter}>
      <NotificationIcon type={type} />
      <Flex alignItems="center">
        <Text
          style={{
            wordBreak: 'break-word',
          }}
        >
          {children}
        </Text>
      </Flex>
      <IconButton useIcon={Cross} aria-label="Close" onClick={onClose} />
    </Wrapper>
  )
}

export default Notification
