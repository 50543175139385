import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Flex,
  TableWidget,
  Token,
  Box,
  Placeholder,
  Tag,
  HStack,
  FilterButton,
} from '@revolut/ui-kit'
import { ListBullet, ViewGrid } from '@revolut/icons'

import { api } from '@src/api'
import { API } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import { EmployeeInterface } from '@src/interfaces/employees'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { EmployeeDocumentInterface } from '@src/interfaces/documents'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  CellTypes,
  FetchDataQueryInterface,
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { getEmployeeDocuments } from '@src/api/accessRequests'
import {
  documentCategory,
  documentIssueDateTime,
  documentIssuer,
  documentName,
  documentSigningDateTime,
  documentSource,
  documentStatus,
} from '@src/constants/columns/documents'
import { useGetEmployeeDocumentsCategories } from '@src/api/documents'
import Stat from '@src/components/Stat/Stat'
import { PreviewSidebar } from './PreviewSidebar'
import { FoldersByCategories } from './FoldersByCategories'
import { ActionButtons } from './common'

const getRows = (
  employee: EmployeeInterface,
  fixedCategory?: boolean,
): RowInterface<EmployeeDocumentInterface> => ({
  cells: [
    {
      ...documentName,
      width: 300,
      selectorsKey: async () => {
        const result = await api.get(`${API.EMPLOYEES}/${employee.id}/documentNames`)
        if (result.data) {
          return result.data as { options: OptionInterface[] }
        }
        return { options: [] }
      },
    },
    {
      ...documentCategory,
      type: CellTypes.insert,
      insert: ({ data }) => (
        <Tag variant="faded">{data.category?.name || 'unknown category'}</Tag>
      ),
      width: 200,
      ...(fixedCategory ? { filterKey: null, sortKey: null } : null),
    },
    {
      ...documentSource,
      width: 150,
    },
    {
      ...documentIssueDateTime,
      width: 100,
    },
    {
      ...documentSigningDateTime,
      width: 180,
    },
    {
      ...documentIssuer,
      width: 250,
    },
    {
      ...documentStatus,
      width: 200,
    },
  ],
})

type Props = {
  data: EmployeeInterface
  mode?: 'tab' | 'page'
}
export const DocumentsLayoutTab = ({ data, mode = 'tab' }: Props) => {
  const { categoryId } = useParams<{ categoryId?: string }>()

  const [document, setDocument] = useState<EmployeeDocumentInterface>()

  const { data: documentCategories, isLoading: isLoadingCategories } =
    useGetEmployeeDocumentsCategories(data.id)

  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs: ['tiles', 'table'],
    useIcons: { tiles: ViewGrid, table: ListBullet },
    defaultTab: 'tiles',
    highlightSelected: false,
    onTabChange: tab => {
      if (tab === 'tiles') {
        clearOwnDocsFilter()
      } else if (tab === 'table') {
        setOwnDocsFilter()
      }
    },
  })

  const sortByInitial = [
    {
      sortBy: 'status',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const ownDocsFilter = {
    columnName: 'apply_additional_own_filters',
    filters: [{ id: 'true', name: 'true' }],
  }

  const getFilterByInitial = () => {
    const filters: FilterByInterface[] = currentTab === 'table' ? [ownDocsFilter] : []
    if (categoryId) {
      filters.push({
        columnName: 'category__id',
        filters: [
          {
            id: categoryId,
            name: categoryId,
          },
        ],
        nonResettable: true,
      })
    }
    return filters
  }

  const table = useTable<EmployeeDocumentInterface>(
    {
      getItems: (requestData: FetchDataQueryInterface) =>
        getEmployeeDocuments(requestData, data.id),
    },
    getFilterByInitial(),
    sortByInitial,
    { disable: isLoadingCategories },
  )

  const isFilteredByOwn = table.filterBy.some(
    filteredBy => filteredBy.columnName === 'apply_additional_own_filters',
  )

  const setOwnDocsFilter = () => table.onFilterChange(ownDocsFilter)

  const clearOwnDocsFilter = () => {
    if (isFilteredByOwn) {
      table.resetFiltersAndSorting(
        table.filterBy.filter(f => f.columnName !== 'apply_additional_own_filters'),
        table.sortBy,
      )
    }
  }

  const selectedCategory = documentCategories?.options.find(
    option => option.category_id === Number(categoryId),
  )
  const totalPending = categoryId
    ? selectedCategory?.documents_pending
    : documentCategories?.options.reduce((total, category) => {
        return total + category.documents_pending
      }, 0)
  const isLayoutTabView = mode === 'tab'
  const isTableMode = isLayoutTabView ? currentTab === 'table' : true

  const emptyState = (
    <Placeholder>
      <Placeholder.Image
        src="https://assets.revolut.com/assets/3d-images/3D086.png"
        srcSet="https://assets.revolut.com/assets/3d-images/3D086@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D086@3x.png 3x"
      />
      <Placeholder.Title>Employee documents not found</Placeholder.Title>
    </Placeholder>
  )

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <HStack gap="s-24">
            <Stat val={table.loading ? undefined : table.count} label="Total" />
            <Stat
              color={Token.color.warning}
              val={isLoadingCategories ? undefined : totalPending}
              label="Pending"
            />
          </HStack>
        </TableWidget.Info>
        {isLayoutTabView && (
          <TableWidget.Search>
            <Flex flex={1} justifyContent="flex-end">
              {tabBar}
            </Flex>
          </TableWidget.Search>
        )}
        <TableWidget.Actions>
          <ActionButtons data={data} showSecondary={isLayoutTabView} />
        </TableWidget.Actions>
        {isTableMode && (
          <TableWidget.Filters>
            <FilterButton
              active={isFilteredByOwn}
              onClick={() => {
                if (isFilteredByOwn) {
                  clearOwnDocsFilter()
                } else {
                  setOwnDocsFilter()
                }
              }}
            >
              My documents
            </FilterButton>
          </TableWidget.Filters>
        )}
        <TableWidget.Table>
          {isTableMode && (
            <AdjustableTable<EmployeeDocumentInterface>
              name={TableNames.EmployeeDocuments}
              useWindowScroll
              dataType="Document"
              row={getRows(data, !!categoryId)}
              {...table}
              noDataMessage="All employee documents will appear here"
              emptyState={emptyState}
              onRowClick={selectedDocument => {
                setDocument(selectedDocument)
              }}
            />
          )}
          {isLayoutTabView && currentTab === 'tiles' && (
            <Box mt="s-12">
              <FoldersByCategories
                isLoading={isLoadingCategories}
                categories={documentCategories?.options}
                emptyState={emptyState}
                onClickCategory={id => {
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS_BY_CATEGORY, {
                      employeeId: data.id,
                      categoryId: id,
                    }),
                  )
                }}
              />
            </Box>
          )}
        </TableWidget.Table>
      </TableWidget>
      <PreviewSidebar
        employeeId={data.id}
        document={document}
        onClose={() => setDocument(undefined)}
      />
    </>
  )
}
