import React from 'react'
import { Switch, Text } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'

type Props = {
  disabled: boolean
  checked: boolean
  onChange: (value: boolean) => void
}

const OrderingModeCheckbox = ({ disabled, checked, onChange }: Props) => {
  return (
    <Tooltip
      placement="top"
      hide={!disabled}
      text="Prioritisation not possible when filters are applied. Clear filters to activate prioritisation"
    >
      <Switch
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        disabled={disabled}
      >
        <Text color="blue" ml="-10px">
          Edit priorities
        </Text>
      </Switch>
    </Tooltip>
  )
}

export default OrderingModeCheckbox
