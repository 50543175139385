import React, { useState } from 'react'
import { PageBody } from '@components/Page/PageBody'
import { useParams } from 'react-router-dom'
import { deleteCandidateDocument, useGetCandidateDocuments } from '@src/api/hiringProcess'
import { Group, Item, ItemSkeleton, MoreBar, Box, StatusPopup } from '@revolut/ui-kit'
import { InfoOutline, Plus } from '@revolut/icons'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SettingsButtons from '@src/features/SettingsButtons'
import PreviewDocumentSidebar from '@src/features/PreviewDocumentSidebar/PreviewDocumentSidebar'
import { FileInterface } from '@src/interfaces/files'
import DocumentItem from '@src/pages/Forms/Candidate/Documents/DocumentItem'
import { deleteFile } from '@src/api/files'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import CVPreviewSidebar from '@src/features/CVPreviewSidebar/CVPreviewSidebar'

type Props = {
  refreshStats: () => void
  onOpenSidebar: () => void
}

const Documents = ({ refreshStats, onOpenSidebar }: Props) => {
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(false)
  const [fileForDelete, setFileForDelete] = useState<{
    id: number
    name: string
  }>()
  const [successDeleteName, setSuccessDeleteName] = useState<string>()
  const [openFile, setOpenFile] = useState<FileInterface>()
  const { data, isLoading, refetch } = useGetCandidateDocuments(id)

  return (
    <>
      <StatusPopup
        variant="success"
        open={!!successDeleteName}
        onClose={() => setSuccessDeleteName(undefined)}
      >
        <StatusPopup.Title>{successDeleteName} deleted successfully</StatusPopup.Title>
      </StatusPopup>
      <ConfirmationDialog
        label={`Are you sure you want to delete ${fileForDelete?.name || 'the file'}?`}
        body="You will not be able to retrieve this document once you delete."
        open={fileForDelete !== undefined}
        onClose={() => setFileForDelete(undefined)}
        submitDisabled={loading}
        onConfirm={async () => {
          if (!fileForDelete) {
            return
          }

          setLoading(true)

          try {
            await deleteCandidateDocument(id, fileForDelete.id)
            refetch()
            deleteFile(fileForDelete.id)
            setFileForDelete(undefined)
            setSuccessDeleteName(fileForDelete.name)
            refreshStats()
          } finally {
            setLoading(false)
          }
        }}
        onReject={() => setFileForDelete(undefined)}
        yesMessage="Delete"
        noMessage="Go back"
      />
      {openFile && (
        <>
          {openFile.category?.id === 'candidate_resume' ? (
            <CVPreviewSidebar
              isOpen
              file={openFile}
              onClose={() => setOpenFile(undefined)}
            />
          ) : (
            <PreviewDocumentSidebar
              file={openFile}
              onClose={() => setOpenFile(undefined)}
            />
          )}
        </>
      )}
      <PageBody>
        <SettingsButtons mb="s-16">
          <MoreBar.Action
            useIcon={Plus}
            onClick={() =>
              navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE_ADD_DOCUMENT, { id }))
            }
            data-testid="Add new"
          >
            Add new
          </MoreBar.Action>
        </SettingsButtons>
        <Box>
          {isLoading && <ItemSkeleton />}

          <Group>
            {data?.map(({ file, name }) => (
              <DocumentItem
                key={file.id}
                title={name || file.name}
                owner={file.created_by}
                date={file.created_date_time}
                onOpen={() => {
                  onOpenSidebar()
                  setOpenFile(file)
                }}
                onDelete={() =>
                  setFileForDelete({
                    id: file.id,
                    name: name || file.name,
                  })
                }
                disabled={loading}
              />
            ))}
          </Group>

          {!isLoading && !data?.length && (
            <Item useIcon={InfoOutline}>
              <Item.Content>
                <Item.Title color="grey-tone-50">No documents were added</Item.Title>
              </Item.Content>
            </Item>
          )}
        </Box>
      </PageBody>
    </>
  )
}

export default Documents
