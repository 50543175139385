import React, { useEffect, useState } from 'react'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { GoalsPreviewHeader } from './Header'
import { GoalsPreviewLeftSide } from './LeftSide'
import { GoalsPreviewRightSide } from './RightSide'
import { PageWrapper } from '@src/components/Page/Page'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { KpiInterface } from '@src/interfaces/kpis'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'

const getCycleFilter = (kpis: KpiInterface[]) => {
  const kpiCycles = kpis
    .map(kpi => {
      return kpi.target_epics?.[0]
        ? kpi.target_epics[0].review_cycle?.id
        : kpi.targets?.[0].review_cycle?.id
    })
    .filter(Boolean)
  return (cycle: ReviewCyclesInterface): boolean => kpiCycles.includes(cycle.id)
}

export const GoalPreviewPage = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const [cycle, setCycle] = useState<ReviewCyclesInterface>()

  const { data: reviewCycles } = useGetReviewCycles()

  const cycleFilter = getCycleFilter(values.kpis)

  const availableCycles = reviewCycles?.results.filter(cycleFilter) || []

  useEffect(() => {
    if (!cycle && availableCycles && availableCycles.length) {
      const initialCycle =
        availableCycles.find(({ offset }) => offset === 0) || availableCycles[0]
      setCycle(initialCycle)
    }
  }, [availableCycles])

  return (
    <PageWrapper>
      <GoalsPreviewHeader />
      {cycle ? (
        <TwoColumnsLayout
          leftFlex={3}
          rightFlex={2}
          left={
            <GoalsPreviewLeftSide
              cycle={cycle}
              availableCycles={availableCycles}
              onCycleChanged={id => setCycle(availableCycles?.find(c => c.id === id))}
            />
          }
          right={<GoalsPreviewRightSide cycle={cycle} />}
        />
      ) : null}
    </PageWrapper>
  )
}
