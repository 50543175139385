import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { chain, Text } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeContractsInterface } from '@src/interfaces/employees'
import { useTheme } from '@src/styles/theme'
import { getContractStatusColor } from '@src/constants/columns/contracts'
import { LapeFormInterface } from '@src/features/Form/LapeForm'

export const useContractHeaderProps = (
  form: LapeFormInterface<EmployeeContractsInterface> | undefined,
) => {
  const { employeeId } = useParams<{ employeeId: string }>()
  const theme = useTheme()
  const isPreview = useRouteMatch(ROUTES.FORMS.EMPLOYEE_CONTRACT.PREVIEW)
  const isEdit = useRouteMatch(ROUTES.FORMS.EMPLOYEE_CONTRACT.GENERAL)
  const isChangelog = useRouteMatch(ROUTES.FORMS.EMPLOYEE_CONTRACT.CHANGELOG)

  if (!form) {
    return { backUrl: '' }
  }
  const { values } = form

  if (isPreview || isChangelog) {
    return {
      title: chain(
        values.title,
        values.contract_status && (
          <Text color={getContractStatusColor(values.contract_status.id, theme)}>
            {values.contract_status.name}
          </Text>
        ),
      ),
      backUrl: pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, { id: employeeId }),
    }
  }
  if (isEdit) {
    return {
      isEdit: true,
      title: values.id ? 'Edit contract' : 'Create new contract',
      backUrl: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CONTRACT, {
        id: employeeId,
      }),
    }
  }
  return { backUrl: '' }
}
