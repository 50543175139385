import React from 'react'
import { Box, Token, useTooltip, Tooltip, Icon } from '@revolut/ui-kit'

export const ExceedingExpectationTooltip = () => {
  const tooltip = useTooltip()

  return (
    <Box>
      <Icon
        name="ExclamationMarkOutline"
        size={20}
        {...tooltip.getAnchorProps()}
        color={Token.color.warning}
      />
      <Tooltip {...tooltip.getTargetProps()} placement="left" width={270}>
        This rating is above the expectation. Please add your justification below.
      </Tooltip>
    </Box>
  )
}
