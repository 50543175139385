import React from 'react'
import { VStack } from '@revolut/ui-kit'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { CareerLink } from '@src/pages/Settings/CareerPage/General'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { pathToUrl } from '@src/utils/router'
import CareersPagePreview from '@src/pages/Careers/Preview'

const CareersPagePublish = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCareerPagePreferences)
  return (
    <VStack gap="s-16">
      <CareersPagePreview />
      <SectionTitle
        title="Do you want to publish career website?"
        subtitle={
          <>
            You’ll be able to access page at{' '}
            <CareerLink
              // @ts-expect-error object works fine here, but UI kit expects string
              to={pathToUrl(PUBLIC_ROUTES.CAREERS.JOB_LIST)}
              target="_blank"
              color="inherit"
            >
              {window.location.hostname}
              {PUBLIC_ROUTES.CAREERS.JOB_LIST}
            </CareerLink>
            . <br /> All the published Job Postings will appear there.
          </>
        }
      />
      <LapeBooleanRadioSwitch
        name="publish_career_website"
        variant="horizontal"
        disabled={disableEdit}
        yesLabel={
          <>
            Yes <Recommended />
          </>
        }
        yesDescription="Make the page visible for candidates."
        noDescription="I do not want to publish my careers website right now, will decide later"
      />
      <SectionTitle
        title="Data Privacy Notice"
        subtitle="Link your Data Privacy Notice for candidates. It will be placed in the footer of the application page and added to each job posting. Please be aware that a Privacy Notice explaining how personal data is processed may be required"
      />
      <LapeNewInput
        name="data_privacy_notice_url"
        placeholder="Data privacy notice URL"
        disabled={disableEdit}
      />
    </VStack>
  )
}

export default CareersPagePublish
