import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTable } from '@components/Table/hooks'
import { CalibratedGradeInterface, UploadStatus } from '@src/interfaces/supportTool'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  getCalibratedGrades,
  getCalibratedGradesExport,
  getCalibratedGradesStats,
  getUploadStatus,
  syncDisplayGrades,
  syncEligibilityEmployees,
  updateGrade,
  UpdateGradesRequestData,
} from '@src/api/supportTool/calibratedGrades'
import {
  calibratedContributorTypeColumn,
  calibratedDeliverablesRatingColumn,
  calibratedEligibilityStatusColumn,
  calibratedGradeCycleColumn,
  calibratedGradeDisplayGradeColumn,
  calibratedGradeEmployeeNameColumn,
  calibratedHodGradeColumn,
  calibratedHofGradeColumn,
  calibratedOverallRatingColumn,
  calibratedSkillsRatingColumn,
  calibrationNeededColumn,
  commentColumn,
} from '@src/constants/columns/supportTool/calibratedGrade'
import { selectorKeys } from '@src/constants/api'
import { getSelectors } from '@src/api/selectors'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import UploadPopup, {
  UploadPopupType,
} from '@src/features/Popups/supportTool/UploadPopup'
import { useDispatch, useSelector } from 'react-redux'
import { startUpload } from '@src/store/performance/actions'
import { selectPerformanceGradesUploading } from '@src/store/performance/selectors'
import { RadioOptionInterface } from '@components/Inputs/RadioButtons/RadioButtons'
import Loader from '@components/CommonSC/Loader'
import { ratingToColor, useSelectedPerformanceCycle } from '@src/utils/performance'
import {
  Flex,
  MoreBar,
  StatusPopup,
  Text,
  Tooltip as UIKitTooltip,
  useTooltip,
  Widget,
} from '@revolut/ui-kit'
import { FinalGrade, PerformanceRating } from '@src/interfaces/performance'
import produce from 'immer'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { ArrowExchange, ShareIOs } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import CalibratedGradeColumn from '@src/features/Calibration/CalibratedGradeColumn'
import PerformanceRatingSelect from '@src/features/Talent/PerformanceRatingSelect'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { filterSortPageIntoQuery } from '@src/utils/table'
import CalibrationAction from '@src/features/Talent/CalibrationAction'
import AddCalibrationGradePopup from '@src/features/Popups/AddCalibrationGradePopup'
import { TableNames } from '@src/constants/table'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { OptionInterface } from '@src/interfaces/selectors'
import { pushError, successNotification } from '@src/store/notifications/actions'

type FieldType = 'hod' | 'hof' | 'deliverables' | 'skills' | 'rating' | 'grade'

const ROW = (
  grades: RadioOptionInterface[],
  onChangeField: (
    type: FieldType,
    id: number,
    value?: FinalGrade | PerformanceRating,
  ) => void,
  setCommentPopup: (params: {
    id: number
    comment?: string | null
    canChange?: boolean
  }) => void,
): RowInterface<CalibratedGradeInterface> => ({
  cells: [
    {
      ...calibratedGradeEmployeeNameColumn,
      width: 170,
    },
    {
      ...calibratedGradeCycleColumn,
      width: 80,
    },
    {
      ...calibratedContributorTypeColumn,
      width: 80,
    },
    {
      ...calibratedEligibilityStatusColumn,
      width: 80,
    },
    {
      ...calibratedDeliverablesRatingColumn,
      width: 120,
    },
    {
      ...calibratedSkillsRatingColumn,
      width: 120,
    },
    {
      ...calibratedHodGradeColumn,
      width: 150,
      insert: ({ data }) => (
        <CalibratedGradeColumn
          value={data.department_owner_grade_suggestion}
          comment={data.department_owner_grade_suggestion_comment}
          grades={grades}
          onChange={value => {
            onChangeField('hod', data.id, value)
          }}
          readOnly={data?.field_options?.read_only?.includes(
            'department_owner_grade_suggestion',
          )}
        />
      ),
    },
    {
      ...calibratedHofGradeColumn,
      width: 150,
      insert: ({ data }) => (
        <CalibratedGradeColumn
          value={data.function_owner_grade_suggestion}
          comment={data.function_owner_grade_suggestion_comment}
          grades={grades}
          onChange={value => {
            onChangeField('hof', data.id, value)
          }}
          readOnly={data?.field_options?.read_only?.includes(
            'function_owner_grade_suggestion',
          )}
        />
      ),
    },
    {
      ...calibratedOverallRatingColumn,
      width: 150,
      background: (data, theme) =>
        ratingToColor(theme, data.absolute_rating_label) || 'inherit',
      insert: ({ data }) => (
        <PerformanceRatingSelect
          onChange={value => {
            onChangeField('rating', data.id, value)
          }}
          value={data.absolute_rating_label}
          readOnly={data?.field_options?.read_only?.includes('absolute_rating_label')}
        />
      ),
    },
    {
      ...calibratedGradeDisplayGradeColumn,
      width: 150,
      insert: ({ data }) => (
        <CalibratedGradeColumn
          value={data.display_grade}
          grades={grades}
          onChange={value => {
            onChangeField('grade', data.id, value)
          }}
          readOnly={data?.field_options?.read_only?.includes('display_grade')}
        />
      ),
    },
    {
      ...calibrationNeededColumn,
      width: 50,
    },
    {
      ...commentColumn,
      width: 60,
      insert: ({ data }) => {
        const canChange = !data?.field_options?.read_only?.includes(
          'performance_team_comment',
        )

        return (
          <CalibrationAction
            comment={data.performance_team_comment}
            onOpen={() => {
              setCommentPopup({
                id: data.id,
                comment: data.performance_team_comment,
                canChange,
              })
            }}
            canChange={canChange}
          />
        )
      },
    },
  ],
})

const GradeCalibrationContent = ({
  initialCycleOffset,
}: {
  initialCycleOffset: number
}) => {
  const [grades, setGrades] = useState<RadioOptionInterface[]>([])
  const [showUploadPopup, setShowUploadPopup] = useState(false)
  const [displayGradesPending, setDisplayGradesPending] = useState(false)
  const [employeesSyncPending, setEmployeesSyncPending] = useState(false)
  const [countCalibrationNeeded, setCountCalibrationNeeded] = useState<number>()
  const [commentPopup, setCommentPopup] = useState<{
    id: number
    comment?: string | null
    canChange?: boolean
  }>()
  const dispatch = useDispatch()
  const isUploading = useSelector(selectPerformanceGradesUploading)
  const mountRef = useRef(false)
  const syncGradesTooltip = useTooltip()
  const syncEmployeesTooltip = useTooltip()

  const initialFilterBy = [
    {
      filters: [{ id: initialCycleOffset, name: `${initialCycleOffset}` }],
      columnName: 'cycle__offset',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'absolute_rating_score__value',
      direction: SORT_DIRECTION.ASC,
      nonResettable: true,
    },
  ]
  const table = useTable<CalibratedGradeInterface, UploadStatus>(
    { getItems: params => getCalibratedGrades(params), getStats: getUploadStatus() },
    initialFilterBy,
    initialSortBy,
  )

  const [checkedFlagged, setCheckedFlagged] = useState(
    table.filterBy.find(item => item.columnName === 'grade_calibration_needed')
      ?.filters?.[0]?.id === 'True',
  )

  const filteredFilters = useMemo(
    () =>
      table.filterBy
        ?.find((filterBy: FilterByInterface) => filterBy.columnName === 'cycle__offset')
        ?.filters.filter(option => option.id !== '' && option.name !== ''),
    [table.filterBy],
  )
  const { options: cycleOptions } = useFetchOptions<OptionInterface>(
    selectorKeys.cycle_offsets,
  )
  const filteredCycle = cycleOptions
    ?.map(option => option.value)
    .find(reviewCycle => String(reviewCycle.id) === String(filteredFilters?.[0].id))
  const isCurrentCycleFiltered =
    filteredFilters?.length === 1 && filteredCycle?.performance_reviews_selected_cycle

  const onFlaggedFilterChange = () => {
    table.onFilterChange({
      columnName: 'grade_calibration_needed',
      filters: !checkedFlagged
        ? [
            {
              id: 'True',
              name: 'True',
            },
          ]
        : [],
    })

    setCheckedFlagged(!checkedFlagged)
  }

  useEffect(() => {
    const fetchGrades = async () => {
      const result = await getSelectors(selectorKeys.performance_grades)
      const options = result.data.options as RadioOptionInterface[]
      setGrades(options)
    }

    fetchGrades()
  }, [])

  useEffect(() => {
    if (mountRef.current && isUploading === false) {
      table.refresh()
    } else {
      mountRef.current = true
    }
  }, [isUploading])

  useEffect(() => {
    const fetchCalibrationNeeded = async () => {
      const resp = await getCalibratedGradesStats({ filters: table.filterBy })
      setCountCalibrationNeeded(resp.data.calibration_needed_employees_count)
    }

    fetchCalibrationNeeded()
  }, [table.data])

  useEffect(() => {
    if (table.stats?.is_upload_task_running) {
      dispatch(startUpload())
    }
  }, [table.stats?.is_upload_task_running])

  const handleSubmit = () => {
    setShowUploadPopup(false)
    dispatch(startUpload())
  }

  const updateAfterChangingField = (
    id: number,
    responseData: CalibratedGradeInterface,
  ) => {
    if (responseData) {
      const updatedData = produce(table.data, draft => {
        const idx = draft.findIndex(item => item.id === id)

        if (idx !== -1) {
          draft[idx] = responseData
        }
      })

      table.setData(updatedData)
    }
  }

  const onChangeField = async (
    type: FieldType,
    id: number,
    value?: FinalGrade | PerformanceRating,
  ) => {
    const requestData: UpdateGradesRequestData = {
      department_owner_grade_suggestion: undefined,
      function_owner_grade_suggestion: undefined,
      deliverables_rating_label: undefined,
      functional_skills_label: undefined,
      absolute_rating_label: undefined,
      display_grade: undefined,
    }

    const upd = produce(table.data, draft => {
      const row = draft.find(item => item.id === id)
      const valueWithEmptyOption =
        value === FinalGrade.Empty ? FinalGrade.None : (value as FinalGrade)
      if (row) {
        switch (type) {
          case 'hod': {
            row.department_owner_grade_suggestion = value as FinalGrade
            requestData.department_owner_grade_suggestion = valueWithEmptyOption
            break
          }
          case 'hof': {
            row.function_owner_grade_suggestion = value as FinalGrade
            requestData.function_owner_grade_suggestion = valueWithEmptyOption
            break
          }

          case 'deliverables': {
            row.deliverables_rating_label = value as PerformanceRating
            requestData.deliverables_rating_label = value as PerformanceRating
            break
          }

          case 'skills': {
            row.functional_skills_label = value as PerformanceRating
            requestData.functional_skills_label = value as PerformanceRating
            break
          }

          case 'rating': {
            row.absolute_rating_label = value as PerformanceRating
            requestData.absolute_rating_label = value as PerformanceRating
            break
          }

          case 'grade': {
            row.display_grade = value as FinalGrade
            requestData.display_grade = valueWithEmptyOption
            break
          }
        }
      }
    })
    table.setData(upd)

    try {
      const response = await updateGrade(id, requestData)
      updateAfterChangingField(id, response.data)
    } catch (e) {
      table.refresh()
    }
  }

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const changeComment = async (id: number, comment?: string) => {
    try {
      table.setData(
        produce(table.data, draft => {
          const found = draft.find(item => item.id === id)

          if (found) {
            found.performance_team_comment = comment
          }
        }),
      )

      await updateGrade(id, { performance_team_comment: comment })
    } catch (e) {
      table.refresh()
    }
  }

  const onClickSyncDisplayGrades = async () => {
    if (isCurrentCycleFiltered && filteredCycle?.cycle_id) {
      setDisplayGradesPending(true)
      try {
        const gradesIds = table.data.map(el => el.id)
        await syncDisplayGrades(filteredCycle.cycle_id, gradesIds)
        successNotification('Grade recalculation triggered in the background')
      } finally {
        setDisplayGradesPending(false)
      }
    }
  }

  const syncEmployees = async () => {
    if (filteredCycle) {
      try {
        setEmployeesSyncPending(true)
        await syncEligibilityEmployees(filteredCycle.cycle_id!)
        successNotification('Eligibility sync triggered in the background')
      } catch (err) {
        pushError(err)
      } finally {
        setEmployeesSyncPending(false)
      }
    }
  }

  return (
    <>
      {commentPopup && (
        <AddCalibrationGradePopup
          onSubmit={async (commentValue?: string) => {
            await changeComment(commentPopup.id, commentValue)
            setCommentPopup(undefined)
          }}
          comment={commentPopup.comment}
          disabled={!commentPopup.canChange}
          onClose={() => setCommentPopup(undefined)}
        />
      )}
      <Widget p="s-16">
        <CycleFilter
          onFilterChange={table.onFilterChange}
          columnName="cycle__offset"
          filter={table.filterBy}
          type={CycleFilterType.NewUI}
        />
        <Flex alignItems="center" my="s-16">
          <MoreBar>
            <SwitchButton checked={checkedFlagged} onClick={onFlaggedFilterChange}>
              {Number.isFinite(countCalibrationNeeded) ? (
                <Text>Flagged employees ({countCalibrationNeeded})</Text>
              ) : (
                <Text>Flagged employees</Text>
              )}
            </SwitchButton>
            <ExportMenu
              request={getCalibratedGradesExport}
              filterQuery={filterQuery}
              fileName="Grade Calibration"
            />
            <MoreBar.Action useIcon={ShareIOs} onClick={() => setShowUploadPopup(true)}>
              Upload grades
            </MoreBar.Action>
            <MoreBar.Action
              useIcon={ArrowExchange}
              onClick={onClickSyncDisplayGrades}
              aria-disabled={!isCurrentCycleFiltered}
              pending={displayGradesPending}
              {...syncGradesTooltip.getAnchorProps()}
            >
              Sync display grades
              <UIKitTooltip {...syncGradesTooltip.getTargetProps()} width={250}>
                This action will trigger all display grades for the selected employees to
                be recalculated based upon the grade logic setting. It can only be
                actioned for ongoing performance review cycles.
              </UIKitTooltip>
            </MoreBar.Action>

            <MoreBar.Action
              useIcon={ArrowExchange}
              onClick={syncEmployees}
              aria-disabled={filteredFilters?.length !== 1}
              pending={employeesSyncPending}
              {...syncEmployeesTooltip.getAnchorProps()}
            >
              Sync eligible employees
              {filteredFilters?.length !== 1 && (
                <UIKitTooltip {...syncEmployeesTooltip.getTargetProps()} width={250}>
                  Only one cycle should be selected
                </UIKitTooltip>
              )}
            </MoreBar.Action>
          </MoreBar>
        </Flex>
        <UploadPopup
          open={showUploadPopup}
          onClose={() => setShowUploadPopup(false)}
          onSubmit={handleSubmit}
          type={UploadPopupType.Grades}
        />
        <AdjustableTable<CalibratedGradeInterface, UploadStatus>
          name={TableNames.GradeCalibration}
          useWindowScroll
          row={ROW(grades, onChangeField, setCommentPopup)}
          {...table}
          noDataMessage="There are no grades"
        />
        <StatusPopup variant="in-progress" open={isUploading} preventUserClose>
          <StatusPopup.Title>
            Your file is being processed. <br />
            This process can take up to 5 minutes.
          </StatusPopup.Title>
        </StatusPopup>
      </Widget>
    </>
  )
}

const GradeCalibration = () => {
  const { initialCycleOffset } = useSelectedPerformanceCycle()

  if (initialCycleOffset === undefined) {
    return <Loader />
  }

  return <GradeCalibrationContent initialCycleOffset={initialCycleOffset} />
}

export default GradeCalibration
