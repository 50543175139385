import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { unarchiveInterviewRound } from '@src/api/recruitment/interviews'

type Props = {
  onSuccess: () => void
  round: InterviewRoundInterface
}

const Unarchive = ({ round, onSuccess }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onClick = async () => {
    setIsLoading(true)
    await unarchiveInterviewRound(round.id)
    setIsLoading(false)
    onSuccess()
  }

  return (
    <MoreBar.Action onClick={onClick} pending={isLoading}>
      Unarchive
    </MoreBar.Action>
  )
}

export default Unarchive
