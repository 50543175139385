import React, { useState } from 'react'
import get from 'lodash/get'
import { Button, ButtonProps } from '@revolut/ui-kit'
import { Delete } from '@revolut/icons'

export interface DeleteActionProps extends ButtonProps {
  handleDelete: (id: string | number) => Promise<any>
  data: Object
  primaryKey?: string
}

const DeleteAction = ({
  handleDelete,
  data,
  primaryKey = 'id',
  ...rest
}: DeleteActionProps) => {
  const [isPending, setIsPending] = useState<boolean>(false)
  return (
    <Button
      data-testid="delete-button"
      variant="text"
      pending={isPending}
      color="grey-tone-50"
      size="xs"
      height="min-content"
      useIcon={Delete}
      onClick={async e => {
        e.stopPropagation()
        setIsPending(true)
        try {
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          await handleDelete(get(data, primaryKey))
        } finally {
          setIsPending(false)
        }
      }}
      {...rest}
    />
  )
}

export default DeleteAction
