import React from 'react'
import { useSelector } from 'react-redux'
import pluralize from 'pluralize'
import { Cell, Flex, Button, Box, Color, StatusWidget } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import {
  ContractStatuses,
  EmployeeContractsInterface,
  EmployeeContractsStatsInterface,
  EmployeeInterface,
} from '@src/interfaces/employees'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import {
  contractAnnualSalaryColumn,
  contractEndAtColumn,
  contractEntityColumn,
  contractTypeColumn,
  contractLocationColumn,
  contractRoleColumn,
  contractSeniorityColumn,
  contractStartAtColumn,
  contractStatusColumn,
  contractTermColumn,
} from '@src/constants/columns/contracts'
import { useTable } from '@components/Table/hooks'
import { getEmployeeContracts, getEmployeeContractsStats } from '@src/api/employees'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import AdjustableTable from '@components/Table/AdjustableTable'
import Stat from '@components/Stat/Stat'
import { TableNames } from '@src/constants/table'

interface Props {
  data: EmployeeInterface
}

const ROW: RowInterface<EmployeeContractsInterface> = {
  highlight: (data, theme) => {
    if (data.contract_status.id === ContractStatuses.Expired) {
      return theme.colors['grey-tone-2']
    }
    return ''
  },
  cells: [
    {
      ...contractRoleColumn,
      width: 200,
    },
    {
      ...contractSeniorityColumn,
      width: 200,
    },
    {
      ...contractTypeColumn,
      width: 200,
    },
    {
      ...contractLocationColumn,
      width: 200,
    },
    {
      ...contractEntityColumn,
      width: 200,
    },
    {
      ...contractTermColumn,
      width: 200,
    },
    {
      ...contractAnnualSalaryColumn,
      width: 200,
    },
    {
      ...contractStartAtColumn,
      width: 200,
    },
    {
      ...contractEndAtColumn,
      width: 200,
    },
    {
      ...contractStatusColumn,
      width: 200,
    },
  ],
}

const ContractsTable = ({ data }: Props) => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddEmployeeContract)

  const table = useTable<EmployeeContractsInterface, EmployeeContractsStatsInterface>(
    {
      getItems: getEmployeeContracts(data.id),
      getStats: getEmployeeContractsStats(data.id),
    },
    undefined,
    [
      {
        sortBy: 'seniority',
        direction: SORT_DIRECTION.ASC,
      },
    ],
  )

  const navigateToCreateNew = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.GENERAL, { employeeId: data.id }))
  }

  const handleRowClick = (contract: EmployeeContractsInterface) => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.PREVIEW, {
        employeeId: data.id,
        id: contract.id,
      }),
    )
  }

  return (
    <Cell display="flex" width="100%" mb="s-20">
      <Flex flexDirection="column" width="100%">
        <Box mb="s-24">
          <Flex>
            <Stat
              label={pluralize('Contract', table.stats?.total)}
              val={table.stats?.total}
            />
            <Stat
              color={Color.GREEN}
              label="Active"
              val={table.stats?.active}
              ml="s-32"
            />
            <Stat
              color={Color.ORANGE}
              label="Pending"
              val={table.stats?.pending}
              ml="s-32"
            />
          </Flex>
          {!!table.stats?.total && canAdd && (
            <Button
              mt="s-24"
              useIcon={Plus}
              variant="secondary"
              size="sm"
              onClick={navigateToCreateNew}
            >
              Add new contract
            </Button>
          )}
        </Box>
        <AdjustableTable<EmployeeContractsInterface, EmployeeContractsStatsInterface>
          name={TableNames.EmployeeContracts}
          hideCount
          useWindowScroll
          dataType="Contract"
          row={ROW}
          {...table}
          onRowClick={handleRowClick}
          emptyState={
            <StatusWidget>
              <StatusWidget.Image
                src="https://assets.revolut.com/assets/3d-images/3D109.png"
                srcSet="https://assets.revolut.com/assets/3d-images/3D109@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D109@3x.png 3x"
              />
              <StatusWidget.Title>
                No contracts history for this employee yet
              </StatusWidget.Title>
              <StatusWidget.Description>
                Once you add the new contract, details will be shown here.
              </StatusWidget.Description>
              <StatusWidget.Action>
                <Button
                  mt="s-16"
                  useIcon={Plus}
                  variant="secondary"
                  size="sm"
                  onClick={navigateToCreateNew}
                >
                  Add new contract
                </Button>
              </StatusWidget.Action>
            </StatusWidget>
          }
        />
      </Flex>
    </Cell>
  )
}

export default ContractsTable
