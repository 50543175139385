import React from 'react'
import styled from 'styled-components'
import Icon from '@components/Icon/Icon'
import Button from '@components/Button/Button'
import { COOKIE } from '@src/constants/api'
import { ReviewCategory } from '@src/interfaces/performance'
import { colorGetter } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

interface Props {
  name: string
  firstName: string
  jobTitle: string
  onClose: () => void
  onSubmit: () => void
  category: ReviewCategory
}

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.popup};
  background: ${colorGetter(Color.GREY_50_OPAQUE_10)};
  padding: 24px 40px;
  color: ${({ theme }) => theme.colors.foreground};
`

const CloseLink = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  border: 1px solid ${colorGetter(Color.GREY_20_OPAQUE_90)};
  color: ${colorGetter(Color.GREY_TONE_50)};
  box-sizing: border-box;
  border-radius: 56px;
  width: 40px;
  height: 40px;
  align-self: start;
  justify-self: center;
  display: grid;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.background};
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Content = styled.div`
  max-width: 800px;
`

const Title = styled.div`
  font-size: 35px;
  line-height: 44px;
  width: 100%;
  text-align: left;
  font-weight: 600;
`
const Subtitle = styled.div`
  font-size: 21px;
  font-weight: 500;
  margin: 4px 0 20px 0;
`

const Accent = styled.span`
  color: ${colorGetter(Color.TEAL)};
`

const ContinueButton = styled(Button)`
  margin-top: 36px;
`

const Anonymous = styled.span`
  color: ${colorGetter(Color.TEAL)};
`

const Description = styled.div`
  line-height: 28px;
  padding-bottom: 30px;

  span {
    color: ${colorGetter(Color.GREY_TONE_50)};
  }
`

const Timeline = styled.div`
  line-height: 28px;
`

const PerformanceReviewPopup = ({
  name,
  firstName,
  jobTitle,
  onClose,
  onSubmit,
  category,
}: Props) => {
  const proceed = () => {
    const key =
      category === ReviewCategory.Upwards
        ? COOKIE.UPWARDS_INFO_SEEN
        : COOKIE.PERFORMANCE_INFO_SEEN
    workspaceLocalStorage.setItem(key, '1')
    onSubmit()
  }

  const isUpwards = category === ReviewCategory.Upwards

  return (
    <Container>
      <CloseLink onClick={onClose}>
        <Icon type="Close" />
      </CloseLink>
      <Wrapper>
        <Content>
          {isUpwards ? (
            <Title>
              <Anonymous>Anonymous</Anonymous> Q4 2020 Upwards Manager Review
            </Title>
          ) : (
            <Title>Performance Review: Q4 2020</Title>
          )}

          <Subtitle>
            of {name}, <Accent>{jobTitle}</Accent>
          </Subtitle>
          {isUpwards ? (
            <Description>
              <div>
                <span>a.</span> This is a completely anonymous feedback on {name}’s
                managerial skills and culture fit.
              </div>
              <div>
                <span>b.</span> By default, only {firstName}’s line manager and their
                managers will be able to view the feedback.
              </div>
              <div>
                <span>c.</span> You can choose to allow your line manager to see your
                upwards review.
              </div>
              <div>
                <span>d.</span> Your identity is completely confidential and will not be
                visible to anyone, at all times.
              </div>
            </Description>
          ) : (
            <Description>
              We have listened and have introduced some changes to make the process
              easier:
              <br />
              - If you have reviewed the same person before, you will see your previous
              answers and ratings next to each section
              <br />
              - Revisited skills and roles definitions
              <br />- Extended time for reviews
            </Description>
          )}

          <Timeline>
            {isUpwards ? (
              <b>
                Important dates
                <br />- <Accent>December 22 - January 8 </Accent> : Upwards reviews
              </b>
            ) : (
              <b>
                Important dates
                <br />- <Accent>December 22 - January 8</Accent> : Self / Peer / Upwards
                reviews
                <br />- <Accent>December 22 - January 12</Accent> : Line and Functional
                manager reviews
              </b>
            )}
          </Timeline>
          <ContinueButton type="button" onClick={proceed} square>
            Continue
          </ContinueButton>
        </Content>
      </Wrapper>
    </Container>
  )
}

export default PerformanceReviewPopup
