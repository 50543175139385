import React from 'react'
import { TeamInterface, TeamNPS, TeamNPSStats } from '@src/interfaces/teams'
import { useTable } from '@components/Table/hooks'
import { getTeamNPSDetractors, getTeamNPSGraph, getTeamNPSStats } from '@src/api/teams'
import { formatNumber, formatPercentage } from '@src/utils/format'
import { RevolutTheme, useTheme } from '@src/styles/theme'
import { RowInterface } from '@src/interfaces/data'
import {
  npsDetractorCommentColumn,
  npsDetractorCreationDateColumn,
  npsDetractorLinkColumn,
  npsDetractorScoreColumn,
} from '@src/constants/columns/npsDetractors'
import { PerformanceTimeRange } from '@src/constants/api'
import { getBarMonthFilter } from '@src/utils/graph'
import { BaseChartInner } from '@components/Charts/BaseChart/BaseChartInner'
import { Flex, Text } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import useChartTableSwitcher, {
  ChartTableTabs,
} from '@src/features/TabBarSwitcher/useChartTableSwitcher'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

interface StructureProps {
  data: TeamInterface
}

export const getNPSColor = (value: number, theme: RevolutTheme) => {
  if (value >= 40) {
    return theme.colors.green
  }
  if (value >= 20) {
    return theme.colors.warning
  }
  return theme.colors.red
}

const ROW: RowInterface<TeamNPS> = {
  cells: [
    {
      ...npsDetractorScoreColumn,
      width: 120,
    },
    {
      ...npsDetractorCommentColumn,
      width: 700,
    },
    {
      ...npsDetractorCreationDateColumn,
      width: 200,
    },
    {
      ...npsDetractorLinkColumn,
      width: 120,
    },
  ],
}

const NPS = ({ data }: StructureProps) => {
  const theme = useTheme()
  const { currentTab, tabBar, setCurrentTab } = useChartTableSwitcher()

  const table = useTable<TeamNPS, TeamNPSStats>({
    getItems: getTeamNPSDetractors(data.id),
    getStats: getTeamNPSStats(data.id),
  })

  return (
    <TableWrapper>
      <Flex mb="s-24" alignItems="center">
        <Stat
          label="NPS"
          mr="s-32"
          val={
            table?.stats?.nps !== undefined ? (
              <Text color={getNPSColor(table.stats.nps * 100, theme)}>
                {formatNumber(table?.stats?.nps * 100, 0)}
              </Text>
            ) : undefined
          }
        />
        <Stat
          label="VS last month"
          mr="s-32"
          val={
            table?.stats?.nps_vs_last_month !== undefined ? (
              <Text
                color={
                  table?.stats?.nps_vs_last_month! < 0
                    ? theme.colors.red
                    : theme.colors.green
                }
              >
                {formatPercentage(table?.stats?.nps_vs_last_month)}
              </Text>
            ) : undefined
          }
        />
        <Stat
          label="Dept avg"
          mr="s-32"
          val={
            table?.stats?.nps_department !== undefined
              ? formatNumber(table?.stats?.nps_department * 100, 0)
              : undefined
          }
        />
        <Stat
          label="Company avg"
          mr="s-32"
          val={
            table?.stats?.nps_company !== undefined
              ? formatNumber(table?.stats?.nps_company * 100, 0)
              : undefined
          }
        />
      </Flex>
      <Flex>
        <Flex mr="s-16">{tabBar}</Flex>
      </Flex>
      {currentTab === ChartTableTabs.Table && (
        <>
          <Text mt="36px">Showing only detractor scores (1-3)</Text>
          <Flex mt="s-16" style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable<TeamNPS, TeamNPSStats>
              name={TableNames.TeamNPS}
              useWindowScroll
              dataType="NPS"
              row={ROW}
              {...table}
              noDataMessage="No NPS for this team"
            />
          </Flex>
        </>
      )}
      {currentTab === ChartTableTabs.Chart && (
        <Flex
          flexDirection="column"
          minHeight={0}
          style={{ position: 'relative' }}
          height={{ all: 570, md: 400, sm: 250 }}
        >
          <BaseChartInner
            isNew
            title="NPS over time"
            id={data.id}
            fetchData={getTeamNPSGraph(data.id)}
            showButtons
            graphTimeRange={PerformanceTimeRange.week}
            onClick={bar => {
              setCurrentTab(ChartTableTabs.Table)
              table.onFilterChange(
                getBarMonthFilter(bar.data.progress_datetime as string),
                false,
              )
            }}
          />
        </Flex>
      )}
    </TableWrapper>
  )
}

export default NPS
