import React, { useEffect, useState } from 'react'
import { selectorKeys } from '@src/constants/api'
import { TeamAccessRequestInterface } from '@src/interfaces/accessRequest'
import { Statuses } from '@src/interfaces'
import { formTeamAccessRequest, teamAccessRequests } from '@src/api/accessRequests'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import styled from 'styled-components'
import Tooltip from '@components/Tooltip/Tooltip'
import { PermissionTypes } from '@src/store/auth/types'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import upperFirst from 'lodash/upperFirst'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  InputGroup,
  Link,
  Text,
  chain,
} from '@revolut/ui-kit'
import AutoStepper from '@components/Stepper/AutoStepper'
import { Grid } from '@components/CommonSC/Grid'
import {
  CancelAccessRequestButton,
  DeleteButtonLape,
  LapeApproveButton,
  LapeCopyButton,
  LapeRejectButton,
} from '@src/pages/Forms/FormButtons'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { Status } from '@components/CommonSC/General'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AccessRequestConfirmationDialogueProps } from '@src/pages/Forms/AccessRequestsForm'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { PROFUSION_ID } from '@src/pages/Forms/AccessRequestsForm/MetabseUser/MetabaseUser'
import { useParams } from 'react-router-dom'
import { teamsRequests } from '@src/api/teams'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { NameIdInterface } from '@src/interfaces/employees'
import useMetabasePermissionOptions from '@src/pages/Forms/AccessRequestsForm/useMetabasePermissionOptions'

const CustomTooltip = styled(Tooltip)`
  width: 140px;
`

const General = () => {
  const form = useLapeContext<TeamAccessRequestInterface>()
  const { values } = form
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(true)
  const params = useParams<{ teamId: string }>()
  const [isConfirmed, setIsConfirmed] = useState(false)
  const canRevoke = !!values.field_options?.permissions?.includes?.(
    PermissionTypes.RevokeTeamAccessRequests,
  )
  const canAdd = !!values.field_options?.permissions?.includes?.(
    PermissionTypes.AddTeamAccessRequests,
  )
  const permissionOptions = useMetabasePermissionOptions(values.database)

  const isProfusion = values?.database?.id === PROFUSION_ID

  const backUrl = pathToUrl(ROUTES.FORMS.TEAM.ACCESS_REQUESTS, {
    id: values?.team?.id,
  })

  useEffect(() => {
    fetchTeam()
    if (!values?.permission) {
      values.permission = 'read'
    }
    if (!values?.from_date_time) {
      values.from_date_time = new Date().toISOString()
    }
  }, [])

  const fetchTeam = async () => {
    try {
      if (params.teamId) {
        const result = await teamsRequests.getItem(params.teamId)
        if (result.data) {
          values.team = result.data
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const handleRevokeAccess = async () => {
    try {
      setLoading(true)
      const result = await teamAccessRequests?.putItem?.(
        { ...values, status: Statuses.revoked },
        values.id,
      )
      if (result?.data) {
        values.status = Statuses.revoked
        setOpenDialog(false)
        pushNotification({
          value: 'The access was revoked.',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <PageWrapper>
      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleRevokeAccess}
        loading={loading}
        onReject={() => setOpenDialog(false)}
        label={`Revoke this access?`}
        body={
          <div>
            The access is about to be permanently revoked.
            <Box mt="s-8" color="red">
              <b>Warning: You can't undo this action.</b>
            </Box>
          </div>
        }
      />
      <PageHeader
        title={chain(
          values?.id ? 'Metabase Request' : 'New Metabase Request',
          !!values?.status && (
            <Status status={values.status}>{upperFirst(values.status)}</Status>
          ),
        )}
        subtitle={values.team?.name || ''}
        backUrl={backUrl}
      />
      <PageBody>
        <AutoStepper>
          <Grid
            mb="s-32"
            flow="column"
            justifyContent="start"
            alignItems="center"
            gap={16}
          >
            <LapeApproveButton
              afterSubmitUrl={backUrl}
              notification={{
                path: ROUTES.FORMS.TEAM_ACCESS_REQUESTS.GENERAL,
                updateMsg: 'Access request successfully approved.',
              }}
            />
            <LapeRejectButton
              afterSubmitUrl={backUrl}
              dialog={{
                title: 'Reasons for rejection',
                placeholder: 'Please provide reasons for rejection',
                fieldName: 'description',
              }}
              notification={{
                path: ROUTES.FORMS.TEAM_ACCESS_REQUESTS.GENERAL,
                updateMsg: 'Access request successfully rejected.',
              }}
            />
            <DeleteButtonLape<TeamAccessRequestInterface>
              data={values}
              backUrl={backUrl}
              deleteApi={formTeamAccessRequest.delete!}
              title="Request"
            />
            {values.status === Statuses.approved && (
              <CustomTooltip
                placement="right"
                text="You don't have permissions for this action."
                hide={canRevoke}
              >
                <Button
                  size="sm"
                  variant="negative"
                  disabled={!canRevoke}
                  onClick={() => setOpenDialog(true)}
                >
                  Revoke Access
                </Button>
              </CustomTooltip>
            )}
            <CancelAccessRequestButton afterSubmitUrl={backUrl} />
            {canAdd && (
              <LapeCopyButton
                type="access-request"
                cleanFields={['specialisation', 'role']}
                replaceValues={{ from_date_time: new Date() }}
                afterSubmitUrl={pathToUrl(ROUTES.FORMS.TEAM_ACCESS_REQUESTS.GENERAL, {})}
              />
            )}
          </Grid>
          <NewStepperTitle title="Request Information" />
          <InputGroup>
            <LapeRadioSelectInput<TeamAccessRequestInterface>
              name="specialisation"
              label="Specialisation"
              selector={selectorKeys.specialisations}
            />
            <LapeRadioSelectInput<NameIdInterface>
              name="database"
              label="Database Name"
              selector={selectorKeys.helios_metabase_databases}
            />
            {isProfusion && (
              <Box width={450}>
                <Text use="div" mb="s-8">
                  By requesting access to the Profusion Database you must comply with the
                  PAD Policy. More information{' '}
                  <Link
                    use="a"
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://revolut.atlassian.net/wiki/spaces/WT/pages/2401961853/Personal+Account+Dealing+PAD"
                  >
                    here
                  </Link>
                </Text>
                <Flex alignItems="center">
                  <Checkbox
                    checked={isConfirmed}
                    onChange={e => {
                      setIsConfirmed(e.currentTarget.checked)
                    }}
                  />
                  <Text style={{ fontStyle: 'italic' }} fontWeight="bold" pl="10px">
                    I confirm that I have read and understood PAD Policy
                  </Text>
                </Flex>
              </Box>
            )}
            <Text color="grey-tone-50">Type of access</Text>
            <LapeNewRadioButtons
              name="permission"
              options={permissionOptions}
              resetDisabledOptions
            />
            <LapeDatePickerInput
              disabledDays={{ before: new Date() }}
              defaultValue={new Date()}
              required
              name="from_date_time"
              label="From Date"
            />
          </InputGroup>
          <NewStepperTitle title="Provide reason" />
          <InputGroup>
            <LapeNewTextArea name="reason" label="Reason for Request" required rows={3} />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          confirmationDialogue={AccessRequestConfirmationDialogueProps}
          successText="Access Request received"
          afterSubmitUrl={pathToUrl(ROUTES.FORMS.TEAM.ACCESS_REQUESTS, {
            id: values?.team?.id,
          })}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default General
