import React from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { Color, MoreBar } from '@revolut/ui-kit'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { SendMessage } from '@revolut/icons'

type Props = {
  candidateId: number | string
  round?: InterviewRoundInterface
}

const UpcomingOnlineTestNotifications = ({ candidateId, round }: Props) => {
  if (
    round?.latest_interview_stage?.interview_type === 'online_test' &&
    round?.latest_interview_stage?.scheduling_status === 'test_not_sent'
  ) {
    return (
      <ActionWidget
        title={`Test for "${round?.latest_interview_stage.title}" stage is pending`}
        text="Please send the test to the candidate"
        avatarColor={Color.BLUE}
      >
        <MoreBar>
          <MoreBar.Action
            useIcon={SendMessage}
            onClick={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.SEND_ONLINE_TEST, {
                  candidateId,
                  roundId: round.id,
                  stageId: round.latest_interview_stage!.id,
                }),
              )
            }
          >
            Send test
          </MoreBar.Action>
        </MoreBar>
      </ActionWidget>
    )
  }

  return null
}

export default UpcomingOnlineTestNotifications
