import { api } from '@src/api/index'
import { API } from '@src/constants/api'
import {
  EstimatedRequisitionAnalytics,
  RecruitmentAnalytics,
  SpecialisationLocations,
} from '@src/interfaces/specialisations'
import { AxiosResponse } from 'axios'
import { GetRequestData } from '@src/interfaces'
import { SeniorityInterface, SenioritySublevelInterface } from '@src/interfaces/seniority'
import { useFetch } from '@src/utils/reactQuery'
import { LocationInterface } from '@src/interfaces/requisitions'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'

export const getRecruitmentAnalytics = (id: number) => {
  return api.get<RecruitmentAnalytics>(
    `${API.SPECIALISATIONS}/${id}/recruitmentAnalytics`,
  )
}

export const calcEstimatedRequisitionAnalytics = (id: number, headcount: number) => {
  return api.post<EstimatedRequisitionAnalytics>(
    `${API.SPECIALISATIONS}/${id}/estimatedLastRequisitionAnalyticsFields`,
    { headcount },
  )
}

export const getSpecialisationSeniorities = async (
  id: number | string,
): Promise<AxiosResponse<SeniorityInterface[]>> =>
  api.get(`${API.SPECIALISATIONS}/${id}/seniorities`)

export const useGetSpecialisationSeniorities = (id: number | string | null) => {
  const url = `${API.SPECIALISATIONS}/${id}/seniorities`
  return useFetch<SeniorityInterface[]>(id ? url : null)
}

export const useGetSpecialisationPreferredHiringLocations = (
  specialisationId?: number | string,
) =>
  useFetch<LocationInterface[]>(
    specialisationId
      ? `${API.SPECIALISATIONS}/${specialisationId}/preferredHiringLocations`
      : null,
  )

export const getSpecialisationPreferredHiringLocations = (
  specialisationId: number | string,
) =>
  api.get<LocationInterface[]>(
    `${API.SPECIALISATIONS}/${specialisationId}/preferredHiringLocations`,
  )

export const useGetSpecialisationLocations = (id?: number | string) => {
  const url = `${API.SPECIALISATIONS}/${id}/locations`
  return useFetch<SpecialisationLocations>(id ? url : null)
}

export const getSpecialisationSublevels = (specialisationId: number) =>
  api.get<GetRequestData<SenioritySublevelInterface>>(
    `${API.SPECIALISATIONS}/${specialisationId}/senioritySubLevels`,
  )

export const renameSenioritySublevel = (
  specialisationId: number,
  sublevelId: number,
  newTitle: string,
) =>
  api.patch<SenioritySublevelInterface>(
    `${API.SPECIALISATIONS}/${specialisationId}/senioritySubLevels/${sublevelId}`,
    { job_title: newTitle },
  )

export const updateHiringProcessRounds = (
  specialisationId: number,
  hiring_process_rounds: HiringProcessInterface[],
) =>
  api.patch(
    `${API.SPECIALISATIONS}/${specialisationId}`,
    {
      hiring_process_rounds,
    },
    {
      params: {
        step: 'hiring_process',
      },
    },
  )
