import React from 'react'
import { TeamInterface } from '@src/interfaces/teams'
import { ROUTES } from '@src/constants/routes'
import { Route, Switch, useParams } from 'react-router-dom'

import Complaints from '@src/features/FormTabs/CX/Complaints'
import SupportNPS from '@src/features/FormTabs/CX/SupportNPS'
import NetContacts from '@src/features/FormTabs/CX/NetContacts'
import { Flex } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { EntityPermissions } from '@src/store/auth/types'
import { ColoredText } from '@components/CommonSC/General'
import NPS, { getNPSColor } from '@src/pages/Forms/TeamForm/CX/NPS/NPS'
import { formatNumber } from '@src/utils/format'
import { useTheme } from '@src/styles/theme'
import CXSummary from '@src/features/FormTabs/CX/CXSummary'

interface StructureProps {
  data: TeamInterface
}

const CX = ({ data }: StructureProps) => {
  const theme = useTheme()
  const params = useParams<{ id: string }>()

  const tabs = [
    {
      path: ROUTES.FORMS.TEAM.CX.ALL,
      to: pathToUrl(ROUTES.FORMS.TEAM.CX.ALL, params),
      title: 'Summary',
      canView: true,
      component: CXSummary,
    },
    {
      path: ROUTES.FORMS.TEAM.CX.CSAT_DETRACTORS,
      to: pathToUrl(ROUTES.FORMS.TEAM.CX.CSAT_DETRACTORS, params),
      title: 'Support NPS',
      component: SupportNPS,
      canView: true,
      quickSummary: <QuickSummaryCount count={data.cx_csat_detractors_last_30_days} />,
    },
    {
      path: ROUTES.FORMS.TEAM.CX.SUPPORT_LOAD,
      to: pathToUrl(ROUTES.FORMS.TEAM.CX.SUPPORT_LOAD, params),
      title: 'Net Contacts',
      component: NetContacts,
      canView: true,
      quickSummary: <QuickSummaryCount count={data.cx_support_load_last_30_days} />,
    },
    {
      path: ROUTES.FORMS.TEAM.CX.COMPLAINTS,
      to: pathToUrl(ROUTES.FORMS.TEAM.CX.COMPLAINTS, params),
      title: 'Complaints',
      component: Complaints,
      canView: true,
      quickSummary: <QuickSummaryCount count={data.cx_complaints_last_30_days} />,
    },
    {
      title: 'Product NPS',
      path: ROUTES.FORMS.TEAM.CX.NPS,
      to: pathToUrl(ROUTES.FORMS.TEAM.CX.NPS, params),
      component: NPS,
      canView: data.field_options.permissions?.includes(EntityPermissions.ViewNPS),
      quickSummary: (
        <ColoredText color={getNPSColor(data?.nps * 100, theme)}>
          {formatNumber(data?.nps * 100, 0)}
        </ColoredText>
      ),
    },
  ]

  const filteredTabs = tabs.filter(tab => tab.canView)

  return (
    <Flex flexDirection="column" flex="1 0">
      <Flex alignItems="flex-start" mb="s-24">
        <TabBarNavigation isSubtab tabs={filteredTabs} />
      </Flex>
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component data={data} isTeam />
          </Route>
        ))}
      </Switch>
    </Flex>
  )
}

export default CX
