import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Box, Button, Item, Subheader, Switch, Text, Widget } from '@revolut/ui-kit'
import { PageBody } from '@components/Page/PageBody'
import {
  getSlackIntegrationBots,
  useGetSlackPreferences,
  useUpdatePreferences,
} from '@src/api/integrations'
import { Plus } from '@revolut/icons'
import { InternalLink } from '@components/InternalLink/InternalLink'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import {
  slackBotActionsColumn,
  slackBotDescriptionColumn,
  slackBotNameColumn,
} from '@src/constants/columns/slackBots'
import { SlackBotInterface } from '@src/interfaces/integrations'
import SlackBotActions from '@components/ColumnInserts/SlackBotActions/SlackBotActions'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { navigateTo } from '@src/actions/RouterActions'
import Tooltip from '@components/Tooltip/Tooltip'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { API } from '@src/constants/api'
import { useQueryClient } from 'react-query'

const getRows = (
  refresh: () => void,
  canEdit: boolean,
): RowInterface<SlackBotInterface> => ({
  linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.HUB.INTEGRATION.SLACK.BOT, { id })),
  cells: [
    {
      ...slackBotNameColumn,
      width: 100,
    },
    {
      ...slackBotDescriptionColumn,
      width: 200,
    },
    {
      ...slackBotActionsColumn,
      insert: ({ data }) =>
        canEdit ? <SlackBotActions id={data.id} onDelete={refresh} /> : '-',
      width: 80,
    },
  ],
})

const Slack = () => {
  const { data: preferences, isLoading } = useGetSlackPreferences()
  const updatePreferences = useUpdatePreferences()
  const table = useTable({ getItems: getSlackIntegrationBots })
  const permissions = useSelector(selectPermissions)
  const queryClient = useQueryClient()

  const canEdit = permissions.includes(PermissionTypes.ChangeSlackIntegration)

  const handleSwitch = async () => {
    if (!preferences?.id) {
      return
    }
    await updatePreferences.mutateAsync([
      preferences.id,
      { enabled: !preferences.enabled },
    ])

    // communication settings depend on slack integration
    queryClient.invalidateQueries(API.COMMUNICATION_SETTINGS)

    pushNotification({
      value: `Slack integration turned ${preferences.enabled ? 'off' : 'on'}`,
      duration: SUCCESS_DEFAULT_DURATION,
      type: NotificationTypes.success,
    })
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Slack integration settings"
        subtitle={
          <Text whiteSpace="pre-wrap" maxWidth={720} use="div">
            Revolut People’s Slack Integration enables IT Admins to configure the platform
            to manage & configure HR/platform communications via Slack to employees,
            driving improved engagement through day to day comms.
          </Text>
        }
        backUrl={pathToUrl(ROUTES.HUB.INTEGRATIONS)}
      />
      <PageBody>
        <Item use="label">
          <Item.Content>
            <Item.Title>Enable Slack integration</Item.Title>
            <Item.Description>Turn on/off the integration with Slack</Item.Description>
          </Item.Content>
          <Item.Side>
            <Tooltip
              placement="right"
              text="You need at least one bot to turn on Slack integration"
              hide={table.count > 0}
              delay={0}
            >
              <Switch
                onChange={handleSwitch}
                checked={preferences?.enabled}
                disabled={
                  updatePreferences.isLoading ||
                  isLoading ||
                  !canEdit ||
                  table.count === 0
                }
              />
            </Tooltip>
          </Item.Side>
        </Item>
        <Widget mt="s-24" px="s-16" pb="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Slack bots</Subheader.Title>
          </Subheader>
          <Button
            useIcon={Plus}
            use={InternalLink}
            to={pathToUrl(ROUTES.HUB.INTEGRATION.SLACK.BOT)}
            variant="secondary"
            size="sm"
            disabled={!canEdit}
          >
            Register Slack bot
          </Button>

          <Box pt="s-16">
            <AdjustableTable
              name={TableNames.SlackBots}
              useWindowScroll
              row={getRows(table.refresh, canEdit)}
              {...table}
              noDataMessage="Slack bots will appear here"
              hideCountAndButtonSection
            />
          </Box>
        </Widget>
      </PageBody>
    </PageWrapper>
  )
}

export default Slack
