import React from 'react'
import { MouseEventHandler, ReactNode, useMemo, useState } from 'react'
import { createChain, Text, TextButton, TextProps } from '@revolut/ui-kit'

interface CareersPositionWidgetLocationsProps extends TextProps {
  locations: string[]
  children: ReactNode
}

const VISIBLE_COUNT = 8

const CareersPositionLocations = ({
  locations,
  children,
  ...rest
}: CareersPositionWidgetLocationsProps) => {
  const [showAll, setShowAll] = useState(false)
  const uniqLocations = useMemo(() => Array.from(new Set(locations)), [locations])

  const count = uniqLocations.length
  const visibleLocations = showAll ? uniqLocations : uniqLocations.slice(0, VISIBLE_COUNT)

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()
    setShowAll(true)
  }

  return (
    <Text color="grey-tone-50" {...rest}>
      {children}
      {createChain(<Text color="grey-tone-20"> | </Text>)(visibleLocations)}
      {!showAll && count > VISIBLE_COUNT && (
        <TextButton ml="s-8" onClick={handleClick}>
          <Text letterSpacing="0.016em">+{count - VISIBLE_COUNT}</Text>
        </TextButton>
      )}
    </Text>
  )
}

export default CareersPositionLocations
