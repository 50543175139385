import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { Box, InputGroup, Side } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import AutoStepper from '@components/Stepper/AutoStepper'
import { EMPLOYEE_PROFILE_SUB_SECTIONS } from '@src/interfaces/employees'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import { PageBody } from '@components/Page/PageBody'
import {
  addressLine1Message,
  addressLine2Message,
  addressLine3Message,
} from '../constants'
import { useEmployeeDetailsOnAfterSubmit } from '@src/pages/EmployeeProfile/Forms/hooks'
import { LapePhoneInput } from '@components/Inputs/LapeFields/LapePhoneInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageActions } from '@src/components/Page/PageActions'

export interface Props {
  dynamicGroups: DynamicGroupIDs
  isSidebarContent?: boolean
  refreshData?: () => void
}

const ContactInfo = ({ dynamicGroups, isSidebarContent, refreshData }: Props) => {
  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <PageBody>
      <AutoStepper>
        <Box mb="s-32">
          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.CONTACT_INFO}
            dynamicGroups={dynamicGroups}
          >
            <InputGroup>
              <LapeNewInput name="personal_email" label="Personal email" required />
              <LapePhoneInput
                prefixName="phone_country_code"
                phoneName="phone_number_short"
                message="Please provide your personal mobile number"
                required
              />
              <LapeNewInput
                name="address_line_1"
                label="Address Line 1"
                message={addressLine1Message}
                required
              />
              <LapeNewInput
                name="address_line_2"
                label="Address line 2"
                message={addressLine2Message}
                required
              />
              <LapeNewInput
                name="address_line_3"
                label="Address line 3"
                message={addressLine3Message}
              />
              <InputGroup variant="horizontal">
                <LapeNewInput name="county" label="County" required width="50%" />
                <LapeNewInput
                  name="city"
                  label="City"
                  required
                  message="City name in local language"
                />
              </InputGroup>
              <InputGroup variant="horizontal">
                <LapeNewInput
                  name="post_code"
                  label="Post code/ZIP code"
                  required
                  width="50%"
                />
                <LapeRadioSelectInput
                  selector={selectorKeys.countries}
                  name="country"
                  label="Country"
                  placement="top"
                />
              </InputGroup>
            </InputGroup>
          </NewStepperSectionCustomFields>
        </Box>

        <ActionsWrapper>
          <NewSaveButtonWithPopup
            useValidator
            successText="Contact info successfully updated."
            onAfterSubmit={onAfterSubmit}
          />
        </ActionsWrapper>
      </AutoStepper>
    </PageBody>
  )
}

export default ContactInfo
