import React, { useState } from 'react'
import { Action, Box, Subheader, TransitionCollapse } from '@revolut/ui-kit'
import { ChevronDown, ChevronUp } from '@revolut/icons'

type Props = {
  title: string
  openByDefault?: boolean
}

const CollapsibleSubheader: React.FC<Props> = ({ title, openByDefault, children }) => {
  const [open, setOpen] = useState(openByDefault)

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{title}</Subheader.Title>
        <Subheader.Side>
          <Action
            onClick={() => {
              setOpen(!open)
            }}
          >
            <Box>{open ? <ChevronUp size={24} /> : <ChevronDown size={24} />}</Box>
          </Action>
        </Subheader.Side>
      </Subheader>
      <TransitionCollapse in={open}>{children}</TransitionCollapse>
    </Box>
  )
}

export default CollapsibleSubheader
