import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useParams } from 'react-router-dom'
import { goBack } from '@src/actions/RouterActions'
import { successNotification } from '@src/actions/NotificationActions'
import { pathToUrl } from '@src/utils/router'
import React, { useState } from 'react'
import { MoreBar, useStatusPopup } from '@revolut/ui-kit'
import { StatusCheck } from '@revolut/icons'
import { FieldOptions, Statuses } from '@src/interfaces'
import { SettingsButtonsInstanceProps } from '@src/features/SettingsButtons'
import { EntityPermissions } from '@src/store/auth/types'
import { AxiosError } from 'axios'
import { StatusPopup, useTooltip, Tooltip as UIKitTooltip } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { UNKNOWN_ERROR } from '@src/pages/Forms/QueryForm/constants'

interface ApproveButtonProps extends SettingsButtonsInstanceProps {
  buttonActionText?: string
  afterSubmitUrl?: string
  withPreApproval?: boolean
  submit?: () => Promise<any>
  onBeforeSubmit?: () => void
  onAfterSubmit?: () => void
  onSubmitFailed?: (error: AxiosError) => void
  statusToSet?: Statuses
  statusFieldName?: string
  notification?: {
    updateMsg: string
    path?: string
  }
  popupMessage?: string
  disabled?: boolean
  tooltip?: string
  showErrorPopup?: boolean
}

const LapeApproveButton = ({
  afterSubmitUrl,
  notification,
  withPreApproval,
  isVisible = true,
  globalPermissions,
  entityPermissions = EntityPermissions.Approve,
  onAfterSubmit,
  onBeforeSubmit,
  submit,
  onSubmitFailed,
  statusToSet,
  buttonActionText,
  statusFieldName = 'status',
  popupMessage,
  showErrorPopup,
  disabled,
  tooltip,
}: ApproveButtonProps) => {
  const statusPopup = useStatusPopup()
  const tooltipProps = useTooltip()
  const [loading, setLoading] = useState(false)
  const form =
    useLapeContext<{ status: Statuses; field_options: FieldOptions; id: number }>()
  const params = useParams()
  const preapproving =
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    withPreApproval && form.values[statusFieldName] === Statuses.pending

  const permissions = useSelector(selectPermissions)

  const canApprove =
    form.values.field_options?.actions?.includes(entityPermissions) ||
    globalPermissions?.some(p => permissions.includes(p))

  const submitWithForm = async () => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const fallbackStatus = form.values[statusFieldName]
    const defaultStatus = preapproving ? Statuses.preapproved : Statuses.approved
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    form.values[statusFieldName] = statusToSet || defaultStatus

    try {
      const result = await form.submit()
      form.reset(result)
      form.changelog = undefined
      if (afterSubmitUrl) {
        goBack(afterSubmitUrl)
      }
      if (notification) {
        successNotification(
          notification.updateMsg,
          notification.path
            ? pathToUrl(notification.path, { ...params, id: result?.id })
            : undefined,
        )
      }
      return result
    } catch (error) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      form.values[statusFieldName] = fallbackStatus
      onSubmitFailed?.(error)

      if (showErrorPopup) {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>{error?.data?.message || UNKNOWN_ERROR}</StatusPopup.Title>
          </StatusPopup>,
        )
      }
    }
    return Promise.resolve()
  }

  const approve = async () => {
    onBeforeSubmit?.()
    setLoading(true)

    try {
      await (submit ? submit() : submitWithForm())
      if (popupMessage) {
        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>{popupMessage}</StatusPopup.Title>
          </StatusPopup>,
        )
      }
      onAfterSubmit?.()
    } catch (error) {
      onSubmitFailed?.(error)
    } finally {
      setLoading(false)
    }

    return Promise.resolve()
  }

  const defaultTextAction = preapproving ? 'Pre-approve' : 'Approve'
  const textAction = buttonActionText || defaultTextAction
  const isShown = isVisible && canApprove

  const getLabel = () => {
    if (form.dirty) {
      return Object.keys(form.fields).length === 1 && form.fields.rejection_reason
        ? textAction
        : `Save and ${textAction}`
    }

    return textAction
  }

  return isShown ? (
    <>
      {isShown && (
        <MoreBar.Action
          useIcon={StatusCheck}
          pending={loading}
          onClick={() => {
            if (!disabled) {
              approve()
            }
          }}
          data-testid="approve-btn"
          aria-disabled={disabled}
          {...tooltipProps.getAnchorProps()}
        >
          {getLabel()}
          {tooltip && (
            <UIKitTooltip {...tooltipProps.getTargetProps()} width={200}>
              {tooltip}
            </UIKitTooltip>
          )}
        </MoreBar.Action>
      )}
    </>
  ) : null
}

export default connect(LapeApproveButton)
