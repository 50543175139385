import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CareerPageSettingsInterface } from '@src/interfaces/settings'
import { VStack, Widget } from '@revolut/ui-kit'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import CompanyLogoInput from '@src/pages/Settings/CareerPage/CompanyLogoInput'
import LinksInput from '@src/pages/Settings/CareerPage/LinksInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import JobPostingGroupsInput from '@src/pages/Settings/CareerPage/JobPostingGroupsInput'

const CareersPagePersonalisation = () => {
  const { values } = useLapeContext<CareerPageSettingsInterface>()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCareerPagePreferences)

  return (
    <VStack gap="s-16">
      <SectionTitle
        title="Slogan"
        subtitle="Define a short paragraph (one or two sentences) and set the tone for your Careers Page."
      />
      <LapeNewTextArea
        required
        name="slogan"
        placeholder="Join our journey of innovation and excellence!"
        message="This will be the first thing visible to your candidates, highlighted at the top of the Careers Page"
        disabled={disableEdit}
        maxCharacters={140}
      />
      <SectionTitle
        title="Company logo"
        subtitle={
          <>
            Showcase your brand and add a personal touch to your page by uploading your
            logo. <br /> This will be visible in the header of the page
          </>
        }
      />
      <Widget p="s-16">
        <CompanyLogoInput disabled={disableEdit} />
      </Widget>
      <SectionTitle
        title="Custom links"
        subtitle={
          <>
            Add up to 4 links to the header of the page. For example, your company’s blog
            or “About us” page.
            <br />
            When adding custom links, keep in mind that they will be displayed from left
            to right on the final page.
            <br />
            The order is determined by how they appear in the customisation settings.
          </>
        }
      />
      <Widget>
        <LinksInput disabled={disableEdit} />
      </Widget>
      <SectionTitle
        title="About company"
        subtitle={
          <>
            Provide a concise overview of your organisation's mission, core values, and
            culture. Highlight your milestones, and any recognitions that showcase your
            commitment to employee experience. <br /> This will be visible at the top of
            every job posting across your organisation
          </>
        }
      />
      <Widget p="s-16">
        <LapeHTMLEditor
          required
          name="about_the_company"
          placeholder={`Example:
At our company, we are driven by a single goal: to be the benchmark of excellence in our industry. Since our founding, we've been at the forefront of innovation, committed to not only providing exceptional products and services but also to making a positive impact on our community and the environment.

We want exceptional candidates that love great companies.`}
          disabled={disableEdit}
          maxCharacters={5000}
        />
      </Widget>
      <SectionTitle
        title="Do you want to enable job postings grouping?"
        subtitle="By default all the Job Postings are grouped by their corresponding Functions. Activate this option if you wish to define custom groupings that combine multiple Functions under a single custom group. Any functions not included in these custom groupings will be categorised under 'Others'."
      />
      <LapeBooleanRadioSwitch
        name="group_by_function"
        variant="horizontal"
        disabled={disableEdit}
        noLabel={
          <>
            No <Recommended />
          </>
        }
        onAfterChange={groupByFunction => {
          if (!groupByFunction) {
            values.function_groups = []
          }
        }}
      />
      {values.group_by_function && (
        <Widget>
          <JobPostingGroupsInput disabled={disableEdit} />
        </Widget>
      )}
    </VStack>
  )
}

export default CareersPagePersonalisation
