import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  CandidateOpenedSidebarType,
  CandidateInterface,
  InterviewPendingSchedulingStatuses,
  InterviewPendingSchedulingTypes,
  InterviewRoundInterface,
  InterviewShowScheduleSidebarStatuses,
  InterviewStageRowChildrenInterface,
  InterviewStageRowChildrenInternalInterface,
  InterviewStageRowInterface,
  InterviewType,
  ScheduleSidebarModeType,
  InterviewStageRowWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import UpcomingInterviewNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/UpcomingInterviewNotifications'
import {
  Box,
  MoreBar,
  MoreBarSkeleton,
  VStack,
  ActionButton,
  ActionBar,
  List,
  Text,
  Color,
} from '@revolut/ui-kit'
import { PermissionTypes } from '@src/store/auth/types'
import StagesTable from '@src/pages/Forms/Candidate/InterviewProgress/StagesTable'
import InterviewStartCard from '@src/pages/Forms/Candidate/InterviewProgress/InterviewStartCard'
import AddFeedback from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/AddFeedback'
import ChangeStage from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/ChangeStage'
import NextStage from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/NextStage'
import Sidebar from '@src/pages/Forms/Candidate/InterviewProgress/components/Sidebar/Sidebar'
import { PageBody } from '@src/components/Page/PageBody'
import ScheduleSidebar from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduleSidebar'
import SettingsButtons from '@src/features/SettingsButtons'
import MultipleDetailsSkeleton from '@components/Skeletons/MultipleDetailsSkeleton'
import CVPreviewSidebar from '@src/features/CVPreviewSidebar/CVPreviewSidebar'
import Unarchive from './components/Actions/Unarchive'
import ArchiveDetails from '@src/pages/Forms/Candidate/InterviewProgress/components/ArchiveDetails'
import CandidateShortSummarySidebar from '@src/features/InterviewTool/CandidateShortSummarySidebar'
import { ROUTES } from '@src/constants/routes'
import PermissionTransferButton from '@src/pages/PermissionTransfer/Button/PermissionTransferButton'
import SendEmail from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/SendEmail'
import { AddContact, Anonymous, Archive, Newspaper } from '@revolut/icons'
import OnlineTestSummarySidebar from '@src/features/InterviewTool/OnlineTestSummarySidebar'
import UpcomingOnlineTestNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/UpcomingOnlineTestNotifications'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import SendDataEmail from './components/Actions/SendDataEmail'
import AnonymizeNotice from '@src/pages/Forms/Candidate/InterviewProgress/components/Widgets/AnonymizeNotice'
import UpcomingOfferNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/UpcomingOfferNotifications'
import ChangeRoundWidget from '@src/pages/Forms/Candidate/InterviewProgress/components/Widgets/ChangeRoundWidget'
import ConfidentialCandidateWidget from '@src/pages/Forms/Candidate/InterviewProgress/components/Widgets/ConfidentialCandidateWidget'
import PrepCallInterviewNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/PrepCallInterviewNotifications'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useGetCandidateSettings } from '@src/api/settings'
import { useGetOfferSettings } from '@src/api/settings'
import ScheduleInterviewButton from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/ScheduleInterviewButton'
import RefreshInterviewStages from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/RefreshInterviewStages'
import Comments from '@src/pages/Forms/Candidate/Comments/Comments'
import CVScreeningNotification from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/CVScreeningNotification'
import GenerateFeedbackLink from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/GenerateFeedbackLink'
import { useFetchStagesTable } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'
import useIsCommercial from '@src/hooks/useIsCommercial'
import {
  SnoozeAction,
  SnoozeBanner,
} from '@src/pages/Forms/Candidate/InterviewProgress/components/Snooze'

interface Props {
  candidate?: CandidateInterface
  canEditRound: boolean
  previewStageMode: boolean
  round?: InterviewRoundInterface
  refresh: () => Promise<void>
  refreshStats: () => void
  isLoading?: boolean
  openedSidebar?: CandidateOpenedSidebarType
  onOpenSidebar: (type?: CandidateOpenedSidebarType) => void
  showCandidateInformationAction: React.ReactNode
}

const Summary = ({
  candidate,
  canEditRound,
  round,
  previewStageMode,
  refresh,
  refreshStats,
  isLoading,
  openedSidebar,
  onOpenSidebar,
  showCandidateInformationAction,
}: Props) => {
  const params = useParams<{ id: string }>()
  const { id } = params

  const [selectedRow, setSelectedRow] = useState<
    InterviewStageRowWithoutRoundInterface | InterviewStageRowChildrenInterface
  >()
  const [selectedStageType, setSelectedStageType] = useState<InterviewType>()
  const currentStageId = candidate?.active_interview_round?.latest_interview_stage?.id
  const canViewEditOffer = candidate?.field_options?.permissions?.includes(
    PermissionTypes.ViewCandidateOffers,
  )
  const canUnarchive = round?.field_options?.permissions?.includes(
    PermissionTypes.UnarchiveInterviewRound,
  )
  const { data: candidateSettings } = useGetCandidateSettings()
  const { data: offerSettings, isLoading: isLoadingOfferSettings } = useGetOfferSettings()
  const {
    stages,
    feedbacks,
    refetch: refetchStages,
    status: stagesLoadingStatus,
  } = useFetchStagesTable(round?.id)
  const [selectedStageId, setSelectedStageId] = useState<number>()
  const isCommercial = useIsCommercial()

  const hireButtonDisabled = !isCommercial && !candidate?.phone

  useEffect(() => {
    setSelectedStageId(openedSidebar?.isPrepCall ? currentStageId : selectedRow?.id)
  }, [openedSidebar?.isPrepCall, currentStageId, selectedRow?.id])

  useEffect(() => {
    refetchStages()
  }, [candidate])

  const handleOpenSidebar = (
    data: InterviewStageRowWithoutRoundInterface | InterviewStageRowChildrenInterface,
    mode?: ScheduleSidebarModeType,
  ) => {
    if ('children' in data) {
      if (previewStageMode) {
        onOpenSidebar(undefined)
        setSelectedRow(data)
        return
      }

      if (data.interview_type === 'online_test') {
        onOpenSidebar({
          type: 'onlineTestSummary',
        })
      } else if (mode === 'rescheduling') {
        onOpenSidebar({
          type: 'schedule',
          mode: 'rescheduling',
        })
      } else if (
        InterviewPendingSchedulingStatuses.includes(data.scheduling_status) &&
        InterviewPendingSchedulingTypes.includes(data.interview_type)
      ) {
        onOpenSidebar({
          type: 'schedule',
          mode: 'scheduling',
        })
      } else if (InterviewShowScheduleSidebarStatuses.includes(data.scheduling_status)) {
        onOpenSidebar({
          type: 'schedule',
          mode: 'view',
        })
      } else {
        onOpenSidebar({
          type: 'shortSummary',
        })
      }

      setSelectedRow(data)
      return
    }

    onOpenSidebar({
      type: 'stage',
    })
  }

  const renderStagesTable = () => {
    if (isLoading) {
      return <MultipleDetailsSkeleton />
    }

    if (round && candidate?.id) {
      return (
        <StagesTable
          round={round}
          onClick={(data, mode, stageType) => {
            setSelectedRow(data)
            setSelectedStageType(stageType)

            if ('interview_type' in data && data.interview_type === 'offer') {
              return
            }
            handleOpenSidebar(data, mode)
          }}
          selectedRowId={selectedRow?.id}
          onRefresh={() => {
            refetchStages()
            refresh()
            onOpenSidebar(undefined)
            setSelectedRow(undefined)
          }}
          canViewEditOffer={!!canViewEditOffer}
          disableActions={previewStageMode}
          stages={stages}
          feedbacks={feedbacks}
          status={stagesLoadingStatus}
          candidateId={candidate.id}
        />
      )
    }

    return null
  }

  const renderComments = () => (
    <Comments
      roundId={round?.id}
      refreshStats={refreshStats}
      onClickSeeAll={() => navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.COMMENTS, params))}
    />
  )

  const renderActions = () => {
    if (stagesLoadingStatus === 'error') {
      return (
        <ActionWidget
          mb="s-16"
          title="The hiring process is not defined correctly"
          text={
            <>
              Please ensure that all following conditions are met:
              <List variant="compact" color="grey-tone-50">
                <List.Item>
                  - there are no missing scorecards in stage definitions (one exception is
                  Online Test stage)
                </List.Item>
                <List.Item>
                  - there are no missing CV Screening or Screen Call stage
                </List.Item>
                <List.Item>- there are no missing tests in Online Test stages</List.Item>
                <List.Item>
                  - there are no missing interviewers in stage definitions (one exception
                  is Online Test stage)
                </List.Item>
              </List>
            </>
          }
        >
          {round && (
            <ActionBar>
              <ActionButton
                variant="negative"
                onClick={() => {
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
                      id: round.specialisation.id,
                    }),
                  )
                }}
              >
                Go to hiring process
              </ActionButton>
            </ActionBar>
          )}
        </ActionWidget>
      )
    }
    if (isLoading) {
      return null
    }

    if (stagesLoadingStatus === 'loading') {
      return <MoreBarSkeleton mb="s-16" />
    }

    return (
      <SettingsButtons pb="s-16">
        {showCandidateInformationAction}
        {!round?.archived_reason && round?.latest_interview_stage && (
          <>
            {candidateSettings?.enable_scheduling && (
              <>
                <ScheduleInterviewButton
                  stages={stages}
                  onClickSchedule={() => {
                    onOpenSidebar({
                      type: 'schedule',
                      mode: 'scheduling',
                    })
                  }}
                  onClickPrepCall={() => {
                    onOpenSidebar({
                      type: 'schedule',
                      mode: 'scheduling',
                      isPrepCall: true,
                    })
                  }}
                />
              </>
            )}
            {canEditRound && (
              <ChangeStage
                data={stages}
                currentStageId={currentStageId}
                roundId={round.id}
                onChange={refresh}
              />
            )}
            {candidateSettings?.enable_emails && (
              <SendEmail
                onClick={() =>
                  onOpenSidebar({
                    type: 'sendEmail',
                  })
                }
              />
            )}
            {canEditRound && (
              <NextStage
                data={stages}
                currentStageId={currentStageId}
                roundId={round.id}
                onChange={refresh}
              />
            )}
            {canAddFeedback && (
              <>
                <AddFeedback data={stages} currentStageId={currentStageId} />

                <GenerateFeedbackLink
                  stages={stages}
                  currentStageId={currentStageId}
                  round={round}
                  refresh={refresh}
                />
              </>
            )}
          </>
        )}

        {round?.latest_interview_stage && canEditRound && round.archived_reason && (
          <>
            <MoreBar.Action
              onClick={() => {
                onOpenSidebar({
                  type: 'candidate',
                })
              }}
            >
              Change opportunity
            </MoreBar.Action>
            {canUnarchive && <Unarchive onSuccess={refresh} round={round!} />}
          </>
        )}

        <MoreBar.Action
          useIcon={AddContact}
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.ADD_INTERVIEW_ROUND.GENERAL, {
                candidateId: id,
              }),
            )
          }}
        >
          Add new interview round
        </MoreBar.Action>

        {round && <RefreshInterviewStages roundId={round.id} onRefresh={refresh} />}

        {round?.latest_interview_stage && canViewEditOffer && !candidate?.employee_id && (
          <MoreBar.Action
            useIcon={Newspaper}
            onClick={() => {
              if (!hireButtonDisabled) {
                navigateTo(
                  pathToUrl(ROUTES.FORMS.OFFER_CREATION.GENERAL, { candidateId: id }),
                )
              }
            }}
            aria-disabled={hireButtonDisabled}
          >
            {offerSettings?.enable_offer_templates ? 'Create offer' : 'Hire'}
            {hireButtonDisabled && (
              <Text use="div" color={Color.RED}>
                Candidate has no phone number
              </Text>
            )}
          </MoreBar.Action>
        )}

        <PermissionTransferButton
          values={candidate}
          path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.CANDIDATE}
        />

        {round?.latest_interview_stage && <SendDataEmail id={id} />}

        {candidate && <SnoozeAction candidate={candidate} onAfterSubmit={refresh} />}

        {canAnonymize && (
          <MoreBar.Action
            useIcon={Anonymous}
            onClick={() =>
              navigateTo(pathToUrl(ROUTES.FORMS.ANONYMIZE_CANDIDATE.GENERAL, { id }))
            }
          >
            Anonymize data
          </MoreBar.Action>
        )}

        {round?.latest_interview_stage && canEditRound && !round.archived_reason && (
          <MoreBar.Action
            useIcon={Archive}
            variant="negative"
            onClick={() =>
              navigateTo(pathToUrl(ROUTES.FORMS.ARCHIVE_OPPORTUNITY.GENERAL, { id }))
            }
          >
            Archive opportunity
          </MoreBar.Action>
        )}
      </SettingsButtons>
    )
  }

  if (!candidate) {
    return <MultipleDetailsSkeleton maxWidth={720} />
  }

  let canAddFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.SeeAddFeedbackButton,
  )
  let canCancel = !!round?.field_options?.permissions?.includes(
    PermissionTypes.CancelPendingScorecard,
  )
  let canAnonymize = candidate.field_options?.permissions?.includes(
    PermissionTypes.AnonymizeCandidate,
  )

  if (previewStageMode) {
    canAddFeedback = false
    canCancel = false
    canAnonymize = false
  }

  return (
    <>
      {previewStageMode ? (
        <PageBody maxWidth={720}>
          <Box pb="s-32">
            <SettingsButtons pb="s-16">{showCandidateInformationAction}</SettingsButtons>
          </Box>
          {round && (
            <ChangeRoundWidget
              candidateId={candidate.id}
              round={round}
              onRefresh={refresh}
            />
          )}
          <ArchiveDetails round={round} />
          {renderStagesTable()}
          {renderComments()}
        </PageBody>
      ) : (
        <PageBody maxWidth={720}>
          <Box pb="s-32">
            {renderActions()}
            <VStack gap="s-16">
              {candidate.is_confidential && (
                <ConfidentialCandidateWidget candidate={candidate} />
              )}
              <SnoozeBanner candidate={candidate} onAfterSubmit={refresh} />
              <AnonymizeNotice date={candidate.anonymising_expected_date_time} />
              {canViewEditOffer && round && !isLoadingOfferSettings && (
                <UpcomingOfferNotifications
                  id={round.id}
                  signingEnabled={!!offerSettings?.enable_offer_signing}
                  onRefresh={refresh}
                />
              )}
              <UpcomingInterviewNotifications id={id} />
              <UpcomingOnlineTestNotifications
                candidateId={id}
                round={candidate.active_interview_round}
              />
              <PrepCallInterviewNotifications
                roundId={round?.id}
                onView={(interviewId, stageId) => {
                  onOpenSidebar({
                    type: 'schedule',
                    mode: 'view',
                    interviewId,
                    stageId,
                    isPrepCall: true,
                  })
                }}
              />
              <CVScreeningNotification
                candidateId={id}
                round={candidate.active_interview_round}
              />
            </VStack>

            <ArchiveDetails round={round} canEdit />
            {!candidate.active_interview_round && (
              <Box mt="s-16">
                <InterviewStartCard candidate={candidate} />
              </Box>
            )}
            {renderStagesTable()}
            {renderComments()}
          </Box>
        </PageBody>
      )}

      <Sidebar
        isOpen={openedSidebar?.type === 'stage'}
        onExit={() => {
          onOpenSidebar(undefined)
          setSelectedRow(undefined)
        }}
        scorecard={selectedRow as InterviewStageRowChildrenInternalInterface}
        title={selectedRow?.title}
        round={round}
        stageType={selectedStageType}
      />
      {candidateSettings?.enable_scheduling && openedSidebar?.type === 'schedule' && (
        <ScheduleSidebar
          selectedStageId={selectedStageId}
          stages={stages}
          totalStagesCount={stages.length}
          roundId={round?.id}
          onClose={() => {
            onOpenSidebar(undefined)
            setSelectedRow(undefined)
          }}
          onRefresh={() => {
            refetchStages()
            refreshStats()
          }}
          onSchedulingSuccess={stageId => {
            setSelectedStageId(stageId)
          }}
          initialMode={openedSidebar?.mode}
          interviewId={openedSidebar?.interviewId}
          isPrepCall={openedSidebar?.isPrepCall}
          onChangeInitialMode={mode => {
            onOpenSidebar({
              ...openedSidebar,
              mode,
            })
          }}
        />
      )}
      <CVPreviewSidebar
        id={candidate.id}
        isOpen={openedSidebar?.type === 'cv'}
        onClose={() => onOpenSidebar(undefined)}
      />
      <CandidateShortSummarySidebar
        isOpen={Boolean(openedSidebar?.type === 'shortSummary' && selectedRow)}
        onClose={() => onOpenSidebar(undefined)}
        stages={stages}
        stage={selectedRow as InterviewStageRowInterface | undefined}
        roundId={round?.id}
        onRefresh={() => {
          refresh()
          onOpenSidebar(undefined)
          setSelectedRow(undefined)
        }}
        canAddFeedback={canAddFeedback}
        canCancel={!!canCancel}
        onOpenSidebar={handleOpenSidebar}
        canViewEditOffer={!!canViewEditOffer}
        disableActions={previewStageMode}
        currentStageId={round?.latest_interview_stage?.id}
        candidateId={candidate.id}
      />
      <OnlineTestSummarySidebar
        isOpen={Boolean(openedSidebar?.type === 'onlineTestSummary' && selectedRow)}
        onClose={() => onOpenSidebar(undefined)}
        stage={selectedRow as InterviewStageRowInterface | undefined}
        roundId={round?.id}
        onRefresh={() => {
          refetchStages()
          refreshStats()
          onOpenSidebar(undefined)
          setSelectedRow(undefined)
        }}
        onOpenSidebar={handleOpenSidebar}
        candidateId={candidate.id}
      />
    </>
  )
}

export default Summary
