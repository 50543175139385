import React, { useState } from 'react'
import { Pencil } from '@revolut/icons'
import { Box, Color, Flex, IconButton } from '@revolut/ui-kit'

import TabBarNavigation, {
  TabBarTableNavigationProps,
} from '@src/features/TabBarNavigation/TabBarNavigation'
import { TabBarNavigationNames } from '@src/constants/tabBarNavigation'
import { SettingsSidebar } from '@src/features/TabBarNavigationEditable/SettingsSidebar'
import { ConfigurableTabBarNavigationInterface, TabBarSettingsInterface } from './types'
import { getTabBarBySettings, useTabBarSettings } from './common'

type Props = Omit<TabBarTableNavigationProps, 'tabs'> & {
  tabs: ConfigurableTabBarNavigationInterface[]
  name: TabBarNavigationNames
}
export const TabBarNavigationEditable = ({ tabs, ...props }: Props) => {
  const [openSidebar, setOpenSidebar] = useState(false)

  const storedSettings = useTabBarSettings(props.name)
  const defaultSettings = {
    reordered: tabs?.map(tab => tab.key) || [],
  }
  const initialSettings = storedSettings || defaultSettings

  const [tabsSettings, setTabsSettings] =
    useState<TabBarSettingsInterface>(initialSettings)

  const reorderedTabs = getTabBarBySettings(props.name, tabsSettings, tabs)

  return (
    <>
      <Flex flex={1} alignItems="center">
        <Box width="calc(100% - 24px)">
          <TabBarNavigation tabs={reorderedTabs} {...props} />
        </Box>
        <Box ml="auto">
          <IconButton
            size={16}
            useIcon={Pencil}
            color={Color.GREY_TONE_50}
            onClick={() => setOpenSidebar(true)}
          />
        </Box>
      </Flex>
      {openSidebar && (
        <SettingsSidebar
          tabs={tabs}
          settings={tabsSettings}
          name={props.name}
          isOpen={openSidebar}
          onClose={() => setOpenSidebar(false)}
          onSave={(newSettings: TabBarSettingsInterface | undefined) => {
            setTabsSettings(newSettings || defaultSettings)
          }}
        />
      )}
    </>
  )
}
