import React, { useMemo } from 'react'
import { ErrorWidget, Flex, StatusWidget, Spinner, Token } from '@revolut/ui-kit'
import { useGetReport } from '@src/api/dataAnalytics'
import { reportRunsTableRequests } from '@src/api/dataAnalytics'
import { EntityTypes } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import {
  RunReportResponseInterface,
  QueryRunStatuses,
} from '@src/interfaces/dataAnalytics'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { useTable } from '@components/Table/hooks'
import { TableReport } from './TableReport'

interface TabProps {
  data: TeamInterface | DepartmentInterface
  entity: EntityTypes.department | EntityTypes.team
  reportId: number
}

export const TabReport = ({ entity, data, reportId }: TabProps) => {
  const { data: reportData } = useGetReport(reportId)

  const reportRuns = useTable<RunReportResponseInterface>({
    getItems: reportRunsTableRequests(reportId),
  })

  const latestSuccessRun = useMemo(() => {
    return reportRuns.data.find(run => run.status === QueryRunStatuses.Success)
  }, [reportRuns.data])

  const latestErrorRun = useMemo(() => {
    return reportRuns.data.find(run => run.status === QueryRunStatuses.Error)
  }, [reportRuns.data])

  if (reportRuns.loading) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Spinner color={Token.color.blue} size={48} />
      </Flex>
    )
  }

  if (latestSuccessRun && reportData) {
    return (
      <TableReport
        entity={entity}
        entityData={data}
        reportData={reportData}
        run={latestSuccessRun}
        tableName={TableNames.CXBugs}
      />
    )
  }

  if (latestErrorRun) {
    return (
      <ErrorWidget>
        <ErrorWidget.Image />
        <ErrorWidget.Description>{latestErrorRun.error_detail}</ErrorWidget.Description>
      </ErrorWidget>
    )
  }

  return (
    <StatusWidget>
      <StatusWidget.Description>Report has not been run yet</StatusWidget.Description>
    </StatusWidget>
  )
}
