import {
  Action,
  Avatar,
  Box,
  DetailsCell,
  Group,
  Link,
  Subheader,
  Text,
  HStack,
} from '@revolut/ui-kit'
import React from 'react'
import {
  InterviewRoundInterface,
  InterviewRoundState,
} from '@src/interfaces/interviewTool'
import { useGetUnarchivedInterviewRounds } from '@src/api/recruitment/interviews'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { getAvatarUrl } from '@src/utils/employees'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

type Props = {
  round?: InterviewRoundInterface
  canEdit?: boolean
}

const ArchiveDetails = ({ round, canEdit }: Props) => {
  const isArchived = round?.archived_reason
  const { data: rounds } = useGetUnarchivedInterviewRounds(
    isArchived && round.candidate?.id ? round.candidate.id : null,
  )

  if (!isArchived) {
    return null
  }

  return (
    <Box mb="s-16">
      <Subheader variant="nested">
        <Subheader.Title>Archival details</Subheader.Title>
        {canEdit && (
          <Subheader.Side>
            <Action
              onClick={() => {
                navigateTo(
                  pathToUrl(ROUTES.FORMS.ARCHIVE_OPPORTUNITY.GENERAL, {
                    id: round.candidate.id,
                  }),
                )
              }}
            >
              Edit
            </Action>
          </Subheader.Side>
        )}
      </Subheader>
      {round.state === InterviewRoundState.archived && rounds && rounds.length > 0 && (
        <Box mb="s-16">
          <ActionWidget
            title={
              <Text variant="h6">
                Candidate has been archived for this opportunity. <br />
                There are {rounds.length} other opportunities to which the candidate has
                applied.
              </Text>
            }
          />
        </Box>
      )}
      <Group>
        <DetailsCell>
          <DetailsCell.Title>Reason</DetailsCell.Title>
          <DetailsCell.Content>{round.archived_reason?.name}</DetailsCell.Content>
        </DetailsCell>
        {round.archived_comment && (
          <DetailsCell>
            <DetailsCell.Title>Comments</DetailsCell.Title>
            <DetailsCell.Note whiteSpace="pre-line">
              {round.archived_comment}
            </DetailsCell.Note>
          </DetailsCell>
        )}
        {round.archived_employee && (
          <DetailsCell>
            <DetailsCell.Title>Archived by</DetailsCell.Title>
            <DetailsCell.Content>
              <Link
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                  id: round.archived_employee.id,
                })}
                use={InternalLink}
                target="_blank"
                color="inherit"
              >
                <HStack space="s-8">
                  <Avatar size={24} image={getAvatarUrl(round.archived_employee.avatar)}>
                    {round.archived_employee.avatar
                      ? ''
                      : round.archived_employee.display_name?.[0]}
                  </Avatar>
                  <Text>{round.archived_employee.display_name}</Text>
                </HStack>
              </Link>
            </DetailsCell.Content>
          </DetailsCell>
        )}
        {round.archived_date_time && (
          <DetailsCell>
            <DetailsCell.Title>Date</DetailsCell.Title>
            <DetailsCell.Content>
              {formatDate(round.archived_date_time)}
            </DetailsCell.Content>
          </DetailsCell>
        )}
      </Group>
    </Box>
  )
}

export default ArchiveDetails
