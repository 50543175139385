import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import {
  Absolute,
  Box,
  Button,
  Flex,
  Header,
  Modal,
  Relative,
  ThemeProvider,
  TransitionSlide,
} from '@revolut/ui-kit'
import React, { useRef, useState } from 'react'
import { PlayOutline } from '@revolut/icons'
import styled from 'styled-components'

const VideoPlayer = styled.video`
  object-fit: cover;
  border-radius: 12px;
  outline: none;
  width: 100%;
  height: auto;
`

const VideoOverlay = styled(Absolute)`
  cursor: pointer;
  background-color: black;
  border-radius: 12px;
`

const Video = () => {
  const [isPlayingVideo, setIsPlayingVideo] = useState(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  return (
    <Relative>
      {isPlayingVideo ? null : (
        <VideoOverlay
          top={0}
          bottom={0}
          left={0}
          right={0}
          zIndex={1}
          onClick={() => {
            videoRef.current?.play()
          }}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100%"
          >
            <Button variant="text" useEndIcon={PlayOutline}>
              Play video
            </Button>
          </Flex>
        </VideoOverlay>
      )}
      <VideoPlayer
        ref={videoRef}
        controls={isPlayingVideo}
        onPlay={() => setIsPlayingVideo(true)}
      >
        <source
          src="https://storage.googleapis.com/revoluters-public-files/linkAccountInstructions.mp4"
          type="video/mp4"
        />
      </VideoPlayer>
    </Relative>
  )
}

const VideoModal = () => {
  const [showVideoModal, setShowVideoModal] = useLocalStorage(
    LOCAL_STORAGE.SHOW_LINKED_ACCOUNTS_VIDEO_MODAL,
    true,
  )
  const nodeRef = useRef(null)

  return (
    <ThemeProvider elevated>
      <Modal open={showVideoModal} onClose={() => setShowVideoModal(false)}>
        <TransitionSlide nodeRef={nodeRef} in={showVideoModal} offsetY={-100}>
          <Box
            ref={nodeRef}
            bg="background"
            radius="popup"
            maxWidth={720}
            p="s-24"
            my="s-48"
            mx="auto"
          >
            <Header variant="main">
              <Header.Title>How to link your account</Header.Title>
            </Header>
            <Video />
            <Button onClick={() => setShowVideoModal(false)} mt="s-8">
              Close
            </Button>
          </Box>
        </TransitionSlide>
        <Modal.CloseButton aria-label="Close" onClick={() => setShowVideoModal(false)} />
      </Modal>
    </ThemeProvider>
  )
}

export default VideoModal
