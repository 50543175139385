import React from 'react'
import { FinalGrade } from '@src/interfaces/performance'
import { RevolutTheme, useTheme } from '@src/styles/theme'
import { getColor } from '@src/styles/colors'
import { Color, TextProps, Text } from '@revolut/ui-kit'

interface Props extends TextProps {
  value: FinalGrade
}

const getColorsAndGrade = (theme: RevolutTheme, grade?: FinalGrade) => {
  switch (grade) {
    case FinalGrade.Poor:
      return {
        color: getColor(theme, Color.RED),
        grade: 'Unsatisfactory',
      }
    case FinalGrade.AverageMinus:
      return {
        color: getColor(theme, Color.ORANGE),
        grade: 'Average -',
      }
    case FinalGrade.AveragePlus:
      return {
        color: getColor(theme, Color.FOREGROUND),
        grade: 'Average +',
      }
    case FinalGrade.Strong:
      return {
        color: getColor(theme, Color.GREEN),
        grade: 'Strong',
      }
    case FinalGrade.Exceptional:
      return {
        color: getColor(theme, Color.GREEN),
        grade: 'Exceptional',
      }
    default:
      return {
        color: getColor(theme, Color.FOREGROUND),
        grade: 'N/A',
      }
  }
}

const RecommendationGrade = ({ value, ...rest }: Props) => {
  const theme = useTheme()
  const config = getColorsAndGrade(theme, value)
  return (
    <Text use="div" color={config.color} {...rest}>
      {config.grade}
    </Text>
  )
}

export default RecommendationGrade
