import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { Box, chain, Color, Text, VStack } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@components/Page/Page'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { mapStatusToColor } from '@src/apps/People/Engagement/helpers'
import { formatDateTime } from '@src/utils/format'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useTheme } from '@src/styles/theme'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { SurveyPreview } from './Preview'
import { SurveyHistory } from './History'

export const Preview = () => {
  const params = useParams()
  const theme = useTheme()

  const { values } = useLapeContext<EngagementSurveyInterface>()

  const tabs = [
    {
      title: 'History',
      path: ROUTES.APPS.ENGAGEMENT.SURVEY.HISTORY,
      to: pathToUrl(ROUTES.APPS.ENGAGEMENT.SURVEY.HISTORY, params),
      component: SurveyHistory,
    },
    {
      title: 'Settings',
      path: ROUTES.APPS.ENGAGEMENT.SURVEY.PREVIEW,
      to: pathToUrl(ROUTES.APPS.ENGAGEMENT.SURVEY.PREVIEW, params),
      component: SurveyPreview,
    },
  ]

  const subtitle = (
    <VStack space="s-4">
      <Text fontWeight={500} color={Color.FOREGROUND} variant="secondary">
        {chain(
          'Survey',
          <Text color={mapStatusToColor(values, theme)}>{values.status?.name}</Text>,
        )}
      </Text>
      <Text variant="caption" color={Color.GREY_TONE_50}>
        {values.update_date_time
          ? `Last updated on ${formatDateTime(values.update_date_time)}`
          : ''}
      </Text>
    </VStack>
  )

  return (
    <PageWrapper>
      <PageHeader
        title={values.title}
        subtitle={subtitle}
        backUrl={ROUTES.APPS.ENGAGEMENT.SURVEYS_TABLE}
      />
      <Box mb="s-24">
        <TabBarNavigation tabs={tabs} />
      </Box>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}
