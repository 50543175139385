import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Cell, Flex, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { RowInterface } from '@src/interfaces/data'
import { RegimeInterface } from '@src/interfaces/timeOff'
import {
  regimeApproverGroupColumn,
  regimeDefaultApproverColumn,
  regimeGroupsColumn,
  regimeNameColumn,
  regimePublicHolidaysColumn,
  regimeWorkingDaysEndColumn,
  regimeWorkingDaysStartColumn,
} from '@src/constants/columns/timeOff'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@src/components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import { getTimeOffRegimes } from '@src/api/timeOff'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<RegimeInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.TIME_OFF_REGIMES.PREVIEW, { id })),
  cells: [
    {
      ...regimeNameColumn,
      width: 200,
    },
    {
      ...regimeGroupsColumn,
      width: 300,
    },
    {
      ...regimeDefaultApproverColumn,
      width: 150,
    },
    {
      ...regimeApproverGroupColumn,
      width: 150,
    },
    {
      ...regimeWorkingDaysStartColumn,
      width: 100,
    },
    {
      ...regimeWorkingDaysEndColumn,
      width: 100,
    },
    {
      ...regimePublicHolidaysColumn,
      width: 100,
    },
  ],
}

const Regimes = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddTimeOffRegimes)

  const table = useTable({ getItems: getTimeOffRegimes })

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-16">
          <Stat label="Total" val={table?.loading ? undefined : table?.count} mr="s-32" />
        </Flex>
        {canAdd && (
          <Box mb="s-16">
            <MoreBar>
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.TIME_OFF_REGIMES.GENERAL)}
                useIcon={Plus}
              >
                Add regime
              </MoreBar.Action>
            </MoreBar>
          </Box>
        )}
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.UserTimeOffRegimes}
            useWindowScroll
            row={ROW}
            {...table}
            noDataMessage="Regimes will appear here"
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default Regimes
