import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import { BREAKPOINTS, Button, Flex } from '@revolut/ui-kit'
import { ShareAndroid } from '@revolut/icons'
import {
  connectionPermissionsRequests,
  connectionPermissionsRequestsNew,
} from '@src/api/permissionManagement'
import {
  permissionsActionsColumn,
  permissionsEmployeeNameColumn,
  permissionsNotesColumn,
  permissionsPermissionColumn,
  permissionsStatusColumn,
  permissionsValidFromColumn,
  permissionsValidTillColumn,
} from '@src/constants/columns/permissions'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { PermissionsInterface } from '@src/interfaces/permissions'
import { PageBody } from '@src/components/Page/PageBody'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableWrapper } from '@components/Table/TableWrapper'
import { useTable } from '@components/Table/hooks'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import PermissionTransferRevokeDialogue from '@src/pages/PermissionTransfer/Dialogue/PermissionTransferRevokeDialogue'
import { pathToUrl } from '@src/utils/router'

const getRow = (
  onRevoke: (data: PermissionsInterface) => void,
): RowInterface<PermissionsInterface> => ({
  cells: [
    {
      ...permissionsEmployeeNameColumn,
      width: 250,
    },
    {
      ...permissionsPermissionColumn,
      width: 250,
    },
    {
      ...permissionsStatusColumn,
      width: 200,
    },
    {
      ...permissionsValidFromColumn,
      width: 200,
    },
    {
      ...permissionsValidTillColumn,
      width: 150,
    },
    {
      ...permissionsNotesColumn,
      width: 150,
    },
    {
      ...permissionsActionsColumn(onRevoke),
      width: 150,
    },
  ],
})

export interface OptionInterface {
  id: string
  name: string
  fields: string[]
}

export const PermissionTable = connect(() => {
  const params = useParams<{ id: string }>()
  const table = useTable<any>(connectionPermissionsRequests(params.id))
  const [isDialogueOpen, setDialogueOpen] = useState(false)
  const [revokingData, setRevokingData] = useState<PermissionsInterface | null>()

  const onRevoke = (data: PermissionsInterface) => {
    setDialogueOpen(true)
    setRevokingData(data)
  }

  return (
    <>
      <PermissionTransferRevokeDialogue
        isOpen={isDialogueOpen}
        onAfterSubmit={() => {
          setDialogueOpen(false)
          setRevokingData(null)
          table.refresh()
        }}
        permission={(revokingData || undefined) as PermissionsInterface}
        revoke={id =>
          connectionPermissionsRequestsNew(params.id).delete?.({ id }, params)
        }
        setOpen={setDialogueOpen}
      />
      <PageBody maxWidth={BREAKPOINTS.xxl}>
        <TableWrapper>
          <Flex mb="s-16">
            <Button
              size="sm"
              to={pathToUrl(ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.CONNECTION, {
                entityId: params.id,
                tab: 'form',
              })}
              variant="secondary"
              use={InternalLink}
              useIcon={ShareAndroid}
            >
              Add new permission
            </Button>
          </Flex>
          <AdjustableTable<PermissionsInterface>
            dataType="Permission"
            name={TableNames.ReportingAppConnectionsPermissions}
            noDataMessage="All permissions will appear here"
            row={getRow(onRevoke)}
            useWindowScroll
            {...table}
          />
        </TableWrapper>
      </PageBody>
    </>
  )
})
