import { Avatar, Bar, Button, Flex, HStack, Text, Widget } from '@revolut/ui-kit'
import React, { useEffect, useRef } from 'react'
import {
  InterviewFeedbackStatus,
  InterviewRoundInterface,
  InterviewStageRowChildrenInternalInterface,
  InterviewType,
} from '@src/interfaces/interviewTool'
import LapeForm from '@src/features/Form/LapeForm'
import { format } from 'date-fns'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { Link } from 'react-router-dom'
import Overview from '@src/pages/Forms/Candidate/InterviewProgress/components/Sidebar/Overview'
import Notes from '@src/pages/Forms/Candidate/InterviewProgress/components/Sidebar/Notes'
import ChangesDetected from '@src/pages/Forms/Candidate/InterviewProgress/components/Sidebar/ChangesDetected'
import { Document } from '@revolut/icons'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { connect } from 'lape'
import SidebarForm from '@src/pages/Forms/Candidate/InterviewProgress/components/Sidebar/SidebarForm'
import SideBar from '@components/SideBar/SideBar'
import { getAvatarUrl } from '@src/utils/employees'

type Props = {
  isOpen: boolean
  onExit: () => void
  scorecard?: InterviewStageRowChildrenInternalInterface
  round?: InterviewRoundInterface
  title?: string
  stageType?: InterviewType
}

const Sidebar = ({ isOpen, onExit, scorecard, round, title, stageType }: Props) => {
  const user = useSelector(selectUser)
  const scrollRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ block: 'center' })
  }, [scorecard?.id])

  if (!isOpen) {
    return null
  }

  const scorecardUrl = getLocationDescriptor(
    pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, {
      id: scorecard?.id,
    }),
  )

  const canEdit =
    scorecard?.is_feedback_editable && user.id === scorecard?.interviewer?.id

  return (
    <SideBar
      title={title}
      variant="wide"
      onClose={onExit}
      subtitle={
        scorecard?.interviewer ? (
          <HStack gap="s-8">
            <Avatar
              style={{ flexShrink: 0 }}
              size={24}
              image={getAvatarUrl(scorecard?.interviewer?.avatar)}
            >
              {!scorecard?.interviewer?.avatar ? (
                <Text>{scorecard?.interviewer?.full_name?.[0]}</Text>
              ) : undefined}
            </Avatar>
            {scorecard?.status === InterviewFeedbackStatus.completed ? (
              <Text>
                Submitted by {scorecard?.interviewer?.full_name} on
                {scorecard?.updated_date_time
                  ? format(new Date(scorecard.updated_date_time), ' d MMM yyyy, hh:mm a')
                  : ''}
              </Text>
            ) : (
              <Text>Pending with {scorecard?.interviewer?.full_name}</Text>
            )}
          </HStack>
        ) : undefined
      }
    >
      {scorecard?.status === InterviewFeedbackStatus.completed ? (
        <>
          <Bar mb="s-16" ref={scrollRef}>
            {canEdit && (
              <Button
                variant="secondary"
                size="sm"
                use={Link}
                // @ts-expect-error object works fine here, but UI kit expects string
                to={scorecardUrl}
              >
                Edit Scorecard
              </Button>
            )}
            <Button
              variant="secondary"
              size="sm"
              use={Link}
              // @ts-expect-error object works fine here, but UI kit expects string
              to={scorecardUrl}
              target="_blank"
            >
              Open in new tab
            </Button>
          </Bar>
          <Overview expectedSeniority={round?.seniority?.name} scorecard={scorecard} />
          {scorecard && <ChangesDetected scorecard={scorecard} />}
          <Notes scorecard={scorecard} />
          <LapeForm onSubmit={() => Promise.resolve({})}>
            <SidebarForm scorecard={scorecard} round={round} stageType={stageType} />
          </LapeForm>
        </>
      ) : (
        <>
          {scorecard?.id && (
            <Bar mb="s-16">
              <Button
                variant="secondary"
                size="sm"
                use={Link}
                // @ts-expect-error object works fine here, but UI kit expects string
                to={scorecardUrl}
                target="_blank"
              >
                View Scorecard
              </Button>
            </Bar>
          )}
          <Widget p="s-48">
            <Flex alignItems="center" flexDirection="column">
              <Document color="grey-20" />
              <Text color="grey-20" variant="h6">
                Scorecard not submitted yet
              </Text>
            </Flex>
          </Widget>
        </>
      )}
    </SideBar>
  )
}

export default connect(Sidebar)
