import React from 'react'
import { Color } from '@revolut/ui-kit'
import { Drag } from '@revolut/icons'
import styled from 'styled-components'

const DragIconWrapper = styled.div`
  cursor: move;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`

type DragIconProps = {
  iconProps?: {}
}

const DragIcon = ({ iconProps, ...props }: DragIconProps) => {
  return (
    <DragIconWrapper {...props}>
      <Drag color={Color.GREY_TONE_20} {...iconProps} />
    </DragIconWrapper>
  )
}

export default DragIcon
