import React from 'react'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { DocumentsBulkRequestInterface } from '@src/interfaces/documents'
import { selectorKeys } from '@src/constants/api'
import { getDocumentsBulkRequestStatusColor } from '@src/apps/People/Documents/BulkRequest/common'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

export const documentsBulkRequestsNameColumn: ColumnInterface<DocumentsBulkRequestInterface> =
  {
    type: CellTypes.text,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Name',
  }

export const documentsBulkRequestsCreatedByColumn: ColumnInterface<DocumentsBulkRequestInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'created_by.id',
    dataPoint: 'created_by.full_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Created by',
    insert: ({ data }) => <UserWithAvatar {...data.created_by} />,
  }

export const documentsBulkRequestsStatusColumn: ColumnInterface<DocumentsBulkRequestInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    colors: data => getDocumentsBulkRequestStatusColor(data.status),
  }
