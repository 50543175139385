import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import isArray from 'lodash/isArray'

import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import ImportFile from '@src/pages/Forms/ImportData/common/ImportFile'
import { uploadPaycycleFile } from '@src/api/importData'
import { navigateTo } from '@src/actions/RouterActions'
import { usePayCycle } from '@src/api/payroll'
import { chain, Text } from '@revolut/ui-kit'
import { formatPeriod } from '@src/utils/format'
import { getStatusColor } from '@src/components/CommonSC/General'
import { Statuses } from '@src/interfaces'

export const UploadFile = () => {
  const { id } = useParams<{ id: string }>()
  const [importPending, setImportPending] = useState(false)
  const { data: cycle } = usePayCycle(id)

  const onFileUpload = (file: File) => {
    setImportPending(true)

    uploadPaycycleFile(id, file)
      .then(() => {
        navigateTo(
          pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.REPORTS, {
            id,
          }),
        )
      })
      .catch(() => setImportPending(false))
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Upload file"
        subtitle={
          !!cycle &&
          chain(
            formatPeriod(cycle?.start_date, cycle?.end_date),
            <Text color={getStatusColor(cycle.status.id as Statuses)}>
              {cycle.status.name}
            </Text>,
          )
        }
        backUrl={ROUTES.PEOPLE.COMPENSATION.BONUSES}
      />
      <PageBody>
        <ImportFile
          importPending={importPending}
          supportedFormats={['PDF']}
          onFileUpload={file => {
            if (isArray(file)) {
              onFileUpload(file[0])
            } else if (file) {
              onFileUpload(file)
            }
          }}
        />
      </PageBody>
    </PageWrapper>
  )
}
