import React, { useState } from 'react'
import { DeliverablesAveragesInterface } from '@src/interfaces/deliverables'
import {
  MatrixColumnCellInterface,
  MatrixRowInterface,
} from '@components/Table/MatrixTable'
import { CellTypes } from '@src/interfaces/data'
import styled from 'styled-components'
import { ratingToColor } from '@src/utils/performance'
import { PerformanceRatingTitle } from '@src/constants/performance'
import DeliverablesCommentsPopup from '@src/pages/Forms/Probation/DeliverablesCommentsPopup'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { colorGetter } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import AdjustableMatrixTable from '@components/Table/AdjustableMatrixTable'

const Container = styled.div`
  margin: 24px 0 0 0;
`

const Cell = styled.div<{ backgroundColor?: string | null }>`
  padding: 0 16px;
  background-color: ${props => props.backgroundColor};
`

const Result = styled(Cell)`
  width: 100%;
  height: 49px;
  display: flex;
  align-items: center;
`

const AddComment = styled.button`
  border: 0;
  outline: none;
  background-color: transparent;
  color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
  height: 49px;
  text-align: left;
  width: 100%;

  &:active {
    color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
  }
`

const ViewComment = styled.button`
  border: 0;
  outline: none;
  background-color: transparent;
  color: ${colorGetter(Color.BLUE)};
  height: 50px;
  text-align: left;
  width: 100%;
`

interface Props {
  data: DeliverablesAveragesInterface
}

interface CellInterface<T> extends MatrixColumnCellInterface<T> {
  resizeWidth?: number
}

interface RowInterface<T> extends MatrixRowInterface<T> {
  cells: CellInterface<T>[]
}

const ROW = (
  handleCommentClick: () => void,
  hasComment: boolean,
): RowInterface<DeliverablesAveragesInterface> => ({
  cells: [
    {
      type: CellTypes.insert,
      title: '',
      width: 784,
      insert: () => {
        return <Cell>Summary</Cell>
      },
    },
    {
      type: CellTypes.insert,
      title: 'Complexity',
      width: 142,
      resizeWidth: 10,
      insert: ({ data, theme }) => {
        return (
          <Result backgroundColor={ratingToColor(theme, data.avg_complexity_rating)}>
            {PerformanceRatingTitle[data.avg_complexity_rating]}
          </Result>
        )
      },
    },
    {
      type: CellTypes.insert,
      title: 'Speed',
      width: 142,
      resizeWidth: 10,
      insert: ({ data, theme }) => {
        return (
          <Result backgroundColor={ratingToColor(theme, data.avg_speed_rating)}>
            {PerformanceRatingTitle[data.avg_speed_rating]}
          </Result>
        )
      },
    },
    {
      type: CellTypes.insert,
      title: 'Quality',
      width: 142,
      resizeWidth: 10,
      insert: ({ data, theme }) => {
        return (
          <Result backgroundColor={ratingToColor(theme, data.avg_quality_rating)}>
            {PerformanceRatingTitle[data.avg_quality_rating]}
          </Result>
        )
      },
    },
    {
      type: CellTypes.insert,
      title: 'Comments',
      width: 156,
      insert: () => {
        return (
          <Cell>
            {hasComment ? (
              <ViewComment type="button" onClick={handleCommentClick}>
                View
              </ViewComment>
            ) : (
              <AddComment type="button" onClick={handleCommentClick}>
                Click to add
              </AddComment>
            )}
          </Cell>
        )
      },
    },
  ],
})

const Averages = ({ data }: Props) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const hasComment = !!values.review_data.extra_deliverables_overall_feedback
  const handleCommentClick = () => {
    setIsOpenPopup(true)
  }

  return (
    <>
      <Container>
        <AdjustableMatrixTable
          rows={ROW(handleCommentClick, hasComment)}
          data={[data]}
          noPadding
          autoResize
        />
      </Container>
      <DeliverablesCommentsPopup
        isOpen={isOpenPopup}
        onClose={() => {
          setIsOpenPopup(false)
        }}
      />
    </>
  )
}

export default Averages
