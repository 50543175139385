import { Statuses } from '@src/interfaces'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import React from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Button } from '@revolut/ui-kit'
import { goBack } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router-dom'
import { getCleanValuesBeforeSave } from '@src/utils/performance'

export const SaveDraftButton = connect(() => {
  const { employeeId } = useParams<{ employeeId: string }>()
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, submit, isSubmitting } = form

  if (
    values.status !== Statuses.draft &&
    values.status !== Statuses.pending &&
    values.status !== Statuses.rejected
  ) {
    return null
  }

  const onSaveDraft = () => {
    form.values = getCleanValuesBeforeSave(values)
    form.values.status = Statuses.draft

    return submit().then(() => {
      goBack(
        pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
          id: employeeId,
        }),
      )
    })
  }

  return (
    <Button
      onClick={onSaveDraft}
      variant="secondary"
      pending={isSubmitting && values.status === Statuses.draft}
      disabled={isSubmitting}
      width={167}
      elevated
      data-testid="btn-save-draft"
    >
      Save to Drafts
    </Button>
  )
})
