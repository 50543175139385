import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationDescription,
  notificationsActionColumn,
  notificationsEmployeedColumn,
  notificationsPriorityColumn,
  notificationsReceivedColumn,
  notificationsTextColumn,
} from '@src/constants/columns/notifications'
import { notificationsBulkUploadRequests } from '@src/api/notifications'
import { getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

const BulkUpload = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const isDashboard = type === 'dashboard'

  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationDescription(employeeId),
        width: 370,
      },
      {
        ...notificationsEmployeedColumn(isDashboard),
        width: isDashboard ? 100 : 200,
      },
      {
        ...notificationsReceivedColumn,
        width: 130,
      },
      {
        ...notificationsPriorityColumn,
        width: 100,
      },
      {
        ...notificationsTextColumn,
        title: 'Status',
        colors: (data, theme) => {
          switch (data.text) {
            case 'Success':
              return getColor(theme, Color.GREEN)
            case 'Failure':
              return getColor(theme, Color.RED)
            default:
              return getColor(theme, Color.ORANGE)
          }
        },
        width: 100,
      },
      {
        ...notificationsActionColumn,
        width: 200,
      },
    ],
  }

  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsBulkUploadRequests(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default BulkUpload
