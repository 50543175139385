import React, { useEffect, useMemo, useState } from 'react'
import { isArray, sumBy } from 'lodash'
import { Flex, TableWidget } from '@revolut/ui-kit'
import { getQueryRunResult, getQueryRunResultColumns } from '@src/api/dataAnalytics'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import {
  ReportInterface,
  RunReportResponseInterface,
} from '@src/interfaces/dataAnalytics'
import { TableNames } from '@src/constants/table'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { orderingToSort } from '@src/pages/Forms/DataAnalyticsReportForm/hooks/useTableSettings'

interface Props {
  run: RunReportResponseInterface
}

export const PreviewTable = ({ run }: Props) => {
  const { values } = useLapeContext<ReportInterface>()
  const initialSortBy = orderingToSort(values.output_format.ordering || [])
  const [tableSummary, setTableSummary] = useState<
    { value: string | null; count: number }[]
  >([])

  const table = useTable(
    {
      getItems: getQueryRunResult(run.query_run?.id, values.query.id),
    },
    undefined,
    initialSortBy,
  )

  const tableRows = useMemo(() => {
    const cells = values.output_format.query_column_mappings.map(
      (key, index) =>
        ({
          type: CellTypes.insert,
          idPoint: key.name,
          dataPoint: key.name,
          sortKey: key.name,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: key.alias || key.name,
          width: 300,
          insert: ({ data }) => {
            if (isArray(data)) {
              return typeof data[index] === 'boolean'
                ? data[index].toString()
                : data[index]
            }

            return typeof data[key.name] === 'boolean'
              ? data[key.name].toString()
              : data[key.name]
          },
        } as ColumnCellInterface<any>),
    )

    return { cells }
  }, [])

  const fetchSummary = async () => {
    const runResult = await getQueryRunResultColumns(
      run.query_run.id!,
      values.query.id,
      values.output_format.summary_column!,
    )

    if (runResult.data) {
      setTableSummary(runResult.data)
    }
  }

  useEffect(() => {
    if (run.query_run?.id && values.output_format.summary_column) {
      fetchSummary()
    } else {
      setTableSummary([])
    }
  }, [run.query_run?.id])

  return (
    <TableWidget>
      <TableWidget.Info>
        {values.output_format.summary_column && (
          <Flex gap="s-32">
            {tableSummary.map(item => (
              <Stat
                key={item.value}
                label={item.value === null ? '(blank)' : item.value}
                val={item.count}
              />
            ))}
            <Stat label="total" val={sumBy(tableSummary, 'count')} />
          </Flex>
        )}
      </TableWidget.Info>
      <TableWidget.Table>
        <AdjustableTable
          {...table}
          count={table.count}
          hideCount
          name={TableNames.ReportingAppReportsPreviewTable}
          row={tableRows}
          useWindowScroll
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
