import React, { forwardRef } from 'react'
import { InfoOutline } from '@revolut/icons'
import { Color, ActionWidget as ActionWidgetComponent, Box } from '@revolut/ui-kit'
import { BoxInnerProps } from '@revolut/ui-kit/types/dist/components/Box/Box'
import { TextStyle } from '@revolut/ui-kit'

export interface ActionWidgetProps extends BoxInnerProps {
  title?: React.ReactNode
  text?: React.ReactNode
  children?: React.ReactNode
  bg?: string
  avatarColor?: string
  avatar?: React.ReactNode
  textVariant?: TextStyle
}

const ActionWidget = forwardRef<HTMLDivElement, ActionWidgetProps>(
  ({ title, text, avatarColor, children, bg, avatar, textVariant, ...props }, ref) => {
    return (
      <Box {...props} ref={ref}>
        <ActionWidgetComponent bg={bg}>
          {title && <ActionWidgetComponent.Title>{title}</ActionWidgetComponent.Title>}
          <ActionWidgetComponent.Avatar>
            {avatar !== undefined ? (
              avatar
            ) : (
              <InfoOutline color={avatarColor || Color.RED} />
            )}
          </ActionWidgetComponent.Avatar>
          {text && (
            <ActionWidgetComponent.Content variant={textVariant}>
              {text}
            </ActionWidgetComponent.Content>
          )}
          {children && (
            <ActionWidgetComponent.Actions>{children}</ActionWidgetComponent.Actions>
          )}
        </ActionWidgetComponent>
      </Box>
    )
  },
)

export default ActionWidget
