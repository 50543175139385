import React, { useEffect } from 'react'
import { Box, Color, VStack, Text } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useLapeContext, useLapeField } from '@src/features/Form/LapeForm'
import { SelectorType } from '@src/interfaces/selectors'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import { useFormValidator } from '@src/features/Form/FormValidator'
import RadioSelectInput, {
  RadioSelectInputProps,
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import DropdownAction from '@components/Inputs/DropdownAction/DropdownAction'

interface LapeDataBaseSelectProps<Value> extends RadioSelectInputProps<Value> {
  name?: string
  selector?: SelectorType
  initialValue?: Value
  optional?: boolean
  options?: RadioSelectOption<Value>[]
}

const LapeDataBaseSelect = <Value extends { id: number | string }>({
  name = 'sql_query_db',
  selector,
  initialValue,
  optional,
  message,
  options,
  ...props
}: LapeDataBaseSelectProps<Value>) => {
  const lapeProps = useLapeField(name)
  const { value, onChange, touched, error } = lapeProps
  const { values } = useLapeContext()
  const formValidator = useFormValidator()

  useEffect(() => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    if (!values[name] && initialValue) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      values[name] = initialValue
    }
  }, [])

  const commonInputProps = getCommonInputProps(
    {
      name,
      required: !optional,
      placeholder: props.label,
      message,
      ...props,
    },
    lapeProps,
    !!formValidator?.validated,
  )

  const hasError = (touched && error) || commonInputProps.hasError

  return (
    <RadioSelectInput
      value={value}
      onChange={onChange}
      disabled={commonInputProps.disabled}
      selector={selector}
      options={options}
      renderInput={(open, setOpen, ref) => (
        <VStack space="s-4">
          <DropdownAction
            open={open}
            onClick={() => setOpen(!open)}
            ref={ref}
            data-testid="database-selector-label"
            data-name={name}
          >
            <Box color={hasError ? Color.RED : undefined}>
              {props.label || value?.name}
            </Box>
          </DropdownAction>

          {hasError && commonInputProps.message ? (
            <Text color={Color.RED} fontSize="small">
              {commonInputProps.message}
            </Text>
          ) : undefined}
        </VStack>
      )}
      searchable={false}
      {...props}
    />
  )
}

export default connect(LapeDataBaseSelect)
