import React, { useState } from 'react'
import SideBar from '@components/SideBar/SideBar'
import {
  MoreBar,
  Button,
  Widget,
  Portal,
  Link,
  Flex,
  Text,
  Box,
  Avatar,
} from '@revolut/ui-kit'
import { LinkExternal, QuestionOutline } from '@revolut/icons'
import { KPIS_FAQ, PERFORMANCE_SERVICE_DESK } from '@src/constants/externalLinks'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { KpiHelpButtonFaq } from '@src/features/KPI/KpiHelpButtonFaq'

export const KpiHelpButton = ({
  kpiChampions = [],
}: {
  kpiChampions?: EmployeeOptionInterface[]
}) => {
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  // showing avatars for first three kpiChampions
  // if we have more than three, they'll be collapsed to an icon with number of leftChampionsToShow
  // if kpiChampions defined with a fallback to an empty array, championsToShow will also be []
  const showChampionsCount = 3
  const championsToShow = kpiChampions.slice(0, showChampionsCount)
  const leftChampionsToShow = kpiChampions.slice(showChampionsCount)
  const hasChampionsToShow = !!championsToShow?.length

  const onHover = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  const ExpandingIcons = () => (
    <Box width="s-24" onMouseOver={onHover} onMouseLeave={onMouseLeave}>
      {isHovered ? (
        <Flex gap="s-8">
          {leftChampionsToShow?.map(champion => (
            <UserWithAvatar key={champion.id} {...champion} compact />
          ))}
        </Flex>
      ) : (
        <Avatar size={24} color="grey-tone-50">
          +{leftChampionsToShow.length}
        </Avatar>
      )}
    </Box>
  )

  const showChampions = (
    firstChampions: EmployeeOptionInterface[],
    leftChampions?: EmployeeOptionInterface[],
  ) => (
    <>
      <Text variant="h6">
        Your KPI champions have increased permissions to help when managing KPIs.
      </Text>
      <Flex gap="s-8" my="s-16">
        {firstChampions.map(champion => (
          <UserWithAvatar
            key={champion.id}
            {...champion}
            compact
            data-testid={`kpi_champ_${champion.full_name}`}
          />
        ))}
        {!!leftChampions?.length && <ExpandingIcons />}
      </Flex>
    </>
  )

  return (
    <>
      <MoreBar.Action
        onClick={() => setSidebarOpened(!sidebarOpened)}
        useIcon={QuestionOutline}
      >
        Help & More Info
      </MoreBar.Action>
      <Portal>
        <SideBar
          title="KPI Guidance"
          isOpen={sidebarOpened}
          onClose={() => setSidebarOpened(false)}
          variant="medium"
          height="100%"
        >
          {!isCommercial && (
            <Button
              variant="secondary"
              size="sm"
              useIcon={LinkExternal}
              use="a"
              target="_blank"
              href={KPIS_FAQ}
              mb="s-16"
            >
              All FAQs
            </Button>
          )}
          {(!isCommercial || hasChampionsToShow) && (
            <Widget p="s-12">
              {hasChampionsToShow && showChampions(championsToShow, leftChampionsToShow)}
              {!isCommercial && (
                <Text variant="caption">
                  Further support is available if required from the{' '}
                  <Link href={PERFORMANCE_SERVICE_DESK} target="_blank">
                    Performance Service Desk.
                  </Link>{' '}
                </Text>
              )}
            </Widget>
          )}
          <KpiHelpButtonFaq />
        </SideBar>
      </Portal>
    </>
  )
}
