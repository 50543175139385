import React from 'react'
import HelpSections from '@src/pages/Forms/EmployeePerformance/components/HelpSections/HelpSections'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewScorecardInterface } from '@src/interfaces/performance'

const DeliverablesHelp = () => {
  const { values } = useLapeContext<ReviewScorecardInterface>()

  return (
    <HelpSections
      sections={[
        `Please provide an overall assessment on all ${values.reviewed_employee.display_name}'s deliverables this cycle.`,
        `Add links to a justification field as an evidence of your scores.`,
      ]}
    />
  )
}

export default DeliverablesHelp
