import { ReviewCategory } from '@src/interfaces/performance'
import { ROUTES } from '@src/constants/routes'
import { IconComponentType, Pencil, Plus, Profile } from '@revolut/icons'
import { ProbationCheckpoint } from '@src/interfaces/probationReview'
import { Button, Flex, useTooltip } from '@revolut/ui-kit'
import React from 'react'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import AvatarSnippet from '@components/AvatarSnippet/AvatarSnippet'
import { useGetCommitteeResult } from '@src/api/probationReview'
import { formatDate } from '@src/utils/format'

enum ButtonStates {
  Add = 'Add',
  View = 'View',
  Edit = 'Edit',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Decision',
  [ButtonStates.Edit]: 'Edit Decision',
  [ButtonStates.View]: 'View Decision',
}

const ButtonIcon: { [key: string]: IconComponentType } = {
  [ButtonStates.Add]: Plus,
  [ButtonStates.Edit]: Pencil,
}

const ReviewResultPath: { [key: string]: string } = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_COMMITTEE_RESULT.GENERAL,
  [ReviewCategory.PIP_V2]: ROUTES.FORMS.PIP_COMMITTEE_RESULT.GENERAL,
}

export const CommitteeStepReviewers = ({
  checkpoint,
  reviewCategory,
  cycleId,
  employeeId,
}: {
  checkpoint: ProbationCheckpoint
  reviewCategory: ReviewCategory
  cycleId: string
  employeeId: number
}) => {
  const tooltip = useTooltip()

  const { data: decision } = useGetCommitteeResult(
    employeeId,
    checkpoint?.decision?.id,
    cycleId,
  )

  const buttonDisabled = !checkpoint?.can_be_submitted && !checkpoint?.decision

  const onClickAddReview = () => {
    navigateTo(
      pathToUrl(ReviewResultPath[reviewCategory], {
        employeeId,
        cycleId,
        id: checkpoint?.decision?.id,
      }),
    )
  }

  let state = ButtonStates.View
  if (checkpoint?.can_be_submitted) {
    state = !checkpoint.decision ? ButtonStates.Add : ButtonStates.Edit
  }

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <AvatarSnippet
        avatar={decision?.reviewer.avatar || undefined}
        badge={checkpoint.decision ? Statuses.completed : Statuses.pending}
        fullName={
          checkpoint.decision?.reviewer
            ? checkpoint.decision.reviewer.full_name
            : 'Committee team'
        }
        icon={checkpoint.decision?.reviewer ? undefined : Profile}
        text={formatDate(checkpoint.checkpoint_date_time)}
      />
      {!buttonDisabled && (
        <Button
          useIcon={ButtonIcon[state]}
          variant="secondary"
          size="sm"
          onClick={onClickAddReview}
          {...tooltip.getAnchorProps()}
        >
          {ButtonTitles[state]}
        </Button>
      )}
    </Flex>
  )
}
