import React, { useRef, useState } from 'react'
import { FilterButton, chain, Dropdown } from '@revolut/ui-kit'
import produce from 'immer'
import { connect } from 'lape'
import { FilterByInterface } from '@src/interfaces/data'
import { Filter, RadiobuttonOff, RadiobuttonOn } from '@revolut/icons'

interface FilterDropdownButtonItem {
  id: string
  name: string
  count?: number | null
}

type Props = {
  disabled?: boolean
  onChange: (filters: FilterByInterface[]) => void
  items: FilterDropdownButtonItem[]
  filters: FilterByInterface[]
}

const FilterDropdownButtons = ({ items, onChange, disabled, filters }: Props) => {
  const [openFilters, setOpenFilters] = useState(false)
  const filtersRef = useRef<HTMLButtonElement>(null)
  const initialFilters = items.reduce((obj, val) => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    obj[val.id] = !!filters.find(item => item.columnName === val.id)?.filters?.length
    return obj
  }, {})

  const [filtersList, setFiltersList] = useState(initialFilters)

  const onClick = (item: FilterDropdownButtonItem) => {
    const updateFiltersList = produce(filtersList, draft => {
      Object.keys(draft).forEach(id => {
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        draft[id] = item.id === id ? !draft[id] : false
      })
    })

    setFiltersList(updateFiltersList)

    onChange(
      Object.keys(updateFiltersList).map(columnName => ({
        columnName,
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        filters: updateFiltersList[columnName]
          ? [
              {
                id: 'True',
                name: 'True',
              },
            ]
          : [],
      })),
    )
  }

  return (
    <>
      <FilterButton
        ref={filtersRef}
        useIcon={Filter}
        onClick={() => setOpenFilters(!openFilters)}
      >
        Filters
      </FilterButton>
      <Dropdown
        open={openFilters}
        anchorRef={filtersRef}
        autoClose
        minWidth={300}
        disabled={disabled}
        placement="top-start"
      >
        {items.map(item => (
          <Dropdown.Item
            key={item.id}
            use="button"
            color="foreground"
            onClick={() => onClick(item)}
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            useIcon={filtersList[item.id] ? RadiobuttonOn : RadiobuttonOff}
            iconSize={16}
          >
            {chain(item.name, item.count)}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}

export default connect(FilterDropdownButtons)
