import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useParams } from 'react-router-dom'
import { goBack } from '@src/actions/RouterActions'
import React, { useState } from 'react'
import { MoreBar, useToggleContext } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { Delete } from '@revolut/icons'
import { FieldOptions, Statuses } from '@src/interfaces'
import { AxiosPromise } from 'axios'
import ConfirmationDialog, {
  ConfirmationDialogInterface,
} from '@src/features/Popups/ConfirmationDialog'
import { SettingsButtonsInstanceProps } from '@src/features/SettingsButtons'
import { EntityPermissions } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'

interface DeleteButtonProps extends SettingsButtonsInstanceProps {
  deleteApi: (data: any, params: { id: string }) => AxiosPromise<any>
  onBeforeDelete?: () => void
  onAfterDelete?: () => void
  title: string
  backUrl?: string
  dialogProps?: Partial<ConfirmationDialogInterface>
  label?: string
}

const DeleteButton = ({
  deleteApi,
  backUrl,
  title,
  onBeforeDelete,
  isVisible = true,
  globalPermissions,
  entityPermissions = EntityPermissions.Delete,
  onAfterDelete,
  dialogProps,
  label = 'Delete',
}: DeleteButtonProps) => {
  const [loading, setLoading] = useState(false)
  const [openedDialog, setOpenedDialog] = useState(false)
  const toggle = useToggleContext()
  const { values } =
    useLapeContext<{ status: Statuses; field_options: FieldOptions; id: number }>()
  const params = useParams<{ id: any }>()
  const permissions = useSelector(selectPermissions)

  const canDelete =
    values.field_options?.actions?.includes(entityPermissions) ||
    globalPermissions?.some(p => permissions.includes(p))

  const onDelete = async () => {
    try {
      onBeforeDelete?.()
      setLoading(true)
      await deleteApi(values, params)
      onAfterDelete?.()
      backUrl && goBack(backUrl)
    } catch {
      setOpenedDialog(false)
    } finally {
      setLoading(false)
    }

    return null
  }

  if (!isVisible || !canDelete) {
    return null
  }

  const isDraft = values.status === Statuses.draft

  const confirmationLabel = isDraft
    ? `Deleting Draft ${title.toLowerCase()}`
    : `Deleting ${title}`
  const confirmationBody = isDraft
    ? 'Are you sure you want to delete this draft?'
    : `Deleting ${title} might affect other items that depend on this ${title}. \n Are you sure you want to delete this item?`

  const handleClose = () => {
    toggle?.(false)
    setOpenedDialog(false)
  }

  return (
    <>
      <ConfirmationDialog
        open={openedDialog}
        onClose={handleClose}
        onConfirm={onDelete}
        loading={loading}
        onReject={handleClose}
        label={confirmationLabel}
        body={confirmationBody}
        {...dialogProps}
      />
      <MoreBar.Action
        useIcon={Delete}
        variant="negative"
        onClick={e => {
          e.preventDefault()
          setOpenedDialog(true)
        }}
      >
        {label}
      </MoreBar.Action>
    </>
  )
}

export default connect(DeleteButton)
