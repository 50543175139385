import React from 'react'
import styled from 'styled-components'
import { Avatar, Flex, Item, Text, Token } from '@revolut/ui-kit'
import { Check, IconComponentType, Cross, SandWatch } from '@revolut/icons'
import { Statuses } from '@src/interfaces'
import { AvatarType } from '@src/interfaces/employees'
import { getAvatarUrl } from '@src/utils/employees'

type Props = {
  firstName?: string
  lastName?: string
  text?: string
  label?: string
  avatar?: AvatarType
  badge?: Statuses
  fullName?: string
  icon?: IconComponentType
  noItemWrap?: boolean
}

const Wrap = styled(Item)`
  padding: 0;
`

const AvatarSnippet = ({
  firstName,
  lastName,
  text,
  label,
  avatar,
  badge,
  fullName,
  icon,
  noItemWrap = false,
}: Props) => {
  const getBadge = () => {
    if (!badge) {
      return null
    }

    switch (badge) {
      case Statuses.completed:
      case Statuses.completed_late:
        return (
          <Avatar.Badge bg={Token.color.green} position="bottom-right" useIcon={Check} />
        )
      case Statuses.rejected:
        return (
          <Avatar.Badge bg={Token.color.red} position="bottom-right" useIcon={Cross} />
        )
      case Statuses.expired:
        return (
          <Avatar.Badge
            bg={Token.color.greyTone50}
            position="bottom-right"
            useIcon={Cross}
          />
        )
      default:
        return (
          <Avatar.Badge
            bg={Token.color.orange}
            position="bottom-right"
            useIcon={SandWatch}
          />
        )
    }
  }

  const renderInitials = () => {
    if (icon || avatar) {
      return null
    }

    if (firstName && lastName) {
      return (
        <>
          {firstName[0]}
          {lastName[0]}
        </>
      )
    }

    if (fullName) {
      return fullName[0]
    }

    return null
  }

  if (noItemWrap) {
    return (
      <Flex alignItems="center" gap="s-16">
        <Avatar image={getAvatarUrl(avatar)} useIcon={icon}>
          {renderInitials()}
          {getBadge()}
        </Avatar>
        <Flex flexDirection="column">
          <Flex>
            <Text variant="primary" color={Token.color.foreground}>
              {fullName}
            </Text>
            {label && (
              <Text color={Token.color.greyTone50} fontWeight={400}>
                {label}
              </Text>
            )}
          </Flex>
          {text && <Text variant="caption">{text}</Text>}
        </Flex>
      </Flex>
    )
  }

  return (
    <Wrap>
      <Item.Avatar>
        <Avatar image={getAvatarUrl(avatar)} useIcon={icon}>
          {renderInitials()}
          {getBadge()}
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          {fullName}{' '}
          {label && (
            <Text color={Token.color.greyTone50} fontWeight={400}>
              {label}
            </Text>
          )}
        </Item.Title>
        {text && <Item.Description>{text}</Item.Description>}
      </Item.Content>
    </Wrap>
  )
}

export default AvatarSnippet
