import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { HiringPipelineInterface } from '@src/interfaces/hiringPipelines'
import HiringPipelineChart from '@components/Charts/HiringPipelineChart/HiringPipelineChartContent'
import ChartInsideTooltip from '@components/Charts/ChartInsideTooltip/ChartInsideTooltip'
import { HStack, Link } from '@revolut/ui-kit'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { Statuses } from '@src/interfaces'
import { getJobPostingStatusColor } from '@src/pages/Forms/JobPosting/utils'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { LinkExternal } from '@revolut/icons'

export const hiringPipelineNameColumn: ColumnInterface<HiringPipelineInterface> = {
  type: CellTypes.text,
  idPoint: 'job_posting.id',
  dataPoint: 'job_posting.name',
  sortKey: 'job_posting__name',
  filterKey: 'job_posting__id',
  selectorsKey: selectorKeys.job_postings,
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.HIRING_PIPELINE_OVERVIEW.CANDIDATES, { id: data.id }),
  title: 'Pipeline',
}

export const hiringPipelineJobPostingColumn: ColumnInterface<HiringPipelineInterface> = {
  type: CellTypes.insert,
  idPoint: 'job_posting__id',
  dataPoint: 'job_posting__id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (
    <Link
      use="a"
      href={pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
        id: data.job_posting?.id,
        specId: data.job_posting.specialisation?.id,
      })}
      target="_blank"
      onClick={e => e.stopPropagation()}
    >
      <LinkExternal size={15} />
    </Link>
  ),
  title: 'Job posting',
}

export const hiringPipelineSpecialisationColumn: ColumnInterface<HiringPipelineInterface> =
  {
    type: CellTypes.text,
    idPoint: 'job_posting.specialisation.id',
    dataPoint: 'job_posting.specialisation.name',
    sortKey: 'job_posting__specialisation__name',
    filterKey: 'job_posting__specialisation__id',
    selectorsKey: selectorKeys.specialisations,
    title: 'Specialisation',
  }

export const hiringPipelineRecruiterColumn: ColumnInterface<HiringPipelineInterface> = {
  type: CellTypes.insert,
  idPoint: 'job_posting__recruiter__id',
  dataPoint: 'job_posting__recruiter__id',
  sortKey: 'job_posting__recruiter__name',
  filterKey: 'job_posting__recruiter__id',
  selectorsKey: selectorKeys.employee,
  title: 'Recruiter',
  insert: ({ data }) => <UserWithAvatar {...data.job_posting.recruiter} />,
}

export const hiringPipelineHiringManagerColumn: ColumnInterface<HiringPipelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'job_posting__requisition_postings__requisition__line_manager',
    dataPoint: 'job_posting__requisition_postings__requisition__line_manager',
    sortKey: null,
    filterKey: 'job_posting__requisition_postings__requisition__line_manager',
    selectorsKey: selectorKeys.employee,
    title: 'Hiring managers',
    insert: ({ data }) => (
      <HStack gap="s-8">
        {data.job_posting.requisition_postings
          .reduce<EmployeeOptionInterface[]>((acc, item) => {
            const employee = item.requisition.line_manager
            const opened =
              item.requisition.status === Statuses.opened ||
              item.requisition.status === Statuses.pending
            if (employee && !acc.find(el => el.id === employee.id) && opened) {
              return [...acc, employee]
            }
            return acc
          }, [])
          .map((employee, _, arr) => (
            <UserWithAvatar
              key={employee.id}
              avatar={employee.avatar}
              id={employee.id}
              name={employee.display_name}
              compact={arr.length > 1}
            />
          ))}
      </HStack>
    ),
  }

export const hiringPipelineLocationsColumn: ColumnInterface<HiringPipelineInterface> = {
  type: CellTypes.insert,
  idPoint: 'job_posting.locations',
  dataPoint: 'job_posting.locations',
  sortKey: null,
  filterKey: 'job_posting__locations__id',
  selectorsKey: selectorKeys.location,
  title: 'Locations',
  insert: ({ data }) =>
    data.job_posting.locations?.map(item => item.name)?.join(', ') || '',
}

export const hiringPipelinePublishedStatusColumn: ColumnInterface<HiringPipelineInterface> =
  {
    type: CellTypes.text,
    idPoint: 'job_posting.status_display',
    dataPoint: 'job_posting.status_display',
    sortKey: 'job_posting__status',
    filterKey: 'job_posting__status',
    selectorsKey: selectorKeys.postings_status,
    colors: (data, theme) => getJobPostingStatusColor(data.job_posting.status, theme),
    title: 'Status',
  }

export const hiringPipelineRequisitionsColumn: ColumnInterface<HiringPipelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'job_posting__requisition_postings__requisition',
    dataPoint: 'job_posting__requisition_postings__requisition',
    sortKey: null,
    filterKey: 'job_posting__requisition_postings__requisition',
    selectorsKey: selectorKeys.requisitions,
    title: 'Requisitions',
    insert: ({ data }) =>
      data.job_posting.requisition_postings
        ?.filter(
          item =>
            item.requisition.status === Statuses.opened ||
            item.requisition.status === Statuses.pending,
        )
        ?.map(item => item.requisition.requisition_title)
        ?.join(', ') || '',
  }

export const getHiringPipelineSummaryColumn: (
  isOwnPipelines?: boolean,
) => ColumnInterface<HiringPipelineInterface> = isOwnPipelines => ({
  type: CellTypes.insert,
  idPoint: 'summary',
  dataPoint: 'summary',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Summary',
  insert: ({ data }) => {
    return data.job_posting.id ? (
      <ChartInsideTooltip>
        <HiringPipelineChart id={data.id} isOwnPipelines={isOwnPipelines} />
      </ChartInsideTooltip>
    ) : (
      ''
    )
  },
})
