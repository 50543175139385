import { AxiosPromise } from 'axios'

import { FetchDataQueryInterface } from '@src/interfaces/data'
import { api, apiWithoutHandling } from '.'
import { uploadFile } from './files'
import { GetRequestInterface } from '@src/interfaces'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { ImportInterface, ImportSessionInterface } from '@src/interfaces/bulkDataImport'
import { useFetch } from '@src/utils/reactQuery'

export const importFile = async (file: File, endpoint: string, category: string) => {
  const uploadResult = await uploadFile(file, category, true, apiWithoutHandling)

  return apiWithoutHandling.post(endpoint, {
    file: { id: uploadResult.data.id },
  })
}

export const getImportFileTemplate = async (endpoint: string) => {
  const res = await api.get<Blob>(`${endpoint}/template`, {
    responseType: 'blob',
  })
  return URL.createObjectURL(res.data)
}

export const getUploadHistory = (
  endpoint: string,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<GetRequestInterface<ImportSessionInterface>> =>
  api.get(endpoint, { params: filterSortPageIntoQuery(sortBy, filters, page) })

export const useGetImportSessionData = (apiEndpoint: string, id: string) => {
  const data = useFetch<ImportSessionInterface>(
    `${apiEndpoint}/${id}`,
    undefined,
    undefined,
    true,
    {
      refetchInterval: session =>
        session?.state.id === 'pending' ||
        session?.state.id === 'processing_file' ||
        session?.state.id === 'applying'
          ? 1000
          : false,
    },
  )

  return data
}

export const getUploadSessionTable =
  <T>(apiEndpoint: string, id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ImportInterface<T>>> => {
    return api.get(`${apiEndpoint}/${id}/items`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
  }

export const deleteImportSessionRow =
  (apiEndpoint: string) => (sessionId: number, rowId: number) =>
    api.delete(`${apiEndpoint}/${sessionId}/items/${rowId}`)

export const editImportSessionRow = <T>(
  apiEndpoint: string,
  sessionId: number,
  rowId: number,
  data: ImportInterface<T>,
) => api.patch<ImportInterface<T>>(`${apiEndpoint}/${sessionId}/items/${rowId}`, data)

export const applyImportSession = (apiEndpoint: string, sessionId: number) =>
  api.post(`${apiEndpoint}/${sessionId}/apply`)
