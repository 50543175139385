import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { endOfYear, format, startOfYear } from 'date-fns'

import { Text, Token } from '@revolut/ui-kit'
import { selectUser } from '@src/store/auth/selectors'
import { Calendar } from '@src/features/Calendar/Calendar'
import { useEmployeeTimeOffCalendar } from '@src/api/timeOff'
import { TimeOffTableMonthCell } from '@src/features/TimeOffCalendarTable/TimeOffTableMonthCell'
import { DayBox } from '@src/features/Calendar/Day'
import { END_DATE_FILTER_KEY, START_DATE_FILTER_KEY } from './constants'

const defaultFormat = 'yyyy-MM-dd'

type Props = {
  employeeId: string | number
}
export const EmployeeTimeOffCalendar = ({ employeeId }: Props) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date())

  const startDateFilter = format(startOfYear(currentDate), defaultFormat)
  const endDateFilter = format(endOfYear(currentDate), defaultFormat)

  const user = useSelector(selectUser)
  const isSelfView = String(employeeId) === String(user.id)

  const { data: calendarData } = useEmployeeTimeOffCalendar(employeeId, {
    filters: [
      {
        filters: [
          {
            id: startDateFilter,
            name: startDateFilter,
          },
        ],
        columnName: START_DATE_FILTER_KEY,
        nonResettable: true,
      },
      {
        filters: [
          {
            id: endDateFilter,
            name: endDateFilter,
          },
        ],
        columnName: END_DATE_FILTER_KEY,
        nonResettable: true,
      },
    ],
  })
  const [employeeCalendarData] = calendarData || []

  const renderDay = (day: Date) => {
    if (!employeeCalendarData) {
      return null
    }

    return (
      <TimeOffTableMonthCell
        day={day}
        requestsCalendar={employeeCalendarData}
        emptyRequestBody={
          <DayBox withBorder>
            <Text color={Token.color.greyTone50}>{format(day, 'd')}</Text>
          </DayBox>
        }
        nonWorkingBody={
          <DayBox withBackground>
            <Text color={Token.color.greyTone20}>{format(day, 'd')}</Text>
          </DayBox>
        }
        canSeePolicy={isSelfView}
      />
    )
  }

  return (
    <Calendar
      isLoading={!employeeCalendarData}
      renderDay={renderDay}
      onCurrentDateChange={newDate => setCurrentDate(newDate)}
    />
  )
}
