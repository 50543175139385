import React, { useState, useRef } from 'react'
import { Button, Dropdown, Icon } from '@revolut/ui-kit'
import { Bank, CormorantGaramond, Profile, Services } from '@revolut/icons'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { ProfileSectionsData } from '@src/pages/EmployeeProfile/Preview/hooks'
import { useActiveProfileSidebar } from '@src/pages/EmployeeProfile/Layout/Summary/common'
import { canViewPersonal } from '../../ProfileSummary/common'

type Props = {
  data: EmployeeInterface
  sectionsData: ProfileSectionsData
}
export const EditDropdown = ({ data, sectionsData }: Props) => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const { setSidebarQueryParam } = useActiveProfileSidebar()
  const showPersonalDetailsSidebar = () => setSidebarQueryParam('personalDetails', true)

  const canRequestNameChange = !!sectionsData.name.permissions?.canRequestChange
  const canRequestPositionChange = !!sectionsData.position.permissions?.canRequestChange
  const canRequestOrganisationChange =
    !!sectionsData.organisation.permissions?.canRequestChange
  const showPersonal = canViewPersonal(data)

  const hasActions =
    canRequestNameChange ||
    canRequestPositionChange ||
    canRequestOrganisationChange ||
    showPersonal

  if (!hasActions) {
    return null
  }

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={() => setOpen(!open)}
        useIcon={<Icon name="Pencil" size={16} />}
        size="sm"
        variant="secondary"
      >
        Edit
      </Button>
      <Dropdown
        open={open}
        anchorRef={anchorRef}
        onClose={() => setOpen(false)}
        minWidth={180}
      >
        {canRequestNameChange && (
          <Dropdown.Item
            use={InternalLink}
            useIcon={CormorantGaramond}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
              employeeId: data.id,
              type: 'name',
            })}
          >
            Name
          </Dropdown.Item>
        )}
        {showPersonal && (
          <Dropdown.Item
            use="button"
            useIcon={Profile}
            onClick={() => {
              setOpen(false)
              showPersonalDetailsSidebar()
            }}
          >
            Personal
          </Dropdown.Item>
        )}
        {canRequestPositionChange && (
          <Dropdown.Item
            use={InternalLink}
            useIcon={Services}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
              employeeId: data.id,
              type: 'position',
            })}
          >
            Position
          </Dropdown.Item>
        )}
        {canRequestOrganisationChange && (
          <Dropdown.Item
            use={InternalLink}
            useIcon={Bank}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
              employeeId: data.id,
              type: 'organisation',
            })}
          >
            Organisation
          </Dropdown.Item>
        )}
      </Dropdown>
    </>
  )
}
