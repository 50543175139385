import React, { useState } from 'react'
import { BottomSheet, Flex, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { createDashboardRating } from '@src/api/analyticsDashboards'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { FeedbackCard } from '@src/features/Feedback/FeedbackCard'

interface Props {
  id: number
  isOpen: boolean
  onClose: () => void
}

export const RateDashboardPopup = ({ id, isOpen, onClose }: Props) => {
  const statusPopup = useStatusPopup()
  const { data: ratingLabels } = useGetSelectors(selectorKeys.dashboard_rating_labels)
  const [commentValue, setComment] = useState<string | undefined>()
  const [isPending, setIsPending] = useState(false)
  const [labelsValue, setLabelsValue] = useState<string[]>([])
  const [scoreValue, setScoreValue] = useState<number | null>(null)

  const handleSubmit = async () => {
    setIsPending(true)
    try {
      await createDashboardRating(id, {
        comment: commentValue,
        labels: ratingLabels
          ? labelsValue.map(item => ratingLabels.find(label => label.name === item)!.id)
          : [],
        score: scoreValue,
      })
      onClose()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Thank you, feedback received</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      onClose()
      statusPopup.show(
        <StatusPopup onClose={statusPopup.hide} variant="error">
          <StatusPopup.Title>Feedback was not submitted</StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }

  return (
    <BottomSheet onClose={onClose} open={isOpen}>
      <Flex alignItems="center" data-autofocus="true" flexDirection="column">
        <FeedbackCard
          labels={labelsValue}
          labelsOptions={ratingLabels?.map(label => label.name)}
          pending={isPending}
          placeholder="Leave your review (optional)"
          score={scoreValue}
          text={commentValue}
          title={`Rate this dashboard`}
          onLabelClick={option => {
            const checked = labelsValue.includes(option)

            if (checked) {
              setLabelsValue(prev => prev.filter(o => o !== option))
            } else {
              setLabelsValue(prev => [...prev, option])
            }
          }}
          onSubmit={handleSubmit}
          onScoreChange={setScoreValue}
          onTextChange={setComment}
        />
      </Flex>
    </BottomSheet>
  )
}
