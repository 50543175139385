import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, MoreBar, TableWidget } from '@revolut/ui-kit'
import { Plus, SwitchOff, SwitchOn } from '@revolut/icons'

import { EmployeeInterface } from '@src/interfaces/employees'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@components/Table/hooks'
import { getEmployeePolicyAssignments } from '@src/api/timeOff'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeTimeOffPolicyAssignmentInterface } from '@src/interfaces/timeOff'
import {
  timeOffPolicyAssignmentAssignedByColumn,
  timeOffPolicyAssignmentAssignedOnColumn,
  timeOffPolicyAssignmentCategoryColumn,
  timeOffPolicyAssignmentEndDateColumn,
  timeOffPolicyAssignmentNameColumn,
  timeOffPolicyAssignmentStartDateColumn,
  timeOffPolicyAssignmentStatusColumn,
} from '@src/constants/columns/timeOff'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { Statuses } from '@src/interfaces'
import { EmptyTableState, PolicyPortalButton, UserGuidesButton } from './common'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<EmployeeTimeOffPolicyAssignmentInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.PREVIEW, {
        employeeId: data.employee.id,
        id: data.id,
      }),
    ),
  cells: [
    {
      ...timeOffPolicyAssignmentCategoryColumn,
      width: 100,
    },
    {
      ...timeOffPolicyAssignmentNameColumn,
      width: 300,
    },
    {
      ...timeOffPolicyAssignmentStartDateColumn,
      width: 100,
    },
    {
      ...timeOffPolicyAssignmentEndDateColumn,
      width: 100,
    },
    {
      ...timeOffPolicyAssignmentAssignedOnColumn,
      width: 200,
    },
    {
      ...timeOffPolicyAssignmentAssignedByColumn,
      width: 200,
    },
    {
      ...timeOffPolicyAssignmentStatusColumn,
      width: 100,
    },
  ],
}

const showAllFilterOff = {
  columnName: 'status',
  filters: [
    { id: Statuses.active, name: Statuses.active },
    { id: Statuses.pending, name: Statuses.pending },
  ],
}

const showAllFilterOn = {
  columnName: 'status',
  filters: [],
}

interface Props {
  data: EmployeeInterface
}

export const EmployeeTimeOffPolicies = ({ data }: Props) => {
  const table = useTable({ getItems: getEmployeePolicyAssignments(data.id) }, [
    showAllFilterOff,
  ])

  const [showAllItems, setShowAllItems] = useState(false)

  const canAdd = data.field_options?.permissions?.includes(
    PermissionTypes.AddTimeOffPolicyAssignments,
  )

  const onShowAllChange = () => {
    const nextState = !showAllItems
    table.onFilterChange(nextState ? showAllFilterOn : showAllFilterOff)
    setShowAllItems(nextState)
  }

  const emptyState = table.data.length === 0

  const linkToAssignPolicy = pathToUrl(
    ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.GENERAL,
    {
      employeeId: data.id,
    },
  )

  return (
    <TableWidget>
      {emptyState ? null : (
        <TableWidget.Info>
          <Stat label="Policies" val={table?.loading ? undefined : table?.count} />
        </TableWidget.Info>
      )}
      <TableWidget.Actions>
        <MoreBar>
          {canAdd && !emptyState && (
            <MoreBar.Action use={InternalLink} to={linkToAssignPolicy} useIcon={Plus}>
              Assign policy
            </MoreBar.Action>
          )}
          <MoreBar.Action
            useIcon={showAllItems ? SwitchOn : SwitchOff}
            onClick={onShowAllChange}
          >
            Show all
          </MoreBar.Action>
          <PolicyPortalButton />
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.EmployeeTimeOffPolicies}
          useWindowScroll
          row={ROW}
          {...table}
          emptyState={
            <EmptyTableState
              title="No time off policies assigned yet"
              description={
                <>
                  You must ensure that the applicable time off policies have been assigned
                  to this employee before they can request time off.
                  <br />
                  If you need help enabling automatic policy assignment, or if something
                  doesn’t look right, please consult the time off user guides.
                </>
              }
              actions={
                <>
                  {canAdd && (
                    <Button
                      use={Link}
                      to={linkToAssignPolicy}
                      useIcon={Plus}
                      size="sm"
                      variant="secondary"
                    >
                      Assign policy
                    </Button>
                  )}
                  <UserGuidesButton />
                </>
              }
            />
          }
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
