import React from 'react'
import styled from 'styled-components'
import { Box, mq } from '@revolut/ui-kit'
import { Rocket, Lightning, Trophy, ArrowRightLeft, Target } from '@revolut/icons'

import { Grid } from '@src/components/CommonSC/Grid'
import { Card, Video } from '../common'

const StyledGrid = styled(Grid)`
  grid-gap: 16px;
  margin-top: 16px;

  @media ${mq('md')} {
    grid-gap: 32px;
    margin-top: 32px;
    grid-auto-flow: column;
  }
`

export const Values = () => {
  return (
    <Box mt={{ all: '80px', lg: '160px' }}>
      <Video />

      <StyledGrid>
        <Card
          title="Get It Done"
          text="Revoluters always push through! We stopped listening to excuses a long time ago - we care about results. We believe that grit, determination, skill, smartness, and courage can break through almost any wall."
          icon={<Rocket color="teal" />}
        />
        <Card
          title="Deliver WOW"
          text="We love building great products, we love delighting our customers, we love turning the complexity of a chaotic world into the simplicity of a beautiful solution that truly solves customer needs."
          icon={<Lightning color="teal" />}
        />
      </StyledGrid>
      <StyledGrid>
        <Card
          title="Dream Team"
          text="We don't settle for players who are just good. We select, coach, and retain the top talent only and give them all the support they need to achieve greatness."
          icon={<Trophy color="teal" />}
        />
        <Card
          title="Never Settle"
          text="Revolut is for those who always strive for excellence, for those who want to become the best in the world at what they do, for those who never settle for less."
          icon={<ArrowRightLeft color="teal" />}
        />
        <Card
          title="Think Deeper"
          text="Logic, reason, and common sense prevail over everything else in decision making at Revolut. We are open-minded, we listen, and we are always in search of the truth."
          icon={<Target color="teal" />}
        />
      </StyledGrid>
    </Box>
  )
}
