import React, { useEffect, useMemo, useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { chain, COLORS, Text } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import ResignationReasonsForm from './Reasons/Edit'
import ResignationReasonsView from './Reasons/View'
import ResignationSubmitForm from './Submit/Edit'
import ResignationSubmitView from './Submit/View'
import ResignationLetterForm from './Letter/Edit'
import ResignationLetterView from './Letter/View'
import ResignationDashboard from './Dashboard'
import {
  formatApproval,
  getProfileBackUrl,
  getResignationLetterStatusColor,
  getResignationStatusColor,
  RESIGNATION_LETTER_PAGE_TITLE,
  RESIGNATION_PAGE_TITLE,
} from './common'
import { HeaderStatusProps, ResignationPageTitle, RouteParams } from './types'
import { pathToUrl } from '@src/utils/router'
import NavigationBar from './Components/NavigationBar'
import {
  getEmployeeResignation,
  getEmployeeResignationApprovals,
} from '@src/api/employeeResignation'
import { useResignationPermissions } from './hooks'
import {
  EmployeeResignationInterface,
  ResignationApprovalInterface,
} from '@src/interfaces/employeeResignation'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'

const HeaderTitle = ({
  title,
  statusProps,
}: {
  title: string
  statusProps?: HeaderStatusProps
}) => {
  return (
    <Text variant="h1">
      {chain(
        title,
        statusProps?.name && (
          <Text variant="h1" color={statusProps.color}>
            {statusProps.name}
          </Text>
        ),
      )}
    </Text>
  )
}

const EmployeeResignation = () => {
  const { employeeId, id: resignationId, tab, action } = useParams<RouteParams>()

  const [resignation, setResignation] = useState<EmployeeResignationInterface>()
  const { canView } = useResignationPermissions(resignation)
  const [resignationApprovals, setResignationApprovals] =
    useState<ResignationApprovalInterface[]>()
  const approval = resignationApprovals?.length
    ? resignationApprovals[resignationApprovals.length - 1]
    : undefined
  const formattedApprovals = useMemo(
    () => (resignationApprovals?.length ? resignationApprovals?.map(formatApproval) : []),
    [resignationApprovals],
  )
  const [statusProps, setStatusProps] = useState<HeaderStatusProps>()
  const [backUrl, setBackUrl] = useState<string>(getProfileBackUrl(Number(employeeId)))
  const [title, setTitle] = useState<ResignationPageTitle>(RESIGNATION_PAGE_TITLE)
  const [noticePeriod, setNoticePeriod] = useState<string>('')

  const getResignation = async () => {
    const { data } = await getEmployeeResignation(employeeId, resignationId)
    setResignation(data)
  }
  const getApprovals = async () => {
    const { data } = await getEmployeeResignationApprovals(employeeId, resignationId)
    setResignationApprovals(data)
  }

  useEffect(() => {
    if (resignationId) {
      getResignation()
      getApprovals()
    }
    if (canView) {
      setStatusProps({
        name: resignation?.status?.name || '',
        color: getResignationStatusColor(resignation?.status?.id),
      })
    }
    switch (tab) {
      case 'reasons':
        setTitle(RESIGNATION_PAGE_TITLE)
        setBackUrl(pathToUrl(getProfileBackUrl(Number(employeeId))))
        return
      case 'submit':
        setTitle(RESIGNATION_PAGE_TITLE)
        setBackUrl(
          pathToUrl(
            action === 'edit'
              ? ROUTES.FORMS.EMPLOYEE_RESIGNATION.REASONS
              : ROUTES.FORMS.EMPLOYEE_RESIGNATION.REASONS_PREVIEW,
            {
              employeeId,
              id: resignationId,
            },
          ),
        )
        return
      case 'letter':
        if (canView) {
          setStatusProps({
            name: approval?.approval_status?.name || '',
            color: getResignationLetterStatusColor(approval?.approval_status?.id),
          })
        }
        setTitle(RESIGNATION_LETTER_PAGE_TITLE)

        if (action === 'edit' || action === 'edit-view') {
          setBackUrl(
            pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.SUBMIT, {
              id: resignationId,
              employeeId,
            }),
          )
        } else if (action === 'view') {
          setBackUrl(
            pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.SUBMIT_PREVIEW, {
              id: resignationId,
              employeeId,
            }),
          )
        }
        return
      case 'dashboard':
        setNoticePeriod(resignation?.notice_period || '')

        if (action === 'view') {
          setTitle(RESIGNATION_PAGE_TITLE)
          setBackUrl(pathToUrl(getProfileBackUrl(Number(employeeId))))
        } else if (action === 'approve') {
          setTitle(RESIGNATION_LETTER_PAGE_TITLE)
          setBackUrl(
            pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.DASHBOARD, {
              id: resignationId,
              employeeId,
            }),
          )
          if (canView) {
            setStatusProps({
              name: approval?.approval_status?.name || '',
              color: getResignationLetterStatusColor(approval?.approval_status?.id),
            })
          }
        }
    }
  }, [tab, action, employeeId, resignationId])

  return (
    <PageWrapper>
      <PageHeader
        title={<HeaderTitle title={title} statusProps={statusProps} />}
        subtitle={<EmployeeUserWithAvatar id={employeeId} />}
        backUrl={backUrl}
      />
      {noticePeriod && (
        <Text use="div" variant="caption" color={COLORS['grey-tone-50']} mt="-s-12">
          Notice period as per contract: {noticePeriod}
        </Text>
      )}
      {(tab === 'reasons' || tab === 'submit') && (
        <NavigationBar resignation={resignation} />
      )}
      <Switch>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_RESIGNATION.REASONS}>
          <ResignationReasonsForm resignation={resignation} />
        </Route>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_RESIGNATION.REASONS_PREVIEW}>
          <ResignationReasonsView resignation={resignation} />
        </Route>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_RESIGNATION.SUBMIT}>
          <ResignationSubmitForm resignation={resignation} approval={approval} />
        </Route>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_RESIGNATION.SUBMIT_PREVIEW}>
          <ResignationSubmitView resignation={resignation} approval={approval} />
        </Route>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_RESIGNATION.LETTER}>
          <ResignationLetterForm resignation={resignation} />
        </Route>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_RESIGNATION.LETTER_EDIT_PREVIEW}>
          <ResignationLetterForm resignation={resignation} />
        </Route>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_RESIGNATION.LETTER_PREVIEW}>
          <ResignationLetterView
            resignation={resignation}
            approvalSteps={formattedApprovals as unknown as ApprovalFlowResponse}
            getApprovals={getApprovals}
            updateResignation={setResignation}
          />
        </Route>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_RESIGNATION.DASHBOARD}>
          <ResignationDashboard
            resignation={resignation}
            getResignation={getResignation}
            approval={approval}
            getApprovals={getApprovals}
          />
        </Route>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_RESIGNATION.DASHBOARD_LETTER_APPROVE}>
          <ResignationLetterView
            resignation={resignation}
            approvalSteps={formattedApprovals as unknown as ApprovalFlowResponse}
            getApprovals={getApprovals}
            updateResignation={setResignation}
          />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default EmployeeResignation
