import React, { useState } from 'react'
import {
  ChangeScorecardInterface,
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ScorecardError,
  SkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { FormError, useLapeContext } from '@src/features/Form/LapeForm'
import ScorecardSection from '@components/ScorecardGeneral/ScorecardSection'
import {
  CardContentTypes,
  cardHasMissingValues,
  countSuperpowers,
  SelectedFieldInterface,
  updateValueRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import set from 'lodash/set'
import {
  getCardsForView,
  getNormalizedCards,
  getUpdatedRatingCard,
} from '@src/utils/performance'
import ScorecardValueBasedSection from '@components/ScorecardGeneral/ScorecardValueBasedSection'

interface Props {
  isViewMode: boolean
  selectedField: SelectedFieldInterface
}

export const Skills = connect(({ isViewMode, selectedField }: Props) => {
  const [resultsLoading, setResultsLoading] = useState<(number | string)[]>([])
  const { values, errors, submitFailed, fields } = useLapeContext<
    ReviewScorecardInterface | ReviewScorecardViewInterface
  >()

  const reviewData = isViewMode
    ? (values as ReviewScorecardViewInterface).summary
    : (values as ReviewScorecardInterface).review_data
  const formErrors = errors as FormError<ReviewScorecardInterface>

  if (selectedField.cardIndex === undefined) {
    return null
  }

  const hasManagerValues = !!reviewData?.manager_values

  const managerValuesCards = reviewData?.manager_values?.cards || []
  const functionalSkillsCards = isViewMode
    ? getCardsForView(reviewData?.functional_skills?.cards || [])
    : getNormalizedCards(
        (values as ReviewScorecardInterface).review_data?.functional_skills?.cards || [],
      )
  const skillsCards = hasManagerValues ? managerValuesCards : functionalSkillsCards
  const card = skillsCards[selectedField.cardIndex]

  if (!card) {
    return null
  }

  const errorsList = !submitFailed
    ? undefined
    : hasManagerValues
    ? (formErrors?.review_data?.manager_values?.cards as ScorecardError[])
    : (formErrors?.review_data?.functional_skills?.cards as ScorecardError[])

  const touched = Object.values(fields).some(state => state.touched)
  const missingValues = cardHasMissingValues(
    CardContentTypes.SKILLS,
    card as SkillCardInterface,
  )

  const onChange = async ({
    cardIndex,
    sectionIndex,
    optionIndex,
    value,
  }: ChangeScorecardInterface) => {
    set(
      values,
      `${selectedField.field}.sections.${sectionIndex}.items.${optionIndex}.value`,
      value,
    )

    if ('id' in values) {
      const updatedCard = await getUpdatedRatingCard(
        values.id,
        values.category,
        values.reviewed_employee.id!,
        values.review_data?.functional_skills?.cards?.[cardIndex],
      )

      if (updatedCard) {
        set(values, `${selectedField.field}.rating`, updatedCard.rating)
      }
    }
  }

  const onChangeManagerValues = async ({
    sectionIndex,
    value,
  }: ChangeScorecardInterface) => {
    set(values, `${selectedField.field}.sections.${sectionIndex}.value`, value)

    updateValueRating({
      values: values as ReviewScorecardInterface,
      path: selectedField.field,
    })
  }

  return hasManagerValues ? (
    <ScorecardValueBasedSection
      mt={0}
      card={card as ValueBasedCardInterface}
      cardIndex={selectedField.cardIndex}
      category={ReviewCategory.Performance}
      errors={errorsList?.[selectedField.cardIndex]}
      key={`${(card as ValueBasedCardInterface).value_id}`}
      onChange={onChangeManagerValues}
      sectionErrors={errorsList}
      superpowersCount={countSuperpowers(skillsCards as ValueBasedCardInterface[])}
      touched={touched}
      type={PerformanceReviewTypes.managerValuesSkills}
      valueId={(card as ValueBasedCardInterface).value_id}
      viewMode={isViewMode}
      hideCount
    />
  ) : (
    <ScorecardSection
      mt={0}
      noWidgetMargin
      skillId={`${
        (card as SkillCardInterface).skill_id || (card as SkillCardInterface).criteria_key
      }`}
      cardIndex={selectedField.cardIndex}
      key={`${
        (card as SkillCardInterface).skill_id || (card as SkillCardInterface).criteria_key
      }`}
      disabled={isViewMode}
      type={PerformanceReviewTypes.skills}
      category={ReviewCategory.Performance}
      onChange={onChange}
      viewMode={isViewMode}
      errors={errorsList?.[selectedField.cardIndex]}
      sectionErrors={errorsList}
      touched={!!errorsList?.[selectedField.cardIndex] || touched}
      previousReviews={undefined}
      card={card as SkillCardInterface}
      resultLoading={
        !!(card as SkillCardInterface).skill_id &&
        resultsLoading.includes((card as SkillCardInterface).skill_id!)
      }
      onSetResultLoading={setResultsLoading}
      titleTooltip={(card as SkillCardInterface).titleTooltip}
      showErrorLabel={missingValues}
      hideCountAndPrefill
      showExceeding
    />
  )
})
