import React from 'react'
import {
  ChangeScorecardInterface,
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ScorecardError,
  SkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { FormError, useLapeContext } from '@src/features/Form/LapeForm'
import ScorecardSection from '@components/ScorecardGeneral/ScorecardSection'
import {
  CardContentTypes,
  cardHasMissingValues,
  countSuperpowers,
  SelectedFieldInterface,
  updateValueRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import set from 'lodash/set'
import { getNormalizedCards, getUpdatedRatingCard } from '@src/utils/performance'
import ScorecardValueBasedSection from '@components/ScorecardGeneral/ScorecardValueBasedSection'

interface Props {
  isViewMode: boolean
  selectedField: SelectedFieldInterface
}

export const CultureValues = connect(({ isViewMode, selectedField }: Props) => {
  const { values, errors, submitFailed, fields } = useLapeContext<
    ReviewScorecardInterface | ReviewScorecardViewInterface
  >()

  const reviewData = isViewMode
    ? (values as ReviewScorecardViewInterface).summary
    : (values as ReviewScorecardInterface).review_data
  const formErrors = errors as FormError<ReviewScorecardInterface>

  if (selectedField.cardIndex === undefined) {
    return null
  }

  const hasCultureValues = !!reviewData?.culture_values

  const cultureValuesCards = reviewData?.culture_values?.cards || []

  const cultureSkillsCards = getNormalizedCards(reviewData?.culture_skills?.cards || [])

  const valuesCards = hasCultureValues ? cultureValuesCards : cultureSkillsCards
  const card = valuesCards[selectedField.cardIndex]

  if (!card) {
    return null
  }

  const onChange = async ({
    cardIndex,
    sectionIndex,
    optionIndex,
    value,
  }: ChangeScorecardInterface) => {
    const path = `review_data.culture_skills.cards.${cardIndex}`
    set(values, `${path}.sections.${sectionIndex}.items.${optionIndex}.value`, value)

    if ('id' in values) {
      const updatedCard = await getUpdatedRatingCard(
        values.id,
        values.category,
        values.reviewed_employee.id!,
        values.review_data?.culture_skills?.cards?.[cardIndex],
      )
      if (updatedCard) {
        set(values, `${path}.rating`, updatedCard.rating)
      }
    }
  }

  const onChangeCultureValues = async ({
    cardIndex,
    sectionIndex,
    value,
  }: ChangeScorecardInterface) => {
    const path = `review_data.culture_values.cards.${cardIndex}`
    set(values, `${path}.sections.${sectionIndex}.value`, value)

    updateValueRating({ values: values as ReviewScorecardInterface, path })
  }

  const allErrors = !submitFailed
    ? undefined
    : hasCultureValues
    ? (formErrors?.review_data?.culture_values?.cards as ScorecardError[])
    : (formErrors?.review_data?.culture_skills?.cards as ScorecardError[])

  const touched = Object.values(fields).some(state => state.touched)
  const missingValues = cardHasMissingValues(
    CardContentTypes.VALUES,
    card as SkillCardInterface,
  )

  return hasCultureValues ? (
    <ScorecardValueBasedSection
      mt={0}
      card={card as ValueBasedCardInterface}
      cardIndex={selectedField.cardIndex}
      category={ReviewCategory.Performance}
      errors={allErrors?.[selectedField.cardIndex]}
      key={`${(card as ValueBasedCardInterface).value_id}`}
      onChange={onChangeCultureValues}
      sectionErrors={allErrors}
      superpowersCount={countSuperpowers(valuesCards as ValueBasedCardInterface[])}
      touched={touched}
      type={PerformanceReviewTypes.cultureValuesFit}
      valueId={(card as ValueBasedCardInterface).value_id}
      viewMode={isViewMode}
      hideCount
    />
  ) : (
    <ScorecardSection
      mt={0}
      noWidgetMargin
      skillId={`${
        (card as SkillCardInterface).skill_id || (card as SkillCardInterface).criteria_key
      }`}
      cardIndex={selectedField.cardIndex}
      key={`${
        (card as SkillCardInterface).skill_id || (card as SkillCardInterface).criteria_key
      }`}
      disabled={isViewMode}
      type={PerformanceReviewTypes.cultureFit}
      category={ReviewCategory.Performance}
      onChange={onChange}
      viewMode={isViewMode}
      errors={allErrors?.[selectedField.cardIndex]}
      sectionErrors={allErrors}
      touched={!!allErrors?.[selectedField.cardIndex] || touched}
      previousReviews={reviewData?.previous_reviews_by_cycle}
      card={card as SkillCardInterface}
      onSetResultLoading={() => {}}
      showErrorLabel={missingValues}
      hideCountAndPrefill
      showExceeding
    />
  )
})
