import React, { useState } from 'react'
import {
  Avatar,
  Button,
  chain,
  Color,
  Flex,
  Group,
  Item,
  Subheader,
  Box,
  Text,
  MoreBar,
  Popup,
  Header,
  StatusPopup,
  Input,
  VStack,
} from '@revolut/ui-kit'
import {
  ArrowRightLeft,
  ArrowSend,
  Chat,
  Check,
  Cross,
  Document,
  Envelope,
  IconComponentType,
  Jira,
  Key,
  Profile,
  SandWatch,
  Time,
} from '@revolut/icons'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { OffboardingScreenProps, taskStatusToColor, useOffboardingParams } from './common'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { formatDate, formatDateTime, formatWithoutTimezone } from '@src/utils/format'
import { getTimeFromString } from '@src/utils/timezones'
import Tooltip from '@src/components/Tooltip/Tooltip'
import DocumentSidebar from '@src/features/DocumentSidebar/DocumentSidebar'
import {
  OffboardingTaskInterface,
  OffboardingTaskType,
} from '@src/interfaces/offboarding'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { getStatusThemeColor } from '@src/components/CommonSC/General'
import SettingsButtons from '@src/features/SettingsButtons'
import {
  cancelTermination,
  completeTermination,
  createAccessRetentionRequest,
  getOffboardingDashboardCommentsAPI,
  updateOffboardingHRManager,
  useHrManagers,
} from '@src/api/offboarding'
import ChatSidebar from '@src/components/Chat/ChatSidebar'
import { ChatMessageType } from '@src/components/Chat/common'
import SideBar from '@src/components/SideBar/SideBar'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { EmployeeEmails } from '@src/features/EmployeeEmails/EmployeeEmails'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PageActions } from '@src/components/Page/PageActions'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { PermissionTypes } from '@src/store/auth/types'
import { useGetLifecycleSettings } from '@src/api/settings'
import { getEmployeePosition } from './helpers'

interface OffboardingTaskProps {
  task: OffboardingTaskInterface
  onSendEmail: () => void
}

const taskToAvatar: Record<OffboardingTaskType, IconComponentType> = {
  termination_payments: Document,
  send_email: Envelope,
  jira_tasks_progress: Jira,
  transfer_ownership: ArrowRightLeft,
}

const taskStatusToIcon = {
  completed: Check,
  pending: SandWatch,
} as const

const approvalStatusToIcon = {
  pending: SandWatch,
  rejected: Cross,
  approved: Check,
}

const processStatusToColor = {
  completed: Color.GREEN,
  pending: Color.ORANGE,
}

const OffboardingTask = ({ task, onSendEmail }: OffboardingTaskProps) => {
  const params = useOffboardingParams()

  const description = (() => {
    if (task.status.id === 'pending') {
      return <Text color={Color.ORANGE}>Pending</Text>
    }

    if (task.status.id === 'completed') {
      if (task.completed_at && task.completed_by) {
        return `Completed by ${task.completed_by.full_name}, ${formatDateTime(
          task.completed_at,
        )}`
      }
      return `Completed`
    }

    return ''
  })()

  const onTerminationPaymentsClick = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.OFFBOARDING.PAYMENTS, params))
  }

  const onJiraTasksClick = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.OFFBOARDING.TICKETS, params))
  }

  const onTransferOwnershipClick = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ALL, { id: params.employeeId }))
  }

  const taskToCallback: Record<OffboardingTaskType, () => void> = {
    termination_payments: onTerminationPaymentsClick,
    send_email: onSendEmail,
    jira_tasks_progress: onJiraTasksClick,
    transfer_ownership: onTransferOwnershipClick,
  }

  return (
    <Item
      use="button"
      variant="disclosure"
      type="button"
      onClick={taskToCallback[task.name.id]}
    >
      <Item.Avatar>
        <Avatar useIcon={taskToAvatar[task.name.id]}>
          <Avatar.Badge
            useIcon={taskStatusToIcon[task.status.id]}
            bg={taskStatusToColor[task.status.id]}
          />
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{task.name.name}</Item.Title>
        <Item.Description>{description}</Item.Description>
      </Item.Content>
    </Item>
  )
}

type OpenedSidebar =
  | 'chat'
  | 'transfer-ownership'
  | 'correspondence'
  | 'send-offboarding-email'
  | null

export const Dashboard = ({ data, refreshDashboard }: OffboardingScreenProps) => {
  const params = useOffboardingParams()

  const backUrl = ROUTES.PEOPLE.OFFBOARDING

  const [selectedDocumentId, setSelectedDocumentId] = useState<number>()
  const [documentSidebarOpen, setDocumentSidebarOpen] = useState(false)
  const [openedSidebar, setOpenedSidebar] = useState<OpenedSidebar>(null)
  const [offboardingCompletedModalOpen, setOffboardingCompletedModalOpen] =
    useState(false)
  const [confirmOwnershipPending, setConfirmOwnershipPending] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [cancelReason, setCancelReason] = useState('')
  const [newHrManager, setNewHrManager] = useState<EmployeeOptionInterface>()
  const [requestAccessRetentionPopupOpen, setRequestAccessRetentionPopupOpen] =
    useState(false)
  const [requestAccessRetentionPending, setRequestAccessRetentionPending] =
    useState(false)

  const commentsApi = getOffboardingDashboardCommentsAPI(params.employeeId)
  const {
    data: chatData,
    isLoading: isChatLoading,
    refetch: refetchChat,
  } = commentsApi.useGetComments()
  const { data: lifecycleSettings } = useGetLifecycleSettings()

  const offboardingCanBeCompleted = data.offboarding_tasks
    .filter(task => task.is_required)
    .every(task => task.status.id === 'completed')

  const { data: hrManagers } = useHrManagers(params.employeeId)

  const onMarkOffboardingComplete = async () => {
    if (!offboardingCanBeCompleted) {
      throw new Error('Offboarding tasks not complete')
    }

    await completeTermination(params.employeeId)
    setOffboardingCompletedModalOpen(true)
  }

  const onConfirmTransferOwnership = async () => {
    if (!newHrManager) {
      return
    }

    setConfirmOwnershipPending(true)

    try {
      await updateOffboardingHRManager(params.employeeId, newHrManager)
      refreshDashboard()
      setOpenedSidebar(null)
    } finally {
      setConfirmOwnershipPending(false)
    }
  }

  const onRequestAccessRetention = () => {
    setRequestAccessRetentionPending(true)

    createAccessRetentionRequest(params.employeeId)
      .then(() => {
        refreshDashboard()
        setRequestAccessRetentionPopupOpen(false)
      })
      .finally(() => {
        setRequestAccessRetentionPending(false)
      })
  }

  const cancel = () => {
    setCancelLoading(true)
    cancelTermination(params.employeeId, cancelReason)
      .then(() => {
        pushNotification({
          value: 'Termination cancelled',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
        setCancelModalOpen(false)
        navigateTo(pathToUrl(backUrl))
      })
      .finally(() => {
        setCancelLoading(false)
      })
  }

  const showCompleteButton =
    data.status.id !== 'cancelled' && data.status.id !== 'completed'

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={chain(
            'Offboarding dashboard',
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            <Text color={processStatusToColor[data.status.id]}>{data.status.name}</Text>,
          )}
          subtitle={
            <Flex flexDirection="column" gap="s-4" mt="s-4">
              <Flex alignItems="center">
                {chain(
                  <UserWithAvatar {...data.employee} asText />,
                  <Text fontSize="16px" color={Color.FOREGROUND}>
                    {getEmployeePosition(data.employee)} at {data.employee.team.name},{' '}
                    {data.employee.location.name}
                  </Text>,
                )}
              </Flex>
              <Text color={Color.GREY_TONE_50} variant="caption">
                Offboarding manager: {data.hr_manager?.full_name || '-'}
              </Text>
            </Flex>
          }
          backUrl={backUrl}
        />

        <PageBody>
          <SettingsButtons mb="s-24">
            <MoreBar.Action useIcon={Chat} onClick={() => setOpenedSidebar('chat')}>
              Comments
            </MoreBar.Action>
            <MoreBar.Action
              onClick={() => setOpenedSidebar('correspondence')}
              useIcon={Envelope}
            >
              Correspondence
            </MoreBar.Action>
            <MoreBar.Action
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: params.employeeId })}
              use={InternalLink}
              useIcon={Profile}
            >
              Profile
            </MoreBar.Action>
            {data.el_ticket_url && (
              <MoreBar.Action
                href={data.el_ticket_url}
                useIcon={Jira}
                rel="noreferrer noopener"
                target="_blank"
                use="a"
              >
                EL ticket
              </MoreBar.Action>
            )}
            <MoreBar.Action
              onClick={() => setOpenedSidebar('transfer-ownership')}
              useIcon={ArrowRightLeft}
            >
              Reassign
            </MoreBar.Action>
            {data?.field_options?.permissions?.includes(
              PermissionTypes.CancelEmployeeTermination,
            ) && (
              <MoreBar.Action
                onClick={() => setCancelModalOpen(true)}
                useIcon={Time}
                color="red"
              >
                Cancel termination
              </MoreBar.Action>
            )}
          </SettingsButtons>

          <FormPreview
            title="Termination details"
            data={data}
            onEdit={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION_V2, {
                  id: params.employeeId,
                }),
              )
            }
          >
            <Group>
              <FormPreview.Item
                title="Last day in office"
                insert={() =>
                  data.termination_date_time
                    ? `${formatDate(data.termination_date_time)}, ${getTimeFromString(
                        data.termination_date_time,
                      )}, (${data.termination_date_timezone?.name})`
                    : '-'
                }
              />
              <FormPreview.Item
                title="Last day of contract"
                insert={() =>
                  data.last_day_of_contract
                    ? formatWithoutTimezone(data.last_day_of_contract)
                    : '-'
                }
              />
              <FormPreview.Item title="Termination type" field="termination_type.name" />
              <FormPreview.Item
                title="Reason for termination"
                field="termination_reason.name"
              />

              <FormPreview.Item
                title="Documents"
                insert={() =>
                  data.uploaded_documents.length ? (
                    <Flex>
                      {data.uploaded_documents.map(doc => (
                        <Tooltip text={doc.name} placement="bottom" key={doc.id}>
                          <Button
                            onClick={() => {
                              setDocumentSidebarOpen(true)
                              setSelectedDocumentId(Number(doc.id))
                            }}
                            ml="s-16"
                            useIcon={Document}
                            variant="secondary"
                            size="sm"
                          />
                        </Tooltip>
                      ))}
                    </Flex>
                  ) : (
                    '-'
                  )
                }
              />
            </Group>
          </FormPreview>

          {lifecycleSettings?.enable_access_retention ? (
            <>
              <Box mt="s-16">
                <Subheader variant="nested">
                  <Subheader.Title>System access retention</Subheader.Title>
                </Subheader>
              </Box>

              {data.access_retention == null ? (
                <Item>
                  <Item.Avatar>
                    <Avatar color={Color.GREY_TONE_20} useIcon={Key} />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title color={Color.GREY_TONE_50}>Not requested</Item.Title>
                  </Item.Content>
                  <Item.Side>
                    <Button
                      onClick={() => setRequestAccessRetentionPopupOpen(true)}
                      useIcon={ArrowSend}
                      variant="secondary"
                      size="sm"
                    >
                      Request access retention
                    </Button>
                  </Item.Side>
                </Item>
              ) : (
                <Item
                  use="button"
                  type="button"
                  onClick={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.OFFBOARDING.ACCESS_RETENTION, params),
                    )
                  }
                >
                  <Item.Avatar>
                    <Avatar useIcon={Key}>
                      <Avatar.Badge
                        useIcon={
                          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                          approvalStatusToIcon[data.access_retention.approval_status.id]
                        }
                        bg={getStatusThemeColor(data.access_retention.approval_status.id)}
                      />
                    </Avatar>
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>Access retention</Item.Title>
                    <Item.Description>
                      {(() => {
                        if (data.access_retention.status.id === 'pending_approval') {
                          return `Requested by ${
                            data.access_retention.requested_by.full_name
                          } on ${formatWithoutTimezone(
                            data.access_retention.requested_on,
                          )}`
                        }
                        if (data.access_retention.status.id === 'rejected') {
                          return `Rejected by ${
                            data.access_retention.approver?.full_name
                          } on ${
                            data.access_retention.approval_date_time
                              ? formatWithoutTimezone(
                                  data.access_retention.approval_date_time,
                                )
                              : '-'
                          }`
                        }
                        if (data.access_retention.status.id === 'approved') {
                          return `Approved by ${
                            data.access_retention.approver?.full_name
                          } on ${
                            data.access_retention.approval_date_time
                              ? formatWithoutTimezone(
                                  data.access_retention.approval_date_time,
                                )
                              : '-'
                          }`
                        }
                        return null
                      })()}
                    </Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <Item.Value>
                      <Text
                        color={getStatusThemeColor(
                          data.access_retention.approval_status.id,
                        )}
                      >
                        {data.access_retention.status.name}
                      </Text>
                    </Item.Value>
                  </Item.Side>
                </Item>
              )}
            </>
          ) : null}

          <Box mt="s-16">
            <Subheader variant="nested">
              <Subheader.Title>Offboarding tasks</Subheader.Title>
            </Subheader>
          </Box>

          <Group>
            {data.offboarding_tasks
              .sort((a, b) => a.id - b.id)
              .map(task => (
                <OffboardingTask
                  task={task}
                  onSendEmail={() => setOpenedSidebar('send-offboarding-email')}
                  key={task.id}
                />
              ))}
          </Group>
        </PageBody>
      </PageWrapper>

      {selectedDocumentId && (
        <DocumentSidebar
          employeeId={data.id}
          id={selectedDocumentId}
          isOpen={documentSidebarOpen}
          onClose={() => setDocumentSidebarOpen(false)}
        />
      )}

      <SideBar
        title={`Correspondence with ${data.employee.full_name}`}
        isOpen={
          openedSidebar === 'correspondence' || openedSidebar === 'send-offboarding-email'
        }
        onClose={() => setOpenedSidebar(null)}
        variant="wide"
      >
        <EmployeeEmails
          employeeId={data.employee.id}
          emailUrl={
            openedSidebar === 'send-offboarding-email'
              ? pathToUrl(ROUTES.FORMS.OFFBOARDING.SEND_EMAIL, params)
              : undefined
          }
        />
      </SideBar>

      <ChatSidebar
        type={ChatMessageType.Comment}
        isOpen={openedSidebar === 'chat'}
        onClose={() => setOpenedSidebar(null)}
        data={chatData?.results || []}
        isLoading={isChatLoading}
        refetch={refetchChat}
        onAddMessage={commentsApi.addComment}
        onEdit={commentsApi.editComment}
        onArchive={commentsApi.archiveComment}
        onResolve={commentsApi.resolveComment}
      />

      <SideBar
        title="Reassign"
        isOpen={openedSidebar === 'transfer-ownership'}
        onClose={() => setOpenedSidebar(null)}
        variant="wide"
      >
        <MoreBar>
          <MoreBar.Action
            useIcon={Check}
            onClick={onConfirmTransferOwnership}
            variant="accent"
            pending={confirmOwnershipPending}
            disabled={newHrManager?.id === data.hr_manager?.id}
          >
            Confirm
          </MoreBar.Action>
        </MoreBar>

        <Box mt="s-16">
          <RadioSelectInput
            label="HR manager"
            options={
              hrManagers?.options.map(hrManager => ({
                label: hrManager.full_name,
                value: {
                  ...hrManager,
                  id: hrManager.id,
                },
                key: hrManager.id,
              })) || []
            }
            onChange={option => {
              if (option) {
                setNewHrManager(option)
              }
            }}
            value={newHrManager}
          />
        </Box>
      </SideBar>

      <Popup
        open={requestAccessRetentionPopupOpen}
        variant="bottom-sheet"
        onClose={() => setRequestAccessRetentionPopupOpen(false)}
      >
        <Header variant="bottom-sheet">
          <Text fontSize="h5" fontWeight={600}>
            Do you want to request access retention during gardening leave for{' '}
            {data.employee.full_name}?
          </Text>
          <Header.Description>This will be subject to approval.</Header.Description>
        </Header>
        <Popup.Actions horizontal>
          <Button
            onClick={() => setRequestAccessRetentionPopupOpen(false)}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={onRequestAccessRetention}
            pending={requestAccessRetentionPending}
            elevated
          >
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>

      <StatusPopup
        variant="success-result"
        onClose={() => setOffboardingCompletedModalOpen(false)}
        open={offboardingCompletedModalOpen}
      >
        <StatusPopup.Title>Offboarding successfully completed</StatusPopup.Title>
        <StatusPopup.Description>
          Employee’s status will be changed to ‘Terminated’
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.employee.id })}
            elevated
          >
            Go to employee's profile
          </Button>
          <Button
            use={InternalLink}
            to={pathToUrl(ROUTES.PEOPLE.OFFBOARDING)}
            variant="secondary"
          >
            Go back to offboarding queue
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>

      <Popup
        open={cancelModalOpen}
        variant="bottom-sheet"
        onClose={() => setCancelModalOpen(false)}
      >
        <Header variant="compact">
          <Header.Title>
            Are you sure you want to cancel employee’s termination and offboarding
            process?
          </Header.Title>
        </Header>
        <VStack gap="s-8">
          <Text color="gray">Offboarding status will be changed to ‘Cancelled’.</Text>
          <Input
            placeholder="Reason of cancellation"
            value={cancelReason}
            onChange={e => setCancelReason(e.currentTarget.value)}
          />
        </VStack>
        <Popup.Actions>
          <Flex flexDirection="row" alignItems="center" gap="s-16">
            <Button variant="secondary" onClick={() => setCancelModalOpen(false)}>
              Cancel
            </Button>
            <Button variant="primary" elevated onClick={cancel} pending={cancelLoading}>
              Confirm
            </Button>
          </Flex>
        </Popup.Actions>
      </Popup>

      <PageActions>
        {showCompleteButton && (
          <Button
            onClick={onMarkOffboardingComplete}
            disabled={!offboardingCanBeCompleted}
          >
            Complete offboarding
          </Button>
        )}
      </PageActions>
    </>
  )
}
